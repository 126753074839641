import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { toast } from 'react-toastify';
import { communicationGroupStudentListSvc } from "./commGroupsSvc";

const CommunicationGroupsStudentTable = (props, ref) => {

    const [lstStudents, setLstStudents] = useState([]);
    const [lstStudentFiltered, setLstStudentFiltered] = useState([]);
    const [studentId, setStudentId] = useState(0);

    const [studentFiltered, setStudentFiltered] = useState("");
    const [enrollmentNoFiltered, setenrollmentNoFiltered] = useState("");
    const [classFiltered, setClassFiltered] = useState("");
    const [sectionFiltered, setSectionFiltered] = useState("");
    const [minDueFiltered, setMinDueFiltered] = useState("");
    const [maxDueFiltered, setMaxDueFiltered] = useState("");

    const [isAllSelected, setIsAllSelected] = useState(false);

    useEffect(() => {
        // // console.log("CommunicationGroupStudentTable called, groupId passed", props.groupId);
        getStudentTable();
    }, [props.groupId]);

    const getStudentTable = () => {
        // // console.log("getStudentTable called");
        props.setLoader("block");
        props.setOpacity(0.5);
        communicationGroupStudentListSvc(props.groupId, studentListResponse);
    }

    const studentListResponse = (data) => {
        // // console.log("studentListResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            setLstStudents(obj.lstCommGroupStudent);
            setLstStudentFiltered(obj.lstCommGroupStudent);
            countSelected(obj.lstCommGroupStudent);

            const initialSelected = obj.lstCommGroupStudent
                .filter((student) => student.selected === true)
                .map((student) => student.studentId);

            props.setLstSelected(initialSelected);

        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        props.setLoader('none');
        props.setOpacity(1);
    }

    const toggleStudentSelection = (sId) => {
            if (props.lstSelected.length === lstStudents.length){
                setIsAllSelected(false);
            } else if(props.lstSelected.length === lstStudents.length-1){
                if(!props.lstSelected.includes(sId)){
                    setIsAllSelected(true);
                }
            }

            if (props.lstSelected.includes(sId)) {
                const updatedLstSelected = props.lstSelected.filter((id) => id !== sId);
                props.setLstSelected(updatedLstSelected);
            } else {
                props.setLstSelected([...props.lstSelected, sId]);
            }
    }

    const toggleSelectAll = (check) => {
        setIsAllSelected(check);
        if (check) {
            const allSelected = lstStudentFiltered.map((student) => student.studentId);
            props.setLstSelected(allSelected);
        } else {
            props.setLstSelected([]);
        }
    }

    const countSelected = (listStd) => {
        let selected = 0;
        let unselected = 0;
        if (listStd && listStd.length) {
            listStd.map((student) => {
                if (student.selected) {
                    selected++;
                } else {
                    unselected++;
                }
            })
        }
        props.setTotalSelected(selected);
        props.setTotalUnselected(unselected);
    }

    useImperativeHandle(ref, () => ({
        filterSelection: (sel, unSel) => {
            //   // // console.log("useImperativeHandle called");
            //   // // console.log("filterSelection Called");
            filterStudentList(studentFiltered, enrollmentNoFiltered, classFiltered, sectionFiltered, minDueFiltered, maxDueFiltered, sel, unSel)
        }
    }));


    const filterStudent = (fStudent) => {
        setStudentFiltered(fStudent);
        filterStudentList(fStudent, enrollmentNoFiltered, classFiltered, sectionFiltered, minDueFiltered, maxDueFiltered, props.selectedOnly, props.unselectedOnly)
    }
    const filterEnrollment = (fEnroll) => {
        setenrollmentNoFiltered(fEnroll);
        filterStudentList(studentFiltered, fEnroll, classFiltered, sectionFiltered, minDueFiltered, maxDueFiltered, props.selectedOnly, props.unselectedOnly)
    }
    const filterClass = (fClass) => {
        setClassFiltered(fClass);
        filterStudentList(studentFiltered, enrollmentNoFiltered, fClass, sectionFiltered, minDueFiltered, maxDueFiltered, props.selectedOnly, props.unselectedOnly)
    }
    const filterSection = (fSection) => {
        setSectionFiltered(fSection);
        filterStudentList(studentFiltered, enrollmentNoFiltered, classFiltered, fSection, minDueFiltered, maxDueFiltered, props.selectedOnly, props.unselectedOnly)
    }
    const filterMinDues = (fMinDues) => {
        setMinDueFiltered(fMinDues);
        filterStudentList(studentFiltered, enrollmentNoFiltered, classFiltered, sectionFiltered, fMinDues, maxDueFiltered, props.selectedOnly, props.unselectedOnly)
    }
    const filterMaxDues = (fMaxDues) => {
        setMaxDueFiltered(fMaxDues);
        filterStudentList(studentFiltered, enrollmentNoFiltered, classFiltered, sectionFiltered, minDueFiltered, fMaxDues, props.selectedOnly, props.unselectedOnly)
    }

    const filterStudentList = (fStudent, fEnroll, fClass, fSection, fMinDues, fMaxDues, fSelectedOnly, fUnselectedOnly) => {
        // // console.log("filterStudentList called");
        // // console.log("Student:", fStudent);
        // // console.log("Enrollment Status:", fEnroll);
        // // console.log("Class:", fClass);
        // // console.log("Section:", fSection);
        // // console.log("Minimum Dues:", fMinDues);
        // // console.log("Maximum Dues:", fMaxDues);
        // // console.log("Show Selected Only:", fSelectedOnly);
        // // console.log("Show Unselected Only:", fUnselectedOnly);

        let tempLstStudent = [];

        if (lstStudents && lstStudents.length) {
            tempLstStudent = lstStudents.filter((student) =>
                student.student.toLowerCase().includes(fStudent.toLowerCase()));

            tempLstStudent = tempLstStudent.filter((student) =>
                student.enrollmentNo.toLowerCase().includes(fEnroll.toLowerCase()));

            tempLstStudent = tempLstStudent.filter((student) =>
                student.cls.toLowerCase().includes(fClass.toLowerCase()));

            tempLstStudent = tempLstStudent.filter((student) =>
                student.section.toLowerCase().includes(fSection.toLowerCase()));

            if (fMinDues !== "")
                tempLstStudent = tempLstStudent.filter((student) =>
                    student.due >= parseInt(fMinDues));

            if (fMaxDues !== "")
                tempLstStudent = tempLstStudent.filter((student) =>
                    student.due <= parseInt(fMaxDues));

            if (fSelectedOnly) {
                tempLstStudent = tempLstStudent.filter((student) => student.selected === true);
            } else if (fUnselectedOnly) {
                tempLstStudent = tempLstStudent.filter((student) => student.selected === false);
            }
        }

        setLstStudentFiltered(tempLstStudent);
    }


    return (

        <div className="col-md-12" style={{ marginTop: "5px" }}>
            <table className="tableLayout1" >
                <thead>
                    <tr>
                        <th className="no-display" style={{ width: 50 }}>
                            Student Id
                        </th>
                        <th style={{ width: 100 }}>
                            <input type="text" className="form-control inp-search text-center"
                                value={studentFiltered}
                                onChange={(e) => filterStudent(e.target.value)}
                                name="Student"
                                placeholder="Student" />
                        </th>
                        <th style={{ width: 50 }}>
                            <input type="text" className="form-control inp-search text-center"
                                value={enrollmentNoFiltered}
                                onChange={(e) => filterEnrollment(e.target.value)}
                                name="Enrollment No"
                                placeholder="Enrollment No" />
                        </th>
                        <th style={{ width: 50 }}>
                            Gender
                        </th>
                        <th style={{ width: 50 }}>
                            <input type="text" className="form-control inp-search text-center"
                                value={classFiltered}
                                onChange={(e) => filterClass(e.target.value)}
                                name="Class"
                                placeholder="Class" />
                        </th>
                        <th style={{ width: 50 }}>
                            <input type="text" className="form-control inp-search text-center"
                                value={sectionFiltered}
                                onChange={(e) => filterSection(e.target.value)}
                                name="Section"
                                placeholder="Section" />
                        </th>
                        <th style={{ width: 50 }}>
                            Transport
                        </th>
                        <th style={{ width: 50 }}>
                            Route
                        </th>
                        <th style={{ width: 60 }}>
                            <span style={{ display: 'flex', justifyContent: 'space-evenly' }} >
                                <input type="text" className="form-control inp-search text-center"
                                    value={minDueFiltered}
                                    onChange={(e) => filterMinDues(e.target.value)}
                                    name="Minimum Due"
                                    placeholder="Min Due" />
                                <input type="text" className="form-control inp-search text-center"
                                    value={maxDueFiltered}
                                    onChange={(e) => filterMaxDues(e.target.value)}
                                    name="Maximum Due"
                                    placeholder="Max Due" />
                            </span>
                        </th>
                        <th style={{ width: 50 }}>
                            <input type='checkbox' onChange={(e) => toggleSelectAll(e.target.checked)} checked={isAllSelected}></input>
                        </th>
                    </tr>
                </thead>
            </table>
            <div className="tableDiv" style={{ height: "550px" }}>
                <table className='tableLayout'>
                    <tbody id="commGroupTable">
                        {
                            lstStudentFiltered && lstStudentFiltered ?
                                lstStudentFiltered.map((student) => (
                                    <tr key={student.studentId}
                                        onClick={(e) => setStudentId(student.studentId)}
                                        className={student.studentId === studentId ? "selected" : ""}
                                    >
                                        <td style={{ width: 50 }} className='no-display text-center'>{student.studentId}</td>
                                        <td style={{ width: 100 }} className='text-left'>{student.student}</td>
                                        <td style={{ width: 50 }} className='text-center'>{student.enrollmentNo}</td>
                                        <td style={{ width: 50 }} className='text-center'>{student.gender}</td>
                                        <td style={{ width: 50 }} className='text-center'>{student.cls}</td>
                                        <td style={{ width: 50 }} className='text-center'>{student.section}</td>
                                        <td style={{ width: 50 }} className='text-center'>{student.tpt ? "yes" : "-"}</td>
                                        <td style={{ width: 50 }} className='text-left'>{student.route}</td>
                                        <td style={{ width: 60 }} className='text-center'>{student.due}</td>
                                        <td style={{ width: 50 }} className='text-center'>
                                            <input type='checkbox' onChange={() => toggleStudentSelection(student.studentId)} checked={props.lstSelected.includes(student.studentId)}></input>
                                        </td>
                                    </tr>
                                ))
                                : null
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default forwardRef(CommunicationGroupsStudentTable);