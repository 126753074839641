import React, { Component } from 'react';




import '../css/error.css'



class Error extends Component {


    render() {
        return (
            <div className="error">
                <div className="contentWrapper"><h3>Oops !</h3>
                    <h4>This Page is Under Construction And will be available soon</h4>
                    <a href="/enquiry" className="homepage">Go To Homepage</a></div>
            </div>
        );
    }

}
export default Error;