import React, { useState, useEffect } from 'react'
import { getOneTimeHeadListSvc, saveOneTimeHeadSvc } from './oneTimeHeadSvc'

const OneTimeHeadTable = props => {
  const [loader, setLoader] = useState('none')

  const [oneTimeHeadId, setOneTimeHeadId] = useState(props.oneTimeHeadId)
  const [lstOneTimeHead, setLstOneTimeHead] = useState([])
  useEffect(() => {
    setLstOneTimeHead(props.lstOneTimeHead)
  }, [props.lstOneTimeHead, props.oneTimeHeadId])

  const colgrp = (
    <colgroup>
      <col width='40%'></col>
      <col width='20%'></col>
      <col width='20%'></col>
      <col width='20%'></col>
    </colgroup>
  )
  return (
    <div style={{ pointerEvents: loader === 'none' ? 'auto' : 'none' }}>
      <table className='tableLayout1'>
        {colgrp}
        <thead>
          <tr>
            <th>Fee Type</th>
            <th>Amount</th>
            <th>Updated On</th>
            <th>Action</th>
          </tr>
        </thead>
      </table>
      <div className='tablebodyWrapper'>
        <table className='tableLayout'>
          {colgrp}
          <tbody>
            {lstOneTimeHead.map((obj, idx) => {
              return (
                <tr
                  key={idx}
                  id={obj.headId}
                  onClick={e => props.setOneTimeHeadId(obj.headId)}
                  className={
                    obj.headId == props.oneTimeHeadId ? 'selected' : ''
                  }
                >
                  <td className='no-display'>{obj.headId}</td>
                  <td className='text-left'>{obj.head}</td>
                  <td className='text-right'>
                    {obj.amount.toLocaleString('en-IN')}
                  </td>
                  <td className='text-center'>{obj.updatedOn}</td>
                  <td className='text-center'>
                    <span
                      onClick={e =>
                        props.openOneTimeHeadSlider(obj.headId, obj)
                      }
                      className='glyphicon glyphicon-edit'
                    ></span>
                    <span
                      onClick={e => props.deleteOneTimeHead(obj.headId)}
                      className='glyphicon glyphicon-trash'
                    ></span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default OneTimeHeadTable
