import AdvanceTable from "./advanceTable"
import AdvanceSlider from "./advanceSlider"
import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios'
import Header from "common/js/header/header.js";
import lstModules from 'common/json/lstModules.json';
const httpURL = lstModules['payrollSetup'].url + ':' + lstModules['payrollSetup'].port;
//var httpURL = lstModules['payrollSetup'].url + ":" + lstModules['enquiry'].port;
var tokenid = localStorage.getItem('tokenid');
function Advance() {
    const [randNo, setRandNo] = useState(0)
    const [selObj, setSelObj] = useState({})
    const [selAdvId, setSelAdvId] = useState()
    const [add, setAdd] = useState(true)
    function getSelObj(param) {
        setSelObj(param)
        setSelAdvId(param.advanceId)
        setAdd(false)
    }
    function generateRandomNo() {
        var lRand = Math.floor(Math.random() * 999999);
        setRandNo(lRand)
    }
    function addNew() {
        setSelObj({})
        setSelAdvId(null)
        setAdd(true)
    }
    return (<div>
        <Header></Header>
        <div className="col-sm-11 col-centered" style={{ paddingTop: '2%' }}>
            <div className="col-sm-8">
                <AdvanceTable
                    key={randNo}
                    getSelObj={getSelObj}
                />
                <div className="col-xs-1" onClick={(e) => addNew()}><span id="addReadyId" title="Add" className="addIcon glyphicon glyphicon-plus-sign"></span>
                </div>
            </div>
            <div className="col-sm-4">
                <AdvanceSlider
                    key={selAdvId}
                    advObj={selObj}
                    generateRandomNo={generateRandomNo}
                    add={add}
                />
            </div>
        </div>
    </div >)
}
export default Advance