
import React, { useEffect, useState } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { getICardSvc, getLogoSvc, getPrinciSignSvc } from './IdCardSliderSvc.js'
import { toast } from "react-toastify";
import IDCardContent from './idCardContent.js';


const PrintPage = (props) => {

    const { pdfKey, lstIdCard, selGroup, selTpt, baseColor, reissueFee, selPageType, groupPrintId, lstStudentId, logoUrl, imageUrl, totalRows, totalCols, totalCopies, pageOrientaion } = props;

    useEffect(() => {
        // // console.log("useeffect in printPage");

        // // console.log("baseColor:", baseColor);
        // // console.log("selPageType:", selPageType);
        // // console.log("lstSudentId:", lstStudentId);
        // // console.log("logoUrl:", logoUrl);
        // // console.log("imageUrl:", imageUrl);
        // // console.log("lstIdCard:", lstIdCard);
        // // console.log("selGroup:", selGroup);
        // // console.log("selTpt:", selTpt);
        // // console.log("reissueFee:", reissueFee);
        // // console.log("totalRows:", totalRows);
        // // console.log("totalCols:", totalCols);
        // // console.log("totalCopies:", totalCopies);
        // // console.log("groupPrintId:", groupPrintId);
        //     props.updateKey();

    }, [pdfKey]);



    return (
        <>
            <div className='col-sm-12 padding-remove' >
                {lstIdCard && lstStudentId && selPageType && groupPrintId && totalCopies &&
                    <PDFViewer style={{ width: "100%", height: "600px", overflowY: "scroll" }}>
                        <IDCardContent
                            baseColor={baseColor}
                            selPageType={selPageType}
                            lstSudentId={lstStudentId}
                            logoUrl={logoUrl}
                            imageUrl={imageUrl}
                            lstIdCard={lstIdCard}
                            selGroup={selGroup}
                            selTpt={selTpt}
                            reissueFee={reissueFee}
                            totalRows={totalRows}
                            totalCols={totalCols}
                            totalCopies={totalCopies}
                            groupPrintId={groupPrintId}
                            pageOrientaion={pageOrientaion}
                        />
                    </PDFViewer>
                }
            </div>
        </>
    );
}

export default PrintPage;