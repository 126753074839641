import axios from 'axios';
import lstModules from 'common/json/lstModules.json';
import { toast } from 'react-toastify';

const httpURL = lstModules['students'].url + ':' + lstModules['students'].port;
var tokenid = localStorage.getItem("tokenid");
const httpURLCommon = lstModules['main'].url + ':' + lstModules['main'].port;
const httpURLStudent = lstModules['students'].url + ':' + lstModules['students'].port
const academicYearId= localStorage.getItem('academicYearId') || 0;



function getICardSvc(pLstStudentId, callback) {
    var formData = new FormData()
    formData.append("lstStudentId", JSON.stringify(pLstStudentId));
    formData.append("acdYrId", academicYearId);
    var obj = { status: "Success", lstIdCard: [] }
    axios({
        url: httpURL + '/sms/student/idcard',
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // // console.log('data.SvcStatus=' + response.data.SvcStatus);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;

        if (response.data.SvcStatus === "Success") {
            obj.lstIdCard = response.data.lstIdCard;
        } else {
            obj.lstIdCard = []
        }
        callback(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        obj.lstIdCard = [];
        callback(obj);
    });
}
function getLogoSvc(callback) {

    var xhr = new XMLHttpRequest();
    xhr.open("GET", httpURLCommon + '/sms/school/profile/logo/view', true);
    xhr.setRequestHeader('tokenid', tokenid);

    xhr.responseType = "arraybuffer";

    xhr.onload = function (e) {
        var arrayBufferView = new Uint8Array(this.response);
        var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(blob);
        // // console.log("imageUrl in getLogoSvc", imageUrl);
        callback(imageUrl);
    };
    xhr.send();
}

function getPrinciSignSvc(cb) {

    var xhr = new XMLHttpRequest();
    xhr.open("GET", httpURLCommon + '/sms/mst/sign/view?roleId=24', true);
    xhr.setRequestHeader('tokenid', tokenid);

    xhr.responseType = "arraybuffer";

    xhr.onload = function (e) {
        var arrayBufferView = new Uint8Array(this.response);
        var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(blob);
        // // console.log("imageUrl in getPrinciSignSvc", imageUrl);
        cb(imageUrl);
    };
    xhr.send();

}

const getStudentImageUrlSvc = (stdId, cb) => {
    // // console.log("getImageUrl called, stdId", stdId);
    axios({
        url: httpURLStudent + '/sms/student/pic',
        method: 'GET',
        params: { studentId: stdId },
        headers: { tokenid: tokenid},
        withCredentials: true,
        responseType: 'arraybuffer'
    })
        .then(response => {
            var file = new Blob([response.data], { type: 'mime' })
            var fileURL = URL.createObjectURL(file)
            // // console.log("imageUrl", fileURL);
            cb(fileURL);
        })
        .catch(e => {
            toast.error('Service failed: ' + e)
        })
}

const getDocDtlsSvc = (stdId, cbDocDtlsResponse) => {
    //var studentId = localStorage.getItem("studentId");
    var obj = {stdId:stdId};
    axios({
      url: httpURLStudent + "/sms/student/docs/dtls",
      method: "POST",
      params: { studentId: stdId },
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        obj.data = Response.data;
        // // console.log("list fetching success");
        cbDocDtlsResponse(obj);
      })
      .catch((error) => {
        console.error(error);
      });
  };

export { getICardSvc, getLogoSvc, getPrinciSignSvc, getStudentImageUrlSvc, getDocDtlsSvc }

