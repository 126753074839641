import { useEffect, useState } from "react";
import "../css/login.css";
import "common/css/master.css";
import { toast } from "react-toastify";

import Forgotpassword from "./forgotPassword";

const LoginForm = (props) => {
    const { email, setEmail, password, setPassword, login, isRememberChecked, setIsRememberChecked, setLoader, setOpacity } = props;

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisiblity = () => {
        setIsPasswordVisible((prevIsPasswordVisible) => !prevIsPasswordVisible);
    }

    return (
        <>
            <form id="loginForm">
                <div
                    className="form-group input-group"
                    style={{ marginBottom: 40, width: "100%" }}>
                    <span className="has-float-label">
                        <input
                            type="email"
                            autoComplete="off"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Username"
                            id="usr"
                            required
                        />
                        <label >Username</label>
                    </span>
                </div>
                <div
                    className="form-group input-group"
                    style={{ marginTop: 40, marginBottom: 40, width: "100%" }}>
                    <span className="has-float-label">
                        <input
                            type={isPasswordVisible ? "text" : "password"}
                            className="form-control"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            onKeyDown={(event) => {
                                if (event.key === "Enter")
                                    login()
                            }}
                            id="pwd"
                            required
                        />
                        <label >Password</label>
                    </span>
                    <span
                        onClick={togglePasswordVisiblity}
                        className={isPasswordVisible ? "fa fa-fw fa-eye-slash field_icon" : "fa fa-fw fa-eye field_icon"}
                    ></span>
                </div>
                <div
                    className="form-group input-group"
                    style={{ marginTop: 40, marginBottom: 40, width: "100%" }}
                >
                    <input type="checkbox" value={isRememberChecked} onChange={(e) => setIsRememberChecked(e.target.checked)} />
                    <label style={{ marginLeft: 10 }}>
                        Remember me
                    </label>
                </div>
                <div className="text-center loginButtons">
                    <button
                        type="button"
                        title="Login"
                        className="btn btn-info text-center"
                        onClick={() => login()}
                        id="loginBtn"
                    >
                        Login{" "}
                        <span
                            className="glyphicon glyphicon-arrow-right arrowShow"
                            aria-hidden="true"
                        ></span>
                    </button>
                </div>
                <Forgotpassword
                    email={email}
                    setLoader={setLoader}
                    setOpacity={setOpacity}
                />
            </form>
        </>
    );
}

export default LoginForm;