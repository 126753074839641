import React, { Component, useEffect, useState } from "react";
import "../css/items.css";
import "common/css/master.css";
import Header from "common/js/header/header.js";
import { toast, ToastContainer } from "react-toastify";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { getItemListSvc } from "./itemsSvc.js";


const Items = (props) => {
    const [lstItems, setLstItems] = useState([]);
    const [lstSubItems, setLstSubItems] = useState([]);
    const [lstChildItems, setLstChildItems] = useState([]);
    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [flg, setFlg] = useState(true);
    const [flg1, setFlg1] = useState(true);
    const [expiryDate, setExpiryDate] = useState(null);
    const [openingBalanceDate, setOpeningBalanceDate] = useState(null);
    // const [tokenid, setTokenid] = useState(localStorage.getItem("tokenid"));

    useEffect (() => {
        getItemList();
    })

    const getItemList = () => {
        setLoader("block");
        setOpacity(0.5);
        getItemListSvc(itemListResponse)
    }

    const itemListResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            setLstItems = obj.lstItems;
            setItemTable();
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } 
        setLoader("none");
        setOpacity(1) ;
    }

    const setItemTable = () => {
    }

    return (
        <div style={{ height: '100%' }}>
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
        </div>
    );
};

export default Items;
