import React, { useState, useEffect, useMemo } from "react";
import "../css/EnquiryCtrl.css";
import { getTestListSvc } from "./ScheduleTestSvc";

var lstTestSlotIdTemp = [];
var lstTempArr = [];
const TestTable = (props) => {
  const [lstTest, setLstTest] = useState([]);
  const [lstTestSlotId, setLstTestSlotId] = useState([]);
  const [lstSelTestId, setLstSelTestId] = useState([]);
  const [selTestId, setSelTestId] = useState(0);

  function cbTestListResponse(pLstTest) {
    setLstTest(pLstTest);
    props.setLstTest(pLstTest);
    // // console.log("checking chkboxes based on testId:" + props.lstTestId);
    var lstTestId = props.lstTestId;

    for (var i = 0; i < lstTestId.length; i++) {
      var list_cbox = document.querySelectorAll(
        "#testTBody td input[type=checkbox]"
      );
      list_cbox.forEach((cbox) => {
        if (lstTestId.includes(parseInt(cbox.id))) {
          cbox.click();
        }
      });
    }
    // // console.log("Fetching Test List");
  }
  useEffect(() => {
    if (props.regsId && props.clsId) {
      getTestListSvc(cbTestListResponse, props.regsId, props.clsId);
      lstTestSlotIdTemp = [];
      lstTempArr = [];
    }
  }, [props.regsId]);

  function selectLstTest(testId, pTestSlotId, status) {
    // // console.log( "Clicked on checkbox::" + testId + "-" + pTestSlotId + "-" + status );

    if (status && pTestSlotId != 0 && !lstTestSlotId.includes(pTestSlotId)) {
      // lstTestSlotIdTemp = lstTestSlotId;
      lstTestSlotIdTemp.push(pTestSlotId);
      setLstTestSlotId(lstTestSlotIdTemp);
      // // console.log("testSlotId array  " + lstTestSlotIdTemp);
    } else {
      // lstTestSlotIdTemp = lstTestSlotId;
      lstTestSlotIdTemp.splice(lstTestSlotIdTemp.indexOf(pTestSlotId), 1);
      setLstTestSlotId(lstTestSlotIdTemp);
      // // console.log("Selected Test Slot Id " + lstTestSlotIdTemp);
    }
    if (status && !lstSelTestId.includes(testId)) {
      lstTempArr.push(testId);
      setLstSelTestId(lstTempArr);
      // // console.log("checked  " + lstTempArr);
    } else {
      lstTempArr.splice(lstTempArr.indexOf(testId), 1);
      setLstSelTestId(lstTempArr);
      // // console.log("now checked" + lstTempArr);
    }
    var p2 = new Promise(function (resolve, reject) {
      resolve(returnSelTestId(lstTempArr));
    });

    p2.then(function () {
      returnSelTestSlotId(lstTestSlotIdTemp);
    });
  }
  const returnSelTestId = async (pLstSelTestId) => {
    // // console.log("setSelectedTestId triggered in TestTable::" + pLstSelTestId);
    await props.setSelectedTestId(pLstSelTestId);
  };

  const returnSelTestSlotId = async (pLstTestSlotId) => {
    // // console.log( "setSelectedTestSlotId triggered in TestTable::" + pLstTestSlotId );
    await props.setSelectedTestSlotId(pLstTestSlotId);
  };
  function handleSelTestId(param) {
    setSelTestId(param);
  }
  const colgrp = (
    <colgroup>
      <col width="8%"></col>
      <col width="5%"></col>
      <col width="5%"></col>
      <col width="5%"></col>
      <col width="5%"></col>
      <col width="8%"></col>
      <col width="5%"></col>
      <col width="8%"></col>
      <col width="8%"></col>
      <col width="5%"></col>
      <col width="8%"></col>
      <col width="5%"></col>
    </colgroup>
  );
  return (
    <div>
      <table className="tableLayout1">
        <thead>
          <tr>
            <th>Test List</th>
          </tr>
        </thead>
      </table>
      <table className="tableLayout1">
        {colgrp}
        <thead>
          <tr>
            <th>Test</th>
            <th>
              Passing
              <br /> Score
            </th>
            <th>
              Max
              <br /> Score
            </th>
            <th>Duration (Mins)</th>
            <th>Mandatory</th>
            <th>
              Test
              <br />
              Date
            </th>
            <th>
              Slot
              <br />
              Id
            </th>
            <th>
              Candidate
              <br />
              Informed On
            </th>
            <th>
              Evaluator
              <br />
              Informed On
            </th>
            <th>Result</th>
            <th>Status</th>
            <th>Select</th>
          </tr>
        </thead>
      </table>
      <div className="tablebodyWrapper">
        <table className="tableLayout" id="testTBody">
          {colgrp}
          <tbody>
            {lstTest.map((test, idx) => {
              return (
                <tr
                  test-id={test.testId}
                  slot-id={test.slotId}
                  onClick={(e) => handleSelTestId(test.testId)}
                  className={test.testId === selTestId ? "selected" : ""}
                  key={"t_" + idx}
                >
                  <td>{test.test}</td>
                  <td>{test.passScore}</td>
                  <td>{test.maxScore}</td>
                  <td>{test.duration}</td>
                  <td>{test.mandatory ? "Yes" : "-"}</td>
                  <td>{test.slotDt}</td>
                  <td>{test.slotId > 0 ? test.slotId : "-"}</td>
                  <td>{test.candInformDt}</td>
                  <td>{test.evalInformDt}</td>
                  <td>{test.result}</td>
                  <td>{test.status}</td>
                  <td>
                    <input
                      id={test.testId}
                      type="checkbox"
                      onClick={(e) =>
                        selectLstTest(
                          test.testId,
                          test.testSlotId,
                          e.target.checked
                        )
                      }
                      /* onClick={(e) =>
                      selectLstTestSlotId(test.testSlotId, e.target.checked)
                    } */
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default TestTable;
