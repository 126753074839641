import React, { useEffect, useState } from 'react'
import 'sms/tt/teacher/css/tabList.css'
import 'common/css/master.css'

const SectionList = props => {
  const [lstSection, setLstSection] = useState(props.lstSection)
  useEffect(() => {
    setLstSection(props.lstSection)
  }, [props])

  return (
    <div className='tab'>
      {lstSection.map(obj => {
        var isSelected = obj.sectionId === props.selSectionId
        return (
          <button
            className={isSelected ? 'sellected' : ''}
            onClick={() => props.sectionClick(obj.sectionId)}
            key={obj.sectionId}
          >
            <span hidden>{obj.sectionId}</span>
            <span>{obj.section}</span>
          </button>
        )
      })}
    </div>
  )
}
export default SectionList
