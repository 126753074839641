import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css';
import Header from 'common/js/header/header.js';
import HeaderSession from 'common/components/headerSession';
import { getLstSession, getLstTTSummary, deleteRow, publish } from './classWiseServices';
import ClassWiseSummaryTbl from './classWiseSummaryTbl';

const ClassWiseCtrl = () => {
    var initialState = {
        loader: 'none',
        opacity: 1,
    };
    const [selSessionId, setSelSessionId] = useState(0)
    const [state, setState] = useState(initialState);
    const [lstSession, setLstSession] = useState([]);
    const [lstTTSummary, setLstTTSummary] = useState([

    ]);

    useEffect(() => {
        setSessions();

    }, []);


    function setSessions() {
        getLstSession(handleLstSessionResponse);
    }
    function handleLstSessionResponse(data) {
        // // console.log(data)
        var obj = data;
        if (obj.status === 'Success') {
            // // console.log(obj.lstSession)
            setLstSession(obj.lstSession);
        } else if (obj.status === 'Failure') {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {
            console.error("Service failed " + obj.message);
        }
    }


    function setTTSummary(sessionId) {
        getLstTTSummary(sessionId, handleLstTTSummaryResponse);
    }

    function handleLstTTSummaryResponse(data) {
        var obj = data;
        if (obj.status === 'Success') {
            setLstTTSummary(obj.lstTTSummary);
        } else if (obj.status === 'Failure') {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error("Service failed " + obj.message);
        }
    }

    const getWingId = (wingId) => {
        // // console.log("wingId" + wingId);
    }
    const clickSessionTab = (e, sessionId) => {
        for (let sibling of e.currentTarget.parentNode.children) {
            if (sibling !== e.target) sibling.classList.remove('active');
        }
        e.currentTarget.classList.add('active');
        setSelSessionId(sessionId);
        setTTSummary(sessionId);
    }

    function remove(clsId, sectionId) {
        deleteRow(clsId, sectionId, selSessionId)
    }

    function send(clsId, sectionId) {
        publish(clsId, sectionId, selSessionId)
    }



    function edit(clsId, sectionId, sessionId, data) {
        // // console.log("Edit method called onclick edit icon, parameters passed to edit clsId", clsId, ", sectionId", sectionId, "sessionId", sessionId, ", data", data);
        var obj = data;
        if (obj.subAllocation) {
            // localStorage.setItem("clsId", clsId);
            // localStorage.setItem("sectionId", sectionId);
            // localStorage.setItem("sessionId", sessionId);
            // window.open('/tttch');
            const teacherTTURL = "/tttch?clsId=" + clsId + "&sectionId=" + sectionId + "&sessionId=" + sessionId;
            window.open(teacherTTURL);
            
        } else {
            // localStorage.setItem("clsId", clsId);
            // localStorage.setItem("sectionId", sectionId);
            // localStorage.setItem("sessionId", sessionId);
            // window.open('/ttsub')
            const subjectTTURL = "/ttsub?clsId=" + clsId + "&sectionId=" + sectionId + "&sessionId=" + sessionId;
            window.open(subjectTTURL);
        }
    }


    return (
        <div style={{ height: '100%' }}>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: state.loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="mainContainer" style={{ marginTop: 15, opacity: state.opacity }}>
                <div className={lstSession.length > 4 ? "col-sm-11 col-centered padding-remove" : "col-sm-6 col-centered padding-remove"}>
                    {/* style={{width:"145px",marginLeft:"315px"}} */}
                    {lstSession.length !== 0 && <HeaderSession lstSession={lstSession} clickSessionTab={clickSessionTab} />}
                    {/* <h1>HeaderSession to be placed here</h1> */}
                </div>
                <div className="col-sm-7 col-centered padding-remove">
                    {/* <ClassWiseSummaryTbl lstTTSummary={lstTTSummary} /> */}
                    <ClassWiseSummaryTbl lstTTSummary={lstTTSummary} remove={remove} edit={edit} send={send} selSessionId={selSessionId} />

                </div>
            </div>
        </div >

    )
}

export default ClassWiseCtrl