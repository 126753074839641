import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import Header from 'common/js/header/header';
import Violation from './violation';

import { getViolationSvc } from './CctvViolationSvc';

const CctvViolation = () => {
    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [lstViolation, setLstViolation] = useState([])

    useEffect(() => {
        getViolationSvc(cbViolationSvc)

    }, [])

    function cbViolationSvc(data) {
        var obj = data;
        if (obj.SvcStatus == 'Success') {
            // // console.log(obj.lstViolation)
            setLstViolation(obj.lstViolation);
        } else if (obj.SvcStatus == 'Failure') {
            if (obj.SvcMsg === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.SvcMsg === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.SvcMsg);
            }
        } else {
            toast.error("Service failed " + obj.SvcMsg);
        }
    }

    return (
        <div >
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="col-lg-11 col-md-12 col-sm-12 col-xs-12 col-centered padding-remove "
                style={{
                    marginTop: 15,
                    cursor: (loader === "none") ? 'pointer' : 'none',
                    pointerEvents: (loader === "none") ? 'auto' : 'none',
                    opacity: opacity
                }}>
                <div style={{ overflowY: "scroll", height: 500 }} className="col-sm-8 padding-remove col-centered">
                    {lstViolation.map((violationObj, i) => {
                        return <div className="col-sm-12 col-centered">
                            <Violation obj={violationObj} key={i + violationObj.studentId} />
                        </div>
                    })}
                </div>

            </div>
        </div>
    )
}

export default CctvViolation