import React, { Component } from "react";
import lstModules from "common/json/lstModules.json";

import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import Header from "common/js/header/header.js";
// import './../bootstrap/css/bootstrap.min.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import LoaderImage from "common/components/LoaderImage.jsx";
//import moment from 'moment';
//import {baseUrl} from './const';
import StudentDocs from "./studentDocs";
import StudentPersonalDtlsForm from "./studentPersonalDtlsForm";

var httpURL = "",
  httpURLStudent = "";
var queryString = require("querystring");
var schoolId = localStorage.getItem("schoolId");
var lstClass = [];
var lstSection = [];
var screenId = 15;
var lstSiblings = [];
var lstCity = [];
var lstLocality = [];
var lstState = [];
var tokenid = localStorage.getItem("tokenid");
var lstEmailStatus = [
  { emailStatusId: 10, emailStatus: "N/A" },
  { emailStatusId: 20, emailStatus: "Pending" },
  { emailStatusId: 30, emailStatus: "Verified" },
];
var lstGender = [
  { genderId: "M", gender: "Male" },
  { genderId: "F", gender: "Female" },
];
var COL_STUDENTID = "td:eq(0)";
var MAX_STUDENT_LENGTH = 30;
var PHOTO = 50;
var classId = 0;
var sectionId = 0;
var clsSectionStudentId = 0;

class StudentDtls extends Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    this.state = {
      studentId: params.get("studentId"),
      academicYearId: params.get("academicYrId"),
      schoolId: schoolId,
      lstClass: [],
      lstCity: [],
      lstState: [],
      lstLocality: [],
      studentObj: {},
      studentDtls: {},
      parents: {},
      lstSiblings: [],
      flg: params.get("flg"),
      width: "97%",
      loader: "none",
      opacity: 1,
      tokenid: tokenid,
      scholar: false,
      sibling: false,
      meritMeans: false,
      scSt: false,
      backward: false,
      comm: 0,
      commPhone: 0,
      photoPath: "",
      lstDocDtls: [],
      lstSection: [],
      classId: 0,
      secId: 0,
      clsSectionStudentId: 0,
    };
  }
  /* async componentWillMount() {
    await this.setState({
      studentId: parseInt(localStorage.getItem("studentId")),
      flg: localStorage.getItem("flg"),
    });
  } */
  onChangeRadio = (e) => {
    this.setState({ comm: e.target.value });
  };
  onChangePhone = (e) => {
    this.setState({ commPhone: e.target.value });
  };
  componentDidMount = () => {
    // // console.log("componentDidMount called");
    // // console.log("window.location.search", window.location.search);
    this.handleResize();
    window.addEventListener("load", this.handleResize);
    window.addEventListener("resize", this.handleResize);
    this.getMasterData();
    this.fillEmailStatus();
  };




  getFeeEmail = (studentId) => {
    var schoolStudent = {
      schoolId: schoolId,
      studentId: parseInt(studentId),
    };
    axios({
      url: httpURLStudent + "/sms/student/commId",
      method: "POST",
      data: queryString.stringify({
        schoolId: schoolId,
        studentId: parseInt(studentId),
        screenId,
      }),
      headers: { tokenid: this.state.tokenid },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          this.setState({
            comm: response.data.commDtls.personId,
          });
        } else {
          toast.error(response.data.SvcMsg);
          if (response.data.SvcMsg === "You are not Logged In") {
            setTimeout(function () {
              window.location.href = "/";
            }, 2000);
            return false;
          } else if (response.data.SvcMsg === "Server Timed Out, Login Again") {
            setTimeout(function () {
              window.location.href = "/";
            }, 2000);
            return false;
          }
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((error) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("21. Service failed " + error);
      });
  };

  getStudentsData = (studentId) => {
    // // console.log("132 getStudentsData() called... ");
    var schoolStudent = {
      schoolId: schoolId,
      studentId: parseInt(studentId),
    };
    axios({
      url: httpURLStudent + "/sms/student/dtls",
      method: "POST",
      data: queryString.stringify({
        schoolStudent: JSON.stringify(schoolStudent),
        screenId,
        acdYrId: this.state.academicYearId,
      }),
      headers: { tokenid: this.state.tokenid },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          this.setState({
            studentDtls: response.data.studentDtls,
            classId: response.data.studentDtls.clsId,
            clsSectionStudentId: response.data.studentDtls.clsSectionStudentId,
            secId: response.data.studentDtls.sectionId,
          });
          classId = response.data.studentDtls.personal.clsId;
          sectionId = response.data.studentDtls.personal.sectionId;
          clsSectionStudentId = response.data.studentDtls.personal.clsSectionStudentId;
          // // console.log("getStudentsData response successful, response", response);
          // // console.log("getStudentsData response successful, classId", response.data.studentDtls.personal.clsId);
          // // console.log("getStudentsData response successful, sectionId", response.data.studentDtls.personal.sectionId);
          // // console.log("getStudentsData response successful, clsSectionStudentId", response.data.studentDtls.personal.clsSectionStudentId);
          // // console.log("after setting data in var classId", classId);
          // // console.log("after setting data in var sectionId", sectionId);
          // // console.log("after setting data in var clsSectionStudentId", clsSectionStudentId);
          this.getMstSectionList(classId);

          var studentDtls = response.data.studentDtls;
          // // console.log(studentDtls);
          this.setStudentDtls(studentDtls);
        } else {
          toast.error(response.data.SvcMsg);
          if (response.data.SvcMsg === "You are not Logged In") {
            setTimeout(function () {
              window.location.href = "/";
            }, 2000);
            return false;
          } else if (response.data.SvcMsg === "Server Timed Out, Login Again") {
            setTimeout(function () {
              window.location.href = "/";
            }, 2000);
            return false;
          }
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((error) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("22. Service failed " + error);
      });
  };
  getParentsData = (studentId) => {
    var schoolStudent = {
      schoolId: schoolId,
      studentId: parseInt(studentId),
    };
    // // console.log(schoolStudent);
    axios({
      url: httpURLStudent + "/sms/student/parents",
      method: "POST",
      data: queryString.stringify({
        schoolStudent: JSON.stringify(schoolStudent),
        screenId,
      }),
      headers: { tokenid: this.state.tokenid },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          this.setState({
            parents: response.data.parents,
          });
          var parents = response.data.parents;
          // // console.log(parents);
          this.setParentsDtls(parents);
        } else {
          toast.error(response.data.SvcMsg);
          if (response.data.SvcMsg === "You are not Logged In") {
            setTimeout(function () {
              window.location.href = "/";
            }, 2000);
            return false;
          } else if (response.data.SvcMsg === "Server Timed Out, Login Again") {
            setTimeout(function () {
              window.location.href = "/";
            }, 2000);
            return false;
          }
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((error) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("23. Service failed " + error);
      });
  };

  async setSiblingsTable(lstSiblings) {
    $("#siblingTableBody tr").remove();
    // var table 				= document.getElementById('siblingTable');
    var tbody = document.getElementById("siblingTableBody");

    for (var i = 0; i < lstSiblings.length; i++) {
      var obj = lstSiblings[i];
      var siblingId = obj.siblingId;

      // // console.log("siblingId:"+siblingId);
      var sibling = obj.sibling;
      var age = obj.age;
      var gender = obj.gender;
      var clsId = obj.clsId;
      var cls = obj.cls;
      var school = obj.school;
      var row = document.createElement("tr");
      row.id = siblingId;
      row.onclick = (e) => {
        $(e.target)
          .closest("tr")
          .addClass("selected")
          .siblings()
          .removeClass("selected");
      };
      this.addTD(row, siblingId, "no-display");
      this.addTD(row, sibling, "");
      this.addTD(row, age, "");
      this.addTD(row, gender, "");
      this.addTD(row, clsId, "no-display");
      this.addTD(row, cls, "");
      this.addTD(row, school, "");
      this.addSiblingTDActions(row, "text-center");
      tbody.append(row);
    }
    // table.append(tbody);
  }
  addSiblingTDActions(row, classname) {
    var cell = document.createElement("td");
    cell.className = classname;
    var img1 = document.createElement("img");
    img1.src = require("common/images/edit.png");
    img1.width = 20;
    img1.addEventListener("click", (e) => this.setSiblingPopup(e));
    var img2 = document.createElement("img");
    img2.src = require("common/images/bin.png");
    img2.width = 20;
    img2.addEventListener("click", (e) => this.deleteSibling(e));
    cell.append(img1);
    cell.append(img2);
    row.append(cell);
  }

  deleteSibling = (event) => {
    var schoolSibling = {
      siblingId: $(event.target).closest("tr").find("td:eq(0)").text(),
      schoolId: schoolId,
    };

    // $(event.target).closest('tr').click();
    // $(event.target).closest('tr').remove();
    axios
      .post(
        httpURLStudent + "/sms/student/remove_sibling",
        queryString.stringify({
          schoolSibling: JSON.stringify(schoolSibling),
          screenId,
        }),
        {
          headers: { tokenid: this.state.tokenid },
          withCredentials: true,
        }
      )
      .then(response => {
        // // console.log(JSON.stringify(response));
        // // console.log(response.data.SvcStatus);
        if (response.data.SvcStatus === "Success") {
          toast.success(response.data.SvcMsg);
          this.getSiblingsList(this.state.studentId);
        } else {
          toast.error(response.data.SvcMsg);
          if (response.data.SvcMsg === "You are not logged in") {
            return false;
          }
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((error) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("24. Service failed " + error);
      });
  };

  setStudentDtls = (studentDtls) => {
    // // console.log(studentDtls.personal.dob);
    // var dd = studentDtls.personal.dob.split('-')[0];
    // var mm = studentDtls.personal.dob.split('-')[1];
    // var yr = parseInt(studentDtls.personal.dob.split('-')[2]);
    var admissionDt = studentDtls.personal.admissionDt;
    var d = admissionDt ? admissionDt.split("-")[0] : "";
    var m = admissionDt ? admissionDt.split("-")[1] : "";
    var y = admissionDt ? admissionDt.split("-")[2] : "";
    // // console.log("admissionDt", admissionDt, ", d", d, ", m", m, ", y", y);
    // // console.log("new Date(y, m-1, d)", new Date(y, m - 1, d));
    var regsDt = studentDtls.personal.regsDt;

    var D = regsDt ? regsDt.split("-")[0] : "";
    var M = regsDt ? regsDt.split("-")[1] : "";
    var Y = regsDt ? regsDt.split("-")[2] : "";
    // // console.log("regsDt", regsDt, ", D", D, ", M", M, ", Y", Y);

    var aDt = new Date(y, m - 1, d);
    aDt.setMinutes(aDt.getMinutes() + aDt.getTimezoneOffset())
    var rDt = new Date(Y, M - 1, D);
    rDt.setMinutes(rDt.getMinutes() + rDt.getTimezoneOffset())
    this.setState({
      doa: aDt,
      dor: rDt,
    });
    // this.setState({
    //   // dob: new Date(yr, mm, dd),
    //   doa: new Date(y, m - 1, d),
    //   dor: new Date(Y, M - 1, D),
    // });
    // // console.log(studentDtls.personal.admissionDt);
    // this.setState({
    //     dob:studentDtls.personal.dob,
    //     doa:studentDtls.personal.admissionDt,
    // });
    this.setPersonalDtls(studentDtls.personal);
    this.setStudentHistory(studentDtls.history);
    this.setMedicalHistory(studentDtls.medical);
    this.setCategoryDtls(studentDtls.category);
    this.getDocDtlsSvc();
  };

  setCategoryDtls = (category) => {
    this.setState({
      scholar: category.scholar,
      sibling: category.sibling,
      meritMeans: category.meritMeans,
      scSt: category.scst,
      backward: category.backward,
    });
  };
  setFeeEmail = (feeEmail) => { };
  setParentsDtls = (parents) => {
    this.setFatherDtls(parents.father);
    this.setMotherDtls(parents.mother);
    this.setGuardianDtls(parents.guardian);
  };
  async setPersonalDtls(personal) {
    // this.setState({
    //     dob:new Date(personal.dob),
    //     doa:new Date(personal.admissionDt),
    // });
    $("#inp_state").val(personal.stateId);
    await this.getCityList(personal.cityId, personal.localityId);

    $("#inp_student").val(personal.student);
    // // console.log("personal.gender" + personal.gender);
    $("#inp_gender").val(personal.gender);
    $("#inp_blocked").attr("checked", personal.blocked);
    $("#inp_address1").val(personal.address1);
    $("#inp_address2").val(personal.address2);
    $("#inp_pin").val(personal.pin);
    $("#lbl_enrollment_no").val(personal.enrollmentNo);
    $("#inp_sEmail").val(personal.email);
    $("#inp_sEmailStatus").val(personal.emailStatus);

    if (personal.emailStatus === 30) {
      $("#emailStatusIconS").addClass("fa fa-check-circle success");
      $("#emailStatusIconS").css({ color: "green" });
      $("#emailStatusIconS").title = "Email Verified";
      $("#sReverify").removeClass("disabled");
    } else {
      $("#emailStatusIconS").addClass("fa fa-exclamation-circle  warning");
      $("#emailStatusIconS").css({ color: "orange" });
      $("#emailStatusIconS").title = "Email Pending";
      $("#sReverify").addClass("disabled");
    }

    if (personal.dob === "00-00-0000" || personal.dob === null) {
      this.setState({
        dob: "",
      });
    } else {
      var date = personal.dob;
      date = date ? date.split("-") : "";
      var setDate = date.reverse().join();
      setDate = setDate.replace(/,/g, "-");
      var dob = new Date(setDate);
      dob.setMinutes(dob.getMinutes() + dob.getTimezoneOffset());
      this.setState({ dob: dob })
    }
    this.calcAge(personal.dob)
  }
  calcAge = dob => {
    // // console.log(dob);
    if (typeof dob == 'object') dob = this.formatDate(dob)
    var dd = dob.split('-')[0]
    var mm = dob.split('-')[1]
    var yr = parseInt(dob.split('-')[2])
    dob = new Date(yr, mm, dd, 0, 0)
    var d2 = new Date()
    var diff = d2.getTime() - dob.getTime()
    var age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25))

    // var age = parseInt(new Date().getFullYear())-dob.getTime();
    $('#lbl_age').text('Age:' + age)
  }

  formatDate = date => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [day, month, year].join('-')
  }
  async setStudentHistory(history) {
    $('#inp_prestate').val(!history.stateId ? 'State' : history.stateId)
    await this.getPrevCityList()
    $('#inp_preschool').val(history.school)
    $('#inp_preclass').val(!history.clsId ? 'Class' : history.clsId)

    $('#inp_precity').val(!history.cityId ? 'City' : history.cityId)
    $('#inp_result').val(history.result)
    $('#inp_board').val(history.board)
  }
  setMedicalHistory(medical) {
    $('#inp_height').val(medical.height)
    $('#inp_weight').val(medical.weight)
    $('#inp_bloodgroup').val(medical.bloodGroup)
    $('#inp_disease').val(medical.diseases)
    $('#inp_eyes').val(medical.eyes)
    $('#inp_ears').val(medical.ears)
    $('#inp_allergicto').val(medical.allergicTo)
  }
  async setFatherDtls(father) {
    // // console.log("setFatherDtls called, father", father);
    $('#inp_fstate').val(!father.stateId ? 'State' : father.stateId)
    await this.getFatherCityList()
    $('#inp_fathername').val(father.name)
    $('#inp_fatherId').val(father.id)

    $('#inp_fphone').val(father.phoneNo)
    $('#inp_faddress1').val(father.address1)
    $('#inp_faddress2').val(father.address2)

    $('#inp_fcity').val(!father.cityId ? 'City' : father.cityId)
    $('#inp_fpin').val(father.pin)
    $('#inp_femail').val(father.eMail)
    $('#inp_fEmailStatus').val(father.emailStatus)

    if (father.emailStatus === 30) {
      $('#emailStatusIconF').addClass('fa fa-check-circle success')
      $('#emailStatusIconF').css({ color: 'green' })
      $('#fReverify').removeClass('disabled')
    } else {
      $('#emailStatusIconF').addClass('fa fa-exclamation-circle  warning')
      $('#emailStatusIconF').css({ color: 'orange' })
      $('#fReverify').addClass('disabled')
    }
  }
  async setMotherDtls(mother) {
    // // console.log("setmotherDtls called, mother", mother);
    $('#inp_mstate').val(!mother.stateId ? 'State' : mother.stateId)
    await this.getMotherCityList()
    $('#inp_mothername').val(mother.name)
    $('#inp_motherId').val(mother.id)
    $('#inp_mphone').val(mother.phoneNo)
    $('#inp_maddress1').val(mother.address1)
    $('#inp_maddress2').val(mother.address2)
    $('#inp_mcity').val(!mother.cityId ? 'City' : mother.cityId)
    $('#inp_mpin').val(mother.pin)
    $('#inp_memail').val(mother.eMail)
    $('#inp_mEmailStatus').val(mother.emailStatus)

    if (mother.emailStatus === 30) {
      $('#emailStatusIconM').addClass('fa fa-check-circle success')
      $('#emailStatusIconM').css({ color: 'green' })
      $('#mReverify').removeClass('disabled')
    } else {
      $('#emailStatusIconM').addClass('fa fa-exclamation-circle  warning')
      $('#emailStatusIconM').css({ color: 'orange' })
      $('#mReverify').addClass('disabled')
    }
  }
  async setGuardianDtls(guardian) {
    // // console.log("setguardianDtls called, guardian", guardian);
    $('#inp_gstate').val(!guardian.stateId ? 'State' : guardian.stateId)
    await this.getGuardianCityList()
    $('#inp_guardianname').val(guardian.name)
    $('#inp_guardianId').val(guardian.id)
    $('#inp_gphone').val(guardian.phoneNo)
    $('#inp_gaddress1').val(guardian.address1)
    $('#inp_gaddress2').val(guardian.address2)
    $('#inp_gcity').val(!guardian.cityId ? 'City' : guardian.cityId)
    $('#inp_gpin').val(guardian.pin)
    $('#inp_gemail').val(guardian.eMail)
    $('#inp_gEmailStatus').val(guardian.emailStatus)

    if (guardian.emailStatus === 30) {
      $('#emailStatusIconG').addClass('fa fa-check-circle success')
      $('#emailStatusIconG').css({ color: 'green' })
      $('#gReverify').removeClass('disabled')
    } else {
      $('#emailStatusIconG').addClass('fa fa-exclamation-circle  warning')
      $('#emailStatusIconG').css({ color: 'orange' })
      $('#gReverify').addClass('disabled')
    }
  }
  fillGenderCmb() {
    $('#inp_gender').empty()
    $('#inp_gender').prepend(
      $('<option> </option>').val('Gender').html('Gender')
    )
    for (var i = 0; i < lstGender.length; i++) {
      var genderId = lstGender[i].genderId
      var gender = lstGender[i].gender
      $('#inp_gender').append(
        $('<option> </option>').val(genderId).html(gender)
      )
    }
  }
  fillEmailStatus() {
    // // console.log("fillEmailStatus called, lstEmailStatus", lstEmailStatus);
    $(
      '#inp_sEmailStatus, #inp_mEmailStatus, #inp_fEmailStatus, #inp_gEmailStatus'
    ).empty()
    $(
      '#inp_sEmailStatus, #inp_mEmailStatus, #inp_fEmailStatus, #inp_gEmailStatus'
    ).prepend($('<option> </option>').val('0').html('Email Status'))
    for (var i = 0; i < lstEmailStatus.length; i++) {
      var emailStatusId = lstEmailStatus[i].emailStatusId
      var emailStatus = lstEmailStatus[i].emailStatus
      $(
        '#inp_sEmailStatus, #inp_mEmailStatus, #inp_fEmailStatus, #inp_gEmailStatus'
      ).append($('<option> </option>').val(emailStatusId).html(emailStatus))
    }
  }
  getMasterData = () => {
    this.getMstClassList(schoolId)
    this.setState({ loader: 'block', opacity: 0.5 })
    axios({
      url: httpURL + '/sms/mst/state/list',
      method: 'POST',
      data: queryString.stringify({ schoolId: schoolId, screenId }),
      headers: { tokenid: this.state.tokenid },
      withCredentials: true
    })
      .then(response => {
        if (response.data.SvcStatus === 'Success') {
          this.setState({
            lstState: response.data.lstState
          })
          lstState = response.data.lstState
          // // console.log(lstState);
          this.fillStateCmb()
          this.fillGenderCmb()
          this.setSiblingGenderCmb()
          const params = new URLSearchParams(window.location.search);
          var studentId = params.get("studentId");
          this.setState({ studentId: studentId })
          if (parseInt(studentId) !== 0) {
            // $('#StudentHistoryForm input,#StudentHistoryForm select,#StudentHistoryForm button').css({cursor:'pointer'});
            // $('#medicalHistoryForm input,#medicalHistoryForm select,#medicalHistoryForm button').css({cursor:'pointer'});
            // $('.siblingTableDiv input, #addSibling input').css({cursor:'pointer'});
            // $('#parentsForm input,#parentsForm select,#parentsForm button').css({cursor:'pointer'});
            this.getStudentsData(studentId);
            this.getParentsData(studentId);
            this.getSiblingsList(studentId);
            this.getFeeEmail(studentId);
            $("#addSibling").css({ opacity: "1" });

            $(
              "#StudentHistoryForm *,#parentsForm *,.siblingTableDiv *,#medicalHistoryForm *"
            ).removeAttr("disabled");
            $("#inp_fEmailStatus , #inp_mEmailStatus , #inp_gEmailStatus").attr(
              "disabled",
              "true"
            );


            //this.getCommCallId(studentId);
            $('#addSibling').css({ opacity: '1' })
            $('#addSibling').click(function (e) {
              return true
            })
          } else {
            // $('#StudentHistoryForm input,#StudentHistoryForm select,#StudentHistoryForm button').css({cursor:'not-allowed'});
            // $('#medicalHistoryForm input,#medicalHistoryForm select,#medicalHistoryForm button').css({cursor:'not-allowed'});
            // $('.siblingTableDiv input, #addSibling').css({cursor:'not-allowed'});
            // $('#parentsForm input,#parentsForm select,#parentsForm button').css({cursor:'not-allowed'});
            $('#addSibling').css({ opacity: '.65' })
            $('#addSibling').click(function (e) {
              return false
            })
            $(
              '#StudentHistoryForm *,#parentsForm *,.siblingTableDiv *,#medicalHistoryForm *'
            ).attr('disabled', 'true')
          }
        } else {
          toast.error(response.data.SvcMsg)
          if (response.data.SvcMsg === 'You are not Logged In') {
            setTimeout(function () {
              window.location.href = '/'
            }, 2000)
            return false
          } else if (response.data.SvcMsg === 'Server Timed Out, Login Again') {
            setTimeout(function () {
              window.location.href = '/'
            }, 2000)
            return false
          }
        }
        this.setState({ loader: 'none', opacity: 1 })
      })
      .catch(error => {
        this.setState({ loader: 'none', opacity: 1 })
        toast.error('25. Service failed ' + error)
      })

    // axios({
    //     url: httpURL + '/sms/mst/city/list',
    //     method: "POST",
    //     data:  queryString.stringify({ 'schoolId': schoolId,screenId}) ,
    //     headers: { 'tokenid': this.state.tokenid },
    //     withCredentials: true,
    // }).then((response) => {
    //     if (response.data.SvcStatus === 'Success') {
    //         this.setState({
    //             lstCity:response.data.lstCity,
    //         })
    //         lstCity = this.state.lstCity;

    // // console.log(lstCity);
    //         this.fillCityCmb();
    //         this.fillFCityCmb();
    //         this.fillMCityCmb();
    //         this.fillGCityCmb();
    //         this.fillPreCityCmb();
    //     }
    //     else {
    //         toast.error(response.data.SvcMsg);
    //         if (response.data.SvcMsg === 'You are not Logged In') {
    //             setTimeout(function () { window.location.href = ('/') }, 2000);
    //             return false;
    //         }
    //         else if (response.data.SvcMsg === 'Server Timed Out, Login Again') {
    //             setTimeout(function () { window.location.href = ('/') }, 2000);
    //             return false;
    //         }
    //     }
    //     this.setState({ loader: 'none', opacity: 1 });
    // }).catch((error) => {
    //     toast.error("Service failed " + error);
    // });
  }
  fillStateCmb() {
    this.fillSStateCmb()
    this.fillFStateCmb()
    this.fillMStateCmb()
    this.fillGStateCmb()
    this.fillPreStateCmb()
  }
  fillSStateCmb() {
    $('#inp_state').empty()
    $('#inp_state').prepend($('<option> </option>').val('State').html('State'))
    for (var i = 0; i < lstState.length; i++) {
      var stateId = lstState[i].stateId
      var state = lstState[i].state
      $('#inp_state').append($('<option> </option>').val(stateId).html(state))
    }
    // // console.log('Selected State after fillSStateCmb:', $('#inp_state').val())
  }
  fillFStateCmb() {
    $('#inp_fstate').empty()
    $('#inp_fstate').prepend($('<option> </option>').val('State').html('State'))
    for (var i = 0; i < lstState.length; i++) {
      var stateId = lstState[i].stateId
      var state = lstState[i].state
      $('#inp_fstate').append($('<option> </option>').val(stateId).html(state))
    }
  }
  fillMStateCmb() {
    $('#inp_mstate').empty()
    $('#inp_mstate').prepend($('<option> </option>').val('State').html('State'))
    for (var i = 0; i < lstState.length; i++) {
      var stateId = lstState[i].stateId
      var state = lstState[i].state
      $('#inp_mstate').append($('<option> </option>').val(stateId).html(state))
    }
  }
  fillGStateCmb() {
    $('#inp_gstate').empty()
    $('#inp_gstate').prepend($('<option> </option>').val('State').html('State'))
    for (var i = 0; i < lstState.length; i++) {
      var stateId = lstState[i].stateId
      var state = lstState[i].state
      $('#inp_gstate').append($('<option> </option>').val(stateId).html(state))
    }
  }
  fillPreStateCmb() {
    $('#inp_prestate').empty()
    $('#inp_prestate').prepend(
      $('<option> </option>').val('State').html('State')
    )
    for (var i = 0; i < lstState.length; i++) {
      var stateId = lstState[i].stateId
      var state = lstState[i].state
      $('#inp_prestate').append(
        $('<option> </option>').val(stateId).html(state)
      )
    }
  }
  getMstClassList = schoolId => {
    axios({
      url: httpURL + '/sms/mst/cls/list',
      method: 'POST',
      data: queryString.stringify({ schoolId: schoolId, screenId }),
      headers: { tokenid: this.state.tokenid },
      withCredentials: true
    })
      .then(response => {
        if (response.data.SvcStatus === 'Success') {
          this.setState({
            lstClass: response.data.lstClass,
          });
          lstClass = response.data.lstClass;

          // // console.log(response.data.lstClass);
          this.fillClassCmb()
          this.fillPreClassCmb()
          this.fillSibClassCmb()
          if (classId) {
            this.getMstSectionList(classId);
          }
        } else {
          toast.error(response.data.SvcMsg)
          if (response.data.SvcMsg === 'You are not Logged In') {
            setTimeout(function () {
              window.location.href = '/'
            }, 2000)
            return false
          } else if (response.data.SvcMsg === 'Server Timed Out, Login Again') {
            setTimeout(function () {
              window.location.href = '/'
            }, 2000)
            return false
          }
        }
        this.setState({ loader: 'none', opacity: 1 })
      })
      .catch(error => {
        this.setState({ loader: 'none', opacity: 1 })
        toast.error('26. Service failed ' + error)
      })
  }
  getMstSectionList = clsId => {
    axios({
      url: httpURL + '/sms/mst/section/list',
      method: 'POST',
      data: queryString.stringify({ clsId: clsId }),
      headers: { tokenid: this.state.tokenid },
      withCredentials: true
    })
      .then(response => {
        if (response.data.SvcStatus === 'Success') {
          this.setState({
            lstSection: response.data.lstSection,
          });
          lstSection = response.data.lstSection;
          // // console.log("getMstSectionList successful, response.data.lstSection", response.data.lstSection);
          // // console.log("getMstSectionList successful, lstSection", lstSection);

        } else {
          toast.error(response.data.SvcMsg)
          if (response.data.SvcMsg === 'You are not Logged In') {
            setTimeout(function () {
              window.location.href = '/'
            }, 2000)
            return false
          } else if (response.data.SvcMsg === 'Server Timed Out, Login Again') {
            setTimeout(function () {
              window.location.href = '/'
            }, 2000)
            return false
          }
        }
        this.setState({ loader: 'none', opacity: 1 })
      })
      .catch(error => {
        this.setState({ loader: 'none', opacity: 1 })
        toast.error('26. Service failed ' + error)
      })
  }

  getSiblingsList = studentId => {
    var schoolStudent = {
      schoolId: schoolId,
      studentId: parseInt(studentId)
    }
    axios({
      url: httpURLStudent + '/sms/student/list_siblings',
      method: 'POST',
      data: queryString.stringify({
        schoolStudent: JSON.stringify(schoolStudent),
        screenId
      }),
      headers: { tokenid: this.state.tokenid },
      withCredentials: true
    })
      .then(response => {
        if (response.data.SvcStatus === 'Success') {
          this.setState({
            lstSiblings: response.data.lstSiblings,
          });
          lstSiblings = response.data.lstSiblings;
          this.setSiblingsTable(lstSiblings);
        } else {
          toast.error(response.data.SvcMsg)
          if (response.data.SvcMsg === 'You are not Logged In') {
            setTimeout(function () {
              window.location.href = '/'
            }, 2000)
            return false
          } else if (response.data.SvcMsg === 'Server Timed Out, Login Again') {
            setTimeout(function () {
              window.location.href = '/'
            }, 2000)
            return false
          }
        }
        this.setState({ loader: 'none', opacity: 1 })
      })
      .catch(error => {
        this.setState({ loader: 'none', opacity: 1 })
        toast.error('1 Service failed ' + error)
      })
  }
  handleResize = () => {
    var width = $('.tableLayout1').parent().width() - 18
    this.setState({ width: width })
  }
  fillClassCmb() {
    $('#inp_class').empty()
    $('#inp_class').prepend($('<option> </option>').val('Class').html('Class'))
    for (var i = 0; i < lstClass.length; i++) {
      var classId = lstClass[i].clsId
      var classNm = lstClass[i].cls
      $('#inp_class').append($('<option> </option>').val(classId).html(classNm))
    }
  }
  fillPreClassCmb() {
    $('#inp_preclass').empty()
    $('#inp_preclass').prepend(
      $('<option> </option>').val('Class').html('Class')
    )
    for (var i = 0; i < lstClass.length; i++) {
      var classId = lstClass[i].clsId
      var classNm = lstClass[i].cls
      $('#inp_preclass').append(
        $('<option> </option>').val(classId).html(classNm)
      )
    }
  }
  fillSibClassCmb() {
    $('#inp_sclass').empty()
    $('#inp_sclass').prepend($('<option> </option>').val('Class').html('Class'))
    for (var i = 0; i < lstClass.length; i++) {
      var classId = lstClass[i].clsId
      var classNm = lstClass[i].cls
      $('#inp_sclass').append(
        $('<option> </option>').val(classId).html(classNm)
      )
    }
  }

  addTD(row, val, classname) {
    var cell = document.createElement('td')
    cell.className = classname
    var text = document.createTextNode(val)
    cell.append(text)
    row.append(cell)
  }

  highlightRow(table, id) {
    $('#' + table + ' tr').each(function () {
      var studentId = $(this).find(COL_STUDENTID).text()
      if (studentId === id) {
        $(this).addClass('selected').siblings().removeClass('selected')
      }
    })
  }

  setSiblingPopup = event => {
    $(event.target).closest('tr').click()
    this.setState({
      selSibling: $('#siblingTableBody tr.selected').find('td:eq(0)').text()
    })

    var selSibling = $('#siblingTableBody tr.selected').find('td:eq(0)').text()
    // // console.log("selSibling: "+selSibling);
    //this.setSiblingSlider();
    this.setSiblingData(selSibling)

    this.openSiblingPopup()
  }

  // setSiblingSlider = () => {
  //     this.setSiblingGenderCmb();
  //     // $('#inp_sgender').val("");

  // }

  setSiblingGenderCmb() {
    $('#inp_sgender').empty()
    $('#inp_sgender').prepend(
      $('<option> </option>').val('Gender').html('Gender')
    )
    for (var i = 0; i < lstGender.length; i++) {
      var genderId = lstGender[i].genderId
      var gender = lstGender[i].gender
      $('#inp_sgender').append(
        $('<option> </option>').val(genderId).html(gender)
      )
    }
  }

  setSiblingData(pSiblingId) {
    var siblingData = this.getSiblingData(pSiblingId)
    // // console.log(siblingData)
    $('#inp_sibling').val(siblingData.sibling)
    $('#inp_sage').val(siblingData.age)
    $('#inp_sgender').val(siblingData.gender)
    $('#inp_sclass').val(siblingData.clsId)
    $('#inp_sschool').val(siblingData.school)
  }

  getSiblingData(pSiblingId) {
    for (var i = 0; i < lstSiblings.length; i++) {
      if (parseInt(pSiblingId) === lstSiblings[i].siblingId) {
        return lstSiblings[i]
      }
    }
  }

  openSiblingPopup = () => {
    $('.cd-panel').addClass('is-visible')
    $('#siblingSlider').show()
    $('#siblingSlider').siblings('#siblingSlider').hide()
    $('.mainContainer').addClass('addOpacity')
    $('.siblingPopup').css('display', 'block')
  }

  onClickAddSiblingIcon = () => {
    //this.setSiblingSlider();
    this.openSiblingPopup()
    $('#siblingForm').trigger('reset')
    $('body').addClass('showPopup')
  }

  hideSiblingSlider(e) {
    e.preventDefault()
    $('.cd-panel').removeClass('is-visible')
    $('.mainContainer').removeClass('addOpacity')
    $('.siblingPopup').hide()
    $('body').removeClass('showPopup')
  }
  getCityList = (cityId, localityId) => {
    var selState = $("#inp_state").val();
    selState = (!selState || selState === "State") ? 0 : selState;
    // // console.log("getCityList called, selState", selState);
    // // console.log(cityId + "::" + localityId);
    //this.getLocalityList(cityId,localityId);
    axios({
      url: httpURL + "/sms/mst/city/list",
      method: "POST",
      data: queryString.stringify({
        schoolId: schoolId,
        stateId: selState,
        screenId,
      }),
      headers: { tokenid: this.state.tokenid },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          this.setState({
            lstCity: response.data.lstCity,
          });
          lstCity = response.data.lstCity;

          // // console.log(lstCity);
          this.fillCityCmb();
          $("#inp_city").val(cityId);
          if ($("#inp_city").find("option:selected").val()) {
            this.getLocalityList(cityId, localityId);
          }
        } else {
          toast.error(response.data.SvcMsg);
          if (response.data.SvcMsg === "You are not Logged In") {
            setTimeout(function () {
              window.location.href = "/";
            }, 2000);
            return false;
          } else if (response.data.SvcMsg === "Server Timed Out, Login Again") {
            setTimeout(function () {
              window.location.href = "/";
            }, 2000);
            return false;
          }
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((error) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("2. Service failed " + error);
      });
  };
  getLocalityList = (cityId, localityId) => {
    // // console.log("getLocalityList called");
    var selCity = $("#inp_city").find("option:selected").val();
    if (selCity === undefined) {
      // // console.log("selCity is undefined in getLocalityList");
      // return false;
    }
    // // console.log("selCity", selCity);
    selCity = selCity === "City" ? 0 : selCity;
    // // console.log("selCity", selCity);
    // // console.log(selCity);
    // // console.log(cityId + "::" + localityId);
    axios({
      url: httpURL + "/sms/mst/locality/list",
      method: "POST",
      data: queryString.stringify({
        schoolId: schoolId,
        cityId: selCity,
        screenId,
      }),
      headers: { tokenid: this.state.tokenid },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          this.setState({
            lstLocality: response.data.lstLocality,
          });
          lstLocality = response.data.lstLocality;

          // // console.log(lstLocality);
          this.fillLocalityCmb();
          $("#inp_locality").val(localityId);
        } else {
          toast.error(response.data.SvcMsg);
          if (response.data.SvcMsg === "You are not Logged In") {
            setTimeout(function () {
              window.location.href = "/";
            }, 2000);
            return false;
          } else if (response.data.SvcMsg === "Server Timed Out, Login Again") {
            setTimeout(function () {
              window.location.href = "/";
            }, 2000);
            return false;
          }
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((error) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("3. Service failed " + error);
      });
  };
  getPrevCityList = async () => {
    var selState = $("#inp_prestate").val();
    selState = selState == "State" ? 0 : selState;
    // // console.log("getPrevCityList called, selState", selState);
    await axios({
      url: httpURL + "/sms/mst/city/list",
      method: "POST",
      data: queryString.stringify({
        schoolId: schoolId,
        stateId: selState,
        screenId,
      }),
      headers: { tokenid: this.state.tokenid },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          this.setState({
            lstCity: response.data.lstCity,
          });
          lstCity = response.data.lstCity;

          // // console.log(lstCity);
          this.fillPreCityCmb();
        } else {
          toast.error(response.data.SvcMsg);
          if (response.data.SvcMsg === "You are not Logged In") {
            setTimeout(function () {
              window.location.href = "/";
            }, 2000);
            return false;
          } else if (response.data.SvcMsg === "Server Timed Out, Login Again") {
            setTimeout(function () {
              window.location.href = "/";
            }, 2000);
            return false;
          }
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((error) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("4. Service failed " + error);
      });
  };
  getFatherCityList = async () => {
    var selState = $("#inp_fstate").val();
    selState = (!selState || selState === "State") ? 0 : selState;
    // // console.log("getFatherCityList called, selState", selState);
    await axios({
      url: httpURL + "/sms/mst/city/list",
      method: "POST",
      data: queryString.stringify({
        schoolId: schoolId,
        stateId: selState,
        screenId,
      }),
      headers: { tokenid: this.state.tokenid },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          this.setState({
            lstCity: response.data.lstCity,
          });
          lstCity = response.data.lstCity;

          // // console.log(lstCity);
          this.fillFCityCmb();
        } else {
          toast.error(response.data.SvcMsg);
          if (response.data.SvcMsg === "You are not Logged In") {
            setTimeout(function () {
              window.location.href = "/";
            }, 2000);
            return false;
          } else if (response.data.SvcMsg === "Server Timed Out, Login Again") {
            setTimeout(function () {
              window.location.href = "/";
            }, 2000);
            return false;
          }
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((error) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("5. Service failed " + error);
      });
  };

  getMotherCityList = async () => {
    var selState = $("#inp_mstate").val();
    selState = (!selState || selState === "State") ? 0 : selState;
    // // console.log("getMotherCityList called, selState", selState);
    await axios({
      url: httpURL + "/sms/mst/city/list",
      method: "POST",
      data: queryString.stringify({
        schoolId: schoolId,
        stateId: selState,
        screenId,
      }),
      headers: { tokenid: this.state.tokenid },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          this.setState({
            lstCity: response.data.lstCity,
          });
          lstCity = response.data.lstCity;

          // // console.log(lstCity);
          this.fillMCityCmb();
        } else {
          toast.error(response.data.SvcMsg);
          if (response.data.SvcMsg === "You are not Logged In") {
            setTimeout(function () {
              window.location.href = "/";
            }, 2000);
            return false;
          } else if (response.data.SvcMsg === "Server Timed Out, Login Again") {
            setTimeout(function () {
              window.location.href = "/";
            }, 2000);
            return false;
          }
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((error) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("6. Service failed " + error);
      });
  };

  getGuardianCityList = async () => {
    var selState = $("#inp_gstate").val();
    selState = (!selState || selState === "State") ? 0 : selState;
    // // console.log("getGuardianCityList called, selState", selState);
    await axios({
      url: httpURL + "/sms/mst/city/list",
      method: "POST",
      data: queryString.stringify({
        schoolId: schoolId,
        stateId: selState,
        screenId,
      }),
      headers: { tokenid: this.state.tokenid },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          this.setState({
            lstCity: response.data.lstCity,
          });
          lstCity = response.data.lstCity;

          // // console.log(lstCity);
          this.fillGCityCmb();
        } else {
          toast.error(response.data.SvcMsg);
          if (response.data.SvcMsg === "You are not Logged In") {
            setTimeout(function () {
              window.location.href = "/";
            }, 2000);
            return false;
          } else if (response.data.SvcMsg === "Server Timed Out, Login Again") {
            setTimeout(function () {
              window.location.href = "/";
            }, 2000);
            return false;
          }
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((error) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("7. Service failed " + error);
      });
  };
  fillCityCmb() {
    // // console.log("fillCityCmb() called..");
    var stateId = parseInt($("#inp_state").find("option:selected").val());
    $("#inp_city").empty();
    $("#inp_city").prepend($("<option> </option>").val("City").html("City"));

    for (var i = 0; i < lstCity.length; i++) {
      //if (parseInt(stateId) === lstCity[i].stateId) {
      var cityId = lstCity[i].cityId;
      var city = lstCity[i].city;
      $("#inp_city").append($("<option> </option>").val(cityId).html(city));
      //}
    }
  }
  fillLocalityCmb() {
    // // console.log("fillLocalityCmb() called..");
    var cityId = parseInt($("#inp_city").find("option:selected").val());
    $("#inp_locality").empty();
    $("#inp_locality").prepend(
      $("<option> </option>").val("Locality").html("Locality")
    );

    for (var i = 0; i < lstLocality.length; i++) {
      //if (parseInt(stateId) === lstLocality[i].stateId) {
      var localityId = lstLocality[i].localityId;
      var locality = lstLocality[i].locality;
      $("#inp_locality").append(
        $("<option> </option>").val(localityId).html(locality)
      );
      //}
    }
  }

  fillFCityCmb() {
    // // console.log('fillFCityCmb() called');
    var stateId = $("#inp_fstate").find("option:selected").val();
    $("#inp_fcity").empty();
    $("#inp_fcity").prepend($("<option> </option>").val("City").html("City"));
    for (var i = 0; i < lstCity.length; i++) {
      //if (parseInt(stateId) === lstCity[i].stateId) {
      var cityId = lstCity[i].cityId;
      var city = lstCity[i].city;
      $("#inp_fcity").append($("<option> </option>").val(cityId).html(city));
      //}
    }
  }
  fillMCityCmb() {
    // // console.log("fillMCityCmb() called..");
    var stateId = $("#inp_mstate").find("option:selected").val();
    $("#inp_mcity").empty();
    $("#inp_mcity").prepend($("<option> </option>").val("City").html("City"));
    for (var i = 0; i < lstCity.length; i++) {
      //if (parseInt(stateId) === lstCity[i].stateId) {
      var cityId = lstCity[i].cityId;
      var city = lstCity[i].city;
      $("#inp_mcity").append($("<option> </option>").val(cityId).html(city));
      //}
    }
  }
  fillGCityCmb() {
    // // console.log("fillGCityCmb() called..");
    var stateId = $("#inp_gstate").find("option:selected").val();
    $("#inp_gcity").empty();
    $("#inp_gcity").prepend($("<option> </option>").val("City").html("City"));
    for (var i = 0; i < lstCity.length; i++) {
      //if (parseInt(stateId) === lstCity[i].stateId) {
      var cityId = lstCity[i].cityId;
      var city = lstCity[i].city;
      $("#inp_gcity").append($("<option> </option>").val(cityId).html(city));
      //}
    }
  }
  fillPreCityCmb() {
    // // console.log("fillPreCityCmb() called..");
    var stateId = $("#inp_prestate").find("option:selected").val();
    $("#inp_precity").empty();
    $("#inp_precity").prepend($("<option> </option>").val("City").html("City"));
    for (var i = 0; i < lstCity.length; i++) {
      //if (parseInt(stateId) === lstCity[i].stateId) {
      var cityId = lstCity[i].cityId;
      var city = lstCity[i].city;
      $("#inp_precity").append($("<option> </option>").val(cityId).html(city));
      //}
    }
  }
  maintainSibling = () => {
    // // console.log("this.state.studentId = "+this.state.studentId);
    var siblingId = parseInt(
      $("#siblingTableBody tr.selected").find("td:eq(0)").text()
    );
    // // console.log("siblingId:"+siblingId);
    // // console.log(typeof siblingId);
    if (parseInt(siblingId) === 0 || isNaN(siblingId)) {
      this.addSibling();
    } else {
      this.editSibling();
    }
  };

  addSibling = () => {
    var siblingId = 0;
    var sibling = $("#inp_sibling").val();
    var sgender = $("#inp_sgender").find("option:selected").val();
    var clsId = parseInt($("#inp_sclass").find("option:selected").val());
    var cls = $("#inp_sclass").find("option:selected").text();
    var sage = parseInt($("#inp_sage").val());
    var sschool = $("#inp_sschool").val();
    // if(teacher === ''){
    // 	toast.warning('Please select teacher');
    // 	document.getElementById('inp_teacher').focus();
    // 	return false;
    // }

    // if(educationId == 0){
    // 	toast.warning('Please select education');
    // 	document.getElementById('inp_education').focus();
    // 	return false;
    // }

    var studentSibling = {
      studentId: this.state.studentId,
      siblingId: siblingId,
      sibling: sibling,
      age: sage,
      gender: sgender,
      clsId: clsId,
      cls: cls,
      school: sschool,
    };
    // // console.log(studentSibling);
    axios
      .post(
        httpURLStudent + "/sms/student/save_sibling",
        queryString.stringify({
          studentSibling: JSON.stringify(studentSibling),
          screenId,
        }),
        {
          headers: { tokenid: this.state.tokenid },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          toast.success(response.data.SvcMsg);
          this.setState({ loader: "block", opacity: 0.5 });
          this.getSiblingsList(this.state.studentId);
          $(".cd-panel").removeClass("is-visible");
          $(".mainContainer").removeClass("addOpacity");
          $(".siblingPopup").hide();
          $("body").removeClass("showPopup");
        } else {
          toast.error(response.data.SvcMsg);
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((e) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("8. Service failed: " + e);
      });
  };

  editSibling = () => {
    var siblingId = parseInt(
      $("#siblingTableBody tr.selected").find("td:eq(0)").text()
    );
    var sibling = $("#inp_sibling").val();
    var sgender = $("#inp_sgender").find("option:selected").val();
    var clsId = parseInt($("#inp_sclass").find("option:selected").val());
    var cls = $("#inp_sclass").find("option:selected").text();
    var sage = parseInt($("#inp_sage").val());
    var sschool = $("#inp_sschool").val();
    // if(teacher === ''){
    // 	toast.warning('Please select teacher');
    // 	document.getElementById('inp_teacher').focus();
    // 	return false;
    // }

    // if(educationId == 0){
    // 	toast.warning('Please select education');
    // 	document.getElementById('inp_education').focus();
    // 	return false;
    // }

    var studentSibling = {
      siblingId: siblingId,
      sibling: sibling,
      age: sage,
      gender: sgender,
      clsId: clsId,
      cls: cls,
      school: sschool,
    };
    // // console.log(studentSibling);
    axios
      .post(
        httpURLStudent + "/sms/student/save_sibling",
        queryString.stringify({
          studentSibling: JSON.stringify(studentSibling),
          screenId,
        }),
        {
          headers: { tokenid: this.state.tokenid },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          toast.success(response.data.SvcMsg);
          this.setState({ loader: "block", opacity: 0.5 });
          this.getSiblingsList(this.state.studentId);
          $(".cd-panel").removeClass("is-visible");
          $(".mainContainer").removeClass("addOpacity");
          $(".siblingPopup").hide();
          $("body").removeClass("showPopup");
        } else {
          toast.error(response.data.SvcMsg);
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((e) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("9. Service failed: " + e);
      });
  };
  maintainStudent = () => {
    // // console.log("this.state.studentId = "+this.state.studentId);
    if (parseInt(this.state.studentId) === 0) {
      this.addStudent();
    } else {
      this.editStudent();
    }
  };
  addStudent = () => {
    var studentObj = {};
    var studentId = this.state, studentId;
    var student = $("#inp_student").val();
    var dob = $("#inp_dob").val();
    var gender = $("#inp_gender").find("option:selected").val();
    var doa = $("#inp_doa").val();
    var dor = $("#inp_dor").val();
    var age = $("#lbl_age").text();
    age = parseInt(age.substring(4, age.length));
    var blocked = $("#inp_blocked").is(":checked");
    var address1 = !$("#inp_address1").val() ? null : $("#inp_address1").val();
    var address2 = !$("#inp_address2").val() ? null : $("#inp_address2").val();
    var stateId = parseInt($("#inp_state").find("option:selected").val());
    var cityId = parseInt($("#inp_city").find("option:selected").val());
    var localityId = parseInt($("#inp_locality").find("option:selected").val());
    var pin = $("#inp_pin").val();
    var enrollmentNo = document.getElementById("lbl_enrollment_no").value;
    // // console.log("enrollmentNo = " + enrollmentNo);
    var email = $("#inp_sEmail").val().trim();
    var emailStatus = email === "" ? 10 : 20;

    studentObj = {
      schoolId: schoolId,
      enrollmentNo: enrollmentNo,
      studentId: this.state.flg ? 0 : this.state.studentId,
      student: student,
      dob: dob,
      gender: gender,
      age: !age ? "" : age,
      email: email !== "" ? email : null,
      admissionDt: doa,
      regsDt: dor,
      blocked: blocked,
      address1: !address1 ? "" : address1,
      address2: !address2 ? "" : address2,
      stateId: !stateId ? 0 : stateId,
      cityId: !cityId ? 0 : cityId,
      localityId: !localityId ? 0 : localityId,
      pin: pin,
      emailStatus: emailStatus,
      // clsId:this.classId,
      // sectionId:this.sectionId,
      // clsSectionStudentId:this.clsSectionStudentId,
      clsId: classId,
      sectionId: sectionId,
      clsSectionStudentId: clsSectionStudentId,
    };
    // // console.log("studentObj: "+studentObj);

    this.setState({ loader: "none", opacity: 1 });
    if (student === "") {
      toast.warning("Please fill student name");
      document.getElementById("inp_student").focus();
      return false;
    }
    // if (age === null) {
    //   toast.warning("Please fill student dob");
    //   document.getElementById("inp_dob").focus();
    //   return false;
    // }

    if (student.length > MAX_STUDENT_LENGTH) {
      toast.warning(
        "Student length must not exceed " + MAX_STUDENT_LENGTH + " characters"
      );
      document.getElementById("inp_student").focus();
      return false;
    }
    axios
      .post(
        httpURLStudent + "/sms/student/save",
        queryString.stringify({
          student: JSON.stringify(studentObj),
          screenId,
        }),
        {
          headers: { tokenid: this.state.tokenid },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          // // console.log("new student details saved successfully, response", response);
          toast.success(response.data.SvcMsg);
          this.setState({ loader: "block", opacity: 0.5 });
          const searchParams = new URLSearchParams(window.location.search);
          // // console.log("searchParams.get('studentId')", searchParams.get("studentId"))
          searchParams.set("studentId", response.data.studentId);
          const newUrl = "studentdtls?" + searchParams.toString();
          window.location.href = newUrl;
        } else {
          toast.error(response.data.SvcMsg);
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((e) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("10. Service failed: " + e);
      });
  };

  editStudent = () => {
    var studentObj = {};
    var student = $("#inp_student").val();
    var dob = $("#inp_dob").val();
    var gender = $("#inp_gender").find("option:selected").val();
    var doa = $("#inp_doa").val();
    var dor = $("#inp_dor").val();
    var age = $("#lbl_age").text();
    age = parseInt(age.substring(4, age.length));
    var blocked = $("#inp_blocked").is(":checked");
    var address1 = !$("#inp_address1").val() ? null : $("#inp_address1").val();
    var address2 = !$("#inp_address2").val() ? null : $("#inp_address2").val();
    var stateId = parseInt($("#inp_state").find("option:selected").val());
    var cityId = parseInt($("#inp_city").find("option:selected").val());
    var localityId = parseInt($("#inp_locality").find("option:selected").val());
    var pin = $("#inp_pin").val();
    var email = $("#inp_sEmail").val().trim();
    var emailStatus = email === "" ? 10 : 20;
    var enrollmentNo = document.getElementById("lbl_enrollment_no").value;

    studentObj = {
      studentId: this.state.studentId,
      student: student,
      dob: dob,
      admissionDt: doa,
      regsDt: dor,
      blocked: blocked,
      gender: gender,
      age: !age ? "" : age,
      address1: !address1 ? "" : address1,
      address2: !address2 ? "" : address2,
      stateId: !stateId ? 0 : stateId,
      cityId: !cityId ? 0 : cityId,
      localityId: !localityId ? 0 : localityId,
      pin: pin,
      email: email !== "" ? email : null,
      emailStatus: emailStatus,
      // clsId:this.classId,
      // sectionId:this.sectionId,
      // clsSectionStudentId:this.clsSectionStudentId,
      clsId: classId,
      sectionId: sectionId,
      clsSectionStudentId: clsSectionStudentId,
      enrollmentNo: enrollmentNo,
    };
    // // console.log("studentObj = "+studentObj);

    if (student === "") {
      toast.warning("Please fill student name");
      document.getElementById("inp_student").focus();
      return false;
    }

    if (student.length > MAX_STUDENT_LENGTH) {
      toast.warning(
        "Student length must not exceed " + MAX_STUDENT_LENGTH + " characters"
      );
      document.getElementById("inp_student").focus();
      return false;
    }

    this.setState({ loader: "block", opacity: 0.5 });

    axios
      .post(
        httpURLStudent + "/sms/student/save",
        queryString.stringify({
          student: JSON.stringify(studentObj),
          screenId,
        }),
        {
          headers: { tokenid: this.state.tokenid },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          toast.success(response.data.SvcMsg);
          this.setState({ loader: "none", opacity: 1 });
          // // console.log("getStudentsData() called 1615");
          this.getStudentsData(this.state.studentId);
          // setTimeout(function () {
          //   window.close();
          // }, 5000);

        } else {
          toast.error(response.data.SvcMsg);
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((e) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("11. Service failed: " + e);
      });
  };

  maintainStudentHistory = () => {
    // // console.log("this.state.studentId = "+this.state.studentId);
    this.editStudentHistory();
  };
  editStudentHistory = () => {
    var school = $("#inp_preschool").val();
    var clsId = parseInt($("#inp_preclass").find("option:selected").val());
    var cls = $("#inp_preclass").find("option:selected").text();
    var result = !$("#inp_result").val() ? null : $("#inp_result").val();
    var state = $("#inp_prestate").find("option:selected").text();
    var city = $("#inp_precity").find("option:selected").text();
    var stateId = parseInt($("#inp_prestate").find("option:selected").val());
    var cityId = parseInt($("#inp_precity").find("option:selected").val());
    var board = $("#inp_board").val();

    // var studentHistory={};
    var studentHistory = {
      studentId: this.state.studentId,
      schoolId: schoolId,
      school: school,
      clsId: clsId,
      cls: cls,
      result: result,
      stateId: stateId,
      state: state,
      cityId: cityId,
      city: city,
      board: board,
    };

    // if(student === ''){
    // 	toast.warning('Please fill student name');
    // 	document.getElementById('inp_student').focus();
    // 	return false;
    // }

    // if(student.length > MAX_STUDENT_LENGTH){
    // 	toast.warning('Student length must not exceed '+ MAX_STUDENT_LENGTH + ' characters');
    // 	document.getElementById('inp_student').focus();
    // 	return false;
    // }

    this.setState({ loader: "block", opacity: 0.5 });

    axios
      .post(
        httpURLStudent + "/sms/student/save_history",
        queryString.stringify({
          studentHistory: JSON.stringify(studentHistory),
          screenId,
        }),
        {
          headers: { tokenid: this.state.tokenid },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          toast.success(response.data.SvcMsg);
          this.setState({ loader: "none", opacity: 1 });
          // // console.log("getStudentsData() called... 1689");
          this.getStudentsData(this.state.studentId);
        } else {
          toast.error(response.data.SvcMsg);
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((e) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("12. Service failed: " + e);
      });
  };
  maintainParentsInfo = () => {
    // // console.log("this.state.studentId = "+this.state.studentId);
    this.editParentsData();
  };

  editParentsData = () => {
    var father = $("#inp_fathername").val();
    var fId = $("#inp_fatherId").val();
    var fPhoneNo = $("#inp_fphone").val();
    var fAddress1 = !$("#inp_faddress1").val()
      ? null
      : $("#inp_faddress1").val();
    var fAddress2 = !$("#inp_faddress2").val()
      ? null
      : $("#inp_faddress2").val();
    var fState = $("#inp_fstate").find("option:selected").text();
    var fCity = $("#inp_fcity").find("option:selected").text();
    var fStateId = parseInt($("#inp_fstate").find("option:selected").val());
    var fCityId = parseInt($("#inp_fcity").find("option:selected").val());
    var fPin = $("#inp_fpin").val();
    var fEmail = $("#inp_femail").val().trim();
    var emailStatusF = fEmail === "" ? 10 : 20;

    var mother = $("#inp_mothername").val();
    var mId = $("#inp_motherId").val();
    var mPhoneNo = $("#inp_mphone").val();
    var mAddress1 = !$("#inp_maddress1").val()
      ? null
      : $("#inp_maddress1").val();
    var mAddress2 = !$("#inp_maddress2").val()
      ? null
      : $("#inp_maddress2").val();
    var mState = $("#inp_mstate").find("option:selected").text();
    var mCity = $("#inp_mcity").find("option:selected").text();
    var mStateId = parseInt($("#inp_mstate").find("option:selected").val());
    var mCityId = parseInt($("#inp_mcity").find("option:selected").val());
    var mPin = $("#inp_mpin").val();
    var mEmail = $("#inp_memail").val().trim();
    var emailStatusM = mEmail === "" ? 10 : 20;

    var guardian = $("#inp_guardianname").val();
    var gId = $("#inp_guardianId").val();
    var gPhoneNo = $("#inp_gphone").val();
    var gAddress1 = !$("#inp_gaddress1").val()
      ? null
      : $("#inp_gaddress1").val();
    var gAddress2 = !$("#inp_gaddress2").val()
      ? null
      : $("#inp_gaddress2").val();
    var gState = $("#inp_gstate").find("option:selected").text();
    var gCity = $("#inp_gcity").find("option:selected").text();
    var gStateId = parseInt($("#inp_gstate").find("option:selected").val());
    var gCityId = parseInt($("#inp_gcity").find("option:selected").val());
    var gPin = $("#inp_gpin").val();
    var gEmail = $("#inp_gemail").val().trim();
    var emailStatusG = gEmail === "" ? 10 : 20;

    var fatherObj = {
      name: father,
      id: fId,
      phoneNo: fPhoneNo,
      address1: !fAddress1 ? "" : fAddress1,
      address2: !fAddress2 ? "" : fAddress2,
      stateId: !fStateId ? 0 : fStateId,
      cityId: !fCityId ? 0 : fCityId,
      state: fState,
      city: fCity,
      pin: fPin,
      eMail: fEmail !== "" ? fEmail : null,
      emailStatus: emailStatusF,
    };
    var motherObj = {
      name: mother,
      id: mId,
      phoneNo: mPhoneNo,
      address1: !mAddress1 ? "" : mAddress1,
      address2: !mAddress2 ? "" : mAddress2,
      stateId: !mStateId ? 0 : mStateId,
      cityId: !mCityId ? 0 : mCityId,
      state: mState,
      city: mCity,
      pin: mPin,
      eMail: mEmail !== "" ? mEmail : null,
      emailStatus: emailStatusM,
    };
    var guardianObj = {
      name: guardian,
      id: gId,
      phoneNo: gPhoneNo,
      address1: !gAddress1 ? "" : gAddress1,
      address2: !gAddress2 ? "" : gAddress2,
      stateId: !gStateId ? 0 : gStateId,
      cityId: !gCityId ? 0 : gCityId,
      state: gState,
      city: gCity,
      pin: gPin,
      eMail: gEmail !== "" ? gEmail : null,
      emailStatus: emailStatusG,
    };
    var parentsObj = {
      studentId: this.state.studentId,
      schoolId: schoolId,
      father: fatherObj,
      mother: motherObj,
      guardian: guardianObj,
    };

    // if(student === ''){
    // 	toast.warning('Please fill student name');
    // 	document.getElementById('inp_student').focus();
    // 	return false;
    // }

    // if(student.length > MAX_STUDENT_LENGTH){
    // 	toast.warning('Student length must not exceed '+ MAX_STUDENT_LENGTH + ' characters');
    // 	document.getElementById('inp_student').focus();
    // 	return false;
    // }

    this.setState({ loader: "block", opacity: 0.5 });
    axios
      .post(
        httpURLStudent + "/sms/student/save_parents",
        queryString.stringify({
          studentParents: JSON.stringify(parentsObj),
          screenId,
        }),
        {
          headers: { tokenid: this.state.tokenid },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          toast.success(response.data.SvcMsg);
          this.setState({ loader: "none", opacity: 1 });
          this.getParentsData(this.state.studentId);
        } else {
          toast.error(response.data.SvcMsg);
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((e) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("13. Service failed: " + e);
      });
  };

  maintainMedicalHistory = () => {
    // // console.log("this.state.studentId = "+this.state.studentId);
    this.editMedicalHistory();
  };
  editMedicalHistory = () => {
    var height = $("#inp_height").val();
    var weight = $("#inp_weight").val();
    var bloodGroup = $("#inp_bloodgroup").val();
    var diseases = $("#inp_disease").val();
    var eyes = $("#inp_eyes").val();
    var ears = $("#inp_ears").val();
    var allergicTo = $("#inp_allergicto").val();

    var studentMedical = {
      studentId: this.state.studentId,
      schoolId: schoolId,
      height: height,
      weight: weight,
      bloodGroup: bloodGroup,
      diseases: diseases,
      eyes: eyes,
      ears: ears,
      allergicTo: allergicTo,
    };

    // if(student === ''){
    // 	toast.warning('Please fill student name');
    // 	document.getElementById('inp_student').focus();
    // 	return false;
    // }

    // if(student.length > MAX_STUDENT_LENGTH){
    // 	toast.warning('Student length must not exceed '+ MAX_STUDENT_LENGTH + ' characters');
    // 	document.getElementById('inp_student').focus();
    // 	return false;
    // }

    this.setState({ loader: "block", opacity: 0.5 });
    axios
      .post(
        httpURLStudent + "/sms/student/save_medical",
        queryString.stringify({
          studentMedical: JSON.stringify(studentMedical),
          screenId,
        }),
        {
          headers: { tokenid: this.state.tokenid },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          toast.success(response.data.SvcMsg);
          this.setState({ loader: "none", opacity: 1 });
          // // console.log("getStudentsData() called... 1897");
          this.getStudentsData(this.state.studentId);
        } else {
          toast.error(response.data.SvcMsg);
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((e) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("14. Service failed: " + e);
      });
  };

  reverifySEmail = () => {
    // // console.log("ReverifySEmail method called");
    var resendDetails = {};
    let email = $("#inp_sEmail").val();
    let name = $("#inp_student").val();
    if (email === "") {
      toast.warning("Please Enter Email");
      $("#inp_sEmail").focus();
      return false;
    }
    resendDetails = {
      pId: 1,
      fId: 0,
      mId: 0,
      gId: 0,
      email: email,
      stuId: this.state.studentId,
      schId: schoolId,
      name: name,
    };
    this.setState({ loader: "block", opacity: 0.5 });
    axios
      .post(
        httpURLStudent + "/sms/student/resendMail",
        queryString.stringify({
          resendDetails: JSON.stringify(resendDetails),
          screenId,
        }),
        {
          headers: { tokenid: this.state.tokenid },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          toast.success(response.data.SvcMsg);
        } else {
          toast.error(response.data.SvcMsg);
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((e) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("15. Service failed: " + e);
      });
  };
  reverifyGEmail = () => {
    // // console.log("ReverifyGEmail method called");
    var resendDetails = {};
    let email = $("#inp_gemail").val();
    let name = $("#inp_guardianname").val();
    if (email === "") {
      toast.warning("Please Enter Gaurdian Email");
      $("#inp_gemail").focus();
      return false;
    }
    resendDetails = {
      pId: 0,
      fId: 0,
      mId: 0,
      gId: 1,
      email: email,
      stuId: this.state.studentId,
      schId: schoolId,
      name: name,
    };
    this.setState({ loader: "block", opacity: 0.5 });
    axios
      .post(
        httpURLStudent + "/sms/student/resendMail",
        queryString.stringify({
          resendDetails: JSON.stringify(resendDetails),
          screenId,
        }),
        {
          headers: { tokenid: this.state.tokenid },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          toast.success(response.data.SvcMsg);
        } else {
          toast.error(response.data.SvcMsg);
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((e) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("16. Service failed: " + e);
      });
  };


  deleteFatherDetails = () => {
    // // console.log("deleteFatherDetails method called");
    let pStudentId = this.state.studentId;
    let fId = $("#inp_fatherId").val();
    let pType = 20;
    this.deleteParentDetails(pStudentId, fId, pType);
  }


  deleteMotherDetails = () => {
    // // console.log("deleteMotherDetails method called");
    let pStudentId = this.state.studentId;
    let mId = $("#inp_motherId").val();
    let pType = 30;
    this.deleteParentDetails(pStudentId, mId, pType);
  }

  deleteGuardianDetails = () => {
    // // console.log("deleteGuardianDetails method called");
    let pStudentId = this.state.studentId;
    let gId = $("#inp_guardianId").val();
    let pType = 40;
    this.deleteParentDetails(pStudentId, gId, pType);
  }

  deleteParentDetails = (pStudentId, parentId, pType) => {
    // // console.log("deleteParentDetails called, pStudentId", pStudentId, ", parentId", parentId, ", pType", pType);
    this.setState({ loader: "block", opacity: 0.5 });
    axios({
      url: httpURLStudent + "/sms/student/delete_parent",
      method: "POST",
      params: { "studentId": pStudentId, "parentId": parentId, "parentType": pType },
      headers: { tokenid: this.state.tokenid },
      withCredentials: true,
    })
      .then((response) => {
        // // console.log("resposne in deleteMotherDetails");
        if (response.data.SvcStatus === "Success") {
          toast.success(response.data.SvcMsg);
          this.setState({ loader: "none", opacity: 1 });
          this.getParentsData(this.state.studentId);
        } else {
          toast.error(response.data.SvcMsg);
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((e) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("13. Service failed: " + e);
      });

    // axios
    // .post(
    //   httpURLStudent + "/sms/student/delete_parent",
    //   queryString.stringify({
    //     "studentId": pStudentId,
    //     "parentId": parentId,
    //     "parentType": pType,
    //   }),
    //   {
    //     headers: { tokenid: this.state.tokenid },
    //     withCredentials: true,
    //   }
    // )
    // .then((response) => {
    //   // // console.log("resposne in deleteGuardianDetails");
    //   if (response.data.SvcStatus === "Success") {
    //     toast.success(response.data.SvcMsg);
    //     this.setState({ loader: "none", opacity: 1 });
    //     this.getParentsData(this.state.studentId);
    //   } else {
    //     toast.error(response.data.SvcMsg);
    //   }
    //   this.setState({ loader: "none", opacity: 1 });
    // })
    // .catch((e) => {
    //   this.setState({ loader: "none", opacity: 1 });
    //   toast.error("13. Service failed: " + e);
    // });
  }


  reverifyFEmail = () => {
    // // console.log("ReverifyFEmail method called");
    var resendDetails = {};
    let email = $("#inp_femail").val();
    let name = $("#inp_fathername").val();
    let id = $("#inp_fatherId").val();
    if (email === "") {
      toast.warning("Please Enter Father Email");
      $("#inp_femail").focus();
      return false;
    }
    resendDetails = {
      pId: 0,
      fId: 1,
      mId: 0,
      gId: 0,
      email: email,
      stuId: this.state.studentId,
      schId: schoolId,
      name: name,
    };
    this.setState({ loader: "block", opacity: 0.5 });
    axios
      .post(
        httpURLStudent + "/sms/student/resendMail",
        queryString.stringify({
          resendDetails: JSON.stringify(resendDetails),
          screenId,
        }),
        {
          headers: { tokenid: this.state.tokenid },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          toast.success(response.data.SvcMsg);
        } else {
          toast.error(response.data.SvcMsg);
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((e) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("17. Service failed: " + e);
      });
  };
  reverifyMEmail = () => {
    // // console.log("ReverifyMEmail method called");
    var resendDetails = {};
    let email = $("#inp_memail").val();
    let name = $("#inp_mothername").val();
    if (email === "") {
      toast.warning("Please Enter Mother Email");
      $("#inp_memail").focus();
      return false;
    }
    resendDetails = {
      pId: 0,
      fId: 0,
      mId: 1,
      gId: 0,
      email: email,
      stuId: this.state.studentId,
      schId: schoolId,
      name: name,
    };
    this.setState({ loader: "block", opacity: 0.5 });
    axios
      .post(
        httpURLStudent + "/sms/student/resendMail",
        queryString.stringify({
          resendDetails: JSON.stringify(resendDetails),
          screenId,
        }),
        {
          headers: { tokenid: this.state.tokenid },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          toast.success(response.data.SvcMsg);
        } else {
          toast.error(response.data.SvcMsg);
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((e) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("17. Service failed: " + e);
      });
  };
  getWingId = (wingId) => {
    // // console.log("wingId" + wingId);
  };
  handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    // // console.log(checked)
    this.setState({
      [event.target.id]: checked,
    });
  };

  savecategory = (scholar, sibling, meritMeans, scSt, backward) => {
    var category;
    category = {
      studentId: this.state.studentId,
      scholar: scholar,
      sibling: sibling,
      scst: scSt,
      backward: backward,
      meritMeans: meritMeans,
    };
    // this.setState({ loader: 'block', opacity: 0.5 });

    axios({
      url: httpURLStudent + "/sms/student/category",
      method: "POST",
      params: { screenId: screenId, category: JSON.stringify(category) },
      headers: { tokenid: this.state.tokenid },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          toast.success(response.data.SvcMsg);
        } else {
          toast.error(response.data.SvcMsg);
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((e) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("18. Service failed: " + e);
      });
  };
  saveFeeEmail = (comm) => {
    // this.setState({ loader: 'block', opacity: 0.5 });

    axios({
      url: httpURLStudent + "/sms/student/saveCommId",
      method: "POST",
      params: {
        screenId: screenId,
        studentId: this.state.studentId,
        personId: comm,
      },
      headers: { tokenid: this.state.tokenid },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          toast.success(response.data.SvcMsg);
        } else {
          toast.error(response.data.SvcMsg);
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((e) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("19. Service failed: " + e);
      });
  };
  getDocDtlsSvc = () => {
    //var studentId = localStorage.getItem("studentId");
    axios({
      url: httpURLStudent + "/sms/student/docs/dtls",
      method: "POST",
      params: { studentId: this.state.studentId },
      headers: { tokenid: this.state.tokenid },
      //s: {},
      withCredentials: true,
    })
      .then((Response) => {
        var data = Response.data;
        // // console.log("list fetching success");
        this.cbIdDocDtlsResponse(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  cbIdDocDtlsResponse = (pLstDocDtls) => {
    if (pLstDocDtls.SvcStatus === "Success") {
      this.setState({ lstDocDtls: pLstDocDtls.lstDocDtls });
      //setLstDocDtls(pLstDocDtls.lstDocDtls);
      for (var i = 0; i < pLstDocDtls.lstDocDtls.length; i++) {
        var docObj = pLstDocDtls.lstDocDtls[i];
        if (docObj.typeId === PHOTO) {
          this.setState({
            photoPath:
              httpURLStudent +
              "/sms/student/docs/view?imgPath=" +
              docObj.imgPath,
          });
          //setPhotoPath();
        }
      }
      //cbIdDocDtlsResponse1(lstDocDtls)
    } else {
      toast.error(pLstDocDtls.SvcMsg);
    }
    this.setState({ loader: "none", opacity: 1 });
  };
  handlePhotoChange = (e) => {
    // // console.log(e.target.files[0].name);
    // // console.log("src:" + URL.createObjectURL(e.target.files[0]));
    if (e.target.files && e.target.files[0]) {
      this.setState({ photoPath: URL.createObjectURL(e.target.files[0]) });
      this.setState({ loader: "block", opacity: 0.5 });
      this.saveImgIdSvc(e.target.files[0], 50);
    }
  };
  onError = (e) => {
    this.setState({ photoPath: window.appURL + "/no-image.png" });
    // // console.log("photoPath: window.appURL + \"/no-image.png\" in studentdtls.js", window.appURL + "/no-image.png");
    // this.setState({ photoPath: window.appURL + "/images/no-image.png" })
  };
  handlePhotoClick = (e) => {
    // // console.log(document.getElementById("img_photoId"));
    // document.getElementById("img_photoId").dispatchEvent(new Event('click'));
    document.getElementById("img_photoId").click();
  };
  saveImgIdSvc = (file, type) => {
    var formData = new FormData();
    formData.append("studentId", this.state.studentId);
    formData.append("file", file);
    formData.append("typeId", type);
    axios({
      url: httpURLStudent + "/sms/student/docs/save",
      method: "POST",
      data: formData,
      headers: { tokenid: this.state.tokenid },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          toast.success(response.data.SvcMsg);
          this.cbSaveImgIdResponse(response.data);
        } else {
          toast.error(response.data.SvcMsg);
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((e) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("20. Service failed: " + e);
      });
  };
  cbSaveImgIdResponse = (data) => {
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
    } else {
      toast.error(data.SvcMsg);
    }
    this.setState({ loader: "none", opacity: 1 });
  };
  handleClassChange = (e) => {
    // // console.log("handleClassChange called, e.target.value", e.target.value);
    // // console.log("classId before change", this.classId);
    this.setState({ classId: e.target.value });
    classId = e.target.value;
    this.getMstSectionList(e.target.value);
  }
  handleSectionChange = (e) => {
    // // console.log("handleSectionChange called, e.target.value", e.target.value);
    // // console.log("secId before change", this.secId);
    this.setState({ secId: e.target.value });
    sectionId = e.target.value;
  }

  render() {
    httpURL = lstModules["main"].url + ":" + lstModules["main"].port;
    httpURLStudent =
      lstModules["students"].url + ":" + lstModules["students"].port;
    return (
      <div>
        <Header wingFromChild={this.getWingId} />
        <ToastContainer autoClose={3000} hideProgressBar />
        <div
          style={{
            position: "fixed",
            zIndex: 10000,
            width: 100,
            height: 100,
            marginLeft: -50,
            marginTop: -50,
            left: "50%",
            top: "50%",
            display: this.state.loader,
          }}
        >
          <LoaderImage />
        </div>

        <div
          className="mainContainer studentsDtls"
          style={{ opacity: this.state.opacity }}
        >
          <div className="container-fluid">
            <div className="col-sm-12 col-centered">
              <div
                className="col-sm-12 col-centered"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <div className="col-sm-12 ">
                  {/* <StudentPersonalDtlsForm
                  /> */}
                  <form
                    id="studentAddForm"
                    className="col-sm-12 form-horizontal">
                    <div className="col-sm-12 section1">
                      <div className="">
                        <label className="control-label col-sm-9">
                          Personal Details
                        </label>
                        <label
                          className="control-label col-sm-2 pull-right"
                          id="enrollment_no"
                        >
                          <input
                            type="text"
                            id="lbl_enrollment_no"
                            className="form-control"
                            placeholder="Enrollment No."
                          />
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-12 shadowShow  padding-remove">
                      <div className="col-sm-5">
                        <div className="form-group">
                          <label className="control-label col-sm-4">
                            Name:
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Name"
                              id="inp_student"
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-sm-4">
                            Date of birth:
                          </label>
                          <div className="col-sm-5">
                            <DatePicker
                              id="inp_dob"
                              className="form-control"
                              placeholderText="Date of birth"
                              dateFormat="dd-MM-yyyy"
                              dropdownMode="select"
                              selected={this.state.dob}
                              onChange={(date) => {
                                this.setState({ dob: date });
                                this.calcAge(date);
                              }}
                              autoComplete="off"
                              showMonthDropdown
                              showYearDropdown
                              adjustDateOnChange
                            />
                          </div>
                          <label
                            className="control-label col-sm-2"
                            id="lbl_age"
                          >
                            Age:
                          </label>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-sm-4">
                            Gender:
                          </label>
                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              id="inp_gender"
                            ></select>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-sm-4">
                            Date of Admission:
                          </label>
                          <div className="col-sm-6">
                            <DatePicker
                              id="inp_doa"
                              className="form-control"
                              placeholderText="Date of admission"
                              dateFormat="dd-MM-yyyy"
                              dropdownMode="select"
                              selected={this.state.doa}
                              onChange={(date) => {
                                this.setState({ doa: date });
                              }}
                              autoComplete="off"
                              showMonthDropdown
                              showYearDropdown
                              adjustDateOnChange
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-sm-4">
                            Date of Registration:
                          </label>
                          <div className="col-sm-6">
                            <DatePicker
                              id="inp_dor"
                              className="form-control"
                              placeholderText="Date of registration"
                              dateFormat="dd-MM-yyyy"
                              dropdownMode="select"
                              selected={this.state.dor}
                              onChange={(date) => {
                                this.setState({ dor: date });
                              }}
                              autoComplete="off"
                              showMonthDropdown
                              showYearDropdown
                              adjustDateOnChange
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-sm-4">
                            Email:
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="email"
                              className="form-control"
                              id="inp_sEmail"
                              placeholder="Email"
                            />
                          </div>
                          <i id="emailStatusIconS" aria-hidden="true"></i>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-sm-4 emailStatusHide">
                            Email Status:
                          </label>
                          <div className="col-sm-5 emailStatusHide">
                            <select
                              className="form-control "
                              id="inp_sEmailStatus"
                              disabled
                            />
                          </div>
                          <a
                            className="reverify"
                            id="sReverify"
                            title="Reverify Student Email"
                            onClick={this.reverifySEmail}
                            href="#!"
                          >
                            Reverify
                          </a>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-sm-4">
                            Class:
                          </label>
                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              value={classId}
                              onChange={(e) => this.handleClassChange(e)}
                            >
                              <option value={0}>Class</option>
                              {lstClass &&
                                lstClass.map((cls) => (
                                  <option
                                    key={cls.clsId}
                                    value={cls.clsId}>
                                    {cls.cls}
                                  </option>
                                ))
                              }
                            </select>
                          </div>
                          <i id="emailStatusIconS" aria-hidden="true"></i>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-sm-4">
                            Section:
                          </label>
                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              value={sectionId}
                              onChange={(e) => this.handleSectionChange(e)}
                            >
                              <option value={0}>Section</option>
                              {lstSection &&
                                lstSection.map((section) => (
                                  <option
                                    key={section.sectionId}
                                    value={section.sectionId}>
                                    {section.section}
                                  </option>
                                ))
                              }
                            </select>
                          </div>
                        </div>
                        <i id="emailStatusIconS" aria-hidden="true"></i>
                      </div>
                      <div className="col-sm-5">
                        <div className="form-group">
                          <label className="control-label col-sm-4">
                            Address 1:
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Address 1"
                              id="inp_address1"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-sm-4">
                            Address 2:
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Address 2"
                              id="inp_address2"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-sm-4">
                            State:
                          </label>
                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              id="inp_state"
                              onChange={this.getCityList}
                            ></select>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-sm-4">
                            City:
                          </label>
                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              id="inp_city"
                              onChange={this.getLocalityList}
                            ></select>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-sm-4">
                            Locality:
                          </label>
                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              id="inp_locality"
                            ></select>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-sm-4">Pin:</label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Pin"
                              id="inp_pin"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-sm-4">
                            Blocked:
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="checkbox"
                              className="form-control"
                              id="inp_blocked"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-sm-2"
                        style={{
                          background: "#fff",
                          height: "100%",
                          boxSizing: "border-box",
                          paddingTop: "1%",
                        }}
                      >
                        <input
                          docid="5"
                          type="file"
                          id="img_photoId"
                          name="img"
                          onChange={this.handlePhotoChange}
                          className="hidden"
                          accept="image/*"
                        />
                        <img
                          src={this.state.photoPath}
                          alt="img"
                          onClick={this.handlePhotoClick}
                          height="160"
                          width="160"
                          style={{ width: 160, border: "1px solid #ddd" }}
                          onError={this.onError}
                        />
                      </div>
                    </div>
                    <div className="form-group" style={{ marginTop: 50 }}>
                      <div className="col-sm-2 text-center pull-right">
                        <button
                          type="button"
                          onClick={this.maintainStudent}
                          className="btn btn-info"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                  {/* <div className="col-sm-12 padding-remove"> */}
                  <form id="categoryForm" className="col-sm-12 form-horizontal">
                    <div className="col-sm-12 section1">
                      <div className="">
                        <label className="control-label col-sm-9">
                          Category
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-12 shadowShow  padding-remove">
                      <div
                        className="col-sm-12 form-group categoryWrap"
                        style={{ paddingLeft: 70 + "px" }}
                      >
                        <div className="col-sm-2 checkbox-inline">
                          <input
                            type="checkbox"
                            checked={this.state.scholar}
                            onChange={this.handleCheckboxChange}
                            className="form-check-input"
                            id="scholar"
                          />
                          <label
                            htmlFor="scholar"
                            style={{ paddingLeft: 5 + "px" }}
                          >
                            {" "}
                            Scholar{" "}
                          </label>
                        </div>
                        <div className="col-sm-2 checkbox-inline">
                          <input
                            type="checkbox"
                            checked={this.state.sibling}
                            onChange={this.handleCheckboxChange}
                            className="form-check-input"
                            id="sibling"
                          />
                          <label
                            htmlFor="sibling"
                            style={{ paddingLeft: 5 + "px" }}
                          >
                            {" "}
                            Sibling{" "}
                          </label>
                        </div>
                        <div className="col-sm-2 checkbox-inline">
                          <input
                            type="checkbox"
                            checked={this.state.meritMeans}
                            onChange={this.handleCheckboxChange}
                            className="form-check-input"
                            id="meritMeans"
                          />
                          <label
                            htmlFor="meritMeans"
                            style={{ paddingLeft: 5 + "px" }}
                          >
                            {" "}
                            Merit-cum-Means{" "}
                          </label>
                        </div>
                        <div className="col-sm-2 checkbox-inline">
                          <input
                            type="checkbox"
                            checked={this.state.scSt}
                            onChange={this.handleCheckboxChange}
                            className="form-check-input"
                            id="scSt"
                          />
                          <label
                            htmlFor="scSt"
                            style={{ paddingLeft: 5 + "px" }}
                          >
                            {" "}
                            SC/ST{" "}
                          </label>
                        </div>
                        <div className="col-sm-2 checkbox-inline">
                          <input
                            type="checkbox"
                            checked={this.state.backward}
                            onChange={this.handleCheckboxChange}
                            className="form-check-input"
                            id="backward"
                          />
                          <label
                            htmlFor="backward"
                            style={{ paddingLeft: 5 + "px" }}
                          >
                            Backward
                          </label>
                        </div>
                        <div className="col-sm-1 text-right">
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              this.savecategory(
                                this.state.scholar,
                                this.state.sibling,
                                this.state.meritMeans,
                                this.state.scSt,
                                this.state.backward
                              );
                            }}
                            className="btn btn-info"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <form id="commForm" className="col-sm-12 form-inline">
                    <div className="col-sm-12 section1">
                      <div className="">
                        <label className="control-label col-sm-9">
                          Communication
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-12 shadowShow  padding-remove">
                      <div
                        className="col-sm-12 form-group categoryWrap"
                        style={{ paddingLeft: 50 + "px" }}
                      >
                        {/* <div
                          className="col-sm-2 form-group radioButtonsWrap"
                          style={{ width: "15%" }}
                        >
                          <input
                            type="radio"
                            id="paidByStudent"
                            name="paidBy"
                            value="10"
                            className="form-check-input"
                            checked={this.state.comm == 10}
                            onChange={(e) => this.onChangeRadio(e)}
                          />
                          <label className="" htmlFor="Student">
                            Student
                          </label>
                        </div> */}
                        <div
                          className="col-sm-2 form-group radioButtonsWrap"
                          style={{ width: "15%", marginLeft: 0 }}
                        >
                          <input
                            type="radio"
                            id="paidByFather"
                            name="paidBy"
                            value="20"
                            className="form-check-input"
                            checked={this.state.comm == 20}
                            onChange={(e) => this.onChangeRadio(e)}
                          />
                          <label className="" htmlFor="Father">
                            Father
                          </label>
                        </div>

                        <div
                          className="col-sm-2 form-group radioButtonsWrap"
                          style={{ width: "15%", marginLeft: 0 }}
                        >
                          <input
                            type="radio"
                            id="paidByMother"
                            name="paidBy"
                            value="30"
                            className="form-check-input"
                            checked={this.state.comm == 30}
                            onChange={(e) => this.onChangeRadio(e)}
                          />
                          <label className="" htmlFor="Mother">
                            Mother
                          </label>
                        </div>
                        <div
                          className="col-sm-2 form-group radioButtonsWrap"
                          style={{ width: "15%", marginLeft: 0 }}
                        >
                          <input
                            type="radio"
                            id="paidByGuardian"
                            name="paidBy"
                            value="40"
                            className="form-check-input"
                            checked={this.state.comm == 40}
                            onChange={(e) => this.onChangeRadio(e)}
                          />
                          <label className="" htmlFor="Guardian">
                            Guardian
                          </label>
                        </div>
                        {/* <div
                          className="col-sm-2 form-group radioButtonsWrap"
                          style={{ width: "15%", marginLeft: 0 }}
                        >
                          <input
                            type="radio"
                            id="paidByDonors"
                            name="paidBy"
                            value="50"
                            className="form-check-input"
                            checked={this.state.comm == 50}
                            onChange={(e) => this.onChangeRadio(e)}
                          />
                          <label className="" htmlFor="Donors">
                            Donors
                          </label>
                        </div> */}
                        {/* <div
                          className="col-sm-2 form-group radioButtonsWrap"
                          style={{ width: "15%", marginLeft: 0 }}
                        >
                          <input
                            type="radio"
                            id="paidByOthers"
                            name="paidBy"
                            value="60"
                            className="form-check-input"
                            checked={this.state.comm == 60}
                            onChange={(e) => this.onChangeRadio(e)}
                          />
                          <label className="" htmlFor="Others">
                            Others
                          </label>
                        </div> */}
                        <div className="col-sm-2 text-center pull-right">
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              this.saveFeeEmail(this.state.comm);
                            }}
                            className="btn btn-info"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  {/* </div> */}
                  <div className="col-sm-12 padding-remove">
                    <div
                      className="col-sm-12 section1"
                      style={{ paddingTop: "6px", top: "2px", width: "100%" }}
                    >
                      <div className="">
                        <label className="control-label col-sm-4">
                          Parents/Guardian
                        </label>
                      </div>
                    </div>
                    <form
                      id="parentsForm"
                      className="form-horizontal padding-remove"
                    >
                      <div className="col-sm-4">
                        <div className="col-sm-12 section1">
                          <div className="">
                            <label className="control-label col-sm-4">
                              Father
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12 shadowShow padding-remove">
                          <div className="col-sm-12">
                            <div style={{ display: "none" }}>
                              <label className="control-label col-sm-4">
                                Id:
                              </label>
                              <div className="col-sm-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Father Id"
                                  id="inp_fatherId"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-sm-4">
                                Name:
                              </label>
                              <div className="col-sm-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Father's Name"
                                  id="inp_fathername"
                                />
                              </div>
                            </div>

                            <div className="form-group">
                              <label className="control-label col-sm-4">
                                Phone No:
                              </label>
                              <div className="col-sm-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Phone No"
                                  id="inp_fphone"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-sm-4">
                                Address 1:
                              </label>
                              <div className="col-sm-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Address 1"
                                  id="inp_faddress1"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-sm-4">
                                Address 2:
                              </label>
                              <div className="col-sm-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Address 2"
                                  id="inp_faddress2"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-sm-4">
                                State:
                              </label>
                              <div className="col-sm-7">
                                <select
                                  className="form-control"
                                  id="inp_fstate"
                                  onChange={this.getFatherCityList}
                                ></select>
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-sm-4">
                                City:
                              </label>
                              <div className="col-sm-7">
                                <select
                                  className="form-control"
                                  id="inp_fcity"
                                ></select>
                              </div>
                            </div>

                            <div className="form-group">
                              <label className="control-label col-sm-4">
                                Pin:
                              </label>
                              <div className="col-sm-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Pin"
                                  id="inp_fpin"
                                />
                              </div>
                            </div>

                            <div className="form-group">
                              <label className="control-label col-sm-4">
                                E-Mail Id:
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Email"
                                  id="inp_femail"
                                />
                              </div>
                              <i id="emailStatusIconF" aria-hidden="true"></i>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-sm-4 emailStatusHide">
                                Email Status:
                              </label>
                              <div className="col-sm-2 emailStatusHide">
                                <select
                                  className="form-control"
                                  id="inp_fEmailStatus"
                                  disabled
                                />
                              </div>
                              <span style={{ marginRight: "15px" }}>
                                <a
                                  className="deleteDtls"
                                  id="pDelete"
                                  title="Delete Father Details"
                                  onClick={this.deleteFatherDetails}
                                  href="#!"
                                >
                                  Delete Details
                                </a>
                              </span>

                              <span>
                                <a
                                  className="reverify"
                                  id="fReverify"
                                  title="Reverify Father Email"
                                  onClick={this.reverifyFEmail}
                                  href="#!"
                                >
                                  Reverify
                                </a>
                              </span>
                            </div>
                            {/* <div className="form-group" style={{ marginTop: 50 }}>
                              <div className="col-sm-4 col-centered text-center ">
                                <button
                                  type="button"
                                  // style={{width:"80%"}}
                                  onClick={this.deleteFatherDetails}
                                  className="btn btn-danger"
                                >
                                  Delete Details
                                </button>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="col-sm-12 section1">
                          <div className="">
                            <label className="control-label col-sm-4">
                              Mother
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12 shadowShow padding-remove">
                          <div className="col-sm-12">
                            <div style={{ display: "none" }}>
                              <label className="control-label col-sm-4">
                                Id:
                              </label>
                              <div className="col-sm-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Mother Id"
                                  id="inp_motherId"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-sm-4">
                                Name:
                              </label>
                              <div className="col-sm-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Mother's Name"
                                  id="inp_mothername"
                                />
                              </div>
                            </div>

                            <div className="form-group">
                              <label className="control-label col-sm-4">
                                Phone No:
                              </label>
                              <div className="col-sm-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Phone No"
                                  id="inp_mphone"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-sm-4">
                                Address 1:
                              </label>
                              <div className="col-sm-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Address 1"
                                  id="inp_maddress1"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-sm-4">
                                Address 2:
                              </label>
                              <div className="col-sm-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Address 2"
                                  id="inp_maddress2"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-sm-4">
                                State:
                              </label>
                              <div className="col-sm-7">
                                <select
                                  className="form-control"
                                  id="inp_mstate"
                                  onChange={this.getMotherCityList}
                                ></select>
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-sm-4">
                                City:
                              </label>
                              <div className="col-sm-7">
                                <select
                                  className="form-control"
                                  id="inp_mcity"
                                ></select>
                              </div>
                            </div>

                            <div className="form-group">
                              <label className="control-label col-sm-4">
                                Pin:
                              </label>
                              <div className="col-sm-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Pin"
                                  id="inp_mpin"
                                />
                              </div>
                            </div>

                            <div className="form-group">
                              <label className="control-label col-sm-4">
                                E-Mail Id:
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Email"
                                  id="inp_memail"
                                />
                              </div>
                              <i id="emailStatusIconM" aria-hidden="true"></i>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-sm-4 emailStatusHide">
                                Email Status:
                              </label>
                              <div className="col-sm-2 emailStatusHide">
                                <select
                                  className="form-control"
                                  id="inp_mEmailStatus"
                                  disabled
                                />
                              </div>
                              <span style={{ marginRight: "15px" }}>
                                <a
                                  className="deleteDtls"
                                  id="mDelete"
                                  title="Delete Mother Details"
                                  onClick={this.deleteMotherDetails}
                                  href="#!"
                                >
                                  Delete Details
                                </a>
                              </span>

                              <span>
                                <a
                                  className="reverify"
                                  id="mReverify"
                                  title="Reverify Mother Email"
                                  onClick={this.reverifyMEmail}
                                  href="#!"
                                >
                                  Reverify
                                </a>
                              </span>
                            </div>
                            {/* <div className="form-group" style={{ marginTop: 50 }}>
                              <div className="col-sm-4 col-centered text-center ">
                                <button
                                  type="button"
                                  // style={{width:"80%"}}
                                  onClick={this.deleteMotherDetails}
                                  className="btn btn-danger"
                                >
                                  Delete Details
                                </button>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-4">
                        <div className="col-sm-12 section1">
                          <div className="">
                            <label className="control-label col-sm-4">
                              Guardian
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-12 shadowShow padding-remove">
                          <div className="col-sm-12">
                            <div style={{ display: "none" }}>
                              <label className="control-label col-sm-4">
                                Id:
                              </label>
                              <div className="col-sm-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Guardian Id"
                                  id="inp_guardianId"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-sm-4">
                                Name:
                              </label>
                              <div className="col-sm-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Guardian's Name"
                                  id="inp_guardianname"
                                />
                              </div>
                            </div>

                            <div className="form-group">
                              <label className="control-label col-sm-4">
                                Phone No:
                              </label>
                              <div className="col-sm-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Phone No"
                                  id="inp_gphone"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-sm-4">
                                Address 1:
                              </label>
                              <div className="col-sm-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Address 1"
                                  id="inp_gaddress1"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-sm-4">
                                Address 2:
                              </label>
                              <div className="col-sm-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Address 2"
                                  id="inp_gaddress2"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-sm-4">
                                State:
                              </label>
                              <div className="col-sm-7">
                                <select
                                  className="form-control"
                                  id="inp_gstate"
                                  onChange={this.getGuardianCityList}
                                ></select>
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-sm-4">
                                City:
                              </label>
                              <div className="col-sm-7">
                                <select
                                  className="form-control"
                                  id="inp_gcity"
                                ></select>
                              </div>
                            </div>

                            <div className="form-group">
                              <label className="control-label col-sm-4">
                                Pin:
                              </label>
                              <div className="col-sm-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Pin"
                                  id="inp_gpin"
                                />
                              </div>
                            </div>

                            <div className="form-group">
                              <label className="control-label col-sm-4">
                                E-Mail Id:
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Email"
                                  id="inp_gemail"
                                />
                              </div>
                              <i id="emailStatusIconG" aria-hidden="true"></i>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-sm-4 emailStatusHide">
                                Email Status:
                              </label>
                              <div className="col-sm-2 emailStatusHide">
                                <select
                                  className="form-control"
                                  id="inp_gEmailStatus"
                                  disabled
                                />
                              </div>
                              <span style={{ marginRight: "15px" }}>
                                <a
                                  className="deleteDtls"
                                  id="gDelete"
                                  title="Delete Guardian Details"
                                  onClick={this.deleteGuardianDetails}
                                  href="#!"
                                >
                                  Delete Details
                                </a>
                              </span>

                              <span>
                                <a
                                  className="reverify"
                                  id="gReverify"
                                  title="Reverify Gaurdian Email"
                                  onClick={this.reverifyGEmail}
                                  href="#!"
                                >
                                  Reverify
                                </a>
                              </span>
                            </div>
                            {/* <div className="form-group" style={{ marginTop: 50 }}>
                              <div className="col-sm-4 col-centered text-center ">
                                <button
                                  type="button"
                                  // style={{width:"80%"}}
                                  onClick={this.deleteGuardianDetails}
                                  className="btn btn-danger"
                                >
                                  Delete Details
                                </button>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>

                      <div className="form-group" style={{ marginTop: 50 }}>
                        <div className="col-sm-2 text-center pull-right">
                          <button
                            type="button"
                            onClick={this.maintainParentsInfo}
                            className="btn btn-info"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  `
                  <div className="col-sm-12 padding-remove">
                    <form
                      id="siblingAddForm"
                      className="col-sm-12 form-horizontal"
                    >
                      <div className="col-sm-12 section1">
                        <div className="">
                          <label className="control-label col-sm-4">
                            Siblings
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <div
                          className="col-sm-10 padding-remove"
                          style={{ top: "10px" }}
                        >
                          <table
                            className="tableLayout1"
                            style={{ width: this.state.width1 }}
                          >
                            <thead>
                              <tr>
                                <th style={{ width: "17%" }}>Name</th>
                                <th style={{ width: "17%" }}>DOB</th>
                                <th style={{ width: "17%" }}>Gender</th>
                                <th style={{ width: "17%" }}>Class</th>
                                <th style={{ width: "16%" }}>School</th>
                                <th style={{ width: "16%" }}>Action</th>
                              </tr>
                            </thead>
                          </table>
                          <div className="siblingTableDiv col-sm-12 col-xs-11 padding-remove">
                            <table id="siblingTable" className="tableLayout">
                              <colgroup>
                                <col width="17%" />
                                <col width="17%" />
                                <col width="17%" />
                                <col width="17%" />
                                <col width="16%" />
                                <col width="16%" />
                              </colgroup>
                              <tbody id="siblingTableBody"></tbody>
                            </table>
                          </div>
                        </div>
                        <div className="col-sm-2 col-xs-2">
                          <span
                            id="addSibling"
                            title="Add Sibling"
                            onClick={this.onClickAddSiblingIcon}
                            className="addIcon glyphicon glyphicon-plus-sign"
                          ></span>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-sm-12 padding-remove">
                    <div
                      className="col-sm-12 section1"
                      style={{ width: "100%" }}
                    >
                      <div className="">
                        <label className="control-label col-sm-4">
                          History
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-12" id="History">
                      <div className="col-sm-6 padding-remove">
                        <form
                          id="StudentHistoryForm"
                          className="form-horizontal padding-remove"
                        >
                          <div
                            className="col-sm-12 section1"
                            style={{ marginLeft: "15px" }}
                          >
                            <div className="">
                              <label className="control-label col-sm-4">
                                Student
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-12 padding-remove">
                            <div className="col-sm-12 padding-remove">
                              <div className="form-group">
                                <label className="control-label col-sm-4">
                                  Previous School:
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Previous School"
                                    id="inp_preschool"
                                  />
                                </div>
                              </div>

                              <div className="form-group">
                                <label className="control-label col-sm-4">
                                  Previous Class:
                                </label>
                                <div className="col-sm-6">
                                  <select
                                    className="form-control"
                                    id="inp_preclass"
                                  ></select>
                                </div>
                              </div>

                              <div className="form-group">
                                <label className="control-label col-sm-4">
                                  Previous State:
                                </label>
                                <div className="col-sm-6">
                                  <select
                                    className="form-control"
                                    id="inp_prestate"
                                    onChange={this.getPrevCityList}
                                  ></select>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-sm-4">
                                  Previous City:
                                </label>
                                <div className="col-sm-6">
                                  <select
                                    className="form-control"
                                    id="inp_precity"
                                  ></select>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-sm-4">
                                  Result:
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Result"
                                    id="inp_result"
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-sm-4">
                                  Board:
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Board"
                                    id="inp_board"
                                  />
                                </div>
                              </div>
                              <div
                                className="form-group"
                                style={{ marginTop: 50 }}
                              >
                                <div className="col-sm-10 text-right">
                                  <button
                                    type="button"
                                    onClick={this.maintainStudentHistory}
                                    className="btn btn-info"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>

                      <div className="col-sm-6 padding-remove">
                        <form
                          id="medicalHistoryForm"
                          className="form-horizontal padding-remove"
                        >
                          <div
                            className="col-sm-12 section1"
                            style={{ marginLeft: "15px" }}
                          >
                            <div className="">
                              <label className="control-label col-sm-4">
                                Medical
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-12 padding-remove">
                            <div className="col-sm-12 padding-remove">
                              <div className="form-group">
                                <label className="control-label col-sm-4">
                                  Height:
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Height(cms)"
                                    id="inp_height"
                                  />
                                </div>
                              </div>

                              <div className="form-group">
                                <label className="control-label col-sm-4">
                                  Weight:
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Weight"
                                    id="inp_weight"
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-sm-4">
                                  Blood Group:
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Blood Group"
                                    id="inp_bloodgroup"
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-sm-4">
                                  Disease:
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Disease"
                                    id="inp_disease"
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-sm-4">
                                  Eyes:
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Eyes"
                                    id="inp_eyes"
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label col-sm-4">
                                  Ears:
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ears"
                                    id="inp_ears"
                                  />
                                </div>
                              </div>

                              <div className="form-group">
                                <label className="control-label col-sm-4">
                                  Allergic To:
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Allergic To"
                                    id="inp_allergicto"
                                  />
                                </div>
                                <div className="col-sm-1 text-right">
                                  <button
                                    type="button"
                                    onClick={this.maintainMedicalHistory}
                                    className="btn btn-info"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <StudentDocs
                    studentId={this.state.studentId}
                    lstDocDtls={this.state.lstDocDtls}
                  ></StudentDocs>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="siblingPopup"
          style={{ width: "33%", left: "77%", height: "60%" }}
        >
          <header style={{ width: "90%" }}>
            <h3 id="classHeader">Add/Edit Sibling</h3>
            <a
              href="#0"
              id="hideSiblingSlider"
              className="cd-panel-close glyphicon glyphicon-remove"
              onClick={this.hideSiblingSlider}
            >
              Close
            </a>
          </header>

          <div style={{ width: "90%" }}>                        //sibling slider/popup
            <div
              className="cd-panel-content"
              style={{ padding: "20px 0% 20px" }}
            >
              <div
                id="SiblingSlider"
                className="col-sm-12"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "90%", paddingLeft: 20 }}>
                  <form id="siblingForm" className="form-horizontal">
                    <div className="col-sm-12 padding-remove">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label className="control-label col-sm-5">
                            Name:
                          </label>
                          <div className="col-sm-7 padding-remove">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Name"
                              id="inp_sibling"
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-sm-5" id="">
                            Age:
                          </label>
                          <div className="col-sm-7 padding-remove">
                            <input
                              type="text"
                              className="form-control"
                              id="inp_sage"
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-sm-5">
                            Gender:
                          </label>
                          <div className="col-sm-7 padding-remove">
                            <select
                              className="form-control"
                              id="inp_sgender"
                            ></select>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-sm-5">
                            Class:
                          </label>
                          <div className="col-sm-7 padding-remove">
                            <select
                              className="form-control"
                              id="inp_sclass"
                            ></select>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-sm-5">
                            School:
                          </label>
                          <div className="col-sm-7 padding-remove">
                            <input
                              type="text"
                              className="form-control"
                              id="inp_sschool"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group" style={{ marginTop: 50 }}>
                      <div className="col-sm-11 text-right pull-right">
                        <button
                          type="button"
                          onClick={this.maintainSibling}
                          className="btn btn-info"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default StudentDtls;
