import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
const LblDtPicker = props => {
  // const [state, setState] = useState({ dataValue: '' })
  const [selDt, setSelDt] = useState(null)

  const controller = new AbortController()
  const signal = controller.signal
  useEffect(() => {
    var dt = props.dataValue
    // // console.log('dt2 = ' + dt)
    if (props.dataValue && props.dataValue != '' && props.dataValue != '-') {
      var dt = dt.split('-')
      dt = dt.reverse().join()
      // // console.log('dt2 = ' + dt)
      dt = new Date(dt)
      setSelDt(dt)
    }
    return () => {
      controller.abort()
    }
  }, [props.dataValue])
  function handleChange1 (pDt) {
    // // console.log(pDt)
    // pDt = convert(pDt)
    setSelDt(pDt)
    props.setPDt(convert(pDt))
  }
  function convert (str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2)
    // // console.log([day, mnth, date.getFullYear()].join('-'))
    return [day, mnth, date.getFullYear()].join('-')
  }
  return (
    <div className='row'>
      <label className='control-label col-sm-4'>{props.label}</label>
      <div className='form-group col-sm-8'>
        {/* <input
          type='text'
          placeholder={props.label}
          defaultValue={selDt}
          onChange={handleChange1}
        /> */}
        <DatePicker
          className='form-control text-center'
          placeholderText={props.label}
          selected={selDt}
          onChange={handleChange1}
          dateFormat='dd-MM-yyyy'
          autoComplete='off'
          popperPlacement={
            props.position !== '' ? props.position : 'bottom-end'
          }
        />
      </div>
    </div>
  )
}

export default LblDtPicker
