// src/utils/dateUtils.js

/**
 * Converts a date string to DD-MM-YYYY format.
 * @param {string} str - The date string to convert.
 * @returns {string} The formatted date string.
 */
export const dtToDMY = (str) => {
    const date = new Date(str);
    const mnth = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join('-');
};

/**
 * Converts a DD-MM-YYYY formatted date string to a Date object.
 * @param {string} dateString - The date string in DD-MM-YYYY format.
 * @returns {Date|null} The Date object or null if invalid.
 */
export const dmyToDate = (dateString) => {
    if (!dateString || !/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
        return null;
    }
    const parts = dateString.split('-');
    const [day, month, year] = parts;
    return new Date(year, month - 1, day);
};
