import React, { useState, useEffect } from 'react'
import { getPersonalDtlsSvc, getLstStateSvc, getLstCitySvc, saveTchSvc } from './PersonalDtlsSliderSvc'
import { toast } from 'react-toastify';
import 'common/css/master.css';

const PersonalDtlsSlider = (props) => {
  const [tchId, setTchId] = useState(props.tchId)
  const [flgSliderType, setFlgSliderType] = useState(props.flgSliderType)
  const [name, setName] = useState('');
  const [gender, setGender] = useState('');
  const [mobileNo, setMobileNo] = useState();
  const [email, setEmail] = useState('');
  const [dob, setDob] = useState('');
  const [stateId, setStateId] = useState(0);
  const [lstState, setLstState] = useState([]);
  const [cityId, setCityId] = useState(0);
  const [lstCity, setLstCity] = useState([]);
  const [flgMobileStatus, setFlgMobileStatus] = useState(false);
  const [flgEmailStatus, setFlgEmailStatus] = useState(false);
  const [emailVerifiedOn, setEmailVerifiedOn] = useState('');
  const [mobVerifiedOn, setMobileVerifiedOn] = useState('');

  useEffect(() => {
    setTchId(props.tchId)
    setFlgSliderType(props.flgSliderType)
    if (props.tchId !== 0) {
      getPersonalDtlsSvc(cbPersonalDtlsResponse, props.tchId)
    }
  }, [props.tchId, props.flgSliderType])

  function cbLstStateResponse(data, pStateId) {
    setLstState(data)
    setStateId(pStateId)
  }

  function cbLstCityResponse(data, pCityId) {
    setLstCity(data)
    setCityId(pCityId)
  }

  function cbPersonalDtlsResponse(response) {
    if (response.SvcStatus === "Success") {
      setName(response.personal.name);
      setGender(response.personal.gender);
      setMobileNo(response.personal.mobileNo);
      setEmail(response.personal.email);
      setDob(response.personal.dob);
      setStateId(response.personal.stateId);
      setCityId(response.personal.cityId);
      setEmailVerifiedOn(response.personal.mailVerifiedOn);
      setFlgEmailStatus(response.personal.lgEmailStatus);
      setMobileVerifiedOn(response.personal.mobileVerifiedOn);
      setFlgMobileStatus(response.personal.flgMobileStatus);
      getLstStateSvc(cbLstStateResponse, response.personal.stateId);
      getLstCitySvc(cbLstCityResponse, response.personal.stateId, response.personal.cityId)
    } else {
      toast.error(response.SvcMsg)
    }
  }

  function saveTch() {
    var personal = {};
    personal.tchId = tchId;
    personal.name = name;
    personal.gender = gender;
    personal.mobileNo = mobileNo;
    personal.email = email;
    personal.dob = dob;
    personal.stateId = stateId;
    personal.cityId = cityId;
    saveTchSvc(cbSaveTchResponse, personal)
  }

  function cbSaveTchResponse(data) {
    if (data.status == "Success") {
      props.onGetRandNo()
      toast.success(data.message)
    } else {
      toast.error(data.message)
    }
  }

  return (
    <div className='container'>
      <form>
        <div className='row'>
          <label className="control-label col-sm-2">Name:</label>
          <div className="form-group col-sm-4">
            <input type="text" className="form-control" defaultValue={name} onChange={(event) => setName(event.target.value)} />
          </div>
        </div>
        <div className='row'>
          <label className="control-label col-sm-2">Gender:</label>
          <div className="form-group col-sm-4">
            <input type="text" className="form-control" defaultValue={gender} onChange={(event) => setGender(event.target.value)} />
          </div>
        </div>
        <div className='row'>
          <label className="control-label col-sm-2">Mobile No:</label>
          <div className="form-group col-sm-4">
            <input type="text" className="form-control" defaultValue={mobileNo} onChange={(event) => setMobileNo(event.target.value)} />
          </div>
          <div className='col-sm-2'>
            {/*             <input type='text'>Verify</input>
 */}          </div>
          <div className='col-sm-2'>
            Verified
          </div>
        </div>
        <div className='row'>
          <label className="control-label col-sm-2">E-Mail:</label>
          <div className="form-group col-sm-4">
            <input type="text" className="form-control" defaultValue={email} onChange={(event) => setEmail(event.target.value)} />
          </div>
          <div className='col-sm-2'>
            {/* <input type='url'>Verify</input>*/}
          </div>
          <div className='col-sm-2'>
            Not Verified
          </div>
        </div>
        <div className='row'>
          <label className="control-label col-sm-2">Date of Birth:</label>
          <div className="form-group col-sm-4">
            <input type="text" className="form-control" defaultValue={dob} onChange={(event) => setDob(event.target.value)} />
          </div>
        </div>
        <div className='row'>
          <label className="control-label col-sm-2">State:</label>
          <div className="form-group col-sm-4">
            <select className="form-control" value={stateId} onChange={(event) => setStateId(event.target.value)}>
              <option value={0}>State</option>
              {lstState.map((obj, idx) => {
                return (
                  <option key={idx} value={obj.stateId}>
                    {obj.state}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className='row'>
          <label className="control-label col-sm-2">City:</label>
          <div className="form-group col-sm-4">
            <select className="form-control" value={cityId} onChange={(event) => setCityId(event.target.value)}>
              <option value={0}>City</option>
              {lstCity.map((obj, stateId) => {
                return (
                  <option key={stateId} value={obj.cityId}>
                    {obj.city}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-1  col-xs-1 pull-right'>
            <button type="button" onClick={(e) => saveTch()} style={{ marginTop: '1%' }} className="btn btn-outline-warning text-dark fw-bold btn_primary">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default PersonalDtlsSlider