import axios from 'axios'
import React from 'react'
import lstModules from 'common/json/lstModules.json';
var httpURL = lstModules['fees'].url + ":" + lstModules['fees'].port;
var tokenid = localStorage.getItem('tokenid');

  
function getLstMonthCollectionSvc(callBack) {

    axios(
        {
            url: httpURL+"/sms/fee/dashboard/annual/month_wise_collection",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
        }
    ).catch((error) => {
        console.error(error)
    });
    // callBack(month)

}
function getLstMonthTblSvc(callBack) {
 axios(
        {
            url: httpURL+"/sms/fee/dashboard/annual/month_wise_planned_collection",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
        }
    ).catch((error) => {
        console.error(error)
    });

}
function getLstClassTblSvc(callBack){
        axios(
          {
              url: httpURL+"/sms/fee/dashboard/annual/class_wise_planned_collection",
              method: 'POST',
              params: {},
              headers: { 'tokenid': tokenid },
              withCredentials: true
          }
      ).then(
          (Response) => {
              var data = Response.data
              callBack(data)
          }
      ).catch((error) => {
          console.error(error)
      });
      // callBack(lstClassCollection)

}
function getLstClassCollectionSvc(callBack) {

    axios(
        {
            url: httpURL+"/sms/fee/dashboard/annual/class_wise_collection",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
        }
    ).catch((error) => {
        console.error(error)
    });

}
function getLstLabelCollectionSvc(callBack) {

    axios(
        {
            url: httpURL+"/sms/fee/dashboard/annual/label_wise_collection",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
        }
    ).catch((error) => {
        console.error(error)
    });
}
export {getLstClassCollectionSvc}
export {getLstMonthCollectionSvc}
export {getLstLabelCollectionSvc}
export {getLstMonthTblSvc}
export {getLstClassTblSvc}
