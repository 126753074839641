import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { getTestLeaderboardSvc } from "./WTestProgressSvc.js";


const WTestStudentScoreTable = (props) => {
    const [lstLeaderBoard, setLstLeaderBoard] = useState([])
    const [loader, setLoader] = useState("none")

    useEffect(()=>{
        setLstLeaderBoard(props.lstLeaderBoard)
    },[props.lstLeaderBoard])
    
    const colgrp = (
        <colgroup>
            <col width='5%'></col>
            <col width='7%'></col>
            <col width='15%'></col>
            <col width='12%'></col>
        </colgroup>
    )

    return (
        <div>
             <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }} >
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div >
            <table className='tableLayout1'>
                {colgrp}
                <thead>
                    <tr>
                        <th>Rank</th>
                        <th>Score</th>
                        <th>Student</th>
                        <th>Nickname</th>
                    </tr>
                </thead>
            </table>
            <div className='tablebodyWrapper'>
                <table className='tableLayout'>
                    {colgrp}
                    <tbody id="tbodyStudentScore">
                        {lstLeaderBoard.map((obj, idx) => {
                            return (
                                <tr key={'lb_'+idx}>
                                    <td className="text-left">{obj.rank}</td>
                                    <td className="text-left">{obj.score}</td>
                                    <td className="text-left">{obj.student}</td>
                                    <td className="text-left">{obj.crypticNm}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default WTestStudentScoreTable