import axios from 'axios'
import { toast } from 'react-toastify'
import lstModules from 'common/json/lstModules.json';
var httpURL = lstModules['fees'].url + ":" + lstModules['fees'].port;
var tokenid = localStorage.getItem("tokenid");

function getMonthWiseSummary(callBack){
    axios(
        {
            url: httpURL+"/sms/fee/dashboard/annual/month_wise_collection/summary",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data

            callBack(data)

        }
    ).catch((error) => {
        console.error(error)
    });
}
function getMonthWiseCollection(pMonthId,callBack){

    axios(
        {
            url: httpURL+"/sms/fee/dashboard/annual/month_wise_collection/month",
            method: 'POST',
            params: {monthId:pMonthId},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data

            callBack(data)

        }
    ).catch((error) => {
        console.error(error)
    });
}
export {getMonthWiseSummary};
export {getMonthWiseCollection};