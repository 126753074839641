import React, { useEffect, useState } from 'react';
import { Text, View, Image } from '@react-pdf/renderer';

import { textStyle, headerStyle } from './syllabusPdfStyle';

const HeaderSection = (props) => {
    const { headerSection, examName, className } = props;

    const [schoolName, setSchoolName] = useState(headerSection.school);
    const [schoolAdress, setSchoolAdress] = useState(headerSection.address);
    const [academicYear, setAcademicYear] = useState(headerSection.acdYr);

    useEffect(() => {
        // // console.log("HeadeSection called, headerSection", headerSection);
    }, []);

    return (
        <View style={[headerStyle.header_text_section]}>
            <View style={[headerStyle.schoolSection]}>
                <View style={[headerStyle.header_text]}>
                    <Text style={textStyle.schoolNameText}>
                        {schoolName}
                    </Text>
                </View>
                <View style={[headerStyle.header_text]}>
                    <Text style={textStyle.headerNameText}>
                        {schoolAdress}
                    </Text>
                </View>
                <View style={[headerStyle.header_text]}>
                    <Text style={textStyle.headerNameText}>
                        {academicYear}
                    </Text>
                </View>
            </View>
            <View style={headerStyle.examClass}>
                            <View style={headerStyle.examName}>
                                <Text style={textStyle.examNameText}>
                                    {examName}
                                </Text>
                            </View>
                            <View style={headerStyle.className}>
                                <Text
                                    style={textStyle.classNameText}
                                >
                                    {className}
                                </Text>
                            </View>
                        </View>
        </View>
    )
}

export default HeaderSection;
