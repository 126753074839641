import React, { useEffect, useState } from 'react'

import "common/css/master.css";


const TeacherAllocationTable = (props) => {
    const { lstTeacherAllocation, teacherAllocationId, setTeacherAllocationId, editTeacherAllocation, deleteTeacherAllocation } = props;

    const [lstTeacherAllocationFiltered, setLstTeacherAllocationFiltered] = useState([]);
    const [selTeacherAllocationId, setSelTeacherAllocationId] = useState(0);

    useEffect(() => {
        // console.log("useEffect in TeacherAllocationTable component called, lstTeacherAllocation", lstTeacherAllocation);
        setLstTeacherAllocationFiltered(lstTeacherAllocation);
    }, [lstTeacherAllocation]);

    useEffect(() => {
        // console.log("useEffect in TeacherAllocationTable component called teacherAllocationId", teacherAllocationId);
        setSelTeacherAllocationId(teacherAllocationId);
    }, [teacherAllocationId]);

    const colgrp = <colgroup>
        <col width="80"></col>
        <col width="35"></col>
        <col width="35"></col>
        <col width="35"></col>
        <col width="40"></col>
    </colgroup>



    return (
        <div >
            <div className="col-md-12 col-xs-12 col-centered padding-remove " >
                <table className="tableLayout1" >
                    {colgrp}
                    <thead>
                        <tr>
                            <th >
                                Teacher
                            </th>
                            <th >
                                Max. Allocation
                            </th>
                            <th >
                                Days Off
                            </th>
                            <th >
                                Periods Off
                            </th>
                            <th >
                                Action
                            </th>
                        </tr>
                    </thead>
                </table>
                <div className="tableDiv" style={{ height: "70vh" }}>
                    <table className="tableLayout">
                        {colgrp}
                        <tbody id="diaryMonthlyTeacherAllocationTable" >
                            {lstTeacherAllocationFiltered &&
                                lstTeacherAllocationFiltered.map((teacherAllocation, i) => (
                                    <tr key={teacherAllocation.teacherAllocationId}
                                        onClick={() => setTeacherAllocationId(teacherAllocation.teacherAllocationId)}
                                        className={teacherAllocation.tchId === selTeacherAllocationId ? "selected" : ''}>
                                        <td className="text-left">{teacherAllocation.tch}</td>
                                        <td className="text-center">{teacherAllocation.maxAlloc}</td>
                                        <td className="text-center">{teacherAllocation.daysOff}</td>
                                        <td className="text-center">{teacherAllocation.periodsOff}</td>
                                        <td className="text-center" >
                                            <span
                                                onClick={() => editTeacherAllocation(teacherAllocation)}
                                                title="Edit Teacher Allocation Details"
                                                style={{ textDecoration: "underline", cursor: "pointer", color: "blue", margin: "5px" }}
                                            >
                                                Edit
                                            </span>
                                            {/* <span
                                                onClick={() => deleteTeacherAllocation(teacherAllocation.teacherAllocationId)}
                                                title="Delete TeacherAllocation"
                                                style={{ textDecoration: "underline", cursor: "pointer", color: "blue", margin: "5px" }}
                                            >
                                                Delete
                                            </span> */}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default TeacherAllocationTable;