import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";

const ExamTable = (props) => {
  const [examId, setExamId] = useState(0);
  const [termId, setTermId] = useState(0);
  const [lstExam, setLstExam] = useState([]);

  useEffect(() => {
    if (props.termId) {
      setTermId(props.termId);
      setExamId(props.examId);
      setLstExam(props.lstExam);
    }
  }, [props.termId, props.examId, props.lstExam]);


  const colgrp = (
    <colgroup>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
      <col width="10%"></col>
    </colgroup>
  );

  function editTest(pExamObj) {
    setExamId(pExamObj.examId);
    props.clickExam(pExamObj);
  }
  function addTest() {
    setExamId(0);
    props.clickExam({
      examId: 0,
      exam: "",
      examOrder: "",
      weight: "",
      typeId: 0,
      reportCardId: 0,
      passScore: "",
      maxScore: "",
    });
  }
  return (
    <>
      <div style={{ pointerEvents: props.loader === "none" ? "auto" : "none" }}>
        <ToastContainer autoClose={3000} hideProgressBar />
        <div
          style={{
            position: "fixed",
            zIndex: 10000,
            width: 100,
            height: 100,
            marginLeft: -50,
            marginTop: -50,
            left: "50%",
            top: "50%",
            display: props.loader,
          }}
        >
          <img
            className="loderImg"
            src={require("common/images/loader.gif")}
            alt="Loader..."
          />
        </div>
        <div className="col-sm-11">
          <table className="tableLayout1">
            {colgrp}
            <thead>
              <tr>
                <th>Exam</th>
                <th>Max. Score</th>
                <th>Pass Score</th>
                <th>Type</th>
                <th>Weight</th>
                <th>Report Card</th>
                <th>Order</th>
                <th>Action</th>
              </tr>
            </thead>
          </table>
          <div className="tablebodyWrapper">
            <table className="tableLayout">
              {colgrp}
              <tbody>
                {lstExam.map((test, idx) => {
                  return (
                    <tr
                      onClick={(e) => editTest(test)}
                      className={test.examId === examId ? "selected" : ""}
                      key={idx}
                    >
                      <td className="text-left">{test.exam}</td>
                      <td className="text-right">{test.maxScore}</td>
                      <td className="text-right">{test.passScore}</td>
                      <td className="text-left">{test.type}</td>
                      <td className="text-right">{test.weight}</td>
                      <td className="text-left">{test.reportCard}</td>
                      <td className="text-right">{test.examOrder}</td>
                      <td className="text-center">
                        <span
                          className="glyphicon glyphicon-edit"
                          onClick={(e) => editTest(test)}
                        ></span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-md-1  col-xs-1 pull-right" onClick={addTest}>
        <span className="addIcon glyphicon glyphicon-plus-sign"></span>
      </div>
    </>
  );
};

export default ExamTable;
