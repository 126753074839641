import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import $ from 'jquery';

export default class periodTypeTable extends Component {
    addClassOnBody = (id) => {
        $('#periodTypeTableBody tr#' + id).addClass('selected').siblings().removeClass('selected');
    }
    setPeriodForm = (id) => {
        this.props.setPeriodTypeForm(id);
    }
    deletePeriodType = (id) => {
        this.props.deletePeriodType(id);
    }
    render() {
        return (
            <>
                <table className="tableLayout1" id='customTable'>
                    <thead>
                        <tr>
                            <th width="100" className="text-left">Type</th>
                            <th width="100">Cover Syllabus</th>
                            <th width="100">Assign Teacher</th>
                            <th width="150">Updated On</th>
                            <th width="100" className="text-left">Updated By</th>
                            <th width="100">Action</th>
                        </tr>
                    </thead>
                </table>
                <div className="tableDiv">
                    <table id="periodTypeTable" className="tableLayout">
                        <tbody id="periodTypeTableBody">
                            {this.props.lstPeriodType.map((periodType) => (
                                <tr key={periodType.periodTypeId} id={periodType.periodTypeId} onClick={
                                    () => this.addClassOnBody(periodType.periodTypeId)}>
                                    <td width="100" className="no-display">{periodType.periodTypeId}</td>
                                    <td width="100" className="text-left">{periodType.periodType}</td>
                                    <td width="100" className="text-center">
                                        {periodType.canCoverSyllabus === true ? "Yes" : "No"}
                                    </td>
                                    <td width="100" className="text-center">
                                        {periodType.canAssignTeacher === true ? "Yes" : "No"}
                                    </td>
                                    <td width="150" className="text-center">{periodType.updatedOn}</td>
                                    <td width="100" className="text-left">{periodType.updatedBy}</td>
                                    <td width="100" className="text-center">
                                        <span onClick={() => this.setPeriodForm(periodType.periodTypeId)}
                                            className="glyphicon glyphicon-edit"></span>
                                        <span
                                            onClick={() => this.deletePeriodType(periodType.periodTypeId)}
                                            className="glyphicon glyphicon-trash" ></span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
}
