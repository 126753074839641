import React, { Component } from 'react';

import $ from 'jquery';
import lstMediaType from '../json/lstMediaType.json'

export default class ContentTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: "",
            mediaTypeId: 0,
            sortAsc: true,

        }

    }

    componentDidMount = () => {
        this.fillMediaType();
    }
    fillMediaType() {
        $('#mediaTypeCmb').empty();
        $('#mediaTypeCmb').prepend($("<option></option>").val(0).html("Media Type"));
        for (let i = 0; i < lstMediaType.length; i++) {
            let obj = lstMediaType[i];
            let mediaTypeId = obj.mediaTypeId;
            let mediaType = obj.mediaType;
            $('#mediaTypeCmb').append($("<option> </option").val(mediaTypeId).html(mediaType));
        }
    }
    addClassOnBody = (id) => {
        $('#contentTableBody tr#' + id).addClass('selected').siblings().removeClass('selected');
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.props.filterTable(this.state.content))
    }
    filterWithType = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.props.filterTableWithType(this.state.mediaTypeId))
    }
    sortTable = () => {
        this.setState(prevState => ({
            mediaTypeId: 0,
            sortAsc: !prevState.sortAsc,
        }), () => this.props.sortTable("uploadDttm"))
    }
    render() {
        return (
            <>
                <table className="tableLayout1" id='customTable'>
                    <thead>
                        <tr>
                            <th width="100">
                                <input type="text" className="form-control inp-search"
                                    value={this.state.content}
                                    onChange={this.handleChange}
                                    name="content" id="contentName" placeholder="Name" />
                            </th>
                            <th width="100">
                                <select id="mediaTypeCmb"
                                    className="form-control inp-search"
                                    name="mediaTypeId"
                                    value={this.state.mediaTypeId}
                                    onChange={this.filterWithType}
                                />
                            </th>
                            <th width="150">Updated On
                                <span id="sortingIcon"
                                    onClick={this.sortTable}
                                    className={this.state.sortAsc === true ?
                                        "glyphicon glyphicon-triangle-top" :
                                        "glyphicon glyphicon-triangle-bottom"}
                                    style={{ marginLeft: 10 }}>
                                </span>
                            </th>
                            <th width="100">Action</th>
                        </tr>
                    </thead>
                </table>
                <div className="tableDiv">
                    <table id="contentTable" className="tableLayout">
                        <tbody id="contentTableBody">
                            {this.props.lstContent.map((content) => (
                                <tr key={content.contentId} id={content.contentId} onClick={
                                    () => this.addClassOnBody(content.contentId)}>
                                    <td width="100" className="no-display">{content.contentId}</td>
                                    <td width="100" className="text-left">{content.content}</td>
                                    <td width="100" className="text-left">{content.mediaType}</td>
                                    <td width="150" className="text-center">{content.uploadDttm}</td>
                                    <td width="100" className="text-center">
                                        <span onClick={() => this.props.setContentForm(content.contentId)}
                                            className="glyphicon glyphicon-edit"></span>
                                        <span
                                            onClick={() => this.props.deleteContent(content.contentId)}
                                            className="glyphicon glyphicon-trash" ></span>
                                        <span
                                            onClick={() => this.props.viewContent(content.contentId)}
                                            className="glyphicon glyphicon-eye-open" ></span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
}
