
import { useEffect, useState } from "react";
// import { approve } from "./approveSchools";
import "../css/approveSchoolForm.css";

function ApprovedSchoolForm(props) {
    const { approvedFormData, closeSlider, loader, callApproveSchool, callRejectSchool, lstModule, schoolId } = props;
    const [ldr, setLoader] = useState('none');
    const [lstMdl, setLstMdl] = useState([]);
    const [schoolid, setSchoolId] = useState();
    const [moduleActive, setModuleActive] = useState([]);
    const [customerReason, setCustomerReason] = useState("");
    const [internalNote, setnIternalNote] = useState("");



    useEffect(() => {
        setLoader(loader);
        setLstMdl(lstModule);
        setSchoolId(schoolId);

        const initialActiveModules = lstModule
            .filter((moduleInstance) => moduleInstance.active === true)
            .map((moduleInstance) => moduleInstance.moduleId);

        setModuleActive(initialActiveModules);

    }, [schoolId, lstModule])

    let dataObj = approvedFormData;

    const updateCustomerReason = (e) => {
        setCustomerReason(e.target.value);
    }
    const updateInternalNote = (e) => {
        setnIternalNote(e.target.value);
    }


    const updateModuleActive = (mdlId) => {
        if (moduleActive.includes(mdlId)) {
            const updatedModuleActive = moduleActive.filter((id) => id !== mdlId);
            setModuleActive(updatedModuleActive);
        } else {
            setModuleActive([...moduleActive, mdlId]);
        }
    };



    // // console.log(lstMdl);
    // // console.log(moduleActive);


    return (
        <div className="col-xs-12 col-centered approveSchoolFormWrap ">
            <div className="formPage"  >
                <div className="form-wrapper" style={{ height: '98vh', width: '100%', margin: '5px auto', paddingTop: '50px', background: '#fff', border: '1px solid #ccc', borderRadius: 10, display: 'flex' }}>
                    <form className='form form-horizontal' style={{ pointerEvents: loader === "none" ? "auto" : "none", width: '75%' }} >
                        <div>
                            <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={closeSlider} style={{ right: "70%" }}>
                                <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                            </button>
                            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: ldr }} >
                                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                            </div >
                            <div >
                                <div className="twoCol">
                                    <div className="form-group col-md-6">
                                        <div >
                                            <label className="control-label col-md-5">Status</label>
                                            <div className="col-sm-6 padding-remove" >
                                                <input autoComplete="off" type="text" id="status" className="form-control" value={dataObj.status} disabled />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <div >
                                            <label className="control-label col-md-5" >Email</label>
                                            <div className="col-sm-7 padding-remove" >
                                                <input autoComplete="off" id="email" className="form-control" value={dataObj.email} disabled />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="twoCol">
                                    <div className="form-group col-md-6">
                                        <div >
                                            <label className="control-label col-md-5">Approve By</label>
                                            <div className="col-sm-6 padding-remove" >
                                                <input autoComplete="off" type="text" id="approverName" className="form-control" value={dataObj.approverName} disabled />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <div >
                                            <label className="control-label col-md-5" >Phone No</label>
                                            <div className="col-sm-7 padding-remove" >
                                                <input autoComplete="off" id="phoneNo" className="form-control" value={dataObj.phoneNo} disabled />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="twoCol">
                                    <div className="form-group col-md-6">
                                        <div >
                                            <label className="control-label col-md-5">Requested On</label>
                                            <div className="col-sm-6 padding-remove" >
                                                <input autoComplete="off" type="text" id="requestedOn" className="form-control" value={dataObj.requestedOn} disabled />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <div >
                                            <label className="control-label col-md-5" >Verified On</label>
                                            <div className="col-sm-7 padding-remove" >
                                                <input autoComplete="off" id="approvedOn" className="form-control" value={dataObj.approvedOn} disabled />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="twoCol">
                                    <div className="form-group col-md-6">
                                        <div >
                                            <label className="control-label col-md-5">Registered On</label>
                                            <div className="col-sm-6 padding-remove" >
                                                <input autoComplete="off" type="text" id="registeredON" className="form-control" value={dataObj.registeredON} disabled />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <div >
                                            <label className="control-label col-md-5" >Docs Uploaded On</label>
                                            <div className="col-sm-7 padding-remove" >
                                                <input autoComplete="off" id="docUploadedOn" className="form-control" value={dataObj.docUploadedOn} disabled />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="twoCol">
                                    <div className="form-group col-md-6">
                                        <div >
                                            <label className="control-label col-md-5">School</label>
                                            <div className="col-sm-6 padding-remove" >
                                                <input autoComplete="off" type="text" id="school" className="form-control" value={dataObj.school} disabled />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <div >
                                            <label className="control-label col-md-5" >Students</label>
                                            <div className="col-sm-7 padding-remove" >
                                                <input autoComplete="off" id="noOfStudents" className="form-control" value={dataObj.noOfStudents} disabled />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="twoCol " >

                                    <div className="form-group col-md-6" >


                                        <div >
                                            <label className="control-label col-md-5" >Address</label>
                                            <div className="col-sm-6 padding-remove" >
                                                <textarea id="address" className="form-control" value={dataObj.addr1} disabled />
                                                <input id="state" className="form-control" value={dataObj.state} disabled />
                                                <input id="city" className="form-control" value={dataObj.city} disabled />
                                                <input id="pin" className="form-control" value={dataObj.pin} disabled />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="form-group col-md-12">
                                    <div >
                                        <label className="control-label col-md-3" > Reason for customer<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                        <div className="col-sm-9 padding-remove">
                                            <textarea value={customerReason} onChange={updateCustomerReason} id="customerReason" autoComplete="off" type="text" className="form-control " placeholder="Reason for customer" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-12">
                                    <div >
                                        <label className="control-label col-md-3" > Internal Notes<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                        <div className="col-sm-9 padding-remove">
                                            <textarea id="internalNote" value={internalNote} onChange={updateInternalNote} autoComplete="off" type="text" className="form-control " placeholder="Internal Note" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: '10px' }}>
                            </div>
                        </div>
                        <div className="form-group"  >
                            <div className="col-sm-12 text-center multiButtonWrap approve-reject-buttons" >
                                <div>
                                    <button type="button" style={{ marginTop: 20, width: 100 }} onClick={() => callApproveSchool(internalNote, moduleActive)} className="btn btn-success">Approve</button>
                                </div>
                                <div>
                                    <button type="button" style={{ marginTop: 20, width: 100 }} onClick={() => callRejectSchool(customerReason, document.getElementById('internalNote').value)} className="btn btn-danger">Reject</button>
                                </div>
                            </div>
                            <div>
                                <button type="button" className="btn btn-info text-center " id="closeSlider" onClick={closeSlider}>
                                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div className="module-div" >

                        {/* {lstMdl && <ApprovedModules lstmodule={lstMdl} toggleApproveButton={toggleApproveButton} key={schoolid} schoolid={schoolid} />} */}

                        <div className="moduleList " >

                            {
                                <>

                                    <table>
                                            <thead>
                                                <tr>
                                                    <th width="150">Module Name</th>
                                                    <th width="100">Approved</th>
                                                </tr>
                                            </thead>
                                    </table>
                                    <div className="scrolls">
                                        <table id="moduleTable" >
                                            <tbody style={{textAlign:"center"}}>
                                                {lstMdl.map((moduleInstance) => (
                                                    <tr key={moduleInstance.moduleId} id={moduleInstance.moduleId} >
                                                        {/* <td width="100" className="no-display">{module.moduleId}</td> */}
                                                        <td width="150" className="text-left">{moduleInstance.module}</td>
                                                        <td width="111" ><input
                                                            type='checkbox'
                                                            defaultChecked={moduleInstance.active}
                                                            onClick={e =>
                                                                updateModuleActive(moduleInstance.moduleId)
                                                            }
                                                        /></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            }
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}

export default ApprovedSchoolForm;