import React from 'react';
import axios from "axios";
import lstModules from '../../../common/json/lstModules.json';
import 'react-toastify/dist/ReactToastify.css';

let httpURL = "";
var sysopstokenid = localStorage.getItem("sysopstokenid");
let queryString = require('querystring');
httpURL = lstModules['approveSchool'].url + ":" + lstModules['approveSchool'].port;
// httpURL = "http://192.168.1.15" + ":" + lstModules['approveSchool'].port;

export const getSchoolList = (callback) => {

    var obj = { status: 'Success', message:"" };
    axios({
        url: httpURL + "/sms/sysops/listSchool",
        method: "POST",
        headers: { 'tokenid': sysopstokenid },
        withCredentials: true,
        data: queryString.stringify({ screenId: 0 }),
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.lstSchool = response.data.lstSchool;
        obj.message = response.data.SvcMsg;
        callback(obj);
    }).catch((e) => {
        obj.status = 'Exception';
        obj.message = 'error';
        callback(obj);
    })
}


export const approvedModulesSvc = (schoolId, callback) => {
    var obj = { status: "Success", message:""};
    axios({
        url: httpURL + "/sms/sysops/school/module",
        method:"POST",
        headers: { 'tokenid': sysopstokenid },
        withCredentials: true,
        data: queryString.stringify({ schoolId }),
    }).then((response) => {
        obj.status=response.data.SvcStatus;
        obj.lstModule=response.data.lstModule;
        obj.message=response.statusText;
        callback(obj);
    }).catch((e) => {
        obj.status = 'Exception';
        obj.message = 'error';
        callback(obj);
        
    })
}

export const setApproveSchoolFormSvc = (schoolId, callback) => {
    var obj = { status: 'Success', message:"" };
    axios({
        url: httpURL + "/sms/sysops/schoolDtls",
        method: "POST",
        headers: { 'tokenid': sysopstokenid },
        withCredentials: true,
        data: queryString.stringify({ screenId: 0, schoolId }),
    }).then((response) => {
        obj.status=response.data.SvcStatus;
        obj.approvedFormData= response.data.schoolDtls;
        obj.schoolId= schoolId;
        obj.message= response.data.SvcMsg;
        callback(obj);
    }).catch((e) => {
        obj.status = 'Exception';
        obj.message = 'error';
        callback(obj);
        
    })
}

export const approveSchoolSvc = (note, schoolId, moduleActive, callback) => {
    var obj = { status: 'Success', message:"" };
    var formData = new FormData()
    formData.append('note', JSON.stringify(note))
    formData.append('schoolId', JSON.stringify(schoolId))
    formData.append('lstModule', JSON.stringify(moduleActive))
    axios({
        url: httpURL + "/sms/sysops/approve",
        method: "POST",
        headers: { 'tokenid': sysopstokenid },
        withCredentials: true,
        // data: queryString.stringify({ schoolId, note, lstModule:moduleActive}),
        data: formData, 
    }).then((response) => {
        obj.status=response.data.SvcStatus;
        obj.approvedFormData= response.data.schoolDtls;
        obj.schoolId= schoolId;
        obj.message= response.data.SvcMsg;
        callback(obj);
    }).catch((e) => {
        obj.status = 'Exception';
        obj.message = 'error';
        callback(obj);
        
    })
}


export const rejectSchoolSvc = (rejectReasonCustomer, rejectReasonInternalNotes, schoolId, callback) => {
    var obj = { status: 'Success', message: "" };
    var rejectSchool = { schoolId, rejectReasonCustomer, rejectReasonInternalNotes };
    axios({
        url: httpURL + "/sms/sysops/reject",
        method: "POST",
        headers: { 'tokenid': sysopstokenid },
        withCredentials: true,
        data: queryString.stringify({ rejectSchool: JSON.stringify(rejectSchool), screenId: 0 }),
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.approvedFormData = response.data.schoolDtls;
        obj.schoolId = schoolId;
        obj.message = response.data.SvcMsg;
        callback(obj);
    }).catch((e) => {
        obj.status = 'Exception';
        obj.message = 'error';
        callback(obj);
    })
}
