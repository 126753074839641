import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import Header from "common/js/header/header.js";
import axios from 'axios'
import lstModules from 'common/json/lstModules.json';
import AllowancesSlider from "./allowancesSlider"
import AllowancesTable from "./allowancesTable"
import "../css/payrollSetupCtrl.css"
var tokenid = localStorage.getItem('tokenid');
function PAllowances() {

  const [randNo, setRandNo] = useState(0)
  const [lstAllowance, setLstAllowance] = useState([])
  const [selRow, setSelRow] = useState()
  const [selObj, setSelObj] = useState({})
  const [allowId, setAllowId] = useState(0)
  useEffect(() => {
    // getAllowanceData(cbGetAllowanceData)

  }, []);

  function getSelData(param) {
    setSelObj(param)
    // // console.log(param)
    setAllowId(param.allowanceId)
  }

  const colgrp = (
    <colgroup>
      <col width="4%"></col>
      <col width="4%"></col>
      <col width="4%"></col>
      <col width="15%"></col>
    </colgroup>
  );
  function generateRandomNo() {
    var lRand = Math.floor(Math.random() * 999999);
    setRandNo(lRand)
  }

  function getSelData(param) {
    setSelObj(param)
    setAllowId(param.allowanceId)
  }
  function addNew() {
    setSelRow(null)
    setSelObj({})
    setAllowId(0)
  }
  return (<div>
    <Header></Header>
    <ToastContainer autoClose={3000} hideProgressBar />
    <div className="col-sm-11 col-centered" style={{ paddingTop: '2%' }}>
      <div className="col-sm-8">
        <AllowancesTable
          key={randNo}
          getSelData={getSelData}

        ></AllowancesTable>
        <div className="col-xs-1" onClick={(e) => addNew()}>
          <span id="addReadyId" title="Add" className="addIcon glyphicon glyphicon-plus-sign"></span>
        </div>
      </div>
      <div className="col-sm-4">
        <AllowancesSlider
          key={allowId}
          allowObj={selObj}
          allowId={allowId}
          generateRandomNo={generateRandomNo}
        ></AllowancesSlider>
      </div>

    </div>
  </div >)
}
export default PAllowances;
