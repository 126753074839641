import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios'
import lstModules from 'common/json/lstModules.json';
const httpURL = lstModules['payrollSetup'].url + ':' + lstModules['payrollSetup'].port;
var tokenid = localStorage.getItem('tokenid');
function AdvanceTable(props){
    const [loader, setLoader] = useState('none')
    const [selAdvId,setSelAdvId]=useState()
    const [lstAdvance,setLstAdvance]=useState([])
    useEffect(()=>{
        getAdvanceList(cbGetAdvanceList)
    },[])    

    const tempData=[{
        "advanceId": 1,
        "staffId":1,
        "staff": "Albie",
        "advAmt": "50000",
        "tenure": "4",
        "advMonth": "202304",
        "paybackFrom": "202205",
        "status": 'pending'
      }, {
        "advanceId": 2,
        "staffId":2,
        "staff": "Renaud",
        "advAmt": "65000",
        "tenure": "8",
        "advMonth": "202206",
        "paybackFrom": "202209",
        "status": 'pending'
      }, {
        "advanceId": 3,
        "staffId":3,
        "staff": "Tamera",
        "advAmt": "70000",
        "tenure": "13",
        "advMonth": "202201",
        "paybackFrom": "202206",
        "status": 'pending'
      }, {
        "advanceId": 4,
        "staffId":95,
        "staff": "Fanny",
        "advAmt": "20000",
        "tenure": "2",
        "advMonth": "202201",
        "paybackFrom": "202202",
        "status": 'completed'
      }, {
        "advanceId": 5,
        "staffId":190,
        "staff": "Forster",
        "advAmt": "10000",
        "tenure": "1",
        "advMonth": "202203",
        "paybackFrom": "202204",
        "status": 'in process'
      }]
    function getAdvanceList(cbGetAdvanceList){
      // // console.log(httpURL)
        axios(
            {
                url: httpURL +"/sms/payroll/advance/list",
                method: 'POST',
                params: {},
                headers: { 'tokenid': tokenid },
                withCredentials: true
            }
        ).then(
            (Response) => {
                var data = Response.data
                cbGetAdvanceList(data)
                
      
            }
        ).catch((error) => {
            // // console.log("error" +error)
        });
        // cbGetAdvanceList(tempData)
    }
    function cbGetAdvanceList(data){
        if(data.SvcStatus==='Success'){
            setLstAdvance(data.lstAdvance)
        }
        else{
            toast.error(data.SvcMsg)
        }
        // setLstAdvance(data)
    }

    function handleSelAdvId(obj,id){
        setSelAdvId(id)
        props.getSelObj(obj)
        
    }
    const colgrp = (
    <colgroup>
    <col width="10" />
    <col width="40" />
    <col width="20" />
    <col width="10" />
    <col width="25" />
    <col width="25" />
    </colgroup>
      );
    return (<div className="col-sm-10"style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
    <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
      <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
    </div>
         <ToastContainer autoClose={3000} hideProgressBar />
         <div>
         <table className="tableLayout1">
         {colgrp}
                 <thead>
                     <tr>
                         <th>Adv Id</th>
                         <th>Employee</th>
                         <th>Amount</th>
                         <th>Tenure (mth)</th>
                         <th>Adv Month</th>
                         <th>Ded. Start</th>
                     </tr>
                 </thead>
             </table>
             <div className='tablebodyWrapper'>
                <table className='tableLayout'>
                {colgrp}
                 <tbody>
                 {lstAdvance.map((obj,idx)=>{
                     return (
                        <tr key ={idx}
                        onClick={(e)=>handleSelAdvId(obj,obj.advanceId)}
                        className={obj.advanceId ===selAdvId  ? "selected" : ""}
                        >
                        <td align="center">{obj.advanceId}</td>
                        <td>{obj.staff}</td>
                        <td align="right">{obj.advAmt}</td>
                        <td align="center">{obj.tenure}</td>
                        <td align="center">{JSON.stringify(obj.advMonth).slice(0, 4)}-{JSON.stringify(obj.advMonth).slice(4)}</td>
                        <td align="center">{JSON.stringify(obj.paybackFrom).slice(0, 4)}-{JSON.stringify(obj.paybackFrom).slice(4)}</td>
                    </tr>
                     )
                 })}

                 </tbody>
             </table>
             </div>
         </div>
    </div>)
}
export default AdvanceTable