import React, { useState, useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import Header from 'common/js/header/header.js'
import BillGenerationTable from './BillGenerationTable.js'
import BillGenerationSlider from './BillGenerationSlider.js'
import BillDisplaySlider from './BillDisplaySlider.js'

const BillGenerationCtrl = () => {
  const [loader, setLoader] = useState('none')
  const [selBillGenId, setSelBillGenId] = useState(0)
  const [flgSlider, setFlgSlider] = useState(0)
  const [selCsvMonth, setSelCsvMonth] = useState('-')
  const [selStrMonth, setSelStrMonth] = useState('-')
  const [selCls, setSelCls] = useState(0)
  const [clsId, setClsId] = useState(0);
  const [tableKey, setTableKey] = useState(0)
  const [sliderKey, setSliderKey] = useState(0)

  function generateRandomNo () {
    return Math.floor(Math.random() * 999999)
  }
  function openSlider () {
    setFlgSlider(1)
    setSliderKey(generateRandomNo)
  }
  function closeSlider () {
    setFlgSlider(0)
  }
  function openViewBillSlider (pFlgSlider, pCls, pClsId, cb, pMonth) {
    setFlgSlider(pFlgSlider)
    setSelCsvMonth(pMonth)
    pMonth = cb(pMonth)
    setSelStrMonth(pMonth)
    setSelCls(pCls)
    setClsId(pClsId);
  }
  const getWingId = wingId => {
    // // console.log('wingId = ' + wingId)
  }
  return (
    <div className='MainContainer'>
      <div
        style={{
          position: 'fixed',
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: '50%',
          top: '50%',
          display: loader
        }}
      >
        <img
          className='loderImg'
          src={require('common/images/loader.gif')}
          alt='Loader...'
        />
      </div>
      <Header wingFromChild={getWingId} />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className='col-sm-9 col-centered ' style={{ top: '1%' }}>
        <BillGenerationTable
          key={tableKey}
          setSelCsvMonth={setSelCsvMonth}
          setFlgSlider={setFlgSlider}
          billGenId={selBillGenId}
          setSelBillGenId={setSelBillGenId}
          openViewBillSlider={openViewBillSlider}
        />

        <div
          className='col-sm-1'
          onClick={e => openSlider()}
          title='Generate FeeBook'
        >
          <span
            id='addReadyId'
            title='Add'
            className='addIcon glyphicon glyphicon-plus-sign'
          ></span>
        </div>
        <div
          style={{ display: flgSlider == 1 ? 'block' : 'none', width: '30%' }}
          className='SlotSlider'
        >
          <button
            type='button'
            className='btn btn-info text-center btnCloseDiv'
            onClick={e => closeSlider()}
            style={{ right: '31%' }}
          >
            <span
              id='close'
              title='Close Slider'
              className='glyphicon glyphicon-remove'
            ></span>
          </button>

          <div
            className='col-sm-12'
            style={{
              pointerEvents: loader === 'none' ? 'auto' : 'none',
              height: '100%',
              margin: '0 auto',
              background: '#fff',
              border: '1px solid #ccc',
              borderRadius: 10
            }}
          >
            <BillGenerationSlider setTableKey={setTableKey} key={sliderKey} />
          </div>
        </div>

        <div
          style={{ display: flgSlider == 2 ? 'block' : 'none' }}
          className='SlotSlider'
        >
          <button
            type='button'
            className='btn btn-info text-center btnCloseDiv'
            onClick={e => closeSlider()}
          >
            <span
              id='close'
              title='Close Slider'
              className='glyphicon glyphicon-remove'
            ></span>
          </button>

          <div
            className='col-sm-12'
            style={{
              pointerEvents: loader === 'none' ? 'auto' : 'none',
              height: '100%',
              margin: '0 auto',
              background: '#fff',
              border: '1px solid #ccc',
              borderRadius: 10
            }}
          >
            <BillDisplaySlider
              selBillGenId={selBillGenId}
              selStrMonth={selStrMonth}
              selCls={selCls}
              clsId={clsId}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BillGenerationCtrl
