import React, { useState, useEffect } from 'react'
import SessionTab from './sessionTab';

const HeaderSession = (props) => {

    const [lstSession, setLstSession] = useState(props.lstSession);

    useEffect(()=> {
        // // console.log("useEffect called in HeaderSession");
        // // console.log("props.lstSession",props.lstSession);
    },[])
    return (
        <div className="wrapper-box" style={{ height: '10vh', boxShadow: 'rgba(64, 60, 67, 0.16) 0px 2px 5px 1px', width: 'auto', marginBottom: 10 }}>
            {lstSession && lstSession.map((session, i) => {
                return <SessionTab key={i} session={session} len={lstSession.length} clickSessionTab={props.clickSessionTab} />
            })}
        </div>
    )
}

export default HeaderSession
