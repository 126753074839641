import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import 'common/css/master.css';
import SmsTd from 'common/components/smsTd.js';
import CELL_TYPES from "common/constants/cellType";
import $ from 'jquery';

const feeDueTable = (props) => {
    const addClassOnBody = (id) => {
        $('#periodTypeTableBody tr#' + id).addClass('selected').siblings().removeClass('selected');
    }
    const myfunc = (feedue) => {
        props.showSlider();
        // // console.log(JSON.stringify(feedue) + feedue.wingId + feedue.wing)
        props.setForm(feedue, feedue.wingId, feedue.wing);
    }
    const getVal = (val) => {
        if (val === 99) {
            return "Last Day of Month";
        }
        return val;
    }
    const getCellTypeByVal = (val) => {
        if (val === 99) {
            return CELL_TYPES.STRING;
        }
        return CELL_TYPES.NUMBER;
    }
    return (
        <>
            <div className="col-md-12">
                <div className="col-md-10 col-md-offset-1" style={{ marginTop: 15 + 'px' }}>
                    <table className="tableLayout1" id='customTable'>
                        <thead>
                            <tr>
                                <th className="text-left" style={{ width: 40 + '%' }}>Wings</th>
                                <th style={{ width: 40 + '%' }}>Fees Due</th>
                                <th style={{ width: 40 + '%' }}>Reminder-1</th>
                                <th style={{ width: 40 + '%' }}>Reminder-2</th>
                                <th style={{ width: 40 + '%' }}>Overdue Notice-1</th>
                                <th style={{ width: 40 + '%' }}>Overdue Notice-2</th>
                                <th style={{ width: 40 + '%' }}>Action</th>


                            </tr>
                        </thead>
                    </table>

                    <div className="tableDiv" style={{ height: 500 + 'px' }}>
                        <table id="periodTypeTable" className="tableLayout">
                            <tbody id="periodTypeTableBody">
                                {props.lstMonthlydata.map((feedue) => (
                                    <tr data={JSON.stringify(feedue)} key={feedue.wingId} id={feedue.wingId}>
                                        <SmsTd br='40%' className="text-center" cellType={CELL_TYPES.STRING} val={feedue.wing} />
                                        <SmsTd br='40%' className="text-center" cellType={CELL_TYPES.STRING} val={getVal(feedue.feeDue)} />
                                        <SmsTd br='40%' className="text-center" cellType={CELL_TYPES.STRING} val={getVal(feedue.reminder1)} />
                                        <SmsTd br='40%' className="text-center" cellType={CELL_TYPES.STRING} val={getVal(feedue.reminder2)} />
                                        <SmsTd br='40%' className="text-center" cellType={CELL_TYPES.STRING} val={getVal(feedue.overDueNotice1)} />
                                        <SmsTd br='40%' className="text-center" cellType={CELL_TYPES.STRING} val={getVal(feedue.overDueNotice2)} />
                                        <td style={{ width: 40 + '%' }} className="text-center">
                                            <span onClick={() => { props.showSlider(); props.setForm(feedue); }}
                                                title="edit" className="glyphicon glyphicon-edit"></span>
                                            {/* <span
                                                onClick={() => { props.deleteFeeDues(feedue.wingId) }}
                                                className="glyphicon glyphicon-trash" ></span> */}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default feeDueTable;