import React, { useState, useEffect } from "react";
import LblTxt from "./LblTxt";
import LblDtPicker from "common/components/LblDtPicker";
import DropDown from "./DropDown";
import AnchorLbl from "./AnchorLbl";
import RdoLbl from "./RdoLbl";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import lstModules from "common/json/lstModules.json";
var tokenid = localStorage.getItem("tokenid");
var httpURL = lstModules["enquiry"].url + ":" + lstModules["enquiry"].port;
var httpURLCommon = lstModules["main"].url + ":" + lstModules["main"].port;
var tokenid = localStorage.getItem("tokenid");
// var regsId = localStorage.getItem('registrationId')
// var userId = localStorage.getItem('pUserId')

const RegistrationPersonal = (props) => {
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [regsDt, setRegsDt] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [flgEmailVerify, setFlgEmailVerify] = useState(false);
  const [address1, setAddress1] = useState("");
  const [stateId, setStateId] = useState(0);
  const [cityId, setCityId] = useState(0);
  const [pin, setPin] = useState("");
  const [lstState, setLstState] = useState([]);
  const [lstGender, setLstGender] = useState([{genderId:'M',gender:'Male'},{genderId:'F',gender:'Female'}]);
  const [lstCity, setLstCity] = useState([]);
  const [lstLocality, setLstLocality] = useState([]);
  const [localityId, setLocalityId] = useState(0);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [locality, setLocality] = useState("");
  const [photoPath, setPhotoPath] = useState(props.photoPath);
  const [loader, setLoader] = useState("none");
  const [regsId, setRegsId] = useState(0);
  const [userId, setUserId] = useState(0);
  const [enqId, setEnqId] = useState(0);
  const [isMounted, setMounted] = useState(false);
  const controller = new AbortController();
  const signal = controller.signal;

  function cbPersonalDtlsResponse(obj) {
    if (obj.SvcStatus === "Success") {
      setName(obj.personal.name);
      setDob(obj.personal.dob);

      setGender(obj.personal.gender);
      setRegsDt(obj.personal.regsDt);
      setEmail(obj.personal.email);
      setFlgEmailVerify(obj.personal.flgEmailVerify);
      setAddress1(obj.personal.address1);
      setContact(obj.personal.contact);
      setStateId(obj.personal.stateId);
      setState(getStateFromStateList(obj.personal.stateId));
      getCityListSvc(cbCityListResponse, obj.personal.stateId);
      setCityId(obj.personal.cityId);
      setCity(getCityFromCityList(obj.personal.cityId));
      getLocalityListSvc(cbLocalityListResponse, obj.personal.cityId);
      setLocalityId(obj.personal.localityId);
      setLocality(getLocalityfromLocalityList(obj.personal.localityId));
      setPin(obj.personal.pin);
    } else {
      toast.error(obj.SvcMsg);
    }
    setLoader("none");
  }

  function getPersonalDtlsSvc(pRegsId, pUserId, pEnqId, callBack) {
    axios({
      url: httpURL + "/sms/regs/personal/dtls",
      method: "POST",
      params: { regsId: pRegsId, userId: pUserId, enqId: pEnqId },
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        var ourData = Response.data;
        callBack(ourData);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const savePersonal = async () => {
    // var enqId = await localStorage.getItem('enquiryId')
    // // console.log("Personal:" + enqId);
    var personal = {
      regsId: !regsId ? 0 : regsId,
      enqId: enqId,
      name: name,
      dob: dob,
      gender: gender,
      regsDt: regsDt,
      email: email,
      contact: contact,
      address1: address1,
      // address2: address2,
      stateId: stateId,
      cityId: cityId,
      localityId: localityId,
      pin: pin,
      userId: userId,
      blocked: false,
      flgEmailVerify: true,
    };
    setLoader("block");
    savePersonalDtlsSvc(personal, cbSavePersonalDtlsResponse);
  };
  async function cbSavePersonalDtlsResponse(data) {
    if (data.SvcStatus === "Success") {
      if (!regsId) {
        await setRegsId(data.regsId);
        await props.setCtrlRegId(data.regsId);
      } else {
        await props.setCtrlRegId(regsId);
      }
      toast.success(data.SvcMsg);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function savePersonalDtlsSvc(personalDtls, callBack) {
    personalDtls = JSON.stringify(personalDtls);
    axios({
      url: httpURL + "/sms/regs/personal/save",
      method: "POST",
      params: { personalDtls: personalDtls },
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        // // console.log(Response.data);
        var ourData = Response.data;

        callBack(ourData);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getStateListSvc(callBack) {
    axios({
      url: httpURLCommon + "/sms/mst/state/list",
      method: "POST",
      params: {},
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        var ourData = Response.data;

        callBack(ourData);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getCityListSvc(callBack, pstateId) {
    axios({
      url: httpURLCommon + "/sms/mst/city/list",
      method: "POST",
      params: { stateId: pstateId },
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        var ourData = Response.data;
        callBack(ourData);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function getLocalityListSvc(callBack, pcityId, pLocalityId) {
    axios({
      url: httpURLCommon + "/sms/mst/locality/list",
      method: "POST",
      params: { cityId: pcityId },
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        // // console.log(Response.data);
        var ourData = Response.data;
        callBack(ourData, pLocalityId);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function cbStateListResponse(pLstState) {
    if (pLstState.SvcStatus === "Success") {
      setLstState(pLstState.lstState);
    } else {
      toast.error(pLstState.SvcMsg);
    }
    setLoader("none");
  }

  function cbCityListResponse(pLstCity) {
    if (pLstCity.SvcStatus === "Success") {
      setLstCity(pLstCity.lstCity);
    } else {
      toast.error(pLstCity.SvcMsg);
    }
    setLoader("none");
  }

  function cbLocalityListResponse(pLstLocality, pLocalityId) {
    if (pLstLocality.SvcStatus === "Success") {
      setLstLocality(pLstLocality.lstLocality);
      // setLocalityId(pLocalityId)
    } else {
      toast.error(pLstLocality.SvcMsg);
    }
    setLoader("none");
  }

  function getStateFromStateList(pStateId) {
    for (var i = 0; i < lstState.length; i++) {
      var obj = lstState[i];
      if (pStateId === obj.stateId) {
        return obj.state;
      }
    }
    return "null";
  }

  function getCityFromCityList(pCityId) {
    for (var i = 0; i < lstCity.length; i++) {
      var obj = lstCity[i];
      if (pCityId === obj.cityId) {
        return obj.city;
      }
    }
    return "null";
  }
  function getLocalityfromLocalityList(pLocalityId) {
    for (var i = 0; i < lstLocality.length; i++) {
      var obj = lstLocality[i];
      if (pLocalityId === obj.localityId) {
        return obj.locality;
      }
    }
    return "null";
  }
  useEffect(() => {
    // // console.log( "useEffect Personal Called " + props.regsId, +"====>" + parseInt(props.userId), +";" + props.enqId );
    setMounted(true);
    setUserData(props.regsId, props.userId, props.enqId);
    setLoader("block");
    getStateListSvc(cbStateListResponse);
    getPersonalDtlsSvc(
      props.regsId,
      props.userId,
      props.enqId,
      cbPersonalDtlsResponse
    );

    return () => {
      controller.abort();
      setMounted(false);
    };
  }, [props.regsId, props.userId, props.enqId]);

  useEffect(() => {
    setPhotoPath(props.photoPath);
  }, [props.photoPath]);

  function setUserData(pRegsId, pUserId, pEnqId) {
    setRegsId(pRegsId);
    setUserId(pUserId);
    setEnqId(pEnqId);
  }

  function handleStateChange(pStateId) {
    setStateId(pStateId);
    getCityListSvc(cbCityListResponse, pStateId);
  }
  function handleCityChange(pCityId, pLocalityId) {
    setCityId(pCityId);
    getLocalityListSvc(cbLocalityListResponse, pCityId, pLocalityId);
  }
  function handleLocalityChange(pLocalityId) {
    setLocalityId(parseInt(pLocalityId));
  }
  function onError() {
    setPhotoPath(window.appURL + "/no-image.png");
  }
  const calcAge = (dob) => {
    // // console.log(dob);
    if (typeof dob == "object") dob = formatDate(dob);
    let dd = dob.split("-")[0];
    let mm = dob.split("-")[1];
    let yr = parseInt(dob.split("-")[2]);
    dob = new Date(yr, mm, dd, 0, 0);
    let d2 = new Date();
    let diff = d2.getTime() - dob.getTime();
    let yrs = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));

    // let age = parseInt(new Date().getFullYear())-dob.getTime();
    setAge(yrs);
  };
  const formatDate = (date) => {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("-");
  };
  const handlePhotoChange = (e) => {
    // // console.log(e.target.files[0].name);
    // // console.log("src:" + URL.createObjectURL(e.target.files[0]));
    if (e.target.files && e.target.files[0]) {
      setPhotoPath(URL.createObjectURL(e.target.files[0]));
      setLoader("block");
      saveImgIdSvc(e.target.files[0], 50, cbSaveImgIdResponse);
    }
  };
  const handlePhotoClick = (e) => {
    // // console.log(document.getElementById("img_photoId"));
    // document.getElementById("img_photoId").dispatchEvent(new Event('click'));
    document.getElementById("img_photoId").click();
  };
  function saveImgIdSvc(file, type, callBack) {
    var formData = new FormData();
    formData.append("regsId", regsId);
    formData.append("file", file);
    formData.append("typeId", type);

    axios({
      url: httpURL + "/sms/regs/docs/save",
      method: "POST",
      data: formData,
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((response) => {
        callBack(response.data);
      })
      .catch((e) => {
        console.error("Service failed " + e);
      });
  }
  function cbSaveImgIdResponse(data) {
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  return (
    <>
      <div
        className="col-sm-10"
        style={{
          height: "100%",
          float: "left",
          background: "#fff",
          paddingTop: "1%",
        }}
      >
        <div className="col-sm-12 padding-remove">
          <div
            className="col-sm-12"
            style={{ pointerEvents: loader === "none" ? "auto" : "none" }}
          >
            <ToastContainer autoClose={3000} hideProgressBar />

            <div
              style={{
                position: "fixed",
                zIndex: 10000,
                width: 100,
                height: 100,
                marginLeft: -50,
                marginTop: -50,
                left: "50%",
                top: "50%",
                display: loader,
              }}
            >
              <img
                className="loderImg"
                src={require("common/images/loader.gif")}
                alt="Loader..."
              />
            </div>
            <div className="col-sm-5" style={{ background: "transparent" }}>
              <LblTxt
                label="Name:"
                dataType="text"
                dataValue={name}
                onChange={setName}
              ></LblTxt>
              <LblDtPicker
                label="Date of Birth:"
                dataType="text"
                dataValue={dob}
                setPDt={setDob}
                position="bottom-start"
              ></LblDtPicker>
              {/* <LblDro
                label='Gender:'
                dataType='text'
                dataValue={gender}
                onChange={setGender}
              ></LblDro>  */}
               <DropDown
                label="Gender:"
                onChangeDropDown={setGender}
                option1="Gender"
                key={gender}
                lst={lstGender}
                dataValue={gender}
                keyId="genderId"
                keyVal="gender"
              ></DropDown>
             {/* <div className="row">
                <label className="control-label col-sm-4">Gender</label>
                <RdoLbl
                  label="Male"
                  key="Male"
                  dataValue="M"
                  checked={gender === "M"}
                  onChange={setGender}
                ></RdoLbl>
                 <RdoLbl
                  label="Female"
                  key="Female"
                  dataValue="F"
                  checked={gender === "F"}
                  onChange={setGender}
                ></RdoLbl>
              </div> */}

              <LblDtPicker
                label="Registration Date:"
                dataType="text"
                dataValue={regsDt}
                setPDt={setRegsDt}
                position="right-start"
              ></LblDtPicker>
              <LblTxt
                label="Contact:"
                dataType="text"
                dataValue={contact}
                onChange={setContact}
              ></LblTxt>
              <LblTxt
                label="Email:"
                dataType="text"
                dataValue={email}
                onChange={setEmail}
              ></LblTxt>
              <AnchorLbl
                dataValue={flgEmailVerify}
                anchorLabel="Reverify"
              ></AnchorLbl>
            </div>
            <div
              className="col-sm-offset-1 col-sm-5"
              style={{ background: "transparent" }}
            >
              <LblTxt
                label="Address1:"
                dataType="text"
                dataValue={address1}
                onChange={setAddress1}
              ></LblTxt>
              <DropDown
                label="State:"
                onChangeDropDown={handleStateChange}
                option1="State"
                key={stateId}
                lst={lstState}
                dataValue={stateId}
                keyId="stateId"
                keyVal="state"
              ></DropDown>
              <DropDown
                label="City:"
                onChangeDropDown={handleCityChange}
                option1="City"
                key={stateId + "_" + cityId}
                lst={lstCity}
                dataValue={cityId}
                keyId="cityId"
                keyVal="city"
              ></DropDown>
              <DropDown
                label="Locality:"
                onChangeDropDown={handleLocalityChange}
                option1="Locality"
                key={"c_" + cityId + "" + localityId}
                lst={lstLocality}
                dataValue={localityId}
                keyId="localityId"
                keyVal="locality"
              ></DropDown>
              {/* <LblTxt label='City:' dataType='text' dataValue={city}></LblTxt> */}
              <LblTxt
                label="Pin:"
                dataType="text"
                dataValue={pin}
                onChange={setPin}
              ></LblTxt>
            </div>
          </div>
          <div className="col-sm-11">
            <button
              type="button"
              className="btn-info btn pull-right"
              onClick={(e) => savePersonal()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <div
        className="col-sm-2"
        style={{
          background: "#fff",
          height: "100%",
          boxSizing: "border-box",
          paddingTop: "1%",
        }}
      >
        <input
          docid="5"
          type="file"
          id="img_photoId"
          name="img"
          onChange={handlePhotoChange}
          className="hidden"
          accept="image/*"
        />
        <img
          src={photoPath}
          alt="img"
          onClick={(e) => handlePhotoClick(e)}
          style={{ height: 160, width: 160, border: "1px solid #ddd" }}
          onError={(e) => onError()}
        />
      </div>
    </>
  );
};

export default RegistrationPersonal;
