import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['performance'].url + ':' + lstModules['performance'].port;

var tokenid = localStorage.getItem("tokenid");
const academicYearId = localStorage.getItem('academicYearId') || 0;


const getTermReportSvc = (termId, sectionId, syllabusId, callback) => {
    // // console.log("getTermReportSvc called, monthId", monthId);
    var formData = new FormData()
    formData.append("sectionId", sectionId);
    formData.append("termId", termId);
    formData.append("syllabusId", syllabusId);
    formData.append("acdYrId", academicYearId);
    var obj = { status: "Success", message: "" }
    axios({
        url: httpURL + '/sms/reportcard/termreportcard/generate/cls_section',
        method: "POST",
        headers: { 'tokenid': tokenid },
        data: formData,
        withCredentials: true,
    }).then((response) => {
        // // console.log('response in getTermReportSvc', response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstReportCardObj = response.data.lstReportCardObj;
            // // console.log("response in getTermReportSvc", response);
        }
        callback(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        callback(obj);
    });
}

function getLstTermSvc(callback) {
    var obj = { status: "Success", message: "", lstTerm: [] }
    axios({
        url: httpURL + '/sms/performance/mst/term/list',
        method: "POST",
        params: {},
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstTerm = response.data.lstTerm;
        }
        // // console.log('data.SvcStatus=' + response.data.SvcStatus);
        callback(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        callback(obj);
    });
}


function getLogoSvc(callback) {

    var xhr = new XMLHttpRequest();
    xhr.open("GET", httpURL + '/sms/school/profile/logo/view', true);
    xhr.setRequestHeader('tokenid', tokenid);

    xhr.responseType = "arraybuffer";

    xhr.onload = function (e) {
        var arrayBufferView = new Uint8Array(this.response);
        var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(blob);
        // // console.log("imageUrl in getLogoSvc", imageUrl);
        callback(imageUrl);
    };
    xhr.send();
}

function getClassData(setData) {
    var status = 'Success'
    var obj = { status: status };

    // // console.log(httpURL)
    axios({
        url: httpURL + '/sms/performance/mst/all_section_list',
        method: "POST",
        // params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.message = response.data.SvcMsg;
        obj.status = response.data.SvcStatus;
        if (response.data.SvcStatus === "Success") {
            obj.lstAllClassSections = response.data.lstAllClassSections;
            // // console.log(obj);
        }
        setData(obj)
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        setData(obj)
    });
}

function getPrinciSignSvc(cb) {

    var xhr = new XMLHttpRequest();
    xhr.open("GET", httpURL + '/sms/mst/sign/view?roleId=24', true);
    xhr.setRequestHeader('tokenid', tokenid);

    xhr.responseType = "arraybuffer";

    xhr.onload = function (e) {
        var arrayBufferView = new Uint8Array(this.response);
        var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(blob);
        // // console.log("imageUrl in getPrinciSignSvc", imageUrl);
        cb(imageUrl);
    };
    xhr.send();

}
export { getLstTermSvc, getLogoSvc, getClassData, getPrinciSignSvc, getTermReportSvc }