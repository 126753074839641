
import axios from "axios";
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['parentAppLogin'].url + ":" + lstModules['parentAppLogin'].port;
const queryString = require("querystring");
const screenId = 11;
const tokenid = localStorage.getItem("tokenid");


export const saveStudentSvc = (lstAccess, cbSaveStudent) => {
    const obj = { status: "", message: "" };
    const formData = new FormData;
    formData.append("lstAccess", JSON.stringify(lstAccess));
    axios({
        url: httpURL + '/sms/pa/access/save',
        method: "POST",
        headers: { 'tokenid': tokenid },
        data: formData,  // Send data in the request body
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbSaveStudent(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbSaveStudent(obj);
    })
}