import { useState } from "react";
import { FaStar } from "react-icons/fa";
import { Radio, Rating } from "../RatingStyles.js";
import { useLocation } from 'react-router-dom'
import { red } from "@mui/material/colors";

const FeedbackField = (props) => {


    const { label, rate, setRate, feedback, setFeedback, formDisabled } = props;


    const search = useLocation().search

    return (
        <div >
            {/* <div style={{ display: "flex", justifyContent: "space-between", margin:0, padding:0, width:"100%" }}> */}
            <div >
                <div >
                    <div className="col-xs-8" style={{ padding: 0, margin:0 }}>
                        <label >{label}</label>
                    </div>
                    <div style={{ padding:0, margin:0}}>
                        {[...Array(5)].map((star, index) => {
                            // index += 1;
                            const givenRating = index + 1;
                            return (


                                // <div  >
                                <label style={{ width: "6%", margin:0}}>
                                    <Radio
                                        style={{ padding:0, margin:0}}
                                        type="radio"
                                        defaultValue={rate}
                                        disabled={formDisabled}
                                        onChange={() => {
                                            setRate(givenRating);
                                        }}
                                    />
                                    <Rating >
                                        <FaStar

                                            color={
                                                givenRating < rate || givenRating === rate
                                                    ? "FF9529"
                                                    : "rgb(192,192,192)"
                                            }
                                            style={{ fontSize: "30px", padding: "0px 0px 0px 10px", margin:0 }}
                                        />
                                    </Rating>
                                </label>
                            );
                        })}
                    </div>
                </div>
                <div>
                    <textarea
                        rows="3"
                        cols="50"
                        className="col-xs-3 form-control"
                        style={{ margin: "0 0 25px 0", padding: 0, width: "100%", backgroundColor:"#F2F3F4" }}
                        placeholder={"Enter your " + label + " feedback here"}
                        value={feedback !== "-" ? feedback : ""}
                        onChange={(e) => setFeedback(e.target.value)}
                        disabled={formDisabled}
                    ></textarea>
                </div>
            </div>
        </div>
    )
}

export default FeedbackField;