import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { getMonthWiseSummary } from "./ActualCollectionSvc";
const MonthWiseSummary = (props) => {
    const [loader, setLoader] = useState('none')
    const [lstMonthWiseSummary, setLstMonthWiseSummary] = useState([])
    var mTotal = 0;
    useEffect(() => {
        setLoader("block")
        getMonthWiseSummary(cbMonthWiseSummary)
    }, [])
    function cbMonthWiseSummary(data) {
        setLoader("none")
        if (data.SvcStatus === 'Success') {
            setLstMonthWiseSummary(data.lstCollection)
        }
        else {
            toast.error(data.SvcMsg)
        }
    }
    function setSelected(monthId, month) {
        props.setSelMonthId(monthId)
        props.setSelMonth(month)
    }

    return (
        <div>
            <div id='tableHead' className="col-sm-4 col-centered">
                <> Billed v/s Collection</>
            </div>

            <div>
                <div className='tableLayout'>
                    <div className='col-sm-1 month-box padding-remove' >
                        <div style={{ background: "rgb(213, 223, 233)", fontWeight: "bold" }}>Collection</div>
                        <div>Billed</div>
                        <div>Collection</div>
                        <div style={{borderBlock:"inherit"}}>Difference</div>
                    </div>
                    {
                        lstMonthWiseSummary.map((actual, idx) => {
                            return <div className={actual.monthId === props.selMonthId ? 'selectedG col-sm-1 month-box padding-remove' : 'col-sm-1 month-box padding-remove'}
                                onClick={(e) => setSelected(actual.monthId, actual.month)}
                                key={idx} >
                                <div style={{background:"rgb(213, 223, 233)", fontWeight:"bold"}}>{actual.month}</div>
                                <div>{Number(actual.expected).toLocaleString("en-in")}</div>
                                <div>{Number(actual.actual).toLocaleString("en-in")}</div>
                                <div style={{borderBlock:"inherit"}}>{Number(actual.difference).toLocaleString("en-in")}</div>

                            </div>
                        })}
                    <div className="col-sm-1 month-box padding-remove" style={{ fontWeight: "bold" }}>
                        <div style={{ background: "rgb(213, 223, 233)" }}>Total</div>
                        <div>
                            {lstMonthWiseSummary.map((obj2, idx2) => { mTotal = mTotal + obj2.expected })}{Number(mTotal).toLocaleString("en-in")}
                        </div>
                        <div style={{ display: 'none' }}>{mTotal = 0}</div>
                        <div>
                            {lstMonthWiseSummary.map((obj2, idx2) => { mTotal = mTotal + obj2.actual })}{Number(mTotal).toLocaleString("en-in")}
                        </div>
                        <div style={{ display: 'none' }}>{mTotal = 0}</div>
                        <div style={{borderBlock:"inherit"}}>
                            {lstMonthWiseSummary.map((obj2, idx2) => { mTotal = mTotal + obj2.difference })}{Number(mTotal).toLocaleString("en-in")}
                        </div>
                        <div style={{ display: 'none' }}>{mTotal = 0}</div>
                    </div>

                </div>

            </div>
        </div>
    )
}
export default MonthWiseSummary;