import React, { useEffect, useState } from 'react';
import { Document, Page, PDFViewer, View, Text } from '@react-pdf/renderer';

import { pageStyle } from './styleTcPdf';
import TcHeader from './pdfHeader';
import TcFooter from './pdfFooter';
import TcContent from './pdfContent';


const TcPdf = (props) => {
    const [schoolLogo, setSchoolLogo] = useState("");
    const [principalSign, setPrincipalSign] = useState("");

    const [schoolName, setSchoolName] = useState("");
    const [schoolAddress, setSchoolAddress] = useState("");
    const [schoolAffiliationNo, setSchoolAffiliationNo] = useState("");

    const [tcSerialNo, setTcSerialNo] = useState("");
    const [enrollmentNo, setEnrollmentNo] = useState("");
    const [date, setDate] = useState("");
    const [gender, setGender] = useState("");
    const [student, setStudent] = useState("");
    const [age, setAge] = useState(null);
    const [issuedDt, setIssuedDt] = useState("");
    const [fatherName, setFatherName] = useState("");
    const [motherName, setMotherName] = useState("");
    const [nationality, setNationality] = useState("");
    const [religion, setReligion] = useState("");
    const [dob, setDob] = useState("");
    const [doa, setDoa] = useState("");
    const [educationBoard, setEducationBoard] = useState("");
    const [lastClass, setLastClass] = useState("");
    const [academicYear, setAcademicYear] = useState("");
    const [flgEleigiblePromotion, setFlgEleigiblePromotion] = useState(false);
    const [flgFeesCleared, setFlgFeesCleared] = useState(false);
    const [pendingFees, setPendingFees] = useState(null);
    const [otherDues, setOtherDues] = useState("");
    const [generalConduct, setGeneralConduct] = useState("");
    const [reasonTc, setReasonTc] = useState("");
    const [specialRemarks, setSpecialRemarks] = useState("");
    const [attendance, setAttendance] = useState("");


    useEffect(() => {
        // // console.log("BonafidePdf component called, props.pdfDtls", props.pdfDtls);
        setSchoolLogo(props.schoolLogo);
        setPrincipalSign(props.principalSign);

        if (props.studentDtlsObj) {
            setSchoolName(props.studentDtlsObj.school ? props.studentDtlsObj.school : "");
            setSchoolAffiliationNo(props.studentDtlsObj.affiliationNo ? props.studentDtlsObj.affiliationNo : "");
            setTcSerialNo(props.certificateId ? props.certificateId : "");
            setEnrollmentNo(props.studentDtlsObj.enrollmentNo ? props.studentDtlsObj.enrollmentNo : "");
            setDate(props.studentDtlsObj.printDt ? props.studentDtlsObj.printDt : "");
            setGender(props.studentDtlsObj.gender ? props.studentDtlsObj.gender : "");
            setStudent(props.studentDtlsObj.student ? props.studentDtlsObj.student : "");
            setIssuedDt(props.studentDtlsObj.approveDt ? props.studentDtlsObj.approveDt : "");
            setFatherName(props.studentDtlsObj.father ? props.studentDtlsObj.father : "");
            setMotherName(props.studentDtlsObj.mother ? props.studentDtlsObj.mother : "");
            setNationality(props.studentDtlsObj.nationality ? props.studentDtlsObj.nationality : "");
            setReligion(props.studentDtlsObj.religion ? props.studentDtlsObj.religion : "");
            setDob(props.studentDtlsObj.dob ? props.studentDtlsObj.dob : "");
            setDoa(props.studentDtlsObj.doa ? props.studentDtlsObj.doa : "");
            setEducationBoard(props.studentDtlsObj.educationBoard ? props.studentDtlsObj.educationBoard : "");
            setLastClass(props.studentDtlsObj.lastClass ? props.studentDtlsObj.lastClass : "");
            setAcademicYear(props.studentDtlsObj.acdYr ? props.studentDtlsObj.acdYr : "");
            setFlgEleigiblePromotion(props.studentDtlsObj.promoteToNextCls ? props.studentDtlsObj.promoteToNextCls : false);
            setFlgFeesCleared(props.studentDtlsObj.feeCleared ? props.studentDtlsObj.feeCleared : false);
            setPendingFees(props.studentDtlsObj.feeClearedRemarks ? props.studentDtlsObj.feeClearedRemarks : null);
            setOtherDues(props.studentDtlsObj.otherDues ? props.studentDtlsObj.otherDues : "");
            setGeneralConduct(props.studentDtlsObj.generalConduct ? props.studentDtlsObj.generalConduct : "");
            setReasonTc(props.studentDtlsObj.reasonForTc ? props.studentDtlsObj.reasonForTc : "");
            setSpecialRemarks(props.studentDtlsObj.remarksPrint ? props.studentDtlsObj.remarksPrint : "");
            setAttendance(props.studentDtlsObj.attendance ? props.studentDtlsObj.attendance : "");

            let studentAge = calculateAge(props.studentDtlsObj.dob);
            // // console.log("tcPdf called, studentAge", studentAge);

            let schoolFullAddress =
                (props.studentDtlsObj.schoolAddress ? props.studentDtlsObj.schoolAddress : "") +
                (props.studentDtlsObj.schoolLocality ? props.studentDtlsObj.schoolLocality + " " : "") +
                (props.studentDtlsObj.schoolCity ? props.studentDtlsObj.schoolCity + " " : "") +
                (props.studentDtlsObj.schoolState ? props.studentDtlsObj.schoolState + " " : "");

            // // console.log("tcPdf called, schoolFullAddress", schoolFullAddress);

            // Uncomment these if you plan to set age and address:
            setAge(studentAge);
            setSchoolAddress(schoolFullAddress);
        }


    }, [props]);

    function calculateAge(dob, calculatedOn) {
        // Function to parse date in dd-mm-yyyy format and return current date if invalid
        function parseDate(dateStr) {
            if (!dateStr) {
                return new Date(); // Use current date if no date is provided
            }

            const [day, month, year] = dateStr.split('-').map(Number);

            // Check if date is valid (month in range 1-12, day is a valid date in that month)
            if (
                !year || !month || !day ||
                month < 1 || month > 12 ||
                day < 1 || day > new Date(year, month, 0).getDate()
            ) {
                return new Date(); // Use current date if the format is incorrect
            }

            return new Date(year, month - 1, day);
        }

        // Parse the input dates, using the current date as a fallback
        const birthDate = parseDate(dob);
        const calculationDate = parseDate(calculatedOn);

        // Calculate age
        let age = calculationDate.getFullYear() - birthDate.getFullYear();
        const monthDiff = calculationDate.getMonth() - birthDate.getMonth();

        // Adjust age if necessary
        if (monthDiff < 0 || (monthDiff === 0 && calculationDate.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    }


    return (
        <div className='col-sm-12 padding-remove'>
            <PDFViewer style={{ width: "100%", height: "95vh", margin: "2vh 0", overflowY: "scroll" }}>
                <Document>
                    <Page orientation="portrait" size="A4" style={pageStyle.page}>
                        <View style={pageStyle.page_border}>
                            <View style={pageStyle.header_section}>
                                <TcHeader
                                    schoolLogo={schoolLogo}
                                    schoolName={schoolName}
                                    schoolAddress={schoolAddress}
                                    schoolAffiliationNo={schoolAffiliationNo}
                                />
                            </View>
                            <View style={[pageStyle.certificate_content_section]}>
                                <TcContent
                                    tcSerialNo={tcSerialNo}
                                    enrollmentNo={enrollmentNo}
                                    date={date}
                                    gender={gender}
                                    student={student}
                                    age={age}
                                    issuedDt={issuedDt}
                                    fatherName={fatherName}
                                    motherName={motherName}
                                    nationality={nationality}
                                    religion={religion}
                                    dob={dob}
                                    doa={doa}
                                    educationBoard={educationBoard}
                                    lastClass={lastClass}
                                    academicYear={academicYear}
                                    flgEleigiblePromotion={flgEleigiblePromotion}
                                    flgFeesCleared={flgFeesCleared}
                                    pendingFees={pendingFees}
                                    otherDues={otherDues}
                                    generalConduct={generalConduct}
                                    reasonTc={reasonTc}
                                    specialRemarks={specialRemarks}
                                    attendance={attendance}
                                />
                            </View>
                            <View style={pageStyle.footer_section}>
                                <TcFooter
                                    principalSign={principalSign}
                                />
                            </View>
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        </div >
    );
}

export default TcPdf;