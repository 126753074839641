import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import Header from "common/js/header/header";
import Loader from "common/components/Loader";

import { getFacilityListSvc } from "./facilitySvc";
import FacilityTable from "./facilityTbl";
import FacilitySlider from "./facilitySlider";


const FacilityCtrl = () => {

    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [lstFacility, setLstFacility] = useState([]);
    const [facilityId, setFacilityId] = useState(0);
    const [facility, setFacility] = useState({});
    const [isFacilityVisible, setIsFacilityVisible] = useState(false);
    const [key, setKey] = useState(0);

    useEffect(() => {
        // console.log("FacilityCtls called");
        getFacilityList();
    }, [key]);

    const getFacilityList = () => {
        // console.log("getFacilityList called");
        setLoader("block");
        setOpacity(0.5);
        getFacilityListSvc(facilityListResponse);
    }


    const facilityListResponse = (data) => {
        // console.log("facilityListResponse called, data", data);
        if (data.status === "Success") {
            setLstFacility(data.lstFacility);
        } else if (data.status === "Failure") {
            handleServiceError(data.message);
        } else {
            toast.error("Service failed " + data.error);
        }
        setLoader("none");
        setOpacity(1);
    }

    const handleServiceError = (svcMsg) => {
        if (svcMsg === "You are not Logged In") {
            toast.error("You are not logged in. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else if (svcMsg === "Server Timed Out, Login Again") {
            toast.error("Server timed out. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else {
            toast.error(svcMsg);
        }
    };

    const addFacility = async () => {
        // console.log("addFacility called");
        await setFacility({});
        await setFacilityId(0);
        setIsFacilityVisible(true);
    }

    const editFacility = async (pFacility) => {
        // console.log("editFacility called, pFacility ", pFacility);
        await setFacility(pFacility);
        await setFacilityId(pFacility.facilityId);
        setIsFacilityVisible(true);
    }

    const deleteFacility = (pFacilityId) => {
        // console.log("deleteFacility called, pFacilityId ", pFacilityId);
    }

    const closeSlider = () => {
        setIsFacilityVisible(false);
    }

    const updateKey = () => {
        setKey(prevKey => prevKey + 1);
    }



    return (

        <div >
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <Loader loader={loader} position="fixed" />
            <div className="col-md-12 col-xs-12 col-centered padding-remove"
                style={{
                    cursor: (loader === "none") ? 'pointer' : 'none',
                    pointerEvents: (loader === "none") ? 'auto' : 'none',
                    opacity: opacity
                }}>
                <div className="col-ls-6 col-md-7 col-sm-8 col-xs-9 col-centered padding-remove" style={{ marginTop: "30px" }}>
                    <div className="col-ls-10 col-md-10 col-sm-10 col-xs-10 " >
                        <FacilityTable
                            lstFacility={lstFacility}
                            facilityId={facilityId}
                            setFacilityId={setFacilityId}
                            editFacility={editFacility}
                            deleteFacility={deleteFacility}
                        />
                    </div>
                    <div className="col-ls-2 col-md-2 col-sm-2 col-xs-2 " >
                        <span
                            id="addFacility"
                            title="Add Facility"
                            className="addIcon glyphicon glyphicon-plus-sign"
                            onClick={() => addFacility()}
                        ></span>
                    </div>
                </div>
            </div>
            {
                isFacilityVisible ?

                    <div className="slider1-60">
                        <FacilitySlider
                            facilityId={facilityId}
                            facility={facility}
                            updateKey={updateKey}
                            deleteFacility={deleteFacility}
                            closeSlider={closeSlider}
                        />
                    </div>
                    : null
            }
        </div>

    );
}

export default FacilityCtrl;