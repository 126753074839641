import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { testScheduleDatesListSvc, deleteTestScheduleDtSvc, saveTestScheduleDtSvc } from './WTestScheduleSvc';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const ScheduleTestDates = (props) => {

    const { testScheduleId, closeSlider } = props;
    const [lstTestDt, setLstTestDt] = useState([]);
    const [lstOrigTestDt, setLstOrigTestDt] = useState([]);
    const [loaderSlider, setLoaderSlider] = useState("none");
    const [lstMyTestDt, setLstMyTestDt] = useState([]);
    useEffect(() => {
        // // console.log("useEffect called in ScheduleTestDates");
        // // console.log("testScheduleId", testScheduleId);
        getTestDates();
    }, []);

    const getTestDates = () => {
        // // console.log("getTestDates called");
        setLoaderSlider("block");
        testScheduleDatesListSvc(testScheduleId, cbScheduleDatesResponse);
    }

    const cbScheduleDatesResponse = (data) => {
        // // console.log("scheduleBatchDatesResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            setLstTestDt(obj.lstTestDt);

            let lstTmpTestDt = []
            for (let i = 0; i < obj.lstTestDt.length; i++) {
                let objTmpTestDt = { testId: i, testDt: obj.lstTestDt[i], flgSave: false };
                lstTmpTestDt.push(objTmpTestDt)
            }
            setLstMyTestDt(lstTmpTestDt)
            setLstOrigTestDt(lstTmpTestDt);
            // // console.log("obj.lstTestDt", lstTmpTestDt);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoaderSlider('none');
    }

    const saveTestDt = (pIdx) => {
        // // console.log("removeTestDt called for idx:" + pIdx);
        setLoaderSlider("block");
        let lstTmpTestDt = [...lstMyTestDt];
        let obj = { testScheduleId: testScheduleId, testDt: lstTmpTestDt[pIdx].testDt };
        saveTestScheduleDtSvc(obj, saveTestScheduleDtResponse);
    }

    const saveTestScheduleDtResponse = (data) => {
        // // console.log("saveTestScheduleDtResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            toast.success(obj.message);
            // // console.log("obj", obj);
            getTestDates();
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoaderSlider('none');
    }

    const removeTestDt = (pIdx) => {
        // // console.log("removeTestDt called for idx:" + pIdx);
        let r = window.confirm("Are you sure to delete the Test Date?");
        if (r) {
            setLoaderSlider("block");
            let obj = { testScheduleId: testScheduleId, testDt: lstTestDt[pIdx] };
            deleteTestScheduleDtSvc(obj, deleteTestScheduleDtResponse);
        }
    }

    const deleteTestScheduleDtResponse = (data) => {
        // // console.log("deleteTestScheduleDtResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            toast.success(obj.message);
            // // console.log("obj", obj);
            getTestDates();
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoaderSlider('none');
    }
    const todayDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return dd + '-' + mm + '-' + yyyy;

    }
    const addRow = () => {
        let lstTmpTestDt = [...lstTestDt, todayDate()];
        // setLstTestDt(lstTmpTestDt)
        let lstNTmpTestDt = []
        for (let i = 0; i < lstTmpTestDt.length; i++) {
            let objTmpTestDt = {}
            // // console.log(i+ (i>lstTestDt.length-1))
            if (i>lstTestDt.length-1) {
                objTmpTestDt = { testId: i, testDt: lstTmpTestDt[i], flgSave: true };
            } else {
                objTmpTestDt = { testId: i, testDt: lstTmpTestDt[i], flgSave: false };
            }
            lstNTmpTestDt.push(objTmpTestDt)
            setLstMyTestDt(lstNTmpTestDt)
        }
        // lstNTmpTestDt[lstTmpTestDt.length - 1].flgSave = true;
        setLstMyTestDt(lstNTmpTestDt)
        // // console.log(lstNTmpTestDt)
    }
    function convert(str) {
        var date = new Date(str),
            mnth = ('0' + (date.getMonth() + 1)).slice(-2),
            day = ('0' + date.getDate()).slice(-2)
        return [day, mnth, date.getFullYear()].join('-')
    }
    const changeLstTestDt = (pVal, pIdx) => {
        // // console.log(pVal)
        let lstTmpTestDt = [...lstMyTestDt];
        // // console.log(convert(pVal))
        lstTmpTestDt[pIdx].testDt = convert(pVal);
        setLstMyTestDt(lstTmpTestDt)
    }
    function dateString2Date(dateString) {
        // // console.log('dtStr:' + dateString);
        var dtArr = dateString.split('-')
        // // console.log(dtArr[2]+'/'+dtArr[1]+'/'+dtArr[0]+" :: "+new Date(dtArr[2]+'/'+dtArr[1]+'/'+dtArr[0]))
        return new Date(dtArr[2]+'/'+dtArr[1]+'/'+dtArr[0]);
    }

    return (
        <div className="col-xs-12 col-centered padding-remove " style={{ marginTop: 20 }}>
            <div style={{ position: "fixed", zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '70%', top: '40%', display: loaderSlider }} >
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div >
            <ToastContainer autoClose={3000} hideProgressBar />
            <div>
                <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={() => closeSlider()} style={{ right: "60%" }}>
                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                </button>
                <div className='col-sm-12'>
                    <div className='col-sm-1'>
                        <div className='row'>
                            <label className="col-sm-12 padding-remove">Dates</label>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        {lstMyTestDt.length > 0 && lstMyTestDt.map(function (obj, idx) {
                            return <div className="col-sm-12 padding-remove" key={idx}>
                                <div className="row">
                                    <div className="form-group col-sm-12">
                                        <div className='inner-addon'>
                                            <div className="col-sm-8">
                                                <DatePicker id="inp_toDate"
                                                    className='form-control text-center'
                                                    placeholderText="dd-mm-yyyy"
                                                    selected={dateString2Date(obj.testDt)}
                                                    dateFormat="dd-MM-yyyy"
                                                    onChange={(date) => changeLstTestDt(date, idx)}
                                                    autoComplete='off'
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    dropdownMode="select"
                                                    disabled={!obj.flgSave}
                                                />

                                            </div>
                                            {obj.flgSave ? <button type="button" onClick={(e) => saveTestDt(idx)} className="btn btn-info col-sm-4" >
                                                Save
                                            </button> : <></>}
                                            {!obj.flgSave ? <button type="button" onClick={(e) => removeTestDt(idx)} className="btn btn-info col-sm-4" >
                                                Delete
                                            </button> : <></>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                    <div className="col-sm-2 padding-remove" onClick={(e) => addRow()}>
                        <span id="addReadyId" title="Add" className="addIcon glyphicon glyphicon-plus-sign"></span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ScheduleTestDates;