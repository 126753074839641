import React, { useEffect, useState } from 'react'
import { getCellDtls, deleteCellDtls } from './ttTeacherService';
import { toast, ToastContainer } from 'react-toastify';
import 'sms/tt/teacher/css/tabList.css'
import 'sms/tt/disruption/css/duty.css'

const TchCellDtls = (props) => {
    var initialState = {
        loaderCell: 'none',
        opacityCell: 1,
    };
    const [state, setState] = useState(initialState);
    const [cellDtls, setCellDtls] = useState([props.cellDtls]);
    const [randNo, setRandNo] = useState(0)
    const [refresh, setRefresh] = useState(true)
    const [tchId, setTchId] = useState(props.selTchId);
    const [selSubPosId, setSelSubPosId] = useState(props.selSubPosId);
    const [selCellId, setSelCellId] = useState(props.selCellId);
    function showloaderCell() {
        setState({ ...state, loaderCell: 'flex', opacityCell: 0.5 });
    }
    function hideloaderCell() {
        setState({ ...state, loaderCell: 'none', opacityCell: 1 });
    }
    useEffect(() => {
        // // console.log(props);
        setSelCellId(props.selCellId)
        setSelSubPosId(props.selSubPosId)
        setTchId(props.selTchId);
        if (refresh === true) {

            var rqCell = {
                schedulePeriodId: props.periodId,
                classSectionId: props.sectionId,
                dayId: props.dayId,
                cellId: 0,
            };
            setCell(rqCell, false);
            return;
        }

        if (props.cellId === props.selCellDtls.cellId && props.cellRefresh === true) {

            var rqCell1 = {
                schedulePeriodId: props.selCellDtls.schedulePeriodId,
                classSectionId: props.selCellDtls.classSectionId,
                dayId: props.selCellDtls.dayId,
                cellId: props.selCellDtls.cellId,
            };
            setCell(rqCell1, false);
            return;
        }

    }, [props.selCellDtls, props.cellRefresh, props.selSubPosId, props.selCellId, props.selTchId])

    function generateRandomNo() {
        var lRandNo = Math.floor(Math.random() * 999999);
        setRandNo(lRandNo)
    }
    function setCell(rqCell, pRefresh) {
        showloaderCell();
        getCellDtls(handleCellResponse, rqCell, pRefresh, rqCell.cellId);
    }
    function handleCellResponse(data, pRefresh) {
        var obj = data;
        if (obj.status === 'Success') {
            setRefresh(false)
            props.setCellRefresh(false)
            hideloaderCell();
            setCellDtls(obj.cellDtls);
            if (pRefresh) generateRandomNo();
        } else if (obj.status === 'Failure') {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(data.message);
            }
        } else {
            toast.error("Service failed " + obj.message);
        }
    }

    function removeTeacher(cellId, subPosition) {
        deleteCellDtls(cellId, subPosition, handleDeleteResponse);
    }

    function handleDeleteResponse(data, pRqCell) {
        if (data.status === "Success") {
            var rqCell = {
                schedulePeriodId: props.periodId,
                classSectionId: props.sectionId,
                dayId: props.dayId,
                cellId: pRqCell.cellId,
            };

            setCell(rqCell, false);
            toast.success(data.message);
        } else if (data.status === 'Failure') {
            if (data.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (data.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(data.message);
            }
        } else {
            toast.error("Service failed " + data.error);
        }
    }



    return (<div

        style={{ pointerEvents: state.opacityCell === 1 ? 'auto' : 'none', height: '100%' }}>

        <div style={{ position: 'absolute', zIndex: 10000, right: '14%', top: '34%', display: state.loaderCell }}>
            <img className="cellLoderImg" src={require("common/images/loader.gif")} alt="Loader..." />
        </div>
        <div style={{ height: "100%", display: state.loaderCell === 'none' ? 'block' : 'none' }}>
            {cellDtls.subCtr === 0 && <CellForSubZero onClickSubCell={props.onClickSubCell} key={randNo} cellDtls={cellDtls} generateRandomNo={generateRandomNo} cellId={cellDtls.cellId} pos={cellDtls.subCtr} selSubPosId={props.selSubPosId} selCellId={props.selCellId} dayId={props.dayId} periodId={props.periodId} classSectionId={props.sectionId} /> ||
                cellDtls.subCtr === 1 && <CellForSubOne onClickSubCell={props.onClickSubCell} key={randNo} generateRandomNo={generateRandomNo} removeTeacher={removeTeacher} cellId={cellDtls.cellId} pos={cellDtls.subCtr} cellDtls={cellDtls} selSubPosId={props.selSubPosId} selCellId={props.selCellId} dayId={props.dayId} periodId={props.periodId} classSectionId={props.sectionId} /> ||
                cellDtls.subCtr === 2 && <CellForSubTwo onClickSubCell={props.onClickSubCell} key={randNo} generateRandomNo={generateRandomNo} removeTeacher={removeTeacher} cellId={cellDtls.cellId} pos={cellDtls.subCtr} cellDtls={cellDtls} selSubPosId={props.selSubPosId} selCellId={props.selCellId} dayId={props.dayId} periodId={props.periodId} classSectionId={props.sectionId} /> ||
                cellDtls.subCtr === 3 && <CellForSubThree onClickSubCell={props.onClickSubCell} key={randNo} generateRandomNo={generateRandomNo} removeTeacher={removeTeacher} cellId={cellDtls.cellId} pos={cellDtls.subCtr} cellDtls={cellDtls} selSubPosId={props.selSubPosId} selCellId={props.selCellId} dayId={props.dayId} periodId={props.periodId} classSectionId={props.sectionId} /> ||
                cellDtls.subCtr === 4 && <CellForSubFour onClickSubCell={props.onClickSubCell} key={randNo} generateRandomNo={generateRandomNo} removeTeacher={removeTeacher} cellId={cellDtls.cellId} pos={cellDtls.subCtr} cellDtls={cellDtls} selSubPosId={props.selSubPosId} selCellId={props.selCellId} dayId={props.dayId} periodId={props.periodId} classSectionId={props.sectionId} />}
        </div>
    </div>);
}

const CellForSubZero = (props) => {
    const [cellDtls, setCellDtls] = useState([props.cellDtls]);
    const [periodId, setPeriodId] = useState(props.periodId);
    const [dayId, setDayId] = useState(props.dayId);
    const [classSectionId, setClassSectionId] = useState(props.classSectionId);
    const [cellId, setCellId] = useState([props.cellId]);
    const [sessionId, setSessionId] = useState([props.sessionId]);
    const [tchId, setTchId] = useState(props.tchId);
    const [selCellId, setSelCellId] = useState(props.selCellId);
    const [selSubPosId, setSelSubPosId] = useState(props.selSubPosId);

    useEffect(() => {
        setCellDtls(props.cellDtls);
        setSelCellId(props.selCellId)
        setSelSubPosId(props.selSubPosId)
        setDayId(props.dayId)
        setClassSectionId(props.classSectionId)
        setPeriodId(props.periodId)
        setTchId(props.tchId)
    }, [])

    return <div
        className={selCellId == cellId && selSubPosId == 1 ? "sub SubZero selected-cell" : "sub SubZero"}
        onClick={(e) => props.onClickSubCell(e, cellDtls.cellId, dayId, periodId, 0, cellDtls.tch1Id, classSectionId)}
        cell={JSON.stringify(cellDtls)} session-id={sessionId} class-sec-id={classSectionId} cell-id={cellId} day-id={dayId} period-id={periodId}>
    </div>
}

const CellForSubOne = (props) => {
    const [cellDtls, setCellDtls] = useState([props.cellDtls]);
    const [periodId, setPeriodId] = useState(props.periodId);
    const [dayId, setDayId] = useState(props.dayId);
    const [classSectionId, setClassSectionId] = useState(props.classSectionId);
    const [cellId, setCellId] = useState([props.cellId]);
    const [sessionId, setSessionId] = useState([props.sessionId]);
    const [tchId, setTchId] = useState(props.tchId);
    const [selCellId, setSelCellId] = useState(props.selCellId);
    const [selSubPosId, setSelSubPosId] = useState(props.selSubPosId);
    useEffect(() => {
        setCellDtls(props.cellDtls);
        setSelCellId(props.selCellId)
        setSelSubPosId(props.selSubPosId)
        setDayId(props.dayId)
        setClassSectionId(props.classSectionId)
        setPeriodId(props.periodId)
        setTchId(props.tchId)
    }, [])

    return (
        <div style={{ height: '100%' }} className={selCellId == cellId && selSubPosId == 1 ? "sub SubOne selected-cell" : "sub SubOne"}
            onClick={(e) => props.onClickSubCell(e, cellDtls.cellId, dayId, periodId, 0, cellDtls.tch1Id, classSectionId)}
            cell={JSON.stringify(cellDtls)}>
            <div session-id={sessionId} class-sec-id={classSectionId} cell-id={cellId} tch-id={tchId} day-id={dayId} period-id={periodId} className="spanSub">{cellDtls.sub1}</div>
            <div session-id={sessionId} class-sec-id={classSectionId} cell-id={cellId} tch-id={tchId} day-id={dayId} period-id={periodId} className="spanTch">
                <span style={{ float: 'left', width: '88%', textOverflow: 'ellipsis', overflow: 'hidden', paddingRight: '5px', whiteSpace: 'nowrap', paddingLeft: '2%' }}>{cellDtls.tch1}</span>
                <button onClick={() => props.removeTeacher(props.cellDtls.cellId, 1)} className="glyphicon glyphicon-remove" style={{ cursor: 'pointer', float: 'right', width: '10%', marginRight: ' 2%', border: 'none', background: '#fff' }}></button>
            </div>
        </div>
    )
}

const CellForSubTwo = (props) => {
    const [cellDtls, setCellDtls] = useState([props.cellDtls]);
    const [periodId, setPeriodId] = useState(props.periodId);
    const [dayId, setDayId] = useState(props.dayId);
    const [classSectionId, setClassSectionId] = useState(props.classSectionId);
    const [cellId, setCellId] = useState([props.cellId]);
    const [sessionId, setSessionId] = useState([props.sessionId]);
    const [tchId, setTchId] = useState(props.tchId)
    const [selCellId, setSelCellId] = useState(props.setSelCellId)
    const [selSubPosId, setSelSubPosId] = useState(props.selSubPosId);
    useEffect(() => {
        setCellDtls(props.cellDtls);
        setSelCellId(props.selCellId)
        setSelSubPosId(props.selSubPosId)
        setDayId(props.dayId)
        setClassSectionId(props.classSectionId)
        setPeriodId(props.periodId)
        setTchId(props.tchId)
    }, [])

    return (
        <div className="sub SubTwo" cell={JSON.stringify(cellDtls)}>
            <div className={selCellId == cellId && selSubPosId == 1 ? "tch1 selected-cell" : "tch1"}
                style={{ borderBottom: "2px solid blue" }}
                onClick={(e) => props.onClickSubCell(e, cellDtls.cellId, dayId, periodId, 0, cellDtls.tch1Id, classSectionId)}>
                <div session-id={sessionId} class-sec-id={classSectionId} cell-id={cellId} day-id={dayId} period-id={periodId} tch-id={tchId} className="spanSub">{cellDtls.sub1}</div>
                <div session-id={sessionId} class-sec-id={classSectionId} cell-id={cellId} day-id={dayId} period-id={periodId} tch-id={tchId} className="spanTch">
                    <span style={{ float: 'left', width: '88%', textOverflow: 'ellipsis', overflow: 'hidden', paddingRight: '5px', whiteSpace: 'nowrap', paddingLeft: '2%' }}>{cellDtls.tch1}</span>
                    <button onClick={() => props.removeTeacher(props.cellDtls.cellId, 1)} className="glyphicon glyphicon-remove" style={{ cursor: 'pointer', float: 'right', width: '10%', marginRight: ' 2%', border: 'none', background: '#fff' }}></button>
                </div>
            </div>
            <div className={selCellId == cellId && selSubPosId == 2 ? "tch2 selected-cell" : "tch2"}
                onClick={(e) => props.onClickSubCell(e, cellDtls.cellId, dayId, periodId, 1, cellDtls.tch3Id, classSectionId)}>
                <div session-id={sessionId} class-sec-id={classSectionId} cell-id={cellId} day-id={dayId} period-id={periodId} tch-id={tchId} className="spanSub">{cellDtls.sub2}</div>
                <div session-id={sessionId} class-sec-id={classSectionId} cell-id={cellId} day-id={dayId} period-id={periodId} tch-id={tchId} className="spanTch">
                    <span style={{ float: 'left', width: '88%', textOverflow: 'ellipsis', overflow: 'hidden', paddingRight: '5px', whiteSpace: 'nowrap', paddingLeft: '2%' }}>{cellDtls.tch2}</span>
                    <button onClick={() => props.removeTeacher(props.cellDtls.cellId, 2)} className="glyphicon glyphicon-remove" style={{ cursor: 'pointer', float: 'right', width: '10%', marginRight: ' 2%', border: 'none', background: '#fff' }}></button></div></div>
        </div>
    )
}

const CellForSubThree = (props) => {
    const [cellDtls, setCellDtls] = useState([props.cellDtls]);
    const [periodId, setPeriodId] = useState(props.periodId);
    const [dayId, setDayId] = useState(props.dayId);
    const [classSectionId, setClassSectionId] = useState(props.classSectionId);
    const [cellId, setCellId] = useState([props.cellId]);
    const [sessionId, setSessionId] = useState([props.sessionId]);
    const [tchId, setTchId] = useState(props.tchId)
    const [selSubPosId, setSelSubPosId] = useState(props.selSubPosId)
    const [selCellId, setSelCellId] = useState(props.selCellId)

    useEffect(() => {
        setCellDtls(props.cellDtls);
        setSelCellId(props.selCellId)
        setSelSubPosId(props.selSubPosId)
        setDayId(props.dayId)
        setClassSectionId(props.classSectionId)
        setPeriodId(props.periodId)
        setTchId(props.tchId)
    }, [])

    return (
        <div className="sub SubTwo" cell={JSON.stringify(cellDtls)}>
            <div className="tch1">
                <div style={{ float: 'left', height: '100%', width: '50%', borderRight: '1px solid #e4e4e4', borderBottom: '1px solid #e4e4e4', borderBottom: '1px solid #e4e4e4' }}
                    className={selCellId == cellId && selSubPosId == 1 ? "selected-cell" : ""}
                    onClick={(e) => props.onClickSubCell(e, cellDtls.cellId, dayId, periodId, 0, cellDtls.tch1Id, classSectionId)}>
                    <div session-id={sessionId} class-sec-id={classSectionId} cell-id={cellId} day-id={dayId} period-id={periodId} tch-id={tchId} className="spanSub">{cellDtls.sub1}</div>
                    <div session-id={sessionId} class-sec-id={classSectionId} cell-id={cellId} day-id={dayId} period-id={periodId} tch-id={tchId} className="spanTch">
                        <span style={{ float: 'left', width: '88%', textOverflow: 'ellipsis', overflow: 'hidden', paddingRight: '5px', whiteSpace: 'nowrap', paddingLeft: '2%' }}>{cellDtls.tch1}</span>
                        <button onClick={() => props.removeTeacher(props.cellDtls.cellId, 1)} className="glyphicon glyphicon-remove" style={{ cursor: 'pointer', float: 'right', width: '10%', marginRight: ' 2%', border: 'none', background: '#fff' }}></button>
                    </div>
                </div>
                <div style={{ float: 'left', height: '100%', width: '50%', borderRight: '1px solid #e4e4e4', borderBottom: '1px solid #e4e4e4' }}
                    className={selCellId == cellId && selSubPosId == 3 ? "selected-cell" : ""}
                    onClick={(e) => props.onClickSubCell(e, cellDtls.cellId, dayId, periodId, 2, cellDtls.tch2Id, classSectionId)}>
                    <div session-id={sessionId} class-sec-id={classSectionId} cell-id={cellId} day-id={dayId} period-id={periodId} tch-id={tchId} className="spanSub">{cellDtls.sub3}</div>
                    <div session-id={sessionId} class-sec-id={classSectionId} cell-id={cellId} day-id={dayId} period-id={periodId} tch-id={tchId} className="spanTch">
                        <span style={{ float: 'left', width: '88%', textOverflow: 'ellipsis', overflow: 'hidden', paddingRight: '5px', whiteSpace: 'nowrap', paddingLeft: '2%' }}>{cellDtls.tch3}</span>
                        <button onClick={() => props.removeTeacher(props.cellDtls.cellId, 3)} className="glyphicon glyphicon-remove" style={{ cursor: 'pointer', float: 'right', width: '10%', marginRight: ' 2%', border: 'none', background: '#fff' }}></button>
                    </div>
                </div>
            </div>
            <div className="tch2">
                <div style={{ float: 'left', height: '100%', width: '50%', borderRight: '1px solid #e4e4e4', borderBottom: '1px solid #e4e4e4' }}
                    className={selCellId == cellId && selSubPosId == 2 ? "selected-cell" : ""}
                    onClick={(e) => props.onClickSubCell(e, cellDtls.cellId, dayId, periodId, 1, cellDtls.tch3Id, classSectionId)}>
                    <div session-id={sessionId} class-sec-id={classSectionId} cell-id={cellId} day-id={dayId} period-id={periodId} tch-id={tchId} className="spanSub">{cellDtls.sub2}</div>
                    <div session-id={sessionId} class-sec-id={classSectionId} cell-id={cellId} day-id={dayId} period-id={periodId} tch-id={tchId} className="spanTch">
                        <span style={{ float: 'left', width: '88%', textOverflow: 'ellipsis', overflow: 'hidden', paddingRight: '5px', whiteSpace: 'nowrap', paddingLeft: '2%' }}>{cellDtls.tch2}</span>
                        <button onClick={() => props.removeTeacher(props.cellDtls.cellId, 2)} className="glyphicon glyphicon-remove" style={{ cursor: 'pointer', float: 'right', width: '10%', marginRight: ' 2%', border: 'none', background: '#fff' }}></button>
                    </div>
                </div>

            </div>
        </div>
    )
}
const CellForSubFour = (props) => {
    const [cellDtls, setCellDtls] = useState([props.cellDtls]);
    const [periodId, setPeriodId] = useState(props.periodId);
    const [dayId, setDayId] = useState(props.dayId);
    const [classSectionId, setClassSectionId] = useState(props.classSectionId);
    const [cellId, setCellId] = useState([props.cellId]);
    const [sessionId, setSessionId] = useState([props.sessionId]);
    const [tchId, setTchId] = useState(props.tchId)
    const [selSubPosId, setSelSubPosId] = useState(props.selSubPosId)
    const [selCellId, setSelCellId] = useState(props.selCellId)
    useEffect(() => {
        // // console.log(props)
        setCellDtls(props.cellDtls);
        setSelCellId(props.selCellId)
        setSelSubPosId(props.selSubPosId)
        setDayId(props.dayId)
        setClassSectionId(props.classSectionId)
        setPeriodId(props.periodId)
        setTchId(props.tchId)
    }, [])

    return (
        <div className="sub SubTwo" cell={JSON.stringify(cellDtls)}>
            <div className="tch1">
                <div className={selCellId == cellId && selSubPosId == 1 ? "selected-cell" : ""}
                    style={{ float: 'left', height: '100%', width: '50%', borderRight: '1px solid #e4e4e4', borderBottom: '1px solid #e4e4e4' }}
                    onClick={(e) => props.onClickSubCell(e, cellDtls.cellId, dayId, periodId, 0, cellDtls.tch1Id, classSectionId)}>
                    <div session-id={sessionId} class-sec-id={classSectionId} cell-id={cellId} day-id={dayId} period-id={periodId} tch-id={cellDtls.sub1Id} className="spanSub">{cellDtls.sub1}</div>
                    <div session-id={sessionId} class-sec-id={classSectionId} cell-id={cellId} day-id={dayId} period-id={periodId} tch-id={cellDtls.tch1Id} className="spanTch">
                        <span style={{ float: 'left', width: '88%', textOverflow: 'ellipsis', overflow: 'hidden', paddingRight: '5px', whiteSpace: 'nowrap', paddingLeft: '2%' }}>{cellDtls.tch1}</span>
                        <button onClick={() => props.removeTeacher(props.cellDtls.cellId, 1)} className="glyphicon glyphicon-remove" style={{ cursor: 'pointer', float: 'right', width: '10%', marginRight: ' 2%', border: 'none', background: '#fff' }}></button>
                    </div>
                </div>
                <div className={selCellId == cellId && selSubPosId == 3 ? "selected-cell" : ""}
                    style={{ float: 'left', height: '100%', width: '50%', borderRight: '1px solid #e4e4e4', borderBottom: '1px solid #e4e4e4' }}
                    onClick={(e) => props.onClickSubCell(e, cellDtls.cellId, dayId, periodId, 2, cellDtls.tch3Id, classSectionId)}>
                    <div session-id={sessionId} class-sec-id={classSectionId} cell-id={cellId} day-id={dayId} period-id={periodId} tch-id={cellDtls.sub3Id} className="spanSub">{cellDtls.sub3}</div>
                    <div session-id={sessionId} class-sec-id={classSectionId} cell-id={cellId} day-id={dayId} period-id={periodId} tch-id={cellDtls.tch3Id} className="spanTch">
                        <span style={{ float: 'left', width: '88%', textOverflow: 'ellipsis', overflow: 'hidden', paddingRight: '5px', whiteSpace: 'nowrap', paddingLeft: '2%' }}>{cellDtls.tch3}</span>
                        <button onClick={() => props.removeTeacher(props.cellDtls.cellId, 3)} className="glyphicon glyphicon-remove" style={{ cursor: 'pointer', float: 'right', width: '10%', marginRight: ' 2%', border: 'none', background: '#fff' }}></button>
                    </div>
                </div>
            </div>
            <div className="tch2">
                <div className={selCellId == cellId && selSubPosId == 2 ? "selected-cell" : ""}
                    style={{ float: 'left', height: '100%', width: '50%', borderRight: '1px solid #e4e4e4', borderBottom: '1px solid #e4e4e4' }}
                    onClick={(e) => props.onClickSubCell(e, cellDtls.cellId, dayId, periodId, 1, cellDtls.tch2Id, classSectionId)}>
                    <div session-id={sessionId} class-sec-id={classSectionId} cell-id={cellId} day-id={dayId} period-id={periodId} tch-id={cellDtls.sub2Id} className="spanSub">{cellDtls.sub2}</div>
                    <div session-id={sessionId} class-sec-id={classSectionId} cell-id={cellId} day-id={dayId} period-id={periodId} tch-id={cellDtls.tch2Id} className="spanTch">
                        <span style={{ float: 'left', width: '88%', textOverflow: 'ellipsis', overflow: 'hidden', paddingRight: '5px', whiteSpace: 'nowrap', paddingLeft: '2%' }}>{cellDtls.tch2}</span>
                        <button onClick={() => props.removeTeacher(props.cellDtls.cellId, 2)} className="glyphicon glyphicon-remove" style={{ cursor: 'pointer', float: 'right', width: '10%', marginRight: ' 2%', border: 'none', background: '#fff' }}></button>
                    </div>
                </div>
                <div style={{ float: 'left', height: '100%', width: '50%', borderRight: '1px solid #e4e4e4', borderBottom: '1px solid #e4e4e4' }}
                    className={selCellId == cellId && selSubPosId == 4 ? "selected-cell" : ""}
                    onClick={(e) => props.onClickSubCell(e, cellDtls.cellId, dayId, periodId, 3, cellDtls.tch4Id, classSectionId)}>
                    <div session-id={sessionId} class-sec-id={classSectionId} cell-id={cellId} day-id={dayId} period-id={periodId} tch-id={cellDtls.sub4Id} className="spanSub">{cellDtls.sub4}</div>
                    <div session-id={sessionId} class-sec-id={classSectionId} cell-id={cellId} day-id={dayId} period-id={periodId} tch-id={cellDtls.tch4Id} className="spanTch">
                        <span style={{ float: 'left', width: '88%', textOverflow: 'ellipsis', overflow: 'hidden', paddingRight: '5px', whiteSpace: 'nowrap', paddingLeft: '2%' }}>{cellDtls.tch4}</span>
                        <button onClick={() => props.removeTeacher(props.cellDtls.cellId, 4)} className="glyphicon glyphicon-remove" style={{ cursor: 'pointer', float: 'right', width: '10%', marginRight: ' 2%', border: 'none', background: '#fff' }}></button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default TchCellDtls;