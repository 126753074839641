import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';


import 'common/css/master.css';


const ClassTable = (props) => {
    const { lstClsSection, sectionId, setSectionId, viewReportCard } = props;


    const colgrp = <colgroup>
        <col width="40"></col>
        <col width="30"></col>
        <col width="70"></col>
    </colgroup>

    return (
        <div >
            <div className="col-md-12 col-xs-12 col-centered padding-remove " >
                <table className="tableLayout1" >
                    {colgrp}
                    <thead>
                        <tr>
                            <th>Class</th>
                            <th>Section</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                </table>
                <div className="tableDiv" style={{ height: "60vh" }}>
                    <table className="tableLayout">
                        {colgrp}
                        <tbody id="termReportClsTable" >
                            {
                                lstClsSection && lstClsSection.length ?
                                    lstClsSection.map((cls) => (
                                        <tr className={cls.classSectionId === parseInt(sectionId) ? "selected" : ""} key={cls.classSectionId}>
                                            <td hidden>{cls.classId}</td>
                                            <td>{cls.className}</td>
                                            <td>{cls.section}</td>
                                            <td className='text-center'>
                                                <span className='fa fa-eye' title="View"
                                                    onClick={e =>
                                                        viewReportCard(cls.classSectionId, cls.syllabusId)
                                                    }
                                                ></span>
                                                {/* <span
                                                    className='glyphicon glyphicon-share'
                                                    title="Share"
                                                // data={JSON.stringify(obj)}
                                                ></span> */}
                                            </td>
                                        </tr>
                                    ))
                                    : null
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ClassTable;