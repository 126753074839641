import { red } from '@mui/material/colors';
import { StyleSheet, Font } from '@react-pdf/renderer';



import NaanumGothicRegular from "common/fonts/Nanum_Gothic/NanumGothic-Regular.ttf";
import NaanumGothicBold from "common/fonts/Nanum_Gothic/NanumGothic-Bold.ttf";
import InterItalic from "common/fonts/Inter/Inter-Italic-VariableFont_opsz,wght.ttf";
import Inter from "common/fonts/Inter/Inter-VariableFont_opsz,wght.ttf";


Font.register({ family: 'Inter', src: Inter });
Font.register({ family: 'InterItalic', src: InterItalic });
Font.register({
    family: 'NanumGothic',
    fonts: [
        { src: NaanumGothicRegular, fontWeight: 'normal' },
        { src: NaanumGothicBold, fontWeight: 'bold' }
    ]
});



const pageStyle = StyleSheet.create({
    page: {
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        backgroundColor: "#f5f5f5",  // Light background
    },
    page_border: {
        paddingVertical: 20,
        paddingHorizontal: 30,
        border: "5px solid #4a90e2", // Blue border
        borderRadius: 25,
        backgroundColor: "#fff", // White background inside
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Soft shadow
        height: '100%',
        width: '100%',
        backgroundImage: "linear-gradient(to right, #f6d365, #fda085)", // Gradient background
    },
    header_section: {
        flex: 6,
        borderBottom: "2px solid #ddd",
        paddingBottom: 10,
    },
    certificate_content_section: {
        flex: 41,
        // marginTop: 20,
    },
    footer_section: {
        flex: 4,
        padding: "10px",
        borderTop: "1px solid #ddd",
    },
});



const fontStyles = StyleSheet.create({
    textCenterSmallBold: {
        fontFamily: 'NanumGothic',
        justifyContent: "center",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: 10,
        color: '#000',
        // borderWidth: 1,
        // borderColor: "#000",
    },
    textLeftSmall: {
        fontFamily: 'NanumGothic',
        justifyContent: "center",
        textAlign: "left",
        fontWeight: "normal",
        fontSize: 14,
        color: '#000',
    },
    textLeftSmallBold: {
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'left',
        lineHeight:1.5,
    },
    textLeftNormal: {
        fontFamily: 'NanumGothic',
        justifyContent: "center",
        textAlign: "left",
        fontWeight: "normal",
        fontSize: 11,
        color: '#000',
        lineHeight:1.5,
    },
    textRightNormal: {
        fontFamily: 'NanumGothic',
        justifyContent: "center",
        textAlign: "right",
        fontWeight: "normal",
        fontSize: 11,
        color: '#000',
        lineHeight:1.5,
    },
    textCenterNormalInter: {
        fontFamily: 'Inter',
        justifyContent: "center",
        textAlign: "center",
        fontSize: 12,
        color: '#000',
    },
    textCenterBigBold: {
        fontFamily: 'NanumGothic',
        fontWeight: "medium",
        justifyContent: "center",
        textAlign: "center",
        fontSize: 26,
        color: '#000',
    },
    textSchool: {
        fontFamily: 'NanumGothic',
        justifyContent: "center",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: 20,
        color: '#000',
    },
    textSchoolDtls: {
        fontFamily: 'NanumGothic',
        justifyContent: "center",
        textAlign: "center",
        fontWeight: "normal",
        fontSize: 13,
        color: '#000',
        lineHeight:1.5,
    },
    textSchoolDtlsSmall: {
        fontFamily: 'NanumGothic',
        justifyContent: "center",
        textAlign: "center",
        fontWeight: "normal",
        fontSize: 11,
        color: '#000',
        lineHeight:1.5,
    },
    fontNaunamGauthicBold: {
        fontFamily: 'NanumGothic',
        justifyContent: "center",
        textAlign: "left",
        fontWeight: "bold",
        fontSize: 14,
        color: '#000',
    },
    fontNaunamGauthicMedium: {
        fontFamily: 'NanumGothic',
        fontWeight: "medium",
        justifyContent: "center",
        textAlign: "left",
        fontSize: 14,
        color: '#000',
    },
    fontInter: {
        fontFamily: 'Inter',
        fontSize: 18,
        justifyContent: "center",
        textAlign: "left",
        color: '#000',
    },
    fontInterItalic: {
        fontFamily: 'InterItalic',
        justifyContent: "center",
        textAlign: "left",
        alignItems: "flex-start",
        alignContent: "flex-start",
        alignSelf: "flex-start",
        fontSize: 16,
        color: '#000',
        lineHeight: 1.75,

    },
    boldText: {
        fontFamily: 'NanumGothic',
        fontWeight: 'bold',
        fontSize: 16, // You can adjust this as needed
        color: '#000',
        textAlign: "left",
        alignItems: "flex-start",
        alignContent: "flex-start",
        alignSelf: "flex-start",
    },
});


const styleContent = StyleSheet.create({
    content_section: {
        flex: 1,
        flexDirection: "column",
        padding: 10,
        // border: "1px solid #ddd",
        // borderRadius: 10,
    },
    certificate_dtls_section: {
        flex: 5,
        flexDirection: "column",
        // marginBottom: 10,
    },
    gap_section_small: {
        // flex:1,
        height: "5px",
    },
    gap_section: {
        // flex:2,
        height: "10px",
    },
    gap_section_big: {
        // flex:3,
        height: "15px",
    },
    row: {
        // flex:1,
        flexDirection: "row",
    },
    rowRemarks: {
        // flex:15,
        flexDirection: "row",
    },
    column: {
        flex: 5,
        flexDirection: "column",
        // border: "1px solid #ddd",
    },
    columnDivider: {
        flex: 1,
        flexDirection: "column",
        // border: "1px solid #ddd",
    },
    columnRight: {
        flex: 14,
        flexDirection: "column",
        // border: "1px solid #ddd",
    },
    certificate_name: {
        flex: 4,
        // marginBottom: 10,
    },
    certificate_name_container: {
        height: "80%",
        alignContent: "center",
        justifyContent: "center",
        border: "1px solid #ccc",
        borderRadius: 10,
    },
    content: {
        flex: 50,
        flexDirection: "column",
        // marginTop: 10,
    },
    notes: {
        flex: 30,
        flexDirection: "column",
        // marginTop: 10,
    },
});


const styleFooter = StyleSheet.create({
    block: {
        flex: 1,
        flexDirection: "column",
        padding: "2 10 2 10",
        // backgroundColor: '#f0f0f0',
    },
    empty_row: {
        flex: 7,
        // height: "70%",
        flexDirection: "row",
    },
    row_labels: {
        flex: 3,
        flexDirection: "row",
        // height: "30%",
    },
    colSign: {
        flex: 1,
        // backgroundColor: '#f0f0f0',
        // border:"1px solid black",
        // margin: "0 20px",
    },
    colSignLabel: {
        flex: 1,
        alignContent: "center"
        // backgroundColor: '#f0f0f0',
    },
    sign_text: {
        fontSize: 12,
        fontFamily: "Helvetica-Bold",
        textAlign: "center",
        alignContent: "center",
        color: "#37353b"
    },
    image_sign: {
        height: "auto",
        maxWidth: "100%",
        // textAlign: "center",
        // padding: 0,
        // justifyContent:"center",
        // alignContent:"center",
        // textAlign:"center",
        // alignItems:"center",
        // alignSelf:"center"
    },
});


const styleHeaderLogo = StyleSheet.create({
    header_logo_section: {
        height: "100%",
        // marginLeft: 15,
        // marginTop: 15,
        padding: 5,
        color: "#000",
        display: 'flex',
        flexDirection: 'row',
        family: 'Calibri',
    },
    header_left: {
        flex: '2',
        height: '100%',
        // borderWidth:innerBorderWidth,
        // borderColor:"#ccc",
        alignContent: "center",
    },
    header_center: {
        flex: '6',
        flexDirection: "column",
        textAlign: "center",
        justifyContent: "center",
        // fontWeight: 'heavy',
        fontStyle: 'normal',
        // borderWidth:innerBorderWidth,
        // borderColor:"#ccc",
        // fontFamily: "Helvetica-Bold",
    },
    school_header: {
        fontSize: 11,
        paddingTop: 5,
        textTransform: "uppercase",
    },
    header_right: {
        margin: 10,
        padding: 10,
        flex: '2',
        // borderWidth:innerBorderWidth,
        // borderColor:"#ccc",
    },
    image1: {
        maxHeight: "80px",
        maxWidth: "80px",
        // width:"auto",
        // maxWidth: "100%",
        textAlign: "center",
        padding: 0,
        objectFit: "contian",
        // justifyContent:"center",
        // alignContent:"center",
        // textAlign:"center",
        // alignItems:"center",
        // alignSelf:"center"
    },
});


export { pageStyle, styleFooter, styleHeaderLogo, fontStyles, styleContent };