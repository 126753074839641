import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['main'].url + ':' + lstModules['main'].port;
var tokenid = localStorage.getItem("tokenid");


export const lstBonafideSvc = (callback) => {
    // // console.log("lstBonafideSvc called");
    var obj = { status: "Success", message: "" }
    axios({
        url: httpURL + '/sms/certificate/bonafide/list',
        method: "POST",
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // // console.log('data.SvcStatus=' + response.data.SvcStatus);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstBonafide = response.data.lstBonafide;
        }
        callback(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        obj.lstIdCard = [];
        callback(obj);
    });
}

export const lstStudentSvc = (callback) => {
    // // console.log("lstStudentSvc called");
    var obj = { status: "Success", message: "" }
    axios({
        url: httpURL + '/sms/certificate/bonafide/list_student',
        method: "POST",
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // // console.log('data.SvcStatus in lstStudentSvc', response.data.SvcStatus);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstStudent = response.data.lstStudent;
        }
        callback(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        obj.lstIdCard = [];
        callback(obj);
    });
}

export const saveCertificateSvc = (bonafideCertObj, callback) => {
    // // console.log("saveCertificateSvc called, bonafideCertObj", bonafideCertObj);
    const formData = new FormData();
    formData.append("bonafideCertObj", JSON.stringify(bonafideCertObj));
    var obj = { status: "Success", message: "" }
    axios({
        url: httpURL + '/sms/certificate/bonafide/save',
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // // console.log('data.SvcStatus in saveCertificateSvc', response.data.SvcStatus);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.certificateId = response.data.certificateId;
        }
        callback(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        obj.lstIdCard = [];
        callback(obj);
    });
}

export const bonafideApproverSvc = (callback) => {
    // // console.log("bonafideApproverSvc called");
    var obj = { status: "Success", message: "" }
    axios({
        url: httpURL + '/sms/certificate/bonafide/list_principal_viceprincipal',
        method: "POST",
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // // console.log('data.SvcStatus in bonafideApproverSvc', response.data.SvcStatus);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstApprovalAuthority = response.data.lstApprovalAuthority;
        }
        callback(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        obj.lstIdCard = [];
        callback(obj);
    });
}

export const bonafideDtlsSvc = (certificateId, callback) => {
    // // console.log("bonafideDtlsSvc called,certificateId", certificateId);
    const formData = new FormData();
    formData.append("certificateId", certificateId);
    var obj = { status: "Success", message: "" }
    axios({
        url: httpURL + '/sms/certificate/bonafide/dtls',
        method: "POST",
        headers: { 'tokenid': tokenid },
        data:formData,
        withCredentials: true,
    }).then((response) => {
        // // console.log('data.SvcStatus in bonafideDtlsSvc', response.data.SvcStatus);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.certificateDtls = response.data.certificateDtls;
        }
        callback(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        obj.lstIdCard = [];
        callback(obj);
    });
}


export function getPrinciSignSvc(cb) {

    var xhr = new XMLHttpRequest();
    xhr.open("GET", httpURL + '/sms/mst/sign/view?roleId=24', true);
    xhr.setRequestHeader('tokenid', tokenid);

    xhr.responseType = "arraybuffer";

    xhr.onload = function (e) {
        var arrayBufferView = new Uint8Array(this.response);
        var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(blob);
        // // console.log("imageUrl in getPrinciSignSvc", imageUrl);
        cb(imageUrl);
    };
    xhr.send();

}

export function getLogoSvc(callback) {

    var xhr = new XMLHttpRequest();
    xhr.open("GET", httpURL + '/sms/school/profile/logo/view', true);
    xhr.setRequestHeader('tokenid', tokenid);

    xhr.responseType = "arraybuffer";

    xhr.onload = function (e) {
        var arrayBufferView = new Uint8Array(this.response);
        var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(blob);
        // // console.log("imageUrl in getLogoSvc", imageUrl);
        callback(imageUrl);
    };
    xhr.send();
}

