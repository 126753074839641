import React from 'react';
import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['timeTable'].url + ':' + lstModules['timeTable'].port;
const httpURLAcdSch = lstModules['academicSchedule'].url + ":" + lstModules['academicSchedule'].port;
var tokenid = localStorage.getItem("tokenid");
var screenId = 8;
var queryString = require('querystring');
function getLstRole(callback) {
    var status = 'Success'
    var obj = { status: status };

    axios({
        url: httpURL + '/sms/tt/v1/tt/mst/acd_role/list',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === 'Success') {
            obj.lstRole = response.data.lstRole;
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            // // console.log(obj);
            callback(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });
}

function getLstSession(callback) {
    var acdYrId = localStorage.getItem('academicYearId');
    var wingId = localStorage.getItem('wingId');
    var status = 'Success'
    var obj = { status: status };

    axios({
        url: httpURLAcdSch + '/sms/acdSch/list',
        method: "POST",
        params: { screenId, acdYrId, wingId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === 'Success') {
            obj.lstSession = response.data.lstAcademicSchedule;
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            // // console.log(obj);
            callback(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        console.error(error)
    });
    /* var obj = {};
    obj.lstSession = [{ sessionId: 1, session: 'Ist Term', pct: '15%', startDt: '01-Mar', endDt: '4-May' }
        , { sessionId: 2, session: 'Session-2', pct: '40%', startDt: '01-Jun', endDt: '4-Sep' }
        , { sessionId: 3, session: 'Session-3', pct: '30%', startDt: '21-Sep', endDt: '24-Dec' }
        , { sessionId: 4, session: 'Session-4', pct: '15%', startDt: '05-Jan', endDt: '23-Feb' }];
    obj.SvcStatus = 'Success';
    // // console.log(typeof obj)
    // // console.log(typeof cb)
    cb(obj); */
}

function getLstTTtchSummary(sessionId, callback) {
    var status = 'Success'
    var obj = { status: status };
    axios({
        url: httpURL + '/sms/tt/v1/tch_summary/tch/list',
        method: "POST",
        params: { screenId, sessionId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === 'Success') {
            obj.lstTTtchSummary = response.data.lstTchAllocation;
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';

            callback(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });
}
function sendTimeTable(sessionId, forced, lstTch, callback) {
    var status = 'Success'
    var obj = { status: status };
    var tchDtls = { sessionId: sessionId, forced: forced, lstTch: lstTch }
    axios.post(httpURL + '/sms/tt/v1/tch_summary/tch/send',
        queryString.stringify({ 'tchDtls': JSON.stringify(tchDtls), screenId }),
        {
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then((response) => {
        // // console.log(response);
        if (response.data.SvcStatus === 'Success') {
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            callback(obj)
        } else {
            obj.message = response.data.SvcMsg;
            obj.status = response.data.SvcStatus;
            callback(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });
}

function previewPdf(sessionId, tchId, callback) {
    var status = 'Success'
    var obj = { status: status };
    axios({
        url: httpURL + '/sms/tt/v1/tch_summary/tch/preview',
        method: "POST",
        params: { screenId, tchId, sessionId },
        headers: { 'tokenid': tokenid },
        responseType: 'arraybuffer',
        withCredentials: true,
    }).then((response) => {
        // // console.log(response)
        obj.data = response.data;
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        callback(obj)
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });
}
function getClsSecTTView(pClsId, pSecId, pSessionId, callBack) {
    axios(
        {
            url: httpURL + "/sms/tt/v1/summary/cls_sec/tt/preview",
            method: 'POST',
            params: { clsId: pClsId, sectionId: pSecId, sessionId: pSessionId },
            headers: { 'tokenid': tokenid },
            withCredentials: true,
            responseType: 'arraybuffer'
        }
    ).then(
        (Response) => {
            // // console.log(Response)
            var data = Response
            callBack(data)
        }
    ).catch((error) => {
        // // console.log("Catch error")
    });
}
export { getLstRole, getLstSession, getLstTTtchSummary, previewPdf, sendTimeTable, getClsSecTTView };