import React, { useState, useEffect } from "react";
import Header from "common/js/header/header";
import { toast, ToastContainer } from "react-toastify";
import { getLstTeamSvc, deleteTeamSvc } from "./TeamSvc";
import TeamSlider from "./TeamSlider";
const ListingTeam = () => {
  const [lstTeam, setLstTeam] = useState([]);
  const [teamId, setTeamId] = useState(0);
  const [teamObj, setTeamObj] = useState({});
  const [flgSlider, setFlgSlider] = useState(0);
  const [randNo, setRandNo] = useState(0);
  useEffect(() => {
    getTeamList();
  }, []);
  function getTeamList() {
    getLstTeamSvc(cbLstTeamResponse);
  }
  function closeSlider() {
    setFlgSlider(0);
  }

  function cbLstTeamResponse(obj) {
    if (obj.status === "Success") {
      setLstTeam(obj.lstTeam);
    } else if (obj.status === "Failure") {
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed " + obj.message);
    }
  }

  function deleteTeam(pId) {
    var r = window.confirm("Are you sure to delete the team member?");
    if (!r) {
      return;
    }
    deleteTeamSvc(pId, cbDeleteTeamResponse);
  }

  function cbDeleteTeamResponse(pObj) {
    if (pObj.status === "Success") {
      toast.success(pObj.message);
      getTeamList();
    } else {
      toast.error(pObj.message);
    }
  }

  const colgrp = (
    <colgroup>
      <col width="30"></col>
      <col width="20"></col>
      <col width="20"></col>
      <col width="20"></col>
    </colgroup>
  );
  function openTeamSlider(pTeamId, pTeamObj) {
    setTeamId(pTeamId);
    // // console.log(typeof pTeamId);
    // // console.log(teamObj);
    /*  if (pTeamId) {
        getTeamDtlsSvc(pTeamId, cbTeamDtlsResponse);
    } */
    setTeamObj(pTeamObj);
    setFlgSlider(true);
  }

  return (
    <div>
      <div style={{ height: "100%" }}>
        <Header />
        <ToastContainer autoClose={3000} hideProgressBar />
        <div
          style={{
            position: "fixed",
            zIndex: 10000,
            width: 100,
            height: 100,
            marginLeft: -50,
            marginTop: -50,
            left: "50%",
            top: "50%",
            display: "none",
          }}
        >
          <img
            className="loderImg"
            src={require("common/images/loader.gif")}
            alt="Loader..."
          />
        </div>
        <div className="MainContainer" style={{ marginTop: 20 }}>
          <div className="col-sm-6 col-centered">
            <div className="col-sm-11">
              <table className="tableLayout1">
                {colgrp}
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Role</th>
                    <th>Type</th>
                    <th>Action</th>
                  </tr>
                </thead>
              </table>
              <div className="" style={{ height: "83vh", overflowY: "scroll" }}>
                <table className="tableLayout">
                  {colgrp}
                  <tbody>
                    {lstTeam.map((obj, key) => {
                      return (
                        <tr
                          className={teamId === obj.teamId ? "selected" : ""}
                          key={"tm_" + key}
                          onClick={(e) => openTeamSlider(obj.teamId, obj)}
                        >
                          <td className="text-left">{obj.name}</td>
                          <td className="text-left">{obj.role}</td>
                          <td className="text-center">
                            {obj.management === 0 ? "Staff" : "Management"}
                          </td>
                          <td className="text-center">
                            {" "}
                            <span
                              onClick={(e) => openTeamSlider(obj.teamId, obj)}
                              className="glyphicon glyphicon-edit"
                            ></span>
                            <span
                              onClick={(e) => deleteTeam(obj.teamId)}
                              className="glyphicon glyphicon-trash"
                            ></span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-xs-1" onClick={(e) => openTeamSlider(0, {})}>
              <span
                id="addReadyId"
                title="Add"
                className="addIcon glyphicon glyphicon-plus-sign"
              ></span>
            </div>
          </div>
        </div>
      </div>
      {teamObj && (
        <div
          className="TestSlider"
          style={{ display: flgSlider ? "block" : "none", width: "60%" }}
        >
          <TeamSlider
            key={teamId}
            teamId={teamId}
            openSlider={openTeamSlider}
            closeSlider={closeSlider}
            setRandNo={setRandNo}
            teamObj={teamObj}
            getTeamList={getTeamList}
          />
        </div>
      )}
    </div>
  );
};

export default ListingTeam;
