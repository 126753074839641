import { useEffect, useState } from "react";
import { duplicateExamSubjectListSvc, getClassSectionListSvc } from "./examSubjectsSvc";
import { toast } from 'react-toastify';

const DuplicateExamSubjects = (props) => {

    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [toSectionId, setToSectionId] = useState(0);
    const [lstSection, setLstSection] = useState([]);

    useEffect(() => {
        // // console.log("duplicateSubjectPopup called");
        getSectionList();
    }, []);

    const getSectionList = () => {
        // // console.log("getSectionList called");
        setLoader("block");
        setOpacity(0.5);
        getClassSectionListSvc(props.clsId, sectionListResponse);
    }

    const sectionListResponse = (data) => {
        // // console.log("sectionListResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            // // console.log("successful response in sectionListResponse");
            setLstSection(obj.lstClsSection);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader('none');
        setOpacity(1);
    }

    const duplicateExamSubjectsCall = () => {
        // // console.log("duplicateExamSubjectsCall called");
        const examDuplicateRQ = {
            examId: props.examId,
            fromSectionId: props.sectionId,
            toSectionId: toSectionId
        };
        setLoader("block");
        setOpacity(0.5);
        duplicateExamSubjectListSvc(examDuplicateRQ, duplicateExamSubjectsResponse);
    }

    const duplicateExamSubjectsResponse = (data) => {
        // // console.log("duplicateExamSubjectsResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            // // console.log("successful response in duplicateExamSubjectsResponse");
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader('none');
        setOpacity(1);
    }


    return (
        <div style={{ display: "flex", justifyContent: "center", position: "absolute", padding: 0, top: "35%", left: "30%", backgroundColor: "#F8F8F8", width: 350, height: 200, zIndex: 1001, border: "1px solid #ccc" }}>
            <div style={{ position: 'absolute', zIndex: 1000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="col-sm-12 padding-remove" >
                <div className="col-sm-12 padding-remove">
                    <div className="col-sm-8" style={{ padding: "10px 2px", fontSize: "large" }}>
                        Duplicate exam subjects
                    </div>
                    <div className="col-sm-4 padding-remove" >
                        <button type="button"
                            title="Close Popup"
                            onClick={() => props.closePopup()}
                            className='btn btn-info text-center pull-right'>X</button>
                    </div>
                </div>
                <form style={{ opacity: opacity }}>
                    <div className="form-group " >
                        <label className="control-label col-sm-4 text-left" >From class: </label>
                        <div className="col-sm-8 ">
                            <input autoComplete="off" type="text" className="form-control" value={props.cls + "-" + props.section} disabled={true} />
                        </div>
                    </div>
                    <div className="form-group " >
                        <div className="col-sm-12 padding-remove" style={{ minHeight: 10 }} >
                        </div>
                    </div>
                    <div className="form-group ">
                        <label className="control-label col-sm-4 text-left" >To Class: </label>
                        <div className="col-sm-8 ">
                            <select
                                className="form-control"
                                value={toSectionId}
                                onChange={(e) => setToSectionId(e.target.value)}
                            >
                                <option value={0}>Section</option>
                                {lstSection && lstSection.length ?
                                    lstSection.map((section) => (
                                        <option
                                            key={section.sectionId}
                                            value={section.sectionId}>
                                            {props.cls + "-" + section.section}
                                        </option>
                                    )) : null
                                }
                            </select>
                        </div>
                    </div>

                    <div className="col-sm-12 padding-remove" >
                        {/* <div className=" col-md-12 form-group text-center multiButtonWrap1" > */}
                        {/* <div style={{ marginTop: 15, width: 150 }}>
                                <button type="button" style={{ width: "90%" }} onClick={() => props.closePopup()} className="btn btn-success">Cancel</button>
                            </div> */}
                        <div className="col-md-6 padding-remove pull-right" style={{ marginTop: 15, width: 150 }}>
                            <button type="button" style={{ width: "90%" }} onClick={() => duplicateExamSubjectsCall()} className="btn btn-success">Duplicate </button>
                        </div>
                        {/* </div> */}
                    </div>
                </form>
            </div>
        </div>
    );
}

export default DuplicateExamSubjects;