import React, { useState, useEffect } from "react";
import LblTxt from "./LblTxt";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import lstModules from "common/json/lstModules.json";
var tokenid = localStorage.getItem("tokenid");
var httpURL = lstModules["enquiry"].url + ":" + lstModules["enquiry"].port;
var httpURLCommon = lstModules["main"].url + ":" + lstModules["main"].port;

const RegistrationDocs = (props) => {
  const [lstDocDtls, setLstDocDtls] = useState([]);
  const ID_PROOF = 10;
  const AGE_PROOF = 20;
  const ADDRESS_PROOF = 30;
  const CATEGORY_PROOF = 40;
  const PHOTO = 50;
  const [imgId, setImgId] = useState("");
  const [imgAge, setImgAge] = useState("");
  const [imgAddress, setImgAddress] = useState("");
  const [imgCategory, setImgCategory] = useState("");
  const [imgPhoto, setImgPhoto] = useState("");
  const [loader, setLoader] = useState("none");
  const controller = new AbortController();
  const signal = controller.signal;

  useEffect(() => {
    if (props.regsId) {
      cbIdDocDtlsResponse(props.lstDocDtls);
    }
    return () => {
      controller.abort();
    };
  }, [props]);

  function cbIdDocDtlsResponse(pLstDocDtls) {
    setLstDocDtls(pLstDocDtls);
    for (var i = 0; i < pLstDocDtls.length; i++) {
      var obj = pLstDocDtls[i];
      // // console.log(obj.typeId + "::" + obj.imgPath);
      cbFetchDocResponse(obj.typeId, obj.imgPath);
    }
  }
  function cbFetchDocResponse(type, imgPath) {
    if (type === 10) {
      setImgId(httpURL + "/sms/regs/docs/view?imgPath=" + imgPath);
      return;
    }
    if (type === 20) {
      setImgAge(httpURL + "/sms/regs/docs/view?imgPath=" + imgPath);
      return;
    }
    if (type === 30) {
      setImgAddress(httpURL + "/sms/regs/docs/view?imgPath=" + imgPath);
      return;
    }
    if (type === 40) {
      setImgCategory(httpURL + "/sms/regs/docs/view?imgPath=" + imgPath);
      return;
    }
  }
  const onIdChange = (e) => {
    // // console.log(e.target.files[0].name);
    // // console.log("src:" + URL.createObjectURL(e.target.files[0]));
    if (e.target.files && e.target.files[0]) {
      setImgId(URL.createObjectURL(e.target.files[0]));
      setLoader("block");
      saveImgIdSvc(e.target.files[0], 10, cbSaveImgIdResponse);
    }
  };
  function saveImgIdSvc(file, type, callBack) {
    var formData = new FormData();
    formData.append("regsId", props.regsId);
    formData.append("file", file);
    formData.append("typeId", type);

    axios({
      url: httpURL + "/sms/regs/docs/save",
      method: "POST",
      data: formData,
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((response) => {
        callBack(response.data);
      })
      .catch((e) => {
        console.error("Service failed " + e);
      });
  }
  function cbSaveImgIdResponse(data) {
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  const onAgeProofChange = (e) => {
    // // console.log(e.target.files[0].name);
    // // console.log("src:" + URL.createObjectURL(e.target.files[0]));
    if (e.target.files && e.target.files[0]) {
      setImgAge(URL.createObjectURL(e.target.files[0]));
      setLoader("block");
      saveImgIdSvc(e.target.files[0], 20, cbSaveImgIdResponse);
    }
  };

  const onAddressProofChange = (e) => {
    // // console.log(e.target.files[0].name);
    // // console.log("src:" + URL.createObjectURL(e.target.files[0]));
    if (e.target.files && e.target.files[0]) {
      setImgAddress(URL.createObjectURL(e.target.files[0]));
      setLoader("block");
      saveImgIdSvc(e.target.files[0], 30, cbSaveImgIdResponse);
    }
  };
  const onCategoryProofChange = (e) => {
    // // console.log(e.target.files[0].name);
    // // console.log("src:" + URL.createObjectURL(e.target.files[0]));
    if (e.target.files && e.target.files[0]) {
      setImgCategory(URL.createObjectURL(e.target.files[0]));
      setLoader("block");
      saveImgIdSvc(e.target.files[0], 40, cbSaveImgIdResponse);
    }
  };

  const fileToDataUri = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });
  function fetchDocSvc(type, imgPath, callBack) {
    axios({
      url: httpURL + "/sms/regs/docs/view",
      method: "POST",
      params: { imgPath: imgPath },
      headers: { tokenid: tokenid },
      withCredentials: true,
    }).then((response) => {
      callBack(type, response);
    });
    /* .then(
    (Response) => {
      var data = Response.data;
      callBack(type, toDataURL(data));
      let imageDataUrl = URL.createObjectURL(data)
      if (data.SvcStatus === "Success") {
        // // console.log("list fetching success")
        let reader = new window.FileReader();
        reader.readAsDataURL(data);
        reader.onload = function () {
          let imageDataUrl = reader.result;
          callBack(type, imageDataUrl)
        };
      }
    }).catch((error) => {
      console.error(error)
    });*/
  }
  function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }
  const handleIdClick = (e) => {
    // // console.log(document.getElementById("img_id"));
    // document.getElementById("img_id").dispatchEvent(new Event('click'));
    document.getElementById("img_id").click();
  };
  const handleAgeClick = (e) => {
    // // console.log(document.getElementById("img_ageId"));
    // document.getElementById("img_ageId").dispatchEvent(new Event('click'));
    document.getElementById("img_ageId").click();
  };
  const handleAddressClick = (e) => {
    // // console.log(document.getElementById("img_addressId"));
    // document.getElementById("img_addressId").dispatchEvent(new Event('click'));
    document.getElementById("img_addressId").click();
  };
  const handleCategoryClick = (e) => {
    // // console.log(document.getElementById("img_categoryId"));
    // document.getElementById("img_categoryId").dispatchEvent(new Event('click'));
    document.getElementById("img_categoryId").click();
  };
  const onError = () => {
    setImgId(window.appURL + "/no-image.png");
    setImgAge(window.appURL + "/no-image.png");
    setImgAddress(window.appURL + "/no-image.png");
    setImgCategory(window.appURL + "/no-image.png");
  };
  return (
    <>
      <div
        className="col-sm-12"
        style={{
          height: "100%",
          float: "left",
          background: "#fff",
          paddingTop: "1%",
        }}
      >
        <div
          className="col-sm-12"
          style={{ pointerEvents: loader === "none" ? "auto" : "none" }}
        >
          <ToastContainer autoClose={3000} hideProgressBar />

          <div
            style={{
              position: "fixed",
              zIndex: 10000,
              width: 100,
              height: 100,
              marginLeft: -50,
              marginTop: -50,
              left: "50%",
              top: "50%",
              display: loader,
            }}
          >
            <img
              className="loderImg"
              src={require("common/images/loader.gif")}
              alt="Loader..."
            />
          </div>
          <div className="col-sm-3" style={{ background: "transparent" }}>
            <label className="col-sm-3 control-label">ID Proof</label>
            <div className="form-group col-sm-9">
              <input
                docid="3"
                type="file"
                id="img_id"
                name="img"
                onChange={onIdChange}
                className="hidden"
                accept="image/*"
              />
              <img
                src={imgId}
                alt="img"
                onClick={(e) => handleIdClick(e)}
                style={{ height: 160, width: 160, border: "1px solid #ddd" }}
                onError={(e) => onError()}
              />
            </div>
          </div>
          <div className="col-sm-3" style={{ background: "transparent" }}>
            <label className="col-sm-3 control-label">Age Proof</label>
            <div className="form-group col-sm-9">
              <input
                docid="2"
                type="file"
                id="img_ageId"
                name="img"
                onChange={onAgeProofChange}
                className="hidden"
                accept="image/*"
              />
              <img
                src={imgAge}
                alt="Age Proof"
                onClick={(e) => handleAgeClick(e)}
                style={{ height: 160, width: 160, border: "1px solid #ddd" }}
                onError={(e) => onError()}
              />
            </div>
          </div>
          <div className="col-sm-3" style={{ background: "transparent" }}>
            <div>
              <label className="col-sm-3 control-label">Address Proof</label>
            </div>
            <div className="form-group col-sm-9">
              <input
                docid="1"
                type="file"
                id="img_addressId"
                name="img"
                onChange={onAddressProofChange}
                className="hidden"
                accept="image/*"
              />
              <img
                src={imgAddress}
                alt="Address Proof"
                onClick={(e) => handleAddressClick(e)}
                style={{ height: 160, width: 160, border: "1px solid #ddd" }}
                onError={(e) => onError()}
              />
            </div>
          </div>
          <div className="col-sm-3" style={{ background: "transparent" }}>
            <label className="col-sm-3 control-label">Category Proof</label>
            <div className="form-group col-sm-9">
              <input
                docid="4"
                type="file"
                id="img_categoryId"
                name="img"
                onChange={onCategoryProofChange}
                className="hidden"
                accept="image/*"
              />
              <img
                src={imgCategory}
                alt="Category Proof"
                onClick={(e) => handleCategoryClick(e)}
                style={{ height: 160, width: 160, border: "1px solid #ddd" }}
                onError={(e) => onError()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationDocs;
