import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Time from "common/components/time";
import scheduleTimelineList from '../schedulTimeline.json'
import { toast } from 'react-toastify';

import { saveAcademicYearSvc, getAcademicScheduleDetailsSvc } from "./academicScheduleSvc";

const AcademicScheduleForm = (props) => {
    const { acdSchId, acdYrId, wngId, closeSlider, updateKey, setLoader, setOpacity } = props;

    const [academicScheduleId, setAcademicScheduleId] = useState(acdSchId);
    const [academicScheduleData, setAcademicScheduleData] = useState([]);
    const [academicYearId, setAcademicYearId] = useState(acdYrId);
    const [lstAcademicSessionType, setLstAcademicSessionType] = useState(scheduleTimelineList.lstSessionType);
    const [sessionTypeId, setSessionTypeId] = useState(0);
    const [academicSessionLabel, setAcademicSessionLabel] = useState("");
    const [fromDt, setFromDt] = useState("");
    const [toDt, setToDt] = useState("");
    const [period, setPeriod] = useState("");
    const [startHour, setStartHour] = useState("");
    const [startMinute, setStartMinute] = useState("");
    const [startAMPM, setStartAMPM] = useState("");
    const [endHour, setEndHour] = useState("");
    const [endMinute, setEndMinute] = useState("");
    const [endAMPM, setEndAMPM] = useState("");
    const [wingId, setWingId] = useState(wngId);
    // const [formKey, setFormKey] = useState(0);

    useEffect(() => {
        // console.log("useEffect called in acdScheduleForm");
        // console.log("academicScheduleId Received", acdSchId);
        // console.log("lstAcademicSessionType", lstAcademicSessionType);
        // console.log("academicScheduleData", academicScheduleData);
        if (acdSchId) {
            getAcademicScheduleData();
        }
    }, [acdSchId]);

    const getAcademicScheduleData = () => {
        getAcademicScheduleDetailsSvc(acdSchId, academicScheduleDataResponse);
    }

    const academicScheduleDataResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            setAcademicScheduleData(obj.academicScheduleData);
            fillAcademicScheduleData(obj.academicScheduleData);
            // console.log("obj.academicScheduleData in academicScheduleDataResponse", obj.academicScheduleData)
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    const fillAcademicScheduleData = (acdSchData) => {

        // if (acdSchData.academicScheduleId) {
        // setAcademicScheduleId(acdSchData.academicScheduleId);
        // setAcademicYearId(acdSchData.academicYearId);
        setAcademicSessionLabel(acdSchData.label);
        setPeriod(acdSchData.periods);
        setSessionTypeId(acdSchData.sessionTypeId);
        setWingId(acdSchData.wingId);
        setFromDt(dmyToString(acdSchData.fromDt));
        setToDt(dmyToString(acdSchData.toDt));

        // const timeRegex = /^(\d{1,2}):(\d{2})(AM|PM)$/;
        // if (acdSchData.endTm && timeRegex.test(acdSchData.endTm)) {
        const [endTime, endAMPM] = acdSchData.endTm.split(' ');
        const [endHours, endMinutes] = endTime.split(':');
        setEndAMPM(endAMPM);
        setEndHour(endHours);
        setEndMinute(endMinutes);
        // }

        // if (acdSchData.startTm && timeRegex.test(acdSchData.startTm)) {
        const [startTime, startAMPM] = acdSchData.startTm.split(' ');
        const [startHours, startMinutes] = startTime.split(':');
        setStartAMPM(startAMPM);
        setStartHour(startHours);
        setStartMinute(startMinutes);
        // }

        // }
    }

    // const getSessionName = () => {
         // console.log("getSession name called");
    //     if (lstAcademicSessionType) {
    //         for (const sType of lstAcademicSessionType) {
    //             if (sType.sessionTypeId === sessionTypeId) {
    // console.log("getSession name returned value:", sType.sessionType);
    //                 return sType.sessionType;
    //             }
    //         }
    //     }
    // console.log("getSession name returned value:", "regular");
    //     return "regular";
    // };

    const dmyToString = (dt) => {
        if (dt === "00-00-0000" || dt === null || dt === undefined) {
            return "";
        }
        var date = dt;
        date = date.split('-');
        var setDate = date.reverse().join();
        setDate = setDate.replace(/,/g, '-');
        var formatDt = new Date(setDate);
        return formatDt;
    }

    const handleChangeDateFormat = (dt) => {
        // console.log("handleChangeDateFormat called date received", dt);
        if (!!dt)
            var ddmmyyyy = toDMY(dt);
        var formatedDate = dmyToString(ddmmyyyy);
        // console.log("date returned", formatedDate);
        return formatedDate;
    }

    const toDMY = (dt) => {
        var tmpDd = dt.getDate();
        var dd = tmpDd < 9 ? "0" + tmpDd : tmpDd;
        var mon = dt.getMonth() + 1;
        var mm = mon < 10 ? "0" + mon : mon;
        let dmyDate = dd + "-" + mm + "-" + dt.getFullYear();
        return dmyDate;
    }

    const saveForm = () => {
        // console.log("saveForm called, academicYearId", academicYearId);

        var startTm = startHour + ":" + startMinute + startAMPM;
        var endTm = endHour + ":" + endMinute + endAMPM;
        if (sessionTypeId == 0) {
            toast.warning("Please Select Session Type");
            return false
        }
        if (fromDt === "") {
            toast.warning("Please Fill From Date");
            return false
        }
        if (toDt === "") {
            toast.warning("Please Fill To Date");
            return false
        }
        if (sessionTypeId == 30) {
            startTm = "";
            endTm = "";
        }
        else {
            if (startHour === "HH") {
                toast.warning('Please Select Start Hour');
                return false;
            }
            else if (startMinute === 'MM') {
                toast.warning('Please Select Start Minute');
                return false;
            }
            else if (endHour === "HH") {
                toast.warning('Please Select End Hour');
                return false;
            }
            else if (endMinute === 'MM') {
                toast.warning('Please Select End Minute');
                return false;
            }
        }


        const academicSchedule = {
            academicYearId: academicYearId,
            academicScheduleId: academicScheduleId,
            sessionTypeId: sessionTypeId,
            fromDt: toDMY(fromDt),
            toDt: toDMY(toDt),
            label: academicSessionLabel,
            startTm: startTm,
            endTm: endTm,
            periods: period,
            wingId: wingId,
        };

        setLoader("block");
        setOpacity(0.5);
        saveAcademicYearSvc(academicSchedule, saveAcademicScheduleResponse);
    }

    const saveAcademicScheduleResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            toast.success(obj.message);
            updateKey();
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    return (
        <div className="col-xs-12 col-centered padding-remove ">

            <div>
                <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={closeSlider} style={{ right: "60%" }}>
                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                </button>

            </div>
            <div className="col-xs-12 col-centered padding-remove " style={{ height: '100vh', width: '100%', background: '#fff', border: '1px solid #ccc' }}>
                <form id="addForm" className="form-horizontal formWrapper" style={{ width: '60%', borderRadius: 10, marginLeft: "20%", marginTop: "100px" }}>
                    <div className="form-group col-md-10">
                        <label className="control-label col-sm-6">
                            {" "}
                            Type <span style={{ color: "red", marginLeft: 2 }}>*</span>
                        </label>
                        <div className="col-sm-6 padding-remove">
                            <select
                                className="form-control"
                                value={sessionTypeId}
                                onChange={(e) => setSessionTypeId(e.target.value)}
                            >
                                <option value={0}>Session Type</option>
                                {lstAcademicSessionType &&
                                    lstAcademicSessionType.map((sessionType) => (
                                        <option
                                            key={sessionType.sessionTypeId}
                                            value={sessionType.sessionTypeId}>
                                            {sessionType.sessionType}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="form-group col-md-10">
                        <label className="control-label col-sm-6">
                            {" "}
                            Session Label{" "}
                            <span style={{ color: "red", marginLeft: 2 }}>*</span>
                        </label>
                        <div className="col-sm-6 padding-remove">
                            <input
                                autoComplete="off"
                                className="form-control"
                                type="text"
                                value={academicSessionLabel}
                                onChange={(e) => setAcademicSessionLabel(e.target.value)}
                                placeholder="regular"
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group col-md-10">
                        <label className="control-label col-sm-6">
                            From Date<span style={{ color: "red", marginLeft: 2 }}>*</span>
                        </label>
                        <div className="col-sm-6 padding-remove">
                            <DatePicker id="fromDt"
                                className='form-control'
                                placeholderText="Enter From Date"
                                selected={fromDt}
                                onChange={(dt) => setFromDt(handleChangeDateFormat(dt))}
                                dateFormat="dd-MM-yyyy"
                                dropdownMode="select"
                                autoComplete='off'
                                showMonthDropdown
                                showYearDropdown
                                adjustDateOnChange
                            />
                        </div>
                    </div>
                    <div className="form-group col-md-10">
                        <label className="control-label col-sm-6">
                            To Date<span style={{ color: "red", marginLeft: 2 }}>*</span>
                        </label>
                        <div className="col-sm-6 padding-remove">
                            <DatePicker id="fromDt"
                                className='form-control'
                                placeholderText="Enter From Date"
                                selected={toDt}
                                onChange={(dt) => setToDt(handleChangeDateFormat(dt))}
                                dateFormat="dd-MM-yyyy"
                                dropdownMode="select"
                                autoComplete='off'
                                showMonthDropdown
                                showYearDropdown
                                adjustDateOnChange
                            />
                        </div>
                    </div>
                    <div className="form-group col-md-10">
                        <label className="control-label col-sm-6"> Period</label>
                        <div className="col-sm-6 padding-remove">
                            <input
                                autoComplete="off"
                                type="text"
                                className="form-control"
                                placeholder="8"
                                value={period > 0 ? period : ""}
                                onChange={(e) => setPeriod(e.target.value)}
                                disabled={sessionTypeId == 10 ? false : true}
                            />
                        </div>
                    </div>
                    {sessionTypeId != 30  &&
                        <div className="form-group col-md-10 startTime ">
                            <Time label={"Start Time"}
                                hour={startHour} setHour={setStartHour}
                                minutes={startMinute} setMinutes={setStartMinute}
                                ampm={startAMPM} setAMPM={setStartAMPM}
                            // disabled={sessionTypeId == 30? true : false}
                            />
                        </div>
                    }
                    {sessionTypeId != 30 &&
                        <div className="form-group col-md-10 startTime ">
                            <Time label={"End Time"}
                                hour={endHour} setHour={setEndHour}
                                minutes={endMinute} setMinutes={setEndMinute}
                                ampm={endAMPM} setAMPM={setEndAMPM}

                            />
                        </div>
                    }
                    <div className="form-group">
                        <div className="col-sm-10 text-right">
                            <button
                                type="button"
                                style={{ marginTop: 20 }}
                                onClick={() => saveForm()}
                                className="btn btn-success"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AcademicScheduleForm;
