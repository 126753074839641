import React from 'react';
import { Text, View } from '@react-pdf/renderer';

import { toast } from "react-toastify";
import { styles, styleStudentSection } from './examReportCardStyles.js';





const StudentSection = (props) => {
    const { studentHeader, clsSectionDtls } = props;
    return (
        <View style={styleStudentSection.student_dtls}>
            <View style={styleStudentSection.row}>
                <Text style={styleStudentSection.section_head_text}>{'Student Profile'} :-</Text>
            </View>
            <View style={styleStudentSection.row}>
                <View style={styleStudentSection.col}>
                    <Text style={styleStudentSection.col_label}>Admission No.</Text>
                    <Text style={styleStudentSection.col_colon}>:</Text>
                    <Text style={styleStudentSection.col_value}>{studentHeader.enrollmentNo}</Text>
                </View>
            </View>
            <View style={styleStudentSection.row}>
                <View style={styleStudentSection.col}>
                    <Text style={styleStudentSection.col_label}>Name</Text>
                    <Text style={styleStudentSection.col_colon}>:</Text>
                    <Text style={styleStudentSection.col_value}>{studentHeader.studentName}</Text>
                </View>
            </View>
            <View style={styleStudentSection.row}>
                <View style={styleStudentSection.col}>
                    <Text style={styleStudentSection.col_label}>Class</Text>
                    <Text style={styleStudentSection.col_colon}>:</Text>
                    <Text style={styleStudentSection.col_value}>{clsSectionDtls.clsSection}</Text>
                </View>
            </View>
            <View style={styleStudentSection.row}>
                <View style={styleStudentSection.col}>
                    <Text style={styleStudentSection.col_label}>Father's Name</Text>
                    <Text style={styleStudentSection.col_colon}>:</Text>
                    <Text style={styleStudentSection.col_value}>{studentHeader.father}</Text>
                </View>
            </View>
            <View style={styleStudentSection.row}>
                <View style={styleStudentSection.col}>
                    <Text style={styleStudentSection.col_label}>Mother's Name</Text>
                    <Text style={styleStudentSection.col_colon}>:</Text>
                    <Text style={styleStudentSection.col_value}>{studentHeader.mother}</Text>
                </View>
            </View>
        </View>
    );
};

export default StudentSection;