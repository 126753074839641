import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import Header from 'common/js/header/header.js';
import '../css/teacher.css';
import 'common/css/master.css';
// import './../bootstrap/css/bootstrap.min.css';

import axios from 'axios';
//import {baseUrl} from './const';

var httpURL = "";
var queryString = require('querystring');
var schoolId = 1;
var lstClass = [];
var lstSubject = [];
var lstCity = [];
var lstState = [];
var lstTeacher = [];
var tokenid = localStorage.getItem("tokenid");
var screenId = 16;
var COL_TEACHERID = 'td:eq(0)';

class Teacher extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schoolId: 1,
            lstClass: [],
            lstSubject: [],
            lstTeacher: [],
            lstCity: [],
            lstState: [],
            flg: true,
            width: '97%',
            loader: 'none',
            opacity: 1,
            tokenid: tokenid
        }
    }

    componentDidMount = () => {
        this.handleResize();
        window.addEventListener("load", this.handleResize);
        window.addEventListener("resize", this.handleResize);
        this.getMasterData();
        this.fillStateCmb();
        this.fillCityCmb(parseInt($('#inp_state').find('option:selected').val()));
        this.setTeacherTable();
    }

    getMasterData = () => {
        var schoolId = 1;
        axios({
            url: httpURL + '/sms/mst/state/list',
            method: "POST",
            data: queryString.stringify({ 'schoolId': schoolId, screenId }),
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                this.setState({
                    lstState: response.data.lstState,
                })
                lstState = this.state.lstState;
                // // console.log(lstState);
                this.fillStateCmb();
            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not Logged In') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });


        axios({
            url: httpURL + '/sms/mst/class/list',
            method: "POST",
            data: queryString.stringify({ 'schoolId': schoolId, screenId }),
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                this.setState({
                    lstClass: response.data.lstClass,
                })
                lstClass = this.state.lstClass;

                // // console.log(lstClass);
                this.fillClassCmb();
            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not Logged In') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });

        axios({
            url: httpURL + '/sms/mst/subject/list',
            method: "POST",
            data: queryString.stringify({ 'schoolId': schoolId, screenId }),
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                this.setState({
                    lstSubject: response.data.lstSubject,
                })
                lstSubject = this.state.lstSubject;
                // // console.log(lstSubject);
                this.fillSubjectCmb();
            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not Logged In') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });

        axios({
            url: httpURL + '/sms/teacher/list',
            method: "POST",
            data: queryString.stringify({ 'schoolId': schoolId, screenId }),
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                this.setState({
                    lstTeacher: response.data.lstTeacher,
                })
                lstTeacher = this.state.lstTeacher;
                this.setTeacherTable();
            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not Logged In') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });

    }

    handleResize = () => {
        var width = ($('.tableLayout1').parent().width()) - 18;
        this.setState({ width: width });
    }
    fillClassCmb() {
        $('#inp_class').empty();
        $('#inp_class').prepend($("<option> </option>").val('Class').html('Class'));
        for (var i = 0; i < lstClass.length; i++) {
            var classId = lstClass[i].classId;
            var classNm = lstClass[i].className;
            $('#inp_class').append($("<option> </option>").val(classId).html(classNm));
        }
    }
    fillSubjectCmb() {
        $('#inp_subject').empty();
        $('#inp_subject').prepend($("<option> </option>").val('Subject').html('Subject'));
        for (var i = 0; i < lstSubject.length; i++) {
            var subjectId = lstSubject[i].subjectId;
            var subject = lstSubject[i].subject;
            $('#inp_subject').append($("<option> </option>").val(subjectId).html(subject));
        }
    }

    fillStateCmb() {
        $('#inp_state').empty();
        $('#inp_state').prepend($("<option> </option>").val('State').html('State'));
        for (var i = 0; i < lstState.length; i++) {
            var stateId = lstState[i].stateId;
            var state = lstState[i].state;
            $('#inp_state').append($("<option> </option>").val(stateId).html(state));
        }
    }
    fillCityCmb() {
        var stateId = $('#inp_state').find("option:selected").val();
        $('#inp_city').empty();
        $('#inp_city').prepend($("<option> </option>").val('City').html('City'));
        for (var i = 0; i < lstCity.length; i++) {
            if (stateId == lstCity[i].stateId) {
                var cityId = lstCity[i].cityId;
                var city = lstCity[i].city;
                $('#inp_city').append($("<option> </option>").val(cityId).html(city));
            }
        }
    }

    setTeacherTable = () => {
        $('#teacherTableBody tr').remove();
        var table = document.getElementById('teacherTable');
        var tbody = document.getElementById('teacherTableBody');
        var filterTeacher = $('#teacher').val() !== undefined ? $('#teacher').val().toLowerCase() : '';
        var filterEmail = $('#email').val() !== undefined ? $('#email').val().toLowerCase() : '';
        filterTeacher = filterTeacher.trim();
        filterEmail = filterEmail.trim();
        for (var i = 0; i < lstTeacher.length; i++) {
            var id = lstTeacher[i].teacherId;
            var teacher = lstTeacher[i].teacher;
            var cityId = lstTeacher[i].cityId;
            var gender = lstTeacher[i].gender;
            var stateId = lstTeacher[i].stateId;
            var email = lstTeacher[i].email;
            var pin = lstTeacher[i].pin;
            var contactNo = lstTeacher[i].mobileNo;
            var blocked = lstTeacher[i].blocked;
            var experience = lstTeacher[i].experience;
            var row = document.createElement('tr');
            row.id = id;
            row.onclick = (e) => {
                $(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');
                this.setState({ flg: false });
                this.addTeacherReady();
                this.setState({ flg: true });

            }

            if (teacher != null && teacher.toLowerCase().indexOf(filterTeacher) === -1)
                continue;
            if (email != null && email.toLowerCase().indexOf(filterEmail) === -1)
                continue;

            this.addTD(row, id, 'no-display');
            this.addTD(row, teacher, '');
            this.addTD(row, cityId, 'no-display');
            this.addTD(row, gender, '');
            this.addTD(row, stateId, 'no-display');
            this.addTD(row, email, '');
            this.addTD(row, pin, 'no-display');
            this.addTD(row, contactNo, '');
            this.addTD(row, blocked ? 'Yes' : 'No', '');
            this.addTD(row, experience, '');

            tbody.append(row);
        }
        table.append(tbody);
    }

    addTD(row, val, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var text = document.createTextNode(val);
        cell.append(text);
        row.append(cell);
    }

    addTeacherReady = () => {
        var teacherId = 0;
        var flg = false;
        if (!this.state.flg) {
            // localStorage.setItem("flg", this.state.flg);
            flg = this.state.flg;
            teacherId = $('#teacherTableBody tr.selected').attr('id')
        } else {
            // localStorage.setItem("flg", true);
            flg = true;
        }

        // localStorage.setItem("teacherId", teacherId);

        var newPageUrl = "/teacherdtls?teacherId=" + teacherId + "&flg="+flg;
        // route to new page by changing window.location
        window.open(newPageUrl, "")
    }

    highlightRow(table, id) {
        $('#' + table + ' tr').each(function () {
            var teacherId = $(this).find(COL_TEACHERID).text();
            if (teacherId === id) {
                $(this).addClass('selected').siblings().removeClass('selected');
            }
        });
    }

    getWingId = (wingId) => {
        // // console.log("wingId" + wingId);
    }

    render() {
        httpURL = lstModules['teacher'].url + ":" + lstModules['teacher'].port;
        return (
            <div >
                <Header wingFromChild={this.getWingId} />
                <ToastContainer autoClose={3000} hideProgressBar />
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: this.state.loader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>


                <div className="mainContainer" style={{ opacity: this.state.opacity }}>
                    <div className="container-fluid">
                        <div className="col-sm-9 col-centered">
                            <div className="form-group">
                                <div className="col-sm-2 padding-left-remove">
                                    <select className="form-control" id="inp_class">
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-2 padding-left-remove">
                                    <select className="form-control" id="inp_subject">
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-11 padding-remove" style={{ marginTop: 10 }}>
                                <table className="tableLayout1" style={{ width: this.state.width }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: 50, display: 'none' }}>Teacher Id</th>
                                            <th style={{ width: 100 }}>
                                                <input onChange={this.setTeacherTable} type="text" className="form-control inp-search" id="teacher" placeholder="Teacher" />
                                            </th>
                                            <th style={{ width: 50, display: 'none' }}>City Id</th>
                                            <th style={{ width: 60 }}>Gender</th>
                                            <th style={{ width: 50, display: 'none' }}>State Id</th>
                                            <th style={{ width: 170 }}><input onChange={this.setTeacherTable} type="text" className="form-control inp-search" id="email" placeholder="E-Mail Id" /></th>
                                            <th style={{ width: 100 }}>Phone No</th>
                                            <th style={{ width: 100 }}>Blocked</th>
                                            <th style={{ width: 70 }}>Experience</th>
                                        </tr>
                                    </thead>
                                </table>
                                <div className="teacherTableDiv col-sm-12 col-xs-11 padding-remove">
                                    <table id="teacherTable" className="tableLayout">
                                        <colgroup>
                                            <col width="100" />
                                            <col width="60" />
                                            <col width="170" />
                                            <col width="100" />
                                            <col width="100" />
                                            <col width="70" />
                                        </colgroup>
                                        <tbody id="teacherTableBody">
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-sm-1 col-xs-1">
                                <span id="addTeacher" title="Add Teacher" onClick={this.addTeacherReady} className="addIcon glyphicon glyphicon-plus-sign"></span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
export default Teacher;