import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import Loader from "common/components/Loader";

import "../css/schoolProfile.css";
import { saveIdCardSvc, getIDCardDtlsSvc} from "./schoolProfileSvc";


const SchoolIdCard = (props) => {
    const { schoolId } = props;
    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [phone, setPhone] = useState("");
    const [whatsapp, setWhatsapp] = useState("");
    const [email, setEmail] = useState("");
    const [website, setWebsite] = useState("");

    useEffect(() => {
        // // console.log("SchoolIdCard component called");
        getIdCardDetails();

    }, []);

    const getIdCardDetails = () => {
        // // console.log("getIdCardDetails called");
        setLoader("block");
        setOpacity(0.5);
        getIDCardDtlsSvc(handleIDCardResponse);
    }
    const handleIDCardResponse = (obj) => {
        setLoader("none");
        setOpacity(1);
      if (obj.status === "Success") {
        setPhone(obj.idCard.phone);
        setWhatsapp(obj.idCard.whatsapp);
        setEmail(obj.idCard.email);
        setWebsite(obj.idCard.website);
      } else if (obj.status === "Failure") {
        if (obj.message === "You are not logged in") {
          setTimeout(function () {
            window.location.href = "/";
          }, 2000);
          return false;
        } else if (obj.message === "Your Session is expired, login again") {
          setTimeout(function () {
            window.location.href = "/";
          }, 2000);
          return false;
        } else {
          toast.error(obj.message);
        }
      } else {
        toast.error("Service failed" + obj);
      }
    };

    
  function saveIdCardDtls(e) {
    e.preventDefault();
    var idCard = {
      phone: phone,
      email: email,
      website: website,
      whatsapp: whatsapp,
    };
    setLoader("block");
    setOpacity(0.5);
    saveIdCardSvc(idCard, handleIdCardSaveResponse);
  }
  function handleIdCardSaveResponse(obj) {
    setLoader("none");
    setOpacity(1);
    if (obj.status === "Success") {
      toast.success(obj.message);
    } else if (obj.status === "Failure") {
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed" + obj);
    }
  }

    return (
        <div className="col-xl-12 col-ls-12 col-md-12 col-sm-12 col-xs-12 padding-remove"
            style={{
                cursor: (loader === "none") ? 'pointer' : 'none',
                pointerEvents: (loader === "none") ? 'auto' : 'none',
                opacity: opacity
            }}>
            <Loader loader={loader} position="fixed" />
            <form
                className="form form-vertical col-sm-12"
                style={{ margin: "2%", border: "1px solid #ddd", borderRadius: 6 }}>
                <div
                    className="EnquiryHead col-centered"
                    style={{
                        fontWeight: "bold",
                    }}>
                    <h4>Student ID Card Details</h4>
                </div>
                <div className="row">
                    <label className="control-label col-sm-2" htmlFor="phone">
                        Phone
                    </label>
                    <div className="form-group col-sm-3">
                        <input
                            className="form-control"
                            name="phone"
                            defaultValue={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <label className="control-label col-sm-2" htmlFor="whatsapp">
                        Whatsapp
                    </label>
                    <div className="form-group col-sm-3">
                        <input
                            className="form-control"
                            name="whatsapp"
                            defaultValue={whatsapp}
                            onChange={(e) => setWhatsapp(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <label className="control-label col-sm-2" htmlFor="email">
                        Email
                    </label>
                    <div className="form-group col-sm-5">
                        <input
                            className="form-control"
                            name="email"
                            defaultValue={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <label className="control-label col-sm-2" htmlFor="website">
                        WebSite
                    </label>
                    <div className="form-group col-sm-5">
                        <input
                            className="form-control"
                            name="website"
                            defaultValue={website}
                            onChange={(e) => setWebsite(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-sm-11">
                        <button
                            className="pull-right btn btn-info"
                            title="Save"
                            onClick={(e) => saveIdCardDtls(e)}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default SchoolIdCard;
