import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

var screenId = 8;
var tokenid = localStorage.getItem("tokenid");
var httpURL = lstModules['main'].url + ":" + lstModules['main'].port;
var httpURLStudent = lstModules['students'].url + ":" + lstModules['students'].port;
var httpURLStaff = lstModules['staff'].url + ":" + lstModules['staff'].port;

function getClassListSvc(callback1) {
    // // console.log("getClassListSvc service called");
    axios({
        url: httpURL + '/sms/cls/list',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // // console.log('response in getClassListSvc', response);
        callback1(response.data);
    }).catch((error) => {
        callback1(error);
    });
}

function getSectionListSvc(callback2, pClassId) {
    // // console.log("getSectionListSvc called, pClassId passed", pClassId);
    axios({
        url: httpURLStaff + "/sms/cls/sec/list",
        method: "POST",
        params: { screenId: 8, classId: pClassId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // // console.log('response in getSectionListSvc', response);
        // // console.log('data.SvcStatus=' + response.data.SvcStatus);
        callback2(response.data);
    }).catch((error) => {
        callback2(error);
    });
}

export {getClassListSvc, getSectionListSvc}