
import React, { useEffect, useState } from 'react';
import { Text, View, } from '@react-pdf/renderer';

import { scoreSectionStyle } from './termReportPDFStyle.js';
import ScholasticSection from './termReportScholastic.js';
import SummarySection from './termReportSummarySection.js';
import CoScholasticSection from './termReportCoscholastic.js';




const TermReportScore = (props) => {
    const { studentSummary, lstScholastic, lstCoscholastic, lstExam, term } = props;


    useEffect(() => {
        // // console.log("scoreSection.js called");
        // // console.log("studentSummary:", JSON.stringify(studentSummary, null, 2));
        // // console.log("lstScholastic:", JSON.stringify(lstScholastic, null, 2));
        // // console.log("lstCoscholastic:", JSON.stringify(lstCoscholastic, null, 2));
        // // console.log("lstExam:", JSON.stringify(lstExam, null, 2));
        // // console.log("term", term);
    }, [studentSummary, lstScholastic, lstCoscholastic, lstExam, term]);


    return (
        <View style={scoreSectionStyle.container}>
            <View style={scoreSectionStyle.scho_section_header}>
                <Text style={scoreSectionStyle.section_header_text}>SCHOLASTIC AREAS</Text>
            </View>
            <View style={scoreSectionStyle.marks_section}>
                <View style={scoreSectionStyle.scho_section}>
                    <ScholasticSection
                        lstScholastic={lstScholastic}
                        lstExam={lstExam}
                        term={term}
                    />
                    <SummarySection
                        studentSummary={studentSummary}
                    />
                </View>
                <View style={scoreSectionStyle.coscho_section}>
                    <CoScholasticSection
                        studentSummary={studentSummary}
                        lstCoscholastic={lstCoscholastic}
                        lstExam={lstExam}
                    />
                </View>
            </View >
        </View >
    );
}

export default TermReportScore;