import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import 'jquery-ui';
import axios from 'axios';
import { toast } from 'react-toastify';

import ReactApexChart from 'react-apexcharts';



var httpURL = '';
var queryString = require('querystring');
var tokenid = localStorage.getItem('tokenid');
var year1 = [];
var year2 = [];
var year3 = [];
var classes = [];
var years = [];
var screenId = 4;


class classEnquiryGraph extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: 'none',
            opacity: 1,
            year1: [],
            year2: [],
            year3: [],
            options: {},
            series: [],
            tokenid: tokenid,
        }
    }
    componentDidMount = () => {
        this.getGraph();

    }
    getGraph = () => {

        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/enquiry/classcomparison?wingId=2',
            queryString.stringify({ screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {

                classes = response.data.classWiseData.classes;
                year1 = response.data.classWiseData.year1;
                year2 = response.data.classWiseData.year2;
                year3 = response.data.classWiseData.year3;
                years = response.data.classWiseData.years;

                this.setState({
                    series: [{
                        name: years[0],
                        type: 'column',
                        data: year1
                    }, {
                        name: years[1],
                        type: 'column',
                        data: year2
                    }, {
                        name: years[2],
                        type: 'column',
                        data: year3
                    }],
                    options: {
                        stroke: {
                            show: false,
                        },
                        legend: {
                            show: true,
                            showForSingleSeries: false,
                            showForNullSeries: true,
                            showForZeroSeries: true,
                            position: 'top',
                            horizontalAlign: 'center',
                            floating: false,
                            fontSize: '14px',
                            fontWeight: 400,
                            offsetX: 100,
                            offsetY: 0,
                        },

                        markers: {
                            size: [4, 4, 4],
                        },
                        title: {
                            text: 'Class Wise  Enquiry',
                            align: 'left',
                        },
                        dataLabels: {
                            enabled: true,
                            textAnchor: 'start',
                            style: {
                                fontSize: '15px',
                                fontWeight: 'bold',
                            },
                        },
                        xaxis: {
                            categories: classes,
                            labels: {
                                rotate: -45,
                                show: true,

                            },
                            axisTicks: {
                                show: false,
                            },
                            title: {
                                text: 'Classes',
                                offsetX: 0,
                                offsetY: -20,
                                style: {
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                },
                            },
                        },
                        yaxis: [{
                            axisTicks: {
                                show: true,
                            },
                            axisBorder: {
                                show: true,
                                color: '#000'
                            },
                            labels: {
                                style: {
                                    color: '#000',
                                    fontSize: 13
                                }
                            },
                            title: {
                                text: 'Enquiry',
                                style: {
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                },
                            },
                            tooltip: {
                                enabled: false
                            }

                        }]
                    },
                })
            }
            else {

                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }
    render() {
        httpURL = lstModules['enquiry'].url + ":" + lstModules['enquiry'].port;
        return (
            <div className="classEnquiryGraph graphWrapper" style={{ opacity: this.state.opacity }}>
                <div className="col-sm-10 col-centered">
                    <ReactApexChart type="bar" options={this.state.options} series={this.state.series} height={500} />
                </div>
            </div>
        );
    }
}
export default classEnquiryGraph;