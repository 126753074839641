import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import Header from "common/js/header/header.js";
import axios from 'axios'
import lstModules from 'common/json/lstModules.json';
const httpURL = lstModules['transport'].url + ':' + lstModules['transport'].port;
var tokenid = localStorage.getItem('tokenid');
function StudentTable(props) {
    const [lstStudent, setLstStudent] = useState([]);
    const [loader, setLoader] = useState('none');
    const [studentId, setStudentId] = useState(0);

    const [filteredEnroll, setFilteredEnroll] = useState("");
    const [filteredName, setFilteredName] = useState("");
    const [filteredContact, setFilteredContact] = useState("");
    const [filteredLocality, setFilteredLocality] = useState("");
    const [filteredRoute, setFilteredRoute] = useState("");
    const [isWithTransportSelected, setIsWithTranportSelected] = useState(false);
    const [isWithoutTransportSelected, setIsWithoutTranportSelected] = useState(false);
    const [lstFound, setLstFound] = useState(lstStudent);

    const [countTransportAssigned, setCountTransportAssigned] = useState(0);
    const [countTransporUnassigned, setCountTransportUnassigned] = useState(0);



    useEffect(() => {
        getLstStudent(cbGetLstStudent)
    }, []);


    function getLstStudent(callBack) {
        axios({
            url: httpURL + '/sms/tpt/student/list',
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }).then((Response) => {
            var data = Response.data
            callBack(data)
        }).catch((error) => {
            // // console.log('error' + error)
        });
    }
    function cbGetLstStudent(data) {
        setLoader('none')
        if (data.SvcStatus === 'Success') {
            setLstStudent(data.lstStudent);
            setLstFound(data.lstStudent);
            countTransportStudents(data.lstStudent);
        }
        else {
            toast.error(data.SvcMsg)
        }
    }
    function giveSelData(sId) {
        // // console.log("giveSelData called");
        const sObject = lstStudent.find((obj) => obj.studentId === sId);
        // // console.log("found object, sObject", sObject);
        props.getSelData(sId, sObject);

    }

    function handleCbxChange(e, studentId) {
        var check = e.target.checked;
        axios({
            url: httpURL + '/sms/tpt/student/opt_transport',
            method: 'POST',
            params: { studentId: studentId, flgSchoolTpt: check },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }).then((Response) => {
            var data = Response.data
            if (data.SvcStatus === 'Success') {
                toast.success(data.SvcMsg)
                if (check) {

                    getDistanceSvc(studentId, cbGetDistance)
                }
                else {
                    removeDistance(studentId)
                }

            }
            else {
                // // console.log(document.getElementById("cbx" + studentId))
                document.getElementById("cbx" + studentId).checked = false
                toast.error(data.SvcMsg)
            }
        }).catch((error) => {
            // // console.log('error' + error)
        });
    }
    function removeDistance(studentId) {

        document.getElementById("sd" + studentId).textContent = "-";
        document.getElementById("rn" + studentId).textContent = "-";
        document.getElementById("vt" + studentId).textContent = "-";
        document.getElementById("sn" + studentId).textContent = "-";
        document.getElementById("sr" + studentId).textContent = "-";
        document.getElementById("bd" + studentId).textContent = "-";
    }
    function getDistanceSvc(studentId, cbGetDistance) {
        axios({
            url: httpURL + '/sms/tpt/student/get_distance',
            method: 'POST',
            params: { studentId: studentId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }).then((Response) => {
            var data = Response.data
            cbGetDistance(data, studentId)
        }).catch((error) => {
            // // console.log('error' + error)
        });
    }
    function cbGetDistance(data, studentId) {
        if (data.SvcStatus === 'Success') {
            // // console.log(data.schoolDistance)
            document.getElementById("sd" + studentId).textContent = data.schoolDistance
        }
        else {
            // // console.log(document.getElementById("cbx" + studentId))
            toast.error(data.SvcMsg)
        }
    }

    function handleSelRow(sId) {
        setStudentId(sId);
    }

    const filterName = (e) => {
        const keyword = e.target.value;
        setFilteredName(keyword);
        filterFn(filteredEnroll, keyword, filteredContact, filteredLocality, filteredRoute, isWithTransportSelected, isWithoutTransportSelected);
    };
    const filterEno = (e) => {
        const keyword = e.target.value;
        setFilteredEnroll(keyword);
        filterFn(keyword, filteredName, filteredContact, filteredLocality, filteredRoute, isWithTransportSelected, isWithoutTransportSelected);
    };
    const filterPNo = (e) => {
        const keyword = e.target.value;
        setFilteredContact(keyword);
        filterFn(filteredEnroll, filteredName, keyword, filteredLocality, filteredRoute, isWithTransportSelected, isWithoutTransportSelected);
    };
    const filterLoc = (e) => {
        const keyword = e.target.value;
        setFilteredLocality(keyword);
        filterFn(filteredEnroll, filteredName, filteredContact, keyword, filteredRoute, isWithTransportSelected, isWithoutTransportSelected);
    };
    const filterRt = (e) => {
        const keyword = e.target.value;
        setFilteredRoute(keyword);
        filterFn(filteredEnroll, filteredName, filteredContact, filteredLocality, keyword, isWithTransportSelected, isWithoutTransportSelected);
    };
    const selectTransportOnly = () => {
        // setIsWithTranportSelected(check);
        setIsWithTranportSelected(true);
        setIsWithoutTranportSelected(false);
        // filterFn(filteredEnroll, filteredName, filteredContact, filteredLocality, filteredRoute, check, isWithoutTransportSelected);
        filterFn(filteredEnroll, filteredName, filteredContact, filteredLocality, filteredRoute, true, false);
    }
    const selectWithoutTransport = () => {
        // setIsWithoutTranportSelected(check);
        setIsWithoutTranportSelected(true);
        setIsWithTranportSelected(false);
        // filterFn(filteredEnroll, filteredName, filteredContact, filteredLocality, filteredRoute, isWithTransportSelected, check);
        filterFn(filteredEnroll, filteredName, filteredContact, filteredLocality, filteredRoute, false, true);
    }

    const removeTransportFilter = () => {
        setIsWithoutTranportSelected(false);
        setIsWithTranportSelected(false);
        filterFn(filteredEnroll, filteredName, filteredContact, filteredLocality, filteredRoute, false, false);
    }

    async function filterFn(fEnroll, fName, fContact, fLocality, fRoute, isWithTransportSelected, isWithoutTransportSelected) {
        var tmpLst = [];
        if (lstStudent && lstStudent.length) {
            tmpLst = lstStudent.filter((student) => student.enrollmentNo === null || student.enrollmentNo === undefined || student.enrollmentNo.toLowerCase().includes(fEnroll.toLowerCase()));
            tmpLst = tmpLst.filter((student) => student.name.toLowerCase().includes(fName.toLowerCase()));
            tmpLst = tmpLst.filter((student) => student.phoneNo.toLowerCase().includes(fContact.toLowerCase()));
            tmpLst = tmpLst.filter((student) => student.locality.toLowerCase().includes(fLocality.toLowerCase()));
            tmpLst = tmpLst.filter((student) => student.routeNo.toLowerCase().includes(fRoute.toLowerCase()));

            if (isWithTransportSelected) {
                tmpLst = tmpLst.filter((student) => student.flgSchoolTpt === true);
            } else if (isWithoutTransportSelected) {
                tmpLst = tmpLst.filter((student) => student.flgSchoolTpt === false);
            }
        }

        setLstFound(tmpLst);
    }

    const countTransportStudents = (lstStd) => {
        let stdTransport = 0;
        let stdNoTransport = 0;
        if (lstStd && lstStd.length) {
            lstStd.map((student) => {
                if (student.flgSchoolTpt === true) {
                    stdTransport++;
                } else if (student.flgSchoolTpt === false) {
                    stdNoTransport++;
                }
            })
            setCountTransportAssigned(stdTransport);
            setCountTransportUnassigned(stdNoTransport);
        }
    }

    const colgrp = (
        <colgroup>
            <col width="8%"></col>
            <col width="8%"></col>
            <col width="8%"></col>
            <col width="4%"></col>
            <col width="4%"></col>
            <col width="4%"></col>
            <col width="4%"></col>
            <col width="6%"></col>
            <col width="6%"></col>
            <col width="4%"></col>
            <col width="6%"></col>
            <col width="6%"></col>
            <col width="6%"></col>
            <col width="6%"></col>
            <col width="6%"></col>
            <col width="8%"></col>
        </colgroup>
    );

    return (
        <div style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="col-sm-11 col-centered padding-remove" >
                <div className="col-sm-12 padding-remove" style={{ marginBottom: "5px" }} >
                    <div className="col-sm-3 padding-remove div_summary" >
                        <div className={"col-sm-4 padding-remove"} style={{ marginTop: "5px" }}
                            onClick={() => removeTransportFilter()}>
                            <div
                                className="circle-transport-students col-sm-12 col-centered"
                                style={{
                                    background: (!isWithTransportSelected && !isWithoutTransportSelected) ? '#4a86e8' : '',
                                    color: (!isWithTransportSelected && !isWithoutTransportSelected) ? '#fff' : ''
                                }}>
                                <div className="div-transport-student-count col-sm-12 col-centered" >{countTransportAssigned + countTransporUnassigned}</div>
                            </div>
                            <div className="text-center">All Students</div>
                        </div>
                        <div className={"col-sm-4 padding-remove"} style={{ marginTop: "5px" }}
                            onClick={() => selectTransportOnly()}>
                            <div
                                className="circle-transport-students col-sm-12 col-centered"
                                style={{
                                    background: isWithTransportSelected ? '#4a86e8' : '',
                                    color: isWithTransportSelected ? '#fff' : ''
                                }}>
                                <div className="div-transport-student-count col-sm-12 col-centered" >{countTransportAssigned}</div>
                            </div>
                            <div className="text-center">With Transport</div>
                        </div>
                        <div className={"col-sm-4 padding-remove"} style={{ marginTop: "5px" }}
                            onClick={() => selectWithoutTransport()}>
                            <div
                                className="circle-transport-students col-sm-12 col-centered"
                                style={{
                                    background: isWithoutTransportSelected ? '#4a86e8' : '',
                                    color: isWithoutTransportSelected ? '#fff' : ''
                                }}>
                                <div className="div-transport-student-count col-sm-12 col-centered" >{countTransporUnassigned}</div>
                            </div>
                            <div className="text-center">Without Transport</div>
                        </div>
                    </div>
                    {/* <div className="col-sm-5 padding-remove pull-right">
                        <div className="col-sm-4 padding-remove">
                            <button className={(isWithTransportSelected || isWithoutTransportSelected) ? "filterButtonOff" : "filterButtonOn"} onClick={() => removeTransportFilter()}>All Students {countTransportAssigned + countTransporUnassigned}</button>
                        </div>
                        <div className="col-sm-4 padding-remove">
                            <button className={isWithTransportSelected ? "filterButtonOn" : "filterButtonOff"} onClick={() => selectTransportOnly()}>transport assigned {countTransportAssigned}</button>
                        </div>
                        <div className="col-sm-4 padding-remove">
                            <button className={isWithoutTransportSelected ? "filterButtonOn" : "filterButtonOff"} onClick={() => selectWithoutTransport()}>transport not assigned {countTransporUnassigned}</button>
                        </div>
                        <div className="col-sm-3">
                        <span>Export</span>
                        <span style={{ padding: '4px' }}><button>XLS</button></span>
                    </div>
                    </div> */}
                </div>
                <div>
                    <table className="tableLayout1 table-head-borders">
                        {colgrp}
                        <thead>
                            <tr>
                                <th style={{ width: 75 }}>
                                    <input onChange={(e) => filterEno(e)} type="text" value={filteredEnroll} className="form-control inp-search" placeholder="Enrollment Number" />
                                </th>
                                <th style={{ width: 75 }}>
                                    <input onChange={(e) => filterName(e)} type="text" value={filteredName} className="form-control inp-search" placeholder="Name" />
                                </th>
                                <th style={{ width: 75 }}>
                                    <input onChange={(e) => filterPNo(e)} type="text" value={filteredContact} className="form-control inp-search" placeholder="Contact Number" />
                                </th>
                                <th>Class</th>
                                <th>Section</th>
                                <th>Gender</th>
                                <th>Age</th>
                                <th style={{ width: 75 }}>
                                    <input onChange={(e) => filterLoc(e)} type="text" value={filteredLocality} className="form-control inp-search" placeholder="Locality" />
                                </th>
                                <th>School Tpt</th>
                                <th>School Distance</th>
                                <th style={{ width: 75 }}>
                                    <input onChange={(e) => filterRt(e)} type="text" value={filteredRoute} className="form-control inp-search" placeholder="Route" />
                                </th>
                                <th>Vehicle Type</th>
                                <th>Stop</th>
                                <th>Stop Range</th>
                                <th>Distance (from home)</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                    </table>
                    <div className="tableDiv2" style={{height:"450px"}}>
                        <table id="transportStudentTable" className="tableLayout">
                            {colgrp}
                            <tbody>
                                {
                                    lstFound.map((obj) => {
                                        return (
                                            <tr onClick={(e) => handleSelRow(obj.studentId)} className={studentId === obj.studentId ? 'selected' : ''} key={obj.studentId}>
                                                <td>{obj.enrollmentNo}</td>
                                                <td>{obj.name}</td>
                                                <td>{obj.phoneNo}</td>
                                                <td>{obj.cls}</td>
                                                <td>{obj.section}</td>
                                                <td>{obj.gender}</td>
                                                <td>{obj.age}</td>
                                                <td>{obj.locality}</td>
                                                <td align="center">
                                                    <input type='checkbox' id={"cbx" + obj.studentId} onChange={(e) => handleCbxChange(e, obj.studentId)} defaultChecked={obj.flgSchoolTpt}></input>
                                                </td>
                                                <td id={"sd" + obj.studentId}>{obj.schoolDistance}</td>
                                                <td id={"rn" + obj.studentId}>{obj.routeNo}</td>
                                                <td id={"vt" + obj.studentId}>{obj.vehicleType}</td>
                                                <td id={"sn" + obj.studentId}>{obj.stopNo}</td>
                                                <td id={"sr" + obj.studentId}>{obj.stopRange}</td>
                                                <td id={"bd" + obj.studentId}>{obj.baseDistance}</td>
                                                <td align="center">
                                                    <span className="glyphicon glyphicon-edit" style={{ pointerEvents: obj.flgSchoolTpt ? 'auto' : 'none', opacity: obj.flgSchoolTpt ? 1 : 0.4 }} onClick={(e) => giveSelData(obj.studentId)}></span>
                                                    <span className="" >Publish</span>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>)
}
export default StudentTable;