import React, { useState, useEffect } from 'react'

import { deleteStudentSvc } from './studentsSvc';
import { toast } from 'react-toastify';


const StudentTable = (props) => {
    const { setLoader, setOpacity, lstClass, lstStudent, studentId, setStudentId, lstSelStudentId, setLstSelStudentId, setStudentCount, viewIdCard, editStudent, updateKey } = props;


    const [lstStudentFiltered, setLstStudentFiltered] = useState([]);
    const [filteredSection, setFilteredSection] = useState("");
    const [filteredStudentId, setFilteredStudentId] = useState("");
    const [filteredStudent, setFilteredStudent] = useState("");
    const [filteredClassId, setFilteredClass] = useState(0);
    const [filteredAcademicYear, setFitlteredAcademicYear] = useState("");
    const [flgFilterBlocked, setFlgFilterBlocked] = useState(false);
    const [flgSelectAll, setFlgSelectAll] = useState(false);



    const colgrp = <colgroup>
        <col width="40"></col>
        <col width="150"></col>
        <col width="50"></col>
        <col width="60"></col>
        <col width="70"></col>
        <col width="50"></col>
        <col width="75"></col>
        <col width="60"></col>
        <col width="60"></col>
        <col width="110"></col>
    </colgroup>


    useEffect(() => {
        // // console.log("StudentTable component called, lstStudent", lstStudent);
        setLstStudentFiltered(lstStudent);
        setFilteredClass(0);
        setFilteredSection("");
        setStudentId(0);
        setFilteredStudent("");
        setFitlteredAcademicYear("");
    }, [lstStudent]);



    const filterSection = (fSection) => {
        // // console.log('filterSeciton called, fSection', fSection);
        setFilteredSection(fSection);
        // // console.log("filteredSection", filteredSection);
        filterStudentTable(fSection, filteredStudent, filteredStudentId, filteredClassId, filteredAcademicYear, flgFilterBlocked);
    }

    const filterStudent = (fStudent) => {
        // // console.log('filterStudent called, fStudent', fStudent);
        setFilteredStudent(fStudent);
        filterStudentTable(filteredSection, fStudent, filteredStudentId, filteredClassId, filteredAcademicYear, flgFilterBlocked);
    };

    const filterStudentId = (fStudentId) => {
        // // console.log('filterStudentId called, fStudentId', fStudentId);
        setFilteredStudentId(fStudentId);
        filterStudentTable(filteredSection, filteredStudent, fStudentId, filteredClassId, filteredAcademicYear, flgFilterBlocked);
    };


    const filterAcademicYear = (fAcademicYear) => {
        // // console.log('filterAcademicYear called, fAcademicYear', fAcademicYear);
        setFitlteredAcademicYear(fAcademicYear);
        filterStudentTable(filteredSection, filteredStudent, filteredStudentId, filteredClassId, fAcademicYear, flgFilterBlocked);
    };

    const handleClassFilterChange = (fClassId) => {
        // // console.log("handleClassFilterChange called, fClassId ", fClassId);
        setFilteredClass(fClassId);
        filterStudentTable(filteredSection, filteredStudent, filteredStudentId, fClassId, filteredAcademicYear, flgFilterBlocked);
    }

    const toggleFilterBlocked = (pCheck) => {
        // // console.log("toggleFilterBlocked called pCheck", pCheck);
        setFlgFilterBlocked(pCheck);
        filterStudentTable(filteredSection, filteredStudent, filteredStudent, filteredClassId, filteredAcademicYear, pCheck);
    }

    const filterStudentTable = async (fSection, fStudent, fStudentId, fClass, fAcademicYear, fBlocked) => {
        // // console.log("filterStudentTable called");
        // // console.log("lstStudent:", lstStudent);
        // // console.log("Section:", fSection);
        // // console.log("Student:", fStudent);
        // // console.log("Student ID:", fStudentId);
        // // console.log("Class:", fClass);
        // // console.log("Academic Year:", fAcademicYear);
        // // console.log("Blocked:", fBlocked);

        let filteredStudentTable = [];
        filteredStudentTable = lstStudent.filter(student =>
            student.studentId.toString().includes(fStudentId)
        );
        filteredStudentTable = filteredStudentTable.filter(student =>
            student.section === null || student.section === undefined || student.section.toLowerCase().includes(fSection.toString().toLowerCase())
        );
        filteredStudentTable = filteredStudentTable.filter(student =>
            student.student.toLowerCase().includes(fStudent.toLowerCase())
        );
        filteredStudentTable = filteredStudentTable.filter(student =>
            student.academicYear.includes(fAcademicYear)
        );
        if (fClass != 0) {
            filteredStudentTable = filteredStudentTable.filter(student =>
                student.clsId == fClass
            );
        }

        if (fBlocked) {
            filteredStudentTable = filteredStudentTable.filter(student =>
                student.blocked
            );
        }

        await setLstStudentFiltered(filteredStudentTable);
        setStudentCount(filteredStudentTable.length);
    }

    const toggleSelectAll = (pCheck) => {
        // // console.log("toggleSelectAll called pCheck", pCheck);
        if (pCheck) {
            const newSelStudentIds = lstStudentFiltered.map(student => student.studentId);
            setLstSelStudentId(newSelStudentIds);
        } else {
            setLstSelStudentId([]);
        }
        setFlgSelectAll(pCheck);
    };


    const handleCheckboxChange = (sId) => {
        if (lstSelStudentId.includes(sId)) {
            const updatedLstSelectedStudent = lstSelStudentId.filter((id) => id !== sId);
            setLstSelStudentId(updatedLstSelectedStudent);
            if (flgSelectAll) {
                setFlgSelectAll(false);
            }
        } else {
            setLstSelStudentId([...lstSelStudentId, sId]);
            if (!flgSelectAll && lstStudent.length - 1 === lstSelStudentId.length) {
                setFlgSelectAll(true);
            }
        }
    }

    const handleDelete = async (studentId) => {
        await setStudentId(studentId);
        if (window.confirm('Do you want to delete this student')) {
            setLoader("block");
            setOpacity(0.5);
            deleteStudentSvc(studentId, handleDeleteResponse);
        } else {
            // // console.log('Cancel Pressed');
        }
    };

    const handleDeleteResponse = (data) => {
        let obj = data;
        if (obj.status === 'Success') {
            toast.success(obj.message);
            updateKey();
        }
        else {
            handleServiceError(obj.message);
        }
        setLoader('none');
        setOpacity(1);
    };


    const handleServiceError = (svcMsg) => {
        if (svcMsg === "You are not Logged In") {
            toast.error("You are not logged in. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else if (svcMsg === "Server Timed Out, Login Again") {
            toast.error("Server timed out. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else {
            toast.error(svcMsg);
        }
    };




    return (
        <div >
            <div className="col-md-12 col-xs-12 col-centered padding-remove " >
                <table className="tableLayout1" >
                    {colgrp}
                    <thead>
                        <tr>
                            <th >
                                <input
                                    type="text" className="form-control inp-search"
                                    name="Student Id"
                                    value={filteredStudentId}
                                    onChange={(e) => filterStudentId(e.target.value)}
                                    placeholder="Id" />
                            </th>
                            <th >
                                <input
                                    type="text" className="form-control inp-search"
                                    value={filteredStudent}
                                    onChange={(e) => filterStudent(e.target.value)}
                                    name="student name"
                                    placeholder="Student" />
                            </th>
                            <th > Gender </th>
                            <th > Age </th>
                            <th >
                                <select
                                    className='form-control  inp-search'
                                    // style={{border:"1px solid #ccc"}}
                                    value={filteredClassId}
                                    onChange={e => handleClassFilterChange(e.target.value)}
                                >
                                    <option value={0}>Class</option>
                                    {lstClass.map((obj, idx) => {
                                        return (
                                            <option key={idx} value={obj.clsId}>
                                                {obj.cls}
                                            </option>
                                        )
                                    })}
                                </select>
                            </th>
                            <th >
                                <input
                                    type="text" className="form-control inp-search"
                                    name="Section"
                                    value={filteredSection}
                                    onChange={(e) => filterSection(e.target.value)}
                                    placeholder="Section" />
                            </th>
                            <th >
                                <input
                                    type="text" className="form-control inp-search"
                                    name="Academic Year"
                                    id="academicYear"
                                    value={filteredAcademicYear}
                                    onChange={(e) => filterAcademicYear(e.target.value)}
                                    placeholder="Academic Year" />
                            </th>
                            <th >
                                <div style={{ float: 'left', width: '70%', marginTop: 5 }}>Blocked</div>
                                <div style={{ width: '15%', float: 'left' }}>
                                    <input
                                        type="checkbox"
                                        className="form-control"
                                        id="inp_blocked"
                                        checked={flgFilterBlocked}
                                        onChange={(e) => toggleFilterBlocked(e.target.checked)}
                                        disabled={false}
                                    />
                                </div>
                            </th>
                            <th >
                                <div style={{ float: 'left', width: '70%', marginTop: 5 }}>Select</div>
                                <div style={{ width: '15%', float: 'left' }}>
                                    <input
                                        type="checkbox"
                                        className="form-control"
                                        id="select_all"
                                        checked={flgSelectAll}
                                        onChange={(e) => toggleSelectAll(e.target.checked)}
                                        disabled={false}
                                    />
                                </div>
                            </th>
                            <th > Action </th>
                        </tr>
                    </thead>
                </table>
                <div className="tableDiv" style={{ height: "500px" }}>
                    <table className="tableLayout">
                        {colgrp}
                        <tbody id="studentBodyTable" >
                            {lstStudentFiltered &&
                                lstStudentFiltered.map((student, i) => (
                                    <tr key={student.studentId}
                                        onClick={() => setStudentId(student.studentId)}
                                        className={student.studentId === studentId ? "selected" : ''}>
                                        <td className="text-left">{student.studentId}</td>
                                        <td className="text-left">{student.student}</td>
                                        <td className="text-center">{student.gender}</td>
                                        <td className="text-center">{student.age}</td>
                                        <td className="text-center">{student.cls ? student.cls : ''}</td>
                                        <td className="text-center">{student.section ? student.section : ''}</td>
                                        <td className="text-center">{student.academicYear}</td>
                                        <td className="text-center">{student.blocked ? "Yes" : "-"}</td>
                                        <td className="text-center" >
                                            <input type="checkbox"
                                                className="formControl"
                                                checked={lstSelStudentId.includes(student.studentId)}
                                                onChange={() => handleCheckboxChange(student.studentId)}
                                                onClick={(e) => { e.stopPropagation(); }}
                                                disabled={false}
                                            />
                                        </td>
                                        <td className="text-center" >
                                                <span
                                                    onClick={() => editStudent(student.studentId)}
                                                    title="Delete"
                                                    style={{ textDecoration: "underline", cursor: "pointer", color: "blue", margin:"5px"}}
                                                >
                                                    Edit
                                                </span>
                                                <span
                                                    onClick={() => handleDelete(student.studentId)}
                                                    title="Delete"
                                                    style={{ textDecoration: "underline", cursor: "pointer", color: "blue", margin:"5px" }}
                                                >
                                                    Delete
                                                </span>
                                                <span
                                                    onClick={() => viewIdCard(student.studentId)}
                                                    title="ID Card"
                                                    style={{ textDecoration: "underline", cursor: "pointer", color: "blue", margin:"5px" }}
                                                >
                                                    ID Card
                                                </span>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default StudentTable;

