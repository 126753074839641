import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "common/css/master.css";
import "../css/transport.css";
import {
  getRouteStaffDtlsSvc,
  getRouteStaffListSvc,
  saveRouteStaffSvc,
} from "./routeSvc";
const RouteStaffSlider = (props) => {
  const [loader, setLoader] = useState("none");
  const [lstDriver, setLstDriver] = useState([]);
  const [lstConductor, setLstConductor] = useState([]);
  const [lstDriverHelper, setLstDriverHelper] = useState([]);
  const [lstFemaleAssistant, setLstFemaleAssistant] = useState([]);
  const [lstGuard, setLstGuard] = useState([]);
  const [lstCoordinator, setLstCoordinator] = useState([]);
  /*const [mainDriver, setMainDriver] = useState("");
  const [mainConductor, setMainConductor] = useState("");
  const [mainDriverHelper, setMainDriverHelper] = useState("");
  const [mainFemaleAssistant, setMainFemlaleAssistant] = useState("");
  const [mainGuard, setMainGuard] = useState("");
  const [mainCoordinator, setMainCoordinator] = useState("");
  const [standByDriver, setStandByDriver] = useState("");
  const [standByConductor, setStandByConductor] = useState("");
  const [standByDriverHelper, setStandByDriverHelper] = useState("");
  const [standByFemaleAssistant, setStandByFemlaleAssistant] = useState("");
  const [standByGuard, setStandByGuard] = useState("");
  const [standByCoordinator, setStandByCoordinator] = useState("");*/
  const [mainDriverId, setMainDriverId] = useState(0);
  const [mainConductorId, setMainConductorId] = useState(0);
  const [mainDriverHelperId, setMainDriverHelperId] = useState(0);
  const [mainFemaleAssistantId, setMainFemaleAssistantId] = useState(0);
  const [mainGuardId, setMainGuardId] = useState(0);
  const [mainCoordinatorId, setMainCoordinatorId] = useState(0);
  const [standByDriverId, setStandByDriverId] = useState(0);
  const [standByConductorId, setStandByConductorId] = useState(0);
  const [standByDriverHelperId, setStandByDriverHelperId] = useState(0);
  const [standByFemaleAssistantId, setStandByFemaleAssistantId] = useState(0);
  const [standByGuardId, setStandByGuardId] = useState(0);
  const [standByCoordinatorId, setStandByCoordinatorId] = useState(0);

  useEffect(() => {
    if (props.flgStaff && props.routeId) {
      setLoader("block");
      getRouteStaffListSvc(15, cbDriverList);
      getRouteStaffListSvc(29, cbConductorList);
      getRouteStaffListSvc(30, cbDriverHelperList);
      getRouteStaffListSvc(31, cbFemaleAssistantList);
      getRouteStaffListSvc(32, cbGuardList);
      getRouteStaffListSvc(33, cbCoordinatorList);
    }
  }, [props]);
  function cbDriverList(data) {
    if (data.SvcStatus === "Success") {
      setLstDriver(data.lstStaff);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function cbConductorList(data) {
    if (data.SvcStatus === "Success") {
      setLstConductor(data.lstStaff);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function cbDriverHelperList(data) {
    if (data.SvcStatus === "Success") {
      setLstDriverHelper(data.lstStaff);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function cbFemaleAssistantList(data) {
    if (data.SvcStatus === "Success") {
      setLstFemaleAssistant(data.lstStaff);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function cbGuardList(data) {
    if (data.SvcStatus === "Success") {
      setLstGuard(data.lstStaff);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function cbCoordinatorList(data) {
    if (data.SvcStatus === "Success") {
      setLstCoordinator(data.lstStaff);
      getRouteStaffDtlsSvc(props.routeId, cbRouteStaffDtls);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function cbRouteStaffDtls(data) {
    if (data.SvcStatus === "Success") {
      setMainConductorId(data.staff.mainConductorId);
      setMainCoordinatorId(data.staff.mainCoordinatorId);
      setMainDriverId(data.staff.mainDriverId);
      setMainFemaleAssistantId(data.staff.mainFemaleAssistantId);
      setMainGuardId(data.staff.mainGuardId);
      setMainDriverHelperId(data.staff.mainHelperId);
      setStandByConductorId(data.staff.standByConductorId);
      setStandByCoordinatorId(data.staff.standByCoordinatorId);
      setStandByDriverId(data.staff.standByDriverId);
      setStandByFemaleAssistantId(data.staff.standByFemaleAssistantId);
      setStandByGuardId(data.staff.standByGuardId);
      setStandByDriverHelperId(data.staff.standByHelperId);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function handleMainDriverChange(param) {
    setMainDriverId(param);
  }
  function handleMainConductorChange(param) {
    setMainConductorId(param);
  }
  function handleMainDriverHelperChange(param) {
    setMainDriverHelperId(param);
  }
  function handleMainFemaleAssistantChange(param) {
    setMainFemaleAssistantId(param);
  }
  function handleMainGuardChange(param) {
    setMainGuardId(param);
  }
  function handleMainCoordinatorChange(param) {
    setMainCoordinatorId(param);
  }
  function handleStandByDriverChange(param) {
    setStandByDriverId(param);
  }
  function handleStandByConductorChange(param) {
    setStandByConductorId(param);
  }
  function handleStandByDriverHelperChange(param) {
    setStandByDriverHelperId(param);
  }
  function handleStandByFemaleAssistantChange(param) {
    setStandByFemaleAssistantId(param);
  }
  function handleStandByGuardChange(param) {
    setStandByGuardId(param);
  }
  function handleStandByCoordinatorChange(param) {
    setStandByCoordinatorId(param);
  }
  function saveRouteStaff() {
    /*var tempDriverId = 0;
    var tempConductorId = 0;
    var tempDriverHelperId = 0;
    var tempFemaleAssistantId = 0;
    var tempGuardId = 0;
    var tempCoordinatorId = 0;
    var tempStandByDriverId = 0;
    var tempStandByConductorId = 0;
    var tempStandByDriverHelperId = 0;
    var tempStandByFemaleAssistantId = 0;
    var tempStandByGuardId = 0;
    var tempStandByCoordinatorId = 0;
    for (var i = 0; i < lstDriver.length; i++) {
      if (lstDriver[i].name === mainDriver) {
        tempDriverId = lstDriver[i].staffId;
      }
      if (lstDriver[i].name === standByDriver) {
        tempStandByDriverId = lstDriver[i].staffId;
      }
    }
    for (var i = 0; i < lstConductor.length; i++) {
      if (lstConductor[i].name === mainConductor) {
        tempConductorId = lstConductor[i].staffId;
      }
      if (lstConductor[i].name === standByConductor) {
        tempStandByConductorId = lstConductor[i].staffId;
      }
    }
    for (var i = 0; i < lstDriverHelper.length; i++) {
      if (lstDriverHelper[i].name === mainDriverHelper) {
        tempDriverHelperId = lstDriverHelper[i].staffId;
      }
      if (lstDriverHelper[i].name === standByDriverHelper) {
        tempStandByDriverHelperId = lstDriverHelper[i].staffId;
      }
    }
    for (var i = 0; i < lstFemaleAssistant.length; i++) {
      if (lstFemaleAssistant[i].name === mainFemaleAssistant) {
        tempFemaleAssistantId = lstFemaleAssistant[i].staffId;
      }
      if (lstFemaleAssistant[i].name === standByFemaleAssistant) {
        tempStandByFemaleAssistantId = lstFemaleAssistant[i].staffId;
      }
    }
    for (var i = 0; i < lstGuard.length; i++) {
      if (lstGuard[i].name === mainGuard) {
        tempGuardId = lstGuard[i].staffId;
      }
      if (lstGuard[i].name === standByGuard) {
        tempStandByGuardId = lstGuard[i].staffId;
      }
    }
    for (var i = 0; i < lstCoordinator.length; i++) {
      if (lstCoordinator[i].name === mainCoordinator) {
        tempCoordinatorId = lstCoordinator[i].staffId;
      }
      if (lstCoordinator[i].name === standByCoordinator) {
        tempStandByCoordinatorId = lstCoordinator[i].staffId;
      }
    }
    const pstaffObj = {
      mainDriverId: tempDriverId,
      mainConductorId: tempConductorId,
      mainHelperId: tempDriverHelperId,
      mainGuardId: tempGuardId,
      mainFemaleAssistantId: tempFemaleAssistantId,
      mainCoordinatorId: tempCoordinatorId,
      standByDriverId: tempStandByDriverId,
      standByConductorId: tempStandByConductorId,
      standByHelperId: tempStandByDriverHelperId,
      standByGuardId: tempStandByGuardId,
      standByFemaleAssistantId: tempStandByFemaleAssistantId,
      standByCoordinatorId: tempStandByCoordinatorId,
    };*/
    const pstaffObj = {
      mainDriverId: mainDriverId,
      mainConductorId: mainConductorId,
      mainHelperId: mainDriverHelperId,
      mainGuardId: mainGuardId,
      mainFemaleAssistantId: mainFemaleAssistantId,
      mainCoordinatorId: mainCoordinatorId,
      standByDriverId: standByDriverId,
      standByConductorId: standByConductorId,
      standByHelperId: standByDriverHelperId,
      standByGuardId: standByGuardId,
      standByFemaleAssistantId: standByFemaleAssistantId,
      standByCoordinatorId: standByCoordinatorId,
    };
    setLoader("block");
    saveRouteStaffSvc(props.routeId, pstaffObj, cbSaveRouteStaff);
  }
  function cbSaveRouteStaff(data) {
    setLoader("none");
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
    } else {
      toast.error(data.SvcMsg);
    }
  }
  return (
    <>
      <form
        className="form form-horizontal"
        style={{
          pointerEvents: loader === "none" ? "auto" : "none",
          height: "auto",
          width: "90%",
          margin: "10px auto",
          background: "#fff",
          border: "1px solid #ccc",
          borderRadius: 10,
        }}
      >
        <button
          type="button"
          className="btn btn-info text-center btnCloseDiv"
          onClick={props.closeSlider}
        >
          <span
            id="close"
            title="Close Slider"
            className="glyphicon glyphicon-remove"
          ></span>
        </button>
        <div className="row" style={{ width: "100%" }}>
          <div className="form-group col-md-6">
            <h5>Main</h5>
          </div>
          <div className="form-group col-md-6">
            <h5>Stand By</h5>
          </div>
        </div>
        <div className="row" style={{ width: "100%" }}>
          <div className="form-group col-md-6">
            <label className="control-label col-sm-5">Driver</label>
            <div className="col-sm-6 padding-remove">
              <select
                className="form-control"
                onChange={(e) => handleMainDriverChange(e.target.value)}
                value={mainDriverId}
              >
                <option value={0}>Driver</option>
                {lstDriver.map((obj, idx) => {
                  return (
                    <option key={idx} value={obj.staffId}>
                      {obj.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="form-group col-md-6">
            <label className="control-label col-sm-5">Driver</label>
            <div className="col-sm-6 padding-remove">
              <select
                className="form-control"
                onChange={(e) => handleStandByDriverChange(e.target.value)}
                value={standByDriverId}
              >
                <option value={0}>Driver</option>
                {lstDriver.map((obj, idx) => {
                  return (
                    <option key={idx} value={obj.staffId}>
                      {obj.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="row" style={{ width: "100%" }}>
          <div className="form-group col-md-6">
            <label className="control-label col-sm-5">Conductor</label>
            <div className="col-sm-6 padding-remove">
              <select
                className="form-control"
                value={mainConductorId}
                onChange={(e) => handleMainConductorChange(e.target.value)}
              >
                <option value={0}>Conductor</option>
                {lstConductor.map((obj, idx) => {
                  return (
                    <option key={idx} value={obj.staffId}>
                      {obj.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="form-group col-md-6">
            <label className="control-label col-sm-5">Conductor</label>
            <div className="col-sm-6 padding-remove">
              <select
                className="form-control"
                value={standByConductorId}
                onChange={(e) => handleStandByConductorChange(e.target.value)}
              >
                <option value={0}>Conductor</option>
                {lstConductor.map((obj, idx) => {
                  return (
                    <option key={idx} value={obj.staffId}>
                      {obj.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="row" style={{ width: "100%" }}>
          <div className="form-group col-md-6">
            <label className="control-label col-sm-5">Driver Helper</label>
            <div className="col-sm-6 padding-remove">
              <select
                className="form-control"
                value={mainDriverHelperId}
                onChange={(e) => handleMainDriverHelperChange(e.target.value)}
              >
                <option value={0}>Driver Helper</option>
                {lstDriverHelper.map((obj, idx) => {
                  return (
                    <option key={idx} value={obj.staffId}>
                      {obj.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="form-group col-md-6">
            <label className="control-label col-sm-5">Driver Helper</label>
            <div className="col-sm-6 padding-remove">
              <select
                className="form-control"
                value={standByDriverHelperId}
                onChange={(e) =>
                  handleStandByDriverHelperChange(e.target.value)
                }
              >
                <option value={0}>Driver Helper</option>
                {lstDriverHelper.map((obj, idx) => {
                  return (
                    <option key={idx} value={obj.staffId}>
                      {obj.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="row" style={{ width: "100%" }}>
          <div className="form-group col-md-6">
            <label className="control-label col-sm-5">Female Assistant</label>
            <div className="col-sm-6 padding-remove">
              <select
                className="form-control"
                value={mainFemaleAssistantId}
                onChange={(e) =>
                  handleMainFemaleAssistantChange(e.target.value)
                }
              >
                <option value={0}>Female Assistant</option>
                {lstFemaleAssistant.map((obj, idx) => {
                  return (
                    <option key={idx} value={obj.staffId}>
                      {obj.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="form-group col-md-6">
            <label className="control-label col-sm-5">Female Assistant</label>
            <div className="col-sm-6 padding-remove">
              <select
                className="form-control"
                value={standByFemaleAssistantId}
                onChange={(e) =>
                  handleStandByFemaleAssistantChange(e.target.value)
                }
              >
                <option value={0}>Female Assistant</option>
                {lstFemaleAssistant.map((obj, idx) => {
                  return (
                    <option key={idx} value={obj.staffId}>
                      {obj.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="row" style={{ width: "100%" }}>
          <div className="form-group col-md-6">
            <label className="control-label col-sm-5">Guard</label>
            <div className="col-sm-6 padding-remove">
              <select
                className="form-control"
                value={mainGuardId}
                onChange={(e) => handleMainGuardChange(e.target.value)}
              >
                <option value={0}>Guard</option>
                {lstGuard.map((obj, idx) => {
                  return (
                    <option key={idx} value={obj.staffId}>
                      {obj.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="form-group col-md-6">
            <label className="control-label col-sm-5">Guard</label>
            <div className="col-sm-6 padding-remove">
              <select
                className="form-control"
                value={standByGuardId}
                onChange={(e) => handleStandByGuardChange(e.target.value)}
              >
                <option value={0}>Guard</option>
                {lstGuard.map((obj, idx) => {
                  return (
                    <option key={idx} value={obj.staffId}>
                      {obj.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="row" style={{ width: "100%" }}>
          <div className="form-group col-md-6">
            <label className="control-label col-sm-5">Coordinator</label>
            <div className="col-sm-6 padding-remove">
              <select
                className="form-control"
                value={mainCoordinatorId}
                onChange={(e) => handleMainCoordinatorChange(e.target.value)}
              >
                <option value={0}>Coordinator</option>
                {lstCoordinator.map((obj, idx) => {
                  return (
                    <option key={idx} value={obj.staffId}>
                      {obj.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="form-group col-md-6">
            <label className="control-label col-sm-5">Coordinator</label>
            <div className="col-sm-6 padding-remove">
              <select
                className="form-control"
                value={standByCoordinatorId}
                onChange={(e) => handleStandByCoordinatorChange(e.target.value)}
              >
                <option value={0}>Coordinator</option>
                {lstCoordinator.map((obj, idx) => {
                  return (
                    <option key={idx} value={obj.staffId}>
                      {obj.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="row" style={{ width: "100%", paddingBottom: "1px" }}>
          <div className="col-md-offset-10 col-md-1 padding-remove">
            <button
              type="button"
              className="btn btn-info pull-right"
              onClick={(e) => saveRouteStaff()}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
export default RouteStaffSlider;
