import React, { useEffect, useState } from 'react'


const SyllabusBody = (props) => {
    const [state, setState] = useState([]);
    const [lstClsSyl, setLstClsSyl] = useState(props.lstClsSyl);
    const [cls,setCls]=useState("")
    const [section,setSection]=useState("")
    const [found, setFound] = useState(lstClsSyl);
    useEffect(() => {
        setLstClsSyl(props.lstClsSyl)
        setFound(props.lstClsSyl)
        // // console.log()
    }, [props]);

    function showSlider(e, obj) {
        props.showSlider(e, obj);
    }
    const filterClass = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = found.filter((user) => {
                return user.classLabel.toLowerCase().includes(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
            setFound(results);
        } else {
            setFound(lstClsSyl);
            // If the text field is empty, show all users
        }

        setCls(keyword);
    };
    const filterSection = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = found.filter((user) => {
                return user.section.toLowerCase().includes(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
            setFound(results);
        } else {
            setFound(lstClsSyl);
            // If the text field is empty, show all users
        }

        setSection(keyword);
    };
    return (
        <>
            <table className="tableLayout1" id='customTable'>
                <thead>
                    <tr>
                        <th style={{ width: 10 + '%' }}>
                            <input onChange={filterClass} type="text" value={cls} className="form-control inp-search" placeholder="Class" />
                        </th>
                        <th style={{ width: 10 + '%' }}>
                            <input onChange={filterSection} type="text" value={section} className="form-control inp-search" placeholder="Section" />
                        </th>
                        <th style={{ width: 15 + '%' }}>Syllabus</th>
                        <th style={{ width: 15 + '%' }}>Action</th>
                    </tr>
                </thead>
            </table>

            <div className="tableDiv" style={{ height: '80vh' }}>
                <table id="periodTypeTable" className="tableLayout">
                    <colgroup>
                        <col width="10%" />
                        <col width="10%" />
                        <col width="15%" />
                        <col width="15%" />
                    </colgroup>
                    <tbody id="periodTypeTableBody">
                        {
                            found.map((obj) => {
                                var isSelected = obj.classId === props.selClassId && obj.sectionId === props.selSectionId;
                                return <tr key={obj.sectionId} className={isSelected ? "selected" : ""} id={obj.classId}>
                                    <td hidden>{obj.classId}</td>
                                    <td className="text-center">{obj.classLabel}</td>
                                    <td hidden>{obj.sectionId}</td>
                                    <td className="text-center">{obj.section}</td>
                                    <td hidden>{obj.syllabusId}</td>
                                    <td className="text-center">{obj.syllabus}</td>
                                    <td className="text-center" data={JSON.stringify(obj)}>
                                        <span className="glyphicon glyphicon-pencil" data={JSON.stringify(obj)} onClick={(e) => showSlider(e, obj)}></span>
                                    </td>
                                </tr>
                            })}
                    </tbody>
                </table>
            </div>
        </>
    )
}
export default SyllabusBody;