import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css'
import { saveVehicleType, getVehicleTypeDtls } from './vehicleTypeSvc';
const VehicleTypeSlider = (props) => {
    const [loader, setLoader] = useState("none")
    const [vehicleTypeId, setVehicleTypeId] = useState(0)
    const [vehicleType, setVehicleType] = useState("")
    const [seatingCapacity, setSeatingCapacity] = useState("")
    const [maxAllowed, setMaxAllowed] = useState("")
    const [mileage, setMileage] = useState("")
    const [maintenanceA, setMaintenanceA] = useState("")
    const [maintenanceB, setMaintenanceB] = useState("")
    useEffect(() => {
        if (props.vehicleTypeId) {
            setVehicleTypeId(props.vehicleTypeId)
            setLoader("block")
            getVehicleTypeDtls(props.vehicleTypeId, cbVehicleTypeDtls)
        }
    }, [props])
    function cbVehicleTypeDtls(data) {
        if (data.SvcStatus === 'Success') {
            for (var i = 0; i < data.vehicleType.length; i++) {
                setVehicleType(data.vehicleType[i].vehicleType)
                setSeatingCapacity(data.vehicleType[i].seatingCapacity)
                setMaxAllowed(data.vehicleType[i].maxAllowed)
                setMileage(data.vehicleType[i].mileage)
                setMaintenanceA(data.vehicleType[i].maintenanceA)
                setMaintenanceB(data.vehicleType[i].maintenanceB)
            }
        }
        else {
            toast.error(data.SvcMsg)
        }
        setLoader('none')
    }
    function handleVehicleTypeChange(param) {
        setVehicleType(param)
    }
    function handleSeatingCapacityChange(param) {
        setSeatingCapacity(param)
    }
    function handleMaxAllowedChange(param) {
        setMaxAllowed(param)
    }
    function handleMileageChange(param) {
        setMileage(param)
    }
    function handleMaintenanceAChange(param) {
        setMaintenanceA(param)
    }
    function handleMaintenanceBChange(param) {
        setMaintenanceB(param)
    }
    function saveVehicleTypeDtls() {
        const pVehicleObj = {
            vehicleTypeId: !vehicleTypeId ? 0 : vehicleTypeId,
            vehicleType: vehicleType,
            seatingCapacity: parseInt(seatingCapacity),
            maxAllowed: parseInt(maxAllowed),
            mileage: parseInt(mileage),
            maintenanceA: parseInt(maintenanceA),
            maintenanceB: parseInt(maintenanceB)
        }
        setLoader("block")
        saveVehicleType(pVehicleObj, cbSaveVehicleType)
    }
    function cbSaveVehicleType(data) {
        setLoader("none")
        if (data.SvcStatus === "Success") {
            props.generateRandomNo()
            toast.success(data.SvcMsg)
        } else {
            toast.error(data.SvcMsg)
        }
    }
    return (
        <form className='form form-horizontal' style={{ height: "70vh", width: '100%', overflowX: 'hidden', margin: '0 auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
            <div className="col-sm-12" style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
                <ToastContainer autoClose={3000} hideProgressBar />
                <div className="form-group col-md-12">
                    <label className="control-label col-md-5">Vehicle Type</label>
                    <div className='col-sm-6 padding-remove'>
                        <input type="text" defaultValue={vehicleType} onChange={(e) => handleVehicleTypeChange(e.target.value)} className="form-control"></input>
                    </div>
                </div>
                <div className="form-group col-md-12">
                    <label className="control-label col-md-5">Seating Capacity</label>
                    <div className='col-sm-6 padding-remove'>
                        <input type="number" defaultValue={seatingCapacity} onChange={(e) => handleSeatingCapacityChange(e.target.value)} className="form-control"></input>
                    </div>
                </div>
                <div className="form-group col-md-12">
                    <label className="control-label col-md-5">Max. Allowed</label>
                    <div className='col-sm-6 padding-remove'>
                        <input type="number" defaultValue={maxAllowed} onChange={(e) => handleMaxAllowedChange(e.target.value)} className="form-control"></input>
                    </div>
                </div>
                <div className="form-group col-md-12">
                    <label className="control-label col-md-5">Mileage</label>
                    <div className='col-sm-6 padding-remove'>
                        <input type="number" defaultValue={mileage} onChange={(e) => handleMileageChange(e.target.value)} className="form-control"></input>
                    </div>
                </div>
                <div className="form-group col-md-12">
                    <label className="control-label col-md-5">Maintenance A Kms</label>
                    <div className='col-sm-6 padding-remove'>
                        <input type="number" defaultValue={maintenanceA} onChange={(e) => handleMaintenanceAChange(e.target.value)} className="form-control"></input>
                    </div>
                </div>
                <div className="form-group col-md-12">
                    <label className="control-label col-md-5">Maintenance B Kms</label>
                    <div className='col-sm-6 padding-remove'>
                        <input type="number" defaultValue={maintenanceB} onChange={(e) => handleMaintenanceBChange(e.target.value)} className="form-control"></input>
                    </div>
                </div>
                <div className="form-group col-md-12">
                    <div className="col-md-offset-10 col-md-1 padding-remove">
                        <button type="button" className="btn btn-info pull-right" onClick={(e) => saveVehicleTypeDtls()}>Save</button>
                    </div>
                </div>
            </div>
        </form>
    )
}
export default VehicleTypeSlider;