import React, { useEffect } from 'react';
import { Text, View, Font } from '@react-pdf/renderer';

import { styleSchoScore, styleSummary } from "./termReportPDFStyle.js";


Font.register({
    family: 'Calibri'
    , fonts: [{ src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibri.ttf" }
        , { src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibrib.ttf", fontWeight: "bold" }], format: 'truetype'
});


const SummarySection = (props) => {
    const { studentSummary } = props;

    useEffect(() => {
        // // console.log("useEffect called in SummarySection");
        // // console.log("studentSummary", studentSummary);
    }, [studentSummary]);


    return (
        <View style={styleSchoScore.container}>
            <View style={styleSchoScore.score_table_summary}>
                <View style={[styleSchoScore.exam_row, { height: "20px" }]}>
                    <View style={[styleSummary.subject_col_first, { flex: 3 }]}>
                        <View style={[{ flex: 2, backgroundColor: '#f0f0f0', }]}>
                            <Text style={[styleSchoScore.text_schoExam_label1, styleSchoScore.text_center]} >
                                Total
                            </Text>
                        </View>
                        <View style={[styleSummary.sectionCell, { flex: 2 }]}>
                            <Text style={[styleSchoScore.text_schoExam_label, styleSchoScore.text_center]}>
                                {!!studentSummary ? studentSummary.totalScore + " / " + studentSummary.totalMM : ""}
                            </Text>
                        </View>
                    </View>
                    <View style={[styleSummary.subject_col, { flex: 3 }]}>
                        <View style={[{ flex: 2, backgroundColor: '#f0f0f0', }]}>
                            <Text style={[styleSchoScore.text_schoExam_label1, styleSchoScore.text_center]}>
                                PERCENTAGE
                            </Text>
                        </View>
                        <View style={[styleSummary.sectionCell, { flex: 2 }]}>
                            <Text style={[styleSchoScore.text_schoExam_label, styleSchoScore.text_center]}>
                                {!!studentSummary ? studentSummary.percentage + "%" : ""}
                            </Text>
                        </View>
                    </View>
                    <View style={[styleSummary.subject_col, { flex: 2 }]}>
                        <View style={[{ flex: 2, backgroundColor: '#f0f0f0', }]}>
                            <Text style={[styleSchoScore.text_schoExam_label1, styleSchoScore.text_center]}>
                                GRADE
                            </Text>
                        </View>
                        <View style={[styleSummary.sectionCell, { flex: 2 }]}>
                            <Text style={[styleSchoScore.text_schoExam_label, styleSchoScore.text_center]}>
                                {!!studentSummary ? studentSummary.grade : ""}
                            </Text>
                        </View>
                    </View>
                    <View style={[styleSummary.subject_col, { flex: 2 }]}>
                        <View style={[{ flex: 2, backgroundColor: '#f0f0f0', }]}>
                            <Text style={[styleSchoScore.text_schoExam_label1, styleSchoScore.text_center]}>
                                RANK
                            </Text>
                        </View>
                        <View style={[styleSummary.sectionCell, { flex: 2 }]}>
                            <Text style={[styleSchoScore.text_schoExam_label, styleSchoScore.text_center]}>
                                {!!studentSummary ? studentSummary.rank : ""}
                            </Text>
                        </View>
                    </View>
                </View>
            </View>
        </View >
    );
}

export default SummarySection;
