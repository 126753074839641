import axios from 'axios';
import lstModules from 'common/json/lstModules.json';
var tokenid = localStorage.getItem('tokenid');
var httpURL = lstModules['fees'].url + ":" + lstModules['fees'].port;

function viewReportSvc(fromDt, toDt, callBack) {
    axios(
        {
            url: httpURL + "/sms/fee/report/daily_collection",
            method: "POST",
            params: { 'fromDt': fromDt, 'toDt': toDt },
            headers: { 'tokenid': tokenid },
            withCredentials: true,
            responseType: 'arraybuffer',
        }
    ).then((response) => {
        callBack(response);

    }
    ).catch((error) => {
        console.error(error)
    });
}

function viewMonthlyReportSvc(fromDt, toDt, callBack) {
    axios(
        {
            url: httpURL + "/sms/fee/report/monthly_collection",
            method: "POST",
            params: { 'fromDt': fromDt, 'toDt': toDt },
            headers: { 'tokenid': tokenid },
            withCredentials: true,
            responseType: 'arraybuffer',
        }
    ).then((response) => {
        callBack(response);

    }
    ).catch((error) => {
        console.error(error)
    });
}
export { viewReportSvc, viewMonthlyReportSvc }