let socket = null;

export const initiateSocketConnection = (url) => {
    // // console.log("initiateSocketConnection called in socketServices.js");
    return new Promise((resolve, reject) => {
        socket = new WebSocket(url);

        socket.onopen = () => {
            // // console.log('WebSocket connection established');
            resolve();  // Resolve the promise when the connection is open
        };

        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
            reject(error);  // Reject the promise if an error occurs
        };

        socket.onclose = (event) => {
            // // console.log('WebSocket connection closed');
            // // console.log(`Close code: ${event.code}, Reason: ${event.reason || 'No reason provided'}, Clean close: ${event.wasClean}`);

            if (!event.wasClean) {
                console.warn('Connection closed unexpectedly!');
            } else {
                // // console.log('Connection closed cleanly.');
            }
        };
    });
};

export const disconnectSocket = () => {
    // // console.log("disconnectSocket called in socketServices");
    if (socket) {
        socket.close();
        // // console.log('WebSocket Disconnected');
    }
};

export const sendSocketMessage = (message) => {
    // // console.log("sendMessage called in socketServices, message", message);
    if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify(message));
    } else {
        console.warn('Cannot send message, socket is not connected');
    }
};

export const subscribeToMessages = (callback) => {
    // // console.log("subscribeToMessage called");
    if (!socket) {
        console.warn('WebSocket is not initialized');
        return () => { }; // Return a no-op function to avoid breaking the unsubscribe logic
    }

    const messageHandler = (event) => {
        try {
            const data = JSON.parse(event.data);
            if (data && typeof data === 'object') {
                // // console.log("New message received:", data);
                callback(data);  // Assuming callback expects valid data
            } else {
                console.error("Invalid data format received:", data);
            }
        } catch (error) {
            // Handle case where JSON parsing fails
            console.error("Error parsing WebSocket message:", error);
            console.error("Received message was not valid JSON:", event.data);
        }
    };


    socket.addEventListener('message', messageHandler);

    // Return an unsubscribe function
    return () => {
        socket.removeEventListener('message', messageHandler);
    };
};
