import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
var tokenid = localStorage.getItem('tokenid')
var httpURL = lstModules['ptm'].url + ':' + lstModules['ptm'].port
var httpURLCommon = lstModules['main'].url + ':' + lstModules['main'].port
function getPtmDtlsSvc (ptmId, callBack) {
  axios({
    url: httpURLCommon + '/sms/ptm/dtls',
    method: 'POST',
    params: { ptmId: ptmId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // // console.log(Response.data)
      var ourData = Response.data

      callBack(ourData)
    })
    .catch(error => {
      // // console.log('error')
    })
}
function getTemplateSvc (callBack) {
  axios({
    url: httpURLCommon + '/sms/ptm/comm/template',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var ourData = Response.data
      callBack(ourData)
    })
    .catch(error => {
      // // console.log('error')
    })
}

function cancelPtmSvc (ptmId, callBack) {
  axios({
    url: httpURL + '/sms/ptm/cancel',
    method: 'POST',
    params: { ptmId: ptmId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // // console.log(Response.data)
      var ourData = Response.data
      callBack(ourData)
    })
    .catch(error => {
      // // console.log('error')
    })
}
function deletePtmSvc (ptmId, callBack) {
  axios({
    url: httpURL + '/sms/ptm/delete',
    method: 'POST',
    params: { ptmId: ptmId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // // console.log(Response.data)
      var ourData = Response.data
      callBack(ourData)
    })
    .catch(error => {
      // // console.log('error')
    })
}
function getWingsListSvc (handleToFunction) {
  axios({
    url: httpURL + '/sms/wings/list',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      var data = response.data
      if (data.SvcStatus === 'Success') {
        handleToFunction(data)
      }
    })
    .catch(error => {
      handleToFunction(error)
    })
}
function savePtmSvc (pObj, callBack) {
  axios({
    url: httpURL + '/sms/ptm/save',
    method: 'POST',
    data: pObj,
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      var data = response.data
      if (data.SvcStatus === 'Success') {
        callBack(data)
      }
    })
    .catch(error => {
      callBack(error)
    })
}
export { getPtmDtlsSvc, getTemplateSvc, getWingsListSvc, savePtmSvc }
