import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';

// project related common imports

import 'common/css/master.css';
import Header from 'common/js/header/header.js';
import ExamDateHeader from './examDateHeader';
import TchTable from './TchTable';

const TchDutyCtrl = () => {
    const [examId, setExamId] = useState(0)
    const [tchId, setTchId] = useState(0)
    const [randNo, setRandNo] = useState(0)

    function refreshExamSchedule(pExamId) {
        setExamId(pExamId)
        generateRandomNo()
    }

    function generateRandomNo() {
        var lRandNo = Math.floor(Math.random() * 999999);
        // // console.log(lRandNo)
        setRandNo(lRandNo)
    }
    const getWingId = (wingId) => {
        // // console.log("wingId" + wingId);
    }
    return (
        <div style={{ height: '100%' }}>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div className="mainContainer" style={{ marginTop: '15px', opacity: '1' }}>
                <div className="col-sm-12 col-centered ">
                    <div className="col-centered col-sm-7 padding-remove">
                        <ExamDateHeader examClick={refreshExamSchedule} examId={examId} />
                    </div>
                    <div className="col-sm-5 col-centered" style={{ top: 10, height: '100%' }}>
                        <TchTable
                            key={randNo}
                            setTchId={setTchId}
                            tchId={tchId}
                            examId={examId}
                        />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default TchDutyCtrl