import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import Loader from "common/components/LoaderSlider";

import "../css/schoolProfile.css";
import { getAddressSvc, getLstCity, getLstLocality, saveAddressSvc } from "./schoolProfileSvc";


const SchoolAddress = (props) => {
    const { schoolId, lstState } = props;

    const [loader, setLoader] = useState("block");
    const [opacity, setOpacity] = useState(1);

    const [pin, setPin] = useState("");
    const [schoolName, setSchoolName] = useState("");
    const [schoolAddress, setSchoolAddress] = useState("");

    const [lstCities, setLstCities] = useState([]);
    const [lstLocalities, setLstLocalities] = useState([]);

    const [stateId, setStateId] = useState(0);
    const [cityId, setCityId] = useState(0);
    const [localityId, setLocalityId] = useState(0);


    useEffect(() => {
        // // console.log("SchoolAddress component called");
        getAddressDtls();
    }, []);

    const getAddressDtls = () => {
        // // console.log("getAddressDtls called");
        setLoader("block");
        setOpacity(0.5);
        getAddressSvc(cbAddressResponse);
    }


    const cbAddressResponse = (obj) => {
        setLoader("none");
        setOpacity(1);
        if (obj.status === "Success") {
            setSchoolName(obj.addr.name);
            setSchoolAddress(obj.addr.address.address1);
            setPin(obj.addr.address.pin);
            var pStateId = obj.addr.address.stateId;
            var pCityId = obj.addr.address.cityId;
            var pLocalityId = obj.addr.address.localityId;
            setStateId(pStateId);
            getLstCity(pStateId, handleLstCityResponse, pCityId);
            getLstLocality(pCityId, handleLocalityResponse, pLocalityId);
        } else if (obj.status === "Failure") {
            if (obj.message === "You are not logged in") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else if (obj.message === "Your Session is expired, login again") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error("Service failed" + obj);
        }
    };


    const changeState = (e) => {
        var newStateId = e.target.value;
        setStateId(newStateId);
        setLoader("block");
        setOpacity(0.5);
        getLstCity(newStateId, handleLstCityResponse, 0);
    };

    function handleLstCityResponse(data, selectedCityId) {
        var obj = data;
        setLoader("none");
        setOpacity(1);
        if (obj.status === "Success") {
            setLstCities(obj.lstCity);
            setCityId(selectedCityId);
        } else if (obj.status === "Failure") {
            if (obj.message === "You are not logged in") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else if (obj.message === "Your Session is expired, login again") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error("Service failed" + obj);
        }
    }

    const changeCity = (e) => {
        var newCityId = e.target.value;
        setCityId(newCityId);
        setLoader("block");
        setOpacity(0.5);
        getLstLocality(newCityId, handleLocalityResponse, 0);
    };

    function handleLocalityResponse(data, selectedLocalityId) {
        var obj = data;
        setLoader("none");
        setOpacity(1);
        if (data.status === "Success") {
            setLstLocalities(obj.lstLocality);
            setLocalityId(selectedLocalityId);
        } else if (obj.status === "Failure") {
            if (obj.message === "You are not logged in") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else if (obj.message === "Your Session is expired, login again") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error("Service failed" + obj);
        }
    }

    function saveAddress(e) {
        e.preventDefault();
        var address = {
            stateId: stateId,
            cityId: cityId,
            localityId: localityId,
            address1: schoolAddress,
            pin: pin,
        };
        var addr = { name: schoolName, address: address };
        setLoader("block");
        setOpacity(0.5);
        saveAddressSvc(addr, handleSaveResponse);
    }

    function handleSaveResponse(obj) {
        setLoader("none");
        setOpacity(1);
        if (obj.status === "Success") {
            toast.success(obj.message);
        } else if (obj.status === "Failure") {
            if (obj.message === "You are not logged in") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else if (obj.message === "Your Session is expired, login again") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error("Service failed" + obj);
        }
    }

    const changeLocality = (e) => {
        setLocalityId(e.target.value);
    };

    return (
        <div className="col-xl-12 col-ls-12 col-md-12 col-sm-12 col-xs-12 padding-remove"
            style={{
                cursor: (loader === "none") ? 'pointer' : 'none',
                pointerEvents: (loader === "none") ? 'auto' : 'none',
                opacity: opacity
            }}>
            <Loader loader={loader} position="fixed" top="30%" left="45%" />
            <form
                className="form form-vertical col-sm-12"
                style={{ margin: "2%", border: "1px solid #ddd", borderRadius: 6 }}
            >
                <div
                    className="EnquiryHead col-centered"
                    style={{
                        fontWeight: "bold",
                    }}
                >
                    <h4>School Address</h4>
                </div>
                <div className="row">
                    <label className="control-label col-sm-2" htmlFor="name">
                        Name
                    </label>
                    <div className="form-group col-sm-9">
                        <input
                            className="form-control"
                            name="schoolName"
                            value={schoolName}
                            onChange={(e) => setSchoolName(e.target.value)}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-sm-offset-2 col-sm-3">
                        <select
                            value={stateId}
                            onChange={(e) => changeState(e)}
                            className="form-control"
                            style={{ width: "100%" }}
                            id="stateCmb"
                            name="stateId"
                        >
                            <option>State</option>

                            {lstState &&
                                lstState.map(function (obj, i) {
                                    return (
                                        <option key={i} value={obj.stateId}>
                                            {obj.state}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-sm-offset-2 col-sm-3">
                        <select
                            value={cityId}
                            onChange={(e) => changeCity(e)}
                            className="form-control"
                            style={{ width: "100%" }}
                            id="cityCmb1"
                            name="cityId"
                        >
                            <option value="0">City</option>
                            {lstCities &&
                                lstCities.map(function (obj, i) {
                                    return (
                                        <option key={i} value={obj.cityId}>
                                            {obj.city}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-sm-offset-2 col-sm-3">
                        <select
                            value={localityId}
                            onChange={(e) => changeLocality(e)}
                            className="form-control"
                            style={{ width: "100%" }}
                            id="localityCmb"
                            name="localityId"
                        >
                            <option value="0">Locality</option>
                            {lstLocalities &&
                                lstLocalities.map(function (obj, i) {
                                    return (
                                        <option key={i} value={obj.localityId}>
                                            {obj.locality}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <label className="control-label col-sm-2" htmlFor="pin">
                        Pin
                    </label>
                    <div className="form-group col-sm-2">
                        <input
                            className="form-control"
                            name="pin"
                            value={pin}
                            onChange={(e) => setPin(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <label className="control-label col-sm-2" htmlFor="address1">
                        Address
                    </label>
                    <div className="form-group col-sm-9">
                        <input
                            className="form-control"
                            name="address1"
                            value={schoolAddress}
                            onChange={(e) => setSchoolAddress(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-sm-11">
                        <button
                            className="pull-right btn btn-info"
                            onClick={(e) => saveAddress(e)}
                            title="Save"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default SchoolAddress;
