import React, { Component, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import { toast, ToastContainer } from 'react-toastify';
import '../css/paymentSlider.css';
import lstModules from 'common/json/lstModules.json';
var httpURL = '';
var queryString = require('querystring');
var httpURL = lstModules['fees'].url + ":" + lstModules['fees'].port;
const screenId = 37;
const tokenid = localStorage.getItem('tokenid')
function FeeDuePaymentSlider(props) {
    const [studentId, setStudentId] = useState(0)
    useEffect(() => {
        // // console.log("FeeDuePayment Slider called");
        // // console.log("props.studentId received", props.studentId);
        // if(props.studentId){
            getFeeDueDtls(props.studentId, cbFeeDueDtls);
            setStudentId(props.studentId)
        // }
    }, [props.studentId]);
    const screenId = 37;
    function getFeeDueDtls(studentId, callBack) {
        axios(
            {
                url:  httpURL +"/sms/payment/feedue/dtls_pdf",
                method: 'POST',
                params: { studentId, screenId },
                headers: { 'tokenid': tokenid },
                withCredentials: true,
                responseType: 'arraybuffer',
            }
        ).then(
            (Response) => {
                // // console.log('response'+ Response)
                var data = Response.data
                callBack(data)
            }
        ).catch((error) => {
            console.error(error)
        });
    };
    function cbFeeDueDtls(data) {

        let blob = new Blob([data], { type: 'application/pdf' }),
            url = window.URL.createObjectURL(blob);
        const iframe = document.getElementById('viewer');
        iframe.setAttribute('src', url);
        // URL.revokeObjectURL(url);

    }
    function sendMail(e) {
        sendMailSvc(studentId, cbSendMail)
    }
    function sendMailSvc(studentId, callBack) {
        axios(
            {
                url: httpURL+ "/sms/payment/feedue/student/mail",
                method: 'POST',
                params: { studentId, screenId },
                headers: { 'tokenid': tokenid },
                withCredentials: true
            }
        ).then(
            (Response) => {
                var data = Response.data
                callBack(data)
            }
        ).catch((error) => {
            console.error(error)
        });
    }
    function cbSendMail(data) {
        if (data.SvcStatus === 'Success') {
            toast.success(data.SvcMsg)
        }
        else (toast.error(data.SvcMsg))
    }
    return (
        <div>
            <button type='button' className='btn btn-info text-center pull-right' id='mail' onClick={(e) => sendMail()}
            > Send Email</button>
            <button type="button" className="btn btn-info text-center " id="closeSlider" style={{ right: '71%', opacity: 1 }}
                onClick={props.removeClassFromBody}>
             <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
            </button>
               <div className="pdf" style={{ width: '100%', height: '96vh' }}>
                <iframe src={require("common/images/loader.gif")} title="Outstanding Fee" id="viewer" style={{ width: '100%', height: '100%' }} />
            </div>
        </div>
    )

}
export default FeeDuePaymentSlider;