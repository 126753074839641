import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import $ from 'jquery';

var httpURL = '';

export default class Section extends Component {
    state = {
        tabClicked: 0,
        tokenid: localStorage.getItem('tokenid')
    }
    componentDidMount = () => {
        // // console.log('data in section = ' + JSON.stringify(this.props.data));
        $('.nav-tabs > .active').find('a').trigger('click');
        var clsSectionId = $('.nav-tabs > .active').find('a').attr('id');
        this.setState(tabClicked => ({
            tabClicked: clsSectionId
        }));
        this.props.parentCallback(clsSectionId);
    }
    handleTabClick = (e, classSectionId, classSectionName) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        // // console.log("handleTabClick classSectionId = " + classSectionId);
        $(".nav-item").removeClass('active');
        $(e.target).closest(".nav-item").addClass('active');
        this.setState(tabClicked => ({
            tabClicked: classSectionId
        }));
        this.props.parentCallback(classSectionId, classSectionName);
    }

    render() {
        httpURL = lstModules['examTT'].url + ":" + lstModules['examTT'].port;

        return (
            <div >
                <div className="container-fluid">
                    <ul className="nav nav-tabs nav-pills nav-fill" role="tablist">
                        {this.props.data.map((item, idx) => {
                            return idx == 0 ?
                                <li className="nav-item active" key={item.sectionId} role="presentation">
                                    <a id={item.sectionId}
                                        data-mdb-toggle="pill"
                                        /* href="#ex1-pills-1"  */
                                        className="nav-link active"
                                        aria-current="page"
                                        href="#"
                                        key={"a_" + item.sectionId}
                                        role="tab"
                                        aria-controls="ex1-pills-1"
                                        aria-selected="true"
                                        onClick={(e) => { this.handleTabClick(e, item.sectionId, item.section) }}
                                    >
                                        {item.section}
                                    </a>
                                </li>
                                :
                                <li className="nav-item" key={item.sectionId} role="presentation">
                                    <a id={item.sectionId}
                                        data-mdb-toggle="pill"
                                        /* href="#ex1-pills-1"  */
                                        className="nav-link"
                                        aria-current="page"
                                        href="#"
                                        key={"a_" + item.sectionId}
                                        role="tab"
                                        aria-controls="ex1-pills-1"
                                        aria-selected="true"
                                        onClick={((e) => this.handleTabClick(e, item.sectionId, item.section))}
                                    >
                                        {item.section}
                                    </a>
                                </li>
                        })}
                    </ul>
                </div>
            </div>
        )
    }
};

