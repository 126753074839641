import React, { useEffect, useState } from 'react';
import 'sms/tt/subject/css/tabList.css'
import 'common/css/master.css'

const SectionList = (props) => {
    const [lstSection, setLstSection] = useState(props.lstSection);
    useEffect(() => {
        setLstSection(props.lstSection);
    }, [  props.lstSection]);

    return (
        <div className="tab" style={{marginLeft:4}}>

            {!!lstSection && lstSection.map((obj) => {
                var isSelected = obj.sectionId == props.selSectionId;
                return <div className={isSelected ? "sellected" : ""} onClick={(e) => props.sectionClick(obj.sectionId)} key={obj.sectionId} style={{height:35}}>
                    <span hidden>{obj.sectionId}</span>
                    <span>{obj.section}</span>
                </div>
            })}
        </div>
    )

}
export default SectionList;