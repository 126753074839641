import axios from "axios";
import { toast } from "react-toastify";
import lstModules from "common/json/lstModules.json";

const  tokenid = localStorage.getItem("tokenid");
const screenId = 12;
const httpURL = lstModules['purchase'].url + ":" + lstModules['purchase'].port;


export const getItemListSvc = (cbItemList) => {
    let obj = { status: "success", message: ""};

    axios({
        url: httpURL + '/sms/item/list',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }) .then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        obj.lstItems = response.data.lstItems;
        cbItemList(obj);
    })
    .catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbItemList(obj);
    })
    
}