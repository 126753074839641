import axios from 'axios'
import React, { useState, useEffect } from 'react'
import lstModules from 'common/json/lstModules.json'
var tokenid = localStorage.getItem('tokenid')
var httpURL = lstModules['enquiry'].url + ':' + lstModules['enquiry'].port
var httpURLCommon = lstModules['main'].url + ':' + lstModules['main'].port

function getAdmissionListSvc (callBack) {
  axios({
    url: httpURL + '/sms/admission/list',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      callBack(data)
    })
    .catch(error => {
      if (error.response) {
        // // console.log(error.response.data)
        // // console.log(error.response.status)
        // // console.log(error.response.headers)
      }
    })
}

function enqDtlsSvc (pEnqId, callBack) {
  if (!pEnqId) {
    return
  }
  axios({
    url: httpURL + '/sms/admission/dtls',
    method: 'POST',
    params: { enqId: pEnqId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var ourData = Response.data
      callBack(ourData)
    })
    .catch(error => {
      callBack(error)
    })
}
function getClassListSvc (callBack) {
  axios({
    url: httpURLCommon + '/sms/mst/cls/list',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // // console.log(Response.data)
      var ourData = Response.data
      callBack(ourData)
    })
    .catch(error => {
      // // console.log('error')
    })
}
function getStreamListSvc (callBack) {
  axios({
    url: httpURLCommon + '/sms/mst/stream/list',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // // console.log(Response.data)
      var ourData = Response.data
      callBack(ourData)
    })
    .catch(error => {
      // // console.log('error')
    })
}
function getStateListSvc (callBack) {
  axios({
    url: httpURLCommon + '/sms/mst/state/list',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // // console.log(Response.data)
      var ourData = Response.data
      callBack(ourData)
    })
    .catch(error => {
      // // console.log('error')
    })
}
function getCityListSvc (callBack, pstateId, pCityId) {
  axios({
    url: httpURLCommon + '/sms/mst/city/list',
    method: 'POST',
    params: { stateId: pstateId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // // console.log(Response.data)
      var ourData = Response.data
      callBack(ourData, pCityId)
    })
    .catch(error => {
      // // console.log('error')
    })
}
function getLocalityListSvc (callBack, pcityId, pLocalityId) {
  axios({
    url: httpURLCommon + '/sms/mst/locality/list',
    method: 'POST',
    params: { cityId: pcityId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // // console.log(Response.data)
      var ourData = Response.data
      callBack(ourData, pLocalityId)
    })
    .catch(error => {
      // // console.log('error')
    })
}
function getSourceListSvc (callBack) {
  axios({
    url: httpURLCommon + '/sms/mst/source/list',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // // console.log(Response.data)
      var ourData = Response.data
      callBack(ourData)
    })
    .catch(error => {
      // // console.log('error')
    })
}
function getCampaignListSvc (callBack) {
  axios({
    url: httpURLCommon + '/sms/mst/campaign/list',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // // console.log(Response.data)
      var ourData = Response.data
      callBack(ourData)
    })
    .catch(error => {
      // // console.log('error')
    })
}
function getStaffListSvc (callBack) {
  axios({
    url: httpURLCommon + '/sms/mst/staff/list',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // // console.log(Response.data)
      var ourData = Response.data
      callBack(ourData)
    })
    .catch(error => {
      // // console.log('error')
    })
}
function saveEnqSvc (pEnqObj, cbSaveEnqResponse) {
  axios({
    url: httpURL + '/sms/admission/save',
    method: 'POST',
    params: { enq: JSON.stringify(pEnqObj) },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      var data = response.data
      cbSaveEnqResponse(data)
    })
    .catch(error => {
      // // console.log('Error Occured')
    })
}
function assignStaffSvc (pLstEnqId, pStaffId, cbAssignResponse) {
  var formData = new FormData()
  formData.append('lstEnqId', JSON.stringify(pLstEnqId))
  formData.append('assignedToId', pStaffId)
  axios({
    url: httpURL + '/sms/admission/assign',
    method: 'POST',
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      cbAssignResponse(data)
    })
    .catch(error => {
      // // console.log('Error Occured here while assigning ' + error)
    })
}

function unassignStaffSvc (pLstEnqId, cbUnassignResponse) {
  var formData = new FormData()
  formData.append('lstEnqId', JSON.stringify(pLstEnqId))
  axios({
    url: httpURL + '/sms/admission/unassign',
    method: 'POST',
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      cbUnassignResponse(data)
    })
    .catch(error => {
      // // console.log('Error Occured here while assigning ' + error)
    })
}
function associateFeeHeadSvc (pLstEnqId, headId, cbAssociateResponse) {
  var formData = new FormData()
  formData.append('lstAdmissionId', JSON.stringify(pLstEnqId))
  formData.append('headId', headId)

  axios({
    url: httpURL + '/sms/admission/onetimefee/associate',
    method: 'POST',
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      cbAssociateResponse(data)
    })
    .catch(error => {
      // // console.log('Error Occured here while assigning ' + error)
    })
}
function getHeadListSvc (callBack) {
  axios({
    url: httpURLCommon + '/sms/onetimehead/list',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      callBack(data)
    })
    .catch(error => {
      if (error.response) {
        // // console.log(error.response.data)
        // // console.log(error.response.status)
        // // console.log(error.response.headers)
      }
    })
}
function getModeList (cbGetModeResponse) {
  axios({
    url: httpURLCommon + '/sms/mst/payment_mode/list',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      var data = Response.data
      cbGetModeResponse(data.lstPaymentMode)
    })
    .catch(e => {
      if (e.response) {
        // // console.log(e.response.data)
        // // console.log(e.response.status)
        // // console.log(e.response.headers)
      }
    })
}

export { enqDtlsSvc }
export { getClassListSvc }
export { getStreamListSvc }
export { getCityListSvc }
export { getLocalityListSvc }
export { getSourceListSvc }
export { getCampaignListSvc }
export { getStaffListSvc }
export { getStateListSvc }
export { getAdmissionListSvc }
export { saveEnqSvc }
export { assignStaffSvc }
export { unassignStaffSvc }
export { associateFeeHeadSvc }
export { getHeadListSvc }
