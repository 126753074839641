import React, { useState } from 'react';
import SliderType from '../js/sliderType';

export default function MainRegTable(props) {
    const [selStudentId, setSelStudentId] = useState(0);
    const [filterName, setFilterName] = useState('');
    const [filterContact, setFilterContact] = useState('');

    function openMenu(e) {
        var id = parseInt(e.currentTarget.getAttribute('id'));
        setSelStudentId(id);
    }
    function openEdit(e) {
        e.stopPropagation();
        setSelStudentId(0);
        var dtls=JSON.parse(e.target.closest('tr').getAttribute('data'));
        props.setFlgCallback(SliderType.SLIDER_EDT_DTLS, dtls)
        document.querySelector('body').classList.add('openSlider');
    }
    function openFees(e) {
        e.stopPropagation();
        setSelStudentId(0);
        var dtls=JSON.parse(e.target.closest('tr').getAttribute('data'));
        props.setFlgCallback(SliderType.SLIDER_FEES, dtls)
        document.querySelector('body').classList.add('openSlider');
    }
    function openAdmissionTest(e) {
        e.stopPropagation();
        setSelStudentId(0);
        var dtls=JSON.parse(e.target.closest('tr').getAttribute('data'));
        props.setFlgCallback(SliderType.SLIDER_ADM_TEST, dtls)
        document.querySelector('body').classList.add('openSlider');
    }
    function openScheduleInterview(e) {
        e.stopPropagation();
        setSelStudentId(0);
        var dtls=JSON.parse(e.target.closest('tr').getAttribute('data'));
        props.setFlgCallback(SliderType.SLIDER_SCH_INT, dtls)
        document.querySelector('body').classList.add('openSlider');
    }
    function openInterviewResult(e) {
        e.stopPropagation();
        setSelStudentId(0);
        var dtls=JSON.parse(e.target.closest('tr').getAttribute('data'));
        props.setFlgCallback(SliderType.SLIDER_INT_RSLT,dtls)
        document.querySelector('body').classList.add('openSlider');
    }
    function openAssignClassSection(e) {
        e.stopPropagation();
        setSelStudentId(0);
        var dtls=JSON.parse(e.target.closest('tr').getAttribute('data'));
        props.setFlgCallback(SliderType.SLIDER_CLS_SEC,dtls)
        document.querySelector('body').classList.add('openSlider');
    }
    function openFeesPayment(e) {
        e.stopPropagation();
        setSelStudentId(0);
        var dtls=JSON.parse(e.target.closest('tr').getAttribute('data'));
        props.setFlgCallback(SliderType.SLIDER_FEE_PYMT,dtls)
        document.querySelector('body').classList.add('openSlider');
    }
    function handleChange(e) {
        setFilterName(e.target.value.toLowerCase());
    }
    function handleContactChange(e) {
        setFilterContact(e.target.value + '');
    }

    return (
        <div className="col-sm-11  col-xs-11  padding-remove">
            <table className="tableLayout1" style={{ width: 700 }}>
                <thead>
                    <tr>
                        <th style={{ display: 'none' }}> Registration Id </th>
                        <th style={{ width: 30 }}> Registration No. </th>
                        <th style={{ width: 50 }}>
                            <input type="text" onChange={handleChange} className="form-control inp-search" name="filterName" id="nameFilter" placeholder="Name" />
                        </th>
                        <th style={{ width: 30 }}> Gender/Age </th>
                        <th style={{ width: 40 }}>
                            <input type="text" onChange={handleContactChange} className="form-control inp-search" id="contactFilter" placeholder="Contact" />
                        </th>
                        <th style={{ width: 30 }}>
                            <div style={{ float: 'left', width: '100%' }}>
                                <select type="text" className="form-control inp-search" id="filterEnquiryTypeCmb">
                                    <option id={0} value={0}>Source</option>
                                    {props.lstSource.map((obj, idx) => {
                                        return <option id={obj.sourceId} key={idx} value={obj.sourceId}>{obj.source}</option>
                                    })}

                                </select>
                            </div>
                        </th>
                        <th style={{ width: 30 }}> Applied For </th>
                        <th style={{ width: 40 }}> Allocated</th>
                        <th style={{ width: 50 }}>
                            <div style={{ float: 'left', width: '100%' }}>
                                <select type="text" className="form-control inp-search" id="filterStatusCmb">
                                    <option id={0} value={0}>Status</option>
                                    {props.lstStatus.map((obj, idx) => {
                                        return <option id={obj.statusId} key={idx} value={obj.statusId}>{obj.status}</option>
                                    })}
                                </select>
                            </div>
                        </th>
                        <th style={{ width: 30 }}> Action</th>

                    </tr>
                </thead>
            </table>
            <div className="registrationTableDiv" style={{ height: '400px', overflowY: 'scroll', border: '1px solid #ccc' }} >
                <table id="registrationTable" className="tableLayout">
                    <colgroup>
                        <col width="30" />
                        <col width="50" />
                        <col width="30" />
                        <col width="40" />
                        <col width="30" />
                        <col width="30" />
                        <col width="40" />
                        <col width="50" />
                        <col width="30" />
                    </colgroup>
                    <tbody id="registrationTableBody">
                        {props.lstRegistration.map((obj, idx) => {
                            return ((filterName === '' || obj.name.toLowerCase().indexOf(filterName) > -1)
                                &&
                                (filterContact === '' || obj.phoneNo.indexOf(filterContact) > -1))
                                && <tr key={idx} data={JSON.stringify(obj)}>
                                    <td className='no-display'> {obj.studentId} </td>
                                    <td className='text-center'> {obj.regsNo} </td>
                                    <td className='text-center'> {obj.name} </td>
                                    <td className='text-center'> {obj.genderAge} </td>
                                    <td className='text-center'> {obj.phoneNo} </td>
                                    <td className='text-center'> {obj.source || '-'} </td>
                                    <td className='no-display'> {obj.classAppliedId} </td>
                                    <td className='text-center'> {obj.classApplied} </td>
                                    <td className='text-center'> {obj.classAllocated} </td>
                                    <td className='text-center'> {obj.status || '-'} </td>
                                    <td className='text-center' >
                                        <span className='glyphicon glyphicon-option-vertical' onClick={openMenu} id={obj.studentId} >
                                            <ul className='ulStyle1' style={{ display: obj.studentId === selStudentId ? 'block' : 'none' }}>
                                                <li onClick={openEdit}>Edit Details</li>
                                                <li onClick={openFees}>Update Fees</li>
                                                <li onClick={openAdmissionTest}>Admission Test</li>
                                                <li onClick={openScheduleInterview}>Schedule Interview</li>
                                                <li onClick={openInterviewResult}>Interview Result</li>
                                                <li onClick={openAssignClassSection}>Assign Class-Section</li>
                                                <li onClick={openFeesPayment}>Fee-Payment</li>
                                            </ul>
                                        </span> </td>
                                </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>)
}