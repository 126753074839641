import React from 'react';
function TableHead({ heads, headWidth, headCls, clickHandler }) {  
    return (
      <div>
          <div className="">
            <table className={headCls} style={{ width: headWidth }} >
            <thead>
                <tr>
                {heads.map((head,index)=>{
                    return <th key={index} style={{width:head[1]}} className={head[2]}>{head[0]}</th>
                })}
                </tr>
            </thead>
            </table>
      </div>
    </div>
    )
}
export default TableHead;