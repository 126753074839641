import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { deleteInterviewSlotSvc, informCandidatesSvc, informPanelSvc } from './InterviewSlotConfigSvc';
import { getInterviewSlotListSvc } from './InterviewSlotConfigSvc'

const InterviewSlotConfigTable = (props) => {
    // const [lstInterviewSlot, setlstInterviewSlot] = useState([])
    const [slotLabel, setSlotLabel] = useState("")
    const [slotDt, setSlotDt] = useState(0)
    const [startTm, setStartTm] = useState(0);
    const [endTm, setEndTm] = useState(0);
    const [timePerCandidate, setTimePerCandidate] = useState("")
    const [loader, setLoader] = useState("none");
    var [all, setAll] = useState(false);
    const [lstInterviewSlot, setLstInterviewSlot] = useState([])

    useEffect(() => {
        setLoader('block')
        getInterviewSlotListSvc(handleInterviewResponse)
    }, []);
    function handleInterviewResponse(data) {
        // // console.log(data);
        if (data.SvcStatus === 'Success') {

            setLstInterviewSlot(data.lstInterviewSlot)
        }
        else {
            toast.error(data.SvcMsg)
        }
        setLoader('none')
    }

    function editInterviewSlot(interviewSlotId) {
        props.setInterviewSlotforEdit(interviewSlotId)
        props.openSlider();
    }
    function updateInterviewSlot(interviewSlot) {
        setSlotLabel(interviewSlot.slotLabel)
        setSlotDt(interviewSlot.slotDt)
        setStartTm(interviewSlot.startTm)
        setEndTm(interviewSlot.endTm)
        setTimePerCandidate(interviewSlot.timePerCandidate)
    }
    function deleteInterviewSlot(pInterviewSlotId) {
        var pConfirmation = window.confirm("Are you sure to delete the interview slot?");
        if (pConfirmation) {
            setLoader('block');
            deleteInterviewSlotSvc(pInterviewSlotId, cbDeleteInterviewSlot);
        }
    }
    function cbDeleteInterviewSlot(data) {
        setLoader('none')
        if (data.SvcStatus === "Success") {
            toast.success(data.SvcMsg);
            props.getLstInterviewSlot();
            props.setInterviewSlotId(0)
            props.generateRandomNo()
        } else {
            toast.error(data.SvcMsg)
        }
    }
    function informCandidates(pinterviewSlotId, pAll) {
        informCandidatesSvc(pinterviewSlotId, pAll, cbInformCandidates)
    }
    function cbInformCandidates(data) {
        if (data.SvcStatus === "Success") {
            toast.success(data.SvcMsg)
        } else {
            toast.error(data.SvcMsg)
        }
    }
    function informPanel(pinterviewSlotId, pAll) {
        informPanelSvc(pinterviewSlotId, pAll, cbInformPanel)
    }
    function cbInformPanel(data) {
        if (data.SvcStatus === "Success") {
            toast.success(data.SvcMsg)
        } else {
            toast.error(data.SvcMsg)
        }
    }
    const colgrp = <colgroup>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="40%"></col>
    </colgroup>
    return (
        <div style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            {/* <table className='tableLayout2'><thead><tr><th>Interview Slots</th></tr></thead></table> */}
            <table className='tableLayout1'>
                {colgrp}
                <thead>
                    <tr>
                        <th className="text-left">Slot Label</th>
                        <th>Date</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Time per Candidate(mins)</th>
                        <th>Total Time(mins)</th>
                        <th>Max Candidates</th>
                        <th>Action</th>
                    </tr>
                </thead>
            </table>
            <div className='tablebodyWrapper'>
                <table className='tableLayout'>
                    {colgrp}
                    <tbody>
                        {
                            lstInterviewSlot.map((slot, idx) => {
                                return <tr key={idx} onClick={(e) => props.setInterviewSlotId(slot.interviewSlotId)} className={slot.interviewSlotId === props.interviewSlotId ? "selected" : ""}>
                                    <td className="text-left">{slot.slotLabel}</td>
                                    <td>{slot.slotDt}</td>
                                    <td>{slot.startTm}</td>
                                    <td>{slot.endTm}</td>
                                    <td>{slot.timePerCandidate}</td>
                                    <td>{slot.totalTm}</td>
                                    <td>{slot.maxCandidates}</td>

                                    <td className="text-center">
                                        <button type='button' className="btn-space" style={{ padding: '1px' }} onClick={(e) => editInterviewSlot(slot.interviewSlotId)}>Edit</button>
                                        <button type='button' className="btn-space" style={{ padding: '1px' }} onClick={(e) => deleteInterviewSlot(slot.interviewSlotId)}>Delete</button>
                                        <button type='button' className="btn-space" style={{ padding: '1px' }} onClick={(e) => informCandidates(slot.interviewSlotId, all = true)}>Inform Candidates</button>
                                        <button type='button' className="btn-space" style={{ padding: '1px' }} onClick={(e) => informPanel(slot.interviewSlotId, all = true)}>Inform Panel</button>
                                        {/* <button type='button' className="btn-space" onClick={(e) => informCandidates(slot.interviewSlotId,all=false)}>Inform New Candidates</button>
                                        <button type='button' className="btn-space" onClick={(e) => informPanel(slot.interviewSlotId,all=false)}>Inform New Panel</button> */}
                                    </td>
                                </tr>
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
export default InterviewSlotConfigTable;