import React, { Component } from 'react';
import lstStudentFeeDiscount from './lstStudentFeeDiscount.json';
import $ from 'jquery'


export default class studentFeeDiscountTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tokenid: localStorage.getItem("tokenid"),
            wingId: localStorage.getItem("wingId"),
            feeLabel: ""

        };
        // this.compare=this.compare.bind(this);
    }
    addClassOnBody = (id) => {
        $('#feeHeadTableBody tr#' + id).addClass('selected').siblings().removeClass('selected');
    }

    componentDidMount = () => {
        // this.props.lstFeeHead.forEach(function(obj) {
        //     obj.amount.toLocaleString('en-IN');
        //     obj.discountAmt.toLocaleString('en-IN');
        //     obj.netAmt.toLocaleString('en-IN');
        //     }
        // )
        // this.props.lstFeeHead.sort(this.compare);
    }

    // indianFormat =(lstFeeHead)=>{
    //     for (var element in lstFeeHead){
    //         element['amount'].toLocaleString('en-IN')
    //         element['discountAmt'].toLocaleString('en-IN')
    //         element['netAmt'].toLocaleString('en-IN')
    //     }
    //     this.setState({
    //         lstFeeHead:lstFeeHead
    //     })
    // }
    // compare( a, b ) {
    //     if ( a.selected < b.selected ){
    //       return -1;
    //     }
    //     if ( a.selected > b.selected ){
    //       return 1;
    //     }
    //     return 0;
    //   }
    handleFeesLabelChange = (e) => {

        this.setState({
            [e.target.name]: e.target.value
        }, () => this.props.filterFeesLabelTable(this.state.feeLabel))

    }
    render() {

        return (
            <div>
                <table className="tableLayout1" id='feeCollectionHeadTable'>
                    <thead>
                        <tr>
                            <th width="25">Select</th>
                            <th width="40">Fees Code </th>
                            <th width="40"><input type="text" className="form-control inp-search"
                                // value={this.state.enrollmentNo}
                                onKeyUp={this.handleFeesLabelChange}
                                name="feeLabel" id="feeLabelFilter" placeholder="Fees Label" />  </th>
                            <th width="35">Collection</th>
                            <th width="35" > Amount</th>
                            {/* <th width="35" > Discount</th> */}
                            {/* <th width="35" > Net Amt</th> */}
                            {/* <th width="40" > Action</th> */}
                        </tr>
                    </thead>
                </table>

                <div className="tableDivMedium">
                    <table id="feeHeadTable" className="tableLayout">
                        <tbody id="feeHeadTableBody">
                            {this.props.lstFeeHead.map((item, index) => (
                                <tr item={JSON.stringify(item)} key={item.feeHeadId} id={item.feeHeadId} onClick={
                                    () => this.addClassOnBody(item.feeHeadId)}>
                                    <td width="25" className="text-center">
                                        <input id={index} type="checkbox"
                                            checked={item.selected}
                                            onChange={(e) => e.preventDefault}
                                            onClick={(e) => {
                                                this.props.handleCheckboxSave(e,
                                                    item.feeHeadId,
                                                    item.feeClassId);
                                            }}
                                        /></td>
                                    <td width="40" className="text-center">{item.feeHeadCode}</td>
                                    <td width="40" className="text-center">{item.feeLabel}</td>
                                    <td width="35" className="text-center">{item.collectionFrequency}</td>
                                    <td width="35" className="text-right">{item.amount.toLocaleString('en-IN')}</td>
                                    {/* <td width="35" className="text-right">{item.discountAmt.toLocaleString('en-IN')}</td> */}
                                    {/* <td width="40" className="text-center" style={{ cursor: "default" }} onClick={() => {
                                        this.props.getDiscount(item.feeHeadId, item.feeClassId
                                            , item.feeStudentId)
                                    }}>{item.feeStudentId !== 0 ?
                                        <a className="discountTd" onClick={(e) => {
                                            e.preventDefault()
                                        }}>Discount</a>
                                        : ""}</td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <button type="button" className="btn btn-info text-center " id="closeSlider" onClick={this.props.closeSlider}>
                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                </button>
            </div >
        )
    }
}
