import React, { useState, useEffect } from 'react';
import lstModules from 'common/json/lstModules.json';
import ContentTable from './ContentTable';
import ContentForm from './ContentForm';
import Header from 'common/js/header/header'
import axios from 'axios';
import $ from 'jquery';
import { toast, ToastContainer } from 'react-toastify';


var httpURL = lstModules['content'].url + ":" + lstModules['content'].port;
var queryString = require('querystring');
var screenId = 33;
var contentFile = "";
var lstContents = "";

export default function Content() {
    const [state, setState] = useState(
        {
            loader: 'none',
            opacity: 1,
            flg: true,
            tokenid: localStorage.getItem('tokenid'),
            contentData: {},
            lstContent: [],
            contentId: 0,
            direction: {
                uploadDttm: "asc"
            }
        });

    useEffect(() => {
        getContentList()
    },
        [])



    function getContentList() {
        setState({ ...state, loader: 'block', opacity: 0.5 });
        axios({
            url: httpURL + '/sms/content/list',
            method: "POST",
            params: { screenId },
            headers: { 'tokenid': state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                setState({
                    lstContent: response.data.lstContent,
                });
                lstContents = state.lstContent;
            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
            }
            setState({ ...state, loader: 'none', opacity: 1 });
        }).catch((error) => {
            setState({ ...state, loader: 'none', opacity: 1 });
            toast.error("Service failed " + error);
        });
    }

    function uploadFile() {
        $("#contentFile").on('change', function (event) {
            if (!event.target.files) return;
            var files = event.target.files; //FileList object
            contentFile = files[0];
        });
    }

    function saveContent() {
        var content = {};
        var contentId = 0;
        var formData = new FormData();
        var content = $('#content').val();
        var tags = $('#tags').val();

        if (content === "") {
            toast.warning("Please Enter Content Name");
            $('#content').focus()
            return false
        }

        if (state.flg) {
            contentId = 0;
            if (contentFile === "") {
                toast.warning("Please Enter Content Media");
                $('#contentFile').focus()
                return false
            }
        }
        else {
            contentId = state.contentId;
            contentFile = null;
        }
        content = {
            contentId,
            content,
            tags
        }
        formData.append("content", JSON.stringify(content));
        formData.append("contentFile", contentFile);
        formData.append('screenId', screenId);
        setState({ ...state, loader: 'block', opacity: 0.5 })
        axios({
            url: httpURL + '/sms/content/save',
            method: "POST",
            data: formData,
            headers: { 'tokenid': state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === "Success") {
                toast.success(response.data.SvcMsg);
                getContentList();
                addNewItem();
            }
            else {
                toast.error(response.data.SvcMsg);
            }
            setState({ ...state, loader: 'none', opacity: 1 });
        }).catch((e) => {
            setState({ ...state, loader: 'none', opacity: 1 })
            toast.error("Service failed " + e);
        });

    }
    function addNewItem() {
        $('#addForm').trigger('reset');
        $('#contentTableBody tr').removeClass('selected');
        contentFile = "";
        setState({
            flg: true,
        });
    }
    function setContentForm(contentId) {
        var objData = getObjData(contentId);
        $('#content').val(objData.content);
        $('#tags').val(objData.tags);
        setState({
            flg: false,
            contentId: contentId,
        })
    }

    function getObjData(contentId) {
        for (var i = 0; i < state.lstContent.length; i++) {
            if (parseInt(contentId) === parseInt(state.lstContent[i].contentId))
                return state.lstContent[i];
        }
    }

    function deleteContent(contentId) {
        var retVal = window.confirm("Do you want to Delete This Content ?");
        if (retVal == true) {
            setState({ ...state, loader: 'block', opacity: 0.5 });
            axios.post(httpURL + '/sms/content/delete',
                queryString.stringify({ contentId, screenId }),
                {
                    headers: { tokenid: state.tokenid },
                    withCredentials: true
                }
            ).then((response) => {
                if (response.data.SvcStatus === 'Success') {
                    toast.success(response.data.SvcMsg);
                    getContentList();
                } else {
                    toast.error(response.data.SvcMsg);
                }
                setState({ ...state, loader: 'none', opacity: 1 });
            }).catch((e) => {
                setState({ ...state, loader: 'none', opacity: 1 });
                toast.error('Service failed: ' + e);
            });
            return true;
        } else {
            return false;
        }
    }
    function viewContent(contentId) {
        axios.post(httpURL + '/sms/content/view',
            queryString.stringify({ contentId, screenId }),
            {
                headers: { tokenid: state.tokenid },
                withCredentials: true,
                responseType: 'arraybuffer'
            }
        ).then((response) => {
            var file = new Blob([response.data], { type: 'mime' });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);

        }).catch((e) => {
            setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });
    }

    function filterTable(content) {
        if (content !== "") {
            const filterContent = state.lstContent.filter(contents =>
                contents.content.toLowerCase().includes(content.toLowerCase()));
            setState({
                lstContent: filterContent
            })
        }
        else {
            setState({
                lstContent: lstContents
            })
        }
    }

    function filterTableWithType(mediaTypeId) {
        setState({
            lstContent: lstContents
        }, () => {
            if (parseInt(mediaTypeId) !== 0) {
                const filterContent = state.lstContent.filter(contents =>
                    parseInt(contents.mediaTypeId) === parseInt(mediaTypeId));
                setState({
                    lstContent: filterContent
                },
                // // console.log(filterContent)
                 )
            }
            else {
                setState({
                    lstContent: lstContents
                })
            }
        })
    }
    function sortTable(key) {
        setState({
            lstContent: lstContents.sort((a, b) => (
                state.direction[key] === "asc"
                    ? (a[key]) - (b[key])
                    : (b[key]) - (a[key])
            )).reverse(),
            direction: {
                [key]: state.direction[key] === "asc" ?
                    "desc" : "asc"
            }
        })
    }

    function getWingId(wingId) {
        // // console.log("wingId" + wingId);
    }




    return (
        <div style={{ height: '100%' }}>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: state.loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="mainContainer Timing" style={{ opacity: state.opacity }}>
                <div className="col-md-11 col-centered">
                    <div className="col-xs-7 periodTypeWrap  padding-remove">
                        {/* {state.lstContent.length !== 0 ?
                               
                                : null} */}
                        <ContentTable lstContent={state.lstContent}
                            setContentForm={setContentForm}
                            deleteContent={deleteContent}
                            viewContent={viewContent}
                            filterTable={filterTable}
                            filterTableWithType={filterTableWithType}
                            sortTable={sortTable} />
                    </div>
                    <div className="col-xs-1 ">
                        <span id="addIcon" title="Add" onClick={addNewItem} className="addIcon glyphicon glyphicon-plus-sign"></span>
                    </div>
                    <div className="col-xs-4 padding-remove">
                        <ContentForm
                            contentData={state.contentData}
                            uploadFile={uploadFile}
                            saveContent={saveContent}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

