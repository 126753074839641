import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { toast } from 'react-toastify';
import { getLeavesListSvc } from "./studentLeavesSvc";


const StudentLeavesTable = (props, ref) => {

    const [lstLeaves, setLstLeaves] = useState([]);
    const [lstLeave, setLstLeave] = useState([]);
    const [id, setId] = useState(0);
    const [nameFiltered, setNameFiltered] = useState("");
    const [classFiltered, setClassFiltered] = useState("");
    const [statusFiltered, setStatusFiltered] = useState("");

    useEffect(() => {
        // // console.log("stdLeavesTable called");

    }, []);

    useImperativeHandle(ref, () => ({
        getLeavesList: (fromDt, toDt) => {
            // // console.log("useImperativeHandle called");
            // // console.log("getLeavesList Called, fromDt", fromDt, ", toDt", toDt);
            props.setLoader("block");
            props.setOpacity(0.5);
            getLeavesListSvc(fromDt, toDt, leavesListResponse)
        }
    }));

    const leavesListResponse = (data) => {
        // // console.log("leavesListResponse called, data", data)
        let obj = data;
        if (obj.status === 'Success') {
            setLstLeave(obj.lstLeave);
            setLstLeaves(obj.lstLeave);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        props.setLoader('none');
        props.setOpacity(1);
    }
    const filterName = (fName) => {
        // // console.log("filterName called", fName);
        setNameFiltered(fName);
        filterLeavesList(fName, classFiltered, statusFiltered);
    }

    const filterStatus = (fStatus) => {
        // // console.log("fStatus", fStatus)
        setStatusFiltered(fStatus);
        filterLeavesList(nameFiltered, classFiltered, fStatus);
    }

    const filterClass = (fClass) => {
        // // console.log("filterClass called, fClass", fClass);
        setClassFiltered(fClass);
        filterLeavesList(nameFiltered, fClass, statusFiltered);
    }

    const filterLeavesList = (fName, fClass, fStatus) => {
        // // console.log("filterLeavesList called fName", fName, ", fClass", fClass, ", fStatus", fStatus);
        let tempLstLeaves = [];
        tempLstLeaves = lstLeaves.filter((leave) => leave.student.toLowerCase().includes(fName.toLowerCase()));
        tempLstLeaves = tempLstLeaves.filter((leave) => leave.cls.toLowerCase().includes(fClass.toLowerCase()));
        tempLstLeaves = tempLstLeaves.filter((leave) => leave.leaveStatus.toLowerCase().includes(fStatus.toLowerCase()));
        setLstLeave(tempLstLeaves);
    }


    return (
        <div className="col-md-12" style={{ marginTop: "5px" }}>
            <table className="tableLayout1" >
                <thead>
                    <tr>
                        <th className="no-display" style={{ width: 50 }}>
                            Leave Id
                        </th>
                        <th className='text-center' style={{ width: 80 }}>
                            Application Date
                        </th>
                        <th className='text-center' style={{ width: 150 }}>
                            <input type="text" className="form-control inp-search text-center"
                                value={nameFiltered}
                                onChange={(e) => filterName(e.target.value)}
                                name="Student Name"
                                placeholder="Student" />
                        </th>
                        <th className='text-center' style={{ width: 50 }}>
                            Gender
                        </th>
                        <th className='text-center' style={{ width: 50 }}>
                            <input type="text" className="form-control inp-search text-center"
                                value={classFiltered}
                                onChange={(e) => filterClass(e.target.value)}
                                name="Student Class"
                                placeholder="Class" />
                        </th>
                        <th className='text-center' style={{ width: 50 }}>
                            Section
                        </th>
                        <th className='text-center' style={{ width: 50 }}>
                            Days
                        </th>
                        <th className='text-center' style={{ width: 80 }}>
                            From Data
                        </th>
                        <th className='text-center' style={{ width: 80 }}>
                            To Date
                        </th>
                        <th className='text-center' style={{ width: 80 }}>
                            Leave Type
                        </th>
                        <th className='text-center' style={{ width: 50 }}>
                            <input type="text" className="form-control inp-search text-center"
                                value={statusFiltered}
                                onChange={(e) => filterStatus(e.target.value)}
                                name="Leave Status"
                                placeholder="Status" />
                        </th>
                        <th className='text-center' style={{ width: 100 }}>
                            Action
                        </th>
                    </tr>
                </thead>
            </table>
            <div className="tableDiv tableBodyBorder" style={{ height: "500px" }}>
                <table className='tableLayout'>
                    <tbody id="studentLeavesTable">
                        {
                            lstLeave && lstLeave ?
                                lstLeave.map((leave) => (
                                    <tr key={leave.leaveId}
                                        onClick={(e) => setId(leave.leaveId)}
                                        className={leave.leaveId === id ? "selected" : ""}>
                                        <td style={{ width: 50 }} className='no-display text-center'>{leave.leaveId}</td>
                                        <td style={{ width: 80 }} className='text-center'>{leave.applicationDt}</td>
                                        <td style={{ width: 150 }} className='text-center'>{leave.student}</td>
                                        <td style={{ width: 50 }} className='text-center'>{leave.gender}</td>
                                        <td style={{ width: 50 }} className='text-center'>{leave.cls}</td>
                                        <td style={{ width: 50 }} className='text-center'>{leave.section}</td>
                                        <td style={{ width: 50 }} className='text-center'>{leave.daysCount}</td>
                                        <td style={{ width: 80 }} className='text-center'>{leave.fromDt}</td>
                                        <td style={{ width: 80 }} className='text-center'>{leave.toDt}</td>
                                        <td style={{ width: 80 }} className='text-center'>{leave.leaveType}</td>
                                        <td style={{ width: 50 }} className='text-center'>{leave.leaveStatus}</td>

                                        <td style={{ width: 100 }} >
                                            <span style={{ display: 'flex', justifyContent: 'space-evenly' }} >
                                                <label
                                                    style={{
                                                        textDecoration: 'underline',
                                                        cursor: 'pointer'
                                                    }}
                                                    className='text-primary'
                                                    onClick={e => { e.preventDefault(); props.approveLeave(leave.leaveId); }}
                                                >
                                                    Approve
                                                </label>
                                                {/* {' | '} */}
                                                <label
                                                    style={{
                                                        textDecoration: 'underline',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={e => { e.preventDefault(); props.rejectLeave(leave); }}
                                                    className='text-primary'
                                                >
                                                    Reject
                                                </label>
                                            </span>
                                        </td>
                                    </tr>
                                ))
                                : null
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default forwardRef(StudentLeavesTable);