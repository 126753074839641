import React, { useState, useEffect } from 'react'
import lstModules from 'common/json/lstModules.json'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import 'common/css/master.css'
import Header from 'common/js/header/header'
import WTestProgressTable from './WTestProgressTable.js';
import WTestStudentScoreTable from './WTestStudentScoreTable.js';
import WTestProgressHeader from './WTestProgressHeader.js';
import { getTestLeaderboardSvc } from './WTestProgressSvc.js';

const WTestProgressCtrl = () => {
    const [lstStudentId, setLstStudentId] = useState([]);
    const [flg, setFlg] = useState(0);
    const [lstQusStatus, setLstQusStatus] = useState([]);
    const search = useLocation().search
    const [testId, setTestId] = useState(
        new URLSearchParams(search).get('testId')
    );
    const [test, setTest] = useState(
        new URLSearchParams(search).get('test')
    );
    const [lstLeaderBoard, setLstLeaderBoard] = useState([]);

    useEffect(() => {
        var params = new URLSearchParams(search);
        if (params.get("testId")) {
            setTestId(params.get("testId"))
        }
        if (params.get("test")) {
            setTest(params.get("test"))
        }
        getTestLeaderboard();
    }, [])
    function getTestLeaderboard() {
        getTestLeaderboardSvc(testId, cbGetTestLeaderboardSvc);
      }
      function cbGetTestLeaderboardSvc(data) {
        // // console.log("cbGetTestLeaderboardSvc() called.. ")
        if (data.SvcStatus === "Success") {
            setLstLeaderBoard(data.lstLeaderBoard)
        } else {
            setLstLeaderBoard([])
          toast.error(data.SvcMsg)
        }
      } 

   
    return (
        <div style={{ height: '100%' }}>

            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div className='MainContainer' style={{ marginTop: 30 }}>
                <div className="col-sm-12 col-centered">
                    <WTestProgressHeader
                        testId={testId}
                        test={test}
                    />
                </div>
                <div className="col-sm-12 col-centered" style={{ top: 15 }}>
                    <div className="col-sm-4 padding-remove">
                        <WTestProgressTable
                            testId={testId}  getTestLeaderboard={getTestLeaderboard} />
                    </div>
                    {<div className="col-sm-5 " >
                        <WTestStudentScoreTable lstLeaderBoard={lstLeaderBoard}/>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default WTestProgressCtrl