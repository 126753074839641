import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import Header from 'common/js/header/header.js';
import { toast, ToastContainer } from 'react-toastify';
import MainTimeTable from './mainTimeTable.js';
import '../css/tt.css';
import $ from "jquery";
import axios from 'axios';
import Section from '../../header/js/section.js';
import ExamTimeLine from '../../header/js/examTimeLine.js';
import SubjectTable from './subjectTable.js';
import TeacherTable from './teacherTable.js';
import DatesheetTable from './datesheetTable.js';

var httpURL = '';
var queryString = require('querystring');
var screenId = 33;

export default class ExamTimeTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: 'none',
            opacity: 1,
            flg: true,
            timetableId: 1,
            timetable: "Abc",
            wingId: 1,
            classSectionId: 1,
            lstClass: [],
            tokenid: localStorage.getItem('tokenid'),
            showMTT: 'none',
            // lstSubject:[],
            showSubTbl: 'none',
            showTeachTbl: 'none',
            showDtsheetTbl: 'none',
            lstExamDays: [],
            lstDateSheet: []
        }
        this.changeCls = this.changeCls.bind(this);
    }
    componentDidMount() {
        this.getExamSchedule();
        this.getLstClass();
        window.addEventListener("load", this.handleResize);
        window.addEventListener("resize", this.handleResize);
    }

    getSubjectList = (classId) => {
        axios({
            url: httpURL + '/sms/cls/sub',
            method: "POST",
            data: queryString.stringify({ screenId, 'classId': classId }),
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                var lstSubject = response.data.lstSubject;
                this.setState({ lstSubject: lstSubject, showSubTbl: 'block' });
                // // console.log("lstSubject in subjectView: "+this.state.lstSubject);
            }
            else {
                this.setState({ lstSubject: [], showSubTbl: 'block' });
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not Logged In') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
        this.setState({ loader: 'none', opacity: 1 });

    }

    getTeacherList = (classId) => {
        axios({
            url: httpURL + '/sms/cls/tch',
            method: "POST",
            data: queryString.stringify({ screenId, 'classId': classId }),
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                var lstTeacher = response.data.lstTeacher;
                this.setState({ lstTeacher: lstTeacher, showTeachTbl: 'block' });
                // // console.log("lstSubject in subjectView: "+this.state.lstSubject);
            }
            else {
                this.setState({ lstTeacher: [], showTeachTbl: 'block' });
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not Logged In') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
        this.setState({ loader: 'none', opacity: 1 });

    }
    getDatesheetList = (examId, alongWithStudy) => {
        var datesheet = {
            "examId": examId,
            "classSectionId": this.state.classSectionId,
            "alongWithStudy": alongWithStudy
        };
        axios({
            url: httpURL + '/sms/exam/datesheet',
            method: "POST",
            params: { screenId, 'datesheet': JSON.stringify(datesheet) },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                var lstDateSheet = response.data.lstDateSheet;
                this.setState({ lstDateSheet: lstDateSheet, showDtsheetTbl: 'block' });
                // // console.log("lstSubject in subjectView: "+this.state.lstSubject);
            }
            else {
                this.setState({ lstDateSheet: [], showDtsheetTbl: 'block' });
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not Logged In') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
        this.setState({ loader: 'none', opacity: 1 });

    }
    handleResize = () => {
        var width = ($('.tableLayout2').parent().width()) - 18;
        this.setState({ width2: width });
    }

    getExamSchedule = () => {
        let screenId = 33;
        this.setState({ loader: 'block', opacity: 0.5 });
        axios({
            url: httpURL + '/sms/performance/term/exam/list',
            method: "POST",
            params: { 'screenId': screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                // // console.log("response.data.lstSchedule = " + response.data.lstSchedule);
                this.setState({ lstExamSchedule: response.data.lstExam });
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });

    }



    getLstClass = () => {
        // let wingId = $('#wingCmb').find('option:selected').val();
        let wingId = 1;
        let screenId = 33;
        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/tt/list/class',
            queryString.stringify({ wingId, screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                // // console.log("response.data.lstWingClass = "+response.data.lstWingClass);
                this.setState({ lstClass: response.data.lstWingClass });
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });
    }

    changeCls = (e) => {
        let wingId = 1;
        let screenId = 33;
        var classId = $('#clsCmb').find('option:selected').attr('id');
        this.setState({ classId: classId });


        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/tt/list/section',
            queryString.stringify({ classId, screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                // // console.log("response.data.lstClassSection = "+response.data.lstClassSection);
                this.setState({ lstClassSection: response.data.lstClassSection });
                this.getSubjectList(this.state.classId);
                this.getTeacherList(this.state.classId);
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });
    }

    handleSchCallback = (examId, alongWithStudy, startDt, lstExamDays) => {
        this.setState({ examScheduleId: examId });
        this.setState({ showMTT: 'block' });
        this.setState({ alongWithStudy: alongWithStudy });
        this.setState({ examDt: startDt });
        this.setState({ lstExamDays: lstExamDays });
        // // console.log(lstExamDays);
        this.getLstExamSlot(examId, alongWithStudy, this.state.classId, startDt);
        this.getDatesheetList(examId, alongWithStudy);

    }
    handleCallback = (classSectionId) => {
        this.setState({ classSectionId: classSectionId });
    }

    getLstExamSlot = (examId, alongWithStudy, classId, startDt) => {
        let screenId = 33;
        this.setState({ loader: 'block', opacity: 0.5 });
        var examSlotRQ = { "examId": examId, "alongWithStudy": alongWithStudy, "classId": classId, "startDt": startDt }
        axios({
            url: httpURL + '/sms/exam/list/slot',
            method: "POST",
            params: { 'screenId': screenId, 'examSlotRQ': JSON.stringify(examSlotRQ) },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                // // console.log("response.data.lstExamPeriod = " + response.data.lstExamPeriod);
                this.setState({ lstExamPeriod: response.data.lstExamSlot });
            } else {
                toast.error(response.data.SvcMsg);
                this.setState({ lstExamPeriod: [] });
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });

    }

    getMainTimeTable = () => {
        return (
            this.state.lstExamDays.length > 0 &&
            <>
                <MainTimeTable
                    style={{ display: this.state.showMTT }}
                    classSectionId={this.state.classSectionId}
                    examScheduleId={this.state.examScheduleId}
                    lstExamPeriod={this.state.lstExamPeriod}
                    alongWithStudy={this.state.alongWithStudy}
                    examDt={this.state.examDt}
                    lstExamDays={this.state.lstExamDays}
                    examId={this.state.examScheduleId}
                    lstTeacher={this.state.lstTeacher}
                    lstSubject={this.state.lstSubject}

                />
                <button type="button" className="btn btn-secondary">Publish</button>
            </>
        );
    }
    getSubjectTable = () => {

        return (
            this.state.showSubTbl
            &&
            this.state.lstSubject
            &&
            <SubjectTable
                style={{ display: this.state.showSubTbl }}
                lstSubject={this.state.lstSubject}

            />
        );
    }
    getTeacherTable = () => {

        return (
            this.state.showTeachTbl
            &&
            this.state.lstTeacher
            &&
            <TeacherTable
                style={{ display: this.state.showTeachTbl }}
                lstTeacher={this.state.lstTeacher}
            />
        );
    }
    getDatesheetTable = () => {

        return (
            this.state.showDtsheetTbl
            &&
            this.state.lstDateSheet
            &&
            <DatesheetTable
                style={{ display: this.state.showDtsheetTbl }}
                lstDateSheet={this.state.lstDateSheet}
            />
        );
    }
    getWingId = (wingId) => {
        // // console.log('wingId = ' + wingId)
    }
    render() {
        httpURL = lstModules['examTT'].url + ":" + lstModules['examTT'].port;
        // httpURL = 'http://192.168.1.20:8081';
        return (
            <div style={{ height: '100%' }}>
                <Header wingFromChild={this.getWingId} />
                <ToastContainer autoClose={3000} hideProgressBar />
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: this.state.loader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>
                <div className="mainContainer timeTableWrapper" style={{ opacity: this.state.opacity }}>
                    <>
                        {/* <ClsSection /> */}
                        <div className="container-fluid" style={{ boxShadow: '0 0 10px 0 #214a75', marginTop: '-10px' }}>
                            <div className="col-sm-12" style={{ padding: '10px' }}>
                                <div className="col-sm-2">
                                    <select autoComplete="off" id="clsCmb" onChange={() => this.changeCls()} className="form-control">
                                        <option id={0} key={0}>Class</option>
                                        {this.state.lstClass.map((cls) => {
                                            return <option id={cls.classId} key={cls.classId}>{cls.className}</option>
                                        })}
                                    </select>
                                </div>

                                <div className="col-sm-10">
                                    {this.state
                                        && this.state.lstClassSection
                                        && this.state.lstClassSection.length != 0
                                        && <Section
                                            data={this.state.lstClassSection}
                                            selected={this.state.lstClassSection[0].classSectionId}
                                            parentCallback={this.handleCallback} />
                                    }
                                </div>
                                {
                                    <ExamTimeLine data={this.state.lstExamSchedule} parentCallback={this.handleSchCallback} />
                                }
                            </div>

                        </div>


                        <div className="col-sm-12 padding-remove">
                            <div className="col-sm-8">
                                {this.getMainTimeTable()}

                            </div>
                            <div className="col-sm-4">
                                <div className="col-sm-12 padding-remove">
                                    {this.state
                                        &&
                                        this.getSubjectTable()}
                                </div>
                                <div className="col-sm-12 padding-remove">
                                    {this.state
                                        &&
                                        this.getTeacherTable()}
                                </div>
                                <div className="col-sm-12 padding-remove">
                                    {this.state
                                        &&
                                        this.getDatesheetTable()}
                                </div>
                            </div>

                        </div>
                    </>
                </div>
            </div>
        )
    }
}
