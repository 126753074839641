import React, { useEffect, useState } from 'react';
import { Document, Page, PDFViewer, View, Text, Image } from '@react-pdf/renderer';

import { pageStyle, textStyle, headerFooter } from './styleQuestionsPDF';



const TestHeader = (props) => {
    const { logoUrl, subject, clsSec, school, academicYear, duration, questionCount } = props;

    useEffect(() => {
        // // console.log("useEffect in TestHeader called, logoUrl", logoUrl);
    }, []);

    return (
        <View style={headerFooter.row}>
            <View style={headerFooter.sideSection}>
                {!!logoUrl ?
                    <Image style={headerFooter.image_logo} src={logoUrl} />
                    : null
                }
            </View>
            <View style={headerFooter.centralSection}>
                <View style={headerFooter.viewCenter}>
                    <Text style={textStyle.textCenterLargeBold}>{school}</Text>
                </View>
                <View style={headerFooter.viewCenter}>
                    <Text style={textStyle.textCenterMediumBold}>{academicYear}</Text>
                </View>
                <View style={headerFooter.viewCenter}>
                    <Text style={textStyle.textCenterMediumBold}>{subject}</Text>
                </View>
            </View>
            <View style={headerFooter.sideSection}>
                <View style={headerFooter.row}>
                    <View style={headerFooter.viewLeft}>
                        <Text style={textStyle.textLeftMedium1Bold}>{"Duration:"}</Text>
                    </View>
                    <View style={headerFooter.viewCenter}>
                        <Text style={textStyle.textCenterMediumBold}>{duration}</Text>
                    </View>
                </View>
                <View style={headerFooter.row}>
                    <View style={headerFooter.viewLeft}>
                        <Text style={textStyle.textLeftMedium1Bold}>{"Questions:"}</Text>
                    </View>
                    <View style={headerFooter.viewCenter}>
                        <Text style={textStyle.textCenterMediumBold}>{questionCount}</Text>
                    </View>
                </View>
                <View style={headerFooter.row}>
                    <View style={headerFooter.viewCenter}>
                        <Text style={textStyle.textLeftMedium1Bold}>{clsSec}</Text>
                    </View>
                </View>
            </View>
        </View>
    );
}


const PageHeader = (props) => {
    const { className } = props;

    useEffect(() => {
        // // console.log("useEffect in PageHeader called, className", className);
    }, []);

    return (
        <View style={headerFooter.header}>
            {/* <View style={headerFooter.column}>
                <Text style={textStyle.textLeftLarge}>Subject</Text>
            </View>
            <View style={headerFooter.column}>
                <Text style={textStyle.textRightLarge}>{className}</Text>
            </View> */}
        </View>
    );
}


const PageFooter = (props) => {
    const { pageNo } = props;

    useEffect(() => {
        // // console.log("useEffect in PageFooter called, pageNo", pageNo);
    }, []);

    return (
        <View style={headerFooter.footer}>
            <View style={headerFooter.column}>
                <Text style={textStyle.textRightExtraSmall}>{""}</Text>
            </View>
        </View>
    );
}


export { TestHeader, PageHeader, PageFooter };

