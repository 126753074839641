import axios from 'axios';
import lstModules from 'common/json/lstModules.json';
var tokenid = localStorage.getItem("tokenid");
var httpURL = lstModules['recruitment'].url + ":" + lstModules['recruitment'].port;



function recruitmentEducationLstSvc(cbTchLstResponse,tchId) {
    axios(
        {   url:                httpURL + "/sms/recruitment/education/list",
            method:             "POST",
            withCredentials:    true,
            params:{tchId:tchId},
            headers: { 'tokenid': tokenid }
           
    })
    .then((response) => {
    cbTchLstResponse(response.data.lstQualification)
    })
    .catch((error)=>{
        // // console.log( error )
    });

    
}
function recruitmentEducationDtlsSvc(cbTchLstResponse,tchId,qualificationId) {
    axios(
        {   url:                httpURL + "/sms/recruitment/education/dtls",
            method:             "POST",
            withCredentials:    true,
            params:{tchId:tchId,qualificationId:qualificationId},
            headers: { 'tokenid': tokenid }
           
    })
    .then((response) => {
    cbTchLstResponse(response.data.qualification)
    })
    .catch((error)=>{
        // // console.log( error )
    });

    
}
function recruitmentEducationSaveSvc(cbRecruitmentEducationSaveResponse, qualification,lstAttachment) {
    var formData =new FormData()
    formData.append("qualification", JSON.stringify(qualification));
    formData.append("lstAttachment",lstAttachment)
    axios(
        {   url:                httpURL + "/sms/recruitment/education/save",
            method:             "POST",
            data:formData,
            withCredentials:    true,
            params:{},
           
            headers: { 'tokenid': tokenid }
           
    })
    .then((response) => {
    cbRecruitmentEducationSaveResponse(response.data.lstEducation)
    })
    .catch((error)=>{
        // // console.log( error )
    });

    
}

function recruitmentEducationAttachmentSaveSvc(cbrecruitmentEducationAttachmentSaveResponse,attachmentFile,staffAttachmentId,rowId,tchId) {
    var formData = new FormData();

    formData.append("attachmentFile",attachmentFile);
    var attachment ={"staffAttachmentId": staffAttachmentId,"rowId":rowId,"typeId":40,"tchId":tchId}
    formData.append("attachment",JSON.stringify(attachment));
    


axios({
        url: httpURL + "/sms/recruitment/attachment/save",
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === "Success") {
            cbrecruitmentEducationAttachmentSaveResponse(response.data.SvcMsg);
           
        }
        else {
            // // console.log(response.data.SvcMsg);
        }
        
    });

    
}
function recruitmentEducationDeleteSvc(cbEducationDeleteResponse,tchId,qualificationId) {
    axios(
        {   url:                httpURL + "/sms/recruitment/education/delete",
            method:             "POST",
            withCredentials:    true,
            params:{tchId:tchId,qualificationId:qualificationId},
            headers: { 'tokenid': tokenid }
           
    })
    .then((response) => {
    cbEducationDeleteResponse(response.data)
    })
    .catch((error)=>{
        // // console.log( error )
    });

    
}
function recruitmentAttachmentDeleteSvc(staffAttachmentId) {
    axios(
        {   url:                httpURL + "/sms/recruitment/attachment/delete",
            method:             "POST",
            withCredentials:    true,
            params:{staffAttachmentId:staffAttachmentId},
            headers: { 'tokenid': tokenid }
           
    })
    .then((response) => {
    // // console.log(response.data)
    })
    .catch((error)=>{
        // // console.log( error )
    });

    
}
function recruitmentMstEducationSvc(cbRecruitmentMstEducationResponse) {
    axios(
        {   url:                httpURL + "/sms/recruitment/mst/education/list",
            method:             "POST",
            withCredentials:    true,
            params:{},
            headers: { 'tokenid': tokenid }
           
    })
    .then((response) => {
    cbRecruitmentMstEducationResponse(response.data.lstEducation)
    })
    .catch((error)=>{
        // // console.log( error )
    });

    
}
function recruitmentMstGradeSvc(cbRecruitmentMstGradeResponse) {
    axios(
        {   url:                httpURL + "/sms/recruitment/mst/grade/list",
            method:             "POST",
            withCredentials:    true,
            params:{},
            headers: { 'tokenid': tokenid }
           
    })
    .then((response) => {
    cbRecruitmentMstGradeResponse(response.data.lstGrade)
    })
    .catch((error)=>{
        // // console.log( error )
    });

    
}
function recruitmentAttachmentView(staffAttachmentId) {
    axios(
        {   url:                httpURL + "/sms/recruitment/attachment/viewimg",
            method:             "GET",
            withCredentials:    true,
            params:{staffAttachmentId:staffAttachmentId},
            headers: { 'tokenid': tokenid }
           
    })
    .then((response) => {
        // // console.log(response.data)
    })
    .catch((error)=>{
        // // console.log( error )
    });

    
}

function recruitmentMstModeSvc(cbRecruitmentMstModeResponse) {
    axios(
        {   url:                httpURL + "/sms/recruitment/mst/mode/list",
            method:             "POST",
            withCredentials:    true,
            params:{},
            headers: { 'tokenid': tokenid }
           
    })
    .then((response) => {
    cbRecruitmentMstModeResponse(response.data.lstMode)
    })
    .catch((error)=>{
        // // console.log( error )
    });

    
}








export { recruitmentEducationLstSvc,recruitmentEducationDtlsSvc,recruitmentAttachmentView,recruitmentEducationSaveSvc,recruitmentMstEducationSvc,recruitmentMstGradeSvc,recruitmentMstModeSvc,recruitmentEducationDeleteSvc,recruitmentEducationAttachmentSaveSvc,recruitmentAttachmentDeleteSvc }