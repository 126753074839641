import React from "react";
import "common/css/master.css";
import "../../css/subjectAllocation.css";
import "../../css/allocationCards.css";

const AllocationFilter = ({ allocationFiltered, setAllocationFiltered }) => {
    // Define allocation ranges with their labels and ensure consistent value types (strings)
    const allocationRanges = [
        { label: "< 70", value: "< 70" },
        { label: "> 70", value: "> 70" },
        { label: "> 80", value: "> 80" },
        { label: "> 90", value: "> 90" },
        { label: "> 95", value: "> 95" }
    ];

    // Function to handle card click and update allocationFiltered
    const handleCardClick = (value) => {
        // If the selected value is already the current one, reset to null (unselect)
        if (allocationFiltered === value) {
            setAllocationFiltered(null); // Use null for no selection
        } else {
            setAllocationFiltered(value);
        }
    };

    return (
        <div className="col-md-12 col-xs-12 padding-remove">
            <div className="custom-row">
                {allocationRanges.map((range) => (
                    <div className="custom-col" key={range.value}>
                        <div
                            className={`allocation-card ${allocationFiltered === range.value ? "selected" : ""}`}
                            onClick={() => handleCardClick(range.value)}
                        >
                            <div className="allocation-card-content">
                                <div className="allocation-card-label">
                                    Allocation {range.label}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AllocationFilter;
