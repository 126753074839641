import React from 'react';

const RowRenderer = ({
    rowData,
    lstSection,
    lstUnselectedSection,
    handleSectionChange,
    groupIndex
}) => (
    <div
        className="col-sm-11 padding-remove checkbox-days-container"
        style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}
    >
        {lstSection.map((section) => {
            const isSectionDisabled =
                !rowData.lstSecId.includes(section.secId) && !lstUnselectedSection.includes(section.secId);
            return (
                <div key={section.secId} className="checkbox-days">
                    <input
                        type="checkbox"
                        disabled={isSectionDisabled}
                        checked={rowData.lstSecId.includes(section.secId)}
                        onChange={() => handleSectionChange(groupIndex + 1, section.secId)}
                    />
                </div>
            );
        })}
    </div>

);

export default RowRenderer;
