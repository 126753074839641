import React from 'react';

export default function errorTable(props) {
    return (
        <div>
            <table className="tableLayout1 errorTable" style={{ width: props.width }}>
                <thead>
                    <tr>
                        <th style={{ display: 'none' }}> error Id </th>
                        <th style={{ width: 30 }}>Row Number </th>
                        <th style={{ width: 60 }}> Error </th>
                        <th style={{ width: 60 }}> Warning </th>
                        <th style={{ width: 60 }}> Message</th>
                        <th style={{ width: 60 }}> Sheet Name </th>

                    </tr>
                </thead>
            </table>
            <div className="errorTableDiv" style={{ height: '300px', overflowY: 'scroll', border: '1px solid #ccc' }} >
                <table id="errorTable" className="tableLayout">
                    <colgroup>
                        <col width="30" />
                        <col width="60" />
                        <col width="60" />
                        <col width="60" />
                        <col width="60" />
                    </colgroup>
                    <tbody id="errorTableBody"></tbody>
                </table>
            </div>
        </div>
    )
}
