import lstModules from 'common/json/lstModules.json';
import axios from 'axios';

let queryString = require('querystring');
const tokenid = localStorage.getItem('tokenid');
const httpURLUserLogin = lstModules['userlogin'].url + ':' + lstModules['userlogin'].port;
const httpURLLogin = lstModules["login"].url + ":" + lstModules["login"].port;
const httpURLSetup = lstModules["setup"].url + ":" + lstModules["setup"].port;


export const getLoginDataSvc = (emails, cbLoginData) => {
    // // console.log("getLoginDataSvc called in loginSvc, emails", emails);
    const formData = new FormData;
    formData.append("emails", JSON.stringify(emails));
    const obj = { status: "", message: "" };
    axios({
        url: httpURLUserLogin + '/cooledsys/user/loginData',
        method: "POST",
        headers: { 'tokenid': tokenid },
        data: formData,
        withCredentials: true,
    }).then((response) => {
        // // console.log("response in getLoginData", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.loginData = response.data.loginData;
            obj.lastLogin = response.data.loginData[0].lastLogin;
            obj.top3ScreensId = response.data.loginData;
        }
        cbLoginData(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbLoginData(obj);
    })
}



export const loginSvc = (loginArr, cbLogin) => {
    // // console.log("loginSvc in loginSvc, loginArr", loginArr);
    // // console.log(httpURLUserLogin + '/cooledsys/user/login');
    const formData = new FormData;
    formData.append("loginInfo", JSON.stringify(loginArr));
    const obj = { loginArr, status: "", message: "" };
    axios({
        url: httpURLUserLogin + '/cooledsys/user/login',
        method: "POST",
        headers: { 'tokenid': tokenid },
        data: formData,
        // withCredentials: true,
    }).then((response) => {
        // // console.log("loginSvc called, response", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.defaultSchoolId = response.data.userDtls.defaultSchoolId;
            obj.lstSchool = JSON.stringify(response.data.userDtls.lstSchool);
            obj.defaultRoleId = response.data.userDtls.defaultRoleId;
            obj.defaultWingId = response.data.userDtls.defaultWingId;
            obj.defaultAcdYrId = response.data.userDtls.defaultAcdYrId;
            obj.academicYear = response.data.userDtls.acdYr;
            obj.tokenId = response.headers.tokenid;
        }
        // // console.log("loginSvc called, obj", obj);
        cbLogin(obj);
    })
        .catch((error) => {
            obj.status = "Exception";
            obj.message = error;
            cbLogin(obj);
        })
}



export const checkSetupCompletedSvc = (pTokenId, cbCheckSetupCompleted) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURLSetup + '/sms/setup/completed',
        method: "POST",
        headers: { 'tokenid': pTokenId },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.setupCompleted = response.data.setupCompleted;
        }
        cbCheckSetupCompleted(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbCheckSetupCompleted(obj);
    })
}


export const getModuleSvc = (pTokenId, cbGetModule) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURLSetup + '/sms/setup/module',
        method: "POST",
        headers: { 'tokenid': pTokenId },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.module = JSON.stringify(response.data.module);
        }
        cbGetModule(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbGetModule(obj);
    })
}


export const forgotPasswordSvc = (mail, cbForgotPassword) => {
    const obj = { status: "", message: "" };
    const formData = new FormData;
    formData.append("mail", mail);
    axios({
        url: httpURLUserLogin + '/cooledsys/user/forgotpassword',
        method: "POST",
        headers: { 'tokenid': tokenid },
        // params: {email: mail},
        data: formData,
        withCredentials: true,
    }).then((response) => {
        // // console.log("response in getForgotPasswordSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbForgotPassword(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbForgotPassword(obj);
    })
}





