
import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import { styles } from './idCardStyles';
import { iconButtonClasses } from '@mui/material';


const IDCardContent = (props) => {

    const { baseColor, selPageType, lstSudentId, logoUrl, imageUrl, lstIdCard, selGroup, selTpt, reissueFee, totalRows, totalCols, totalCopies, groupPrintId, pageOrientaion } = props;

    const [noOfPages, setNoOfPages] = useState(0);
    const [sides, setSides] = useState(0);
    const [iCardListLength, setICardListLength] = useState(lstSudentId.length);

    useEffect(() => {
        // // console.log("useeffect in idcardcontent");
        // // console.log("pageType is", selPageType);
        // // console.log("baseColor:", baseColor);
        // // console.log("selPageType:", selPageType);
        // // console.log("lstSudentId:", lstSudentId);
        // // console.log("logoUrl:", logoUrl);
        // // console.log("imageUrl:", imageUrl);
        // // console.log("lstIdCard:", lstIdCard);
        // // console.log("selGroup:", selGroup);
        // // console.log("selTpt:", selTpt);
        // // console.log("reissueFee:", reissueFee);
        // // console.log("totalRows:", totalRows);
        // // console.log("totalCols:", totalCols);
        // // console.log("totalCopies:", totalCopies);
        // // console.log("groupPrintId:", groupPrintId);
        // // console.log("iCardListLength", iCardListLength);


        if (groupPrintId === 30) {
            setSides(iCardListLength * 2);
            setNoOfPages( Math.ceil((iCardListLength * 2) / totalCopies));
            // // console.log("total no of Pages, Math.ceil((iCardListLength * 2) / totalCopies)",Math.ceil((iCardListLength * 2) / totalCopies));
        }
        else {
            setSides(iCardListLength);
            setNoOfPages(Math.ceil(iCardListLength / totalCopies));
            // // console.log("total no of Pages, Math.ceil((iCardListLength * 2) / totalCopies)",Math.ceil(iCardListLength  / totalCopies));
        }


        // // console.log("sides", (groupPrintId === 30) ? iCardListLength * 2 : iCardListLength);
        // // console.log("noOfPages", (groupPrintId === 30) ? Math.ceil((iCardListLength * 2) / totalCopies) : Math.ceil(iCardListLength / totalCopies));

    }, [baseColor, selPageType, groupPrintId, selGroup, selTpt, reissueFee]);

    const setSchoolAddress = (school) => {
        let addr1 = (school.addressLine1 === "-" || school.addressLine1 === "") ? "" : school.addressLine1;
        let addr2 = (school.addressLine2 === "-" || school.addressLine2 === "") ? "" : school.addressLine2;
        let cty = (school.city === "-" || school.city === "Others" || school.city === "") ? "" : school.city;
        let state = (school.state === "-" || school.state === "Others" || school.state === "") ? "" : school.state;
        let pin = (school.pin === "-" || school.pin === "") ? "" : school.pin;
        return (
            addr1 +
            (addr1 && (addr2 || cty || state || pin) ? ", " : "") +
            addr2 +
            (addr2 && (cty || state || pin) ? ", " : "") +
            cty +
            (cty && (state || pin) ? ", " : "") +
            state +
            (state && pin ? ", " : "") +
            pin
        );
    }

    const getAddressLine3 = (sDtls) => {
        let cty = (sDtls.city === "-" || sDtls.city === "" || sDtls.city === "Others") ? "" : sDtls.city;
        let state = (sDtls.state === "-" || sDtls.state === "" || sDtls.state === "Others") ? "" : sDtls.state;
        let pin = (sDtls.pin === "-" || sDtls.pin === "") ? "" : sDtls.pin;
        return (
            cty +
            (cty && (state || pin) ? ", " : "") +
            state +
            (state && pin ? " - " : "") +
            pin
        );
    }

    const getAddrLn1 = (addr) => {
        let addr1 = (addr === "-" || addr === "" || addr === "Others") ? "" : addr;
        return addr1;
    }

    const getAddrLn2 = (addr) => {
        let addr2 = (addr === "-" || addr === "" || addr === "Others") ? "-" : addr;
        return addr2;
    }

    const onImagerror = () => {
        // return (window.appURL + '/no-image.png')
        return "images/no-image.png";
    }


    const pages = [];
    let pageIdx = 0;
    let cardIdx = 0;
    let sideIdx = 0;
    let pageContent = [];
    let rowContent = [];

    for (pageIdx = 0; pageIdx < noOfPages; pageIdx++) {
        if (cardIdx >= iCardListLength) {
            break;
        }
        pageContent = [];
        for (let rowIdx = 0; rowIdx < totalRows; rowIdx++) {
            if (cardIdx >= iCardListLength) {
                break;
            }
            sideIdx = pageIdx * totalCopies + rowIdx * totalCols;
            rowContent = [];
            for (let colIdx = 0; ;) {
                if (groupPrintId === 10 || (groupPrintId === 30 && sideIdx % 2 === 0)) {
                    if (cardIdx >= iCardListLength) {
                        break;
                    }
                    if (colIdx >= totalCols) {
                        break;
                    }

                    rowContent.push(
                        <View style={[styles.frontPage, styles.pageDiv, { backgroundColor: baseColor }]}>
                            <View style={styles.topDiv}>
                                <View style={styles.divCol2}>
                                    <View style={styles.divText1} >
                                        <Text style={[styles.headingText1]}>{lstIdCard[cardIdx].front.school.school}</Text>
                                    </View>
                                    <View style={styles.divText1}>
                                        <Text style={[styles.text1]}>{setSchoolAddress(lstIdCard[cardIdx].front.school)}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.mainDiv}>
                                <View style={styles.divCol1}>
                                    <View style={styles.divRow1}>
                                        <View style={styles.divCol2}>
                                            <View style={styles.divText2}>
                                                <Text style={[styles.text1]}>{lstIdCard[cardIdx].front.acdYr}</Text>
                                            </View>
                                            <View style={styles.divImg2}>
                                                <Image style={styles.image1} src={logoUrl} />
                                            </View>
                                        </View>
                                        <View style={styles.divCol2}>
                                            <View style={styles.divImg1}>
                                                {/* <Image style={styles.image1} src="https://th.bing.com/th/id/OIP.wuN6FNhAbL8uwjQnKicEMQHaE8?rs=1&pid=ImgDetMain" /> */}
                                                {/* <Image style={styles.image1} src="http://192.168.1.3:8280/sms/student/docs/view?imgPath=/home/ubuntu/sms/be/sms_files/4/student/docs/120_50_61ac3d0cf349d277fca215a9b0fd182f.jpg"  /> */}
                                                {/* <Image style={styles.image1} src="https://sms.cooledsys.com/no-image.png" /> */}
                                                <Image style={styles.imageProfile} src={lstIdCard[cardIdx].picURL} />
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.divRow2}>
                                        <View style={styles.divCol2}>
                                            <View style={[styles.divText5]}>
                                                <Text style={[styles.headingText2]}>{lstIdCard[cardIdx].front.student.name}</Text>
                                            </View>
                                        </View>
                                        <View style={styles.divCol2}>
                                            <View style={styles.divText3}>
                                                <Text style={[styles.text1]}>{lstIdCard[cardIdx].front.student.clsSection}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.horizontalBar}></View>
                                <View style={styles.divCol2}>
                                    <View style={[styles.divText6, { flexDirection: "row" }]}>
                                        <Text style={[styles.text4]}>Father: </Text>
                                        <Text style={[styles.text3]}>{lstIdCard[cardIdx].front.student.father}</Text>
                                    </View>
                                    <View style={[styles.divText6, { flexDirection: "row" }]}>
                                        <Text style={[styles.text4]}>Mother: </Text>
                                        <Text style={[styles.text3]}>{lstIdCard[cardIdx].front.student.mother}</Text>
                                    </View>
                                </View>
                                <View style={styles.horizontalBar}></View>
                                <View style={styles.divRow1}>
                                    <View style={styles.divCol2}>
                                        <View style={styles.divImg3}>
                                            <Image style={styles.image1} src={imageUrl} />
                                            {/* <Image style={styles.image1} src={NoImage}  /> */}
                                        </View>
                                        <View style={styles.divText2}>
                                            <Text style={[styles.text1]}>Principal</Text>
                                        </View>
                                    </View>
                                    <View style={styles.divImg4}>
                                        <Image style={styles.image1} src={require('common/images/codescan.png')} />
                                        {/* <QRCode value={qrCodeUrl} size={100} /> */}
                                    </View>
                                </View>
                            </View>
                        </View>
                    );
                    colIdx++;
                    sideIdx++;
                }
                if (groupPrintId === 20 || groupPrintId === 30) {
                    if (cardIdx >= iCardListLength) {
                        break;
                    }
                    if (colIdx >= totalCols) {
                        break;
                    }
                    rowContent.push(
                        <View style={[styles.backPage, styles.pageDiv, { backgroundColor: baseColor }]}>
                            <View style={styles.backPageDiv}>
                                <View style={[styles.divCol2]}>
                                    <View style={[styles.divRow1]}>
                                        <View style={[styles.divCol2]}>
                                            <View style={[styles.divText7]}>
                                                <View style={styles.divText9}>
                                                    <Text style={[styles.text4]}>Student Address :</Text>
                                                </View>
                                                {(getAddrLn1(lstIdCard[cardIdx].back.studentDtls.addressLine1) !== "") &&
                                                    <View style={styles.divText11}>
                                                        <Text style={[styles.text3]}>{getAddrLn1(lstIdCard[cardIdx].back.studentDtls.addressLine1)}</Text>
                                                    </View>
                                                }
                                                <View style={styles.divText11}>
                                                    <Text style={[styles.text3]}>{getAddrLn2(lstIdCard[cardIdx].back.studentDtls.addressLine2)}</Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={[styles.divCol2]}>
                                            <View style={[styles.divText8]}>
                                                <View style={styles.divText10}>
                                                    <Text style={[styles.text1]}>Blood Group</Text>
                                                </View>
                                                <View style={[styles.divText10]}>
                                                    <Text style={[styles.text2]}>{lstIdCard[cardIdx].back.studentDtls.bloodGroup}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={[styles.divCol2]}>
                                        <View style={styles.divText13}>
                                            <Text style={[styles.text3]}>{getAddressLine3(lstIdCard[cardIdx].back.studentDtls)}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.horizontalBar2}></View>
                                <View style={styles.divBlock1}>
                                    {selTpt &&
                                        <View style={[styles.divCol2]}>
                                            <View style={[styles.divCol2]}>
                                                <View style={styles.divText6}>
                                                    <Text style={[styles.text4]}>Route: {lstIdCard[cardIdx].back.transportDtls.routeNo}</Text>
                                                    {/* <Text style={[styles.text2]}>{lstIdCard[cardIdx].back.transportDtls.routeNo}</Text> */}
                                                </View>
                                                <View style={styles.divImg5}>
                                                    <Image style={styles.image2} src={require("common/images/schoolBus.jpg")} alt="bus" />
                                                </View>
                                            </View>
                                            <View style={[styles.divCol2]}>
                                                <View style={styles.divText14}>
                                                    <Text style={[styles.text3]}>Transport coordinator:</Text>
                                                </View>
                                                <View style={styles.divText14}>
                                                    <Text style={[styles.text3]}>{lstIdCard[cardIdx].back.transportDtls.coordinatorName} - {lstIdCard[cardIdx].back.transportDtls.coordinatorPhone}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    }
                                </View>
                                <View style={styles.horizontalBar2}></View>
                                <View style={styles.divBlock2}>
                                    {selGroup &&
                                        <View style={[styles.divCol2]}>
                                            <View style={styles.divText14}>
                                                <Text style={[styles.text3]}>Group1: {lstIdCard[cardIdx].back.groupDtls.group1}</Text>
                                            </View>
                                            <View style={styles.divText14}>
                                                <Text style={[styles.text3]}>Group2: {lstIdCard[cardIdx].back.groupDtls.group2}</Text>
                                            </View>
                                        </View>
                                    }
                                </View>
                                <View style={styles.horizontalBar2}></View>
                                {reissueFee &&
                                    <View style={styles.divBlock3}>
                                        <Text style={[styles.text3]}>Dear Parent , If this id card is lost , Rs {reissueFee || ''}/- will be charged
                                            towards a new card</Text>
                                    </View>
                                }
                                {reissueFee &&
                                    <View style={styles.horizontalBar2}></View>
                                }
                                <View style={styles.divBlock4}>
                                    <View style={[styles.divCol2]}>
                                        <View style={styles.divText14}>
                                            <Text style={[styles.text3]}>Phone: {lstIdCard[cardIdx].back.schoolContact.phone}</Text>
                                        </View>
                                        <View style={styles.divText14}>
                                            <Text style={[styles.text3]}>Whatsapp: {lstIdCard[cardIdx].back.schoolContact.whatsapp}</Text>
                                        </View>
                                        <View style={styles.divText14}>
                                            <Text style={[styles.text3]}>Email: {lstIdCard[cardIdx].back.schoolContact.email}</Text>
                                        </View>
                                        <View style={styles.divText14}>
                                            <Text style={[styles.text3]}>Website: {lstIdCard[cardIdx].back.schoolContact.website}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    );
                    colIdx++;
                    sideIdx++;
                }
                cardIdx = cardIdx + 1;
            }
            pageContent.push(
                <View key={rowIdx} style={[styles.pageAllRows]}>
                    <View style={[styles.pageSingleRow]}>
                        {rowContent}
                    </View>
                </View>
            );
        }
        pages.push(
            <Page key={pageIdx} size={selPageType} orientation={pageOrientaion} style={styles.pageStyle}>
                {pageContent}
            </Page>
        );
    }
    return <Document>{pages}</Document>;
}

export default IDCardContent;

