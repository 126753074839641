import React, { useState, useEffect } from 'react'
import '../css/EnquiryCtrl.css'
import InterviewSlotTable from './InterviewSlotTable.js'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import SliderType from './sliderType'
import lstModules from 'common/json/lstModules.json'
var tokenid = localStorage.getItem('tokenid')
var httpURL = lstModules['enquiry'].url + ':' + lstModules['enquiry'].port
var httpURLCommon = lstModules['main'].url + ':' + lstModules['main'].port
function ScheduleInterviewSlider (props) {
  const [loader, setLoader] = useState('none')
  const [lstInterviewSlot, setLstInterviewSlot] = useState([])

  const [selectedInterviewSlotId, setSelectedInterviewSlotId] = useState(0)
  useEffect(() => {
    if (props.flgSliderType === SliderType.SLIDER_SCHEDULE_INTERVIEW) {
      setLoader('block')
      getInterviewSlotListSvc(cbInterviewSlotListResponse)
    }
  }, [props.flgSliderType])

  function generateRandomNo () {
    return Math.floor(Math.random() * 999999)
  }
  function assignSlot (slotId, lstAdmissionId) {
    // // console.log('btn click:' + slotId)
    // // console.log('btn click:' + lstAdmissionId)

    if (lstAdmissionId.length === 0) {
      toast.warn('Please select atleast one admission')
      return
    }
    if (!slotId) {
      toast.warn('Please select the slot')
      return
    }
    setLoader('block')

    assignSlotSvc(slotId, lstAdmissionId, cbAssignSlotResponse)
  }
  function cbAssignSlotResponse (data) {
    setLoader('none')
    props.closeSlider()
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
    } else {
      toast.error(data.SvcMsg)
    }
  }
  function assignSlotSvc (
    interviewSlotId,
    lstAdmissionId,
    cbAssignSlotResponse
  ) {
    var formData = new FormData()
    formData.append('interviewSlotId', interviewSlotId)
    formData.append('lstAdmissionId', JSON.stringify(lstAdmissionId))
    axios({
      url: httpURL + '/sms/admission/interview/schedule/slot/assign',
      method: 'POST',
      data: formData,
      headers: { tokenid: tokenid },
      withCredentials: true
    })
      .then(response => {
        var data = response.data
        cbAssignSlotResponse(data)
      })
      .catch(error => {
        // // console.log('Error Occured')
      })
  }
  function getInterviewSlotListSvc (cbInterviewSlotListResponse) {
    axios({
      url: httpURL + '/sms/admission/interview/schedule/slot/list',
      method: 'POST',
      params: {},
      headers: { tokenid: tokenid },
      withCredentials: true
    })
      .then(Response => {
        var data = Response.data
        cbInterviewSlotListResponse(data)
      })
      .catch(error => {
        // // console.log('error')
      })
  }

  function cbInterviewSlotListResponse (data) {
    setLoader('none')
    if (data.SvcStatus === 'Success') {
      setLstInterviewSlot(data.lstInterviewSlot)
    } else {
      toast.error(data.SvcMsg)
    }
  }

  return (
    <div>
      <div
        className='col-sm-12'
        style={{ pointerEvents: loader === 'none' ? 'auto' : 'none' }}
      >
        <ToastContainer autoClose={3000} hideProgressBar />

        <div
          style={{
            position: 'fixed',
            zIndex: 10000,
            width: 100,
            height: 100,
            marginLeft: -50,
            marginTop: -50,
            left: '50%',
            top: '50%',
            display: loader
          }}
        >
          <img
            className='loderImg'
            src={require('common/images/loader.gif')}
            alt='Loader...'
          />
        </div>
        <InterviewSlotTable
          lstInterviewSlot={lstInterviewSlot}
          setInterviewSlotId={setSelectedInterviewSlotId}
          selectedInterviewSlotId={selectedInterviewSlotId}
        >
          Interview Slots
        </InterviewSlotTable>
      </div>
      <div className='col-sm-12'>
        <button
          type='button'
          className='btn btn-info pull-right'
          onClick={e =>
            assignSlot(selectedInterviewSlotId, props.selectedLstEnquiryId)
          }
        >
          Assign Slot
        </button>
      </div>
    </div>
  )
}

export default ScheduleInterviewSlider
