import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

export default function MonthWise(props) {
  const [label, setLabel] = useState(props.label);
  const [lstMonthTbl, setLstMonthTbl] = useState(props.lstMonthTbl);
  const [selRowId, setSelRowId] = useState(1)
  var mTotal = 0;
  var lTotal;
  useEffect(() => {
    setLabel(props.label);
    setLstMonthTbl(props.lstMonthTbl);

  }, []);
  function setSelRow(param) {
    setSelRowId(param)
  }
  return (
    <div id="monthTable">

    <div id='tableHead' className="col-sm-4 col-centered">
            <> Month Wise Planned Collection</>
          </div>
          <table className='col-sm-12'>
        <thead>
          <tr>
            {props.label.map((head) => (
              <th key={head}>{head}</th>
            ))}
          </tr>
        </thead>
        <tbody>



          {
            lstMonthTbl.map((obj1, idx1) => {

              return (
                
                  <tr id={obj1.monthId} style={{ background: idx1 === 0 ? '#d5dfe9' : '', fontWeight: idx1 === 0 ? 'bold' : '' }} key={idx1} className={obj1.monthId == selRowId ? "selected" : ""} onClick={(e) => setSelRow(obj1.monthId)}>

                    <td style={{ background: '#d5dfe9' }}>{obj1.month}</td>
                    {
                      obj1.lstLabelAmt.map((obj2, idx2) => {

                        return (
                            <td key={idx2}>{Number(obj2.amt).toLocaleString("en-in")}</td>

                        )
                      })
                    }
                    <td style={{ background: '#d5dfe9', fontWeight: 'bold' }}>{obj1.lstLabelAmt.map((obj2, idx2) => {
                      
                      mTotal = mTotal + obj2.amt
                      // return(
                      //   <div>{mTotal}</div>
                      //   )
                    })}{Number(mTotal).toLocaleString("en-in")}</td>
                    <td style={{display:'none'}}>{mTotal=0}</td>
                  </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  );
}
