import React from 'react';
import { Text, View } from '@react-pdf/renderer';

import { toast } from "react-toastify";
import { styles, styleStudentSection } from './reportCardStyles.js';



const objReport = {
    headerSection: {
        schoolName: "GOLDEN ERA PUBLIC SCHOOL",
        schoolAddress: "MURADNAGAR, HISALI, U.P. – 201206",
        sessionName: "2023 - 24",
        schoolPhoneNo: "7393939321",
        schoolAffiliation: "GHA0909088271"
    },
    headerName: {
        headerName: "Performance Profile"
    },
    studentSection: {
        studentId: 1,
        enrollmentNo: "A-232",
        studentName: "Priya Sharma",
        clsId: 2,
        sectionId: 2,
        clsSection: "L.K.G.-A",
        father: "Kirpal Sharma",
        mother: "Hema Sharma"
    },
    scoreSection: {
        schoScore: {
            lstTermHeader: [
                {
                    termId: 1
                    , term: "Term-1"
                    , weight: 50
                    , countExam: 3
                    , totalScore: 100
                }
                , {
                    termId: 2
                    , term: "Term-2"
                    , weight: 50
                    , countExam: 3
                    , totalScore: 100
                }]
            , lstExamHeaderScho: [
                { examId: 1, exam: "P.A.-1", maxScore: 20, passScore: 10, weight: 20 }
                , { examId: 2, exam: "S.A.-1", maxScore: 80, passScore: 40, weight: 80 }
                , { examId: 3, exam: "Total", maxScore: 100, passScore: 50, weight: 100 }
                , { examId: 4, exam: "P.A.-2", maxScore: 20, passScore: 10, weight: 20 }
                , { examId: 5, exam: "S.A.-2", maxScore: 80, passScore: 40, weight: 80 }
                , { examId: 6, exam: "Total", maxScore: 100, passScore: 50, weight: 100 }]
        }
        , lstSubjectScore: [
            { subjectId: 1, subject: "English", lstScore: [10, 70, 80, 10, 50, 140] }
            , { subjectId: 2, subject: "Hindi", lstScore: [10, 60, 70, 10, 50, 130] }
            , { subjectId: 3, subject: "Maths", lstScore: [10, 70, 80, 10, 50, 140] }
            , { subjectId: 4, subject: "E.V.S.", lstScore: [10, 70, 80, 10, 50, 140] }
            , { subjectId: 5, subject: "Sanskrit", lstScore: [10, 70, 80, 10, 50, 140] }
            , { subjectId: 6, subject: "Computer", lstScore: [10, 70, 80, 10, 50, 140] }
            , { subjectId: 7, subject: "G.K.", lstScore: [10, 70, 80, 10, 50, 140] }]
        , scholasticSummary: {
            lstTotal: [70, 480, 550, 70, 350, 970]
            , rank: 3
            , attendance: 50
        }
    },
    coschoScore: {
        lstExamHeaderCoscho: [
            { examId: 1, exam: "P.A.-1", maxScore: 20, passScore: 10, weight: 20 }
            , { examId: 2, exam: "S.A.-1", maxScore: 80, passScore: 40, weight: 80 }
            , { examId: 4, exam: "P.A.-2", maxScore: 20, passScore: 10, weight: 20 }
            , { examId: 5, exam: "S.A.-2", maxScore: 80, passScore: 40, weight: 80 }
        ]
        , lstSubjectGrade: [
            { subjectId: 8, subject: "Drawing", lstGrade: ["A1", "A2", "A3", "A4"] }
            , { subjectId: 9, subject: "Cleanliness", lstGrade: ["A1", "A4", "A3", "A2"] }
            , { subjectId: 10, subject: "Discipline", lstGrade: ["A1", "A3", "A2", "A4"] }
            , { subjectId: 11, subject: "Hand writing", lstGrade: ["A4", "A2", "A3", "A1"] }
            // ,{ subjectId: 12, subject: "Drawing", lstGrade: ["A1", "A2", "A3", "A4"] }
            // , { subjectId: 13, subject: "Cleanliness", lstGrade: ["A1", "A4", "A3", "A2"] }
            // , { subjectId: 14, subject: "Discipline", lstGrade: ["A1", "A3", "A2", "A4"] }
            // , { subjectId: 15, subject: "Hand writing", lstGrade: ["A4", "A2", "A3", "A1"] }
        ]
        , summary: {
            remarks: "Good score. Can perform better."
            , result: "Promoted"
        }
    },

};

const StudentSection = (props) => {
    const { studentSection } = props;
    return (
        <View style={styleStudentSection.student_dtls}>
            <View style={styleStudentSection.row}>
                <Text style={styleStudentSection.section_head_text}>{'Student Profile'} :-</Text>
            </View>
            <View style={styleStudentSection.row}>
                <View style={styleStudentSection.col}>
                    <Text style={styleStudentSection.col_label}>Admission No.</Text>
                    <Text style={styleStudentSection.col_colon}>:</Text>
                    <Text style={styleStudentSection.col_value}>{studentSection.enrollmentNo}</Text>
                </View>
            </View>
            <View style={styleStudentSection.row}>
                <View style={styleStudentSection.col}>
                    <Text style={styleStudentSection.col_label}>Name</Text>
                    <Text style={styleStudentSection.col_colon}>:</Text>
                    <Text style={styleStudentSection.col_value}>{studentSection.studentName}</Text>
                </View>
            </View>
            <View style={styleStudentSection.row}>
                <View style={styleStudentSection.col}>
                    <Text style={styleStudentSection.col_label}>Class</Text>
                    <Text style={styleStudentSection.col_colon}>:</Text>
                    <Text style={styleStudentSection.col_value}>{studentSection.clsSection}</Text>
                </View>
            </View>
            <View style={styleStudentSection.row}>
                <View style={styleStudentSection.col}>
                    <Text style={styleStudentSection.col_label}>Father's name</Text>
                    <Text style={styleStudentSection.col_colon}>:</Text>
                    <Text style={styleStudentSection.col_value}>{studentSection.father}</Text>
                </View>
            </View>
            <View style={styleStudentSection.row}>
                <View style={styleStudentSection.col}>
                    <Text style={styleStudentSection.col_label}>Mother's name</Text>
                    <Text style={styleStudentSection.col_colon}>:</Text>
                    <Text style={styleStudentSection.col_value}>{studentSection.mother}</Text>
                </View>
            </View>
        </View>
    );
};

export default StudentSection;