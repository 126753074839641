import React, { useState, useEffect } from "react";

const AppraisalTbl = (props) => {
    const [loader, setLoader] = useState("none");


    const [lstKPI, setLstKPI] = useState(props.lstKPI);

    useEffect(() => {
        setLstKPI(props.lstKPI)
    }, [props.lstKPI, props.kpiId])

    function changeRatio(e) {

    }
    const colgrp = <colgroup>
        <col width="40%"></col>
        <col width="30%"></col>
        <col width="25%"></col>

    </colgroup>
    return (
        < div style={{ pointerEvents: loader === "none" ? "auto" : "none" }
        }>
            <div className="col-sm-12" style={{ height: '60px', marginBottom: '1%', fontWeight: 'bold', fontFamily: 'Arial', fontSize: '15px', textTransform: 'uppercase', padding: '4%', background: '#ffdb00', borderRadius: '4px', border: '1px solid #000' }}>Key Performance Areas</div>

            <table className='tableLayout'>
                {colgrp}
                <thead>
                    <tr>
                        <th>KPI</th>
                        <th>Relative Weight</th>
                        <th>Ratio</th>
                    </tr>
                </thead>
            </table>
            <div className='tablebodyWrapper'>
                <table className='tableLayout'>
                    {colgrp}
                    <tbody>
                        {lstKPI.map((obj, idx) => {
                            return <tr key={idx} style={{ opacity: obj.disabled ? '0.4' : '1' }}
                                onClick={(e) => props.onClick(obj.kpiId)} className={obj.kpiId === props.kpiId ? "selected" : ""}>
                                <td className="no-display">{obj.kpiId}</td>
                                <td className="text-left">{obj.kpi}</td>
                                <td className="text-center">{obj.relativeWt}</td>
                                <td className="text-center"><input onChange={(e) => changeRatio(e.target.value)} className="form-control" type="text" defaultValue={obj.ratio} /></td>

                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            <div className="col-sm-12">
                <button type="button" title="Save" className="pull-right btn btn-info" >Save</button>
            </div>
        </div >
    )
}

export default AppraisalTbl