import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import { toast, ToastContainer } from "react-toastify";
import lstModules from "common/json/lstModules.json";
var httpURL = lstModules["enquiry"].url + ":" + lstModules["enquiry"].port;
var httpURLCommon = lstModules["main"].url + ":" + lstModules["main"].port;
var queryString = require("querystring");
var tokenid = localStorage.getItem("tokenid");

const RegistrationSiblingDtls = (props) => {
  const [siblingId, setSiblingId] = useState(0);
  const [school, setSchool] = useState("");
  const [clsId, setClsId] = useState(0);
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState(null);
  const [sibling, setSibling] = useState("");
  const [lstGender, setLstGender] = useState([
    { genderId: "M", gender: "Male" },
    { genderId: "F", gender: "Female" },
    { genderId: "O", gender: "Other" },
  ]);
  const [lstCls, setLstCls] = useState([]);
  const [regsId, setRegsId] = useState(0);
  
  useEffect(() => {
    // // console.log(props);
    setRegsId(props.regsId);
  }, [props.regsId]);
  
  useEffect(() => {
    setSiblingId(props.siblingId);
    getClsListSvc(cbClsListResponse);
    setSibling(props.sibling);
    setDob(props.dob);
    setSchool(props.school);
    setGender(props.gender);
    setClsId(props.clsId);
  }, [
    props.siblingId,
    props.enquiryId,
    props.sibling,
    props.dob,
    props.gender,
    props.clsId,
    props.school
  ]);
  function getClsListSvc(callBack) {
    axios({
      url: httpURLCommon + "/sms/mst/cls/list",
      method: "POST",
      params: {},
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        var ourData = Response.data;
        callBack(ourData);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function cbClsListResponse(data) {
    // // console.log(data);
    if (data.SvcStatus === "Success") {
      setLstCls(data.lstClass);
    } else {
      toast.error(data.SvcMsg);
    }
  }
  function handleDOBChange(param) {
    setDob(param);
  }
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join("-");
  }
  function hideSiblingSlider() {
    props.setPopup(false);
    document.querySelector("body").classList.remove("showPopup");
  }
  function maintainSibling() {
    var studentSibling = {
      siblingId: siblingId,
      name: sibling,
      dob: convert(dob),
      gender: gender,
      clsId: clsId,
      school: school,
    };

    axios
      .post(
        httpURL + "/sms/regs/sibling/save",
        queryString.stringify({
          regsId: props.regsId,
          siblingDtls: JSON.stringify(studentSibling),
          screenId: 0,
        }),
        {
          headers: { tokenid: tokenid },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          toast.success(response.data.SvcMsg);
          /* $('.cd-panel').removeClass('is-visible');
                $('.mainContainer').removeClass('addOpacity');
                $('.siblingPopup').hide();
                $('body').removeClass('showPopup'); */
          props.setPopup(false);
          document.querySelector("body").classList.remove("showPopup");
          props.setSiblingSliderKey(Math.round(Math.random() * 999999));
        } else {
          toast.error(response.data.SvcMsg);
        }
      })
      .catch((e) => {
        e =
          e.message === "Network Error"
            ? "No Internet or Server not reachable"
            : e.message;
        toast.error(e);
      });
  }

  return (
    <div
      className="siblingPopup"
      style={{
        display: props.popup ? "block" : "none",
        width: 400,
        height: 400,
        top: -200,
      }}
    >
      <header style={{ width: "90%" }}>
        <h3 id="classHeader">Add/Edit Sibling</h3>
        <a
          href="#0"
          id="hideSiblingSlider"
          className="cd-panel-close glyphicon glyphicon-remove"
          onClick={(e) => hideSiblingSlider()}
        >
          Close
        </a>
      </header>

      <div style={{ width: "90%" }}>
        <div className="cd-panel-content" style={{ padding: "20px 0% 20px" }}>
          <div
            id="SiblingSlider"
            className="col-sm-12"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "90%", paddingLeft: 20 }}>
              <form id="siblingForm" className="form-horizontal">
                <div className="col-sm-12 padding-remove">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="control-label col-sm-5">Name:</label>
                      <div className="col-sm-7 padding-remove">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          defaultValue={sibling}
                          onChange={(e) => setSibling(e.target.value)}
                          id="inp_sibling"
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="control-label col-sm-5" id="">
                        Age:
                      </label>
                      <div className="col-sm-7 padding-remove">
                        <DatePicker
                          id="inp_ptmDate"
                          className="form-control text-center"
                          placeholderText="DOB"
                          selected={dob}
                          dateFormat="dd-MM-yyyy"
                          onChange={handleDOBChange}
                          autoComplete="off"
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="control-label col-sm-5">Gender:</label>
                      <div className="col-sm-7 padding-remove">
                        <select
                          className="form-control"
                          id="inp_sgender"
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                        >
                          <option value={""}>Gender</option>
                          {lstGender.map((obj, idx) => {
                            return (
                              <option key={"sg_" + idx} value={obj.genderId}>
                                {obj.gender}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label col-sm-5">Class:</label>
                      <div className="col-sm-7 padding-remove">
                        <select
                          className="form-control"
                          id="inp_sclass"
                          value={clsId}
                          onChange={(e) => setClsId(e.target.value)}
                        >
                          <option value={0}>Class</option>
                          {lstCls.map((obj, idx) => {
                            return (
                              <option key={"scls_" + idx} value={obj.clsId}>
                                {obj.cls}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label col-sm-5">School:</label>
                      <div className="col-sm-7 padding-remove">
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={school}
                          onChange={(e) => setSchool(e.target.value)}
                          id="inp_sschool"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group" style={{ marginTop: 50 }}>
                  <div className="col-sm-11 text-right pull-right">
                    <button
                      type="button"
                      onClick={maintainSibling}
                      className="btn btn-info"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationSiblingDtls;
