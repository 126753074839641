import React, { useState, useEffect } from "react";

const DiaryTable = (props) => {
  const [lstSubject, setLstSubject] = useState([
    {
      class: "V",
      section: "A",
      subject: "S.St.",
      topic: "Chapter-2",
      coverage: "20%",
      diary: "Write the paragraph about the role of Women in 1857 revolt against British rule",
      duedate: "24-Jul",
      students: 35,
      completed: 20,
      verified: "20",
      action: "",
    },
    {
      class: "VII",
      section: "C",
      subject: "S.St.",
      topic: "",
      coverage: "",
      diary: "",
      duedate: "24-Jul",
      students: "",
      completed: "",
      verified: "",
    },
    {
      class: "V",
      section: "B",
      subject: "G.K.",
      topic: "",
      coverage: "",
      diary: "",
      duedate: "",
      students: "",
      completed: "",
      verified: "",
    },
  ]);
  const colgrp = (
    <colgroup>
      <col width="35%"></col>
      <col width="35%"></col>
      <col width="35%"></col>
      <col width="35%"></col>
      <col width="35%"></col>
      <col width="60%"></col>
      <col width="35%"></col>
      <col width="35%"></col>
      <col width="35%"></col>
      <col width="35%"></col>
      <col width="50%"></col>
    </colgroup>
  );
  return (
    <div className="col-sm-10 col-centered">
      <table className="tableLayout1">
        {colgrp}

        <thead>
          <tr>
            <th>Class</th>
            <th>Section</th>
            <th>Subject</th>
            <th>Topic</th>
            <th>Coverage</th>
            <th>Diary</th>
            <th>DueDate</th>
            <th>Students</th>
            <th>Completed</th>
            <th>Verified</th>
            <th>Action</th>
          </tr>
        </thead>
      </table>
      <div className="tablebodyWrapper">
        <table className="tableLayout">
          {colgrp}
          <tbody>
            {lstSubject.map((value, key) => {
              return (
                <tr key={key}>
                  <td className="text-centered" >{value.class}</td>
                  <td className="text-centered">{value.section}</td>
                  <td className="text-centered">{value.subject}</td>
                  <td className="text-centered">{value.topic}</td>
                  <td className="text-centered">{value.coverage}</td>
                  <td className="text-centered">{value.diary}</td>
                  <td className="text-centered">{value.duedate}</td>
                  <td className="text-centered">{value.students}</td>
                  <td className="text-centered">{value.completed}</td>
                  <td className="text-centered">{value.verified}</td>
                  <td className="text-centered">{value.action}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DiaryTable;
