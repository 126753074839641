import React from 'react';

const ApprovedTable = (props) => {
   const { lstSchool, setApproveSchoolForm, approveModules, setSchoolId } = props;

   
    return (
        <>
            <table className="tableLayout1" id='customTable'>
                <thead>
                    <tr>
                        <th width="100">Request No.</th>
                        <th width="200">School Name</th>
                        <th width="100">City</th>
                        <th width="200">eMail</th>
                        <th width="150">Requested On</th>
                        <th width="150">Approved On</th>
                        <th width="100">Status</th>
                    </tr>
                </thead>
            </table>
            <div className="tableDiv">
                <table id="approvedTable" className="tableLayout">
                    <tbody id="approvedTableBody">
                        {lstSchool.map((school) => (
                            <tr key={school.schoolId} id={school}
                                 onClick={() => { setApproveSchoolForm(school.schoolId); approveModules(school.schoolId); setSchoolId(school.schoolId) }}  >
                                <td width="100" className="no-display">{school.schoolId}</td>
                                <td width="100" className="text-left">{school.requestNo}</td>
                                <td width="200" className="text-left">{school.school}</td>
                                <td width="100" className="text-left">{school.city}</td>
                                <td width="200" className="text-left">{school.email}</td>
                                <td width="150" className="text-center">{school.requestedDt}</td>
                                <td width="150" className="text-center">{school.approvalDt}</td>
                                <td width="100" className="text-center">{school.status}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}
export default ApprovedTable;

