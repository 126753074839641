import axios from 'axios'
import lstModules from 'common/json/lstModules.json'

const httpURL = lstModules['cms'].url + ':' + lstModules['cms'].port
var tokenid = localStorage.getItem('tokenid')
var screenId = 8

function getBlogListSvc (callback) {
  var status = 'Success'
  var obj = { status: status }

  axios({
    url: httpURL + '/sms/cms/blog/list',
    method: 'POST',
    params: { screenId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      if (response.data.SvcStatus === 'Success') {
        obj.lstBlog = response.data.lstBlog
        obj.message = response.data.SvcMsg
        obj.status = 'Success'
        callback(obj)
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      callback(obj)
    })
}
function getBlogListDtlsSvc (pBlogId, callback) {
  var status = 'Success'
  var obj = { status: status }

  axios({
    url: httpURL + '/sms/cms/blog/list',
    method: 'POST',
    params: { screenId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      if (response.data.SvcStatus === 'Success') {
        obj.lstBlog = response.data.lstBlog
        obj.message = response.data.SvcMsg
        obj.status = 'Success'
        callback(pBlogId, obj)
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      callback(pBlogId, obj)
    })
}

function saveBlogSvc (formData, callBack) {
  axios({
    url: httpURL + '/sms/cms/blog/save',
    method: 'POST',
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      callBack(response.data)
    })
    .catch(e => {
      console.error('Service failed ' + e)
    })
}

function deleteBlogSvc (pBlogId, callBack) {
  // // console.log('pBlogId = ' + pBlogId)
  axios({
    url: httpURL + '/sms/cms/blog/delete',
    method: 'POST',
    params: { blogId: pBlogId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      callBack(response.data)
    })
    .catch(e => {
      console.error('Service failed ' + e)
    })
}

function saveBlogImgSvc (formData, pBlogId, callBack) {
  axios({
    url: httpURL + '/sms/cms/blog/img/upload',
    method: 'POST',
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      callBack(response.data, pBlogId)
    })
    .catch(e => {
      console.error('Service failed ' + e)
    })
}

function deleteBlogImgSvc (pBlogId, pos, callBack) {
  // // console.log('pBlogId = ' + pBlogId)
  axios({
    url: httpURL + '/sms/cms/blog/img/delete',
    method: 'POST',
    params: { blogId: pBlogId, pos: pos },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      callBack(response.data,pBlogId)
    })
    .catch(e => {
      console.error('Service failed ' + e)
    })
}

export {
  getBlogListSvc,
  getBlogListDtlsSvc,
  saveBlogSvc,
  deleteBlogSvc,
  saveBlogImgSvc,
  deleteBlogImgSvc
}
