import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['performance'].url + ':' + lstModules['performance'].port;
var tokenid = localStorage.getItem("tokenid");

function getEvaluationDtlsSvc(callback, allocationId) {
    var obj = { status: "success", message: "", examDtls: [] }
    axios({
        url: httpURL + '/sms/performance/evaluate/dtls',
        method: "POST",
        params: { 'allocationId': allocationId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // // console.log(response.data.SvcStatus);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.examDtls = response.data.examDtls;

        }
        callback(obj)
    }).catch((error) => {
        obj.status = 'Exception'
        obj.message = error
        callback(obj);
    });
}

function getStudentLstSvc(callback, allocationId, sectionId) {
    axios({
        url: httpURL + '/sms/performance/evaluate/student/list',
        method: "POST",
        params: { 'sectionId': sectionId, 'allocationId': allocationId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // // console.log(response.data.SvcStatus);
        callback(response.data);
    }).catch((error) => {
        callback(error);
    });
}

function saveScoreSvc(score, cb) {
    var obj = { status: "success", message: "" }

    axios({
        url: httpURL + '/sms/performance/evaluate/save_score',
        method: "POST",
        params: { score: JSON.stringify(score) },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        // // console.log(response.data.SvcStatus);
        cb(obj)
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = 'Service failed: ' + error;

        cb(obj)
    });
}

function saveRemarksSvc(remarks, cb) {
    var obj = { status: "success", message: "" }
    axios({
        url: httpURL + '/sms/performance/evaluate/save_remarks',
        method: "POST",
        params: { remarks: JSON.stringify(remarks) },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        // // console.log(response.data.SvcStatus);
        cb(obj)
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = 'Service failed: ' + error;

        cb(obj)
    });
}

function submitEvaluationSvc(callback, allocationId) {
    axios({
        url: httpURL + '/sms/performance/evaluate/submit_exam',
        method: "POST",
        params: { 'allocationId': allocationId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // // console.log(response.data.SvcStatus, response.data.SvcMsg);
        callback(response.data);
    }).catch((error) => {
        callback(error);
    });
}

export { getEvaluationDtlsSvc, getStudentLstSvc, saveScoreSvc, saveRemarksSvc, submitEvaluationSvc }