import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { getLstFeeStudentSvc } from "./feeStudentSvc";

const FeeStudentsTable = (props) => {
  const { getDiscountData, openDiscountSlider, classId, classSectionId, sendFeebook, sendFeeBookStudent, setLoader, setOpacity } = props;

  const [lstFeesStudent, setLstFeesStudent] = useState([]);
  const [lstFeesStudents, setLstFeesStudents] = useState([]);
  const [filterstudent, setFilterstudent] = useState("");
  const [filterFather, setFilterFather] = useState("");
  const [filterMother, setFilterMother] = useState("");
  const [filterCategory, setFiltercategory] = useState("");

  useEffect(() => {
    // // console.log("feeStudentTable called");
    getLstFeeStudent(classId, classSectionId);
    setFilterFather("");
    setFilterstudent("");
    setFilterMother("");
    setFiltercategory("");
  }, [classId, classSectionId]);

  const getLstFeeStudent = (clsId, clsSecId) => {
    // // console.log("getLstFeeStudent called from feeStudentTable.js, classId", clsId, ", classSectionId", clsSecId);
    setLoader("block");
    setOpacity(0.5);
    getLstFeeStudentSvc(clsId, clsSecId, lstFeeStudentResponse);
  }

  const lstFeeStudentResponse = (data) => {
    var obj = data;
    if (obj.status === 'Success') {
      setLstFeesStudent(obj.lstFeeStudent);
      setLstFeesStudents(obj.lstFeeStudent);
    }
    else {
      if (obj.message == 'You are not logged in') {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      }
      else if (obj.message == 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    }
    setLoader("none");
    setOpacity(1);
  }

  const filterFeeStudentTable = (fStudent, fFatherName, fMotherName, fCategory) => {
    // // console.log("filterFeeStudnetTable called");
    // // console.log("lstFeeStudents", lstFeesStudents);
    // // console.log("lstFeeStudent", lstFeesStudent);
    let filterFeeStudentList = [];
    filterFeeStudentList = lstFeesStudents.filter(feeStudent =>
      feeStudent.student.toString().toLowerCase().includes(fStudent.toLowerCase()));

    filterFeeStudentList = filterFeeStudentList.filter(feeStudent =>
      feeStudent.father.toString().toLowerCase().includes(fFatherName.toLowerCase()));

    filterFeeStudentList = filterFeeStudentList.filter(feeStudent =>
      feeStudent.mother.toString().toLowerCase().includes(fMotherName.toLowerCase()));

    filterFeeStudentList = filterFeeStudentList.filter(feeStudent =>
      feeStudent.studentCategory.toString().toLowerCase().includes(fCategory.toLowerCase()));

    setLstFeesStudent(filterFeeStudentList);
  }

  const handleFilterStudent = (e) => {
    setFilterstudent(e.target.value);
    filterFeeStudentTable(e.target.value, filterFather, filterMother, filterCategory);
  }

  const handleFilterFather = (e) => {
    setFilterFather(e.target.value);
    filterFeeStudentTable(filterstudent, e.target.value, filterMother, filterCategory);
  }

  const handleFilterMother = (e) => {
    setFilterMother(e.target.value);
    filterFeeStudentTable(filterstudent, filterFather, e.target.value, filterCategory);
  }

  const handleFilterCategory = (e) => {
    setFiltercategory(e.target.value);
    filterFeeStudentTable(filterstudent, filterFather, filterMother, e.target.value);
  }

  return (
    <div className="col-md-12">
      <table className="tableLayout1">
        <thead>
          <tr>
            <th style={{ width: 100 + 'px' }} colSpan='1'> Enrollment No. </th>
            <th style={{ width: 75 + 'px' }} colSpan='1'> Roll No. </th>
            <th style={{ width: 125 + 'px' }} colSpan='1'>
              <input type="text" className="form-control inp-search text-left"
                value={filterstudent}
                onChange={(e) => { handleFilterStudent(e) }}
                placeholder="Student"
              />
            </th>
            <th style={{ width: 125 + 'px' }} colSpan='1'>
              <input type="text" className="form-control inp-search text-left"
                value={filterFather}
                onChange={(e) => { handleFilterFather(e) }}
                placeholder="Father"
              />
            </th>
            <th style={{ width: 125 + 'px' }} colSpan='1'>
              <input type="text" className="form-control inp-search text-left"
                value={filterMother}
                onChange={(e) => { handleFilterMother(e) }}
                placeholder="Mother"
              />
            </th>
            <th style={{ width: 100 + 'px' }} colSpan='5'>
              <input type="text" className="form-control inp-search text-left"
                value={filterCategory}
                onChange={(e) => { handleFilterCategory(e) }}
                placeholder="Category"
              />
            </th>
            <th style={{ width: 100 + 'px' }} rowSpan='2' colSpan='1'> Fee Book Sent on </th>
            <th style={{ width: 150 + 'px' }} rowSpan='2' colSpan='1'> Action </th>
          </tr>
        </thead>
      </table>
      <div
        className="feeStudentsTableDiv"
        style={{
          height: "400px",
          overflowY: "scroll",
          border: "1px solid #ccc",
        }}
      >
        <table id="feeStudentsTable" className="tableLayout">

          <tbody id="feeStudentsBodyTable">
            {lstFeesStudent && lstFeesStudent.map((students, i) => (
              <tr key={students.studentId} id={students.studentId}>
                <td style={{ width: 100 + 'px' }} className="text-left">{students.enrollmentNo}</td>
                <td style={{ width: 75 + 'px' }} className="text-center">{students.rollNo}</td>
                <td style={{ width: 125 + 'px' }} className="text-left">{students.student}</td>
                <td style={{ width: 125 + 'px' }} className="text-left">{students.father}</td>
                <td style={{ width: 125 + 'px' }} className="text-left">{students.mother}</td>
                <td style={{ width: 100 + 'px' }} className="text-center">{students.studentCategory}</td>
                <td style={{ width: 100 + 'px' }} className="text-center">{students.feeBookSentDt}</td>
                <td style={{ width: 150 + 'px' }} className="text-center"
                >
                  <span className="glyphicon glyphicon-edit"
                    title="Setup Fee" onClick={() => {
                      getDiscountData(students.studentId, students.enrollmentNo, students.student)
                    }}
                  ></span>
                  <span className="glyphicon glyphicon-th-list"
                    title="Fee Details" onClick={() =>
                      openDiscountSlider(students.studentId, students.student)
                    }
                  ></span>
                  <span className="glyphicon glyphicon-send"
                    title="Generate Feebook"
                    onClick={() => sendFeebook(students.studentId)}
                  ></span>

                  <span className="glyphicon glyphicon-envelope" title="Send Feebook" onClick={() => sendFeeBookStudent(students.studentId)}
                  ></span>

                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>

  );
}

export default FeeStudentsTable;