import React, { useState, useEffect } from 'react'
import { getEventListSvc, saveEventSvc } from './eventSvc'

const EventTable = props => {
  const [loader, setLoader] = useState('none')

  const [eventId, setEventId] = useState(props.eventId)
  const [lstEvent, setLstEvent] = useState([])

  useEffect(() => {
    setLstEvent(props.lstEvent)
  }, [props])

  const colgrp = (
    <colgroup>
      <col width='10%'></col>
      <col width='30%'></col>
      <col width='20%'></col>
    </colgroup>
  )
  return (
    <div style={{ pointerEvents: loader === 'none' ? 'auto' : 'none' }}>
      <table className='tableLayout1'>
        {colgrp}
        <thead>
          <tr>
            <th>Date</th>
            <th>Event</th>
            <th>Action</th>
          </tr>
        </thead>
      </table>
      <div className='tablebodyWrapper'>
        <table className='tableLayout'>
          {colgrp}
          <tbody>
            {lstEvent.map((obj, idx) => {
              return (
                <tr
                  key={idx}
                  route-id={eventId}
                  stop-id={obj.eventId}
                  id={obj.eventId}
                  onClick={e => props.setEventId(obj.eventId)}
                  className={obj.eventId === props.eventId ? 'selected' : ''}
                >
                  <td className='no-display'>{obj.eventId}</td>
                  <td>{obj.eventDt}</td>
                  <td className='text-left'>{obj.event}</td>
                  <td className='text-center'>
                    <span
                      onClick={e => props.openEventSlider(obj.eventId, obj)}
                      className='glyphicon glyphicon-edit'
                    ></span>
                    <span
                      onClick={e => props.openImageSlider(obj.eventId, obj)}
                      className='fa fa-eye'
                    ></span>
                    <span
                      onClick={e => props.deleteEvent(obj.eventId)}
                      className='glyphicon glyphicon-trash'
                    ></span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default EventTable
