import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import Datepicker from "react-datepicker";
import "common/css/master.css";
import "../css/transport.css";
import {
  getFuelTypeList,
  getVehicleDtls,
  getVehicleTypeList,
  saveExpiryInfoSvc,
  saveMainInfoSvc,
  saveMakeInfoSvc,
  saveOdometerInfoSvc,
} from "./vehicleSvc";
const VehicleSlider = (props) => {
  const [loader, setLoader] = useState("none");
  const [vehicleId, setVehicleId] = useState(0);
  const [regsNo, setRegsNo] = useState("");
  const [vin, setVin] = useState("");
  const [mfgYear, setMfgYear] = useState(0);
  const [fuelTypeId, setFuelTypeId] = useState(0);
  const [vehicleType, setVehicleType] = useState("");
  const [vehicleTypeId, setVehicleTypeId] = useState(0);
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [variant, setVariant] = useState("");
  const [flgAc, setFlgAc] = useState("");
  const [rcExpiryDt, setRcExpiryDt] = useState("");
  const [pucExpiryDt, setPucExpiryDt] = useState("");
  const [odometer, setOdometer] = useState("");
  const [average, setAverage] = useState("");
  const [lstFuelType, setLstFuelType] = useState([]);
  const [lstYear, setLstYear] = useState([]);
  const [lstVehicleType, setLstVehicleType] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [isShow, setIsShow] = useState(false);
  var tempLst = [];
  useEffect(() => {
    if (props.flgSlider && props.vehicleId) {
      getVehicleTypeList(cbVehicleTypeList);
      getFuelTypeList(cbFuelTypeList);
      getVehicleDtls(props.vehicleId, cbVehicleDtls);
      setVehicleId(props.vehicleId);
    }
    for (var i = 2000; i <= new Date().getFullYear(); i++) {
      tempLst.push(i);
    }
    if (tempLst !== []) {
      setLstYear(tempLst);
    }
    if (props.flgSlider && props.vehicleId === 0) {
      setIsShow(false);
      getVehicleTypeList(cbVehicleTypeList);
      getFuelTypeList(cbFuelTypeList);
    } else {
      setIsShow(true);
    }
    // // console.log(props)
  }, [props]);
  function cbVehicleDtls(data) {
    if (data.SvcStatus === "Success") {
      setVehicle(data.vehicle);
      setRegsNo(data.vehicle.mainInfo.regsNo);
      setVin(data.vehicle.mainInfo.vin);
      setMfgYear(data.vehicle.mainInfo.mfgYear);
      setFuelTypeId(data.vehicle.mainInfo.fuelTypeId);
      setVehicleTypeId(data.vehicle.mainInfo.vehicleTypeId);
      setMake(data.vehicle.makeInfo.make);
      setModel(data.vehicle.makeInfo.model);
      setVariant(data.vehicle.makeInfo.variant);
      setFlgAc(data.vehicle.makeInfo.flgAc);
      setRcExpiryDt(data.vehicle.expiryInfo.rcExpiryDt);
      setPucExpiryDt(data.vehicle.expiryInfo.pucExpiryDt);
      setOdometer(data.vehicle.odometerInfo.odometer);
      setAverage(data.vehicle.odometerInfo.average);
    } else {
      toast.error(data.SvcMsg);
    }
  }
  function cbVehicleTypeList(data) {
    if (data.SvcStatus === "Success") {
      setLstVehicleType(data.lstVehicleType);
    } else {
      toast.error(data.SvcMsg);
    }
  }
  function cbFuelTypeList(data) {
    if (data.SvcStatus === "Success") {
      setLstFuelType(data.lstFuelType);
    } else {
      toast.error(data.SvcMsg);
    }
  }
  function getDateForCalendar(strDt) {
    if (!strDt) return null;
    var parts = strDt.split("-");
    var d = parts[0];
    var m = parts[1] - 1;
    var y = parts[2];

    return new Date(y, m, d);
  }
  function handleRegsNoChange(param) {
    setRegsNo(param);
  }
  function handleVinChange(param) {
    // // console.log("vin:" + param);
    setVin(param);
  }
  function handleMfrYearChange(param) {
    setMfgYear(param);
  }
  function handleFuelTypeChange(param) {
    setFuelTypeId(param);
  }
  function handleVehicleTypeChange(param) {
    setVehicleTypeId(param);
  }
  function handleMakeChange(param) {
    setMake(param);
  }
  function handleModelChange(param) {
    setModel(param);
  }
  function handleVariantChange(param) {
    setVariant(param);
  }
  function handleAcChange(param) {
    setFlgAc(param);
  }
  function handleRCExpiryDtChange(param) {
    var m = param.getMonth() + 1;
    if (m < 10) {
      m = "0" + m;
    }
    var d = param.getDate();
    if (d < 10) {
      d = "0" + d;
    }
    const dt = d + "-" + m + "-" + param.getFullYear();
    setRcExpiryDt(dt);
  }
  function handlePucExpiryDtChange(param) {
    var m = param.getMonth() + 1;
    if (m < 10) {
      m = "0" + m;
    }
    var d = param.getDate();
    if (d < 10) {
      d = "0" + d;
    }
    const dt = d + "-" + m + "-" + param.getFullYear();
    setPucExpiryDt(dt);
  }
  function handleOdometerChange(param) {
    setOdometer(param);
  }
  function handleAverageChange(param) {
    setAverage(param);
  }
  function saveMainInfo() {
    const pMainObj = {
      vehicleId: !vehicleId ? 0 : vehicleId,
      regsNo: regsNo,
      vin: vin,
      mfgYear: mfgYear,
      fuelTypeId: parseInt(fuelTypeId),
      vehicleTypeId: parseInt(vehicleTypeId),
    };
    setLoader("block");
    saveMainInfoSvc(pMainObj, cbSaveMainInfo);
  }
  function cbSaveMainInfo(data) {
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function saveMakeInfo() {
    const pMakeObj = {
      make: make,
      model: model,
      variant: variant,
      flgAc: flgAc,
    };
    setLoader("block");
    saveMakeInfoSvc(pMakeObj, vehicleId, cbSaveMakeInfo);
  }
  function cbSaveMakeInfo(data) {
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function saveExpiryInfo() {
    const pExpiryObj = {
      rcExpiryDt: rcExpiryDt,
      pucExpiryDt: pucExpiryDt,
    };
    setLoader("block");
    saveExpiryInfoSvc(pExpiryObj, vehicleId, cbSaveExpiryInfo);
  }
  function cbSaveExpiryInfo(data) {
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function saveOdometerInfo() {
    const pOdoObj = {
      odometer: parseInt(odometer),
      average: parseInt(average),
    };
    setLoader("block");
    saveOdometerInfoSvc(pOdoObj, vehicleId, cbSaveOdometerInfo);
  }
  function cbSaveOdometerInfo(data) {
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  return (
    <>
      <form
        className="form form-horizontal"
        id={"mainInfo"}
        style={{
          pointerEvents: loader === "none" ? "auto" : "none",
          height: "auto",
          width: "90%",
          margin: "10px auto",
          background: "#fff",
          border: "1px solid #ccc",
          borderRadius: 10,
        }}
      >
        <button
          type="button"
          className="btn btn-info text-center btnCloseDiv"
          onClick={props.closeSlider}
        >
          <span
            id="close"
            title="Close Slider"
            className="glyphicon glyphicon-remove"
          ></span>
        </button>
        <div className="row" style={{ width: "100%" }}>
          <div className="form-group col-md-6">
            <label className="control-label col-sm-5">Registration No.</label>
            <div className="col-sm-6 padding-remove">
              <input
                type="text"
                defaultValue={regsNo}
                className="form-control"
                onChange={(e) => handleRegsNoChange(e.target.value)}
              ></input>
            </div>
          </div>
          <div className="form-group col-md-6">
            <label className="control-label col-sm-5">VIN</label>
            <div className="col-sm-6 padding-remove">
              <input
                type="text"
                defaultValue={vin}
                className="form-control"
                onChange={(e) => handleVinChange(e.target.value)}
              ></input>
            </div>
          </div>
        </div>
        <div className="row" style={{ width: "100%" }}>
          <div className="form-group col-md-6">
            <label className="control-label col-sm-5">Mfr Year</label>
            <div className="col-sm-6 padding-remove">
              <select
                className="form-control"
                onChange={(e) => handleMfrYearChange(e.target.value)}
                value={mfgYear}
              >
                <option value={0}>Mfr Year</option>
                {lstYear.map((sec, idx) => {
                  return (
                    <option key={idx} value={sec}>
                      {sec}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="form-group col-md-6">
            <label className="control-label col-sm-5">Fuel Type</label>
            <div className="col-sm-6 padding-remove">
              <select
                className="form-control"
                value={fuelTypeId}
                onChange={(e) => handleFuelTypeChange(e.target.value)}
              >
                <option value={0}>Fuel Type</option>
                {lstFuelType.map((obj, idx) => {
                  return (
                    <option key={idx} value={obj.fuelTypeId}>
                      {obj.fuelType}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="row" style={{ width: "100%" }}>
          <div className="form-group col-md-6">
            <label className="control-label col-sm-5">
              Vehicle Type<span className="red-color">*</span>
            </label>
            <div className="col-sm-6 padding-remove">
              <select
                className="form-control"
                value={vehicleTypeId}
                onChange={(e) => handleVehicleTypeChange(e.target.value)}
                required
              >
                <option value={0}>Vehicle Type</option>
                {lstVehicleType.map((obj, idx) => {
                  return (
                    <option key={idx} value={obj.vehicleTypeId}>
                      {obj.vehicleType}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="row" style={{ width: "100%", paddingBottom: "1px" }}>
          <div className="col-md-offset-10 col-md-1 padding-remove">
            <button
              type="button"
              className="btn btn-info pull-right"
              onClick={(e) => saveMainInfo()}
            >
              Save
            </button>
          </div>
        </div>
      </form>
      {isShow && (
        <form
          className="form form-horizontal"
          style={{
            pointerEvents: loader === "none" ? "auto" : "none",
            height: "auto",
            width: "90%",
            margin: "10px auto",
            background: "#fff",
            border: "1px solid #ccc",
            borderRadius: 10,
          }}
        >
          <div className="row" style={{ width: "100%" }}>
            <div className="form-group col-md-6">
              <label className="control-label col-sm-5">Make</label>
              <div className="col-sm-6 padding-remove">
                <input
                  type="text"
                  defaultValue={make}
                  className="form-control"
                  onChange={(e) => handleMakeChange(e.target.value)}
                ></input>
              </div>
            </div>
            <div className="form-group col-md-6">
              <label className="control-label col-sm-5">Model</label>
              <div className="col-sm-6 padding-remove">
                <input
                  type="text"
                  defaultValue={model}
                  className="form-control"
                  onChange={(e) => handleModelChange(e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <div className="row" style={{ width: "100%" }}>
            <div className="form-group col-md-6">
              <label className="control-label col-sm-5">Variant</label>
              <div className="col-sm-6 padding-remove">
                <input
                  type="text"
                  defaultValue={variant}
                  className="form-control"
                  onChange={(e) => handleVariantChange(e.target.value)}
                ></input>
              </div>
            </div>
            <div className="form-group col-md-6">
              <label className="control-label col-sm-5">With AC</label>
              <div className="col-sm-6 padding-remove">
                <input
                  type="checkbox"
                  className="form-control"
                  checked={!flgAc ? 0 : 1}
                  onChange={(e) => handleAcChange(e.target.checked)}
                />
              </div>
            </div>
          </div>
          <div className="row" style={{ width: "100%", paddingBottom: "1px" }}>
            <div className="col-md-offset-10 col-md-1 padding-remove">
              <button
                type="button"
                className="btn btn-info pull-right"
                onClick={(e) => saveMakeInfo()}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      )}
      {isShow && (
        <form
          className="form form-horizontal"
          style={{
            pointerEvents: loader === "none" ? "auto" : "none",
            height: "auto",
            width: "90%",
            margin: "10px auto",
            background: "#fff",
            border: "1px solid #ccc",
            borderRadius: 10,
          }}
        >
          <div className="row" style={{ width: "100%" }}>
            <div className="form-group col-md-6">
              <label className="control-label col-sm-5">RC Expiry</label>
              <div className="col-sm-6 padding-remove">
                <Datepicker
                  type="date"
                  className="form-control"
                  selected={getDateForCalendar(rcExpiryDt)}
                  onChange={handleRCExpiryDtChange}
                  dateFormat="dd-MM-yyyy"
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                ></Datepicker>
              </div>
            </div>
            <div className="form-group col-md-6">
              <label className="control-label col-sm-5">PUC Expiry</label>
              <div className="col-sm-6 padding-remove">
                <Datepicker
                  type="date"
                  className="form-control"
                  selected={getDateForCalendar(pucExpiryDt)}
                  onChange={handlePucExpiryDtChange}
                  dateFormat="dd-MM-yyyy"
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                ></Datepicker>
              </div>
            </div>
          </div>
          <div className="row" style={{ width: "100%", paddingBottom: "1px" }}>
            <div className="col-md-offset-10 col-md-1 padding-remove">
              <button
                type="button"
                className="btn btn-info pull-right"
                onClick={(e) => saveExpiryInfo()}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      )}
      {isShow && (
        <form
          className="form form-horizontal"
          style={{
            pointerEvents: loader === "none" ? "auto" : "none",
            height: "auto",
            width: "90%",
            margin: "10px auto",
            background: "#fff",
            border: "1px solid #ccc",
            borderRadius: 10,
          }}
        >
          <div className="row" style={{ width: "100%" }}>
            <div className="form-group col-md-6">
              <label className="control-label col-sm-5">Odometer</label>
              <div className="col-sm-6 padding-remove">
                <input
                  type="number"
                  defaultValue={odometer}
                  className="form-control"
                  onChange={(e) => handleOdometerChange(e.target.value)}
                ></input>
              </div>
              <label className="control-label col-sm-1">(kms)</label>
            </div>
            <div className="form-group col-md-6">
              <label className="control-label col-sm-5">Average</label>
              <div className="col-sm-6 padding-remove">
                <input
                  type="number"
                  defaultValue={average}
                  className="form-control"
                  onChange={(e) => handleAverageChange(e.target.value)}
                ></input>
              </div>
              <label className="control-label col-sm-1">(kms/ltr)</label>
            </div>
          </div>
          <div className="row" style={{ width: "100%", paddingBottom: "1px" }}>
            <div className="col-md-offset-10 col-md-1 padding-remove">
              <button
                type="button"
                className="btn btn-info pull-right"
                onClick={(e) => saveOdometerInfo()}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};
export default VehicleSlider;
