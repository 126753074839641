import React, { useEffect, useState } from "react";
import lstModules from 'common/json/lstModules.json';

const AcademicScheduleTable = (props) => {
    const { lstAcademicSchedule, setAcademicScheduleForm, deleteAcademicSchedule, editSchedulePeriod } = props;

    const [academicScheduleId, setAcademicScheduleId] = useState(0);

    return (
        <>
            <table className="tableLayout1" id='customTable'>
                <thead>
                    <tr>
                        <th width="100" className="text-left">Session Type</th>
                        <th width="100" className="text-left">Label</th>
                        <th width="100">Session From</th>
                        <th width="100">Session To</th>
                        <th width="100">Start Time</th>
                        <th width="100">End Time</th>
                        <th width="50">Periods</th>
                        <th width="100">Action</th>
                    </tr>
                </thead>
            </table>
            <div className="tableDiv1">
                <table id="mainTable" className="tableLayout">
                    <tbody id="tableBody">
                        {lstAcademicSchedule && lstAcademicSchedule.map((academicSchedule) => (
                            <tr className={academicScheduleId === academicSchedule.academicScheduleId ? "selected" : ""}
                                key={academicSchedule.academicScheduleId}
                                id={academicSchedule.academicScheduleId}
                                onClick={() => setAcademicScheduleId(academicSchedule.academicScheduleId)} >
                                <td width="100" className="no-display">{academicSchedule.academicScheduleId}</td>
                                <td width="100" className="text-left">{academicSchedule.sessionType}</td>
                                <td width="100" className="text-left">{academicSchedule.label}</td>
                                <td width="100" className="text-center">{academicSchedule.fromDt}</td>
                                <td width="100" className="text-center">{academicSchedule.toDt}</td>
                                <td width="100" className="text-center">{academicSchedule.sessionType == "Vacation" ? "-" : academicSchedule.startTm}</td>
                                <td width="100" className="text-center">{academicSchedule.sessionType == "Vacation" ? "-" : academicSchedule.endTm}</td>
                                <td width="50" className="text-center">{academicSchedule.periods}</td>
                                <td width="100" className="text-center">
                                    <span onClick={(e) => {setAcademicScheduleForm(academicSchedule.academicScheduleId); e.stopPropagation(); setAcademicScheduleId(academicSchedule.academicScheduleId);}}
                                        className="glyphicon glyphicon-edit">
                                    </span>
                                    <span
                                        onClick={(e) => {deleteAcademicSchedule(academicSchedule.academicScheduleId); e.stopPropagation(); setAcademicScheduleId(academicSchedule.academicScheduleId);}}
                                        className="glyphicon glyphicon-trash" >
                                    </span>
                                    {academicSchedule.sessionType === "Regular" ?
                                        <span
                                            onClick={(e) => {editSchedulePeriod(academicSchedule.academicScheduleId); e.stopPropagation(); setAcademicScheduleId(academicSchedule.academicScheduleId);}}
                                            className="glyphicon glyphicon-time" >
                                        </span>
                                        : <span className="glyphicon" style={{ marginRight: 15, marginLeft: 15 }}>
                                        </span>
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div >
        </>
    );
}

export default AcademicScheduleTable;
