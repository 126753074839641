const SliderType = Object.freeze({
SLIDER_EDT_DTLS: '10'
, SLIDER_FEES: '20'
, SLIDER_ADM_TEST: '30'
, SLIDER_SCH_INT: '40'
, SLIDER_INT_RSLT:'50'
, SLIDER_CLS_SEC: '60'
,SLIDER_FEE_PYMT: '70'
});

export default SliderType