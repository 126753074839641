import StaffTable from "./staffTable";
import StaffSlider from "./staffSlider";
import Header from "common/js/header/header.js";
import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios'
import lstModules from 'common/json/lstModules.json';
var tokenid = localStorage.getItem('tokenid');
function StaffType() {
    const [randNo, setRandNo] = useState(0)
    const [selObj, setSelObj] = useState({})
    const [selIdx, setSelIdx] = useState()
    function generateRandomNo() {
        var lRand = Math.floor(Math.random() * 999999);
        setRandNo(lRand)
    }
    function getSelData(param) {
        setSelObj(param)
        setSelIdx(param.typeId)
    }
    function addNew() {
        setSelObj({})
        setSelIdx(null)
        generateRandomNo()

    }
    return (<div>
        <Header></Header>
        <ToastContainer autoClose={3000} hideProgressBar />
        <div className="TestTblWrapper">
            <div className="col-sm-8 col-centered">
                <div className="col-sm-4">
                    <StaffTable
                        key={randNo}
                        getSelData={getSelData}

                    ></StaffTable>
                </div>

                <div className="col-xs-1" onClick={(e) => addNew()}><span id="addReadyId" title="Add" className="addIcon glyphicon glyphicon-plus-sign"></span>
                </div>
                <div className="col-sm-6">
                    <StaffSlider
                        key={selIdx}
                        staffObj={selObj}
                        generateRandomNo={generateRandomNo}
                    ></StaffSlider>
                </div>
            </div>
        </div>
    </div >)
}
export default StaffType;