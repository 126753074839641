import React, { useEffect, useState } from 'react';
import { Text, View, Image } from '@react-pdf/renderer';

import { pageStyle, styleHeaderLogo, fontStyles } from './styleAdmitCard';

const AdmitCardHeader = (props) => {
    const { schoolLogo, schoolName, schoolAddress, academicYear, examName } = props;

    useEffect(() => {
        // // console.log("AdmitCardHeader component called");
        // // console.log("schoolLogo", schoolLogo);
        // // console.log("schoolName", schoolName);
        // // console.log("schoolAddress", schoolAddress);
        // // console.log("academicYear", academicYear);
        // // console.log("examName", examName);
    }, [schoolLogo, schoolName]);

    return (
        <View style={[styleHeaderLogo.header_logo_section,]}>
            <View style={[styleHeaderLogo.header_left]}>
                <Image style={styleHeaderLogo.image1} src={schoolLogo} />
            </View>
            <View style={[styleHeaderLogo.header_center]}>
                <Text style={fontStyles.textSchool}>
                    {schoolName}<Text style={{ textTransform: "capitalize" }}></Text>
                </Text>
                <Text style={fontStyles.textCenterSmallInter}>
                    {schoolAddress}
                </Text>
            </View>
            <View style={[styleHeaderLogo.header_right,]}>
                <View style={[styleHeaderLogo.exam_name_box]}>
                    <Text style={fontStyles.fontNaunamGauthicBold}>
                        {academicYear}
                    </Text>
                    <Text style={fontStyles.textCenterNormalInter}>
                        {examName}
                    </Text>
                </View>
            </View>
        </View>
    )
}

export default AdmitCardHeader;