import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
// var httpURL = lstModules['event'].url + ':' + lstModules['event'].port;
var httpURL = lstModules['main'].url + ":" + lstModules['main'].port;
var tokenid = localStorage.getItem('tokenid');


function getTestQusListSvc(pTestId, callBack) {
    // // console.log("QusList called");
    axios(
        {
            url: httpURL + "/sms/wt/test/invigilator/list_qus",
            method: 'POST',
            params: { testId: pTestId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)

        }
    ).catch((error) => {
        // // console.log("QusList Catch error")
        callBack(error)
    });
}
function getQusInfoSvc(pTestId, callBack) {
    // // console.log("getQusInfoSvc called");
    axios(
        {
            url: httpURL + "/sms/wt/test/invigilator/qus_progress",
            method: 'POST',
            params: { testId: pTestId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)

        }
    ).catch((error) => {
        // // console.log("getQusInfoSvc Catch error")
        callBack(error)
    });
}


function getTestLeaderboardSvc(pTestId, callBack) {
    // // console.log("getTestLeaderboardSvc called");
  
    axios(
        {
            url: httpURL + "/sms/wt/test/invigilator/leader_board",
            method: 'POST',
            params: {testId:pTestId},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)

        }
    ).catch((error) => {
        // // console.log("getTestLeaderboardSvc Catch error")
        callBack(error)
    });
}
function nextQusSvc(pTestId, callBack){
    // // console.log("nextQus called");
    axios(
        {
            url: httpURL + "/sms/wt/test/invigilator/next_qus",
            method: 'POST',
            params: { testId: pTestId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
        }
    ).catch((error) => {
        // // console.log("startTest Catch error")
        callBack(error)
    });

}


function showLeaderBoardSvc(pTestId, callBack){
    // // console.log("showLeaderBoardSvc called");
    axios(
        {
            url: httpURL + "/sms/wt/test/invigilator/show_leaderboard",
            method: 'POST',
            params: { testId: pTestId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
        }
    ).catch((error) => {
        // // console.log("showLeaderBoardSvc Catch error")
        callBack(error)
    });

}


export { getTestQusListSvc, getQusInfoSvc, getTestLeaderboardSvc, nextQusSvc, showLeaderBoardSvc }