import React, { useState } from "react";
import { toast } from 'react-toastify';
import { deleteTestScheduleSvc } from "./WTestScheduleSvc";


const DeleteTestSchedule = (props) => {

    const {testScheduleId, closePopup, updateKey} = props;

    const [loader, setLoader] = useState("none");


    const deleteSchedule = () => {
        // // console.log("deleteSchedule confirm called");
        setLoader("block");
        deleteTestScheduleSvc(testScheduleId, cbDeleteTestScheduleResponse);
    }
   

    function cbDeleteTestScheduleResponse(data) {
        // // console.log("cbDeleteTestScheduleResponse, data", data);
        const obj = data;
        if (obj.status === 'Success') {
            toast.success(obj.message);
            updateKey();
            closePopup();
        } else if (obj.status === 'Failure') {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = '/'
                }, 2000)
                return false
            } else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = '/'
                }, 2000)
                return false
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error('Service failed ' + obj)
        }
        setLoader("none");
    }

    return (
        <div style={{ display: "flex", justifyContent: "center", position: "absolute", padding: 0, top: "35%", left: "40%", backgroundColor: "#F8F8F8", width: 350, height: 150, zIndex: 1001, border: "1px solid #ccc" }}>
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }} >
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div >
            <div className="col-sm-12 padding-remove" >
                <div className="col-sm-12 padding-remove">
                    <div className="col-sm-8" style={{ padding: "10px 2px", fontSize: "large" }}>
                        Delete Schedule
                    </div>
                    <div className="col-sm-4 padding-remove" >
                        <button type="button"
                            title="Close Popup"
                            onClick={() => props.closePopup()}
                            className='btn btn-info text-center pull-right'>X</button>
                    </div>
                </div>
                <form >
                    <div className="form-group " >
                        <label className="control-label col-sm-12 " >Are you sure you want to delete test schedule!</label>
                        
                    </div>
                    

                    <div className="col-sm-12 padding-remove" >
                        <div className=" col-md-12 form-group text-center multiButtonWrap1" >
                            <div style={{ marginTop: 15, width: 150 }}>
                                <button type="button" style={{ width: "90%" }} onClick={() => closePopup()} className="btn btn-success">Cancel</button>
                            </div>
                            <div style={{ marginTop: 15, width: 150 }}>
                                <button type="button" style={{ width: "90%" }} onClick={() => deleteSchedule()} className="btn btn-success">Delete Schedule</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default DeleteTestSchedule;