import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { getCompletionDtlsSvc } from "./pDashboardSvc";
{/* <script src='https://kit.fontawesome.com/a076d05399.js' crossorigin='anonymous'></script> */}

const CompletionDtlsSlider = (props) => {
  const [loader, setLoader] = useState("none");
  const [lstStudentDtls, setLstStudentDtls] = useState(props.lstStudentDtls);

  useEffect(() => {
    getCompletionDtls();
  }, [props]);
  function getCompletionDtls() {
    getCompletionDtlsSvc(cbCompletionDtlsResponse);
  }
  function cbCompletionDtlsResponse(data) {
    if (data.SvcStatus === "Success") {
      // // console.log(data);
      setLstStudentDtls(data.lstCompletionDtls);
    } else {
      toast.error(data.SvcMsg);
    }
  }
  const colgrp = (
    <colgroup>
      <col width="6.667%"></col>
      <col width="6.667%"></col>
      <col width="6.667%"></col>
      <col width="6.667%"></col>
      <col width="6.667%"></col>
      <col width="6.667%"></col>
      <col width="6.667%"></col>
      <col width="6.667%"></col>
      <col width="6.667%"></col>
      <col width="6.667%"></col>
      <col width="6.667%"></col>
      <col width="6.667%"></col>
    </colgroup>
  );

  return (
    <div
      style={{
        width: "100%",
        margin: "0 auto",
        background: "#fff",
        border: "1px solid #ccc",
        borderRadius: 10,
        pointerEvents: loader === "none" ? "auto" : "none",
      }}
    >
      <div
        className="col-sm-11 col-xs-11 col-centered"
        style={{
          border: "1px solid rgb(170, 170, 170)",
          borderRadius: "10px 10px 5px 5px",
          fontWeight: "bold",
        }}
      >
        <h4>Attendance Completion</h4>
      </div>
      <div className="col-xs-1 col-sm-1">
        <i class="fas fa-medal" style={{fontSize:"48px", color:"red"}}></i>
        {/* <i class="fas fa-medal" style="font-size:48px;color:red"></i> */}
      </div>
      {lstStudentDtls && lstStudentDtls.length > 0 && (
        <div className="col-sm-12" style={{ height: "auto", marginTop: "1em" }}>
          <div className="col-sm-12">
            <table className="tableLayout1">
              {colgrp}
              <thead>
                <tr>
                  {lstStudentDtls.map((obj, idx) => {
                    return <th key={idx}>{obj.cls}</th>;
                  })}
                </tr>
              </thead>
            </table>
            <div className="tablebodyWrapper">
              <div className="tableLayout" style={{ display: "table" }}>
                <div className="col-sm-12 padding-remove">
                  {lstStudentDtls.map((obj, idx) => {
                    return (
                      <div
                        key={idx}
                        style={{
                          float: "left",
                          width: "6.667%",
                          padding: "5px",
                        }}
                      >
                        {/*{obj.lstSection.length === 0 &&
                                         <div className="text-center" style={{ width: 50, height: 50, margin: '0 auto', marginBottom: 5 }}>
                                                <div className="col-sm-12 col-centered subsec-circle1"><span className="glyphicon glyphicon-remove"></span></div>
                                            </div>} */}
                        {obj.lstSection.map((secObj, subIdx) => {
                          return (
                            <div
                              key={subIdx}
                              className="text-center"
                              style={{
                                width: 50,
                                height: 50,
                                margin: "0 auto",
                                marginBottom: 5,
                              }}
                            >
                              <div
                                className={
                                  secObj.flgAttendance
                                    ? "col-sm-12 col-centered subsec-circle1 bg-green"
                                    : "col-sm-12 col-centered subsec-circle1"
                                }
                                style={{
                                  border: secObj.flgAttendance
                                    ? "1px solid #8BC34A"
                                    : "1px solid #b4b4b4",
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: secObj.flgAttendance
                                      ? "bold"
                                      : "",
                                    color: secObj.flgAttendance ? "#fff" : "",
                                  }}
                                >
                                  {secObj.section}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompletionDtlsSlider;
