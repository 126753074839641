import React, { useState, useEffect } from 'react'
import 'jquery-ui'
import '../css/wings.css'
import 'common/css/master.css'
import 'react-datepicker/dist/react-datepicker.css'

const WingsMain = props => {
  const [lstWing, setLstWings] = useState(props.lstWing)

  useEffect(() => {
    setLstWings(props.lstWing)
  }, [props])

  return (
    <div>
      <table className='tableLayout1 wings' style={{ width: 700 }}>
        <thead>
          <tr>
            <th style={{ display: 'none' }}> wings Id </th>
            <th style={{ width: 60 }} className='text-left'>
              {' '}
              Wings{' '}
            </th>
            <th style={{ width: 40 }} className='text-left'>
              {' '}
              From Class{' '}
            </th>
            <th style={{ width: 40 }} className='text-left'>
              {' '}
              To Class{' '}
            </th>
            <th style={{ width: 40 }} className='text-left'>
              {' '}
              Days{' '}
            </th>
            <th style={{ width: 30 }}> Action </th>
          </tr>
        </thead>
      </table>
      <div
        className='wingsTableDiv'
        style={{
          height: '200px',
          overflowY: 'scroll',
          border: '1px solid #ccc'
        }}
      >
        <table id='wingsTable' className='tableLayout'>
          <colgroup>
            <col width='60' />
            <col width='40' />
            <col width='40' />
            <col width='40' />
            <col width='30' />
          </colgroup>
          <tbody id='wingsBodyTable'>
            {lstWing.map(obj => {
              var isSelected = obj.wingId === props.selWingId
              return (
                <tr
                  key={obj.wingId}
                  className={isSelected ? 'selected' : ''}
                  id={obj.wingId}
                >
                  <td className='text-left'>{obj.wing}</td>
                  <td className='text-left'>{obj.fromClass}</td>
                  <td className='text-left'>{obj.toClass}</td>
                  <td className='text-left'>{obj.day}</td>
                  <td className='text-center'>
                    <span
                      className='glyphicon glyphicon-edit'
                      data={JSON.stringify(obj)}
                      onClick={props.rowClick}
                    ></span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default WingsMain
