import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import "../../../common/css/master.css";
import "../css/header.css";
import PasswordChecklist from 'react-password-checklist';
import { changePasswordSvc } from './saHeaderSvc';

const Changepassword = (props) => {
    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [password, setPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [passwordAgain, setPasswordAgain] = useState('');
    const [reset, setReset] = useState('')
    const [showPass, setShowPass] = useState(false)
    const [showOldPass, setShowOldPass] = useState(false)
    const [showAgainPass, setShowAgainPass] = useState(false)
    const [hide, setHide] = useState(false)
    const [hide2, setHide2] = useState(false)
    const [hide3, setHide3] = useState(false)

    useEffect(() => {
        // // console.log("Change password component opened ");
    })

    function showPassword() {
        if (showPass === true) {
            setShowPass(false)
            setHide2(false)
        } else {
            setShowPass(true)
            setHide2(true)
        }
    }
    function showAgainPassword() {
        if (showAgainPass === true) {
            setShowAgainPass(false)
            setHide3(false)
        } else {
            setShowAgainPass(true)
            setHide3(true)
        }
    }

    function showOldPassword() {
        if (showOldPass === true) {
            setShowOldPass(false)
            setHide(false)
        } else {
            setShowOldPass(true)
            setHide(true)
        }
    }

    function handleOldPass(e) {
        setOldPassword(e.target.value)
    }
    function handlePass(e) {
        setPassword(e.target.value)
    }
    function handlePassAgain(e) {
        setPasswordAgain(e.target.value)
    }

    const changePassword = () => {
        // // console.log('change password triggered');
        if (!oldPassword.trim()) {
            toast.error('Please fill your current password');
            return;
        }
        if (!password.trim()) {
            toast.error('Please fill a new password');
            return;
        }
        if (password !== passwordAgain) {
            toast.error('Password and confirm password are not same');
            return;
        }
        setLoader("block");
        setOpacity(0.5);
        changePasswordSvc(oldPassword, password, changePasswordResponse);
    }

    const changePasswordResponse = (data) => {
        var obj = data;
        if (obj.status === "Success") {
            toast.success(obj.message);
            props.closePass();
            localStorage.setItem("isRememberMeSelected", false);
        }
        else {
            if (data.message === "You are not logged in") {
                localStorage.setItem("isRememberMeSelected", false);
                setTimeout(function () {
                    window.location.href = "/sa";
                }, 2000);
                return false;
            } else if (data.message === "Your Session is expired, login again") {
                localStorage.setItem("isRememberMeSelected", false);
                toast.error(data.message);
                // // console.log("session expired");
                setTimeout(function () {
                    window.location.href = "/sa";
                }, 2000);
                return false;
            } else {
                toast.error(data.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    const logout = () => {
    }

    function resetState(e) {
        // // console.log('inside reset  ' + e)
        if (e === false) {
            setReset(false)
            return
        }
        setReset(true)
        // document.getElementById("resetBtn").removeAttribute('disabled')
    }

    return (
        <div style={{ height: 'fit-content' }}>
            <ToastContainer autoClose={3000} hideProgressBar />
            <button
                type='button'
                className='btn btn-info pull-right'
                onClick={() => props.closePass()}
            >
                <span
                    id='close'
                    title='Close'
                    className='glyphicon glyphicon-remove'
                ></span>
            </button>
            <form>
                <div className='form-group col-md-12'>
                    <label className='control-label col-md-4' align='right'>
                        Old Password:
                    </label>
                    <div className='col-sm-6 padding-remove'>
                        <input
                            type={showOldPass ? 'text' : 'password'}
                            className='form-control'
                            onChange={e => handleOldPass(e)}
                        />
                        <span
                            toggle='#password-field'
                            onClick={e => showOldPassword()}
                            className={
                                hide === true
                                    ? 'fa fa-fw field_icon toggle-password fa-eye-slash'
                                    : 'fa fa-fw fa-eye field_icon toggle-password'
                            }
                        ></span>
                    </div>
                </div>

                <div className='form-group col-md-12'>
                    <label
                        style={{ align: 'right' }}
                        className='control-label col-md-4'
                        align='right'
                    >
                        Password:
                    </label>
                    <div className='col-sm-6 padding-remove'>
                        <input
                            type={showPass ? 'text' : 'password'}
                            className='form-control'
                            onChange={e => handlePass(e)}
                        />
                        <span
                            toggle='#password-field'
                            onClick={e => showPassword()}
                            className={
                                hide2 === true
                                    ? 'fa fa-fw field_icon toggle-password fa-eye-slash'
                                    : 'fa fa-fw fa-eye field_icon toggle-password'
                            }
                        ></span>
                    </div>
                </div>

                <div className='form-group col-md-12'>
                    <label
                        style={{ align: 'right' }}
                        className='control-label col-md-4'
                        align='right'
                    >
                        Confirm Password:
                    </label>
                    <div className='col-sm-6 padding-remove'>
                        <input
                            type={showAgainPass ? 'text' : 'password'}
                            className='form-control'
                            onChange={e => handlePassAgain(e)}
                        />
                        <span
                            toggle='#password-field'
                            onClick={e => showAgainPassword()}
                            className={
                                hide3 === true
                                    ? 'fa fa-fw field_icon toggle-password fa-eye-slash'
                                    : 'fa fa-fw fa-eye field_icon toggle-password'
                            }
                        ></span>
                    </div>
                </div>
                <div className='form-group col-md-10 col-md-offset-2'>
                    <div className=' col-sm-12 padding-remove'>
                        <PasswordChecklist
                            rules={['minLength', 'specialChar', 'number', 'capital']}
                            minLength={8}
                            iconSize={10}
                            value={password}
                            valueAgain={passwordAgain}
                            onChange={isValid => {
                                resetState(isValid)
                            }}
                            messages={{
                                minLength: 'Minimum length should be 8 chars',
                                specialChar: 'Should contain at least one special char',
                                number: 'Should contain at least one number.',
                                capital: 'Should contain at least one Capital letter'
                            }}
                        />
                    </div>
                </div>
                <div className='form-group row col-sm-4 col-md-offset-4'>
                    {/* <button type="button"  className="btn btn-info btn-space" onClick={(e) => forgetPassword(cbForgetPassword)}>
                Forget Password?
                </button> */}
                    <button
                        type='button'
                        id='resetBtn'
                        className='btn btn-info btn-space'
                        onClick={e => changePassword()}
                        disabled={!reset}
                    >
                        Change Password
                    </button>
                </div>
            </form>
        </div>
    );

}

export default Changepassword;