import React, { Component } from 'react'
import $ from 'jquery'

export default class StudentDuesTable extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loader: 'none',
      opacity: 1,
      flg: true,
      tokenid: localStorage.getItem('tokenid'),
      totalPayment1: 0,
      totalPayment2: 0,
      totalPayment3: 0,
      overAllTotalPayment: 0
    }
  }

  componentDidMount = () => {
    $('.checkboxClick').prop('checked', true)
    this.calculateTotal()
  }

  handlePayment = id => {
    let payment = $('#' + id).val()

    if (payment < 0 || payment === 0 || payment === '') {
      $('#payCbx_' + id).prop('checked', false)
    } else {
      $('#payCbx_' + id).prop('checked', true)
    }
    this.totalPayment(id)
  }
  handleCheck = id => {
    // // console.log('cbox clicked:' + id)
    let feeDue = $('#feeDue' + id).text()
    let checked = $('#payCbx_' + id).prop('checked')
    // // console.log('checked = ' + checked)
    // // console.log(feeDue)
    if (checked == true) {
      // // console.log($('#paymentInput_' + id))
      $('#paymentInput_' + id).val(feeDue)
    } else {
      $('#paymentInput_' + id).val('')
    }
    this.props.handlePayment(id)
    this.totalPayment(id)
  }
  totalPayment = id => {
    var total = 0
    let suffixId = id.substr(0, id.length - 4)
    let tableId = '#feeDueTableBody' + suffixId
    // // console.log(tableId)
    $(tableId)
      .find('tr')
      .each(function (index, value) {
        // // console.log(
        //   $(this)
        //     .find($('input[type=checkbox]'))
        //     .prop('checked') === true
        // )
        if (
          $(this)
            .find($('input[type=checkbox]'))
            .prop('checked') === true
        ) {
          var payment = $(this)
            .find('td:eq(5) input')
            .val()
          total = total + parseInt(payment)
          // // console.log('payment = ' + payment + ', total=' + total)
        }
      })
    $('#dueFeepayment' + suffixId).html(total)

    this.calculateTotal()
  }
  calculateTotal = () => {
    let totalPayment = 0
    if (this.props.count === 1) {
      let payment1 = parseInt($('.dueFeepayment0').text())
      this.props.calculateTotal(payment1)
    } else if (this.props.count === 2) {
      let payment1 = parseInt($('.dueFeepayment0').text())
      let payment2 = parseInt($('.dueFeepayment1').text())
      totalPayment = parseInt(payment1) + parseInt(payment2)
      this.props.calculateTotal(totalPayment)
    } else {
      let payment1 = parseInt($('.dueFeepayment0').text())
      let payment2 = parseInt($('.dueFeepayment1').text())
      let payment3 = parseInt($('.dueFeepayment2').text())
      totalPayment =
        parseInt(payment1) + parseInt(payment2) + parseInt(payment3)
      this.props.calculateTotal(totalPayment)
    }
  }

  render () {
    return (
      <div
        className='col-sm-4'
        id={`dueFee` + `studentId` + this.props.feeDues.studentId}
      >
        <div className='headerSection'>
          <span>{this.props.feeDues.enrollmentNo} - </span>
          <span>{this.props.feeDues.student} </span>
          <span>({this.props.feeDues.cls} - </span>
          <span>{this.props.feeDues.section})</span>
          <div className='pull-right'>
            <span>{this.props.feeDues.totalFeeDue}</span>
          </div>
        </div>
        <table className='tableLayout1 studentDuesTable'>
          <thead>
            <tr>
              <th style={{ width: 40 }}> Receipt No.</th>
              <th style={{ width: 40 }}> Month </th>
              <th style={{ width: 40 }}> Fee </th>
              <th style={{ width: 40 }}> Due</th>
              <th style={{ width: 40 }}> Payment</th>
              <th style={{ width: 30 }}> Select</th>
              <th style={{ width: 30 }}> Action</th>
            </tr>
          </thead>
        </table>
        <div className='tableDivSmall'>
          <table id='feeDueTable' className='tableLayout'>
            {/* {className={parseInt(feeDue.due) === 0 ? "disableRow" : ""}} */}
            <tbody
              className={`feeDueTableBody` + this.props.paymentid}
              id={`feeDueTableBody` + this.props.feeDues.studentId}
            >
              {this.props.feeDues.lstBillDtls.map((feeDue, i) => (
                <tr key={i} lstbilldtls={JSON.stringify(feeDue.lstBillDtls)}>
                  <td width='40' className='no-display'>
                    {this.props.feeDues.studentId}
                  </td>
                  <td width='40' className='text-center'>
                    {feeDue.billNo}
                  </td>
                  <td width='40' className='text-center'>
                    {feeDue.months}
                  </td>
                  <td
                    width='40'
                    className='text-center'
                  
                  >
                    {feeDue.amount}
                  </td>
                  <td width='40' className='text-center'   id={
                      `feeDue` +
                      this.props.feeDues.studentId +
                      `_` +
                      feeDue.billNo
                    }>
                    {feeDue.due}
                  </td>
                  <td width='40' className='text-center'>
                    <input
                      type='text'
                      onChange={e =>
                        this.props.handlePayment(
                          this.props.feeDues.studentId + `_` + feeDue.billNo
                        )
                      }
                      className='form-control text-right'
                      defaultValue={feeDue.due}
                      id={
                        `paymentInput_` +
                        this.props.feeDues.studentId +
                        `_` +
                        feeDue.billNo
                      }
                    />
                  </td>
                  <td width='30' className='text-center'>
                    <input
                      type='checkbox'
                      className={
                        parseInt(feeDue.due) === 0 ? '' : 'checkboxClick'
                      }
                      onClick={e =>
                        this.handleCheck(
                          this.props.feeDues.studentId + `_` + feeDue.billNo
                        )
                      }
                      id={
                        `payCbx_` +
                        this.props.feeDues.studentId +
                        `_` +
                        feeDue.billNo
                      }
                    />
                  </td>
                  <td width='30' className='text-center'>
                    <span
                      className='glyphicon glyphicon-book'
                      onClick={e =>
                        this.props.showFeeDueDtls(
                          this.props.feeDues.studentId,
                          feeDue.billNo
                        )
                      }
                    ></span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className=' col-sm-12 headerSection padding-remove'>
          <span className='col-sm-3 paymentText'>Payment</span>
          <div className='col-sm-9 text-center'>
            <span
              className={`dueFeepayment` + this.props.paymentid}
              id={`dueFee` + `payment` + this.props.feeDues.studentId}
            >
              {this.props.feeDues.totalFeeDue}
            </span>
          </div>
        </div>
      </div>
    )
  }
}
