import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import lstModules from 'common/json/lstModules.json'

var httpURL = lstModules['timeTable'].url + ':' + lstModules['timeTable'].port
var httpURLCommon = lstModules['main'].url + ':' + lstModules['main'].port

var screenId = 33
var tokenid = localStorage.getItem('tokenid')

function saveStudentFeedbackSvc (feedback, cb) {
  var status = 'Success'
  var obj = { status: status, message: '' }
  axios({
    url: httpURLCommon + '/sms/ptm/student/feedback/save',
    //queryString.stringify({ screenId: screenId, cellId: cellId, sub1: sub1 }),
    method: 'POST',
    headers: { tokenid: tokenid },
    withCredentials: true,
    params: { feedback: JSON.stringify(feedback) }
  })
    .then(response => {
      // // console.log( 'message:' + response.data.SvcMsg + 'status:' + response.data.SvcStatus  )
      obj.message = response.data.SvcMsg
      obj.status = response.data.SvcStatus
      cb(obj)
      //}
      // else {
      //     obj.status = 'Failure';
      //     obj.message = response.data.SvcMsg;
      //     cb({}, obj)
      // }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      cb({}, obj)
    })
}
function getSubjectListSvc (sectionId, callback1) {
  var status = 'Success'
  var obj = { status: status }
  axios({
    url: httpURLCommon + '/sms/mst/section/subject/list',
    method: 'POST',
    params: { sectionId: sectionId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      if (response.data.SvcStatus === 'Success') {
        obj.message = response.data.SvcMsg
        obj.status = 'Success'
        obj.lstSubject = response.data.lstSubject
        callback1(obj)
      } else {
        obj.status = 'Failure'
        obj.message = response.data.SvcMsg
        callback1(obj)
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      callback1(obj)
    })
}

function getTchListSvc (sectionId, callback2) {
  var status = 'Success'
  var obj = { status: status }
  axios({
    url: httpURLCommon + '/sms/mst/section/teacher/list',
    method: 'POST',
    params: { sectionId: sectionId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      if (response.data.SvcStatus === 'Success') {
        obj.message = response.data.SvcMsg
        obj.status = 'Success'
        obj.lstTeacher = response.data.lstTeacher
        callback2(obj)
      } else {
        obj.status = 'Failure'
        obj.message = response.data.SvcMsg
        callback2(obj)
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      callback2(obj)
    })
}
function getStudentFeedbackSvc (ptmStudentId, callback2) {
  var status = 'Success'
  var obj = { status: status }
  axios({
    url: httpURLCommon + '/sms/ptm/student/feedback/dtls',
    method: 'POST',
    params: { ptmStudentId: ptmStudentId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      if (response.data.SvcStatus === 'Success') {
        obj.message = response.data.SvcMsg
        obj.status = 'Success'
        obj.feedbackDtls = response.data.feedbackDtls
        callback2(obj)
      } else {
        obj.status = 'Failure'
        obj.message = response.data.SvcMsg
        callback2(obj)
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      callback2(obj)
    })
}
export {
  getSubjectListSvc,
  getTchListSvc,
  getStudentFeedbackSvc,
  saveStudentFeedbackSvc
}
