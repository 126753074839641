import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import Header from 'common/js/header/header.js'
import '../css/PayrollMonthProcessCtrl.css'
import PayrollMonthProcessSlider from './PayrollMonthProcessSlider';
import PayrollMonthProcessTable from './PayrollMonthProcessTable';
import { getPayrollMonthProcessListSvc } from './PayrollMonthProcessSvc';
var tokenid = localStorage.getItem("tokenid");
function PayrollMonthProcessCtrl() {
    const [loader, setLoader] = useState('none')
    const [flgSlider, setFlgSlider] = useState(false)
    const [randNo, setRandNo] = useState(0);
    const [lstPayroll, setLstPayroll] = useState([])
    const [payrollProcessId, setPayrollProcessId] = useState(0)
    const [payrollObj, setPayrollObj] = useState([])
    useEffect(() => {
        getPayrollMonthProcessListSvc(cbPayrollMonthProcessList)
    }, []);
    function cbPayrollMonthProcessList(data) {
        if (data.SvcStatus === "Success") {
            setLstPayroll(data.lstPayrollMonth)
            // // console.log("Payroll List::"+JSON.stringify(data.lstPayroll))
        }
        else {
            toast.error(data.SvcMsg)
        }
        setLoader('none');
    }
    function setPayrollforEdit(payrollProcessId) {
        // // console.log("Param " + payrollProcessId)
        for (var i = 0; i < lstPayroll.length; i++) {
            // // console.log("Inside loop " + lstPayroll[i].payrollProcessId)
            if (lstPayroll[i].payrollProcessId === payrollProcessId) {
                // // console.log("inside if " + JSON.stringify(lstPayroll[i]))
                setPayrollProcessId(lstPayroll[i].payrollProcessId)
                setPayrollObj(lstPayroll[i])
                break;
            }
        }
    }
    function openSlider() {
        setFlgSlider(true)
    }
    function closeSlider() {
        setFlgSlider(false)
        setPayrollObj([])
        setPayrollProcessId(0)
        generateRandomNo();
    }
    function generateRandomNo() {
        var lRandNo = Math.floor(Math.random() * 999999);
        // // console.log(lRandNo)
        setRandNo(lRandNo)
    }
    const getWingId = (wingId) => {
        // // console.log("wingId" + wingId);
    }

    return (
        <div className='MainContainer'>
            <Header wingFromChild={getWingId} />
            <div className="col-sm-12" style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
                <ToastContainer autoClose={3000} hideProgressBar />
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>
            </div>
            <div className='TestTblWrapper' style={{ width: "70%" }}>
                <div className='TestTbl'>
                    <PayrollMonthProcessTable
                        key={randNo}
                        closeSlider={closeSlider}
                        openSlider={openSlider}
                        setPayrollforEdit={setPayrollforEdit}
                        lstPayroll={lstPayroll}
                    />
                </div>
                <div className="" onClick={(e) => openSlider()}>
                    <span id="addReadyId" title="Add" className="addIcon glyphicon glyphicon-plus-sign"></span>
                </div>
            </div>
            <div style={{ width: "50%", display: flgSlider ? 'block' : 'none' }} className='TestSlider'>
                <button type="button" style={{ right: '50%' }} className='btn btn-info text-center btnCloseDiv' onClick={closeSlider}>
                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                </button>
                <PayrollMonthProcessSlider
                    key={randNo}
                    payrollProcessId={payrollProcessId}
                    payrollObj={payrollObj}
                    closeSlider={closeSlider}
                    openSlider={openSlider}
                    flgSlider={flgSlider}
                />
            </div>
        </div>
    );
}
export default PayrollMonthProcessCtrl;