import React, { useState, useEffect } from "react";
import {
  getEvaluationDtlsSvc,
  getStudentLstSvc,
  saveScoreSvc,
  saveRemarksSvc,
  submitEvaluationSvc,
} from "./EvaluateSliderSvc.js";
import { toast } from "react-toastify";
import "common/css/master.css";

const EvaluateSlider = (props) => {
  const [allocationId, setAllocationId] = useState(props.allocationId);
  const [cls, setCls] = useState("");
  const [clsId, setClsId] = useState(0);
  const [section, setSection] = useState("");
  const [sectionId, setSectionId] = useState(0);
  const [sub, setSub] = useState("");
  const [subId, setSubId] = useState(0);
  const [maxScore, setMaxScore] = useState("");
  const [passScore, setPassScore] = useState("");
  const [studentId, setStudentId] = useState(0);
  const [lstStudent, setLstStudent] = useState([]);
  const [name, setName] = useState("");
  const [maximumScore, setMaximumScore] = useState(0);
  const [minScore, setMinScore] = useState(0);
  const [avgScore, setAvgScore] = useState(0);
  const [found, setFound] = useState([]);
  const [enrollmentNo, setEnrollmentNo] = useState("");
  const [selScore, setSelScore] = useState({});
  const [selRemarks, setSelRemarks] = useState({});

  useEffect(() => {
    // // console.log(props.allocationId);
    if (props.flgSlider && props.allocationId !== 0) {
      setAllocationId(props.allocationId);
      getEvaluationDtlsSvc(cbEvaluationDtlsResponse, props.allocationId);
      getStudentLstSvc(
        cbStudentLstResponse,
        props.allocationId,
        props.sectionId
      );
      // getStudentLstSvc(cbStudentLstResponse, props.clsId, props.sectionId, props.subId)
    }
    // getStudentLstSvc(cbStudentLstResponse, props.clsId, props.sectionId, props.subId)
    /*saveScoreSvc()
        saveRemarksSvc()*/
  }, [props.flgSlider, props.allocationId, props.sectionId]);
  function cbSubmitEvaluation(result) {
    // // console.log(result)
    if (result.SvcStatus === "Success") {
      toast.success(result.SvcMsg);
    } else {
      toast.error(result.SvcMsg);
    }
  }
  function cbEvaluationDtlsResponse(result) {
    // // console.log(result);
    if (result.status === "Success") {
      setCls(result.examDtls[0].cls);
      setClsId(result.examDtls[0].clsId);
      setSection(result.examDtls[0].section);
      setSectionId(result.examDtls[0].sectionId);
      setSub(result.examDtls[0].sub);
      setSubId(result.examDtls[0].subId);
      setMaxScore(result.examDtls[0].maxScore);
      setPassScore(result.examDtls[0].passScore);
    }
  }

  function cbStudentLstResponse(data) {
    if (data.SvcStatus === "Success") {
      setLstStudent(data.lstStudent);
    } else {
      toast.error(data.SvcStatus);
    }
  }

  function submitEvaluation() {
    submitEvaluationSvc(cbSubmitEvaluation, props.allocationId)
  }

  const filterStudent = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = found.filter((user) => {
        return user.subject.toLowerCase().includes(keyword.toLowerCase());
      });
      setFound(results);
    } else {
      setFound(lstStudent);
    }
    setName(keyword);
  };

  const filterEnrollment = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = found.filter((user) => {
        return user.subject.toLowerCase().includes(keyword.toLowerCase());
      });
      setFound(results);
    } else {
      setFound(lstStudent);
    }
    setEnrollmentNo(keyword);
  };
  async function handleLstScoreChange(pId, subId, studentId, pVal) {
    var tmpScore = selScore;
    pId = parseInt(pId);
    if (pVal && selScore.examStudentId !== pId) {
      tmpScore = {
        allocationId: props.allocationId,
        examStudentId: parseInt(pId),
        examSectionSubId: subId,
        studentId: studentId,
        score: pVal,
      };
      setSelScore(tmpScore);
    } else {
      tmpScore = {
        allocationId: props.allocationId,
        examStudentId: !pId ? 0 : parseInt(pId),
        examSectionSubId: subId,
        studentId: studentId,
        score: 0,
      };
      setSelScore(tmpScore);
    }
    // // console.log(JSON.stringify(tmpScore));
    setSelScore(tmpScore);
    saveScoreSvc(tmpScore, cbResponse);
  }

  async function handleRemarksChange(pId, subId, studentId, pVal) {
    var tmpRemarks = selRemarks;
    pId = parseInt(pId);

    if (pVal && selRemarks.examStudentId !== pId) {
      tmpRemarks = {
        allocationId: props.allocationId,
        examStudentId: !pId ? 0 : parseInt(pId),
        examSectionSubId: subId,
        studentId: studentId,
        remarks: pVal,
      };
      setSelRemarks(tmpRemarks);
    } else {
      tmpRemarks = {
        allocationId: props.allocationId,
        examStudentId: parseInt(pId),
        examSectionSubId: subId,
        studentId: studentId,
        remarks: "",
      };
      setSelRemarks(tmpRemarks);
    }
    // // console.log(JSON.stringify(tmpRemarks));
    setSelRemarks(tmpRemarks);
    saveRemarksSvc(tmpRemarks, cbResponse);
  }
  function cbResponse(data) {
    if (data.status === "Success") {
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
  }
  const colgrp = (
    <colgroup>
      <col width="40" />
      <col width="60" />
      <col width="60" />
      <col width="40" />
      <col width="90" />
    </colgroup>
  );
  return (
    <div className="">
      <form>
        <div className="col-sm-12">
          <div className="col-sm-4 text-left">
            <div className="">
              <input
                readOnly
                className="form-control-plain text-left"
                style={{
                  border: "none",
                  background: "#ffffff",
                  fontWeight: "bold",
                }}
                value={cls + "-" + section || ""}
                disabled
              ></input>
            </div>
          </div>
        </div>

        <div className="col-sm-12">
          <label className="col-sm-2 control-label text-left">Subject:</label>
          <div className="form-group col-sm-3">
            <input
              type="text"
              readOnly
              className="form-control"
              defaultValue={sub}
            />
          </div>
        </div>

        <div className="col-sm-12">
          <label className="col-sm-2 control-label text-left">Max Score:</label>
          <div className="form-group col-sm-3">
            <input
              type="text"
              className="form-control"
              defaultValue={maxScore}
              readOnly
            />
          </div>
        </div>

        <div className="col-sm-12">
          <label className="col-sm-2 control-label text-left">
            Pass Score:
          </label>
          <div className="form-group col-sm-3">
            <input
              type="text"
              className="form-control"
              defaultValue={passScore}
              readOnly
            />
          </div>
        </div>
      </form>

      <div className="col-sm-12">
        <div className="col-sm-2">
          <label className="control-label col-sm-6">Average:</label>
          <div className="form-group col-sm-1">
            <input
              type="text"
              style={{
                border: "none",
                background: "#ffffff",
                fontWeight: "bold",
              }}
              className="form-control-plain"
              defaultValue={avgScore}
              onChange={(event) => setAvgScore(event.target.value)}
            />
          </div>
        </div>
        <div className="col-sm-2">
          <label className="control-label col-sm-6">Max:</label>
          <div className="form-group col-sm-1">
            <input
              type="text"
              readOnly
              style={{
                border: "none",
                background: "#ffffff",
                fontWeight: "bold",
              }}
              className="form-control-plain"
              defaultValue={maximumScore}
              onChange={(event) => setMaximumScore(event.target.value)}
            />
          </div>
        </div>
        <div className="col-sm-2">
          <label className="control-label col-sm-6">Min:</label>
          <div className="form-group col-sm-1">
            <input
              type="text"
              readOnly
              style={{
                border: "none",
                background: "#ffffff",
                fontWeight: "bold",
              }}
              className="form-control-plain"
              defaultValue={minScore}
              onChange={(event) => setMinScore(event.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="col-sm-11">
        <table className="tableLayout1">
          {colgrp}
          <thead>
            <tr>
              <th className="text-center">Roll No.</th>
              <th className="padding-remove">
                <input
                  onChange={filterEnrollment}
                  type="text"
                  value={enrollmentNo}
                  className="form-control inp-search text-center"
                  placeholder="Enrollment No."
                />
              </th>
              <th className="padding-remove">
                <input
                  onChange={filterStudent}
                  type="text"
                  value={name}
                  className="form-control inp-search text-center"
                  placeholder="Student"
                />
              </th>
              <th className="text-center">Score</th>
              <th className="text-center">Remarks</th>
            </tr>
          </thead>
        </table>
        <div className="tablebodyWrapper">
          <table className="tableLayout">
            {colgrp}
            <tbody>
              {lstStudent.map((obj, idx) => {
                return (
                  <tr key={idx} obj={JSON.stringify(obj)}>
                    <td className="text-left">{obj.rollNo}</td>
                    <td className="text-left">{obj.enrollmentNo}</td>
                    <td className="text-left">{obj.student}</td>
                    <td className="text-center">
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={obj.score}
                        id={obj.examStudentId}
                        key={"s_" + obj.examStudentId}
                        onBlur={(e) =>
                          handleLstScoreChange(
                            obj.examStudentId,
                            obj.subId,
                            obj.studentId,
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={obj.remarks}
                        id={"r_" + obj.examStudentId}
                        key={"r_" + obj.examStudentId}
                        onBlur={(e) =>
                          handleRemarksChange(
                            obj.examStudentId,
                            obj.subId,
                            obj.studentId,
                            e.target.value
                          )
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <div className="col-sm-11">
          <div className="col-md-1  col-xs-1 pull-right">
            <button
              onClick={(e) =>
                submitEvaluation()
              }
              style={{ marginTop: "1%" }}
              className="btn btn-outline-warning text-dark fw-bold btn_primary"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EvaluateSlider;
