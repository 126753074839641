import axios from 'axios'

import React, { Component, useEffect, useState } from 'react';
import lstModules from 'common/json/lstModules.json';
var tokenid = localStorage.getItem('tokenid');
const httpURL = lstModules['timeTable'].url + ':' + lstModules['timeTable'].port;
const ViewSlider = (props) => {
    useEffect(() => {
        if (props.selDt && props.flg) {
            getDutiesView(props.selDt, cbDutiesView)
        }
    })
    function getDutiesView(pDt, callBack) {
        axios(
            {
                url: httpURL + "/sms/tt/v1/absent/duties/view",
                method: 'POST',
                params: { dt: pDt },
                headers: { 'tokenid': tokenid },
                withCredentials: true,
                responseType: 'arraybuffer'
            }
        ).then(
            (Response) => {
                // // console.log(Response)
                var data = Response
                callBack(data)
            }
        ).catch((error) => {
            // // console.log("Catch error")
        });
    }
    function cbDutiesView(data) {
        let blob = new Blob([data.data], { type: 'application/pdf' }),
            url = window.URL.createObjectURL(blob);
        const iframe = document.getElementById('viewer');
        iframe.setAttribute('src', url);
        URL.revokeObjectURL(url);
    }
    return (
        <div>
            <button type="button" className="btn btn-info text-center " id="closeSlider" style={{ right: '71%', opacity: 1 }}
                onClick={(e) => props.setFlgClose()}>
                <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
            </button>
            <div className="pdf" style={{ width: '100%', height: '96vh' }}>
                <iframe src={require("common/images/loader.gif")} title="Time Table Preview" id="viewer" style={{ width: '100%', height: '100%' }} />
            </div>
        </div>
    )
}
export default ViewSlider