import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'

const DropDown = props => {
  return (
    <div className='row'>
      <label className='control-label col-sm-4'>{props.label}</label>
      <div className='form-group col-sm-8'>
        <select
          className='form-control'
          value={props.dataValue}
          onChange={e => props.onChangeDropDown(e.target.value)}
        >
          <option value={0}>{props.option1}</option>
          {props.lst.map((obj, idx) => {
            return (
              <option key={idx} value={obj[props.keyId]}>
                {obj[props.keyVal]}
              </option>
            )
          })}
        </select>
      </div>
    </div>
  )
}
DropDown.propTypes = {
  dataValue: PropTypes.number
}
export default DropDown
