import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "common/css/App.css";
import "common/bootstrap/css/bootstrap.min.css";
import { WebSocketProvider } from "common/context/WebSocketContext";

import Login from "common/login/js/login.js";
// import LoginOld from "common/login/js/loginOldOld.js";
import PayrollMonthProcess from "sms/payrollSetup/monthlyProcess/js/PayrollMonthProcess";
// import TimeTable from 'modules/timeTable/main/js/timeTable.js';
import MainScreen from "sms/parent_portal/js/mainScreen.js";
import SchoolList from "sms/parent_portal/js/schoolList.js";
import Timing from "sms/timings/js/timing.js";
import Class from "sms/classes/js/classes.js";
import ClassSetupCtrl from "sms/setup/js/ClassSetupCtrl.js";
import LabelCtrl from "sms/fees/Label/js/LabelCtrl.js";
import CounselorSetup from "sms/enquiry/js/CounselorSetup.js";
import BillGenerationCtrl from "sms/fees/BillGeneration/js/BillGenerationCtrl.js";
import StaffCtrl from "sms/staff/js/StaffCtrl.js";
import TeacherProfileCtrl from "sms/recruitment/js/TeacherProfileCtrl.js";
import TestConfigCtrl from "sms/enquiry/js/TestConfigCtrl.js";
import SlotConfigCtrl from "sms/enquiry/js/SlotConfigCtrl.js";
import InterviewSlotConfigCtrl from "sms/enquiry/js/InterviewSlotConfigCtrl.js";
import ClassDetail from "sms/classes/js/ClassDetail.js";
import PeriodType from "sms/periodType/js/periodType";
import Syllabus from "sms/syllabus/js/syllabus.jsx";
import Enquiry from "sms/enquiry/js/enquiry.js";
import Tat from "sms/enquiry/js/Tat.js";
// import FeedbackTat from 'modules/enquiry/js/FeedbackTat.js';
import EnquiryCtrl from "sms/enquiry/js/EnquiryCtrl.js";
import AdmissionCtrl from "sms/admission/js/AdmissionCtrl.js";
import FeedbackCtrl from "sms/feedback/js/FeedbackCtrl.js";
import Building from "sms/building/js/building.js";
import ClassStudentManage from "sms/classes/js/classStudentManage";
// import Classedit from "modules/classes/js/classedit";
import Classedit from "sms/classes/js/classEditNew";
import AcademicSession from "sms/academicSession/js/academicSession.js";
import Wings from "sms/wings/js/wings.js";
import SetupCtrl from "sms/setup/js/SetupCtrl.js";
import DiaryCtrl from "sms/diary/diary/js/DiaryCtrl";
import MonthlyDiarySummary from "sms/diary/monthlySummary/monthlySummaryCtrl";
import DailyDiarySummary from "sms/diary/dailySummary/dailySummaryCtrl";
import SchoolProfile from "sms/setup/js/schoolProfile/js/schoolProfile";
import SchoolSetupCtrl from "sms/setup/js/SchoolSetupCtrl.js";
import RoleSetupCtrl from "sms/setup/js/RoleSetupCtrl.js";
import Items from "sms/purchase/js/items-class.js";
import ItemsNew from "sms/purchase/js/items.js";
import Suppliers from "sms/purchase/js/suppliers.js";
import Quotes from "sms/purchase/js/quotes.js";
import Student from "sms/students/js/student";
import StudentNew from "sms/studentsNew/students/students";
import StudentDetails from "sms/students/js/studentdtls";
import StudentDetailsNew from "sms/studentsNew/studentDetails/studentDetails";
import StudentRegisterDetails from "sms/registration/js/studentRegisterDtls";
import Teacher from "sms/teacher/js/teacher.js";
import TeacherDetails from "sms/teacher/js/teacherdtls.js";
import Registration from "sms/registration/registration/registration.js";
import RegistrationFees from "sms/registration/js/registrationFees.js";
import AdmissionTests from "sms/registration/js/admissionTests.js";
import AdmissionTestSlot from "sms/registration/js/admissionTestSlot.js";
import TestSchedule from "sms/registration/js/testSchedule.js";
import Error from "common/components/error.js";
import StaffLeaves from "sms/staffLeaves/js/staffLeaves.js";
import DisruptionCtrl from "sms/tt/disruption/disruptionCtrl";
import FacilityCtrl from "sms/tt/facility/facilityCtrl";
import TeacherAllocationCtrl from "sms/tt/teacherAllocation/teacherAllocationCtrl";
// import SubjectAllocationCtrl from "sms/tt/subjectAllocation/js/subjectAllocationCtrl";
import SubjectAllocationCtrl from "sms/tt/subjectTeacherAllocation/js/subjectAllocationCtrl";
import AllocationReportCtrl from "sms/tt/subjectAllocationReport/js/allocationReportCtrl";
import AppraisalCtrl from "sms/appraisal/js/AppraisalCtrl";
// import Allowances from 'modules/payroll/js/allowances.js';
// import Deductions from 'modules/payroll/js/deductions.js';
// import PF from 'modules/payroll/js/providentFund.js';
// import ESI from 'modules/payroll/js/esi.js';
// import TAX from 'modules/payroll/js/tax.js';
import PayrollMonthProcessCtrl from "sms/payrollSetup/monthlyProcess/js/PayrollMonthProcessCtrl";
import StudentTemplate from "sms/studentTemplate/js/studentTemplate.js";
import SchoolRegister from "sms/schoolRegister/js/schoolRegister";
import PrinciEnqDashboard from "sms/sysops/dashboard/js/dashboard";
import TaskCtrl from "sms/dashboard/js/taskCtrl";
import VerifyEmail from "sms/sysops/verifyEmail/js/verifyEmail";
import PDashboard from "sms/dashboard/js/pDashboard";
import AcademicYear from "sms/academicYear/js/academicYearCtrl";
import AcademicSchedule from "sms/academicSchedule/js/academicScheduleCtrl";
import Content from "sms/content/js/Content";
import Campaign from "sms/campaign/js/Campaign";
/*import SubjectView from 'modules/timeTable-old/main/js/subjectView';*/
import ReportCard from "sms/performance/js/reportCard";
import StudentReport from "sms/performance/js/studentReportCtrl";
import ReportCardTemplate from "sms/performance/js/reportcardtemplate";
import ExamTimeTable from "sms/examTT/main/js/examtimetable";
import ExamSubjects from "sms/performance/js/examSubjects";
import DetailsSlider from "sms/examTT/main/js/detailsSlider";
import ParentAppLogin from "sms/parentAppLogin/js/parentAppLogin";
import TeacherSyllabus from "sms/tt/tchSubject/js/teacherSyllabus";
// import TT from 'modules/timeTable/v1/tt/js/timeTable';
// import TT from 'modules/tt-old/v1/tt/js/timeTable';
// Fees
// import Fees from 'modules/fees'

import FeePayment from "sms/fees/FeePayment/js/feePayment";
import FeePaymentDetails from "sms/fees/FeePayment/js/FeePaymentDetails.jsx";
import CollectionSummary from "sms/fees/FeeReport/js/collectionSummary";
import MonthlySummary from "sms/fees/FeeReport/js/monthlySummary.js";
import ClassSummary from "sms/fees/FeeReport/js/classSummary";
import StudentSummary from "sms/fees/FeeReport/js/studentSummary";
import Outstanding from "sms/fees/FeeReport/js/outstanding";
import FeeHeads from "sms/fees/FeeHead/js/feeHeads";
import FeeStudents from "sms/fees/StudentFee/js/feeStudents";
import StudentDiscount from "sms/fees/DiscountReport/js/feeDiscountCtrl";
import ClassFee from "sms/fees/classFeeSetup/js/classFee";
// import ClassFee from "modules/fees/classFeeSetup/js/classFeeNew.js";
import FeeDue from "sms/fees/FeeDue/js/FeeDue";
import SubjectCtrl from "sms/tt/subject/js/subjectCtrl";
import ClassWiseCtrl from "sms/tt/summary/classWiseCtrl";
import TTTeacherCtrl from "sms/tt/teacher/js/ttTeacherCtrl";
import TchWiseCtrl from "sms/tt/summary/tchWiseCtrl";
import ClsSyllabus from "sms/tt/clsSyb/clsSybCtrl";
import RegistrationCtrl from "sms/enquiry/js/RegistrationCtrl";
import AdmissionRegsCtrl from "sms/admission/js/AdmissionRegsCtrl";
import EnquiryDashboard from "sms/enquiry/js/EnquiryDashboard";
import AnnualCollection from "sms/fees/AnnualCollection/AnnualCollection";
import ActualCollection from "sms/fees/Actual Collection/js/ActualCollection";
import Grade from "sms/payrollSetup/js/grade.js";
import StaffType from "sms/payrollSetup/js/staffType.js";
import PAllowances from "sms/payrollSetup/js/allowances.js";
import Advance from "sms/payrollSetup/js/advanceCtrl";
import PDeductions from "sms/payrollSetup/js/deductions.js";
import providentFundCtrl from "sms/payrollSetup/js/providentFundCtrl";
import esiCtrl from "sms/payrollSetup/js/esiCtrl";
import taxCtrl from "sms/payrollSetup/js/taxCtrl";
import EmployeeCtrl from "sms/payrollSetup/js/employeeCtrl";
import VehicleTypeCtrl from "sms/transport/js/vehicleTypeCtrl";
import VehicleCtrl from "sms/transport/js/vehicleCtrl";
import TptReportCtrl from "sms/transport/js/tptReportCtrl";
import RouteCtrl from "sms/transport/js/routeCtrl";
import RouteStopReport from "sms/transport/js/routeStopReport";
import RouteStopCtrl from "sms/transport/js/routeStopCtrl";
import StopRange from "sms/transport/js/stopRange";
import Driver from "sms/transport/js/driver";
import StudentTr from "sms/transport/js/students";
import TermCtrl from "sms/performance/js/termCtrl.js";
import ExamCtrl from "sms/performance/js/examCtrl";
import ExamSyllabus from "sms/performance/examSyllabus/js/examSyllabus";
import ClassExamSyllabus from "sms/performance/SyllabusPDF/js/classExamSyllabus";
import AllocateCtrl from "sms/performance/js/AllocateCtrl";
import ProgressReport from "../../sms/performance/js/reportCard/progressReport";
import ExamReport from "sms/performance/js/examReportCard/examReport";
import ExamReportStandalone from "sms/performance/examReportCardStandalone/examReportStandalone";
import TermReportCtrl from "sms/performance/termReportCard.js/termReportCardCtrl";
import ExamRemarks from "sms/performance/examRemarks/examRemarks";
import EvaluateCtrl from "sms/performance/js/EvaluateCtrl";
import ExamEvaluateCtrl from "sms/performance/js/ExamEvaluateCtrl";
import AdmitCardCtrl from "sms/performance/js/admitCard/js/admitCardCtrl";
import ValidateSummary from "sms/performance/js/validateSummary/validateSummaryCtrl";
import SubjCtrl from "sms/setup/js/SubjCtrl.js";
import ExamScheduleCtrl from "sms/performance/js/ExamScheduleCtrl";
import ExamDashboardCtrl from "sms/performance/js/ExamDashboardCtrl";
import DatesheetCtrl from "sms/performance/js/DatesheetCtrl";
import TchDutyCtrl from "sms/performance/js/TchDutyCtrl";
import DailyCollectionReport from "sms/fees/FeeReport/js/DailyCollectionReport";
import DailyAttendanceCtrl from "sms/attendance/js/DailyAttendanceCtrl";
import MonthlyAttendanceCtrl from "sms/attendance/js/MonthlyAttendanceCtrl";
import YearlyAttendanceCtrl from "sms/attendance/js/YearlyAttendanceCtrl";
import NewAcademicYrCtrl from "sms/promote/js/NewAcademicYrCtrl";
import PromoteCtrl from "sms/promote/js/PromoteCtrl";
import PtmFeedbackParentApp from "sms/ptmParentApp/js/ptmFeedBack";
import PtmCtrl from "sms/ptm/js/PtmCtrl";
import PtmFeedbackCtrl from "sms/ptm/js/PtmFeedbackCtrl";
import ClsPtmFeedbackCtrl from "sms/ptm/js/ClsPtmFeebackCtrl";
import StudentPtmFeedbackCtrl from "sms/ptm/js/StudentPtmFeebackCtrl";
import StudentFeedback from "sms/ptm/js/StudentFeedback";
import OneTimeHeadCtrl from "sms/oneTimeHead/js/OneTimeHeadCtrl";
import StudentFeeHeadCtrl from "sms/oneTimeHead/js/StudentFeeHeadCtrl";
import WebsiteCtrl from "sms/website/js/WebsiteCtrl";
import Books from "sms/library/js/books/books";
import IssueBookCtrl from "sms/library/js/issue/issueBooks";
import InfoCtrl from "sms/website/js/InfoCtrl.js";
import BlogCtrl from "sms/website/js/blogCtrl";
import ResultCtrl from "sms/website/js/resultCtrl";
import CommCtrl from "sms/comm/js/commCtrl";
import CommunicationGroups from "sms/communicationGroups/js/commGroups";
import TptAttendanceCtrl from "sms/transport/js/tptAttendanceCtrl";
import AcceptPayment from "sms/transport/acceptPayment/js/acceptPayment";
import Info from "sms/universalSchoolListing/Info";
import Fees from "sms/universalSchoolListing/Fees";
import ListingFacility from "sms/universalSchoolListing/ListingFacility";
import ListingGallery from "sms/universalSchoolListing/ListingGallery";
import ListingTeam from "sms/universalSchoolListing/ListingTeam";
import Specialty from "sms/universalSchoolListing/Specialty";
import ReturnCtrl from "sms/library/js/return/returnCtrl";
import SALogin from "../../sa/login/js/login";
import ApproveSchools from "../../sa/approveSchools/js/approveSchools";
import SettlementCtrl from "../../sa/settlement/js/SettlementCtrl";
import SettlementTab from "../../sa/settlement/js/SettlementTab";
import SAWebsiteApikeysCtrl from "sa/API Keys/WebsiteApikeysCtrl";
import WebsiteApikeysCtrl from "sms/API Keys/WebsiteApikeysCtrl";
import StudentLeaves from "sms/studentLeaves/js/studentLeaves";
import EventCtrl from "sms/event/js/EventCtrl";

import StudentWeeklyTest from "student/WeeklyTest/TestLogin/testStudent";
import TestInstruction from "student/WeeklyTest/TestInstruction/testInstruction";
import TestQuestion from "student/WeeklyTest/TestQuestion/testQuestion";
import LeaderBoard from "student/WeeklyTest/Leaderboard/leaderboard";
import WTestCtrl from "sms/WeeklyTest/test/js/WTestCtrl";
import WTestProgressCtrl from "sms/WeeklyTest/test/js/WTestProgressCtrl";
import WTestScheduleCtrl from "sms/WeeklyTest/schedule/js/WTestScheduleCtrl";
import AnswerSheet from "sms/WeeklyTest/answerSheet/answerSheetCtrl";
import TestFeedback from "student/WeeklyTest/TestFeedback/testFeedback";

// import StudentWeeklyTest from "student/WeeklyTestSocket/TestLogin/testStudent";
// import TestInstruction from "student/WeeklyTestSocket/TestInstruction/testInstruction";
// import TestQuestion from "student/WeeklyTestSocket/TestQuestion/testQuestion";
// import LeaderBoard from "student/WeeklyTestSocket/Leaderboard/leaderboard";
// import WTestCtrl from "sms/WeeklyTestSocket/test/js/WTestCtrl";
// import WTestProgressCtrl from "sms/WeeklyTestSocket/test/js/WTestProgressCtrl";
// import WTestScheduleCtrl from "sms/WeeklyTestSocket/schedule/js/WTestScheduleCtrl";
// import AnswerSheet from "sms/WeeklyTestSocket/answerSheet/answerSheetCtrl";
// import TestFeedback from "student/WeeklyTestSocket/TestFeedback/testFeedback";

import TcCtrl from "sms/certificates/transferCertificate/js/tcCtrl.js";
import BonafideCtrl from "sms/certificates/bonafideCertificate/js/bonafideCtrl";
import CctvViolation from "sms/cctv/js/cctvViolation";
//window.httpURL = "http://ec2-13-233-192-173.ap-south-1.compute.amazonaws.com:8080";
// window.httpURL = "http://sms.kmsgtech.com:8080";
//window.httpURL = "http://192.168.1.13:8080";
/* window.appURL = "http://13.126.35.191:3000";
window.appURL = 'http://65.0.143.2:3000'
window.appURL = "http://localhost:3000";*/
window.appURL = "https://sms.cooledsys.com";
function App() {
  return (
    <WebSocketProvider>
      <Router>
        <Routes>
          <Route path="/studentTr" basename="Login" element={<StudentTr />} />
          <Route path="/driver" basename="Login" element={<Driver />} />
          <Route path="/stopRange" basename="Login" element={<StopRange />} />
          <Route path="/stop" basename="Login" element={<RouteStopCtrl />} />
          <Route path="/schoolinfo" basename="Login" element={<Info />} />
          <Route path="/schoolfee" basename="Login" element={<Fees />} />
          <Route path="/listingfacility" basename="Login" element={<ListingFacility />} />
          <Route path="/listinggallery" basename="Login" element={<ListingGallery />} />
          <Route path="/listingteam" basename="Login" element={<ListingTeam />} />
          <Route path="/listingspecialty" basename="Login" element={<Specialty />} />

          <Route path="/tptRouteStop" basename="/Login" exact element={<RouteStopReport />} />
          <Route path="/vehicleType" basename="Login" element={<VehicleTypeCtrl />} />
          <Route path="/vehicle" basename="Login" element={<VehicleCtrl />} />
          <Route path="/tptReport" basename="Login" element={<TptReportCtrl />} />
          <Route path="/route" basename="Login" element={<RouteCtrl />} />
          <Route path="/tptAttendance" basename="Login" element={<TptAttendanceCtrl />} />
          <Route path="/acceptPayment" basename="Login" element={<AcceptPayment />} />
          <Route path="/staffType" basename="Login" element={<StaffType />} />
          <Route path="/advance" basename="Login" element={<Advance />} />
          <Route path="/employeeData" basename="Login" element={<EmployeeCtrl />} />
          <Route path="/payrollProvidentfund" basename="Login" element={<providentFundCtrl />} />
          <Route path="/payrollEsi" basename="Login" element={<esiCtrl />} />
          <Route path="/payrollTax" basename="Login" element={<taxCtrl />} />
          <Route path="/pallowances" basename="/Login" element={<PAllowances />} />
          <Route path="/pdeduction" basename="/Login" element={<PDeductions />} />
          <Route path="/grade" basename="/Login" element={<Grade />} />
          <Route path="/actualCollection" basename="/Login" element={<ActualCollection />} />
          <Route path="/AnnualCollection" basename="/Login" element={<AnnualCollection />} />

          <Route path="/timing" basename="/Login" element={<Timing />} />
          <Route path="/periodType" basename="/Login" element={<PeriodType />} />
          <Route path="/counselorSetup" basename="/Login" element={<CounselorSetup />} />
          <Route path="/enq" basename="/Login" element={<Enquiry />} />
          <Route path="/building" basename="/Login" element={<Building />} />
          <Route path="/classStudentManage" basename="/Login" element={<ClassStudentManage />} />
          <Route path="/enqDB" basename="/Login" element={<EnquiryDashboard />} />
          <Route path="/classedit" basename="/Login" element={<Classedit />} />
          <Route path="/classes" basename="/Login" element={<ClassDetail />} />
          <Route path="/academicSession" basename="/Login" element={<AcademicSession />} />
          <Route path="/wings" basename="/Login" element={<Wings />} />
          <Route path="/schoolProfile" basename="/Login" element={<SchoolProfile />} />
          <Route path="/schoolSetup" basename="/Login" element={<SchoolSetupCtrl />} />
          <Route path="/roleSetup" basename="/Login" element={<RoleSetupCtrl />} />
          <Route path="/studentNew" basename="/Login" element={<StudentNew />} />
          <Route path="/student" basename="/Login" element={<Student />} />
          <Route path="/studentDtls" basename="/Login" element={<StudentDetails />} />
          <Route path="/studentDtlsNew" basename="/Login" element={<StudentDetailsNew />} />
          <Route path="/teacher" basename="/Login" element={<Teacher />} />
          <Route path="/teacherdtls" basename="/Login" element={<TeacherDetails />} />
          <Route path="/studentRegisterDtls" basename="/Login" element={<StudentRegisterDetails />} />
          <Route path="/tat" basename="/Login" element={<Tat />} />
          <Route path="/label" basename="/Login" element={<LabelCtrl />} />
          <Route path="/staffctrl" basename="/Login" element={<StaffCtrl />} />
          <Route path="/items" basename="/Login" element={<Items />} />
          <Route path="/itemsnew" basename="/Login" element={<ItemsNew />} />
          <Route path="/suppliers" basename="/Login" element={<Suppliers />} />
          <Route path="/quotes" basename="/Login" element={<Quotes />} />
          <Route path="/registration" basename="/Login" element={<Registration />} />
          <Route path="/registrationFees" basename="/Login" element={<RegistrationFees />} />
          <Route path="/admissionTests" basename="/Login" element={<AdmissionTests />} />
          <Route path="/testConfig" basename="/Login" element={<TestConfigCtrl />} />
          <Route path="/slotConfig" basename="/Login" element={<SlotConfigCtrl />} />
          <Route path="/interviewSlotConfig" basename="/Login" element={<InterviewSlotConfigCtrl />} />
          <Route path="/admissionTestSlot" basename="/Login" element={<AdmissionTestSlot />} />
          <Route path="/testSchedule" basename="Login" element={<TestSchedule />} />
          <Route path="/staffLeaves" basename="Login" element={<StaffLeaves />} />
          <Route path="/studentLeaves" basename="Login" element={<StudentLeaves />} />
          <Route path="/monthlyProcess" basename="Login" element={<PayrollMonthProcessCtrl />} />
          <Route path="/syllabus" basename="Login" element={<Syllabus />} />
          <Route path="/dashboard" basename="Login" element={<PrinciEnqDashboard />} />
          <Route path="/pdashboard" basename="Login" element={<PDashboard />} />
          <Route path="/task" basename="Login" element={<TaskCtrl />} />
          <Route path="/verifyEmail" basename="Login" element={<VerifyEmail />} />
          <Route path="/error" basename="/Login" element={<Error />} />
          <Route path="/payrollSlip" element={<PayrollMonthProcess />} />
          <Route path="/studentTemplate" basename="Login" element={<StudentTemplate />} />
          <Route path="/schoolRegister" basename="Login" element={<SchoolRegister />} />
          <Route path="/academicYear" basename="Login" element={<AcademicYear />} />
          <Route path="/academicSchedule" basename="Login" element={<AcademicSchedule />} />
          <Route path="/content" basename="Login" element={<Content />} />
          <Route path="/campaign" basename="Login" element={<Campaign />} />
          <Route path="/examTimetable" basename="/Login" exact element={<ExamTimeTable />} />
          <Route path="/examSubjects" basename="/Login" exact element={<ExamSubjects />} />
          <Route path="/detailslider" basename="/Login" exact element={<DetailsSlider />} />
          <Route path="/reportCard" basename="/Login" exact element={<ReportCard />} />
          <Route path="/studentReport" basename="/Login" exact element={<StudentReport />} />
          <Route path="/reportCardTemplate" basename="/Login" exact element={<ReportCardTemplate />} />
          <Route path="/parentLogin" basename="/Login" exact element={<ParentAppLogin />} />
          <Route path="/feeHeads" basename="/Login" exact element={<FeeHeads />} />
          <Route path="/feeDue" basename="/Login" exact element={<FeeDue />} />
          <Route path="/feePayment" basename="/Login" exact element={<FeePayment />} />
          <Route path="/feePaymentDetails" basename="/Login" element={<FeePaymentDetails />} />
          <Route path="/feebook" basename="/Login" element={<BillGenerationCtrl />} />

          <Route path="/studentFees" basename="/Login" exact element={<FeeStudents />} />
          <Route path="/studentDiscount" basename="/Login" exact element={<StudentDiscount />} />
          <Route path="/classFee" basename="/Login" exact element={<ClassFee />} />
          <Route path="/feeReport" basename="/Login" exact element={<CollectionSummary />} />
          <Route path="/dailyCollection" basename="/Login" exact element={<DailyCollectionReport />} />
          <Route path="/outstanding" basename="/Login" exact element={<Outstanding />} />
          <Route path="/monthSummary" basename="/Login" exact element={<MonthlySummary />} />
          <Route path="/classSummary" basename="/Login" exact element={<ClassSummary />} />
          <Route path="/studentSummary" basename="/Login" exact element={<StudentSummary />} />

          <Route path="/facility" basename="/Login" exact element={<FacilityCtrl />} />
          <Route path="/tchAllocation" basename="/Login" exact element={<TeacherAllocationCtrl />} />
          <Route path="/subAllocation" basename="/Login" exact element={<SubjectAllocationCtrl />} />
          <Route path="/allocationReports" basename="/Login" exact element={<AllocationReportCtrl />} />
          <Route path="/disruption" basename="/Login" exact element={<DisruptionCtrl />} />
          <Route path="/ttsub" basename="/Login" exact element={<SubjectCtrl />} />
          <Route path="/tttch" basename="/Login" exact element={<TTTeacherCtrl />} />
          <Route path="/clswisesummary" basename="/Login" exact element={<ClassWiseCtrl />} />
          <Route path="/tchwisesummary" basename="/Login" exact element={<TchWiseCtrl />} />
          <Route path="/clssyb" basename="/Login" exact element={<ClsSyllabus />} />
          <Route path="/clslabels" basename="/Login" exact element={<ClassSetupCtrl />} />
          <Route path="/tchsub" element={<TeacherSyllabus />} />
          <Route path="/register" element={<RegistrationCtrl />} />
          <Route path="/admnregister" element={<AdmissionRegsCtrl />} />
          <Route path="/mainscreen" basename="/Login" exact element={<MainScreen />} />
          <Route path="/schlist" basename="/Login" exact element={<SchoolList />} />
          <Route path="/enquiry" basename="/Login" exact element={<EnquiryCtrl />} />
          <Route path="/admn" basename="/Login" exact element={<AdmissionCtrl />} />
          <Route path="/feedback" basename="/Login" exact element={<FeedbackCtrl />} />
          {/* <Route path="/login" basename="/Login" exact element={<LoginOld/>}/> */}
          <Route path="/" basename="/Login" exact element={<Login />} />
          <Route path="/term" basename="/Login" exact element={<TermCtrl />} />
          <Route path="/exam" basename="/Login" exact element={<ExamCtrl />} />
          <Route path="/examSyllabus" basename="/Login" exact element={<ExamSyllabus />} />
          <Route path="/classExamSyllabus" basename="/Login" exact element={<ClassExamSyllabus />} />
          <Route path="/allocate" basename="/Login" exact element={<AllocateCtrl />} />
          {/* <Route path="/evaluate" basename="/Login" exact element={<EvaluateCtrl/>}/>  */}
          <Route path="/examRemarks" basename="/Login" exact element={<ExamRemarks />} />
          <Route path="/exam_evaluate" basename="/Login" exact element={<ExamEvaluateCtrl />} />
          <Route path="/admitCard" basename="/Login" exact element={<AdmitCardCtrl />} />
          <Route path="/validateSummary" basename="/Login" exact element={<ValidateSummary />} />
          <Route path="/progress_report" basename="/Login" exact element={<ProgressReport />} />
          <Route path="/exam_report" basename="/Login" exact element={<ExamReport />} />
          <Route path="/examReportStandalone" basename="/Login" exact element={<ExamReportStandalone />} />
          <Route path="/termReportCard" basename="/Login" exact element={<TermReportCtrl />} />
          <Route path="/daily_attendance" basename="/Login" exact element={<DailyAttendanceCtrl />} />
          <Route path="/monthly_attendance" basename="/Login" exact element={<MonthlyAttendanceCtrl />} />
          <Route path="/yearly_attendance" basename="/Login" exact element={<YearlyAttendanceCtrl />} />
          <Route path="/tchProfile" basename="/Login" exact element={<TeacherProfileCtrl />} />
          <Route path="/setup" basename="/Login" exact element={<SetupCtrl />} />
          <Route path="/subjects" basename="/Login" exact element={<SubjCtrl />} />
          <Route path="/diary" basename="/Login" exact element={<DiaryCtrl />} />
          <Route path="/montlyDiarySummary" basename="/Login" exact element={<MonthlyDiarySummary />} />
          <Route path="/dailyDiarySummary" basename="/Login" exact element={<DailyDiarySummary />} />
          <Route path="/appraisal" basename="/Login" exact element={<AppraisalCtrl />} />
          <Route path="/examsch" basename="/Login" exact element={<ExamScheduleCtrl />} />
          <Route path="/examdashboard" basename="/Login" exact element={<ExamDashboardCtrl />} />
          <Route path="/datesheet" basename="/Login" exact element={<DatesheetCtrl />} />
          <Route path="/tchduty" basename="/Login" exact element={<TchDutyCtrl />} />
          <Route path="/newAcdYr" basename="/Login" exact element={<NewAcademicYrCtrl />} />
          <Route path="/promote" basename="/Login" exact element={<PromoteCtrl />} />
          <Route path="/parentFeedback" basename="/Login" exact element={<PtmFeedbackParentApp />} />
          <Route path="/ptm" basename="/Login" exact element={<PtmCtrl />} />
          <Route path="/ptmFeedback" basename="/Login" exact element={<PtmFeedbackCtrl />} />
          <Route path="/clsPtmFeedback" basename="/Login" exact element={<ClsPtmFeedbackCtrl />} />
          <Route path="/studentPtmFeedback" basename="/Login" exact element={<StudentPtmFeedbackCtrl />} />
          <Route path="/stufeedback" basename="/Login" exact element={<StudentFeedback />} />
          <Route path="/onetimehead" basename="/Login" exact element={<OneTimeHeadCtrl />} />
          <Route path="/studentonetimehead" basename="/Login" exact element={<StudentFeeHeadCtrl />} />
          <Route path="/website" basename="/Login" exact element={<WebsiteCtrl />} />
          <Route path="/books" basename="/Login" exact element={<Books />} />
          <Route path="/issuebook" basename="/Login" exact element={<IssueBookCtrl />} />
          <Route path="/returnbook" basename="/Login" exact element={<ReturnCtrl />} />
          <Route path="/blog" basename="/Login" exact element={<BlogCtrl />} />
          <Route path="/result" basename="/Login" exact element={<ResultCtrl />} />
          <Route path="/info" basename="/Info" exact element={<InfoCtrl />} />

          <Route path="/transferCertificate" basename="/Login" exact element={<TcCtrl />} />
          <Route path="/bonafideCertificate" basename="/Login" exact element={<BonafideCtrl />} />

          <Route path="/wTest" basename="/Login" exact element={<WTestCtrl />} />
          <Route path="/wTestProgress" basename="/Login" exact element={<WTestProgressCtrl />} />
          <Route path="/wTestSchedule" basename="/Login" exact element={<WTestScheduleCtrl />} />
          <Route path="/wAnswersheet" basename="/Login" exact element={<AnswerSheet />} />

          <Route path="/test" basename="/" exact element={<StudentWeeklyTest />} />
          <Route path="/testInstruction" basename="/" exact element={<TestInstruction />} />
          <Route path="/testQuestion" basename="/" exact element={<TestQuestion />} />
          <Route path="/testLeaderBoard" basename="/" exact element={<LeaderBoard />} />
          <Route path="/testFeedback" basename="/" exact element={<TestFeedback />} />

          <Route path="/sa" basename="/Login" exact element={<SALogin />} />
          <Route path="/sa/approveSchools" basename="/Login" exact element={<ApproveSchools />} />
          <Route path="/sa/settlement" basename="Login" element={<SettlementCtrl />} />
          <Route path="/sa/settlementTab" basename="Login" element={<SettlementTab />} />
          <Route path="/sa/apikeys" basename="/Login" element={<SAWebsiteApikeysCtrl />} />
          <Route path="/apikeys" basename="/Login" element={<WebsiteApikeysCtrl />} />

          <Route path="/comm" basename="/Login" exact element={<CommCtrl />} />
          <Route path="/commGroups" basename="/Login" exact element={<CommunicationGroups />} />
          <Route path="/events" basename="/Login" exact element={<EventCtrl />} />
          
          <Route path="/cctv" basename="/Login" exact element={<CctvViolation />} />
        </Routes>
      </Router>
    </WebSocketProvider>
  );
}

export default App;
