import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import 'jquery-ui';
import '../css/registration.css';
import 'common/css/master.css';
import Header from 'common/js/header/header.js';
// import axios from 'axios';
import {  ToastContainer } from 'react-toastify';
// import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker.css';
// import CalenderScreen from 'common/js/calendarScreen';


import SliderType from '../js/sliderType.js';
import RegForm from './regForm.js';
import FeeForm from './feeForm.js';
import MainRegTable from './mainRegTable';
import ScheduleInterview from './scheduleInterview';
import InterviewResult from './interviewResult';
import FeePayment from './feePayment';
import {
    getModeList
    , getClsList
    , getTchList
    , getRegistrationList
    , getFeeData
    , saveFees
    , getSlotTimeList
    , getInterviewScheduleData
    , saveInterviewDtls
} from './registrationServices'
import AssignClassSection from './assignClassSection';
import ErrorTable from './errorTable';
import studentFeeDiscountTable from 'sms/fees/StudentFee/js/studentFeeDiscountTableOld';
// import './sliderForm.css';
let queryString = require('querystring');
/* let lstSlotType = [];



let lstRegistration = [];
let lstGender = [];
let lstSource = [];
let lstStatus = [];
let lstClass = [];
let lstTeacher = [];
let lstSlotsTime = [];
let lstResult = [];
let lstPaymentMode = [];
let regsFeeData = [];
let lstClassSectionData = [];
let lstRegistrationCS = [];
let interviewData = [];
var evaluationDateCheck = "";
let lstErrors = []; 
let tokenid = localStorage.getItem('tokenid');
let slotType = localStorage.getItem('slotType');
*/
function Registration() {

    const [lstRegistration, setLstRegistration] = useState([]);
    const [lstPaymentMode, setLstPaymentMode] = useState([]);
    const [rowData, setRowData] = useState({});
    const [feeData, setFeeData] = useState({});
    const [formFlg, setFormFlg] = useState('')
    const [lstSlotType, setLstSlotType] = useState([]);
    const [lstResult, setLstResult] = useState([]);
    const [lstGender, setLstGender] = useState([]);
    const [lstStatus, setLstStatus] = useState([]);
    const [lstSource, setLstSource] = useState([]);
    const [flg, setFlg] = useState(true);
    const [feesPaidOn, setFeesPaidOn] = useState(null);
    const [evaluationDate, setEvaluationDate] = useState(new Date());
    const [classId, setClassId] = useState(4)
    const [slotType, setSlotType] = useState(20)
    const [classAppliedFor, setClassAppliedFor] = useState('')
    const [scheduleInterviewId, setScheduleInterviewId] = useState(1);
    const [interviewFlg, setInterviewFlg] = useState(true);
    const [interviewDate, setInterviewDate] = useState(null);
    const [lstModes, setLstModes] = useState([]);
    const [lstSlotsTime, setLstSlotsTime] = useState([]);
    const [lstTeacher, setLstTeacher] = useState([]);
    const [dt, setDt] = useState(null);
    const [dob, setDob] = useState(null);
    const [dtls, setDtls] = useState({});
    const [interviewSchDtls, setInterviewSchDtls] = useState({});

    /* handleChangeFeePaid = handleChangeFeePaid.bind(;
    handleChangeDob = handleChangeDob.bind(;
    handelEvaluationDate = handelEvaluationDate.bind(;
    handleChangeFeePayment = handleChangeFeePayment.bind(; */

    const handleChangeFeePaid = (date) => {
        setFeesPaidOn(date);
    }
    const handleChangeFeePayment = (date) => {
        setFeesPaidOn(date);
    }

    const handleChangeDob = (date) => {
        setDob(date);
    }
    const handelEvaluationDate = (date) => {
        setEvaluationDate(date);
    }

    useEffect(() => {
        getRegistrationList(handleRegListResponse);

        $('.Registration').show();
        $('#errorButton').hide();
        document.body.addEventListener('click', myHandler);

        setLstSlotType([{
            'slotId': 10, 'slotType': 'Test Slot'
        }, {
            'slotId': 20, 'slotType': 'Interview Slot'
        }]);

        setLstResult([{
            'resultId': 1, 'result': 'Pass'
        }, {
            'resultId': 2, 'result': 'Fail'
        }]);

        setSlotType(10);
        getModeList(handlePaymentModeListResponse);
        /* fillSlotTypeCmb(slotType);
        fillResultsCmb();
        fillPaymentModeCmb(); */
        /* $('#genderCmb').prepend($("<option> </option>").val(0).html('Gender'));
        $('#sourceCmb').prepend($("<option> </option>").val(0).html('Registration Type'));
        $('#statusCmb').prepend($("<option> </option>").val(0).html('Status'));
        $('#classAppliedCmb').prepend($("<option> </option>").val(0).html('Class'));
        $('#assignToCmb').prepend($("<option> </option>").val(0).html('Assign To')); */
        setLstGender([{ 'genderId': '1', 'gender': 'M' }, { 'genderId': '2', 'gender': 'F' },
        { 'genderId': '3', 'gender': 'O' },
        ]);
        // fillGenderCmb();

        setLstStatus([{ 'statusId': 10, 'status': 'Fee Pending' }, { 'statusId': 20, 'status': 'Fee Paid' },
        { 'statusId': 30, 'status': 'Test Scheduled' }, { 'statusId': 40, 'status': 'Test Completed' },
        { 'statusId': 50, 'status': 'Interview Scheduled' }, { 'statusId': 60, 'status': 'Evaluation Completed' },
        { 'statusId': 70, 'status': 'Class Section Assigned' },
        ]);
        /* fillStatusCmb();
        fillFilterStatusCmb(); */
        setLstSource([{ 'sourceId': 10, 'source': 'Online' }, { 'sourceId': 20, 'source': 'Walk In' },
        { 'sourceId': 30, 'source': 'Phone' }, { 'sourceId': 40, 'source': 'Event' },
        { 'sourceId': 50, 'source': 'Customer Care' },
        ]);
        /* fillEnquiryTypeCmb();
        fillFilterEnquiryCmb(); */
        getRegistrationList();
        return () => {
            document.body.removeEventListener('click', myHandler);
        }
    }, [])
    /* componentWillUnmount = () => {
        document.body.removeEventListener('click', myHandler);
    } */

    function handleRegListResponse(data) {
        setLstRegistration(data)
    }
    function handleClsListResponse(data) {

    }
    function handlePaymentModeListResponse(data) {
        setLstPaymentMode(data)
    }
    function myHandler() {
        $('.ulStyle1').removeClass('showUl');
    }

    const getWingId = (wingId) => {
        // // console.log("wingId" + wingId);
    }
    function setCallback(flg, dtls) {
        setFlagCallback(flg, dtls);
        // // console.log(dtls);
        setDtls(dtls);
        if (flg === SliderType.SLIDER_SCH_INT) {
            setTchList();
            getInterviewScheduleData(dtls.studentId, handleInterviewDtlsResponse);
        }
    }
    function handleInterviewDtlsResponse(data) {
        if (data.SvcStatus === "Success") {
            setInterviewSchDtls(data.interviewData);
        }
    }
    function setTchList() {
        getTchList(handleTchListResponse);
    }
    function handleTchListResponse(lstTeacher) {
        setLstTeacher(lstTeacher);
    }
    function setFlagCallback(flg, dtls) {
        // // console.log(flg);
        getFeeData(dtls.studentId, handleFeeResponse);
        setFormFlg(flg);

    }
    function handleFeeResponse(regsFeeData) {
        setFeeData(regsFeeData)
    }
    function addInterview(schedule, studentId) {
        saveInterviewDtls(schedule, studentId, handleSaveInterviewResponse);
    }
    function handleSaveInterviewResponse(data) {
        if (data.SvcStatus === "Success") {
            getRegistrationList();
        }
    }
    function closeSlider() {
        document.querySelector('body').classList.remove('openSlider');
    }
    function saveFeesData(regFees) {
        saveFees(regFees, handleFeeSaveResponse);
    }
    function handleFeeSaveResponse(data) {
        getRegistrationList(handleRegListResponse);

    }
    function getSlotList(classId, slotType, dt) {
        getSlotTimeList(classId, slotType, dt, handleSlotTmListResponse)
    }
    function handleSlotTmListResponse(slotListData) {
        // // console.log(slotListData);
        setLstSlotsTime(slotListData);
    }
    function setSchDtls(data) {
        setInterviewSchDtls(data);
    }
    return (
        <div style={{ height: '100%' }}>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: 'none' }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="mainContainer" >
                <div className=" Registration clearfix" >
                    <div className="col-md-11 col-centered">
                        <MainRegTable lstSource={lstSource} lstStatus={lstStatus} lstRegistration={lstRegistration}
                            setFlgCallback={setCallback} />
                        <div className="col-xs-1 ">
                            <span id="addRegisration" title="Add Registration" className="addIcon glyphicon glyphicon-plus-sign"></span>
                        </div>
                        <div className="col-sm-5 col-xs-8 padding-remove slider fullWidthSlider" >
                            {formFlg === SliderType.SLIDER_FEES &&
                                <FeeForm
                                    lstPaymentMode={lstPaymentMode}
                                    feeDtls={feeData}
                                    saveFees={saveFeesData} />}
                            {formFlg === SliderType.SLIDER_SCH_INT &&
                                <ScheduleInterview
                                    studentId={dtls.studentId}
                                    interviewerId={"4"}
                                    classId={classId}
                                    slotType={slotType}
                                    interviewDate={dtls.interviewDate}
                                    schData={interviewSchDtls}
                                    getSlotList={getSlotList}
                                    lstSlotsTime={lstSlotsTime}
                                    lstTeacher={lstTeacher}
                                    addInterview={addInterview}
                                    handleSchChange={setSchDtls} />}
                            {/* {formFlg === SliderType.SLIDER_EDT_DTLS
                                &&
                                <RegForm
                                    lstGender={lstGender}
                                    lstClass={lstClass} /> */}
                            {/* <RegForm />
                            
                            <InterviewResult />
                            <FeePayment />
                            <AssignClassSection /> */}
                            <button type="button" className="btn btn-info text-center " id="closeSlider" onClick={closeSlider}>
                                <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-md-9 popupTable">
                    <div className="col-xs-12 padding-remove">
                        <h4>Errors</h4>
                        <ErrorTable />
                        <div className="col-xs-12 text-center">
                            <button type="button" title="Close" className="btn btn-info">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default Registration;