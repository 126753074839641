import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';

import Header from 'common/js/header/header';
import 'common/css/master.css';
import Loader from "common/components/Loader";

import TermHeader from './termHeader';
import TermReportSlider from './termReportSlider';
import ClassTable from './classTable';
import { getClassData } from './termReportSvc';


const TermReportCtrl = () => {
    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [sectionId, setSectionId] = useState(0);
    const [termId, setTermId] = useState(0);
    const [term, setTerm] = useState('');
    const [flgSliderVisible, setFlgSliderVisible] = useState(false);
    const [syllabusId, setSyllabusId] = useState(0);
    const [lstClassSection, setLstClassSection] = useState([]);

    useEffect(() => {
        // // console.log("useEffect called termReportCardCtrl called");
        setClassList();
    }, []);


    function setClassList() {
        setLoader("block");
        setOpacity(0.5);
        getClassData(handleClassListResponse)
    }

    function handleClassListResponse(data) {
        var obj = data;
        setOpacity(1);
        setLoader("none");
        // // console.log('lstAllClassSection =' + obj.lstAllClassSections)
        if (obj.status === 'Success') {
            setLstClassSection(obj.lstAllClassSections)
        } else if (obj.status === 'Failure') {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = '/'
                }, 2000)
                return false
            } else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = '/'
                }, 2000)
                return false
            } else {
                toast.error(obj.message)
                return false
            }
        } else {
            toast.error('Service failed ' + obj)
        }
    }

    function closeSlider() {
        setFlgSliderVisible(false);
    }

    function openSlider() {
        setFlgSliderVisible(true);
    }

    const viewReportCard = async (pClsSecId, pSyllabusId) => {
        // // console.log("viewReportCard method called, pClsSecId", pClsSecId, ", pSyllabusId", pSyllabusId);
        await setSectionId(pClsSecId);
        await setSyllabusId(pSyllabusId);
        if(!termId){
            toast.warn("please select a term");
            return;
        }
        openSlider();
    }

    async function termClick(pTermId, pTerm) {
        // // console.log("termClick called, pTermId", pTermId, ", pTerm", pTerm);
        await setTermId(pTermId);
        await setTerm(pTerm);
    }

    return (
        <div >
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <Loader loader={loader} position="fixed" />
            <div className="col-md-12 col-xs-12 col-centered padding-remove"
                style={{
                    cursor: (loader === "none") ? 'pointer' : 'none',
                    pointerEvents: (loader === "none") ? 'auto' : 'none',
                    opacity: opacity
                }}>
                <div className="col-md-12 col-centered wingsWrap clearfix">
                    <TermHeader termId={termId} termClick={termClick} />
                </div>
                <div className="col-md-10 col-sm-10 col-centered padding-remove" style={{ marginTop: "40px" }}>
                    <ClassTable
                        lstClsSection={lstClassSection}
                        sectionId={sectionId}
                        setSectionId={setSectionId}
                        viewReportCard={viewReportCard}
                    />
                </div>
            </div>
            {!!flgSliderVisible ? (
                <div className='slider1-70'>
                    <TermReportSlider
                        sectionId={sectionId}
                        termId={termId}
                        term={term}
                        syllabusId={syllabusId}
                        closeSlider={closeSlider}
                    />
                </div>
            ) : null
            }
        </div>
    );

}

export default TermReportCtrl;