import axios from 'axios';
import lstModules from 'common/json/lstModules';

var sysopstokenid = localStorage.getItem("sysopstokenid");
let httpURLCommon = lstModules['main'].url + ":" + lstModules["main"].port;


function getLstSettlementSvc(pSchoolId, cb) {
    var obj = { status: "Success", message:"" };

    axios({
        url: httpURLCommon + "/sms/sysops/settlement/list",
        method: "POST",
        headers: { tokenid: sysopstokenid },
        params: { schoolId: pSchoolId },
        withCredentials: true,
    }).then((response) => {
        obj.message = response.data.SvcMsg;
        obj.status = response.data.SvcStatus;
        obj.lstSettlement = response.data.lstSettlement;
        cb(obj);
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        cb(obj)
    })

}

export { getLstSettlementSvc };