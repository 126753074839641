
import React, { useEffect, useState } from 'react';
import { Document, Page, PDFViewer, Text, View, Image, Font } from '@react-pdf/renderer';
import { StyleSheet } from '@react-pdf/renderer';

import { toast } from "react-toastify";
import { styles, scoreSectionStyle } from './examReportCardStyles.js';
import ScholasticSection from './schoSection.js';
import CoScholasticSection from './coSchoSection.js';
import SummarySection from './summarySection.js';

Font.register({
    family: 'Calibri'
    , fonts: [{ src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibri.ttf" }
        , { src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibrib.ttf", fontWeight: "bold" }], format: 'truetype'
});


const ScoreSection = (props) => {
    const { scoreSection, exam } = props;

    useEffect(async () => {
        // // console.log("scoreSection.js called");

    }, [scoreSection, exam]);


    return (
        <View style={scoreSectionStyle.container}>
            <View style={scoreSectionStyle.scho_section_header}>
                <Text style={scoreSectionStyle.section_header_text}>SCHOLASTIC AREAS</Text>
            </View>
            <View style={scoreSectionStyle.marks_section}>
                <View style={scoreSectionStyle.scho_section}>
                    <ScholasticSection
                        lstSchoScore={scoreSection.lstSchoScore}
                        exam={exam}
                        examSchoSummary={scoreSection.examSchoSummary}
                    />
                    {/* </View>
                <View style={scoreSectionStyle.summary_section}> */}
                    <SummarySection
                        examSchoSummary={scoreSection.examSchoSummary}
                        />
                </View>
                <View style={scoreSectionStyle.coscho_section}>
                    <CoScholasticSection
                        lstCoschoScore={scoreSection.lstCoschoScore}
                        exam={exam}
                    />
                </View>
            </View >
        </View >
    );
}

export { ScoreSection };