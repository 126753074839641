import React, { useState, useEffect } from 'react'

const SetupHeader = props => {
  const [lstSetup, setLstSetup] = useState([
    /* { setupId: 0, tabName: 'General' },
    { setupId: 1, tabName: 'Fee' },
    { setupId: 2, tabName: 'Timetable' },
    { setupId: 3, tabName: 'Performance' },
    { setupId: 4, tabName: 'Payroll' } */
  ])
  const [setupId, setSetupId] = useState(0)

  useEffect(() => {
    // // console.log(props.setupId)
    setSetupId(props.setupId)
    setLstSetup(props.lstSetup)
  }, [props.setupId, props.lstSetup])
  return (
    <div>
      <div
        className='row'
        style={{
          boxShadow: '#ccc 0px 1px 4px 0px',
          borderWidth: '1px 0px',
          borderStyle: 'solid',
          height: '50px',
          borderColor: '#ccc',
          padding: '5px 20px',
          height: 'auto'
        }}
      >
        <div className='tab'>
          {lstSetup &&
            lstSetup.map((obj, idx) => {
              var isSelected = idx === setupId
              return (
                <div
                  id={'tabName' + idx}
                  className={isSelected ? 'sellected' : ''}
                  onClick={e => props.setSetupId(idx)}
                  style={{
                    pointerEvents: obj.setupCompleted ? 'auto' : 'none',
                    opacity: obj.setupCompleted ? 1 : 0.4
                  }}
                  key={idx}
                >
                  <span hidden>{idx}</span>
                  <span>{obj.tabName}</span>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default SetupHeader
