import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { getBorrowerBookListSvc, saveReturnBookSvc } from "./returnBooksSvc";

import Loader from "common/components/Loader";

export function ReturnDtls(props) {
  // // console.log(props);
  // const [returnDt, setReturnDt] = useState(null);
  const [totalFine, setTotalFine] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [totalSecurityDeposit, setTotalSecurityDeposit] = useState(0);
  const [paramLstBook, setParamLstBook] = useState([]);
  const [isFineEditable, setIsFineEditable] = useState(false);

  

  useEffect(() => {
    
    getBorrowerBookList();
   
    setIsFineEditable(props.lstBookCopyNo.length === 1);
    setTotalSecurityDeposit(props.totalSecurityDeposit);
  }, [ totalFine,
    props.lstBookCopyNo,
    props.totalSecurityDeposit,
    props.staffId,
    props.studentId,
    props.flgStaff,
    props.returnDt,
  ]);

  const handleTotalFine = (lstBook) => {
    var dailyFine = props.flgStaff ? props.staffFine : props.studentFine;
    // // console.log("flag staff",props.flgStaff);
    // // console.log("student fine",props.studentFine);
    // // console.log("staff fine",props.staffFine);
    // // console.log("daily fine",dailyFine);
    var ttlFine = 0;
    lstBook.forEach((book) => {
        var dueDt = getDateObj(book.dueDt);
        // // console.log("dueDt",dueDt);
        // // console.log("props.returnDt",props.returnDt);
        const differenceInTime = props.returnDt.getTime() - dueDt.getTime();
        // // console.log("diffence in time", differenceInTime);
        var differenceInDays = differenceInTime / (1000 * 3600 * 24);
        // // console.log("diffence in days", differenceInDays);
        if (differenceInDays < 0)
          differenceInDays = 0;
        const bookFine = dailyFine * differenceInDays;
        // // console.log("bookFine",bookFine);
        ttlFine = ttlFine + bookFine;
        // // console.log("ttlFine",ttlFine);
      })
    // // console.log("total fine calculated",ttlFine)
    setTotalFine(ttlFine);
  }

  

  const handleFineChange = (e) => {
    setTotalFine(e.target.value);
    // // console.log("handleFineChange paramLstBook", paramLstBook);
    if (!!paramLstBook) {
      // // console.log("paramLstBook", paramLstBook);
      if (paramLstBook.length === 1) {
        // // console.log("paramLstBook.legth", paramLstBook.legth);
        var tmpLstBook = [...paramLstBook];
        tmpLstBook[0] = { ...tmpLstBook[0], "fine": (e.target.value) };
        setParamLstBook(tmpLstBook);
      }
      else {
        toast("can't change fine for multiple books");
      }
    }
  }

  const getDateObj = (dt) => {
    const [dtDay, dtMonth, dtYear] = dt.split('-');
    const dtObj = new Date(dtYear, dtMonth - 1, dtDay);
    return dtObj;
  }

  // function getDateForCalendar(strDt) {
  //   if (!strDt) return null;
  //   var parts = strDt.split("-");
  //   var d = parts[0];
  //   var m = parts[1] - 1;
  //   var y = parts[2];

  //   return new Date(y, m, d);
  // }
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join("-");
  }

  function returnBook() {
    // props.setSliderLoader("block");
    // props.setLoader("block");

    var returnObj = {
      returnDttm: convert(props.returnDt),
      // returnDttm: new Date(),
      returnRemarks: remarks,
      lstBook: paramLstBook,
    };
    saveReturnBookSvc(returnObj, returnBookCallback);
  }
  function getBorrowerBookList() {
    getBorrowerBookListSvc(
      props.staffId,
      props.studentId,
      props.flgStaff,
      props.lstBookCopyNo,
      borrowerBookListCallback
    );
  }
  function returnBookCallback(data) {
    // props.setSliderLoader("none");
    props.setLoader("none");
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
      props.setSliderDisplayed(0);
    } else {
      if (data.SvcMsg === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (data.SvcMsg === "Your Session is expired, login again") {
        toast.error(data.SvcMsg);
        // // console.log("session expired");
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(data.SvcMsg);
      }
    }
  }

  function borrowerBookListCallback(data) {
    var tmpLstBook = [];
    // props.setSliderLoader("none");
    props.setLoader("none");
    if (data.SvcStatus === "Success") {
      props.setLstBook(data.lstBook);
      handleTotalFine(data.lstBook);
      // // console.log("lstBook set in ReturnDtls", data.lstBook);
      data.lstBook.forEach((element) => {
        tmpLstBook.push({
          transactionId: element.transactionId,
          fine: element.fine,
          securityReturn: element.securityDeposit,//element.securityDeposit,
          bookNo: element.bookNo,
          copyNo: element.copyNo,
        });
      });
      setParamLstBook(tmpLstBook);
    } else {
      if (data.SvcMsg === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (data.SvcMsg === "Your Session is expired, login again") {
        toast.error(data.SvcMsg);
        // // console.log("session expired");
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(data.SvcMsg);
      }
    }
  }

  const handleSecurityDepositChange = (e) => {
    setTotalSecurityDeposit(e.target.value);
    // // console.log("handleSecurityDepositeChange paramLstBook", paramLstBook);
    if (!!paramLstBook) {
      // // console.log("paramLstBook", paramLstBook);
      if (paramLstBook.length === 1) {
        // // console.log("paramLstBook.legth", paramLstBook.legth);
        var tmpLstBook = [...paramLstBook];
        tmpLstBook[0] = { ...tmpLstBook[0], "securityReturn": (e.target.value) };
        setParamLstBook(tmpLstBook);
      }
      else {
        toast("can't change security deposite for multiple books");
      }
    }
  }


  return (
    <form
      className="form form-horizontal"
      style={{
        width: "100%",
        margin: "0px 15px",
        background: "#fff",
        padding: 10,
        // pointerEvents: sectionLoader === "none" ? "auto" : "none",
      }}
    >
      <Loader loader={props.loader} position="fixed" />
      {/* <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          // display: sectionLoader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div> */}
      <div className="form-group">
        <label className="control-label col-sm-2">
          Return Date:
        </label>
        <div className="col-sm-2">
          <DatePicker
            className="form-control text-center"
            placeholderText="Return Date"
            selected={props.returnDt}
            dateFormat="dd-MM-yyyy"
            onChange={(date) => props.handleChangeReturnDt(date)}
            autoComplete="off"
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
          />
        </div>
        <label className="control-label col-sm-2" >
          Fine Collected:
        </label>
        <div className="col-sm-2">
          <input
            autoComplete="off"
            type="number"
            className="form-control text-right"
            // defaultValue={totalFine}
            value={totalFine}
            onChange={(e) => handleFineChange(e)}
            placeholder="Fine Collected"
            disabled={!isFineEditable}
          />
        </div>
      </div>
      <div className="form-group">

        <label className="control-label col-sm-2" >
          Security Deposit:
        </label>
        <div className="col-sm-2">
          <input
            autoComplete="off"
            type="text"
            className="form-control text-right"
            value={totalSecurityDeposit}
            onChange={(e) => handleSecurityDepositChange(e)}
            placeholder="Security Deposit"
            disabled={!isFineEditable}
          />
        </div>
        <label className="control-label col-sm-2" >
          Remarks:
        </label>
        <div className="col-sm-3">
          <input
            autoComplete="off"
            type="text"
            className="form-control text-left"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            placeholder="Remarks"
          // disabled={!isFineEditable}
          />
        </div>
        <div className="col-sm-12">
          <div className="form-group col-sm-4">
            <button
              type="button"
              className="btn btn-info col-sm-6 pull-right"
              onClick={() => returnBook()}
            >
              Return Book
            </button>
          </div>
        </div>
      </div >
    </form >
  );
}
