import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { getLstAllocationSvc, saveAllocationSvc, teachersList } from './AllocationTableSvc.js'
import '../css/allocateTable.css'

var lstTmpAllocation = []
const AllocationTable = (props) => {

  const [filterSubject, setFilterSubject] = useState('')
  const [filterClass, setFilterClass] = useState('')
  const [filterSection, setFilterSection] = useState('')
  const [filterSubjectType, setFilterSubjectType] = useState('')
  const [filterAllotedTo, setFilterAllotedTo] = useState('')
  const [lstAllocation, setLstAllocation] = useState([])
  const [lstTeacher, setLstTeacher] = useState([])
  const [allocationId, setAllocationId] = useState('')
  const [examId, setExamId] = useState('')
  const [clsId, setClsId] = useState('')
  const [subId, setSubId] = useState('')
  const [allocatedToId, setallocatedToId] = useState('')
  const [expHrs, setExpHrs] = useState('')
  const [sectionId, setSectionId] = useState('')
  const [foundLst, setFoundLst] = useState([]);

  useEffect(() => {
    setExamId(props.examId);
    getLstAllocation(props.examId)
    teachersList(cbTchListResponse)
  }, [props.examId])

  function rowClick(e, obj, idx) {
    e.preventDefault();
    setAllocationId(obj.allocationId)
    setClsId(obj.clsId)
    setSectionId(obj.sectionId)
    setSubId(obj.subjectId)
    props.onSetSlider(obj, idx, false)
  }

  function getLstAllocation(examId) {
    getLstAllocationSvc(cbLstAllocationResponse, examId)
  }

  function cbLstAllocationResponse(obj) {
    if (obj.status === "Success") {
      setLstAllocation(obj.lstAllocation)
      setFoundLst(obj.lstAllocation)
    } else if (obj.status === 'Failure') {
      if (obj.message === 'You are not logged in') {
        setTimeout(function () { window.location.href = ('/') }, 2000);
        return false;
      } else if (obj.message === 'Your Session is expired, login again') {
        setTimeout(function () { window.location.href = ('/') }, 2000);
        return false
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed " + obj);
    }


  }
  function cbTchListResponse(obj) {
    if (obj.status === "Success") {
      setLstTeacher(obj.lstTch);
    } else if (obj.status === 'Failure') {
      if (obj.message === 'You are not logged in') {
        setTimeout(function () { window.location.href = ('/') }, 2000);
        return false;
      } else if (obj.message === 'Your Session is expired, login again') {
        setTimeout(function () { window.location.href = ('/') }, 2000);
        return false
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed " + obj);
    }
  }
  function saveAllocation() {
    if (!expHrs || expHrs === '0') {
      toast.warn('Please enter the Expected Time');
      return;
    }
    if (!allocatedToId) {
      toast.warn('Please select the teacher to allocate');
      return;
    }
    var state = {};
    state.allocationId = allocationId;
    state.clsId = clsId;
    state.sectionId = sectionId
    state.subId = subId;
    if (lstTmpAllocation.length === 0) {
      toast.warn("Please select atleast one row");
      return;
    }
    saveAllocationSvc(props.examId, expHrs, allocatedToId, lstTmpAllocation, cbsaveAllocationResponse)
  }

  function cbsaveAllocationResponse(obj) {
    if (obj.status === "Success") {
      toast.success(obj.message)
      props.generateRandomNo();
    } else if (obj.status === 'Failure') {
      if (obj.message === 'You are not logged in') {
        setTimeout(function () { window.location.href = ('/') }, 2000);
        return false;
      } else if (obj.message === 'Your Session is expired, login again') {
        setTimeout(function () { window.location.href = ('/') }, 2000);
        return false
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed " + obj);
    }
  }

  const filterCls = async (e) => {
    e.persist()
    if (document.getElementById('cbxSelectAll').checked) {
      document.getElementById('cbxSelectAll').click()
    }
    const keyword = e.target.value.toLowerCase();
    await setFilterClass(keyword);
    if (e.keyCode === 8) {
      document.getElementById('cbxSelectAll').checked = false;
    }
    filterFn()
  };

  const filterSec = async (e) => {
    e.persist()
    if (document.getElementById('cbxSelectAll').checked) {
      document.getElementById('cbxSelectAll').click()
    }
    const keyword = e.target.value.toLowerCase();
    await setFilterSection(keyword);
    if (e.keyCode === 8) {
      document.getElementById('cbxSelectAll').checked = false;
    }
    filterFn()
  };

  const filterSub = async (e) => {
    e.persist()
    if (document.getElementById('cbxSelectAll').checked) {
      document.getElementById('cbxSelectAll').click()
    }
    const keyword = e.target.value.toLowerCase();
    await setFilterSubject(keyword);
    if (e.keyCode === 8) {
      document.getElementById('cbxSelectAll').checked = false;
    }
    filterFn()
  };

  const filterSubType = async (e) => {
    e.persist()
    if (document.getElementById('cbxSelectAll').checked) {
      document.getElementById('cbxSelectAll').click()
    }
    const keyword = e.target.value.toLowerCase();
    await setFilterSubjectType(keyword);
    if (e.keyCode === 8) {
      document.getElementById('cbxSelectAll').checked = false;
    }
    filterFn()
  };

  const filterAllocatedTo = async (e) => {
    e.persist()
    if (document.getElementById('cbxSelectAll').checked) {
      document.getElementById('cbxSelectAll').click()
    }
    const keyword = e.target.value.toLowerCase();
    if (e.keyCode === 8) {
      document.getElementById('cbxSelectAll').checked = false;
    }
    await setFilterAllotedTo(keyword);
    filterFn()
  };

  function filterFn() {
    var tmpLst = []
    for (var i = 0; i < lstAllocation.length; i++) {
      var obj = lstAllocation[i]
      if (filterClass !== '' && filterClass !== obj.cls.toLowerCase()) {
        continue;
      }
      if (filterSection !== '' && !obj.section.toLowerCase().includes(filterSection)) {
        continue;
      }
      if (filterSubject !== '' && !obj.subject.toLowerCase().includes(filterSubject)) {
        continue;
      }
      if (filterSubjectType !== '' && !obj.subType.toLowerCase().includes(filterSubjectType)) {
        continue;
      }
      if (filterAllotedTo !== '' && !obj.allocatedTo.toLowerCase().includes(filterAllotedTo)) {
        continue;
      }
      tmpLst.push(obj)

    }
    setFoundLst(tmpLst)
  }

  const colgrp = <colgroup>
    <col width="4%" />
    <col width="5%" />
    <col width="9%" />
    <col width="9%" />
    <col width="8%" />
    <col width="9%" />
    <col width="8%" />
    <col width="8%" />
    <col width="6%" />
    <col width="4%" />
    <col width="4%" />
  </colgroup>

  const selectAll = (e) => {
    var list_cbox = document.querySelectorAll('#allocationTableBody td input[type=checkbox]');
    list_cbox.forEach((cbox) => { if (cbox.checked !== e.target.checked) cbox.click(); })
  }

  function handleCboxChange(e, obj, status) {
    e.stopPropagation()
    if (status) {
      lstTmpAllocation.push({
        allocationId: obj.allocationId,
        clsId: obj.clsId,
        sectionId: obj.sectionId,
        subId: obj.subjectId
      })
    }
    else {
      var idx = existsIn(obj.clsId, obj.subjectId, obj.sectionId, lstTmpAllocation);
      lstTmpAllocation.splice(idx, 1)
    }
  }

  function existsIn(clsId, subId, sectionId, arr) {
    var idx = -1;
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].subId === subId && arr[i].clsId === clsId && arr[i].sectionId === sectionId) {
        idx = i;
        break;
      }
    }
    return idx;
  }
  return (
    <div className='container'>
      {examId !== 0 && <div style={{ marginTop: '15px' }} >
        <div className="col-md-1  col-xs-1 pull-right" onClick={props.openNewSlider}>
          <span className="addIcon glyphicon glyphicon-plus-sign" ></span></div>

        <div className="col-md-11">
          <table className="tableLayout1 ">
            {colgrp}
            <thead>
              <tr>
                <th>
                  <input type="text" defaultValue={filterClass} className="form-control inp-search" placeholder="Class" onChange={(e) => filterCls(e)} onKeyUp={(e) => filterCls(e)} />
                </th>
                <th>
                  <input type="text" defaultValue={filterSection} className="form-control inp-search" placeholder="Section" onChange={(e) => filterSec(e)} onKeyUp={(e) => filterSec(e)} />
                </th>
                <th>
                  <input type="text" defaultValue={filterSubject} className="form-control inp-search" placeholder="Subject" onChange={(e) => filterSub(e)} onKeyUp={(e) => filterSub(e)} />
                </th>
                <th >
                  <input type="text" defaultValue={filterSubjectType} className="form-control inp-search" placeholder="Type" onChange={(e) => filterSubType(e)} onKeyUp={(e) => filterSubType(e)} />
                </th>
                <th>Expected Time</th>
                <th >
                  <input type="text" defaultValue={filterAllotedTo} className="form-control inp-search" placeholder="Allocated To" onChange={(e) => filterAllocatedTo(e)} onKeyUp={(e) => filterAllocatedTo(e)} />
                </th>
                <th >Allocated On</th>
                <th >Evaluated On</th>
                <th>Verified On</th>
                <th>Select<br />
                  <div>
                    <input onChange={(e) => selectAll(e)} type="checkbox" id="cbxSelectAll" />
                  </div></th>
                <th style={{ width: 50 }}>Action</th>
              </tr>
            </thead>
          </table>
          <div className='tablebodyWrapper'>
            <table>
              {colgrp}
              <tbody id="allocationTableBody">
                {foundLst.map((obj, idx) => (
                  <tr
                    className={idx === props.selIdx ? 'selected' : ''}
                    key={idx}
                    onClick={(e) => rowClick(e, obj, idx)}>
                    <td>{obj.cls}</td>
                    <td>{obj.section}</td>
                    <td className="text-left">{obj.subject}</td>
                    <td className="text-left">{obj.subType}</td>
                    <td>{obj.expectedTime}</td>
                    <td className="text-left">{obj.allocatedTo}</td>
                    <td>{obj.allocatedOn}</td>
                    <td>{obj.evaluateOn}</td>
                    <td>{obj.verifyOn}</td>
                    <td onClick={(e) => { e.stopPropagation(); e.target.firstChild.click() }}>
                      <input key={obj.clsId + "_" + obj.sectionId + "_" + obj.subjectId} id={obj.clsId + "_" + obj.sectionId + "_" + obj.subjectId} onClick={(e) => handleCboxChange(e, obj, e.target.checked)}
                        type="checkbox" defaultChecked={false}
                      />
                    </td>
                    <td width="100" className="text-center">
                      <span onClick={(e) => { e.stopPropagation(); props.onSetSlider(obj, idx, true) }}
                        className="glyphicon glyphicon-edit">
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="form form-horizontal" >
          <div className="col-md-8 col-md-offset-2" style={{ border: '1px solid #ccc', paddingTop: 10 }}>
            <div className="form-group">
              <label className="control-label col-sm-2">Expected Time<span className="red-color">*</span></label>
              <div className='col-sm-2 padding-remove'>
                <input type='text' className="form-control" onChange={(event) => setExpHrs(event.target.value)} ></input>
              </div>
              <label className="control-label col-sm-2">Teacher<span className="red-color">*</span></label>
              <div className='col-sm-5 padding-remove'>
                <div className="form-group col-sm-5">
                  <select className="form-control" value={allocatedToId} onChange={(event) => setallocatedToId(event.target.value)} >
                    <option value={0}>Teacher</option>
                    {lstTeacher && lstTeacher.length !== 0 && lstTeacher.map((obj, idx) => {
                      return <option key={idx} value={obj.tchId}>{obj.tch}</option>
                    })}
                  </select>
                </div>
                <div className="col-sm-3">
                  <button type="button" className="btn btn-info " id="assignCounsellor " onClick={() => saveAllocation()} >Assign</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      }</div>
  );
}

export default AllocationTable