import React, { useState, useEffect } from 'react'
import { getTaskListSvc, saveTaskSvc } from './taskSvc'

const TaskTable = props => {
  const [loader, setLoader] = useState('none')

  const [taskId, setTaskId] = useState(props.taskId)
  const [lstTask, setLstTask] = useState([])
  useEffect(() => {
    setLstTask(props.lstTask)
  }, [props])

  const colgrp = (
    <colgroup>
      <col width='10%'></col>
      <col width='40%'></col>
      <col width='10%'></col>
      <col width='10%'></col>
      <col width='10%'></col>
      <col width='20%'></col>
    </colgroup>
  )
  return (
    <div style={{ pointerEvents: loader === 'none' ? 'auto' : 'none' }}>
      <table className='tableLayout1'>
        {colgrp}
        <thead>
          <tr>
            <th>Due Date</th>
            <th>Task</th>
            <th>Assigned To</th>
            <th>Completed</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
      </table>
      <div className='tablebodyWrapper'>
        <table className='tableLayout'>
          {colgrp}
          <tbody>
            {lstTask.map((obj, idx) => {
              return (
                <tr
                  key={idx}
                  route-id={taskId}
                  stop-id={obj.taskId}
                  id={obj.taskId}
                  onClick={e => props.setTaskId(obj.taskId)}
                  className={obj.taskId === props.taskId ? 'selected' : ''}
                >
                  <td className='no-display'>{obj.taskId}</td>
                  <td>{obj.dueDt}</td>
                  <td className='text-left'>{obj.task}</td>
                  <td className='text-left'>{obj.assignedTo}</td>
                  <td className='text-left'>{obj.pctCompleted}%</td>
                  <td className='text-left'>{obj.status}</td>
                  <td className='text-center'>
                    <span
                      onClick={e => props.openTaskSlider(obj.taskId, obj)}
                      className='glyphicon glyphicon-edit'
                    ></span>
                    <span
                      onClick={e => props.deleteTask(obj.taskId)}
                      className='glyphicon glyphicon-trash'
                    ></span>
                    <span
                      onClick={e => props.completeTask(obj.taskId)}
                      className='glyphicon glyphicon-ok'
                    ></span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TaskTable
