
import { StyleSheet } from '@react-pdf/renderer';


const innerBorderWidth = ".60px";
const tablePartitionWidth = ".60px";

const pageStyle = StyleSheet.create({
    page: {
        // backgroundColor: "#fff",
        color: "white",
        display: 'flex',
        flexDirection: 'column',
        padding: 20
    },
    page_border: {
        height: '100%',
        width: '100%',
        border: 1,
        borderColor: "#000",
        // backgroundColor:"red"
    },
    header_section: {
        // height: '25%',
        flex: 5,
        // backgroundColor: 'yellow'
    },
    student_section: {
        // height: '15%',
        flex: 3,
        // backgroundColor: 'orange'
    },
    score_section: {
        // height: '50%',
        flex: 10,
        // backgroundColor: 'yellow'
    },
    footer_section: {
        // height: '10%',
        flex: 2,
        // backgroundColor: 'orange'
    },

    header_logo: {
        // height: '45%',
        flex: 9,
        // backgroundColor: 'orange',
        fontFamily: "Helvetica-Bold",
    },
    header_address: {
        // height: '40%',
        flex: 8,
        // backgroundColor: 'blue',
        // fontFamily: "Helvetica-Bold",
    },
    header_name: {
        // height: '15%',
        flex: 3,
        // backgroundColor: 'green',
        fontFamily: "Helvetica-Bold",
    },
});

const styleHeaderLogo = StyleSheet.create({
    header_logo_section: {
        height: "100%",
        // marginLeft: 15,
        // marginTop: 15,
        padding: "15 0 5 15",
        color: "#000",
        display: 'flex',
        flexDirection: 'row',
        family: 'Calibri',
    },
    header_left: {
        flex: '2',
        height: '100%',
        // borderWidth:innerBorderWidth,
        // borderColor:"#ccc",
        alignContent: "center",
    },
    header_center: {
        flex: '6',
        textAlign: "center",
        // fontWeight: 'heavy',
        fontStyle: 'normal',
        // borderWidth:innerBorderWidth,
        // borderColor:"#ccc",
        // fontFamily: "Helvetica-Bold",
    },
    school_header: {
        fontSize: 11,
        paddingTop: 5,
        textTransform: "uppercase",
    },
    header_right: {
        margin: 10,
        padding: 10,
        flex: '2',
        // borderWidth:innerBorderWidth,
        // borderColor:"#ccc",
    },
    image1: {
        height: "auto",
        maxWidth: "60%",
        textAlign: "center",
        padding: 0,
        // justifyContent:"center",
        // alignContent:"center",
        // textAlign:"center",
        // alignItems:"center",
        // alignSelf:"center"
    },
});


const styleHeaderAddress = StyleSheet.create({
    header_address_section: {
        height: '100%',
        color: "#000",
        display: 'flex',
        flexDirection: 'row',
        family: 'Calibri',
    },
    address_left: {
        flex: '1',
        height: '100%',
    },
    address_center: {
        justifyContent: 'center',
        padding: 10,
        flex: '2',
        flexDirection: 'column',

    },
    row_1: {
        flexDirection: "row",
        flex: '1',
        marginBottom: '1'
    },
    text_address_label: {
        flex: '5',
        fontSize: 10,
        color: '#000',
        textAlign: 'left',
        family: 'Calibri'
    },
    text_address_colon: {
        flex: '2',
        fontSize: 10,
        color: '#000',
        textAlign: 'left',
        family: 'Calibri'
    },
    text_address_value: {
        flex: '13',
        fontSize: 10,
        color: '#000',
        textAlign: 'left',
        family: 'Calibri'
    }
});


const styleHeaderName = StyleSheet.create({
    header_text_section: {
        height: '100%',
        color: "#000",
        display: 'flex',
        flexDirection: 'column',
    },
    header_text: {
        flex: 1,
        flexDirection: 'row',
        family: 'Calibri',
        alignContent: "center",
    },
    headerNameText: {
        textAlign: "center",
        textTransform: "uppercase",
        fontSize: 12,
        fontWeight: "bold",
        color: '#000',
        flex: 1,
        justifyContent: "center",
        margin: "10 0"
    }
})


const styleStudentSection = StyleSheet.create({
    student_dtls: {
        height: "100%",
        flexDirection: "column",
        flex: '4',
        padding: "2 10 2 10",
    },

    row: {
        flexDirection: "row",
        flex: '1'
    },
    col: {
        flexDirection: "row",
        height: 11,
        width: "40%"
    },
    col_label: {
        fontSize: 10,
        color: '#000',
        flex: "7"
    },
    col_colon: {
        fontSize: 10,
        color: '#000',
        flex: "2"
    },
    col_value: {
        fontSize: 10,
        color: '#000',
        flex: "10",
        textTransform: "uppercase"
    },
    section_head_text: {
        fontSize: 10,
        color: '#000',
        fontFamily: "Helvetica-Bold",
    },
})


const scoreSectionStyle = StyleSheet.create({
    container: {
        height: "100%",
        flexDirection: "column",
        padding: "2 10 2 10",
    },
    scho_section_header: {
        flex: 1,
        // height: '7%',
        justifyContent: "center",
        // backgroundColor: 'grey',
        fontFamily: "Helvetica-Bold",
        // borderWidth:"1px",
        // borderColor:"#ccc",
    },
    marks_section: {
        flex: 18,
        flexDirection: "column",
        // borderWidth:"1px",
        // borderColor:"#ccc",
    },
    scho_section: {
        flex: 10,
        flexDirection:"column",
        justifyContent:"flex-start"
        // height: '68%',
        // backgroundColor: 'lightgreen',
    },
    summary_section: {
        flex: 2,
        // height: '68%',
        // backgroundColor: 'lightgreen',
    },
    coscho_section: {
        flex: 5,
        // height: '27%',
        // backgroundColor: 'grey'
    },
    section_header_text: {
        textTransform: "uppercase",
        color: "#000",
        textDecoration: "underline",
        fontSize: 10,
    },
})


const styles = StyleSheet.create({

    header_logo_section: {
        height: '50%',
        marginLeft: 15,
        marginTop: 15,
        color: "#000",
        display: 'flex',
        flexDirection: 'row',
        family: 'Calibri',

    },
    header_address_section: {
        height: '50%',
        color: "#000",
        display: 'flex',
        flexDirection: 'row',
        family: 'Calibri',

    },
    header_left: {

        flex: '1',
        height: 'auto'
    },
    header_center: {
        alignItems: 'center',
        flex: '2',
        // fontWeight: 'heavy',
        fontFamily: "Helvetica-Bold",
        fontStyle: 'normal'
    },
    school_header: {
        fontSize: 11,
        paddingTop: 5
    },
    address_left: {
        flex: '0.5',
        height: '100%',

    },
    address_center: {
        justifyContent: 'center',
        padding: 10,
        flex: '1',
        flexDirection: 'column',

    },
    row_1: { flexDirection: "row", flex: '1', marginBottom: '1' },
    text_address_label: { flex: '0.25', fontSize: 10, color: '#000', textAlign: 'left', family: 'Calibri' },
    text_address_colon: { flex: '0.1', fontSize: 10, color: '#000', textAlign: 'left', family: 'Calibri' },
    text_address_value: { flex: '0.65', fontSize: 10, color: '#000', textAlign: 'left', family: 'Calibri' },
    header_right: {
        margin: 10,
        padding: 10,
        flex: '1'
    },
    viewer: {
        width: window.innerWidth, //the pdf viewer will take up all of the width and height
        height: window.innerHeight,
    },
    student_section_header: {

    },
});

const styleSchoScore = StyleSheet.create({
    container: {
        // height: "100%",
        flexDirection: "column",
        padding: "0 0 20 0",
        // padding:0,
        // flex: 1,
    },
    score_table: {
        // flex: 1,
        flexDirection: "column",
        border: "1px solid black",
    },
    score_table_summary: {
        // flex: 1,
        flexDirection: "column",
        // border: "1px solid black",
    },
    score_cols: {
        flexDirection: "row",
    },
    // term_cols: {
    // flexDirection: "row",
    // },
    // overall_cols: {
    //     flexDirection: "row",
    // },
    overall_head: {
        flexDirection: "column",
        padding: 0,
        margin: 0,
    },
    overall_score: {
        flexDirection: "column",
        padding: 0,
        margin: 0,
    },
    score_head: {
        flexDirection: "column",
    },
    score_rows: {
        flexDirection: "column",
    },
    scho_bottom_rows: {
        flexDirection: "column",
    },
    // exam_head: {
    //     flexDirection: "column",
    //     flex: 1,
    //     padding: 0,
    //     margin: 0,
    // },
    score_rows: {
        flexDirection: "column",
        padding: 0,
        margin: 0,
    },
    score_row: {
        flexDirection: "row",
        padding: 0,
        margin: 0,
    },
    exam_row: {
        flexDirection: "row",
        padding: 0,
        margin: 0,
    },
    subject_col: {
        flexDirection: "row",
        flex: 1,
    },
    mark_col: {
        flexDirection: "row",
        flex: 1,
    },
    row_height_top: {
        height: 30,
    },
    row_height_second: {
        height: 20,
    },
    inner_cell: {
        borderLeftWidth: innerBorderWidth,
        borderTopWidth: innerBorderWidth,
        bordercolor: '#ccc',
        margin: 0,
        justifyContent: "center",
    },
    top_left_cell: {
        // borderLeftWidth: 1,
        // borderTopWidth: 1,
        // bordercolor: '#ccc',
        margin: 0,
        justifyContent: "center",
    },
    left_cell: {
        // borderLeftWidth: 1,
        borderTopWidth: innerBorderWidth,
        bordercolor: '#ccc',
        margin: 0,
        justifyContent: "center",
    },
    top_cell: {
        borderLeftWidth: innerBorderWidth,
        // borderTopWidth: 1,
        bordercolor: '#ccc',
        margin: 0,
        justifyContent: "center",
    },
    text_schoExam_label: {
        fontSize: 9,
        color: '#000',
        textAlign: 'center',
        // verticalAlign: "sub",
        justifyContent: "center",
        fontFamily: "Helvetica-Bold",
        paddingTop: 0,
        paddingLeft: 2,
        textTransform: "uppercase"
    },
    text_schoExam_label1: {
        fontSize: 9,
        color: '#000',
        textAlign: 'center',
        // verticalAlign: "sub",
        // backgroundColor: '#f0f0f0',
        justifyContent: "center",
        fontFamily: "Helvetica-Bold",
        paddingTop: 4,
        paddingLeft: 2,
        textTransform: "uppercase"
    },
    text_subject_label: {
        fontSize: 9,
        fontFamily: "Helvetica-Bold",
        color: '#000',
        textAlign: 'left',
        paddingLeft: 2,
        paddingTop: 2,
        justifyContent: "center",
        textTransform: "uppercase"
    },
    text_value: {
        paddingLeft: 2,
        fontSize: 10,
        color: '#000',
    },
    text_left: {
        justifyContent: "center",
        textAlign: "left"
    },
    text_center: {
        justifyContent: "center",
        textAlign: "center"
    },
    text_center_red: {
        justifyContent: "center",
        textAlign: "center",
        color:"red"
    },
})
const styleSummary = StyleSheet.create({
    subject_col: {
        marginLeft:"10px",
        flexDirection: "row",
        // flex: 1,
        border:"1px solid black",
        // backgroundColor: '#f0f0f0',
    },
    subject_col_first: {
        margin:0,
        flexDirection: "row",
        // flex: 1,
        border:"1px solid black",
    },
    sectionCell: {
        borderLeftWidth: innerBorderWidth,
        // borderTopWidth: 1,
        bordercolor: '#ccc',
        margin: 0,
        justifyContent: "center",
    }
})
const styleCoSchoScore = StyleSheet.create({
    container: {
        // height: "100%",
        flexDirection: "column",
        padding: "10 0",
        // padding:0,
        flex: 1,
    },
    score_table: {
        flexDirection: "row",
        flex: 1,
        // borderRight: "1px solid black",
        border: "1px solid black",
    },
    score_table_coscho: {
        flex: 1,
        flexDirection: "row",    
        gap:"10px",
    },
    exam_list: {
        flexDirection: "column",
        flex: 3,
        padding: 0,
        margin: 0,
        border: "1px solid black",
        // borderLeft: "1px solid black",
        // borderTop: "1px solid black",
        // borderBottom: "1px solid black",
    },
    remarks_list: {
        flexDirection: "column",
        flex: 2,
        padding: 0,
        margin: 0,
        justifyContent:"flex-start"
    },
    exam_row: {
        flexDirection: "row",
        padding: 0,
        margin: 0,
    },
    remark_data: {
        borderWidth: tablePartitionWidth,
        borderStyle:"solid",
        bordercolor: '#ccc',
        // border:"1px solid black",
        padding: 0,
        margin: "5px 0 0 0",
    },
    result_row: {
        flexDirection: "row",
        borderLeftWidth: tablePartitionWidth,
        borderTopWidth: tablePartitionWidth,
        bordercolor: '#ccc',
        padding: 0,
        margin: 0,
    },
    inner_cell: {
        borderLeftWidth: innerBorderWidth,
        borderTopWidth: innerBorderWidth,
        bordercolor: '#ccc',
        margin: 0,
        justifyContent: "center",
    },
    top_left_cell: {
        margin: 0,
        justifyContent: "center",
    },
    left_cell: {
        borderTopWidth: innerBorderWidth,
        bordercolor: '#ccc',
        margin: 0,
        justifyContent: "center",
    },
    top_cell: {
        borderLeftWidth: innerBorderWidth,
        bordercolor: '#ccc',
        margin: 0,
        justifyContent: "center",
    },
    text_schoExam_label: {
        fontSize: 9,
        color: '#000',
        fontFamily: "Helvetica-Bold",
        textAlign: 'center',
        justifyContent: "center",
        paddingTop: 2,
        paddingLeft: 2,
        textTransform: "uppercase"
    },
    text_subject_label: {
        fontSize: 9,
        fontFamily: "Helvetica-Bold",
        color: '#000',
        textAlign: 'left',
        paddingLeft: 2,
        paddingTop: 2,
        justifyContent: "center",
        textTransform: "uppercase"
    },
    text_subject_score: {
        fontSize: 9,
        color: '#000',
        textAlign: 'center',
        paddingLeft: 2,
        paddingTop: 2,
        justifyContent: "center",
        textTransform: "uppercase"
    },
    text_subject_score_red: {
        fontSize: 9,
        color: '#000',
        textAlign: 'center',
        paddingLeft: 2,
        paddingTop: 2,
        justifyContent: "center",
        textTransform: "uppercase",
        color:"red"
    },
    text_remark_label: {
        fontSize: 10,
        fontFamily: "Helvetica-Bold",
        color: '#000',
        justifyContent:"flex-start",
        textAlign: 'center',
        paddingLeft: 2,
        paddingTop: 4
    },
    text_remark: {
        fontSize: 12,
        color: '#37353b',
        textAlign: 'left',
        paddingLeft: 2,
        paddingTop: 2
    },
    text_result_label: {
        fontSize: 9,
        fontFamily: "Helvetica-Bold",
        color: '#000',
        textAlign: 'left',
        paddingLeft: 2,
        paddingTop: 4
    },
    text_result: {
        fontSize: 11,
        // fontFamily:"Helvetica-Bold",
        color: '#000',
        textAlign: 'left',
        paddingLeft: 2,
        paddingTop: 2
    },
});

const styleFooter = StyleSheet.create({
    block: {
        flex: 1,
        flexDirection: "column",
        padding: "2 10 2 10",
        // backgroundColor: '#f0f0f0',
    },
    empty_row: {
        flex: 7,
        // height: "70%",
        flexDirection: "row",
    },
    row_labels: {
        flex: 3,
        flexDirection: "row",
        // height: "30%",
    },
    colSign: {
        flex: 1,
        // backgroundColor: '#f0f0f0',
        // border:"1px solid black",
        margin:"5px 20px",
    },
    colSignLabel: {
        flex: 1,
        // backgroundColor: '#f0f0f0',
    },
    sign_text: {
        fontSize: 12,
        fontFamily: "Helvetica-Bold",
        textAlign: "center",
        alignContent: "center",
        color: "#37353b"
    },
    image_sign: {
        height: "auto",
        maxWidth: "100%",
        // textAlign: "center",
        // padding: 0,
        // justifyContent:"center",
        // alignContent:"center",
        // textAlign:"center",
        // alignItems:"center",
        // alignSelf:"center"
    },
});

export {
    styles, pageStyle, styleFooter, styleCoSchoScore, styleSchoScore, styleHeaderLogo,
    styleHeaderAddress, styleHeaderName, styleStudentSection, scoreSectionStyle, styleSummary
};