import axios from 'axios'
import $ from 'jquery'
import { toast, ToastContainer } from "react-toastify"
import lstModules from 'common/json/lstModules.json';

var queryString = require('querystring');
var screenId = 33;
var tokenid = localStorage.getItem("tokenid");
var httpURL = lstModules['main'].url + ":" + lstModules['main'].port;
const httpURLCommon = lstModules['main'].url + ':' + lstModules['main'].port;
const httpURLStudent = lstModules['students'].url + ':' + lstModules['students'].port
const academicYearId= localStorage.getItem('academicYearId') || 0;

function getClassData(setData) {
    var status = 'Success'
    var obj = { status: status };

    // // console.log(httpURL)
    axios({
        url: httpURL + '/sms/cls/section/list',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.message = response.data.SvcMsg;
        obj.status = response.data.SvcStatus;
        if (response.data.SvcStatus === "Success") {
        obj.lstAllClassSections = response.data.lstAllClassSections;
        // // console.log(obj);
    }
        setData(obj)
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        setData(obj)
    });
}


function getLogoSvc(callback) {

    var xhr = new XMLHttpRequest();
    xhr.open("GET", httpURLCommon + '/sms/school/profile/logo/view', true);
    xhr.setRequestHeader('tokenid', tokenid);

    xhr.responseType = "arraybuffer";

    xhr.onload = function (e) {
        var arrayBufferView = new Uint8Array(this.response);
        var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(blob);
        // // console.log("imageUrl in getLogoSvc", imageUrl);
        callback(imageUrl);
    };
    xhr.send();
}

function getPrinciSignSvc(cb) {

    var xhr = new XMLHttpRequest();
    xhr.open("GET", httpURLCommon + '/sms/mst/sign/view?roleId=24', true);
    xhr.setRequestHeader('tokenid', tokenid);

    xhr.responseType = "arraybuffer";

    xhr.onload = function (e) {
        var arrayBufferView = new Uint8Array(this.response);
        var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(blob);
        // // console.log("imageUrl in getPrinciSignSvc", imageUrl);
        cb(imageUrl);
    };
    xhr.send();

}

function getExamReportSvc(examId, sectionId, cbProgressReport) {
    // // console.log("getProgressReportSvc called, sectionId", sectionId, ", examId", examId);
    var formData = new FormData()
    formData.append("sectionId", sectionId);
    formData.append("examId", examId);
    formData.append("acdYrId", academicYearId);
    var obj = { status: "Success", message:"" }
    axios({
        url: httpURL + '/sms/reportcard/examreportcard/generate/cls_section',
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // // console.log('response in getProgressReportSvc',  response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstReportCardObj = response.data.lstReportCardObj;
            // // console.log("response in getExamReportSvc", response);
        }
        cbProgressReport(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbProgressReport(obj);
    });
}


export {  getLogoSvc, getPrinciSignSvc, getClassData, getExamReportSvc};