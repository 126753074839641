import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import $ from 'jquery';

export default class CampaignTable extends Component {
    state = {
        campaign: "",
        mediaTypeId: 0,
        sortAsc: true,
    }

    addClassOnBody = (id) => {
        $('#campaignTableBody tr#' + id).addClass('selected').siblings().removeClass('selected');
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.props.filterTable(this.state.campaign))
    }

    render() {
        return (
            <>
                <table className="tableLayout1" id='customTable'>
                    <thead>
                        <tr>
                            <th width="100"> Start Date </th>
                            <th width="100"> End Date </th>
                            <th width="150" className="text-left">
                                <input type="text" className="form-control inp-search text-left"
                                    value={this.state.campaign}
                                    onChange={this.handleChange}
                                    name="campaign" id="campaignFilter" placeholder="Campaign" />
                            </th>
                            <th width="100">Action</th>
                        </tr>
                    </thead>
                </table>
                <div className="tableDiv">
                    <table id="campaignTable" className="tableLayout">
                        <tbody id="campaignTableBody">
                            {this.props.lstCampaign.map((campaign) => (
                                <tr key={campaign.campaignId} id={campaign.campaignId} onClick={
                                    () => this.addClassOnBody(campaign.campaignId)}>
                                    <td width="100" className="no-display">{campaign.campaignId}</td>
                                    <td width="100" className="text-center">{campaign.startDt}</td>
                                    <td width="100" className="text-center">{campaign.endDt}</td>
                                    <td width="150" className="text-left">{campaign.campaign}</td>
                                    <td width="100" className="text-center">
                                        <span onClick={() => this.props.setForm(campaign.campaignId)}
                                            className="glyphicon glyphicon-edit"></span>
                                        <span
                                            onClick={() => this.props.deleteRow(campaign.campaignId)}
                                            className="glyphicon glyphicon-trash" ></span>
                                        <span
                                            onClick={() => this.props.showContent(campaign.campaignId)}
                                            className="glyphicon glyphicon-film" ></span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
}
