import { StyleSheet } from '@react-pdf/renderer';


const innerBorderWidth = ".40px";
const tablePartitionWidth = ".60px";

export const pageStyle = StyleSheet.create({
    page: {
        // backgroundColor: "#fff",
        backgroundColor: "white",
        display: 'flex',
        flexDirection: 'column',
        padding: "25px 15px 15px 15px"
    },
    fullPage: {
        flex: 1,
    },
    page_border: {
        height: '100%',
        width: '100%',
        borderWidth: 1,
        borderColor: "#000",
        // backgroundColor:"red"
    },
    testHeader: {
        minHeight: "60px",
    },
    pageHeader: {
        // flex: 1,
    },
    pageContent: {
        // flex: 40,
        flexDirection: "column",
    },
    pageFooter: {
        // flex: 1,
        // bottom:"10px",
        // position:"absolute"
    },
    horizontalBar1: {
        borderBottom: '2px solid #b7b7b7',
        margin: '3px 20px',
        // alignContent:"center",
        // alignItems:"center",
        // textAlign:"center",
        // width:"300px"
    },
    horizontalBar2: {
        borderBottom: '2px solid #b7b7b7',
        // borderBottom: '1px solid #ccc',
        // borderBottom: '2px solid #101010',
        margin: '5px 0',
    },
});

export const headerFooter = StyleSheet.create({
    row: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        textAlign: "center",
    },
    column: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
    },
    sideSection: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        borderWidth: 1,
        borderColor: "#ccc",
    },
    centralSection: {
        flex: 2,
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        borderTopWidth: 1,
        borderTopColor: "#ccc",
        borderBottomWidth: 1,
        borderBottomColor: "#ccc",
    },
    viewCenter:{
        flex: 9,
        // flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        padding:"2px 5px"
    },
    viewLeft:{
        flex: 11,
        // flexDirection: "column",
        justifyContent: "center",
        textAlign: "left",
        padding:"2px 5px"
    },
    header:{
        margin:"10px 0"
    },
    footer:{
        margin:"10px 0"
    },
    image_logo: {
        maxHeight:"100%",
        maxWidth: "100%",
        textAlign: "center",
        justifyContent:"center",
        padding: "2px",
        objectFit: "contain",
    },
});

export const textStyle = StyleSheet.create({
    textCenterExtraLarge: {
    fontFamily: 'Nanum Gothic',
        justifyContent: "center",
        textAlign: "center",
        fontSize: 18,
        color: '#000',
    },
    textCenterExtraLargeBold: {
    fontFamily: 'Nanum Gothic',
        justifyContent: "center",
        textAlign: "center",
        fontSize: 18,
        fontWeight: "bold",
        color: '#000',
    },
    textCenterLarge: {
    fontFamily: 'Nanum Gothic',
        justifyContent: "center",
        textAlign: "center",
        fontSize: 16,
        color: '#000',
    },
    textLeftLarge: {
    fontFamily: 'Nanum Gothic',
        justifyContent: "center",
        textAlign: "left",
        fontSize: 16,
        color: '#000',
    },
    textRightLarge: {
    fontFamily: 'Nanum Gothic',
        justifyContent: "center",
        textAlign: "right",
        fontSize: 16,
        color: '#000',
    },
    textCenterLargeBold: {
    fontFamily: 'Nanum Gothic',
        justifyContent: "center",
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        color: '#000',
    },
    textCenterMedium: {
    fontFamily: 'Nanum Gothic',
        justifyContent: "center",
        textAlign: "center",
        fontSize: 14,
        color: '#000',
    },
    textLeftMedium: {
    fontFamily: 'Nanum Gothic',
        justifyContent: "center",
        textAlign: "left",
        fontSize: 14,
        color: '#000',
    },
    textLeftMedium1Bold: {
    fontFamily: 'Nanum Gothic',
        justifyContent: "center",
        textAlign: "left",
        fontSize: 13,
        color: '#000',
        fontWeight: "bold",
    },
    textCenterMediumBold: {
    fontFamily: 'Nanum Gothic',
        justifyContent: "center",
        textAlign: "center",
        fontSize: 14,
        fontWeight: "bold",
        color: '#000',
    },
    textCenterSmall: {
    fontFamily: 'Nanum Gothic',
        justifyContent: "center",
        textAlign: "center",
        fontSize: 12,
        color: '#000',
    },
    textLeftSmall: {
    fontFamily: 'Nanum Gothic',
        justifyContent: "center",
        textAlign: "left",
        fontSize: 12,
        color: '#000',
        // borderWidth: 1,
        // borderColor: "#000",
    },
    textLeftSmallBold: {
    fontFamily: 'Nanum Gothic',
        justifyContent: "center",
        textAlign: "left",
        fontSize: 12,
        fontWeight: "bold",
        color: '#000',
        // borderWidth: 1,
        // borderColor: "#000",
    },
    textCenterSmallBold: {
    fontFamily: 'Nanum Gothic',
        justifyContent: "center",
        textAlign: "center",
        fontSize: 12,
        fontWeight: "bold",
        color: '#000',
    },
    textCenterExtraSmall: {
    fontFamily: 'Nanum Gothic',
        justifyContent: "center",
        textAlign: "center",
        fontSize: 10,
        color: '#000',
    },
    optionTextLeft: {
    fontFamily: 'Nanum Gothic',
        justifyContent: "center",
        textAlign: "left",
        fontSize: 11,
        color: '#000',
    },
    textRightExtraSmall: {
    fontFamily: 'Nanum Gothic',
        justifyContent: "center",
        textAlign: "right",
        fontSize: 11,
        color: '#000',
    },
    textCenterExtraSmallBold: {
    fontFamily: 'Nanum Gothic',
        justifyContent: "center",
        textAlign: "center",
        fontSize: 10,
        fontWeight: "bold",
        color: '#000',
    },
    imageQuestion: {
    fontFamily: 'Nanum Gothic',
        // height: "100%",
        // width: "100%",
        maxWidth: "300px",
        maxHeight: "150px",

        textAlign: "center"
    },
    imageOption: {
    fontFamily: 'Nanum Gothic',
        // height: "100%",
        // width: "100%",
        maxWidth: "200px",
        maxHeight: "100px",
        // padding:"3px",
        textAlign: "center"
    },


})

export const styleQuestion = StyleSheet.create({
    questionRow: {
        // flex:1,
        display: "flex",
        flexDirection: "row",
        marginTop: "25px",
    },
    questionNo: {
        flex: 1,
        // flexDirection:"row",
    },
    question: {
        flex: 20,
        flexDirection: "row",
    },
    questionImg: {
        flex: 1,
        // width: "100%",
        padding: "3px"
    },
    questionText: {
        flex: 1,
        flexDirection: "column",
        padding: 0,
        minHeight:"15px",
        // borderWidth: 1,
        // borderColor: "#000",
        // alignContent:"center",
        // alignItems:"center",
        alignSelf: "flex-start"
    },
    row: {
        // flex:1,
        flexDirection: "row",

    },
    optionLeftSpace: {
        flex: 1,
    },
    answerLeftSpace: {
        flex: 1,
    },
    optionRow: {
        flex: 10,
        flexDirection: "row",
        paddingTop: "5px",
    },
    answerRow: {
        flex: 20,
        flexDirection: "row",
        paddingTop: "5px",
    },
    optionCell: {
        flex: 1,
        // minHeight:"40px",
        flexDirection: "row",
        // borderWidth: 1,
        // borderColor: "#000",
        // justifyContent:"center",
        // alignItems:"center",
    },
    optionNameSpace: {
        flex: 1,
        paddingTop: "5px",
        // borderWidth: 1,
        // borderColor: "#000",
        // justifyContent:"center",
        // alignItems:"center",
        // alignContent:"center"
        // alignSelf:"center"
    },
    optionSpaceQuarter: {
        flex: 7,
        // borderWidth: 1,
        // borderColor: "#000",
        // justifyContent:"center",
        // alignItems:"center",
        // alignContent:"center"
    },
    optionSpaceHalf: {
        flex: 14,
        flexDirection: "column",
    },
    optionSpaceFull: {
        flex: 28,
        flexDirection: "column",
    },
    optionSpaceCombined: {
        flex: 28,
        flexDirection: "row",
    },
    optionColumn: {
        flex: 1,
        flexDirection: "column",
    },
    textSection: {
        flex: 1,
    },
    imageSection: {
        flex: 1,
        flexDirection: "column",
        // borderWidth: 1,
        // borderColor: "#000",
    },
    optionText: {
        // flex:1,
        paddingTop: "5px",
        paddingLeft: "0",
        paddingRight: "10px",
        paddingBottom: "0",
        justifyContent: "flex-start",
        textAlign: "left",
        // borderWidth: 1,
        // borderColor: "#000",
    },
    optionTextCombined: {
        // flex:1,
        paddingTop: "5px",
        paddingLeft: "0",
        paddingRight: "0",
        paddingBottom: "0",
        justifyContent: "flex-start",
        textAlign: "left",
        // borderWidth: 1,
        // borderColor: "#000",
    },
    answerCell: {
        // flex: 1,
        // width: "50%",
        width: "70px",
        padding: "0 0 3px 5px",
        marginTop: "15px",
        // marginLeft:"0",
        marginRight: "10px",
        // marginBottom:"0",
        justifyContent: "flex-start",
        textAlign: "left",
        borderWidth: 1,
        borderColor: "#ccc",
        borderRadius: "10px",
    },
    optionImage: {
        // flex:1,
        flexDirection: "row",
        padding: "2px",
        // borderWidth: 1,
        // borderColor: "#ccc",
    }
})