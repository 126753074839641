import React from 'react';
import { Text, View, Font } from '@react-pdf/renderer';

import { toast } from "react-toastify";
import { styleCoSchoScore } from './reportCardStyles.js';

Font.register({
    family: 'Calibri'
    , fonts: [{ src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibri.ttf" }
        , { src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibrib.ttf", fontWeight: "bold" }], format: 'truetype'
});


const CoScholasticSection = (props) => {
    const { coschoScore } = props;
    return (
        <View style={styleCoSchoScore.container}>
            <View style={styleCoSchoScore.score_table}>
                <View style={styleCoSchoScore.exam_list}>
                    <View style={[styleCoSchoScore.exam_row, { flex: 1 }]}>
                        <View style={[styleCoSchoScore.top_left_cell, { flex: 5 }]}>
                            <Text style={styleCoSchoScore.text_schoExam_label}>Grade Subjects</Text>
                        </View>
                        {coschoScore.lstExamHeaderCoscho && coschoScore.lstExamHeaderCoscho.length ?
                            coschoScore.lstExamHeaderCoscho.map((exam) => (
                                exam.examId > 0 ?
                                    <View key={exam.examId} style={[styleCoSchoScore.top_cell, { flex: 2 }]}>
                                        <Text style={styleCoSchoScore.text_schoExam_label}>{exam.exam}</Text>
                                    </View>
                                    : null
                            )) :
                            <View style={[styleCoSchoScore.top_cell, { flex: 2 }]}>
                            </View>
                        }
                    </View>
                    {coschoScore.lstSubjectGrade && coschoScore.lstSubjectGrade.length ?
                        coschoScore.lstSubjectGrade.map((subject, idx) => (
                            <View key={subject.sub.subjectId} style={[styleCoSchoScore.exam_row, { flex: 1 }]}>
                                <View style={[styleCoSchoScore.left_cell, { flex: 5 }]}>
                                    <Text style={styleCoSchoScore.text_subject_label}>{subject.sub.subject}</Text>
                                </View>
                                {subject.lstGrade && subject.lstGrade.length ?
                                    subject.lstGrade.map((grade, index) => (
                                        <View key={index} style={[styleCoSchoScore.inner_cell, { flex: 2 }]}>
                                            <Text style={styleCoSchoScore.text_subject_score}>{grade}</Text>
                                        </View>
                                    ))
                                    :
                                    <View style={[styleCoSchoScore.exam_row, { flex: 2 }]}>
                                        <View style={[styleCoSchoScore.inner_cell, { flex: 1 }]}>
                                        </View>
                                    </View>
                                }
                            </View>
                        )) :
                        <View style={[styleCoSchoScore.exam_row, { flex: 1 }]}>
                            <View style={[styleCoSchoScore.inner_cell, { flex: 1 }]}>
                            </View>
                        </View>
                    }
                </View>
                <View style={styleCoSchoScore.remarks_list}>
                    <View style={[styleCoSchoScore.remark_row, { flex: 3 }]}>
                        <Text style={styleCoSchoScore.text_remark_label}>REMARKS</Text>
                        <Text style={styleCoSchoScore.text_remark}></Text>
                    </View>
                    <View style={[styleCoSchoScore.result_row, { flex: 2 }]}>
                        <Text style={styleCoSchoScore.text_result_label}>RESULT</Text>
                        <Text style={styleCoSchoScore.text_result}></Text>
                    </View>
                </View>
            </View>
        </View>
    );
}

export default CoScholasticSection;