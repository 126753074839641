import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import DatePicker from 'react-datepicker'
import { viewSampleSvc } from './commSvc'

const CommPopup = props => {
  const [loader, setLoader] = useState('none')
  const [sample, setSample] = useState('')

  useEffect(() => {
    viewSampleSvc(props.template, cbViewSampleResponse)
  }, [props.template])

  function cbViewSampleResponse (data) {
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      setSample(data.sample)
    } else {
      toast.error(data.SvcMsg)
    }
  }

  function closeClick () {
    props.closePopup()
  }

  return (
    <>
      <button
        type='button'
        className='btn btn-info pull-right'
        onClick={() => closeClick()}
      >
        <span
          id='close'
          title='Close'
          className='glyphicon glyphicon-remove'
        ></span>
      </button>
      <form
        className='form form-horizontal'
        style={{
          width: '100%',
          height: 300,
          margin: '0 auto',
          background: '#fff',
          border: '1px solid #ccc',
          borderRadius: 10,
          pointerEvents: loader === 'none' ? 'auto' : 'none'
        }}
      >
        <h6 className='text-center'>Sample Template</h6>
        <div
          style={{
            position: 'fixed',
            zIndex: 10000,
            width: 100,
            height: 100,
            marginLeft: -50,
            marginTop: -50,
            left: '50%',
            top: '50%',
            display: loader
          }}
        >
          <img
            className='loderImg'
            src={require('common/images/loader.gif')}
            alt='Loader...'
          />
        </div>

        <div className='col-md-12'>
          <label className='control-label col-sm-3 text-left'>Remarks</label>
          <div className='form-group col-sm-9'>
            <textarea
              maxLength={500}
              rows={10}
              value={sample}
              className='form-control'
              readOnly
              disabled
            ></textarea>
          </div>
        </div>
      </form>
    </>
  )
}

export default CommPopup
