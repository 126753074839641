import { StyleSheet } from '@react-pdf/renderer';

const otpBorderWidth = ".6px";
const otpBorderColor = "#ccc";

export const pageStyle = StyleSheet.create({
    page: {
        // backgroundColor: "#fff",
        backgroundColor: "white",
        display: 'flex',
        flexDirection: 'column',
        padding: "25px 15px 15px 15px"
    },
    fullPage: {
        flex: 1,
    },
    page_border: {
        height: '100%',
        width: '100%',
        borderWidth: 1,
        borderColor: "#000",
        // backgroundColor:"red"
    },
    testHeader: {
        height: "60px",
    },
    pageHeader: {
        // flex: 1,
    },
    pageContent: {
        // flex: 40,
        // marginTop:"20px",
        flexDirection: "column",
    },
    pageFooter: {
        // flex: 1,
        // bottom:"10px",
        // position:"absolute"
    },
    horizontalBar1: {
        borderBottom: '2px solid #b7b7b7',
        margin: '3px 20px',
        // alignContent:"center",
        // alignItems:"center",
        // textAlign:"center",
        // width:"300px"
    },
    horizontalBar2: {
        borderBottom: '2px solid #b7b7b7',
        // borderBottom: '1px solid #ccc',
        // borderBottom: '2px solid #101010',
        margin: '5px 0 10px 0',
    },
   
});


export const leaderboardPdfStyle = StyleSheet.create({
    page:{
        flex:1, 
        flexDirection:"column",
        padding:"10px 20px",
    },
    header:{
        // height: "120px",
        flex:3,
        flexDirection:"row",
        // borderWidth: 1,
        // borderColor: "#000",
        justifyContent: "flex-end",
        alignItems:"flex-end",
    },
    pageContent:{
        flex:12,
        // padding:"0 5px",
        flexDirection:"column",
        // borderWidth: 1,
        // borderColor: "#000",
    },
    pageHeading:{
        height: "80px",
        flex:10,
        // borderWidth: 1,
        // borderColor: "#000",
        padding:"10px 0 0 0",
        justifyContent: "flex-end",
    },
    pageHeadingText:{
        flex:1,
        backgroundColor:"blue",
        justifyContent: "center",
        // borderWidth: 1,
        // borderColor: "#000",
    },
    qrSection:{
        flex:3,
    },
    leaderboardImageSection:{
        flex:12,
        // borderWidth: 1,
        // borderColor: "#000",
        justifyContent:"flex-start"
    },
    pointsSection:{
        flex:8,
        padding:"30px 0 0 0",
        // borderWidth: 1,
        // borderColor: "#000",
        flexDirection:"column",
    },
    pointsColumn:{
        flex:1,
        padding:"0",
        flexDirection:"column",
        justifyContent:'center',
        alignItems:"flex-start",
        // textAlign:"left",
        // alignContent:"flex-start",
    },
    pointsRow:{
        flex:1,
        padding:"0",
        flexDirection:"row",
        justifyContent:'center',
    },
    pointsCell:{
        flex:1,
        padding:"0",
        textAlign:"left",
        alignItems:"flex-start",
        justifyContent:'center',
    },
    pointsCellFooter:{
        flex:1,
        padding:"0",
        justifyContent:'flex-start',
    },
    logoImageSection:{
        flex:3,
        // borderWidth: 1,
        // borderColor: "#000",
        justifyContent:"center",
        textAlign:"center",
        alignItems:"center",
    },
    footerSection:{
        flex:2,
        justifyContent:"flex-start",
        alignItems:"center"
        // borderWidth: 1,
        // borderColor: "#000",
    },
    leaderboardImageDiv:{
        height:"100%",
        width:"100%",
    },
    logoImageDiv:{
        height:"100%",
        width:"80%",
        // borderWidth: 1,
        // borderColor: "#000",
        justifyContent:"center",
        textAlign:"center",
    },
    leaderboardImage:{
        maxHeight:"400px",
        maxWidth:"100%",
        // width:"100%",
    },
    logoImage:{
        maxHeight:"80px",
        // width:"100%",
    },
    horizontalBar: {
        borderBottomWidth: '1px',
        borderBottomColor: '#b7b7b7',
        margin: '2px',
    },
});


export const otpPDFStyle = StyleSheet.create({
    otpRow: {
        // flex:1,
        display: "flex",
        flexDirection: "row",
        // marginTop: "25px",
        borderLeftWidth: otpBorderWidth,
        bordercolor: '#ccc',
        padding: 0,
        margin:0,
    },
    firstOtpRow: {
        // flex:1,
        display: "flex",
        flexDirection: "row",
        // marginTop: "25px",
        borderLeftWidth: otpBorderWidth,
        borderTopWidth: otpBorderWidth,
        bordercolor: otpBorderColor,
        padding: 0,
        margin:0,
    },
    row: {
        // flex:1,
        flexDirection: "row",
    },
    column: {
        flex:1,
        flexDirection:"column",
    },
    studentCell: {
        flex: 1,
        flexDirection: "column",
        height: "60px",
        padding: "5px 0",
        margin: 0,
        borderRightWidth: otpBorderWidth,
        borderBottomWidth: otpBorderWidth,
        bordercolor: otpBorderColor,
    },
    studentCellColumn: {
        // flex: 1,
        margin: 0,
        justifyContent: "center",
        textAlign: "left",
        padding: "5px",
    }
});


export const textStyle = StyleSheet.create({
    textCenterLarge: {
        justifyContent: "center",
        textAlign: "center",
        fontSize: 16,
        color: '#000',
    },
    textCenterMediumBold: {
        justifyContent: "center",
        textAlign: "center",
        fontSize: 13,
        color: '#000',
        fontWeight: "bold",
    },
    textLeftMediumBold: {
        justifyContent: "center",
        textAlign: "left",
        fontSize: 13,
        color: '#000',
        fontWeight: "bold",
    },
    textRightMediumBold: {
        justifyContent: "center",
        textAlign: "right",
        fontSize: 13,
        color: '#000',
        fontWeight: "bold",
    },
    textLeftVerySmall: {
        justifyContent: "center",
        textAlign: "left",
        fontSize: 9,
        color: '#000',
        // borderWidth: 1,
        // borderColor: "#000",
    },
    textLeftSmall: {
        justifyContent: "center",
        textAlign: "left",
        fontSize: 10,
        color: '#000',
        // borderWidth: 1,
        // borderColor: "#000",
    },
    textCenterSmallBold: {
        justifyContent: "center",
        textAlign: "center",
        fontWeight:"bold",
        fontSize: 10,
        color: '#000',
        // borderWidth: 1,
        // borderColor: "#000",
    },
    whiteHeading:{
        justifyContent: "center",
        textAlign: "center",
        fontWeight:"bold",
        fontSize: 36,
        color: '#fff',
    },
    bluePoint:{
        justifyContent: "center",
        textAlign: "left",
        fontWeight:"400",
        fontSize: 20,
        color: 'blue',
    },
    footerPoint:{
        justifyContent: "center",
        textAlign: "center",
        fontWeight:"400",
        fontSize: 18,
        color:"gray"
    }
})

export const styleQR = StyleSheet.create({
    divImg: {
        height:"100px",
        width:"100px",
    },
    image1: {
        height: "100%",
        width: "100%",
        textAlign: "center"
    },
    studentCell: {
        flex: 1,
        flexDirection: "column",
        height: "140px",
        padding: "5px 0",
        margin: 0,
        borderRightWidth: otpBorderWidth,
        borderBottomWidth: otpBorderWidth,
        bordercolor: otpBorderColor,
    },
    studentCellColumn: {
        // flex: 1,
        margin: 0,
        justifyContent: "center",
        textAlign: "left",
        padding: "5px",
    }
})
export const headerStyle = StyleSheet.create({
    row: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        textAlign: "center",
    },
    column: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
    },
    leftSection: {
        flex: 3,
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        // borderWidth: 1,
        // borderColor: "#ccc",
    },
    rightSection: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "flex-end",
        textAlign: "right",
        // borderTopWidth: 1,
        // borderTopColor: "#ccc",
        // borderBottomWidth: 1,
        // borderBottomColor: "#ccc",
        // borderRightWidth: 1,
        // borderRightColor: "#ccc",
    },
    viewRight: {
        flex: 1,
        justifyContent: "center",
        textAlign: "right",
        padding: "2px 5px",
    },
    viewLeft: {
        flex: 1,
        justifyContent: "center",
        textAlign: "left",
        padding: "2px 5px"
    },
    header: {
        margin: "10px 0"
    },
});