import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
// var httpURL = lstModules['event'].url + ':' + lstModules['event'].port;
var httpURL = lstModules['main'].url + ":" + lstModules['main'].port;
var tokenid = localStorage.getItem('tokenid');

function getEventListSvc(callBack) {
    // // console.log("getEventListSvc called");
    axios(
        {
            url: httpURL + "/sms/event/list",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)

        }
    ).catch((error) => {
        // // console.log("Event List Catch error")
    });
}

function saveEventSvc(pEventObj,file, callBack) {
    // // console.log("saveEventSvc called");
    var formData = new FormData();
    formData.append("event", JSON.stringify(pEventObj));
    formData.append("files",file)
    axios({
        url: httpURL + "/sms/event/save",
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {

        var data = response.data
        callBack(data);
    }).catch((error) => { 
        // // console.log("Error Occured")
     });
}

function deleteEventSvc(pEventId, callBack) {
    // // console.log("deleteEventSvc called");
    axios(
        {
            url: httpURL + "/sms/event/delete",
            method: 'POST',
            params: { eventId: pEventId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data;
            callBack(data);
        }
    ).catch((error) => {
        console.error(error)
    });
}

function getLstClsSection(callback) {
    var status = 'Success'
    var obj = { status: status, message:"" };
    // // console.log("getLstClsSection");
    axios({
        url: httpURL + '/sms/cls/section/list',
        method: "POST",
        params: {  },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === 'Success') {
            // // console.log('data.SvcStatus=' + response.data.SvcStatus);
            // // console.log(response.data);
            obj.lstClsSection = response.data.lstAllClassSections;
            // // console.log(obj);
        }
        callback(obj)
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });
}

/* function getClassListSvc(callback1) {
    // // console.log("getClassListSvc called");
    var status = 'Success'
    var obj = { status: status };

    axios({
        url: httpURL + '/sms/mst/cls/list',
        method: "POST",
        params: { screenId: 0 },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // // console.log('data.SvcStatus=' + response.data.SvcStatus);
        obj.message = response.data.SvcMsg;
        obj.status = 'Success';
        obj.lstClass = response.data.lstClass;
        // // console.log(obj);
        callback1(obj);
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback1(obj)
    });
}
 */

export { getEventListSvc, saveEventSvc, deleteEventSvc, getLstClsSection }