import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import "../css/subjectAllocation.css";

import Loader from "common/components/Loader";
import Header from "common/js/header/header";
import ReportTabs from "./reportTabs";
import TeacherReportCtrl from "./teacherReport/teacherReportCtrl";
import ClassReportCtrl from "./classReport/classReportCtrl";
import AllocationFilter from "./teacherReport/allocationFilter";


const AllocationReportCtrl = () => {

    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [flgClsReportTab, setFlgClsReportTab] = useState(false); // Optional state if needed
    const [key, setKey] = useState(0);
    const [allocationFiltered, setAllocationFiltered] = useState(">0");


    useEffect(() => {
        // console.log("SubjectAllocationCtls called");
    }, [key]);


    const updateKey = () => {
        setKey(prevKey => prevKey + 1);
    };

    return (
        <div>
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <Loader loader={loader} position="fixed" />
            <div
                className="col-md-12 col-xs-12 col-centered padding-remove"
                style={{
                    cursor: loader === "none" ? 'pointer' : 'none',
                    pointerEvents: loader === "none" ? 'auto' : 'none',
                    opacity: opacity
                }}
            >
                <div className="col-ls-10 col-md-10 col-sm-10 col-xs-10 col-centered padding-remove" style={{ marginTop: "15px" }}>
                    <div className="col-ls-4 col-md-4 col-sm-4 col-xs-4 padding-remove">
                        <ReportTabs
                            flgClsReportTab={flgClsReportTab}
                            setFlgClsReportTab={setFlgClsReportTab}
                        />
                    </div>
                    {
                        !flgClsReportTab ?
                            <div className="col-ls-8 col-md-8 col-sm-8 col-xs-8 padding-remove" >
                                <AllocationFilter
                                    allocationFiltered={allocationFiltered}
                                    setAllocationFiltered={setAllocationFiltered}
                                />
                            </div>
                            : null
                    }
                </div>
                {
                    flgClsReportTab ?
                        <div className="col-ls-10 col-md-10 col-sm-10 col-xs-10 col-centered padding-remove" style={{ marginTop: "45px" }}>
                            <ClassReportCtrl
                                setLoader={setLoader}
                                setOpacity={setOpacity}
                            />
                        </div >
                        :
                        <div className="col-ls-10 col-md-10 col-sm-10 col-xs-10 col-centered padding-remove" style={{ marginTop: "45px" }}>
                            <TeacherReportCtrl
                                setLoader={setLoader}
                                setOpacity={setOpacity}
                                allocationFiltered={allocationFiltered}
                            />
                        </div >
                }
            </div >
        </div >
    );
};

export default AllocationReportCtrl;
