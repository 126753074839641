import React, { useEffect, useState } from "react";
import 'common/css/master.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../css/studentTest.css';
import '../css/testInstruction.css';

// let newArray = [];
const InstructionStudents = (props) => {

    const { lstStudent } = props;

    const [mapAvatar, setMapAvatar] = useState(new Map());
    const [avatarPath, setAvatarPath] = useState("");


    useEffect(() => {
        getMapAvatar();
        getPathAvatar();
    }, []);

    const getMapAvatar = () => {
        const tempMapAvatar = JSON.parse(localStorage.getItem('mapAvatar'));
        setMapAvatar(tempMapAvatar);
    }

    const getPathAvatar = () => {
        const tempPathAvatar = localStorage.getItem('avatarPath');;
        setAvatarPath(tempPathAvatar);
    }

    return (
        <div className="col-sm-12 studentContainer padding-remove" >
            <div className="label-pallet" >
                <span className="avatar-list-label"  >
                    {"In arena"}
                </span>
            </div>
            <div className="studentGrid">
                {
                    lstStudent && lstStudent.length ?
                        lstStudent.map((student) => (
                            <div >
                                <div key={student.studentId} className="studentItem" style={{ textAlign: "center" }}>
                                    <div>
                                        {student.crypticNm}
                                    </div>
                                    <div className="avatarItem">
                                        <div
                                            className="studentAvatarImage">
                                            <img src={avatarPath + "/" + student.avatarId + ".png"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                        : null
                }
            </div>
        </div>
    );
}

export default InstructionStudents;