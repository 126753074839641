import React, { Component } from 'react'
import lstModules from 'common/json/lstModules.json'
import $ from 'jquery'
import 'jquery-ui'
import '../css/building.css'
import 'common/css/master.css'
import axios from 'axios'
import Header from 'common/js/header/header.js'
import { toast, ToastContainer } from 'react-toastify'
import UploadErrorSlider from 'common/components/UploadErrorSlider'

// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import QrCode from 'react.qrcode.generator';

var httpURL = ''
// var queryString = require('querystring');
var httpURLCommon = ''

var lstBuildings = []
var lstBuildingRooms = []
var lstType = []
var buildingFile = ''
var tokenid = localStorage.getItem('tokenid')
var screenId = 5

class Building extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loader: 'none',
      opacity: 1,
      tokenid: tokenid,
      flg: true,
      flg1: true,
      buildingId: 0
    }
  }

  componentDidMount = () => {
    $('.Floor').hide()
    this.getBuildingList()

    lstType = [
      {
        roomTypeId: 1,
        roomType: 'Classroom'
      },
      {
        roomTypeId: 2,
        roomType: 'Lab'
      },
      {
        roomTypeId: 3,
        roomType: 'Staffroom'
      },
      {
        roomTypeId: 4,
        roomType: 'Library'
      },
      {
        roomTypeId: 5,
        roomType: 'Store'
      },
      {
        roomTypeId: 6,
        roomType: 'Washroom-Boys'
      },
      {
        roomTypeId: 7,
        roomType: 'Washroom-Girls'
      },
      {
        roomTypeId: 8,
        roomType: 'Washroom-Staffs'
      }
    ]

    this.fillRoomTypeCmb()
  }

  getBuildingList = () => {
    this.setState({ loader: 'block', opacity: 0.5 })
    axios({
      url: httpURL + '/sms/a/building/list',
      method: 'POST',
      params: { screenId },
      headers: { tokenid: this.state.tokenid },
      withCredentials: true
    })
      .then(response => {
        if (response.data.SvcStatus === 'Success') {
          lstBuildings = response.data.lstBuildings
          this.setBuidlingTable()
        } else {
          if (response.data.SvcMsg === 'You are not logged in') {
            setTimeout(function () {
              window.location.href = '/'
            }, 2000)
            return false
          } else if (
            response.data.SvcMsg === 'Your Session is expired, login again'
          ) {
            setTimeout(function () {
              window.location.href = '/'
            }, 2000)
            return false
          } else {
            toast.error(response.data.SvcMsg)
          }
        }
        this.setState({ loader: 'none', opacity: 1 })
      })
      .catch(error => {
        toast.error('Service failed ' + error)
      })
  }

  fillRoomTypeCmb () {
    $('#roomTypeCmb').empty()
    $('#roomTypeCmb').prepend(
      $('<option> </option>')
        .val(0)
        .html('Room Type')
    )
    for (var i = 0; i < lstType.length; i++) {
      var roomTypeId = lstType[i].roomTypeId
      var roomType = lstType[i].roomType
      $('#roomTypeCmb').append(
        $('<option> </option>')
          .val(roomTypeId)
          .html(roomType)
      )
    }
  }

  setBuidlingTable = () => {
    $('#buildingTableBody tr').remove()
    var table = document.getElementById('buildingTable')
    var tbody = document.getElementById('buildingTableBody')

    for (var i = 0; i < lstBuildings.length; i++) {
      var buildingId = lstBuildings[i].buildingId
      var buildingName = lstBuildings[i].buildingName
      var noOfFloors = lstBuildings[i].noOfFloors

      var row = document.createElement('tr')
      row.id = buildingId

      row.ondblclick = e => {
        $(e.target)
          .closest('tr')
          .addClass('selected')
          .siblings()
          .removeClass('selected')
        this.setBuidingForm(
          $(e.target)
            .closest('tr')
            .attr('id')
        )
        this.setState({
          buildingId: $(e.target)
            .closest('tr')
            .attr('id'),
          flg: false
        })
        $('.Floor').show()
        var buildingName = $('#buildingTableBody tr.selected')
          .find('td:eq(1)')
          .text()
        $('#buildingRoomValue').val(buildingName)
        $('#buildingRoomValue').attr('disabled', true)
        this.getBuildingRoomList(
          $(e.target)
            .closest('tr')
            .attr('id')
        )
      }

      this.addTD(row, buildingId, 'no-display')
      this.addTD(row, buildingName, 'text-center')
      this.addTD(row, noOfFloors, 'text-center')
      this.addTDPdf(row, 'glyphicon glyphicon-file', 'text-center')

      tbody.append(row)
    }
    table.append(tbody)
  }

  addTDPdf (row, val, classname) {
    var cell = document.createElement('td')
    cell.className = classname
    var span = document.createElement('span')
    span.className = val
    span.style.cursor = 'pointer'
    span.style.width = '100%'
    span.title = 'Building Documents'
    span.onclick = () => {
      $(row)
        .addClass('selected')
        .siblings()
        .removeClass('selected')
      $('td').removeClass('selected')
      var buildingId = $('#buildingTableBody tr.selected')
        .find('td:eq(0)')
        .text()
      axios({
        url: httpURL + '/sms/a/building/viewimg?buildingId=' + buildingId,
        method: 'GET',
        params: { screenId },
        headers: { tokenid: this.state.tokenid },
        responseType: 'arraybuffer',
        withCredentials: true
      })
        .then(response => {
          var temp = JSON.stringify(response)
          // // console.log(response.status)

          if (response.status !== 200) {
            if (response.data.SvcMsg === 'You are not logged in') {
              setTimeout(function () {
                window.location.href = '/'
              }, 2000)
              return false
            } else if (
              response.data.SvcMsg === 'Your Session is expired, login again'
            ) {
              setTimeout(function () {
                window.location.href = '/'
              }, 2000)
              return false
            } else {
              toast.error('No Building File For This Building Id' + buildingId)
            }
          } else {
            var file = new Blob([response.data], { type: 'image/jpeg' })
            var fileURL = URL.createObjectURL(file)
            window.open(fileURL)
          }
        })
        .catch(e => {
          toast.error('Service failed: ' + e)
        })
    }
    cell.append(span)
    row.append(cell)
  }

  setBuidingForm = buildingId => {
    var buildingData = this.getBuildingData(buildingId)

    $('#buildingValue').val(buildingData.buildingName)
    $('#no_of_floors').val(buildingData.noOfFloors)
  }

  getBuildingData (buildingId) {
    for (var i = 0; i < lstBuildings.length; i++) {
      if (parseInt(buildingId) === lstBuildings[i].buildingId)
        return lstBuildings[i]
    }
  }

  addTD (row, val, classname) {
    var cell = document.createElement('td')
    cell.className = classname
    var text = document.createTextNode(val)
    cell.append(text)
    row.append(cell)
  }

  addBuildingsReady = () => {
    $('#buildingAddForm').trigger('reset')
    $('#buildingTableBody tr').removeClass('selected')
    $('.Floor').hide()
    this.addRoomsReady()
  }
  editBuilding = () => {
    var Building = {}
    var buildingId = 1
    var buildingName = $('#buildingValue').val()
    var noOfFloors = $('#no_of_floors').val()

    if (buildingName === '') {
      toast.warning('Please Enter Building Name')
      $('#buildingValue').focus()
      return false
    }
    if (noOfFloors === '') {
      toast.warning('Please Enter No. of Floors')
      $('#no_of_floors').focus()
      return false
    }

    Building = {
      buildingId: buildingId,
      buildingName: buildingName,
      noOfFloors: noOfFloors
    }

    var lstBuild = this.getlstBuild(buildingId)
    lstBuildings.splice(lstBuild, 1, Building)
    lstBuildings.push(Building)
    this.setBuidlingTable()
  }

  getlstBuild (buildingId) {
    for (var i = 0; i < lstBuildings.length; i++) {
      if (lstBuildings[i].buildingId === parseInt(buildingId)) {
        return i
      }
    }
    return -1
  }

  addBuilding = () => {
    // // console.log('state.tokenid=' + this.state.tokenid)
    var buildingArr = {}
    var buildingId = 0
    var buildingName = $('#buildingValue').val()
    var noOfFloors = $('#no_of_floors').val()

    if (buildingName === '') {
      toast.warning('Please Enter Building Name')
      $('#buildingValue').focus()
      return false
    }
    if (noOfFloors === '') {
      toast.warning('Please Enter No. of Floors')
      $('#no_of_floors').focus()
      return false
    }

    this.setState({ loader: 'block', opacity: 0.5 })
    buildingArr = {
      buildingId: buildingId,
      buildingName: buildingName,
      noOfFloors: noOfFloors
    }
    var formData = new FormData()

    formData.append('building', JSON.stringify(buildingArr))
    formData.append('buildingFile', buildingFile)
    formData.append('screenId', screenId)

    axios({
      url: httpURL + '/sms/a/building/save',
      method: 'POST',
      data: formData,
      headers: { tokenid: this.state.tokenid },
      withCredentials: true
    })
      .then(response => {
        if (response.data.SvcStatus === 'Success') {
          toast.success(response.data.SvcMsg)
          this.getBuildingList()
          this.addBuildingsReady()
        } else {
          toast.error(response.data.SvcMsg)
        }
        this.setState({ loader: 'none', opacity: 1 })
      })
      .catch(e => {
        toast.error('Service failed ' + e)
      })
  }

  editBuilding = () => {
    // // console.log('tokenid=' + tokenid)
    var buildingArr = {}
    var buildingId = $('#buildingTableBody tr.selected')
      .find('td:eq(0)')
      .text()
    var buildingName = $('#buildingValue').val()
    var noOfFloors = $('#no_of_floors').val()
    var formData = new FormData()

    if (buildingName === '') {
      toast.warning('Please Enter Building Name')
      $('#buildingValue').focus()
      return false
    }
    if (noOfFloors === '') {
      toast.warning('Please Enter No. of Floors')
      $('#no_of_floors').focus()
      return false
    }

    this.setState({ loader: 'block', opacity: 0.5 })
    buildingArr = {
      buildingId: buildingId,
      buildingName: buildingName,
      noOfFloors: noOfFloors
    }
    formData.append('building', JSON.stringify(buildingArr))
    formData.append('buildingFile', buildingFile)
    formData.append('screenId', screenId)

    axios({
      url: httpURL + '/sms/a/building/save',
      method: 'POST',
      data: formData,
      headers: { tokenid: this.state.tokenid },
      withCredentials: true
    })
      .then(response => {
        if (response.data.SvcStatus === 'Success') {
          toast.success(response.data.SvcMsg)
          this.getBuildingList()
          this.addBuildingsReady()
        } else {
          toast.error(response.data.SvcMsg)
        }
        this.setState({ loader: 'none', opacity: 1 })
      })
      .catch(e => {
        toast.error('Service failed ' + e)
      })
  }

  getBuildingRoomList = buildingId => {
    this.setState({ loader: 'block', opacity: 0.5 })
    axios({
      url: httpURL + '/sms/a/building/rooms/list',
      method: 'POST',
      params: { buildingId: buildingId, screenId },
      headers: { tokenid: this.state.tokenid },
      withCredentials: true
    })
      .then(response => {
        if (response.data.SvcStatus === 'Success') {
          lstBuildingRooms = response.data.lstBuildingRooms
          this.setRoomTable()
        } else {
          toast.error(response.data.SvcMsg)
        }
        this.setState({ loader: 'none', opacity: 1 })
      })
      .catch(error => {
        toast.error('Service failed ' + error)
      })
  }

  setRoomTable = () => {
    $('#roomTableBody tr').remove()
    var table = document.getElementById('roomTable')
    var tbody = document.getElementById('roomTableBody')

    for (var i = 0; i < lstBuildingRooms.length; i++) {
      //var buildingId = lstBuildingRooms[i].buildingId;
      var roomId = lstBuildingRooms[i].roomId
      var floor = lstBuildingRooms[i].floor
      var room = lstBuildingRooms[i].room
      var roomType = lstBuildingRooms[i].roomType

      var row = document.createElement('tr')
      row.id = roomId

      row.onclick = e => {
        $(e.target)
          .closest('tr')
          .addClass('selected')
          .siblings()
          .removeClass('selected')
        this.setRoomForm(
          $(e.target)
            .closest('tr')
            .attr('id')
        )
        this.setState({
          flg1: false
        })
      }

      this.addTD(row, roomId, 'no-display')
      this.addTD(row, floor, 'text-center')
      this.addTD(row, room, 'text-center')
      this.addTD(row, roomType, 'text-center')

      tbody.append(row)
    }
    table.append(tbody)
  }

  setRoomForm = roomId => {
    var roomData = this.getRoomData(roomId)

    $('#roomValue').val(roomData.room)
    $('#floors').val(roomData.floor)
    $('#roomTypeCmb').val(roomData.roomTypeId)
    $('#capacity').val(roomData.capacity)
    $('#roomValue').val(roomData.room)
    $('#dimensions').val(roomData.dimensions)
  }

  getRoomData (roomId) {
    for (var i = 0; i < lstBuildingRooms.length; i++) {
      if (parseInt(roomId) === lstBuildingRooms[i].roomId)
        return lstBuildingRooms[i]
    }
  }
  addRoomsReady = () => {
    $('#roomAddForm').trigger('reset')
    $('#roomTableBody tr').removeClass('selected')
    var buildingName = $('#buildingTableBody tr.selected')
      .find('td:eq(1)')
      .text()
    $('#buildingRoomValue').val(buildingName)
  }

  uploadImg = () => {
    $('#buildingImg').on('change', function (event) {
      if (!event.target.files) return
      var files = event.target.files //FileList object
      buildingFile = files[0]
    })
  }

  maintainBuilding = () => {
    if (this.state.flg) {
      this.addBuilding()
    } else {
      this.editBuilding()
    }
  }
  maintainRoom = () => {
    if (this.state.flg1) {
      this.addRoom()
    } else {
      this.editRoom()
    }
  }

  addRoom = () => {
    var roomArr = {}
    var roomId = 0
    var buildingId = $('#buildingTableBody tr.selected')
      .find('td:eq(0)')
      .text()
    var room = $('#roomValue').val()
    var floor = $('#floors').val()
    var roomTypeId = $('#roomTypeCmb').val()
    var capacity = $('#capacity').val()
    var dimensions = $('#dimensions').val()

    if (floor === '') {
      toast.warning('Please Enter Floor')
      $('#floors').focus()
      return false
    }
    if (room === '') {
      toast.warning('Please Enter Room')
      $('#room').focus()
      return false
    }
    if (dimensions === '') {
      toast.warning('Please Enter Dimension')
      $('#dimensions').focus()
      return false
    }
    if (roomTypeId === 0) {
      toast.warning('Please Select Valid Room Type')
      $('#roomTypeCmb').focus()
      return false
    }
    if (capacity === '') {
      toast.warning('Please Enter Capacity')
      $('#capacity').focus()
      return false
    }

    this.setState({ loader: 'block', opacity: 0.5 })
    roomArr = {
      buildingId: buildingId,
      roomId: roomId,
      room: room,
      floor: floor,
      roomTypeId: roomTypeId,
      dimensions: dimensions,
      capacity: capacity
    }

    axios({
      url: httpURL + '/sms/a/building/rooms/save',
      method: 'POST',
      params: { buildingRoom: JSON.stringify(roomArr), screenId },
      headers: { tokenid: this.state.tokenid },
      withCredentials: true
    })
      .then(response => {
        if (response.data.SvcStatus === 'Success') {
          toast.success(response.data.SvcMsg)
          this.getBuildingRoomList(buildingId)
          this.addRoomsReady()
        } else {
          toast.error(response.data.SvcMsg)
        }
        this.setState({ loader: 'none', opacity: 1 })
      })
      .catch(e => {
        toast.error('Service failed: ' + e)
      })
  }

  editRoom = () => {
    var roomArr = {}
    var roomId = $('#roomTableBody tr.selected')
      .find('td:eq(0)')
      .text()
    var buildingId = $('#buildingTableBody tr.selected')
      .find('td:eq(0)')
      .text()
    var room = $('#roomValue').val()
    var floor = $('#floors').val()
    var roomTypeId = $('#roomTypeCmb').val()
    var capacity = $('#capacity').val()
    var dimensions = $('#dimensions').val()

    if (floor === '') {
      toast.warning('Please Enter Floor')
      $('#floors').focus()
      return false
    }
    if (room === '') {
      toast.warning('Please Enter Room')
      $('#room').focus()
      return false
    }
    if (dimensions === '') {
      toast.warning('Please Enter Dimension')
      $('#dimensions').focus()
      return false
    }
    if (roomTypeId === 0) {
      toast.warning('Please Select Valid Room Type')
      $('#roomTypeCmb').focus()
      return false
    }
    if (capacity === '') {
      toast.warning('Please Enter Capacity')
      $('#capacity').focus()
      return false
    }

    this.setState({ loader: 'block', opacity: 0.5 })
    roomArr = {
      buildingId: buildingId,
      roomId: roomId,
      room: room,
      floor: floor,
      roomTypeId: roomTypeId,
      dimensions: dimensions,
      capacity: capacity
    }

    axios({
      url: httpURL + '/sms/a/building/rooms/save',
      method: 'POST',
      params: { buildingRoom: JSON.stringify(roomArr), screenId },
      headers: { tokenid: this.state.tokenid },
      withCredentials: true
    })
      .then(response => {
        if (response.data.SvcStatus === 'Success') {
          toast.success(response.data.SvcMsg)
          this.getBuildingRoomList(buildingId)
          this.addRoomsReady()
        } else {
          toast.error(response.data.SvcMsg)
        }
        this.setState({ loader: 'none', opacity: 1 })
      })
      .catch(e => {
        toast.error('Service failed: ' + e)
      })
  }

  getWingId = wingId => {
    // // console.log('wingId' + wingId)
  }
  triggerUploadExcel = () => {
    $('#excelUpload').trigger('click')
  }

  uploadExcel = event => {
    if (!event.target.files) {
      this.setState({ loader: 'none', opacity: 1 })
      return
    }

    var files = event.target.files //FileList object
    files = files[0]
    var formData = new FormData()
    formData.append('buildingUploadExcelFile', files)
    this.uploadBuildingExcelSvc(formData, this.cbBldgExcelUpload, this)
  }

  cbBldgExcelUpload (data, ctx) {
    if (data.status === 'Success') {
      toast.success(data.message)
      // $('#btn_showerror').trigger('click');
    } else if (data.status === 'Failure') {
      toast.error(data.message)
      // // console.log(data.hasOwnProperty('errors'))
      // // console.log(data.errors.length !== 0)
      // // console.log(ctx)
      if (data.hasOwnProperty('errors') && data.errors.length !== 0) {
        ctx.setErrorList(data.errors)
        // // console.log('control is here L194')
        ctx.showErrors()
      }
    } else {
      if (data.message === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (data.message === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else {
        toast.error(data.message)
      }
    }
    ctx.setState({ loader: 'none', opacity: 1 })
  }

  setErrorList = async errors => {
    // // console.log(errors)
    await this.setState({ lstError: errors })
  }
  showSlider () {
    $('body').addClass('openSlider')
  }
  showErrors = () => {
    // // console.log('slider should be open')
    this.setState({ flgSlider: true })
    this.showSlider()
  }

  uploadBuildingExcelSvc (formData, cb, ctx) {
    var status = 'Success'
    var obj = { status: status }
    axios({
      url: httpURLCommon + '/sms/building/excel/upload',
      method: 'POST',
      data: formData,
      headers: { tokenid },
      withCredentials: true
    })
      .then(response => {
        if (response.data.SvcStatus === 'Success') {
          obj.message = response.data.SvcMsg
          obj.status = response.data.SvcStatus
        } else {
          obj.message = response.data.SvcMsg
          obj.status = response.data.SvcStatus
          obj.errors = response.data.errors
        }
        cb(obj, ctx)
      })
      .catch(e => {
        obj.status = 'Exception'
        obj.message = e
        cb(obj)
      })
  }
  closeSlider = () => {
    $('body').removeClass('openSlider')
  }

  render () {
    httpURL = lstModules['building'].url + ':' + lstModules['building'].port
    httpURLCommon = lstModules['main'].url + ':' + lstModules['main'].port
    return (
      <div style={{ height: '100%' }}>
        <Header wingFromChild={this.getWingId} />
        <ToastContainer autoClose={3000} hideProgressBar />
        <div
          style={{
            position: 'fixed',
            zIndex: 10000,
            width: 100,
            height: 100,
            marginLeft: -50,
            marginTop: -50,
            left: '50%',
            top: '50%',
            display: this.state.loader
          }}
        >
          <img
            className='loderImg'
            src={require('common/images/loader.gif')}
            alt='Loader...'
          />
        </div>
        <div
          className='mainContainer Building'
          style={{ opacity: this.state.opacity }}
        >
          <div className='col-md-11 col-centered'>
            <div className='col-md-5  col-xs-6 BuildingWrap  padding-remove'>
              <table
                className='tableLayout1 Building'
                style={{ width: this.state.width }}
              >
                <thead>
                  <tr>
                    <th style={{ display: 'none' }}> Building Id </th>
                    <th style={{ width: 40 }}> Building </th>
                    <th style={{ width: 40 }}> No. of floors </th>
                    <th style={{ width: 30 }}> Action </th>
                  </tr>
                </thead>
              </table>
              <div
                className='buildingTableDiv'
                style={{
                  height: '180px',
                  overflowY: 'scroll',
                  border: '1px solid #ccc'
                }}
              >
                <table id='buildingTable' className='tableLayout'>
                  <colgroup>
                    <col width='40' />
                    <col width='40' />
                    <col width='30' />
                  </colgroup>
                  <tbody id='buildingTableBody'></tbody>
                </table>
              </div>
            </div>
            <div className='col-xs-1 '>
              <div>
                <span
                  id='addBuilding'
                  title='Add Buildings'
                  onClick={this.addBuildingsReady}
                  className='addIcon glyphicon glyphicon-plus-sign'
                ></span>
              </div>
              <div>
                <span
                  id='uploadBuilding'
                  title='Upload Building'
                  onClick={this.triggerUploadExcel}
                  className='addIcon glyphicon glyphicon-upload'
                ></span>
              </div>
              <input
                key={new Date().getTime()}
                type='file'
                id='excelUpload'
                className='hidden'
                accept='.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                onChange={e => this.uploadExcel(e)}
              />
              <div>
                <span
                  id='viewErrorList'
                  title='View Error List'
                  onClick={this.showErrors}
                  className='addIcon glyphicon glyphicon-list-alt hidden'
                ></span>
              </div>
            </div>
            <div className='col-md-6 col-xs-8 padding-remove builingRoomsFormWrap'>
              <form
                id='buildingAddForm'
                className='form-horizontal formWrapper'
              >
                <div className='form-group col-md-6 padding-remove'>
                  <label className='control-label col-sm-6'>
                    Building
                    <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                  </label>
                  <div className='col-sm-6 padding-remove'>
                    <input
                      autoComplete='off'
                      id='buildingValue'
                      type='text'
                      className='form-control '
                      placeholder='Enter Building Name'
                    />
                  </div>
                </div>
                <div className='form-group col-md-6 padding-remove'>
                  <label className='control-label col-sm-6'>
                    No. of floors
                    <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                  </label>
                  <div className='col-sm-6 padding-remove'>
                    <input
                      autoComplete='off'
                      id='no_of_floors'
                      type='text'
                      className='form-control '
                      placeholder='Enter No. of Floor'
                    />
                  </div>
                </div>
                <div className='form-group col-md-6 padding-remove'>
                  <label className='control-label col-sm-6'>
                    Image<span style={{ color: 'red', marginLeft: 2 }}></span>
                  </label>
                  <div className='col-sm-6 padding-remove'>
                    <input
                      autoComplete='off'
                      id='buildingImg'
                      onClick={this.uploadImg}
                      type='file'
                      accept='image/*'
                      className='form-control '
                    />
                  </div>
                </div>
                <div className='form-group col-md-6'>
                  <div className='col-sm-12 text-center userButtonWrap'>
                    <button
                      type='button'
                      style={{ marginTop: 10 }}
                      title='Save'
                      onClick={this.maintainBuilding}
                      className='btn btn-info'
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className='col-md-12 padding-remove Floor'>
          <div className='col-md-11 col-centered'>
            <div className='col-md-5  col-xs-6 FloorWrap  padding-remove'>
              <table
                className='tableLayout1 Floor'
                style={{ width: this.state.width }}
              >
                <thead>
                  <tr>
                    <th style={{ display: 'none' }}> Room Id </th>
                    <th style={{ width: 30 }}> Floor </th>
                    <th style={{ width: 40 }}> Room </th>
                    <th style={{ width: 40 }}> Type </th>
                  </tr>
                </thead>
              </table>
              <div
                className='roomTableDiv'
                style={{
                  height: '220px',
                  overflowY: 'scroll',
                  border: '1px solid #ccc'
                }}
              >
                <table id='roomTable' className='tableLayout'>
                  <colgroup>
                    <col width='30' />
                    <col width='40' />
                    <col width='40' />
                  </colgroup>
                  <tbody id='roomTableBody'></tbody>
                </table>
              </div>
            </div>
            <div className='col-xs-1 ' style={{ marginTop: 15 }}>
              <span
                id='addBuildingRoom'
                title='Add Building Rooms'
                onClick={this.addRoomsReady}
                className='addIcon glyphicon glyphicon-plus-sign'
              ></span>
            </div>
            <div className='col-md-6 col-xs-8 padding-remove builingRoomsFormWrap'>
              <form id='roomAddForm' className='form-horizontal formWrapper'>
                <div className='form-group col-md-6 padding-remove'>
                  <label className='control-label col-sm-5'>
                    Building
                    <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                  </label>
                  <div className='col-sm-6 padding-remove'>
                    <input
                      autoComplete='off'
                      id='buildingRoomValue'
                      type='text'
                      className='form-control '
                      placeholder='Enter Building Name'
                    />
                  </div>
                </div>
                <div className='form-group col-md-6 padding-remove'>
                  <label className='control-label col-sm-5'>
                    Floor<span style={{ color: 'red', marginLeft: 2 }}>*</span>
                  </label>
                  <div className='col-sm-6 padding-remove'>
                    <input
                      autoComplete='off'
                      id='floors'
                      type='text'
                      className='form-control '
                      placeholder='Enter Floor'
                    />
                  </div>
                </div>
                <div className='form-group col-md-6 padding-remove'>
                  <label className='control-label col-sm-5'>
                    Room<span style={{ color: 'red', marginLeft: 2 }}>*</span>
                  </label>
                  <div className='col-sm-6 padding-remove'>
                    <input
                      autoComplete='off'
                      id='roomValue'
                      type='text'
                      className='form-control '
                      placeholder='Enter Room'
                    />
                  </div>
                </div>
                <div className='form-group col-md-6 padding-remove'>
                  <label className='control-label col-sm-5'>
                    Dimensions
                    <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                  </label>
                  <div className='col-sm-6 padding-remove'>
                    <input
                      autoComplete='off'
                      id='dimensions'
                      type='text'
                      className='form-control '
                      placeholder='Enter Dimension'
                    />
                  </div>
                </div>
                <div className='form-group col-md-6 padding-remove'>
                  <label className='control-label col-sm-5'>Type</label>
                  <div className='col-sm-6 padding-remove'>
                    <select
                      onChange={this.changeRoomType}
                      className='form-control'
                      style={{ width: '100%' }}
                      id='roomTypeCmb'
                    ></select>
                  </div>
                </div>
                <div className='form-group col-md-6 padding-remove'>
                  <label className='control-label col-sm-5'>
                    capacity
                    <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                  </label>
                  <div className='col-sm-6 padding-remove'>
                    <input
                      autoComplete='off'
                      id='capacity'
                      type='text'
                      className='form-control '
                      placeholder='Enter Capacity'
                    />
                  </div>
                </div>
                <div className='form-group'>
                  <div className='col-sm-12 text-center userButtonWrap'>
                    <button
                      type='button'
                      title='Save'
                      onClick={this.maintainRoom}
                      className='btn btn-info'
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className='slider halfWidthSlider'>
          {this.state.flgSlider && (
            <UploadErrorSlider
              heading='Building'
              lstErrors={this.state.lstError}
              countError={this.state.lstError.length || 0}
              removeClassFromBody={this.closeSlider}
            />
          )}
        </div>
      </div>
    )
  }
}
export default Building
