import React, { useState, useEffect } from "react";
import axios from 'axios'
import { toast, ToastContainer } from "react-toastify";
import lstModules from 'common/json/lstModules.json';
const httpURL = lstModules['payrollSetup'].url + ':' + lstModules['payrollSetup'].port;
var tokenid = localStorage.getItem('tokenid');
function GradeTable(props){
  const [loader, setLoader] = useState('none')
    const [lstGrade,setLstGrade]=useState([])
    const [selObj,setSelObj]=useState({})
    const [selIdx,setSelIdx]=useState()
    useEffect(()=>{
      setLoader('block')
        getLstGrade(cbGetLstGrade)
    },[])
    function handleSelRow(param){
        setSelObj(lstGrade[param])
        setSelIdx(param)
        props.getSelData(lstGrade[param])
      }
    function getLstGrade(cbGetLstGrade){
        axios(
          {
              url: httpURL +"/sms/grade/list",
              method: 'POST',
              params: {},
              headers: { 'tokenid': tokenid },
              withCredentials: true
          }
      ).then(
          (Response) => {
              var data = Response.data
  
              cbGetLstGrade(data)
  
          }
      ).catch((error) => {
          console.error(error)
      });
      }
      function cbGetLstGrade(data){
        if(data.SvcStatus==='Success'){
          setLstGrade(data.lstGrade)
        }
        else{
          toast.error(data.SvcMsg)
        }
        setLoader('none')
    }
    const colgrp = (
        <colgroup>
          <col width="8%"></col>
          <col width="8%"></col>
          <col width="8%"></col>
        </colgroup>
      );
return(<div style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
<div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
  <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
</div>

      <table className="col-sm-8 col-sm-offset-2">
        <thead>
          <tr>
            <th>
              Grade
            </th>
          </tr>
        </thead>
      </table>
      <table className="col-sm-8  col-sm-offset-2">
      {colgrp}
          <thead>
          <tr>
              <th>Grade</th>
              <th>Min Basic</th>
              <th>Max Basic</th>
          </tr>
          </thead>
          <tbody>
              {
                  lstGrade.map((obj,idx)=>{
                      return (
                          <tr onClick={(e)=>handleSelRow(idx)} className={selIdx === idx ? 'selected' : ''} key={idx}>
                              <td>{obj.grade}</td>
                              <td style={{textAlign:'right'}}>{Number(obj.minBasic).toLocaleString("en-in")}</td>
                              <td style={{textAlign:'right'}}>{Number(obj.maxBasic).toLocaleString("en-in")}</td>
                          </tr>   
                      )
                  })
              }
          </tbody>
      </table>

</div>)
}

export default GradeTable;