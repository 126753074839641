import React from 'react';

const ChkLbl = (props) => {
    return (
        <div className='col-sm-2'>
            <label className='checkbox-inline control-label'>
                <input type='checkbox' className='' defaultChecked={props.dataValue} onChange={(e)=>props.onChecked(e.target.checked)} />
                {props.label}
            </label>
        </div>
    );
};

export default ChkLbl;
