import React, { useState, useEffect } from 'react';

import GroupRenderer from './groupRenderer.js';

const GroupSectionSelector = ({ lstSection, lstGroup, setLstGroup }) => {
    const [tempLstGroup, setTempLstGroup] = useState([]);
    const [lstUnselectedSection, setLstUnselectedSection] = useState([]);
    const [rowMap, setRowMap] = useState({});
    const [maxRows, setMaxRows] = useState(0);

    useEffect(() => {
        // console.log("lstSection in groupSectionSelector", lstSection);
        setInitialData(lstSection);
        setMaxRows(Math.floor((lstSection.length) / 2));
    }, [lstSection]);

    const setInitialData = async (pLstSection) => {
        console.log("setInitialData called, pLstSection", pLstSection, ", lstGroup", lstGroup);
        let idArray = pLstSection.map(item => item.secId);
        const newRowMap = { ...rowMap };
        lstGroup.forEach((group) => {
            const { ssgId, lstSecId } = group;
            if (Object.values(newRowMap).some(row => row.ssgId === ssgId)) {
                idArray = idArray.filter(id => !lstSecId.includes(id));
            } else {
                const newRowNo = Object.keys(newRowMap).length + 1;
                newRowMap[newRowNo] = { ssgId, lstSecId };
                idArray = idArray.filter(id => !lstSecId.includes(id));
            }
        });

        const emptyRows = Math.floor((idArray.length) / 2);  // Calculate how many empty rows to add
        for (let i = 1; i <= emptyRows; i++) {
            const newRowNo = Object.keys(newRowMap).length + 1;  // Determine the next available row number
            newRowMap[newRowNo] = { ssgId: 0, lstSecId: [] };  // Add empty row
        }
        setLstUnselectedSection(idArray);
        setRowMap(newRowMap);
    };



    const handleSectionChange = async (groupIndex, secId) => {
        console.log(`handleSectionChange called for group ${groupIndex} and section ${secId}`);
        console.log("before updation rowMap", rowMap);
        console.log("before updation lstUnselectedSection", lstUnselectedSection);

        let localUnselectedSection = [...lstUnselectedSection];

        await setRowMap((prevRowMap) => {
            const updatedRowMap = { ...prevRowMap };

            if (!updatedRowMap[groupIndex]) {
                updatedRowMap[groupIndex] = { ssgId: 0, lstSecId: [] };
            }

            const group = updatedRowMap[groupIndex];

            if (group.lstSecId.includes(secId)) {
                group.lstSecId = group.lstSecId.filter((id) => id !== secId);
                localUnselectedSection.push(secId);
            } else {
                group.lstSecId.push(secId);
                localUnselectedSection = localUnselectedSection.filter((id) => id !== secId);
            }

            console.log("after step 1 rowMap", updatedRowMap);
            console.log("after step 1 localUnselectedSection", localUnselectedSection);

            const idArray = localUnselectedSection.filter((id) => !group.lstSecId.includes(id));
            const requiredEmptyRows = Math.floor((idArray.length) / 2);


            const areArraysEqual = idArray.length === localUnselectedSection.length &&
                idArray.every((value, index) => value === localUnselectedSection[index]);
            console.log("Are idArray and localUnselectedSection equal?", areArraysEqual);

            console.log("requiredEmptyRows after step2", requiredEmptyRows);

            // Count rows with non-zero ssgId and empty lstSecId
            const emptyNonZeroRows = Object.values(updatedRowMap).filter(
                (row) => row.ssgId !== 0 && row.lstSecId.length === 0
            ).length;

            console.log("emptyNonZeroRows after step3", emptyNonZeroRows);

            const countRequired = requiredEmptyRows - emptyNonZeroRows;
            console.log("countRequired before step4", countRequired);

            const emptyZeroSsgIdRows = Object.keys(updatedRowMap).filter(
                (key) => updatedRowMap[key].ssgId === 0 && updatedRowMap[key].lstSecId.length === 0
            );

            let currentRows = Object.keys(rowMap).length;
            if (emptyZeroSsgIdRows.length < countRequired && currentRows < maxRows) {
                const rowsToAdd = countRequired - emptyZeroSsgIdRows.length;
                for (let i = 0; i < rowsToAdd; i++) {
                    const newRowNo = Math.max(...Object.keys(updatedRowMap).map(Number)) + 1;
                    updatedRowMap[newRowNo] = { ssgId: 0, lstSecId: [] };
                }
            } else if (emptyZeroSsgIdRows.length > countRequired) {
                const rowsToRemove = emptyZeroSsgIdRows.length - countRequired;
                emptyZeroSsgIdRows.slice(0, rowsToRemove).forEach((rowNo) => {
                    console.log("Removing row:", rowNo);
                    delete updatedRowMap[rowNo];
                });
            }

            return updatedRowMap;
        });

        setLstUnselectedSection(localUnselectedSection);
        console.log("after updation rowMap", rowMap);
        console.log("after updation localUnselectedSection", localUnselectedSection);

        const updatedLstGroup = Object.keys(rowMap).map((groupIndex) => {
            const group = rowMap[groupIndex];
            return {
                ssgId: group.ssgId,
                lstSecId: group.lstSecId,
            };
        });

        // Update lstGroup with the new data
        setLstGroup(updatedLstGroup);
    };




    const checkSectionStatus = (groupIndex, secId) => {
        console.log(`checkSectionStatus called for group ${groupIndex} and section ${secId}`);
        // Placeholder method for checking section status
    };


    return (
        <div className="form-group col-md-12 padding-remove">
            <div className="form-group col-md-12 padding-remove" key="section-header">
                <label className="control-label col-md-3" style={{ padding: "7px 0 0 0" }}></label>
                <div className="col-sm-8 padding-remove daysWrapper">
                    {lstSection && lstSection.length ? (
                        lstSection.map((section) => (
                            <div key={section.secId} className="checkbox-inline">
                                <span>{section.cls}</span>
                            </div>
                        ))
                    ) : null}
                </div>
            </div>

            <GroupRenderer
                rowMap={rowMap}
                lstSection={lstSection}
                lstUnselectedSection={lstUnselectedSection}
                handleSectionChange={handleSectionChange}
            />
        </div>
    );
};

export default GroupSectionSelector;
