import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
// var httpURL = lstModules['transport'].url + ':' + lstModules['transport'].port;
var httpURL = lstModules['pDash'].url + ':' + lstModules['pDash'].port;
var tokenid = localStorage.getItem('tokenid');

function getAttendanceSummarySvc(callBack) {
    axios(
        {
            url: httpURL + "/sms/dashboard/principal/attendance/summary",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)

        }
    ).catch((error) => {
        // // console.log("Test List Catch error")
    });
}
function getFeeSummarySvc(callBack) {
    axios(
        {
            url: httpURL + "/sms/dashboard/principal/fees/summary",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)

        }
    ).catch((error) => {
        // // console.log("Test List Catch error")
    });
}
function getTchSummarySvc(callBack) {
    axios(
        {
            url: httpURL + "/sms/dashboard/principal/tch/summary",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)

        }
    ).catch((error) => {
        // // console.log("Test List Catch error")
    });
}
function getEnquirySummarySvc(callBack) {
    axios(
        {
            url: httpURL + "/sms/dashboard/principal/enquiry/summary",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)

        }
    ).catch((error) => {
        // // console.log("Enquiry Summary Service Failed")
    });
}

function getTaskSummarySvc(callBack) {
    axios(
        {
            url: httpURL + "/sms/dashboard/principal/task/summary",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)

        }
    ).catch((error) => {
        // // console.log("Test List Catch error")
    });
}
function getFeedbackSummarySvc(callBack) {
    axios(
        {
            url: httpURL + "/sms/dashboard/principal/feedback/summary",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)

        }
    ).catch((error) => {
        // // console.log("Feedback Summary Service Failed")
    });
}

function getStudentDtlsSvc(callBack) {
    axios(
        {
            url: httpURL + "/sms/dashboard/principal/attendance/student/dtls",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)

        }
    ).catch((error) => {
        // // console.log("Test List Catch error")
    });
}
function getCompletionDtlsSvc(callBack) {
    axios(
        {
            url: httpURL + "/sms/dashboard/principal/attendance/completion/dtls",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)

        }
    ).catch((error) => {
        // // console.log("Test List Catch error")
    });
}
function getOnTimeDtlsSvc(callBack) {
    axios(
        {
            url: httpURL + "/sms/dashboard/principal/attendance/ontime/dtls",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)

        }
    ).catch((error) => {
        // // console.log("Test List Catch error")
    });
}

export { getAttendanceSummarySvc, getStudentDtlsSvc, getCompletionDtlsSvc, getOnTimeDtlsSvc, getTaskSummarySvc, getFeeSummarySvc, getTchSummarySvc, getEnquirySummarySvc, getFeedbackSummarySvc };