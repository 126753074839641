import React, { useEffect, useState } from 'react';
import 'sms/tt/subject/css/tabList.css'
import 'common/css/master.css'

const SectionListSyllabus = (props) => {
    const [lstSection, setLstSection] = useState(props.lstSection)
    useEffect(() => {
        // // console.log("props.selSectionId sectionList",props.selSectionId);
        setLstSection(props.lstSection);
        // // console.log("useEffect in SectionListSyllabus, props.lstSection", props.lstSection);
    }, [props.classId, props.sectionId, props.lstSection]);

    return (
        <div className="tab" style={{marginLeft:4}}>

            {lstSection.map((obj) => {
                var isSelected = obj.sectionId == props.selSectionId;
                return <div className={isSelected ? "sellected" : ""} onClick={(e) => props.sectionClick(obj.sectionId, obj.syllabusId)} key={obj.sectionId} style={{height:35}}>
                    <span hidden>{obj.sectionId}</span>
                    <span>{obj.section}</span>
                </div>
            })}
        </div>
    )

}
export default SectionListSyllabus;