import React from 'react'
import { useEffect,useState,useRef } from 'react'
import { recruitmentTchLstSvc } from './TeacherTableSvc'
import SliderType from './SliderType'
import '../css/TeacherTable.css'

const TeacherTable = (props) => {
    const[listrecruitTch,setListRecruitTch]=useState([])
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const[selectedIdx,setSelectedIdx] = useState()
    const[selectedTchId,setSelectedTchId] = useState()
    

    const ref = useRef()


    useEffect(()=>{
        recruitmentTchLstSvc(cbRecruitmentTchLstResponse)
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
              setIsMenuOpen(false)
            }
          }
      
          document.addEventListener("mousedown", checkIfClickedOutside)
      
          return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
          }
        }

    ,[isMenuOpen])
    function cbRecruitmentTchLstResponse(data){
        setListRecruitTch(data)

    }

    function rowClick(rec,idx){
      setSelectedIdx(idx)
      props.setTchId(rec.tchId)
    }
    function actionClick(pIdx, pTchId) {
        setSelectedTchId(pTchId)
        props.setTchId(pTchId)
        setIsMenuOpen(oldState => !oldState)
      }
    return (
        <div>
            <div className='container'>
     { <div>
        
       <div className='tablebodyWrapper' >
        <table className="tableLayout " >
        
          <thead>
            <tr>
              
              <th style={{ width: 100 }}> 
                <input  type="text"  className="form-control inp-search" placeholder="Name" />
              </th>
              <th  style={{ width: 100 }}>
                <input  type="text"  className="form-control inp-search" placeholder="Mobile No." />
              </th>
              <th style={{ width: 125 }}>
              <input  type="text" className="form-control inp-search" placeholder="eMail" />
              </th>
              <th style={{ width: 50 }}>Gender</th>
              <th style={{ width: 75 }}>
              <input  type="text" className="form-control inp-search" placeholder="City" />
              </th>
              <th  style={{ width: 125}}>Experience</th>
              <th style={{ width: 75}}>Action</th>

            </tr>
          </thead>
         <tbody>
          
          {listrecruitTch.map((rec,idx) =>( 
                <tr selected='0' className={idx === selectedIdx ? 'selected' : ''} key={idx} onClick={(event)=>rowClick(rec,idx)}  >
                <td>{rec.name}</td>
                <td>{rec.mobileNo}</td>
                <td>{rec.email}</td>
                <td>{rec.gender}</td>
                <td>{rec.city}</td>
                <td>{rec.experience}</td>
               

                
                <td className='text-center' >
                
                  <span className='glyphicon glyphicon-option-vertical' key={idx} onClick={(e) => actionClick(idx,rec.tchId)}>
                    <ul className='ulStyle1' style={{ display: isMenuOpen && rec.tchId === selectedTchId ? 'block' : 'none'  }}>
                      <li onClick={(e) => props.setFlgSliderType(SliderType.SLIDER_PERSONAL_DTLS)}>Edit Details</li>
                      <li onClick={(e) => props.setFlgSliderType(SliderType.SLIDER_ADDRESS_DTLS)}>Address Details</li>
                      <li onClick={(e) => props.setFlgSliderType(SliderType.SLIDER_EDUCATION)}>Education Details</li>
                      <li onClick={(e) => props.setFlgSliderType(SliderType.SLIDER_EXPERIENCE)}>Experience Details</li>
                      <li onClick={(e) => props.setFlgSliderType(SliderType.SLIDER_AWARD)}>Award Details</li>
                      <li onClick={(e) => props.setFlgSliderType(SliderType.SLIDER_CLS_SUBJECT)}>Class and Subject Details</li>
                      <li onClick={(e) => props.setFlgSliderType(SliderType.SLIDER_PROFILE)}>Profile</li>
                      <li onClick={(e) => props.setFlgSliderType(SliderType.SLIDER_ATTACHMENTS)}>Attachments</li>
                    </ul>
                  </span> </td>
                </tr>
                
                
          ))}
          
          </tbody>
         
          
        </table>
        </div>
        

        </div>}
        </div>
        </div>


    )
}

export default TeacherTable