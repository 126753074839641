import axios from "axios";
import lstModules from "common/json/lstModules.json"
import reactSelect from "react-select";

var httpURLCommon = lstModules['main'].url + ':' + lstModules['main'].port

var screenId = 33;
var tokenId = localStorage.getItem('tokenId');

export const getPtmDetails = (schoolId, ptmStudentId, sectionId, cbPtmDetails) => {
    var obj = { status: "Success", message: "" }
    var formData = new FormData();
    formData.append("schoolId", schoolId)
    formData.append("ptmStudentId", ptmStudentId)
    formData.append("sectionId", sectionId)
    axios({
        url: httpURLCommon + "/sms/ptm/student/feedback/dtls_public",
        method: "POST",
        data: formData,
        withCredentials: true
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        obj.feedbackDtls = response.data.feedbackDtls;
        cbPtmDetails(obj);
    }).catch((e) => {
        obj.status = "Exception";
        obj.message = "error";
        cbPtmDetails(obj);
    })
}

export const getTeachersSvc = (schoolId, sectionId, cbTeachersList) => {
    var obj = { status: "Success", message: "" }
    var formData = new FormData();
    formData.append("schoolId", schoolId)
    formData.append("sectionId", sectionId)
    axios({
        url: httpURLCommon + "/sms/mst/section/teacher/list_public",
        method: "POST",
        data: formData,
        withCredentials: true
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        obj.lstTeacher = response.data.lstTeacher;
        cbTeachersList(obj);
    }).catch((e) => {
        obj.status = "Exception";
        obj.message = "error";
        cbTeachersList(obj);
    })
}



export const getSubjectsSvc = (schoolId, sectionId, cbSubjectsList) => {
    var obj = { status: "Success", message: "" }
    // var formData = new FormData();
    // formData.append("schoolId", schoolId)
    // formData.append("sectionId", sectionId)
    axios({
        url: httpURLCommon + "/sms/mst/section/subject/list_public",
        method: "POST",
        params:{"schoolId":schoolId, "sectionId":sectionId},
        withCredentials: true
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        obj.lstSubject = response.data.lstSubject;
        cbSubjectsList(obj);
    }).catch((e) => {
        obj.status = "Exception";
        obj.message = "error";
        cbSubjectsList(obj);
    })
}

export const saveFeedbackSvc = (feedback, cbSaveFeedback) => {
    var obj = { status: "Success", message: "" }
    axios({
        url: httpURLCommon + "/sms/ptm/submit_feedback",
        method: "POST",
        params: { feedback: JSON.stringify(feedback) },
        headers: { tokenId: tokenId },
        withCredentials: true
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbSaveFeedback(obj);
    }).catch((e) => {
        obj.status = "Exception";
        obj.message = "error";
        cbSaveFeedback(obj);
    })
}