import React from 'react'

const ChkLbl = props => {
  return (
    <div className='col-sm-2'>
      <label className='checkbox-inline control-label'>
        <input
          type='checkbox'
          className=''
          onChange={e => props.onChange(e.target.checked)}
          checked={props.dataValue}
        />
        {props.label}
      </label>
    </div>
  )
}

export default ChkLbl
