import React, { useState } from "react";
import { toast } from 'react-toastify';
import { deletePaymentSvc } from "./feePaymentSvc";


const DeletePayment = (props) => {

    const [remarks, setRemarks] = useState("");

    const deletePayment = () => {
        // // console.log("deletePayment called");
        const RQPaymentDelete = {
            paymentId: props.paymentId,
            remarks: remarks
        }
        props.setLoader("block");
        props.setOpacity(0.5);
        deletePaymentSvc(RQPaymentDelete, deletePaymentResponse);
    }

    const deletePaymentResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            toast.success(obj.message);
            props.closePopup();
            props.refreshPage();
        }
        else {
            if (obj.message == 'You are not logged in') {
                toast.error('You are not logged in');
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                toast.error('Your Session is expired, login again');
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        props.setLoader("none");
        props.setOpacity(1);
    }

    return (
        <div style={{ display: "flex", justifyContent: "center", position: "absolute", padding: 0, top: "35%", left: "40%", backgroundColor: "#F8F8F8", width: 350, height: 280, zIndex: 1001, border: "1px solid #ccc" }}>
            <div className="col-sm-12 padding-remove" >
                <div className="col-sm-12 padding-remove">
                    <div className="col-sm-8" style={{ padding: "10px 2px", fontSize: "large" }}>
                        Delete Payment
                    </div>
                    <div className="col-sm-4 padding-remove" >
                        <button type="button"
                            title="Close Popup"
                            onClick={() => props.closePopup()}
                            className='btn btn-info text-center pull-right'>X</button>
                    </div>
                </div>
                <form >
                    <div className="form-group " >
                        <label className="control-label col-sm-6 " >Payment Date: </label>
                        <div className="col-sm-6 ">
                            <input autoComplete="off" type="text" className="form-control" value={props.paymentDt} disabled={true} />
                        </div>
                    </div>
                    <div className="form-group ">
                        <label className="control-label col-sm-6 " >Payment Amount: </label>
                        <div className="col-sm-6 ">
                            <input autoComplete="off" type="text" className="form-control" value={props.paymentAmt} disabled={true} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-sm-4" > Remarks <span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                        <div className="col-sm-12 ">
                            <textarea
                                className='form-control'
                                value={remarks} 
                                onChange={(e) => setRemarks(e.target.value)} 
                                placeholder="Reason for deleting Payment"
                                maxLength='500'
                                rows='3'
                            ></textarea>
                        </div>
                    </div>

                    <div className="col-sm-12 padding-remove" >
                        <div className=" col-md-12 form-group text-center multiButtonWrap1" >
                            <div style={{ marginTop: 15, width: 150 }}>
                                <button type="button" style={{ width: "90%" }} onClick={() => props.closePopup()} className="btn btn-success">Cancel</button>
                            </div>
                            <div style={{ marginTop: 15, width: 150 }}>
                                <button type="button" style={{ width: "90%" }} onClick={() => deletePayment()} className="btn btn-success">Delete Payment</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default DeletePayment;