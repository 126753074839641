import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "../css/EnquiryCtrl.css";
import "common/css/master.css";
import EnquiryTable from "./EnquiryTable.js";
import EnquirySlider from "./EnquirySlider.js";
import SchTestSlider from "./SchTestSlider.js";
import AssignCounsellor from "./AssignCounsellor";
import FollowUpSlider from "./FollowUpSlider";
import CloseSlider from "./CloseSlider";
import EvaluateTestSlider from "./EvaluateTestSlider";
import AssociateHead from "./AssociateHead";
import Header from "common/js/header/header.js";
import SliderType from "./sliderType";
import ScheduleInterviewSlider from "./ScheduleInterviewSlider";
import EvaluateInterviewSlider from "./EvaluateInterviewSlider";
import RegistrationFeeSlider from "./RegistrationFeeSlider";
import AdmissionFeeSlider from "./AdmissionFeeSlider";
import RegsFeePaymentSlider from "./RegsFeePaymentSlider";
import AdmnFeePaymentSlider from "./AdmnFeePaymentSlider";
import RegistrationSlider from "./RegistrationSlider";
import RegistrationSiblingDtls from "./RegistrationSiblingDtls";

function EnquiryCtrl() {
  const [selectedEnquiryId, setSelectedEnquiryId] = useState(0);
  const [lstSelEnqId, setLstSelEnqId] = useState([]);
  const [flgSliderType, setFlgSliderType] = useState(0);
  const [randNo, setRandNo] = useState(0);
  const [cls, setCls] = useState("");
  const [selectedClsId, setSelectedClsId] = useState(0);
  const [userId, setUserId] = useState(0);
  const [registrationId, setRegistrationId] = useState(0);
  const [popup, setPopup] = useState(false);
  const [siblingId, setSiblingId] = useState(0);
  const [school, setSchool] = useState("");
  const [clsId, setClsId] = useState(0);
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState(null);
  const [sibling, setSibling] = useState("");
  const [siblingSliderKey, setSiblingSliderKey] = useState(0);
  const [schTestSliderKey, setSchTestSliderKey] = useState(0);
  const controller = new AbortController();
  const signal = controller.signal;

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);
  function refreshScreen() {
    setSelectedEnquiryId(0);
    setFlgSliderType(0);
    generateRandomNo();
  }
  function setEnqForAssign(param) {
    setLstSelEnqId(param);
  }
  function generateRandomNo() {
    var lRandNo = Math.floor(Math.random() * 999999);
    // // console.log(lRandNo);
    setRandNo(lRandNo);
  }
  function scheduleInterviewSlider(pTypeId) {
    if (lstSelEnqId.length === 0) {
      toast.warn("Please select atleast one enquiry");
      return;
    }
    setFlgSliderType(pTypeId);
  }
  function setEnqData(obj) {
    var promise = setEnquiryData(obj);
    promise.then(() => setFlgSliderType(SliderType.SLIDER_REGISTER));
  }
  async function setEnquiryData(obj) {
    await setRegistrationId(obj.registrationId);
    await setSelectedEnquiryId(obj.enquiryId);
    await setUserId(obj.userId);
    // // console.log(obj);
  }
  const getWingId = (wingId) => {
    // // console.log("wingId = " + wingId);
  };
  return (
    <div className="MainContainer">
      <Header wingFromChild={getWingId} />
      <ToastContainer autoClose={3000} hideProgressBar />

      <div className="enquiryTblWrapper">
        <div className="enquiryTbl">
          <EnquiryTable
            key={randNo}
            setCls={setCls}
            selectedEnquiryId={selectedEnquiryId}
            setSelectedEnquiryId={setSelectedEnquiryId}
            setEnqForAssign={setEnqForAssign}
            setRegistrationId={setRegistrationId}
            setFlgSliderType={setFlgSliderType}
            setSelectedClsId={setSelectedClsId}
            setEnqData={setEnqData}
          />
          <div className="col-sm-12">
            <div className="assignStaffWrapper col-sm-7">
              <AssignCounsellor
                key={randNo}
                lstSelEnqId={lstSelEnqId}
                refreshScreen={refreshScreen}
                generateRandomNo={generateRandomNo}
              />
            </div>
            <div className="form form-horizontal">
              <div className="col-md-2 pull-right" style={{ marginTop: "1%" }}>
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={(e) =>
                    scheduleInterviewSlider(
                      SliderType.SLIDER_SCHEDULE_INTERVIEW
                    )
                  }
                >
                  Schedule Interview
                </button>
              </div>
              <div className="col-md-3">
                <div className="assignStaffWrapper col-sm-12">
                  <AssociateHead
                    key={randNo}
                    lstSelEnqId={lstSelEnqId}
                    refreshScreen={refreshScreen}
                    generateRandomNo={generateRandomNo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className=""
          onClick={(e) => setFlgSliderType(SliderType.SLIDER_ENQUIRY_DTLS)}
        >
          <span
            id="addReadyId"
            title="Add"
            className="addIcon glyphicon glyphicon-plus-sign"
          ></span>
        </div>
      </div>

      <div
        style={{
          display:
            flgSliderType === SliderType.SLIDER_ENQUIRY_DTLS ? "block" : "none",
        }}
        className="enquirySlider"
      >
        <button
          type="button"
          className="btn btn-info text-center btnCloseDiv"
          onClick={refreshScreen}
        >
          <span
            id="close"
            title="Close Slider"
            className="glyphicon glyphicon-remove"
          ></span>
        </button>
        {flgSliderType === SliderType.SLIDER_ENQUIRY_DTLS ? (
          <EnquirySlider
            key={selectedEnquiryId}
            enquiryId={selectedEnquiryId}
            closeSlider={refreshScreen}
            setSelectedEnquiryId={setSelectedEnquiryId}
            generateRandomNo={generateRandomNo}
            flgSliderType={flgSliderType}
          />
        ) : null
        }
      </div>
      <div
        style={{
          width: "90%",
          display:
            flgSliderType === SliderType.SLIDER_SCHEDULE_TEST
              ? "block"
              : "none",
        }}
        className="enquirySlider"
      >
        <button
          type="button"
          className="btn btn-info text-center btnCloseDiv"
          style={{ right: "90%" }}
          onClick={refreshScreen}
        >
          <span
            id="close"
            title="Close Slider"
            className="glyphicon glyphicon-remove"
          ></span>
        </button>
        {flgSliderType === SliderType.SLIDER_SCHEDULE_TEST ? (
          <SchTestSlider
            setSchTestSliderKey={setSchTestSliderKey}
            key={registrationId + "_" + schTestSliderKey}
            enquiryId={selectedEnquiryId}
            regsId={registrationId}
            closeSlider={refreshScreen}
            clsId={selectedClsId}
            flgSliderType={flgSliderType}
          />
        ) : null
        }
      </div>
      <div
        style={{
          width: "90%",
          display:
            flgSliderType === SliderType.SLIDER_TEST_RESULT ? "block" : "none",
        }}
        className="enquirySlider"
      >
        <button
          type="button"
          className="btn btn-info text-center btnCloseDiv"
          style={{ right: "90%" }}
          onClick={refreshScreen}
        >
          <span
            id="close"
            title="Close Slider"
            className="glyphicon glyphicon-remove"
          ></span>
        </button>
        {flgSliderType === SliderType.SLIDER_TEST_RESULT ? (
          <EvaluateTestSlider
            key={selectedEnquiryId}
            enquiryId={selectedEnquiryId}
            regsId={registrationId}
            closeSlider={refreshScreen}
            clsId={selectedClsId}
            flgSliderType={flgSliderType}
          />
        ) : null
        }
      </div>
      <div
        style={{
          width: "50%",
          display:
            flgSliderType === SliderType.SLIDER_FOLLOWUP_DTLS
              ? "block"
              : "none",
        }}
        className="enquirySlider"
      >
        <button
          type="button"
          className="btn btn-info text-center btnCloseDiv"
          style={{ right: "50%" }}
          onClick={refreshScreen}
        >
          <span
            id="close"
            title="Close Slider"
            className="glyphicon glyphicon-remove"
          ></span>
        </button>
        {flgSliderType === SliderType.SLIDER_FOLLOWUP_DTLS ? (
          <FollowUpSlider
            key={selectedEnquiryId}
            enquiryId={selectedEnquiryId}
            setSelectedEnquiryId={setSelectedEnquiryId}
            closeSlider={refreshScreen}
            flgSliderType={flgSliderType}
          />
        ) : null
        }
      </div>
      {/* ***************REGISTRATION SLIDER*************** */}
      {userId ? (
        <div
          style={{
            width: "95%",
            display:
              flgSliderType === SliderType.SLIDER_REGISTER ? "block" : "none",
          }}
          className="enquirySlider"
        >
          <button
            type="button"
            className="btn btn-info text-center btnCloseDiv"
            style={{ right: "95%" }}
            onClick={refreshScreen}
          >
            <span
              id="close"
              title="Close Slider"
              className="glyphicon glyphicon-remove"
            ></span>
          </button>
          {flgSliderType === SliderType.SLIDER_REGISTER ? (
            <RegistrationSlider
              key={selectedEnquiryId}
              enquiryId={selectedEnquiryId}
              setSiblingId={setSiblingId}
              siblingId={siblingId}
              setSelectedEnquiryId={setSelectedEnquiryId}
              registrationId={registrationId}
              setRegsId={setRegistrationId}
              userId={userId}
              setPopup={setPopup}
              closeSlider={refreshScreen}
              flgSliderType={flgSliderType}
              setSibling={setSibling}
              setDob={setDob}
              setSchool={setSchool}
              setGender={setGender}
              setClsId={setClsId}
              siblingSliderKey={siblingSliderKey}
            />
          ) : null
          }
        </div>
      ) : null
      }

      {/* ***************REGISTRATION_FEE SLIDER*************** */}
      <div
        style={{
          width: "50%",
          display:
            flgSliderType === SliderType.SLIDER_REGISTER_FEE ? "block" : "none",
        }}
        className="enquirySlider"
      >
        <button
          type="button"
          className="btn btn-info text-center btnCloseDiv"
          style={{ right: "50%" }}
          onClick={refreshScreen}
        >
          <span
            id="close"
            title="Close Slider"
            className="glyphicon glyphicon-remove"
          ></span>
        </button>
        {flgSliderType === SliderType.SLIDER_REGISTER_FEE ? (
          <RegistrationFeeSlider
            key={selectedEnquiryId + "_" + registrationId}
            enquiryId={selectedEnquiryId}
            setSelectedEnquiryId={setSelectedEnquiryId}
            regsId={registrationId}
            cls={cls}
            closeSlider={refreshScreen}
            flgSliderType={flgSliderType}
          />
        ) : null
        }
      </div>
      <div
        style={{
          width: "50%",
          display:
            flgSliderType === SliderType.SLIDER_ADMISSION_FEE
              ? "block"
              : "none",
        }}
        className="enquirySlider"
      >
        <button
          type="button"
          className="btn btn-info text-center btnCloseDiv"
          style={{ right: "50%" }}
          onClick={refreshScreen}
        >
          <span
            id="close"
            title="Close Slider"
            className="glyphicon glyphicon-remove"
          ></span>
        </button>
        {flgSliderType === SliderType.SLIDER_ADMISSION_FEE ? (
          <RegsFeePaymentSlider
            key={selectedEnquiryId + "_" + registrationId}
            enquiryId={selectedEnquiryId}
            setSelectedEnquiryId={setSelectedEnquiryId}
            cls={cls}
            closeSlider={refreshScreen}
            flgSliderType={flgSliderType}
          />
        ) : null
        }
      </div>
      <div
        style={{
          width: "50%",
          display:
            flgSliderType === SliderType.SLIDER_ADMISSION_FEE
              ? "block"
              : "none",
        }}
        className="enquirySlider"
      >
        <button
          type="button"
          className="btn btn-info text-center btnCloseDiv"
          style={{ right: "50%" }}
          onClick={refreshScreen}
        >
          <span
            id="close"
            title="Close Slider"
            className="glyphicon glyphicon-remove"
          ></span>
        </button>
        {flgSliderType === SliderType.SLIDER_ADMISSION_FEE ? (
          <AdmissionFeeSlider
            key={selectedEnquiryId + "_" + registrationId}
            enquiryId={selectedEnquiryId}
            setSelectedEnquiryId={setSelectedEnquiryId}
            cls={cls}
            closeSlider={refreshScreen}
            flgSliderType={flgSliderType}
          />
        ) : null
        }
      </div>
      <div
        style={{
          width: "50%",
          display:
            flgSliderType === SliderType.SLIDER_ADMISSION_FEE_PAYMENT
              ? "block"
              : "none",
        }}
        className="enquirySlider"
      >
        <button
          type="button"
          className="btn btn-info text-center btnCloseDiv"
          style={{ right: "50%" }}
          onClick={refreshScreen}
        >
          <span
            id="close"
            title="Close Slider"
            className="glyphicon glyphicon-remove"
          ></span>
        </button>
        {flgSliderType === SliderType.SLIDER_ADMISSION_FEE_PAYMENT ? (
          <AdmnFeePaymentSlider
            key={selectedEnquiryId + "_" + registrationId}
            enquiryId={selectedEnquiryId}
            setSelectedEnquiryId={setSelectedEnquiryId}
            cls={cls}
            closeSlider={refreshScreen}
            flgSliderType={flgSliderType}
          />
        ) : null
        }
      </div>

      <div
        style={{
          width: "50%",
          display: flgSliderType === SliderType.SLIDER_CLOSE ? "block" : "none",
        }}
        className="enquirySlider"
      >
        <button
          type="button"
          className="btn btn-info text-center btnCloseDiv"
          style={{ right: "50%" }}
          onClick={refreshScreen}
        >
          <span
            id="close"
            title="Close Slider"
            className="glyphicon glyphicon-remove"
          ></span>
        </button>
        {flgSliderType === SliderType.SLIDER_CLOSE ? (
          <CloseSlider
            key={selectedEnquiryId}
            setSelectedEnquiryId={setSelectedEnquiryId}
            enquiryId={selectedEnquiryId}
            closeSlider={refreshScreen}
            flgSliderType={flgSliderType}
          />
        ) : null
        }
      </div>
      <div
        style={{
          width: "50%",
          display:
            flgSliderType === SliderType.SLIDER_INTERVIEW_RESULT
              ? "block"
              : "none",
        }}
        className="enquirySlider"
      >
        <button
          type="button"
          className="btn btn-info text-center btnCloseDiv"
          style={{ right: "50%" }}
          onClick={refreshScreen}
        >
          <span
            id="close"
            title="Close Slider"
            className="glyphicon glyphicon-remove"
          ></span>
        </button>
        {flgSliderType === SliderType.SLIDER_INTERVIEW_RESULT ? (
          <EvaluateInterviewSlider
            key={selectedEnquiryId + "_" + registrationId}
            regsId={registrationId}
            setSelectedEnquiryId={setSelectedEnquiryId}
            enquiryId={selectedEnquiryId}
            flgSliderType={flgSliderType}
            closeSlider={refreshScreen}
          />
        ) : null
        }
      </div>

      <div
        style={{
          width: "85%",
          display:
            flgSliderType === SliderType.SLIDER_SCHEDULE_INTERVIEW
              ? "block"
              : "none",
        }}
        className="enquirySlider"
      >
        <button
          type="button"
          className="btn btn-info text-center btnCloseDiv"
          style={{ right: "85%" }}
          onClick={refreshScreen}
        >
          <span
            id="close"
            title="Close Slider"
            className="glyphicon glyphicon-remove"
          ></span>
        </button>
        {flgSliderType === SliderType.SLIDER_SCHEDULE_INTERVIEW ? (
          <ScheduleInterviewSlider
            key={lstSelEnqId}
            regsId={registrationId}
            selectedLstEnquiryId={lstSelEnqId}
            closeSlider={refreshScreen}
            flgSliderType={flgSliderType}
          />
        ) : null
        }
      </div>
      <div className="popup">
        {popup ? (
          <RegistrationSiblingDtls
            popup={popup}
            setSiblingSliderKey={setSiblingSliderKey}
            regsId={registrationId}
            siblingId={siblingId || 0}
            setSiblingId={setSiblingId}
            sibling={sibling}
            clsId={clsId}
            gender={gender}
            school={school}
            dob={dob}
            enquiryId={selectedEnquiryId}
            setPopup={setPopup}
          />
        ) : null
        }
      </div>
    </div>
  );
}

export default EnquiryCtrl;
