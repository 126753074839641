import axios from 'axios';
import lstModules from 'common/json/lstModules.json'

const httpURL = lstModules['performance'].url + ':' + lstModules['performance'].port;
var tokenid = localStorage.getItem("tokenid");

function getCellDataSvc(pExamId, pExamDt, pSectionId, callback) {
    var status = 'Success'
    var obj = { examDtls: [], status: status };

    axios({
        url: httpURL + '/sms/performance/exam/schedule/dtls',
        method: "POST",
        params: { examId: pExamId, examDt: pExamDt, sectionId: pSectionId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === 'Success') {
            obj.examDtls = response.data.examDtls;
        }
        callback(obj)
    }).catch((error) => {
        console.error(error);
    });
}


export { getCellDataSvc }