import React, { useEffect, useState } from "react";
import "common/css/master.css";
import "../../css/subjectAllocation.css";
import ClassData from "../../json/classData.json"; // Importing class data from JSON

const ClassReportTbl = (props) => {
    const { lstCls } = props;


    const [lstClass, setLstClass] = useState([]);
    const [lstClassFiltered, setLstClassFiltered] = useState([]);

    const [classFiltered, setClassFiltered] = useState("");
    const [sectionFiltered, setSectionFiltered] = useState("");
    const [subjectFiltered, setSubjectFiltered] = useState("");
    const [teacherFiltered, setTeacherFiltered] = useState("");

    const colgrp = (
        <colgroup>
            <col width="150" />
            <col width="100" />
            <col width="150" />
            <col width="100" />
            <col width="150" />
        </colgroup>
    );

    useEffect(() => {
        setLstClass(lstCls);
        setLstClassFiltered(lstCls);
    }, [lstCls]);

    const filterClass = (fClass) => {
        setClassFiltered(fClass || "");
        filterClassReportTable(fClass || "", sectionFiltered, subjectFiltered, teacherFiltered);
    };
    
    const filterSection = (fSection) => {
        setSectionFiltered(fSection || "");
        filterClassReportTable(classFiltered, fSection || "", subjectFiltered, teacherFiltered);
    };
    
    const filterSubject = (fSubject) => {
        setSubjectFiltered(fSubject || "");
        filterClassReportTable(classFiltered, sectionFiltered, fSubject || "", teacherFiltered);
    };
    
    const filterTeacher = (fTeacher) => {
        setTeacherFiltered(fTeacher || "");
        filterClassReportTable(classFiltered, sectionFiltered, subjectFiltered, fTeacher || "");
    };
    
    const filterClassReportTable = async (fClass, fSection, fSubject, fTeacher) => {
        let filteredClassReportTable = lstClass;
    
        // Apply filters sequentially
        filteredClassReportTable = filteredClassReportTable.filter(item =>
            item.cls.toLowerCase().includes(fClass.toLowerCase())
        );
    
        filteredClassReportTable = filteredClassReportTable.filter(item =>
            item.sec.toLowerCase().includes(fSection.toLowerCase())
        );
    
        filteredClassReportTable = filteredClassReportTable.filter(item =>
            item.sub.toLowerCase().includes(fSubject.toLowerCase())
        );
    
        filteredClassReportTable = filteredClassReportTable.filter(item =>
            item.tch.toLowerCase().includes(fTeacher.toLowerCase())
        );
    
        await setLstClassFiltered(filteredClassReportTable);
        console.log("Filtered class report table updated");
    };
    

    
    return (
        <div>
            <div className="col-md-12 col-xs-12 col-centered padding-remove">
                <table className="tableLayout1">
                    {colgrp}
                    <thead>
                        <tr>
                            <th>
                                <input
                                    type="text"
                                    className="form-control inp-search"
                                    value={classFiltered}
                                    onChange={(e) => filterClass(e.target.value)}
                                    name="Class"
                                    placeholder="Class"
                                />
                            </th>
                            <th>
                                <input
                                    type="text"
                                    className="form-control inp-search"
                                    value={sectionFiltered}
                                    onChange={(e) => filterSection(e.target.value)}
                                    name="Section"
                                    placeholder="Section"
                                />
                            </th>
                            <th>
                                <input
                                    type="text"
                                    className="form-control inp-search"
                                    value={subjectFiltered}
                                    onChange={(e) => filterSubject(e.target.value)}
                                    name="Subject"
                                    placeholder="Subject"
                                />
                            </th>
                            <th>Total Periods</th>
                            <th>
                                <input
                                    type="text"
                                    className="form-control inp-search"
                                    value={teacherFiltered}
                                    onChange={(e) => filterTeacher(e.target.value)}
                                    name="Teacher"
                                    placeholder="Teacher"
                                />
                            </th>
                        </tr>
                    </thead>
                </table>
                <div className="tableDiv" style={{ height: "60vh", pointerEvents: "auto", zIndex: 1, position: "relative" }}>
                    <table className="tableLayout" style={{ position: "relative" }}>
                        {colgrp}
                        <tbody id="classReportTable">
                            {lstClassFiltered &&
                                lstClassFiltered.map((classItem) => (
                                    <tr key={classItem.clsId}>
                                        <td className="text-left">{classItem.cls}</td>
                                        <td className="text-center">{classItem.sec}</td>
                                        <td className="text-left">{classItem.sub}</td>
                                        <td className="text-center">{classItem.totalPeriod}</td>
                                        <td className="text-left">{classItem.tch}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default ClassReportTbl;
