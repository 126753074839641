import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import $ from 'jquery';
import 'jquery-ui';
import '../css/periodType.css';
import 'common/css/master.css';
import axios from 'axios';
import Header from 'common/js/header/header.js';
import { toast, ToastContainer } from 'react-toastify';

import PeriodTypeTable from './periodTypeTable';
import PeriodTypeForm from './periodTypeForm';

var httpURL = '';
var queryString = require('querystring');
var lstPeriodType = [];
var tokenid = localStorage.getItem('tokenid');
var screenId = 3;

class PeriodType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: 'none',
            opacity: 1,
            flg: true,
            tokenid: tokenid,
            periodTypeData: {},
            lstPeriodType: [],
        }
    }

    componentDidMount = () => {
        this.getPeriodList();
    }

    getPeriodList = () => {
        this.setState({ loader: 'block', opacity: 0.5 });
        axios({
            url: httpURL + '/sms/mst/periodType/list',
            method: "POST",
            params: { screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                this.setState({
                    lstPeriodType: response.data.lstPeriodType,
                });
                lstPeriodType = this.state.lstPeriodType;
            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error("Service failed " + error);
        });
    }

    deletePeriodType = (periodTypeId) => {
        var retVal = window.confirm("Do you want to Delete This Period Type ?");
        if (retVal == true) {
            this.setState({ loader: 'block', opacity: 0.5 });
            axios.post(httpURL + '/sms/mst/periodType/delete',
                queryString.stringify({ periodTypeId, screenId }),
                {
                    headers: { tokenid: this.state.tokenid },
                    withCredentials: true
                }
            ).then((response) => {
                if (response.data.SvcStatus === 'Success') {
                    toast.success(response.data.SvcMsg);
                    this.getPeriodList();
                } else {
                    toast.error(response.data.SvcMsg);
                }
                this.setState({ loader: 'none', opacity: 1 });
            }).catch((e) => {
                this.setState({ loader: 'none', opacity: 1 });
                toast.error('Service failed: ' + e);
            });
            return true;
        } else {
            return false;
        }
    }

    setPeriodTypeForm = (periodTypeId) => {
        var periodTypeData = this.getPeriodTypeData(periodTypeId);
        $('#period_type').val(periodTypeData.periodType);
        var canAssign = periodTypeData.canAssignTeacher;

        if (canAssign === true)
            $('#canAssignTeacher').prop("checked", true);
        else
            $('#canAssignTeacher').prop("checked", false);

        {
            periodTypeData.canCoverSyllabus === true ?
                $('#canCoverSyllabus').prop("checked", true) :
                $('#canCoverSyllabus').prop("checked", false);
        }
        this.setState({
            flg: false,
        })
    }

    getPeriodTypeData(periodTypeId) {
        for (var i = 0; i < lstPeriodType.length; i++) {
            if (parseInt(periodTypeId) === parseInt(lstPeriodType[i].periodTypeId))
                return lstPeriodType[i];
        }
    }

    addPeriodTypeReady = () => {
        $('#periodTypeAddForm').trigger('reset');
        $('#periodTypeTableBody tr').removeClass('selected');
        this.setState({
            flg: true,
        });
    }
    savePeriodType = () => {
        var periodTypeArr = {};
        var periodTypeId = 0;
        var periodType = $('#period_type').val();
        var canAssignTeacher = $('#canAssignTeacher').prop('checked');
        canAssignTeacher = (canAssignTeacher === true) ? 1 : 0;
        var canCoverSyllabus = $('#canCoverSyllabus').prop('checked') === true ? 1 : 0;
        if (periodType === "") {
            toast.warning("Please Enter Period type");
            $('#period_type').focus()
            return false
        }
        if (this.state.flg) {
            periodTypeId = 0;
        }
        else {
            periodTypeId = $('#periodTypeTableBody tr.selected').find('td:eq(0)').text();
        }
        this.setState({ loader: 'block', opacity: 0.5 });
        periodTypeArr = {
            'periodTypeId': periodTypeId,
            "periodType": periodType,
            "canAssignTeacher": canAssignTeacher,
            "canCoverSyllabus": canCoverSyllabus,
        };
        axios({
            url: httpURL + '/sms/mst/periodType/save',
            method: "POST",
            params: { 'periodType': JSON.stringify(periodTypeArr), screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getPeriodList();
                this.addPeriodTypeReady();

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });
    }
    getWingId = (wingId) => {
        // // console.log("wingId" + wingId);
    }

    render() {
        httpURL = lstModules['periodType'].url + ":" + lstModules['periodType'].port;
        return (
            <div style={{ height: '100%' }}>
                <Header wingFromChild={this.getWingId} />
                <ToastContainer autoClose={3000} hideProgressBar />
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: this.state.loader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>
                <div className="mainContainer Timing" style={{ pointerEvents: this.state.opacity ? 'auto' : 'none', marginTop: '1%' }}>
                    <div className="col-md-11 col-centered">
                        <div className="col-xs-7 periodTypeWrap  padding-remove">
                            {this.state.lstPeriodType.length !== 0 ?
                                <PeriodTypeTable lstPeriodType={this.state.lstPeriodType}
                                    setPeriodTypeForm={this.setPeriodTypeForm}
                                    deletePeriodType={this.deletePeriodType} />
                                : null}
                        </div>
                        <div className="col-xs-1 ">
                            <span id="addPeriodType" title="Add Period Type" onClick={this.addPeriodTypeReady} className="addIcon glyphicon glyphicon-plus-sign"></span>
                        </div>
                        <div className="col-xs-4 padding-remove">
                            <PeriodTypeForm
                                periodTypeData={this.state.periodTypeData}
                                savePeriodType={this.savePeriodType}
                            />
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
export default PeriodType;