import React, { useEffect, useState } from 'react';
import { View, Image } from '@react-pdf/renderer';
import QRCode from 'qrcode';
import { styleQR } from './styleStudentOtpPdf';

const StaffOTPQR = ({ schoolId, testId, otp, qrSize }) => {
    const [qrLink, setQRLink] = useState("");
    const [qrImageUrl, setQrImageUrl] = useState("");

    useEffect(() => {
        const testPageUrl = `https://sms.cooledsys.com/test?tId=${testId}&otp=${otp}&schId=${schoolId}`;
        setQRLink(testPageUrl);
    }, [schoolId, testId, otp]);

    useEffect(() => {
        if (qrLink) {
            QRCode.toDataURL(qrLink)
                .then((dataUrl) => {
                    setQrImageUrl(dataUrl);
                })
                .catch((error) => {
                    console.error('Error generating QR code image:', error);
                });
        }
    }, [qrLink]);

    return (
        <View style={{width:qrSize, height:qrSize}}>
            {qrImageUrl && <Image style={styleQR.image1} src={qrImageUrl} />}
        </View>
    );
};

export default StaffOTPQR;
