import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['main'].url + ":" + lstModules['main'].port + "/sms/student_leaves";
const tokenid = localStorage.getItem('tokenid');



export const getLeavesListSvc = (fromDt, toDt, cbGetLeavesList) => {
    // // console.log("etLeavesListSvc called, url", httpURL + '/list');
    // // console.log("parameters passed fromDt",fromDt, ", toDt", toDt)
    const obj = { status: "", message: "" };
    const formData = new FormData();
    formData.append("fromDt", fromDt);
    formData.append("toDt", toDt);
    axios({
        url: httpURL + '/list',
        method: "POST",
        headers: { 'tokenid': tokenid },
        // params:{"fromDt":JSON.stringify(fromDt), "toDt":JSON.stringify(toDt)},
        data: formData,
        withCredentials: true,
    }).then((response) => {
        // // console.log("response in etLeavesListSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstLeave = response.data.lstLeave;
        }
        cbGetLeavesList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbGetLeavesList(obj);
    })
}

export const leaveReplySvc = (leaveId, flgApproveReject, rejectionRemarks, cbLeavesReply) => {
    // // console.log("leaveReplySvc called, url", httpURL + '/action');
    // // console.log("parameters passed leaveId", leaveId, ", flgApproveReject", flgApproveReject, ", rejectionRemarks",rejectionRemarks)
    const obj = { status: "", message: "" };
    // const formData = new FormData();
    // formData.append("leaveId", leaveId);
    // formData.append("flgApproveReject",  JSON.stringify(flgApproveReject));
    // formData.append("rejectionRemarks",  JSON.stringify(rejectionRemarks));
    const approveRejectObj = {
        "leaveId": leaveId,
        "flgApproveReject": flgApproveReject,
        "rejectionRemarks": rejectionRemarks
    }
    axios({
        url: httpURL + '/action',
        method: "POST",
        headers: { 'tokenid': tokenid },
        // params:{"leaveId": leaveId, "flgApproveReject": flgApproveReject, "rejectionRemarks":rejectionRemarks},
        // data: formData,
        params:{approveRejectObj:JSON.stringify(approveRejectObj)},
        withCredentials: true,
    }).then((response) => {
        // // console.log("response in leaveReplySvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            // // console.log("leaveReplySvc response succesfull, response", response);
        }
        cbLeavesReply(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbLeavesReply(obj);
    })
}
