import React, { useState, useEffect } from "react";
import Header from "common/js/header/header";
import 'common/css/master.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import FeeDiscountTable from "./feeDiscountTable";
// import StudentFeeDetails from "sms/fees/StudentFeeDetailsDiscount/js/studentFeeDetailsDiscount";


const StudentDiscount = () => {

    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [studentCount, setStudentCount] = useState(0);
    const [discountSum, setDiscountSum] = useState(0);
    const [studentId, setStudentId] = useState(0);
    const [studentName, setStudentName] = useState("");
    const [isFeeDiscountSliderOpen, setIsFeeDiscountSliderOpen] = useState(false);

    const openSlider = (stdId, stdName) => {
        setStudentId(stdId);
        setStudentName(stdName);
        setIsFeeDiscountSliderOpen(true);
    }

    const closeSlider = () => {
        setIsFeeDiscountSliderOpen(false);
    }

    return (
        <div>
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="mainContainer" >
                <div className="col-xs-10 col-centered ">
                    <div className="col-sm-4 padding-remove pull-right">
                        <div className="col-sm-6 padding-remove "  >
                            <div className="col-sm-10 padding-remove " style={{ border: "1px solid #ccc", borderRadius: "4px" }} >
                                <label className=" col-sm-12 text-center padding-remove" style={{ fontWeight: 400 }}  >No of Students: {studentCount.toLocaleString('en-IN')}</label>
                            </div>
                        </div>
                        <div className="col-sm-6 padding-remove "  >
                            <div className="col-sm-10 padding-remove " style={{ border: "1px solid #ccc", borderRadius: "4px" }} >
                                <label className=" col-sm-12 text-center padding-remove" style={{ fontWeight: 400 }}  >Total Discount: {discountSum.toLocaleString('en-IN')}</label>
                            </div>
                        </div>
                    </div>
                    <div>
                        <FeeDiscountTable
                            setLoader={setLoader}
                            setOpacity={setOpacity}
                            setStudentCount={setStudentCount}
                            setDiscountSum={setDiscountSum}
                        />
                    </div>
                </div>
                {/* { isFeeDiscountSliderOpen &&
                    <div className="slider-80" >
                    <div className="col-sm-12"  >
                        <StudentFeeDetails
                            feeDtlskey={studentId}
                            studentId={studentId}
                            closeSlider={closeSlider}
                            studentName={studentName}
                        />
                    </div>
                </div>} */}
            </div>

        </div>
    );
}

export default StudentDiscount;