import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { saveRemarkSvc } from "./examRemarksSvc";




const SaveExamRemark = (props) => {

    const { setLoader, examId, sectionId, lstSelect, lstRemark, setLstSelect, closePopup } = props;

    const [remarks, setRemarks] = useState("");
     
    useEffect(() => {
        // // console.log("saveExamRemark called, examId", examId, ', sectionId', sectionId, ", lstSelect", lstSelect, ", lstRemark", lstRemark);
    },[ examId, sectionId, lstSelect, lstRemark]);


    const saveRemark = () => {
        // Check if any students are selected
        if (lstSelect.length === 0) {
            toast.error("Please select student to write response");
            return;
        }

        const academicYearId = localStorage.getItem('academicYearId') || 0;

        // Create tempLstStudent array within saveRemark
        const tempLstStudent = lstSelect.map(studentId => {
            const student = lstRemark.find(item => item.studentId === studentId);
            if (student) {
                return {
                    studentId: student.studentId,
                    attendance: "",
                    remarks: remarks
                };
            } else {
                console.error(`Student with ID ${studentId} not found in lstRemark`);
                return null;
            }
        }).filter(student => student !== null); // Filter out any null values

        const examRemarksSaveRQ = {
            examId: examId,
            sectionId: sectionId,
            acdYrId: academicYearId,
            lstStudent: tempLstStudent
        };
        // // console.log("examRemaksSaveRQ", examRemarksSaveRQ);
        setLoader("block");
        saveRemarkSvc(examRemarksSaveRQ, saveRemarkResponse);
    };

    const saveRemarkResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            toast.success(obj.message);
            setLstSelect([]);
            closePopup();
        }
        else {
            if (obj.message == 'You are not logged in') {
                toast.error('You are not logged in');
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                toast.error('Your Session is expired, login again');
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
    }

    return (
        <div style={{ display: "flex", justifyContent: "center", position: "absolute", padding: 0, top: "35%", left: "40%", backgroundColor: "#F8F8F8", width: 350, height: 280, zIndex: 1001, border: "1px solid #ccc" }}>
            <div className="col-sm-12 padding-remove" >
                <div className="col-sm-12 padding-remove">
                    <div className="col-sm-8" style={{ padding: "10px 2px", fontSize: "large" }}>
                        Save remark for selected students
                    </div>
                    <div className="col-sm-4 padding-remove" >
                        <button type="button"
                            title="Close Popup"
                            onClick={() => closePopup()}
                            className='btn btn-info text-center pull-right'>X</button>
                    </div>
                </div>
                <form >
                    <div className="form-group">
                        <label className="control-label col-sm-4" > Remarks <span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                        <div className="col-sm-12 ">
                            <textarea
                                className='form-control'
                                value={remarks}
                                onChange={(e) => setRemarks(e.target.value)}
                                placeholder="Student performance remarks"
                                maxLength='500'
                                rows='3'
                            ></textarea>
                        </div>
                    </div>

                    <div className="col-sm-12 padding-remove" >
                        <div className=" col-md-12 form-group text-center multiButtonWrap1" >
                            <div style={{ marginTop: 15, width: 150 }}>
                                <button type="button" style={{ width: "90%" }} onClick={() => props.closePopup()} className="btn btn-success">Cancel</button>
                            </div>
                            <div style={{ marginTop: 15, width: 150 }}>
                                <button type="button" style={{ width: "90%" }} onClick={() => saveRemark()} className="btn btn-success">Save Remark</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SaveExamRemark;