import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['performance'].url + ':' + lstModules['performance'].port;

var tokenid = localStorage.getItem("tokenid");

function getLstTermSvc(callback, pTermId) {

    axios({
        url: httpURL + '/sms/perf/performance/term/list',
        method: "POST",
        params: {},
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // // console.log('data.SvcStatus=' + response.data.SvcStatus);
        callback(response.data, pTermId);
    }).catch((error) => {
        callback(error);
    });
}
export { getLstTermSvc }