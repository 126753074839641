import React, { useEffect, useState } from 'react'

import "common/css/master.css";

const BonafideSliderTable = (props) => {
    const { lstStudent, studentId, setStudentId, setClsSection, setStudentName, getCertificateId, setCertificateId } = props;

    const [lstStudentFiltered, setLstStudentFiltered] = useState([]);
    // const [selStudentId, setSelStudentId] = useState(0);
    const [studentFiltered, setStudentFiltered] = useState([]);
    const [enrollmentFiltered, setEnrollmentFiltered] = useState([]);
    const [fatherFiltered, setFatherFiltered] = useState([]);
    const [classSectionFiltered, setClassSectionFiltered] = useState([]);

    const colgrp = <colgroup>
        <col width="40"></col>
        <col width="100"></col>
        <col width="60"></col>
        <col width="80"></col>
        <col width="100"></col>
    </colgroup>

    useEffect(() => {
        // // console.log("BonafideSliderTable called");
        setLstStudentFiltered(lstStudent);
        // // console.log("studentId", studentId, ", lstStudent", lstStudent);
        if (!!studentId && !!lstStudent) {
            // // console.log("after checking studnetId and lstStudent (in condition)");
            let student = lstStudent.find(obj => obj.studentId == studentId);
            if (student) {
                setClsSection(student.clsSection);
                setStudentName(student.student);
            }
            setCertificateId(getCertificateId(studentId));
            setFatherFiltered("");
            setClassSectionFiltered("");
            setEnrollmentFiltered("");
            setStudentFiltered("");
        }
    }, [lstStudent]);

    useEffect(() => {
        // // console.log("BonafideSliderTable called");
        if (studentId && lstStudent) {
            let student = lstStudent.find(obj => obj.studentId == studentId);
            if (student) {
                setClsSection(student.clsSection);
                setStudentName(student.student);
            }
            setCertificateId(getCertificateId(studentId));
        }
    }, [studentId]);

    const filterStudentName = (fStudent) => {
        // // console.log("filterStudentName called, fStudent", fStudent);
        setStudentFiltered(fStudent);
        filterStudentTable(fStudent, classSectionFiltered, enrollmentFiltered, fatherFiltered);
    }

    const filterEnrollment = (fEnrollment) => {
        // // console.log("filterEnrollment called, fEnrollment", fEnrollment);
        setEnrollmentFiltered(fEnrollment);
        filterStudentTable(studentFiltered, classSectionFiltered, fEnrollment, fatherFiltered);
    }

    const filterFather = (fFather) => {
        // // console.log("filterFather called, fFather", fFather);
        setFatherFiltered(fFather);
        filterStudentTable(studentFiltered, classSectionFiltered, enrollmentFiltered, fFather);
    }

    const filterClassSection = (fClassSection) => {
        // // console.log('filterClassSection called, fClassSection', fClassSection);
        setClassSectionFiltered(fClassSection);
        filterStudentTable(studentFiltered, fClassSection, enrollmentFiltered, fatherFiltered);
    }

    const filterStudentTable = (fStudent, fClassSection, fEnrollment, fFather) => {
        // // console.log("filterCerficateTable called fStudent", fStudent, ", fClassSection", fClassSection, ", fEnrollment", fEnrollment, ", fFather", fFather);
        let filteredStudentTable = lstStudent.filter(student =>
            student.student.toString().toLowerCase().includes(fStudent.toLowerCase())
        );
        filteredStudentTable = filteredStudentTable.filter(student =>
            student.clsSection === null || student.clsSection === undefined || student.clsSection.toLowerCase().includes(fClassSection.toString().toLowerCase())
        );
        filteredStudentTable = filteredStudentTable.filter(student =>
            student.enrollmentNo === null || student.enrollmentNo === undefined || student.enrollmentNo.toLowerCase().includes(fEnrollment.toString().toLowerCase())
        );
        filteredStudentTable = filteredStudentTable.filter(student =>
            student.father === null || student.father === undefined || student.father.toLowerCase().includes(fFather.toString().toLowerCase())
        );
        setLstStudentFiltered(filteredStudentTable);
    }

    const handleStudentChange = async (pStudent) => {
        // // console.log("handleStudentChange called, pStudent", pStudent);
        await setCertificateId(getCertificateId(pStudent.studentId));
        setStudentId(pStudent.studentId);
        setClsSection(pStudent.clsSection);
        setStudentName(pStudent.student);
    }


    return (
        <div >
            <div className="col-md-12 col-xs-12 col-centered padding-remove " >
                <table className="tableLayout1" >
                    {colgrp}
                    <thead>
                        <tr>
                            <th >
                                Student Id
                            </th>
                            <th >
                                <input
                                    type="text"
                                    className="form-control inp-search"
                                    value={studentFiltered}
                                    onChange={(e) => filterStudentName(e.target.value)}
                                    name="student name"
                                    placeholder="Student" />
                            </th>
                            <th >
                                <input
                                    type="text"
                                    className="form-control inp-search"
                                    value={enrollmentFiltered}
                                    onChange={(e) => filterEnrollment(e.target.value)}
                                    name="Enrollment No"
                                    placeholder="Enrollment No" />
                            </th>
                            <th >
                                <input
                                    type="text"
                                    className="form-control inp-search"
                                    value={classSectionFiltered}
                                    onChange={(e) => filterClassSection(e.target.value)}
                                    name="Class Section"
                                    placeholder="classSection" />
                            </th>
                            <th >
                                <input
                                    type="text"
                                    className="form-control inp-search"
                                    value={fatherFiltered}
                                    onChange={(e) => filterFather(e.target.value)}
                                    name="Father"
                                    placeholder="Father" />
                            </th>
                        </tr>
                    </thead>
                </table>
                <div className="tableDiv" style={{ height: "80vh" }}>
                    <table className="tableLayout">
                        {colgrp}
                        <tbody id="bonafideBodyTable" >
                            {lstStudentFiltered &&
                                lstStudentFiltered.map((student, i) => (
                                    <tr key={student.studentId}
                                        onClick={() => handleStudentChange(student)}
                                        className={student.studentId === studentId ? "selected" : ''}>
                                        <td className="text-left">{student.studentId}</td>
                                        <td className="text-left">{student.student}</td>
                                        <td className="text-left">{student.enrollmentNo}</td>
                                        <td className="text-left">{student.clsSection}</td>
                                        <td className="text-left">{student.father}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default BonafideSliderTable;