import React, { useState, useEffect, useRef } from "react";
import Header from "common/js/header/header";
import 'common/css/master.css';
import "../css/studentLeaves.css";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";

import { leaveReplySvc } from "./studentLeavesSvc";
import StudentLeavesTable from "./stdLeavesTbl";
import RejectLeave from "./rejectLeavePopup";

const StudentLeaves = () => {

    const childRef = useRef();
    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [listFromDt, setListFromDt] = useState("");
    const [listToDt, setListToDt] = useState("");
    const [leaveObj, setLeaveObj] = useState({});
    const [isRejectLeavePopupOpen, setIsRejectLeavePopupOpen] = useState(false);
    // const [tableKey, setTableKey] = useState(0);


    useEffect(() => {
        // // console.log("studentLeaves.js called");
        const stDt = new Date();
        const endDt = new Date(stDt);
        endDt.setMonth(endDt.getMonth() + 1);
        endDt.setDate(endDt.getDate() - 1);

        setListFromDt(stDt);
        setListToDt(endDt);
        getDetails(stDt, endDt)
    }, []);

    const getDetails = (stDt, endDt) => {
        // // console.log("getDetails called, stDt ", stDt, ", endDt", endDt);
        childRef.current.getLeavesList(toDMY(stDt), toDMY(endDt));
    }

    const approveLeave = (lId) => {
        // // console.log("approveLeave called, leaveId", lId)
        setLoader("block");
        setOpacity(0.5);
        leaveReplySvc(lId, "A", "", approveLeaveReponse)
    }

    const approveLeaveReponse = (data) => {
        // // console.log("approveLeaveResponse called, data", data)
        let obj = data;
        if (obj.status === 'Success') {
            // // console.log("successful response in approveLeaveReponse");
            getDetails(listFromDt, listToDt);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader('none');
        setOpacity(1);
    }

    const rejectLeave = (lObj) => {
        // // console.log("rejectLeave called");
        setLeaveObj(lObj);
        openPopup();
    }

    const openPopup = () => {
        // // console.log("openPopup called");
        setIsRejectLeavePopupOpen(true);
    }
    
    const closePopup = () => {
        // // console.log("closePopup called");
        setIsRejectLeavePopupOpen(false);
        getDetails(listFromDt, listToDt);
    }

    // const updateKey = () => {
    //     // // console.log("updateKey called");
    //     setTableKey((prevKey) => prevKey + 1);
    // }

    const toDMY = (dt) => {
        // // console.log("toDMY called, dt", dt);
        var tmpDd = dt.getDate();
        var dd = tmpDd < 9 ? "0" + tmpDd : tmpDd;
        var mon = dt.getMonth() + 1;
        var mm = mon < 10 ? "0" + mon : mon;
        let dmyDate = dd + "-" + mm + "-" + dt.getFullYear();
        return dmyDate;
    }


    return (
        <div>
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="mainContainer" style={{ opacity: opacity }}>
                <div className="col-xs-10 col-centered padding-remove">
                    <div className="col-xs-12 padding-remove">
                        <div className="col-sm-2">
                            <label
                                className="col-sm-6 text-left padding-remove"
                                style={{ top: 5 }}
                            >
                                From Date
                            </label>
                            <div className="form-group col-sm-6 padding-remove">
                                <DatePicker
                                    id="inp_toDate"
                                    className="form-control text-center"
                                    placeholderText="From Date"
                                    selected={listFromDt}
                                    dateFormat="dd-MM-yyyy"
                                    onChange={(dt) => setListFromDt(dt)}
                                    autoComplete="off"
                                    showYearDropdown
                                    showMonthDropdown
                                    dropdownMode="select"
                                />
                            </div>
                        </div>

                        <div className="col-sm-2">
                            <label
                                className="text-left col-sm-6 padding-remove"
                                style={{ top: 5 }}
                            >
                                To Date
                            </label>
                            <div className="form-group col-sm-6 padding-remove">
                                <DatePicker
                                    id="inp_toDate"
                                    className="form-control text-center"
                                    placeholderText="To Date"
                                    selected={listToDt}
                                    onChange={(dt) => setListToDt(dt)}
                                    dateFormat="dd-MM-yyyy"
                                    autoComplete="off"
                                    showYearDropdown
                                    showMonthDropdown
                                    dropdownMode="select"
                                />
                            </div>
                        </div>
                        <div
                            className="col-sm-1 form-group "
                            style={{ width: "60%" }}>
                            <button
                                type="button"
                                className="btn btn-info "
                                onClick={(e) => getDetails(listFromDt, listToDt)}
                            >
                                Get Details
                            </button>
                        </div>
                    </div>
                    <div className="col-xs-12 padding-remove">
                        <StudentLeavesTable
                            ref={childRef}
                            setLoader={setLoader}
                            setOpacity={setOpacity}
                            approveLeave={approveLeave}
                            rejectLeave={rejectLeave}
                        />
                    </div>
                </div>
            </div>
            {isRejectLeavePopupOpen ?
                <div >
                    <RejectLeave
                        setLoader={setLoader}
                        setOpacity={setOpacity}
                        closePopup={closePopup}
                        leaveObj={leaveObj}
                    />
                </div>
                : null
            }
        </div>
    );
}

export default StudentLeaves;