import React, { useState, useEffect } from 'react';
import 'common/css/master.css'
import Header from 'common/js/header/header.js'
import '../css/EnquiryCtrl.css'
import LblTxtLbl from './LblTxtLbl'
import { getEnquiryTatSvc, saveEnquiryTatSvc } from './TatSvc';
import { toast, ToastContainer } from 'react-toastify';



const Tat = () => {
  const [assignCounsellor, setAssignCounsellor] = useState(0);
  const [followUp, setFollowUp] = useState(0);
  const [registration, setRegistration] = useState(0);
  const [scheduleTest, setScheduleTest] = useState(0);
  const [scheduleInterview, setScheduleInterview] = useState(0);
  const [interviewDate, setInterviewDate] = useState(0);
  const [loader, setLoader] = useState('none');

  function cbTatDtlsResponse(data) {
    setLoader("none")
    if (data.SvcStatus === "Success") {
      setAssignCounsellor(data.tat.assignCounsellor);
      setFollowUp(data.tat.followUp);
      setRegistration(data.tat.registration);
      setScheduleTest(data.tat.scheduleTest);
      setScheduleInterview(data.tat.scheduleInterview);
      setInterviewDate(data.tat.interviewDate);
    } else {
      toast.error(data.SvcMsg)
    }

  }

  function cbSaveTat(data) {
    setLoader("none");
    if (data.SvcStatus === "Success") {
      getEnquiryTatSvc(cbTatDtlsResponse)
      toast.success(data.SvcMsg)
    } else {
      toast.error(data.SvcMsg)
    }
  }

  function saveEnquiryTat() {
    var tat = {};
    tat.assignCounsellor = assignCounsellor;
    tat.followUp = followUp;
    tat.registration = registration;
    tat.scheduleTest = scheduleTest;
    tat.scheduleInterview = scheduleInterview;
    tat.interviewDate = interviewDate;
    setLoader("block")
    saveEnquiryTatSvc(tat, cbSaveTat)
  }

  useEffect(() => {
    setLoader("block")
    getEnquiryTatSvc(cbTatDtlsResponse)
  }, []);

  function changeAssignCounsellor(param) {
    setAssignCounsellor(param);
  }

  function changeFollowUp(param) {
    setFollowUp(param);
  }

  function changeRegistration(param) {
    setRegistration(param);
  }

  function changeScheduleTest(param) {
    setScheduleTest(param);
  }

  function changeScheduleInterview(param) {
    setScheduleInterview(param);
  }

  function changeInterviewDate(param) {
    setInterviewDate(param);
  }
  function generateRandomNo() {
    return Math.floor(Math.random() * 999999);
  }
  const getWingId = (wingId) => {
    // // console.log('wingId = ' + wingId)
  }
  return <div className='mainContainer' style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
    <Header wingFromChild={getWingId} />
    <ToastContainer autoClose={3000} hideProgressBar />
    <div className='col-sm-6 col-centered form-horizontal formWrapper' style={{ padding: '2%', background: 'white', border: '1px solid #ddd', borderRadius: '4px', height: '60vh', marginTop: '1%' }}>
      <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
        <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
      </div>
      <div className='row'>
        <label className='col-sm-4 text-left' style={{ fontWeight: 'bold' }}>Step</label>
        <div className='col-sm-4' style={{ paddingLeft: '0', width: '29%' }}>
          <label className='text-left padding-remove' style={{ fontWeight: 'bold' }}>Days</label>
        </div>
        <label className='col-sm-4 text-left' style={{ fontWeight: 'bold' }}>Preceding Activity</label>

      </div>
      <LblTxtLbl label='Assign Counsellor:' dataType='text' dataValue={assignCounsellor} precededBy={'Enquiry Addition'} onChange={changeAssignCounsellor} />
      <LblTxtLbl label='Follow Up:' dataType='text' dataValue={followUp} precededBy={'Assign Counsellor'} onChange={changeFollowUp} />
      <LblTxtLbl label='Registration:' dataType='text' dataValue={registration} precededBy={'Follow Up'} onChange={changeRegistration} />
      <LblTxtLbl label='Schedule Test:' dataType='text' dataValue={scheduleTest} precededBy={'Registration'} onChange={changeScheduleTest} />
      <LblTxtLbl label='Schedule Interview:' dataType='text' dataValue={scheduleInterview} precededBy={'Test Schedule Date'} onChange={changeScheduleInterview} />
      <LblTxtLbl label='Interview Date:' dataType='text' dataValue={interviewDate} precededBy={'Registration'} onChange={changeInterviewDate} />
      <div className="form-group col-sm-8"><button type='button' onClick={saveEnquiryTat} className='btn-info btn pull-right'>Save</button></div>
    </div>
  </div>;
};

export default Tat;
