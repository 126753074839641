import React, { Component } from "react";
import $ from "jquery";

export default class FeeHeadsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feeHeadId: 0,
      feeHeads: {
        feeHeadCode: "",
        label: "",
        feeFrequency: "",
        collectionFrequency: "",
        defaultAmt: 0,
        lateFeeFixAmt: 0,
        lateFeeAmtPerDay: 0,
        lateFeeLimit: 0,
        discountSiblingAmt: 0,
        discountScholarAmt: 0,
        discountMeritMeansAmt: 0,
        discountScStAmt: 0,
        discountBackwardAmt: 0,
        lstCollection: {
          feeId: 0,
          month: "",
          amount: 0,
          remarks: "",
          selected: ""
        }
      }
    }
  }
  /* componentDidMount() {
    this.rowClick = this.rowClick.bind(this);
  } */
  rowClick = (e, feeHeadId, feeHeadData) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    $('#feeHeadsBodyTable tr#' + feeHeadId).addClass('selected').siblings().removeClass('selected');

  };
  handleFilterLabel = (e) => {
    var value = e.target.value
    // value = value.toString()
    this.setState({
        [e.target.name]: value
    }, () => this.props.filterFeeHeadList(value))

}
  render() {
    return (
      <>
        <div className="col-md-12">
          <div className="col-md-9 col-centered">
            <h4 style={{ marginTop: 10 + 'px', marginLeft: 10 + 'px' }}>Fee Heads</h4>
            <hr style={{ marginTop: 10 + 'px', marginLeft: 10 + 'px' }}></hr>
          </div>
        </div>
        <div className="col-md-12">
          <div className="col-md-9 col-centered">
            <div className="col-md-11 padding-remove">
              <table className="tableLayout1">
                <thead>
                  <tr>
                    {/* <th style={{ display: 'none' }}> Fee Head Code Id </th> */}
                    <th rowSpan="2" style={{ width: 20 }}> Collection  Frequency </th>
                    <th rowSpan="2" style={{ width: 20 }}> Fee Head Code </th>
                    <th rowSpan="2" style={{ width: 20 }}> <input type="text" 
                    className='form-control' 
                    onKeyUp={this.handleFilterLabel}
                    onChange={this.handleFilterLabel}/> </th>
                    <th style={{ width: 75 }} colSpan="5"> Discount Setup </th>
                    <th rowSpan="2" style={{ width: 15 }}> Late fee Setup </th>
                    <th rowSpan="2" style={{ width: 20 }}> Actions </th>
                  </tr>
                  <tr>
                    <th>Sibling</th>
                    <th>Scholar</th>
                    <th>Merit</th>
                    <th>SC/ST</th>
                    <th>Backward</th>

                  </tr>
                </thead>
              </table>
              <div
                className="feeHeadsTableDiv"
                style={{
                  height: "400px",
                  overflowY: "scroll",
                  border: "1px solid #ccc",
                }}
              >
                <table id="feeHeadsTable" className="tableLayout">
                  <tbody id="feeHeadsBodyTable">
                    {this.props.lstFeeHead && this.props.lstFeeHead.map((feeHeads, index) => (
                      <tr
                        key={feeHeads.feeHeadId}
                        id={feeHeads.feeHeadId}
                        onClick={(e) =>
                          this.rowClick(e, feeHeads.feeHeadId, feeHeads)
                        }
                      >
                        <td width="20" className="text-left">
                          {feeHeads.collectionFrequency}
                        </td>
                        <td width="20" className="text-left">
                          {feeHeads.feeHeadCode}
                        </td>
                        <td width="20" className="text-left">
                          {feeHeads.label}
                        </td>

                        <td width="15" className="text-center">
                          {feeHeads.flgSiblingAmt ? <span className="glyphicon glyphicon-ok"></span> : <span className="glyphicon"></span>}
                        </td>
                        <td width="15" className="text-center">
                          {feeHeads.flgScholarAmt ? <span className="glyphicon glyphicon-ok"></span> : <span className="glyphicon"></span>}
                        </td>
                        <td width="15" className="text-center">
                          {feeHeads.flgMeritMeansAmt ? <span className="glyphicon glyphicon-ok"></span> : <span className="glyphicon"></span>}
                        </td>
                        <td width="15" className="text-center">
                          {feeHeads.flgScstAmt ? <span className="glyphicon glyphicon-ok"></span> : <span className="glyphicon"></span>}
                        </td>
                        <td width="15" className="text-center">
                          {feeHeads.flgBackwardAmt ? <span className="glyphicon glyphicon-ok"></span> : <span className="glyphicon"></span>}
                        </td>
                        <td width="15" className="text-center">
                          {feeHeads.flgLateFee ? <span className="glyphicon glyphicon-ok"></span> : <span className="glyphicon"></span>}
                        </td>
                        <td width="20" className="text-center">
                          <span
                            onClick={() => {
                              this.setState({ flgAdd: false });
                              this.props.showSlider(feeHeads.feeHeadId, feeHeads, false);
                            }}
                            title="edit"
                            className="glyphicon glyphicon-edit"
                          ></span>
                          {/* <span
                            onClick={() => {
                              this.props.getDiscountData(feeHeads.feeHeadId, feeHeads)

                            }}
                            title="discount"
                            className="glyphicon glyphicon-asterisk"
                          ></span> */}
                          <span
                            onClick={() =>
                              this.props.deleteFeeHead(feeHeads.feeHeadId)
                            }
                            title="delete"
                            className="glyphicon glyphicon-trash"
                          ></span>                          
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="col-md-1 mt-10 ">
              <span
                id="addFeeHeads"
                title="Add Fee Head"
                onClick={() => { this.setState({ flgAdd: true }); this.props.showSlider(this.state.feeHeadId, this.state.feeHeads, true); }}
                className="addIcon glyphicon glyphicon-plus-sign"
              ></span>
            </div>
          </div>

        </div>
      </>
    );
  }
}
