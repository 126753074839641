import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import Header from "common/js/header/header.js";

import WTestScheduleTable from './WTestScheduleTable.js';
import TestScheduleFormSlider from './TestScheduleFormSlider.js';
import TestPDFSlider from './testPDFSlider.js';
import BatchTimeSlider from './wTestScheduleBatchTime.js';
import ScheduleTestDates from './wTestScheduleDates.js';
import DeleteTestSchedule from './deleteTestSchedule.js';
import { generateQuestionsSvc } from './WTestScheduleSvc.js'

const WTestScheduleCtrl = () => {


  const [loader, setLoader] = useState("none");
  const [testId, setTestId] = useState(0);
  const [testScheduleId, setTestScheduleId] = useState(0);
  const [testBatchId, setTestBatchId] = useState(0);
  const [test, setTest] = useState("");
  const [testDt, setTestDt] = useState("");

  const lstTestMode = [{ testModeId: 10, testMode: "Printout" }, { testModeId: 20, testMode: "CBT-Interactive" }, { testModeId: 30, testMode: "CBT-Fixed" }];
  const lstEvaluationMode = [{ evaluationModeId: 10, evaluationMode: "Manual" }, { evaluationModeId: 20, evaluationMode: "Automated" }];

  const [flgSlider, setFlgSlider] = useState(0);        // edit:10, batchtimte:20, testDates:30, print:40, deleteWarning: 50
  const [scheduleTableKey, setScheduleTableKey] = useState(0);


  useEffect(() => {
    // // console.log("useEffect called in WtestScheduleCtrl.");
  }, []);

  async function openTestSchSlider(pTestScheduleId, pTestBatchId) {
    await setTestScheduleId(pTestScheduleId);
    await setTestBatchId(pTestBatchId);
    setFlgSlider(10);
  }

  function closeSlider() {
    setFlgSlider(0);
    setTestScheduleId(0);
    setTestBatchId(0);
    setTestId(0);
  }

  const editSchedule = (pTestScheduleId, pTestBatchId) => {
    // // console.log("editSchdule called, pTestScheduleId", pTestScheduleId, ", pTestBatchId", pTestBatchId);
    openTestSchSlider(pTestScheduleId, pTestBatchId);
  }

  const setBatchTime = async (pTestScheduleId) => {
    // // console.log("setBatchTime called, pTestScheduleId", pTestScheduleId);
    await setTestScheduleId(pTestScheduleId);
    setFlgSlider(20);
  }

  const setTestDates = async (pTestScheduleId) => {
    // // console.log("setTestDates called, pTestScheduleId", pTestScheduleId);
    await setTestScheduleId(pTestScheduleId);
    setFlgSlider(30);
  }

  const deleteSchedule = async (pTestScheduleId) => {
    // // console.log("deleteSchedule called, pTestScheduleId", pTestScheduleId);
    await setTestScheduleId(pTestScheduleId);
    setFlgSlider(50);
  }

  const generateQuestions = (pTestScheduleId, pTestId, pNoOfQus, pClsId) => {
    // // console.log("generateQuestions called, pTestScheduleId", pTestScheduleId);
    generateQuestionsSvc(pTestScheduleId, pTestId, pNoOfQus, pClsId, cbGenerateQuestionsResponse)
  }

  function cbGenerateQuestionsResponse(data) {
    // // console.log("cbGenerateQuestionsResponse() called.. ");
    if (data.status === "Success") {
      toast.success(data.message)
    } else {
      toast.error(data.message)
    }
  }
  const startTest = (pTestScheduleId, pTestId) => {
    // // console.log("startTest called, pTestScheduleId", pTestScheduleId, ", pTestId", pTestId);
    const testPageUrl = "/wTest?testId=" + pTestId;
    // window.location.href = testPageUrl;
    window.open(testPageUrl, '_blank');
  }

  const printTest = (pTestScheduleId, pTestId) => {
    // // console.log("printTest called, pTestScheduleId", pTestScheduleId, ", pTestId", pTestId);
    setTestId(pTestId);
    setTestScheduleId(pTestScheduleId);
    setFlgSlider(40);
  }

  const updateKey = () => {
    // // console.log("updateKey called");
    setScheduleTableKey((prevKey) => prevKey + 1);
  }



  return (
    <div style={{ height: '100%' }}>
      <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '40%', top: '40%', display: loader }} >
        <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
      </div >
      <Header />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className='MainContainer' style={{ pointerEvents: loader === "none" ? "auto" : "none", marginTop: 30 }}>
        <div className="col-sm-12 col-centered">
          <div className="col-sm-11 padding-remove" style={{ width: '96%' }}>
            <WTestScheduleTable
              loader={loader}
              setLoader={setLoader}
              lstEvaluationMode={lstEvaluationMode}
              lstTestMode={lstTestMode}
              testScheduleId={testScheduleId}
              testBatchId={testBatchId}
              setTestScheduleId={setTestScheduleId}
              setTestBatchId={setTestBatchId}
              scheduleTableKey={scheduleTableKey}
              editSchedule={editSchedule}
              deleteSchedule={deleteSchedule}
              setBatchTime={setBatchTime}
              setTestDates={setTestDates}
              generateQuestions={generateQuestions}
              startTest={startTest}
              printTest={printTest}
            />
          </div>
          <div className="col-sm-1 padding-remove" style={{ width: '4%' }} onClick={(e) => openTestSchSlider(0, 0)}>
            <span id="addReadyId" title="Add" className="addIcon glyphicon glyphicon-plus-sign"></span>
          </div>
        </div>
      </div>
      {flgSlider === 10 ?
        <div className="TestSlider" style={{ display: flgSlider ? 'block' : 'none', width: "50%" }}>
          <TestScheduleFormSlider
            key={testScheduleId}
            testScheduleId={testScheduleId}
            testBatchId={testBatchId}
            setTestScheduleId={setTestScheduleId}
            lstEvaluationMode={lstEvaluationMode}
            lstTestMode={lstTestMode}
            updateKey={updateKey}
            closeSlider={closeSlider}
          />
        </div>
        : null
      }
      {flgSlider === 20 ?
        <div className='slider-90'>
          <BatchTimeSlider
            key={testScheduleId}
            testScheduleId={testScheduleId}
            closeSlider={closeSlider}
            updateKey={updateKey}
          />
        </div>
        : null
      }
      {flgSlider === 30 ?
        <div className='slider1-60'>
          <ScheduleTestDates
            key={testScheduleId}
            testScheduleId={testScheduleId}
            closeSlider={closeSlider}
          />
        </div>
        : null
      }
      {flgSlider === 40 ?
        <div className='slider1-60'>
          <TestPDFSlider
            testId={testId}
            testScheduleId={testScheduleId}
            closeSlider={closeSlider}
          />
        </div>
        : null
      }
      {flgSlider === 50 ?
        <DeleteTestSchedule
          testScheduleId={testScheduleId}
          closePopup={closeSlider}
          updateKey={updateKey}
        />
        : null
      }
    </div>
  )
}

export default WTestScheduleCtrl