import React, { useEffect } from 'react';
import { Text, View, Font } from '@react-pdf/renderer';

import { styleCoSchoScore, styleSummary, styleSchoScore } from './termReportPDFStyle';


Font.register({
    family: 'Calibri'
    , fonts: [{ src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibri.ttf" }
        , { src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibrib.ttf", fontWeight: "bold" }], format: 'truetype'
});


const CoScholasticSection = (props) => {
    const { studentSummary, lstCoscholastic, lstExam } = props;

    useEffect(() => {
        // // console.log("CoScholasticSection called, lstCoScholastic", lstCoscholastic);
        // // console.log("CoScholasticSection called, lstExam", lstExam);
    }, [lstCoscholastic, lstExam]);

    return (
        <View style={styleCoSchoScore.container}>
            <View style={styleCoSchoScore.score_table_coscho}>
                <View style={styleCoSchoScore.exam_list}>
                    <View style={[styleCoSchoScore.exam_row, { flex: 1 }]}>
                        <View style={[styleCoSchoScore.top_left_cell, { flex: 4, backgroundColor: '#f6f6f6' }]}>
                            <Text style={styleCoSchoScore.text_schoExam_label}>Grade Subjects</Text>
                        </View>
                        <View style={[styleCoSchoScore.mark_col, { flex: 6, backgroundColor: '#f6f6f6' }]}>
                            {lstExam && lstExam.length ?
                                lstExam.map((exam, idx) => (
                                    exam.examId != -1 ?
                                        <View key={exam.examId} style={[styleCoSchoScore.top_cell, { flex: 1, backgroundColor: '#f6f6f6' }]}>
                                            <Text style={[styleCoSchoScore.text_schoExam_label, styleCoSchoScore.text_center]}>
                                                {exam.exam}
                                            </Text>
                                        </View>
                                        : null
                                ))
                                : null
                            }
                        </View>
                    </View>
                    {lstCoscholastic && lstCoscholastic.length ?
                        lstCoscholastic.map((subject, idx) => (
                            <View key={subject.subId} style={[styleCoSchoScore.exam_row, { flex: 1 }]}>
                                <View style={[styleCoSchoScore.left_cell, { flex: 4 }]}>
                                    <Text style={styleCoSchoScore.text_subject_label}>{subject.sub}</Text>
                                </View>
                                <View style={[styleCoSchoScore.mark_col, { flex: 6 }]}>
                                    {
                                        !!subject.lstGrade && subject.lstGrade.length ?
                                            subject.lstGrade.map((grade) => (
                                                <View style={[styleCoSchoScore.inner_cell, { flex: 6 }]}>
                                                    <Text style={[styleCoSchoScore.text_subject_score, { color: grade === "Ab" ? "red" : "" }]}>{grade}</Text>
                                                </View>
                                            ))
                                            : null
                                    }
                                </View>
                            </View>
                        )) :
                        <View style={[styleCoSchoScore.exam_row, { flex: 1 }]}>
                            <View style={[styleCoSchoScore.left_cell, { flex: 1 }]}>
                            </View>
                        </View>
                    }
                </View>
                <View style={[styleCoSchoScore.remarks_list, { flex: 3 }]}>
                    <View style={[{ flex: 1, flexDirection: "row" }]}>
                        <View style={[{ flex: 2 }]}>
                            <Text style={styleCoSchoScore.text_remark_label}>{"REMARKS"}</Text>
                        </View>
                        <View style={[styleSummary.subject_col, { flex: 3 }]}>
                            <View style={[{ flex: 11, backgroundColor: '#f0f0f0', }]}>
                                <Text style={[styleSchoScore.attendance_label, styleSchoScore.text_center]}>
                                    Attendance
                                </Text>
                            </View>
                            <View style={[styleSummary.sectionCell, { flex: 9 }]}>
                                <Text style={[styleSchoScore.text_schoExam_label, styleSchoScore.text_center]}>
                                    {!!studentSummary ? studentSummary.attendance : ""}
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={[styleCoSchoScore.remark_data, { flex: 5 }]}>
                        <Text style={[styleCoSchoScore.text_remark,]}>{!!studentSummary ? studentSummary.remarks : ""}</Text>
                    </View>
                </View>
            </View>
        </View>
    );
}

export default CoScholasticSection;