import axios from "axios";
import lstModules from "common/json/lstModules.json";
var httpURL = lstModules["library"].url + ":" + lstModules["library"].port;
// var httpURLMain = lstModules["main"].url + ":" + lstModules["main"].port;
var tokenid = localStorage.getItem("tokenid");

function searchBookSvc(pBookNo, pCopyNo, callBack) {
  axios({
    url: httpURL + "/sms/library/issue/book/search",
    method: "POST",
    params: { bookNo: pBookNo, copyNo: pCopyNo },
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      callBack(data);
    })
    .catch((error) => {
      // // console.log("Book Search Catch error");
    });
}

function searchBorrowerSvc(pKey, pFlgStaff, callBack) {
  axios({
    url: httpURL + "/sms/library/issue/borrower/search",
    method: "POST",
    params: { key: pKey, flgStaff: pFlgStaff },
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      callBack(data);
    })
    .catch((error) => {
      // // console.log("Borrower Search Catch error");
    });
}

function getBorrowerBookListSvc(pStaffId, pStudentId, callBack) {
  axios({
    url: httpURL + "/sms/library/issue/borrower/issue/list",
    method: "POST",
    params: { studentId: pStudentId, staffId: pStaffId },
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((Response) => {
      var data = Response.data;
      callBack(data);
    })
    .catch((error) => {
      // // console.log("Borrower Book List Catch error");
    });
}

function saveIssueBookSvc(pBorrowerObj, callBack) {
  var formData = new FormData();
  formData.append("borrowerObj", JSON.stringify(pBorrowerObj));
  axios({
    url: httpURL + "/sms/library/issue/borrower/issue/save",
    method: "POST",
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true,
  })
    .then((response) => {
      var data = response.data;
      callBack(data);
    })
    .catch((error) => {
      // // console.log("Error Occured in Issue Book");
    });
}

export {
  searchBookSvc,
  searchBorrowerSvc,
  getBorrowerBookListSvc,
  saveIssueBookSvc,
};
