import React, { Component, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import { toast, ToastContainer } from 'react-toastify';
import '../css/paymentSlider.css';
var httpURL = '';
var queryString = require('querystring');
function PaymentReceipt(props) {
    const [pdfObj, setPdfObj] = useState(props.pdfObj)

    useEffect(() => {
        // // console.log('payment receipt')
        
    }, [props]);
    return (
        <div>
            <button type="button" className="btn btn-info text-center " id="closeSlider" style={{ right: '71%', opacity: 1 }}
                onClick={props.closeSlider}>
                <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
            </button>
            <div className="pdf" style={{ width: '100%', height: '96vh' }}>
                <iframe src={require("common/images/loader.gif")} title="Time Table Preview" id="viewer" style={{ width: '100%', height: '100%' }} />
            </div>


        </div>
    )
}
export default PaymentReceipt;