import React, { useEffect, useState } from 'react';
import { Document, Page, PDFViewer, View, Text } from '@react-pdf/renderer';

import { pageStyle } from './styleBonafidePdf';
import BonafideHeader from './BonafideHeader';
import BonafideContent from './BonafideContent';
import BonafideNotice from './BonafideNotice';
import BonafideFooter from './BonafideFooter';


const BonafidePdf = (props) => {
    const [certificateName, setCertificateName] = useState("Bonafide Certificate");
    const [gender, setGender] = useState(""); // Passed from props
    const [parentName, setParentName] = useState("");
    const [enrollmentNo, setEnrollmentNo] = useState("");
    const [cls, setCls] = useState("");
    const [academicYear, setAcademicYear] = useState("");
    const [dob, setDob] = useState("");
    const [studentName, setStudentName] = useState("");
    const [schoolName, setSchoolName] = useState("");
    const [classTeacherSign, setClassTeacherSign] = useState("");
    const [schoolLogo, setSchoolLogo] = useState("");
    const [principalSign, setPrincipalSign] = useState("");
    const [studentNotes, setStudentNotes] = useState("");
    const [printDt, setPrintDt] = useState("");

    useEffect(() => {
        // // console.log("BonafidePdf component called, props.pdfDtls", props.pdfDtls);
        setSchoolLogo(props.schoolLogo);
        setPrincipalSign(props.principalSign);

        setAcademicYear(props.pdfDtls.acdYr);
        setParentName(props.pdfDtls.father);
        setEnrollmentNo(props.pdfDtls.enrollmentNo);
        setCls(props.pdfDtls.cls);
        setDob(props.pdfDtls.dob);
        setStudentName(props.pdfDtls.student);
        setSchoolName(props.pdfDtls.school);
        setGender(props.pdfDtls.gender);
        setClassTeacherSign(props.pdfDtls.pathClassTeacherSign);
        setStudentNotes(props.pdfDtls.remarksPrint);
        setPrintDt(props.pdfDtls.printDt);
    }, [props]);

    return (
        <div className='col-sm-12 padding-remove'>
            <PDFViewer style={{ width: "100%", height: "95vh", margin: "2vh 0", overflowY: "scroll" }}>
                <Document>
                    <Page orientation="landscape" size="A4" style={pageStyle.page}>
                        <View style={pageStyle.page_border}>
                            <View style={pageStyle.header_section}>
                                <BonafideHeader
                                    schoolLogo={schoolLogo}
                                    schoolName={schoolName}
                                />
                            </View>
                            <View style={[pageStyle.certificate_content_section]}>
                                <BonafideContent
                                    printDt={printDt}
                                    certificateName={certificateName}
                                    gender={gender}
                                    studentName={studentName}
                                    parentName={parentName}
                                    enrollmentNo={enrollmentNo}
                                    cls={cls}
                                    year={academicYear}
                                    dob={dob}
                                    schoolName={schoolName}
                                    notes={studentNotes}
                                />
                            </View>
                            <View style={pageStyle.footer_section}>
                                <BonafideFooter
                                    principalSign={principalSign}
                                    teacherSign={classTeacherSign}
                                />
                            </View>
                        </View>
                    </Page>
                    {/* <Page orientation="portrait" size="A4" style={pageStyle.page}>
                        <View>
                            <Text style={fontStyles.fontKaushan}>{"Font Kaushan_Script normal fontsize 14"}</Text>
                            <Text style={fontStyles.fontNaunamGauthic}>{"Font Nanum_Gothic normal fontsize 14"}</Text>
                            <Text style={fontStyles.fontNaunamGauthicBold}>{"Font Nanum_Gothic bold fontsize 14"}</Text>
                            <Text style={fontStyles.fontNaunamGauthicMedium}>{"Font Nanum_Gothic medium fontsize 14"}</Text>
                            <Text style={fontStyles.fontOleo}>{"Font Oleo_Script normal fontsize 14"}</Text>
                            <Text style={fontStyles.fontOleoBold}>{"Font Oleo_Script  bold fontsize 14"}</Text>
                            <Text style={fontStyles.fontMingzatMedium}>{"Font fontMingzatMedium  normal fontsize 14"}</Text>
                            <Text style={fontStyles.fontMontserrat}>{"Font fontMontserrat  normal fontsize 14"}</Text>
                            <Text style={[fontStyles.fontMontserratItalic, {color:"black"}]}>{"Font fontMontserrat italic fontsize 14"}</Text>
                            <Text style={fontStyles.fontInter}>{"Font inter  normal fontsize 14"}</Text>
                            <Text style={fontStyles.fontInterItalic}>{"Font Inter italic fontsize 14"}</Text>
                        </View>
                    </Page> */}
                </Document>
            </PDFViewer>
        </div>
    );
}

export default BonafidePdf;
