

const ClassEditPopup = (props) => {

    return (
        <div style={{ display: "flex", justifyContent: "center", padding: 0, position: "absolute", top: "25%", left: "35%", width: "400px", height: "150px", zIndex: 1001 }}>

        <form className="form-horizontal  formWrapper" style={{ width: '100%', height:"100%" }} >
            <div className="form-group">
                <label className="control-label col-sm-5" > Prefect<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                <div className="col-sm-6 padding-remove">
                    <select
                        // onChange={changeName} 
                        className="form-control" style={{ width: '100%' }} ></select>
                </div>
            </div>

            <div className="form-group ">
                <label className="control-label col-sm-5" >Monitor <span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                <div className="col-sm-6 padding-remove">
                    <select
                        // onChange={changeMonitor} 
                        className="form-control" style={{ width: '100%' }} ></select>
                </div>
            </div>


            <div className="form-group" style={{ marginTop: 10 }}>
                <div className="col-sm-12 text-center userButtonWrap" >
                    <button type="button" title="Save"
                        // onClick={maintainStundent} 
                        className="btn btn-info">Save</button>
                    <button type="button" title="Cancel" onClick={props.cancelStudent} className="btn btn-info">Cancel</button>
                </div>
            </div>
        </form>
            </div>
    );
}

export default ClassEditPopup;