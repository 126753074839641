import lstModules from 'common/json/lstModules.json';
import axios from 'axios';


const screenId = 36;
const tokenid = localStorage.getItem('tokenid');
const httpURL = lstModules['fees'].url + ":" + lstModules['fees'].port;
// const httpURLCommon = lstModules['main'].url + ":" + lstModules['main'].port;


export const getClassListSvc = (cbGetClassList) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/sms/fee/cls/list',
        method: "POST",
        headers: { 'tokenid': tokenid },
        params: { 'screenId': screenId },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstFeeClass= response.data.lstFeeClass;
        }
        cbGetClassList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbGetClassList(obj);
    })
}


export const feeCollectionListSvc = (classSectionId, cbFeeCollectionList) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/sms/fee/cls/head/list',
        method: "POST",
        headers: { 'tokenid': tokenid, "classSectionId": classSectionId },
        params: { 'screenId': screenId },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstFeeCollection= response.data.lstFeeClass;
        }
        cbFeeCollectionList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbFeeCollectionList(obj);
    })
}


export const saveFeeCollectionSvc = (saveFeeClassHead, cbSaveFeeCollection) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/sms/fee/cls/head/list',
        method: "POST",
        headers: { 'tokenid': tokenid, "saveFeeClassHead": JSON.stringify(saveFeeClassHead) },
        params: { 'screenId': screenId },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbSaveFeeCollection(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbSaveFeeCollection(obj);
    })
}


export const sendFeebookSvc = (classSectionId, studentId, cbSendFeebook) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/sms/fee/cls/students/feebook',
        method: "POST",
        headers: { 'tokenid': tokenid, "classSectionId": classSectionId, "studentId": studentId },
        params: { 'screenId': screenId },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbSendFeebook(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbSendFeebook(obj);
    })
}


export const sendFeebookClassSectionSvc = (classSectionId, cbSendFeebookClassSection) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/sms/fee/cls/feebook/mail',
        method: "POST",
        headers: { 'tokenid': tokenid, "classSectionId": classSectionId },
        params: { 'screenId': screenId },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbSendFeebookClassSection(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbSendFeebookClassSection(obj);
    })
}

