import { StyleSheet } from '@react-pdf/renderer';


const innerBorderWidth = ".40px";
const tablePartitionWidth = ".60px";


export const pageStyle = StyleSheet.create({
    page: {
        // backgroundColor: "#fff",
        color: "white",
        display: 'flex',
        flexDirection: 'column',
        padding: 20
    },
    page_border: {
        height: '100%',
        width: '100%',
        borderWidth: 1,
        borderColor: "#000",
        // backgroundColor:"red"
    },
    header_section: {
        marginTop:"10px",
        flex:4,
    },
    syllabus_section:{
        flex:36,
    },
    
    horizontalBar1: {
        borderBottom: '2px solid #b7b7b7',
        margin: '3px 20px',
        // alignContent:"center",
        // alignItems:"center",
        // textAlign:"center",
        // width:"300px"
    },
    horizontalBar2: {
        borderBottom: '1px solid #b7b7b7',
        margin: '2px 0',
    },
});

export const textStyle = StyleSheet.create({
    schoolNameText: {
        textAlign: "center",
        // textTransform: "uppercase",
        fontSize: 14,
        fontWeight: "bold",
        color: '#000',
        flex: 1,
        justifyContent: "center",
        margin: "5px 0"
    },
    headerNameText: {
        textAlign: "center",
        // textTransform: "uppercase",
        fontSize: 12,
        fontWeight: "bold",
        color: '#000',
        flex: 1,
        justifyContent: "center",
        margin: "2px 0"
    },
    examNameText:{
        // textAlign:"left",
        fontSize:12,
        fontWeight:"bold",
        justifyContent:"center",
        margin:0
    },
    classNameText:{
        // textAlign:"right",
        fontSize:12,
        fontWeight:"bold",
        justifyContent:"center",
        margin:0
    },
    subjectNameText: {
        textAlign: "left",
        // textTransform: "uppercase",
        fontSize: 12,
        fontWeight: "400",
        color: '#000',
        flex: 1,
        justifyContent: "center",
        margin: "2px 5px"
    },
    chapterNameText: {
        textAlign: "left",
        // textTransform: "uppercase",
        fontSize: 11,
        fontWeight: "400",
        color: '#000',
        flex: 1,
        justifyContent: "center",
        margin: "2px 0"
    }
})

export const headerStyle = StyleSheet.create({
    header_text_section: {
        // height: '100%',
        flex:1,
        color: "#000",
        display: 'flex',
        flexDirection: 'column',
        // borderWidth: 1,
        // borderColor: "#000",
    },
    schoolSection:{
        flex:3,
        marginBottom:"10px",
    },
    examClass:{
        margin:"0 10px",
        // flex:1,
        height:"20px",
        flexDirection:"row",
        // borderWidth: "1px",
        // borderColor: "#000",
        justifyContent:"center"
    },
    examName:{
        padding:"0 5px",
        flex:1,
        // borderWidth: "1px",
        // borderColor: "#000",
        justifyContent:"center"
    },
    className:{
        padding:"0 5px",
        flex:1,
        // borderWidth: "1px",
        // borderColor: "#000",
        textAlign:"right",
        justifyContent:"center"
    },
    header_text: {
        flexDirection: 'row',
        family: 'Calibri',
        alignContent: "center",
        // borderWidth: 1,
        // borderColor: "#000",
        paddingVertical:0,
        // justifyContent:"center",
        // alignSelf:"center",
        alignItems:"center"
    },
})

export const syllabusSectionStyle = StyleSheet.create({
    syllabusContainer:{
        margin:"0 10px 10px 10px",
        flex:36,
        flexDirection:"row",
        borderWidth: "1px",
        borderColor: "#000",
    },
    syllabusColLeft:{
        padding:"5px",
        flex:1,
        flexDirection:"column"
    },
    syllabusColRight:{
        padding:"5px",
        flex:1,
        flexDirection:"column",
        borderLeftWidth:"1px",
        borderLeftColor:"#000"
    },
    subjectNameRow: {
        height:"20px",
        marginTop:"10px",
        // marginBottom:"5px",
        marginLeft:"5px",
        marginRight:"2px",
        // padding:"5px",
        // backgroundColor:"gray",
        backgroundColor:"#d3d3d3",
        borderWidth: tablePartitionWidth,
        borderColor: "#000",
        // alignItems:"center"
        // justifyContent:"center",
    },
    chapterRow:{
        marginLeft:"25px",
        marginRight:"5px",
        padding:"5px",
        // alignItems:"center"
        // justifyContent:"center",
    },
})