import React, { useState, useEffect } from 'react'
import { getCellDataSvc } from './CellForExamSvc';
import { toast } from 'react-toastify';


const CellForExam = (props) => {
    const [examDtls, setExamDtls] = useState({});
    const [cellLoader, setCellLoader] = useState('none')
    const [isMounted, setIsMounted] = useState(true)

    useEffect(() => {
        if (isMounted) {
            var examObj = {
                examId: props.examId
                , examDt: props.examDt
                , sectionId: props.sectionId
            }
            setCellLoader('block')
            getCellDataSvc(props.examId, props.examDt, props.sectionId, cb);
        }
        return () => {
            setIsMounted(false)
        }
    }, [props])

    function cb(data) {
        if (data.status === "Success") {
            setExamDtls(data.examDtls)
        }
        else if (data.status === 'Failure') {
            if (data.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (data.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else {
                toast.error(data.message);
                return false;
            }
        } else {
            toast.error("Service failed " + data.error);
        }
        setCellLoader('none')
        
    }
    return (
        <>
            <div style={{
                display: cellLoader === 'block' ? 'flex' : cellLoader, justifyContent: 'center',
                alignItems: 'center'
            }}>
                <img style={{ width: '20px', height: '20px', marginleft: '34%', margintop: '10%' }} className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>

            <div className="hidden">{examDtls.subId}</div>
            <div>{examDtls.sub}</div>
            <div>{examDtls.tch}</div>
        </>
    )
}

export default CellForExam