import React, { useEffect, useState } from "react";
import "../css/login.css";
import "common/css/master.css";
import { toast } from "react-toastify";

import { forgotPasswordSvc } from "./loginSvc";

const Forgotpassword = (props) => {
    const { email, setLoader, setOpacity } = props;

    const forgotPassword = () => {
        if (email === "") {
            toast.warning("Please enter email Id");
            return false;
        }
        setLoader("block");
        setOpacity(0.5);
        forgotPasswordSvc(email, forgotPasswordResponse);
    }

    const forgotPasswordResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            toast.success(obj.message);
        }
        else {
            toast.error(obj.message);
        }
        setLoader("none");
        setOpacity(1);
    }

    return (
        <>
            <div className="loginWrap">
                <div className="form-group forgotPass">
                    <span onClick={forgotPassword} id="forgotPwd">
                        Forgot password ?
                    </span>
                </div>
            </div>
        </>
    );
}

export default Forgotpassword;