import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import { toast, ToastContainer } from 'react-toastify';
export default class discountForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            paidById: 0,
            discountTo: 10,
        }
    }

    save = (feeClassId) => {
        let discountId = $('input[name=discountTo]:checked').val();
        if (discountId === undefined) {
            toast.warning('Please Select Discount');
            return false;
        }

        this.props.saveDiscount(discountId, feeClassId);
    }

    handleRadioChange = (discountId) => {
        this.setState({
            discountTo: discountId
        })
    }

    render() {
        let feeHeadData = this.props.feeHeadData
        return (

            <>
                

                <form id="addForm" className="form-horizontal formWrapper clearfix">

                    <div className="col-sm-12">
                        <div className="form-group col-sm-6">
                            <label className="control-label col-sm-6" >Fees Code</label>
                            <div className="col-sm-6 padding-remove">
                                <input autoComplete="off" name="feesCode" id="feesCode"
                                    type="text" className="form-control "
                                    value={feeHeadData.feeHeadCode}
                                    disabled />
                            </div>
                        </div>
                        <div className="form-group col-sm-6">
                            <label className="control-label col-sm-6" >Fees Label</label>
                            <div className="col-sm-6 padding-remove">
                                <input autoComplete="off" name="feesLabel" id="feesLabel"
                                    type="text" className="form-control "
                                    value={feeHeadData.feeLabel}
                                    disabled />
                            </div>
                        </div>
                        <div className="form-group col-sm-6">
                            <label className="control-label col-sm-6" >Collection</label>
                            <div className="col-sm-6 padding-remove">
                                <input autoComplete="off" name="collection" id="collection"
                                    type="text" className="form-control "
                                    value={feeHeadData.collectionFrequency}
                                    disabled />
                            </div>
                        </div>
                        <div className="form-group col-sm-6">
                            <label className="control-label col-sm-6" >Amount</label>
                            <div className="col-sm-6 padding-remove">
                                <input autoComplete="off" name="amount" id="amount"
                                    type="text" className="form-control "
                                    value={feeHeadData.amount}
                                    disabled />
                            </div>
                        </div>

                    </div>
                    <div className="col-sm-12 ">
                        <div className="col-sm-10 radioButtonsWrap">
                            <div className="col-sm-6 text-center">
                                <h5>Discount</h5>
                            </div>
                            <div className="col-sm-6 text-center">
                                <h5>Amount</h5>
                            </div>
                        </div>
                        {this.props.feeDiscount.map((discountObj) => (
                            <div key={discountObj.discountId} className="col-sm-12 radioButtonsWrap">
                                <div className="col-sm-6">
                                    <input type="radio"
                                        id={discountObj.discountLabel}
                                        name="discountTo"
                                        value={discountObj.discountId}
                                        defaultChecked={discountObj.selected}
                                        // checked={this.state.discountTo === discountObj.discountId}
                                        // onChange={() => this.handleRadioChange(discountObj.discountId)}
                                        disabled={discountObj.discountAmt > 0 ? false : true}
                                    />
                                    <label htmlFor="Backward">{discountObj.discountLabel}</label>
                                </div>
                                <div className="col-sm-6">
                                    <input autoComplete="off"
                                        name={discountObj.discountLabel}
                                        id={discountObj.discountLabel}
                                        type="text" className="form-control "
                                        value={discountObj.discountAmt}
                                        disabled />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group" >
                            <div className="col-sm-12 text-center pull-right" >
                                <button type="button" style={{ marginTop: 20 }}
                                    title="Save"
                                    onClick={() => this.save(feeHeadData.feeClassId)}
                                    className="btn btn-info">Save</button>
                            </div>
                        </div>
                    </div>
                </form >
            </>

        )
    }
}
