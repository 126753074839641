import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'common/css/master.css'
import '../css/StaffCtrl.css'
import Header from 'common/js/header/header.js'
import StaffTable from './StaffTable'
import StaffDtlsSlider from './StaffDtlsSlider.js'
import RoleSlider from './RoleSlider'
import SliderType from './SliderType'
import ClassSubjectSlider from './ClassSubjectSlider'
import StaffSystemAccess from './StaffSystemAccess'
import AllocationSlider from './AllocationSlider'
import { uploadStaffExcelSvc } from './StaffSvc'
import UploadErrorSlider from 'common/components/UploadErrorSlider'
import StaffSign from './StaffSign';
import AcademicYearPopup from "common/login/js/academicYearPopup";


var tokenid = localStorage.getItem('tokenid')
function StaffCtrl() {
  // const [selStaffId, setSelStaffId] = useState(0)
  const [flgSliderType, setFlgSliderType] = useState(0)
  // const [selectedStaffId, setSelectedStaffId] = useState(0)
  const [selectedUserId, setSelectedUserId] = useState(0)
  const [primaryRoleId, setPrimaryRoleId] = useState(0)
  const [flgSystemAccess, setFlgSystemAccess] = useState(false)
  const [randNo, setRandNo] = useState(0)
  const [loader, setLoader] = useState('none')
  const [minAllocation, setMinAllocation] = useState(0)
  const [maxAllocation, setMaxAllocation] = useState(0)
  const [lstError, setLstError] = useState([]);
  const [academicYearId, setAcademicYearId] = useState(localStorage.getItem('academicYearId') || 0);

  function refreshScreen() {
    setFlgSliderType(0)
    // setSelectedStaffId(0)
    setSelectedUserId(0)
    setPrimaryRoleId(0)
    setFlgSystemAccess(false)
    generateRandomNo()
  }
  function refreshScreen2() {
    setFlgSliderType(0)
    generateRandomNo()
  }
  function generateRandomNo() {
    var lRandNo = Math.floor(Math.random() * 999999)
    // // console.log(lRandNo)
    setRandNo(lRandNo)
  }

  function setSelectedStaffInfo(sliderType, Staff) {
    setSelectedUserId(Staff.userId)
    setMinAllocation(Staff.minAllocation)
    setMaxAllocation(Staff.maxAllocation)
    setFlgSystemAccess(Staff.flgSystemAccess)
    setFlgSliderType(sliderType)
    // // console.log(Staff)
  }
  const triggerUploadExcel = () => {
    document.getElementById('excelUpload').click()
  }
  const uploadExcel = event => {
    setLoader('block')
    /* $("#excelUpload").on('change', function (event) { */

    if (!event.target.files) {
      setLoader('none')
      return
    }

    var files = event.target.files //FileList object
    /* if (!event.target.files.length) {
        // // console.log("no file selected")
        this.setState({ loader: 'none', opacity: 1 });
        return;
    } */
    files = files[0]
    var formData = new FormData()
    formData.append('staffUploadExcelFile', files)
    formData.append("acdYrId", academicYearId);
    uploadStaffExcelSvc(formData, cbStaffExcelUpload)

    /* }); */
  }
  function cbStaffExcelUpload(data) {
    if (data.status === 'Success') {
      toast.success(data.message)
      // $('#btn_showerror').trigger('click');
      refreshScreen()
    } else if (data.status === 'Failure') {
      toast.error(data.message)
      if (data.hasOwnProperty('errors') && data.errors.length !== 0) {
        setFlgSliderType(SliderType.SLIDER_ERROR)
        setErrorList(data.errors)
      }
    } else {
      if (data.message === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (data.message === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else {
        toast.error(data.message)
      }
    }
    setLoader('none')
  }
  const setErrorList = async errors => {
    await setLstError(errors)
  }

  const showErrors = () => {
    // // console.log('slider should be open')
    setFlgSliderType(SliderType.SLIDER_ERROR)
  }
  const getWingId = wingId => {
    // // console.log('wingId' + wingId)
  }

  return (
    <div style={{ height: '100%' }}>
      <Header wingFromChild={getWingId} />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className='MainContainer' style={{ marginTop: '1%' }}>
        <div className='col-sm-8 col-centered'>
          <div className='col-sm-3' style={{ marginBottom: '3px' }}>
            {/* <AcademicYearPopup
              academicYearId={academicYearId}
              setAcademicYearId={setAcademicYearId}
            /> */}
          </div>
          <div
            className='col-sm-11'
            style={{ pointerEvents: loader === 'none' ? 'auto' : 'none' }}
          >
            <StaffTable
              setLoader={setLoader}
              loader={loader}
              key={randNo}
              // selectedS={selectedStaffId}
              // setSelectedStaffId={setSelectedStaffId}
              selectedUserId={selectedUserId}
              setSelectedUserId={setSelectedUserId}
              setFlgSliderType={setFlgSliderType}
              setPrimaryRoleId={setPrimaryRoleId}
              setFlgSystemAccess={setFlgSystemAccess}
              setSelectedStaffInfo={setSelectedStaffInfo}
              refreshScreen={refreshScreen}
            ></StaffTable>
          </div>

          <div className='col-xs-1'>
            <div onClick={e => setFlgSliderType(SliderType.SLIDER_EDIT_DTLS)}>
              <span
                id='addReadyId'
                title='Add'
                className='addIcon glyphicon glyphicon-plus-sign'
              ></span>
            </div>
            <div>
              <span
                id='uploadStaff'
                title='Upload Staff'
                onClick={e => triggerUploadExcel()}
                className='addIcon glyphicon glyphicon-upload'
              ></span>
            </div>
            <input
              key={new Date().getTime()}
              type='file'
              id='excelUpload'
              className='hidden'
              accept='.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              onChange={e => uploadExcel(e)}
            />
            <div>
              <span
                id='viewErrorList'
                title='View Error List'
                onClick={e => showErrors()}
                className='addIcon glyphicon glyphicon-list-alt hidden'
              ></span>
            </div>
          </div>
          <div
            style={{
              width: '50%',
              display:
                flgSliderType === SliderType.SLIDER_SYSTEM_ACCESS
                  ? 'block'
                  : 'none'
            }}
            className='enquirySlider'
          >
            <button
              type='button'
              className='btn btn-info text-center btnCloseDiv'
              style={{ right: '50%' }}
              onClick={refreshScreen}
            >
              <span
                id='close'
                title='Close Slider'
                className='glyphicon glyphicon-remove'
              ></span>
            </button>
            <StaffSystemAccess
              key={selectedUserId}
              selectedUserId={selectedUserId}
              flgSystemAccess={flgSystemAccess}
            />
          </div>
          <div
            style={{
              width: '50%',
              display:
                flgSliderType === SliderType.SLIDER_SIGN ? 'block' : 'none'
            }}
            className='enquirySlider'
          >
            <button
              type='button'
              className='btn btn-info text-center btnCloseDiv'
              style={{ right: '50%' }}
              onClick={refreshScreen}
            >
              <span
                id='close'
                title='Close Slider'
                className='glyphicon glyphicon-remove'
              ></span>
            </button>
            <StaffSign
              key={selectedUserId}
              selectedUserId={selectedUserId}
              flgSliderType={flgSliderType}
              roleId={primaryRoleId}
            />
          </div>

          <div
            style={{
              width: '60%',
              display:
                flgSliderType === SliderType.SLIDER_EDIT_DTLS ? 'block' : 'none'
            }}
            className='staffSlider'
          >
            <button
              type='button'
              className='btn btn-info text-center btnCloseDiv'
              style={{ right: '60%' }}
              onClick={refreshScreen}
            >
              <span
                id='close'
                title='Close Slider'
                className='glyphicon glyphicon-remove'
              ></span>
            </button>
            <StaffDtlsSlider
              key={selectedUserId}
              // staffId={selectedStaffId}
              userId={selectedUserId}
              // setSelectedStaffId={setSelectedStaffId}
              setSelectedUserId={setSelectedUserId}
              closeSlider={refreshScreen2}
              flgSliderType={flgSliderType}
              generateRandomNo={generateRandomNo}
            />
          </div>
          <div
            style={{
              width: '60%',
              display:
                flgSliderType === SliderType.SLIDER_ROLES ? 'block' : 'none'
            }}
            className='enquirySlider'
          >
            <button
              type='button'
              className='btn btn-info text-center btnCloseDiv'
              style={{ right: '60%' }}
              onClick={refreshScreen}
            >
              <span
                id='close'
                title='Close Slider'
                className='glyphicon glyphicon-remove'
              ></span>
            </button>
            <RoleSlider
              key={selectedUserId}
              userId={selectedUserId}
              flgSliderType={flgSliderType}
              closeSlider={refreshScreen}
              primaryRoleId={primaryRoleId}
            />
          </div>
          <div
            style={{
              width: '70%',
              display:
                flgSliderType === SliderType.SLIDER_CLASS_SUBJECTS
                  ? 'block'
                  : 'none'
            }}
            className='enquirySlider'
          >
            <button
              type='button'
              className='btn btn-info text-center btnCloseDiv'
              style={{ right: '70%' }}
              onClick={refreshScreen}
            >
              <span
                id='close'
                title='Close Slider'
                className='glyphicon glyphicon-remove'
              ></span>
            </button>
            <ClassSubjectSlider
              key={selectedUserId}
              userId={selectedUserId}
              flgSliderType={flgSliderType}
              closeSlider={refreshScreen}
            />
          </div>
          {flgSliderType === SliderType.SLIDER_ERROR && (
            <UploadErrorSlider
              lstErrors={lstError}
              heading='Staff'
              countError={lstError.length || 0}
              removeClassFromBody={refreshScreen}
            />
          )}
          <div
            style={{
              width: '50%',
              display:
                flgSliderType === SliderType.SLIDER_WEEKLY_ALLOC
                  ? 'block'
                  : 'none'
            }}
            className='enquirySlider'
          >
            <button
              type='button'
              className='btn btn-info text-center btnCloseDiv'
              style={{ right: '50%' }}
              onClick={refreshScreen}
            >
              <span
                id='close'
                title='Close Slider'
                className='glyphicon glyphicon-remove'
              ></span>
            </button>
            <AllocationSlider
              key={selectedUserId}
              selectedUserId={selectedUserId}
              minAllocation={minAllocation}
              maxAllocation={maxAllocation}
              flgSliderType={flgSliderType}
              closeSlider={refreshScreen}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default StaffCtrl
