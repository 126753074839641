import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    pageStyle: {
        // flexDirection: "row",
        flexDirection: "column",
        // flexDirection: flexDirect,
    },
    pageAllRows: {
        margin: "0px",
        padding: "0px",
        flexDirection: "column",
    },
    pageSingleRow: {
        margin: "0px",
        padding: "0px",
        flexDirection: "row",
    },
    pageDiv: {
        flexDirection: "column",
        border: 1,
        borderColor: "#b7b7b7",
        borderRadius: "5%",
        height: '325px',
        width: '200px',
        margin: "10px",
    },
    frontPage: {
        // backgroundColor: baseColor,
        // backgroundColor: "red",
        padding: "5px",
    },
    backPage: {
        // backgroundColor: baseColor,
        padding: "5px",
    },
    topDiv: {
        height: "45px",
        margin: 0,
        padding: 0,
    },
    mainDiv: {
        backgroundColor: "#ffffff",
        margin: 0,
        flexDirection: "column",
        gap: "2px",
        padding: "5px",
        height: "270px",
    },
    backPageDiv: {
        backgroundColor: "#ffffff",
        margin: 0,
        flexDirection: "column",
        gap: "2px",
        padding: "5px",
        height: "315px",
    },
    divCol1: {
        margin: 0,
        padding: 0,
        flexDirection: "column",
        gap: "5px",
    },
    divCol2: {
        margin: 0,
        padding: 0,
        flexDirection: "column",
        gap: 0,
    },
    divRow1: {
        margin: 0,
        padding: 0,
        flexDirection: "row",
        gap: "5px",
    },
    divRow2: {
        margin: 0,
        padding: 0,
        flexDirection: "row",
        gap: 0,
    },
    divText1: {
        height: "20px",
        width: "190px"
    },
    divText2: {
        height: "20px",
        width: "95px"
    },
    divText3: {
        height: "20px",
        width: "80px"
    },
    divText4: {
        height: "20px",
        width: "95px",
    },
    divText5: {
        height: "30px",
        width: "95px",
    },
    divText6: {
        height: "20px",
        width: "180px"
    },
    divText7: {
        height: "50px",
        width: "120px"
    },
    divText8: {
        height: "50px",
        width: "50px"
    },
    divText9: {
        height: "20px",
        width: "120px"
    },
    divText10: {
        height: "20px",
        width: "50px"
    },
    divText11: {
        height: "15px",
        width: "120px"
    },
    divText12: {
        height: "15px",
        width: "50px"
    },
    divText13: {
        height: "15px",
        width: "180px"
    },
    divText14: {
        height: "12px",
        width: "180px"
    },
    divText15: {
        height: "50px",
        width: "30px"
    },
    divImg1: {
        border: "1px solid #b7b7b7",
        height: "80px",
        width: "80px"
    },
    divImg2: {
        height: "60px",
        width: "95px"
    },
    divImg3: {
        border: "1px solid #b7b7b7",
        height: "45px",
        width: "95px"
    },
    divImg4: {
        height: "70px",
        width: "80px"
    },
    divImg5: {
        height: "80px",
        width: "145px",
        textAlign: "center",
    },
    headingText1: {
        // fontFamily: "Arial sans-serif",
        fontSize: 15,
        fontWeight: 'bold',
        textAlign: "center",
    },
    headingText2: {
        // fontFamily: "Arial sans-serif",
        fontSize: 13,
        fontWeight: 'bold',
        textAlign: "center",
    },
    text1: {
        fontSize: 8,
        fontWeight: "100",
        textAlign: "center",
    },
    text2: {
        // fontFamily: "Arial sans-serif",
        fontSize: 11,
        fontWeight: 'bold',
        textAlign: "center",
    },
    text3: {
        fontSize: 8,
        fontWeight: "100",
        textAlign: "left",
    },
    text4: {
        // fontFamily: "Arial sans-serif",
        fontSize: 11,
        fontWeight: 'bold',
        textAlign: "left",
    },
    imageProfile: {
        // maxHeight: "100%",
        // maxWidth: "100%",
        // height:"80px",
        // width:"80px",
        height:"100%",
        width:"100%",
        textAlign: "center"
    },
    image1: {
        height: "100%",
        width: "100%",
        textAlign: "center"
    },
    image2: {
        height: "120px",
        width: "140px",
        textAlign: "center"
    },
    divBlock1: {
        height: "110px",
        width: "180px",
    },
    divBlock2: {
        height: "25px",
        width: "180px",
    },
    divBlock3: {
        height: "20px",
        width: "180px",
    },
    divBlock4: {
        height: "50px",
        width: "180px",
    },
    horizontalBar: {
        borderBottom: '2px solid #b7b7b7',
        margin: '3px 0',
    },
    horizontalBar2: {
        borderBottom: '1px solid #b7b7b7',
        margin: '2px 0',
    },
});

export { styles };