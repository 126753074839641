import React, { useState, useEffect } from 'react'
import './css/completionInd.css'

const CompletionInd = (props) => {
    const [style, setStyle] = useState({})
    const [completionPct, setCompletionPct] = useState(props.completionPct)
    useEffect(() => {
        setCompletionPct(props.completionPct)

    }, [props])
    setTimeout(() => {
        const newStyle = {
            opacity: 1,
            width: `${completionPct.pct}%`
        }
        setStyle(newStyle);
    }, 200);

    return (
        <div>
            <h1 style={{ fontSize: 20 }}>Completion</h1>
            <div className="progress" style={{ display: 'inline-table' }}>
                <div className="progress-done" style={style}>
                </div>
            </div>
            <div style={{ display: 'inline-table', marginLeft: '15px' }}>
                {completionPct.completed && completionPct.total && <span style={{ fontSize: 20 }}>{completionPct.completed + '/' + completionPct.total}</span>}
            </div>
            <button className="button" style={{ marginLeft: "26.5%", marginRight: "10px" }}
                onClick={(e) => props.setFlgView(true)}>View</button>
            <button className="button" style={{ marginLeft: "0px" }}
                onClick={props.informAll}>Inform All</button>
        </div>
    )
}
export default CompletionInd;