import axios from "axios";
import lstModules from "common/json/lstModules.json";

let httpURL = "";
httpURL = lstModules['library'].url + ":" + lstModules['library'].port;
var httpURLCommon = lstModules['main'].url + ':' + lstModules['main'].port
var tokenId = localStorage.getItem('tokenid');
let queryString = require('querystring');

export const getBookList = (cbBookList, tag) => {
    var obj = { status: "Success", message: "" };
    axios({
        url: httpURL + "/sms/library/book/list",
        method: "POST",
        headers: { 'tokenid': tokenId },
        withCredentials: true,
        params: { "tags": tag },
    })
        .then((response) => {
            obj.status = response.data.SvcStatus;
            obj.message = response.data.SvcMsg;
            obj.lstBook = response.data.lstBook;
            cbBookList(obj);
        })
        .catch((e) => {
            obj.status = "Exception";
            obj.message = "error";
            cbBookList(obj);
        })
}


export const getBookDtls = (cbBookDtls, bookNo, copyNo) => {
    var obj = { status: "Success", message: "" };
    axios({
        url: httpURL + "/sms/library/book/dtls",
        method: "POST",
        headers: { 'tokenid': tokenId },
        withCredentials: true,
        data: queryString.stringify({ "bookNo": bookNo, "copyNo": copyNo }),
    })
        .then((response) => {
            obj.status = response.data.SvcStatus;
            obj.message = response.data.SvcMsg;
            obj.bookDtls = response.data.bookDtls;
            cbBookDtls(obj);
        })
        .catch((e) => {
            obj.status = "Exception";
            obj.message = "error";
            cbBookDtls(obj);
        })
}


export const getGenreList = (cbGenreList) => {
    var obj = { status: "Success", message: "" };
    axios({
        url: httpURL + "/sms/library/mst/genre/list",
        method: "POST",
        headers: { 'tokenid': tokenId },
        withCredentials: true,
    })
        .then((response) => {
            obj.status = response.data.SvcStatus;
            obj.message = response.data.SvcMsg;
            obj.lstGenre = response.data.lstGenre;
            cbGenreList(obj);
        })
        .catch((e) => {
            obj.status = "Exception";
            obj.message = "error";
            cbGenreList(obj);
        })
}

export const getMediaType = (cbMediaType) => {
    var obj = { status: "Success", message: "" };
    axios({
        url: httpURL + "/sms/library/mst/media/list",
        method: "POST",
        headers: { 'tokenid': tokenId },
        withCredentials: true,
    })
    .then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        obj.lstType = response.data.lstType;
        cbMediaType(obj);
    })
    .catch((e) => {
        obj.status = "Exception";
        obj.message = "error";
        cbMediaType(obj);
    })
}

export const getStatusList = (cbStatusList) => {
    // // console.log("getStatusList Service called");
    var obj = { status: "Success", message: "" };
    axios({
        url: httpURL + "/sms/library/mst/status/list",
        method: "POST",
        headers: { 'tokenid': tokenId },
        withCredentials: true,
    })
        .then((response) => {
            obj.status = response.data.SvcStatus;
            obj.message = response.data.SvcMsg;
            obj.lstStatus = response.data.lstStatus;
            cbStatusList(obj);
            // // console.log("response.data.lstStatus", response.data.lstStatus)
        })
        .catch((e) => {
            obj.status = "Exception";
            obj.message = "error";
            cbStatusList(obj);
        })
}

export const saveBookDtlsSvc = (cbSaveBookDtls,bookObj) => {
    // // console.log("got bookObj in saveBookDtlsSvc ",bookObj)
    var formData = new FormData();
    var obj = { status: "Success", message: "" };
    axios({
        url: httpURL + "/sms/library/book/save",
        method: "POST",
        headers: { 'tokenid': tokenId },
        withCredentials: true,
        params: { "bookObj": bookObj },
        // data: queryString.stringify({ "bookObj":bookObj }),
    })
        .then((response) => {
            obj.status = response.data.SvcStatus;
            obj.message = response.data.SvcMsg;
            cbSaveBookDtls(obj);
        })
        .catch((e) => {
            obj.status = "Exception";
            obj.message = "error";
            cbSaveBookDtls(obj);
        })
}

export const getClassList = (cbClassList) => {
    var obj = { status: "Success", message: "" };
    axios({
        url: httpURLCommon + '/sms/mst/cls/list',
        method: "POST",
        headers: { 'tokenid': tokenId },
        withCredentials: true,
    })
        .then((response) => {
            obj.status = response.data.SvcStatus;
            obj.message = response.data.SvcMsg;
            obj.lstClass = response.data.lstClass;
            cbClassList(obj);
        })
        .catch((e) => {
            obj.status = "Exception";
            obj.message = "error";
            cbClassList(obj);
        })
}

export const getSchoolList = (cbGetSchoolList, schoolId) => {
    var obj = { status: "Success", message: "" };
    axios({
        url: httpURLCommon + '/sms/mst/user/school/list',
        method: "POST",
        params: {},
        headers: { 'tokenid': tokenId },
        withCredentials: true,
    })
        .then((response) => {
            obj.status = response.data.SvcStatus;
            obj.message = response.data.SvcMsg;
            obj.lstSchool = response.data.lstSchool;
            cbGetSchoolList(obj);
        })
        .catch((e) => {
            obj.status = "Exception";
            obj.message = "error";
            cbGetSchoolList(obj);
        })
}

export const getCopyCount = (cbCopyCount, bookNo) => {
    var obj = { status: "Success", message: "" };
    axios({
        url: httpURL + '/sms/library/book/copy/count',
        method: "POST",
        headers: { 'tokenid': tokenId },
        withCredentials: true,
        params: { "bookNo": bookNo },
    })
        .then((response) => {
            obj.status = response.data.SvcStatus;
            obj.message = response.data.SvcMsg;
            obj.copyCount = response.data.copyCount;
            cbCopyCount(obj);
        })
        .catch((e) => {
            obj.status = "Exception";
            obj.message = "error";
            cbCopyCount(obj);
        })
}

export const saveCopySvc = (cbSaveCopy, bookNo, copyNo, createCopies) => {
    var obj = { status: "Success", message: "" };
    axios({
        url: httpURL + '/sms/library/book/copy/save',
        method: "POST",
        headers: { 'tokenid': tokenId },
        withCredentials: true,
        data: queryString.stringify({ "bookNo": bookNo, "copyNo": copyNo, "copyCount":createCopies }),
    })
        .then((response) => {
            obj.status = response.data.SvcStatus;
            obj.message = response.data.SvcMsg;
            cbSaveCopy(obj);
        })
        .catch((e) => {
            obj.status = "Exception";
            obj.message = "error";
            cbSaveCopy(obj);
        })
}


export const getIssueDetails = (cbIssueDtls) => {
    var obj = { status: "Success", message: "" };
    axios({
        url: httpURL + '/sms/library/mst/school/issue_period',
        method: "POST",
        headers: { 'tokenid': tokenId },
        withCredentials: true,
    })
        .then((response) => {
            obj.status = response.data.SvcStatus;
            obj.message = response.data.SvcMsg;
            obj.issueDtls = response.data.issueDtls;
            cbIssueDtls(obj);
        })
        .catch((e) => {
            obj.status = "Exception";
            obj.message = "error";
            cbIssueDtls(obj);
        })
}