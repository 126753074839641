import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';

export default class periodTypeForm extends Component {
    savePeriodType = () => {
        this.props.savePeriodType();
    }
    render() {
        let dataObj = this.props.periodTypeData;
        return (
            <div>
                <div className="col-xs-12 padding-remove timingFormWrap" >
                    <form id="periodTypeAddForm" className="form-horizontal formWrapper">
                        <div className="form-group valueWrap">
                            <label className="control-label col-sm-5" >Period Type<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                            <div className="col-sm-6 padding-remove">
                                <input autoComplete="off" id="period_type" type="text" className="form-control " placeholder="Enter Period Type" />
                            </div>
                        </div>
                        <div className="form-group valueWrap">
                            <label className="control-label col-sm-5" >Cover Syllabus</label>
                            <div className="col-sm-6 padding-remove">
                                <input autoComplete="off" id="canCoverSyllabus" type="checkbox" className="form-control " />
                            </div>
                        </div>
                        <div className="form-group valueWrap">
                            <label className="control-label col-sm-5" >Assign Teacher</label>
                            <div className="col-sm-6 padding-remove">
                                <input autoComplete="off" id="canAssignTeacher" type="checkbox" className="form-control " />
                            </div>
                        </div>
                        <div className="form-group" >
                            <div className="col-sm-12 text-center userButtonWrap" >
                                <button type="button" style={{ marginTop: 20 }} title="Save" onClick={this.savePeriodType} className="btn btn-info">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
