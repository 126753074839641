import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['students'].url + ':' + lstModules['students'].port;
const tokenid = localStorage.getItem('tokenid');
const schoolId = localStorage.getItem("schoolId");
var queryString = require("querystring");


export const getCommunicationDtlsSvc = (studentId, callback) => {
    // // console.log('getCommunicationDtls called, studentObj', studentObj);
    const responseObj = { status: "", message: "", error: "" };
    axios({
        url: httpURL + "/sms/student/commId",
        method: 'POST',
        data: queryString.stringify({
            schoolId: schoolId,
            studentId: parseInt(studentId),
        }),
        headers: { tokenid },
        withCredentials: true,
    })
        .then(response => {
            // // console.log("response in getCommunicationDtls, response", response);
            responseObj.status = response.data.SvcStatus;
            responseObj.message = response.data.SvcMsg;
            if (response.data.SvcStatus === "Success") {
                responseObj.commDtls = response.data.commDtls;
            }
            callback(responseObj);
        })
        .catch(error => {
            responseObj.status = "Exception";
            responseObj.error = error.message;
            callback(responseObj);
        });
};


export const saveCommunicationIdSvc = (studentId, comm, callback) => {
    // // console.log('saveCommunicationIdSvc called, studentObj', studentObj);
    const responseObj = { status: "", message: "", error: "" };
    axios({
        url: httpURL + "/sms/student/saveCommId",
        method: 'POST',
        params: {
            studentId: studentId,
            personId: comm,
        },
        headers: { tokenid },
        withCredentials: true,
    })
        .then(response => {
            // // console.log("response in saveCommunicationIdSvc, response", response);
            responseObj.status = response.data.SvcStatus;
            responseObj.message = response.data.SvcMsg;
            callback(responseObj);
        })
        .catch(error => {
            responseObj.status = "Exception";
            responseObj.error = error.message;
            callback(responseObj);
        });
};

