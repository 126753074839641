import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
const httpURL = lstModules['transport'].url + ':' + lstModules['transport'].port;
var tokenid = localStorage.getItem('tokenid');
function DriverTable(props){
    useEffect(()=>{
        setLoader('block')
          getLstDriver(cbGetLstDriver)
      },[])
    const [loader,setLoader]=useState('none')
    const [lstDriver,setLstDriver]=useState([])
    const [selIdx,setSelIdx]=useState()
    function handleSelRow(param,staffId){
        setSelIdx(param)
      }
      function handleEdit(param){
        var type=1;  
        props.getSelStaff(param,type)
      }
      function handleVehicle(param){
          var type=2;
          props.getSelStaff(param,type)
      }
    function getLstDriver(callback){
        axios(
            {
                url: httpURL +"/sms/tpt/driver/list",
                method: 'POST',
                params: {},
                headers: { 'tokenid': tokenid },
                withCredentials: true
            }
        ).then(
            (Response) => {
                var data = Response.data
    
                callback(data)
    
            }
        ).catch((error) => {
            console.error(error)
        });
    }
    function cbGetLstDriver(data){
        if(data.SvcStatus==='Success'){
            setLstDriver(data.lstDriver)
          }
          else{
            toast.error(data.SvcMsg)
          }
          setLoader('none')
    }
    const colgrp = (
        <colgroup>
          <col width="8%"></col>
          <col width="8%"></col>
          <col width="8%"></col>
          <col width="8%"></col>
        </colgroup>
      );
    return (<div style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
    <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
      <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
    </div>
    
          <table className="col-sm-8  col-sm-offset-2">
          {colgrp}
              <thead>
              <tr>
                  <th>Name</th>
                  <th>Contact No</th>
                  <th>E-Mail</th>
                  <th>Action</th>
              </tr>
              </thead>
              <tbody>
                  {
                      lstDriver.map((obj,idx)=>{
                          return (
                              <tr onClick={(e)=>handleSelRow(idx,obj.staffId)} className={selIdx === idx ? 'selected' : ''} key={idx}>
                                  <td>{obj.staff}</td>
                                  <td>{obj.contactNo}</td>
                                  <td>{obj.email}</td>
                                  <td align="center">
                                      <span className="glyphicon glyphicon-edit" onClick={(e)=>handleEdit(obj.staffId)}></span>
                                      <span className="fa fa-bus" onClick={(e)=>handleVehicle(obj.staffId)}></span>
                                  </td>
                              </tr>   
                          )
                      })
                  }
              </tbody>
          </table>
    
    </div>)
}
export default DriverTable;