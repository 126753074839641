import React, { useEffect, useState } from 'react';
import { Text, View, Image } from '@react-pdf/renderer';

import { styleDtls, fontStyles } from './styleAdmitCard';

const AdmitCardDetails = (props) => {
    const { studentName, cls, rollNo, classTeacherSign, studentImgUrl } = props;

    const [teacherSignUrl, setTeacherSignUrl] = useState("");
    const [studentImagePath, setStudentImagePath] = useState("");

    useEffect(() => {
        // // console.log("AdmitCardDetails component called");
        let tempTeacherSignUrl = "https://www.sms.cooledsys.com:8280/sms/student/docs/view?imgPath=" + classTeacherSign;
        // // console.log("tempTeacherSignUrl", tempTeacherSignUrl);
        setTeacherSignUrl(tempTeacherSignUrl);
        let tempStudentImgUrl = "https://www.sms.cooledsys.com:8280/sms/student/docs/view?imgPath=" + studentImgUrl;
        // // console.log("tempStudentImgUrl", tempStudentImgUrl);
        setStudentImagePath(tempStudentImgUrl);
    }, [studentName]);

    return (
        <View style={[styleDtls.dtls_section]}>
            <View style={[styleDtls.photo_section]}>
                <View style={[styleDtls.photo_container]}>
                    {
                        !!studentImagePath ?
                            <Image style={styleDtls.image_photo} src={studentImagePath} />
                            : null
                    }
                </View>
                <View style={[styleDtls.sign_container_student]}>
                    <View style={[styleDtls.sign_space_student]}>
                    </View>
                    <View style={[styleDtls.sign_label_student]}>
                        <Text style={fontStyles.textCenterSmallInter}>
                            Student
                        </Text>
                    </View>
                </View>
            </View>
            <View style={[styleDtls.details_section]}>
                <View style={[styleDtls.student_dtls_section]}>
                    <View style={[styleDtls.studentRow]}>
                        <View style={[styleDtls.rowLabel]}>
                            <Text style={fontStyles.textLeftNormalInter}>
                                Name
                            </Text>
                        </View>
                        <View style={[styleDtls.rowData]}>
                            <Text style={fontStyles.textLeftNormalInter}>
                                :    {studentName}
                            </Text>
                        </View>
                    </View>
                    <View style={[styleDtls.studentRow]}>
                        <View style={[styleDtls.rowLabel]}>
                            <Text style={fontStyles.textLeftNormalInter}>
                                Role No
                            </Text>
                        </View>
                        <View style={[styleDtls.rowData]}>
                            <Text style={fontStyles.textLeftNormalInter}>
                                :    {rollNo}
                            </Text>
                        </View>
                    </View>
                    <View style={[styleDtls.studentRow]}>
                        <View style={[styleDtls.rowLabel]}>
                            <Text style={fontStyles.textLeftNormalInter}>
                                Class
                            </Text>
                        </View>
                        <View style={[styleDtls.rowData]}>
                            <Text style={fontStyles.textLeftNormalInter}>
                                :    {cls}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={[styleDtls.sign_section]}>
                    <View style={[styleDtls.sign_container]}>
                        <View style={[styleDtls.sign_space]}>
                        </View>
                        <View style={[styleDtls.sign_label]}>
                            <Text style={fontStyles.textCenterSmallInter}>
                                Parent
                            </Text>
                        </View>
                    </View>
                    <View style={[styleDtls.sign_container]}>
                        <View style={[styleDtls.sign_space]}>
                            {
                                !!teacherSignUrl ?
                                    <Image style={styleDtls.image_sign} src={teacherSignUrl} />
                                    : null
                            }
                        </View>
                        <View style={[styleDtls.sign_label]}>
                            <Text style={fontStyles.textCenterSmallInter}>
                                Class Teacher
                            </Text>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    )
}

export default AdmitCardDetails;