import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
const httpURL = lstModules['payrollSetup'].url + ':' + lstModules['payrollSetup'].port;
var tokenid = localStorage.getItem('tokenid');
function GradeSlider(props){
  const [grade,setGrade]=useState(props.gradeObj.grade)
  const [gradeId,setGradeId]=useState(props.gradeObj.gradeId)
  const [minBasic,setMinBasic]=useState(props.gradeObj.minBasic)
  const [maxBasic,setMaxBasic]=useState(props.gradeObj.maxBasic)
  const [loader, setLoader] = useState("none");
  function handleGradeChange(val){
    setGrade(val)
  }
  useEffect(()=>{

    // // console.log(props.gradeObj)
    setGradeId(props.gradeObj.gradeId)
    setGrade(props.gradeObj.grade)
    setMaxBasic(props.gradeObj.maxBasic)
    setMinBasic(props.gradeObj.minBasic)
  },[])
  function handleMinBasicChange(val){
    setMinBasic(val)
  }
  function handleMaxBasicChange(val){
    setMaxBasic(val)
  }
  function saveResult(cbSaveGradeData){
    setLoader('block')
    var gradeObj={
        gradeId:gradeId,
        grade:grade,
        minBasic:minBasic,
        maxBasic:maxBasic
    }
    axios(
        {
            url: httpURL +"/sms/grade/save",
            method: 'POST',
            params: {grade: JSON.stringify(gradeObj) },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            cbSaveGradeData(data)
            

        }
    ).catch((error) => {
        console.error(error)
    });
  }
  function cbSaveGradeData(data){
      if(data.SvcStatus==='Success'){
          toast.success(data.SvcMsg)
          props.generateRandomNo()
      }
      else{
          toast.error(data.SvcMsg)
      }
      setLoader('none')
  }
    return(<form    
        
    className="form form-horizontal"
    style={{
    pointerEvents: loader === "none" ? "auto" : "none",
      height: "100%",
      width: "100%",
      overflowX: 'hidden',
      background: "#fff",
      border: "1px solid #ccc",
      borderRadius: 10,
    }}>
        <ToastContainer autoClose={3000} hideProgressBar />
                <div className="form-group col-md-12">
                    <label className="control-label col-md-5">Grade<span className="red-color">*</span></label>
                    <div className='col-sm-6 padding-remove'>
                        <input type='text' className="form-control" defaultValue={props.gradeObj.grade} onChange={(e) => handleGradeChange(e.target.value)} required></input>
                    </div>
                </div>
                <div className="form-group col-md-12">
                    <label className="control-label col-md-5">Min Basic<span className="red-color">*</span></label>
                    <div className='col-sm-6 padding-remove'>
                        <input type='text' className="form-control" defaultValue={props.gradeObj.minBasic} onChange={(e) => handleMinBasicChange(e.target.value)} required></input>
                    </div>
                </div>
                <div className="form-group col-md-12">
                    <label className="control-label col-md-5">Max Basic<span className="red-color">*</span></label>
                    <div className='col-sm-6 padding-remove'>
                        <input type='text' className="form-control" defaultValue={props.gradeObj.maxBasic} onChange={(e) => handleMaxBasicChange(e.target.value)} required></input>
                    </div>
                </div>
                <div className="form-group col-md-12">
                    <div className="col-md-offset-10 col-md-1 padding-remove">
                        <button type="button" className="btn btn-info pull-right" onClick={(e) => saveResult(cbSaveGradeData)}>Save</button>
                    </div>
                </div>

    </form>)
}
export default GradeSlider;
