import React, { useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import '../css/EnquiryCtrl.css'
import {
  assignSlotAndInformCandSvc,
  assignSlotSvc,
  getSlotListSvc,
  informCandSvc,
} from "./ScheduleTestSvc";
import SliderType from "./sliderType";

const SlotTable = (props) => {
  const [lstSlot, setLstSlot] = useState([]);
  const [lstTestSlot, setLstTestSlot] = useState([]);
  const [enqId, setEnqId] = useState(props.enquiryId);
  const [lstTestId, setLstTestId] = useState(props.lstTestId);
  const [slotId, setSlotId] = useState(0);

  function cbSlotListResponse(lstSlot) {
    setLstSlot(lstSlot);
  }
  useEffect(() => {
    if (props.flgSliderType === SliderType.SLIDER_SCHEDULE_TEST) {
      if (props.enquiryId) {
        setEnqId(props.enquiryId);
        if (props.clsId) {
          getSlotListSvc(cbSlotListResponse, props.enquiryId, props.clsId);
        }
      }
      setLstTestId(props.lstTestId);
    }

    // assignSlotSvc(cbAssignSlotResponse);
    // assignSlotAndInformCandSvc(cbAssignSlotAndInformResponse)
    // informCandSvc()

  }, [props.enquiryId, props.clsId, props.flgSliderType, props.lstTestId]);


  function cbAssignSlotAndInformResponse(data) {
    if (data.SvcStatus === "Success") {
      setLstTestSlot(data.lstTestSlot);
      toast.success(data.SvcMsg)
    } else {
      toast.error(data.SvcMsg)
    }
  }
  function assignSlotAndInformCand() {
    assignSlotAndInformCandSvc(cbAssignSlotAndInformResponse, enqId, lstTestId, slotId);
  }

  function cbAssignSlotResponse(data) {
    if (data.SvcStatus === "Success") {
      setLstTestSlot(data.lstSlot);
      toast.success(data.SvcMsg);
    } else {
      toast.error(data.SvcMsg)
    }
  }
  function assignSlot() {
    // // console.log("/TestId/" + lstTestId + "/EnqId/" + enqId + "/SlotId/" + slotId);
    assignSlotSvc(cbAssignSlotResponse, enqId, lstTestId, slotId);
  }

  function cbInformCandResp(data) {
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
    }
    else {
      toast.error(data.SvcMsg);
    }

  }

  function informCand() {
    informCandSvc(cbInformCandResp, enqId, lstTestId, slotId);
  }

  function selectSlot(param) {
    // // console.log("clicked on slotId " + param);
    setSlotId(param);
  }
  const colgrp = (
    <colgroup>
      <col width="6%"></col>
      <col width="16%"></col>
      <col width="8%"></col>
      <col width="14%"></col>
      <col width="16%"></col>
      <col width="6%"></col>
    </colgroup>
  );
  return (
    <div className="slotTable">
      <table className="tableLayout1">
        {colgrp}
        <thead>
          <tr>
            <th>Slot Id</th>
            <th>Date</th>
            <th>Day</th>
            <th>Time</th>
            <th>Class</th>
            <th>Alloted</th>
          </tr>
        </thead>
      </table>

      <div className="tablebodyWrapper" style={{ height: '35vh' }}>
        <table className="tableLayout">
          {colgrp}
          <tbody>
            {lstSlot.map((slot, idx) => {
              return (
                <tr key={'sl_' + idx} onClick={(e) => selectSlot(slot.slotId)} className={slot.slotId == slotId ? "selected" : ""}  >
                  <td>{slot.slotId}</td>
                  <td>{slot.slotDt}</td>
                  <td>{slot.day}</td>
                  <td>{slot.slotTm}</td>
                  <td>{slot.cls}</td>
                  <td>{slot.allotedCount}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="col-sm-12" style={{ border: '1px solid #ddd', padding: 2, marginTop: 2 }}>
        <div className="col-sm-12" style={{ marginTop: 5 }}>
          <button
            className="btn btn-primary pull-right"
            id="bAdd"
            type="button"
            onClick={(e) => assignSlotAndInformCand()}>
            Assign Slot And Inform Candidate
          </button>
        </div>
        <div className="col-sm-12" style={{ marginTop: 5 }}>
          <button
            id="bAdd"
            className="btn btn-primary pull-right"
            type="button"
            onClick={(e) => assignSlot()}
          >
            Assign Slot
          </button>
        </div>
        <div className="col-sm-12" style={{ marginTop: 5 }}>
          <button
            id="bAdd"
            className="btn btn-primary pull-right"
            type="button"
            onClick={(e) => informCand()}
          >
            Inform Candidate
          </button>
        </div>
      </div>
    </div>
  );
};
export default SlotTable;
