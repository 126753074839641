import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import '../css/register.css';
import 'common/css/master.css';
import axios from 'axios';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
var httpURL = "";
var queryString = require('querystring');
var lstStates = [];
var lstCity = [];

class register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      loader: 'none',
      opacity: 1,
      mailSection: 'block',
      registerSection: 'none',
      lstStates: [],
      lstCity: [],

    };
  }

  componentDidMount() {
    $('body').addClass('loginPage');
    $('#statesCmb').prepend($("<option> </option").val(0).html("States*"));
    $('#cityCmb').prepend($("<option> </option").val(0).html("City*"));
    lstStates = [{ statesId: 1, states: "Delhi" }, { statesId: 2, states: "Up" }, { statesId: 3, states: "Bihar" }];

    //  this.setState({ loader: 'block', opacity: 0.5 });
    //       axios.post(httpURL + '/sms/admin/stateList',
    //         queryString.stringify(), { withCredentials: true }
    //       ).then((response) => {
    //         if (response.data.SvcStatus === 'Success') {
    //           toast.success(response.data.SvcMsg);
    //          this.setState({
    //            lstStates : response.data.lstStates,
    //          });
    //          lstStates = this.state.lstStates;
    //          this.fillStatesCmb();
    //         }
    //         else {
    //           toast.error(response.data.SvcMsg);
    //         }
    //       }).catch((error) => {
    //         toast.error("Service failed " + error);
    //       });

    this.fillStatesCmb();
    lstCity = [{ cityId: 1, city: "New Delhi" }, { cityId: 2, city: "Noida" }, { cityId: 3, city: "Patna" }];
    this.fillCityCmb();
  }

  changeState = () => {
    var statesId = $('#statesCmb').val();
    axios.post(httpURL + '/sms/admin/cityList',
      queryString.stringify({ statesId: statesId }), { withCredentials: true }
    ).then((response) => {
      if (response.data.SvcStatus === 'Success') {
        toast.success(response.data.SvcMsg);
        this.setState({
          lstCity: response.data.lstCity,
        });
        lstCity = this.state.lstCity;
        this.fillCityCmb();
      }
      else {
        toast.error(response.data.SvcMsg);
      }
      this.setState({ loader: 'none', opacity: 1 });
    }).catch((error) => {
      toast.error("Service failed " + error);
    });
  }

  fillStatesCmb() {
    $('#statesCmb').empty();
    $('#statesCmb').prepend($("<option> </option>").val(0).html('States*'));
    for (var i = 0; i < lstStates.length; i++) {
      var statesId = lstStates[i].statesId;
      var states = lstStates[i].states;
      $('#statesCmb').append($("<option> </option>").val(statesId).html(states));
    }
  }
  fillCityCmb() {
    $('#cityCmb').empty();
    $('#cityCmb').prepend($("<option> </option>").val(0).html('City*'));
    for (var i = 0; i < lstCity.length; i++) {
      var cityId = lstCity[i].cityId;
      var city = lstCity[i].city;
      $('#cityCmb').append($("<option> </option>").val(cityId).html(city));
    }
  }


  verifyMail = () => {
    var usernameTrim = document.getElementById('email').value;
    var passwordTrim = document.getElementById('pwd').value;
    var username = $.trim(usernameTrim);
    var password = $.trim(passwordTrim);
    var cnfpassword = $('#cnfpwd').val().trim();
    var verfiyEmail = {};

    if (username === '') {
      toast.warning('Please Fill Email');
      $('#email').focus();
    }
    else if (password === '') {
      toast.warning('Please Fill Password');
      $('#pwd').focus();
    }
    else if (cnfpassword === '') {
      toast.warning('Please Fill Confirm Password');
      $('#cnfpwd').focus();
    }
    else if (password !== cnfpassword) {
      toast.warning(' Password Does Not Match');
      $('#cnfpwd').focus();
    }
    else {
      verfiyEmail = { 'email': username, 'password': password, 'cnfpassword': cnfpassword };
      // window.location.href = '/timeTable';
      this.setState({
        mailSection: 'none',
        registerSection: 'block',
      });
      this.setState({ loader: 'block', opacity: 0.5 });
      axios.post(httpURL + '/sms/admin/register',
        queryString.stringify({ 'admin': JSON.stringify(verfiyEmail) }), { withCredentials: true }
      ).then((response) => {
        if (response.data.SvcStatus === 'Success') {
          toast.success(response.data.SvcMsg);
        }
        else {
          toast.error(response.data.SvcMsg);
        }
        this.setState({ loader: 'none', opacity: 1 });
      }).catch((error) => {
        toast.error("Service failed " + error);
      });
    }
  }

  registerSchool = () => {
    var mobile = $('#mobile').val();
    var name = $('#name').val();
    var statesId = $('#statesCmb').val();
    var cityId = $('#cityCmb').val();
    var address = $('#address').val();
    var pin = $('#pin').val();
    var studentsNumber = $('#studentsNumber').val();
    var registerSchool = {};


    if (mobile === '') {
      toast.warning('Please Fill Mobile Number');
      $('#mobile').focus();
      return false;
    }
    if (name === '') {
      toast.warning('Please Fill School Name');
      $('#name').focus();
      return false;
    }
    if (statesId == 0) {
      toast.warning('Please Select valid State');
      $('#statesCmb').focus();
      return false;
    }
    if (cityId == 0) {
      toast.warning('Please Select valid City');
      $('#cityCmb').focus();
      return false;
    }
    if (address === '') {
      toast.warning('Please Fill School Address');
      $('#address').focus();
      return false;
    }
    if (pin === '') {
      toast.warning('Please Fill Pin Code');
      $('#pin').focus();
      return false;
    }


    registerSchool = {
      'mobile': mobile, 'name': name, 'statesId': statesId, 'cityId': cityId,
      'address': address, 'pin': pin, 'studentsNumber': studentsNumber
    };
    this.setState({ loader: 'block', opacity: 0.5 });
    axios.post(httpURL + '/sms/admin/register',
      queryString.stringify({ 'register': JSON.stringify(registerSchool) }), { withCredentials: true }
    ).then((response) => {
      if (response.data.SvcStatus === 'Success') {
        toast.success(response.data.SvcMsg);
      }
      else {
        toast.error(response.data.SvcMsg);
      }
      this.setState({ loader: 'none', opacity: 1 });
    }).catch((error) => {
      toast.error("Service failed " + error);
    });


  }

  enterRegister = (e) => {
    if (e.key === 'Enter') {
      this.verifyMail();
    }
  }



  render() {
    httpURL = lstModules['registration'].url + ":" + lstModules['registration'].port;
    return (
      <div className="wrapper">
        <ToastContainer autoClose={3000} hideProgressBar />
        <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: this.state.loader }}>
          <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
        </div>
        <div className="formContent">
          <div className="reg-form-wrapper" style={{ opacity: this.state.opacity }}>
            <h2 className="registerHeader">Register School</h2>
            <form id="loginForm" style={{ display: this.state.mailSection }}>
              <div className="form-group input-group inputWrap" >
                <span className="has-float-label">
                  <input type="email" autoComplete="off" className="form-control" id="email" placeholder="Email" required />
                  <label htmlFor="email">Email<span style={{ color: 'red' }}>*</span></label>
                </span>
              </div>
              <div className="form-group input-group inputWrap">
                <span className="has-float-label">
                  <input type="password" className="form-control" id="pwd" placeholder="Password" onKeyPress={this.enterRegister} required />
                  <label htmlFor="pwd">Password<span style={{ color: 'red' }}>*</span></label>
                </span>
              </div>
              <div className="form-group input-group inputWrap">
                <span className="has-float-label">
                  <input type="password" className="form-control" id="cnfpwd" placeholder="Confirm Password" onKeyPress={this.enterRegister} required />
                  <label htmlFor="cnfpwd">Confirm Password <span style={{ color: 'red' }}>*</span></label>
                </span>
              </div>
              <div className="text-center loginButtons">
                <button type="button" title="Continue" className="btn btn-info text-center" onClick={this.verifyMail} id="verifyMail">
                  Continue <span className="glyphicon glyphicon-arrow-right arrowShow" aria-hidden="true"></span>
                </button>
              </div>
            </form>
            <form id="registerForm" style={{ display: this.state.registerSection }}>
              <div className="form-group input-group inputWrap" >
                <span className="has-float-label">
                  <input type="number" autoComplete="off" className="form-control" id="mobile" placeholder="Mobile Number" required />
                  <label htmlFor="mobile">Mobile Number<span style={{ color: 'red' }}>*</span></label>
                </span>
              </div>
              <div className="form-group input-group inputWrap">
                <span className="has-float-label">
                  <input type="text" autoComplete="off" className="form-control" id="name" placeholder="School Name" required />
                  <label htmlFor="name">School Name<span style={{ color: 'red' }}>*</span></label>
                </span>
              </div>
              <div className="selectWrap">
                <div className="form-group input-group inputWrap">
                  <select className="form-control states" id="statesCmb" onChange={this.changeState} />
                </div>
                <div className="form-group input-group inputWrap">
                  <select className="form-control city" id="cityCmb" onChange={this.changeCity} />
                </div>
              </div>
              <div className="form-group input-group inputWrap">
                <span className="has-float-label">
                  <textarea type="text" autoComplete="off" className="form-control" id="address" placeholder="Address" required />
                  <label htmlFor="address">Address<span style={{ color: 'red' }}>*</span></label>
                </span>
              </div>
              <div className="form-group input-group inputWrap" >
                <span className="has-float-label">
                  <input type="number" autoComplete="off" className="form-control" id="pin" placeholder="Pin Code" required />
                  <label htmlFor="pin">Pin Code <span style={{ color: 'red' }}>*</span></label>
                </span>
              </div>
              <div className="form-group input-group inputWrap" >
                <span className="has-float-label">
                  <input type="number" autoComplete="off" className="form-control" id="studentsNumber" placeholder="Number of Students" required />
                  <label htmlFor="studentsNumber">Number of Studets</label>
                </span>
              </div>
              <div className="text-center loginButtons">
                <button type="button" title="Continue" className="btn btn-info text-center" onClick={this.registerSchool} id="registerSchool">
                  Continue <span className="glyphicon glyphicon-arrow-right arrowShow" aria-hidden="true"></span>
                </button>
              </div>
            </form>
            <div className="copyright">
              <a href="https://kmsgtech.com/" target="blank">Powered By KMSG Technologies</a>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
export default register;
