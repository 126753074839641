import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { getDashboardListSvc } from "./DashboardTableSvc";

let key = ''
const DashboardTable = (props) => {


    const [loader, setLoader] = useState("none");
    const [sectionId, setSectionId] = useState(props.selSectionId);
    const [foundLst, setFoundLst] = useState([])

    const [lstDashboard, setLstDashboard] = useState([])

    useEffect(() => {
        getDashboardListSvc(props.examId, cbLstDashboardResponse)
    }, [props.examId])

    useEffect(() => {
        if (props.selStatusId === 1) { key = 'flgSchedule' }
        if (props.selStatusId === 2) { key = 'flgTeacher' }
        if (props.selStatusId === 3) { key = 'flgDatesheet' }
        if (props.selStatusId === 4) { key = 'flgAllocation' }
        if (props.selStatusId === 5) { key = 'flgEvaluation' }
        if (props.selStatusId === 6) { key = 'flgInclassVerification' }
        if (props.selStatusId === 7) { key = 'flgFreeze' }
        if (props.selStatusId === 8) { key = 'flgPublish' }
        if (props.selStatusId === 9) { key = 'flgPTM' }
        // // console.log(props.selStatusId)
        // // console.log(key)
        if (lstDashboard)
            filterByStatus(props.selStatusId, key, lstDashboard)
    }, [props.selStatusId])

    function cbLstDashboardResponse(obj) {
        // // console.log(obj)
        if (obj.status === "Success") {
            setLstDashboard(obj.lstClsSection)
            setFoundLst(obj.lstClsSection)
        } else if (obj.status === 'Failure') {

            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {

            toast.error("Service failed " + obj.message);
        }
    }

    const filterByStatus = (pStatusId, pKey, pFoundLst) => {
        if (pStatusId !== '') {
            var results = []
            for (var i = 0; i < pFoundLst.length; i++) {

                if (!!pFoundLst[i][pKey]) {
                    results.push(pFoundLst[i])
                }
            }

            setFoundLst(results);
        } else {
            setFoundLst(lstDashboard);
            // If the text field is empty, show all users
        }

    };

    const colgrp = <colgroup>
        <col width="4%"></col>
        <col width="5%"></col>
        <col width="9%"></col>
        <col width="9%"></col>
        <col width="9%"></col>
        <col width="9%"></col>
        <col width="9%"></col>
        <col width="9%"></col>
        <col width="9%"></col>
        <col width="9%"></col>
        <col width="9%"></col>

    </colgroup>

    return (
        <div style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
            <table className='tableLayout1'>
                {colgrp}
                <thead>
                    <tr>
                        <th>Class</th>
                        <th>Section</th>
                        <th>Schedule Completed</th>
                        <th>Teacher Assigned</th>
                        <th>Datesheet Published</th>
                        <th>Allocation Done</th>
                        <th>Evaluation Done</th>
                        <th>In-class Verification</th>
                        <th>Freeze</th>
                        <th>Published</th>
                        <th>PTM</th>
                    </tr>
                </thead>
            </table>
            <div className='tablebodyWrapper'>
                <table className='tableLayout'>
                    {colgrp}
                    <tbody>
                        {lstDashboard && lstDashboard.length > 0 && lstDashboard.map((obj, idx) => {
                            return <tr key={idx} id={obj.sectionId}
                                onClick={(e) => props.setSectionId(obj.sectionId)} className={obj.sectionId === props.sectionId ? "selected" : ""}
                            >
                                <td className="no-display">{obj.sectionId}</td>
                                <td className="text-center">{obj.cls}</td>
                                <td className="text-center">{obj.section}</td>
                                <td className="text-center">{
                                    obj.flgSchedule
                                        ? <span className="glyphicon glyphicon-ok" style={{ color: 'green' }}></span>
                                        : <span className="glyphicon glyphicon-remove" style={{ color: '#ffc107' }}>
                                        </span>}</td>
                                <td className="text-center">{obj.flgTeacher
                                    ? <span className="glyphicon glyphicon-ok" style={{ color: 'green' }}></span>
                                    : <span className="glyphicon glyphicon-remove" style={{ color: '#ffc107' }}>
                                    </span>}</td>
                                <td className="text-center">{obj.flgDatesheet
                                    ? <span className="glyphicon glyphicon-ok" style={{ color: 'green' }}></span>
                                    : <span className="glyphicon glyphicon-remove" style={{ color: '#ffc107' }}>
                                    </span>}</td>
                                <td className="text-center">{obj.flgAllocation
                                    ? <span className="glyphicon glyphicon-ok" style={{ color: 'green' }}></span>
                                    : <span className="glyphicon glyphicon-remove" style={{ color: '#ffc107' }}>
                                    </span>}</td>
                                <td className="text-center">{obj.flgEvaluation
                                    ? <span className="glyphicon glyphicon-ok" style={{ color: 'green' }}></span>
                                    : <span className="glyphicon glyphicon-remove" style={{ color: '#ffc107' }}>
                                    </span>}</td>
                                <td className="text-center">{obj.flgInclassVerification
                                    ? <span className="glyphicon glyphicon-ok" style={{ color: 'green' }}></span>
                                    : <span className="glyphicon glyphicon-remove" style={{ color: '#ffc107' }}>
                                    </span>}</td>
                                <td className="text-center">{obj.flgFreeze
                                    ? <span className="glyphicon glyphicon-ok" style={{ color: 'green' }}></span>
                                    : <span className="glyphicon glyphicon-remove" style={{ color: '#ffc107' }}>
                                    </span>}</td>
                                <td className="text-center">{obj.flgPublish
                                    ? <span className="glyphicon glyphicon-ok" style={{ color: 'green' }}></span>
                                    : <span className="glyphicon glyphicon-remove" style={{ color: '#ffc107' }}>
                                    </span>}</td>
                                <td className="text-center">{obj.flgPTM
                                    ? <span className="glyphicon glyphicon-ok" style={{ color: 'green' }}></span>
                                    : <span className="glyphicon glyphicon-remove" style={{ color: '#ffc107' }}>
                                    </span>}</td>

                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default DashboardTable