import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['performance'].url + ':' + lstModules['performance'].port;

var tokenid = localStorage.getItem("tokenid");

function getLstDateSvc(pExamId, callback) {
    var status = 'Success'
    var obj = { lstDates: [], status: status };
    const formData = new FormData();
    formData.append("examId", pExamId);
    axios({
        url: httpURL + '/sms/performance/exam/schedule/dates_list',
        method: "POST",
        params: { examId: pExamId },
        // data:formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === 'Success') {
            obj.lstDates = response.data.lstDates;
        }
        callback(obj)
    }).catch((error) => {
        console.error(error);
    });
}
function getLstSectionSvc(callback) {
    var status = 'Success'
    var obj = { lstSection: [], status: status };

    axios({
        url: httpURL + '/sms/performance/mst/section/list',
        method: "POST",
        params: {},
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === 'Success') {
            obj.lstSection = response.data.lstSection;
        }
        callback(obj)
    }).catch((error) => {
        console.error(error);
    });
}
export { getLstDateSvc, getLstSectionSvc }