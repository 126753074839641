import axios from 'axios'
import lstModules from 'common/json/lstModules.json'

const httpURL = lstModules['main'].url + ':' + lstModules['main'].port
var tokenid = localStorage.getItem('tokenid')
var screenId = 8

function getAddressSvc (callback) {
  var status = 'Success'
  var obj = { status: status }

  axios({
    url: httpURL + '/sms/school/profile/addr/dtls',
    method: 'POST',
    params: { screenId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      if (response.data.SvcStatus === 'Success') {
        obj.addr = response.data.addr
        obj.message = response.data.SvcMsg
        obj.status = 'Success'
        // // console.log(obj)
        callback(obj)
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      callback(obj)
    })
}

function getIDCardDtlsSvc (callback) {
  var status = 'Success'
  var obj = { status: status }

  axios({
    url: httpURL + '/sms/school/profile/id_card/dtls',
    method: 'POST',
    params: { screenId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      if (response.data.SvcStatus === 'Success') {
        obj.idCard = response.data.idCard
        obj.message = response.data.SvcMsg
        obj.status = 'Success'
        // // console.log(obj)
        callback(obj)
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      callback(obj)
    })
}

function getLstState (callback) {
  var status = 'Success'
  var obj = { status: status }

  axios({
    url: httpURL + '/sms/mst/state/list',
    method: 'POST',
    params: { screenId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      if (response.data.SvcStatus === 'Success') {
        obj.lstState = response.data.lstState
        obj.message = response.data.SvcMsg
        obj.status = 'Success'
        // // console.log(obj)
        callback(obj)
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      callback(obj)
    })
}
function getDocListSvc (callback) {
  var status = 'Success'
  var obj = { status: status }

  axios({
    url: httpURL + '/sms/cms/doc/list',
    method: 'POST',
    params: { screenId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      if (response.data.SvcStatus === 'Success') {
        obj.lstDoc = response.data.lstDoc
        obj.message = response.data.SvcMsg
        obj.status = 'Success'
        // // console.log(obj)
        callback(obj)
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      callback(obj)
    })
}

function getLstCity (stateId, callback, cityId) {
  var status = 'Success'
  var obj = { status: status }

  axios({
    url: httpURL + '/sms/mst/city/list',
    method: 'POST',
    params: { stateId: stateId, screenId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      if (response.data.SvcStatus === 'Success') {
        obj.lstCity = response.data.lstCity
        obj.message = response.data.SvcMsg
        obj.status = 'Success'
        // // console.log(obj)
        callback(obj, cityId)
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      callback(obj)
    })
}
function getLstLocality (pcityId, callBack, pLocalityId) {
  var status = 'Success'
  var obj = { status: status }

  axios({
    url: httpURL + '/sms/mst/locality/list',
    method: 'POST',
    params: { cityId: pcityId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      obj.message = response.data.SvcMsg
      obj.status = response.data.SvcStatus
      if (response.data.SvcStatus === 'Success') {
        obj.lstLocality = response.data.lstLocality
        // // console.log(obj)
      }
      callBack(obj, pLocalityId)
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      callBack(obj)
    })
}

function getGeneralInfoSvc (callback) {
  var status = 'Success'
  var obj = { status: status }

  axios({
    url: httpURL + '/sms/cms/general_info',
    method: 'POST',
    params: { screenId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      if (response.data.SvcStatus === 'Success') {
        obj.genInfo = response.data.genInfo
        obj.message = response.data.SvcMsg
        obj.status = 'Success'
        // // console.log(obj)
        callback(obj)
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      callback(obj)
    })
}

function getPdfSvc (callback) {
  var xhr = new XMLHttpRequest()
  xhr.open('GET', httpURL + '/sms/school/profile/logo/view', true)
  xhr.setRequestHeader('tokenid', tokenid)

  xhr.responseType = 'arraybuffer'

  xhr.onload = function (e) {
    var arrayBufferView = new Uint8Array(this.response)
    var blob = new Blob([arrayBufferView], { type: 'image/jpeg' })
    var urlCreator = window.URL || window.webkitURL
    var imageUrl = urlCreator.createObjectURL(blob)
    callback(imageUrl)
  }
  xhr.send()
}
function getFeeBookDtlsSvc (callback) {
  var status = 'Success'
  var obj = { status: status }

  axios({
    url: httpURL + '/sms/school/profile/feebook/dtls',
    method: 'POST',
    params: { screenId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      obj.message = response.data.SvcMsg
      obj.status = response.data.SvcStatus

      if (response.data.SvcStatus === 'Success') {
        obj.feebookObj = response.data.feebookObj
        // // console.log(obj)
      }
      callback(obj)
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      callback(obj)
    })
}

function saveImgLogoSvc (file, callBack) {
  var formData = new FormData()
  formData.append('logo', file)

  axios({
    url: httpURL + '/sms/school/profile/logo/save',
    method: 'POST',
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      callBack(response.data)
    })
    .catch(e => {
      console.error('Service failed ' + e)
    })
}
function saveDocSvc (formData, cb) {
  var obj = { status: 'Success', message: '' }
  axios({
    url: httpURL + '/sms/cms/doc/save',
    method: 'POST',
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // // console.log(Response.data)
      var data = Response.data
      obj.status = data.SvcStatus
      obj.message = data.SvcMsg
      cb(obj)
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      cb(obj)
    })
}
function saveGeneralInfoSvc (genInfo, cb) {
  var obj = { status: 'Success', message: '' }
  axios({
    url: httpURL + '/sms/cms/general_info/save',
    method: 'POST',
    params: { genInfo: JSON.stringify(genInfo) },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // // console.log(Response.data)
      var data = Response.data
      obj.status = data.SvcStatus
      obj.message = data.SvcMsg
      cb(obj)
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      cb(obj)
    })
}

function deleteDocSvc (pDocId, callBack) {
  // // console.log('pDocId = ' + pDocId)
  axios({
    url: httpURL + '/sms/cms/doc/delete',
    method: 'POST',
    params: { docId: pDocId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      callBack(response.data)
    })
    .catch(e => {
      console.error('Service failed ' + e)
    })
}

export {
  getLstState,
  getLstCity,
  getLstLocality,
  getDocListSvc,
  getGeneralInfoSvc,
  getPdfSvc,
  getFeeBookDtlsSvc,
  saveDocSvc,
  saveGeneralInfoSvc,
  deleteDocSvc
}
