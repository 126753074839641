import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import { getAttendanceSvc } from './YearlyAttendanceSvc';


const YearlyAttendanceTable = (props) => {
    const [loader, setLoader] = useState("none");
    const [lstAttendance, setLstAttendance] = useState([])
    const [clsSecKeyword, setClsSecKeyword] = useState('')
    const [lstFoundAttendance, setLstFoundAttendance] = useState([])

    useEffect(() => {
        setLoader('block')
        getAttendanceSvc(cbAttendanceResponse);

    }, [])

    function cbAttendanceResponse(data) {
        setLoader('none')
        if (data.SvcStatus === "Success") {
            setLstAttendance(data.lstAttendance)
            setLstFoundAttendance(data.lstAttendance)
        } else {
            toast.error(data.SvcMsg)
        }
    }

    const filterByClsSection = async (e) => {
        // // console.log(e.key)
        if (e.target.value == '') {
            setLstFoundAttendance(lstAttendance)
        }

        const keyword = e.target.value.toLowerCase();
        await setClsSecKeyword(keyword);
        filterFn()

    };

    async function filterFn() {

        var tmpLst = []

        for (var i = 0; i < lstAttendance.length; i++) {
            var obj = lstAttendance[i]
            var clsSec = obj.clsSection.toLowerCase();
            // // console.log(clsSecKeyword + "::" + clsSec + "::" + clsSec.includes(clsSecKeyword))
            if (clsSecKeyword !== '' && !clsSec.includes(clsSecKeyword)) {
                continue;
            }
            tmpLst.push(obj)

        }
        await setLstFoundAttendance(tmpLst)
    }


    const colgrp = <colgroup>
        <col width="10%"></col>
        {lstAttendance.length > 0 && lstAttendance[0].lstMonth.map((subObj, subIdx) => {
            return <col key={'c_' + subIdx} width={(90 / lstAttendance[0].lstMonth.length) + "%"}></col>
        })}
    </colgroup>

    return (

        <div style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
            <div style={{ position: 'absolute', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <table className='tableLayout1'>
                {colgrp}
                <thead>
                    <tr>
                        <th><input className="form-control inp-search" placeholder="Class Section" onChange={(e) => filterByClsSection(e)} onKeyUp={(e) => filterByClsSection(e)} /></th>
                        {lstAttendance.length > 0 && lstAttendance[0].lstMonth.map((subobj, subIdx) => {
                            return <th key={'m_' + subIdx}>{subobj.month}</th>
                        })}
                    </tr>
                </thead>
            </table>
            <div style={{ height: '80vh', overflowY: 'scroll' }} >
                <table className='tableLayout'>
                    {colgrp}
                    <tbody>
                        {lstFoundAttendance.length > 0 && lstFoundAttendance.map((obj, idx) => {
                            var min = 0, minId = 0;
                            var max = 0, maxId = 0;

                            var iMonth = obj.lstMonth[0];
                            if (iMonth.presentPct && min > iMonth.presentPct) {
                                min = iMonth.presentPct;
                                minId = iMonth.monthId
                            }
                            if (iMonth.presentPct && max > iMonth.presentPct) {
                                max = iMonth.presentPct;
                                maxId = iMonth.monthId
                            }
                            return <tr key={idx} id={obj.clsSectionId}
                                onClick={(e) => props.setClsSectionId(obj.clsSectionId)} className={obj.clsSectionId === props.clsSectionId ? "selected" : ""}
                            >
                                <td className="no-display">{obj.clsSectionId}</td>
                                <td>{obj.clsSection}</td>

                                {obj.lstMonth.map((subobj, subIdx, maxId, minId) => {

                                    var minCond = minId == subobj.monthId;
                                    var maxCond = maxId == subobj.monthId;

                                    return <td style={{ background: minCond ? 'red' : maxCond ? 'blue' : 'transparent', color: minCond || maxCond ? '#fff' : '' }}
                                        key={'mth_' + subobj.monthId + '_k_' + subIdx}>
                                        {subobj.presentPct === 0 ? '-' : subobj.presentPct + '%'}
                                    </td>
                                })}
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div >
    )
}

export default YearlyAttendanceTable