import React, { useEffect, useState } from 'react';
import 'sms/tt/disruption/css/duty.css'

const DutyCell = (props) => {
    const [cellDtls, setCellDtls] = useState([props.cellDtls]);
    const [tchId, setTchId] = useState(props.tchId)
    const [roleId, setRoleId] = useState(props.roleId)
    const [secId, setSecId] = useState(cellDtls.secId)
    const [periodId, setPeriodId] = useState(cellDtls.periodId)
    const [cellType, setCellType] = useState(cellDtls.cellType)
    const [cellId, setCellId] = useState([props.cellId]);

    useEffect(() => {
        setCellDtls(props.cellDtls)
        setTchId(props.tchId)
        setRoleId(props.roleId)
        setCellId(props.cellId)
    }, [props])


    return <>
        <div className="clsSec" >
            <div celltype={cellDtls.cellType} secid={cellDtls.sectionId} periodid={cellDtls.periodId} tchid={props.tchId} className="cls">
                <span>{cellDtls.cellType === 10 ? cellDtls.cls + '-' : ''}</span>
                <span>{cellDtls.cellType === 10 ? cellDtls.section : ''}</span>
            </div>
            <div className="sub">{cellDtls.cellType === 10 ? cellDtls.sub : ''}</div>
        </div>
        <div className="subs">
            <span className="spanSubs">{cellDtls.cellType === 10 ? cellDtls.subsTch : ''}</span>
            <div onClick={(e) => props.removeTchDuty(e, cellDtls.subsId, cellDtls.subsTchId,cellDtls.periodId, cellDtls.clsId, cellDtls.sectionId, '')}
                className={cellDtls.subsTchId !== 0 && (parseInt(props.selPeriodId) === cellDtls.periodId && parseInt(props.selTchId) === props.tchId)
                    ? "spanRemove" : "spanRemove hide"}
            >
                <span className="glyphicon glyphicon-remove cross-icon"></span>
            </div>
        </div>
    </>
}
export default DutyCell;