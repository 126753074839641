import React, { useEffect } from "react";


const AcademicYearTable = (props) => {
    const { lstAcademicYear, setAcademicYearForm, deleteAcademicYear } = props;

    return (
        <>
            <table className="tableLayout1" id='customTable'>
                    <thead>
                        <tr>
                            <th width="100" className="text-left">Name</th>
                            <th width="100">From</th>
                            <th width="100">To</th>
                            <th width="100" className="text-left">Level</th>
                            {/* <th width="100">Setup Done</th>
                            <th width="100">Default</th>
                            <th width="100">Locked</th> */}
                            <th width="100">Action</th>
                        </tr>
                    </thead>
                </table>
                <div className="tableDiv">
                    <table id="mainTable" className="tableLayout">
                        <tbody id="tableBody">
                            {lstAcademicYear.map((academicYear) => (
                                <tr key={academicYear.academicYearId} id={academicYear.academicYearId} >
                                    <td width="100" className="no-display">{academicYear.academicYearId}</td>
                                    <td width="100" className="text-left">{academicYear.academicYear}</td>
                                    <td width="100" className="text-center">{academicYear.fromDt}</td>
                                    <td width="100" className="text-center">{academicYear.toDt}</td>
                                    <td width="100" className="text-left">{academicYear.applyLevel === 10
                                        ? 'School' : "Wing"}</td>
                                    
                                    <td width="100" className="text-center">
                                        <span onClick={() => setAcademicYearForm(academicYear.academicYearId)}
                                            className="glyphicon glyphicon-edit"></span>
                                        <span
                                            onClick={() => deleteAcademicYear(academicYear.academicYearId)}
                                            className="glyphicon glyphicon-trash" ></span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
        </>
    )
}

export default AcademicYearTable;