import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import $ from 'jquery';
import 'jquery-ui';
import 'common/css/master.css';
import axios from 'axios';
import Header from 'common/js/header/header.js';
import { toast, ToastContainer } from 'react-toastify';


let httpURL = '';
let queryString = require('querystring');
let lstRegsTest = [];
let lstTeacher = [];
let lstWings = [];
let lstWingClass = [];
let evaluation = [];
let lstTeacherSelect = [];
let tokenid = localStorage.getItem('tokenid');
var screenId = 19;


class admissionTests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: 'none',
            opacity: 1,
            flg: true,
            dupFlg: false,
            tokenid: tokenid,
        }

    }

    componentDidMount = () => {


        this.getAdmissionTestsList();

        $('#wingsCmb').prepend($("<option> </option>").val(0).html('All Wings'));
        $('#classTypeCmb').prepend($("<option> </option>").val(0).html('All Class'));

        this.setState({ loader: 'block', opacity: 0.5 });
        axios({
            url: httpURL + '/sms/teacher/list',
            method: "POST",
            params: { screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstTeacher = response.data.lstTeacher;
                this.setTeacherTable();
            }
            else {
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);

                }
            }
        }).catch((error) => {
            toast.error("Service failed " + error);
        });

        axios({
            url: httpURL + '/sms/wings/list',
            method: "POST",
            params: { screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstWings = response.data.lstWings;
                this.fillWingsCmb();
            }
            else {
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);

                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error("Service failed " + error);
        });

    }

    getAdmissionTestsList = () => {
        axios({
            url: httpURL + '/sms/mst/regs/test/list',
            method: "POST",
            params: { screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstRegsTest = response.data.lstRegsTest;
                this.setAdmissionTestTable();
            }
            else {
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);

                }
            }
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }

    fillWingsCmb() {
        $('#wingsCmb').empty();
        $('#wingsCmb').prepend($("<option> </option>").val(0).html('All Wings'));
        for (let i = 0; i < lstWings.length; i++) {
            let wingId = lstWings[i].wingId;
            let wing = lstWings[i].wing;
            // // console.log(wingId + wing);
            $('#wingsCmb').append($("<option> </option>").val(wingId).html(wing));
        }
    }

    changeWing = async () => {
        let wingId = $('#wingsCmb').find('option:selected').val();
        if (parseInt(wingId) === 0) {
            lstWingClass = [];
            this.fillClassCmb();
        }
        else {
            this.setState({ loader: 'block', opacity: 0.5 });
            await axios({
                url: httpURL + '/sms/wings/cls/list',
                method: "POST",
                params: { wingId, screenId },
                headers: { 'tokenid': this.state.tokenid },
                withCredentials: true,
            }).then((response) => {
                if (response.data.SvcStatus === 'Success') {
                    lstWingClass = response.data.lstWingClass;
                    this.fillClassCmb();
                }
                else {
                    if (response.data.SvcMsg === 'You are not logged in') {
                        setTimeout(function () { window.location.href = ('/') }, 2000);
                        return false;
                    }
                    else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                        setTimeout(function () { window.location.href = ('/') }, 2000);
                        return false;
                    } else {
                        toast.error(response.data.SvcMsg);

                    }
                } this.setState({ loader: 'none', opacity: 1 });
            }).catch((error) => {
                this.setState({ loader: 'none', opacity: 1 });
                toast.error("Service failed " + error);
            });

        }
    }

    fillClassCmb() {
        $('#classTypeCmb').empty();
        $('#classTypeCmb').prepend($("<option> </option>").val(0).html('All Class'));
        for (let i = 0; i < lstWingClass.length; i++) {
            let classId = lstWingClass[i].classId;
            let className = lstWingClass[i].className;
            $('#classTypeCmb').append($("<option> </option>").val(classId).html(className));
        }
    }

    setAdmissionTestTable = () => {
        $('#admissionTestsTableBody tr').remove();
        let table = document.getElementById('admissionTestsTable');
        let tbody = document.getElementById('admissionTestsTableBody');

        for (let i = 0; i < lstRegsTest.length; i++) {
            let regsTestId = lstRegsTest[i].regsTestId;
            let wing = lstRegsTest[i].wing;
            let className = lstRegsTest[i].className;
            let testName = lstRegsTest[i].testName;
            let mandatory = lstRegsTest[i].mandatory === true ? "Yes" : 'No';

            let duration = lstRegsTest[i].duration;


            let row = document.createElement('tr');
            row.id = regsTestId;

            row.onclick = (e) => {
                $(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');
                this.setAdmissionTestForm($(e.target).closest('tr').attr('id'));
                this.setState({
                    flg: false
                })
            }

            this.addTD(row, regsTestId, 'no-display');
            this.addTD(row, wing, 'text-center');
            this.addTD(row, className, 'text-center');
            this.addTD(row, testName, 'text-center');
            this.addTD(row, mandatory, 'text-center');
            this.addTD(row, duration, 'text-right');

            tbody.append(row);
        }
        table.append(tbody);
    }

    setAdmissionTestForm = async (regsTestId) => {
        let wingData = this.getwingData(regsTestId);
        // // console.log(wingData);
        $('#wingsCmb').val(wingData.wingId);
        await this.changeWing();
        $('#classTypeCmb').val(wingData.classId);
        $('#testName').val(wingData.testName);
        $('#duration').val(wingData.duration);
        $('#maximumMarks').val(wingData.maximumMarks);
        $('#passingMarks').val(wingData.passingMarks);
        if (wingData.mandatory === true)
            $('#mandatory').prop("checked", true);
        else
            $('#mandatory').prop("checked", false);


        lstTeacherSelect = wingData.evaluation;
        // // console.log(lstTeacherSelect);
        this.setTeacherSelectTable();


    }
    setTeacherSelectTable = () => {
        var teacherId = "";
        $('#teacherBodyTable tr input').prop("checked", false);
        for (var i = 0; i < lstTeacherSelect.length; i++) {
            teacherId = lstTeacherSelect[i].teacherId;
            $('#teacherBodyTable tr').each(function (index, value) {
                var teacherIdTemp = $(this).find('td:eq(0)').text();
                if (teacherId == teacherIdTemp) {
                    $('#cbx' + teacherId).prop("checked", true);
                }
                else {
                    $('#cbx' + teacherId).attr("checked", false);
                }
            });
        }
    }
    getwingData(regsTestId) {
        for (let i = 0; i < lstRegsTest.length; i++) {
            if (parseInt(regsTestId) === lstRegsTest[i].regsTestId)
                return lstRegsTest[i];
        }
    }

    duplicateAdmissionTests = () => {
        this.setState({
            dupFlg: true,
        });

        this.addAdmissionTests();
    }

    addAdmissionTests = () => {
        let regsTest = {};
        let regsTestId = 0;;
        let testName = $('#testName').val();
        let wingId = $('#wingsCmb').find('option:selected').val();
        let classId = $('#classTypeCmb').find('option:selected').val();
        let mandatory = $('#mandatory').prop('checked');
        mandatory = (mandatory === true) ? 1 : 0;
        let duration = $('#duration').val();
        let maximumMarks = $('#maximumMarks').val();
        let passingMarks = $('#passingMarks').val();



        if (testName === "") {
            toast.warning('Please Enter Test Name');
            $('#testName').focus();
            return false;
        }
        if (duration === "" || parseInt(duration) < 15 || parseInt(duration) > 180) {
            toast.warning('Duration Should be between 15-180');
            $('#duration').focus();
            return false;
        }
        if (maximumMarks === "" || parseInt(maximumMarks) === 0) {
            toast.warning('Max. Marks Should be between more than 0');
            $('#maximumMarks').focus();
            return false;
        }
        if (passingMarks === "" || parseInt(passingMarks) < 1 || parseInt(passingMarks) >= maximumMarks) {
            toast.warning('Pass Marks Should be More than 0 and less then Max. Marks');
            $('#passingMarks').focus();
            return false;
        }

        evaluation = [];
        $('#teacherBodyTable tr').each(function (index, value) {
            if ($(this).find($('input[type=checkbox]')).prop('checked') == true) {
                var teacherId = $(this).find('td:eq(0)').text();
                var lstTemp = { 'teacherId': teacherId }
                evaluation.push(lstTemp);
            }

        });

        if (this.state.flg) {
            regsTestId = 0;
        }
        else {
            regsTestId = $('#admissionTestsTableBody tr.selected').find('td:eq(0)').text();
        }

        if (this.state.dupFlg) {
            regsTestId = 0;
        }
        else {
            regsTestId = $('#admissionTestsTableBody tr.selected').find('td:eq(0)').text();
        }

        regsTest = {
            regsTestId,
            wingId,
            classId,
            testName,
            maximumMarks,
            passingMarks,
            duration,
            mandatory,
            evaluation
        }


        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/mst/regs/test/save ',
            queryString.stringify({ 'regsTest': JSON.stringify(regsTest), screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getAdmissionTestsList();
                this.setAdmissionTestTable();
                this.addAdmissionTestsReady();

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });


    }

    addTD(row, val, classname) {
        let cell = document.createElement('td');
        cell.className = classname;
        let text = document.createTextNode(val);
        cell.append(text);
        row.append(cell);
    }

    addAdmissionTestsReady = () => {
        $('#addAdmissionAddForm').trigger('reset');
        $('#admissionTestsTableBody tr').removeClass('selected');
    }

    // =================== Evaluate Panel ==========================

    setTeacherTable = () => {
        $('#teacherBodyTable tr').remove();
        var table = document.getElementById('teacherTable');
        var tbody = document.getElementById('teacherBodyTable');

        for (var i = 0; i < lstTeacher.length; i++) {
            var teacherId = lstTeacher[i].teacherId;
            var teacher = lstTeacher[i].teacher;

            var row = document.createElement('tr');
            row.id = teacherId;


            this.addTD(row, teacherId, 'no-display');
            this.addTDCbx(row, teacherId, 'text-center');
            this.addTD(row, teacher, 'text-center');

            tbody.append(row);
        }
        table.append(tbody);
    }

    addTDCbx(row, id, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var cbx = document.createElement('input');
        cbx.type = 'checkbox';
        cbx.id = 'cbx' + id;
        cbx.className = 'checkbx'
        // cell.onclick = () => {
        //     $('td').removeClass('selected');
        //     $(row).addClass('selected').siblings().removeClass('selected');
        // };

        cell.append(cbx);
        row.append(cell);
    }


    getWingId = (wingId) => {
        // // console.log("wingId" + wingId);
    }


    render() {
        httpURL = lstModules['registration'].url + ":" + lstModules['registration'].port;
        return (
            <div style={{ height: '100%' }}>
                <Header wingFromChild={this.getWingId} />
                <ToastContainer autoClose={3000} hideProgressBar />
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: this.state.loader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>
                <div className="mainContainer admissionTests" style={{ opacity: this.state.opacity }}>
                    <div className="col-md-11 col-centered">
                        <div className="col-md-6  col-xs-6  padding-remove">
                            <table className="tableLayout1" style={{ width: this.state.width }}>
                                <thead>
                                    <tr>
                                        <th style={{ display: 'none' }}> admissionTests Id </th>
                                        <th style={{ width: 40 }}> Wing </th>
                                        <th style={{ width: 40 }}> Class </th>
                                        <th style={{ width: 60 }}> Test Name </th>
                                        <th style={{ width: 30 }}> Mandatory </th>
                                        <th style={{ width: 30 }}> Duration (M.) </th>

                                    </tr>
                                </thead>
                            </table>
                            <div className="admissionTestsTableDiv" style={{ height: '400px', overflowY: 'scroll', border: '1px solid #ccc' }} >
                                <table id="admissionTestsTable" className="tableLayout">
                                    <colgroup>
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="60" />
                                        <col width="30" />
                                        <col width="30" />
                                    </colgroup>
                                    <tbody id="admissionTestsTableBody"></tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-xs-1 ">
                            <span id="addAdmissionTests" title="Add Admission Tests " onClick={this.addAdmissionTestsReady} className="addIcon glyphicon glyphicon-plus-sign"></span>
                        </div>
                        <div className="col-md-5 col-xs-8 padding-remove timingFormWrap" >
                            <form id="addAdmissionAddForm" className="form-horizontal formWrapper">
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Wing</label>
                                    <div className="col-sm-6 padding-remove">
                                        <select onChange={this.changeWing} className="form-control" style={{ width: '100%' }} id="wingsCmb"></select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Class</label>
                                    <div className="col-sm-6 padding-remove">
                                        <select onChange={this.changeClass} className="form-control" style={{ width: '100%' }} id="classTypeCmb"></select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Test Name<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="testName" type="text" maxLength={50} className="form-control " placeholder=" Test Name (50 Character)" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Duration<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="duration" type="text" maxLength={50} className="form-control " placeholder=" Duration(minutes)" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Max. Marks<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="maximumMarks" type="text" maxLength={50} className="form-control " placeholder=" Maximum Marks" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Passing Marks<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="passingMarks" type="text" maxLength={50} className="form-control " placeholder=" Passing Marks" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Mandatory<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="mandatory" type="checkbox" className="form-control " />
                                    </div>
                                </div>
                                <div className="evaluationPanel">
                                    <h4>Evaluation Panel</h4>
                                    <div className="col-xs-10 col-centered">
                                        <table className="tableLayout1" style={{ width: this.state.width }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ display: 'none' }}> Fee Id </th>
                                                    <th style={{ width: 20 }}> Select </th>
                                                    <th style={{ width: 50 }}> Teacher </th>

                                                </tr>
                                            </thead>
                                        </table>

                                        <div className="teacherTableDiv" style={{ height: '100px', overflowY: 'scroll', border: '1px solid #ccc' }} >
                                            <table id="teacherTable" className="tableLayout">
                                                <colgroup>
                                                    <col width="20" />
                                                    <col width="50" />
                                                </colgroup>
                                                <tbody id="teacherBodyTable"></tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>

                                <div className="form-group" >
                                    <div className="col-sm-12 text-center userButtonWrap" >
                                        <button type="button" style={{ marginTop: 20 }} title="Save" onClick={this.addAdmissionTests} className="btn btn-info">Save</button>
                                        <button type="button" style={{ marginTop: 20 }} title="Delete" onClick={this.deleteAdmissionTests} className="btn btn-danger">Delete</button>
                                        <button type="button" style={{ marginTop: 20 }} title="Duplicate" onClick={this.duplicateAdmissionTests} className="btn btn-warning">Duplicate</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        );
    }



}
export default admissionTests;