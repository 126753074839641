import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
var tokenid = localStorage.getItem('tokenid')
var httpURL = lstModules['enquiry'].url + ':' + lstModules['enquiry'].port
function getTestListSvc (callBack, admissionId, clsId) {
  axios({
    url: httpURL + '/sms/admission/eval/test/list',
    method: 'POST',
    params: { admissionId: admissionId, clsId: clsId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      callBack(data)
    })
    .catch(error => {
      // // console.log('Test List Catch error')
    })
}

function getTestResultDtlsSvc (callBack, ptestSlotId) {
  if (!ptestSlotId) {
    return
  }
  axios({
    url: httpURL + '/sms/admission/test/result/dtls',
    method: 'POST',
    params: { testSlotId: ptestSlotId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      callBack(data)
    })
    .catch(error => {
      // // console.log('Catch error')
    })
}

function saveResultSvc (pResultObj, cbSaveResult) {
  axios({
    url: httpURL + '/sms/admission/test/result/save',
    method: 'POST',
    params: { result: JSON.stringify(pResultObj) },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      var data = response.data
      cbSaveResult(data)
    })
    .catch(error => {
      // // console.log('Error Occured')
    })
}
export { getTestListSvc }
export { getTestResultDtlsSvc }
export { saveResultSvc }
