import React, { Component } from "react";
import lstModules from 'common/json/lstModules.json';
import $ from "jquery";
import "jquery-ui";

import 'common/css/master.css';
import "../css/feeHeads.css";
import axios from "axios";
import Header from "common/js/header/header.js";
import { toast, ToastContainer } from "react-toastify";
import FeeHeadsSlider from "./feeHeadsSlider";
import FeeHeadsDiscountSlider from "./feeHeadsDiscountSlider";
import FeeHeadsTable from "./feeHeadsTable";

var httpURL = "";
var queryString = require("querystring");
var screenId = 9;

class FeeHeads extends Component {
  constructor(props) {
    // // console.log("constructor");
    super(props);
    this.state = {
      // lstFeeHead: [],
      lstMonths: [],
      feeHeadId: 0,
      tokenid: localStorage.getItem("tokenid"),
      lstFound:[]
    };
    // this.slider = <></>;
  }

  componentDidMount() {
    // // console.log("cdm");
    this.getFeeHeadList();
    this.getLabels();
    this.showSlider = this.showSlider.bind(this);
  }

  getFeeHeadList = () => {

    this.setState({ loader: "block", opacity: 0.5 });
    axios({
      url: httpURL + "/sms/fee/head/list",
      method: "POST",
      params: { screenId: screenId },
      headers: { tokenid: this.state.tokenid },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          var lstTmpFeeHead = response.data.lstFeeHead
          this.setState({ lstFeeHead:lstTmpFeeHead , lstFound: lstTmpFeeHead});
        } else {
          toast.error(response.data.SvcMsg);
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((e) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("Service failed: " + e);
      });
  };
  filterFeeHeadList=(filterLbl)=>{
    // // console.log(filterLbl)
    filterLbl = filterLbl.toLowerCase().trim()
    var tmpList = this.state.lstFeeHead;
    var tmpFeeHeadList = []
    for(var i=0;i<tmpList.length;i++){
      if(tmpList[i].label && tmpList[i].label.toLowerCase().indexOf(filterLbl)>-1){
        tmpFeeHeadList.push(tmpList[i])
      }
    } 
    this.setState({lstFound:tmpFeeHeadList})
  }


  addFeeHeads = ({
    feeHeadId,
    feeHeadCode,
    labelId,
    feeFrequency,
    collectionFrequency,
    defaultAmt,
    lateFeeFixAmt,
    lateFeeAmtPerDay,
    lateFeeLimit,
    /*  discountSiblingAmt,
     discountScholarAmt,
     discountMeritMeansAmt,
     discountScStAmt,
     discountBackwardAmt, */
    lstCollection,
  }) => {
    var feeHead = {};

    if (feeHeadCode === "") {
      toast.warning('Please Enter Fee Head Code');
      $('#feeHeadCode').focus();
      return false;
    }
    if (labelId === 0) {
      toast.warning('Please select Fee Label');
      $('#label').focus();
      return false;
    }
    if (defaultAmt === "" || defaultAmt === 0) {
      toast.warning('Please Enter Default Amount');
      $('#defaultAmt').focus();
      return false;
    }
    if (feeFrequency === "" || feeFrequency === "Frequency") {
      toast.warning('Please Select Frequency');
      $('#feeFrequency').focus();
      return false;
    }
    if (collectionFrequency === "" || collectionFrequency === "Collection") {
      toast.warning('Please Select Collection');
      $('#collectionFrequency').focus();
      return false;
    }
    this.setState({ loader: 'block', opacity: 0.5 });

    feeHead = {
      feeHeadId: feeHeadId,
      feeHeadCode: feeHeadCode,
      labelId: labelId,
      feeFrequency: feeFrequency,
      collectionFrequency: collectionFrequency,
      defaultAmt: defaultAmt,
      lateFeeFixAmt: lateFeeFixAmt,
      lateFeeAmtPerDay: lateFeeAmtPerDay,
      lateFeeLimit: lateFeeLimit,
      /* discountSiblingAmt: discountSiblingAmt,
      discountScholarAmt: discountScholarAmt,
      discountMeritMeansAmt: discountMeritMeansAmt,
      discountScStAmt: discountScStAmt,
      discountBackwardAmt: discountBackwardAmt, */
      lstCollection: lstCollection,
    };

    axios
      .post(
        httpURL + "/sms/fee/head/save",
        queryString.stringify({
          screenId: screenId,
          feeHead: JSON.stringify(feeHead),
        }),
        {
          headers: { tokenid: this.state.tokenid },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          toast.success(response.data.SvcMsg);
          this.getFeeHeadList();
          this.closeSlider();

          // this.resetSlider();
        } else {
          toast.error(response.data.SvcMsg);
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((e) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("Service failed: " + e);
      });
  };

  saveDiscount = ({
    feeHeadId,
    discountSiblingAmt,
    discountScholarAmt,
    discountMeritMeansAmt,
    discountScStAmt,
    discountBackwardAmt,
  }) => {
    var discount = {};

    discount = {
      feeHeadId: feeHeadId,
      discountSiblingAmt: discountSiblingAmt,
      discountScholarAmt: discountScholarAmt,
      discountMeritMeansAmt: discountMeritMeansAmt,
      discountScStAmt: discountScStAmt,
      discountBackwardAmt: discountBackwardAmt,
    };
    this.setState({ loader: 'block', opacity: 0.5 });
    axios
      .post(
        httpURL + "/sms/fee/head/discount/save",
        queryString.stringify({
          screenId: screenId,
          discount: JSON.stringify(discount),
        }),
        {
          headers: { tokenid: this.state.tokenid },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          toast.success(response.data.SvcMsg);
          this.getFeeHeadList();
          this.closeSlider();

          // this.resetSlider();
        } else {
          toast.error(response.data.SvcMsg);
        }

        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((e) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("Service failed: " + e);
      });
  };

  deleteFeeHead = (feeHeadId) => {
    if (window.confirm("Are you sure to delete the Fee Head?")) {
      axios.post(
        httpURL + "/sms/fee/head/delete",
        queryString.stringify({ screenId: screenId, feeHeadId: feeHeadId }),
        {
          headers: { tokenid: this.state.tokenid },
          withCredentials: true,
        }
      ).then((response) => {
        if (response.data.SvcStatus === "Success") {
          toast.success(response.data.SvcMsg);
          this.getFeeHeadList();
        } else {
          toast.error(response.data.SvcMsg);
        }
        this.setState({ loader: "none", opacity: 1 });
      })
        .catch((e) => {
          this.setState({ loader: "none", opacity: 1 });
          toast.error("Service failed: " + e);
        });
    }
  };
  showDiscountSlider = (feeHeadId, feeHeadData, feeHeadDiscount) => {
    // // console.log(feeHeadId);
    // feeHeadData = feeHeadData;
    // this.getDiscountData(feeHeadId);


    this.slider = <div className="slider halfWidthSlider">
      <FeeHeadsDiscountSlider
        removeClassFromBody={this.closeSlider}
        saveDiscount={this.saveDiscount}
        feeHeadId={feeHeadId}
        feeHeadData={feeHeadData}
        feeHeadDiscount={feeHeadDiscount}
        flgAdd={false}
      /></div>
    this.setState({ feeHeadId: feeHeadId, feeHeadData: feeHeadData });

    $("body").addClass("openSlider");
  }

  /*   addFeeHeads = ({
      feeHeadId,
      discountSiblingAmt,
      discountScholarAmt,
      discountMeritMeansAmt,
      discountScStAmt,
      discountBackwardAmt,
    }) => {
      var feeHead = {};
  
      feeHead = {
        feeHeadId: feeHeadId,
        discountSiblingAmt: discountSiblingAmt,
        discountScholarAmt: discountScholarAmt,
        discountMeritMeansAmt: discountMeritMeansAmt,
        discountScStAmt: discountScStAmt,
        discountBackwardAmt: discountBackwardAmt,
      };
  
      axios
        .post(
          httpURL + "/sms/fee/head/save",
          queryString.stringify({
            screenId: screenId,
            discount: JSON.stringify(feeHead),
          }),
          {
            headers: { tokenid: this.state.tokenid },
            withCredentials: true,
          }
        )
        .then((response) => {
          if (response.data.SvcStatus === "Success") {
            toast.success(response.data.SvcMsg);
            this.closeSlider();
  
            // this.resetSlider();
          } else {
            toast.error(response.data.SvcMsg);
          }
          this.setState({ loader: "none", opacity: 1 });
        })
        .catch((e) => {
          this.setState({ loader: "none", opacity: 1 });
          toast.error("Service failed: " + e);
        });
    };
   */

  setLoader = (loader, opacity) => {
    this.setState({ loader: loader, opacity: opacity });
  }
  showSlider = (feeHeadId, feeHeadData, flgAdd) => {
    // feeHeadData = feeHeadData;
    if (flgAdd) {
      document.getElementById('feeHeadAddForm') && document.getElementById('feeHeadAddForm').reset();
      $('#feeHeadsBodyTable tr.selected').removeClass('selected');
    }

    this.slider = <div className="slider halfWidthSlider">
      <FeeHeadsSlider
        key={new Date().getTime()}
        // setLoader={this.setLoader}
        removeClassFromBody={this.closeSlider}
        addFeeHeads={this.addFeeHeads}
        feeHeadId={feeHeadId}
        feeHeadData={feeHeadData}
        flgAdd={flgAdd}
        labelId={feeHeadData.labelId}
        lstLabel={this.state.lstFeeLabel}
      /></div>
    this.setState({ feeHeadId: feeHeadId, feeHeadData: feeHeadData });

    $("body").addClass("openSlider");
  };

  closeSlider = () => {
    this.getFeeHeadList();
    $("body").removeClass("openSlider");
  };

  getSlider = () => {
    return this.slider;
  }

  getWingId = (wingId) => {
    // // console.log("wingId" + wingId);
  }
  getLabels = () => {
    this.setState({ loader: "block", opacity: 0.5 });
    axios({
      url: httpURL + "/sms/fee/head/label",
      method: "POST",
      params: { screenId: screenId },
      headers: { tokenid: this.state.tokenid },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          this.setState({ lstFeeLabel: response.data.lstFeeLabel });
        } else {
          toast.error(response.data.SvcMsg);
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((e) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("Service failed: " + e);
      });
  }

  getDiscountData = (feeHeadId, feeHeads) => {

    this.setState({ loader: "block", opacity: 0.5 });
    axios({
      url: httpURL + "/sms/fee/head/discount/list",
      method: "POST",
      params: { screenId: screenId, feeHeadId },
      headers: { tokenid: this.state.tokenid },
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.SvcStatus === "Success") {
          this.setState({ feeHeadDiscount: response.data.feeHeadDiscount });
          this.showDiscountSlider(feeHeadId, feeHeads, response.data.feeHeadDiscount);
        } else {
          toast.error(response.data.SvcMsg);
        }
        this.setState({ loader: "none", opacity: 1 });
      })
      .catch((e) => {
        this.setState({ loader: "none", opacity: 1 });
        toast.error("Service failed: " + e);
      });
  };

  render() {
    // // console.log("render is called twice");
    httpURL = lstModules['fees'].url + ":" + lstModules['fees'].port;
    return (
      <div style={{ pointerEvents: this.state.opacity === 1 ? 'auto' : 'none' }}>
        <Header wingFromChild={this.getWingId} />
        <ToastContainer autoClose={3000} hideProgressBar />
        {/* <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: this.state.loader }}>
          <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
        </div> */}
        <FeeHeadsTable
          showSlider={this.showSlider}
          lstFeeHead={this.state.lstFound}
          deleteFeeHead={this.deleteFeeHead}
          getDiscountData={this.getDiscountData}
          filterFeeHeadList={this.filterFeeHeadList}
        // showDiscountSlider={this.showDiscountSlider}
        />
        {this.getSlider()}

      </div>
    );
  }
}

export default FeeHeads;
