import React, { useEffect, useState } from 'react'

import "common/css/master.css";


const SubjectAllocationTable = (props) => {
    const { lstSubjectAllocation, subjectAllocationId, setSubjectAllocationId, editSubjectAllocation, deleteSubjectAllocation } = props;

    const [lstSubjectAllocationFiltered, setLstSubjectAllocationFiltered] = useState([]);
    const [selSubjectAllocationId, setSelSubjectAllocationId] = useState(0);

    useEffect(() => {
        // console.log("useEffect in SubjectAllocationTable component called, lstSubjectAllocation", lstSubjectAllocation);
        setLstSubjectAllocationFiltered(lstSubjectAllocation);
    }, [lstSubjectAllocation]);

    useEffect(() => {
        // console.log("useEffect in SubjectAllocationTable component called subjectAllocationId", subjectAllocationId);
        setSelSubjectAllocationId(subjectAllocationId);
    }, [subjectAllocationId]);

    const colgrp = <colgroup>
        <col width="80"></col>
        <col width="35"></col>
        <col width="35"></col>
        <col width="35"></col>
        <col width="60"></col>
        <col width="40"></col>
    </colgroup>



    return (
        <div >
            <div className="col-md-12 col-xs-12 col-centered padding-remove " >
                <table className="tableLayout1" >
                    {colgrp}
                    <thead>
                        <tr>
                            <th >
                                Subject
                            </th>
                            <th >
                                Total Periods
                            </th>
                            <th >
                                Comman Teacher
                            </th>
                            <th >
                                Shared Slot
                            </th>
                            <th >
                                Facility
                            </th>
                            <th >
                                Action
                            </th>
                        </tr>
                    </thead>
                </table>
                <div
                    className="tableDiv"
                    style={{ height: "60vh", pointerEvents: "auto", zIndex: 1, position: "relative" }}
                >
                    <table className="tableLayout" style={{ position: "relative" }}>
                        {colgrp}
                        <tbody id="diaryMonthlySubjectAllocationTable">
                            {lstSubjectAllocationFiltered &&
                                lstSubjectAllocationFiltered.map((subjectAllocation, i) => (
                                    <tr
                                        key={subjectAllocation.subjectAllocationId}
                                        onClick={() => setSubjectAllocationId(subjectAllocation.subjectAllocationId)}
                                        className={subjectAllocation.subId === selSubjectAllocationId ? "selected" : ''}
                                    >
                                        <td className="text-left">{subjectAllocation.sub}</td>
                                        <td className="text-center">{subjectAllocation.totalPeriod}</td>
                                        <td className="text-center">{subjectAllocation.flgCommonTch ? "Yes" : "No"}</td>
                                        <td className="text-center">{subjectAllocation.flgSharedSlot ? "Yes" : "No"}</td>
                                        <td className="text-center">{!!subjectAllocation.facility ? subjectAllocation.facility : "-"}</td>
                                        <td className="text-center">
                                            <span
                                                onClick={() => editSubjectAllocation(subjectAllocation)}
                                                title="Edit Subject Allocation Details"
                                                style={{ textDecoration: "underline", cursor: "pointer", color: "blue", margin: "5px" }}
                                            >
                                                Edit
                                            </span>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    );
}

export default SubjectAllocationTable;