import React, { useState, useEffect } from 'react';
import 'jquery-ui';
import 'common/css/master.css';
import 'react-datepicker/dist/react-datepicker.css';
import { saveTchForCell } from './ttTeacherService';
import { toast, ToastContainer } from 'react-toastify';

const TchSubjectTableBody = (props) => {
    const [lstTch, setlstTch] = useState(props.lstTch)
    const [selTchId, setSelTchId] = useState(props.selTchId);
    const [classLabel, setClassLabel] = useState(props.classLabel)
    const [found, setFound] = useState([])
    const [teacher, setTeacher] = useState("")

    useEffect(() => {
        setlstTch(props.lstTch)
        setFound(props.lstTch)
        setClassLabel(props.classLabel)
    }, [props.classLabel, props.lstTch]);
    function rowClick(e, pTchId) {
        setSelTchId(pTchId);
        props.rowClick(e);
    }
    const rowClickInMain = (e) => {
        var tchId = e.currentTarget.getAttribute("tchid");
        // // console.log('tchId=' + tchId);
        setSelTchId(tchId);
        var cellDtls = props.selCellDtls;
        // // console.log(props.selCellDtls)
        cellDtls.tchId = tchId;
        // showLoader();
        saveTchForCell(cellDtls, handleSaveTchResponse);

    }

    function handleSaveTchResponse(cellDtls, data) {
        // hideLoader();
        // setCellKey(cellDtls.cellId);
        var obj = data;
        // // console.log("Save cell" + JSON.stringify(obj))
        // // console.log("Save cell" + JSON.stringify(cellDtls))

        if (obj.status === 'Success') {
            toast.success(obj.message);
            props.setCellProps(cellDtls, cellDtls.cellId, true)
        } else if (obj.status === 'Failure') {
            props.setCellProps(cellDtls, cellDtls.cellId, false)
            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(data.message);
            }
        } else {
            toast.error("Service failed " + obj);
        }
    }
    const filterTeacher = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = found.filter((user) => {
                return user.tch.toLowerCase().includes(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
            setFound(results);
        } else {
            setFound(lstTch);
            // If the text field is empty, show all users
        }
        setTeacher(keyword);
    };
    return (
        <div style={{ height: "100%" }}>
            <table className="tableLayout1 " style={{ width: 250 }}>
                <thead>
                    <tr>
                        <th style={{ display: 'none' }} rowSpan="2"> tch Id </th>
                        <th style={{ width: 20 + '%' }} rowSpan="2"> Select </th>
                        <th className="padding-remove" style={{ width: 35 + '%' }} rowSpan="2"><input onChange={filterTeacher} type="text" value={teacher} className="form-control inp-search" placeholder="Teacher" /></th>
                        {/* <th style={{ width: 35 + '%' }} rowSpan="2"> Teacher </th> */}
                        <th style={{ width: 35 + '%' }} rowSpan="2"> Role </th>
                        <th style={{ display: 'none' }} rowSpan="2"> canShare </th>
                        <th style={{ width: 90 + '%' }} colSpan="3"> Allocation </th>
                    </tr>
                    <tr>
                        <th style={{ width: 30 + '%' }}> Range(%)</th>
                        <th style={{ width: 30 + '%' }}> {(props.classLabel !== '' && props.classLabel) + (props.sectionLabel !== '' && ('-' + props.sectionLabel)) + '(%)'}
                            <button className='glyphicon glyphicon glyphicon-arrow-up' style={{ backgroundColor: "#4a86e8", margin: "0 1px" }} onClick={props.sortAscAllocation}></button>
                            <button className='glyphicon glyphicon glyphicon-arrow-down' style={{ backgroundColor: "#4a86e8", margin: "0 1px" }} onClick={props.sortDscAllocation}></button>
                        </th>
                        <th style={{ width: 30 + '%' }}>Total(%)
                            <button className='glyphicon glyphicon glyphicon-arrow-up' style={{ backgroundColor: "#4a86e8", margin: "0 1px" }} onClick={props.sortAscTotal}></button>
                            <button className='glyphicon glyphicon glyphicon-arrow-down' style={{ backgroundColor: "#4a86e8", margin: "0 1px" }} onClick={props.sortDscTotal}></button>
                        </th>
                    </tr>
                </thead>
            </table>
            <div className="subjectTableDiv" style={{ height: '90%', overflowY: 'scroll', border: '1px solid #ccc' }} >
                <table id="subjectTable" className="tableLayout">
                    <colgroup>
                        <col width="20" />
                        <col width="35" />
                        <col width="35" />
                        <col width="30" />
                        <col width="30" />
                        <col width="30" />
                    </colgroup>
                    <tbody>{
                        found.map(obj => {
                            var isSelected = obj.tchId === selTchId;
                            return <tr key={obj.tchId} className={isSelected ? "selected" : ""} id={obj.tchId} >
                                <td className="text-center">
                                    <span className="glyphicon glyphicon-triangle-left"
                                        tchid={obj.tchId}
                                        onClick={(e) => rowClickInMain(e, obj.tchId)}></span></td>
                                <td>{obj.tch}</td>
                                <td>{obj.role}</td>
                                <td style={{ textAlign: "center" }}>{obj.range}</td>
                                <td style={{ textAlign: "center" }}>{obj.pct}</td>
                                <td style={{ textAlign: "center", color: obj.flgRange === 1 ? "gray" : obj.flgRange === 2 ? "#333333" : obj.flgRange === 3 ? "red" : "" }}>{obj.overallPct}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default TchSubjectTableBody;