import React, { useEffect, useState } from "react";
import 'common/css/master.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../css/leaderboard.css';

const TopFiveRankers = ({ lstLeaderBoard }) => {


    const [avatarPath, setAvatarPath] = useState("");

    useEffect(() => {
        // // console.log("TopStudents called lstLeaderBoard",lstLeaderBoard);
        getPathAvatar();
    }, [lstLeaderBoard]);


    const getPathAvatar = () => {
        // // console.log("getPathAvatar Called");
        const tempPathAvatar = localStorage.getItem('avatarPath');
        // // console.log("tempPathAvatar in getMapAvatar", tempPathAvatar);
        setAvatarPath(tempPathAvatar);
    }



    return (
        <div className="col-sm-12 col-xs-12 padding-remove" >
            <div className="col-sm-12 col-xs-12 leaderbord-poles-5 col-centered padding-remove" >
                <div className="col-sm-12 col-xs-12 leaderboard-pole-scorer-5 padding-remove"  >
                    {/* <div className=" col-sm-1 col-xs-1 padding-remove text-center" >
                    </div> */}
                    <div className="rankDiv-5 div1-five padding-remove text-center" style={{ width: "19%" }}  >
                        {lstLeaderBoard[3] ?
                            <div className="col-sm-12 col-xs-12 padding-remove">
                                <div className="col-sm-12 col-xs-12 top-scorer-name-5">
                                    {lstLeaderBoard[3].crypticNm}
                                </div>
                                <div className="col-sm-12 col-xs-12 padding-remove">
                                    <div className="topper-avatar-4 ">
                                        <div className="avatar-image-five-4">
                                            <img src={avatarPath + "/" + lstLeaderBoard[3].avatarId + ".png"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>
                    <div className="rankDiv-5 div2-five padding-remove text-center" style={{ width: "20%" }}  >
                        {lstLeaderBoard[1] ?
                            <div className="col-sm-12 col-xs-12 padding-remove">
                                <div className="col-sm-12 col-xs-12 top-scorer-name-5">
                                    {lstLeaderBoard[1].crypticNm}
                                </div>
                                <div className="col-sm-12 col-xs-12 padding-remove">
                                    <div className="topper-avatar-2 ">
                                        <div className="avatar-image-five-2">
                                            <img src={avatarPath + "/" + lstLeaderBoard[1].avatarId + ".png"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>
                    <div className="rankDiv-5 div3-five padding-remove text-center " style={{ width: "22%" }}  >
                        {lstLeaderBoard[0] ?
                            <>
                                <div className="col-sm-12 col-xs-12 top-scorer-name-5 ">
                                    {lstLeaderBoard[0].crypticNm}
                                </div>
                                <div className="col-sm-12 col-xs-12 padding-remove">
                                    <div className="topper-avatar-1 ">
                                        <div className="avatar-image-five-1 ">
                                            <img className=" " src={avatarPath + "/" + lstLeaderBoard[0].avatarId + ".png"} />
                                        </div>
                                    </div>
                                </div>
                            </>
                            : null}
                    </div>
                    <div className="rankDiv-5 div4-five padding-remove text-center " style={{ width: "20%" }} >
                        {lstLeaderBoard[2] ?
                            <>
                                <div className="col-sm-12 col-xs-12 top-scorer-name-5" >
                                    {lstLeaderBoard[2].crypticNm}
                                </div>
                                <div className="col-sm-12 col-xs-12 padding-remove">
                                    <div className="topper-avatar-3  ">
                                        <div className="avatar-image-five-3 ">
                                            <img src={avatarPath + "/" + lstLeaderBoard[2].avatarId + ".png"} />
                                        </div>
                                    </div>
                                </div>
                            </>
                            : null}
                    </div>
                    <div className="rankDiv-5 div5-five padding-remove text-center" style={{ width: "19%" }} >
                        {lstLeaderBoard[4] ?
                            <>
                                <div className="col-sm-12 col-xs-12 top-scorer-name-5" >
                                    {lstLeaderBoard[4].crypticNm}
                                </div>
                                <div className="col-sm-12 col-xs-12 padding-remove">
                                    <div className="topper-avatar-5 ">
                                        <div className="avatar-image-five-5  ">
                                            <img src={avatarPath + "/" + lstLeaderBoard[4].avatarId + ".png"} />
                                        </div>
                                    </div>
                                </div>
                            </>
                            : null}
                    </div>
                    {/* <div className=" col-sm-1 col-xs-0 padding-remove text-center">
                    </div> */}
                </div>
            </div>
            <div className="col-sm-12 col-xs-12 col-centered padding-remove" style={{ height: 50, background: '#104b5d', }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    {/* <div className="col-sm-1 col-xs-1 padding-remove text-center" style={{ height: 50, backgroundColor: "#F4F4F4" }}>
                    </div> */}
                    <div className="padding-remove text-center" style={{ width: "19%", borderRight: '1px solid #fff', height: 50, alignContent: "center" }}>
                        {lstLeaderBoard[3] ?
                            <div className=" toper-score " >
                                {lstLeaderBoard[3].score}
                            </div>
                            : null}
                    </div>
                    <div className=" padding-remove text-center" style={{ width: "20%", borderRight: '1px solid #fff', height: 50, alignContent: "center" }}>
                        {lstLeaderBoard[1] ?
                            <div className="toper-score " >
                                {lstLeaderBoard[1].score}
                            </div>
                            : null}
                    </div>
                    <div className=" padding-remove text-center" style={{ width: "22%", borderRight: '1px solid #fff', height: 50, alignContent: "center" }}>
                        {lstLeaderBoard[0] ?
                            <div className="col-sm-12 toper-score col-xs-12" >
                                {lstLeaderBoard[0].score}
                            </div>
                            : null}
                    </div>
                    <div className=" padding-remove text-center" style={{ width: "20%", borderRight: '1px solid #fff', height: 50, alignContent: "center" }}>
                        {lstLeaderBoard[2] ?
                            <div className="col-sm-12 toper-score col-xs-12" >
                                {lstLeaderBoard[2].score}
                            </div>
                            : null}
                    </div>
                    <div className="padding-remove text-center" style={{ width: "19%", borderRight: '0px solid #fff', height: 50, alignContent: "center" }}>
                        {lstLeaderBoard[4] ?
                            <div className="col-sm-12 toper-score col-xs-12" >
                                {lstLeaderBoard[4].score}
                            </div>
                            : null}
                    </div>
                    {/* <div className="col-sm-1 col-xs-0 padding-remove text-center" style={{ height: 50, backgroundColor: "#F4F4F4" }}>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default TopFiveRankers;