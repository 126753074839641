import axios from 'axios';
import lstModules from 'common/json/lstModules.json';


const httpURL = lstModules['students'].url + ':' + lstModules['students'].port;
const tokenid = localStorage.getItem('tokenid');


var queryString = require("querystring");

const schoolId = localStorage.getItem('schoolId');
const academicYearId = localStorage.getItem('academicYearId');

export const getMstClassListSvc = (cbClassList) => {
    axios({
        url: httpURL + "/sms/mst/cls/list",
        method: "POST",
        data: queryString.stringify({ schoolId: schoolId, academicYearId: academicYearId }),
        headers: { tokenid: tokenid },
        withCredentials: true,
    })
        .then((response) => {
            cbClassList(response.data);
        })
        .catch((error) => {
            cbClassList({ status: "Exception", message: error });
        });
}

export const getStudentListSvc = (cbStudentList) => {
    axios({
        url: httpURL + "/sms/student/list",
        method: "POST",
        data: queryString.stringify({ schoolId: schoolId, acdYrId: academicYearId }),
        headers: { tokenid: tokenid },
        withCredentials: true,
    })
        .then((response) => {
            cbStudentList(response.data);
        })
        .catch((error) => {
            cbStudentList({ status: "Exception", message: error });
        });
}

export const uploadExcelSvc = (formData, cb) => {
    axios({
        url: httpURL + "/sms/student/excel/upload",
        method: "POST",
        data: formData,
        headers: { tokenid },
        withCredentials: true,
    })
        .then((response) => {
            cb(response.data);
        })
        .catch((error) => {
            cb({ SvcStatus: "Failure", SvcMsg: "Service failed " + error });
        });
};

export const deleteStudentSvc = (studentId, callback) => {
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + "/sms/student/delete",
        method: "POST",
        params: { studentId },
        headers: { tokenid },
        withCredentials: true,
    })
        .then((response) => {
            obj.status = response.data.SvcStatus;
            obj.message = response.data.SvcMsg;
            callback(obj);
        })
        .catch((error) => {
            callback({ status: "Exception", error: error.message });
        });
};