import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import "../css/subjectAllocation.css";


const ReportTabs = (props) => {
    const { flgClsReportTab, setFlgClsReportTab } = props;
    const mapTabs = {
        tch: "Teacher",
        cls: "Class"
    };

    useEffect(() => {
    }, []);

    const handleTabClick = (tabId) => {
        console.log("handleTabClick called");
        if (tabId === "tch") {
            setFlgClsReportTab(false);
        } else {
            setFlgClsReportTab(true);
        }
    };



    return (
        <div className="col-md-12 col-xs-12 col-centered padding-remove " >
            {/* <div className="col-md-3 col-sm-3 col-xs-3 padding-remove">
            </div> */}
            <div className="col-md-10 col-sm-10 col-xs-10 padding-remove">
                <ul className="nav nav-tabs school-profile-nav-tabs">
                    {Object.keys(mapTabs).map((id) => (
                        <li
                            key={id}
                            className={`nav-item ${flgClsReportTab === (id === "cls") ? "active" : ""}`}
                            onClick={() => handleTabClick(id)}
                        >
                            <a className="nav-link">{mapTabs[id]}</a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default ReportTabs;