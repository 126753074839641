import React, { useEffect, useState } from 'react';
import Header from 'common/js/header/header';
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css';

import { getSyllabusDetailsSvc } from './classExamSyllabusSvc';
import ExamSyllaubsPDF from './examSyllabusPDF';

const ExamSyllaubsSlider = (props) => {
    const { examId, classSectionId, className, setLoader, setOpacity, closeSlider } = props;

    const [headerSection, setHeaderSection] = useState({});
    const [lstExam, setLstExam] = useState([]);

    useEffect(() => {
        // // console.log("ExamSyllabusSlider called, examId", examId, ",classSectionId", classSectionId);
        getSyllabusDtls(examId, classSectionId)
    }, [examId, classSectionId]);

    const getSyllabusDtls = (pExamId, pSectionId) => {
        setLoader((prevLoader) => prevLoader + 1);
        setOpacity(0.5);
        getSyllabusDetailsSvc(pExamId, pSectionId, getSyllabusDtlsResponse);
    }

    const getSyllabusDtlsResponse = (data) => {
        // // console.log("getSyllabusDtlsResponse called, data", data);
        setLoader((prevLoader) => prevLoader - 1);
        setOpacity(1);
        let obj = data;
        if (obj.status === 'Success') {
            setHeaderSection(obj.headerSection);
            setLstExam(obj.lstExam);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
    }



    return (
        <div className="col-xs-12 col-centered padding-remove ">
            <ToastContainer autoClose={3000} hideProgressBar />
            <div>
                <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={() => closeSlider()} style={{ right: "60%" }}>
                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                </button>
                <div className='col-sm-12 padding-remove' style={{ margin: 0 }}>
                    {!!headerSection && lstExam && lstExam.length ?
                        <ExamSyllaubsPDF
                            headerSection={headerSection}
                            lstExam={lstExam}
                            className={className}
                        />
                        : null
                    }
                </div>
            </div>
        </div>
    );

}

export default ExamSyllaubsSlider;