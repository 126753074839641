import React from "react";

export default function Loader({ loader, position }) {
  return (
    <div
      style={{
        position: position,
        zIndex: 10000,
        width: 100,
        height: 100,
        marginLeft: -50,
        marginTop: -50,
        left: "50%",
        top: "50%",
        display: loader,
      }}
    >
      <img
        className="loderImg"
        src={require("common/images/loader.gif")}
        alt="Loader..."
      />
    </div>
  );
}
