import React from 'react'
//Please create AwardTableSvc.js, AwardFormSvc.js


const AwardSlider = () => {
    return (
        <div>AwardSlider</div>
    )
}

export default AwardSlider