import React, { useEffect, useState } from "react";
import "../css/login.css";
import "common/css/master.css";
import { ToastContainer, toast } from "react-toastify";

import { checkSetupCompletedSvc, getModuleSvc, loginSvc } from "./loginSvc";

import LastLoginData from "./lastLoginData";
import LoginForm from "./loginForm";

const Login = () => {

    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [email, setEmail] = useState(localStorage.getItem("email"));
    const [password, setPassword] = useState(null);
    const [keyLogin, setKeyLogin] = useState(0);
    const [lastLogin, setLastLogin] = useState(localStorage.getItem("lastLogin"));
    const [isRememberChecked, setIsRememberChecked] = useState(false);
    const [rememberedEmail, setRememberedEmail] = useState("");
    const [defaultPage, setDefaultPage] = useState("wings");
    const [emailList, setEmailList] = useState([]);
    const [setupCompleted, setSetupCompleted] = useState([]);


    // const [emails, setEmails] = useState([]);
    // const [emailsStore, setEmailsStore] = useState(localStorage.getItem("emailsStore"));
    // const [top3ScreensId, setTop3ScreensId] = useState([]);
    // const [loginData, setLoginData] = useState([]);

    useEffect(() => {
        // setEmails(emailsStore);
        // // console.log("localStorage", localStorage);
        if (localStorage.checkbox && localStorage.checkbox !== "") {
            setIsRememberChecked(true);
            setRememberedEmail(localStorage.username);
        } else {
            setIsRememberChecked(false);
            setRememberedEmail("");
        }
    }, []);

    const login = () => {
        // // console.log("login() called..");
        if (!email || email === "") {
            toast.warning("Please enter email Id");
            return false;
        } else if (email.trim() === "") {
            toast.warning("Please enter email Id");
            return false;
        }
        if (!password || password === "") {
            toast.warning("Please enter password");
            return false;
        } else if (password.trim() === "") {
            toast.warning("Please enter password");
            return false;
        }
        let loginArr = { email: email, password: password, loginAs: 30 };
        localStorage.setItem('checkbox', isRememberChecked);
        localStorage.setItem('username', isRememberChecked ? email : "");
        setLoader("block");
        setOpacity(0.5);
        loginSvc(loginArr, loginResponse);
    }

    const loginResponse = (data) => {
        // // console.log("loginResponse() called",JSON.stringify(data,null,2))
        let obj = data;
        // // console.log("loginResponse called, obj", obj);
        if (obj.status === 'Success') {
            // // console.log("obj.status is successful loginResponse called, obj", obj);
            // // console.log("obj.status is successful loginResponse called, obj.loginArr", obj.loginArr);
            toast.success(obj.message);
            if (!emailList.includes(email)) {
                let d = new Date();
                let month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                let date =
                    d.getDate() + " " + month[d.getMonth()] + ", " + d.getFullYear();
                let time = d.toLocaleTimeString().toLowerCase();
                setLastLogin(date + " at " + time);
                localStorage.setItem("email", email);
                localStorage.setItem("lastLogin", lastLogin);
                // // console.log("obj.loginArr.email",obj.loginArr.email);
                localStorage.setItem("emailsStore", obj.loginArr.email);
                // // console.log("localStorage after setting emailStor", localStorage);
                // // console.log("localStorage - emailStore after setting emailStor", localStorage.getItem("emailsStore"));
                let defaultSchoolId = obj.defaultSchoolId;
                let lstSchool = JSON.stringify(obj.lstSchool);
                localStorage.setItem("lstSchool", lstSchool);
                if (!chkIfExists("schoolId", defaultSchoolId)) {
                    if (lstSchool.length === 1) {
                        localStorage.setItem("schoolId", lstSchool[0].schoolId);
                        localStorage.setItem("roleId", lstSchool[0].primaryRoleId);
                    } else {
                        redirectTo("defaultSchoolSetup");
                    }
                } else {
                    let defaultRoleId = obj.defaultRoleId;
                    if (!chkIfExists("roleId", defaultRoleId)) {
                        redirectTo("defaultRoleSetup");
                    }

                    let defaultWingId = obj.defaultWingId;
                    if (!chkIfExists("wingId", defaultWingId)) {
                        redirectTo("wings");
                    }

                    let defaultAcdYrId = obj.defaultAcdYrId;
                    if (!chkIfExists("academicYearId", defaultAcdYrId)) {
                        localStorage.setItem(
                            "academicYear",
                            obj.academicYear
                        );
                        redirectTo("academicYear");
                    }

                    // chkSubModule(obj.tokenId);
                    getModule(obj.tokenId);
                    checkSetupCompleted(obj.tokenId);
                }
                localStorage.setItem("tokenid", obj.tokenId);
            }
            // getModule(obj.tokenId);
        }
        else {
            toast.error(obj.message);
        }
        setLoader("none");
        setOpacity(1);
    }


    const getModule = (pTokenId) => {
        // // console.log("getModule called in header.js");
        setLoader("block");
        setOpacity(0.5);
        getModuleSvc(pTokenId, getModuleResponse);
    }

    const getModuleResponse = (data) => {
        // // console.log("getModuleResponse called in header.js");
        var obj = data;
        if (obj.status === 'Success') {
            localStorage.setItem('module', obj.module);
        }
        else {
            toast.error(obj.message);
        }
        setLoader("none");
        setOpacity(1);
    }

    const checkSetupCompleted = (pTokenId) => {
        setLoader("block");
        setOpacity(0.5);
        checkSetupCompletedSvc(pTokenId, checkSetupCompletedResponse);
    }

    const checkSetupCompletedResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            setSetupCompleted(obj.setupCompleted);
            if (!obj.setupCompleted) {
                setDefaultPage("setup");
            }
            setTimeout(function () {
                window.location.href = "/" + defaultPage;
            }, 2000);
        }
        else {
            toast.error(obj.message);
        }
        setLoader("none");
        setOpacity(1);
    }

    // chkSubModule = (pTokenId) => {
    //     axios({
    //         method: "POST",
    //         url: httpURLSetup + "/sms/setup/sub_module",
    //         headers: { tokenid: pTokenId },
    //         withCredentials: true,
    //     })
    //         .then((response) => {
    //             if (response.data.SvcStatus === "Success") {
    //                 localStorage.setItem(
    //                     "subModule",
    //                     JSON.stringify(response.data.subModule)
    //                 );
    //             } else {
    //                 toast.error(response.data.SvcMsg);
    //             }
    //             this.setState({ loader: "none", opacity: 1 });
    //         })
    //         .catch((error) => {
    //             toast.error('Service failed ' + error);
    //         });
    // };

    const changeLoginKey = () => {
        setKeyLogin((prevKeyLogin) => prevKeyLogin + 1);
    }

    const chkIfExists = async (pKey, pVal) => {
        if (!pVal) {
            return false;
        } else {
            await localStorage.setItem(pKey, pVal);
            return true;
        }
    };

    const redirectTo = (page) => {
        setTimeout(function () {
            window.location.href = "/" + page;
        }, 2000);
    };

    return (
        <>
            <LastLoginData
                setEmail={setEmail}
                login={login}
                setLastLogin={setLastLogin}
                setLoader={setLoader}
                setOpacity={setOpacity}
            />
            <div className="wrapper-login">

                <ToastContainer autoClose={3000} hideProgressBar />
                <div
                    style={{
                        position: "fixed",
                        zIndex: 10000,
                        width: 100,
                        height: 100,
                        marginLeft: -50,
                        marginTop: -50,
                        left: "50%",
                        top: "50%",
                        display: loader,
                    }}
                >
                    <img
                        className="loderImg"
                        src={require("common/images/loader.gif")}
                        alt="Loader..."
                    />
                </div>
                <div className="col-xs-8">
                    <div className="col-xs-5 textWrap">
                        {/* <h3>Welcome To</h3>
                        <h1>SMS</h1>
                        <div>School Management System</div> */}
                        <img
                            className="loginImg"
                            style={{
                                width: 300,
                                height: "auto",
                                justifyContent: "center",
                                display: "flex",
                            }}
                            src={require("../images/bg.jpeg")}
                            alt="Background"
                        />
                    </div>
                    <div className="col-xs-7 imageWrap">
                        {/* <img className="loginImg" src={require("../images/background.png")} alt="Background" /> */}
                    </div>
                </div>
                <div className="formContent">
                    <div
                        className="form-wrapper"
                        style={{ opacity: opacity }}
                    >
                        <h3>Welcome To</h3>
                        {/* <h1>SMS</h1> */}
                        <h5 style={{ marginBottom: "10%" }}>School Management System</h5>
                        {/* <img className="logoImg" src={require("../images/Logo1.JPG")} alt="SMS" /> */}

                        <LoginForm
                            email={email}
                            setEmail={setEmail}
                            password={password}
                            setPassword={setPassword}
                            login={login}
                            isRememberChecked={isRememberChecked}
                            setIsRememberChecked={setIsRememberChecked}
                            setLoader={setLoader}
                            setOpacity={setOpacity}
                        />

                        <div className="copyright">
                            <a href="https://kmsgtech.com/" target="blank">
                                Powered By KMSG Technologies
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;