import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import { toast, ToastContainer } from 'react-toastify';
import $ from "jquery";
import axios from 'axios';
import "../css/fee.css";

var httpURL = '';
var queryString = require('querystring');
var screenId = 33;
var lstFrequency = [];

export default class FeeHeadsDiscountSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            feeHeadId: 0,
            discountSiblingAmt: 0,
            discountScholarAmt: 0,
            discountMeritMeansAmt: 0,
            discountScStAmt: 0,
            discountBackwardAmt: 0,
            tokenid: localStorage.getItem('tokenid')

        }
    }
    componentWillReceiveProps(props) {

    }
    componentDidMount() {
        this.setDiscountSlider();

    }
    resetForm = () => {
        this.setState({
            discountSiblingAmt: 0,
            discountScholarAmt: 0,
            discountMeritMeansAmt: 0,
            discountScStAmt: 0,
            discountBackwardAmt: 0,
            feeHeadId: 0
        })
    }

    resetSlider = () => {
        document.getElementById('feeHeadAddForm').reset();
        $('#feeHeadAddForm input').val('');
        $('#feeHeadAddForm input[type="checkbox"]').attr('checked', false);
    }

    componentDidUpdate = async (prevProps) => {
        // this.resetSlider();
        if (prevProps.feeHeadId !== this.props.feeHeadId) {
            if (this.props.feeHeadId !== 0) {
                await this.setDiscountSlider(this.props.flgAdd);
            }
        }


    }
    setDiscountSlider = async (flgAdd) => {
        if (!flgAdd) {
            var objData = this.props.feeHeadDiscount;


            await this.setState({
                feeHeadId: objData.feeHeadId,
                discountSiblingAmt: objData.discountSiblingAmt,
                discountScholarAmt: objData.discountScholarAmt,
                discountMeritMeansAmt: objData.discountMeritMeansAmt,
                discountScStAmt: objData.discountScStAmt,
                discountBackwardAmt: objData.discountBackwardAmt,
            })
        } else {
            this.resetForm();
        }
    }

    inputOnChange = async (e) => {
        e.preventDefault();

        await this.setState({
            [e.target.id]: e.target.value
        });

        var discountSiblingAmt = $('#discountSiblingAmt').val()
        $('#discountSiblingAmt').val(discountSiblingAmt.replace(/^0+/,))

        var discountScholarAmt = $('#discountScholarAmt').val()
        $('#discountScholarAmt').val(discountScholarAmt.replace(/^0+/,))


        var discountMeritMeansAmt = $('#discountMeritMeansAmt').val()
        $('#discountMeritMeansAmt').val(discountMeritMeansAmt.replace(/^0+/,))

        var discountScStAmt = $('#discountScStAmt').val()
        $('#discountScStAmt').val(discountScStAmt.replace(/^0+/,))


        var discountBackwardAmt = $('#discountBackwardAmt').val()
        $('#discountBackwardAmt').val(discountBackwardAmt.replace(/^0+/,))

    }




    handleSubmit = (e) => {
        e.preventDefault();
        var feeHeadId = this.props.feeHeadId;
        var discountSiblingAmt = this.state.discountSiblingAmt;
        var discountScholarAmt = this.state.discountScholarAmt;
        var discountMeritMeansAmt = this.state.discountMeritMeansAmt;
        var discountScStAmt = this.state.discountScStAmt;
        var discountBackwardAmt = this.state.discountBackwardAmt;

        var objToSave = {
            feeHeadId,
            discountSiblingAmt,
            discountScholarAmt,
            discountMeritMeansAmt,
            discountScStAmt,
            discountBackwardAmt,

        }
        this.props.saveDiscount(objToSave);

    }

    removeClassFromBody = () => {
        if (this.props.flgAdd) {
            this.resetForm();
        }
        this.props.removeClassFromBody();
    }

    render() {
        httpURL = lstModules['fees'].url + ":" + lstModules['fees'].port;
        return (
            <div className="col-md-12 col-xs-12 FeeHeadFormWrap" >
                <form id="feeHeadDiscountForm" className="form-horizontal formWrapper" onSubmit={this.handleSubmit}>

                    <h5>Discounts</h5>
                    <div className="row">
                        <div className="form-group">
                            <label className="control-label col-sm-3 " >Sibling Amount</label>
                            <div className="col-sm-2 padding-remove ">
                                <input autoComplete="off" id="discountSiblingAmt" type="number" min='0' className="text-right form-control "
                                    placeholder="Sibling Amount" onChange={this.inputOnChange}
                                    value={this.state.discountSiblingAmt || 0} />
                            </div>
                            <span className="col-sm-7 control-label" style={{ textAlign: 'left' }}>Applicable if the student has a sibling. Only applicable for the younger one only</span>                                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group">
                            <label className="control-label col-sm-3" >Scholar Amount</label>
                            <div className="col-sm-2 padding-remove">
                                <input autoComplete="off" id="discountScholarAmt" type="number" min='0' className="text-right form-control "
                                    placeholder="Scholar Amount" onChange={this.inputOnChange}
                                    value={this.state.discountScholarAmt || 0}
                                />
                            </div>
                            <span className="col-sm-7 control-label" style={{ textAlign: 'left' }}>Applicable if a student is marked as scholar category</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group">
                            <label className="control-label col-sm-3" >Merit-cum-means</label>
                            <div className="col-sm-2 padding-remove">
                                <input autoComplete="off" id="discountMeritMeansAmt" type="number" min='0' className="text-right form-control "
                                    placeholder="Merit-cum-means" onChange={this.inputOnChange}
                                    value={this.state.discountMeritMeansAmt || 0} />
                            </div>
                            <span className="col-sm-7 control-label" style={{ textAlign: 'left' }}>Applicable if a student is marked as Merit-cum-means category</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group">
                            <label className="control-label col-sm-3" >SC/ST</label>
                            <div className="col-sm-2 padding-remove">
                                <input autoComplete="off" id="discountScStAmt" type="number" min='0' className="text-right form-control "
                                    placeholder="SC/ST Amount" onChange={this.inputOnChange}
                                    value={this.state.discountScStAmt || 0} />
                            </div>
                            <span className="col-sm-7 control-label" style={{ textAlign: 'left' }}>Applicable if a student is marked as SC/ST category</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group">
                            <label className="control-label col-sm-3" >Backward</label>
                            <div className="col-sm-2 padding-remove">
                                <input autoComplete="off" id="discountBackwardAmt" type="number" min='0' className="text-right form-control "
                                    placeholder="backward Amount" onChange={this.inputOnChange}
                                    value={this.state.discountBackwardAmt || 0} />
                            </div>
                            <span className="col-sm-7 control-label" style={{ textAlign: 'left' }}>Applicable if a student is marked as backward class</span>
                        </div>
                    </div>
                    <div className="formFlex row">
                        <div className="form-group" >
                            <div className="col-sm-12 text-center userButtonWrap" >
                                <button type="submit" style={{ marginTop: 10 }} title="Save" className="btn btn-info">Save</button>
                                <button type="button" className="btn btn-info text-center " id="closeSlider" onClick={this.removeClassFromBody}>
                                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }


}









