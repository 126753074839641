import React, { useEffect, useState } from 'react';
import { Text, View, Font } from '@react-pdf/renderer';
import { StyleSheet } from '@react-pdf/renderer';

import { toast } from "react-toastify";
import { styleSchoScore, styleSummary } from './examReportCardStyles.js';

Font.register({
    family: 'Calibri'
    , fonts: [{ src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibri.ttf" }
        , { src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibrib.ttf", fontWeight: "bold" }], format: 'truetype'
});


const SummarySection = (props) => {
    const { examSchoSummary } = props;




    return (
        <View style={styleSchoScore.container}>
            <View style={styleSchoScore.score_table}>
                <View style={[styleSchoScore.exam_row, { height: "30px" }]}>
                    <View style={styleSummary.subject_col}>
                        <View style={[ { flex: 3 }]}>
                            <Text style={[styleSchoScore.text_schoExam_label, styleSchoScore.text_center]}>
                                OVERALL
                            </Text>
                        </View>
                        <View style={[styleSummary.sectionCell, { flex: 2 }]}>
                            <Text style={[styleSchoScore.text_schoExam_label, styleSchoScore.text_center]}>
                                {examSchoSummary.totalObtained + " / " + examSchoSummary.totalMax}
                            </Text>
                        </View>
                    </View>
                    <View style={styleSummary.subject_col}>
                        <View style={[ { flex: 3 }]}>
                            <Text style={[styleSchoScore.text_schoExam_label, styleSchoScore.text_center]}>
                                PERCENTAGE
                            </Text>
                        </View>
                        <View style={[styleSummary.sectionCell, { flex: 2 }]}>
                            <Text style={[styleSchoScore.text_schoExam_label, styleSchoScore.text_center]}>
                                {examSchoSummary.pct} %
                            </Text>
                        </View>
                    </View>
                    <View style={styleSummary.subject_col}>
                        <View style={[ { flex: 3 }]}>
                            <Text style={[styleSchoScore.text_schoExam_label, styleSchoScore.text_center]}>
                                GRADE
                            </Text>
                        </View>
                        <View style={[styleSummary.sectionCell, { flex: 2 }]}>
                            <Text style={[styleSchoScore.text_schoExam_label, styleSchoScore.text_center]}>
                                {examSchoSummary.grade}
                            </Text>
                        </View>
                    </View>
                </View>
            </View>
        </View >
    );
}

export default SummarySection;
