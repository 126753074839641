import React from "react";
import "./DiarySlider.css";

const diarySlider = () => {
  return (
    <div className="container">
      <div className="row">
        <label className="col-sm-2 Label">Class</label>
        <div className="form-group col-sm-2">
          <select className="dropdown">
            <option value="Class">Class</option>
            <option value='I'>I</option>
            <option value='II'>II</option>
            <option value='III'>III</option>
            <option value='IV'>IV</option>
            <option value='V'>V</option>
            <option value='VI'>VI</option>
          </select>
        </div>
      </div>
      <div className="row">
        <label className="col-sm-2 Label">Section</label>
        <div className="form-group col-sm-2">
          <select className="dropdown">
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="D">D</option>
            
          </select>
        </div>
      </div>

      <div className="row">
        <label className="col-sm-2 Label">Subject</label>
        <div className="form-group col-sm-2">
          <select className="dropdown">
            <option value="Marks">Subject</option>
          </select>
        </div>
      </div>
      <div className="row">
        <label className="col-sm-2 Label">Topic</label>
        <div className="form-group col-sm-2">
          <input type="text" className="form-control"></input>
        </div>
      </div>
      <div className="row">
        <label className="col-sm-2 Label">Coverage</label>
        <div className="form-group col-sm-2">
          <input type="text" className="form-control"></input>
        </div>
      </div>
      <div className="row">
        <label className="col-sm-2 Label">Diary</label>
        <div className="form-group col-sm-8">
          <textarea
            rows="4"
            style={{ resize: "noun" }}
            className="form-control"
          />
        </div>
      </div>
      <div className="row">
        <label className="col-sm-2 Label">Due Date</label>
        <div className="form-group col-sm-2">
          <input type="text" className="form-control"></input>
        </div>
      </div>

      <button className="btnSave">Save</button>
    </div>
  );
};

export default diarySlider;
