import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

var screenId = 8;
var tokenid = localStorage.getItem("tokenid");
var httpURLPerformance = lstModules['performance'].url + ":" + lstModules['performance'].port;

function getStudentScoreSvc(examId, sectionId, syllabusId, cb) {
    // // console.log("getStudentScoreSvc called, examId", examId, ", sectionId", sectionId,", syllabusId", syllabusId );
    axios({
        url: httpURLPerformance + "/sms/perf/evaluate/list_student",
        method: "POST",
        params: { examId: examId, sectionId: sectionId, syllabusId: syllabusId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // // console.log("service url", httpURLPerformance + "/sms/perf/evaluate/list_student", "response in getStudentScoreSvc called", response.data);
        // // console.log('data.SvcStatus=' + response.data.SvcStatus);
        cb(response.data);
    }).catch((error) => {
        cb(error);
    });
}

function saveStudentScoreSvc(req, cb) {
    var obj = { status: "Success" }
    var formData = new FormData();
    var scoreObj = {
        examId: req.examId
        , secId: req.sectionId
        , subId:req.subId
        ,examSectionSubId:req.examSectionSubId
        , lstScore: req.lstScore
    }
    formData.append("saveScoreRQ", JSON.stringify(scoreObj));
 
   axios({
        url: httpURLPerformance + "/sms/perf/evaluate/save_score",
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {
        var data = response.data
        obj.status = data.SvcStatus;
        obj.message = data.SvcMsg;
        cb(obj);
    }).catch((error) => {
        // // console.log("Error Occured")
        obj.status = 'Exception';
        obj.message = 'Service failed ' + error;
        cb(obj)
    });
}
function getSubjectSvc(callback2, pExamId, pSectionId, pSyllabusId) {
    // // console.log("getSubjectSvc called, pExamId", pExamId, ", pSectionId", pSectionId, ", pSyllabusId", pSyllabusId);
    axios({
        url: httpURLPerformance + "/sms/perf/evaluate/list_subject",
        method: "POST",
        params: {  examId: pExamId, sectionId:pSectionId, syllabusId: pSyllabusId},
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // // console.log("response in getSubjectSvc", response);
        // // console.log('data.SvcStatus=' + response.data.SvcStatus);
        callback2(response.data);
    }).catch((error) => {
        callback2(error);
    });
}

export { getStudentScoreSvc,saveStudentScoreSvc,getSubjectSvc };