import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getPayrollMonthProcessListSvc } from "./PayrollMonthProcessSvc";
const PayrollMonthProcessTable = (props) => {
  const [lstPayroll, setLstPayroll] = useState(props.lstPayroll);
  const [loader, setLoader] = useState("none");
  const [selPayrollId, setSelPayrollId] = useState();
  useEffect(() => {
    //getPayrollMonthProcessListSvc(cbPayrollMonthProcessList)
    setLstPayroll(props.lstPayroll);
  }, [props]);
  function cbPayrollMonthProcessList(data) {
    if (data.SvcStatus === "Success") {
      setLstPayroll(data.lstPayrollMonth);
      // // console.log("Payroll List::"+JSON.stringify(data.lstPayroll))
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function editPayroll(payrollProcessId) {
    props.setPayrollforEdit(payrollProcessId);
    props.openSlider();
  }
  function handleSelPayrollId(param) {
    setSelPayrollId(param);
  }
  const goToPayrollView = async (pYrMonth) => {
    // await localStorage.setItem("yrMonth", pYrMonth)
    let newUrl = "/payrollSlip?yrMonth=" + pYrMonth;
    window.open(newUrl, "_blank");
  };
  const colgrp = (
    <colgroup>
      <col width="5%"></col>
      <col width="5%"></col>
      <col width="5%"></col>
      <col width="10%"></col>
      <col width="5%"></col>
      <col width="5%"></col>
    </colgroup>
  );
  return (
    <div style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>
      <table className="tableLayout1">
        {colgrp}
        <thead>
          <tr>
            <th>Month</th>
            <th>Working Days</th>
            <th>Employees</th>
            <th>Processed On</th>
            <th>Mode</th>
            <th>Action</th>
          </tr>
        </thead>
      </table>
      <div className="tablebodyWrapper">
        <table className="tableLayout">
          {colgrp}
          <tbody>
            {lstPayroll.map((payroll, idx) => {
              return (
                <tr
                  onClick={(e) => handleSelPayrollId(payroll.payrollProcessId)}
                  className={
                    payroll.payrollProcessId === selPayrollId ? "selected" : ""
                  }
                  key={idx}
                >
                  <td>{payroll.yrMonth}</td>
                  <td>{payroll.monthDays}</td>
                  <td>{payroll.staffCount}</td>
                  <td>{payroll.processedOn}</td>
                  <td>{payroll.mode}</td>
                  <td className="text-center">
                    <span
                      className="glyphicon glyphicon-film"
                      onClick={(e) => goToPayrollView(payroll.yrMonth)}
                    >
                      <a href="/payrollSlip" target="_blank"></a>
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default PayrollMonthProcessTable;
