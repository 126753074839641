
import React, { useEffect, useState } from 'react';
import { Document, Page, PDFViewer, View, Font } from '@react-pdf/renderer';

import { pageStyle } from './termReportPDFStyle';
import { HeaderLogoSection, HeaderAddressSection, HeaderNameSection } from "./termReportHeader.js";
import TermReportStudentSection from './termReportStudent';
import TermReportScore from './termReportScore';
import FooterSection from './termReportFooter';


const TermReportPdf = (props) => {
    const [headerName, setHeaderName] = useState("");
    const [reportHeader, setReportHeader] = useState({});
    const [clsSectionDtls, setClsSectionDtls] = useState({});
    const [lstStudent, setLstStudent] = useState([]);
    const [principalSign, setPrincipalSign] = useState('');
    const [teacherSign, setTeacherSign] = useState('');
    const [lstTermExamHdr, setLstTermExamHdr] = useState([]);

    useEffect(() => {
        // // console.log("TermReportPDF called");
        // // console.log("props.termReportCard", props.termReportCard);
        // // console.log("props.termReportCard.headerName", props.termReportCard.headerName);
        // // console.log("props.termReportCard.reportHeader", props.termReportCard.reportHeader);
        // // console.log("props.termReportCard.clsSectionDtls", props.termReportCard.clsSectionDtls);
        // // console.log("props.termReportCard.lstStudent", props.termReportCard.lstStudent);
        if (props.termReportCard) {
            setHeaderName(props.termReportCard.headerName ? props.termReportCard.headerName : null);
            setReportHeader(props.termReportCard.reportHeader ? props.termReportCard.reportHeader : null);
            setClsSectionDtls(props.termReportCard.clsSectionDtls ? props.termReportCard.clsSectionDtls : null);
            setLstStudent(props.termReportCard.lstStudent ? props.termReportCard.lstStudent : null);
            setTeacherSign(props.termReportCard.clsSectionDtls && props.termReportCard.clsSectionDtls.signClassTeacher ? props.termReportCard.clsSectionDtls.signClassTeacher : null);
        }
        setLstTermExamHdr(props.termReportCard.lstTermExamHdr);
        setPrincipalSign(props.principalSign ? props.principalSign : "")
    }, [props.termReportCard, props.logoUrl, props.principalSign]);

    const pages = [];

    if (!!lstStudent) {
        // // console.log("inside condition !!lstStudent");
        for (let i = 0; i < lstStudent.length; i++) {
            pages.push(
                <Page
                    key={lstStudent[i].studentHeader.studentId}
                    size="A4" style={pageStyle.page}>
                    <View style={pageStyle.page_border}>

                        <View style={pageStyle.header_section}>
                            <View style={pageStyle.header_logo}>
                                <HeaderLogoSection
                                    logoUrl={props.logoUrl}
                                    reportHeader={reportHeader}
                                />
                            </View>
                            <View style={pageStyle.header_address}>
                                <HeaderAddressSection
                                    reportHeader={reportHeader}
                                />
                            </View>
                            <View style={pageStyle.header_name}>
                                <HeaderNameSection
                                    // headerName={headerName}
                                    headerName={"Performance Profile " + props.term}
                                />
                            </View>
                        </View>
                        <View style={pageStyle.student_section}>
                            <TermReportStudentSection
                                studentHeader={lstStudent[i].studentHeader}
                                clsSectionDtls={clsSectionDtls}
                            />
                        </View>
                        <View style={pageStyle.score_section}>
                            <TermReportScore
                                studentSummary={lstStudent[i].studentSummary}
                                lstScholastic={lstStudent[i].lstScholastic}
                                lstCoscholastic={lstStudent[i].lstCoscholastic}
                                lstExam={lstTermExamHdr}
                                term={props.term}
                            />
                        </View>
                        <View style={pageStyle.footer_section}>
                            <FooterSection
                                principalSign={principalSign}
                                teacherSign={teacherSign}
                            />
                        </View>
                    </View>
                </Page>
            );
        }
    }

    return (
        <div style={{ width: '98%', height: "98vh", margin: "1vh 1%" }} >
            <PDFViewer style={{ width: "100%", height: "100%", overflowY: "scroll" }}>
                <Document>{pages}</Document>
            </PDFViewer>
        </div>
    );

}

export default TermReportPdf;