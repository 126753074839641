import axios from 'axios';

import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['main'].url + ':' + lstModules['main'].port;
var tokenid = localStorage.getItem("tokenid");


export const getDiaryMonthlyService = (monthId, callback) => {
    // // console.log("getDiaryMonthlyService called, monthId", monthId);
    var month = { status: "Success", message: "" }
    const formData = new FormData();
    formData.append("calMthId", monthId)
    axios({
        url: httpURL + '/sms/diary/report/monthly_summary',
        method: "POST",
        headers: { 'tokenid': tokenid },
        data:formData,
        withCredentials: true,
    }).then((response) => {
        // // console.log('data.SvcStatus=' + response.data.SvcStatus);
        month.status = response.data.SvcStatus;
        month.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            month.lstSectionSummary = response.data.lstMonthlySummary;
        }
        callback(month);
    }).catch((error) => {
        month.status = "Exception";
        month.message = error;
        month.lstIdCard = [];
        callback(month);
    });
}