import { fn } from 'jquery';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getStudentEligibleList, assignClsSec } from './classService';

const AddClassStudent = (props) => {
    const { classId, className, section, sectionId, setLoader, setOpacity, closeSlider } = props;

    const [lstEligibleStudents, setLstEligibleStudents] = useState([]);
    const [eligibleStudentTable, setEligibleStudentTable] = useState([]);

    const [rollNoFiltered, setRollNoFiltered] = useState("");
    const [nameFiltered, setNameFiltered] = useState("");
    const [classFiltered, setClassFiltered] = useState("");
    const [regNoFiltered, setRegNoFiltered] = useState("");

    useEffect(() => {
        setStudentEligibleList();
    }, []);

    const setStudentEligibleList = () => {
        setLoader("block");
        setOpacity(0.5);
        getStudentEligibleList(classId, sectionId, getStudentEligibleListResponse);
    }

    const getStudentEligibleListResponse = (data) => {
        let obj = data;
        if (obj.status === 'Success') {
            setLstEligibleStudents(obj.lstEligibleStudents);
            setEligibleStudentTable(obj.lstEligibleStudents);
        } else if (obj.status === 'Failure') {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error("Service failed " + obj);
        }
        setLoader("none");
        setOpacity(1);
    }

    const assignClassSection = (sId, sName) => {
        confirmAssign(className, section, sId, sName)

    }

    const confirmAssign = (cls, sec, sId, sName) => {
        var retVal = window.confirm("Do you want to Assign Class Section ", cls, "-", sec, " to ", sName, " ?");
        if (retVal == true) {
            setLoader("block");
            setOpacity(0.5);
            let studentClassSection = { studentId: sId, classId, section, sectionId };
            assignClsSec(studentClassSection, handleClsSecResponse)
            return true;
        } else {
            return false;
        }
    }

    const handleClsSecResponse = (data) => {
        setLoader("none");
        setOpacity(1);
        if (data.status === 'Success') {
            toast.success(data.message);
            props.closeSlider()
        } else if (data.status === 'Failure') {
            if (data.message === 'You are not logged in') {
                toast.error(data.message)
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (data.message === 'Your Session is expired, login again') {
                toast.error(data.message)
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(data.message)
            }
        } else {
            toast.error("Service failed " + data.error);
        }
    }

    const handleChangeRollNoFiltered = (fRoll) => {
        setRollNoFiltered(fRoll);
        filterStudentsTable(nameFiltered, classFiltered, fRoll, regNoFiltered);
    }

    const handleChangeNameFiltered = (fName) => {
        setNameFiltered(fName);
        filterStudentsTable(fName, classFiltered, rollNoFiltered, regNoFiltered);
    }

    const handleChangeRegNoFiltered = (fRegNo) => {
        setRegNoFiltered(fRegNo);
        filterStudentsTable(nameFiltered, classFiltered, rollNoFiltered, fRegNo);
    }

    const handleChangeClassFiltered = (fClass) => {
        setClassFiltered(fClass);
        filterStudentsTable(nameFiltered, fClass, rollNoFiltered, regNoFiltered);
    }

    const filterStudentsTable = (fName, fClass, fRoll, fRegNo) => {
        let filterEligibleStudentList = [];
        filterEligibleStudentList = lstEligibleStudents.filter(student =>
            student.name.toString().toLowerCase().includes(fName.toLowerCase()));

        filterEligibleStudentList = filterEligibleStudentList.filter(student =>
            student.className.toString().toLowerCase().includes(fClass.toLowerCase()));

        filterEligibleStudentList = filterEligibleStudentList.filter(student =>
            student.rollNo.toString().toLowerCase().includes(fRoll.toLowerCase()));

        filterEligibleStudentList = filterEligibleStudentList.filter(student =>
            student.regsNo.toString().toLowerCase().includes(fRegNo.toLowerCase()));

        setEligibleStudentTable(filterEligibleStudentList);
    }

    return (
        <>
            <div className="col-md-11  col-xs-11 col-centered studentTableWrap  padding-remove" >
                <div className='col-md-12 padding-remove'>
                    <label className=" col-sm-12 text-left padding-remove " style={{ fontWeight: 400,}}  >Add student to {className}-{section}</label>
                </div>
                <table className="tableLayout1 studentTable" style={{ width: "" }}>
                    <thead>
                        <tr>
                            <th style={{ display: 'none' }}> Student Id </th>
                            <th style={{ width: 40 }}>
                                <input type="text" value={regNoFiltered} onChange={(e) => handleChangeRegNoFiltered(e.target.value)} className="form-control inp-search" placeholder="Regs. No." />
                            </th>
                            <th style={{ width: 40 }}>
                                <input type="text" value={nameFiltered} onChange={(e) => handleChangeNameFiltered(e.target.value)} className="form-control inp-search" placeholder="Name" />
                            </th>
                            <th style={{ width: 40 }}>
                                <input type="text" value={classFiltered} onChange={(e) => handleChangeClassFiltered(e.target.value)} className="form-control inp-search" placeholder="Class" />
                            </th>
                            <th style={{ width: 40 }}> Section</th>
                            <th style={{ width: 40 }}>
                                <input type="text" value={rollNoFiltered} onChange={(e) => handleChangeRollNoFiltered(e.target.value)} className="form-control inp-search" placeholder="Roll No." />
                            </th>

                        </tr>
                    </thead>
                </table>
                <div className="studentTableDiv" style={{ height: '300px', overflowY: 'scroll', border: '1px solid #ccc' }} >
                    <table id="studentTable" className="tableLayout">
                        <tbody id="studentTableBody">
                            {eligibleStudentTable && eligibleStudentTable.length > 0 &&
                                eligibleStudentTable.map((student) => (
                                    <tr key={student.studentId}
                                        onClick={() => assignClassSection(student.studentId, student.name)} >
                                        <td className='text-center' style={{ width: 40 }}>{student.regsNo}</td>
                                        <td className='text-left' style={{ width: 40 }}>{student.name}</td>
                                        <td className='text-center' style={{ width: 40 }}>{student.className}</td>
                                        <td className='text-center' style={{ width: 40 }}>{student.section}</td>
                                        <td className='text-center' style={{ width: 40 }}>{student.rollNo}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={closeSlider} style={{ right: "70%" }}>
                <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
            </button>
        </>
    );
}

export default AddClassStudent;