import styled from "styled-components";

export const Conatiner = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
    font-size: 60px;
`


export const Radio = styled.input`
   display: none;
`
export const Rating = styled.div`
   cursor: pointer;
`