import React, { useState, useEffect } from "react";
import 'common/css/master.css';
import './css/validateSummary.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getLstExamSvc, getLstStudentScoreSvc, getValidationDtlsSvc, saveValidationDtlsSvc } from "./validateSummarySvc";

const ValidateTable = (props) => {

    const [lstStudentScore, setLstStudentScore] = useState([]);
    const [lstStudentScoreFiltered, setLstStudentScoreFiltered] = useState([]);
    const [lstExam, setLstExam] = useState([]);
    const [totalMaxMarks, setTotalMaxMarks] = useState(0);
    const [lstStudentRank, setLstStudentRank] = useState([]);
    const [studentId, setStudentId] = useState(0);
    const [flgSortMarks, setFlgSortMarks] = useState(0);
    const [filterStudent, setFilterStudent] = useState("");
    const [validationDetailsMap, setValidationDetailsMap] = useState({});
    const [validationBtnLoader, setValidationBtnLoader] = useState("none")

    useEffect(() => {
        // // console.log("validateSummaryTbl.js called");
        getLstExam();
    }, [props.sectionId, props.syllabusId]);

    const getLstExam = () => {
        // // console.log("getLstExam called");
        props.setLoader("block");
        props.setOpacity(0.5);
        getLstExamSvc(lstExamResponse);
    }

    const lstExamResponse = async (data) => {
        // // console.log("lstExamResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            await setLstExam(obj.lstExam);
            getValidationDtls(obj.lstExam);
            getLstStudentScore();
        }
        else {
            handleSessionErrors(obj.message);
        }
        // props.setLoader('none');
        // props.setOpacity(1);
    }

    const getLstStudentScore = () => {
        // // console.log("getLstStudentScore called, sectionId", sectionId);
        props.setLoader("block");
        props.setOpacity(0.5);
        if (props.sectionId) {
            getLstStudentScoreSvc(props.sectionId, props.syllabusId, lstStudentScoreResponse);
        }
    }

    const lstStudentScoreResponse = (data) => {
        // // console.log("lstStudentScoreResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            // // console.log("obj.lstStudentScore", obj.lstStudentScore);
            let tempLstStudentScore = calculateLstStudentScoreSummary(obj.lstStudentScore);
            // // console.log("tmpLstStudentScore get from updateLstStudnetScore in lstStudentResponse", tempLstStudentScore);
            setLstStudentScore(tempLstStudentScore);
            setLstStudentScoreFiltered(tempLstStudentScore);
        }
        else {
            handleSessionErrors(obj.message);
        }
        props.setLoader('none');
        props.setOpacity(1);
    }

    const getValidationDtls = (nLstExams) => {
        // // console.log("getValidationDtls called, nLstExams", nLstExams);
        // // console.log("props.sectionId", props.sectionId);
        for (let i = 0; i < nLstExams.length; i++) {
            props.setLoader("block");
            props.setOpacity(0.5);
            if (props.sectionId && nLstExams[i].examId) {
                getValidationDtlsSvc(props.sectionId, nLstExams[i].examId, validationDtlsResponse);
            }
        }
    }

    const validationDtlsResponse = (data) => {
        // // console.log("validationDtlsResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            // // console.log("obj.examScoreValidationDtls", obj.examScoreValidationDtls);
            setValidationDetailsMap(prevState => ({
                ...prevState,
                [obj.examId]: {
                    "validatedById": obj.examScoreValidationDtls.validatedById,
                    "validationDt": obj.examScoreValidationDtls.validationDt,
                    "validatedBy": obj.examScoreValidationDtls.validatedBy
                }
            }));
        }
        else {
            handleSessionErrors(obj.message);
        }
        props.setLoader('none');
        props.setOpacity(1);
    }

    const saveValidationDtls = (pExamId, e) => {
        e.preventDefault();
        // // console.log("saveValidationDtls called, pExamId", pExamId);
        // // console.log("props.sectionId", props.sectionId);
        props.setLoader("block");
        props.setOpacity(0.5);
        if (props.sectionId) {
            saveValidationDtlsSvc(props.sectionId, pExamId, saveValidationResponse);
        }
    }

    const saveValidationResponse = (data) => {
        // // console.log("saveValidationResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
        }
        else {
            handleSessionErrors(obj.message);
        }
        props.setLoader('none');
        props.setOpacity(1);
    }

    const handleSessionErrors = (message) => {
        if (message === 'You are not logged in') {
            toast.error('You are not logged in. Redirecting to login page...');
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
            return true;
        } else if (message === 'Your Session is expired, login again') {
            toast.error('Your session has expired. Redirecting to login page...');
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
            return true;
        } else {
            toast.error(message);
            return false;
        }
    };

    const calculateLstStudentScoreSummary = (studentScoreList) => {
        // // console.log("calculateLstStudentScoreSummary called");
        let tempLstStudentScore = studentScoreList;
        if (tempLstStudentScore && tempLstStudentScore.length) {
            let maxMarks = 0;
            if (lstExam) {
                lstExam.forEach((exam, idx) => {
                    maxMarks += exam.maxScore * tempLstStudentScore[0].lstExam[idx].countSubjects;
                })
            }
            setTotalMaxMarks(maxMarks);
            tempLstStudentScore.forEach((student) => {
                student.total = 0;
                student.percent = 0;
                student.grade = "F";
                if (student.lstExam && student.lstExam.length) {
                    student.lstExam.forEach((marks) => {
                        student.total += marks.scoreObtained;
                    })
                }
                student.percent = maxMarks ? Math.round((student.total * 100) / maxMarks) : 0;
                student.grade = calcGrade(student.percent);
            });

            let newArray = tempLstStudentScore.map(student => ({
                id: student.studentId,
                marks: student.total
            }));
            newArray.sort((a, b) => b.marks - a.marks);
            let rank = 1;
            let prevMarks = newArray[0].marks;
            newArray[0].rank = 1;
            for (let i = 1; i < newArray.length; i++) {
                if (newArray[i].marks === prevMarks) {
                    newArray[i].rank = rank;
                } else {
                    rank = i + 1;
                    newArray[i].rank = rank;
                }
                prevMarks = newArray[i].marks;
            }
            setLstStudentRank(newArray);
        }
        return tempLstStudentScore;
    }

    function calcGrade(grade) {
        grade = isNaN(grade) ? 0 : parseInt(grade);
        var strGrade = 'D';
        switch (true) {
            case grade > 90:
                strGrade = "A1";
                break;
            case grade > 80:
                strGrade = "A2";
                break;
            case grade > 70:
                strGrade = "B1";
                break;
            case grade > 60:
                strGrade = "B2";
                break;
            case grade > 50:
                strGrade = "C1";
                break;
            case grade > 40:
                strGrade = "C2";
                break;
            case grade > 30:
                strGrade = "D";
                break;
            default:
                strGrade = "F";
        }
        return strGrade;

    }
    function getRankById(id) {
        // // console.log("getRankById called", id);
        const student = lstStudentRank.find(student => student.id === id);
        return student ? student.rank : "-";
    }

    const toggleMarksSorting = (flgSort) => {
        // // console.log("toggleMarkSorting called flgSort", flgSort);
        // // console.log("flgSortMarks before updating", flgSortMarks);
        setFlgSortMarks(flgSort);
        if (flgSort === 0) {
            sortByNamesAscending();
        }
        if (flgSort === 1) {
            sortByNamesDescending();
        }
        if (flgSort === 2) {
            sortMarksDescending();
        }
        if (flgSort === 3) {
            sortMarksAscending();
        }
        if (flgSort === 5) {
            sortByRollNoAscending();
        }
        if (flgSort === 6) {
            sortByRollNoDescending();
        }
    }

    const sortMarksAscending = () => {
        // // console.log("sortMarkAscending called, lstStudentScoreFiltered", lstStudentScoreFiltered);
        setLstStudentScoreFiltered(lstStudentScoreFiltered.sort((a, b) => parseInt(a.total) - parseInt(b.total)));
    }

    const sortMarksDescending = () => {
        // // console.log("sortMarkDescending called, lstStudentScoreFiltered", lstStudentScoreFiltered);
        setLstStudentScoreFiltered(lstStudentScoreFiltered.sort((a, b) => parseInt(b.total) - parseInt(a.total)));
    }

    const sortByRollNoAscending = () => {
        // // console.log("sortMarkAscending called, lstStudentScoreFiltered", lstStudentScoreFiltered);
        setLstStudentScoreFiltered(lstStudentScoreFiltered.sort((a, b) => parseInt(a.rollNo) - parseInt(b.rollNo)));
    }

    const sortByRollNoDescending = () => {
        // // console.log("sortMarkDescending called, lstStudentScoreFiltered", lstStudentScoreFiltered);
        setLstStudentScoreFiltered(lstStudentScoreFiltered.sort((a, b) => parseInt(b.rollNo) - parseInt(a.rollNo)));
    }

    const sortByNamesAscending = () => {
        // // console.log("sortByNameAscending called, lstStudentScoreFiltered", lstStudentScoreFiltered);
        setLstStudentScoreFiltered(lstStudentScoreFiltered.sort((a, b) => a.student.localeCompare(b.student)));
    }

    const sortByNamesDescending = () => {
        // // console.log("sortByNamesDescending called, lstStudentScoreFiltered", lstStudentScoreFiltered);
        setLstStudentScoreFiltered(lstStudentScoreFiltered.sort((a, b) => b.student.localeCompare(a.student)));
    }

    const handleFilterName = (fName) => {
        // // console.log("handleFilterName called, fName", fName);
        setFilterStudent(fName);
        filterStudentScoreTable(fName);
    }

    const filterStudentScoreTable = (fName) => {
        // // console.log("filterGroupTable called, fGroup", fGroup);
        let filteredStudents = [];
        filteredStudents = lstStudentScore.filter(student =>
            student.student.toLowerCase().includes(fName.toLowerCase()));
        setLstStudentScoreFiltered(filteredStudents);
    }

    const colgrp = <colgroup>
        <col width="10%"></col>
        <col width="5%"></col>
        <col width="7%"></col>
        <col width="10%"></col>
        {lstExam.map((obj, idx) => { return <col key={idx} width={`${(100 - 50) / lstExam.length}%`}></col> })}
        <col width="5%"></col>//total//%//grade//rank
        <col width="5%"></col>
        <col width="4%"></col>
        <col width="4%"></col>
    </colgroup>



    return (
        <div className="col-md-12" style={{ marginTop: "5px" }}>
            <table id="validateSummary" className="tableLayout1" >
                {colgrp}
                <thead>
                    <tr>
                        <th className="text-center " colSpan={"4"} style={{ width: "32%" }}>
                            Student Details
                        </th>
                        {lstExam && lstExam.length ?
                            <th className='text-center ' colSpan={lstExam.length} style={{ width: "50%" }}>
                                Exams
                            </th>
                            : null
                        }
                        <th className='text-center ' colSpan={"4"} style={{ width: "18%" }}>
                            Summary
                        </th>
                    </tr>
                    <tr >
                        <th className="text-center" style={{ position: 'relative' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="text"
                                    className="form-control inp-search text-center"
                                    value={filterStudent}
                                    onChange={(e) => handleFilterName(e.target.value)}
                                    name="Student Name"
                                    placeholder="Student"
                                    style={{ flex: '1' }} // Allow input to take remaining space
                                />
                                {flgSortMarks === 1 ? (
                                    <span
                                        title="edit"
                                        className="glyphicon glyphicon-triangle-top icon-btn"
                                        onClick={() => toggleMarksSorting(0)}
                                    ></span>
                                ) : (
                                    <span
                                        title="edit"
                                        className="glyphicon glyphicon-triangle-bottom icon-btn"
                                        onClick={() => toggleMarksSorting(1)}
                                    ></span>
                                )}
                            </div>
                        </th>

                        <th className='text-center' >
                            {"Roll No. "}
                            {flgSortMarks === 6 ?
                                <span title="edit" className="glyphicon glyphicon-triangle-top"
                                    onClick={() => toggleMarksSorting(5)}></span>
                                :
                                <span title="edit" className="glyphicon glyphicon-triangle-bottom"
                                    onClick={() => toggleMarksSorting(6)}></span>
                            }
                        </th>
                        <th className='text-center' >
                            Enrollment No.
                        </th>
                        <th className='text-center' >
                            Father
                        </th>


                        {lstExam && lstExam.length ?
                            lstExam.map((exam) =>
                                <th className='text-center' >
                                    {exam.exam}
                                </th>
                            )
                            : null
                        }
                        <th className="text-center " >
                            {"Total "}
                            {flgSortMarks === 3 ?
                                <span title="edit" className="glyphicon glyphicon-triangle-bottom"
                                    onClick={() => toggleMarksSorting(2)}></span>
                                :
                                <span title="edit" className="glyphicon glyphicon-triangle-top"
                                    onClick={() => toggleMarksSorting(3)}></span>
                            }
                        </th>
                        <th className='text-center ' >
                            %
                        </th>
                        <th className='text-center ' >
                            Grade
                        </th>
                        <th className='text-center ' >
                            Rank
                        </th>

                    </tr>
                </thead>
            </table>



            <div className="tableDiv tableBodyBorder" style={{ height: "400px" }}>
                <table className='tableLayout'>
                    {colgrp}
                    <tbody id="validateSummaryTbl">
                        {
                            lstStudentScoreFiltered && lstStudentScoreFiltered.length ?
                                lstStudentScoreFiltered.map((studentScore) => (
                                    <tr key={studentScore.studentId}
                                        onClick={(e) => setStudentId(studentScore.studentId)}
                                        className={studentScore.studentId === studentId ? "selected" : ""}>
                                        <td className='text-left'>{studentScore.student}</td>
                                        <td className='text-center'>{studentScore.rollNo}</td>
                                        <td className='text-center'>{studentScore.enrollmentNo}</td>
                                        <td className='text-left'>{studentScore.father}</td>
                                        <>
                                            {
                                                studentScore.lstExam && studentScore.lstExam.length ?
                                                    studentScore.lstExam.map((marks, idx) =>
                                                        <td className='text-center' style={{ width: 20 }}>
                                                            {marks.scoreObtained}
                                                        </td>
                                                    )
                                                    :
                                                    lstExam && lstExam.length ?
                                                        lstExam.map((exam) =>
                                                            <td className='text-center' >
                                                            </td>
                                                        )
                                                        : null
                                            }
                                        </>
                                        <td className='text-center'>{studentScore.total}</td>
                                        <td className='text-center'>{studentScore.percent + "%"}</td>
                                        <td className='text-center'>{studentScore.grade}</td>
                                        <td className='text-center'>{studentScore.grade !== "F" ? getRankById(studentScore.studentId) : "-"}</td>
                                    </tr>
                                ))
                                : null
                        }
                    </tbody>
                </table>
            </div>
            <div className="col-sm-12 form-group " style={{ padding: "0 17px 0 0", display: "flex", flexDirection: "row", pointerEvents: validationBtnLoader === "none" ? "auto" : "none" }} >
                <div style={{ width: "32%", height: 50, display: 'table' }}></div>
                <div style={{ width: "50%", display: 'table' }} >
                    {Object.entries(validationDetailsMap).map(([examId, details], idx) => (
                        <div key={examId} style={{ display: 'table-cell', alignContent:"flex-end", width: `${(100 - 50) / Object.keys(validationDetailsMap).length}%` }}>
                            {details.validatedById ?
                                (
                                    <div id="validation-info" className="col-sm-12 padding-remove col-centered"  >
                                        <label className=" col-sm-12 text-center padding-remove  padding-remove " style={{ fontWeight: 400 }}  > {details.validatedBy.length > 20 ? details.validatedBy.substring(0, 20) + '...' : details.validatedBy}<br />{details.validationDt}</label>
                                    </div>
                                ) : (
                                    <button
                                        style={{ width: "90%", margin: "0 5%" }}
                                        type="button"
                                        className="btn btn-info text-center"
                                        onClick={(e) => saveValidationDtls(examId, e)}
                                    >
                                        Validate
                                    </button>
                                )}
                        </div>
                    ))}
                </div>
                <div style={{ width: "18%", height: 50, display: 'table' }}></div>
            </div>

        </div>
    );
}

export default ValidateTable;