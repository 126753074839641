import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import "common/css/master.css";
import "../css/student.css";
import { getCityListSvc, saveStudentHistorySvc, saveStudentMedicalSvc } from "./studentHistorySvc";



const StudentHistory = (props) => {
    const { setLoader, setOpacity, studentId, historyDtls, medicalDtls, lstStates, lstClass, flgDisabled } = props;

    const [lstState, setLstState] = useState([]);
    const [lstCity, setLstCity] = useState([]);
    const [lstCls, setLstCls] = useState([]);
    const schoolId = localStorage.getItem("schoolId");

    const [school, setSchool] = useState("");
    const [clsId, setClsId] = useState(0);
    const [cls, setCls] = useState("");
    const [result, setResult] = useState("");
    const [stateId, setStateId] = useState(0);
    const [state, setState] = useState("");
    const [cityId, setCityId] = useState(0);
    const [city, setCity] = useState("");
    const [board, setBoard] = useState("")

    const [height, setHeight] = useState(0);
    const [weight, setWeight] = useState(0);
    const [bloodGroup, setBloodGroup] = useState("");
    const [disease, setDisease] = useState("");
    const [eyes, setEyes] = useState("");
    const [ears, setEars] = useState("");
    const [allergicTo, setAlergicTo] = useState("");


    useEffect(() => {
        // // console.log("StudentHistory called, studentId", studentId);
        setLstState(lstStates);
        setLstCls(lstClass);
        if (!!historyDtls) {
            setHistoryDetails(historyDtls);
        }
        if (!!medicalDtls) {
            setMedicalDetails(medicalDtls);
        }
    }, [studentId, flgDisabled, lstClass, lstStates, historyDtls, medicalDtls]);

    const setHistoryDetails = (pHistoryDtls) => {
        // // console.log("setHistoryDetails called, pHistoryDtls", pHistoryDtls);
        setSchool(pHistoryDtls.school);
        setClsId(pHistoryDtls.clsId);
        setCls(pHistoryDtls.cls);
        setResult(pHistoryDtls.result);
        setStateId(pHistoryDtls.stateId);
        setState(pHistoryDtls.state);
        setCityId(pHistoryDtls.cityId);
        setCity(pHistoryDtls.city);
        setBoard(pHistoryDtls.board);
    }

    const setMedicalDetails = (pMedicalDtls) => {
        // // console.log("setMedicalDetails called, pHistoryDtls", pMedicalDtls);
        setHeight(pMedicalDtls.height);
        setWeight(pMedicalDtls.weight);
        setBloodGroup(pMedicalDtls.bloodGroup);
        setDisease(pMedicalDtls.diseases);
        setEyes(pMedicalDtls.eyes);
        setEars(pMedicalDtls.ears);
        setAlergicTo(pMedicalDtls.allergicTo);
    }

    const saveHistory = () => {
        // // console.log('saveHistory called');
        const studentHistory = {
            studentId: studentId,
            schoolId: schoolId,
            school: school,
            clsId: clsId,
            cls: cls,
            result: result,
            stateId: stateId,
            state: state,
            cityId: cityId,
            city: city,
            board: board,
        };
        setLoader("block");
        setOpacity(0.5);
        saveStudentHistorySvc(studentHistory, handleSaveDataResponse);
    }

    const saveMedicalDetails = () => {
        // // console.log("saveMedicalDetails called");
        const studentMedical = {
            studentId: studentId,
            schoolId: schoolId,
            height: height,
            weight: weight,
            bloodGroup: bloodGroup,
            diseases: disease,
            eyes: eyes,
            ears: ears,
            allergicTo: allergicTo,
        };
        setLoader("block");
        setOpacity(0.5);
        saveStudentMedicalSvc(studentMedical, handleSaveDataResponse);
    }

    const handleSaveDataResponse = (response) => {
        // // console.log("response in handleSaveDataResponse, response", response);
        if (response.status === 'Success') {
            toast.success(response.message);
        } else {
            handleServiceError(response.message);
        }
        setLoader("none");
        setOpacity(1);
    };

    const handleStateChange = (pStateId) => {
        // // console.log("handleStateChange called, fStateId called");
        setStateId(pStateId);
        setState(getStateById(pStateId));
        setLoader("block");
        setOpacity(0.5);
        getCityListSvc(pStateId, handleCityListResponse);
    }

    const handleCityListResponse = (response) => {
        // // console.log("response in handleCityListResponse, response", response);
        if (response.status === 'Success') {
            setLstCity(response.lstCity);
            // Add any additional processing if needed
        } else {
            handleServiceError(response.message);
        }
        setLoader("none");
        setOpacity(1);
    };

    const handleServiceError = (svcMsg) => {
        if (svcMsg === "You are not Logged In") {
            toast.error("You are not logged in. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else if (svcMsg === "Server Timed Out, Login Again") {
            toast.error("Server timed out. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else {
            toast.error(svcMsg);
        }
    };

    const getStateById = (stateId) => {
        const state = lstState.find(element => element.stateId === stateId);
        return state ? state.state : 'State not found';
    };


    const handleCityChange = (fCityId) => {
        // // console.log('handleCityChange called, fCityId', fCityId);
        setCityId(fCityId);
        setCity(getCityById(lstCity, fCityId));
    }


    const getCityById = (citiesArray, cityId) => {
        const city = citiesArray.find(element => element.cityId === cityId);
        return city ? city.city : 'City not found';
    };


    const handleClassChange = (pClsId) => {
        // // console.log("handleClassChange called, pClsId", pClsId);
        setClsId(pClsId);
        setCls(getClassById(pClsId));
    }

    const getClassById = (pClsId) => {
        const siblingClass = lstCls.find(element => element.clsId === pClsId);
        return siblingClass ? siblingClass.cls : '';
    };


    return (
        <div className="col-sm-12 col-xs-12 padding-remove">
            <div
                className="col-sm-12 section1"
                style={{ width: "100%" }}
            >
                <div className="">
                    <label className="control-label col-sm-4">
                        History
                    </label>
                </div>
            </div>
            <div className="col-sm-12" id="History">
                <div className="col-sm-6 padding-remove">
                    <form
                        id="StudentHistoryForm"
                        className="form-horizontal padding-remove"
                    >
                        <div
                            className="col-sm-12 section1"
                            style={{ marginLeft: "15px" }}
                        >
                            <div className="">
                                <label className="control-label col-sm-4">
                                    Student
                                </label>
                            </div>
                        </div>
                        <div className="col-sm-12 padding-remove">
                            <div className="col-sm-12 padding-remove">
                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        Previous School:
                                    </label>
                                    <div className="col-sm-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Previous School"
                                            id="inp_preschool"
                                            value={school}
                                            onChange={(e) => setSchool(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        Previous Class:
                                    </label>
                                    <div className="col-sm-6">
                                        <select
                                            className='form-control '
                                            // style={{border:"1px solid #ccc"}}
                                            value={clsId}
                                            onChange={e => handleClassChange(e.target.value)}
                                            disabled={flgDisabled}
                                        >
                                            <option value={""}>Class</option>
                                            {lstCls ?
                                                lstCls.map((obj, idx) => {
                                                    return (
                                                        <option key={idx} value={obj.clsId}>
                                                            {obj.cls}
                                                        </option>
                                                    )
                                                }) : null}
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        Previous State:
                                    </label>
                                    <div className="col-sm-6">
                                        <select
                                            className="form-control"
                                            value={stateId}
                                            onChange={(e) => handleStateChange(e.target.value)}
                                            disabled={flgDisabled}
                                        >
                                            <option value={0}>State</option>
                                            {lstState ?
                                                lstState.map((state) => (
                                                    <option
                                                        key={state.stateId}
                                                        value={state.stateId}>
                                                        {state.state}
                                                    </option>
                                                )) : null
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        Previous City:
                                    </label>
                                    <div className="col-sm-6">
                                        <select
                                            className='form-control'
                                            // style={{border:"1px solid #ccc"}}
                                            value={cityId}
                                            onChange={e => handleCityChange(e.target.value)}
                                            disabled={flgDisabled}
                                        >
                                            <option value={""}>City</option>
                                            {lstCity ?
                                                lstCity.map((city, idx) => {
                                                    return (
                                                        <option key={idx} value={city.cityId}>
                                                            {city.city}
                                                        </option>
                                                    )
                                                }) : null
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        Result:
                                    </label>
                                    <div className="col-sm-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Result"
                                            id="inp_result"
                                            value={result}
                                            onChange={(e) => setResult(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        Board:
                                    </label>
                                    <div className="col-sm-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Board"
                                            id="inp_board"
                                            value={board}
                                            onChange={(e) => setBoard(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="form-group"
                                    style={{ marginTop: 50 }}
                                >
                                    <div className="col-sm-10 text-right">
                                        <button
                                            type="button"
                                            onClick={() => saveHistory()}
                                            className="btn btn-info"
                                            disabled={flgDisabled}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="col-sm-6 padding-remove">
                    <form
                        id="medicalHistoryForm"
                        className="form-horizontal padding-remove"
                    >
                        <div
                            className="col-sm-12 section1"
                            style={{ marginLeft: "15px" }}
                        >
                            <div className="">
                                <label className="control-label col-sm-4">
                                    Medical
                                </label>
                            </div>
                        </div>
                        <div className="col-sm-12 padding-remove">
                            <div className="col-sm-12 padding-remove">
                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        Height:
                                    </label>
                                    <div className="col-sm-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Height(cms)"
                                            id="inp_height"
                                            value={height}
                                            onChange={(e) => setHeight(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        Weight:
                                    </label>
                                    <div className="col-sm-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Weight"
                                            id="inp_weight"
                                            value={weight}
                                            onChange={(e) => setWeight(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        Blood Group:
                                    </label>
                                    <div className="col-sm-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Blood Group"
                                            id="inp_bloodgroup"
                                            value={bloodGroup}
                                            onChange={(e) => setBloodGroup(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        Disease:
                                    </label>
                                    <div className="col-sm-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Disease"
                                            id="inp_disease"
                                            value={disease}
                                            onChange={(e) => setDisease(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        Eyes:
                                    </label>
                                    <div className="col-sm-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Eyes"
                                            id="inp_eyes"
                                            value={eyes}
                                            onChange={(e) => setEyes(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        Ears:
                                    </label>
                                    <div className="col-sm-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Ears"
                                            id="inp_ears"
                                            value={ears}
                                            onChange={(e) => setEars(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        Allergic To:
                                    </label>
                                    <div className="col-sm-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Allergic To"
                                            id="inp_allergicto"
                                            value={allergicTo}
                                            onChange={(e) => setAlergicTo(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                    <div className="col-sm-1 text-right">
                                        <button
                                            type="button"
                                            onClick={() => saveMedicalDetails()}
                                            className="btn btn-info"
                                            disabled={flgDisabled}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default StudentHistory;