import React, { useState, useEffect } from "react";

export function BorrowerDtls(props) {
  const [age, setAge] = useState(0);
  const [borrower, setBorrower] = useState("");
  const [clsSection, setClsSection] = useState("");
  const [gender, setGender] = useState("");
  const [staffId, setStaffId] = useState(0);
  const [studentId, setStudentId] = useState(0);

  useEffect(() => {
    setAge(props.borrowerDtls.age);
    setGender(props.borrowerDtls.gender);
    setClsSection(props.borrowerDtls.clsSection);
    setBorrower(props.borrowerDtls.borrower);
    // // console.log(props.borrowerDtls);
    setStaffId(props.borrowerDtls.staffId);
    setStudentId(props.borrowerDtls.studentId);
  }, [props]);

  return (
    <form
      className="form form-horizontal"
      style={{
        width: "96%",
        margin: "0px 15px",
        background: "#fff",
        padding: 10,
      }}
    >
      <div className="col-sm-12">
        <div className="form-group col-md-12 padding-remove">
          <label className="control-label col-md-2">Borrower</label>
          <div className=" padding-remove col-md-10">
            <div className="col-md-5 padding-remove">
              <input
                type="text"
                className="form-control"
                value={borrower}
                readOnly
              />
            </div>
            <label className="control-label col-md-3">Class-Section</label>
            <div className="col-md-2 padding-remove">
              <input
                type="text"
                className="form-control"
                value={clsSection}
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="form-group col-md-12 padding-remove">
          <label className="control-label col-md-2">Gender/Age</label>
          <div className=" padding-remove col-md-1">
            <input
              type="text"
              className="form-control"
              value={gender === "-" ? "" : gender + "/" + age}
              readOnly
            />
          </div>
        </div>
        <div className="col-md-12 padding-remove">
          <div
            className="col-md-12"
            style={{
              backgroundColor: "#ff5722",
              color: "#fff",
              fontWeight: "bold",
              height: 20,
              margin: 10,
              display: props.issueRestriction === "-" ? "none" : "block",
            }}
          >
            {props.issueRestriction === "For Student Only" &&
              "This book is for student only. It can't be issued to staff."}
            {props.issueRestriction === "For Staff Only" &&
              "This book is for staff only. Tt can't be issued to student"}
            {props.issueRestriction === "For Reference Only" &&
              "This book is for reference only. It can't   be issued."}
          </div>
        </div>
      </div>
    </form>
  );
}
