import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
const httpURL = lstModules['payrollSetup'].url + ':' + lstModules['payrollSetup'].port;
var tokenid = localStorage.getItem('tokenid');
function StaffSlider(props){
    const [loader, setLoader] = useState("none");
    const [type,setStaffType]=useState('')
    const [typeId,setTypeId]=useState(0)
    useEffect(()=>{
        setStaffType(props.staffObj.type)
        setTypeId(props.staffObj.typeId)
    },[])
    function saveResult(cbSaveStaffType){
        setLoader('block')
        var staffType={
            type:type,
            typeId:typeId,
        }
        axios(
            {
                url: httpURL +"/sms/staff_type/save",
                method: 'POST',
                params: {staffType},
                headers: { 'tokenid': tokenid },
                withCredentials: true
            }
        ).then(
            (Response) => {
                var data = Response.data
                cbSaveStaffType(data)
            }
        ).catch((error) => {
            console.error(error)
        });
      }
      function cbSaveStaffType(data){
        setLoader('none')
        if(data.SvcStatus==='Success'){
            toast.success(data.SvcMsg)
            props.generateRandomNo()
        }
        else{
            toast.error(data.SvcMsg)
        }
      }
      function handleStaffTypeChange(param){
        setStaffType(param)
      }
      function deleteStaffType(cbDeleteStaffType){
        setLoader('block')
        axios(
            {
                url: httpURL +"/sms/staff_type/delete",
                method: 'POST',
                params: {typeId },
                headers: { 'tokenid': tokenid },
                withCredentials: true
            }
        ).then(
            (Response) => {
                var data = Response.data
                cbDeleteStaffType(data)
            
                
    
            }
        ).catch((error) => {
            console.error(error)
        });
      }
      function cbDeleteStaffType(data){
        setLoader('none')
        if(data.SvcStatus==='Success'){
            toast.success(data.SvcMsg)
            props.generateRandomNo()
        }
        else{
            toast.error(data.SvcMsg)
        }
      }
    return(<form    
        
    className="form form-horizontal"
    style={{
    pointerEvents: loader === "none" ? "auto" : "none",
      height: "100%",
      width: "100%",
      overflowX: 'hidden',
      background: "#fff",
      border: "1px solid #ccc",
      borderRadius: 10,
    }}>
        <ToastContainer autoClose={3000} hideProgressBar />
                <div className="form-group col-md-12">
                    <label className="control-label col-md-5">Staff Type<span className="red-color">*</span></label>
                    <div className='col-sm-6 padding-remove'>
                        <input type='text' className="form-control" defaultValue={props.staffObj.type} onChange={(e) => handleStaffTypeChange(e.target.value)} required></input>
                    </div>
                </div>

                <div className="form-group col-md-12" style={{paddingTop:'8px'}}>
                    <div className="col-md-offset-5 col-md-1 padding-remove">
                        <button type="button" className="btn btn-info" onClick={(e) => saveResult(cbSaveStaffType)}>Save</button>
                    </div>
                    <div className="col-md-offset-2 col-md-1 padding-remove">
                        <button type="button" className="btn btn-danger" onClick={(e) => deleteStaffType(cbDeleteStaffType)}>Delete</button>
                    </div>
                </div>

    </form>)
}
export default StaffSlider;