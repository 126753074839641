import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
var httpURL = lstModules['transport'].url + ':' + lstModules['transport'].port;
var tokenid = localStorage.getItem('tokenid');
function getVehicleList(callBack){
    axios(
        {
            url: httpURL+"/sms/tpt/vehicle/list",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
           
        }
    ).catch((error) => {
        // // console.log("Test List Catch error")
    });
}
function getVehicleTypeList(callBack){
    axios(
        {
            url: httpURL+"/sms/tpt/vtype/list",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
           
        }
    ).catch((error) => {
        // // console.log("Test List Catch error")
    });
}
function getFuelTypeList(callBack){
    axios(
        {
            url: httpURL+"/sms/tpt/fuel_type/list",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
           
        }
    ).catch((error) => {
        // // console.log("Test List Catch error")
    });
}
function getRouteList(pVehicleId,callBack){
    axios(
        {
            url: httpURL+"/sms/tpt/vehicle/route/list",
            method: 'POST',
            params: {vehicleId:pVehicleId},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
           
        }
    ).catch((error) => {
        // // console.log("Test List Catch error")
    });
}
function getVehicleDtls(pVehicleId,callBack){
    axios(
        {
            url: httpURL+"/sms/tpt/vehicle/dtls",
            method: 'POST',
            params: {vehicleId:pVehicleId},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
           
        }
    ).catch((error) => {
        // // console.log("Test List Catch error")
    });
}
function deleteVehicleSvc(pVehicleId,callBack){
    axios(
        {
          url: httpURL+"/sms/tpt/vehicle/delete",
          method: 'POST',
          params: { vehicleId: pVehicleId },
          headers: { 'tokenid': tokenid },
          withCredentials: true
        }
      ).then(
        (Response) => {
          var data = Response.data;
          callBack(data);
        }
      ).catch((error) => {
        console.error(error)
      });
}
function saveMainInfoSvc(pMainObj,callBack){
    var formData = new FormData();
    formData.append("mainInfo", JSON.stringify(pMainObj));
    axios({
        url: httpURL + "/sms/tpt/vehicle/main_info/save",
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {

        var data = response.data
        callBack(data);
    }).catch((error) => { 
        // // console.log("Error Occured") 
    });
}
function saveMakeInfoSvc(pMakeObj,pVehicleId,callBack){
    var formData = new FormData();
    formData.append("makeInfo", JSON.stringify(pMakeObj));
    formData.append("vehicleId", JSON.stringify(pVehicleId));
    axios({
        url: httpURL + "/sms/tpt/vehicle/make_info/save",
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {

        var data = response.data
        callBack(data);
    }).catch((error) => { 
        // // console.log("Error Occured") 
    });
}
function saveExpiryInfoSvc(pExpiryObj,pVehicleId,callBack){
    var formData = new FormData();
    formData.append("expiryInfo", JSON.stringify(pExpiryObj));
    formData.append("vehicleId", JSON.stringify(pVehicleId));
    axios({
        url: httpURL + "/sms/tpt/vehicle/expiry_info/save",
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {

        var data = response.data
        callBack(data);
    }).catch((error) => { 
        // // console.log("Error Occured") 
    });
}
function saveOdometerInfoSvc(pOdoObj,pVehicleId,callBack){
    var formData = new FormData();
    formData.append("odometerInfo", JSON.stringify(pOdoObj));
    formData.append("vehicleId", JSON.stringify(pVehicleId));
    axios({
        url: httpURL + "/sms/tpt/vehicle/odometer_info/save",
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {

        var data = response.data
        callBack(data);
    }).catch((error) => { 
        // // console.log("Error Occured") 
    });
}
function saveRouteInfoSvc(pVehicleId,lstRouteId,callBack){
    var formData = new FormData();
    formData.append("lstRouteId", JSON.stringify(lstRouteId));
    formData.append("vehicleId", JSON.stringify(pVehicleId));
    axios({
        url: httpURL + "/sms/tpt/vehicle/route_info/save",
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {

        var data = response.data
        callBack(data);
    }).catch((error) => { // // console.log("Error Occured") 
    });
}
export {getVehicleList,getVehicleTypeList,getFuelTypeList,getRouteList,getVehicleDtls,deleteVehicleSvc,saveMainInfoSvc,saveMakeInfoSvc,saveExpiryInfoSvc,saveOdometerInfoSvc,saveRouteInfoSvc};