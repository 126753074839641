import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import $ from 'jquery';
import 'jquery-ui';
import '../css/suppliers.css';
import 'common/css/master.css';
import axios from 'axios';
import Header from 'common/js/header/header.js';
import { toast, ToastContainer } from 'react-toastify';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

var httpURL = '';
var queryString = require('querystring');
var lstSuppliers = [];
var lstgender = [];
var lstCategory = [];
var lstState = [];
var lstCity = [];
var tokenid = localStorage.getItem("tokenid");
var screenId = 13;

class Suppliers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: 'none',
            opacity: 1,
            flg: true,
            tokenid: tokenid,

        }

    }

    componentDidMount = () => {
        this.getSupplierList();
        $('#stateCmb').prepend($("<option> </option>").val(0).html('State'));
        $('#cityCmb').prepend($("<option> </option>").val(0).html('City'));

        lstgender = [{
            'genderId': 1, 'gender': 'M',
        },
        {
            'genderId': 2, 'gender': 'F',
        },
        ];
        lstCategory = [{

            'CategoryId': 1, 'category': 'A',
        },
        {
            'CategoryId': 2, 'category': 'B',
        },
        {
            'CategoryId': 3, 'category': 'C',
        },
        ];
        this.fillRoomgenderCmb();
        this.fillRoomcategoryCmb();


        this.setState({ loader: 'block', opacity: 0.5 });

        axios({
            url: httpURL + '/sms/mst/state/list',
            method: "POST",
            params: { screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstState = response.data.lstState;
                this.fillStateCmb();
            }
            else {
                if (response.data.SvcMsg == 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });



    }

    getSupplierList = () => {
        this.setState({ loader: 'block', opacity: 0.5 });
        axios({
            url: httpURL + '/sms/supplier/list',
            method: "POST",
            params: { screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstSuppliers = response.data.lstSupplier;
                this.setSuppliersTable();
            }
            else {
                if (response.data.SvcMsg == 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }

    fillStateCmb() {
        $('#stateCmb').empty();
        $('#stateCmb').prepend($("<option> </option>").val(0).html('State'));
        for (var i = 0; i < lstState.length; i++) {
            var stateId = lstState[i].stateId;
            var state = lstState[i].state;
            $('#stateCmb').append($("<option> </option>").val(stateId).html(state));
        }
    }

    changeState = () => {
        var stateId = $('#stateCmb').find('option:selected').val();
        this.getCityList(stateId);
    }

    getCityList = (stateId) => {
        this.setState({ loader: 'block', opacity: 0.5 });
        axios({
            url: httpURL + '/sms/mst/city/list',
            method: "POST",
            params: { 'stateId': stateId, screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstCity = response.data.lstCity;
                this.fillCityCmb();
            }
            else {
                if (response.data.SvcMsg == 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);

                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }

    fillCityCmb() {
        $('#cityCmb').empty();
        $('#cityCmb').prepend($("<option> </option>").val(0).html('City'));
        for (var i = 0; i < lstCity.length; i++) {
            var cityId = lstCity[i].cityId;
            var city = lstCity[i].city;
            $('#cityCmb').append($("<option> </option>").val(cityId).html(city));
        }
    }

    fillRoomgenderCmb() {
        $('#genderCmb').empty();
        $('#genderCmb').prepend($("<option> </option>").val(0).html('gender'));
        for (var i = 0; i < lstgender.length; i++) {
            var genderId = lstgender[i].genderId;
            var gender = lstgender[i].gender;
            $('#genderCmb').append($("<option> </option>").val(genderId).html(gender));
        }
    }
    fillRoomcategoryCmb() {
        $('#categoryCmb').empty();
        $('#categoryCmb').prepend($("<option> </option>").val(0).html('category'));
        for (var i = 0; i < lstCategory.length; i++) {
            var CategoryId = lstCategory[i].CategoryId;
            var category = lstCategory[i].category;
            $('#categoryCmb').append($("<option> </option>").val(CategoryId).html(category));
        }
    }


    setSuppliersTable = () => {
        $('#SupplierBodyTable tr').remove();
        var table = document.getElementById('SupplierTable');
        var tbody = document.getElementById('SupplierBodyTable');

        for (var i = 0; i < lstSuppliers.length; i++) {
            var supplierId = lstSuppliers[i].supplierId;
            var supplierCode = lstSuppliers[i].supplierCode;
            var supplierName = lstSuppliers[i].supplier;
            var mobileNo = lstSuppliers[i].mobileNo;
            var email = lstSuppliers[i].email;
            var state = lstSuppliers[i].state;
            var city = lstSuppliers[i].city;

            if (city == null || city == "") {
                city = '-';
            }
            var gstIn = lstSuppliers[i].gstin;
            var row = document.createElement('tr');
            row.id = supplierId;

            row.onclick = (e) => {
                $(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');
                this.setSupplierForm($(e.target).closest('tr').attr('id'));
                this.setState({
                    flg: false,
                });
            }

            this.addTD(row, supplierId, 'no-display');
            this.addTD(row, supplierCode, 'text-center');
            this.addTD(row, supplierName, 'text-center');
            this.addTD(row, mobileNo, 'text-center');
            this.addTD(row, email, 'text-center');
            this.addTD(row, state, 'text-center');
            this.addTD(row, city, 'text-center');
            this.addTD(row, gstIn, 'text-center');

            tbody.append(row);
        }
        table.append(tbody);
    }

    setSupplierForm = (supplierId) => {
        var SupplierData = this.getSupplierData(supplierId);
        $('#supplierId').val(SupplierData.supplierId);
        $('#supplierCode').val(SupplierData.supplierCode);
        $('#supplierName').val(SupplierData.supplier);
        // $('#gender').val(SupplierData.gender);
        $('#mobileNo').val(SupplierData.mobileNo);
        $('#secMobileNo').val(SupplierData.secMobileNo);
        $('#email').val(SupplierData.email);
        $('#businessName').val(SupplierData.businessName);
        $('#address1').val(SupplierData.address1);
        $('#address2').val(SupplierData.address2);
        $('#stateCmb').val(SupplierData.stateId);
        $('#cityCmb').val(SupplierData.cityId);
        $('#gstIn').val(SupplierData.gstin);
        $('#categoryCmb').val(SupplierData.category);
        var block = SupplierData.blocked;
        if (block == true)
            $('#block').prop("checked", true);
        else
            $('#block').prop("checked", false);

        var gender = SupplierData.gender;
        if (gender == 'f' || gender == 'F') {
            $('#genderCmb').val(2);
        }
        else if (gender == 'm' || gender == 'M') {
            $('#genderCmb').val(1);
        }



    }

    getSupplierData(supplierId) {
        for (var i = 0; i < lstSuppliers.length; i++) {
            if (supplierId == lstSuppliers[i].supplierId)
                return lstSuppliers[i];
        }
    }


    addSupplier = () => {
        var lstSupplier = {};
        var supplierId = 0;
        var supplierCode = $('#supplierCode').val();
        var supplierName = $('#supplierName').val();
        var gender = $('#genderCmb').find('option:selected').text();
        var genderId = $('#genderCmb').find('option:selected').val();
        var mobileNo = $('#mobileNo').val();
        var secMobileNo = $('#secMobileNo').val();
        var email = $('#email').val();
        var businessName = $('#businessName').val();
        var address1 = $('#address1').val();
        var address2 = $('#address2').val();
        var state = $('#stateCmb').find('option:selected').text();
        var stateId = $('#stateCmb').find('option:selected').val();
        var cityId = $('#cityCmb').find('option:selected').val();
        var city = $('#cityCmb').find('option:selected').text();
        var gstIn = $('#gstIn').val();
        var category = $('#categoryCmb').find('option:selected').text();
        var categoryId = $('#categoryCmb').find('option:selected').val();
        var block = $('#block').prop('checked');
        block = (block == true) ? true : false;



        if (supplierCode == "") {
            toast.warning('Please Enter Supplier Code');
            $('#supplierCode').focus();
            return false;
        }
        if (supplierName == "") {
            toast.warning('Please Enter Supplier Name');
            $('#supplierName').focus();
            return false;
        }
        if (genderId == 0) {
            toast.warning('Please Select Valid Gender');
            $('#genderCmb').focus();
            return false;
        }
        if (mobileNo == "") {
            toast.warning('Please Enter Mobile No');
            $('#mobileNo').focus();
            return false;
        }
        if (email == "") {
            toast.warning('Please Enter Email');
            $('#email').focus();
            return false;
        }
        if (businessName == "") {
            toast.warning('Please Enter Bussiness Name');
            $('#businessName').focus();
            return false;
        }
        if (address1 == "") {
            toast.warning('Please Enter Address');
            $('#address1').focus();
            return false;
        }

        if (stateId == 0) {
            toast.warning('Please Select Valid State');
            $('#state').focus();
            return false;
        }
        // if (city == "") {
        //     toast.warning('Please Enter City');
        //     $('#city').focus();
        //     return false;
        // }
        if (categoryId == 0) {
            toast.warning('Please Select Valid Category');
            $('#categoryCmb').focus();
            return false;
        }

        if (this.state.flg) {
            supplierId = 0;
        } else {
            supplierId = $('#SupplierBodyTable tr.selected').find('td:eq(0)').text();
        }


        lstSupplier = {
            'supplierId': supplierId, 'supplierCode': supplierCode, 'supplier': supplierName, 'gender': gender, 'mobileNo': mobileNo,
            'secMobileNo': secMobileNo, 'email': email, 'businessName': businessName, 'address1': address1, 'address2': address2,
            'stateId': stateId, 'cityId': cityId, 'gstin': gstIn, 'category': category, 'blocked': block,
        }


        axios({
            url: httpURL + '/sms/supplier/save',
            method: "POST",
            params: { 'supplier': JSON.stringify(lstSupplier), screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getSupplierList();
                this.setSuppliersTable();
                this.addSupplierType();

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });



    }

    addTD(row, val, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var text = document.createTextNode(val);
        cell.append(text);
        row.append(cell);
    }

    addSupplierType = () => {
        $('#SupplierAddForm').trigger('reset');
        $('#SupplierBodyTable tr').removeClass('selected');
    }


    getWingId = (wingId) => {
        // // console.log("wingId" + wingId);
    }


    render() {
        httpURL = lstModules['purchase'].url + ":" + lstModules['purchase'].port;
        return (
            <div style={{ height: '100%' }}>
                <Header wingFromChild={this.getWingId} />
                <ToastContainer autoClose={3000} hideProgressBar />
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: this.state.loader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>
                <div className="mainContainer Timing" style={{ opacity: this.state.opacity }}>
                    <div className="col-md-11 col-centered">
                        <div className="col-md-7  col-xs-7 periodTypeWrap  padding-remove">
                            <table className="tableLayout1 periodType" style={{ width: this.state.width }}>
                                <thead>
                                    <tr>
                                        <th style={{ display: 'none' }}> Supplier Id </th>
                                        <th style={{ width: 40 }}> Supplier Code </th>
                                        <th style={{ width: 40 }}> Supplier Name </th>
                                        <th style={{ width: 45 }}> mobileNo </th>
                                        <th style={{ width: 80 }}> email </th>
                                        <th style={{ width: 40 }}> state </th>
                                        <th style={{ width: 40 }}> city </th>
                                        <th style={{ width: 35 }}> GSTIN </th>

                                    </tr>
                                </thead>
                            </table>
                            <div className="SupplierTableDiv" style={{ height: '400px', overflowY: 'scroll', border: '1px solid #ccc' }} >
                                <table id="SupplierTable" className="tableLayout">
                                    <colgroup>
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="45" />
                                        <col width="80" />
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="35" />

                                    </colgroup>
                                    <tbody id="SupplierBodyTable"></tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-xs-1 ">
                            <span id="addSupplier" title="Add Period Type" onClick={this.addSupplierType} className="addIcon glyphicon glyphicon-plus-sign"></span>
                        </div>
                        <div className="col-md-4 col-xs-4 padding-remove timingFormWrap" >
                            <form id="SupplierAddForm" className="form-horizontal formWrapper">

                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Code<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="supplierCode" type="text" className="form-control " placeholder="Supplier Code" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Name<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="supplierName" type="text" className="form-control " placeholder="Supplier Name" />
                                    </div>
                                </div>


                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Gender<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <select onChange={this.changeGender} className="form-control" style={{ width: '100%' }} id="genderCmb"></select>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Mobile No<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="mobileNo" type="text" className="form-control " placeholder="mobileNo" />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Sec Phone No</label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="secMobileNo" type="text" className="form-control " placeholder="secMobileNo" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Email<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="email" type="text" className="form-control " placeholder="email" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Bussiness Name<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="businessName" type="text" className="form-control " placeholder="businessName" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Address<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="address1" type="text" className="form-control " placeholder="Address 1" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >   </label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="address2" type="text" className="form-control " placeholder="Address 2" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >State<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <select onChange={this.changeState} className="form-control" style={{ width: '100%' }} id="stateCmb"></select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >City<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <select onChange={this.changeCity} className="form-control" style={{ width: '100%' }} id="cityCmb"></select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >GSTIN<span style={{ color: 'red', marginLeft: 2 }}></span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="gstIn" type="text" className="form-control " placeholder="gstIn" />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Category<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <select onChange={this.changePeriodType} className="form-control" style={{ width: '100%' }} id="categoryCmb"></select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Block<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="block" type="checkbox" className="form-control " placeholder="block" />
                                    </div>
                                </div>

                                <div className="form-group" >
                                    <div className="col-sm-12 text-center userButtonWrap" >
                                        <button type="button" style={{ marginTop: 20 }} title="Save" onClick={this.addSupplier} className="btn btn-info">Save</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        );
    }



}
export default Suppliers;