import React, { useState } from 'react'
//Please create AttachmentSliderSvc.js

const AttachmentSlider = () => {
  const [education,setEducation]=useState([])
    return (
        
        
         <div className='container' style={{width:'100%'}}>
          
         <form className='form form-horizontal' style={{ width: '100%', overflowX: 'hidden', margin: '0 auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
         
                <div className='EnquiryHead col-sm-12 col-centered' style={{
                    border: "1px solid #aaa", borderTopLeftRadius: 10,
                    borderTopRightRadius: 10, borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5, fontWeight: 'bold'
                }}><h4>Attachments</h4></div>
                <div className="row-sm-8" style={{ width: '100%', overflowX: 'hidden', margin: '0 auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10  ,height:'110px'}}>

              <div className="form-group col-md-12">
              <label className='control-label col-md-5'> Id </label>
            <div className="col-sm-6">
            <input type='file' className="form-control" id="cmb_tch"     >
            
        
              </input>
          </div>
          </div>

          <div className="form-group col-md-12">
              <label className='control-label col-md-5'> Age </label>
            <div className="col-sm-6">
            <input type='file' className="form-control" id="cmb_tch"     >
            
        
              </input>
          </div>
          </div>
          <div className="form-group col-md-12">
              <label className='control-label col-md-5'> Address </label>
            <div className="col-sm-6">
            <input type='file' className="form-control" id="cmb_tch"     >
            
        
              </input>
          </div>
          </div>
          </div>
          
          <div className='EnquiryHead col-sm-12 col-centered' style={{
                    border: "1px solid #aaa", borderTopLeftRadius: 10,
                    borderTopRightRadius: 10, borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5, fontWeight: 'bold', width: '100%',
                    height:'35px'
                    
                }}><h4></h4>Education Attachments</div>
                <div className="row-sm-8" style={{ width: '100%', overflowX: 'hidden', margin: '0 auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10 , overflowY:'scroll' ,height:'150px'}}>
               
                <div className="form-group col-md-12"></div>
                {education.map((rec) =>( 
  <label htmlFor="photo-upload" className="custom-file-upload fas">
 
    <div className="img-wrap img-upload" >
    
      <img src={rec.attachmentPath}/>
    </div>
    </label>))}
    <label htmlFor="photo-upload" className="custom-file-upload fas">
 
 <div className="img-wrap img-upload" >
   <img for="photo-upload" src={require('./upload.svg')}/>
 </div>
 <input autoComplete="off" id="photo-upload" type="file" accept="image/*" className="form-control " />
 </label>
    </div>
    <div className='EnquiryHead col-sm-12 col-centered' style={{
                    border: "1px solid #aaa", borderTopLeftRadius: 10,
                    borderTopRightRadius: 10, borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5, fontWeight: 'bold', width: '100%'
                    ,height:'35px'
                    
                }}><h4></h4>Education Attachments</div>
                <div className="row-sm-8" style={{ width: '100%', overflowX: 'hidden', margin: '0 auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10 , overflowY:'scroll' ,height:'150px'}}>
               
                <div className="form-group col-md-12"></div>
                {education.map((rec) =>( 
  <label htmlFor="photo-upload" className="custom-file-upload fas">
 
    <div className="img-wrap img-upload" >
    
      <img src={rec.attachmentPath}/>
    </div>
    </label>))}
    <label htmlFor="photo-upload" className="custom-file-upload fas">
 
 <div className="img-wrap img-upload" >
   <img for="photo-upload" src={require('./upload.svg')}/>
 </div>
 <input autoComplete="off" id="photo-upload"  type="file" accept="image/*" className="form-control " />
 </label>
    </div>
    <div className="row-sm-8" style={{ width: '100%', overflowX: 'hidden', margin: '0 auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10  ,height:'80px'}}>
          <div className="form-group col-md-12">
              <label className='control-label col-md-5'> Audio-Profile </label>
            <div className="col-sm-6">
            <input type='file' className="form-control" id="cmb_tch"     >
            
        
              </input>
          </div>
          </div>
          <div className="form-group col-md-12">
              <label className='control-label col-md-5'> Video-Profile </label>
            <div className="col-sm-6">
            <input type='file' className="form-control" id="cmb_tch"     >
            
        
              </input>
          </div>
          </div>
          </div>
          <div className='EnquiryHead col-sm-12 col-centered' style={{
                    border: "1px solid #aaa", borderTopLeftRadius: 10,
                    borderTopRightRadius: 10, borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5, fontWeight: 'bold', width: '100%'
                    ,height:'35px'
                    
                }}><h4></h4>special-Accolated</div>
                <div className="row-sm-8" style={{ width: '100%', overflowX: 'hidden', margin: '0 auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10 , overflowY:'scroll' ,height:'150px'}}>
               
                <div className="form-group col-md-12"></div>
                {education.map((rec) =>( 
  <label htmlFor="photo-upload" className="custom-file-upload fas">
 
    <div className="img-wrap img-upload" >
    
      <img src={rec.attachmentPath}/>
    </div>
    </label>))}
    <label htmlFor="photo-upload" className="custom-file-upload fas">
 
 <div className="img-wrap img-upload" >
   <img for="photo-upload" src={require('./upload.svg')}/>
 </div>
 <input autoComplete="off" id="photo-upload" type="file" accept="image/*" className="form-control " />
 </label>
    </div>
          
          </form>
          
          </div>
    )
}

export default AttachmentSlider