import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import "./teacherAllocation.css";
import { saveTeacherAllocationSvc } from './teacherAllocationSvc';


const TeacherAllocationSlider = (props) => {
    const { teacherAllocation, updateKey, deleteTeacherAllocation, closeSlider } = props;

    const [editLoader, setEditLoader] = useState("none");
    const [editOpacity, setEditOpacity] = useState(1);

    const [tchId, setTchId] = useState(0);
    const [tch, setTch] = useState("");
    const [maxAlloc, setMaxAlloc] = useState(0);
    const [daysOff, setDaysOff] = useState("0000000");
    const [periodsOff, setPeriodsOff] = useState("0000000000");

    const dayIndex = {
        mon: 0,
        tue: 1,
        wed: 2,
        thu: 3,
        fri: 4,
        sat: 5,
        sun: 6
    };


    useEffect(() => {
        // console.log("TeacherAllocationSlider called, teacherAllocation", teacherAllocation);
        if (!!teacherAllocation) {
            if(teacherAllocation.tchId)
                setTchId(teacherAllocation.tchId);
            if (teacherAllocation.tch)
                setTch(teacherAllocation.tch);
            if (teacherAllocation.maxAlloc)
                setMaxAlloc(teacherAllocation.maxAlloc);
            if (teacherAllocation.daysOff)
                setDaysOff(teacherAllocation.daysOff);
            if (teacherAllocation.periodsOff)
                setPeriodsOff(teacherAllocation.periodsOff);
        }
    }, [ teacherAllocation]);

    const saveTeacherAllocation = () => {
        if (!tch) {
            toast.error("TeacherAllocation name is required.");
            return;
        }
        if (!maxAlloc) {
            toast.error("Maximum allowed classes are required.");
            return;
        }
        if (!daysOff) {
            toast.error("Days Off is required.");
            return;
        }
        if (!periodsOff) {
            toast.error("Periods Off is required.");
            return;
        }

        // console.log("saveTeacherAllocation called");
        const saveTeacherAllocationObj = {
            tchId,
            tch,
            maxAlloc,
            daysOff,
            periodsOff
        };

        setEditLoader("block");
        setEditOpacity(0.5);
        saveTeacherAllocationSvc(saveTeacherAllocationObj, saveTeacherAllocationResponse);
    };


    const saveTeacherAllocationResponse = async (data) => {
        // console.log("saveTeacherAllocationResponse called, data", data);
        if (data.status === "Success") {
            toast.success(data.message);
            await updateKey();
            closeSlider();
        } else if (data.status === "Failure") {
            handleServiceError(data.message);
        } else {
            toast.error("Service failed " + data.error);
        }
        setEditLoader("none");
        setEditOpacity(1);
    }

    const handleServiceError = (svcMsg) => {
        if (svcMsg === "You are not Logged In") {
            toast.error("You are not logged in. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else if (svcMsg === "Server Timed Out, Login Again") {
            toast.error("Server timed out. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else {
            toast.error(svcMsg);
        }
    };

    const handleChangeCbox = (event) => {
        const { id, checked } = event.target;

        // Log the current daysOff string before updating
        // console.log("daysOff before update:", daysOff);

        let updatedDaysOff = daysOff.split(""); // Convert string to array for easier manipulation

        // Update the specific day in the array based on checkbox
        updatedDaysOff[dayIndex[id]] = checked ? "1" : "0";

        // Join the array back to a string and update state
        setDaysOff(updatedDaysOff.join(""));
    };

    const handlePeriodChange = (event) => {
        const { id, checked } = event.target;
        const periodIndex = parseInt(id.replace("period", ""), 10) - 1;  // Convert "period1" to 0, "period2" to 1, etc.
        let updatedPeriodsOff = periodsOff.split("");
        updatedPeriodsOff[periodIndex] = checked ? "1" : "0";
        const newPeriodsOff = updatedPeriodsOff.join("");
        setPeriodsOff(newPeriodsOff);
        // console.log("Updated periodsOff:", newPeriodsOff);
    };



    return (
        <div >
            <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={() => closeSlider()} style={{ right: "60%" }}>
                <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
            </button>
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '58%', top: '45%', display: editLoader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="col-md-12 col-xs-12 col-centered padding-remove "
                style={{
                    cursor: (editLoader === "none") ? 'pointer' : 'none',
                    pointerEvents: (editLoader === "none") ? 'auto' : 'none',
                    opacity: editOpacity
                }}>
                <div className="col-sm-12 col-md-12 padding-remove"
                    style={{ width: '94%', margin: '3% ', background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
                    <form
                        id="editTeacherAllocationForm"
                        className='form form-horizontal'
                        style={{ height: "80vh", maxHeight: "400px", overflowX: 'hidden', padding: "20px 20px 5px 20px", }}
                    >
                        <div className="form-group col-md-12 " style={{ padding: "5px 0" }}>
                            <div className="form-group col-md-12 padding-remove">
                                <label className="control-label col-md-3 col-xs-6 " style={{ padding: "7px 0 0 0" }}>
                                    TeacherAllocation:
                                </label>
                                <div className='col-md-6 col-sm-6 col-xs-12  '>
                                    <input
                                        type="text"
                                        className="form-control"
                                        // maxLength={20}
                                        value={tch}
                                        disabled
                                        // onChange={(e) => setTch(e.target.value)}
                                        placeholder='Teacher'
                                    ></input>
                                </div>
                            </div>
                            <div className="form-group col-md-12 padding-remove">
                                <label className="control-label col-md-3 " style={{ padding: "7px 0 0 0" }}>
                                    Allowed Classes:
                                </label>
                                <div className='col-sm-3 '>
                                    <input
                                        type="number"
                                        className="form-control"
                                        min="0"
                                        max="100"
                                        value={(tchId === 0 && maxAlloc === 0) ? "" : maxAlloc}
                                        onChange={(e) => {
                                            const value = Math.max(0, Math.min(100, Number(e.target.value)));
                                            setMaxAlloc(value);
                                        }}
                                        placeholder="1"
                                    />
                                </div>
                            </div>
                            <div className="form-group col-md-12 padding-remove">
                                <label className="control-label col-md-3" style={{ padding: "7px 0 0 0" }}>
                                    Days Off:
                                </label>
                                <div className="col-sm-6 padding-remove daysWrapper">
                                    <div>
                                        <span>M</span>
                                        <input
                                            autoComplete="off"
                                            id="mon"
                                            type="checkbox"
                                            className="form-control dayCheckbox"
                                            checked={daysOff[dayIndex.mon] === "1"}
                                            onChange={handleChangeCbox}
                                        />
                                    </div>
                                    <div>
                                        <span>T</span>
                                        <input
                                            autoComplete="off"
                                            id="tue"
                                            type="checkbox"
                                            className="form-control dayCheckbox"
                                            checked={daysOff[dayIndex.tue] === "1"}
                                            onChange={handleChangeCbox}
                                        />
                                    </div>
                                    <div>
                                        <span>W</span>
                                        <input
                                            autoComplete="off"
                                            id="wed"
                                            type="checkbox"
                                            className="form-control dayCheckbox"
                                            checked={daysOff[dayIndex.wed] === "1"}
                                            onChange={handleChangeCbox}
                                        />
                                    </div>
                                    <div>
                                        <span>Th</span>
                                        <input
                                            autoComplete="off"
                                            id="thu"
                                            type="checkbox"
                                            className="form-control dayCheckbox"
                                            checked={daysOff[dayIndex.thu] === "1"}
                                            onChange={handleChangeCbox}
                                        />
                                    </div>
                                    <div>
                                        <span>F</span>
                                        <input
                                            autoComplete="off"
                                            id="fri"
                                            type="checkbox"
                                            className="form-control dayCheckbox"
                                            checked={daysOff[dayIndex.fri] === "1"}
                                            onChange={handleChangeCbox}
                                        />
                                    </div>
                                    <div>
                                        <span>S</span>
                                        <input
                                            autoComplete="off"
                                            id="sat"
                                            type="checkbox"
                                            className="form-control dayCheckbox"
                                            checked={daysOff[dayIndex.sat] === "1"}
                                            onChange={handleChangeCbox}
                                        />
                                    </div>
                                    <div>
                                        <span>Su</span>
                                        <input
                                            autoComplete="off"
                                            id="sun"
                                            type="checkbox"
                                            className="form-control dayCheckbox"
                                            checked={daysOff[dayIndex.sun] === "1"}
                                            onChange={handleChangeCbox}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group col-md-12 padding-remove">
                                <label className="control-label col-md-3" style={{ padding: "7px 0 0 0" }}>Periods Off:</label>
                                <div className="col-sm-8 periodsWrapper">
                                    {[...Array(periodsOff.length)].map((_, i) => (
                                        <div key={i}>
                                            <span>{i + 1}</span>
                                            <input
                                                autoComplete="off"
                                                id={`period${i + 1}`}
                                                type="checkbox"
                                                className="form-control periodCheckbox"
                                                checked={periodsOff[i] === "1"}
                                                onChange={handlePeriodChange}  // Use handlePeriodChange for periods
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className='col-sm-10   padding-remove' style={{ height: "50px", marginTop: "80px" }}>
                                <div className="col-sm-2 col-md-2  padding-remove pull-right">
                                    <button type="button" className="btn btn-info " onClick={() => saveTeacherAllocation()} >Save</button>
                                </div>
                                {/* <div className="col-sm-6 col-md-6  padding-remove">
                                <button type="button" className="btn btn-info " onClick={(e) => deleteTeacherAllocation(e)} disabled={!tchId}>Delete</button>
                                </div> */}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div >
    );
}

export default TeacherAllocationSlider;

