import React from 'react'

const RdoLbl = props => {
  return (
    <div className='col-sm-2'>
      <label className='radio-inline control-label'>
        <input
          value={props.dataValue}
          type='radio'
          checked={props.checked}
          name='comm'
          onChange={e => props.onChange(props.dataValue)}
        />
        {props.label}
      </label>
    </div>
  )
}

export default RdoLbl
