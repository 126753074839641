import React, { useEffect, useState } from 'react'
import {
  getLstStudentSvc,
  viewBillForStudSvc,
  publishBillForStudSvc,
  reGenForBillForStudSvc,
  initiateCallSvc,
  initiateNotifSvc,
  generateStudentBillSvc
} from './BillGenerationSvc'
import { toast, ToastContainer } from 'react-toastify'
import ViewBillPdf from './ViewBillPdf'
import '../css/BillGeneration.css'
import 'common/css/master.css'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import TextField from '@mui/material/TextField'

const BillDisplaySlider = props => {
  const [lstStudent, setLstStudent] = useState([]);
  const [open, setOpen] = useState(false);
  const [studentId, setStudentId] = useState(0);
  const [clsId, setClsId] = useState(0);
  const [selChecked, setSelChecked] = useState(false);
  const [key, setKey] = useState(0);
  const [lstMonth, setLstMonth] = useState([
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ])
  const [filterName, setFilterName] = useState('')
  const [flg, setFlg] = useState(false);

  useEffect(() => {
    getLstStudentSvc(props.selBillGenId, props.clsId, cbStudentListResponse)
  }, [props.selBillGenId, props.selStrMonth, props.selCls, key]);

  function cbStudentListResponse(data) {
    if (data.SvcStatus === 'Success') {
      var tmpLstStudent = data.lstStudent
      for (var i = 0; i < tmpLstStudent.length; i++) {
        tmpLstStudent[i].selected = false
      }
      setLstStudent(tmpLstStudent)
    } else {
      toast.error(data.SvcStatus)
    }
  }

  // function handleClassChange(pClsId) {
  //     setClsId(pClsId)
  // }

  // function handleCboxChange(pChecked) {
  //     setSelChecked(pChecked)
  //     var tmpLstStudent = lstStudent;
  //     for (var i = 0; i < tmpLstStudent.length; i++) {
  //         tmpLstStudent[i].selected = pChecked;
  //     }
  //     setLstStudent(tmpLstStudent)

  // }

  // function handleSelectStudent(pStudentId, pChecked) {
  //     var tmpLstStudent = lstStudent;

  //     for (var i = 0; i < lstStudent.length; i++) {
  //         var objStudent = tmpLstStudent[i];
  //         if (pStudentId === objStudent.studentId) {
  //             objStudent.selected = pChecked;
  //         }
  //     }
  //     setLstStudent(tmpLstStudent);
  // }

  function viewBillForStud(billNo) {
    viewBillForStudSvc(billNo, cbViewBillForStudSvc)
  }
  function cbViewBillForStudSvc(data) {
    // // console.log(data)
    // toast.success(data.SvcMsg)
    let blob = new Blob([data.data], { type: 'application/pdf' }),
      url = window.URL.createObjectURL(blob)
    const iframe = document.getElementById('viewer')
    iframe.setAttribute('src', url)
    URL.revokeObjectURL(url)
    setFlg(true)
  }

  function publishBillForStud(billNo) {
    publishBillForStudSvc(billNo, cbpublishBillForStudSvc)
  }
  function cbpublishBillForStudSvc(data) {
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
    } else {
      toast.error(data.SvcMsg)
    }
  }

  function reGenForBillForStud(billNo) {
    const result = window.confirm(
      'Regenerating will regenerate details against same bill number'
    )
    if (result === true) {
      reGenForBillForStudSvc(billNo, cbReGenForBillForStudSvc)
    }
  }
  function cbReGenForBillForStudSvc(data) {
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
    } else {
      toast.error(data.SvcMsg)
    }
  }
  function generateRandomNo() {
    return Math.floor(Math.random() * 999999)
  }
  function closeSlider2() {
    setFlg(false)
  }
  function initiateCall(billNo, studentId) {
    initiateCallSvc(billNo, studentId, cbIntiateCallSvc)
  }

  function initiateNotification(billNo, studentId) {
    initiateNotifSvc(billNo, studentId, cbIntiateCallSvc)
  }
  function cbIntiateCallSvc(data) {
    // // console.log(data.SvcMsg)
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      setOpen(false)
    } else {
      toast.error(data.SvcMsg)
      setOpen(false)
    }
  }
  function handleSelStudentId(param) {
    setStudentId(param)
  }

  const generateNewBill = (studId) => {
    generateStudentBillSvc(props.selBillGenId, studId, generateNewBillResponse);
  }

  const generateNewBillResponse = (data) => {
    var obj = data;
    if (obj.SvcStatus === 'Success') {
      setKey((prevKey) => prevKey+ 1);
      toast.success(obj.SvcMsg);
    }
    else {
      if (obj.message == 'You are not logged in') {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      }
      else if (obj.message == 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    }
  }

  return (
    <div>
      <div className='col-sm-11'>
        <ToastContainer autoClose={3000} hideProgressBar />

        <div className='row'>
          <div className='form-group'>
            <label className='col-sm-2 text-right'>Class:</label>
            <div className='text-left pull-left'>
              <label className='link'>{props.selCls}</label>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='form-group'>
            <label className='col-sm-2 text-right'>Month:</label>
            <div className=' text-left pull-left'>
              <label className='link'>{props.selStrMonth}</label>
            </div>
            {/* <button className="btn btn-info pull-right" onClick={(e) => viewBill()}>View Bill</button> */}
          </div>
        </div>
        {/* <div className="row">
                <div className="form-group">
                    <label className="control-label col-sm-2 text-right" >All Students</label>
                    <div className="pull-left">
                        <input type="checkbox" checked={selChecked} onChange={(e) => handleCboxChange(e.target.checked)} />
                    </div>
                </div>
            </div> */}
        <div className='row'>
          <div className='col-sm-12 pull-right padding-remove'>
            <table className='tableLayout1'>
              <thead>
                <tr>
                  <th style={{ width: 20 }}>StudentID</th>
                  <th style={{ width: 40 }} className='text-left'>
                    <div className='form-group'>
                      <input
                        className='form-control'
                        type='text'
                        defaultValue={filterName}
                        placeholder='Student'
                        onChange={e =>
                          setFilterName(e.target.value.toLowerCase())
                        }
                      />
                    </div>
                  </th>
                  <th style={{ width: 20 }} className='text-left'>
                    Section
                  </th>
                  <th style={{ width: 20 }} className='text-left'>
                    Receipt No.
                  </th>
                  <th style={{ width: 30 }} className='text-left'>
                    Published On
                  </th>
                  <th style={{ width: 100 }} className='text-center'>
                    Action
                  </th>
                  {/* <th style={{ width: 20 }} className="text-center"><div style={{ float: 'left', width: '70%', marginTop: 6 }}>Select </div><div style={{ width: '15%', float: 'left' }}><input type="checkbox" checked={selChecked} onChange={(e) => handleCboxChange(e.target.checked)} /></div></th> */}
                </tr>
              </thead>
            </table>
            <div style={{ overflowY: 'scroll', height: '500px' }}>
              <table id='errorTable' className='tableLayout'>
                <colgroup>
                  <col width='20' />
                  <col width='40' />
                  <col width='20' />
                  <col width='20' />
                  <col width='30' />
                  <col width='100' />
                </colgroup>
                <tbody>
                  {lstStudent.map((obj, idx) => {
                    return (
                      (filterName == '' ||
                        obj.student.toLowerCase().indexOf(filterName) > -1) && (
                        <tr
                          onClick={e => handleSelStudentId(obj.studentId)}
                          className={
                            studentId === obj.studentId ? 'selected' : ''
                          }
                          key={idx}
                        >
                          <td>{obj.studentId}</td>
                          <td className='text-left'>{obj.student}</td>
                          <td className='text-left'>{obj.clsSection}</td>
                          <td className='text-left' style={{ paddingLeft: "15px" }}>{obj.billNo ? obj.billNo : "-"}</td>
                          <td className='text-center'>{obj.publishedOn}</td>

                          <td>
                            <label
                              style={{
                                textDecoration: 'underline',
                                cursor: 'pointer'
                              }}
                              onClick={e => viewBillForStud(obj.billNo)}
                              className='text-primary'
                            >
                              View
                            </label>
                            {' | '}
                            <label
                              style={{
                                textDecoration: 'underline',
                                cursor: 'pointer'
                              }}
                              onClick={e => publishBillForStud(obj.billNo)}
                              className='text-primary'
                            >
                              Publish
                            </label>
                            {' | '}
                            <label
                              style={{
                                textDecoration: 'underline',
                                cursor: 'pointer'
                              }}
                              onClick={e => generateNewBill(obj.studentId)}
                              className='text-primary'
                            >
                              New
                            </label>
                            {' | '}
                            <label
                              style={{
                                textDecoration: 'underline',
                                cursor: 'pointer'
                              }}
                              onClick={e => reGenForBillForStud(obj.billNo)}
                              className='text-primary'
                            >
                              Re-generate
                            </label>
                            {' | '}
                            <label
                              style={{
                                textDecoration: 'underline',
                                cursor: 'pointer'
                              }}
                              onClick={e =>
                                initiateCall(obj.billNo, obj.studentId)
                              }
                              className='text-primary'
                            >
                              Reminder
                            </label>
                            {' | '}
                            <label
                              style={{
                                textDecoration: 'underline',
                                cursor: 'pointer'
                              }}
                              onClick={e =>
                                initiateNotification(obj.billNo, obj.studentId)
                              }
                              className='text-primary'
                            >
                              Notification
                            </label>
                          </td>
                          {/* <td className='text-center'>
                                                <input type="checkbox" onClick={(e) => handleSelectStudent(obj.studentId, e.target.checked)} />
                                            </td> */}
                        </tr>
                      )
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div
            className='slider halfWidthSlider'
            style={{
              right: 0,
              opacity: 1,
              display: flg === true ? 'block' : 'none',
              zIndex: 10000
            }}
          >
            <div>
              <button
                type='button'
                className='btn btn-info text-center btnCloseDiv'
                onClick={e => closeSlider2()}
              >
                <span
                  id='close'
                  title='Close Slider'
                  className='glyphicon glyphicon-remove'
                ></span>
              </button>
            </div>
            <ViewBillPdf
              key={generateRandomNo}
            // closeSlider={closeSlider}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BillDisplaySlider
