import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'common/css/master.css'
import { getPtmSummaryListSvc } from './PtmFeedbackTableSvc.js'

const PtmFeedbackTable = props => {
  const [lstPtmFeedbackSummary, setLstPtmFeedbackSummary] = useState([]);
  const [lstPTMFeedbackTable, setLstFeedbackTable] = useState([]);
  const [filteredObjective, setFilteredObjective] = useState("");
  const [ptmId, setPtmId] = useState(0);

  useEffect(() => {
    getPtmSummaryListSvc(cbPtmListResponse)
  }, [])

  function cbPtmListResponse(data) {
    if (data.SvcStatus === 'Success') {
      setLstPtmFeedbackSummary(data.lstPtmSummary)
      setLstFeedbackTable(data.lstPtmSummary)
    } else {
      toast.error(data.SvcStatus)
    }
  }

  const filterObjective = (e) => {
    setFilteredObjective(e.target.value);
    filterTable(e.target.value);
  }

  const filterTable = (fObjective) => {
    let tempLst = [];
    tempLst = lstPtmFeedbackSummary.filter(
      (feedback) => feedback.objective.toLowerCase().includes(fObjective.toLowerCase())
    );

    setLstFeedbackTable(tempLst);
  }

  return (
    <div>
      <div className='col-sm-11' style={{ margin: '1% auto' }}>
        <table className='tableLayout1'>
          <thead>
            <tr>
              <th style={{ display: 'none' }}> Ptm Id </th>
              <th style={{ width: 30 }} className='text-center'>
                Date
              </th>
              <th style={{ width: 40 }} className='text-center'>
                <input onChange={(e) => filterObjective(e)} type="text" value={filteredObjective} className="form-control inp-search" placeholder="Objective" />
              </th>
              <th style={{ width: 60 }} className='text-center'>
                Timings
              </th>
              <th style={{ width: 30 }} className='text-center'>
                SMS
              </th>
              <th style={{ width: 30 }} className='text-center'>
                WhatsApp
              </th>
              <th style={{ width: 30 }} className='text-center'>
                Email
              </th>
              <th style={{ width: 30 }} className='text-center'>
                IVR
              </th>
              <th style={{ width: 30 }} className='text-center'>
                Classes
              </th>
              <th style={{ width: 30 }} className='text-center'>
                Students
              </th>
              <th style={{ width: 30 }} className='text-center'>
                Attendance
              </th>
              <th style={{ width: 30 }} className='text-center'>
                Feedback
              </th>
              <th style={{ width: 30 }} className='text-center'>
                Rating
              </th>
            </tr>
          </thead>
        </table>
        <div
          className='tableBodyWrapper'
          style={{
            height: '500px',
            overflowY: 'scroll',
            border: '1px solid #ccc'
          }}
        >
          <table id='ptmfeedbackTable' className='tableLayout'>
            <colgroup>
              <col width='30' />
              <col width='40' />
              <col width='60' />
              <col width='30' />
              <col width='30' />
              <col width='30' />
              <col width='30' />
              <col width='30' />
              <col width='30' />
              <col width='30' />
              <col width='30' />
              <col width='30' />
            </colgroup>
            <tbody>
              {lstPTMFeedbackTable.map((obj, idx) => {
                return (
                  <tr
                    className={obj.ptmId === ptmId ? 'selected' : ''}
                    key={idx}
                    onClick={e => {
                      setPtmId(obj.ptmId)
                      window.open(
                        '/clsPtmFeedback?ptmId=' +
                        obj.ptmId +
                        '&objective=' +
                        obj.objective +
                        '&timings=' +
                        obj.timings,
                        '_blank'
                      )
                    }}
                  >
                    <td className='no-display'>{obj.ptmId}</td>
                    <td className='text-center'>{obj.ptmDt}</td>
                    <td className='text-left'>{obj.objective}</td>
                    <td className='text-center'>{obj.timings}</td>
                    <td className='text-center'>{obj.flgSms ? 'Yes' : '-'}</td>
                    <td className='text-center'>
                      {obj.flgWhatsapp ? 'Yes' : '-'}
                    </td>
                    <td className='text-center'>
                      {obj.flgEmail ? 'Yes' : '-'}
                    </td>
                    <td className='text-center'>{obj.flgIvr ? 'Yes' : '-'}</td>
                    <td className='text-center'>{obj.classSectionCount}</td>
                    <td className='text-center'>{obj.studentCount}</td>
                    <td className='text-center'>{obj.attendancePct} %</td>
                    <td className='text-center'>{obj.feedbackPct} %</td>
                    <td className='text-center'>{obj.ratingSchool}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
export default PtmFeedbackTable
