import axios from "axios";
import lstModules from '../../../common/json/lstModules.json';
import 'react-toastify/dist/ReactToastify.css';


let httpURL = "";
const sysopstokenid = localStorage.getItem('sysopstokenid');
let queryString = require('querystring');
httpURL = lstModules['saLogin'].url + ":" + lstModules['saLogin'].port;

export const changePasswordSvc = ( oldPass, newPass, cbChangePass) => {
    const email = localStorage.getItem('email');
    // // console.log("email", email);
    var obj = { status: 'Success', message:"" };
    axios({
        url: httpURL + "/cooledsys/sysops/changepassword",
        method: "POST",
        headers: { 'tokenid': sysopstokenid },
        withCredentials: true,
        params:{ "email":email, "oldPassword":oldPass, "newPassword":newPass },
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbChangePass(obj);
    }).catch(() => {
        obj.status = 'Exception';
        obj.message = 'error';
        cbChangePass(obj);
    })
}

export const saLogoutSvc = ( cbLogout) => {
    // // console.log("tokenid",sysopstokenid);
    var obj = { status: 'Success', message:"" };
    axios({
        url: httpURL + "/cooledsys/sysops/logout",
        method: "POST",
        headers: { 'tokenid': sysopstokenid },
        withCredentials: true,
        params:{ "tokenId":sysopstokenid },
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cbLogout(obj);
    }).catch(() => {
        obj.status = 'Exception';
        obj.message = 'error';
        cbLogout(obj);
    })
}