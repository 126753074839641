import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
var httpURLStudent = lstModules['students'].url + ':' + lstModules['students'].port;
var tokenid = localStorage.getItem('tokenid');

function saveAttendanceSvc(dt, sectionId, lstTmpAttendance, cb) {
    var obj = { status: "Success" }
    var formData = new FormData();
    var attendanceObj = {
        dt: dt
        , sectionId: sectionId
        , lstAttendance: lstTmpAttendance
    }
    formData.append("attendance", JSON.stringify(attendanceObj));
    axios({
        url: httpURLStudent + "/sms/student/attendance/daily/mark_attendance",
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {
        var data = response.data
        obj.status = data.SvcStatus;
        obj.message = data.SvcMsg;
        cb(obj);
    }).catch((error) => {
        // // console.log("Error Occured")
        obj.status = 'Exception';
        obj.message = 'Service failed ' + error;
        cb(obj)
    });
}

function deleteAttendanceSvc(dt, sectionId, cb) {
    var obj = { status: "Success" }
    var formData = new FormData();
    formData.append("dt", dt);
    formData.append("sectionId", sectionId);
    axios({
        url: httpURLStudent + "/sms/student/attendance/daily/delete_attendance",
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {
        var data = response.data
        obj.status = data.SvcStatus;
        obj.message = data.SvcMsg;
        cb(obj);
    }).catch((error) => {
        // // console.log("Error Occured")
        obj.status = 'Exception';
        obj.message = 'Service failed ' + error;
        cb(obj)
    });
}
function informSvc(dt, studentId, cb) {
    var obj = { status: "Success" }
    axios({
        url: httpURLStudent + "/sms/student/attendance/daily/inform",
        method: "POST",
        params: { dt, studentId },
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {
        var data = response.data
        obj.status = data.SvcStatus;
        obj.message = data.SvcMsg;
        cb(obj);
    }).catch((error) => {
        // // console.log("Error Occured")
        obj.status = 'Exception';
        obj.message = 'Service failed ' + error;
        cb(obj)
    });
}

export { saveAttendanceSvc, informSvc, deleteAttendanceSvc }