import React, { useState, useEffect } from 'react';
import RowRenderer from './rowRenderer.js'; // Adjust the import path as needed

const GroupRenderer = ({ rowMap, lstSection, lstUnselectedSection, handleSectionChange }) => {
    const [localRowMap, setLocalRowMap] = useState(rowMap);

    useEffect(() => {
        setLocalRowMap(rowMap);
    }, [rowMap]);

    return (
        <>
            {Object.entries(localRowMap).map(([rowNo, rowData], groupIndex) => (
                <div key={`group-${groupIndex}`} className="form-group col-md-12 padding-remove">
                    <div className='col-md-3 text-right'>
                        <label className="control-label " style={{ padding: "7px 10px 0 0" }}>
                            {`Group-${groupIndex + 1}`}
                        </label>
                    </div>
                    <div className='col-md-9' style={{  }}>
                        <RowRenderer
                            rowData={rowData}
                            lstSection={lstSection}
                            lstUnselectedSection={lstUnselectedSection}
                            handleSectionChange={handleSectionChange}
                            groupIndex={groupIndex}
                        />
                    </div>
                </div>
            ))}
        </>
    );
};

export default GroupRenderer;
