import axios from 'axios';
import lstModules from 'common/json/lstModules.json';
var tokenid = localStorage.getItem("tokenid");
var httpURLAcdYr = lstModules['academicYear'].url + ":" + lstModules['academicYear'].port;
var httpURLCommon = lstModules['main'].url + ":" + lstModules['main'].port;

function getLstSectionSvc(fromAcdYrId, toAcdYrId, cb) {
    var obj = { status: "Success" }
    axios({
        url: httpURLAcdYr + '/sms/promote/section/list',
        method: "POST",
        params: { fromAcdYrId, toAcdYrId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {

        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === 'Success') {
            obj.sectionObj = response.data.sectionObj;
        } else {
            obj.sectionObj = [];
        }
        cb(obj)

    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = 'Service Failed ' + error;
        obj.sectionObj = {}
        cb(obj)
    });
}
function calcDueSvc(fromYrId, toYrId, cb) {
    var obj = { status: "Success" }
    axios({
        url: httpURLAcdYr + '/sms/acdyr/feedue/carry_forward',
        method: "POST",
        params: { 'oldAcdYrId': fromYrId, 'newAcdYrId': toYrId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {

        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cb(obj)

    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = 'Service Failed ' + error;
        cb(obj)
    });
}

function promoteSvc(pObj, cb) {
    var obj = { status: "Success" }

    axios({
        url: httpURLCommon + '/sms/promote/class/promote',
        method: "POST",
        params: {
            promote: JSON.stringify(pObj)
        },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {

        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cb(obj)

    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = 'Service Failed ' + error;
        cb(obj)
    });
}
export { getLstSectionSvc, promoteSvc }