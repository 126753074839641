import React from 'react';

const AnchorLbl = (props) => {
    return (
        <div className='row'>
            <div className='form-group col-sm-offset-4 col-sm-8 pull right'>
                <label className='control-label col-sm-4'>{props.dataValue ? "Verified" : ""}</label>
                <a className='pull-right' style={{ textDecoration: "underline", cursor: "pointer" }}>{props.anchorLabel}</a>
            </div>
        </div>
    );
};

export default AnchorLbl;
