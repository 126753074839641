import React, { useState, useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import DatePicker from "react-datepicker";

import "common/css/master.css";
import { dmyToDate, dtToDMY } from "common/functions/dateUtil";

import { saveImgSvc, getSectionListSvc, getCityListSvc, getLocalityListSvc, saveStudentDtlsSvc } from "./studentPersonalDetailsSvc";

const StudentPersonalDetails = (props) => {
    const { setLoader, setOpacity, studentId, lstClass, lstStates, personalDtls, studentPhotoPath } = props;

    const [enrollmentNo, setEnrollmentNo] = useState("");
    const [student, setStudent] = useState('');
    const [dob, setDob] = useState('');
    const [gender, setGender] = useState('');
    const [age, setAge] = useState('');
    const [blocked, setBlocked] = useState(false);
    const [lstCls, setLstCls] = useState([]);
    const [clsId, setClsId] = useState(0);
    const [sectionId, setSectionId] = useState(0);
    const [lstSection, setLstSection] = useState([]);
    const [doa, setDoa] = useState('');
    const [dor, setDor] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [cityId, setCityId] = useState('');
    const [stateId, setStateId] = useState('');
    const [localityId, setLocalityId] = useState('');
    const [pin, setPin] = useState('');
    const [lstState, setLstState] = useState([]);
    const [lstCity, setLstCity] = useState([]);
    const [clsSectionStudentId, setClsSectionStudentId] = useState(0);
    const [lstLocality, setLstLocality] = useState([]);
    const [email, setEmail] = useState('');
    const [emailStatus, setEmailStatus] = useState('');
    const [schoolId, setSchoolId] = useState('');
    const [lstGender, setLstGender] = useState([
        { gender: "M", genderName: "Male" },
        { gender: "F", genderName: "Female" },
    ]);
    const [photoPath, setPhotoPath] = useState(window.appURL + "/no-image.png");
    const fileInputRef = useRef(null);


    useEffect(() => {
        // // console.log("StudentPersonalDetails called");
        // // console.log("personalDtls", personalDtls);
        // // console.log("lstClass", lstClass);
        // // console.log("lstStates", lstStates);
        setLstCls(lstClass);
        setLstState(lstStates);
        if (!!studentPhotoPath) {
            setPhotoPath(studentPhotoPath);
        }
        if (studentId && personalDtls) {
            setStudentData(personalDtls);
        }
    }, [lstClass, lstStates, personalDtls, studentPhotoPath]);

    const createStudentObj = () => {
        const studentObj = {
            studentId: studentId,
            student: student,
            dob: dtToDMY(dob),
            admissionDt: dtToDMY(doa),
            regsDt: dtToDMY(dor),
            clsId: clsId,
            enrollmentNo: enrollmentNo,
            blocked: blocked,
            gender: gender,
            age: age,
            address1: address1,
            address2: address2,
            sectionId: sectionId,
            stateId: stateId,
            cityId: cityId,
            localityId: localityId,
            pin: pin,
            email: email,
            emailStatus: emailStatus,
            clsSectionStudentId: clsSectionStudentId
        };

        // // console.log(studentObj);
        return studentObj;
    };

    const validateDateFormat = (dateString) => {
        const regex = /^\d{2}-\d{2}-\d{4}$/; // dd-mm-yyyy
        return regex.test(dateString);
    };

    const setStudentData = (pData) => {
        // // console.log("setStudentData called, pData", pData);
        setStudent(pData.student);
        setGender(pData.gender);
        setAddress1(pData.address1);
        setAddress2(pData.address2);
        setStateId(pData.stateId);
        setCityId(pData.cityId);
        setLocalityId(pData.localityId);
        setPin(pData.pin);
        setEnrollmentNo(pData.enrollmentNo);
        setEmail(pData.email);
        setEmailStatus(pData.emailStatus);
        setBlocked(pData.blocked);
        setDoa(dmyToDate(pData.admissionDt));
        setDor(dmyToDate(pData.regsDt));
        setDob(dmyToDate(pData.dob));
        calcAge(dmyToDate(pData.dob));
        setClsSectionStudentId(pData.clsSectionStudentId);
        setClsId(pData.clsId);
        setSectionId(pData.sectionId);
        if (pData.clsId) {
            getSectionList(pData.clsId);
        }
        if (pData.stateId) {
            getCityList(pData.stateId);
        }
        if (pData.cityId) {
            getLocalityList(pData.localityId);
        }
    }


    const handleSubmit = () => {
        const studentObj = createStudentObj();
        if (!studentObj.student) {
            toast.warn('Student field is required.');
            return;
        }
    
        if (!studentObj.dob || !validateDateFormat(studentObj.dob)) {
            toast.warn('Date of Birth must be in dd-mm-yyyy format.');
            return;
        }
    
        if (!studentObj.admissionDt || !validateDateFormat(studentObj.admissionDt)) {
            toast.warn('Admission Date must be in dd-mm-yyyy format.');
            return;
        }
    
        if (!studentObj.regsDt || !validateDateFormat(studentObj.regsDt)) {
            toast.warn('Registration Date must be in dd-mm-yyyy format.');
            return;
        }
    
        if (studentObj.clsId <= 0) {
            toast.warn('Class ID must be greater than zero.');
            return;
        }
    
        if (studentObj.age <= 0) {
            toast.warn('Age must be greater than zero.');
            return;
        }
    
        if (!studentObj.gender) {
            toast.warn('Gender field is required.');
            return;
        }
    
        if (studentObj.stateId <= 0) {
            toast.warn('State ID must be greater than zero.');
            return;
        }
    
        if (studentObj.cityId <= 0) {
            toast.warn('City ID must be greater than zero.');
            return;
        }
        setLoader("block");
        setOpacity(0.5);
        // // console.log("newStudent in handleSubmit, studentObj", studentObj);
        saveStudentDtlsSvc(studentObj, saveStudentResponse);
    };

    const saveStudentResponse = (data) => {
        // // console.log('saveStudentResponse called, data', data);
        if (data.status === "Success") {
            toast.success(data.message);
            if (data.studentId) {
                const newPageUrl = "/studentDtls?studentId=" + data.studentId;
                window.location.href = newPageUrl;
            }
        } else {
            handleServiceError(data.message);
        }
        setLoader("none");
        setOpacity(1);
    }

    const handleDobChange = (pDob) => {
        // // console.log("handleDobChange called, pDob", pDob);
        setDob(pDob);
        calcAge(pDob);
    }

    const calcAge = pDob => {
        // // console.log(dob);
        if (typeof pDob == 'object');
        pDob = formatDate(pDob)
        var dd = pDob.split('-')[0]
        var mm = pDob.split('-')[1]
        var yr = parseInt(pDob.split('-')[2])
        pDob = new Date(yr, mm, dd, 0, 0)
        var d2 = new Date()
        var diff = d2.getTime() - pDob.getTime()
        var tempAge = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25))

        setAge(tempAge);
    }
    const formatDate = date => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear()

        if (month.length < 2) month = '0' + month
        if (day.length < 2) day = '0' + day

        return [day, month, year].join('-')
    }

    const handleClassChange = (pClsId) => {
        // // console.log('handleClassChange called, pClsId', pClsId);
        setClsId(pClsId);
        getSectionList(pClsId);
    }

    const handleStateChange = (pStateId) => {
        // // console.log("handleStateChange called, pStateId", pStateId);
        setStateId(pStateId);
        getCityList(pStateId);
    }

    const handleCityChange = (pCityId) => {
        // // console.log("handleCityChange called, pCityId", pCityId);
        setCityId(pCityId);
        getLocalityList(pCityId);
    }

    const handlePhotoChange = (e) => {
        // // console.log("handlePhotoCahnge called");
        if (e.target.files && e.target.files[0]) {
            setPhotoPath(URL.createObjectURL(e.target.files[0]));
            setLoader("block");
            setOpacity(0.5);
            saveImgSvc(e.target.files[0], 50, studentId, handleSaveImgResponse);
        }
    };

    const handleSaveImgResponse = (data) => {
        // // console.log("handleSaveImgResponse called");
        if (data.status === "Success") {
            toast.success(data.message);
        } else {
            handleServiceError(data.message);
        }
        setLoader("none");
        setOpacity(1);
    };

    const handlePhotoClick = () => {
        // // console.log("handlePhotoClick called");
        document.getElementById("img_photoId").click();
        // if (fileInputRef.current) {
        //     // // console.log("File input reference is defined");
        //     fileInputRef.current.click();
        // } else {
        //     console.error("File input reference is not defined");
        // }
    };

    const onError = () => {
        // // console.log("onError called in photo");
        setPhotoPath(window.appURL + "/no-image.png");
    };

    const handleServiceError = (svcMsg) => {
        if (svcMsg === "You are not Logged In") {
            toast.error("You are not logged in. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else if (svcMsg === "Server Timed Out, Login Again") {
            toast.error("Server timed out. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else {
            toast.error(svcMsg);
        }
    };

    const reverifyEmail = () => {
        // // console.log("reverifyEmail called");
    }
    const getSectionList = (clsId) => {
        getSectionListSvc(clsId, handleGetSectionListResponse);
    };


    const getCityList = (stateId) => {
        getCityListSvc(stateId, handleGetCityListResponse);
    };

    const getLocalityList = (cityId) => {
        getLocalityListSvc(cityId, handleGetLocalityListResponse);
    };

    const handleGetSectionListResponse = (data) => {
        const obj = data;
        if (obj.status === 'Success') {
            setLstSection(obj.lstSection);
            // Add any additional processing if needed
        } else {
            handleServiceError(obj.message);
        }
    };

    const handleGetCityListResponse = (response) => {
        // // console.log("response in handleGetCityListResponse, response", response);
        if (response.status === 'Success') {
            setLstCity(response.lstCity);
            // Add any additional processing if needed
        } else {
            handleServiceError(response.message);
        }
    };

    const handleGetLocalityListResponse = (response) => {
        if (response.status === 'Success') {
            setLstLocality(response.lstLocality);
            // Add any additional processing if needed
        } else {
            handleServiceError(response.message);
        }
    };



    return (
        <div className="col-sm-12 col-xs-12 padding-remove">
            <form
                id="studentAddForm"
                className="col-sm-12 form-horizontal">
                <div className="col-sm-12 section1">
                    <div className="">
                        <label className="control-label col-sm-9">
                            Personal Details
                        </label>
                        <label
                            className="control-label col-sm-2 pull-right"
                            id="enrollment_no"
                        >
                            <input
                                type="text"
                                id="lbl_enrollment_no"
                                className="form-control"
                                value={enrollmentNo}
                                onChange={(e) => setEnrollmentNo(e.target.value)}
                                placeholder="Enrollment No."
                            />
                        </label>
                    </div>
                </div>
                <div className="col-sm-12 shadowShow  padding-remove">
                    <div className="col-sm-5">
                        <div className="form-group">
                            <label className="control-label col-sm-4">
                                Name:
                            </label>
                            <div className="col-sm-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Name"
                                    id="inp_student"
                                    value={student}
                                    onChange={(e) => setStudent(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label col-sm-4">
                                Date of birth:
                            </label>
                            <div className="col-sm-5">
                                <DatePicker
                                    id="inp_dob"
                                    className="form-control"
                                    placeholderText="Date of birth"
                                    dateFormat="dd-MM-yyyy"
                                    dropdownMode="select"
                                    selected={dob}
                                    onChange={(date) => handleDobChange(date)}
                                    autoComplete="off"
                                    showMonthDropdown
                                    showYearDropdown
                                    adjustDateOnChange
                                />
                            </div>
                            <label
                                className="control-label col-sm-2"
                                id="lbl_age"
                            >
                                Age: {age}
                            </label>
                        </div>

                        <div className="form-group">
                            <label className="control-label col-sm-4">
                                Gender:
                            </label>
                            <div className="col-sm-6">
                                <select
                                    className='form-control'
                                    // style={{border:"1px solid #ccc"}}
                                    value={gender}
                                    onChange={e => setGender(e.target.value)}
                                >
                                    <option value={""}>Gender</option>
                                    {lstGender.map((obj, idx) => {
                                        return (
                                            <option key={idx} value={obj.gender}>
                                                {obj.genderName}
                                            </option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-4">
                                Date of Admission:
                            </label>
                            <div className="col-sm-6">
                                <DatePicker
                                    id="inp_doa"
                                    className="form-control"
                                    placeholderText="Date of admission"
                                    dateFormat="dd-MM-yyyy"
                                    dropdownMode="select"
                                    selected={doa}
                                    onChange={(date) => setDoa(date)}
                                    autoComplete="off"
                                    showMonthDropdown
                                    showYearDropdown
                                    adjustDateOnChange
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-4">
                                Date of Registration:
                            </label>
                            <div className="col-sm-6">
                                <DatePicker
                                    id="inp_dor"
                                    className="form-control"
                                    placeholderText="Date of registration"
                                    dateFormat="dd-MM-yyyy"
                                    dropdownMode="select"
                                    selected={dor}
                                    onChange={(date) => setDor(date)}
                                    autoComplete="off"
                                    showMonthDropdown
                                    showYearDropdown
                                    adjustDateOnChange
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-4">
                                Email:
                            </label>
                            <div className="col-sm-6">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="inp_sEmail"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <i id="emailStatusIconS" aria-hidden="true"></i>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-4 emailStatusHide">
                                Email Status:
                            </label>
                            <div className="col-sm-5 emailStatusHide">
                                <select
                                    className="form-control "
                                    id="inp_sEmailStatus"
                                    disabled
                                />
                            </div>
                            <a
                                className="reverify "
                                id="sReverify"
                                title="Reverify Student Email"
                                onClick={reverifyEmail}
                                href="#!"
                            >
                                Reverify
                            </a>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-4">
                                Class:
                            </label>
                            <div className="col-sm-6">
                                <select
                                    className='form-control '
                                    // style={{border:"1px solid #ccc"}}
                                    value={clsId}
                                    onChange={e => handleClassChange(e.target.value)}
                                >
                                    <option value={""}>Class</option>
                                    {lstCls ?
                                        lstCls.map((obj, idx) => {
                                            return (
                                                <option key={idx} value={obj.clsId}>
                                                    {obj.cls}
                                                </option>
                                            )
                                        }) : null}
                                </select>
                            </div>
                            <i id="emailStatusIconS" aria-hidden="true"></i>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-4">
                                Section:
                            </label>
                            <div className="col-sm-6">
                                <select
                                    className="form-control"
                                    value={sectionId}
                                    onChange={(e) => setSectionId(e.target.value)}
                                >
                                    <option value={0}>Section</option>
                                    {lstSection ?
                                        lstSection.map((section) => (
                                            <option
                                                key={section.sectionId}
                                                value={section.sectionId}>
                                                {section.section}
                                            </option>
                                        )) : null
                                    }
                                </select>
                            </div>
                        </div>
                        <i id="emailStatusIconS" aria-hidden="true"></i>
                    </div>
                    <div className="col-sm-5">
                        <div className="form-group">
                            <label className="control-label col-sm-4">
                                Address 1:
                            </label>
                            <div className="col-sm-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Address 1"
                                    id="inp_address1"
                                    value={address1}
                                    onChange={(e) => setAddress1(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-4">
                                Address 2:
                            </label>
                            <div className="col-sm-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Address 2"
                                    id="inp_address2"
                                    value={address2}
                                    onChange={(e) => setAddress2(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-4">
                                State:
                            </label>
                            <div className="col-sm-6">
                                <select
                                    className="form-control"
                                    value={stateId}
                                    onChange={(e) => handleStateChange(e.target.value)}
                                >
                                    <option value={0}>State</option>
                                    {lstState ?
                                        lstState.map((state) => (
                                            <option
                                                key={state.stateId}
                                                value={state.stateId}>
                                                {state.state}
                                            </option>
                                        )) : null
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-4">
                                City:
                            </label>
                            <div className="col-sm-6">
                                <select
                                    className='form-control'
                                    // style={{border:"1px solid #ccc"}}
                                    value={cityId}
                                    onChange={e => handleCityChange(e.target.value)}
                                >
                                    <option value={""}>City</option>
                                    {lstCity ?
                                        lstCity.map((city, idx) => {
                                            return (
                                                <option key={idx} value={city.cityId}>
                                                    {city.city}
                                                </option>
                                            )
                                        }) : null
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-4">
                                Locality:
                            </label>
                            <div className="col-sm-6">
                                <select
                                    className="form-control"
                                    value={localityId}
                                    onChange={(e) => setLocalityId(e.target.value)}
                                >
                                    <option value={0}>Locality</option>
                                    {lstLocality ?
                                        lstLocality.map((locality) => (
                                            <option
                                                key={locality.localityId}
                                                value={locality.localityId}>
                                                {locality.locality}
                                            </option>
                                        )) : null
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-4">Pin:</label>
                            <div className="col-sm-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Pin"
                                    id="inp_pin"
                                    value={pin}
                                    onChange={(e) => setPin(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-4">
                                Blocked:
                            </label>
                            <div className="col-sm-6">
                                <input
                                    type="checkbox"
                                    className="form-control"
                                    id="inp_blocked"
                                    checked={blocked}
                                    onChange={(e) => setBlocked(e.target.checked)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-2" style={{ background: "#fff", height: "100%", boxSizing: "border-box", paddingTop: "1%" }}>
                        <input
                            type="file"
                            id="img_photoId"
                            name="img"
                            onChange={handlePhotoChange}
                            className="hidden"
                            accept="image/*"
                            disabled={studentId == 0} // `isDisabled` is a boolean that controls whether the input is disabled
                        />

                        <img
                            src={photoPath}
                            alt="img"
                            onClick={() => handlePhotoClick()}
                            height="160"
                            width="160"
                            style={{ width: 160, border: "1px solid #ddd" }}
                            onError={onError}
                        />
                    </div>
                </div>
                <div className="form-group" style={{ marginTop: 50 }}>
                    <div className="col-sm-2 text-center pull-right">
                        <button
                            type="button"
                            onClick={() => handleSubmit()}
                            className="btn btn-info"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );

}
export default StudentPersonalDetails;