const SliderType = Object.freeze({
  NONE: '0',
  SLIDER_ENQUIRY_DTLS: '10',
  SLIDER_FOLLOWUP_DTLS: '20',
  SLIDER_REGISTER: '30',
  SLIDER_REGISTER_FEE: '35',
  POPUP_REGISTER_FEE: '1',
  SLIDER_REGISTER_FEE_PAYMENT: '36',
  SLIDER_ADMISSION_FEE: '37',
  SLIDER_ADMISSION_FEE_PAYMENT: '38',
  SLIDER_SCHEDULE_TEST: '40',
  SLIDER_TEST_RESULT: '50',
  SLIDER_SCHEDULE_INTERVIEW: '60',
  SLIDER_INTERVIEW_RESULT: '70',
  SLIDER_CLOSE: '80'
})

export default SliderType
