import React from 'react';

const LblTxt = (props) => {
    return (
        <div className='row'>
            <label className='control-label col-sm-4'>{props.label}</label>
            <div className='form-group col-sm-8'>
                <input type={props.dataType} className='form-control' defaultValue={props.dataValue} onChange={(e)=>props.onChange(e.target.value)}>
                </input>
            </div>
        </div>
    );
};

export default LblTxt;
