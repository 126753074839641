import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import $ from 'jquery';
import 'jquery-ui';
import '../css/staffLeaves.css';
import 'common/css/master.css';
import axios from 'axios';
import Header from 'common/js/header/header.js';
import { toast, ToastContainer } from 'react-toastify';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

var httpURL = '';
var queryString = require('querystring');
var lstLeave = [];
var lstReason = [];
var lstStaff = [];
var tokenid = localStorage.getItem("tokenid");
var screenId = 22;

class StaffLeaves extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: 'none',
            opacity: 1,
            flg: true,
            fromDt: null,
            toDt: null,
            actionedOn: null,
            tokenid: tokenid,
            paidLeave: null,
            fdpl: null,
            ldpl: null
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChange1 = this.handleChange1.bind(this);
        this.handleActionDate = this.handleActionDate.bind(this);
    }

    handleChange(date) {
        this.setState({ fromDt: date, });
    }

    handleChange1(date) {
        this.setState({ toDt: date });
    }
    handleActionDate(date) {
        this.setState({ actionedOn: date });
    }

    componentDidMount = () => {


        this.getLeavesList();
        $('.leavesActionAddFormWrap , #deleteButton').hide();
        $('#rejectedReason').attr('disabled', true);
        $("input[name=leaveType][value=" + "Full-Day" + "]").attr('checked', 'checked');

        lstReason = [{
            'reasonId': 1, 'reason': 'Medical-Self',
        },
        {
            'reasonId': 2, 'reason': 'Medical-Dependent',
        },
        {
            'reasonId': 3, 'reason': 'Medical-others',
        },
        {
            'reasonId': 4, 'reason': 'Birthday-Self',
        },
        {
            'reasonId': 5, 'reason': 'Birthday-Dependent',
        },
        {
            'reasonId': 6, 'reason': 'Travel',
        },
        {
            'reasonId': 7, 'reason': 'Leisure',
        },
        {
            'reasonId': 8, 'reason': 'Others',
        },

        ];

        this.fillReasonCmb();
        axios({
            url: httpURL + '/sms/teacher/list',
            method: "POST",
            params: { screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstStaff = response.data.lstTeacher;
                this.fillStaffCmb();
            }
            else {
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            } this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });

    }
    getLeavesList = () => {

        axios({
            url: httpURL + '/sms/leaves/list',
            method: "POST",
            params: { screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstLeave = response.data.lstLeave;
                this.setLeavesTable();
            }
            else {
                if (response.data.SvcMsg == 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            } this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });

    }

    fillReasonCmb() {
        $('#reasonCmb').empty();
        $('#reasonCmb').prepend($("<option> </option>").val(0).html('Reason'));
        for (var i = 0; i < lstReason.length; i++) {
            var reasonId = lstReason[i].reasonId;
            var reason = lstReason[i].reason;
            $('#reasonCmb').append($("<option> </option>").val(reasonId).html(reason));
        }
    }
    fillStaffCmb() {
        $('#staffCmb,#actionStaffCmb').empty();
        $('#staffCmb,#actionStaffCmb').prepend($("<option> </option>").val(0).html('Staff'));
        for (var i = 0; i < lstStaff.length; i++) {
            var teacherId = lstStaff[i].teacherId;
            var teacher = lstStaff[i].teacher;
            $('#staffCmb,#actionStaffCmb').append($("<option> </option>").val(teacherId).html(teacher));
        }
    }

    setLeavesTable = () => {
        $('#leavesBodyTable tr').remove();
        var table = document.getElementById('leavesTable');
        var tbody = document.getElementById('leavesBodyTable');

        for (var i = 0; i < lstLeave.length; i++) {
            var leaveId = lstLeave[i].leaveId;
            var staff = lstLeave[i].staff;
            var role = lstLeave[i].role;
            var fromDt = lstLeave[i].fromDt;
            var toDt = lstLeave[i].toDt;
            var days = lstLeave[i].days;
            var appliedOn = lstLeave[i].appliedOn;
            var actionedOn = lstLeave[i].actionedOn;
            var status = lstLeave[i].status;
            var paidLeave = lstLeave[i].lwp

            var row = document.createElement('tr');
            row.id = leaveId;

            row.onclick = (e) => {
                $(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');
                this.setLeaveForm($(e.target).closest('tr').attr('id'));
                $('.leavesActionAddFormWrap , #deleteButton').show();
                this.setState({
                    flg: false,
                })
            }

            this.addTD(row, leaveId, 'no-display');
            this.addTD(row, staff, 'text-center');
            this.addTD(row, role, 'text-center');
            this.addTD(row, fromDt, 'text-center');
            this.addTD(row, toDt, 'text-center');
            this.addTD(row, days, 'text-center');
            this.addTD(row, appliedOn, 'text-center');
            this.addTD(row, appliedOn, 'text-center');
            this.addTD(row, (paidLeave === true ? 'LWP' : '-'), 'text-center');
            this.addTD(row, status, 'text-center');

            tbody.append(row);
        }
        table.append(tbody);
    }

    setLeaveForm = (leaveId) => {
        var leaveData = this.getLeaveData(leaveId);
        // // console.log(leaveData);
        this.setState({ paidLeave: leaveData.lwp })
        this.setState({ fdpl: leaveData.flgSpecialCaseFirstDayPostLunch })
        this.setState({ ldpl: leaveData.flgSpecialCaseLastDayPreLunch })
        // // console.log('paidLeave::' + leaveData.paidLeave)
        $("input[name=specialCase]").attr('checked', false);

        $('#staffCmb').val(leaveData.staffId);

        if (leaveData.fromDt === "00-00-0000" || leaveData.fromDt === null) {
            this.setState({
                fromDt: "",
            });
        }
        else {
            var date = leaveData.fromDt;
            date = date.split('-');
            var setDate = date.reverse().join();
            setDate = setDate.replace(/,/g, '-');
            var fromDt = new Date(setDate);
            this.setState({
                fromDt: fromDt,
            });
        }
        if (leaveData.toDt === "00-00-0000" || leaveData.toDt === null) {
            this.setState({
                toDt: "",
            });
        }
        else {
            var date = leaveData.toDt;
            date = date.split('-');
            var setDate = date.reverse().join();
            setDate = setDate.replace(/,/g, '-');
            var toDt = new Date(setDate);
            this.setState({
                toDt: toDt,
            });
        }
        var getReasonId = this.reasonData(leaveData.reason);
        $('#reasonCmb').val(getReasonId);

        $('#actionStaffCmb').val(leaveData.actionStaffId);
        $("input[name=leaveType][value=" + leaveData.leaveType + "]").attr('checked', 'checked');
        // var specialCase = leaveData.specialCase[0];
        // if (specialCase === "First Day-Post Lunch") {
        //     $("#firstDayPostLunch").attr('checked', 'checked');
        // }
        // else if (specialCase === "Last Day Pre-Lunch") {
        //     $("#lastDayPreLunch").attr('checked', 'checked');
        // }
        if (leaveData.flgSpecialCaseFirstDayPostLunch === true) {
            $("#firstDayPostLunch").attr('checked', 'checked');
        }
        if (leaveData.flgSpecialCaseLastDayPreLunch === true) {
            $("#lastDayPreLunch").attr('checked', 'checked');
        }
        if (leaveData.actionedOn === "00-00-0000" || leaveData.actionedOn === "-") {
            this.setState({
                actionedOn: "",
            });
        }
        else {
            var date = leaveData.actionedOn;
            date = date.split('-');
            var setDate = date.reverse().join();
            setDate = setDate.replace(/,/g, '-');
            var actionedOn = new Date(setDate);
            this.setState({
                actionedOn: actionedOn,
            });
        }
        $('#rejectedReason').val(leaveData.rejectedReason);
        $("input[name=approveReject][value=" + leaveData.approveReject + "]").attr('checked', 'checked');



    }
    reasonData(reason) {
        for (var i = 0; i < lstReason.length; i++) {
            if (reason === lstReason[i].reason)
                return lstReason[i].reasonId;
        }
    }

    getLeaveData(leaveId) {
        for (var i = 0; i < lstLeave.length; i++) {
            if (parseInt(leaveId) === lstLeave[i].leaveId)
                return lstLeave[i];
        }
    }

    addLeaves = () => {
        var leave = {};
        var leaveId = 0;

        var staffId = $('#staffCmb').find('option:selected').val();
        var fromDt = $('#fromDt').val();
        var toDt = $('#toDt').val();
        var reasonId = $('#reasonCmb').find('option:selected').val();
        var reason = $('#reasonCmb').find('option:selected').text();
        var leaveType = $('input[name=leaveType]:checked').val();
        var specialCase = [];
        var spclCase = $('input[name=specialCase]:checked').val();
        var paidLeave = $('input[name=paidLeave]:checked').val();

        specialCase.push(spclCase);


        if (parseInt(staffId) === 0) {
            toast.warning('Please Select Staff');
            $('#staffCmb').focus();
            return false;
        }
        if (fromDt === "") {
            toast.warning('Please Enter Start Date');
            $('#fromDt').focus();
            return false;
        }
        if (toDt === "") {
            toast.warning('Please Enter End Date');
            $('#toDt').focus();
            return false;
        }
        if (leaveType === undefined) {
            toast.warning('Please Select Leave Type');
            return false;
        }
        if (spclCase === undefined) {
            specialCase = []
        }
        if (parseInt(reasonId) === 0) {
            toast.warning('Please Select Reason');
            $('#reasonCmb').focus();
            return false;
        }

        if (this.state.flg) {
            leaveId = 0
        }
        else {
            leaveId = $('#leavesBodyTable tr.selected').find('td:eq(0)').text();
        }
        var flgSpecialCaseLastDayPreLunch = $('#lastDayPreLunch').prop('checked');
        var flgSpecialCaseFirstDayPostLunch = $('#firstDayPostLunch').prop('checked');
        leave = {
            'leaveId': parseInt(leaveId), 'staffId': staffId, 'fromDt': fromDt, 'toDt': toDt, reason, 'flgSpecialCaseLastDayPreLunch': flgSpecialCaseLastDayPreLunch, 'flgSpecialCaseFirstDayPostLunch': flgSpecialCaseFirstDayPostLunch, leaveType, 'lwp': !!paidLeave
        }

        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/leaves/save',
            queryString.stringify({ 'leave': JSON.stringify(leave), screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getLeavesList();
                this.addLeavesReady();

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });

    }
    addStatus = () => {
        var leaveStatus = {};
        var leaveId = $('#leavesBodyTable tr.selected').find('td:eq(0)').text();
        var actionStaffId = $('#actionStaffCmb').find('option:selected').val();
        var actionedOn = $('#actionedOn').val();
        var approveReject = $('input[name=approveReject]:checked').val();
        var rejectedReason = $('#rejectedReason').val();


        if (approveReject === undefined) {
            toast.warning('Please Select Approve / Reject');
            return false;
        }
        if (parseInt(actionStaffId) === 0) {
            toast.warning('Please Select Staff');
            $('#actionStaffCmb').focus();
            return false;
        }
        if (actionedOn === "") {
            toast.warning('Please Enter Start Date');
            $('#actionedOn').focus();
            return false;
        }



        leaveStatus = {
            leaveId, actionStaffId, actionedOn, approveReject, rejectedReason
        }

        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/leaves/status/save',
            queryString.stringify({ 'leaveStatus': JSON.stringify(leaveStatus), screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getLeavesList();
                this.addLeavesReady();


            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });

    }

    addTD(row, val, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var text = document.createTextNode(val);
        cell.append(text);
        row.append(cell);
    }

    addLeavesReady = () => {
        this.setState({ ldpl: false, fdpl: false, paidLeave: false })
        $('#leavesAddForm').trigger('reset');
        $('#leavesBodyTable tr').removeClass('selected');
        $('.leavesActionAddFormWrap , #deleteButton').hide();
        $("input[name=leaveType]").attr('checked', false);
        $("input[name=specialCase]").attr('checked', false);
        $("input[name=leaveType][value=" + "10" + "]").attr('checked', 'checked');
        this.setState({
            flg: true,
            fromDt: null,
            toDt: null,
            actionedOn: null,
        })
    }
    enableRejectReason = () => {

        var approveReject = $('input[name=approveReject]:checked').val();
        if (approveReject === 'Reject') {
            $('#rejectedReason').attr('disabled', false);
        }
        else if (approveReject === 'Approve') {
            $('#rejectedReason').attr('disabled', true);
        }
    }


    deleteLeaves = () => {
        var leaveId = $('#leavesBodyTable tr.selected').find('td:eq(0)').text();
        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/leaves/delete?leaveId=' + leaveId,
            queryString.stringify({ screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getLeavesList();
                this.addLeavesReady();

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });

    }


    getWingId = (wingId) => {
        // // console.log("wingId" + wingId);
    }


    render() {
        httpURL = lstModules['leaves'].url + ":" + lstModules['leaves'].port;
        return (
            <div style={{ height: '100%' }}>
                <Header wingFromChild={this.getWingId} />
                <ToastContainer autoClose={3000} hideProgressBar />
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: this.state.loader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>
                <div className="mainContainer Timing" style={{ opacity: this.state.opacity }}>
                    <div className="col-md-11 col-centered">
                        <div className="col-md-7  col-xs-11 periodTypeWrap  padding-remove">
                            <table className="tableLayout1 periodType" style={{ width: this.state.width }}>
                                <thead>
                                    <tr>
                                        <th style={{ display: 'none' }}> Leave Id </th>
                                        <th style={{ width: 40 }}> Staff </th>
                                        <th style={{ width: 40 }}> Role </th>
                                        <th style={{ width: 40 }}> From Dt </th>
                                        <th style={{ width: 40 }}> To Dt </th>
                                        <th style={{ width: 40 }}> Days </th>
                                        <th style={{ width: 40 }}> Applied On </th>
                                        <th style={{ width: 40 }}> Actioned On </th>
                                        <th style={{ width: 40 }}> LWP </th>
                                        <th style={{ width: 40 }}> Status </th>

                                    </tr>
                                </thead>
                            </table>
                            <div className="leavesTableDiv" style={{ height: '400px', overflowY: 'scroll', border: '1px solid #ccc' }} >
                                <table id="leavesTable" className="tableLayout">
                                    <colgroup>
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                    </colgroup>
                                    <tbody id="leavesBodyTable"></tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-xs-1 ">
                            <span id="addLeaves" title="Add Leaves" onClick={this.addLeavesReady} className="addIcon glyphicon glyphicon-plus-sign"></span>
                        </div>
                        <div className="col-md-4 col-xs-8 padding-remove leavesFormWrap" >
                            <form id="leavesAddForm" className="form-horizontal formWrapper">
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Staff<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <select autoComplete="off" id="staffCmb" type="text" className="form-control " />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label col-sm-5" >From Dt.<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <DatePicker id="fromDt"
                                            className='form-control'
                                            placeholderText="Enter From Date"
                                            selected={this.state.fromDt}
                                            onChange={this.handleChange}
                                            dateFormat="dd-MM-yyyy"
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label col-sm-5" >To Dt.<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <DatePicker id="toDt"
                                            className='form-control'
                                            placeholderText="Enter To Date"
                                            selected={this.state.toDt}
                                            onChange={this.handleChange1}
                                            dateFormat="dd-MM-yyyy"
                                        />
                                    </div>
                                </div>

                                <div className="form-group radioCheckboxWrap">
                                    <div className="col-sm-12 text-center" style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 10, }}>Leave Type <span style={{ color: 'red', marginLeft: 2, fontSize: 20 }}>*</span></div>
                                    <div className="col-sm-4 radioButtonsWrap">
                                        <input type="radio" id="fullDay" name="leaveType" value={10} />
                                        <label htmlFor="Full-Day">Full-Day</label>
                                    </div>
                                    <div className="col-sm-4 radioButtonsWrap">
                                        <input type="radio" id="preLunch" name="leaveType" value={20} />
                                        <label htmlFor="Pre-Lunch">Pre-Lunch</label>
                                    </div>
                                    <div className="col-sm-4 radioButtonsWrap">
                                        <input type="radio" id="postLunch" name="leaveType" value={30} />
                                        <label htmlFor="Post-Lunch">Post-Lunch</label>
                                    </div>

                                </div>
                                <div className="form-group radioCheckboxWrap">
                                    <div className="col-sm-12 text-center" style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 10, }}>Special Case</div>
                                    <div className="col-sm-6 radioButtonsWrap">
                                        <input type="checkbox" id="firstDayPostLunch" name="firstDayPostLunch" defaultChecked={this.state.fdpl} />
                                        <label htmlFor="Full-Day">First Day-Post Lunch</label>
                                    </div>
                                    <div className="col-sm-6 radioButtonsWrap">
                                        <input type="checkbox" id="lastDayPreLunch" name="lastDayPreLunch" defaultChecked={this.state.ldpl} />
                                        <label htmlFor="Pre-Lunch">Last Day Pre-Lunch</label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-sm-6 col-md-offset-3 radioButtonsWrap">
                                        <input type="checkbox" id="paidLeave" name="paidLeave" defaultChecked={this.state.paidLeave} />
                                        <label htmlFor="paidLeave">Leave without Pay   </label>

                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Reason<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <select onChange={this.changeReason} className="form-control" style={{ width: '100%' }} id="reasonCmb"></select>
                                    </div>
                                </div>

                                <div className="form-group" >
                                    <div className="col-sm-12 text-center userButtonWrap" >
                                        <button type="button" style={{ marginTop: 20 }} title="Save" onClick={this.addLeaves} className="btn btn-info">Save</button>
                                        <button id="deleteButton" type="button" style={{ marginTop: 20 }} title="Delete" onClick={this.deleteLeaves} className="btn btn-danger">Delete</button>
                                    </div>
                                </div>
                            </form>
                            <form id="leavesActionAddForm" className="form-horizontal leavesActionAddFormWrap formWrapper">
                                <h4 className="text-center statusHeader">Status</h4>
                                <div className="form-group radioCheckboxWrap">
                                    <div className="col-sm-6 radioButtonsWrap">
                                        <input onChange={this.enableRejectReason} type="radio" id="Approve" name="approveReject" value="Approve" />
                                        <label htmlFor="Approved">Approve</label>
                                    </div>
                                    <div className="col-sm-6 radioButtonsWrap">
                                        <input onChange={this.enableRejectReason} type="radio" id="Reject" name="approveReject" value="Reject" />
                                        <label htmlFor="Reject">Reject</label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Action By<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <select onChange={this.changeActionBy} className="form-control" style={{ width: '100%' }} id="actionStaffCmb"></select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Action On<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <DatePicker id="actionedOn"
                                            className='form-control'
                                            placeholderText="Enter Action Date"
                                            selected={this.state.actionedOn}
                                            onChange={this.handleActionDate}
                                            dateFormat="dd-MM-yyyy"
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Rejection Reason<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <textarea id="rejectedReason" className="form-control" placeholder="Rejection Reason" />
                                    </div>
                                </div>
                                <div className="form-group" >
                                    <div className="col-sm-12 text-center userButtonWrap" >
                                        <button type="button" style={{ marginTop: 20 }} title="Save" onClick={this.addStatus} className="btn btn-info">Save</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        );
    }



}
export default StaffLeaves;