import QRCode from 'react-qr-code';

const StudentFeedbackQR = (props) => {

    const { feedbackQRLink, hideFeedbackQR, name, cls, sec } = props;

    return (
        <div style={{  position: "absolute", top:0, left:"15%", backgroundColor: "#F8F8F8", width: "70%", height:"70%", zIndex: 1001 }}>
            <div style={{textAlign:"left", padding:10, fontSize:"initial", fontWeight:700 }}>
                {name}
                {' ( ' + cls + ' - ' + sec +' ) '}
                <button type="button"
                style={{textAlign:"right"}}
                    title="Close Feedback QR"
                    onClick={hideFeedbackQR}
                    className='btn btn-info text-center pull-right'>X</button>
            </div>
            <div style={{ backgroundColor: "#F8F8F8" }}>
                <QRCode
                    style={{ width: "60%", height: "80%", paddingTop:10 }}
                    xlinkTitle="ptm feedback"
                    value={feedbackQRLink}

                />
            </div>
        </div>
    )
}

export default StudentFeedbackQR;