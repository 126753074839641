import React, { useEffect, useState } from 'react';

const ClassList = (props) => {
    const [lstCls, setLstCls] = useState(props.lstCls);

    useEffect(() => {
        setLstCls(props.lstCls)
    }, [props.lstCls]);


    function handleChange(e) {
        props.classClick(e.target.value);
    }

    return (
        <div className="container1 ">
            <div className="col-sm-12 padding-remove">

                <select 
                autoComplete="off"
                    name="classId" 
                    id="classesCmb"
                    type="text"
                    className="form-control "
                    style={{ height: 37 }}
                    value={props.classId}
                    onChange={handleChange}
                >
                    <option>Class</option>
                    {!!lstCls && lstCls.map((obj) => (
                        <option value={obj.classId}
                            key={obj.classId} id={obj.classId} >{obj.classLabel}</option>
                    ))}
                </select>
            </div>
        </div>
    )
}
export default ClassList;