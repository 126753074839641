import lstModules from 'common/json/lstModules.json';
import axios from 'axios';


const screenId = 11;
const tokenid = localStorage.getItem('tokenid');
const httpURL = lstModules['fees'].url + ":" + lstModules['fees'].port;
const httpURLCommon = lstModules['main'].url + ":" + lstModules['main'].port;




export const getLstFeeStudentSvc = (classId, classSectionId, cbLstFeeStudent) => {
    // // console.log("feeDueSvc called, classId", classId, ", classSectionId", classSectionId);
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/sms/fee/student/list',
        method: "POST",
        headers: { 'tokenid': tokenid },
        params: { 'screenId': screenId, 'classId': classId, 'classSectionId': classSectionId },
        withCredentials: true,
    }).then((response) => {
        // // console.log("response",response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            // // console.log("response",response);
            obj.lstFeeStudent = response.data.lstFeeStudent;
        }
        cbLstFeeStudent(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbLstFeeStudent(obj);
    })
}