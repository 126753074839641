import React, { useEffect, useState } from 'react'

const ClassList = props => {
  const [lstCls, setLstCls] = useState(props.lstCls)
  const [state, setState] = useState()

  useEffect(() => {
    setLstCls(props.lstCls)
  }, [props])

  function handleChange (e) {
    setState({ ...state, [e.target.name]: e.target.value })
    props.classClick(e.target.value)
  }

  return (
    <div className='container1'>
      <div className='col-sm-2 padding-remove'>
        <select
          autoComplete='off'
          name='classId'
          id='classesCmb'
          type='text'
          className='form-control '
          style={{ height: 30 }}
          value={props.selClsId}
          onChange={handleChange}
        >
          <option value='0'>Class</option>
          {lstCls.map(obj => (
            <option value={obj.clsId} key={obj.clsId} id={obj.clsId}>
              {obj.cls}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}
export default ClassList
