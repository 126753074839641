import { useEffect, useState } from "react";
import { useActionData } from "react-router-dom";
import "../../css/library.css";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { getBookDtls, getGenreList, getClassList, getMediaType, getStatusList, saveBookDtlsSvc, getIssueDetails } from "./booksSvc";


function AddEditBook(props) {

    const { bookNo, copyNo, closeSlider, copyBook, setTableData, id, printBooks } = props;

    const [title, setTitle] = useState("");
    const [author, setAuthor] = useState("");
    const [edition, setEdition] = useState("");
    const [publisher, setPublisher] = useState("");
    const [pageCount, setPageCount] = useState(0);
    const [genreId, setGenreId] = useState(0);
    const [flgSyllabus, setFlgSyllabus] = useState(false);
    const [subject, setSubject] = useState("");
    const [classId, setClassId] = useState(0);
    const [flgStudent, setFlgStudent] = useState(false);
    const [flgStaff, setFlgStaff] = useState(false);
    const [studentIssuePeriod, setStudentIssuePeriod] = useState(0);
    const [staffIssuePeriod, setStaffIssuePeriod] = useState(false);
    const [flgEighteen, setFlgEighteen] = useState(false);
    const [flgDigital, setFlgDigital] = useState(false);
    const [mediaTypeId, setMediaTypeId] = useState(0);
    const [location, setLocation] = useState("");
    const [link, setLink] = useState("");
    const [cost, setCost] = useState("");
    const [securityDeposit, setSecurityDeposit] = useState(0);
    const [isbn, setIsbn] = useState("");
    const [tags, setTags] = useState("");
    const [statusId, setStatusId] = useState(0);
    const [studentFine, setStudentFine] = useState(0);
    const [staffFine, setStaffFine] = useState(0);

    const [lstGenre, setLstGenre] = useState([])
    const [lstType, setLstType] = useState([]);
    const [lstStatus, setLstStatus] = useState([]);
    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [lstCls, setLstCls] = useState("");
    const [editId, setEditId] = useState(id);



    useEffect(() => {
        // // console.log("Add/edit page useEffect hook called");
        setFormDetails();
        if (id) {
            setBookDetails();
        }
        else {
            setIssueDetails();
        }
    }, [editId])

    const setFormDetails = () => {
        setGenreList();
        setClassList();
        setMediaType();
        setStatusList();
    }

    const setBookDetails = () => {
        setLoader("block");
        setOpacity(0.5);
        getBookDtls(bookDetailsResponse, bookNo, copyNo);
    }

    const bookDetailsResponse = (data) => {
        var obj = data;
        if (obj.status === "Success") {
            setTitle(obj.bookDtls.title);
            setAuthor(obj.bookDtls.author);
            setEdition(obj.bookDtls.edition);
            setPublisher(obj.bookDtls.publisher);
            setPageCount(obj.bookDtls.pageCount);
            setGenreId(obj.bookDtls.genreId);
            setFlgSyllabus(obj.bookDtls.flgSyllabus);
            setSubject(obj.bookDtls.subject);
            setClassId(obj.bookDtls.classId);
            setFlgStudent(obj.bookDtls.flgStudent);
            setFlgStaff(obj.bookDtls.flgStaff);
            setStudentIssuePeriod(obj.bookDtls.studentIssuePeriod);
            setStaffIssuePeriod(obj.bookDtls.staffIssuePeriod);
            setFlgEighteen(obj.bookDtls.flgEighteen);
            setFlgDigital(obj.bookDtls.flgDigital);
            setMediaTypeId(obj.bookDtls.mediaTypeId);
            setLocation(obj.bookDtls.location);
            setLink(obj.bookDtls.link);
            setCost(obj.bookDtls.cost);
            setSecurityDeposit(obj.bookDtls.securityDeposit);
            setIsbn(obj.bookDtls.isbn);
            setTags(obj.bookDtls.tags);
            setStatusId(obj.bookDtls.statusId);
        }
        else {

            if (data.SvcMsg === "You are not logged in") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else if (data.SvcMsg === "Your Session is expired, login again") {
                toast.error(data.SvcMsg);
                // // console.log("session expired");
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(data.SvcMsg);
            }
        }
        setLoader("none");
        setOpacity(1);
    }


    const setIssueDetails = () => {
        setLoader("block");
        setOpacity(0.5);
        getIssueDetails(issueDetailsResponse);
    }

    const issueDetailsResponse = (data) => {
        var obj = data;
        if (obj.status === "Success") {
            setStudentIssuePeriod(obj.issueDtls.studentIssuePeriod);
            setStaffIssuePeriod(obj.issueDtls.staffIssuePeriod);
            setStudentFine(obj.issueDtls.studentFine);
            setStaffFine(obj.issueDtls.staffFine);
        }
        else {

            if (data.SvcMsg === "You are not logged in") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else if (data.SvcMsg === "Your Session is expired, login again") {
                toast.error(data.SvcMsg);
                // // console.log("session expired");
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(data.SvcMsg);
            }
        }
        setLoader("none");
        setOpacity(1);
    }


    const setGenreList = () => {
        setLoader("block");
        setOpacity(0.5);
        getGenreList(genreListResponse);
    }

    const genreListResponse = (data) => {
        var obj = data;
        if (obj.status === "Success") {
            setLstGenre(obj.lstGenre);
        }
        else {

            if (data.SvcMsg === "You are not logged in") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else if (data.SvcMsg === "Your Session is expired, login again") {
                toast.error(data.SvcMsg);
                // // console.log("session expired");
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(data.SvcMsg);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    const setMediaType = () => {
        setLoader("block");
        setOpacity(0.5);
        getMediaType(mediaTypeResponse);
    }

    const mediaTypeResponse = (data) => {
        var obj = data;
        if (obj.status === "Success") {
            setLstType(obj.lstType);
        }
        else {

            if (data.SvcMsg === "You are not logged in") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else if (data.SvcMsg === "Your Session is expired, login again") {
                toast.error(data.SvcMsg);
                // // console.log("session expired");
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(data.SvcMsg);
            }
        }
        setLoader("none");
        setOpacity(1);
    }


    const setStatusList = () => {
        setLoader("block");
        setOpacity(0.5);
        getStatusList(statusListResponse);
    }

    const statusListResponse = (data) => {
        var obj = data;
        if (obj.status === "Success") {
            setLstStatus(obj.lstStatus);
            // // console.log("obj.lstStatus", obj.lstStatus)
        }
        else {

            if (data.SvcMsg === "You are not logged in") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else if (data.SvcMsg === "Your Session is expired, login again") {
                toast.error(data.SvcMsg);
                // // console.log("session expired");
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(data.SvcMsg);
            }
        }
        setLoader("none");
        setOpacity(1);
    }


    const setClassList = () => {
        setLoader("block");
        setOpacity(0.5);
        getClassList(classListResponse);
    }

    const classListResponse = (data) => {
        var obj = data;
        if (obj.status === "Success") {
            setLstCls(obj.lstClass);
        }
        else {

            if (data.SvcMsg === "You are not logged in") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else if (data.SvcMsg === "Your Session is expired, login again") {
                toast.error(data.SvcMsg);
                // // console.log("session expired");
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(data.SvcMsg);
            }
        }
        setLoader("none");
        setOpacity(1);
    }


    const saveBookDtls = () => {
        const bookObj = {
            title: title,
            author: author,
            edition: edition,
            publisher: publisher,
            pageCount: pageCount,
            genreId: genreId,
            flgSyllabus: flgSyllabus,
            subject: subject,
            classId: classId,
            flgStudent: flgStudent,
            flgStaff: flgStaff,
            studentIssuePeriod: studentIssuePeriod,
            staffIssuePeriod: staffIssuePeriod,
            flgEighteen: flgEighteen,
            flgDigital: flgDigital,
            mediaTypeId: mediaTypeId,
            location: location,
            link: link,
            cost: cost,
            securityDeposit: securityDeposit,
            isbn: isbn,
            tags: tags,
            statusId: statusId
        }
        if (editId === 1) {
            bookObj.bookNo = bookNo;
            bookObj.copyNo = copyNo;
        }
        // // console.log("bookObj before checking conditions", bookObj);



        if (!bookObj.title) {
            toast.warning('Please fill title of book. Fields with * are necessary.');
        }
        else if (!bookObj.author) {
            toast.warning('Please fill author of book. Fields with * are necessary.');
        }
        else if (!bookObj.tags) {
            toast.warning('Please fill tags for book. Fields with * are necessary.');
        }
        else if (!bookObj.statusId) {
            toast.warning('Please select status of book. Fields with * are necessary.');
        }
        else {
            setLoader("block");
            setOpacity(0.5);
            // // console.log("passed bookObj to saveBookDtlsSvc ", bookObj);
            saveBookDtlsSvc(saveBookDtlsResponse, bookObj);
        }
    }

    const saveBookDtlsResponse = (data) => {
        var obj = data;
        if (obj.status === "Success") {
            toast.success(obj.message);
            closeSlider();
            setTableData();
        } else {

            if (data.SvcMsg === "You are not logged in") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else if (data.SvcMsg === "Your Session is expired, login again") {
                toast.error(data.SvcMsg);
                // // console.log("session expired");
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(data.SvcMsg);
            }
        }
        setLoader('none');
        setOpacity(1);
        // closeSlider();
    }

    const getGenreName = (genId) => {
        if (!!lstGenre)
            for (var i = 0; i < lstGenre.length; i++) {
                if (lstGenre[i].genreId === genId) {
                    return lstGenre[i].genre;
                }
            }
        return "Select Genre"
    }

    const getClassName = (classId) => {
        if (!!lstCls)
            for (var i = 0; i < lstCls.length; i++) {
                if (lstCls[i].clsId === classId) {
                    return lstCls[i].cls;
                }
            }
        return "Select Class"
    }


    const getMediaTypeName = (tpId) => {
        // // console.log("lstType", lstType)
        if (!!lstType)
            for (var i = 0; i < lstType.length; i++) {
                if (lstType[i].typeId === tpId) {
                    return lstType[i].type;
                }
            }
        return "Select Media Type"
    }

    const getStatusName = (stsId) => {
        if (!!lstStatus)
            for (var i = 0; i < lstStatus.length; i++) {
                if (lstStatus[i].statusId === stsId) {
                    return lstStatus[i].status;
                }
            }
        return "Select Status"
    }


    const deleteBook = () => {
    }

    return (
        <div className="col-xs-12 col-centered  ">
            <ToastContainer autoClose={3000} hideProgressBar />
            <div>
                <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={closeSlider} style={{ right: "60%" }}>
                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                </button>
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader, opacity: opacity }} >
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div >
            </div>
            <div className="formPage"  >
                <div className="form-wrapper" style={{ height: '98vh', width: '100%', margin: '5px auto', paddingTop: '50px', background: '#fff', border: '1px solid #ccc', borderRadius: 10, display: 'flex' }}>
                    <form className='form form-horizontal' style={{ pointerEvents: loader === "none" ? "auto" : "none", width: '100%' }} >
                        <div>
                            <div className="blocks">
                                <div className="twoCol" >
                                    <div className="form-group col-md-6 ">
                                        <label className="control-label col-md-4 text-left">Title<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                        <div className="col-sm-8 padding-remove " >
                                            <input autoComplete="off" type="text" className="form-control" defaultValue={title} onChange={(e) => setTitle(e.target.value)} required />
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6 pull-right">
                                        <label className="control-label col-md-4 text-left">Author<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                        <div className="col-sm-8 padding-remove" >
                                            <input autoComplete="off" type="text" className="form-control" defaultValue={author} onChange={(e) => setAuthor(e.target.value)} required />
                                        </div>
                                    </div>
                                </div>
                                <div className="twoCol">
                                    <div className="form-group col-md-6">
                                        <label className="control-label col-md-4 text-left">Publisher</label>
                                        <div className="col-sm-8 padding-remove" >
                                            <input autoComplete="off" type="text" className="form-control" defaultValue={publisher} onChange={(e) => setPublisher(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6 pull-right">
                                        <label className="control-label col-md-4 text-left">Edition</label>
                                        <div className="col-sm-8 padding-remove" >
                                            <input autoComplete="off" type="text" className="form-control" defaultValue={edition} onChange={(e) => setEdition(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="twoCol">
                                    <div className="form-group col-md-6">
                                        <label className="control-label col-md-4 text-left">Genre</label>
                                        <div className="col-sm-8 padding-remove" >
                                            <select
                                                // value={genreId}
                                                className="form-control "
                                                defaultValue={genreId}
                                                onChange={(e) => setGenreId(e.target.value)}
                                            >
                                                <option
                                                    value={genreId}>
                                                    {getGenreName(genreId)}
                                                </option>
                                                {lstGenre &&
                                                    lstGenre.map((option) => (
                                                        <option
                                                            key={option.genreId}
                                                            value={option.genreId}>
                                                            {option.genre}
                                                        </option>
                                                    ))
                                                }

                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6 pull-right">
                                        <label className="control-label col-md-4 text-left" >Page Count</label>
                                        <div className="col-sm-8 padding-remove" >
                                            <input autoComplete="off" type="number" className="form-control" defaultValue={pageCount} onChange={(e) => setPageCount(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="blocks">
                                <div className="col-md-12" style={{ padding: 0 }}>
                                    <div className="form-group col-md-6">
                                        <label className="control-label col-md-4 text-left">Syllabus</label>
                                        <div  >
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={flgSyllabus}
                                                onChange={(e) => setFlgSyllabus(e.target.checked)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {
                                    flgSyllabus && (
                                        <div className="twoCol">
                                            <div className="form-group col-md-6">
                                                <label className="control-label col-md-4 text-left">Subject</label>
                                                <div className="col-sm-8 padding-remove" >
                                                    <input autoComplete="off" type="text" className="form-control" defaultValue={subject} onChange={(e) => setSubject(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6 pull-right">
                                                <label className="control-label col-md-4 text-left">Class</label>
                                                <div className="col-sm-8 padding-remove" >
                                                    <select
                                                        // value={genreId}
                                                        className="form-control "
                                                        defaultValue={classId}
                                                        onChange={(e) => setClassId(e.target.value)}
                                                    >
                                                        <option
                                                            value={classId}>
                                                            {getClassName(classId)}
                                                        </option>
                                                        {lstCls &&
                                                            lstCls.map((option) => (
                                                                <option
                                                                    key={option.clsId}
                                                                    value={option.clsId}>
                                                                    {option.cls}
                                                                </option>
                                                            ))
                                                        }

                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                <div className="twoCol col-md-12 padding-remove">
                                    <div className="form-group col-md-3">
                                        <label className="control-label col-md-9 text-left">For Student</label>
                                        <div >
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={flgStudent}
                                                onChange={(e) => setFlgStudent(e.target.checked)}
                                            />
                                        </div>
                                    </div>
                                    {
                                        flgStudent && (
                                            <div className="form-group col-md-9 ">
                                                <label className="control-label col-md-3 text-right">Issue Period</label>
                                                <div className="col-sm-4 padding-remove" >
                                                    <input autoComplete="off" type="number" className="form-control" defaultValue={studentIssuePeriod} onChange={(e) => setStudentIssuePeriod(e.target.value)} placeholder="Student Issue Period" />
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="twoCol col-md-12 padding-remove">
                                    <div className="form-group col-md-3">
                                        <label className="control-label col-md-9 text-left">For Staff</label>
                                        <div>
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={flgStaff}
                                                onChange={(e) => setFlgStaff(e.target.checked)}
                                            />
                                        </div>
                                    </div>
                                    {
                                        flgStaff && (
                                            <div className="form-group col-md-9 ">
                                                <label className="control-label col-md-3 text-right">Issue Period</label>
                                                <div className="col-sm-4 padding-remove" >
                                                    <input autoComplete="off" type="number" className="form-control" defaultValue={staffIssuePeriod} onChange={(e) => setStaffIssuePeriod(e.target.value)} placeholder="Staff Issue Period" />
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="twoCol col-md-12 padding-remove" >
                                    <div className="form-group col-md-3 ">
                                        <label className="control-label col-md-9 text-left">Digital Only</label>
                                        <div>
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={flgDigital}
                                                onChange={(e) => setFlgDigital(e.target.checked)}
                                            />
                                        </div>
                                    </div>
                                    {
                                        flgDigital && (
                                            <div className="form-group col-md-9 ">
                                                <label className="control-label col-md-3 text-right">Media Type</label>
                                                <div className="col-sm-4 padding-remove" >
                                                    <select
                                                        // value={genreId}
                                                        className="form-control"
                                                        defaultValue={mediaTypeId}
                                                        onChange={(e) => setMediaTypeId(e.target.value)}
                                                    >
                                                        <option
                                                            value={mediaTypeId}>
                                                            {getMediaTypeName(mediaTypeId)}
                                                        </option>
                                                        {lstType &&
                                                            lstType.map((option) => (
                                                                <option
                                                                    key={option.typeId}
                                                                    value={option.typeId}>
                                                                    {getMediaTypeName(option.typeId)}
                                                                </option>
                                                            ))
                                                        }

                                                    </select>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="twoCol col-md-12 padding-remove" >
                                    {
                                        !flgDigital && (
                                            <div className="form-group col-md-6 ">
                                                <label className="control-label col-md-4 text-left">Book Location</label>
                                                <div className="col-sm-8 padding-remove" >
                                                    <input autoComplete="off" type="text" className="form-control" defaultValue={location} onChange={(e) => setLocation(e.target.value)} />
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        flgDigital && (
                                            <div className="form-group col-md-6 ">
                                                <label className="control-label col-md-4 text-left">Book Link</label>
                                                <div className="col-sm-8 padding-remove" >
                                                    <input autoComplete="off" type="text" className="form-control" defaultValue={link} onChange={(e) => setLink(e.target.value)} />
                                                </div>
                                            </div>
                                        )
                                    }
                                    <div className="form-group col-md-6 pull-right">
                                        <label className="control-label col-md-4 text-left">18 +</label>
                                        <div className="col-sm-8 padding-remove" >
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={flgEighteen}
                                                onChange={(e) => setFlgEighteen(e.target.checked)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="blocks">
                                <div className="twoCol" >
                                    <div className="form-group col-md-6 ">
                                        <label className="control-label col-md-4 text-left">Book Cost</label>
                                        <div className="col-sm-8 padding-remove" >
                                            <input autoComplete="off" type="text" className="form-control" defaultValue={cost} onChange={(e) => setCost(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6 pull-right">
                                        <label className="control-label col-md-4 text-left">Security Deposit</label>
                                        <div className="col-sm-8 padding-remove" >
                                            <input autoComplete="off" type="text" className="form-control" defaultValue={securityDeposit} onChange={(e) => setSecurityDeposit(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="twoCol" >
                                    <div className="form-group col-md-6 ">
                                        <label className="control-label col-md-4 text-left">ISBN</label>
                                        <div className="col-sm-8 padding-remove" >
                                            <input autoComplete="off" type="text" className="form-control" defaultValue={isbn} onChange={(e) => setIsbn(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6 pull-right">
                                        <label className="control-label col-md-4 text-left">Status<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                        <div className="col-sm-8 padding-remove" >
                                            <select
                                                // value={genreId}
                                                className="form-control"
                                                defaultValue={statusId || (lstStatus && lstStatus.length > 0 ? lstStatus[0].statusId : '')}
                                                onChange={(e) => setStatusId(e.target.value)}
                                                required
                                            >
                                                <option
                                                    value={statusId}>
                                                    {getStatusName(statusId)}
                                                </option>
                                                {lstStatus &&
                                                    lstStatus.map((option) => (
                                                        <option
                                                            key={option.statusId}
                                                            value={option.statusId}>
                                                            {getStatusName(option.statusId)}
                                                        </option>
                                                    ))
                                                }

                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="blocks padding-remove">
                                <div className="col-md-12 padding-remove" style={{ marginLeft: 14 }} >
                                    <div className="col-md-1 padding-remove">
                                        <label className="control-label text-left">Tags<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    </div>
                                    <div className="col-md-11 " style={{ paddingLeft: 42 }}  >
                                        <textarea
                                            defaultValue={tags}
                                            onChange={(e) => setTags(e.target.value)}
                                            autoComplete="off"
                                            type="text"
                                            className="form-control "
                                            placeholder="Tags"
                                            required />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-9 pull-right" >
                                <div className=" col-md-8 form-group text-center multiButtonWrap save-print-buttons " >
                                    <div>
                                        <button type="button" style={{ marginTop: 45, width: 100 }} onClick={() => saveBookDtls()} className="btn btn-success">Save</button>
                                    </div>
                                    {(editId === 1) &&
                                        <div>
                                            <button type="button" style={{ marginTop: 45, width: 100 }} onClick={() => printBooks()} className="btn btn-danger">Print</button>
                                        </div>
                                    }
                                </div>
                                {(editId === 1) &&
                                    <div className="col-md-4 form-group text-center multiButtonWrap save-print-buttons " >
                                        <div style={{ marginTop: 40, width: 100, textAlign: "left", padding: 10, fontSize: "initial", fontWeight: 600 }}>
                                            <a className="discountTd" onClick={() => { copyBook(bookNo, copyNo, title) }}>Copy</a>
                                        </div>
                                        <div style={{ marginTop: 40, width: 100, textAlign: "left", padding: 10, fontSize: "initial", fontWeight: 600 }}>
                                            <a className="discountTd" onClick={deleteBook}>Delete</a>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div >
        </div >
    )
}

export default AddEditBook;