import React, { useState, useEffect } from "react";

import "common/css/master.css";

import "../css/schoolProfile.css"; // Importing the CSS file

const SchoolProfileTab = (props) => {
    const { mapTabs, tabId, setTabId } = props;

    const [selTabId, setSelTabId] = useState(tabId); // Initialize selTabId with tabId prop

    useEffect(() => {
        // // console.log("SchoolProfileTab component called");
        // // console.log("tabId passed:", tabId);
        // // console.log("mapTabs passed:", mapTabs);
        setSelTabId(tabId); // Update the local state with the passed tabId
    }, [tabId, mapTabs]); // Dependency array to trigger on tabId or mapTabs change

    const handleTabClick = (selectedTabId) => {
        setSelTabId(selectedTabId); // Update the local state when a tab is clicked
        setTabId(selectedTabId);    // Call the prop method to update the parent's tabId
    };

    return (
        <div className="col-xl-12 col-ls-12 col-md-12 col-sm-12 col-xs-12">
            <div className="school-profile-tabs">
                <ul className="nav nav-tabs school-profile-nav-tabs"> {/* Updated class name */}
                    {Object.keys(mapTabs).map((id) => (
                        <li
                            key={id}
                            className={`nav-item ${selTabId === id ? "active" : ""}`}
                            onClick={() => handleTabClick(id)}
                        >
                            <a className="nav-link">{mapTabs[id]}</a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default SchoolProfileTab;
