import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';

const FullScreenConfetti = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <Confetti width={windowDimensions.width} height={windowDimensions.height} />;
};

export default FullScreenConfetti;
