import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import "../css/subjectAllocation.css";
import Loader from "common/components/Loader";
import Header from "common/js/header/header";

import SubjectTeacherAllocationCtrl from "./subjectTeacherAllocationCtrl";
import SubjectAllocationHeader from "./subjectAllocationHeader";

const SubjectAllocationCtrl = () => {
    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);

    const [syllabusId, setSyllabusId] = useState(0); // New state for syllabus

    useEffect(() => {
        // console.log("SubjectAllocationCtls called");
    }, []);



    return (
        <div>
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <Loader loader={loader} position="fixed" />
            <div
                className="col-md-12 col-xs-12 col-centered padding-remove"
                style={{
                    cursor: loader === "none" ? 'pointer' : 'none',
                    pointerEvents: loader === "none" ? 'auto' : 'none',
                    opacity: opacity
                }}
            >
                <div className="col-ls-10 col-md-10 col-sm-10 col-xs-10 col-centered padding-remove" style={{ marginTop: "15px" }}>
                    <SubjectAllocationHeader
                        setLoader={setLoader}
                        setOpacity={setOpacity}
                        syllabusId={syllabusId}
                        setSyllabusId={setSyllabusId}
                    />
                </div>
                <SubjectTeacherAllocationCtrl
                    loader={loader}
                    setLoader={setLoader}
                    opacity={opacity}
                    setOpacity={setOpacity}
                    syllabusId={syllabusId}
                />
            </div>
        </div>
    );
};

export default SubjectAllocationCtrl;
