import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
const httpURL = lstModules['payrollSetup'].url + ':' + lstModules['payrollSetup'].port;
var tokenid = localStorage.getItem('tokenid');

function getPfListSvc(callBack) {

    axios(
        {
            url: httpURL +"/sms/pf/list",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
           
        }
    ).catch((error) => {
        // // console.log("Test List Catch error")
    });
}
function savePfSvc(pPfObj,callBack){
    var formData = new FormData();
  formData.append("pf", JSON.stringify(pPfObj));
  axios({
    url:httpURL +"/sms/pf/save",
    method: "POST",
    data: formData,
    headers: { 'tokenid': tokenid },
    withCredentials: true
  }).then((response) => {

    var data = response.data
    callBack(data);
  }).catch((error) => {
    console.error(error) 
});
}
export {getPfListSvc,savePfSvc}