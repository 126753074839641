import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios'
import "../css/payrollSetupCtrl.css"
import lstModules from 'common/json/lstModules.json'
const httpURL = lstModules['payrollSetup'].url + ':' + lstModules['payrollSetup'].port;
var tokenid = localStorage.getItem('tokenid');
function DeductionSlider(props){

  const [deductId,setDeductId]=useState(0)
  const [deductCode,setDeductCode]=useState(0)
  const [label,setLabel]=useState("")
  const [lstGradeId,setLstGradeId]=useState([])
  const [lstRoleId,setLstRoleId]=useState([])
  const [lstRole,setLstRole]=useState([])
  const [flg,setFlg]=useState(false)
  const [lstGrade,setLstGrade]=useState([])
  const [loader, setLoader] = useState("none");
  useEffect(()=>{
    setLoader('block')
        // // console.log('deductObj'+ JSON.stringify(props.deductObj))
        getLstGrade(cbGetLstGrade)
        getLstRole(cbGetLstRole)
        setDeductId(props.deductId)
        setDeductCode(props.deductObj.deductionCode)
        setLabel(props.deductObj.deductionLabel)
        setFlg(props.deductObj.flgStdDeduction)
  },[])
  function getLstGrade(callback){
    axios(
        {
            url: httpURL +"/sms/deduction/grade/list",
            method: 'POST',
            params: {deductionId:props.deductId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callback(data)
            
  
        }
    ).catch((error) => {
        console.error(error)
    });
  }
  function getLstRole(callback){
    axios(
        {
            url: httpURL +"/sms/deduction/role/list",
            method: 'POST',
            params: {deductionId:props.deductId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callback(data)
            
  
        }
    ).catch((error) => {
        console.error(error)
    });
  }
  function cbGetLstGrade(data){
    var tempArr=[]
    for(var i=0;i<data.lstGrade.length;i++){
        if(data.lstGrade[i].selected===true){
          tempArr.push(data.lstGrade[i].gradeId)
        }
    }
    setLstGrade(tempArr)
    setLstGradeId(data.lstGrade)
    setLoader('none')
  }
  function cbGetLstRole(data){
    const tempArr = new Array();

      for(var i=0;i<data.lstRole.length;i++){
          if(data.lstRole[i].selected===true){
            tempArr.push(data.lstRole[i].roleId)
          }
      }

      setLstRole(tempArr)
      setLstRoleId(data.lstRole)
      setLoader('none')
  }
  function handleDeductCodeChange(param){
    setDeductCode(param)
  }
  function handleLabelChange(param){
    setLabel(param)
  }
  function handleFlgChange(param){
    setLabel(param)
  }
  function handleGradeCBoxChange(gradeId,status){
    var lstTempArr=lstGrade
    if(status && !lstTempArr.includes(gradeId)){
      lstTempArr.push(gradeId)
      setLstGrade(lstTempArr)
      // // console.log("checked " + lstTempArr)
    }
    else {

      lstTempArr.splice(lstTempArr.indexOf(gradeId), 1)
      setLstGrade(lstTempArr)
      // // console.log("unchecked" + lstTempArr)
      //returnSelEnqId()
  }

  }
  function handleRoleCBoxChange(roleId,status){
      var lstTempArr=lstRole
      if(status && !lstTempArr.includes(roleId)){
        lstTempArr.push(roleId)
        setLstRole(lstTempArr)
        // // console.log("checked " + lstTempArr)
      }
      else {

        lstTempArr.splice(lstTempArr.indexOf(roleId), 1)
        setLstRole(lstTempArr)
        // // console.log("unchecked" + lstTempArr)
        //returnSelEnqId()
    }
  }

  function saveResult(callback){
    setLoader("block");
    var deduction={
        deductionId:deductId,
        deductionCode:deductCode,
        deductionLabel:label,
        flgStdDeduction:flg,
        lstGradeId:lstGrade,
        lstRoleId:lstRole,
    }
    var formData = new FormData();
    formData.append("deduction", JSON.stringify(deduction))
    // // console.log('TypeOf deductionObj    ::'+typeof(deduction))
    // // console.log('TypeOf deductionId     ::'+typeof(deduction.deductionId))
    // // console.log('TypeOf deductionCode   ::'+typeof(deduction.deductionCode))
    // // console.log('TypeOf deductionLabel  ::'+typeof(deduction.deductionLabel))
    // // console.log('TypeOf flgStdDeduction ::'+typeof(deduction.flgStdDeduction))
    // // console.log('TypeOf lstGradeId      ::'+typeof(deduction.lstGradeId))
    // // console.log('TypeOf lstRoleId       ::'+typeof(deduction.lstRoleId))

    axios(
        {
            url: httpURL +"/sms/deduction/save",
            method: 'POST',
            data:formData,
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            // // console.log(data)
            callback(data)
            

        }
    ).catch((error) => {
        console.error(error)
    });

  }
  function cbSaveAllowance(data){
    setLoader("none");
    if(data.SvcStatus==='Success'){
        toast.success(data.SvcMsg)
        props.generateRandomNo()
    }
    else{
        toast.error(data.SvcMsg)
    }

  }
    return(<form    
    className="form form-horizontal"
    style={{
    pointerEvents: loader === "none" ? "auto" : "none",
      height: "100%",
      width: "100%",
      overflowX: 'hidden',
      background: "#fff",
      border: "1px solid #ccc",
      borderRadius: 10,
    }}>
         <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>
                <div className="form-group col-md-12">
                    <label className="control-label col-md-5">Deduction Code:<span className="red-color">*</span></label>
                    <div className='col-sm-6 padding-remove'>
                        <input type='text' className="form-control" defaultValue={props.deductObj.deductionCode} onChange={(e) => handleDeductCodeChange(e.target.value)} required></input>
                    </div>
                </div>
                <div className="form-group col-md-12">
                    <label className="control-label col-md-5">Label:<span className="red-color">*</span></label>
                    <div className='col-sm-6 padding-remove'>
                        <input type='text' className="form-control" defaultValue={props.deductObj.deductionLabel} onChange={(e) => handleLabelChange(e.target.value)} required></input>
                    </div>
                </div>
                <div className="form-group col-md-12">
                    <label className="control-label col-md-5">Special Deduction:<span className="red-color">*</span></label>
                    <div className='col-sm-6 padding-remove'>
                        <input type='checkbox' className="form-control" defaultChecked={props.deductObj.flgStdDeduction} onChange={(e) => handleFlgChange(e.target.value)} required></input>
                    </div>
                </div>
                <div className=" col-md-12 col-centered">
                <div className="col-md-6">
                    <table className="col-sm-12">
                        <thead>
                            <tr><th colSpan="2">Grade</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lstGradeId.map((obj,idx)=>{
                                return(
                            <tr key={idx}>
                                <td style={{textAlign:'center'}}>
                                   {obj.grade}</td>
                                    <td><input
                                     onClick={(e) => handleGradeCBoxChange(obj.gradeId, e.target.checked)}
                                        defaultChecked={obj.selected}
                                        type="checkbox"/>
                                </td>
                            </tr>)
})}
                        </tbody>
                    </table>
                </div>
                <div className="col-md-6">
                    <table className="tableLayout1" >
                        <thead >
                            <tr><th>Roles</th>
                            </tr>
                        </thead>
                        </table>
                        <div className='tablebodyWrapper2'>
                        <table className='tableLayout'>
                        <tbody>
                            {lstRoleId.map((obj,idx)=>{
                                return(
                            <tr key={idx}>
                                <td style={{textAlign:'center'}}>
                                   {obj.role}</td>
                                    <td><input
                                        defaultChecked={obj.selected}
                                        onClick={(e) => handleRoleCBoxChange(obj.roleId, e.target.checked)}
                                        type="checkbox"/>
                                </td>
                            </tr>)
})}
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>

                <div className="form-group col-md-12">
                    <div className="col-md-offset-6 col-md-1 padding-remove">
                        <button type="button" className="btn btn-info" onClick={(e) => saveResult(cbSaveAllowance)}>Save</button>
                    </div>
                </div>


    </form>)
}
export default DeductionSlider;
