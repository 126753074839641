import React, { useEffect, useState } from "react";
import 'common/css/master.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import TestHeader from "./testHeader";
import TestAvatar from "./testAvatar";
import TestAvatarDescription from "./testAvatarDesc";
import "../css/studentTest.css";
import { avatarListSvc, joinTestSvc, studentDtlsSvc } from "./testSvc"



const StudentWeeklyTest = () => {

    const params = new URLSearchParams(window.location.search);
    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [flgOtp, setFlgOtp] = useState(true);

    const [schoolId, setSchoolId] = useState(0);
    const [studentOtp, setStudentOtp] = useState("");
    const [studentName, setStudentName] = useState("");
    const [studentId, setStudentId] = useState(0);
    const [testId, setTestId] = useState(0);
    const [selAvatarId, setSelAvatarId] = useState(0);
    const [avatar, setAvatar] = useState("");
    const [avatarDesc, setAvatarDesc] = useState("");
    const [nikName, setNikName] = useState("");
    const [mapAvatar, setMapAvatar] = useState(new Map());
    const [avatarPath, setAvatarPath] = useState("");
    const [leaderboardCount, setLeaderboardCount] = useState(0);
    

    useEffect(() => {
        // // console.log("inside useEffect in testStudent.js");
        const otp = params.get("otp");
        const tId = params.get("tId");
        const schId = params.get("schId");
        // // console.log("otp in params", otp);
        // // console.log("tId in params", tId);
        // // console.log("schId in params", schId);
        if(!!otp && !!tId && !!schId){
            setTestId(tId);
            setStudentOtp(otp);
            setSchoolId(schId);
            testOtpSubmit(otp, tId, schId);
        } else {
            setSchoolId(31270);
        }
        getAvatarList(schId || 31270);
    }, []);
    
    const onClickNext = () => {
        joinTest();
    }

    const joinTest = () => {
        // // console.log("joinTest called");
        if (selAvatarId === 0) {
            toast.warn("please select an avatar!");
            return;
        }
        if (nikName === "") {
            toast.warn("please enter Nikname!");
            return;
        }
        if (studentName.toLowerCase().includes(nikName.toLowerCase())) {
            toast.warn('your nikname should not be part of your name!');
            return;
        }
        const words = studentName.split(/\s+/);
        for (let word of words) {
            if (nikName.toLowerCase().includes(word.toLowerCase())) {
                toast.warn(`You can't use your first name or last name in your nikname`);
                return;
            }
        }
        const joinObj = {
            "testId": testId,
            "studentId": studentId,
            "avatarId": selAvatarId,
            "crypticNm": nikName
        };
        setLoader("block");
        setOpacity(0.5);
        joinTestSvc(joinObj, schoolId, joinTestResponse);
    }
    
    const joinTestResponse = (data) => {
        let obj = data;
        if (obj.status === 'Success') {
            const newPageUrl = "/testInstruction?tId=" + testId + "&sId=" + studentId + "&schId=" + obj.schoolId + "&rankers=" + leaderboardCount ;
            window.location.href = newPageUrl;
        }
        else {
            toast.error(obj.message);
        }
        setLoader('none');
        setOpacity(1);
    }



    const getAvatarList = (pSchoolId) => {
        setLoader("block");
        setOpacity(0.5);
        avatarListSvc(pSchoolId, avatarListResponse);
    }

    const avatarListResponse = (data) => {
        let obj = data;
        if (obj.status === 'Success') {
            const avatarsMap = new Map(Object.entries(obj.mapAvatar).map(([key, value]) => [Number(key), value]));
            setMapAvatar(avatarsMap);
            const imgPath = "https://www.sms.cooledsys.com:8280/sms/student/docs/view?imgPath=" + obj.avatarPath;
            setAvatarPath(imgPath);
            saveMapAvatarLocally(obj.mapAvatar, imgPath);
        }
        else {
            toast.error(obj.message);
        }
        setLoader('none');
        setOpacity(1);
    }


    const saveMapAvatarLocally = (pMapAvatar, pAvatarPath) => {
        const mapAvatarString = JSON.stringify(pMapAvatar);
        localStorage.setItem("mapAvatar", mapAvatarString);
        localStorage.setItem("avatarPath", pAvatarPath);
    }

    const handleChangeAvatar = (pAvatar) => {
        setSelAvatarId(pAvatar.avatarId);
        setAvatar(pAvatar.avatar);
        setAvatarDesc(pAvatar.desc);
    }

    const handleNikNameChange = (pNikname) => {
        setNikName(pNikname.trim());
    }

    const testOtpSubmit = (pOtp, pTestId, pSchoolId) => {
        // // console.log("testOtpSubmit called")
        if (pTestId === "" && pOtp === "") {
            toast.warn("please enter testId and Otp!");
            return;
        } else if (pTestId === "") {
            toast.warn("please enter testId!");
            return;
        } else if (pTestId === "") {
            toast.warn("please enter otp!");
            return;
        }

        setLoader("block");
        setOpacity(0.5);
        studentDtlsSvc(pTestId, pOtp, pSchoolId, submitOtpResponse);
    }

    const submitOtpResponse = (data) => {
        // // console.log("handleSubmitOtp called");
        let obj = data;
        if (obj.status === 'Success') {
            const studentObj = obj.studentObj;
            // // console.log("studentObj in submitOtpResponse", studentObj);
            // // console.log("setFlgOtp(false) called");
            if (studentObj.completed == 1) {
                const newPageUrl = "/testLeaderBoard?tId=" + obj.testId + "&sId=" + studentObj.studentId + "&schId=" + studentObj.schoolId  + "&qusNo=" + 0 + "&rankers=" + obj.leaderboardCount;
                window.location.href = newPageUrl;
            }
            else if (studentObj.started == 1) {
                const newPageUrl = "/testQuestion?tId=" + obj.testId + "&sId=" + studentObj.studentId + "&schId=" + studentObj.schoolId + "&rankers=" + obj.leaderboardCount ;
                window.location.href = newPageUrl;
            } else {
                setStudentId(studentObj.studentId);
                setStudentName(studentObj.student);
                setLeaderboardCount(studentObj.leaderboardCount);
                setFlgOtp(false);
            }
        }
        else {
            toast.error(obj.message);
        }
        setLoader('none');
        setOpacity(1);
    }


    return (
        <div style={{ margin: 0 }}>
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="full-page " style={{ pointerEvents: loader == "none" ? "auto" : "none" }}>
                <div className="col-sm-11 col-centered padding-remove" >
                    <form>
                        <div className="col-sm-12 padding-remove weeklyTestHeader">
                            <TestHeader
                                testId={testId}
                                studentOtp={studentOtp} 
                                schoolId={schoolId}
                                setTestId={setTestId}
                                setStudentId={setStudentId}
                                studentName={studentName}
                                setStudentName={setStudentName}
                                setLoader={setLoader}
                                setOpacity={setOpacity}
                                flgOtp={flgOtp}
                                setFlgOtp={setFlgOtp}
                                setLeaderboardCount={setLeaderboardCount}
                            />
                        </div>
                        {
                            !flgOtp ?
                                <div className="col-sm-12 padding-remove" style={{ marginTop: "20px" }}>
                                    <div className="avtar-section">
                                        <div className="col-sm-2  col-xs-12 padding-remove">
                                            <div className="col-sm-12 col-xs-12">
                                                    <label className="control-label col-sm-11 col-xs-4 " style={{ fontSize: 20, paddingTop: "5px"  }}>
                                                        Nickname
                                                    </label>
                                                <div className="form-group col-sm-11 col-xs-8">
                                                    <input
                                                        className="form-control"
                                                        style={{
                                                            borderColor: "blue",
                                                            fontSize: 20
                                                        }}
                                                        name="Cryptic Name"
                                                        placeholder="Nickname"
                                                        value={nikName}
                                                        onChange={(e) => handleNikNameChange(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-7 col-xs-12">
                                            <TestAvatar
                                                selAvatarId={selAvatarId}
                                                handleChangeAvatar={handleChangeAvatar}
                                                mapAvatar={mapAvatar}
                                                avatarPath={avatarPath}
                                                setLoader={setLoader}
                                                setOpacity={setOpacity}
                                            />
                                        </div>
                                        <div className="col-sm-3 col-xs-12 padding-remove">
                                            <div className="desc-section">
                                                {
                                                    !!selAvatarId ?
                                                        <TestAvatarDescription
                                                            key={selAvatarId}
                                                            avatar={avatar}
                                                            avatarDesc={avatarDesc}
                                                        />
                                                        : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 padding-remove">
                                        <div className="col-sm-2 padding-remove pull-right">
                                            <button type="button" style={{ height: "40px", marginTop: "10px", marginBottom: "10px", borderColor: "blue", backgroundColor: "blue", borderRadius: "20px", minWidth: "80px", width: "40%" }} className="btn btn-primary pull-right" onClick={(e) => { e.stopPropagation(); onClickNext(); }}>
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </form>
                </div>
            </div>
        </div>
    );
}
export default StudentWeeklyTest;