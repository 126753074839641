import React, { useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import '../css/EnquiryCtrl.css'
const InterviewTestTable = (props) => {
//   const [lstSlot, setLstSlot] = useState([]);
const [selTest,setSelTest]=useState('')
function handleSelTest(param){
  setSelTest(param)
}

  return (
    <div className="InterviewTestTable">
            <table>
                <thead>
                  <tr>
                    <th>Test</th>
                    <th>Max Score</th>
                    <th>Pass Score</th>
                    <th>Score Obtained</th>
                    <th>Result</th>
                    <th>Test Date</th>
                    <th>Evaluated By</th>
                    </tr>
                </thead>
                <tbody>
                {props.lstTestResult.map((test, idx) => {
              return (
                <tr onClick={(e)=>handleSelTest(test.test)} className={test.test === selTest? "selected" : ""} key={idx}
                >
                  <td>{test.test}</td>
                  <td>Max Score</td>
                  <td>{test.passScore}</td>
                  <td>{test.score}</td>
                  <td>{test.result==='P'? 'Pass':'Fail'}</td>
                  <td>{test.testDt}</td>
                  <td>{test.evaluatedBy}</td>
                </tr>
              );
            })}
                </tbody>
            </table>
    </div>
  );
};
export default InterviewTestTable;
