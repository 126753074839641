import React, { useEffect, useState } from 'react'
import { generateBillSvc, getClassListSvc } from './BillGenerationSvc'
import { toast, ToastContainer } from 'react-toastify'

const BillGenerationSlider = props => {
  const [lstClass, setLstClass] = useState([])
  const [clsId, setClsId] = useState(0)
  const [selMonthId, setSelMonthId] = useState(0)
  const [lstMonth, setLstMonth] = useState([
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    'Jan',
    'Feb',
    'Mar'
  ])
  const [strMonth, setStrMonth] = useState('000000000000')

  useEffect(() => {
    setStrMonth('000000000000')
    getClassListSvc(cbClassListResponse)
  }, [])

  function cbClassListResponse (data) {
    if (data.SvcStatus === 'Success') {
      setLstClass(data.lstClass)
    } else {
      toast.error(data.SvcStatus)
    }
  }

  function handleClassChange (pClsId) {
    setClsId(pClsId)
  }

  function handleSelMonthId (pMonthId) {
    setSelMonthId(pMonthId)
  }

  function generateBill () {
    if (clsId === 0) {
      toast.warn('Please select the class')
      return
    }
    if (strMonth === '000000000000') {
      toast.warn('Please select the months')
      return
    }
    generateBillSvc(clsId, strMonth, cbGenerateBillResponse)
  }

  function generateRandomNo () {
    return Math.floor(Math.random() * 999999)
  }
  function cbGenerateBillResponse (data) {
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      props.setTableKey(generateRandomNo())
    } else {
      toast.error(data.SvcMsg)
    }
  }
  function replaceAt (str, index, replacement) {
    return (
      str.substr(0, index) +
      replacement +
      str.substr(index + replacement.length)
    )
  }
  function handleCboxChange (pChecked, pIdx) {
    // // console.log("handleCboxChange called, pChecked", pChecked, ", pIdx", pIdx);
    var tmpMonth = ''
    tmpMonth = strMonth
    for (var i = 0; i < strMonth.length; i++) {
      tmpMonth = replaceAt(tmpMonth, pIdx, pChecked ? '1' : '0')
    }
    // // console.log(tmpMonth)
    setStrMonth(tmpMonth)
  }

  return (
    <div className='col-sm-8'>
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className='row'>
        <div className='form-group'>
          <label className='control-label col-sm-4'>Class</label>
          <div className='col-sm-8'>
            <select
              className='form-control'
              value={clsId}
              onChange={e => handleClassChange(e.target.value)}
            >
              <option value={0}>Class</option>
              {lstClass.map((obj, idx) => {
                return (
                  <option key={idx} value={obj.clsId}>
                    {obj.cls}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
      </div>
      <div className='form-group'>
        <div className='col-sm-12 pull-right padding-remove'>
          <table className='tableLayout1'>
            <thead>
              <tr>
                <th style={{ width: 40 }} className='text-left'>
                  Month
                </th>
                <th style={{ width: 20 }} className='text-center'>
                  Select
                </th>
              </tr>
            </thead>
          </table>
          <table id='errorTable' className='tableLayout1'>
            <colgroup>
              <col width='40' />
              <col width='20' />
            </colgroup>
            <tbody>
              {lstMonth &&
                lstMonth.length > 0 &&
                lstMonth.map((month, idx) => {
                  return (
                    <tr
                      onClick={e => handleSelMonthId(idx)}
                      className={selMonthId === idx ? 'selected' : ''}
                      key={idx}
                    >
                      <td className='text-left'>{month}</td>
                      <td className='text-center'>
                        <input
                          key={'cbox_' + idx}
                          id={'cbox_' + idx}
                          data-val={strMonth.charAt(idx)}
                          type='checkbox'
                          checked={strMonth.charAt(idx) === '1'}
                          onChange={e =>
                            handleCboxChange(e.target.checked, idx)
                          }
                        />
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      </div>
      <div className='col-sm-12'>
        <div className='form-group '>
          <button
            className='btn btn-info pull-right'
            onClick={e => generateBill()}
          >
            Generate FeeBook
          </button>
        </div>
      </div>
    </div>
  )
}

export default BillGenerationSlider
