import axios from 'axios';

import lstModules from 'common/json/lstModules.json';
import { Form } from 'react-router-dom';

const httpURL = lstModules['main'].url + ':' + lstModules['main'].port;
var tokenid = localStorage.getItem("tokenid");


export const getClassReportListSvc = (callback) => {
    console.log("getClassReportListSvc called");
    var obj = { status: "Success", message: "" }
    axios({
        url: httpURL + '/sms/tt/v2/teacher_allocation_report/class',
        method: "POST",
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        console.log('response from getClassReportListSvc', response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstClass = response.data.lstClass;
        }
        callback(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        obj.lstIdCard = [];
        callback(obj);
    });
}



export const getTeacherReportListSvc = (callback) => {
    console.log("TeacherReportListSvc called");
    var obj = { status: "Success", message: "" }
    axios({
        url: httpURL + '/sms/tt/v2/teacher_allocation_report/teacher',
        method: "POST",
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        console.log('response from TeacherReportListSvc', response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstTeacher = response.data.lstTeacher;
        }
        callback(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        obj.lstIdCard = [];
        callback(obj);
    });
}
