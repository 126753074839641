
import React, { useEffect } from 'react';
import { Text, View, Image } from '@react-pdf/renderer';
import { styleFooter } from './examReportCardStyles.js';


const FooterSection = (props) => {
    const { principalSign } = props;

    useEffect(() => {
        // // console.log("FooterSection called in exam report card, principalSign", principalSign)
    })



    return (
        <View style={styleFooter.block}>
            <View style={styleFooter.empty_row}>
                <View style={styleFooter.colSign}>
                </View>
                <View style={styleFooter.colSign}>
                </View>
                <View style={styleFooter.colSign}>
                    {
                        !!principalSign ?
                            <Image style={styleFooter.image_sign} src={principalSign} />
                            : null
                    }
                </View>
            </View>
            <View style={styleFooter.row_labels}>
                <View style={styleFooter.colSign}>
                    <Text style={[styleFooter.sign_text, {}]}>CLASS TEACHER</Text>
                </View>
                <View style={styleFooter.colSign}>
                    <Text style={styleFooter.sign_text}>PARENT</Text>
                </View>
                <View style={styleFooter.colSign}>
                    <Text style={styleFooter.sign_text}>PRINCIPAL</Text>
                </View>
            </View>
        </View>
    );
}

export default FooterSection;