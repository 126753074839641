import axios from 'axios'
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['recruitment'].url + ':' + lstModules['recruitment'].port;
const httpURLCommon = lstModules['main'].url + ':' + lstModules['main'].port;

var tokenid = localStorage.getItem("tokenid");

function getLstEvaluationSvc(cb) {
    var status = 'Success'
    var obj = { status: status };
    axios({
        url: httpURLCommon + '/sms/mst/sub/evaluation/list',
        method: "POST",
        params: {},
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        var data = response.data;
        // // console.log(data)
        if (data.SvcStatus === 'Success') {
            obj.message = data.SvcMsg;
            obj.status = data.SvcStatus;
            obj.lstEvaluation = data.lstEvaluation;
        } else {
            obj.message = data.SvcMsg;
            obj.status = data.SvcStatus;
        }
        cb(obj)
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        cb(obj)
    });

}

function getLstSubTypeSvc(cb) {
    var status = 'Success'
    var obj = { status: status };
    axios({
        url: httpURLCommon + '/sms/mst/sub/subtype/list',
        method: "POST",
        params: {},
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {

        var data = response.data;
        // // console.log(data)
        if (data.SvcStatus === 'Success') {
            obj.message = data.SvcMsg;
            obj.status = data.SvcStatus;
            obj.lstSubType = data.lstType;
        } else {
            obj.message = data.SvcMsg;
            obj.status = data.SvcStatus;
        }
        cb(obj)
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        cb(obj)
    });

}

function saveSubjectSvc(pSubObj, cbSaveSubResponse) {
    axios({
        url: httpURLCommon + "/sms/mst/sub/save",
        method: "POST",
        params: { subject: JSON.stringify(pSubObj) },
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {
        var data = response.data
        cbSaveSubResponse(data)
    }).catch((error) => { 
        // // console.log("Error Occured") 
    });
}

function getLocaleListSvc(callBack) {
    // // console.log("getLocaleListSvc called");
    axios(
        {
            url: httpURLCommon + "/sms/mst/locale/list",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)

        }
    ).catch((error) => {
        // // console.log("Task List Catch error")
    });
}

function deleteSubjectSvc(subId, callBack) {
    // // console.log(subId)
    axios(
        {
            url: httpURLCommon + "/sms/mst/sub/delete",
            method: 'POST',
            params: { subId: subId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
        }
    ).catch((error) => {
        console.error(error)
    });
}
export { getLstEvaluationSvc, getLstSubTypeSvc, saveSubjectSvc, deleteSubjectSvc, getLocaleListSvc };