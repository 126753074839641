import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import $ from 'jquery';
import 'jquery-ui';
import '../css/academicSession.css';
import 'common/css/master.css';
import axios from 'axios';
import Header from 'common/js/header/header.js';
import { toast, ToastContainer } from 'react-toastify';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

var httpURL = '';
var lstAcademicSession = [];
var lstType = [];
var screenId = 7;
let queryString = require('querystring');
var tokenid = localStorage.getItem('tokenid');

class AcademicSession extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: 'none',
            opacity: 1,
            flg: true,
            startDt: null,
            endDt: null,
            tokenid: tokenid,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChange1 = this.handleChange1.bind(this);
    }

    handleChange(date) {
        this.setState({ startDt: date, });
    }

    handleChange1(date) {
        this.setState({ endDt: date });
    }

    componentDidMount = () => {


        this.setState({ loader: 'block', opacity: 0.5 });

        this.getAcademicSessionList();

        lstType = [{
            'formatId': 1, 'format': 'YYnnnn',
        },
        {
            'formatId': 2, 'format': 'nnnnnn',
        },

        ];

        this.fillEnquiryFormate();
        this.fillRegdFormate();
    }

    getAcademicSessionList = () => {
        axios({
            url: httpURL + '/sms/mst/session/list',
            method: "POST",
            params: { screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstAcademicSession = response.data.lstAcademicSession;
                this.setAcademicSessionTable();
            }
            else {

                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            } this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error("Service failed " + error);
        });
    }

    fillEnquiryFormate() {
        $('#enquiryNoFormat').empty();
        $('#enquiryNoFormat').prepend($("<option> </option>").val(0).html('Enquiry Format'));
        for (var i = 0; i < lstType.length; i++) {
            // var formatId = lstType[i].formatId;
            var format = lstType[i].format;
            $('#enquiryNoFormat').append($("<option> </option>").val(format).html(format));
        }
    }
    fillRegdFormate() {
        $('#regNoFormat').empty();
        $('#regNoFormat').prepend($("<option> </option>").val(0).html('Reg Format'));
        for (var i = 0; i < lstType.length; i++) {
            // var formatId = lstType[i].formatId;
            var format = lstType[i].format;
            $('#regNoFormat').append($("<option> </option>").val(format).html(format));
        }
    }

    setAcademicSessionTable = () => {
        $('#sessionBodyTable tr').remove();
        var table = document.getElementById('sessionTable');
        var tbody = document.getElementById('sessionBodyTable');

        for (var i = 0; i < lstAcademicSession.length; i++) {
            var academicSessionId = lstAcademicSession[i].academicSessionId;
            var session = lstAcademicSession[i].session;
            var startDt = lstAcademicSession[i].startDt;
            var endDt = lstAcademicSession[i].endDt;
            var status = lstAcademicSession[i].status === undefined ? "-" : lstAcademicSession[i].status;

            var row = document.createElement('tr');
            row.id = academicSessionId;

            row.onclick = (e) => {
                $(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');
                this.setSessionForm($(e.target).closest('tr').attr('id'));
                this.setState({
                    flg: false,
                })
            }

            this.addTD(row, academicSessionId, 'no-display');
            this.addTD(row, session, 'text-center');
            this.addTD(row, startDt, 'text-center');
            this.addTD(row, endDt, 'text-center');
            this.addTD(row, status, 'text-center');

            tbody.append(row);
        }
        table.append(tbody);
    }

    setSessionForm = (academicSessionId) => {
        var sessionData = this.getSessionData(academicSessionId);

        $('#session').val(sessionData.session);

        if (sessionData.endDt === "00-00-0000" || sessionData.endDt === null) {
            this.setState({
                endDt: "",
            });
        }
        else {
            let date = sessionData.endDt;
            date = date.split('-');
            let setDate = date.reverse().join();
            setDate = setDate.replace(/,/g, '-');
            let endDt = new Date(setDate);
            this.setState({
                endDt: endDt,
            });
        }
        if (sessionData.startDt === "00-00-0000") {
            this.setState({
                startDt: "",
            });
        }
        else {
            let date = sessionData.startDt;
            date = date.split('-');
            let setDate = date.reverse().join();
            setDate = setDate.replace(/,/g, '-');
            let startDt = new Date(setDate);
            this.setState({
                startDt: startDt,
            });
        }
        $('#enquiryNoFormat').val(sessionData.enquiryNoFormat);
        $('#enquiryNo').val(sessionData.enquiryNo);
        $('#regNoFormat').val(sessionData.regNoFormat);
        $('#regNo').val(sessionData.regNo);

    }

    getSessionData(academicSessionId) {
        for (var i = 0; i < lstAcademicSession.length; i++) {
            if (parseInt(academicSessionId) === lstAcademicSession[i].academicSessionId)
                return lstAcademicSession[i];
        }
    }

    addSession = () => {
        var academicSession = {};
        var academicSessionId = 7;
        var session = $('#session').val();
        var startDt = $('#startDt').val();
        var endDt = $('#endDt').val();
        var enquiryNoFormat = $('#enquiryNoFormat').find('option:selected').text();
        var enquiryNo = $('#enquiryNo').val();
        var regNoFormat = $('#regNoFormat').find('option:selected').text();
        var regNo = $('#regNo').val();


        if (session === "") {
            toast.warning('Please Enter session');
            $('#session').focus();
            return false;
        }
        if (startDt === "") {
            toast.warning('Please Enter Start Date');
            $('#startDt').focus();
            return false;
        }
        if (endDt === "") {
            toast.warning('Please Enter End Date');
            $('#endDt').focus();
            return false;
        }
        if (parseInt(enquiryNoFormat) === 0) {
            toast.warning('Please Enter Enquiry No.');
            $('#enquiryNoFormat').focus();
            return false;
        }
        if (enquiryNo === "") {
            toast.warning('Please Enter Next Enquiry No.');
            $('#enquiryNo').focus();
            return false;
        }
        if (parseInt(regNoFormat) === 0) {
            toast.warning('Please Enter Reg No.');
            $('#regNoFormat').focus();
            return false;
        }
        if (regNo === "") {
            toast.warning('Please Enter next Reg No.');
            $('#regNo').focus();
            return false;
        }

        if (this.state.flg) {
            academicSessionId = 0;
        }
        else {
            academicSessionId = $('#sessionBodyTable tr.selected').find('td:eq(0)').text();
        }

        academicSession = {
            'academicSessionId': academicSessionId, 'session': session, 'startDt': startDt, 'endDt': endDt,
            'enquiryNoFormat': enquiryNoFormat, 'enquiryNo': enquiryNo, 'regNoFormat': regNoFormat,
            'regNo': regNo,
        }

        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/mst/session/save',
            queryString.stringify({ 'academicSession': JSON.stringify(academicSession), screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getAcademicSessionList();
                this.addSessionType();

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });

    }

    addTD(row, val, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var text = document.createTextNode(val);
        cell.append(text);
        row.append(cell);
    }

    addSessionType = () => {
        $('#sessionAddForm').trigger('reset');
        $('#sessionBodyTable tr').removeClass('selected');
        this.setState({
            flg: true,
            startDt: null,
            endDt: null,
        })
    }


    getWingId = (wingId) => {
        // // console.log("wingId" + wingId);
    }


    render() {
        httpURL = lstModules['academicSession'].url + ":" + lstModules['academicSession'].port;
        return (
            <div style={{ height: '100%' }}>
                <Header wingFromChild={this.getWingId} />
                <ToastContainer autoClose={3000} hideProgressBar />
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: this.state.loader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>
                <div className="mainContainer Timing" style={{ opacity: this.state.opacity }}>
                    <div className="col-md-11 col-centered">
                        <div className="col-md-6  col-xs-6 periodTypeWrap  padding-remove">
                            <table className="tableLayout1 periodType" style={{ width: this.state.width }}>
                                <thead>
                                    <tr>
                                        <th style={{ display: 'none' }}> session Id </th>
                                        <th style={{ width: 40 }}> session </th>
                                        <th style={{ width: 40 }}> Start Dt </th>
                                        <th style={{ width: 40 }}> End Dt </th>
                                        <th style={{ width: 40 }}> Status </th>

                                    </tr>
                                </thead>
                            </table>
                            <div className="sessionTableDiv" style={{ height: '400px', overflowY: 'scroll', border: '1px solid #ccc' }} >
                                <table id="sessionTable" className="tableLayout">
                                    <colgroup>
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                    </colgroup>
                                    <tbody id="sessionBodyTable"></tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-xs-1 ">
                            <span id="addSession" title="Add Period Type" onClick={this.addSessionType} className="addIcon glyphicon glyphicon-plus-sign"></span>
                        </div>
                        <div className="col-md-5 col-xs-8 padding-remove timingFormWrap" >
                            <form id="sessionAddForm" className="form-horizontal formWrapper">
                                <div className="form-group">
                                    <label className="control-label col-sm-5" >session<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="session" type="text" className="form-control " placeholder="Enter session" />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Start Dt.<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <DatePicker id="startDt"
                                            className='form-control'
                                            placeholderText="Enter Start Date"
                                            selected={this.state.startDt}
                                            onChange={this.handleChange}
                                            dateFormat="dd-MM-yyyy"
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label col-sm-5" >End Dt.<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <DatePicker id="endDt"
                                            className='form-control'
                                            placeholderText="Enter End Date"
                                            selected={this.state.endDt}
                                            onChange={this.handleChange1}
                                            dateFormat="dd-MM-yyyy"
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Enquiry No. Format<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <select onChange={this.changePeriodType} className="form-control" style={{ width: '100%' }} id="enquiryNoFormat"></select>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Enquiry No.<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="enquiryNo" type="text" className="form-control " placeholder="Enter Enquiry No." />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Reg No. Format<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <select onChange={this.changePeriodType} className="form-control" style={{ width: '100%' }} id="regNoFormat"></select>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label col-sm-5" >Reg No.<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="regNo" type="text" className="form-control " placeholder="Enter Reg No." />
                                    </div>
                                </div>

                                <div className="form-group" >
                                    <div className="col-sm-12 text-center userButtonWrap" >
                                        <button type="button" style={{ marginTop: 20 }} title="Save" onClick={this.addSession} className="btn btn-info">Save</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        );
    }



}
export default AcademicSession;