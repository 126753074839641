import React, { useState, useEffect } from 'react';
import { saveAllocationSvc } from './StaffSvc';
import { toast, ToastContainer } from 'react-toastify';
import SliderType from './SliderType';
import '../css/StaffCtrl.css'

export const AllocationSlider = (props) => {
    const [loader, setLoader] = useState("none")
    // const [staffId, setStaffId] = useState(0)
    const [userId, setUserId] = useState(0);
    const [minAllocation, setMinAllocation] = useState("")
    const [maxAllocation, setMaxAllocation] = useState("")

    function handleMinAllocationChange(param) {
        setMinAllocation(param)
    }
    function handleMaxAllocationChange(param) {
        setMaxAllocation(param)
    }

    function saveAllocation() {
        setLoader("block")
        // // console.log(minAllocation, maxAllocation, userId)
        saveAllocationSvc(userId, minAllocation, maxAllocation, cbSaveAllocation)
    }
    function cbSaveAllocation(data) {
        setLoader('none')
        if (data.SvcStatus === "Success") {
            props.closeSlider();
            props.selectedUserId(data.userId);
            toast.success(data.SvcMsg)


        } else {
            toast.error(data.SvcMsg)
        }

    }
    useEffect(() => {
        // // console.log(props)
        setMinAllocation(props.minAllocation)
        setMaxAllocation(props.maxAllocation)
        setUserId(props.selectedUserId)
    }, [props.userId, props.flgSliderType, props.minAllocation, props.maxAllocation])

    return (
        <form className='form form-horizontal' style={{ width: '100%', margin: '0 auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10, pointerEvents: loader === "none" ? "auto" : "none" }}>
            <div style={{ position: 'absolute', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="StaffHead col-sm-12 col-centered" style={{ border: '1px solid rgb(170, 170, 170)', borderRadius: '10px 10px 5px 5px', fontWeight: 'bold' }}>
                <h4>Weekly Allocation</h4>
            </div>

            <div className='form-group col-sm-12'>
                <label className="control-label col-sm-2">Minimum(%)<span className="red-color">*</span></label>
                <div className='col-sm-2 padding-remove'>
                    <input type='number' className="form-control" defaultValue={minAllocation} onChange={(e) => handleMinAllocationChange(e.target.value)} required></input>
                </div>
            </div>

            <div className='form-group col-sm-12'>
                <label className="control-label col-sm-2">Maximum(%)<span className="red-color">*</span></label>
                <div className='col-sm-2 padding-remove'>
                    <input type='number' className="form-control" defaultValue={maxAllocation} onChange={(e) => handleMaxAllocationChange(e.target.value)} required></input>
                </div>
            </div>
            <div className='row' style={{ width: '100%', marginBottom: '1%' }}>
                <button type="button" className="btn btn-info " onClick={(e) => saveAllocation()}>Save</button>
            </div>
        </form>
    )
}
export default AllocationSlider;