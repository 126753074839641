import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";


export default function Graph(props) {
  const [state, setState] = useState({
    options: {

      chart: {
        redrawOnParentResize: true
      },
      plotOptions: {
        bar: {
          // columnWidth: '30%',
          dataLabels: {
            position: "top" // top, center, bottom
          }
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return Number(val).toLocaleString("en-in");
        },
        offsetY: -20,
        style: {
          fontSize: "8px",
          colors: ["#304758"]
        }
      },
      title: {
        text: "Month-Wise",
        floating: true,
        offsetY: 0,
        align: "center",
        style: {
          color: "#444"
        }
      },

      xaxis: {
        categories: props.month,
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          minHeight: undefined,
          maxHeight: 50,
          style: {
            colors: [],
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 400,
            cssClass: 'apexcharts-xaxis-label',
          },
        },
        tooltip: {
          enabled: false,
          offsetY: -35
        },
        // title: {
        //   text: 'Month',
        //   offsetX: 0,
        //   offsetY: 0,
        //   style: {
        //     color: undefined,
        //     fontSize: '12px',
        //     fontFamily: 'Helvetica, Arial, sans-serif',
        //     fontWeight: 600,
        //     cssClass: 'apexcharts-xaxis-title',
        //   },
        // },


      },
      yaxis: {
        title: {
          text: 'Collection',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            cssClass: 'apexcharts-xaxis-title',
          },
        },
      }
    },
    series: [
      {
        data: props.mCollection,

      }
    ]
  });

  useEffect(() => {

    setState({
      series: [
        {
          data: props.mCollection

        }
      ]
    });

  }, [])


  return (
    <div id="chart" className="col-sm-12">
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="bar"
        height="300"

      />
    </div>
  );
}