import '../css/EnquiryCtrl.css'
import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { getRegsFeeDtlsSvc } from './RegsFeeSliderSvc'
import { getHeadListSvc } from './EnquirySvc'
import { associateFeeHeadSvc } from './EnquirySvc'
import SliderType from './sliderType'

const RegsFeePaymentSlider = props => {
  const [loader, setLoader] = useState('none')
  const [enqId, setEnqId] = useState(props.enquiryId)
  const [oneTimeHeadId, setOneTimeHeadId] = useState(0)
  const [lstOneTimeHead, setLstOneTimeHead] = useState([])
  const [clsSection, setClsSection] = useState('')
  const [amount, setAmount] = useState(0)
  const controller = new AbortController()
  const signal = controller.signal

  function cbGetOneTimeHeadResponse (data) {
    if (data.SvcStatus === 'Success') {
      // // console.log('Fetching success')
      setLstOneTimeHead(data.lstHead)
    } else {
      toast.error(data.SvcMsg)
    }
    setLoader('none')
  }
  function cbRegFeeResponse (data) {
    if (data.SvcStatus === 'Success') {
      // // console.log('Fetching success')
      setOneTimeHeadId(data.headId)
      setClsSection(data.clsSection)
    } else {
      toast.error(data.SvcMsg)
    }
    setLoader('none')
  }
  function cbSaveRegFee (data) {
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      var p2 = new Promise(function (resolve, reject) {
        resolve(props.setSelectedEnquiryId(data.enqId))
      })

      p2.then(function () {
        props.closeSlider()
      })
    } else {
      toast.error(data.SvcMsg)
    }
    setLoader('none')
  }
  useEffect(() => {
    setLoader('block')

    var promise = new Promise((resolve, reject) => {
      if (
        props.flgSliderType === SliderType.SLIDER_REGISTER_FEE &&
        props.enquiryId
      ) {
        getRegsFeeDtlsSvc(cbRegFeeResponse, props.enquiryId)
        getHeadListSvc(cbGetOneTimeHeadResponse)
        setEnqId(props.enquiryId)
      }
    })
    return () => {
      controller.abort()
    }
  }, [props.enquiryId, props.flgSliderType])

  function handleOneTimeHeadChange (param) {
    setOneTimeHeadId(param)
    var amt = 0
    for (var i = 0; i < lstOneTimeHead.length; i++) {
      var obj = lstOneTimeHead[i]

      if (obj.headId == param) {
        amt = obj.amount
        break
      }
      setAmount(amt)
    }
  }
  function saveRegFee () {
    var vLstEnqId = []
    vLstEnqId.push(enqId)
    associateFeeHeadSvc(vLstEnqId, oneTimeHeadId, cbSaveRegFee)
  }
  return (
    <form
      className='form form-horizontal'
      style={{
        width: '100%',
        margin: '0 auto',
        background: '#fff',
        border: '1px solid #ccc',
        borderRadius: 10,
        pointerEvents: loader === 'none' ? 'auto' : 'none'
      }}
    >
      <div
        style={{
          position: 'fixed',
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: '50%',
          top: '50%',
          display: loader
        }}
      >
        <img
          className='loderImg'
          src={require('common/images/loader.gif')}
          alt='Loader...'
        />
      </div>

      <div
        className='EnquiryHead col-sm-12 col-centered'
        style={{
          border: '1px solid #aaa',
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
          fontWeight: 'bold'
        }}
      >
        <h4>Admission Fee</h4>
      </div>

      <div className='form-group col-md-12 padding-remove'>
        <label className='control-label col-md-2'>Class</label>
        <div className=' padding-remove col-md-1'>
          <input
            type='text'
            className='form-control'
            value={clsSection}
            readOnly
          />
        </div>
      </div>
      <div className='form-group col-md-12 padding-remove'>
        <label className='control-label col-md-2'>Admission Fee</label>
        <div className=' padding-remove col-md-2'>
          <select
            className='form-control'
            onChange={e => handleOneTimeHeadChange(e.target.value)}
            value={oneTimeHeadId}
          >
            <option value={0}>Admission Fee</option>
            {lstOneTimeHead &&
              lstOneTimeHead.length > 0 &&
              lstOneTimeHead.map((obj, idx) => {
                return (
                  <option key={idx} value={obj.headId}>
                    {obj.head}
                  </option>
                )
              })}
          </select>
        </div>
      </div>
      <div className='form-group col-md-12 padding-remove'>
        <label className='control-label col-md-2'>Amount</label>
        <div className=' padding-remove col-md-2'>
          <input
            type='text'
            className='form-control text-right'
            value={amount}
            readOnly
          />
        </div>
      </div>

      <div className='form-group col-md-12 padding-remove'>
        <button
          type='button'
          className='btn btn-info pull-right'
          onClick={e => saveRegFee()}
        >
          Save
        </button>
      </div>
    </form>
  )
}
export default RegsFeePaymentSlider
