import React from 'react'
//Please create ExperienceTableSvc.js, ExperienceFormSvc.js
import 'react-datepicker/dist/react-datepicker.css';
import Datepicker from 'react-datepicker'
import { useState, useEffect } from 'react';
import { recruitmentExperienceLstSvc, recruitmentExperienceSaveSvc, recruitmentExperienceDtlsSvc, recruitmentExperienceDeleteSvc, recruitmentExperienceAttachmentSaveSvc, recruitmentAttachmentDeleteSvc } from './ExperienceSliderSvc';
import lstModules from 'common/json/lstModules.json';

var httpURL = lstModules['recruitment'].url + ":" + lstModules['recruitment'].port;

const ExperienceSlider = (props) => {
  const [fromDt, setfromDt] = useState('')
  const [toDt, setToDt] = useState('')
  const [lstExperience, setLstExperience] = useState([])
  const [dtlExperience, setDtlExperience] = useState([])
  const [attachmentId, setAttachmentId] = useState(0)
  const [attachmentPath, setAttachmentPath] = useState()
  const [attachment, setAttachment] = useState([])
  const [selectedIdx, setSelectedIdx] = useState()
  const [experienceId, setExperienceId] = useState(0)
  const [fromYr, setFromYr] = useState('')
  const [toYr, setToYr] = useState('')
  const [role, setRole] = useState('')
  const [institute, setInstitute] = useState('')

  useEffect(() => {
    recruitmentExperienceLstSvc(cbRecruitmentExperienceLstResponse, props.tchId)
  }, [props])
  function saveExperience() {
    var experience = {}
    experience.tchId = props.tchId
    experience.experienceId = experienceId
    experience.fromYr = fromYr
    experience.toYr = toYr
    experience.role = role
    experience.institute = institute
    var lstAttachment = [{ attachmentId, attachmentPath }]
    recruitmentExperienceSaveSvc(cbRecruitmentExperienceSaveResponse, experience, lstAttachment)


  }
  function recruitmentDtls(pExperienceId) {
    recruitmentExperienceDtlsSvc(cbRecruitmentExperienceDtlsResponse, props.tchId, pExperienceId)

  }

  function cbRecruitmentExperienceSaveResponse(data) {
    props.onGetRandNo()
    if (data.status == "Success") {

      // // console.log(data.message)

    }
    else {
      // // console.log(data.message)
    }


  }
  function cbRecruitmentExperienceDeleteResponse(data) {
    if (data.status == "Success") {
      props.onGetRandNo()

      // // console.log(data.message)

    }
    else {
      // // console.log(data.message)
    }


  }
  function cbRecruitmentAttachmentDeleteResponse(data) {
    setOnuploadAttachment()
    if (data.status == "Success") {
      props.onGetRandNo()
      setOnuploadAttachment()

      // // console.log(data.message)

    }
    else {
      // // console.log(data.message)
    }


  }




  function cbRecruitmentExperienceLstResponse(data) {
    setLstExperience(data)
  }

  function rowClick(rec, idx) {
    setSelectedIdx(idx)
    setExperienceId(rec.experienceId)
    recruitmentDtls(rec.experienceId)
    // // console.log(rec.experienceId)

  }
  function saveAttachment(file) {
    // // console.log(experienceId)
    recruitmentExperienceAttachmentSaveSvc(cbrecruitmentEducationAttachmentSaveResponse, file, attachmentId, experienceId, props.tchId)


  }
  function cbrecruitmentEducationAttachmentSaveResponse(data) {
    setOnuploadAttachment()
    if (data.status == "Success") {

      // // console.log(data.message)

    }
    else {
      // // console.log(data.message)
    }


  }


  function getDateForCalendar(strDt) {
    if (!strDt)
      return null;
    var parts = strDt.split('-')
    var d = parts[0];
    var m = parts[1] - 1;
    var y = parts[2];

    return new Date(y, m, d);
  }
  function handleEvaluationFromDtChange(param) {
    var m = param.getMonth() + 1;
    if (m < 10) {
      m = "0" + m;
    }
    var d = param.getDate();
    if (d < 10) {
      d = "0" + d;
    }
    const dt = d + "-" + (m) + "-" + param.getFullYear()
    // // console.log(dt)
    setFromYr(dt)


  }
  function handleEvaluationToDtChange(param) {
    var m = param.getMonth() + 1;
    if (m < 10) {
      m = "0" + m;
    }
    var d = param.getDate();
    if (d < 10) {
      d = "0" + d;
    }
    const dt = d + "-" + (m) + "-" + param.getFullYear()
    // // console.log(dt)
    setToYr(dt)


  }
  function cbRecruitmentExperienceDtlsResponse(data) {
    setDtlExperience(data)
    setAttachment(data.lstAttachment)
    // // console.log(data)

  }
  function setForm(pQualificationId) {

    setFromYr(dtlExperience.fromYr)
    setToYr(dtlExperience.toYr)
    setRole(dtlExperience.role)
    setInstitute(dtlExperience.institute)
    setExperienceId(pQualificationId)




  }
  function setNewForm() {
    setFromYr('')
    setToYr('')
    setRole('')
    setInstitute('')
    setExperienceId(0)




  }
  function deleteQualification(pExperienceId) {
    recruitmentExperienceDeleteSvc(cbRecruitmentExperienceDeleteResponse, props.tchId, pExperienceId)
  }
  function uploadImg(event, staffAttachmentId) {

    if (!event) return;
    var files = event; //FileList object
    // // console.log(files)
    setAttachmentPath(files[0], staffAttachmentId);
    // // console.log(files[0])
    saveAttachment(files[0], staffAttachmentId)


  }

  function setOnuploadAttachment() {
    recruitmentExperienceDtlsSvc(cbRecruitSetAttchmentResponse, props.tchId, experienceId)

  }
  function cbRecruitSetAttchmentResponse(data) {
    setAttachment(data.lstAttachment)

  }

  return (
    <div className='container'>

      <div className="col-sm-6">
        <div className="col-md-1  col-xs-1 pull-right" style={{ top: '20px' }} >
          <span className="addIcon glyphicon glyphicon-plus-sign" onClick={setNewForm} ></span></div>

        <div className='tablebodyWrapper'>
          <table className='tableLayout'>
            <thead>
              <tr>

                <th style={{ width: 125 }}>
                  from
                </th>
                <th style={{ width: 100 }}>
                  to
                </th>
                <th style={{ width: 100 }}>
                  Role
                </th>

                <th style={{ width: 75 }}>
                  Action
                </th>


              </tr>
            </thead>
            <tbody>
              {lstExperience.map((rec, idx) => (
                <tr className={idx === selectedIdx ? 'selected' : ''} key={idx} onMouseOver={(event) => rowClick(rec, idx)}>
                  <td>{rec.fromYr}</td>
                  <td>{rec.toYr}</td>
                  <td>{rec.role}</td>
                  <td width="100" className="text-center">
                    <span onClick={() => setForm(rec.experienceId)}
                      className="glyphicon glyphicon-edit">
                    </span>
                    <span
                      onClick={() => deleteQualification(rec.experienceId)}
                      className="glyphicon glyphicon-trash" >
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className='col-sm-4'>
        <form className='form form-horizontal' style={{ width: '120%', overflowX: 'hidden', margin: '0 auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
          <div className='EnquiryHead col-sm-12 col-centered' style={{
            border: "1px solid #aaa", borderTopLeftRadius: 10,
            borderTopRightRadius: 10, borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5, fontWeight: 'bold'
          }}><h4>Experience Details</h4></div>
          <div className="form-group col-md-12">
            <label className='control-label col-md-5'> From </label>
            <div className='col-sm-6 padding-remove' >
              <Datepicker
                type='date'
                className="form-control"
                selected={getDateForCalendar(fromYr)}
                onChange={handleEvaluationFromDtChange}
                dateFormat="dd-MM-yyyy"
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"></Datepicker>
            </div>
          </div>
          <div className="form-group col-md-12">
            <label className='control-label col-md-5'> To </label>
            <div className='col-sm-6 padding-remove'>
              <Datepicker
                type='date'
                className="form-control"
                selected={getDateForCalendar(toYr)}
                onChange={handleEvaluationToDtChange}
                dateFormat="dd-MM-yyyy"
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"></Datepicker>
            </div>
          </div>


          <div className="form-group col-md-12">
            <label className='control-label col-md-5'> Role </label>
            <div className="col-sm-6">
              <input type='text' className="form-control" id="cmb_tch" value={role} onChange={(event) => setRole(event.target.value)}    >


              </input>
            </div>
          </div>
          <div className="form-group col-md-12">
            <label className='control-label col-md-5'> Institute Name </label>
            <div className="col-sm-6">
              <input type='text' className="form-control" id="cmb_tch" value={institute} onChange={(event) => setInstitute(event.target.value)}      >


              </input>
            </div>
          </div>
          <div className="form-group col-md-12">
            <label className='control-label col-md-5'> Attachments </label>
            <div className="col-sm-6">
              <input type='file' className="form-control" id="cmb_tch"     >


              </input>
            </div>
          </div>
          <div className="col-sm-8">
            <button type="button" className="btn btn-info " id="assignCounsellor " onClick={saveExperience} style={{ marginLeft: '100px', marginBottom: '10px' }} >Save</button>
          </div>


        </form>
        <div className='EnquiryHead col-sm-12 col-centered' style={{
          border: "1px solid #aaa", borderTopLeftRadius: 10,
          borderTopRightRadius: 10, borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5, fontWeight: 'bold', width: '120%'

        }}><h4></h4>Attachments</div>
        <div className="row-sm-8" style={{ width: '120%', overflowX: 'hidden', margin: '0 auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10, overflowY: 'scroll', height: '250px' }}>

          <div className="form-group col-md-12"></div>
          {attachment.map((rec) => (
            <div htmlFor="photo-file" className="custom-file-upload fas">

              <div className="img-wrap img-upload" >
                <label>
                  <span className="glyphicon glyphicon-edit edit-label" htmlFor="photo-file"  >
                    <input autoComplete="off" id="photo-file" onChange={(event) => uploadImg(event.target.files, rec.staffAttachmentId)} type="file" className="form-control " />
                  </span>

                </label>
                <label>
                  <button onClick={(event) => recruitmentAttachmentDeleteSvc(cbRecruitmentAttachmentDeleteResponse, rec.staffAttachmentId)} className="glyphicon glyphicon-trash delete-label"  ></button>
                </label>


                {rec.contentType === "pdf"
                  ? <iframe height="100%" width="100%" src={httpURL + "/sms/recruitment/attachment/viewimg?staffAttachmentId=" + rec.staffAttachmentId} /> :
                  <img for="photo-file" src={httpURL + "/sms/recruitment/attachment/viewimg?staffAttachmentId=" + rec.staffAttachmentId} />
                }
              </div>

            </div>))}


          <label htmlFor="photo-file1" className="custom-file-upload fas">

            <div className="img-wrap img-upload" >
              <img for="photo-file1" src={require('./upload.svg')} />
            </div>
            <input autoComplete="off" id="photo-file1" onChange={(event) => uploadImg(event.target.files, 0)} type="file" accept="image/*" className="form-control " />
          </label>



        </div>

      </div>
    </div>
  )
}

export default ExperienceSlider