import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

function TchWiseSummaryTbl(props) {
    const [lstTTtchSummary, setLstTTtchSummary] = useState(props.lstTTSummary);
    const [tchArr, setTchArr] = useState([]);
    const [iTchArr, setITchArr] = useState([]);
    const [lstRole, setLstRole] = useState(props.lstRole);
    const [roleId, setRoleId] = useState(props.roleId);
    const [tch, setTch] = useState(props.tch);
    const [selTchId, setSelTchId] = useState(0);
    useEffect(() => {
        setLstTTtchSummary(props.lstTTtchSummary)
        setLstRole(props.lstRole);
        setTch(props.tch)
        setRoleId(props.roleId)
    }, [props]);

    function pushToArr(e, tchId, sentOn) {
        var arr = tchArr;
        var newArr = iTchArr;
        if (e.target.checked) {
            arr.push(tchId)
            newArr.push({ tchId: tchId, sentOn: sentOn })
        } else {
            arr = tchArr;
            newArr = iTchArr;
            arr.splice(arr.indexOf(tchId), 1)
            newArr.splice(newArr.indexOf({ tchId: tchId, sentOn: sentOn }), 1)
        }
        setTchArr(arr);
        setITchArr(newArr);
    }

    function onSendClick(tchArr, iTchArr) {
        if (tchArr.length === 0) {
            toast.warning("Please select the teacher to send the timetable");
            return;
        }
        var found = false;
        for (var i = 0; i < iTchArr.length; i++) {
            if (iTchArr[i].sentOn != '-') {
                found = true;
                break;
            }
        }
        if (found) {
            var r = window.confirm("The timetable is already sent. Would you like to force send it again?");
            if (r) {
                props.send(r, tchArr)
            }
        } else {
            props.send(true, tchArr)
        }
    }
    const filterWithType = async (e) => {
        setRoleId(e.target.value)
        filterTableWithType(e.target.value)
    }

    function filterTableWithType(roleId) {
        setLstTTtchSummary(props.lstTTtchSummary)
        if (roleId != 0) {
            let newArr = []
            for (let i = 0; i < lstTTtchSummary.length; i++) {
                if (parseInt(lstTTtchSummary[i].roleId) === parseInt(roleId)) {
                    newArr.push(lstTTtchSummary[i])
                }
            }
            setLstTTtchSummary(newArr)
        }
    }
    const handleChange = (e) => {
        const { name, value } = e.target;

        setTch(value)
        filterTable(value)
    }
    function filterTable(tch) {
        if (tch !== "") {
            const filterContent = lstTTtchSummary.filter(contents =>
                contents.tch.toLowerCase().includes(tch.toLowerCase()));
            setLstTTtchSummary(filterContent);
        }
        else {
            setLstTTtchSummary(props.lstTTtchSummary);
        }
    }

    return (
        <div>
            <button className="sendbtn" onClick={() => onSendClick(tchArr, iTchArr)} style={{
                float: 'right', marginRight: '20px', backgroundColor: '#5bc0de', color: 'white', borderColor: 'transparent', width: '60px'
                , borderRadius: '4px'
            }}>Send</button>
            <table className="tableLayout1 tchsummary" style={{ width: 700 }}>
                <thead>
                    <tr>
                        <th style={{ display: 'none' }} rowSpan="2"> tchId </th>
                        <th style={{ width: 40 + '%' }} rowSpan="2">
                            <input
                                type="text"
                                className="form-control inp-search"
                                id="teacherFilter"
                                defaultValue={tch}
                                onChange={handleChange}
                                placeholder="Teacher" />
                        </th>
                        <th style={{ display: 'none' }} rowSpan="2"> roleId </th>
                        <th style={{ width: 40 + '%' }} rowSpan="2">
                            <select autoComplete="off"
                                className="form-control inp-search"
                                name="roleId"
                                id="cmbRole"
                                value={roleId}
                                onChange={filterWithType}
                            >
                                <option id="0" value="0">Role</option>
                                {props.lstRole.map((obj) => {
                                    return <option value={obj.roleId} key={obj.roleId}>{obj.role}
                                    </option>
                                })}
                            </select>
                        </th>
                        <th style={{ width: 45 + '%' }} colSpan="3" > Allocation</th>
                        <th style={{ width: 40 + '%' }} rowSpan="2"> Sent On</th>
                        <th style={{ width: 15 + '%' }} className="text-center" rowSpan="2"> Select </th>
                        <th style={{ width: 15 + '%' }} rowSpan="2"> Action</th>

                    </tr>
                    <tr>
                        <th style={{ width: 15 + '%', }}> Min </th>
                        <th style={{ width: 15 + '%' }}> Max </th>
                        <th style={{ width: 15 + '%' }}> Actual</th>
                    </tr>
                </thead>
            </table>
            <div className="tchsummaryTableDiv" style={{ height: '63vh', overflowY: 'scroll', border: '1px solid #ccc' }} >
                <table id="tchsummaryTable" className="tableLayout">
                    {/* <colgroup>
                        <col width="40" />
                        <col width="40" />
                        <col width="40" />
                        <col width="40" />
                        <col width="40" />
                        <col width="40" />
                        <col width="400" />
                    </colgroup> */}
                    <tbody id="tchsummaryTablebody">
                        {lstTTtchSummary && lstTTtchSummary.map((obj) => (
                            <tr key={obj.tchId} id={obj.tchId} onClick={(e) => setSelTchId(obj.tchId)} className={obj.tchId === selTchId ? "selected" : ""}>
                                <td style={{ width: 40 + '%' }}>{obj.tch}</td>
                                <td style={{ width: 40 + '%' }}>{obj.role}</td>
                                <td style={{ width: 15 + '%', textAlign: 'center' }}>{obj.minAlloc}</td>
                                <td style={{ width: 15 + '%', textAlign: 'center' }}>{obj.maxAlloc}</td>
                                <td style={{ width: 15 + '%', textAlign: 'center' }}>{obj.actualAlloc}</td>

                                <td style={{ width: 40 + '%', textAlign: 'center' }}>{obj.sentOn}</td>
                                <td style={{ width: 15 + '%' }} className="text-center">
                                    <input type="checkbox" onClick={(e) => pushToArr(e, obj.tchId, obj.sentOn)}></input>
                                </td>
                                <td className="text-center" style={{ width: 15 + '%' }}>
                                    <span id="previewicon" title="Preview" className="glyphicon glyphicon-film" onClick={(e) => props.handleEditClick(e, true, obj.tchId)}></span>
                                </td>
                            </tr>

                        ))}


                    </tbody>

                </table>
            </div>

        </div>


    )
}

export default TchWiseSummaryTbl
