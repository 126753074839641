const SliderType = Object.freeze({
    NONE: '0'
    , SLIDER_EDIT_DTLS: '10'
    , SLIDER_ROLES: '20'
    , SLIDER_CLASS_SUBJECTS: '30'
    , SLIDER_WEEKLY_ALLOC: '40'
    , SLIDER_SALARY: '50'
    , SLIDER_EDUCATION: '60'
    , SLIDER_DOCUMENTS: '70'
    , SLIDER_SYSTEM_ACCESS: '80'
    , SLIDER_ERROR: '90'
    , SLIDER_SIGN: 11
})

export default SliderType;

