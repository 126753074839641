import Header from "common/js/header/header.js";
import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import lstModules from "common/json/lstModules.json";
const httpURL = lstModules["payrollSetup"].url + ":" + lstModules["payrollSetup"].port;
var tokenid = localStorage.getItem("tokenid");
function StaffTable(props) {
  const [lstStaff, setLstGrade] = useState([]);
  const [loader, setLoader] = useState("none");
  const [selObj, setSelObj] = useState({});
  const [selIdx, setSelIdx] = useState();
  useEffect(() => {
    setLoader("block");
    getLstStaffType(cbGetLstStaffSvc);
  }, []);
  function getLstStaffType(cbGetLstStaffSvc) {
    axios({
      url: httpURL + "/sms/staff_type/list",
      method: "POST",
      params: {},
      headers: { tokenid: tokenid },
      withCredentials: true,
    })
      .then((Response) => {
        var data = Response.data;

        cbGetLstStaffSvc(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function cbGetLstStaffSvc(data) {
    if (data.SvcStatus === "Success") {
      setLstGrade(data.lstStaffType);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function handleSelRow(param) {
    setSelObj(lstStaff[param]);
    setSelIdx(param);
    props.getSelData(lstStaff[param]);
  }
  const colgrp = (
    <colgroup>
      <col width="8%"></col>
      <col width="8%"></col>
    </colgroup>
  );
  return (
    <div className="col-sm-offset-4" style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>

      <table className="col-sm-12">
        {colgrp}
        <thead>
          <tr>
            <th>Staff Type Id</th>
            <th>Staff Type</th>
          </tr>
        </thead>
        <tbody>
          {lstStaff.map((obj, idx) => {
            return (
              <tr
                onClick={(e) => handleSelRow(idx)}
                className={selIdx === idx ? "selected" : ""}
                key={idx}
              >
                <td>{obj.typeId}</td>
                <td style={{ textAlign: "right" }}>
                  {obj.type}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
export default StaffTable;
