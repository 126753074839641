import axios from 'axios';

import lstModules from 'common/json/lstModules.json';
import { Form } from 'react-router-dom';

const httpURL = lstModules['main'].url + ':' + lstModules['main'].port;
var tokenid = localStorage.getItem("tokenid");


export const getSubjectAllocationListSvc = (syllabusId, callback) => {
    // console.log("getSubjectAllocationListSvc called, syllabusId", syllabusId);
    const formData = new FormData();
    formData.append("syllabusId", syllabusId)
    var obj = { status: "Success", message: "" }
    axios({
        url: httpURL + '/sms/tt/v2/syllabus_subject/list',
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console.log('data.SvcStatus=' + response.data.SvcStatus);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstSubject = response.data.lstSubject;
        }
        callback(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        obj.lstIdCard = [];
        callback(obj);
    });
}


export const allocateTeacherSvc = (wingId, callback) => {
    console.log("allocateTeacherSvc called, wingId", wingId);
    const formData = new FormData();
    formData.append("wingId", wingId)
    var obj = { status: "Success", message: "" }
    axios({
        url: httpURL + '/sms/tt/v2/teacher_allocation/allocate',
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        console.log('response in allocateTeacherSvc', response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
        }
        callback(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        obj.lstIdCard = [];
        callback(obj);
    });
}

export const getSubjectDtlsSvc = (syllabusId, subjectId, callback) => {
    // console.log("getSubjectDtlsSvc called, syllabusId", syllabusId, ", subjectId", subjectId);
    const formData = new FormData();
    formData.append("syllabusId", syllabusId);
    formData.append("subId", subjectId);
    var obj = { status: "Success", message: "" }
    axios({
        url: httpURL + '/sms/tt/v2/syllabus_subject/dtls',
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console.log('data.SvcStatus=' + response.data.SvcStatus);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstGroup = response.data.lstGroup;
            obj.lstSection = response.data.lstSection;
        }
        callback(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        obj.lstIdCard = [];
        callback(obj);
    });
}


export const getFacilityListSvc = (callback) => {
    // console.log("getFacilityListSvc called");
    var obj = { status: "Success", message: "" }
    axios({
        url: httpURL + '/sms/mst/facility/list',
        method: "POST",
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console.log('data.SvcStatus=' + response.data.SvcStatus);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstFacility = response.data.lstFacility;
        }
        callback(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        obj.lstIdCard = [];
        callback(obj);
    });
}


export const getSyllabusListSvc = (cbSyllabusList) => {
    // console.log("getSyllabusListSvc called");
    const obj = { status: "", message: "" };
    axios({
        url: httpURL + '/sms/exam_syllabus/list',
        method: "POST",
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console.log("response in getSyllabusListSvc", response);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstSyllabus = response.data.lstSyllabus;
        }
        cbSyllabusList(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        cbSyllabusList(obj);
    })
}



export const saveSubjectAllocationSvc = (syllabusId, saveSubjectAllocationObj, callback) => {
    // console.log("saveSubjectAllocationSvc called");
    const formData = new FormData();
    formData.append("syllabusId", syllabusId);
    formData.append("subjectObj", JSON.stringify(saveSubjectAllocationObj))
    var obj = { status: "Success", message: "" }
    axios({
        url: httpURL + '/sms/tt/v2/syllabus_subject/save',
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console.log('data.SvcStatus=' + response.data.SvcStatus);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        callback(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        obj.lstIdCard = [];
        callback(obj);
    });
}