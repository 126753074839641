import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { saveLabelSvc } from './LabelSvc';

const LabelSlider = (props) => {
    const [labelId, setLabelId] = useState(props.labelObj.labelId)
    const [loader, setLoader] = useState(props.loader)
    const [label, setLabel] = useState(props.labelObj.label)
    const [paymentPriority, setpaymentPriority] = useState(props.labelObj.paymentPriority)
    const [inUse, setInUse] = useState(!!props.labelObj.flgInUse)
    function handleLabelChange(param) {
        setLabel(param)
    }
    function handlepaymentPriorityChange(param) {
        setpaymentPriority(param)
    }
    function handleInUseChange(param) {
        setInUse(param)
    }
    function saveLabel(e) {
        const pLabelObj = {
            labelId: labelId,
            label: label,
            paymentPriority: paymentPriority,
            flgInUse: inUse ? 1 : 0
        }
        setLoader("block")
        props.closeSlider()
        props.generateRandomNo()
        saveLabelSvc(pLabelObj, cbSaveLabelResponse);

    }
    function cbSaveLabelResponse(data) {
        setLoader("none")
        if (data.SvcStatus === "Success") {
            toast.success(data.SvcMsg)
            setLoader('none')
            props.generateRandomNo()
        } else {
            toast.error(data.SvcMsg)
            setLoader('none')
        }
    }
    useEffect(() => {
        setLabelId(props.labelObj.labelId)
        setLabel(props.labelObj.label)
        setpaymentPriority(props.labelObj.paymentPriority)
        setInUse(!!props.labelObj.flgInUse)
    }, [])

    return (
        <form className='form form-horizontal' style={{ pointerEvents: loader === "none" ? "auto" : "none", height: '100%', width: '100%', margin: '0 auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={props.closeSlider}>
                <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
            </button>
            <div className='row'>
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-5">LabelId</label>
                    <div className='col-sm-6 padding-remove'>
                        <input
                            type="text"
                            defaultValue={labelId}
                            className="form-control"
                            readOnly
                        ></input>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-5">Label</label>
                    <div className='col-sm-6 padding-remove'>
                        <input type='text' className="form-control" defaultValue={label} onChange={(e) => handleLabelChange(e.target.value)} />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-5">Display Order</label>
                    <div className='col-sm-6 padding-remove'>
                        <input type='number' className="form-control" defaultValue={paymentPriority} onChange={(e) => handlepaymentPriorityChange(e.target.value)} />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className="form-group col-md-6">
                    <label className="control-label col-sm-5">In Use</label>
                    <div className='col-sm-6 padding-remove'>
                        <input type='checkbox' className="form-control" defaultValue={inUse} defaultChecked={!inUse ? 0 : 1} onChange={(e) => handleInUseChange(e.target.checked)} />
                    </div>
                </div>
            </div>
            <div className="col-md-12">
                <div className="col-md-6">
                    <div className="col-md-11 padding-remove">
                        <button type="button" className="btn btn-info btn-space pull-right" onClick={(e) => saveLabel(e)}>Save</button>
                    </div>
                </div>
            </div>
        </form>
    );
};
export default LabelSlider;