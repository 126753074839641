import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { confirm } from 'react-confirm-box'
import 'common/css/master.css'
import Header from 'common/js/header/header.js'
import {
  getLstBillSvc,
  getClassListSvc,
  publishForBillNoSvc,
  reGenForBillNoSvc
} from './BillGenerationSvc.js'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

const BillGenerationTable = props => {
  const [lstFeeBill, setLstFeeBill] = useState([]);
  const [lstFeeBillFiltered, setLstFeeBillFiltered] = useState("");
  const [billGenId, setBillGenId] = useState(0)
  const [open, setOpen] = useState(false)
  const [billId, setBillId] = useState(0)
  const [clsId, setClsId] = useState(0)
  const [lstClass, setLstClass] = useState([])
  const [lstMonth, setLstMonth] = useState([
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    'Jan',
    'Feb',
    'Mar'
  ])
  const [conf, setConf] = useState(0)
  useEffect(() => {
    getClassListSvc(cbClassListResponse)
  }, [])

  function cbClassListResponse(data) {
    if (data.SvcStatus === 'Success') {
      setLstClass(data.lstClass)
    } else {
      toast.error(data.SvcStatus)
    }
  }

  function handleClassChange(pClsId) {
    setClsId(pClsId)
  }

  useEffect(() => {
    getLstBillSvc(clsId, cbBillGenListResponse)
  }, [])

  function cbBillGenListResponse(data) {
    if (data.SvcStatus === 'Success') {
      setLstFeeBill(data.lstFeeBill)
      setLstFeeBillFiltered(data.lstFeeBill)
    } else {
      toast.error(data.SvcMsg)
    }
  }

  function handleSelBillId(pBillId, pCsvMonth) {
    setBillId(pBillId)
    props.setSelBillGenId(pBillId)
    props.setSelCsvMonth(pCsvMonth)
  }

  function getStrMonthByCsvMonth(strMonths) {
    if (strMonths === undefined) {
      return '-'
    }
    var csvMonth = null
    for (var i = 0; i < 12; i++) {
      if (strMonths.charAt(i) === '1') {
        if (!csvMonth) {
          csvMonth = lstMonth[i]
        } else {
          csvMonth += ', ' + lstMonth[i]
        }
      }
    }
    return csvMonth
  }
  // const optionsWithLabelChange = {
  //     closeOnOverlayClick: true,
  //     labels: {
  //         confirmable: "Confirm",
  //         extra:"No",
  //         cancellable: "Cancel"
  //       },
  //       customUI: () => {
  //         return (
  //           <div className='custom-ui'>
  //           </div>
  //         );
  //       }
  //   };
  // const customRender = {
  //     render: (message, onConfirm, onCancel) => {
  //       return (
  //         <div className='col-md-10 popupBox '>
  //           <p>{message}</p>
  //           <button className="btn" onClick={onConfirm} onConfirm={() => setConf(1)}> Publish for All </button>
  //           <button className="btn" onConfirm={() => setConf(2)}> Publish for Remaining </button>
  //           <button className="btn"onCancel={()=>setConf(0)} > Cancel </button>
  //         </div>
  //       );
  //     }
  //   };

  async function publishForBillNo(billGenerationId, flg) {
    publishForBillNoSvc(billGenerationId, flg, cbPublishForBillNoSvc)
    setOpen(false)
  }

  function cbPublishForBillNoSvc(data) {
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
    } else {
      toast.error(data.SvcMsg)
    }
  }
  function handleClickOpen(param) {
    setBillGenId(param)
    setOpen(true)
  }
  function handleClose() {
    setBillGenId(0)
    setOpen(false)
  }
  function reGenForBillNo(billGenerationId) {
    const result = window.confirm(
      'Regenerating will regenrate details against same bill number'
    )
    if (result === true) {
      reGenForBillNoSvc(billGenerationId, cbReGenForBillNoSvc)
    }
  }
  function cbReGenForBillNoSvc(data) {
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
    } else {
      toast.error(data.SvcMsg)
    }
  }

  const handleMonthFilter = (fMonth) => {
    let filteredTable = [];
    // fMonth = fMonth ? fMonth : "";
    if (lstFeeBill && lstFeeBill.length) {
      filteredTable = lstFeeBill.filter(feeBill =>
        getStrMonthByCsvMonth(feeBill.months).toLowerCase().includes(fMonth.toLowerCase()));
    }

    setLstFeeBillFiltered(filteredTable);
  }



  return (
    <div>
      {/* <div className='col-sm-2'>
        <select
          className='form-control'
          value={clsId}
          onChange={e => handleClassChange(e.target.value)}
        >
          <option value={0}>Class</option>
          {lstClass.map((obj, idx) => {
            return (
              <option key={idx} value={obj.clsId}>
                {obj.cls}
              </option>
            )
          })}
        </select>
      </div> */}
      <div className='col-sm-11' style={{ margin: '1% auto' }}>
        <table className='tableLayout1'>
          <thead>
            <tr>
              <th style={{ display: 'none' }}> Bill Gen Id </th>
              <th style={{ width: 30 }} className='text-left'>
                <select
                  className='form-control  inp-search'
                  // style={{border:"1px solid #ccc"}}
                  value={clsId}
                  onChange={e => handleClassChange(e.target.value)}
                >
                  <option value={0}>Class</option>
                  {lstClass.map((obj, idx) => {
                    return (
                      <option key={idx} value={obj.clsId}>
                        {obj.cls}
                      </option>
                    )
                  })}
                </select>
              </th>
              <th style={{ width: 30 }} className='text-left'>
                <input type="text"
                  className="form-control inp-search"
                  onKeyUp={(e) => { handleMonthFilter(e.target.value) }}
                  placeholder="Month"
                />
              </th>
              <th style={{ width: 60 }} className='text-center'>
                Generated On
              </th>
              <th style={{ width: 60 }} className='text-center'>
                Published On
              </th>
              <th style={{ width: 60 }} className='text-center'>
                Due Date
              </th>
              <th style={{ width: 90 }} className='text-center'>
                Action
              </th>
            </tr>
          </thead>
        </table>
        <div
          className='errorTableDiv'
          style={{
            height: '500px',
            overflowY: 'scroll',
            border: '1px solid #ccc'
          }}
        >
          <table id='errorTable' className='tableLayout'>
            <colgroup>
              <col width='30' />
              <col width='30' />
              <col width='60' />
              <col width='60' />
              <col width='60' />
              <col width='90' />
            </colgroup>
            <tbody>
              {
                lstFeeBillFiltered && lstFeeBillFiltered.length ?
                  lstFeeBillFiltered.map((obj, idx) => {
                    return (
                      (clsId === 0 || clsId == obj.clsId) && (
                        <tr
                          onClick={e =>
                            handleSelBillId(
                              obj.billGenerationId,
                              getStrMonthByCsvMonth(obj.month)
                            )
                          }
                          className={
                            obj.billGenerationId === billId ? 'selected' : ''
                          }
                          key={idx}
                        >
                          <td className='no-display'>{obj.billGenerationId}</td>
                          <td className='no-display'>{obj.month}</td>
                          <td className='text-left'>{obj.cls}</td>
                          <td className='text-left'>
                            {getStrMonthByCsvMonth(obj.months)}
                          </td>
                          <td className='text-center'>{obj.generatedDttm}</td>
                          <td className='text-center'>{obj.publishedDttm}</td>
                          <td className='text-center'>{obj.dueDate}</td>
                          <td className='text-center'>
                            <label
                              style={{
                                textDecoration: 'underline',
                                cursor: 'pointer'
                              }}
                              className='text-primary'
                              onClick={e =>
                                props.openViewBillSlider(
                                  2,
                                  obj.cls,
                                  obj.clsId,
                                  getStrMonthByCsvMonth,
                                  obj.months
                                )
                              }
                            >
                              View
                            </label>
                            {' | '}
                            <label
                              style={{
                                textDecoration: 'underline',
                                cursor: 'pointer'
                              }}
                              onClick={e => handleClickOpen(obj.billGenerationId)}
                              className='text-primary'
                            >
                              Publish
                            </label>
                            {' | '}
                            <label
                              style={{
                                textDecoration: 'underline',
                                cursor: 'pointer'
                              }}
                              onClick={e => reGenForBillNo(obj.billGenerationId)}
                              className='text-primary'
                            >
                              Re-generate
                            </label>
                          </td>
                        </tr>
                      )
                    )
                  }) : null
              }
            </tbody>
          </table>
        </div>
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby='responsive-dialog-title'
          >
            <DialogTitle id='responsive-dialog-title'>
              {'Do you want to publish for all or remaining students?'}
            </DialogTitle>
            <DialogActions>
              <Button onClick={e => publishForBillNo(billGenId, true)}>
                Publish For All
              </Button>
              <Button onClick={e => publishForBillNo(billGenId, false)}>
                Publish For Remaining
              </Button>
              <Button onClick={e => handleClose()}>Cancel</Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  )
}
export default BillGenerationTable
