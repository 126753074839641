import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import Header from "common/js/header/header";
import Loader from "common/components/Loader";
import MonthList from "common/components/monthListStatic";
import ClassList from "common/components/classList";
import SectionList from "common/components/sectionList";

import DailyDiarySummaryTbl from "./dailySummaryTbl";
import DiaryData from "./diaryData.json";
import { getDiaryDailyService, getClassListSvc, getSectionListSvc } from "./dailySummarySvc";



const DailyDiarySummary = () => {

    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [lstSummary, setLstSummary] = useState([]);
    const [diaryId, setDiaryId] = useState(0);
    const [monthId, setMonthId] = useState(0);
    const [lstCls, setLstCls] = useState([]);
    const [lstSection, setLstSection] = useState([]);
    const [clsId, setClsId] = useState(0);
    const [sectionId, setSectionId] = useState(0);
    const params = new URLSearchParams(window.location.search);

    useEffect(() => {
        // // console.log("DailyDiarySummary component called");
        if (!!params.get("month") && !!params.get("cls") && !!params.get("section")) {
            getData(params.get("month"), params.get("cls"), params.get("section"));
        }
        else if (!!params.get("month")) {
            handleMonthChange(params.get("month"));
        }
        else if (!!params.get("cls")) {
            handleClassChange(params.get("cls"));
        }
        else if (!!params.get("section")) {
            handleSectionChange(params.get("section"));
        }
        else {
            getClassList();
        }
    }, []);

    const getData = async (pMonthId, pClsId, pSectionId) => {
        // // console.log("getData called, pMonthId", pMonthId, ", pClsId", pClsId, ", pSectionId", pSectionId);
        await setMonthId(pMonthId);
        await setClsId(pClsId);
        await setSectionId(pSectionId);
        getClassList();
        handleClassChange(pClsId);
        getSummary(pMonthId, pSectionId);
    }

    const getSummary = (pMonthId, pSectionId) => {
        // // console.log("getSummary called, pMonthId", pMonthId, ", pSectionId", pSectionId);
        setLoader("block");
        setOpacity(0.5);
        getDiaryDailyService(pMonthId, pSectionId, lstSectionSummaryResponse);
    }


    const lstSectionSummaryResponse = (data) => {
        // // console.log("lstSectionSummaryResponse called, data", data);
        if (data.status === "Success") {
            setLstSummary(data.lstDailySummary);
        } else if (data.status === "Failure") {
            handleServiceError(data.message);
        } else {
            toast.error("Service failed " + data.error);
        }
        setLoader("none");
        setOpacity(1);
    }

    const handleServiceError = (svcMsg) => {
        if (svcMsg === "You are not Logged In") {
            toast.error("You are not logged in. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else if (svcMsg === "Server Timed Out, Login Again") {
            toast.error("Server timed out. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else {
            toast.error(svcMsg);
        }
    };


    const getClassList = () => {
        setLoader("block");
        setOpacity(0.5);

        getClassListSvc((response) => {
            // // console.log("response in getClassListSvc", response);
            if (response.SvcStatus === "Success") {
                setLstCls(response.lstCls);
                if (!!clsId) {
                    handleClassChange(clsId);
                }
            } else {
                toast.error(response.SvcMsg);
                handleServiceError(response.SvcMsg);
            }
            setLoader("none");
            setOpacity(1);
        });
    };

    const getSectionList = (pClsId) => {
        getSectionListSvc(pClsId, handleGetSectionListResponse);
    };

    const handleGetSectionListResponse = (data) => {
        // // console.log("response in handleGetSectionListResponse", data);
        const obj = data;
        if (obj.status === 'Success') {
            setLstSection(obj.lstSection);
            if (!!sectionId) {
                handleSectionChange(sectionId);
            }
        } else {
            handleServiceError(obj.message);
        }
    };

    const handleClassChange = async (pClsId) => {
        // // console.log("handleClassChange called, pClsId", pClsId);
        await setClsId(pClsId);
        getSectionList(pClsId);
    }

    const handleSectionChange = async (pSectionId) => {
        // // console.log("handleSectionChange called, pSectionId", pSectionId);
        if (!!pSectionId) {
            await setSectionId(pSectionId);
            if (!!monthId) {
                getSummary(monthId, pSectionId);
            }
        }
    }

    const handleMonthChange = async (pMonthId) => {
        // // console.log("handleMonthChange called, pMonthId", pMonthId);
        if (!!pMonthId) {
            await setMonthId(pMonthId);
            if (!!sectionId) {
                getSummary(pMonthId, sectionId);
            }
        }

    }


    return (
        <div >
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <Loader loader={loader} position="fixed" />
            <div className="col-md-12 col-xs-12 col-centered padding-remove"
                style={{
                    cursor: (loader === "none") ? 'pointer' : 'none',
                    pointerEvents: (loader === "none") ? 'auto' : 'none',
                    opacity: opacity
                }}>
                <div className="col-md-10 col-xs-10 col-centered padding-remove">
                    <div className="col-md-12 col-xs-12 col-centered padding-remove" style={{ marginTop: "10px", height: "28px" }}>
                        <div className="col-md-2 col-sm-3 col-xs-4 padding-remove">
                            <MonthList
                                monthId={monthId}
                                handleMonthChange={handleMonthChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-12 col-xs-12 col-centered padding-remove" style={{ marginTop: "10px", height: "28px" }}>
                        <div className="col-md-12 col-sm-12 col-xs-12 padding-remove">
                            <div className="col-md-1 col-sm-2 col-xs-6 padding-remove">
                                <ClassList lstCls={lstCls} classClick={handleClassChange} classId={clsId} />
                            </div>
                            <div className="col-md-9 col-sm-10 col-xs-12 padding-remove">
                                <SectionList lstSection={lstSection} sectionClick={handleSectionChange} selSectionId={sectionId} classId={clsId} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-xs-12 padding-remove" style={{ marginTop: "2vh" }}>
                        <div className="col-xl-6 col-lg-7 col-md-9 col-sm-10 col-xs-12 padding-remove">
                            <DailyDiarySummaryTbl
                                lstSummary={lstSummary}
                                diaryId={diaryId}
                                setDiaryId={setDiaryId}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DailyDiarySummary;