import React, { useState, useEffect } from 'react';

const LblTxtLbl = (props) => {
    const [val, setVal] = useState(0);
    useEffect(() => {
        setVal(props.dataValue)
    }, [props])

    return (
        <div className='row'>
            <label className='control-label col-sm-4 text-left'>{props.label}</label>
            <div className='form-group col-sm-4'>
                <input type={props.dataType} className='form-control' value={val} onChange={(e) => props.onChange(e.target.value)}>
                </input>
            </div>
            <label className='col-sm-4 text-left' style={{ fontWeight: 'normal' }}>{props.precededBy}</label>
        </div>
    );
};

export default LblTxtLbl;
