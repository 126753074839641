import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import {
  getStudentListSvc,
} from './commStudentSliderSvc'
import { toast, ToastContainer } from 'react-toastify'
import LEVELS from '../json/lstJson.json'

const CommunicationSlider = props => {
  const [loader, setLoader] = useState('none')
  const [communicationId, setCommunicationId] = useState(props.communicationId)
  const [channelId, setChannelId] = useState(props.channelId)
  const [templateId, setTemplateId] = useState(0)
  const [studentId, setStudentId] = useState(0)
  const [lstStudent, setLstStudent] = useState([])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if (props.communicationId && props.channelId) {
      getStudentListSvc(props.communicationId, props.channelId, cbStudentListResponse)
      setCommunicationId(props.communicationId)
      setChannelId(props.channelId)
    }
    
    return () => {
      controller.abort()
    }
  }, [props.communicationId])

  function cbStudentListResponse (data) {
    var obj = data
    if (obj.SvcStatus === 'Success') {
      setLstStudent(data.lstStudent)
    } else if (obj.SvcStatus === 'Failure') {
      setLstStudent([])
      if (obj.SvcMsg === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (obj.SvcMsg === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else {
        toast.error(obj.SvcMsg)
      }
    } else {
      toast.error('Service failed ' + obj)
    }
  }
  const colgrpStudent = (
    <colgroup>
      <col width='25%'></col>
      <col width='30%'></col>
      <col width='10%'></col>
      <col width='16%'></col>
    </colgroup>
  )

  return (
    <>
      <div
        className='form form-horizontal'
        style={{
          pointerEvents: loader === 'none' ? 'auto' : 'none',
          height: 'calc(100% - 20px)',
          width: '100%',
          margin: '10px auto',
          background: '#fff',
          border: '1px solid #ccc',
          borderRadius: 10
        }}
      >
        <button
          type='button'
          className='btn btn-info text-center btnCloseDiv'
          onClick={props.closeSlider}
          style={{ right: '80%' }}
        >
          <span
            id='close'
            title='Close Slider'
            className='glyphicon glyphicon-remove'
          ></span>
        </button>
        <div
          style={{
            position: 'fixed',
            zIndex: 10000,
            width: 100,
            height: 100,
            marginLeft: -50,
            marginTop: -50,
            left: '50%',
            top: '50%',
            display: loader
          }}
        >
          <img
            className='loderImg'
            src={require('common/images/loader.gif')}
            alt='Loader...'
          />
        </div>
        <h4>Students</h4>
        <div className='col-sm-12'>
          <div className='col-sm-6 col-centered'>
                  <table className='tableLayout1'>
                    {colgrpStudent}
                    <thead>
                      <tr>
                        <th>Enrollment No</th>
                        <th>Student</th>
                        <th>Class</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                  </table>
                  <div className='tablebodyWrapper tableBodyBorder'>
                    <table className='tableLayout '>
                      {colgrpStudent}
                      <tbody>
                        {lstStudent.length > 0 &&
                          lstStudent.map((obj, idx) => {
                            return (
                              <tr
                                key={'student_' + idx}
                                id={'student_' + obj.studentId}
                                className={
                                  obj.studentId == studentId
                                    ? 'selected'
                                    : ''
                                }
                                onClick={e => setStudentId(obj.studentId)}
                              >
                                <td>{obj.enrollmentNo}</td>
                                <td className='text-left'>{obj.name}</td>
                                <td className='text-left'>{obj.clsSection}</td>
                                <td>{obj.status}</td>
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>
                  </div>
            </div>
          </div>
      </div>
    </>
  )
}
export default CommunicationSlider
