
import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['setup'].url + ':' + lstModules['setup'].port;
var tokenid = localStorage.getItem("tokenid");
var screenId = 8;


function getSetupSvc(callback) {
    var status = 'Success'
    var obj = { status: status };
    
    axios({
        url: httpURL + '/sms/setup/completed',
        method: "POST",
        params: {  screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === 'Success') {
            obj.lstTab = response.data.lstTab;
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            // // console.log(obj.lstTab);
            callback(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });

}

export {getSetupSvc}