import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from 'react-toastify';

import { saveExamSubjectListSvc, getExamSubjectListSvc } from "./examSubjectsSvc";
import DuplicateExamSubjects from "./duplicateExamSubjectPopup";

const ExamSubjectsSlider = (props) => {

    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [lstSubject, setLstSubject] = useState([]);
    const [lstSelected, setLstSelected] = useState([]);
    const [isDuplicateFormOpen, setIsDuplicateFormOpen] = useState(false);

    useEffect(() => {
        // // console.log("ExamSubjectsSlider called, props.examId", props.examId, ", props.sectionId", props.sectionId);
        const tempLstSubject = [
            {
                "subId": 1,
                "sub": "Mathematics",
                "subType": "Main Subject",
                "selected": true
            },
            {
                "subId": 2,
                "sub": "English",
                "subType": "Language",
                "selected": true
            },
            {
                "subId": 3,
                "sub": "Science",
                "subType": "Main Subject",
                "selected": false
            },
            {
                "subId": 4,
                "sub": "History",
                "subType": "Elective",
                "selected": false
            },
            {
                "subId": 5,
                "sub": "Geography",
                "subType": "Elective",
                "selected": true
            }
        ]
        // setLstSubject(tempLstSubject);
        getSubjectsList();
    }, [props.examId, props.sectionId]);

    const getSubjectsList = () => {
        // // console.log("getSubjectsList called");
        setLoader("block");
        setOpacity(0.5);
        getExamSubjectListSvc(props.examId, props.sectionId, subjectListResponse);
    }

    const subjectListResponse = (data) => {
        // // console.log("subjectListResponse called, data", data)
        let obj = data;
        if (obj.status === 'Success') {
            // // console.log("successful response in subjectListResponse");
            setLstSubject(obj.lstExamSubject);

            const initialSelected = obj.lstExamSubject
                .filter((subject) => subject.selected === true)
                .map((subject) => subject.subId);

            setLstSelected(initialSelected);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader('none');
        setOpacity(1);
    }

    const saveSubjects = () => {
        // // console.log("saveSubjects called");
        if (!lstSelected || lstSelected.length === 0) {
            toast.error("Select atleast one subject");
        }
        const examSubSaveRQ = {
            examId: props.examId,
            sectionId: props.sectionId,
            lstSubject: lstSelected
        }
        // // console.log("examSubSaveRQ", examSubSaveRQ);
        saveExamSubjectListSvc(examSubSaveRQ, saveSubjectsResponse);
    }

    const saveSubjectsResponse = (data) => {
        // // console.log("saveSubjectsResponse called, data", data)
        let obj = data;
        if (obj.status === 'Success') {
            // // console.log("successful response in saveSubjectsResponse");
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader('none');
        setOpacity(1);
    }

    const toggleSelect = (subId) => {
        // // console.log("toggleSelect called, subId", subId);
        // // console.log("lstSelected before updation",lstSelected);
        if (lstSelected.includes(subId)) {
            const updatedLstSelected = lstSelected.filter((id) => id !== subId);
            setLstSelected(updatedLstSelected);
        } else {
            setLstSelected([...lstSelected, subId]);
        }
    }

    const openDuplicatePopup = () => {
        // // console.log("openDuplicatePopup called");
        setIsDuplicateFormOpen(true);
        setOpacity(0.7);
    }

    const closeDuplicatePopup = () => {
        // // console.log("closeDuplicatePopup called");
        setOpacity(1);
        setIsDuplicateFormOpen(false);
    }

    return (
        <div style={{ width: "100%", textAlign: "center", margin: 0 }}>
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'absolute', zIndex: 1000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={() => props.closeSlider()} style={{ right: "60%" }}>
                <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
            </button>
            <div className="col-centered col-sm-8 form-horizontal formWrapper clearfix" style={{ width: '80%', border: "1px solid #ccc", borderRadius: 10, marginLeft: "10%", paddingTop: "20px", paddingBottom: "20px", marginTop: "20px", opacity: opacity }}>
                <div className="col-sm-12 padding-remove ">
                    <label className=" col-sm-12 text-center padding-remove" style={{ fontWeight: "bold", paddingLeft: 10 }}>
                        {
                            "Subjects" + (props.cls !== "" ? " for Class " + props.cls + (props.section !== "" ? " - " + props.section : "") : "")
                        }
                    </label>
                </div>
                <div className="col-xs-10 col-centered">
                    <table className="tableLayout1" id='customTable'>
                        <thead>
                            <tr>
                                <th className="no-display">Subject Id</th>
                                <th width="100">Subject</th>
                                <th width="100">Type</th>
                                <th width="100">Select</th>
                            </tr>
                        </thead>
                    </table>

                    <div className="tableDiv tableBodyBorder" style={{ height: "300px" }}>
                        <table className='tableLayout'>
                            <tbody id="examSubjectSlider">
                                {lstSubject.map((subject) => (
                                    <tr key={subject.subId}>
                                        <td className="no-display">{subject.subId}</td>
                                        <td width="100" className="text-center">{subject.sub}</td>
                                        <td width="100" className="text-center">{subject.subType}</td>
                                        <td width="100" className="text-center"><input type="checkbox" className="formControl " checked={lstSelected.includes(subject.subId)} onChange={() => toggleSelect(subject.subId)} /></td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="col-sm-12  padding-remove" style={{ marginTop: 20 }} >
                        <div className="col-sm-6 padding-remove pull-right" style={{ display: "flex", justifyContent: "flex-end", }}>
                            <div className="col-sm-6 padding-remove">
                                <button
                                    type="button"
                                    style={{
                                        width: "90%",
                                        padding: "3px 12px",
                                        fontSize: 16,
                                    }}
                                    title="Save"
                                    onClick={() => saveSubjects()}
                                    className="btn btn-success" >
                                    Save
                                </button>
                            </div>
                            <div className="col-sm-6 padding-remove">
                                <button
                                    type="button"
                                    style={{
                                        width: "90%",
                                        padding: "3px 12px",
                                        fontSize: 16,
                                    }}
                                    title="Duplicate Subjects for other sections"
                                    onClick={() => openDuplicatePopup()}
                                    className="btn btn-success" >
                                    Duplicate
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isDuplicateFormOpen ?
                <DuplicateExamSubjects
                    examId={props.examId}
                    sectionId={props.sectionId}
                    cls={props.cls}
                    clsId={props.clsId}
                    section={props.section}
                    closePopup={closeDuplicatePopup}
                />
                : null
            }
        </div >
    );
}

export default ExamSubjectsSlider;