import React, { useEffect, useState } from 'react';
import { Text, View, Font } from '@react-pdf/renderer';
import { StyleSheet } from '@react-pdf/renderer';

import { toast } from "react-toastify";
import { styleSchoScore, styleSummary } from "./examReportCardStyles.js";


Font.register({
    family: 'Calibri'
    , fonts: [{ src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibri.ttf" }
        , { src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibrib.ttf", fontWeight: "bold" }], format: 'truetype'
});


const SummarySection = (props) => {
    const { studentSummary } = props;


    return (
        <View style={styleSchoScore.container}>
            <View style={styleSchoScore.score_table_summary}>
                <View style={[styleSchoScore.exam_row, { height: "20px" }]}>
                    <View style={[styleSummary.subject_col_first, {flex:3}]}>
                        <View style={[ { flex: 2, backgroundColor: '#f0f0f0', }]}>
                            <Text style={[styleSchoScore.text_schoExam_label1, styleSchoScore.text_center]} >
                                Total
                            </Text>
                        </View>
                        <View style={[styleSummary.sectionCell, { flex: 2 }]}>
                            <Text style={[styleSchoScore.text_schoExam_label, styleSchoScore.text_center]}>
                                {studentSummary.totalScore + " / " + studentSummary.totalMM}
                            </Text>
                        </View>
                    </View>
                    <View style={[styleSummary.subject_col, {flex:3}]}>
                        <View style={[ { flex: 2, backgroundColor: '#f0f0f0',  }]}>
                            <Text style={[styleSchoScore.text_schoExam_label1, styleSchoScore.text_center]}>
                                PERCENTAGE
                            </Text>
                        </View>
                        <View style={[styleSummary.sectionCell, { flex: 2 }]}>
                            <Text style={[styleSchoScore.text_schoExam_label, styleSchoScore.text_center]}>
                                {studentSummary.percentage} %
                            </Text>
                        </View>
                    </View>
                    <View style={[styleSummary.subject_col, {flex:2}]}>
                        <View style={[ { flex: 2, backgroundColor: '#f0f0f0',  }]}>
                            <Text style={[styleSchoScore.text_schoExam_label1, styleSchoScore.text_center]}>
                                GRADE
                            </Text>
                        </View>
                        <View style={[styleSummary.sectionCell, { flex: 2 }]}>
                            <Text style={[styleSchoScore.text_schoExam_label, styleSchoScore.text_center]}>
                                {studentSummary.grade}
                            </Text>
                        </View>
                    </View>
                    <View style={[styleSummary.subject_col, {flex:2}]}>
                        <View style={[ { flex: 2, backgroundColor: '#f0f0f0',  }]}>
                            <Text style={[styleSchoScore.text_schoExam_label1, styleSchoScore.text_center]}>
                                RANK
                            </Text>
                        </View>
                        <View style={[styleSummary.sectionCell, { flex: 2 }]}>
                            <Text style={[styleSchoScore.text_schoExam_label, styleSchoScore.text_center]}>
                                {studentSummary.rank}
                            </Text>
                        </View>
                    </View>
                </View>
            </View>
        </View >
    );
}

export default SummarySection;
