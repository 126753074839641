import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
// import tempLstMarks from "./lstmarks";
// import tempLstTerms from "./lstterms";

const StudentReportTable = () => {

    const [lstTerms, setLstTerms] = useState([]);
    const [lstMarks, setLstMarks] = useState([]);
    const [subjectId, setSubjectId] = useState(0);

    useEffect(() => {

    }, []);

    return (
        <div className="col-md-12" style={{ marginTop: "5px" }}>
            <table className="tableLayout1" >
                <thead>
                    <tr>
                        <th className="no-display " rowSpan={"3"} colSpan={"1"} width="20">
                            Subject Id
                        </th>
                        <th className='text-center ' rowSpan={"3"} colSpan={"1"} width="50">
                            Subject
                        </th>
                        {lstTerms && lstTerms.length ?
                            lstTerms.map((term) =>
                                <th className='text-center' rowSpan={""} colSpan={2 * (term.lstExams.length + 1)} width={20 * (term.lstExams.length + 1)}>
                                    {term.termLabel}
                                </th>
                            )
                            : null
                        }
                    </tr>
                    <tr>
                        {lstTerms && lstTerms.length ?
                            lstTerms.map((term) =>
                                <>
                                    {
                                        term.lstExams && term.lstExams.length ?
                                            term.lstExams.map((exam) =>
                                                <th colSpan={"2"} width="20">{exam.examLabel}</th>
                                            )
                                            : null
                                    }
                                    <th colSpan={"2"} width="20">Total</th>
                                </>
                            ) : null
                        }
                    </tr>
                    <tr>
                        {lstTerms && lstTerms.length ?
                            lstTerms.map((term) =>
                                <>
                                    {
                                        term.lstExams && term.lstExams.length ?
                                            term.lstExams.map((exam) =>
                                                <>
                                                    <th width="10">Total</th>
                                                    <th width="10">Obt.</th>
                                                </>
                                            )
                                            : null
                                    }
                                    <th width="10">Total</th>
                                    <th width="10">Obt.</th>
                                </>
                            ) : null
                        }
                    </tr>
                </thead>
            </table>
            <div className="tableDiv tableBodyBorder" style={{ height: "500px" }}>
                <table className='tableLayout'>
                    <tbody id="commGroupTable">
                        {
                            lstMarks && lstMarks.length ?
                                lstMarks.map((marks) => (
                                    <tr key={marks.subjectId}
                                        onClick={(e) => setSubjectId(marks.subjectId)}
                                        className={marks.subjectId === subjectId ? "selected" : ""}>
                                        <td style={{ width: 20 }} className='no-display text-center'>{marks.subjectId}</td>
                                        <td style={{ width: 50 }} className='text-left'>{marks.subjectName}</td>
                                        <>
                                            {
                                                marks.lstTerms && marks.lstTerms.length ?
                                                    marks.lstTerms.map((term) => (
                                                        <>
                                                            {
                                                                term.lstExams && term.lstExams.length ?
                                                                    term.lstExams.map((exam) => (
                                                                        <>
                                                                            <td style={{ width: 10 }} className='text-center'>{exam.total}</td>
                                                                            <td style={{ width: 10 }} className='text-center'>{exam.obtained}</td>
                                                                        </>
                                                                    ))
                                                                    : null
                                                            }
                                                        </>
                                                    ))
                                                    : null
                                            }
                                        </>
                                    </tr>
                                ))
                                : null
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}
export default StudentReportTable;