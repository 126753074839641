import React, { useEffect, useState } from 'react'
import Header from 'common/js/header/header'
import { toast, ToastContainer } from 'react-toastify'
import 'common/css/master.css'
import $ from 'jquery';

import ProgressReportSlider from './progressReportSlider.js';

import { getClassData, } from './progressReportSvc.js';



const ProgressReport = () => {
  const [state, setState] = useState({
    mounted: false,
    loader: 'none',
    opacity: 1,
    flg: true,
    tokenid: localStorage.getItem('tokenid'),
    checkedAll: false,
  })

  const [sectionId, setSectionId] = useState(0);
  const [lstAllClassSections, setLstAllClassSection] = useState([]);
  const [lstAllClassSectionsFiltered, setLstAllClassSectionsFiltered] = useState([]);
  const [selClsId, setSelClassId] = useState(0);
  const [classData1, setClassData1] = useState({});
  const [action, setAction] = useState('error');
  const [classFilter, setClassFilter] = useState("");
  const [sectionFilter, setSectionFilter] = useState("");
  const [heading, setHeading] = useState("");
  const [syllabusId, setSyllabusId] = useState(0);
  const [sliderKey, setSliderKey] = useState(0);

  useEffect(() => {
    setClassList();
    setHeading("Report Card");
  }, [])

  function handleClassListResponse(data) {
    var obj = data
    hideLoader()
    // // console.log('lstAllClassSection =' + obj.lstAllClassSections)
    if (obj.status === 'Success') {
      setLstAllClassSection(obj.lstAllClassSections)
      setLstAllClassSectionsFiltered(obj.lstAllClassSections)
    } else if (obj.status === 'Failure') {
      if (obj.message === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (obj.message === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else {
        toast.error(obj.message)
        return false
      }
    } else {
      toast.error('Service failed ' + obj)
    }
  }


  const setLoaderValue = (ldr) => {
    setState({ loader: ldr });
  }

  const setOpacityValue = (opct) => {
    setState({ opacity: opct });
  }

  function setClassList() {
    showLoader()
    getClassData(handleClassListResponse)
  }

  function closeSlider() {
    $('body').removeClass('openSlider')
    // setSliderKey(prevKey => prevKey + 1);
  }

  const rowClickInMain = async e => {
    var data = e.target.getAttribute('data')
    await setClassData1(JSON.parse(data))
    $('body').addClass('openSlider');
    setSliderKey(prevKey => prevKey + 1);
  }


  const showClsSlider = async (clsObj, e) => {
    // // console.log("showSlider called, clsObj", clsObj);
    e.persist()
    const classSection = clsObj.className + ( clsObj.className ? (" - " + clsObj.section + " ") : "");
    await setSelClassId(clsObj.classId);
    await setSectionId(clsObj.classSectionId);
    await setSyllabusId(clsObj.syllabusId);
    await setHeading(classSection + "Report Card");
    await setAction('cls')
    rowClickInMain(e)
  }
  function getWingId(wingId) {
    // // console.log('wingId' + wingId)
  }

  function hideLoader() {
    setState({ ...state, loader: 'none', opacity: 1, pointerEvents: 'auto' })
  }
  function showLoader() {
    setState({ ...state, loader: 'block', opacity: 0, pointerEvents: 'none' })
  }
  const filterCls = e => {
    setClassFilter(e.target.value);
    filterTable(e.target.value, sectionFilter);
  }
  const filterSec = e => {
    setSectionFilter(e.target.value);
    filterTable(classFilter, e.target.value);
  }

  const filterTable = (fClass, fSection) => {
    // // console.log("lstAllClassSections", lstAllClassSections);
    let filterClassSecList = [];
    filterClassSecList = lstAllClassSections.filter(clsSec =>
      clsSec.className.toString().toLowerCase().includes(fClass.toLowerCase()));

    filterClassSecList = filterClassSecList.filter(clsSec =>
      clsSec.section.toString().toLowerCase().includes(fSection.toLowerCase()));

    setLstAllClassSectionsFiltered(filterClassSecList);
  }
  return (
    <div className='container1'>
      <Header wingFromChild={getWingId} />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div
        style={{
          position: 'fixed',
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: '50%',
          top: '50%',
          display: state.loader
        }}
      >
        <img
          className='loderImg'
          src={require('common/images/loader.gif')}
          alt='Loader...'
        />
      </div>
      <div className='col-md-11 col-centered' style={{ marginTop: 15 + 'px' }}>
        <div className='col-md-10 padding-remove' style={{ width: '87%' }}>
          <table className='tableLayout1' id='customTable'>
            <thead>
              <tr>
                <th style={{ width: 40 + '%' }}>
                  <input
                    type='text'
                    value={classFilter}
                    onChange={filterCls}
                    className='form-control inp-search'
                    placeholder='Class'
                  />
                </th>
                <th style={{ width: 30 + '%' }}>
                  <input
                    type='text'
                    value={sectionFilter}
                    onChange={filterSec}
                    className='form-control inp-search'
                    placeholder='Section'
                  />
                </th>
                <th style={{ width: 70 + '%' }}>Action</th>
              </tr>
            </thead>
          </table>

          <div className='tableDiv' style={{ height: 500 + 'px' }}>
            <table id='periodTypeTable' className='tableLayout'>
              <tbody id='periodTypeTableBody'>
                {lstAllClassSectionsFiltered && lstAllClassSectionsFiltered.map(obj => (
                  <tr
                    className={
                      obj.classSectionId === parseInt(sectionId)
                        ? 'selected'
                        : ''
                    }
                    key={obj.classSectionId}
                    id={obj.classSectionId}
                  >
                    <td hidden>{obj.classId}</td>
                    <td style={{ width: 40 + '%' }} className='text-left'>
                      {obj.className}
                    </td>
                    <td hidden>{obj.classSectionId}</td>
                    <td style={{ width: 30 + '%' }} className='text-left'>
                      {obj.section}
                    </td>
                    <td
                      style={{ width: 70 + '%' }}
                      className='text-center'
                      data={JSON.stringify(obj)}
                    >
                      <span className='fa fa-eye' title="View"></span>
                      <span
                        className='glyphicon glyphicon-share'
                        title="Generate"
                        data={JSON.stringify(obj)}
                        onClick={e =>
                          showClsSlider(obj, e)
                        }
                      ></span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className='slider halfWidthSlider'>
        {action === 'cls' && (
          <ProgressReportSlider
            key={sliderKey}
            heading={heading}
            sectionId={sectionId}
            syllabusId={syllabusId}
            removeClassFromBody={closeSlider}
          />
        )}

      </div>
    </div>
  )
}
export default ProgressReport