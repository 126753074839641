import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['timeTable'].url + ':' + lstModules['timeTable'].port;
var tokenid = localStorage.getItem("tokenid");
var screenId = 8;

function getLstRoles(callback) {
    var status = 'Success'
    var obj = { status: status };

    axios({
        url: httpURL + '/sms/tt/v1/tt/mst/acd_role/list',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === 'Success') {
            obj.lstRole = response.data.lstRole;
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            // // console.log(obj);
            callback(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });
}

function getLstPeriod(dt, callback) {
    var status = 'Success'
    var obj = { status: status };
    axios({
        url: httpURL + '/sms/tt/v1/absent/period/list',
        method: "POST",
        params: { screenId, dt },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === 'Success') {
            obj.lstPeriod = response.data.lstPeriod;
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';

            callback(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });
}
function getLstAbsentTch(dt, callback) {
    var status = 'Success'
    var obj = { status: status };
    axios({
        url: httpURL + '/sms/tt/v1/absent/abs_tch/list',
        method: "POST",
        params: { screenId, dt },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === 'Success') {
            obj.lstAbsentTch = response.data.lstAbsentTch;
            obj.completionPct = response.data.completionPct;
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            callback(obj)
        } else {
            obj.message = response.data.SvcMsg;
            obj.status = response.data.SvcStatus;
            callback(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });
}

function getLstSubs(dt, roleId, clsId, sectionId, periodId, subId, callback) {
    var status = 'Success'
    var obj = { status: status };
    var  objSubsCell= { dt: dt, roleId: roleId, clsId: clsId, sectionId: sectionId, periodId: periodId, subId: subId };
    var formData = new FormData();
    formData.append("subsCell",JSON.stringify(objSubsCell));
    formData.append("screenId", screenId);
    axios({
        url: httpURL + '/sms/tt/v1/absent/subs/list',
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === 'Success') {
            obj.lstSubsTch = response.data.lstSubsTch;
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            callback(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });
}


function getHeaderDates(dt, callback) {
    var status = 'Success'
    var obj = { status: status };
    axios({
        url: httpURL + '/sms/tt/v1/absent/header_days/list',
        method: "POST",
        params: {
            screenId, dt
        },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === 'Success') {
            obj.lstDates = response.data.lstDates;
            obj.message = '';
            obj.status = 'Success';
            callback(obj)
        } else {
            obj.lstDates = [];
            obj.message = response.data.SvcMsg;
            obj.status = 'Failure';
            callback(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });
}

function getLstTch(dt, callback) {
    var status = 'Success'
    var obj = { status: status };
    axios({
        url: httpURL + '/sms/tt/v1/absent/tch/list',
        method: "POST",
        params: { screenId, dt },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === 'Success') {
            obj.lstTch = response.data.lstTch;
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            callback(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });
}

function setMarkAbsent(dt, tchId, absent, callback) {
    // // console.log(tchId + '::' + absent);
    var status = 'Success'
    var obj = { status: status };
    var objParam =  { dt: dt, tchId: tchId, absent: absent };
    var screenId = screenId;
    var formData = new FormData();
    formData.append("markAbsent",JSON.stringify(objParam));
    formData.append("screenId", screenId);
    axios({
        url: httpURL + '/sms/tt/v1/absent/mark_absent',
        method: "POST",
        data:formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === 'Success') {
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            callback(obj)
        }
        else {
            obj.status = 'Failure';
            obj.message = response.data.SvcMsg;
            callback(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });
}

function assignSubsTchDuty(pObj, callback) {
    // // console.log('pObj = ' + JSON.stringify(pObj))
    var status = 'Success'
    var obj = { status: status };
    //subsId, tchId, clsId, sectionId, periodId, dt, absentTchId, subId
    axios({
        url: httpURL + '/sms/tt/v1/absent/abs_tch/save',
        params: { 'subsInfo': JSON.stringify(pObj), screenId },
        method: "POST",
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === 'Success') {
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            obj.subsId=response.data.SubstitutionId;
            callback(obj)
        }
        else {
            obj.status = 'Failure';
            obj.message = response.data.SvcMsg;
            callback(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.SvcMsg = error;
        callback(obj)
    });
}

function informAll(dt, callback) {
    var status = 'Success'
    var obj = { status: status };

    axios({
        url: httpURL + '/sms/tt/v1/absent/abs_tch/inform_all',
        method: "POST",
        params: { screenId: screenId, informAllRQ: JSON.stringify({ dt: dt }) },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === 'Success') {
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            callback(obj)
        }
        else {
            obj.status = 'Failure';
            obj.message = response.data.SvcMsg;
            callback(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.SvcMsg = error;
        callback(obj)
    });
}

function removeSubsTchDuty(dt, subsId,subsTchId, periodId, clsId, sectionId, remarks, callback) {
    var status = 'Success'
    var obj = { status: status };
    var objDuty = {
        dt: dt,
        subsId:subsId,
        subsTchId: !subsTchId?0:subsTchId,
        periodId: periodId,
        clsId: clsId,
        sectionId: sectionId,
        remarks: remarks
    };
    var formData = new FormData();
    formData.append("subsInfo", JSON.stringify(objDuty));
    formData.append("screenId",screenId);
    axios({
        url: httpURL + '/sms/tt/v1/absent/abs_tch/delete',
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === 'Success') {
            obj.SvcMsg = response.data.SvcMsg;
            obj.status = 'Success';
            callback(obj)
        }
        else {
            obj.status = 'Failure';
            obj.SvcMsg = response.data.SvcMsg;
            callback(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.SvcMsg = error;
        callback(obj)
    });
}

export { getLstRoles, getLstPeriod, getLstAbsentTch, getLstSubs, getLstTch, getHeaderDates, setMarkAbsent, assignSubsTchDuty, removeSubsTchDuty, informAll };