
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { feeDueSvc } from "./feePaymentSvc";


const FeeDueCell = (props) => {
    const { cellKey, studentId, feeDue, updateFeeDue } = props;
    const [feeDueStudent, setFeeDueStudent] = useState(feeDue);
    const [loaderCell, setLoaderCell] = useState("none");

    useEffect(() => {
        // // console.log("feeDueCell called, studentId", props.studentId, ", feeDue", props.feeDue, ", cellKey", props.cellKey);
        if(cellKey){
            getFeeDue()
        }
    }, [cellKey]);

    const getFeeDue = () => {
        // // console.log("getFeeDue called from feeDueCellNew")
        setLoaderCell('block');
        feeDueSvc(studentId, feeDueResponse);
    };

    const feeDueResponse = (data) => {
        let obj = data;
        if (obj.status === 'Success') {
            setFeeDueStudent(obj.feeDue);
            props.updateFeeDue(studentId, obj.feeDue);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoaderCell('none');
    };

    return (
        <div>
            <div style={{ zIndex: 10000, margin: '-10px 20px', display: loaderCell }}>
                <img className="cellLoderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <span style={{ display: loaderCell === 'none' ? 'block' : 'none' }} >{feeDueStudent}</span>
        </div>
    );
}

export default FeeDueCell;