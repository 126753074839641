import React, { useState, useEffect } from 'react';
import 'jquery-ui';
import 'common/css/master.css';
import 'react-datepicker/dist/react-datepicker.css';


const SubsTchTbl = (props) => {
    const [lstSubsTch, setLstSubsTch] = useState(props.lstSubsTch)

    useEffect(() => {
        setLstSubsTch(props.lstSubsTch)
    }, [props]);

    return (
        <div>

            <table className="tableLayout1" >
                <thead>
                    <tr>
                        <th style={{ display: 'none' }} rowSpan="2"> Tch Id </th>
                        <th style={{ width: 20 + '%' }} rowSpan="2"> Select </th>
                        <th style={{ width: 35 + '%' }} rowSpan="2"> Teacher </th>
                        <th style={{ width: 35 + '%' }} rowSpan="2"> Role </th>
                        <th style={{ width: 60 + '%' }} colSpan="3"> Allocation </th>
                        <th style={{ width: 60 + '%' }} rowSpan="2"> Remarks/ <br /> Free Periods</th>

                    </tr>
                    <tr>
                        <th style={{ width: 20 + '%' }}> Class</th>
                        <th style={{ width: 20 + '%' }}> Subject</th>
                        <th style={{ width: 20 + '%' }}> Role</th>
                    </tr>
                </thead>
            </table>
            <div className="subjectTableDiv" style={{ height: '24em', overflowY: 'scroll', border: '1px solid #ccc' }} >
                <table id="subjectTable" className="tableLayout">
                    <colgroup>
                        <col width="20" />
                        <col width="35" />
                        <col width="35" />
                        <col width="20" />
                        <col width="20" />
                        <col width="20" />
                        <col width="60" />
                    </colgroup>
                    <tbody>
                        {lstSubsTch.map(obj => {
                            var isSelected = obj.tchId === props.selTchId;
                            return <tr key={obj.tchId} className={isSelected ? "selected" : ""} id={obj.tchId}>
                                <td className="text-center"><span className="glyphicon glyphicon-triangle-left" tch-id={obj.tchId} onClick={props.rowClick}></span></td>
                                <td>{obj.tch}</td>
                                <td>{obj.role}</td>
                                <td style={{ textAlign: "center" }}>{obj.eligibleCls}</td>
                                <td style={{ textAlign: "center" }}>{obj.eligibleSub}</td>
                                <td style={{ textAlign: "center" }}>{obj.eligibleRole}</td>
                                <td style={{ textAlign: "center" }}>{obj.freePeriods}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
export default SubsTchTbl;