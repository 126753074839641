import React from "react";

const LblTxt = (props) => {
  return (
    <div className={props.wrapperClass || "row"}>
      <label className={props.lblCls || "control-label col-sm-4"}>{props.label}</label>
      <div className={props.divClass || "form-group col-sm-8"}>
        <input
          type={props.dataType}
          className="form-control"
          defaultValue={props.dataValue}
        ></input>
      </div>
    </div>
  );
};

export default LblTxt;
