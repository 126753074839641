import React, { useState, useEffect } from "react";
import lstModules from "common/json/lstModules.json";
import axios from "axios";
import "common/css/master.css";
import Header from "common/js/header/header.js";
import { toast, ToastContainer } from "react-toastify";
import {
  previewPdfReport1,
  previewRouteListReport,
  previewRouteDtlsReport,
} from "./tptReportApi.js";

const TptReportCtrl = () => {
  const [state, setState] = useState({
    wingId: "",
    loader: "none",
    opacity: 1,
    tokenid: localStorage.getItem("tokenid"),
  });
  const [sliderDisplayed, setSliderDisplayed] = useState(false);
  const [randNo, setRandNo] = useState(0);

  function showLoader() {
    setState({ ...state, loader: "block", opacity: 0.5 });
  }
  function hideLoader() {
    setState({ ...state, loader: "none", opacity: 1 });
  }
  function getWingId(wingId) {
    // // console.log("wingId" + wingId);
  }
  function generateRandomNo() {
    var lRandNo = Math.floor(Math.random() * 999999);
    // // console.log(lRandNo);
    setRandNo(lRandNo);
  }

  function handlePreviewPdfResponse(data) {
    hideLoader();
    var obj = data;
    if (obj.status === "Failure") {
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    }
    showSlider();
    let blob = new Blob([data.data], { type: "application/pdf" }),
      url = window.URL.createObjectURL(blob);
    const iframe = document.getElementById("viewer");
    iframe.setAttribute("src", url);
    URL.revokeObjectURL(url);
  }
  const showSlider = () => {
    setSliderDisplayed(true);
  };

  const closeSlider = () => {
    generateRandomNo();
    setSliderDisplayed(false);
  };
  function downloadRouteNoList(e, val) {
    // // console.log(val);
    e.preventDefault();
    if (val) {
      showLoader();
      previewPdfReport1(handlePreviewPdfResponse);
    } else closeSlider();
  }
  function downloadRouteList(e, val) {
    // // console.log(val);
    e.preventDefault();
    if (val) {
      showLoader();
      previewRouteListReport(handlePreviewPdfResponse);
    } else closeSlider();
  }
  function downloadRouteDtls(e, val) {
    // // console.log(val);
    e.preventDefault();
    if (val) {
      showLoader();
      previewRouteDtlsReport(handlePreviewPdfResponse);
    } else closeSlider();
  }

  return (
    <div style={{ height: "100%" }}>
      <Header wingFromChild={getWingId} />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: state.loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>
      <div className="MainContainer" style={{ opacity: state.opacity }}>
        <div className="col-sm-10 col-centered" style={{ marginTop: 25 }}>
          <div className="tableLayout1" style={{ display: "table" }}>
            <div
              className="col-sm-12 padding-remove"
              style={{
                cursor:"pointer",
                display: "flex",
                minHeight: 60,
                justifyContent: "center",
              }}
              onClick={(e) => downloadRouteNoList(e, true)}
            >
              <button className="btn btn-info"
                style={{
                  margin: "auto 0",
                  float: "left",
                  textAlign: "left",
                  width: "15%",
                  padding: "5px",
                }}
              >
                <span>Route No. for Vehicle</span>
              </button>
              <button
                style={{
                  fontSize: 12,
                  margin: "auto 2%",
                  float: "left",
                  textAlign: "left",
                  width: "55%",
                  padding: "5px",
                }}
              >
                <span>
                  This will print a large route no. on a single sheet. It can be
                  pasted on the buses.
                </span>
              </button>
            </div>
            <div
              className="col-sm-12 padding-remove"
              onClick={(e) => downloadRouteList(e, true)}
              style={{
                cursor:"pointer",
                display: "flex",
                minHeight: 60,
                justifyContent: "center",
              }}
            >
              <button className="btn btn-info"
                style={{
                  margin: "auto 0",
                  float: "left",
                  textAlign: "left",
                  width: "15%",
                  padding: "5px",
                }}
              >
                <span>Routes List</span>
              </button>
              <button
                style={{
                  fontSize: 12,
                  margin: "auto 2%",
                  float: "left",
                  textAlign: "left",
                  width: "55%",
                  padding: "5px",
                }}
              >
                <span>
                  It will print a list of all routes with details like Route No,
                  Bus No(s)., Driver Details, Conductor Details, Women
                  Coordinator Details, Coordinator Details, No. of Stops, No. of
                  Students
                </span>
              </button>
            </div>
            <div
              className="col-sm-12 padding-remove"
              onClick={(e) => downloadRouteDtls(e, true)}
              style={{
                cursor:"pointer",
                display: "flex",
                minHeight: 60,
                justifyContent: "center",
              }}
            >
              <button className="btn btn-info"
                style={{
                  margin: "auto 0",
                  float: "left",
                  textAlign: "left",
                  width: "15%",
                  padding: "5px",
                }}
              >
                <span>Routes Details</span>
              </button>
              <button
                style={{
                  fontSize: 12,
                  margin: "auto 2%",
                  float: "left",
                  textAlign: "left",
                  width: "55%",
                  padding: "5px",
                }}
              >
                <span>
                  It will the route details on a single sheet. Route Stops,
                  Sequence, Name of students and phone no. for each stop.
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-7 col-centered padding-remove">
        {sliderDisplayed && <Slider />}
      </div>
    </div>
  );
  function Slider(props) {
    return (
      <div
        className="slider halfWidthSlider"
        style={{ opacity: sliderDisplayed ? 1 : 0, right: 0 }}
      >
        <div className="pdf" style={{ width: "100%", height: "96vh" }}>
          <iframe
            src={require("common/images/loader.gif")}
            title="PDF"
            id="viewer"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <button
          type="button"
          className="btn btn-info text-center "
          id="closeSlider"
          style={{ opacity: sliderDisplayed ? 1 : 0, right: "70%" }}
          onClick={(e) => downloadRouteNoList(e, false)}
        >
          <span
            id="close"
            title="Close Slider"
            className="glyphicon glyphicon-remove"
          ></span>
        </button>
      </div>
    );
  }
};

export default TptReportCtrl;
