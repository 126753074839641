import React from 'react';
import { toast, ToastContainer } from 'react-toastify'
import { showLeaderBoardSvc } from './WTestProgressSvc'

const WTestProgressHeader = ({ testId, test }) => {
    function showLeaderboard() {
        showLeaderBoardSvc(testId, cbShowLeaderBoardSvc)
    }
    function cbShowLeaderBoardSvc(data) {
        if (data.SvcStatus === "Success") {
            toast.success(data.SvcMsg)
        } else {
            toast.error(data.SvcMsg)
        }
    }
    return (
        <form className="form form-horizontal">
            <div className="col-sm-12 padding-remove" style={{ padding: 0 }}>
                <div style={{ height: 35, border: '1px solid #b4b4b4' }} className="col-sm-2">
                    <label className="control-label col-sm-7" style={{ paddingRight: 0 }}>Test ID:</label>
                    {testId && <div className='col-sm-5 form-group '>
                        <label className="text-left">{testId}</label>
                    </div>}
                </div>
                <div style={{ height: 35, marginLeft: 10, border: '1px solid #b4b4b4' }} className="col-sm-2">
                    <label className="control-label col-sm-2" style={{ paddingRight: 0 }}>Test:</label>
                    {test && <div className='col-sm-10 form-group'>
                        <label className="text-left" >{test}</label>
                    </div>}
                </div>
                <div className="col-sm-5" style={{ width: '65%' }}>

                    <button type="button" className="btn btn-info pull-right" onClick={(e) => showLeaderboard()}>Show Leaderboard</button>

                </div>
            </div>
        </form>
    )
}

export default WTestProgressHeader