import axios from 'axios';

import lstModules from 'common/json/lstModules';
let httpURLCommon = lstModules['main'].url + ":" + lstModules["main"].port;
let httpURL = lstModules['registration'].url + ":" + lstModules['registration'].port;

let queryString = require('querystring');
var tokenid = localStorage.getItem('tokenid');
let screenId = 17;
function getInterviewScheduleData(studentId, handleInterviewDtlsResponse) {

    axios.post(httpURL + '/sms/reg/sch/int/data',
        queryString.stringify(),
        {
            params: { studentId, screenId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then((response) => {
        handleInterviewDtlsResponse(response.data);//response.data.interviewData

        //setInterViewResult();

    }).catch((e) => {
        // toast.error('Service failed: ' + e);
    });
}
function getModeList(handleGetModeResponse) {
    axios.post(httpURLCommon + '/sms/mst/payment_mode/list',
        queryString.stringify({ screenId }), {
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // // console.log("finished service call");
        handleGetModeResponse(response.data.lstPaymentMode);
    }).catch((e) => {
        //      handleGetModeResponse(e);
    });
}

function getTchList(handleTchListResponse) {
    axios({
        url: httpURLCommon + '/sms/teacher/list',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        handleTchListResponse(response.data.lstTeacher);
        // this.fillTeacherCmb();
    }).catch((error) => {
        //        handleTchListResponse(error);
    });

}
function getClsList(handleClsListResponse) {
    axios({
        url: httpURLCommon + '/sms/mst/class/list',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': this.state.tokenid },
        withCredentials: true,
    }).then((response) => {
        handleClsListResponse(response.data.lstClass);
        // lstClass = response.data.lstClass;
        // this.fillClassCmb();
        this.setState({ loader: 'none', opacity: 1 });
    }).catch((error) => {
        //        handleClsListResponse(error);
    });
}
function getRegistrationList(handleRegListResponse) {
    axios({
        url: httpURL + '/sms/reg/list',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        handleRegListResponse(response.data.lstRegistration);
    }).catch((error) => {
        //        handleRegListResponse(error)
    });
}
function getSlotTimeList(classId, slotType, date, handleSlotListResponse) {

    // this.setState({ loader: 'block', opacity: 0.5 });
    axios.post(httpURL + '/sms/reg/sch/test/slots/time',
        queryString.stringify({ 'date': date, 'classId': classId, 'slotType': slotType, screenId }), {
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        handleSlotListResponse(response.data.lstSlotsTime);
        // this.setSlotTimeTable();
    }).catch((error) => {
        //        handleSlotListResponse(error);
    });
}

function getFeeData(studentId, handleFeeResponse) {
    axios.post(httpURL + '/sms/reg/fees/list',
        queryString.stringify({ studentId, screenId }),
        {
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then((response) => {
        handleFeeResponse(response.data.regsFeeData)
        // toast.success(response.data.SvcMsg);
        // regsFeeData = response.data.regsFeeData;
        // this.setFeeForm();


    }).catch((e) => {
        // toast.error('Service failed: ' + e);
    });
}
function saveFees(regFees, handleFeeSaveResponse) {
    axios.post(httpURL + '/sms/reg/fees/save ',
        queryString.stringify({ 'regFees': JSON.stringify(regFees), screenId }),
        {
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then((response) => {
        handleFeeSaveResponse(response.data);
    }).catch((e) => {
        // toast.error('Service failed: ' + e);
    });
}
function saveInterviewDtls(schedule, studentId, handleSaveInterviewResponse) {
    axios.post(httpURL + '/sms/reg/sch/int/save?studentId=' + studentId,
        queryString.stringify({ 'schedule': JSON.stringify(schedule), screenId }),
        {
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then((response) => {
        handleSaveInterviewResponse(response.data);
    }).catch((e) => {
        // toast.error('Service failed: ' + e);
    });
}

export { getModeList, getClsList, getTchList, getRegistrationList, getSlotTimeList, getFeeData, getInterviewScheduleData, saveFees, saveInterviewDtls }