import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import $ from 'jquery';
import 'jquery-ui';
import '../css/classStudentManage.css';
import 'common/css/master.css';
import axios from 'axios';
import Header from 'common/js/header/header.js';
import { toast, ToastContainer } from 'react-toastify';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const params = new URLSearchParams(window.location.search);
var httpURL = '';
var queryString = require('querystring');
var tokenid = localStorage.getItem('tokenid');
var lstClass = [];
var lstClassStudents = [];
var lstEligibleStudents = [];
var lstClassTeacher = [];
var lstBuildings = [];
var lstRoom = [];
var lstPrefect = [];
var lstMonitor = [];
var screenId = 2;
var lstAllClassSections = [];
var classId = params.get("classId");
var classSectionId = params.get("classSectionId");
var section = params.get("section");
var classSectionData = "";
var students = [];
var lstGroup1 = [];
var lstGroup2 = [];
var groupLabel = [];






class classStudentManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: 'none',
            opacity: 1,
            flg: true,
            classSectionFlg: true,
            flg1: true,
            fromDt: null,
            effectiveDt: null,
            tokenid: tokenid,
            wingId: localStorage.getItem('wingId'),

        }
        this.handleChange1 = this.handleChange1.bind(this);
    }

    handleChange1(date) {
        this.setState({
            effectiveDt: date,
        });
    }

    componentDidMount = async () => {
        $('#roomCmb').attr('disabled', true);
        $('#roomCmb').prepend($("<option> </option>").val(0).html('Room'));
        $('#classesCmb').prepend($("<option> </option>").val(0).html('Class'));
        $('#buildingCmb').prepend($("<option> </option>").val(0).html('Building'));
        $('#group1Cmb').prepend($("<option> </option>").val(0).html('Group 1'));
        $('#group2Cmb').prepend($("<option> </option>").val(0).html('Group 2'));


        this.setState({ loader: 'block', opacity: 0.5 });
        await axios.post(httpURL + '/sms/mst/class/list',
            queryString.stringify({ screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstClass = response.data.lstClass;
                this.fillClassCmb();

            }
            else {
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            }
        }).catch((error) => {
            toast.error("Service failed " + error);
        });

        await this.getBuildingList();

        axios({
            url: httpURL + '/sms/teacher/list',
            method: "POST",
            params: { screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstClassTeacher = response.data.lstTeacher;
                this.fillClassTeacherCmb();
            }
            else {

                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            }
        }).catch((error) => {
            toast.error("Service failed " + error);
        });


        this.varifyClassSection();

        lstPrefect = [{ 'prefectId': '1', 'prefectName': 'Surya' }, { 'prefectId': '2', 'prefectName': 'Arya' },
        { 'prefectId': '3', 'prefectName': 'Vishal' }, { 'prefectId': '4', 'prefectName': 'Stark' },
        { 'prefectId': '5', 'prefectName': 'Dharma' }, { 'prefectId': '6', 'prefectName': 'Sar' },

        ];
        this.fillPrefectCmb();

        lstMonitor = [{ 'monitorId': '1', 'monitorName': 'Aadi' }, { 'monitorId': '2', 'monitorName': 'Arya' },
        { 'monitorId': '3', 'monitorName': 'Vishal' }, { 'monitorId': '4', 'monitorName': 'Rohit' },
        { 'monitorId': '5', 'monitorName': 'Allu' }, { 'monitorId': '6', 'monitorName': 'Pawan' },

        ];
        this.fillMonitorCmb();
    }



    varifyClassSection = async () => {


        if (parseInt(classSectionId) !== 0) {
            this.setState({ loader: 'block', opacity: 0.5 });
            await axios.post(httpURL + '/sms/cls/section/list',
                queryString.stringify({ screenId }), {
                headers: { 'tokenid': this.state.tokenid },
                withCredentials: true
            }).then((response) => {
                if (response.data.SvcStatus === 'Success') {
                    lstAllClassSections = response.data.lstAllClassSections;
                }
                else {

                    if (response.data.SvcMsg === 'You are not logged in') {
                        setTimeout(function () { window.location.href = ('/') }, 2000);
                        return false;
                    }
                    else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                        setTimeout(function () { window.location.href = ('/') }, 2000);
                        return false;
                    } else {
                        toast.error(response.data.SvcMsg);
                    }
                }
                this.setState({ loader: 'none', opacity: 1 });
            }).catch((error) => {
                this.setState({ loader: 'none', opacity: 1 });
                toast.error("Service failed " + error);
            });


            this.getStudentsSectionList(classId, section);
            this.getGroupList();
            this.setClassSectionForm(classSectionId);
            this.setState({
                classSectionFlg: false,
            })

            $('.classButtomSection').show();

        }
        else {
            this.setState({
                classSectionFlg: true,
            })
            $('.classButtomSection').hide();
            $('#classAddForm').trigger('reset');
        }

    }

    getStudentEligibleList = (classId) => {
        axios.post(httpURL + '/sms/cls/elig_stu/list',
            queryString.stringify({ classId, screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstEligibleStudents = response.data.lstEligibleStudents;
                this.setStudentTable();
            }
            else {

                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }

    getStudentsSectionList = (classId, sectionId) => {
        axios.post(httpURL + '/sms/cls/stu/list',
            queryString.stringify({ classId, section, screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstClassStudents = response.data.lstClassStudents;
                this.setClassStudentTable();
            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }
    getGroupList = () => {
        axios.post(httpURL + '/sms/cls/get_groups',
            queryString.stringify({ screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                groupLabel = response.data.groupLabel;
                lstGroup1 = response.data.lstGroup1;
                lstGroup2 = response.data.lstGroup2;
                this.fillGroupCmb();
            }
            else {

                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }
    fillGroupCmb = () => {
        $('#group1Cmb').empty();
        $('#group1Cmb').prepend($("<option> </option>").val(0).html(groupLabel.group1Label));
        for (var i = 0; i < lstGroup1.length; i++) {
            var groupId = lstGroup1[i].groupId;
            var groupName = lstGroup1[i].groupName;
            $('#group1Cmb').append($("<option> </option>").val(groupId).html(groupName));
        }

        $('#group2Cmb').empty();
        $('#group2Cmb').prepend($("<option> </option>").val(0).html(groupLabel.group2Label));
        for (var i = 0; i < lstGroup2.length; i++) {
            var groupId = lstGroup2[i].groupId;
            var groupName = lstGroup2[i].groupName;
            $('#group2Cmb').append($("<option> </option>").val(groupId).html(groupName));
        }
    }

    getBuildingList = () => {
        axios.post(httpURL + '/sms/a/building/list',
            queryString.stringify({ screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstBuildings = response.data.lstBuildings;
                this.fillBuildingCmb();
            }
            else {

                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }

    fillClassCmb() {
        $('#classesCmb').empty();
        $('#classesCmb').prepend($("<option> </option>").val(0).html('Class'));
        for (var i = 0; i < lstClass.length; i++) {
            var classId = lstClass[i].classId;
            var className = lstClass[i].className;
            $('#classesCmb').append($("<option> </option>").val(classId).html(className));
        }
    }

    setClassSectionForm = async (classSectionId) => {
        classSectionData = this.getClassSecionData(classSectionId);
        // // console.log(classSectionData);
        $('#classesCmb').val(classSectionData.classId);
        $('#section').val(classSectionData.section);
        $('#buildingCmb').val(classSectionData.buildingId);
        await this.changeBuilding();
        $('#roomCmb').val(classSectionData.roomId);
        $('#roomCapacity').val(classSectionData.capacity);
        $('#maxStudents').val(classSectionData.maxStudents);
        $('#classTeacherCmb').val(classSectionData.classTeacherId);


    }

    getClassSecionData = (classSectionId) => {
        for (var i = 0; i < lstAllClassSections.length; i++) {
            if (parseInt(classSectionId) === lstAllClassSections[i].classSectionId) {
                return lstAllClassSections[i];
            }
        }
        return -1;
    }


    fillRoomCmb() {
        $('#roomCmb').empty();
        $('#roomCmb').prepend($("<option> </option>").val(0).html('Room'));
        for (var i = 0; i < lstRoom.length; i++) {
            var roomId = lstRoom[i].roomId;
            var room = lstRoom[i].room;
            $('#roomCmb').append($("<option> </option>").val(roomId).html(room));
        }
    }
    fillClassTeacherCmb() {
        $('#classTeacherCmb').empty();
        $('#classTeacherCmb').prepend($("<option> </option>").val(0).html('Class Teacher'));
        for (var i = 0; i < lstClassTeacher.length; i++) {
            var teacherId = lstClassTeacher[i].teacherId;
            var teacher = lstClassTeacher[i].teacher;
            $('#classTeacherCmb').append($("<option> </option>").val(teacherId).html(teacher));
        }
    }
    fillBuildingCmb() {
        $('#buildingCmb').empty();
        $('#buildingCmb').prepend($("<option> </option>").val(0).html('Building'));
        for (var i = 0; i < lstBuildings.length; i++) {
            var buildingId = lstBuildings[i].buildingId;
            var buildingName = lstBuildings[i].buildingName;
            $('#buildingCmb').append($("<option> </option>").val(buildingId).html(buildingName));
        }
    }
    fillPrefectCmb() {
        $('#prefectCmb').empty();
        $('#prefectCmb').prepend($("<option> </option>").val(0).html('Prefect'));
        for (var i = 0; i < lstPrefect.length; i++) {
            var prefectId = lstPrefect[i].prefectId;
            var prefectName = lstPrefect[i].prefectName;
            $('#prefectCmb').append($("<option> </option>").val(prefectId).html(prefectName));
        }
    }
    fillMonitorCmb() {
        $('#monitorCmb').empty();
        $('#monitorCmb').prepend($("<option> </option>").val(0).html('Monitor'));
        for (var i = 0; i < lstMonitor.length; i++) {
            var monitorId = lstMonitor[i].monitorId;
            var monitorName = lstMonitor[i].monitorName;
            $('#monitorCmb').append($("<option> </option>").val(monitorId).html(monitorName));
        }
    }

    changeBuilding = async () => {
        $('#roomCapacity').val("");
        $('#roomCapacity').attr('disabled', false);
        var buildingId = $('#buildingCmb').find('option:selected').val();
        this.setState({ loader: 'block', opacity: 0.5 });
        await axios.post(httpURL + '/sms/a/building/rooms/list',
            queryString.stringify({ 'buildingId': buildingId, screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                $('#roomCmb').attr('disabled', false);
                lstRoom = response.data.lstBuildingRooms;
                this.fillRoomCmb();
            }
            else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }

    changeRoom = () => {
        var roomId = $('#roomCmb').find('option:selected').val();
        for (var i = 0; i < lstRoom.length; i++) {
            if (parseInt(roomId) === lstRoom[i].roomId) {
                $('#roomCapacity').val(lstRoom[i].capacity);
                $('#roomCapacity').attr('disabled', true);
            }
        }
    }


    setClassStudentTable = () => {
        $('#classStudentTableBody tr').remove();
        var table = document.getElementById('classStudentTable');
        var tbody = document.getElementById('classStudentTableBody');

        for (var i = 0; i < lstClassStudents.length; i++) {
            var studentId = lstClassStudents[i].studentId;
            var name = lstClassStudents[i].name;
            var rollNo = lstClassStudents[i].rollNo;
            var email = lstClassStudents[i].email;
            var regsNo = lstClassStudents[i].regsNo;
            var group1 = lstClassStudents[i].group1;
            var group2 = lstClassStudents[i].group2;







            var row = document.createElement('tr');
            row.id = studentId;

            row.onclick = (e) => {
                $(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');
                // $('#curStudent').attr('disabled', true);
                // this.setClassStudentForm($(e.target).closest('tr').attr('id'));
                // this.setState({ flg: false });
            }



            this.addTD(row, studentId, 'no-display');
            this.addTD(row, rollNo, 'text-center');
            this.addTD(row, name, 'text-center');
            this.addTD(row, email, 'text-center');
            this.addTD(row, regsNo, 'text-center');
            this.addTD(row, group1, 'text-center');
            this.addTD(row, group2, 'text-center');
            this.addTDCBX(row, 'StuCbx' + studentId, 'text-center');
            this.addTDAction(row, studentId, 'glyphicon glyphicon-trash', 'text-center actions');



            tbody.append(row);
        }
        table.append(tbody);
    }

    addTDCBX(row, id, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var div = document.createElement('div');

        var cbx = document.createElement('input');
        cbx.type = 'checkbox';
        cbx.id = id;
        div.append(cbx);
        cell.append(div);
        row.append(cell);
    }

    setStudentTable = () => {
        $('#studentTableBody tr').remove();
        var table = document.getElementById('studentTable');
        var tbody = document.getElementById('studentTableBody');
        var stuNameFilter = $('#stuNameFilter').val() !== undefined ? $('#stuNameFilter').val().toLowerCase() : '';
        var stuRegdFilter = $('#stuRegdFilter').val() !== undefined ? $('#stuRegdFilter').val().toLowerCase() : '';
        var stuRollNoFilter = $('#stuRollNoFilter').val() !== undefined ? $('#stuRollNoFilter').val().toLowerCase() : '';
        var stuClassFilter = $('#stuClassFilter').val() !== undefined ? $('#stuClassFilter').val().toLowerCase() : '';

        for (var i = 0; i < lstEligibleStudents.length; i++) {
            var studentId = lstEligibleStudents[i].studentId;
            var name = lstEligibleStudents[i].name;
            var section = lstEligibleStudents[i].section;
            var className = lstEligibleStudents[i].className;
            var rollNo = lstEligibleStudents[i].rollNo;
            var regdNo = lstEligibleStudents[i].regdNo === undefined ? '-' : lstEligibleStudents[i].regdNo;



            if (name != null && name.toLowerCase().indexOf(stuNameFilter) === -1)
                continue;
            if (rollNo != null && rollNo.toLowerCase().indexOf(stuRegdFilter) === -1)
                continue;
            if (className != null && className.toLowerCase().indexOf(stuClassFilter) === -1)
                continue;
            if (regdNo != null && regdNo.toLowerCase().indexOf(stuRollNoFilter) === -1)
                continue;

            var row = document.createElement('tr');
            row.id = studentId;

            row.onclick = (e) => {
                $(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');
                this.getConfirmation();
            }



            this.addTD(row, studentId, 'no-display');
            this.addTD(row, regdNo, 'text-center');
            this.addTD(row, name, 'text-center');
            this.addTD(row, className, 'text-center');
            this.addTD(row, section, 'text-center');
            this.addTD(row, rollNo, 'text-center');



            tbody.append(row);
        }
        table.append(tbody);
    }

    getConfirmation = () => {
        var retVal = window.confirm("Do you want to Assign Section ?");
        if (retVal == true) {
            this.assignClassSection();
            return true;
        } else {
            return false;
        }
    }

    assignClassSection = () => {
        var studentId = $('#studentTableBody tr.selected').find('td:eq(0)').text();
        this.setState({ loader: 'block', opacity: 0.5 });
        var studentClassSection = { studentId, classId, section };
        axios.post(httpURL + '/sms/cls/student/assign',
            queryString.stringify({ 'studentClassSection': JSON.stringify(studentClassSection), screenId }),
            {
                headers: { tokenid: this.state.tokenid },
                withCredentials: true
            }
        ).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getStudentsSectionList(classId, section);
                this.getStudentEligibleList(classId);
                $('body').removeClass('openSlider');
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });

    }

    addTD(row, val, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var text = document.createTextNode(val);
        cell.append(text);
        row.append(cell);
    }
    addTDAction(row, statusId, val, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var span1 = document.createElement('span');
        span1.className = 'glyphicon glyphicon-edit'
        span1.style.cursor = 'pointer';
        span1.onclick = () => {
            $('td').removeClass('selected');
            $(row).addClass('selected').siblings().removeClass('selected');
            //var studentId = $('#classStudentTableBody tr.selected').find('td:eq(0)').text();
            $('body').addClass('showPopup');
            //this.setStudentForm(studentId);
            this.setState({ flg1: false });
        };
        cell.append(span1);
        row.append(cell);
    }


    maintainClass = () => {
        if (this.state.flg) {
            this.addClass();
        } else {
            this.editClass();
        }
    }





    addClass = () => {
        var classArr = {};
        var classclassSectionId = 0;
        var classId = $('#classesCmb').find('option:selected').val();
        var section = $('#section').val();
        //  var buildingName = $('#buildingCmb').find('option:selected').text();
        var buildingId = $('#buildingCmb').find('option:selected').val();
        //var room = $('#roomCmb').find('option:selected').text();
        var roomId = $('#roomCmb').find('option:selected').val();
        var maxStudents = $('#maxStudents').val();
        var roomCapacity = $('#roomCapacity').val();
        var curStudent = $('#curStudent').val();
        // var prefect = $('#prefectCmb').find('option:selected').text();
        // var monitor = $('#monitorCmb').find('option:selected').text();
        var className = $('#classesCmb').find('option:selected').text();





        if (parseInt(classId) === 0) {
            toast.warning('Please Select Valid Class');
            $('#clsName').focus();
            return false;
        }
        if (section === "") {
            toast.warning('Please Enter  Section');
            $('#section').focus();
            return false;
        }

        if (parseInt(buildingId) === 0) {
            toast.warning('Select Valid Building');
            $('#buildingCmb').focus();
            return false;
        }
        if (parseInt(roomId) === 0) {
            toast.warning('Select Valid Room');
            $('#roomCmb').focus();
            return false;
        }
        if (roomCapacity === "") {
            toast.warning('Please Enter  Room Capaciyt');
            $('#roomCapacity').focus();
            return false;
        }
        if (curStudent === "") {
            toast.warning('Please Enter Number of Student');
            $('#curStudent').focus();
            return false;
        }
        if (maxStudents === "") {
            toast.warning('Please Enter  Max Student');
            $('#maxStudents').focus();
            return false;
        }

        if (this.state.classSectionFlg) {
            classSectionId = 0;
        }
        else {
            classSectionId = localStorage.getItem('classSectionId');
        }

        // if (prefect === "") {
        //     toast.warning('Select Valid Prefect');
        //     $('#prefectCmb').focus();
        //     return false;
        // }
        // if (monitor === "") {
        //     toast.warning('Select Valid monitor');
        //     $('#monitorCmb').focus();
        //     return false;
        // }


        classArr = {
            'classSectionId': classSectionId,
            'classId': classId,
            'wingId': this.state.wingId,
            'className': className,
            'section': section,
            'maxStudents': maxStudents,
            'buildingId': buildingId, 'roomId': roomId,
        }

        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/cls/section/save',
            queryString.stringify({ 'classSection': JSON.stringify(classArr), screenId }),
            {
                headers: { tokenid: this.state.tokenid },
                withCredentials: true
            }
        ).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                classSectionId = response.data.classSectionId;
                $('.classButtomSection').show();
                // this.varifyClassSection();

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });
    }


    addClassStudentReady = () => {
        $('#classStudentTableBody tr.selected').removeClass('selected');
        $('td').removeClass('selected');
        $('body').addClass('openSlider');
        var classId = $('#classesCmb').val();
        this.getStudentEligibleList(parseInt(classId));
    }


    cancelStudent = () => {
        $('body').removeClass('showPopup');
    }

    closeSlider = () => {
        $('body').removeClass('openSlider');
    }

    saveClassTeacher = () => {
        var classTeacherId = $('#classTeacherCmb').find('option:selected').val();
        if (parseInt(classTeacherId) === 0) {
            toast.warning('Select Valid Class Teacher');
            $('#classTeacherCmb').focus();
            return false;
        }

        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/cls/class_teacher/save',
            queryString.stringify({ classTeacherId, classSectionId, screenId }),
            {
                headers: { tokenid: this.state.tokenid },
                withCredentials: true
            }
        ).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });
    }


    selectAll = () => {

        if ($('#cbxSelectAll').prop('checked') === true) {
            $('#classStudentTableBody tr').each(function () {
                if ($(this).find($('input[type=checkbox]')).prop('disabled') === false) {
                    $(this).find($('input[type=checkbox]')).prop('checked', true);
                }
            });

        }
        else {
            $('#classStudentTableBody tr input[type=checkbox]').prop('checked', false);
        }
    }


    setRollNo = () => {
        var studentRollNo = {};
        var classId = $('#classesCmb').find('option:selected').val();
        var section = $('#section').val();
        classId = parseInt(classId);


        students = [];
        $('#classStudentTableBody tr').each(function () {
            if ($(this).find($('input[type=checkbox]')).prop('checked') == true) {
                var studentId = $(this).find('td:eq(0)').text();
                students.push(parseInt(studentId));
            }
        });
        if (parseInt(students.length) === 0) {
            toast.warning('Please Select Atleast one Student');
            return false;
        }
        studentRollNo = { students, classId, section }
        // // console.log(studentRollNo);
        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/cls/stu/assign_roll',
            queryString.stringify({ 'studentRollNo': JSON.stringify(studentRollNo), screenId }),
            {
                headers: { tokenid: this.state.tokenid },
                withCredentials: true
            }
        ).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getStudentsSectionList(classId, section);
                $('#cbxSelectAll').prop('checked', false);
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });
    }


    assignGroup = () => {
        var studentGroup = {};
        var group1Id = $('#group1Cmb').find('option:selected').val();
        var group2Id = $('#group2Cmb').find('option:selected').val();
        var section = $('#section').val();
        group1Id = parseInt(group1Id);
        group2Id = parseInt(group2Id);


        students = [];
        $('#classStudentTableBody tr').each(function () {
            if ($(this).find($('input[type=checkbox]')).prop('checked') == true) {
                var studentId = $(this).find('td:eq(0)').text();
                students.push(parseInt(studentId));
            }
        });

        if (parseInt(students.length) === 0) {
            toast.warning('Please Select Atleast one Student');
            return false;
        }
        if (parseInt(group1Id) === 0) {
            toast.warning('Please Select Group');
            $('#group1Cmb').focus();
            return false;
        }
        studentGroup = { students, group1Id, group2Id }
        // // console.log(studentGroup);
        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/cls/stu/assign_group',
            queryString.stringify({ 'studentGroup': JSON.stringify(studentGroup), screenId }),
            {
                headers: { tokenid: this.state.tokenid },
                withCredentials: true
            }
        ).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getStudentsSectionList(classId, section);
                $('#cbxSelectAll').prop('checked', false);

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });
    }

    removeStudents = () => {

        students = [];
        $('#classStudentTableBody tr').each(function () {
            if ($(this).find($('input[type=checkbox]')).prop('checked') == true) {
                var studentId = $(this).find('td:eq(0)').text();
                students.push(parseInt(studentId));
            }
        });

        if (parseInt(students.length) === 0) {
            toast.warning('Please Select Atleast one Student');
            return false;
        }


        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/cls/stu/remove',
            queryString.stringify({ 'students': JSON.stringify(students), screenId }),
            {
                headers: { tokenid: this.state.tokenid },
                withCredentials: true
            }
        ).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getStudentsSectionList(classId, section);
                $('#cbxSelectAll').prop('checked', false);

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });
    }

    getWingId = (wingId) => {
        this.setState({
            wingId: wingId,
        })
    }


    render() {
        httpURL = lstModules['classes'].url + ":" + lstModules['classes'].port;
        return (
            <div style={{ height: '100%' }}>
                <Header wingFromChild={this.getWingId} />
                <ToastContainer autoClose={3000} hideProgressBar />
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: this.state.loader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>
                <div className="mainContainer Class" style={{ opacity: this.state.opacity }}>
                    <div className="col-sm-11 col-centered">
                        <div className="col-sm-12 col-xs-12 padding-remove classFormWrap" >
                            <form id="classAddForm" className="form-horizontal formWrapper clearfix">
                                <div className="form-group col-sm-4">
                                    <label className="control-label col-sm-5" > Class <span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <select onChange={this.changeClass} className="form-control" style={{ width: '100%' }} id="classesCmb"></select>
                                    </div>
                                </div>
                                <div className="form-group col-sm-4">
                                    <label className="control-label col-sm-5 " >Section  <span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="section" type="text" className="form-control " placeholder="Enter Section" />
                                    </div>
                                </div>
                                <div className="form-group col-sm-4">
                                    <label className="control-label col-sm-5" > Buildings <span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <select onChange={this.changeBuilding} className="form-control" style={{ width: '100%' }} id="buildingCmb"></select>
                                    </div>
                                </div>
                                <div className="form-group col-sm-4">
                                    <label className="control-label col-sm-5" > Room <span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <select onChange={this.changeRoom} className="form-control" style={{ width: '100%' }} id="roomCmb"></select>
                                    </div>
                                </div>
                                <div className="form-group col-sm-4">
                                    <label className="control-label col-sm-5 " >Room Capacity<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="roomCapacity" type="text" className="form-control " placeholder="Room Capacity" />
                                    </div>
                                </div>
                                <div className="form-group col-sm-4" style={{ marginBottom: 0 }}>
                                    <label className="control-label col-sm-5 " >Max. Student <span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="maxStudents" type="text" className="form-control " placeholder="Maximum Students Allowed" />
                                    </div>
                                </div>
                                <div className="form-group col-sm-4" style={{ marginTop: 10 }}>
                                    <div className="col-sm-12 text-right userButtonWrap" >
                                        <button type="button" title="Save" onClick={this.maintainClass} className="btn btn-info saveClass">Save</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-sm-12 col-xs-12 padding-remove classTeacherFormWrap ">
                            <form id="classTeacherAddForm" className="form-horizontal formWrapper clearfix">
                                <div className="teacherSection col-sm-5">
                                    <div className="form-group col-sm-10">
                                        <label className="control-label col-sm-6" > Class Teacher<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                        <div className="col-sm-6 padding-remove">
                                            <select onChange={this.changeClassTeacher} className="form-control" style={{ width: '100%' }} id="classTeacherCmb"></select>
                                        </div>
                                    </div>
                                    <div className="form-group col-sm-2" style={{ marginTop: 0 }}>
                                        <div className="col-sm-12 text-center" >
                                            <button type="button" title="Save" onClick={this.saveClassTeacher} className="btn btn-info saveClass">Save</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="prefectMonitorSection col-sm-7">
                                    <div className="col-sm-5 form-group ">
                                        <label className="control-label col-sm-6" > Prefect <span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                        <div className="col-sm-6 padding-remove">
                                            <select onChange={this.changePrefect} className="form-control" style={{ width: '100%' }} id="prefectCmb"></select>
                                        </div>
                                    </div>
                                    <div className="col-sm-5 form-group ">
                                        <label className="control-label col-sm-6" > Monitor <span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                        <div className="col-sm-6 padding-remove">
                                            <select onChange={this.changeMonitor} className="form-control" style={{ width: '100%' }} id="monitorCmb"></select>
                                        </div>
                                    </div>
                                    <div className="col-sm-2 form-group" >
                                        <div className="col-sm-12 text-center" >
                                            <button type="button" title="Save" onClick={this.addPrefectMonitor} className="btn btn-info">Save</button>

                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                    <div className="col-md-11 col-centered classButtomSection">
                        <div className="col-md-11  col-xs-11 classStudentTableWrap  padding-remove">
                            <table className="tableLayout1 classStudentTable" style={{ width: this.state.width }}>
                                <thead>
                                    <tr>
                                        <th style={{ display: 'none' }}> Studnent Id </th>
                                        <th style={{ width: 40 }}>
                                            <input type="text" onChange={this.setClassStudentTable} className="form-control inp-search" id="rollNoFilter" placeholder="Roll No." />
                                        </th>
                                        <th style={{ width: 40 }}>
                                            <div style={{ float: 'left', width: '80%' }}>
                                                <input type="text" onChange={this.setClassStudentTable} className="form-control inp-search" id="nameFilter" placeholder="Name" />
                                            </div>
                                            <div style={{ width: '15%', float: 'left', marginLeft: 2, marginTop: 0 }}>
                                                <div className="assending">
                                                    <span id="nameAscIcon" onClick={this.sortAscByName} className="glyphicon glyphicon-triangle-top"></span>
                                                </div>
                                                <div className="desending">
                                                    <span id="nameDesIcon" onClick={this.sortDesByName} className="glyphicon glyphicon-triangle-bottom"></span>
                                                </div>
                                            </div>
                                        </th>
                                        <th style={{ width: 70 }}> Email</th>
                                        <th style={{ width: 40 }}>
                                            <input type="text" onChange={this.setClassStudentTable} className="form-control inp-search" id="registerFilter" placeholder="Regd. No." />
                                        </th>
                                        <th style={{ width: 40 }}> {groupLabel.group1Label} </th>
                                        <th style={{ width: 40 }}>{groupLabel.group2Label} </th>
                                        <th style={{ width: 30 }}>
                                            <div style={{ float: 'left', width: '70%', marginTop: 6, }}>Select </div>
                                            <div style={{ width: '15%', float: 'left' }}>
                                                <input onChange={this.selectAll} type="checkbox" id="cbxSelectAll" />
                                            </div>
                                        </th>
                                        <th style={{ width: 30 }}> Action </th>

                                    </tr>
                                </thead>
                            </table>
                            <div className="classStudentTableDiv" style={{ height: '150px', overflowY: 'scroll', border: '1px solid #ccc' }} >
                                <table id="classStudentTable" className="tableLayout">
                                    <colgroup>
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="70" />
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="30" />
                                        <col width="30" />
                                    </colgroup>
                                    <tbody id="classStudentTableBody"></tbody>
                                </table>
                            </div>
                            <div className="col-sm-10 padding-remove col-centered rollButtonWrap">
                                <button type="button" title="Apply Roll No." onClick={this.setRollNo} className="btn btn-warning">Apply Roll No.</button>
                                <button type="button" title="Remove From Class" onClick={this.removeStudents} className="btn btn-warning">Remove From Class</button>
                                <div className="col-sm-2 padding-remove">
                                    <select onChange={this.changeGroup1} className="form-control" style={{ width: '100%' }} id="group1Cmb"></select>
                                </div>
                                <div className="col-sm-2 padding-remove">
                                    <select onChange={this.changeGroup2} className="form-control" style={{ width: '100%' }} id="group2Cmb"></select>
                                </div>
                                <button type="button" title="Assign Group" onClick={this.assignGroup} className="btn btn-warning">Assign</button>
                            </div>
                        </div>
                        <div className="col-xs-1 ">
                            <span id="addClassStudent" title="Add Class Student" onClick={this.addClassStudentReady} className="addIcon glyphicon glyphicon-plus-sign"></span>
                        </div>
                    </div>
                    <div className="col-md-11 col-centered slider halfWidthSlider" >
                        <div className="col-md-11  col-xs-11 col-centered studentTableWrap  padding-remove">
                            <table className="tableLayout1 studentTable" style={{ width: this.state.width }}>
                                <thead>
                                    <tr>
                                        <th style={{ display: 'none' }}> Studnent Id </th>
                                        <th style={{ width: 40 }}>
                                            <input type="text" onChange={this.setStudentTable} className="form-control inp-search" id="stuRegdFilter" placeholder="Regd. No." />
                                        </th>
                                        <th style={{ width: 40 }}>
                                            <input type="text" onChange={this.setStudentTable} className="form-control inp-search" id="stuNameFilter" placeholder="Name" />
                                        </th>
                                        <th style={{ width: 40 }}>
                                            <input type="text" onChange={this.setStudentTable} className="form-control inp-search" id="stuClassFilter" placeholder="Class" />
                                        </th>
                                        <th style={{ width: 40 }}> Section</th>
                                        <th style={{ width: 40 }}>
                                            <input type="text" onChange={this.setStudentTable} className="form-control inp-search" id="stuRollNoFilter" placeholder="Roll No." />
                                        </th>

                                    </tr>
                                </thead>
                            </table>
                            <div className="studentTableDiv" style={{ height: '300px', overflowY: 'scroll', border: '1px solid #ccc' }} >
                                <table id="studentTable" className="tableLayout">
                                    <colgroup>
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="40" />
                                    </colgroup>
                                    <tbody id="studentTableBody"></tbody>
                                </table>
                            </div>
                        </div>
                        {/* <div className="col-xs-1 ">
                            <span id="addStudent" title="Add Student" onClick={this.addStudentReady} className="addIcon glyphicon glyphicon-plus-sign"></span>
                        </div> */}
                        <button type="button" className="btn btn-info text-center " id="closeSlider" onClick={this.closeSlider}>
                            <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                        </button>
                    </div>
                    <div className="col-md-5 col-xs-8 padding-remove popup" >
                        <form id="addStudentForm" className="form-horizontal formWrapper">
                            <div className="form-group ">
                                <label className="control-label col-sm-5" > Prefect <span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                <div className="col-sm-6 padding-remove">
                                    <select onChange={this.changePrefect} className="form-control" style={{ width: '100%' }} id="prefectCmb"></select>
                                </div>
                            </div>

                            <div className="form-group ">
                                <label className="control-label col-sm-5" > Monitor <span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                <div className="col-sm-6 padding-remove">
                                    <select onChange={this.changeMonitor} className="form-control" style={{ width: '100%' }} id="monitorCmb"></select>
                                </div>
                            </div>


                            <div className="form-group" style={{ marginTop: 10 }}>
                                <div className="col-sm-12 text-center userButtonWrap" >
                                    <button type="button" title="Save" onClick={this.maintainStundent} className="btn btn-info">Save</button>
                                    <button type="button" title="Cancel" onClick={this.cancelStudent} className="btn btn-info">Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        );
    }



}
export default classStudentManage;