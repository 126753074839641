import React, { useState, useEffect } from 'react'
import lstModules from 'common/json/lstModules.json';

var httpURL = lstModules['main'].url + ":" + lstModules['main'].port;
var basePath = "/home/ubuntu/sms/be/sms_files/31270/violations/";

const Violation = ({ obj }) => {
    const [photoPath1, setPhotoPath1] = useState('')
    const [photoPath2, setPhotoPath2] = useState('')
    const [violationDt, setViolationDt] = useState(null)
    const [violationTm, setViolationTm] = useState(null)

    useEffect(() => {
        let dtParts = obj.violationDttm.split(" ");
        setViolationDt(dtParts[0])
        setViolationTm(dtParts[1])
        setPhotoPath1(httpURL + '/pa/fetch/image?imgPath=' + obj.imgPathStudent);
        setPhotoPath2(httpURL + '/pa/fetch/image?imgPath=' + basePath + obj.imgPathViolation);
    }, [])

    function onError(param) {
        if (param == 1) {
            setPhotoPath1(window.appURL + "/no-image.png");
        } else {
            setPhotoPath2(window.appURL + "/no-image.png");
        }
    }
    return (
        <div className=" col-sm-12" style={{ border: '1px solid #ddd', height: 170 }}>
            <div className="col-sm-5" style={{ paddingTop: 10, height: 170 }}>
                <div className="row">
                    <label className="control-label col-sm-4 lbl_18">Date:</label>
                    <label className='control-label col-sm-8 lbl_18'>{violationDt}</label>
                </div>
                <div className="row">
                    <label className="control-label col-sm-4 lbl_18">Time:</label>
                    <label className='control-label col-sm-8 lbl_18'>{violationTm}</label>
                </div>
                <div className="row">
                    <label className="control-label col-sm-4 lbl_18">Gate:</label>
                    <label className='control-label col-sm-8 lbl_18'>{obj.gate}</label>
                </div>

                <div className="row" style={{ padding: 10, bottom: 0, position: 'absolute' }}>
                    <label className='control-label' style={{ fontSize: 24 }}>{obj.student} ({obj.clsSection})</label>
                </div>


            </div>
            <div className="col-sm-7">
                <div className="col-sm-5" style={{ padding: "10px 0" }}>
                    <img
                        src={photoPath1}
                        alt="img"
                        style={{ height: 150, width: 150, border: "1px solid #eee", boxShadow: '2px 1px 2px #ddd' }}
                        onError={(e) => onError(1)}
                    />
                </div>
                <div className="col-sm-5" style={{ padding: "10px 0" }}>
                    <img
                        src={photoPath2}
                        alt="img"
                        style={{ height: 150, width: 150, border: "1px solid #eee", boxShadow: '2px 1px 2px #ddd' }}
                        onError={(e) => onError(2)}
                    />
                </div>
                <div className=" col-sm-2 " style={{ padding: 10 }}>
                    <div >
                        <span className="addIcon glyphicon glyphicon-bullhorn" title='Report'></span>
                    </div>
                    <div >
                        <span className="addIcon glyphicon glyphicon-remove" title='Ignore'></span>
                    </div>
                    <div >
                        <span className="addIcon glyphicon glyphicon-thumbs-down" title='False'></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Violation