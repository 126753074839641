import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import "common/css/master.css";
import "../css/student.css";
import { deleteSiblingSvc, getSiblingsListSvc } from "./studentSiblingSvc";


const StudentSiblingTable = (props) => {

    const { siblingId, setSiblingId, lstSiblings, editSibling, deleteSibling } = props;

    const [lstSibling, setLstSibling] = useState([]);

    useEffect(() => {
        // // console.log("StudentSiblingTable called passed, lstSiblings", lstSibling);
        setLstSibling(lstSiblings);
    }, [lstSiblings]);

    const colgrp = <colgroup>
        <col width="17%"></col>
        <col width="17%"></col>
        <col width="17%"></col>
        <col width="17%"></col>
        <col width="16%"></col>
        <col width="16%"></col>
    </colgroup>

    return (
        <div className="col-sm-12 col-xs-12 padding-remove">
            <table className="tableLayout1" style={{}} >
                {colgrp}
                <thead>
                    <tr>
                        <th >Name</th>
                        <th >Age</th>
                        <th >Gender</th>
                        <th >Class</th>
                        <th >School</th>
                        <th >Action</th>
                    </tr>
                </thead>
            </table>
            <div className="siblingTableDiv1 col-sm-12 col-xs-11 padding-remove">
                <table id="siblingTable" className="tableLayout">
                    {colgrp}
                    <tbody id="siblingTableBody">
                        {
                            lstSibling && lstSibling.length ?
                                lstSibling.map((sibling, i) => (
                                    <tr key={sibling.siblingId}
                                        onClick={() => setSiblingId(sibling.siblingId)}
                                        className={sibling.siblingId === siblingId ? "selected" : ''}>
                                        <td className="text-left">{sibling.sibling}</td>
                                        <td className="text-center">{sibling.age}</td>
                                        <td className="text-center">{sibling.gender}</td>
                                        <td className="text-left">{sibling.cls}</td>
                                        <td className="text-left">{sibling.school}</td>
                                        <td className="text-center"
                                        //  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                                         >
                                            {/* <div> */}
                                                <span
                                                    onClick={() => editSibling(sibling)}
                                                    title="Delete"
                                                    className="glyphicon glyphicon-edit"
                                                    style={{margin:"5px 10px"}}
                                                >
                                                </span>
                                            {/* </div>
                                            <div> */}
                                                <span
                                                    onClick={() => deleteSibling(sibling.siblingId)}
                                                    title="Delete"
                                                    className="glyphicon glyphicon-trash"
                                                    style={{margin:"5px 10px"}}
                                                >
                                                </span>
                                            {/* </div> */}
                                        </td>
                                    </tr>
                                ))
                                : null
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}
export default StudentSiblingTable;