import React, { useState, useEffect } from 'react';
import 'common/css/master.css';
import Header from 'common/js/header/header.js';
import '../css/classStudentManage.css';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom'
import {
    assignRollNo
    , removeClsStudent
    , saveCls
    , saveClsTch
    , verifyClassSection
    , getStudentsSectionList
    , getGroupList
    , assignGroupSvc
} from './classService';
import AddClassStudent from './addClassStudentSlider';
import ClassEditPopup from './classEditPopup';


const ClassEdit = (props) => {

    const [loader, setLoader] = useState('none');
    const [opacity, setOpacity] = useState(1);
    const [flg, setFlg] = useState(true);
    const [classSectionFlg, setClassSectionFlg] = useState(true);
    const [flg1, setFlg1] = useState(true);
    const [fromDt, setFromDt] = useState(null);
    const [effectiveDt, setEffectiveDt] = useState(null);
    const [tokenid, setTokenid] = useState(localStorage.getItem('tokenid'));
    const [wingId, setWingId] = useState(localStorage.getItem('wingId'));
    const [students, setStudents] = useState([]);
    const [classSectionData, setClassSectionData] = useState([]);
    const [lstAllClassSections, setLstAllClassSections] = useState([]);
    const [lstClass, setLstClass] = useState([]);
    const [lstClassTeacher, setLstClassTeacher] = useState([]);
    const [lstName, setLstName] = useState([]);
    const [classId, setClassId] = useState(0);
    const [sectionId, setSectionId] = useState(0);
    const [clsName, setClassName] = useState("");
    const [section, setSection] = useState("");
    const [total, setTotal] = useState(0);

    const [group1Id, setGroup1Id] = useState(0);
    const [group2Id, setGroup2Id] = useState(0);
    const [lstGroup1, setLstGroup1] = useState([]);
    const [lstGroup2, setLstGroup2] = useState([]);
    const [groupLabel, setGroupLabel] = useState({ group1Label: 'Group 1', group2Label: 'Group 2', });

    const [lstEligibleStudents, setLstEligibleStudents] = useState([]);
    const [lstClassStudents, setLstClassStudents] = useState([]);
    const [lstClassStudentsTable, setLstClassStudentsTable] = useState([]);
    const [lstSelect, setLstSelect] = useState([]);


    const [rollNoFiltered, setRollNoFiltered] = useState("");
    const [nameFiltered, setNameFiltered] = useState("");
    const [regNoFiltered, setRegNoFiltered] = useState("");
    const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);
    const [isaddClassStudentSliderOpen, setIsClassStudentSliderOpen] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        setClassId(params.get('classId'));
        setSectionId(params.get('sectionId'));
        setClassName(params.get('className'));
        setSection(params.get('section'));
        setStudentsSectionList(params.get('classId'), params.get('sectionId'));
        setGroupList();
    }, []);

    const setStudentList = () => {
        setStudentsSectionList(classId, sectionId);
    }

    const setStudentsSectionList = (classId, sectionId) => {
        setLoader("block");
        setOpacity(0.5);
        getStudentsSectionList(classId, sectionId, studentsSectionListResponse);
    }

    const studentsSectionListResponse = (data) => {
        let obj = data;
        if (obj.status === 'Success') {
            setLstClassStudents(obj.lstClassStudents);
            setLstClassStudentsTable(obj.lstClassStudents);
            setTotal(obj.lstClassStudents.length);
        }
        else {
            if (obj.message === 'You are not logged in') {
                toast.error(obj.message);
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                toast.error(obj.message);
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    const setGroupList = () => {
        setLoader("block");
        setOpacity(0.5);
        getGroupList(handleGrpResponse);
    }

    const handleGrpResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            setLstGroup1(obj.lstGroup1);
            setLstGroup2(obj.lstGroup2);
            setGroupLabel(obj.groupLabel);
        } else if (obj.status === 'Failure') {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error("Service failed " + obj.message);
            // // console.log("Service failed " + obj.message);
        }

        setLoader("none");
        setOpacity(1);
    }

    const generateRollNo = () => {
        if (lstSelect.length === 0) {
            toast.warning('Please Select Atleast one Student');
            return false;
        }
        let studentRollNo = {};
        studentRollNo = { "students": lstSelect, classId, sectionId }
        setLoader("block");
        setOpacity(0.5);
        assignRollNo(studentRollNo, handleAssignRollNo);
    }

    const handleAssignRollNo = (data) => {
        let obj = data;
        if (obj.status === 'Success') {
            toast.success(obj.message);
            setStudentList();
            setLstSelect([]);
            setIsSelectAllSelected(false);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    const removeStudents = () => {
        let confirmDelete = window.confirm("Selected student/s will be removed from class. Are you sure?");
        if (!confirmDelete) {
            return;
        }
        if (lstSelect.length === 0) {
            toast.warning('Please Select Atleast one Student');
            return false;
        }
        setLoader("block");
        setOpacity(0.5);
        removeClsStudent(lstSelect, removeClsStudentResponse);
    }

    const removeClsStudentResponse = (data) => {
        let obj = data;
        if (obj.status === 'Success') {
            toast.success(obj.message);
            setStudentList();
            setLstSelect([]);
            setIsSelectAllSelected(false);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    const assignGroup = () => {
        if (lstSelect.length === 0) {
            toast.warning('Please Select Atleast one Student');
            return false;
        }
        if (group1Id === 0 && group1Id === 0) {
            toast.warning('Please Select ' + groupLabel.group1Label + " or " + groupLabel.group2Label);
            return false;
        }
        let studentGroup = { students: "lstStudent", group1Id, group2Id }
        setLoader("block");
        setOpacity(0.5);
        assignGroupSvc(studentGroup, assignGroupResponse);
    }

    const assignGroupResponse = (data) => {
        let obj = data;
        if (obj.status === 'Success') {
            toast.success(obj.message);
            setStudentList();
            setLstSelect([]);
            setIsSelectAllSelected(false);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    const handleChangeRollNoFiltered = (fRoll) => {
        setRollNoFiltered(fRoll);
        filterStudentsTable(nameFiltered, fRoll, regNoFiltered);
    }

    const handleChangeNameFiltered = (fName) => {
        setNameFiltered(fName);
        filterStudentsTable(fName, rollNoFiltered, regNoFiltered);
    }

    const handleChangeRegNoFiltered = (fRegNo) => {
        setRegNoFiltered(fRegNo);
        filterStudentsTable(nameFiltered, rollNoFiltered, fRegNo);
    }

    const filterStudentsTable = (fName, fRoll, fRegNo) => {
        let filteredStudentList = [];
        filteredStudentList = lstClassStudents.filter(student =>
            student.name.toString().toLowerCase().includes(fName.toLowerCase()));

        filteredStudentList = filteredStudentList.filter(student =>
            student.rollNo.toString().toLowerCase().includes(fRoll.toLowerCase()));

        filteredStudentList = filteredStudentList.filter(student =>
            student.regsNo.toString().toLowerCase().includes(fRegNo.toLowerCase()));


        setLstClassStudentsTable(filteredStudentList);
    }

    const handleChangeSelectAll = (isChecked) => {
        // // console.log("lstSelect", lstSelect);
        // // console.log("handleChangeSelectAll called");
        if (isChecked === false) {
            setIsSelectAllSelected(false);
            setLstSelect([]);
        } else {
            setLstSelect([]);
            selectAll();
            setIsSelectAllSelected(true);
        }
    }

    const openSlider = () => {
        setIsPopupOpen(false);
        setIsClassStudentSliderOpen(true);
    }

    const openPopup = () => {
        setIsClassStudentSliderOpen(false);
        setIsPopupOpen(true);
    }

    const closeSlider = () => {
        setIsClassStudentSliderOpen(false);
        setIsPopupOpen(false);
        setStudentList();
        setLstSelect([]);
        setIsSelectAllSelected(false);
    }


    const updateLstSelect = (sId) => {
        // // console.log("updateLstSelect called, sId", sId);
        // // console.log("lstSelect",lstSelect);

        if (lstSelect && lstSelect.length === lstClassStudentsTable.length) {
            setIsSelectAllSelected(false);
        } else if (lstSelect.length === lstClassStudentsTable.length - 1) {
            if (!(lstSelect.some((selected) => selected === sId)))
                setIsSelectAllSelected(true);
        }

        setLstSelect((prevLstSelect) => {
            if (prevLstSelect.some((id) => id === sId)) {
                return prevLstSelect.filter((id) => id !== sId);
            } else {
                return [...prevLstSelect, sId];
            }
        });
    };

    const selectAll = () => {
        // // console.log("selectAll called");
        const tempArray = lstClassStudentsTable.map((student) => student.studentId);
        // // console.log("tempArray", tempArray);
        // lstClassStudentsTable.forEach((student) => {
        //     if (!lstSelect.includes(student.studentId)) {
        //         tempArray.push(student.studentId);
        //     }
        // });
        // // console.log("tempArray",tempArray);

        setLstSelect(tempArray);
    }


    return (
        <div style={{ height: '100%' }}>
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="mainContainer Class" style={{ opacity: opacity }}>
                <div className="col-md-10 col-centered classButtomSection">
                    <div className='col-sm-12 padding-remove' style={{ height: "35px" }}>
                        <div className="col-sm-5 padding-remove "  >
                            <div className="col-sm-4 padding-remove "  >
                                <div className="col-sm-11 padding-remove " style={{ border: "1px solid #ccc", borderRadius: "4px" }} >
                                    <label className=" col-sm-12 text-left " style={{ fontWeight: 400, padding: "0px 5px" }}  >Class: {clsName}-{section}</label>
                                </div>
                            </div>
                            <div className="col-sm-4 padding-remove "  >
                                <div className="col-sm-11 padding-remove " style={{ border: "1px solid #ccc", borderRadius: "4px" }} >
                                    <label className=" col-sm-12 text-left " style={{ fontWeight: 400, padding: "0px 5px" }}  >Total Students: {total}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-11  col-xs-11 classStudentTableWrap  padding-remove">
                        <table className="tableLayout1 classStudentTable">
                            <thead>
                                <tr>
                                    <th style={{ display: 'none' }}> Student Id </th>
                                    <th style={{ width: 20 }}>
                                        <input type="text" value={rollNoFiltered} onChange={(e) => handleChangeRollNoFiltered(e.target.value)} className="form-control inp-search" placeholder="Roll No." />
                                    </th>
                                    <th style={{ width: 50 }}>
                                        <input type="text" value={nameFiltered} onChange={(e) => handleChangeNameFiltered(e.target.value)} className="text-center form-control inp-search" placeholder="Student" />
                                    </th>
                                    <th style={{ width: 50 }} className="text-center"> Email</th>
                                    <th style={{ width: 20 }}>
                                        <input type="text" value={regNoFiltered} onChange={(e) => handleChangeRegNoFiltered(e.target.value)} className="form-control inp-search" placeholder="Regs. No." />
                                    </th>
                                    <th style={{ width: 25 }} className="text-center"> {"Group1" || groupLabel.group1Label} </th>
                                    <th style={{ width: 25 }} className="text-center">{"Group2" || groupLabel.group2Label} </th>
                                    <th style={{ width: 20 }}>
                                        <div style={{ float: 'left', width: '70%', marginTop: 6, }}>Select </div>
                                        <div style={{ width: '15%', float: 'left' }}>
                                            <input type="checkbox" checked={isSelectAllSelected} onChange={(e) => handleChangeSelectAll(e.target.checked)} />
                                        </div>
                                    </th>
                                    <th style={{ width: 30 }}> Action </th>
                                </tr>
                            </thead>
                        </table>
                        <div className="classStudentTableDiv" style={{ height: '32em', overflowY: 'scroll', border: '1px solid #ccc' }} >
                            <table id="classStudentTable" className="tableLayout">
                                <tbody id="classStudentTableBody">
                                    {lstClassStudentsTable && lstClassStudentsTable.length > 0 &&
                                        lstClassStudentsTable.map((student) => (
                                            <tr key={student.studentId} >
                                                <td className='text-center' style={{ display: 'none' }}>{student.studentId}</td>
                                                <td className='text-center' style={{ width: 20 }}>{student.rollNo}</td>
                                                <td className='text-left' style={{ width: 50 }}>{student.name}</td>
                                                <td className='text-left' style={{ width: 50 }}>{student.email}</td>
                                                <td className='text-center' style={{ width: 20 }}>{student.regsNo}</td>
                                                <td className='text-center' style={{ width: 25 }}>{student.group1}</td>
                                                <td className='text-center' style={{ width: 25 }}>{student.group2}</td>
                                                <td className='text-center' style={{ width: 20 }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={lstSelect && lstSelect.length > 0 &&
                                                            lstSelect.includes(student.studentId)}
                                                        onChange={(e) => updateLstSelect(student.studentId)}
                                                    />
                                                </td>
                                                <td className='text-center' style={{ width: 30 }}>
                                                    <span className="glyphicon glyphicon-edit"
                                                        title=""
                                                        // onClick={(e) => {openPopup() }}
                                                        >
                                                    </span>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="col-sm-10 padding-remove col-centered rollButtonWrap">
                            <button type="button" title="Generate Roll No." onClick={(e) => { generateRollNo() }} className="btn btn-warning">Generate Roll No.</button>
                            <button type="button" title="Remove From Class" onClick={(e) => { removeStudents() }} className="btn btn-warning">Remove From Class</button>
                            <div className="col-sm-2 padding-remove">
                                <select className="form-control" value={group1Id} onChange={(e) => setGroup1Id(e.target.value)} >
                                    <option value={0}>{groupLabel.group1Label}</option>
                                    {lstGroup1 &&
                                        lstGroup1.map((group) => (
                                            <option
                                                key={group.groupId}
                                                value={group.groupId}>
                                                {group.groupName}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-sm-2 padding-remove">
                                <select className="form-control" value={group2Id} onChange={(e) => setGroup2Id(e.target.value)} >
                                    <option value={0}>{groupLabel.group2Label}</option>
                                    {lstGroup2 &&
                                        lstGroup2.map((group) => (
                                            <option
                                                key={group.groupId}
                                                value={group.groupId}>
                                                {group.groupName}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <button type="button" title="Assign Group" onClick={(e) => { assignGroup() }} className="btn btn-warning">Assign</button>
                        </div>
                    </div>
                </div>
                <div className="col-xs-1 ">
                    <span id="addClassStudent" title="Add Class Student" onClick={openSlider} className="addIcon glyphicon glyphicon-plus-sign"></span>
                </div>
            </div>
            {isaddClassStudentSliderOpen &&
                <div className='slider1-70'>
                    <AddClassStudent
                        classId={classId}
                        className={clsName}
                        section={section}
                        sectionId={sectionId}
                        setLoader={setLoader}
                        setOpacity={setOpacity}
                        closeSlider={closeSlider}
                    />
                </div>
            }
            {isPopupOpen &&
                <ClassEditPopup
                    cancelStudent={closeSlider}
                />
            }
        </div>
    );
}
export default ClassEdit;


// Old code
// const verifyClsSec = () => {
//     // verifyClassSection(handleVerifyClsSecResponse);
// }
// const handleVerifyClsSecResponse = (data) => {
//     var obj = data;

//     if (obj.status === 'Success') {
//         if (parseInt(obj.sectionId) !== 0) {
//             hideLoader();
//             setStudentsSectionList(classId, sectionId, handleClsStuListResponse);
//             setGroupList();
//             setState({
//                 ...state,
//                 classSectionFlg: false,
//             })
//             $('.classButtomSection').show();
//         }
//         else {
//             setState({
//                 ...state,
//                 classSectionFlg: true,
//             })
//             $('.classButtomSection').hide();
//             $('#classAddForm').trigger('reset');
//         }

//     } else if (obj.status === 'Failure') {
//         if (obj.message === 'You are not logged in') {
//             setTimeout(function () { window.location.href = ('/') }, 2000);
//             return false;
//         } else if (obj.message === 'Your Session is expired, login again') {
//             setTimeout(function () { window.location.href = ('/') }, 2000);
//             return false
//         } else {
//             toast.error(obj.message);
//         }
//     } else {
//         toast.error("Service failed " + obj);
//     }

// }
// function fillNameCmb(vlstName) {
//     // // console.log("fillNameCmb() called... " + JSON.stringify(vlstName))
//     $('#NameCmb').empty();
//     $('#NameCmb').prepend($("<option> </option>").val(0).html('Monitor'));
//     // var vlstName = state.lstClassStudents;
//     for (var i = 0; i < vlstName.length; i++) {
//         var prefectId = vlstName[i].studentId;
//         var prefectName = vlstName[i].name;
//         $('#NameCmb').append($("<option> </option>").val(prefectId).html(prefectName));
//     }
//     $('#monitorCmb').empty();
//     $('#monitorCmb').prepend($("<option> </option>").val(0).html('Prefect'));
//     for (var i = 0; i < vlstName.length; i++) {
//         var monitorId = vlstName[i].studentId;
//         var monitorName = vlstName[i].name;
//         $('#monitorCmb').append($("<option> </option>").val(monitorId).html(monitorName));
//     }
// }
// function addTDCBX(row, id, classname) {
//     var cell = document.createElement('td');
//     cell.className = classname;
//     var div = document.createElement('div');

//     var cbx = document.createElement('input');
//     cbx.type = 'checkbox';
//     cbx.id = id;
//     div.append(cbx);
//     cell.append(div);
//     row.append(cell);
// }
// function addTDAction(row, statusId, val, classname) {
//     var cell = document.createElement('td');
//     cell.className = classname;
//     var span1 = document.createElement('span');
//     span1.className = 'glyphicon glyphicon-edit'
//     span1.style.cursor = 'pointer';
//     span1.onclick = () => {
//         $('td').removeClass('selected');
//         $(row).addClass('selected').siblings().removeClass('selected');
//         //var studentId = $('#classStudentTableBody tr.selected').find('td:eq(0)').text();
//         $('body').addClass('showPopup');
//         //setStudentForm(studentId);
//         setState({ ...state, flg1: false });
//     };
//     cell.append(span1);
//     row.append(cell);
// }


// const maintainClass = () => {
//     if (state.flg) {
//         addClass();
//     }/*  else {
//         editClass();
//     } */
// }



// const handleClsResponse = (data) => {
//     hideLoader();

//     if (data.status === 'Success') {
//         toast.success(data.message);
//         sectionId = data.sectionId;
//         $('.classButtomSection').show();
//         verifyClsSec();
//     } else if (data.status === 'Failure') {
//         if (data.message === 'You are not logged in') {
//             setTimeout(function () { window.location.href = ('/') }, 2000);
//             return false;
//         }
//         else if (data.message === 'Your Session is expired, login again') {
//             setTimeout(function () { window.location.href = ('/') }, 2000);
//             return false;
//         } else {
//             toast.error(data.message);

//         }
//     } else {
//         toast.error("Service failed " + data.error);
//     }
// }

// const addClass = (handleClsResponse) => {
//     var classArr = {};
//     var classsectionId = 0;
//     var classId = $('#classesCmb').find('option:selected').val();
//     var section = $('#section').val();
//     //  var buildingName = $('#buildingCmb').find('option:selected').text();
//     var buildingId = $('#buildingCmb').find('option:selected').val();
//     //var room = $('#roomCmb').find('option:selected').text();
//     var roomId = $('#roomCmb').find('option:selected').val();
//     var maxStudents = $('#maxStudents').val();
//     var roomCapacity = $('#roomCapacity').val();
//     var curStudent = $('#curStudent').val();
//     // var prefect = $('#prefectCmb').find('option:selected').text();
//     // var monitor = $('#monitorCmb').find('option:selected').text();
//     var className = $('#classesCmb').find('option:selected').text();

//     if (parseInt(classId) === 0) {
//         toast.warning('Please Select Valid Class');
//         $('#clsName').focus();
//         return false;
//     }
//     if (section === "") {
//         toast.warning('Please Enter  Section');
//         $('#section').focus();
//         return false;
//     }

//     if (parseInt(buildingId) === 0) {
//         toast.warning('Select Valid Building');
//         $('#buildingCmb').focus();
//         return false;
//     }
//     if (parseInt(roomId) === 0) {
//         toast.warning('Select Valid Room');
//         $('#roomCmb').focus();
//         return false;
//     }
//     if (roomCapacity === "") {
//         toast.warning('Please Enter  Room Capaciyt');
//         $('#roomCapacity').focus();
//         return false;
//     }
//     if (curStudent === "") {
//         toast.warning('Please Enter Number of Student');
//         $('#curStudent').focus();
//         return false;
//     }
//     if (maxStudents === "") {
//         toast.warning('Please Enter  Max Student');
//         $('#maxStudents').focus();
//         return false;
//     }

//     if (state.classSectionFlg) {
//         sectionId = 0;
//     }
//     else {
//         sectionId = localStorage.getItem('sectionId');
//     }

    // if (prefect === "") {
    //     toast.warning('Select Valid Prefect');
    //     $('#prefectCmb').focus();
    //     return false;
    // }
    // if (monitor === "") {
    //     toast.warning('Select Valid monitor');
    //     $('#monitorCmb').focus();
    //     return false;
    // }


//     classArr = {
//         'sectionId': sectionId
//         , 'classId': classId
//         , 'wingId': state.wingId
//         , 'className': className
//         , 'section': section
//         , 'maxStudents': maxStudents
//         , 'buildingId': buildingId
//         , 'roomId': roomId,
//     }
//     saveCls(classArr, handleClsResponse);

// }

// const addClassStudentReady = () => {
//     $('#classStudentTableBody tr.selected').removeClass('selected');
//     $('td').removeClass('selected');
//     $('body').addClass('openSlider');
//     /* var classId = localStorage.getItem("classId")
//     var sectionId = localStorage.getItem("classSectionId") */
//     // // console.log("sectionId" + sectionId)
//     // // console.log("classId" + classId)
//     // setStudentEligibleList(classId, sectionId);

// }


// const saveClassTeacher = (handleSaveClsTchResponse) => {
//     var classTeacherId = $('#classTeacherCmb').find('option:selected').val();
//     if (parseInt(classTeacherId) === 0) {
//         toast.warning('Select Valid Class Teacher');
//         $('#classTeacherCmb').focus();
//         return false;
//     }

//     setState({ ...state, loader: 'block', opacity: 0.5 });
//     saveClsTch(classTeacherId, sectionId, handleSaveClsTchResponse)
// }

// const handleSaveClsTchResponse = (data) => {

//     hideLoader();

//     if (data.status === 'Success') {
//         toast.success(data.message);

//     } else if (data.status === 'Failure') {
//         if (data.message === 'You are not logged in') {
//             setTimeout(function () { window.location.href = ('/') }, 2000);
//             return false;
//         }
//         else if (data.message === 'Your Session is expired, login again') {
//             setTimeout(function () { window.location.href = ('/') }, 2000);
//             return false;
//         } else {
//             toast.error(data.message);
//         }
//     } else {
//         toast.error("Service failed " + data.error);
//     }
// }

// const selectAll = () => {

//     if ($('#cbxSelectAll').prop('checked') === true) {
//         $('#classStudentTableBody tr').each(function () {
//             if ($(this).find($('input[type=checkbox]')).prop('disabled') === false) {
//                 $(this).find($('input[type=checkbox]')).prop('checked', true);
//             }
//         });

//     }
//     else {
//         $('#classStudentTableBody tr input[type=checkbox]').prop('checked', false);
//     }
// }


// const setRollNo = () => {
//     var studentRollNo = {};
//     // var classId = $('#classesCmb').find('option:selected').val();
//     var section = $('#section').val();
//     // classId = parseInt(classId);


//     var students = [];
//     $('#classStudentTableBody tr').each(function () {
//         if ($(this).find($('input[type=checkbox]')).prop('checked') == true) {
//             var studentId = $(this).find('td:eq(0)').text();
//             students.push(parseInt(studentId));
//         }
//     });
//     setState({ ...state, students: students });
//     if (parseInt(students.length) === 0) {
//         toast.warning('Please Select Atleast one Student');
//         return false;
//     }
//     studentRollNo = { students, classId, section, sectionId }
//     // // console.log(studentRollNo);
//     setState({ ...state, loader: 'block', opacity: 0.5 });
//     assignRollNo(studentRollNo, handleAssignRollNoResponse);
// }

// const handleAssignRollNoResponse = (data) => {
//     hideLoader();

//     if (data.status === 'Success') {
//         toast.success(data.message);
//         getStudentsSectionList(classId, sectionId, handleClsStuListResponse);
//         $('#cbxSelectAll').prop('checked', false);
//     } else if (data.status === 'Failure') {
//         if (data.message === 'You are not logged in') {
//             setTimeout(function () { window.location.href = ('/') }, 2000);
//             return false;
//         }
//         else if (data.message === 'Your Session is expired, login again') {
//             setTimeout(function () { window.location.href = ('/') }, 2000);
//             return false;
//         } else {
//             toast.error(data.message)
//         }
//     } else {
//         toast.error("Service failed " + data.error);
//     }

// }
