import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

var screenId = 8;
var tokenid = localStorage.getItem("tokenid");
var httpURL = lstModules['main'].url + ":" + lstModules['main'].port;
var httpURLStudent = lstModules['students'].url + ":" + lstModules['students'].port;
var httpURLStaff = lstModules['staff'].url + ":" + lstModules['staff'].port;

function getClassListSvc(callback1) {
    axios({
        url: httpURL + '/sms/cls/list',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // // console.log('data.SvcStatus=' + response.data.SvcStatus);
        callback1(response.data);
    }).catch((error) => {
        callback1(error);
    });
}
function getSectionListSvc(callback2, pClassId) {
    axios({
        url: httpURLStaff + "/sms/cls/sec/list",
        method: "POST",
        params: { screenId: 8, classId: pClassId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // // console.log('data.SvcStatus=' + response.data.SvcStatus);
        callback2(response.data);
    }).catch((error) => {
        callback2(error);
    });
}
function getAttendanceSvc(monthId, sectionId, cb) {
    axios({
        url: httpURLStudent + "/sms/student/attendance/monthly",
        method: "POST",
        params: { monthId: monthId, sectionId: sectionId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // // console.log('data.SvcStatus=' + response.data.SvcStatus);
        cb(response.data);
    }).catch((error) => {
        cb(error);
    });
}
function getLstMonth(callback) {
    var status = 'Success'
    var obj = { status: status };


    // // console.log("getLstMonth");
    axios({
        url: httpURL + '/sms/mst/month/list',
        method: "POST",
        params: { screenId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === 'Success') {
            // // console.log('data.SvcStatus=' + response.data.SvcStatus);
            // // console.log(response.data);
            obj.lstMonth = response.data.lstMonth;
            obj.message = response.data.SvcMsg;
            obj.status = 'Success';
            // // console.log(obj);
            callback(obj)
        }
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });
}
function getMonthSummary(monthId, sectionId, callback) {
    var status = 'Success'
    var obj = { status: status };


    // // console.log("getLstMonth");
    axios({
        url: httpURLStudent + "/sms/student/attendance/monthly/summary",
        method: "POST",
        params: { monthId: monthId, sectionId: sectionId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,

    }).then((response) => {
        obj.message = response.data.SvcMsg;
        obj.status = response.data.SvcStatus;
        if (response.data.SvcStatus === 'Success') {
            obj.lstSummary = response.data.lstSummary;
            obj.presentPct = response.data.presentPct;
        } else {
            obj.lstSummary = []
            obj.presentPct = 0
        }
        // // console.log(obj);
        callback(obj)
    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = error;
        callback(obj)
    });
}
export { getClassListSvc, getSectionListSvc, getAttendanceSvc, getLstMonth, getMonthSummary };