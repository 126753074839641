import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "../css/TestConfigCtrl.css";
import Header from "common/js/header/header.js";
import InterviewSlotConfigTable from "./InterviewSlotConfigTable";
import InterviewSlotConfigSlider from "./InterviewSlotConfigSlider";
import { getInterviewSlotListSvc } from "./InterviewSlotConfigSvc";
function InterviewSlotConfigCtrl() {
  const [loader, setLoader] = useState("none");
  const [flgSlider, setFlgSlider] = useState(false);
  const [interviewSlotId, setInterviewSlotId] = useState(0);
  const [lstInterviewSlot, setLstInterviewSlot] = useState([]);
  const [interviewObj, setInterviewObj] = useState({});
  const [randNo, setRandNo] = useState(0);
  useEffect(() => {
    setLoader("block");
    getInterviewSlotListSvc(handleInterviewResponse);
  }, []);
  function getLstInterviewSlot() {
    getInterviewSlotListSvc(handleInterviewResponse);
  }
  function handleInterviewResponse(data) {
    // // console.log(data);
    if (data.SvcStatus === "Success") {
      setLstInterviewSlot(data.lstInterviewSlot);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function setInterviewSlotforEdit(interviewSlotId) {
    // // console.log("Param " + interviewSlotId);
    for (var i = 0; i < lstInterviewSlot.length; i++) {
      // // console.log("Inside loop " + lstInterviewSlot[i].interviewSlotId);
      if (lstInterviewSlot[i].interviewSlotId === interviewSlotId) {
        // // console.log("inside if ");
        setInterviewSlotId(lstInterviewSlot[i].interviewSlotId);
        setInterviewObj(lstInterviewSlot[i]);
        // // console.log("Interviewforedit:" + JSON.stringify(lstInterviewSlot[i]));
        break;
      }
    }
  }
  function openSlider() {
    setFlgSlider(true);
  }
  function closeSlider(pSlotId) {
    setFlgSlider(false);
    // setInterviewObj({ interviewSlotId: pSlotId });
    // setInterviewSlotId(pSlotId);
    generateRandomNo();
  }

  function generateRandomNo() {
    var lRandNo = Math.floor(Math.random() * 999999);
    // // console.log(lRandNo);
    setRandNo(lRandNo);
  }
  const getWingId = (wingId) => {
    // // console.log("wingId = " + wingId);
  };
  return (
    <div
      className="MainContainer"
      style={{ pointerEvents: loader === "none" ? "auto" : "none" }}
    >
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>
      <Header wingFromChild={getWingId} />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className="TestTblWrapper">
        <div className="TestTbl">
          <InterviewSlotConfigTable
            key={randNo}
            generateRandomNo={generateRandomNo}
            interviewSlotId={interviewSlotId}
            setInterviewSlotId={setInterviewSlotId}
            openSlider={openSlider}
            lstInterviewSlot={lstInterviewSlot}
            setInterviewSlotforEdit={setInterviewSlotforEdit}
            getLstInterviewSlot={getLstInterviewSlot}
          />
        </div>
        <div
          className=""
          onClick={(e) => {
            setInterviewSlotId(0);
            setInterviewObj({ interviewSlotId: 0 });
            openSlider();
          }}
        >
          <span
            id="addReadyId"
            title="Add"
            className="addIcon glyphicon glyphicon-plus-sign"
          ></span>
        </div>
      </div>
      <div
        style={{ display: flgSlider ? "block" : "none" }}
        className="TestSlider"
      >
        <InterviewSlotConfigSlider
          key={interviewSlotId}
          setInterviewSlotId={setInterviewSlotId}
          closeSlider={closeSlider}
          openSlider={openSlider}
          setInterviewObj={setInterviewObj}
          interviewObj={interviewObj}
          interviewSlotId={interviewSlotId}
          slotLabel={interviewObj.slotLabel}
          slotDt={interviewObj.slotDt}
          startTm={interviewObj.startTm}
          endTm={interviewObj.endTm}
          flgSlider={flgSlider}
          timePerCandidate={interviewObj.timePerCandidate}
        />
      </div>
    </div>
  );
}
export default InterviewSlotConfigCtrl;
