import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import lstModules from 'common/json/lstModules.json';
var tokenid = localStorage.getItem('tokenid');
var httpURL = lstModules['enquiry'].url + ":" + lstModules['enquiry'].port;
var httpURLCommon = lstModules['main'].url + ":" + lstModules['main'].port;

function getEnqListSvc(callBack) {
  axios(
    {
      url: httpURL+"/sms/enquiry/list",
      method: "POST",
      params: {},
      headers: { 'tokenid': tokenid },
      withCredentials: true
    }
  ).then((Response) => {
    var ourData = Response.data
    if (ourData.SvcStatus == "Success") {
      callBack(ourData.lstEnquiry)
    } else {
      toast.error(ourData.SvcMsg)
    }
  }
  ).catch((error) => {
    console.error(error)
  });
}

function enqDtlsSvc(enqId, callBack) {
  axios(
    {
      url: httpURL+"/sms/enquiry/dtls",
      method: "POST",
      params: { enqId: enqId },
      headers: { 'tokenid': tokenid },
      withCredentials: true
    }
  ).then((Response) => {
    var ourData = Response.data
    if (ourData.SvcStatus == "Success") {
      callBack(ourData.enq)
    } else {
      toast.error(ourData.SvcMsg)
    }
  }
  ).catch((error) => {
    console.error(error)
  });
}
function getClassListSvc(callBack) {
  axios(
    {
      url: httpURLCommon+"/sms/mst/cls/list",
      method: "POST",
      params: {},
      headers: { 'tokenid': tokenid },
      withCredentials: true
    }
  ).then((Response) => {
    var ourData = Response.data
    if (ourData.SvcStatus == "Success") {
      callBack(ourData.lstClass)
    } else {
      toast.error(ourData.SvcMsg)
    }
  }
  ).catch((error) => {
    console.error(error)
  });
}
function getStreamListSvc(callBack) {
  axios(
    {
      url: httpURLCommon+"/sms/mst/stream/list",
      method: "POST",
      params: {},
      headers: { 'tokenid': tokenid },
      withCredentials: true
    }
  ).then((Response) => {
    var ourData = Response.data
    if (ourData.SvcStatus == "Success") {
      callBack(ourData.lstStream)
    } else {
      toast.error(ourData.SvcMsg)
    }
  }
  ).catch((error) => {
    console.error(error)
  });
}
function getStateListSvc(callBack) {
  axios(
    {
      url: httpURLCommon+"/sms/mst/state/list",
      method: "POST",
      params: {},
      headers: { 'tokenid': tokenid },
      withCredentials: true
    }
  ).then((Response) => {
    var ourData = Response.data
    if (ourData.SvcStatus == "Success") {
      callBack(ourData.lstState)
    } else {
      toast.error(ourData.SvcMsg)
    }
  }
  ).catch((error) => {
    console.error(error)
  });
}
function getCityListSvc(callBack, pstateId) {

  axios(
    {
      url: httpURLCommon+"/sms/mst/city/list",
      method: "POST",
      params: { stateId: pstateId },
      headers: { 'tokenid': tokenid },
      withCredentials: true
    }
  ).then((Response) => {
    var ourData = Response.data
    if (ourData.SvcStatus == "Success") {
      callBack(ourData.lstCity)
    } else {
      toast.error(ourData.SvcMsg)
    }
  }
  ).catch((error) => {
    console.error(error)
  });
}
function getLocalityListSvc(callBack, pcityId) {

  axios(
    {
      url: httpURLCommon+"/sms/mst/locality/list",
      method: "POST",
      params: { cityId: pcityId },
      headers: { 'tokenid': tokenid },
      withCredentials: true
    }
  ).then((Response) => {
    var ourData = Response.data
    if (ourData.SvcStatus == "Success") {
      callBack(ourData.lstLocality)
    } else {
      toast.error(ourData.SvcMsg)
    }
  }
  ).catch((error) => {
    console.error(error)
  });
}
function getSourceListSvc(callBack) {
  axios(
    {
      url: httpURLCommon+"/sms/mst/source/list",
      method: "POST",
      params: {},
      headers: { 'tokenid': tokenid },
      withCredentials: true
    }
  ).then((Response) => {

    var ourData = Response.data
    if (ourData.SvcStatus == "Success") {
      callBack(ourData.lstSource)
    } else {
      toast.error(ourData.SvcMsg)
    }
  }
  ).catch((error) => {
    console.error(error)
  });
}
function getCampaignListSvc(callBack) {
  axios(
    {
      url: httpURLCommon+"/sms/mst/campaign/list",
      method: "POST",
      params: {},
      headers: { 'tokenid': tokenid },
      withCredentials: true
    }
  ).then((Response) => {

    var ourData = Response.data
    if (ourData.SvcStatus == "Success") {
      callBack(ourData.lstCampaign)
    } else {
      toast.error(ourData.SvcMsg)
    }
  }
  ).catch((error) => {
    console.error(error)
  });
}
function getStaffListSvc(callBack) {
  axios(
    {
      url: httpURLCommon+"/sms/mst/staff/list",
      method: "POST",
      params: {},
      headers: { 'tokenid': tokenid },
      withCredentials: true
    }
  ).then((Response) => {

    var ourData = Response.data
    if (ourData.SvcStatus == "Success") {
      callBack(ourData.lstStaff)
    } else {
      toast.error(ourData.SvcMsg)
    }
  }
  ).catch((error) => {
    console.error(error)
  });
}

export { enqDtlsSvc };
export { getClassListSvc };
export { getStreamListSvc };
export { getCityListSvc };
export { getLocalityListSvc };
export { getSourceListSvc };
export { getCampaignListSvc };
export { getStaffListSvc };
export { getStateListSvc };
export { getEnqListSvc };