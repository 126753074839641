import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

var screenId = 8;
var tokenid = localStorage.getItem("tokenid");
var httpURL = lstModules['main'].url + ":" + lstModules['main'].port;


function getViolationSvc(cb) {
    axios({
        url: httpURL + "/sms/cctv/violations/list_student",
        method: "POST",
        params: {},
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // // console.log('data.SvcStatus=' + response.data.SvcStatus);
        cb(response.data);
    }).catch((error) => {
        cb(error);
    });
}

export { getViolationSvc };