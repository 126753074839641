import React from 'react';
import { FaStar } from 'react-icons/fa';
import { Radio, Rating } from "./RatingStyles.js";

const StarRating = ({ rate, setRate, formDisabled }) => {
    return (
        <div style={{ padding: 0, margin: 0, display: 'flex', justifyContent: 'center' }}>
            {[...Array(5)].map((star, index) => {
                const givenRating = index + 1;
                return (
                    <label key={givenRating} style={{ margin: '0 5px' }}>
                        <Radio
                            type="radio"
                            value={rate}
                            disabled={formDisabled}
                            onChange={() => {
                                setRate(givenRating);
                            }}
                        />
                        <Rating>
                            <FaStar
                                color={
                                    givenRating < rate || givenRating === rate
                                        ? "#FF9529"
                                        : "rgb(192,192,192)"
                                }
                                style={{ fontSize: "40px", cursor: "pointer" }}
                            />
                        </Rating>
                    </label>
                );
            })}
        </div>
    );
};

export default StarRating;
