import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "common/css/master.css";
import "../css/transport.css";
import * as Constants from "./constants";
import {
  getWingsListSvc,
  getRouteArrDepDtls,
  getBaseLocationListSvc,
  getArrDepStateListSvc,
  getArrDepLocalityListSvc,
  getArrDepCityListSvc,
  saveArrDepSvc,
} from "./routeSvc";
const RouteArrDepSlider = (props) => {
  const [loader, setLoader] = useState("none");
  const [lstWings, setLstWings] = useState([]);
  const [wingId, setWingId] = useState(0);
  const [arrivalMins, setArrivalMins] = useState("");
  const [deptMins, setDeptMins] = useState("");
  const [lstBaseLocation, setLstBaseLocation] = useState([]);
  const [baseLocationId, setBaseLocationId] = useState(0);
  const [baseLocationAddress, setBaseLocationAddress] = useState("");
  const [stateId, setStateId] = useState(0);
  const [cityId, setCityId] = useState(0);
  const [localityId, setLocalityId] = useState(0);
  const [lstCity, setLstCity] = useState([]);
  const [lstState, setLstState] = useState([]);
  const [lstLocality, setLstLocality] = useState([]);
  const [locationDisabled, setLocationDisabled] = useState(false);

  useEffect(() => {
    if (props.routeId && props.flgArrDep) {
      setLoader("block");
      getWingsListSvc(cbWingsListResponse);
      getRouteArrDepDtls(props.routeId, "0", cbRouteArrDepDtls);
      getBaseLocationListSvc(cbBaseLocation);
      getArrDepStateListSvc(cbStateResponseChange);
    }
  }, [props]);

  function cbWingsListResponse(data) {
    if (data.SvcStatus === "Success") {
      setLstWings(data.lstWings);
    } else {
      setLstWings([]);
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function cbRouteArrDepDtls(data) {
    if (data.SvcStatus === "Success") {
      setWingId(data.arrDeptDtls.wingId);
      setArrivalMins(data.arrDeptDtls.arrivalMins);
      setDeptMins(data.arrDeptDtls.deptMins);
      setBaseLocationId(data.arrDeptDtls.baseLocationId);
      setLocationDisabledIfOther(data.arrDeptDtls.baseLocationId);
      setStateId(data.arrDeptDtls.stateId);
      getArrDepCityListSvc(
        cbCityResponseChange,
        data.arrDeptDtls.stateId,
        data.arrDeptDtls.cityId
      );
      getArrDepLocalityListSvc(
        cbLocalityResponsechange,
        data.arrDeptDtls.cityId,
        data.arrDeptDtls.localityId
      );
      setBaseLocationAddress(data.arrDeptDtls.baseLocationAddress);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function cbStateResponseChange(data) {
    if (data.SvcStatus === "Success") {
      setLstState(data.lstState);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function cbCityResponseChange(data, pCityId) {
    if (data.SvcStatus === "Success") {
      setLstCity(data.lstCity);
      setCityId(pCityId);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function cbLocalityResponsechange(data, pLocalityId) {
    if (data.SvcStatus === "Success") {
      setLstLocality(data.lstLocality);
      setLocalityId(pLocalityId);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function handleStateIdChange(param) {
    setStateId(param);
    getArrDepCityListSvc(cbCityResponseChange, param);
  }
  function handleCityIdChange(param) {
    setCityId(param);
    getArrDepLocalityListSvc(cbLocalityResponsechange, param);
  }
  function handleLocalityIdChange(param) {
    setLocalityId(param);
  }
  function cbBaseLocation(data) {
    if (data.SvcStatus === "Success") {
      setLstBaseLocation(data.lstBaseLocation);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function handleWingChange(param) {
    setWingId(param);
  }
  function handleArrivalMinsChange(param) {
    setArrivalMins(param);
  }
  function handleDeptMinsChange(param) {
    setDeptMins(param);
  }

  function handleBaseLocationChange(param) {
    setBaseLocationId(param);
    setLocationDisabledIfOther(param);
  }
  function setLocationDisabledIfOther(param) {
    // // console.log( "param, " +  param +  "== otherbaseLocationId, " +  Constants.ID_BASE_LOCATION_OTHER  );
    if (param != Constants.ID_BASE_LOCATION_OTHER) {
      setLocationDisabled(true);
    } else {
      setLocationDisabled(false);
    }
  }
  function handleBaseAddressChange(param) {
    setBaseLocationAddress(param);
  }
  function saveArrDep() {
    const pArrDepObj = {
      wingId: wingId,
      arrivalMins: arrivalMins,
      deptMins: deptMins,
      baseLocationId: baseLocationId,
      stateId: stateId,
      cityId: cityId,
      localityId: localityId,
      baseLocationAddress: baseLocationAddress,
    };
    setLoader("block");
    saveArrDepSvc(props.routeId, pArrDepObj, cbSaveArrDep);
  }
  function cbSaveArrDep(data) {
    setLoader("none");
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
      getRouteArrDepDtls(props.routeId, "0", cbRouteArrDepDtls);
    } else {
      toast.error(data.SvcMsg);
    }
  }
  return (
    <form
      className="form form-horizontal"
      style={{
        pointerEvents: loader === "none" ? "auto" : "none",
        height: "auto",
        width: "90%",
        margin: "10px auto",
        background: "#fff",
        border: "1px solid #ccc",
        borderRadius: 10,
      }}
    >
      <button
        type="button"
        className="btn btn-info text-center btnCloseDiv"
        onClick={props.closeSlider}
      >
        <span
          id="close"
          title="Close Slider"
          className="glyphicon glyphicon-remove"
        ></span>
      </button>
      <div className="row" style={{ width: "100%" }}>
        <div className="form-group col-md-9">
          <label className="control-label col-sm-4">Wing</label>
          <div className="col-sm-4 padding-remove">
            <select
              className="form-control"
              onChange={(e) => handleWingChange(e.target.value)}
              value={wingId}
            >
              <option value={0}>Wing</option>
              {lstWings.map((wing, idx) => {
                return (
                  <option key={idx} value={wing.wingId}>
                    {wing.wing}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="row" style={{ width: "100%" }}>
        <div className="form-group col-md-9">
          <label className="control-label col-sm-4">Arrival At School</label>
          <div className="col-sm-4 padding-remove">
            <input
              type="text"
              defaultValue={arrivalMins}
              className="form-control"
              onChange={(e) => handleArrivalMinsChange(e.target.value)}
            ></input>
          </div>
          <label
            className="control-label col-sm-3"
            style={{ textAlign: "left" }}
          >
            mins before start
          </label>
        </div>
      </div>
      <div className="row" style={{ width: "100%" }}>
        <div className="form-group col-md-9">
          <label className="control-label col-sm-4">
            Departure From School
          </label>
          <div className="col-sm-4 padding-remove">
            <input
              type="text"
              defaultValue={deptMins}
              className="form-control"
              onChange={(e) => handleDeptMinsChange(e.target.value)}
            ></input>
          </div>
          <label
            className="control-label col-sm-3"
            style={{ textAlign: "left" }}
          >
            mins after final bell
          </label>
        </div>
      </div>
      <div className="row" style={{ width: "100%" }}>
        <div className="col-sm-9">
          <div className="form-group col-md-12">
            <label className="control-label col-sm-4">Base Location</label>
            <div className="col-sm-2">
              {lstBaseLocation.map((obj, idx) => {
                return (
                  <div className="col-sm-12 padding-remove" key={idx}>
                    <label className="radio pull-left" >
                      <input
                        key={idx+'_radio'}
                        type="radio"
                        name="baseLocation"
                        value={obj.baseLocationId}
                        checked={baseLocationId == obj.baseLocationId}
                        onChange={(e) =>
                          handleBaseLocationChange(e.target.value)
                        }
                      ></input>
                      {obj.baseLocation}
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="col-sm-6">
              <div className="form-group col-md-12">
                <label className="control-label col-sm-5">State</label>
                <div className="col-sm-6 padding-remove">
                  <select
                    className="form-control"
                    onChange={(e) => handleStateIdChange(e.target.value)}
                    value={stateId}
                    disabled={locationDisabled}
                  >
                    <option value={0}>State</option>
                    {lstState.map((stat, idx) => {
                      return (
                        <option key={idx} value={stat.stateId}>
                          {stat.state}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="form-group col-md-12">
                <label className="control-label col-sm-5">City</label>
                <div className="col-sm-6 padding-remove">
                  <select
                    className="form-control"
                    onChange={(e) => handleCityIdChange(e.target.value)}
                    value={cityId}
                    disabled={locationDisabled}
                  >
                    <option value={0}>City</option>
                    {lstCity.map((city, idx) => {
                      return (
                        <option key={idx} value={city.cityId}>
                          {city.city}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="form-group col-md-12">
                <label className="control-label col-sm-5">Locality</label>
                <div className="col-sm-6 padding-remove">
                  <select
                    className="form-control"
                    onChange={(e) => handleLocalityIdChange(e.target.value)}
                    value={localityId}
                    disabled={locationDisabled}
                  >
                    <option value={0}>Locality</option>
                    {lstLocality.map((local, idx) => {
                      return (
                        <option key={idx} value={local.localityId}>
                          {local.locality}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="form-group col-md-12">
                <label className="control-label col-sm-5">Address</label>
                <div className="col-sm-6 padding-remove">
                  <input
                    type="text"
                    defaultValue={baseLocationAddress}
                    className="form-control"
                    onChange={(e) => handleBaseAddressChange(e.target.value)}
                    disabled={locationDisabled}
                  ></input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{ width: "100%", paddingBottom: "1px" }}>
        <div className="col-md-offset-10 col-md-1 padding-remove">
          <button
            type="button"
            className="btn btn-info pull-right"
            onClick={(e) => saveArrDep()}
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};
export default RouteArrDepSlider;
