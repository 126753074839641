import axios from 'axios'
import lstModules from 'common/json/lstModules.json'

const httpURL = lstModules['cms'].url + ':' + lstModules['cms'].port
var tokenid = localStorage.getItem('tokenid')
var screenId = 8

function getVisionTextSvc (callBack) {
  axios({
    url: httpURL + '/sms/cms/vision/dtls',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      callBack(response.data)
    })
    .catch(e => {
      console.error('Service failed ' + e)
    })
}
function saveVisionTextSvc (wsId, txt, callBack) {
  axios({
    url: httpURL + '/sms/cms/vision/save',
    method: 'POST',
    params: { visionObj: JSON.stringify({ visionText: txt, wsId:wsId }) },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      callBack(response.data)
    })
    .catch(e => {
      console.error('Service failed ' + e)
    })
}
function saveVisionFileSvc (wsId, file, callBack) {
  var formData = new FormData()
  formData.append('file', file)
  formData.append("wsId", wsId)

  axios({
    url: httpURL + '/sms/cms/vision/img/upload',
    method: 'POST',
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      callBack(response.data)
    })
    .catch(e => {
      console.error('Service failed ' + e)
    })
}

export { getVisionTextSvc, saveVisionTextSvc, saveVisionFileSvc }
