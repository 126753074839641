import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "common/css/master.css";
import Header from "common/js/header/header.js";
import { BookSearchBar } from "./BookSearchBar";
import { BookIssueDtls } from "./BookIssueDtls";
import { Slider } from "./Slider";

const IssueBookCtrl = () => {
  const [bookObj, setBookObj] = useState({
    author: "",
    bookNo: "",
    borrower: "",
    borrowerType: "",
    copyNo: "",
    cost: 0,
    dueDt: "",
    issueRestriction: "-",
    name: "",
    publisher: "",
    securityDeposit: 0,
    staffIssuePeriod: 0,
    statusId: 0,
    studentIssuePeriod: 0,
    title: "",
  });
  const [loader, setLoader] = useState("none");
  const [sliderDisplayed, setSliderDisplayed] = useState(0);
  const [bookNo, setBookNo] = useState("");
  const [issueDtls, setIssueDtls] = useState({});
  return (
    <div
      className="MainContainer"
      style={{ pointerEvents: loader === "none" ? "auto" : "none" }}
    >
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>
      <Header />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className="col-sm-9 col-centered" style={{ marginTop: "2%" }}>
        <div className="col-sm-11" style={{ height: "100%" }}>
          <BookSearchBar
            setBookObj={setBookObj}
            setBookNo={setBookNo}
            setLoader={setLoader}
            setIssueDtls={setIssueDtls}
          />
        </div>
        <div
          className="col-sm-11"
          style={{
            border: "1px solid #aaa",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            height: "75vh",
            marginTop: 20,
            marginLeft: "4%",
          }}
        >
          <BookIssueDtls key={bookNo} bookObj={bookObj} setLoader={setLoader} />
          {bookObj.statusId === 10 && (
            <div className="col-sm-1 pull-right" style={{ marginTop: 5 }}>
              <button
                type="button"
                className="btn btn-info "
                onClick={() => setSliderDisplayed(1)}
              >
                Issue
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="col-sm-7 col-centered padding-remove">
        {sliderDisplayed === 1 && (
          <Slider
            sliderDisplayed={sliderDisplayed === 1}
            setSliderDisplayed={setSliderDisplayed}
            key={bookObj.bookNo + "-" + bookObj.copyNo}
            issueDtls={issueDtls}
          />
        )}
      </div>
    </div>
  );
};
export default IssueBookCtrl;
