import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import $ from 'jquery';
import 'jquery-ui';
import 'common/css/master.css';
import axios from 'axios';
import Header from 'common/js/header/header.js';
import { toast, ToastContainer } from 'react-toastify';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import QrCode from 'react.qrcode.generator';


var httpURL = '';
//var queryString = require('querystring');
var lstStudentTemplates = [];
var tokenid = localStorage.getItem('tokenid');


class studentTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: 'none',
            opacity: 1,
            flg: true,
            tokenid: tokenid,
        }
    }

    componentDidMount = () => {
        this.getStudentTemplateList();

    }

    getStudentTemplateList = () => {

        this.setState({ loader: 'block', opacity: 0.5 });
        axios({
            url: httpURL + '/smsadmin/stu/template/list',
            method: "POST",
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstStudentTemplates = response.data.lstStudentTemplates;
                this.setStudentTemplateTable();
            }
            else {
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error("Service failed " + error);
        });
    }

    setStudentTemplateTable = () => {
        $('#studentTemplateTableBody tr').remove();
        var table = document.getElementById('studentTemplateTable');
        var tbody = document.getElementById('studentTemplateTableBody');

        for (var i = 0; i < lstStudentTemplates.length; i++) {
            var studentTemplateId = lstStudentTemplates[i].studentTemplateId;
            var version = lstStudentTemplates[i].version;
            var uploadedOn = lstStudentTemplates[i].uploadedOn;
            var action = '-';




            var row = document.createElement('tr');
            row.id = studentTemplateId;

            row.onclick = (e) => {
                $(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');
                this.setStudentTemplateForm($(e.target).closest('tr').attr('id'));
                this.setState({
                    flg: false,
                });
            }

            this.addTD(row, studentTemplateId, 'no-display');
            this.addTD(row, version, 'text-center');
            this.addTD(row, uploadedOn, 'text-center');
            this.addTD(row, action, 'text-center');

            tbody.append(row);
        }
        table.append(tbody);
    }

    setStudentTemplateForm = (studentTemplateId) => {
        var studentBulkUploadData = this.getStudentBulkUploadData(studentTemplateId);

        $('#version').val(studentBulkUploadData.version);



    }

    getStudentBulkUploadData(studentTemplateId) {
        for (var i = 0; i < lstStudentTemplates.length; i++) {
            if (parseInt(studentTemplateId) === lstStudentTemplates[i].studentTemplateId)
                return lstStudentTemplates[i];
        }
    }

    addTD(row, val, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var text = document.createTextNode(val);
        cell.append(text);
        row.append(cell);
    }

    addStudentBulkUploadReady = () => {
        $('#addStudentBulkUploadForm').trigger('reset');
        $('#studentTemplateTableBody tr').removeClass('selected');
    }



    // addStudentBulkUpload = () => {
    //     var periodTypeArr = {};
    //     var studentTemplateId = 0;
    //     var version = $('#version').val();


    //     if (version === "") {
    //         toast.warning("Please Enter Version");
    //         $('#version').focus()
    //         return false
    //     }

    //     if (this.state.flg) {
    //         studentTemplateId = 0;
    //     } else {
    //         studentTemplateId = $('#studentTemplateTableBody tr.selected').find('td:eq(0)').text();
    //     }

    //     this.setState({ loader: 'block', opacity: 0.5 });
    //     periodTypeArr = { 'studentTemplateId': studentTemplateId, "version": version, };

    //     axios({
    //         url: httpURL + '/sms/a/type/save',
    //         method: "POST",
    //         params: { 'version': JSON.stringify(periodTypeArr) },
    //         headers: { 'tokenid': this.state.tokenid },
    //         withCredentials: true,
    //     }).then((response) => {
    //         if (response.data.SvcStatus === 'Success') {
    //             toast.success(response.data.SvcMsg);
    //             this.getStudentTemplateList();
    //             this.addStudentBulkUploadReady();

    //         } else {
    //             toast.error(response.data.SvcMsg);
    //         }
    //         this.setState({ loader: 'none', opacity: 1 });
    //     }).catch((e) => {
    //         toast.error('Service failed: ' + e);
    //     });
    // }

    uploadExcel = () => {
        var stuTemplate = {};
        var version = $('#version').val();
        if (version === "") {
            toast.warning("Please Enter Version");
            $('#version').focus()
            return false
        }

        if (this.state.flg) {
            var studentTemplateId = 0;
        } else {
            var studentTemplateId = $('#studentTemplateTableBody tr.selected').find('td:eq(0)').text();
        }
        stuTemplate = { studentTemplateId, version }

        $("#excelUpload").click();
        $("#excelUpload").on('change', function (event) {
            if (!event.target.files) return;
            var files = event.target.files; //FileList object
            var files = files[0];
            var formData = new FormData();
            formData.append("stuTemplateFile", files);
            formData.append('stuTemplate', JSON.stringify(stuTemplate));

            axios({
                url: httpURL + '/smsadmin/stu/template/save',
                method: "POST",
                data: formData,
                withCredentials: true,
            }).then((response) => {
                if (response.data.SvcStatus === "Success") {
                    toast.success(response.data.SvcMsg);

                }
                else {
                    toast.error(response.data.SvcMsg);

                }
            }).catch((e) => {
                toast.error("Service failed " + e);
            });
        });

    }

    getWingId = (wingId) => {
        // // console.log("wingId" + wingId);
    }


    render() {
        httpURL = lstModules['students'].url + ':' + lstModules['students'].port;
        return (
            <div style={{ height: '100%' }}>
                <Header wingFromChild={this.getWingId} />
                <ToastContainer autoClose={3000} hideProgressBar />
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: this.state.loader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>
                <div className="mainContainer Timing" style={{ opacity: this.state.opacity }}>
                    <div className="col-md-8 col-centered">
                        <div className="col-md-6  col-xs-6 periodTypeWrap  padding-remove">
                            <table className="tableLayout1 version" style={{ width: this.state.width }}>
                                <thead>
                                    <tr>
                                        <th style={{ display: 'none' }}> Bulk Upload Id </th>
                                        <th style={{ width: 40 }}> Version</th>
                                        <th style={{ width: 40 }}> uploaded On </th>
                                        <th style={{ width: 30 }}> Action </th>

                                    </tr>
                                </thead>
                            </table>
                            <div className="tableDiv" style={{ height: '400px', overflowY: 'scroll', border: '1px solid #ccc' }} >
                                <table id="studentTemplateTable" className="tableLayout">
                                    <colgroup>
                                        <col width="40" />
                                        <col width="40" />
                                        <col width="30" />
                                    </colgroup>
                                    <tbody id="studentTemplateTableBody"></tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-xs-1 ">
                            <span id="addStudentBulkUpload" title="Add Period Type" onClick={this.addStudentBulkUploadReady} className="addIcon glyphicon glyphicon-plus-sign"></span>
                        </div>
                        <div className="col-md-5 col-xs-8 padding-remove timingFormWrap" >
                            <form id="addStudentBulkUploadForm" className="form-horizontal formWrapper">
                                <div className="form-group valueWrap">
                                    <label className="control-label col-sm-5" >Version<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                                    <div className="col-sm-6 padding-remove">
                                        <input autoComplete="off" id="version" type="text" className="form-control " placeholder="1.0" />
                                    </div>
                                </div>
                                <div className=" importWrap text-center">
                                    <input type="file" id="excelUpload" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                    <button id='uploadeExcel' title="Upload Excel" type="button" onClick={this.uploadExcel} className="btn btn-warning">Upload</button>

                                </div>
                                {/* <div className="form-group" >
                                    <div className="col-sm-12 text-center userButtonWrap" >
                                        <button type="button" style={{ marginTop: 20 }} title="Save" onClick={this.addStudentBulkUpload} className="btn btn-info">Save</button>
                                    </div>
                                </div> */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        );
    }



}
export default studentTemplate;