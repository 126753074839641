import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
// var httpURL = lstModules['event'].url + ':' + lstModules['event'].port;
var httpURL = lstModules['main'].url + ":" + lstModules['main'].port;
var tokenid = localStorage.getItem('tokenid');


function getTestDtlsSvc(pTestId, callBack) {
    // // console.log("getTestDtlsSvc called");
    axios(
        {
            url: httpURL + "/sms/wt/test/invigilator/test_dtls",
            method: 'POST',
            params: { testId: pTestId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)

        }
    ).catch((error) => {
        // // console.log("TestDtls Catch error")
        callBack(error)
    });
}
function getStudentInfoSvc(pTestId, pStudentId, callBack) {
    // // console.log("getStudentInfoSvc called");
    axios(
        {
            url: httpURL + "/sms/wt/test/invigilator/student_info",
            method: 'POST',
            params: { testId: pTestId, studentId: pStudentId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)

        }
    ).catch((error) => {
        // // console.log("getStudentInfoSvc Catch error")
        callBack(error)
    });
}


function generateOtpSvc(pTestId, pLstStudentId, callBack) {
    // // console.log("generateOtpSvc called");
    var formData = new FormData();
    var obj = {
        testId: pTestId,
        lstStudent: pLstStudentId
    }
    formData.append("generateOtpRQ", JSON.stringify(obj))
    axios(
        {
            url: httpURL + "/sms/wt/test/invigilator/generate_otp",
            method: 'POST',
            data: formData,
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)

        }
    ).catch((error) => {
        // // console.log("generateOtpSvc Catch error")
        callBack(error)
    });
}

function startTestSvc(pTestId, callBack){
    // // console.log("startTest called");
    axios(
        {
            url: httpURL + "/sms/wt/test/invigilator/start_test",
            method: 'POST',
            params: { testId: pTestId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
        }
    ).catch((error) => {
        // // console.log("startTest Catch error")
        callBack(error)
    });

}
export { getTestDtlsSvc, getStudentInfoSvc, generateOtpSvc, startTestSvc }