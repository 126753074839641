import React, { useState, useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import Header from 'common/js/header/header.js'
import PtmFeedbackTable from './PtmFeedbackTable.js'
// import PtmSlider from './PtmSlider.js'

const PtmFeedbackCtrl = () => {
  const [loader, setLoader] = useState('none')
  const [selPtmId, setSelPtmId] = useState(0)
  const [flgSlider, setFlgSlider] = useState(0)
  const [tableKey, setTableKey] = useState(0)

  /* async function openSlider (pFlgSlider, pPtmId) {
    // // console.log(pPtmId)
    await setSelPtmId(pPtmId)
    setFlgSlider(pFlgSlider)
  }
  function closeSlider () {
    setFlgSlider(0)
  } */

  const getWingId = wingId => {
    // // console.log('wingId = ' + wingId)
  }
  return (
    <div className='MainContainer'>
      <div
        style={{
          position: 'fixed',
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: '50%',
          top: '50%',
          display: loader
        }}
      >
        <img
          className='loderImg'
          src={require('common/images/loader.gif')}
          alt='Loader...'
        />
      </div>
      <Header wingFromChild={getWingId} />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className='col-sm-12 col-centered' style={{ top: '1%' }}>
        <PtmFeedbackTable
          setFlgSlider={setFlgSlider}
          ptmId={selPtmId}
          setSelPtmId={setSelPtmId}
          // openSlider={openSlider}
        />

        {/* <div
          style={{ display: flgSlider == 10 ? 'block' : 'none', width: '80%' }}
          className='SlotSlider'
        >
          <button
            type='button'
            className='btn btn-info text-center btnCloseDiv'
            onClick={e => closeSlider()}
            style={{ right: '81%' }}
          >
            <span
              id='close'
              title='Close Slider'
              className='glyphicon glyphicon-remove'
            ></span>
          </button>

          <div
            className='col-sm-12'
            style={{
              pointerEvents: loader === 'none' ? 'auto' : 'none',
              height: '100%',
              margin: '0 auto',
              background: '#fff',
              border: '1px solid #ccc',
              borderRadius: 10
            }}
          >
            <PtmSlider
              key={selPtmId}
              setTableKey={setTableKey}
              ptmId={selPtmId}
            />
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default PtmFeedbackCtrl
