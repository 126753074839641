import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import { toast } from 'react-toastify';
import '../css/paymentSlider.css';
var httpURL = '';
var queryString = require('querystring');

const screenId = 37;

export default class PaymentSlider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            paymentDate: null,
            refNo: "",
            amount: "",
            modeId: 0,
            remarks: "",

        }
    }


    handleChangeDate = (date) => {
        this.setState({ paymentDate: date, });
    }
    handleInputChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    saveFeePayment = (obj) => {
        let {
            refNo,
            amount,
            modeId,
            remarks,
        } = obj;
        let RQSavePayment = {
            refNo,
            amount,

            modeId,

            remarks,
        }
        this.setState({ loader: 'block', opacity: 0.5 })
        axios.post(httpURL + '/sms/payment/save',
            queryString.stringify({ "RQSavePayment": JSON.stringify(RQSavePayment), screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === "Success") {
                toast.success(response.data.SvcMsg);
                this.getHistoryList();
            }
            else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 })
            toast.error("Service failed " + e);
        });
    }

    save = () => {
        let { refNo,
            modeId,
            remarks, } = this.state;
        let amount = Math.trunc(this.state.amount);
        alert("amt = " + amount);
        let dt = $('#paymentDate').val();
        let paymentType = 10;

        if (amount === 0) {
            toast.warning('Amount Should not be Zero');
            return false;
        }
        if (modeId === 0) {
            toast.warning('Please Select Mode');
            $('#modeId').focus();
            return false;
        }
        if (dt === "") {
            toast.warning('Please Enter Date');
            $('#paymentDate').focus();
            return false;
        }
        if (refNo === 0) {
            toast.warning('Please Enter Reference Number');
            $('#refNo').focus();
            return false;
        }

        let obj = {
            refNo: refNo,
            amt: amount,
            dt: dt,
            modeId: modeId,
            remarks: remarks,
            paymentType: paymentType,

            lstStudentFeeDtls: {
                studentId: this.props.studentId,
                lstMonthlyPaid: [{}],
            }
        }
        this.props.saveFeePayment(obj);
    }

    render() {
        return (
            <>
                <form id="addForm" className="form-horizontal formWrapper clearfix" >
                    <div className="ab">
                        <div className="form-group">
                            <label className="control-label col-sm-4" >Mode<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                            <div className="col-sm-7 padding-remove">
                                <select autoComplete="off" name="modeId" id="modeId"
                                    type="text" className="form-control "
                                    value={this.state.modeId}
                                    onChange={this.handleInputChange}
                                >
                                    <option value="0" >Mode</option>
                                    {this.props.lstModes.map((mode) => (
                                        <option key={mode.modeId} value={mode.modeId} >{mode.mode}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-4" >Date<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                            <div className="col-sm-7 padding-remove">
                                <DatePicker id="paymentDate"
                                    className='form-control'
                                    placeholderText="DD-MM-YYYY"
                                    selected={this.state.paymentDate}
                                    onChange={this.handleChangeDate}
                                    dateFormat="dd-MM-yyyy"
                                    dropdownMode="select"
                                    maxDate={new Date()}
                                    autoComplete='off'
                                    showMonthDropdown
                                    showYearDropdown
                                    adjustDateOnChange
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-4" >Ref. No.<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                            <div className="col-sm-7 padding-remove">
                                <input autoComplete="off" name="refNo" id="refNo"
                                    type="text" className="form-control "
                                    value={this.state.refNo}
                                    onChange={this.handleInputChange}
                                    placeholder="Enter Ref. No." />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-4" >Amount <span style={{ color: 'red', marginLeft: 1 }}>*</span> </label>
                            <div className="col-sm-7 padding-remove">
                                <   input autoComplete="off" name="amount" id="amount"
                                    type="text" className="form-control "
                                    value={this.state.amount}
                                    onChange={this.handleInputChange}
                                    placeholder="Enter Amount" />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="control-label col-sm-4" >Remarks </label>
                            <div className="col-sm-7 padding-remove">
                                <textarea autoComplete="off" name="remarks"
                                    value={this.state.remarks}
                                    onChange={this.handleInputChange}
                                    id="remarks" type="text" className="form-control "
                                    placeholder="Enter Remarks" />
                            </div>
                        </div>

                    </div>
                    <div className="col-sm-3 padding-remove paymentButtons">
                        <div className="form-group" >
                            <div className="ab1" >
                                <button type="button" style={{ marginTop: 20 }}
                                    title="Save"
                                    onClick={this.save}
                                    className="btn btn-info">Save</button>
                            </div>
                            <button type="button" className="btn btn-info text-center " id="closeSlider" style={{ right: '71%', opacity: 1 }}
                                onClick={this.props.removeClassFromBody}>
                            <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                            </button>
                        </div>

                    </div>
                </form >
            </>

        )
    }
}
