import React, { useEffect, useState } from 'react'
import $ from "jquery";
import { toast, ToastContainer } from "react-toastify"
import 'common/css/master.css';
import SyllabusBody from './clsSybBody';
import SyllabusSlider from './clsSybSlider'
import { getClsSyb, getSyllabusList, getSubjectList, saveSyllabus } from './clsSybServices'
import Header from 'common/js/header/header';

const Syllabus = () => {
    const [state, setState] = useState({})
    const [lstClsSyb, setLstClsSyb] = useState([])
    const [data, setData] = useState({ classId: 0, classLabel: '', sectionId: 0, section: '', syllabusId: 0, syllabus: '' });
    const [lstSyb, setLstSyb] = useState([])
    const [lstSub, setLstSub] = useState([])
    const [sliderLoader, setSliderLoader] = useState('none');
    const [clsLoader, setClsLoader] = useState('none');
    const [syllabusId, setSyllabusId] = useState(0);
    useEffect(() => {
        setClsSyb(handleClsSybResponse)
        setSyllabusList(handleSyllabusListResponse)
    }, [])

    function setClsSyb() {
        setClsLoader("block")
        getClsSyb(handleClsSybResponse);
        // // console.log("ClsSyb has been called")
    }

    function handleClsSybResponse(data) {
        setClsLoader("none")
        var obj = data;
        if (obj.SvcStatus == 'Success') {
            setLstClsSyb(obj.lstClsSyb);
        } else if (obj.SvcStatus == 'Failure') {
            if (obj.SvcMsg === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.SvcMsg === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.SvcMsg);
            }
        } else {
            toast.error("Service failed " + obj.SvcMsg);
        }
    }


    function setSyllabusList() {
        getSyllabusList(handleSyllabusListResponse);
        // // console.log("Function has been called")
        // // console.log(getSyllabusList)
    }

    function handleSyllabusListResponse(data) {
        var obj = data;
        if (obj.SvcStatus == 'Success') {
            setLstSyb(obj.lstSyb);
        } else if (obj.SvcStatus == 'Failure') {
            if (obj.SvcMsg === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.SvcMsg === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.SvcMsg);
            }
        } else {
            toast.error("Service failed " + obj.SvcMsg);
        }
    }

    function setSubjectList(handleSubjectListResponse, sybId) {
        getSubjectList(handleSubjectListResponse, sybId);
        // // console.log("SubjectList has been called")
    }

    function handleSubjectListResponse(data, sybId) {
        var obj = data;
        if (obj.SvcStatus === 'Success') {
            setLstSub(obj.lstSub);
            // // console.log("Subject list::"+JSON.stringify(obj.lstSub))
            setSyllabusId(sybId);
        } else if (obj.SvcStatus === 'Failure') {
            if (obj.SvcMsg === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.SvcMsg === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.SvcMsg);
            }
        } else {
            toast.error("Service failed " + obj.SvcMsg);
        }
    }

    function subjectCall(sybId) {
        setSubjectList(handleSubjectListResponse, sybId);
    }


    const rowClickInMain = (e) => {
        var data = e.currentTarget.getAttribute("data");
        data = JSON.parse(data);
        setData(data);
        var sybId = data.syllabusId;
        subjectCall(sybId);
        setSyllabusId(sybId + "");
        // // console.log(data);
        $("body").addClass("openSlider");
    }
    const showSlider = (e) => {
        rowClickInMain(e);
    }

    function closeSlider() {
        $("body").removeClass("openSlider");
    };

    const processSaveSyllabus = (data, syllabusId) => {
        setSliderLoader("block")
        var classId = data.classId;
        var sectionId = data.sectionId;
        // // console.log(data);

        if (syllabusId === 0) {
            toast.warning('Please Select Syllabus');
            return false;
        }

        var obj = {
            'classId': classId, 'sectionId': sectionId, 'syllabusId': syllabusId
        }
        saveSyllabus(obj, handleSyllabusResponse);
    }

    function handleSyllabusResponse(data, obj) {
        setSliderLoader("none")
        // // console.log(data)
        if (data.SvcStatus === 'Success') {
            toast.success(data.SvcMsg);
            setClsSyb();
            if (obj.syllabusId === 0) {
                obj.syllabusId = data.syllabusId;
            }
            setData(obj);

        } else if (data.status === 'Failure') {
            if (data.SvcMsg === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (data.SvcMsg === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(data.SvcMsg)
            }
        } else {
            toast.error("Service failed " + data.error);
        }
    }

    const getWingId = (wingId) => {
        // // console.log("wingId" + wingId);
    }
    return (
        <div className="mainContainer">
            <Header wingFromChild={getWingId} />
            <div>
                <div style={{ position: 'absolute', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: clsLoader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>
                <div className="col-sm-6 col-centered" style={{ marginTop: 15, }}>
                    <SyllabusBody
                        showSlider={showSlider}
                        lstClsSyl={lstClsSyb}
                        selClassId={data.classId}
                        selSectionId={data.sectionId}
                    />
                </div>
            </div>
            <div className="slider halfWidthSlider">
                <div style={{ position: 'absolute', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: sliderLoader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>
                {syllabusId >= 0 && <SyllabusSlider
                    data={data}
                    key={syllabusId}
                    syllabusId={syllabusId}
                    lstSyb={lstSyb}
                    lstSub={lstSub}
                    subjectCall={subjectCall}
                    saveSyllabus={processSaveSyllabus}
                    removeClassFromBody={closeSlider}
                />}
            </div>
        </div>
    )
}
export default Syllabus;