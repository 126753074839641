import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
var tokenid = localStorage.getItem('tokenid')
var httpURL = lstModules['enquiry'].url + ':' + lstModules['enquiry'].port
var httpURLCommon = lstModules['main'].url + ':' + lstModules['main'].port
function getInterviewDtlsSvc (cbInterviewDtlsResponse, admissionId) {
  axios({
    url: httpURL + '/sms/admission/evaluate/interview/dtls',
    method: 'POST',
    params: { admissionId: admissionId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      cbInterviewDtlsResponse(data)
    })
    .catch(error => {
      // // console.log('Interview List Catch error')
    })
}

function saveInterviewEvaluationSvc (
  cbSaveInterviewEvaluationResponse,
  saveObj
) {
  axios({
    url: httpURL + '/sms/admission/evaluate/interview/save',
    method: 'POST',
    params: { interviewResult: saveObj },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      cbSaveInterviewEvaluationResponse(data)
    })
    .catch(error => {
      // // console.log('Save Catch error')
    })
}

function getClassListSvc (cbGetLstClassResponse) {
  axios({
    url: httpURLCommon + '/sms/mst/cls/list',
    method: 'POST',
    params: {},
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      cbGetLstClassResponse(data)
    })
    .catch(error => {
      // // console.log('Class List Catch error')
    })
}

function getSectionListSvc (cbGetSectionListSvc, classId) {
  axios({
    url: httpURLCommon + '/sms/mst/cls/section_count/list',
    method: 'POST',
    params: { clsId: parseInt(classId) },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      var data = Response.data
      cbGetSectionListSvc(data)
    })
    .catch(error => {
      // // console.log('Section List Catch error')
    })
}

export { getInterviewDtlsSvc }
export { saveInterviewEvaluationSvc }
export { getClassListSvc }
export { getSectionListSvc }
