import React, { useState, useEffect } from "react";
import 'common/css/master.css'
import '../css/payrollSetupCtrl.css'
import { toast, ToastContainer } from 'react-toastify';
import { getEmployeeListSvc } from "./employeeSvc";
const EmployeeTable = (props) => {
    const [loader, setLoader] = useState("none");
    const [lstStaff, setLstStaff] = useState([])
    useEffect(() => {
        setLoader("block")
        getEmployeeListSvc(handleEmpListResponse)
    }, [])
    function handleEmpListResponse(data) {
        if (data.SvcStatus === 'Success') {
            setLstStaff(data.lstStaff)
        }
        else {
            toast.error(data.SvcMsg)
        }
        setLoader('none')
    }
    function editEmployeeData(pStaffId) {
        props.setStaffId(pStaffId)
        setEmployeeforEdit(pStaffId)
        props.openSlider()
    }
    function setEmployeeforEdit(pStaffId) {
        for (var i = 0; i < lstStaff.length; i++) {
            // // console.log("Inside loop " + lstStaff[i].staffId)
            if (lstStaff[i].staffId === pStaffId) {
                // // console.log("inside if ")
                //props.setStaffId(lstStaff[i].staffId)
                props.setStaffObj(lstStaff[i])
                // // console.log("Interviewforedit:" + JSON.stringify(lstStaff[i]))
                break;
            }
        }
    }
    const colgrp = <colgroup>
        <col width="20%"></col>
        <col width="20%"></col>
        <col width="20%"></col>
        <col width="20%"></col>
        <col width="10%"></col>
    </colgroup>
    return (
        <div style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
            {/* <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div> */}
            <table className='tableLayout1'>
                {colgrp}
                <thead>
                    <tr>
                        <th>Emp. Code</th>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Grade</th>
                        <th>Action</th>
                    </tr>
                </thead>
            </table>
            <div className='tablebodyWrapper'>
                <table className='tableLayout'>
                    {colgrp}
                    <tbody>
                        {
                            lstStaff.map((staff, idx) => {
                                return <tr key={idx}
                                    onClick={(e) => props.setStaffId(staff.staffId)} className={idx === props.staffId ? "selected" : ""}
                                >
                                    <td>{staff.empCode}</td>
                                    <td style={{textAlign:"left"}}>{staff.staff}</td>
                                    <td>{staff.role}</td>
                                    <td>{staff.grade}</td>
                                    <td className="text-center">
                                        <div className="glyphicon glyphicon-edit" style={{ padding: '1px' }} onClick={(e) => editEmployeeData(staff.staffId)}></div>
                                    </td>
                                </tr>
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    )
};
export default EmployeeTable;