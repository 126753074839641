import React, { useEffect, useState } from "react";


const FeeCollection = (props) => {
    const { key, lstFeeCollection, handleCheckboxSave, closeSlider } = props;

    // addClassOnBody = (id) => {
    //     $('#feeCollectionTableBody tr#' + id).addClass('selected').siblings().removeClass('selected');
    // }


    return (
        <div style={{ marginTop: 20, width: 520 }}>
            <table className="tableLayout1" id='feeCollectionHeadTable'>
                <thead>
                    <tr>
                        <th width="100">Select</th>
                        <th width="100">Collection</th>
                        <th width="100">Fee Code</th>
                        <th width="100">Fill Label</th>
                        <th width="100">Amount</th>
                    </tr>
                </thead>
            </table>
            <div className="tableDiv">
                <table id="feeCollectionTable" className="tableLayout">
                    <tbody id="feeCollectionTableBody">
                        {lstFeeCollection.map((item, idx) => (
                            <tr item={JSON.stringify(item)} key={item.feeHeadId} id={item.feeClassId}
                            /* onClick={() => { this.addClassOnBody(item.feeHeadId); }} */
                            >
                                <td width="100" className="text-center">
                                    <input type="checkbox" id={idx}
                                        defaultChecked={item.selected}
                                        onChange={(e) => props.handleCheckboxSave(e, item.feeHeadId, item.feeClassId)}
                                    />
                                </td>
                                <td width="100" className="text-center">{item.collectionFrequency}</td>
                                <td width="100" className="text-center">{item.feeHeadCode}</td>
                                <td width="100" className="text-center">{item.label}</td>
                                <td width="100" className="text-center">{item.amount.toLocaleString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="form-group" >
                <div className="col-sm-8 text-right" >
                    <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={props.closeSlider} style={{ right: "45%" }}>
                        <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                    </button>
                </div>
            </div>
            {/* <div className="form-group" >
                    <div className="col-sm-12 text-center multiButtonWrap" >
                        <button type="button" style={{ marginTop: 20 }}  className="btn btn-success">Save</button>
                    </div>
            </div> */}
        </div>
    );
}

export default FeeCollection;