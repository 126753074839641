import axios from 'axios';

import lstModules from 'common/json/lstModules.json';
import { Form } from 'react-router-dom';

const httpURL = lstModules['main'].url + ':' + lstModules['main'].port;
var tokenid = localStorage.getItem("tokenid");


export const getFacilityListSvc = (callback) => {
    // console.log("getFacilityListSvc called");
    var obj = { status: "Success", message: "" }
    axios({
        url: httpURL + '/sms/mst/facility/list',
        method: "POST",
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console.log('data.SvcStatus=' + response.data.SvcStatus);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstFacility = response.data.lstFacility;
        }
        callback(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        obj.lstIdCard = [];
        callback(obj);
    });
}

export const saveFacilitySvc = (saveFacilityObj, callback) => {
    // console.log("saveFacilitySvc called");
    const formData = new FormData();
    formData.append("facility", JSON.stringify(saveFacilityObj))
    var obj = { status: "Success", message: "" }
    axios({
        url: httpURL + '/sms/mst/facility/save',
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        // console.log('data.SvcStatus=' + response.data.SvcStatus);
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        callback(obj);
    }).catch((error) => {
        obj.status = "Exception";
        obj.message = error;
        obj.lstIdCard = [];
        callback(obj);
    });
}