import React, { useState, useEffect, useRef } from "react";
import { toast } from 'react-toastify';
import { getDatesheetListSvc } from "./DatesheetTableSvc";
import { viewDatesheetBySectionSvc, generateDatesheetSvc, publishDatesheetSvc, viewCombinedDatesheetBySectionSvc } from "./DatesheetTableSvc";
import ViewDatesheet from "./ViewDatesheet";

const DatesheetTable = (props) => {
    const [lstSelSecId, setLstSelSecId] = useState([]);
    const [randNo, setRandNo] = useState(0)
    const [loader, setLoader] = useState("none");
    const [foundLst, setFoundLst] = useState([])
    const [lstDatesheet, setLstDatesheet] = useState([])
    const [lstSecId, setLstSecId] = useState([])
    const [flgType, setFlgType] = useState(0)

    useEffect(() => {
        getDatesheetListSvc(props.examId, cbLstDatesheetResponse)
    }, [props.examId])

    function cbLstDatesheetResponse(obj) {
        // // console.log(obj)
        if (obj.status === "Success") {
            setLstDatesheet(obj.lstDatesheet)
            setFoundLst(obj.lstDatesheet)
        } else if (obj.status === 'Failure') {

            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {

            toast.error("Service failed " + obj.message);
        }
    }

    const filterByCls = (pCls) => {
        if (pCls !== '') {
            var results = foundLst.filter(obj => obj.cls.toLowerCase.indexOf(pCls.toLowerCase() > -1))
            setFoundLst(results);
        } else {
            setFoundLst(lstDatesheet);
            // If the text field is empty, show all users
        }

    };

    function viewDatesheetBySection(pSectionId, pExamId) {
        viewDatesheetBySectionSvc(pSectionId, pExamId, cbViewDatesheetBySection)
    }

    function cbViewDatesheetBySection(data) {
        let blob = new Blob([data.data], { type: 'application/pdf' }),
            url = window.URL.createObjectURL(blob);
        const iframe = document.getElementById('viewer');
        iframe.setAttribute('src', url);
        // setTimeout(() => URL.revokeObjectURL(url), 18000);
        // URL.revokeObjectURL(url);
        setFlgType(10);
    }

    function generateDatesheet(pExamId) {
        // // console.log(pExamId)
        // // console.log(lstSecId)

        generateDatesheetSvc(pExamId, lstSecId, cbPublishDatesheetSvc)
    }

    function publishDatesheet(pExamId) {
        // // console.log(lstSecId)
        publishDatesheetSvc(pExamId, lstSecId, cbPublishDatesheetSvc)
    }

    function cbPublishDatesheetSvc(data) {
        if (data.status === 'Success') {
            toast.success(data.message)
        }
        else {
            toast.error(data.message)
        }
    }

    function combinedViewPdf(pExamId) {
        // // console.log(lstSecId)
        viewCombinedDatesheetBySectionSvc(lstSecId, pExamId, cbViewCombinedDatesheetBySection)
    }

    function cbViewCombinedDatesheetBySection(data) {
        let blob = new Blob([data.data], { type: 'application/pdf' }),
            url = window.URL.createObjectURL(blob);
        const iframe = document.getElementById('viewer');
        iframe.setAttribute('src', url);
        URL.revokeObjectURL(url);
        setFlgType(10);
    }

    async function handleLstSecIdChange(pId, pChecked) {
        var tmpLstSec = lstSelSecId
        pId = parseInt(pId)
        pChecked = !!(pChecked)

        if (pChecked && !lstSelSecId.includes(pId)) {
            tmpLstSec.push(parseInt(pId));
            setLstSelSecId(tmpLstSec);
        } else {
            tmpLstSec.splice(tmpLstSec.indexOf(pId), 1)
            setLstSelSecId(tmpLstSec);
        }
        setLstSecId(tmpLstSec)
    }

    const colgrp = <colgroup>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="10%"></col>
        <col width="20%"></col>
        <col width="20%"></col>
        <col width="10%"></col>
        <col width="10%"></col>
    </colgroup>

    function generateRandomNo() {
        var lRandNo = Math.floor(Math.random() * 999999);
        // // console.log(lRandNo)
        setRandNo(lRandNo)
    }

    function closeSlider() {
        setFlgType(0)
    }

    return (
        <div style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
            <div className="col-sm-12" >
                <button type="button" title="Combined Date Sheet View" onClick={() => combinedViewPdf(props.examId)} className="btn btn-warning pull-right">Combined View</button>
                <button type="button" style={{ marginRight: '2%' }} title="Publish Date Sheet" onClick={() => { publishDatesheet(props.examId) }} className="btn btn-warning pull-right">Publish</button>
                <button type="button" style={{ marginRight: '2%' }} title="Generate Date Sheet" onClick={() => { generateDatesheet(props.examId) }} className="btn btn-warning pull-right">Generate</button>
            </div>
            <table className='tableLayout1'>
                {colgrp}
                <thead>
                    <tr>
                        <th>Class</th>
                        <th>Section</th>
                        <th>Schedule</th>
                        <th>Teachers</th>
                        <th>Generated On</th>
                        <th>Published On</th>
                        <th>Select</th>
                        <th>Action</th>
                    </tr>
                </thead>
            </table>
            <div className='tablebodyWrapper'>
                <table className='tableLayout'>
                    {colgrp}
                    <tbody>
                        {lstDatesheet && lstDatesheet.length > 0 && lstDatesheet.map((obj, idx) => {
                            var isSelected = obj.sectionId === props.sectionId;
                            return <tr key={idx} id={obj.sectionId}
                                onClick={(e) => props.setSectionId(obj.sectionId)} className={isSelected ? "selected" : ""}
                            >
                                <td className="no-display">{obj.sectionId}</td>
                                <td className="text-center">{obj.cls}</td>
                                <td className="text-center">{obj.section}</td>
                                <td className="text-center">{obj.schPct}%</td>
                                <td className="text-center">{obj.tchPct}%</td>
                                <td className="text-center">{obj.generatedOn}</td>
                                <td className="text-center">{obj.publishedOn}</td>
                                <td className="text-center"><input type="checkbox" key={obj.sectionId}
                                    id={obj.sectionId}
                                    defaultChecked={obj.selected}
                                    onChange={(e) => handleLstSecIdChange(e.target.id, e.target.checked)} ></input></td>
                                <td className="text-center" onClick={() => viewDatesheetBySection(obj.sectionId, props.examId)}>View</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            <div className="slider halfWidthSlider" style={{ right: 0, opacity: 1, display: flgType === 10 ? 'block' : 'none', zIndex: 10000 }}>
                <div>
                    <button
                        type="button"
                        className="btn btn-info text-center btnCloseDiv"
                        onClick={(e) => closeSlider()}>
                        <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                    </button>
                </div>
                <ViewDatesheet
                    key={generateRandomNo}
                />
            </div>
        </div >
    )
}

export default DatesheetTable