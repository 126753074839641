import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import { toast, ToastContainer } from 'react-toastify';
import $ from "jquery";
import axios from 'axios';
import "../css/fee.css";
import { convertToObject } from 'typescript';

var httpURL = '';
var queryString = require('querystring');
var screenId = 33;
var lstFrequency = [];

export default class FeeHeadsSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            feeHeadId: this.props.feeHeadId,
            feeHeadCode: "",
            lstFee: [],
            labelId: this.props.labelId,
            label: "",
            feeFrequency: "Monthly",
            collectionFrequency: "",
            defaultAmt: 0,
            lateFeeFixAmt: 0,
            lateFeeAmtPerDay: 0,
            lateFeeLimit: 0,
            lstCollection: {
                monthId: 0,
                month: "",
                amount: 0,
                selected: ""
            },
            tokenid: localStorage.getItem('tokenid')

        }
        // // console.log('labelId::' + this.state.labelId)
    }
    componentWillReceiveProps(props) {

    }
    async componentDidMount() {
        // this.getMonthsList();
        lstFrequency = [{ 'frequencyId': 'Annual', 'frequency': 'Annual' },
        { 'frequencyId': 'HalfYearly', 'frequency': 'HalfYearly' },
        { 'frequencyId': 'Quarterly', 'frequency': 'Quarterly' },
        { 'frequencyId': 'Monthly', 'frequency': 'Monthly' }
        ];
        // this.fillFrequencyCmb();
        // this.fillCollectionCmb();
        await this.setSlider();
        if (this.props.feeHeadId === 0) {
            this.resetFeeTable();
        }

        if (this.props.flgAdd) {
            this.resetForm();
        }
    }

    resetForm = () => {
        this.setState({
            feeHeadCode: "",
            label: "",
            // feeFrequency: "",
            collectionFrequency: "",
            defaultAmt: 0,
            lateFeeFixAmt: 0,
            lateFeeAmtPerDay: 0,
            lateFeeLimit: 0,
            lstCollection: {
                monthId: 0,
                month: "",
                amount: 0,
                selected: ""
            },
            total: 0,
            labelId: this.props.labelId
        })
    }
    resetFeeTable = () => {
        $('#feesBodyTable tr input[type=checkbox]').prop("defaultChecked", false);
        $('#feesBodyTable tr input').val("");
    }
    resetSlider = () => {
        document.getElementById('feeHeadAddForm').reset();
        $('#feeHeadAddForm input').val('');
        $('#feeHeadAddForm input[type="checkbox"]').attr('checked', false);
    }
    /* setFrequencyCmb = () => {
        var feeFreq = this.props.feeHeadData.feeFrequency || 'Frequency';
        $("#feeFrequency option").removeAttr('selected');
        $("#feeFrequency option:contains(" + feeFreq + ")").attr('selected', 'selected');
        // $("#feeFrequency option").filter(function () {
        //     return $(this).text().trim() == this.state.feeFrequency;
        // }).attr('selected', 'selected');
    } */
    setCollectionCmb = () => {
        var feeFreq = this.props.feeHeadData.feeFrequency || 'Collection';
        $("#collectionFrequency option").removeAttr('selected');
        $("#collectionFrequency option:contains(" + feeFreq + ")").attr('selected', 'selected');
    }

    componentDidUpdate = async (prevProps) => {
        // this.resetSlider();
        if (prevProps.feeHeadId !== this.props.feeHeadId) {
            if (this.props.feeHeadId !== 0) {
                await this.setSlider(this.props.flgAdd);
            }
            else {
                await this.setSlider(this.props.flgAdd);
                this.resetFeeTable();
            }
        }


    }
    setSlider = async (flgAdd) => {
        // this.props.setLoader('block', 0.5);
        this.resetSlider();
        this.getMonthsList();
        this.resetFeeTable();
        // // console.log('lstFee::'+this.state.lstFee)
        var objData = this.state.lstFee;
        // // console.log("objData = " + JSON.stringify(objData));

        // await this.getMonthsList();
        let lstFee = this.state.lstFee;
        // // console.log(objData.lstCollection);
        // // console.log(lstFee);
        if (!flgAdd) {
            this.resetFeeTable();
            for (let i = 0; i < objData.length; i++) {
                for (let j = 0; j < lstFee.length; j++) {
                    if (objData[i].month === lstFee[j].month) {
                        let monthId = lstFee[j].monthId;
                        let amount = objData[i].amount;
                        $('#cbx' + monthId).prop("checked", true);
                        $('#txt' + monthId).val(amount);
                    }
                }

            }

            // let month = objData.lstCollection.filter(items => {
            //     return lstFee.some((fee) => fee.month === items.month)
            // });

            // // console.log(month);


        }
        await this.setState({
            feeHeadId: objData.feeHeadId,
            feeHeadCode: objData.feeHeadCode,
            // label: objData.label,
            // labelId: objData.labelId,
            /* feeFrequency: !objData.feeFrequency ? "" : objData.feeFrequency.trim(), */
            collectionFrequency: !objData.collectionFrequency ? "" : objData.collectionFrequency.trim(),
            defaultAmt: objData.defaultAmt,
            lateFeeFixAmt: objData.lateFeeFixAmt,
            lateFeeAmtPerDay: objData.lateFeeAmtPerDay,
            lateFeeLimit: objData.lateFeeLimit,
            lstCollection: objData.lstCollection,
        })
        /* this.setFrequencyCmb(); */
        // this.props.setLoader('none', 1);
        // this.changeCollection();

    }

    /* fillFrequencyCmb() {
        $('#feeFrequency').empty();
        $('#feeFrequency').prepend($("<option> </option>").val(0).html('Frequency'));
        for (var i = 0; i < lstFrequency.length; i++) {
            var frequencyId = lstFrequency[i].frequencyId;
            var frequency = lstFrequency[i].frequency;
            $('#feeFrequency').append($("<option> </option>").val(frequencyId).html(frequency));
        }
    } */

    fillCollectionCmb() {
        $('#collectionFrequency').empty();
        $('#collectionFrequency').prepend($("<option> </option>").val(0).html('Collection'));
        for (var i = 0; i < lstFrequency.length; i++) {
            var frequencyId = lstFrequency[i].frequencyId;
            var frequency = lstFrequency[i].frequency;
            $('#collectionFrequency').append($("<option> </option>").val(frequencyId).html(frequency));
        }
    }
    cmbLblOnChange = async (e) => {
        // e.preventDefault();
        /* // // console.log($('#cmbLbl').find("option:selected").val());
        // // console.log(e.target.value) */
        this.setState({ labelId: e.target.value })
        // // console.log(e.target.name + ':::' + e.target.value)
    }

    onChangeFeeHeadCode = async (fHead) => {
        this.setState({
            feeHeadCode: fHead !== "" ? fHead.toUpperCase() : ""
        });
    }

    inputOnChange = async (e) => {
        e.preventDefault();

        await this.setState({
            [e.target.id]: e.target.value
        });

        var defaultAmt = $('#defaultAmt').val()
        $('#defaultAmt').val(defaultAmt.replace(/^0+/,))

        var lateFeeFixAmt = $('#lateFeeFixAmt').val()
        $('#lateFeeFixAmt').val(lateFeeFixAmt.replace(/^0+/,))

        var lateFeeAmtPerDay = $('#lateFeeAmtPerDay').val()
        $('#lateFeeAmtPerDay').val(lateFeeAmtPerDay.replace(/^0+/,))

        var lateFeeLimit = $('#lateFeeLimit').val()
        $('#lateFeeLimit').val(lateFeeLimit.replace(/^0+/,))

        var discountSiblingAmt = $('#discountSiblingAmt').val()
        if (discountSiblingAmt)
            $('#discountSiblingAmt').val(discountSiblingAmt.replace(/^0+/,));


        var discountScholarAmt = $('#discountScholarAmt').val()
        if (discountScholarAmt)
            $('#discountScholarAmt').val(discountScholarAmt.replace(/^0+/,))


        var discountMeritMeansAmt = $('#discountMeritMeansAmt').val()
        if (discountMeritMeansAmt)
            $('#discountMeritMeansAmt').val(discountMeritMeansAmt.replace(/^0+/,))

        var discountScStAmt = $('#discountScStAmt').val()
        if (discountScStAmt)
            $('#discountScStAmt').val(discountScStAmt.replace(/^0+/,))


        var discountBackwardAmt = $('#discountBackwardAmt').val()
        if (discountBackwardAmt)
            $('#discountBackwardAmt').val(discountBackwardAmt.replace(/^0+/,))

    }


    /*     cmbOnChange = async (e) => {
            e.preventDefault();
            await this.setState({
                //[e.target.id]: $(e.target).find("option:selected").text(),
                feeFrequency: e.target.value,
                collectionFrequency: e.target.value
            }, () => this.changeCollection());
        } */
    cmbOnChangeCollection = async (e) => {
        e.preventDefault();
        await this.setState({
            [e.target.id]: $(e.target).find("option:selected").text(),
            collectionFrequency: e.target.value
        }, () => this.changeCollection('cf'));
    }
    changeState = () => {
        /* if (!this.state.feeFrequency) { */
        this.setState({
            /* feeFrequency: "", */
            collectionFrequency: ""
        });
        /* } */
    }
    handleSubmit = (e) => {
        e.preventDefault();
        var feeHeadId = this.props.flgAdd ? 0 : this.props.feeHeadId;
        var feeHeadCode = this.state.feeHeadCode;
        var labelId = this.state.labelId;
        var label = this.state.label;
        var collectionFrequency = $('#collectionFrequency').find("option:selected").text();
        var defaultAmt = this.state.defaultAmt;
        var lateFeeFixAmt = this.state.lateFeeFixAmt;
        var lateFeeAmtPerDay = this.state.lateFeeAmtPerDay;
        var lateFeeLimit = this.state.lateFeeLimit;
        var lstCollection = [];
        $('#feesBodyTable tr').each(function (index, value) {
            var monthId = $(this).find('td:eq(0)').text();
            if ($(this).find($('input[type=checkbox]')).prop('checked') == true) {
                var month = $(this).find('td:eq(2)').text();
                var selected = true;
                var amount = Math.trunc($(this).find('td:eq(3) input').val());
                var lstCollectionTemp = { 'monthId': monthId, 'month': month, 'amount': amount, 'selected': selected }
                lstCollection.push(lstCollectionTemp);
            }
        });
        var lstTempCollection = []
        for (var i = 0; i < lstCollection.length; i++) {
            var temp = {
                monthId: lstCollection[i].monthId,
                amount: lstCollection[i].amount
            }
            lstTempCollection.unshift(temp)
        }
        lstCollection = lstTempCollection
        var objToSave = {
            feeHeadId,
            feeHeadCode,
            label,
            labelId,
            collectionFrequency,
            defaultAmt,
            lateFeeFixAmt,
            lateFeeAmtPerDay,
            lateFeeLimit,
            lstCollection
        }
        // this.props.addFeeHeads(objToSave);
        // // console.log("objToSave::" + JSON.stringify(objToSave))
        // // console.log('labelId::' + this.state.labelId)

        axios.post(httpURL + '/sms/fee/head/save'
            , queryString.stringify({ 'feeHeadId': this.props.feeHeadId, labelId: this.state.labelId, screenId, feeHead: JSON.stringify(objToSave) })
            , {
                // params:{feeHead:JSON.stringify(objToSave)},
                headers: { 'tokenid': this.state.tokenid },
                withCredentials: true,
            }).then((response) => {
                if (response.data.SvcStatus === 'Success') {
                    toast.success(response.data.SvcMsg);
                    this.removeClassFromBody();
                    
                } else {
                    toast.error(response.data.SvcMsg);
                }
                this.setState({ loader: 'none', opacity: 1 });
            }).catch((e) => {
                this.setState({ loader: 'none', opacity: 1 });
                toast.error('Service failed: ' + e);
            });


    }
    getTotal = () => {
        var total = 0;
        $('#feesBodyTable').find('tr').each(function (index, value) {
            // // console.log('inside for')
            var amt = $(this).find('td:eq(3) input').val();
            if (amt != 0) {
                $(this).find($('input[type=checkbox]')).prop('defaultChecked', true)
            }
            else { $(this).find($('input[type=checkbox]')).prop('defaultChecked', false) }
            total += parseInt(amt)


        });
        this.setState({ total: total })
    }
    setFeesTable = () => {
        $('#feesBodyTable tr').remove();
        var table = document.getElementById('feesTable');
        var tbody = document.getElementById('feesBodyTable');
        var total = 0;
        for (var i = 0; i < this.state.lstFee.length; i++) {
            var monthId = this.state.lstFee[i].monthId;
            var month = this.state.lstFee[i].month;
            var selected = this.state.lstFee[i].selected;

            var amount = this.state.lstFee[i].amount;
            if (amount === -1) {
                amount = "";
            }

            var row = document.createElement('tr');
            row.id = monthId;


            this.addTD(row, monthId, 'no-display');
            this.addTDCbx(row, monthId, 'text-center', selected);
            this.addTD(row, month, 'text-center');
            this.addTDInp(row, monthId, amount);

            tbody.append(row);

        }
        // this.setState({total:total})
        table.append(tbody);
        this.getTotal();

    }
    addTD(row, val, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var text = document.createTextNode(val);
        cell.append(text);
        row.append(cell);
    }
    addTDCbx(row, id, classname, selected) {
        var cell = document.createElement('td');
        cell.className = classname;
        var cbx = document.createElement('input');
        cbx.type = 'checkbox';
        cbx.id = 'cbx' + id;
        cbx.className = 'checkbx';

        if (selected === true) {
            $(cbx).attr('checked', true);
        }
        else {
            $(cbx).attr('checked', false);
        }

        cell.onclick = () => {
            var frequencyId = $('#feeFrequency').find('option:selected').val();
            frequencyId = !frequencyId ? 0 : frequencyId;
            var collectionId = $('#collectionFrequency').find('option:selected').val();
            collectionId = !collectionId ? 0 : collectionId;
            $('td').removeClass('selected');
            $(row).addClass('selected').siblings().removeClass('selected');

            $('#feesBodyTable tr').each(function (index, value) {
                if ($(this).find($('input[type=checkbox]')).prop('checked') === false) {
                    $('tr.selected input').val("");
                    $('tr.selected #txt' + id).val(0);
                }
            });
        };

        cbx.onchange = () => {
            // var frequencyId = $('#feeFrequency').find('option:selected').val();
            var collectionId = $('#collectionFrequency').find('option:selected').val();
            if (/* frequencyId === "Annual" && */ collectionId === "Annual") {
                $('#feesBodyTable tr').each(function (index, value) {
                    if ($(this).find($('input[type=checkbox]')).prop('checked') === false) {
                        $('tr.selected input').val("");
                        $('tr.selected #txt' + id).val(0);

                    }
                    else {
                        var amount = $('#amount').val();
                        $('tr.selected #txt' + id).val(amount);
                        $('.checkbx').attr("checked", false);
                    }

                });

            }
            this.getTotal()
        }
        cell.append(cbx);
        row.append(cell);
    }
    addTDInp(row, id, val, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var input = document.createElement('input');
        input.className = "form-control text-right"
        input.id = 'txt' + id;
        input.addEventListener("keyup", this.getTotal)
        if (val === -1) {
            $(input).val("");
        }
        else {
            $(input).val(val);
        }

        cell.append(input);
        row.append(cell);
    }

    addTDText(row, id, val, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var input = document.createElement('input');
        input.type = 'text';
        input.className = "form-control"
        input.style.width = '100%'

        $(input).val(val);

        cell.append(input);
        row.append(cell);
    }
    getMonthsList = async () => {
        this.setState({ loader: 'block', opacity: 0.5 });
        // // console.log("this.feeHeadId::"+this.state.feeHeadId)
        var fee = {
            feeHeadId: 0,
            feeHeadCode: "abc",
            label: "abc",
            feeFrequency: "Annual",
            collectionFrequency: "Annual",
            defaultAmt: 0,
            lateFeeFixAmt: 0,
            lateFeeAmtPerDay: 0,
            lateFeeLimit: 0,
            /*  discountSiblingAmt: 0,
             discountScholarAmt: 0,
             discountMeritMeansAmt: 0,
             discountScStAmt: 0,
             discountBackwardAmt: 0, */
            lstCollection: [{
                monthId: 0,
                month: "January",
                amount: 0,
                selected: false
            }]


        }
        await axios.post(httpURL + '/sms/fee/head/months/list'
            , queryString.stringify({ 'feeHeadId': this.props.feeHeadId, screenId })
            , {
                // params:{feeHeadId:this.state.feeHeadId},
                headers: { 'tokenid': this.state.tokenid },
                withCredentials: true,
            }).then((response) => {
                if (response.data.SvcStatus === 'Success') {
                    this.setState({
                        lstFee: response.data.feeHeadDtls.lstCollection,
                        lateFeeAmtPerDay: response.data.feeHeadDtls.lateFeeAmtPerDay,
                        lateFeeFixAmt: response.data.feeHeadDtls.lateFeeFixAmt,
                        lateFeeLimit: response.data.feeHeadDtls.lateFeeLimit,
                        collectionFrequency: response.data.feeHeadDtls.collectionFrequency,
                        label: response.data.feeHeadDtls.label,
                        defaultAmt: response.data.feeHeadDtls.defaultAmt,
                        feeHeadCode: response.data.feeHeadDtls.feeHeadCode
                    });
                    // // console.log("feeHeadDtls::"+JSON.stringify(response.data.feeHeadDtls))
                    this.setFeesTable();

                } else {
                    toast.error(response.data.SvcMsg);
                }
                this.setState({ loader: 'none', opacity: 1 });
            }).catch((e) => {
                this.setState({ loader: 'none', opacity: 1 });
                toast.error('Service failed: ' + e);
            });

    }
    changeCollection = (pSrc, e) => {
        /* var frequencyId = $('#feeFrequency').find('option:selected').text(); */
        var collectionId = $('#collectionFrequency').find('option:selected').text();

        var amount = this.state.defaultAmt || 0;
        if (pSrc === "cf") {
            amount = this.state.defaultAmt || 0;
        }
        if (pSrc === "da") {
            amount = e.target.value;
        }
        /* if (!frequencyId) {
            toast.warning('Please Select Valid Frequency');
            $('#feeFrequency').focus();
            $('.feeWrapper').hide();
            return false;
        } */
        if (!collectionId) {
            toast.warning('Please Select Valid Collection');
            $('#collectionFrequency').focus();
            $('.feeWrapper').hide();
            return false;
        }


        /* if (amount === '') {
            toast.warning('Please Enter Amount');
            $('#defaultAmt').focus();
            $('.feeWrapper').hide();
            return false;
        } */
        if (collectionId == 'Annual') {
            // // console.log("Annual")
            var monthId = 1;
            this.setFeesTable();
            $('td input').prop('checked', false);
            $('td input').val(0)
            // amount = Math.trunc(amount * 12);
            $('#cbx' + monthId).prop("checked", true);
            $('#txt' + monthId).val(amount);
            this.getTotal()
        }
        else if (collectionId == 'HalfYearly') {
            // // console.log("HalfYearly")
            var monthId = 4;
            var feeId1 = 10;
            this.setFeesTable();
            $('td input').prop('checked', false);
            $('td input').val(0);
            // amount = Math.trunc(amount * 6);
            $('#cbx' + monthId).prop("checked", true);
            $('#cbx' + feeId1).prop("checked", true);
            $('#txt' + monthId).val(amount);
            $('#txt' + feeId1).val(amount);
            this.getTotal()
        }
        else if (collectionId == 'Quarterly') {
            // // console.log('Quarterly')
            var monthId = 4;
            var feeId1 = 7;
            var feeId2 = 10;
            var feeId3 = 1;
            this.setFeesTable();
            $('td input').prop('checked', false);
            $('td input').val(0);
            var total = amount * 12;
            // amount = Math.trunc(amount * 3);
            $('#cbx' + monthId).prop("checked", true);
            $('#cbx' + feeId1).prop("checked", true);
            $('#cbx' + feeId2).prop("checked", true);
            $('#cbx' + feeId3).prop("checked", true);
            $('#txt' + monthId).val(amount);
            $('#txt' + feeId1).val(amount);
            $('#txt' + feeId2).val(amount);
            $('#txt' + feeId3).val(amount);
            this.getTotal()
        }
        else if (collectionId == 'Monthly') {
            this.setFeesTable();
            // var total = amount * 12;
            // amount = amount * 12;
            $('td input').prop("checked", true);
            $('td input').val(amount)
            this.getTotal()
            // for (var i=1;i<=12;i++){
            //     $('#txt' + i).val(amount);
            // }
            // $('td.amount input').val(amount);
            // $('td input').attr('disabled', true);

        }

    }

    removeClassFromBody = () => {
        if (this.props.flgAdd) {
        	
            this.resetSlider();
            this.getMonthsList();
            this.resetFeeTable();
        }
        this.props.removeClassFromBody();
    }
    handleCheckboxSave = (e, pMonthId, pAmount) => {
        // // console.log(e.target + "::" + pMonthId + "::" + pAmount);
    }
    render() {
        httpURL = lstModules['fees'].url + ":" + lstModules['fees'].port;
        return (
            <div className="col-md-12 col-xs-12 FeeHeadFormWrap" >
                <form id="feeHeadAddForm" className="form-horizontal formWrapper" onSubmit={this.handleSubmit}>
                    <div className="formFlex">
                        <div className="form-group">
                            <label className="control-label col-sm-6 " >Fees Code<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                            <div className="col-sm-6  ">
                                <input autoComplete="off" id="feeHeadCode" type="text" className="form-control "
                                    placeholder="-" onChange={(e) => this.onChangeFeeHeadCode(e.target.value)}
                                    value={(this.state.feeHeadCode && this.state.feeHeadCode !== "-" ) ? this.state.feeHeadCode : ''} />
                            </div>
                        </div>
                        {/* <div className="form-group">
                            <label className="control-label col-sm-5" >Frequency<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                            <div className="col-sm-6">
                                <select value={this.state.feeFrequency || ''} onChange={e => { this.cmbOnChange(e) }}
                                    className="form-control" style={{ width: '100%' }} id="feeFrequency">
                                    <option>Frequency</option>
                                    {lstFrequency.map((obj, index) => {
                                        return <option key={obj.frequencyId} id={obj.frequencyId}>{obj.frequency}</option>;
                                    })}
                                </select>
                            </div>
                        </div> */}
                        <div className="form-group">
                            <label className="control-label col-sm-6" >Fees Label<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                            <div className="col-sm-6">
                                <select defaultValue={this.props.labelId} onChange={e => { this.cmbLblOnChange(e) }}
                                    className="form-control" style={{ width: '100%' }} id="cmbLbl" name="labelId">
                                    <option>Label</option>
                                    {this.props.lstLabel.map((obj, index) => {
                                        return <option key={obj.labelId} id={obj.labelId} value={obj.labelId}>{obj.label}</option>;
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-5" >Amount<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                            <div className="col-sm-6">
                                <input autoComplete="off" id="defaultAmt" type="number" min='0' className="form-control " style={{ textAlign: 'right' }}
                                    placeholder="0" onChange={(e) => { this.inputOnChange(e); this.changeCollection('da', e); }}
                                    value={(this.state.defaultAmt && this.state.defaultAmt !== 0 ) ? this.state.defaultAmt : ''} pattern="[0-9]+" />
                            </div>
                        </div>

                        {/* <div className="form-group">
                            <label className="control-label col-sm-5" >Frequency<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                            <div className="col-sm-6">
                                <select value={this.state.feeFrequency || ''} onChange={e => { this.cmbOnChange(e) }}
                                    className="form-control" style={{ width: '100%' }} id="feeFrequency">
                                    <option>Frequency</option>
                                    {lstFrequency.map((obj, index) => {
                                        return <option key={obj.frequencyId} id={obj.frequencyId}>{obj.frequency}</option>;
                                    })}
                                </select>
                            </div>
                        </div> */}
                    </div>
                    <hr></hr>
                    <div className="col-sm-12">
                        <div className="col-sm-2 padding-remove">
                            <h5 className="col-sm-12">Collection</h5>
                            {/* <label className="control-label col-sm-5" >Collection<span style={{ color: 'red', marginLeft: 2 }}>*</span></label> */}
                            <div className="col-sm-12">
                                <select value={this.state.collectionFrequency || ''} onChange={e => { this.cmbOnChangeCollection(e); }}
                                    className="form-control" style={{ width: '100%' }} id="collectionFrequency">
                                    <option>Collection</option>
                                    {lstFrequency.map((obj, index) => {
                                        return <option key={obj.frequencyId} id={obj.frequencyId}>{obj.frequency}</option>;
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6 ">
                            <table className="tableLayout">
                                <thead>
                                    <tr>
                                        <th style={{ width: 60 }}> Select </th>
                                        <th style={{ width: 60 }}> Month </th>
                                        <th style={{ width: 60 }}> Amount </th>
                                    </tr>
                                </thead>
                            </table>
                            <div className="ClassTableDiv" style={{ /* height: '400px', overflowY: 'scroll', */ border: '1px solid #ccc' }} >
                                <table id="feesTable" className="tableLayout">
                                    <colgroup>
                                        <col width="60" />
                                        <col width="60" />
                                        <col width="60" />
                                    </colgroup>
                                    <tbody id="feesBodyTable">
                                        {this.state.lstFee.map((item, idx) => (
                                            <tr item={JSON.stringify(item)} key={item.monthId} id={item.monthId}
                                            /* onClick={() => { this.addClassOnBody(item.feeHeadId); }} */
                                            >
                                                <td className="no-display">{item.monthId}</td>
                                                <td width="100" className="text-center">
                                                    <input type="checkbox" className="checkbx" id={idx}
                                                        defaultChecked={item.selected}
                                                        onChange={(e) => {
                                                            this.handleCheckboxSave(e,
                                                                item.monthId,
                                                                item.amount);
                                                        }}
                                                    />
                                                </td>
                                                <td width="100" className="text-center">{item.month}</td>
                                                <td width="100" className="text-center">
                                                    <input className="form-control text-right" id={"txt" + item.monthId} defaultValue={item.amount} onChange={(e) => this.getTotal()} />
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>

                            <label className="control-label col-sm-8" >Total Fee:</label>
                            <div className="col-sm-4 padding-remove ">
                                <input autoComplete="off" id="totalfee" type="number" min='0' className="form-control text-right "
                                    placeholder="Total Fee"
                                    value={this.state.total || 0}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <h5>Late Fees</h5>
                    <div className="formFlex">
                        <div className="form-group">
                            <label className="control-label col-sm-5" >Fix Amount</label>
                            <div className="col-sm-6 padding-remove">
                                <input autoComplete="off" id="lateFeeFixAmt" type="number" min='0' className="form-control "
                                    placeholder="Fix Amount" onChange={this.inputOnChange}
                                    value={this.state.lateFeeFixAmt || 0} step="any" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-6" >Amount Per Day</label>
                            <div className="col-sm-6 padding-remove">
                                <input autoComplete="off" id="lateFeeAmtPerDay" type="number" min='0' className="form-control "
                                    placeholder="Amount Per Day" onChange={this.inputOnChange}
                                    value={this.state.lateFeeAmtPerDay || 0}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-5" >Max Limit</label>
                            <div className="col-sm-6 padding-remove">
                                <input autoComplete="off" id="lateFeeLimit" type="number" min='0' className="form-control "
                                    placeholder="Max Amount" onChange={this.inputOnChange}
                                    value={this.state.lateFeeLimit || 0} />
                            </div>
                        </div>
                    </div>

                    <div className="formFlex row">
                        <div className="form-group" >
                            <div className="col-sm-12 text-center userButtonWrap" >
                                <button type="submit" style={{ marginTop: 10 }} title="Save" className="btn btn-info">Save</button>
                                <button type="button" className="btn btn-info text-center " id="closeSlider" onClick={this.removeClassFromBody}>
                                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }


}