import React from 'react';

// import Toggle from './Toggle';
import useDarkMode from 'use-dark-mode';

const DarkModeToggle = () => {
  const darkMode = useDarkMode(false);

  return (
    <div className="dark-mode-toggle">
      <button className="darkButton light" type="button" onClick={darkMode.toggle}>
      ☾
      </button>
      {/* <Toggle checked={darkMode.value} onChange={darkMode.toggle} /> */}
      <button className="darkButton dark" type="button" onClick={darkMode.toggle}>
        ☀
      </button>
    </div>
  );
};

export default DarkModeToggle;
