import React, { useState, useEffect } from 'react'
import 'react-funnel-pipeline/dist/index.css'
import { FunnelChart } from 'react-funnel-pipeline'
const FunnelSlider = (props) => {
    const [data, setData] = useState([])
    useEffect(() => {
        var lData = [];
        for (var i = 0; i < props.data.length; i++) {
            var obj = props.data[i];
            lData.push({
                name: obj.status,
                value: obj.count,
                id: obj.statusId,
                selected: false,
                ctr: 0
            })

        }
        setData(lData);
    }, [props.data])

    return (
        <div className="col-sm-12" style={{ height: '100%' }}>
            <FunnelChart
                data={data}
                /* title={'Sales Funnel'} */
                showRunningTotal={true}
                onRowClick={(row) => { props.filterDataByStatus(row.id) }}
                getRowStyle={(row) => { return row.id === props.status ? { margin: '0px', boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, .075), 0 1px 1px rgba(0, 0, 0, .075)' } : { margin: '1px' } }}
                getRowNameStyle={(row) => { return row.id === props.status ? { /* opacity: 1,  */fontSize: '14px', color: '#fff', fontWeight: 'bold' } : { /* opacity: 0.7, */ fontSize: '14px', color: 'black' } }}
                getRowValueStyle={(row) => { return row.id === props.status ? { color: '#fff', fontWeight: 'bold' } : { color: 'black', fontWeight: 'normal' } }}
                getToolTip={(row) => { return row.name + ' - ' + row.value }}
            />
        </div>
    )
}

export default FunnelSlider