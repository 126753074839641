import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import "common/css/master.css";
import "../../css/subjectAllocation.css";

import ClassReportTbl from "./classReportTabl";
import ClassData from "../../json/classData.json"; // Import the class data JSON
import { getClassReportListSvc } from "../allocationReportSvc";

const ClassReportCtrl = (props) => {
    const { setLoader, setOpacity } = props;
    const [lstCls, setLstCls] = useState([]);

    useEffect(() => {
        // Fetch class data from the JSON file or API
        getClassList();

    }, []);

    const getClassList = () => {
        console.log("getClassList called");
        setLoader("block");
        setOpacity(0.5);
        getClassReportListSvc(classReportListResponse);
    }

    const classReportListResponse = (data) => {
        console.log("classReportListResponse called, data", data);
        if (data.status === "Success") {
            setLstCls(data.lstClass);
        } else if (data.status === "Failure") {
            handleServiceError(data.message);
        } else {
            toast.error("Service failed " + data.error);
        }
        setLoader("none");
        setOpacity(1);
    };


    const handleServiceError = (svcMsg) => {
        if (svcMsg === "You are not Logged In") {
            toast.error("You are not logged in. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else if (svcMsg === "Server Timed Out, Login Again") {
            toast.error("Server timed out. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else {
            toast.error(svcMsg);
        }
    };

    return (
        <div className="col-md-12 col-xs-12 col-centered padding-remove">
            <div className="col-md-12 col-xs-12 col-centered padding-remove" style={{ marginTop: "45px" }}>
                <ClassReportTbl lstCls={lstCls} />
            </div>
        </div>
    );
};

export default ClassReportCtrl;
