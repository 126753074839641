import React, { useState, useEffect } from "react";
import 'common/css/master.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../css/acceptPayment.css";
import { getLstStudentSvc, saveMonthPaymentSvc } from "./acceptPaymentSvc";

const AcceptPaymentTable = (props) => {

    const [lstStudentTransport, setLstStudentTransport] = useState([]);
    const [lstStudentTransportFiltered, setLstStudentTransportFiltered] = useState([]);
    const [studentId, setStudentId] = useState(0);
    const [filterEnrollment, setFilterEnrollment] = useState("");
    const [filterName, setFilterName] = useState("");
    const [filterClassSection, setFilterClassSection] = useState("");
    const [filterFather, setFilterFather] = useState("");

    const lstMonths = ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"];


    useEffect(() => {
        // // console.log("useEffect in getLstStudent called");
        getLstStudent();
    }, []);

    const getLstStudent = () => {
        // // console.log("getLstStudent called");
        props.setLoader("block");
        props.setOpacity(0.5);
        getLstStudentSvc(lstStudentResponse);
    }

    const lstStudentResponse = async (data) => {
        // // console.log("lstStudentResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            await setLstStudentTransport(obj.lstStudent);
            await setLstStudentTransportFiltered(obj.lstStudent);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        props.setLoader('none');
        props.setOpacity(1);
    }

    // const filterStudentTable = (fEnrollNo, fName, fClassSection, fFather) => {
    //     let filteredStudentTable = [];
    //     filteredStudentTable = lstStudentTransport.filter(studentTransport =>
    //         studentTransport.enrollmentNo.toString().toLowerCase().includes(fEnrollNo.toLowerCase()));

    //     filteredStudentTable = filteredStudentTable.filter(studentTransport =>
    //         studentTransport.studentclsSection.toString().toLowerCase().includes(fClassSection.toLowerCase()));

    //     filteredStudentTable = filteredStudentTable.filter(studentTransport =>
    //         studentTransport.student.toString().toLowerCase().includes(fName.toLowerCase()));

    //     filteredStudentTable = filterStudentTable.filter(studentTransport =>
    //         studentTransport.father.toString().toLowerCase().includes(fFather.toLowerCase()));

    //     setLstStudentTransportFiltered(filteredStudentTable);
    // }

    // const handleFilterEnrollmentNo = (e) => {
    //     setFilterEnrollment(e.target.value);
    //     filterStudentTable(e.target.value, filterName, filterClassSection, filterFather);
    // }

    // const handleFilterName = (e) => {
    //     setFilterName(e.target.value);
    //     filterStudentTable(filterEnrollment, e.target.value, filterClassSection, filterFather);
    // }

    // const handleFilterClassSection = (e) => {
    //     setFilterClassSection(e.target.value);
    //     filterStudentTable(filterEnrollment, filterName, e.target.value, filterFather);
    // }

    // const handleFilterFather = (e) => {
    //     setFilterFather(e.target.value);
    //     filterStudentTable(filterEnrollment, filterName, filterClassSection, e.target.value);
    // }
    const getStudentTotalPayment = (lstPayment) => {
        if (lstPayment && lstPayment.length) {
            return lstPayment.reduce((total, payment) => total + parseInt(payment), 0);
        }
        return 0;
    };

    const handleStudentMonthPaymentChange = async (e, studentId, monthId) => {
        e.preventDefault();
        if (e.target.value < 0)
            return;
        await setLstStudentTransport(prevStudents => {
            return prevStudents.map(student => {
                if (student.studentId === studentId) {
                    const updatedPayment = [...student.lstPayment];
                    updatedPayment[monthId] = (e.target.value ? Number(e.target.value) : 0);
                    return { ...student, lstPayment: updatedPayment };
                }
                return student;
            });
        });
    }

    const savePayment = (mnthId) => {
        // // console.log("savePayment called, mnthId", mnthId);
        let tempLstMonthStudentPayment = lstStudentTransport.map(student => ({
            studentId: student.studentId,
            payment: student.lstPayment[mnthId] ? student.lstPayment[mnthId] : 0
        }));
        // // console.log("tempLstMonthStudentPayment", tempLstMonthStudentPayment);
        const paymentObj = {
            monthId: mnthId + 1,
            lstPayment: tempLstMonthStudentPayment
        };
        // // console.log("paymentObj", paymentObj);
        props.setLoader("block");
        props.setOpacity(0.5);
        saveMonthPaymentSvc(paymentObj, saveMonthPaymentResponse);
    };

    const saveMonthPaymentResponse = (data) => {
        // // console.log("saveMonthPaymentResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            toast.success(obj.message);
        }
        else {
            if (obj.message == 'You are not logged in') {
                toast.error(obj.message);
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                toast.error(obj.message);
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        props.setLoader('none');
        props.setOpacity(1);
    }

    const getMonthTotal = (mnthId) => {
        // // console.log("getMonthTotal called, mnthId", mnthId);
        let total = 0;
        if (lstStudentTransport && lstStudentTransport.length) {
            lstStudentTransport.map(student => {
                total = total + parseInt(student.lstPayment[mnthId]);
            });
        }
        return total;
    }

    const getOverallTotal = () => {
        let total = 0;
        {
            lstMonths.map((month, idx) =>
                total += getMonthTotal(idx)
            )
        }
        return total;
    }



    const getTabIndex = (studIndex, paymentIndex, columnLength) => {
        return paymentIndex * columnLength + studIndex + 1;
    }

    function handleKeyDown(event) {
        // // console.log("handleKeyDown called, event", event);
        //onEnterPress
        if (event.keyCode === 13) {
            // let active = event.currentTarget;
            // let target = document.querySelector('[tabindex="' + (active.tabIndex + 1) + '"]');
            // target.focus();
            event.preventDefault();
            event.currentTarget.select();
        }
    }

    return (
        <div className="col-md-12 padding-remove" style={{ marginTop: "5px" }}>
            <div className="col-sm-12 padding-remove">
                <table id="acceptPayment" className="tableLayout1" >
                    <thead>
                        {/* <tr>
                        <th className="text-center " colSpan={"4"} style={{ width: 200 }}>
                            Student Details
                        </th>
                        <th className='text-center ' colSpan={"12"} width={240}>
                            Months
                        </th>
                    </tr> */}
                        <tr >
                            <th className="text-center" style={{ width: 30 }}>
                                Enrollment
                                {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="text"
                                    className="form-control inp-search text-center"
                                    value={filterEnrollment}
                                    onChange={(e) => handleFilterEnrollmentNo(e.target.value)}
                                    name="Enrollment No"
                                    placeholder="Enrollment"
                                    style={{ flex: '1' }} // Allow input to take remaining space
                                />
                            </div> */}
                            </th>

                            <th className="text-center" style={{ width: 60 }}>
                                Student
                                {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="text"
                                    className="form-control inp-search text-center"
                                    value={filterName}
                                    onChange={(e) => handleFilterName(e.target.value)}
                                    name="Student Name"
                                    placeholder="Student"
                                    style={{ flex: '1' }} // Allow input to take remaining space
                                />
                            </div> */}
                            </th>
                            <th className="text-center" style={{ width: 30 }}>
                                Class-section
                                {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="text"
                                    className="form-control inp-search text-center"
                                    value={filterClassSection}
                                    onChange={(e) => handleFilterClassSection(e.target.value)}
                                    name="Class Section"
                                    placeholder="Class Section"
                                    style={{ flex: '1' }} // Allow input to take remaining space
                                />
                            </div> */}
                            </th>
                            <th className="text-center" style={{ width: 60 }}>
                                Father
                                {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="text"
                                    className="form-control inp-search text-center"
                                    value={filterFather}
                                    onChange={(e) => handleFilterName(e.target.value)}
                                    name="Father Name"
                                    placeholder="Father"
                                    style={{ flex: '1' }} // Allow input to take remaining space
                                />
                            </div> */}
                            </th>
                            {lstMonths && lstMonths.length ?
                                lstMonths.map((month) =>
                                    <th className='text-center' style={{ width: 20 }}>
                                        {month}
                                    </th>
                                )
                                : null
                            }
                            <th className='text-center' style={{ width: 30 }}>
                                Total
                            </th>
                        </tr>
                    </thead>
                </table>
                <div className="tableDiv tableBodyBorder" style={{ maxHeight: "500px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <div >
                        <table className='tableLayout'>
                            <tbody id="acceptPaymentTbl">
                                <>
                                    {
                                        lstStudentTransport && lstStudentTransport.length ?
                                            lstStudentTransport.map((tranportStudent, stdIdx) => (
                                                <tr key={tranportStudent.studentId}
                                                    onClick={(e) => setStudentId(tranportStudent.studentId)}
                                                    className={tranportStudent.studentId === studentId ? "selected" : ""}>
                                                    <td style={{ width: 30 }} className='text-center'>{tranportStudent.enrollmentNo}</td>
                                                    <td style={{ width: 60 }} className='text-left'>{tranportStudent.student}</td>
                                                    <td style={{ width: 30 }} className='text-center'>{tranportStudent.studentClsSection}</td>
                                                    <td style={{ width: 60 }} className='text-left'>{tranportStudent.father}</td>
                                                    <>
                                                        {
                                                            tranportStudent.lstPayment && tranportStudent.lstPayment.length ?
                                                                tranportStudent.lstPayment.map((payment, idx) =>
                                                                    <td key={idx} className='text-center' style={{ width: 20 }}>
                                                                        <input
                                                                            type="number"
                                                                            min="0"
                                                                            className="form-control text-right"
                                                                            // id={tranportStudent.studentId + "-" + idx}
                                                                            tabIndex={getTabIndex(stdIdx, idx, lstStudentTransport.length)}
                                                                            value={(payment && Math.max(0, payment)) ? (payment && Math.max(0, payment)) : ""}
                                                                            placeholder="0"
                                                                            onChange={(e) => handleStudentMonthPaymentChange(e, tranportStudent.studentId, idx)}
                                                                        // disabled={false}
                                                                        />
                                                                    </td>
                                                                )
                                                                :
                                                                lstMonths.map((month) =>
                                                                    <td className='text-center' style={{ width: 20 }}>
                                                                    </td>
                                                                )
                                                        }
                                                    </>
                                                    <td style={{ width: 30 }} className='text-center'>{getStudentTotalPayment(tranportStudent.lstPayment)}</td>
                                                </tr>
                                            ))
                                            : null
                                    }
                                </>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <table className='tableLayout'>
                            <tbody id="acceptPaymentTbl">
                                <tr>
                                    <td style={{ width: 30 }} className='text-center'></td>
                                    <td style={{ width: 60 }} className='text-left'></td>
                                    <td style={{ width: 30 }} className='text-center'></td>
                                    <td style={{ width: 60 }} className='text-center'>Total</td>
                                    {lstMonths.map((month, idx) =>
                                        <td className='text-center' style={{ width: 20 }}>
                                            {getMonthTotal(idx)}
                                        </td>
                                    )}
                                    <td style={{ width: 30 }} className='text-center'>{getOverallTotal()}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 padding-remove" style={{ display: "flex", height: 50, marginTop: "10px" }}>
                <div style={{ width: "40%", display: 'table' }}></div>
                <div style={{ width: "53%", display: 'table' }}>
                    <div style={{ display: 'table-row' }}>
                        {lstMonths.map((month, idx) => (
                            <div key={idx} style={{ display: 'table-cell', width: '20' }}>
                                <button style={{ width: '88%' }} type="button" className="btn btn-info text-center" onClick={(e) => savePayment(idx)}>Save</button>
                            </div>
                        ))}
                    </div>
                </div>
                {/* <div style={{ width: `${30 / (180 + 240 + 30)}%`, display: 'table' }}></div> */}
            </div>

        </div>
    );
}

export default AcceptPaymentTable;