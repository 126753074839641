import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import Header from 'common/js/header/header'
import TopicForm from './topicForm';
import TopicTable from './topicTable';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import $ from 'jquery';

var queryString = require('querystring');
var httpURL = '';
export default class Topic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lstTopics: this.props.lstTopics,
            tokenid: localStorage.getItem('tokenid'),
            screenId: 29,
            chapterId: this.props.chapterId,
            flg: true,
            topicData: {}
        }
    }
    componentDidMount = () => {
        // // console.log(this.props.lstTopics);
        // // console.log(this.props.chapterId);
        this.setState({ lstTopics: this.props.lstTopics, chapterId: this.props.chapterId })
        // this.getTopicsList();
    }
    // addReady = () => {
    //     $('#addForm').trigger('reset');
    //     $('#tableBody tr').removeClass('selected');
    //     this.setState({
    //         flg: true,
    //         syllabusId: 0
    //     });
    // }

    getTopicsList = () => {
        this.setState({ loader: 'block', opacity: 0.5 });
        axios({
            url: httpURL + '/sms/syllabus/chapter/topic/list',
            method: "POST",
            params: { screenId: this.state.screenId, chapterId: this.state.chapterId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                this.setState({
                    lstTopics: response.data.lstTopics,
                })
            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error("Service failed " + error);
        });
    }

    addTopics = ({ topicCode, topicName }) => {

        var topic = {};

        topic = {
            'topicId': 0, 'topicName': topicName, 'chapterId': 0, 'topicOrder': null, 'topicCode': topicCode,
        }
        // // console.log(topic);

        axios.post(httpURL + '/sms/syllabus/chapter/topic/save',
            queryString.stringify({ 'screenId': this.state.screenId, 'topic': JSON.stringify(topic) }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getTopicsList();


            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });
    }
    rowClick = (topicId) => {

        // // console.log(this.getTopicById(topicId));
        this.setState({ topicId: topicId, topicData: this.getTopicById(topicId) });
        // this.props.getTopicList(topicId);
    }
    getTopicById = (topicId) => {
        var lstTopics = this.state.lstTopics;
        for (var i = 0; i < lstTopics.length; i++) {
            if (lstTopics[i].topicId === topicId)
                return lstTopics[i];
        }
    }
    render() {
        httpURL = lstModules['syllabus'].url + ":" + lstModules['syllabus'].port;
        return (
            <div className="col-md-12 col-centered">
                <div className="col-md-6">
                    <TopicTable lstTopics={this.state.lstTopics} key={this.state.chapterId}
                        rowClick={this.rowClick}
                    />
                </div>
                <div className="col-xs-1 ">
                    <span id="addReadyId" title="Add" onClick={this.addReady} className="addIcon glyphicon glyphicon-plus-sign"></span>
                </div>
                <div className="col-md-5">
                    <TopicForm addTopics={this.addTopics}
                        key={this.state.topicId}
                        topicData={this.state.topicData}
                    />
                </div>
            </div>
        )
    }
}