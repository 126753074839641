import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import $ from 'jquery';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
var httpURL = '';
var queryString = require('querystring');
var screenId = 34;

export default class CampaignForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            campaign: "",
            remarks: "",
            startDt: null,
            endDt: null,
            tokenid: localStorage.getItem('tokenid')
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.campaignId !== this.props.campaignId) {
            if (this.props.campaignId !== 0) {
                this.getObjData(this.props.campaignId);
            }
            else {
                this.setState({
                    campaign: "",
                    remarks: "",
                    startDt: null,
                    endDt: null,
                })
            }
        }
    }
    setAllState = () => {
        if (this.props.campaignId === 0) {
            this.setState({
                campaign: "",
                remarks: "",
                startDt: null,
                endDt: null,
            })
        }
    }
    getObjData = (campaignId) => {
        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/campaign/dtls',
            queryString.stringify({ campaignId, screenId }),
            {
                headers: { tokenid: this.state.tokenid },
                withCredentials: true
            }
        ).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                var startDt = null;
                var endDt = null;
                var dataObj = response.data.Campaign;
                if (dataObj.startDt === "00-00-0000" || dataObj.startDt === null ||
                    dataObj.startDt === undefined) {
                    this.setState({
                        startDt: "",
                    });
                }
                else {
                    var date = dataObj.startDt;
                    date = date.split('-');
                    var setDate = date.reverse().join();
                    setDate = setDate.replace(/,/g, '-');
                    startDt = new Date(setDate);
                }
                if (dataObj.endDt === "00-00-0000" || dataObj.endDt === null ||
                    dataObj.endDt === undefined) {
                    this.setState({
                        endDt: "",
                    });
                }
                else {
                    var date = dataObj.endDt;
                    date = date.split('-');
                    var setDate = date.reverse().join();
                    setDate = setDate.replace(/,/g, '-');
                    endDt = new Date(setDate);
                }
                this.setState({
                    campaign: dataObj.campaign,
                    remarks: dataObj.remarks,
                    startDt: startDt,
                    endDt: endDt,
                })
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.startDt === null) {
            toast.warning("Please Enter Start Date");
            $('#startDt').focus()
            return false
        }
        if (this.state.endDt === null) {
            toast.warning("Please Enter End Date");
            $('#endDt').focus()
            return false
        }
        if (this.state.campaign === "") {
            toast.warning("Please Enter Campaign Name");
            $('#campaign').focus()
            return false
        }
        var campaign = this.state.campaign;
        var remarks = this.state.remarks;
        var startDt = $('#startDt').val();
        var endDt = $('#endDt').val();
        var objToSave = {
            campaign,
            remarks,
            startDt,
            endDt
        }
        this.props.saveCampaign(objToSave);
        this.setAllState();
    }

    handleChangeStartDt = (date) => {
        this.setState({ startDt: date, });
    }
    handleChangeEndDt = (date) => {
        this.setState({ endDt: date });
    }
    handleInputChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        httpURL = lstModules['campaign'].url + ":" + lstModules['campaign'].port;
        return (
            <div>
                <ToastContainer autoClose={3000} />
                <div className="col-xs-12 padding-remove" >
                    <form onSubmit={this.handleSubmit} id="addForm" className="form-horizontal formWrapper">
                        <div className="form-group">
                            <label className="control-label col-sm-4" >Start Date<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                            <div className="col-sm-7 padding-remove">
                                <DatePicker id="startDt"
                                    className='form-control'
                                    placeholderText="Enter From Date"
                                    selected={this.state.startDt}
                                    onChange={this.handleChangeStartDt}
                                    dateFormat="dd-MM-yyyy"
                                    dropdownMode="select"
                                    autoComplete='off'
                                    showMonthDropdown
                                    showYearDropdown
                                    adjustDateOnChange
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-4" >End Date<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                            <div className="col-sm-7 padding-remove">
                                <DatePicker id="endDt"
                                    className='form-control'
                                    placeholderText="Enter To Date"
                                    selected={this.state.endDt}
                                    onChange={this.handleChangeEndDt}
                                    dateFormat="dd-MM-yyyy"
                                    dropdownMode="select"
                                    autoComplete='off'
                                    showMonthDropdown
                                    showYearDropdown
                                    adjustDateOnChange
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-4" >Campaign<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                            <div className="col-sm-7 padding-remove">
                                <input autoComplete="off" name="campaign" id="campaign"
                                    type="text" className="form-control "
                                    value={this.state.campaign}
                                    onChange={this.handleInputChange}
                                    placeholder="Enter Campaign" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-sm-4" >Remarks</label>
                            <div className="col-sm-7 padding-remove">
                                <textarea autoComplete="off" name="remarks"
                                    value={this.state.remarks}
                                    onChange={this.handleInputChange}
                                    id="remarks" type="text" className="form-control "
                                    placeholder="Enter Remarks" />
                            </div>
                        </div>
                        <div className="form-group" >
                            <div className="col-sm-12 text-center userButtonWrap" >
                                <button type="submit" style={{ marginTop: 20 }} title="Save" className="btn btn-info">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
