import React from 'react';
import CELL_TYPES from '../constants/cellType';

export default function SmsTd(props) {

    return (
        <td style={{ width: props.br }} className={(props.cellType === CELL_TYPES.NUMBER ? 'text-right' : 'text-left' + ' ' + props.className && props.className)}>
            {props.val}
        </ td>
    );
}
