import React, { useState, useEffect } from 'react'
import { getAreaSummaryList } from './AreaSummarySvc'
import "../css/feedback.css";
var arr = []
var tmpSelLstAreaId = []
const AreaSummary = (props) => {
    const [lstAreaSummary, setLstAreaSummary] = useState([])
    useEffect(() => {
        // // console.log(props.selLstAreaId)
        getAreaSummaryList(cbAreaSummaryResponse)
    }, [])

    function cbAreaSummaryResponse(data) {
        setLstAreaSummary(data.lstAreaSummary)
        if(!!data.lstAreaSummary){
            for (var i = 0; i < data.lstAreaSummary.length; i++) {
                arr[i] = 0;
                tmpSelLstAreaId = []
            }
        }
    }
        
        function clickArea(pIndx, pAreaId) {
            arr[pIndx] = arr[pIndx] + 1;
            if (arr[pIndx] % 2 === 0)
            tmpSelLstAreaId.splice(tmpSelLstAreaId.indexOf(pAreaId), 1)
        else {
            if (!tmpSelLstAreaId.includes(pAreaId))
                tmpSelLstAreaId.push(pAreaId)
        }
        props.clickArea(tmpSelLstAreaId)

    }
    return (
        <>
            {lstAreaSummary && lstAreaSummary.map((obj, idx) => {
                var isSelected = !!props.selLstAreaId && props.selLstAreaId.includes(obj.areaId) && arr[idx] % 2 !== 0;
                return <div key={obj.areaId} className={"col-sm-4 padding-remove"} style={{ marginTop: 0 }} onClick={() => clickArea(idx, obj.areaId)}>
                    <div className="circle_area_summary col-sm-12 col-centered" style={{ background: isSelected ? '#4a86e8' : '', color: isSelected ? '#fff' : '' }}>
                        <div className="div_area_count col-sm-12 col-centered" >{obj.count}</div>
                    </div>
                    <div>{obj.area}</div>
                </div>
            })}
        </>
    )
}

export default AreaSummary