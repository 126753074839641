import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from "react-toastify";
import DatePicker from "react-datepicker";


import "common/css/master.css";
import { dtToDMY, dmyToDate } from 'common/functions/dateUtil';

import { saveCertificateSvc } from './bonafideSvc';



const BonafideForm = (props) => {
    const { certificateId, setCertificateId, studentId, studentName, clsSection, setEditLoader, setEditOpacity, openCertificatePDf, studentDtlsObj, lstApprovalAuthority, flgPrinted } = props;

    const [reqestedDt, setRequestedDt] = useState(null);
    const [approvedBy, setApprovedBy] = useState(0);
    const [approvedDt, setApprovedDt] = useState(null);
    const [notes, setNotes] = useState("");
    const [internalRemarks, setInternalRemarks] = useState("");
    const [printDt, setPrintDt] = useState("");
    const [certStatusId, setCertStatusId] = useState(0);

    useEffect(() => {
        // // console.log("BonafideForm called");
        if (!!certificateId) {
            setApprovedBy(studentDtlsObj.approveBy);
            setApprovedDt(dmyToDate(studentDtlsObj.approveDt));
            setRequestedDt(dmyToDate(studentDtlsObj.reqDt));
            setInternalRemarks(studentDtlsObj.remarksInternal);
            setNotes(studentDtlsObj.remarksPrint);
            setPrintDt(studentDtlsObj.printDt);
            setCertStatusId(studentDtlsObj.certStatusId);
        } else {
            setApprovedBy(0);
            setApprovedDt(null);
            setRequestedDt(null);
            setInternalRemarks("");
            setNotes("");
            setPrintDt(null);
            setCertStatusId(0);
        }
    }, [studentId, certificateId, studentDtlsObj]);

    const saveCertificate = (nPrintDt) => {
        const reqDt = dtToDMY(reqestedDt);
        const approveDt = dtToDMY(approvedDt) || null;
        // // console.log("saveCertificate called");
        // if (!certificateId || certificateId <= 0) {
        //     // toast.warn('Certificate ID is required and must be a positive integer.');
        //     return;
        // }

        if (!studentId || studentId <= 0) {
            toast.warn('Student ID is required and must be a positive integer.');
            return;
        }

        if (!reqDt || !validateDateFormat(reqDt)) {
            toast.warn('Request Date must be in dd-mm-yyyy format.');
            return;
        }

        // Validate conditional fields
        if (approvedBy && (!approveDt || !validateDateFormat(approveDt))) {
            toast.warn('Approved Date is required and must be in dd-mm-yyyy format when Approved By is filled.');
            return;
        }

        // Validate optional fields length
        if (notes && notes.length > 200) {
            toast.warn('Notes cannot exceed 200 characters.');
            return;
        }

        if (internalRemarks && internalRemarks.length > 200) {
            toast.warn('Internal Remarks cannot exceed 200 characters.');
            return;
        }

        const bonafideCertObj = {
            certificateId,
            "studentId": studentId,
            reqDt,
            "approveBy": approvedBy,
            "approveDt": approvedBy ? approveDt : "",
            "remarksPrint": notes,
            "remarksInternal": internalRemarks,
        }
        // // console.log("bonafideCertObj", bonafideCertObj);
        setEditLoader("block");
        setEditOpacity(0.5);
        if (nPrintDt) {
            bonafideCertObj.printDt = nPrintDt;
            saveCertificateSvc(bonafideCertObj, savePrintCertificateResponse);
        } else {
            saveCertificateSvc(bonafideCertObj, saveCertificateResponse);
        }
    }

    const validateDateFormat = (dateString) => {
        const regex = /^\d{2}-\d{2}-\d{4}$/; // dd-mm-yyyy
        return regex.test(dateString);
    };

    const saveCertificateResponse = (data) => {
        // // console.log("saveCertificateResponse called, data", data);
        if (data.status === "Success") {
            if (!certificateId) {
                setCertificateId(data.certificateId);
            }
        } else if (data.status === "Failure") {
            handleServiceError(data.message);
        } else {
            toast.error("Service failed " + data.error);
        }
        setEditLoader("none");
        setEditOpacity(1);
    }
    const savePrintCertificateResponse = (data) => {
        // // console.log("saveCertificateResponse called, data", data);
        if (data.status === "Success") {
            setCertificateId(data.certificateId);
            openCertificatePDf(certificateId);
        } else if (data.status === "Failure") {
            handleServiceError(data.message);
        } else {
            toast.error("Service failed " + data.error);
        }
        setEditLoader("none");
        setEditOpacity(1);
    }

    const savePrintCertificate = () => {
        // // console.log("savePrintCertificate called");
        if (!flgPrinted) {
            let dt = new Date();
            dt = dtToDMY(dt);
            saveCertificate(dt);
        } else {
            openCertificatePDf();
        }

    }

    const deleteCertificate = () => {
        // // console.log("deleteCertificate called");
    }

    const handleApprovedByChange = (pApprovedBy) => {
        // // console.log("handleApprovedChange called, pApprovedBy", pApprovedBy);
        // // console.log("lstApprovalAuthority", lstApprovalAuthority);
        setApprovedBy(pApprovedBy);
    }

    const handleServiceError = (svcMsg) => {
        if (svcMsg === "You are not Logged In") {
            toast.error("You are not logged in. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else if (svcMsg === "Server Timed Out, Login Again") {
            toast.error("Server timed out. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else {
            toast.error(svcMsg);
        }
    };



    return (
        <div className="col-sm-12 col-xs-12 padding-remove">
            <form
                id="certificateAddForm"
                className='form form-horizontal'
                style={{ height: "85vh", width: '90%', overflowX: 'hidden', margin: '0 auto', padding:"20px", background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
                <div className="form-group col-md-12 padding-remove">
                    <label className="control-label col-md-4 padding-remove">
                        Name:
                    </label>
                    <div className='col-sm-8 '>
                        <input
                            type="text"
                            className="form-control"
                            defaultValue={studentName ? studentName : ""}
                            placeholder='Student Name'
                            disabled
                        ></input>
                    </div>
                </div>
                <div className="form-group col-md-12 padding-remove">
                    <label className="control-label col-md-4 padding-remove">
                        Class:
                    </label>
                    <div className='col-sm-6 '>
                        <input
                            type="text"
                            className="form-control"
                            defaultValue={clsSection ? clsSection : ""}
                            placeholder='Class - section'
                            disabled
                        ></input>
                    </div>
                </div>
                <div className="form-group col-md-12 padding-remove">
                    <label className="control-label col-md-4 padding-remove">
                        Request Date:
                    </label>
                    <div className="col-sm-6 ">
                        <DatePicker
                            className="form-control"
                            placeholderText="Request Date"
                            dateFormat="dd-MM-yyyy"
                            dropdownMode="select"
                            selected={reqestedDt}
                            onChange={(date) => setRequestedDt(date)}
                            autoComplete="off"
                            showMonthDropdown
                            showYearDropdown
                            adjustDateOnChange
                        />
                    </div>
                </div>
                <div className="form-group col-md-12 padding-remove">
                    <label className="control-label col-md-4 padding-remove">
                        Approved By:
                    </label>
                    <div className="col-sm-6 ">
                        <select autoComplete="off" name="Approved by"
                            type="text" className="form-control "
                            value={approvedBy}
                            onChange={(e) => handleApprovedByChange(e.target.value)}
                        >
                            <option value="0" >Approved By</option>
                            {lstApprovalAuthority && lstApprovalAuthority.length ?
                                lstApprovalAuthority.map((obj) => (
                                    <option key={obj.userId} value={obj.userId} >{obj.userName}</option>
                                )) : null
                            }
                        </select>
                    </div>
                </div>
                {
                    approvedBy != 0 ?
                        <div className="form-group col-md-12 padding-remove">
                            <label className="control-label col-md-4 padding-remove">
                                Apporved Date:
                            </label>
                            <div className="col-sm-6 ">
                                <DatePicker
                                    className="form-control"
                                    placeholderText="Approved Date"
                                    dateFormat="dd-MM-yyyy"
                                    dropdownMode="select"
                                    selected={approvedDt}
                                    onChange={(date) => setApprovedDt(date)}
                                    autoComplete="off"
                                    showMonthDropdown
                                    showYearDropdown
                                    adjustDateOnChange
                                />
                            </div>
                        </div>
                        : null
                }
                <div className="form-group col-md-12 padding-remove">
                    <label className="control-label col-md-4 padding-remove">
                        Notes:
                    </label>
                    <div className="col-sm-8 ">
                        <textarea
                            className="form-control"
                            rows="4"
                            placeholder="Notes"
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                        ></textarea>
                    </div>
                </div>
                <div className="form-group col-md-12 padding-remove">
                    <label className="control-label col-md-4 padding-remove">
                        Internal Remarks:
                    </label>
                    <div className="col-sm-8 ">
                        <textarea
                            className="form-control"
                            rows="4" // Adjust this number to set the number of visible lines
                            placeholder="Internal Remarks"
                            value={internalRemarks}
                            onChange={(e) => setInternalRemarks(e.target.value)}
                        ></textarea>

                    </div>
                </div>
                <div className='col-sm-8  pull-right padding-remove' style={{ height: "50px" }}>
                    <div className="col-sm-6 col-md-6 form-group padding-remove">
                        <button type="button" className="btn btn-info " onClick={(e) => saveCertificate(null)} disabled={flgPrinted}>Submit</button>
                    </div>
                    <div className="col-sm-6 col-md-6 form-group padding-remove">
                        <button type="button" className="btn btn-info " onClick={(e) => savePrintCertificate(e)} disabled={!certificateId}>Print</button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default BonafideForm;


