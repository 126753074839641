import axios from 'axios'
import { toast } from 'react-toastify'
import lstModules from 'common/json/lstModules.json';
var httpURL = lstModules['fees'].url + ":" + lstModules['fees'].port;
var tokenid = localStorage.getItem("tokenid");
function saveLabelSvc(pLabelObj, callBack) {

    axios({
        url: httpURL+"/sms/fee/labels/save",
        method: "POST",
        params: { label: JSON.stringify(pLabelObj) },
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {

        var data = response.data
        callBack(data);
    }).catch((error) => { 
        console.error(error) 
    });
}

function getLabelListSvc(callBack)
{
    axios(
        {
            url: httpURL+"/sms/fee/labels/details",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)

        }
    ).catch((error) => {
        console.error(error)
    });
}
export {saveLabelSvc};
export {getLabelListSvc}