import React, { useEffect, useState } from 'react';

const MonthList = (props) => {
    const [mnthId, setMnthId] = useState(0);
    const lstMonth = [
        { "monthId": 1, "month": "Jan" },
        { "monthId": 2, "month": "Feb" },
        { "monthId": 3, "month": "Mar" },
        { "monthId": 4, "month": "Apr" },
        { "monthId": 5, "month": "May" },
        { "monthId": 6, "month": "Jun" },
        { "monthId": 7, "month": "Jul" },
        { "monthId": 8, "month": "Aug" },
        { "monthId": 9, "month": "Sep" },
        { "monthId": 10, "month": "Oct" },
        { "monthId": 11, "month": "Nov" },
        { "monthId": 12, "month": "Dec" }
    ];


    useEffect(() => {
        if (!props.monthId) {
            const currentMonth = new Date().getMonth();
            const currentMonthData = lstMonth.find((month) => new Date(0, month.monthId).getMonth() === currentMonth);
            let currentMonthId = 0;
            if (currentMonthData) {
                currentMonthId = (currentMonthData.monthId + 1) ;
            }
            setMnthId(currentMonthId);
            props.handleMonthChange(currentMonthId);
        } else {
            setMnthId(props.monthId);
        }
    }, [props.monthId]);

    return (
        <div className='col-md-6 col-sm-8 col-xs-12 padding-remove'>
            <select autoComplete="off" name="Month"
                type="text" className="form-control "
                value={mnthId}
                onChange={(e) => props.handleMonthChange(e.target.value)}
            >
                <option value={0} >Month</option>
                {lstMonth.map((month) => (
                    <option key={month.monthId} value={month.monthId} >{month.month}</option>
                ))}
            </select>
        </div>
    );
}

export default MonthList;
