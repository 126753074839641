import { fabClasses } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from 'react-toastify'

import { getClassListSvc } from "./classFeeSvc";


const ClassFeeTable = (props) => {
    const { openSlider, sendFeebookClassSection, sendFeebook, setLoader, setOpacity } = props;

    // const [lstFeeHead, setLstFeeHead] = useState([]);
    const [lstFeeCls, setLstFeeCls] = useState([]);
    const [lstFeeClass, setLstFeeClass] = useState([]);
    const [sectionId, setSectionId] = useState(0);
    const [filterClass, setFilterClass] = useState("");
    const [filterSection, setFilterSection] = useState("");

    const heads=[
        ['ClassSectionId', 0, 'no-display', 1, 100],
        ['Class', 40, '', '2', '1', 100],
        ['Section', 40, '', '2', '1', 100],
        ['Students', 40, 'br', '', '3', 300],
        ['Action', 40, '', '2', '1', 100]
      ];

    useEffect(() =>{
        // // console.log("classFeeTable called ");
        getClassFeeList();
    },[]);
    
    const getClassFeeList = () => {
        // // console.log("getClassFeeList called from classFeeTable  ");
        setLoader('block');
        setOpacity(0.5);
        getClassListSvc(getClassFeeListResponse);
    }
    
    const getClassFeeListResponse = (data) => {
        let obj = data;
        // // console.log("getClassFeeListResponse called from classFeeTable, obj",obj);
        if (obj.status === 'Success') {
            setLstFeeClass( obj.lstFeeClass);
            setLstFeeCls(obj.lstFeeClass);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader('none');
        setOpacity(1);
    }

    const handleFilterClass = (e) => {
        setFilterClass(e.target.value);
        filterClassFeeTable(e.target.value, filterSection);
    }

    const handleFilterSection = (e) => {
        setFilterSection(e.target.value);
        filterClassFeeTable(filterClass, e.target.value);
    }

    const filterClassFeeTable = (fClass, fSection) => {
        let filterClassFeeList = [];
        filterClassFeeList = lstFeeClass.filter(clsFee =>
            clsFee.className.toString().toLowerCase().includes(fClass.toLowerCase()));

        filterClassFeeList = filterClassFeeList.filter(clsFee =>
            clsFee.section.toString().toLowerCase().includes(fSection.toLowerCase()));

        setLstFeeCls(filterClassFeeList);
    }

    return (
        <div className='col-xs-12'>
            <div>
                <table className='tableLayout1' id='feeClassSetupHeadTable' style={{ width: 'calc(100%-17)', }} >
                    <thead>
                        <tr>
                            <th rowSpan={heads[1][3]} colSpan={heads[1][4]} width={heads[1][5]}><input type="text" className="inp-search form-control" value={filterClass} onChange={(e) => handleFilterClass(e)} placeholder="Class" /></th>
                            <th rowSpan={heads[2][3]} colSpan={heads[2][4]} width={heads[2][5]}><input type="text" className="inp-search form-control" value={filterSection} onChange={(e) => handleFilterSection(e)} placeholder="Section" /></th>
                            <th rowSpan={heads[3][3]} colSpan={heads[3][4]} width={heads[3][5]}>Students</th>
                            <th rowSpan={heads[4][3]} colSpan={heads[4][4]} width={heads[4][5]}>Actions</th>
                        </tr>
                        <tr>
                            <th width="100">Total</th>
                            <th width="100">Setup</th>
                            <th width="100">Pending</th>
                        </tr>
                    </thead >
                </table >
            </div >
            <div className='tableDiv1' style={{ height: 400, border: '1px solid #ccc', overflowY: 'scroll' }}>
                <table id='feeClassSetupTable' className='tableLayout'>
                    <tbody id='feeClassSetupTableBody'>
                        {lstFeeCls && lstFeeCls.map((item, index) => {
                            return (
                                (<tr key={item.sectionId}
                                    id={item.sectionId} 
                                    onClick={() => setSectionId(item.sectionId)}
                                    className={item.sectionId === sectionId ? "selected" : ""}
                                    >
                                        <td>{item.className}</td>
                                        <td>{item.section}</td>
                                        <td className='text-right'>{item.countStudents}</td>
                                        <td className='text-right'>{item.countSetupDone}</td>
                                        <td className='text-right'>{item.countSetupPending}</td>

                                        <td className='text-center'>
                                            <span
                                                className='glyphicon glyphicon-edit'
                                                style={{ cursor: 'pointer' }}
                                                title='Setup Fee'
                                                onClick={() => {
                                                    openSlider( item['sectionId'], item['classId'] )
                                                }}
                                            ></span>
                                            <span
                                                className='glyphicon glyphicon-send'
                                                title='Associate Fee For All Students'
                                                onClick={() => {
                                                    sendFeebook(item.sectionId)
                                                }}
                                            ></span>
                                            <span className="glyphicon glyphicon-envelope" title="Send Feebook" onClick={() => sendFeebookClassSection(item.sectionId)}
                                            ></span>

                                        </td>
                                    </tr>
                                )
                            )
                        })}
                    </tbody>
                </table>

            </div>
        </div >
    );
}

export default ClassFeeTable;

