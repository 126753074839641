import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import "common/css/master.css";
import { getCommunicationDtlsSvc, saveCommunicationIdSvc } from "./studentCommunicationSvc";


const StudentCommunication = (props) => {
    const { setLoader, setOpacity, studentId, flgDisabled } = props;
    const [communicationId, setCommunicationId] = useState(0);

    

    useEffect(() => {
        // // console.log("StudentCommunication called, studentId");
        if(studentId != 0){
            getCommunicationDtls(studentId);
        }
        
    }, [studentId, flgDisabled]);

    const getCommunicationDtls = (sId) => {
        // // console.log("getCommunicationDtls called");
        setLoader("block");
        setOpacity(0.5);
        getCommunicationDtlsSvc(sId, communicationIdResponse);
    }
    
    const communicationIdResponse = (data) => {
        // // console.log("communicationIdResponse called");
        const obj = data;
        if (obj.status === 'Success') {
            setCommunicationId(data.commDtls.personId);
        } else {
            handleServiceError(obj.message);
        }
        setLoader("none");
        setOpacity(1);
    }

    const saveCommunication = (e) => {
        e.stopPropagation();
        // // console.log("saveCommunication called");

        setLoader("block");
        setOpacity(0.5);
        saveCommunicationIdSvc(studentId, communicationId, saveCommunicationResponse);
    }
    
    const saveCommunicationResponse = (data) => {
        // // console.log("saveCommunicationResponse called");
        const obj = data;
        if (obj.status === 'Success') {
            toast.success(obj.message);
        } else {
            handleServiceError(obj.message);
        }
        setLoader("none");
        setOpacity(1);
    }



    const handleServiceError = (svcMsg) => {
        if (svcMsg === "You are not Logged In") {
            toast.error("You are not logged in. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else if (svcMsg === "Server Timed Out, Login Again") {
            toast.error("Server timed out. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else {
            toast.error(svcMsg);
        }
    };


    return (
        <div className="col-sm-12 col-xs-12 padding-remove">
             <form id="commForm" className="col-sm-12 form-inline">
                    <div className="col-sm-12 section1">
                      <div className="">
                        <label className="control-label col-sm-9">
                          Communication
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-12 shadowShow  padding-remove">
                      <div
                        className="col-sm-12 form-group categoryWrap"
                        style={{ paddingLeft: 50 + "px" }}
                      >
                        {/* <div
                          className="col-sm-2 form-group radioButtonsWrap"
                          style={{ width: "15%" }}
                        >
                          <input
                            type="radio"
                            id="paidByStudent"
                            name="paidBy"
                            value="10"
                            className="form-check-input"
                            checked={communicationId == 10}
                            onChange={(e) => setCommunicationId(e.target.value)}
                          />
                          <label className="" htmlFor="Student">
                            Student
                          </label>
                        </div> */}
                        <div
                          className="col-sm-2 form-group radioButtonsWrap"
                          style={{ width: "15%", marginLeft: 0 }}
                        >
                          <input
                            type="radio"
                            id="paidByFather"
                            name="paidBy"
                            value="20"
                            className="form-check-input"
                            checked={communicationId == 20}
                            disabled={flgDisabled}
                            onChange={(e) => setCommunicationId(e.target.value)}
                          />
                          <label className="" htmlFor="Father">
                            Father
                          </label>
                        </div>

                        <div
                          className="col-sm-2 form-group radioButtonsWrap"
                          style={{ width: "15%", marginLeft: 0 }}
                        >
                          <input
                            type="radio"
                            id="paidByMother"
                            name="paidBy"
                            value="30"
                            className="form-check-input"
                            checked={communicationId == 30}
                            disabled={flgDisabled}
                            onChange={(e) => setCommunicationId(e.target.value)}
                          />
                          <label className="" htmlFor="Mother">
                            Mother
                          </label>
                        </div>
                        <div
                          className="col-sm-2 form-group radioButtonsWrap"
                          style={{ width: "15%", marginLeft: 0 }}
                        >
                          <input
                            type="radio"
                            id="paidByGuardian"
                            name="paidBy"
                            value="40"
                            className="form-check-input"
                            checked={communicationId == 40}
                            disabled={flgDisabled}
                            onChange={(e) => setCommunicationId(e.target.value)}
                          />
                          <label className="" htmlFor="Guardian">
                            Guardian
                          </label>
                        </div>
                        {/* <div
                          className="col-sm-2 form-group radioButtonsWrap"
                          style={{ width: "15%", marginLeft: 0 }}
                        >
                          <input
                            type="radio"
                            id="paidByDonors"
                            name="paidBy"
                            value="50"
                            className="form-check-input"
                            checked={communicationId == 50}
                            onChange={(e) => setCommunicationId(e.target.value)}
                          />
                          <label className="" htmlFor="Donors">
                            Donors
                          </label>
                        </div> */}
                        {/* <div
                          className="col-sm-2 form-group radioButtonsWrap"
                          style={{ width: "15%", marginLeft: 0 }}
                        >
                          <input
                            type="radio"
                            id="paidByOthers"
                            name="paidBy"
                            value="60"
                            className="form-check-input"
                            checked={communicationId == 60}
                            onChange={(e) => setCommunicationId(e.target.value)}
                          />
                          <label className="" htmlFor="Others">
                            Others
                          </label>
                        </div> */}
                        <div className="col-sm-2 text-center pull-right">
                          <button
                            type="button"
                            onClick={(e) => saveCommunication(e)}
                            className="btn btn-info"
                            disabled={flgDisabled}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
        </div>
    );
}
export default StudentCommunication;