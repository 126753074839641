import React, { useState, useEffect } from 'react'
import { sendCommunicationSvc} from "./commSvc";
import { toast, ToastContainer } from "react-toastify";

import {Channel} from './Channel'

const CommTable = props => {
  const [loader, setLoader] = useState('none')

  const [communicationId, setCommunicationId] = useState(props.communicationId)
  const [lstCommunication, setLstCommunication] = useState([])

  useEffect(() => {
    setLstCommunication(props.lstCommunication)
  }, [props])

  function sendCommunication(commId) {
    sendCommunicationSvc(commId, cbSendCommunicationResponse);
  }
  function cbSendCommunicationResponse(data) {
    setLoader("none");
    var obj = data;
    if (obj.SvcStatus === "Success") {
      toast.success(data.SvcMsg);

      props.getCommunicationList();
    } else if (obj.SvcStatus === "Failure") {
      if (obj.SvcMsg === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.SvcMsg === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.SvcMsg);
      }
    } else {
      toast.error("Service failed " + obj);
    }
  }

  const colgrp = (
    <colgroup>
      <col width='15%'></col>
      <col width='25%'></col>
      <col width='10%'></col>
      <col width='10%'></col>
      {/* <col width='10%'></col> */}
      <col width='8%'></col>
      <col width='10%'></col>
      <col width='8%'></col>
      <col width='8%'></col>
      <col width='8%'></col>
      <col width='15%'></col>
      <col width='15%'></col>
      <col width='15%'></col>
    </colgroup>
  )
  return (
    <div style={{ pointerEvents: loader === 'none' ? 'auto' : 'none' }}>
      <table className='tableLayout1'>
        {colgrp}
        <thead>
          <tr>
            <th>Date</th>
            <th>Title</th>
            <th>Level</th>
            <th>Receiver</th>
            {/* <th>Channel</th> */}
            <th>App</th>
            <th>Whatsapp</th>
            <th>Sms</th>
            <th>Email</th>
            <th>IVR</th>
            <th>Saved On</th>
            <th>Sent On</th>
            <th>Action</th>
          </tr>
        </thead>
      </table>
      <div className='tablebodyWrapper tableBodyBorder'>
        <table className='tableLayout'>
          {colgrp}
          <tbody>
            {lstCommunication.map((obj, idx) => {
              return (
                <tr
                  key={'comm_' + idx}
                  id={obj.commId}
                  onClick={e => {e.preventDefault();props.setCommunicationId(obj.commId);}}
                  className={
                    obj.commId === props.communicationId ? 'selected' : ''
                  }
                >
                  <td className='no-display'>{obj.commId}</td>
                  <td>{obj.commDt}</td>
                  <td className='text-left'>{obj.title}</td>
                  <td className='text-left'>{obj.level}</td>
                  <td className='text-left'>{obj.receiver}</td>
                  {/* <td className='text-left'>{obj.channel}</td> */}
                  <td className='text-center' onClick={(e)=>{e.stopPropagation(); props.setChannelId(Channel.NOTIF);props.openStudentSlider(obj.commId)}}>
                    {obj.flgAppNotification ? (
                      <span className='glyphicon glyphicon-eye-open'></span>
                    ) : (
                      <span className='glyphicon'></span>
                    )}
                  </td>
                  <td className='text-center' onClick={(e)=>{e.stopPropagation(); props.setChannelId(Channel.WHATSAPP);props.openStudentSlider(obj.commId)}}>
                    {obj.flgWhatsapp ? (
                      <span className='glyphicon glyphicon-eye-open'></span>
                    ) : (
                      <span className='glyphicon'></span>
                    )}
                  </td>
                  <td className='text-center' onClick={(e)=>{e.stopPropagation(); props.setChannelId(Channel.SMS);props.openStudentSlider(obj.commId)}}>
                    {obj.flgSms ? (
                      <span className='glyphicon glyphicon-eye-open'></span>
                    ) : (
                      <span className='glyphicon'></span>
                    )}
                  </td>
                  <td className='text-center' onClick={(e)=>{e.stopPropagation(); props.setChannelId(Channel.EMAIL);props.openStudentSlider(obj.commId)}}>
                    {obj.flgEmail ? (
                      <span className='glyphicon glyphicon-eye-open'></span>
                    ) : (
                      <span className='glyphicon'></span>
                    )}
                  </td>
                  <td className='text-center' onClick={(e)=>{e.stopPropagation(); props.setChannelId(Channel.IVR);props.openStudentSlider(obj.commId)}}>
                    {obj.flgIvr ? (
                      <span className='glyphicon glyphicon-eye-open'></span>
                    ) : (
                      <span className='glyphicon'></span>
                    )}
                  </td>
                  <td className='text-center'>{obj.savedDttm}</td>
                  <td className='text-center'>{obj.sentDttm}</td>
                  <td className='text-center'>
                    <span
                      onClick={e => props.openCommunicationSlider(obj.commId)} title="View/Edit Communication"
                      className='glyphicon glyphicon-edit'
                    ></span>
                    <span
                      onClick={e =>
                        props.deleteCommunication(obj.commId, obj.templateId)
                      } title="Delete Communication"
                      className='glyphicon glyphicon-trash'
                    ></span>
                     <span style={{cursor:!obj.sentDttm ? "pointer":"not-allowed"}}
                      onClick={e => sendCommunication(obj.commId)
                      } title="Send Communication"
                      className='glyphicon glyphicon-send'
                    ></span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default CommTable
