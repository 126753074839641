import React, { useState, useEffect, useRef } from 'react'
import { toast, ToastContainer } from 'react-toastify';

import { getLogoSvc, getPrinciSignSvc, getTermReportSvc } from './termReportSvc';
import TermReportPdf from './pdf/termReportPDF';
import termReportCardData from "./termReportCard.json";



const TermReportSlider = (props) => {
    const { sectionId, termId, term, syllabusId, closeSlider } = props;

    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [termReportCard, setTermReportCard] = useState({});
    const [logoUrl, setLogoUrl] = useState('');
    const [principalSign, setPrincipalSign] = useState("");


    useEffect(() => {

        // // console.log("termReportSlider called, term passed", term);
        // setTermReportCard(termReportCardData.lstReportCardObj);
        getTermReport(termId, sectionId, syllabusId);
        getLogoSvc(cbLogoResponse);
        getPrinciSignSvc(cbPrinciSign);
    }, [termId, sectionId, syllabusId]);

    function cbLogoResponse(imageUrl) {
        // // console.log("cbLogoResponse called, imageUrl", imageUrl);
        setLogoUrl(imageUrl)
    }

    function cbPrinciSign(imageUrl) {
        setPrincipalSign(imageUrl);
    }


    const getTermReport = (pTermId, pSectionId, pSyllabusId) => {
        setLoader("block");
        setOpacity(0.5);
        getTermReportSvc(pTermId, pSectionId, pSyllabusId, TermReportResponse);
    }

    const TermReportResponse = async (data) => {
        // // console.log("TermReportResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            setTermReportCard(obj.lstReportCardObj);
        } else {
            setTermReportCard({});
            if (obj.message === 'You are not logged in' || obj.message === 'Your Session is expired, login again') {
                setTimeout(() => {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }

        setLoader("none");
        setOpacity(1);
    };




    return (
        <div className='col-md-12 col-sm-12 padding-remove'>
            <div className='col-sm-12 col-centered padding-remove' style={{ opacity: opacity }}>
                <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={() => closeSlider()} style={{ right: "70%" }}>
                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                </button>
                <div className='col-sm-12 padding-remove' style={{ margin: 0 }}>
                    <TermReportPdf
                        logoUrl={logoUrl}
                        termReportCard={termReportCard}
                        term={term}
                        principalSign={principalSign}
                    />
                </div>
            </div>
        </div>
    );
}

export default TermReportSlider;