import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css'
import '../css/transport.css'
import { saveRouteSliderSvc } from './routeSvc';
const RouteSlider = (props) => {
    const [loader, setLoader] = useState("none")
    const [routeNo, setRouteNo] = useState(props.routeObj.routeNo)
    const [routeDesc, setRouteDesc] = useState(props.routeObj.routeDesc)
    // useEffect(()=>{
    //     setRouteNo(props.routeObj.routeNo)
    //     setRouteDesc(props.routeObj.routeDesc)
    // })
    function handleRouteNoChange(param) {
        setRouteNo(param)
    }
    function handleRouteDescChange(param) {
        setRouteDesc(param)
    }
    function saveRouteSlider() {
        const pRouteObj = {
            routeId: props.routeId,
            routeNo: routeNo,
            routeDesc: routeDesc
        }
        setLoader("block")
        saveRouteSliderSvc(pRouteObj, cbRouteSlider)
    }
    function cbRouteSlider(data) {
        setLoader("none")
        if (data.SvcStatus === "Success") {
            toast.success(data.SvcMsg)
            props.closeSlider();
        } else {
            toast.error(data.SvcMsg)
        }
    }
    return (
        <>
            <form className='form form-horizontal' style={{ pointerEvents: loader === "none" ? "auto" : "none", height: 'auto', width: '90%', margin: '10px auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
                <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={props.closeSlider} style={{ right: "50%" }}>
                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                </button>
                <div className='row' style={{ width: '100%' }}>
                    <div className="form-group col-md-6">
                        <label className="control-label col-sm-5">Route No.</label>
                        <div className='col-sm-6 padding-remove'>
                            <input type="text" defaultValue={routeNo} className="form-control" onChange={(e) => handleRouteNoChange(e.target.value)}></input>
                        </div>
                    </div>
                </div>
                <div className='row' style={{ width: '100%' }}>
                    <div className="form-group col-md-6">
                        <label className="control-label col-sm-5">Route Desc.</label>
                        <div className='col-sm-6 padding-remove'>
                            <input type="text" defaultValue={routeDesc} className="form-control" onChange={(e) => handleRouteDescChange(e.target.value)}></input>
                        </div>
                    </div>
                </div>
                <div className='row' style={{ width: '100%', paddingBottom: "1px" }}>
                    <div className="col-md-offset-10 col-md-1 padding-remove">
                        <button type="button" className="btn btn-info pull-right" onClick={(e) => saveRouteSlider()}>Save</button>
                    </div>
                </div>
            </form>
        </>
    )
}
export default RouteSlider;