import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'common/css/master.css'
import Header from 'common/js/header/header.js'
import { getStudentPtmSummaryListSvc, getParentFeedbackFormLink, getParentFeedbackFormQR } from './StudentPtmFeedbackTableSvc.js'
import StudentPtmFeedbackQR from "./studentPtmFeedbackQR.js";
import $ from "jquery";


const StudentPtmFeedbackTable = props => {

  const [lstStudentPtmFeedbackSummary, setLstStudentPtmFeedbackSummary] = useState([])
  const [ptmId, setPtmId] = useState(0)
  const [sectionId, setSectionId] = useState(0)
  const [cls, setCls] = useState('')
  const [section, setSection] = useState('')
  const [studentId, setStudentId] = useState(0)
  const [ptmStudentId, setPtmStudentId] = useState(0);
  const [lstPtmStdId, setLstPtmStdId] = useState([]);
  const [feedbackQRLink, setFeedbackQRLink] = useState("");
  const [selectedStudent, setSelectedStudent] = useState([]);
  const [showQR, setShowQR] = useState(false);
  const [stdName, setStdName] = useState("");

  useEffect(() => {
    setPtmId(props.ptmId)
    setSectionId(props.sectionId)
    setSection(props.section)
    setCls(props.cls)
    getStudentPtmSummaryListSvc(
      props.ptmId,
      props.sectionId,
      cbStudentPtmListResponse
    )
    // setSelectedStudent([]);
  }, [props.ptmId, props.sectionId])

  function cbStudentPtmListResponse(data) {
    // // console.log(data)
    if (data.SvcStatus === 'Success') {
      setLstStudentPtmFeedbackSummary(data.lstStudent)
    } else {
      toast.error(data.SvcStatus)
    }
  }
  const colgrp = (
    <colgroup>
      <col width='30' />
      <col width='60' />
      <col width='30' />
      <col width='30' />
      <col width='30' />
      <col width='30' />
      <col width='30' />
      <col width='30' />
      <col width='30' />
      <col width='15' />
      <col width='30' />
    </colgroup>
  )

  const updateSelectedStudents = (stdId) => {
    if (selectedStudent.includes(stdId)) {
      const updatedSelectedStudents = selectedStudent.filter((id) => id !== stdId);
      setSelectedStudent(updatedSelectedStudents);
    } else {
      setSelectedStudent([...selectedStudent, stdId]);
    }
    // // console.log("selected students", selectedStudent);
  }

  const shareFeedbackPageLinkForSelected = () => {
    // // console.log("shareFeedbackPageLinkForSelected called");
    // // console.log("selectedStudent", selectedStudent, ", sectionId", sectionId);
    if(selectedStudent.length === 0) {
      toast.error("please select atleast 1 student");
      return ;
    }
    getParentFeedbackFormLink(selectedStudent, sectionId, feedbackFormLinkResponse);
  }

  const shareFeedbackPageLink = (stdId) => {
    // // console.log("shareFeedbackPageLink called");
    var updateLstPtmStdId = [];
    updateLstPtmStdId = [...updateLstPtmStdId, stdId];
    setLstPtmStdId(updateLstPtmStdId);
    // // console.log("updateLstPtmStdId in shareFeedbackPageLink", updateLstPtmStdId);
    getParentFeedbackFormLink(updateLstPtmStdId, sectionId, feedbackFormLinkResponse);
  }

  const feedbackFormLinkResponse = (data) => {
    var obj = data;
    if (obj.status === "Success") {
      toast.success(obj.message);
    } else {
      toast.error(obj.message);
    }
  }

  const shareFeedbackPageQR = (stdId, stdNm) => {
    setStdName(stdNm);
    getParentFeedbackFormQR(stdId, sectionId, feedbackFormQRResponse);
  }

  const feedbackFormQRResponse = (data) => {
    var obj = data;
    if (obj.status === "Success") {
      showFeedbackQR(obj.link);
      // var localQR = obj.link.replace("https://sms.cooledsys.com", "192.168.1.18:3000");
      // showFeedbackQR(localQR);
    } else {
      toast.error(obj.message);
    }
  }

  const showFeedbackQR = (link) => {
    setFeedbackQRLink(link);
    setShowQR(true);
  }

  const hideFeedbackQR = () => {
    $('body').removeClass("showPopup");
    setShowQR(false);
  }

  return (
    <div >

      <div>

        <form className='form form-horizontal col-sm-11' style={{ display:'flex', margin: "2% 0 1% 0" }}>
          <div className='col-sm-6 padding-remove' style={{ textAlign: 'right' }}>
            <label className='col-sm-2 padding-remove'>Class:</label>
            <label
              className='col-sm-2 padding-remove'
              style={{ fontWeight: 'normal' }}
            >
              {cls + ' - ' + section}
            </label>
          </div>
          <div className='col-sm-6' style={{ textAlign: 'right', width: "90%" }}>
            <button
              type="button"
              title="Share Link with Parent"
              className="btn btn-success"
              style={{ width: 100 }}
              onClick={shareFeedbackPageLinkForSelected}
            >Share Link</button>
          </div>
        </form>
        <div className='col-sm-11'>
          <table className='tableLayout1'>
            {colgrp}
            <thead>
              <tr>
                <th className='text-center'>Roll No.</th>
                <th className='text-center'>Name</th>
                <th className='text-center'>Present</th>
                <th className='text-center'>Feedback</th>
                <th className='text-center'>Studies</th>
                <th className='text-center'>Hygiene</th>
                <th className='text-center'>Activities</th>
                <th className='text-center'>Infra</th>
                <th className='text-center'>School</th>
                <th className="text-center">Select</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
          </table>
          <div
            className='tableBodyWrapper'
            style={{
              height: '500px',
              overflowY: 'scroll',
              border: '1px solid #ccc'
            }}
          >
            <table id='ptmStudentFeedbackTable' className='tableLayout'>
              {colgrp}
              <tbody>
                {lstStudentPtmFeedbackSummary.map((obj, idx) => {
                  return (
                    <tr
                      className={
                        obj.ptmStudentId === ptmStudentId ? 'selected' : ''
                      }
                      key={idx}
                      onClick={e => {
                        setPtmStudentId(obj.ptmStudentId)
                        window.open(
                          '/stufeedback?name=' +
                          obj.student +
                          '&ptmStudentId=' +
                          obj.ptmStudentId +
                          '&feedbackModeId=30' +
                          '&sectionId=' +
                          sectionId,
                          '_blank'
                        )
                      }}
                    >
                      <td className='no-display'>{obj.studentId}</td>
                      <td className='text-center'>{obj.rollNo}</td>
                      <td className='text-left'>{obj.student}</td>
                      <td className='text-center'>
                        {obj.flgPresent ? 'Yes' : '-'}
                      </td>
                      <td className='text-center'>
                        {obj.flgFeedback ? 'Yes' : '-'}
                      </td>
                      <td className='text-center'>{obj.ratingStudies}</td>
                      <td className='text-center'>{obj.ratingHygiene}</td>
                      <td className='text-center'>{obj.ratingActivities}</td>
                      <td className='text-center'>{obj.ratingInfra}</td>
                      <td className='text-center'>{obj.ratingSchool}</td>
                      <td className='text-center'>
                        <input
                          type='checkbox'
                          defaultChecked={(selectedStudent.includes(obj.ptmStudentId))}
                          onClick={(e) => {
                            e.stopPropagation();
                            updateSelectedStudents(obj.ptmStudentId)
                          }}
                        />
                      </td>
                      <td className='text-center'>
                        <span className="glyphicon glyphicon-link"
                          title="Share Link with Parent"
                          onClick={(e) => {
                            e.stopPropagation();
                            shareFeedbackPageLink(obj.ptmStudentId)
                          }}
                        ></span>
                        <span className="glyphicon glyphicon-qrcode"
                          title="Open QR for Parent Feedback" onClick={(e) => {
                            e.stopPropagation();
                            shareFeedbackPageQR(obj.ptmStudentId, obj.student)
                            // // console.log(obj.student)
                          }}
                        ></span>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div>
        {
          showQR &&
          <StudentPtmFeedbackQR
            feedbackQRLink={feedbackQRLink}
            hideFeedbackQR={hideFeedbackQR}
            name={stdName}
            cls={cls}
            sec={section}
          />
        }
      </div>
    </div>
  )
}
export default StudentPtmFeedbackTable;
