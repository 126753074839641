import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'common/css/master.css'
import Header from 'common/js/header/header.js'
import {
  getOneTimeHeadListSvc,
  getOneTimeHeadDtlsSvc,
  deleteOneTimeHeadSvc
} from './oneTimeHeadSvc'
import OneTimeHeadTable from './OneTimeHeadTable'
import OneTimeHeadSlider from './OneTimeHeadSlider'
const OneTimeHeadCtrl = () => {
  const [loader, setLoader] = useState('none')
  const [oneTimeHeadObj, setOneTimeHeadObj] = useState({})
  const [oneTimeHeadId, setOneTimeHeadId] = useState(0)
  const [lstOneTimeHead, setLstOneTimeHead] = useState([])
  const [randNo, setRandNo] = useState(0)
  const [flgSlider, setFlgSlider] = useState(false)

  useEffect(() => {
    setLoader('block')
    getOneTimeHeadList()
  }, [])

  function getOneTimeHeadList () {
    getOneTimeHeadListSvc(cbOneTimeHeadListResponse)
  }
  function cbOneTimeHeadListResponse (data) {
    setLoader('none')
    if (data.SvcStatus === 'Success') {
      setLstOneTimeHead(data.lstHead)
      toast.success(data.SvcMsg)
    } else {
      toast.error(data.SvcMsg)
    }
  }

  function generateRandomNo () {
    var lRandNo = Math.floor(Math.random() * 999999)
    // // console.log(lRandNo)
    setRandNo(lRandNo)
  }
  function openOneTimeHeadSlider (pOneTimeHeadId, pOneTimeHeadObj) {
    setOneTimeHeadId(pOneTimeHeadId)
    // // console.log(typeof pOneTimeHeadId)
    // // console.log(oneTimeHeadObj)
    if (pOneTimeHeadId) {
      getOneTimeHeadDtlsSvc(pOneTimeHeadId, cbOneTimeHeadDtlsResponse)
    }
    // setOneTimeHeadObj(pOneTimeHeadObj)
    setFlgSlider(true)
  }

  function cbOneTimeHeadDtlsResponse (data) {
    setLoader('none')
    if (data.SvcStatus === 'Success') {
      setOneTimeHeadObj(data.headObj)
      toast.success(data.SvcMsg)
    } else {
      toast.error(data.SvcMsg)
    }
  }

  function closeSlider () {
    setFlgSlider(false)
    setOneTimeHeadObj({})
    setOneTimeHeadId(0)
    getOneTimeHeadListSvc(cbOneTimeHeadListResponse)
  }

  function deleteOneTimeHead (pOneTimeHeadId) {
    var confirmDelOneTimeHead = window.confirm(
      'Are you sure to delete the oneTimeHead?'
    )
    if (confirmDelOneTimeHead) {
      setLoader('block')
      deleteOneTimeHeadSvc(pOneTimeHeadId, cbDeleteOneTimeHeadResponse)
    }
  }

  function cbDeleteOneTimeHeadResponse (data) {
    setLoader('none')
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      getOneTimeHeadListSvc(cbOneTimeHeadListResponse)
    } else {
      toast.error(data.SvcMsg)
    }
    closeSlider()
  }

  const getWingId = wingId => {
    // // console.log('wingId = ' + wingId)
  }
  return (
    <div
      className='MainContainer'
      style={{ pointerEvents: loader === 'none' ? 'auto' : 'none' }}
    >
      <div
        style={{
          position: 'fixed',
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: '50%',
          top: '50%',
          display: loader
        }}
      >
        <img
          className='loderImg'
          src={require('common/images/loader.gif')}
          alt='Loader...'
        />
      </div>
      <Header wingFromChild={getWingId} />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className='col-sm-7 col-centered' style={{ marginTop: '2%' }}>
        <div className='col-sm-11' style={{ height: '100%' }}>
          <OneTimeHeadTable
            setOneTimeHeadId={setOneTimeHeadId}
            oneTimeHeadId={oneTimeHeadId}
            closeSlider={closeSlider}
            generateRandomNo={generateRandomNo}
            openOneTimeHeadSlider={openOneTimeHeadSlider}
            deleteOneTimeHead={deleteOneTimeHead}
            lstOneTimeHead={lstOneTimeHead}
          />
        </div>
        <div className='col-xs-1' onClick={e => openOneTimeHeadSlider(0, {})}>
          <span
            id='addReadyId'
            title='Add'
            className='addIcon glyphicon glyphicon-plus-sign'
          ></span>
        </div>
      </div>
      {oneTimeHeadObj && (
        <div
          className='TestSlider'
          style={{ display: flgSlider ? 'block' : 'none', width: '50%' }}
        >
          <OneTimeHeadSlider
            key={oneTimeHeadId}
            oneTimeHeadId={oneTimeHeadId}
            setOneTimeHeadId={setOneTimeHeadId}
            openSlider={openOneTimeHeadSlider}
            closeSlider={closeSlider}
            setRandNo={setRandNo}
            oneTimeHeadObj={oneTimeHeadObj}
            getOneTimeHeadList={getOneTimeHeadList}
          />
        </div>
      )}
    </div>
  )
}

export default OneTimeHeadCtrl
