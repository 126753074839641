import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import lstModules from 'common/json/lstModules.json';
var tokenid = localStorage.getItem('tokenid');
var httpURLStudent = lstModules['students'].url + ":" + lstModules['students'].port;
const StudentDocs = (props) => {
    const [lstDocDtls, setLstDocDtls] = useState([]);
    const ID_PROOF = 10;
    const AGE_PROOF = 20;
    const ADDRESS_PROOF = 30;
    const CATEGORY_PROOF = 40;
    const PHOTO = 50;
    const [imgId, setImgId] = useState('')
    const [imgAge, setImgAge] = useState('')
    const [imgAddress, setImgAddress] = useState('')
    const [imgCategory, setImgCategory] = useState('')
    const [loader, setLoader] = useState("none")
    useEffect(() => {

        cbIdDocDtlsResponse1(props.lstDocDtls)

    }, [props]);


    function cbIdDocDtlsResponse1(pLstDocDtls) {
        setLstDocDtls(pLstDocDtls)
        for (var i = 0; i < pLstDocDtls.length; i++) {
            var obj = pLstDocDtls[i];
            // // console.log(obj.typeId + "::" + obj.imgPath)
            cbFetchDocResponse(obj.typeId, obj.imgPath);
        }
    }
    function cbFetchDocResponse(type, imgPath) {
        if (type === 10) {
            setImgId(httpURLStudent + "/sms/student/docs/view?imgPath=" + imgPath)
            return;
        }
        if (type === 20) {
            setImgAge(httpURLStudent + "/sms/student/docs/view?imgPath=" + imgPath)
            return;
        }
        if (type === 30) {
            setImgAddress(httpURLStudent + "/sms/student/docs/view?imgPath=" + imgPath);
            return;
        }
        if (type === 40) {
            setImgCategory(httpURLStudent + "/sms/student/docs/view?imgPath=" + imgPath);
            return;
        }

    }
    const onIdChange = (e) => {
        // // console.log(e.target.files[0].name)
        // // console.log("src:" + URL.createObjectURL(e.target.files[0]));
        if (e.target.files && e.target.files[0]) {
            setImgId(URL.createObjectURL(e.target.files[0]));
            setLoader("block")
            saveImgIdSvc(e.target.files[0], 10, cbSaveImgIdResponse);
        }
    }
    function saveImgIdSvc(file, type, callBack) {
        var formData = new FormData();
        formData.append("studentId", props.studentId)
        formData.append("file", file);
        formData.append("typeId", type);


        axios({
            url: httpURLStudent + "/sms/student/docs/save",
            method: "POST",
            data: formData,
            headers: { 'tokenid': tokenid },
            withCredentials: true,
        }).then((response) => {
            callBack(response.data)
        }).catch((e) => {
            console.error("Service failed " + e);
        });


    }
    function cbSaveImgIdResponse(data) {
        if (data.SvcStatus === "Success") {
            toast.success(data.SvcMsg);
        } else {
            toast.error(data.SvcMsg);
        }
        setLoader("none")
    }
    const onAgeProofChange = (e) => {
        // // console.log(e.target.files[0].name)
        // // console.log("src:" + URL.createObjectURL(e.target.files[0]));
        if (e.target.files && e.target.files[0]) {
            setImgAge(URL.createObjectURL(e.target.files[0]));
            setLoader("block")
            saveImgIdSvc(e.target.files[0], 20, cbSaveImgIdResponse);
        }
    }

    const onAddressProofChange = (e) => {

        // // console.log(e.target.files[0].name)
        // // console.log("src:" + URL.createObjectURL(e.target.files[0]));
        if (e.target.files && e.target.files[0]) {
            setImgAddress(URL.createObjectURL(e.target.files[0]));
            setLoader("block")
            saveImgIdSvc(e.target.files[0], 30, cbSaveImgIdResponse);
        }
    }
    const onCategoryProofChange = (e) => {

        // // console.log(e.target.files[0].name)
        // // console.log("src:" + URL.createObjectURL(e.target.files[0]));
        if (e.target.files && e.target.files[0]) {
            setImgCategory(URL.createObjectURL(e.target.files[0]));
            setLoader("block")
            saveImgIdSvc(e.target.files[0], 40, cbSaveImgIdResponse);
        }
    }

    const fileToDataUri = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            resolve(event.target.result)
        };
        reader.readAsDataURL(file);
    })
    function fetchDocSvc(type, imgPath, callBack) {
        axios({
            url: httpURLStudent + "/sms/student/docs/view",
            method: 'POST',
            params: { imgPath: imgPath },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
        )
            .then(response => {

                callBack(type, response)
            });
    }
    function toDataURL(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
        // // console.log("here")
    }
    const handleIdClick = (e) => {
        // // console.log(document.getElementById("img_id"))
        // document.getElementById("img_id").dispatchEvent(new Event('click'));
        document.getElementById("img_id").click();
    }
    const handleAgeClick = (e) => {
        // // console.log(document.getElementById("img_ageId"))
        // document.getElementById("img_ageId").dispatchEvent(new Event('click'));
        document.getElementById("img_ageId").click();
    }
    const handleAddressClick = (e) => {
        // // console.log(document.getElementById("img_addressId"))
        // document.getElementById("img_addressId").dispatchEvent(new Event('click'));
        document.getElementById("img_addressId").click();
    }
    const handleCategoryClick = (e) => {
        // // console.log(document.getElementById("img_categoryId"))
        // document.getElementById("img_categoryId").dispatchEvent(new Event('click'));
        document.getElementById("img_categoryId").click();
    }
    const onError = (id) => {
        if (id === 10) {
            setImgId("/no-image.png")
        }
        else if (id === 20) {
            setImgAge("/no-image.png")
        }
        else if (id === 30) {
            setImgAddress("/no-image.png")
        }
        else if (id === 40) {
            setImgCategory("/no-image.png")
        } else {
            setImgId("/no-image.png")
            setImgAge("/no-image.png")
            setImgAddress("/no-image.png")
            setImgCategory("/no-image.png")
        }
    }
    return (
        <form id="studentAddForm" className="col-sm-12 form-horizontal">
            <div className="col-sm-12 section1">
                <div className="">
                    <label className="control-label col-sm-9">Documents</label>
                </div>
            </div>
            <div className="form-group" >
                <div className='col-sm-3' style={{ background: 'transparent' }}>
                    <label className="col-sm-3 control-label">ID Proof</label>
                    <div className="form-group col-sm-9">
                        <input docid="3" type="file" id="img_id" name="img"
                            onChange={onIdChange}
                            className="hidden" accept="image/*"
                                disabled={props.flgDisabled} />
                        <img alt="img"
                            src={imgId} onClick={(e) => handleIdClick(e)}
                            height="160" width="160" style={{ width: 160, border: '1px solid #ddd' }}
                            onError={(e) => onError(10)}
                        />
                    </div>
                </div>
                <div className='col-sm-3' style={{ background: 'transparent' }}>
                    <label className="col-sm-3 control-label">Age Proof</label>
                    <div className="form-group col-sm-9">
                        <input docid="2" type="file" id="img_ageId" name="img"
                            onChange={onAgeProofChange}
                            className="hidden" accept="image/*" 
                            disabled={props.flgDisabled}/>
                        <img alt="Age Proof"
                            onClick={(e) => handleAgeClick(e)} src={imgAge}
                            height="160" width="160" style={{ width: 160, border: '1px solid #ddd' }}
                            onError={(e) => onError(20)}
                        />
                    </div>
                </div>
                <div className='col-sm-3' style={{ background: 'transparent' }}>
                    <div>
                        <label className="col-sm-3 control-label">Address Proof</label>
                    </div>
                    <div className="form-group col-sm-9">
                        <input docid="1" type="file" id="img_addressId" name="img"
                            onChange={onAddressProofChange}
                            className="hidden" accept="image/*"
                                disabled={props.flgDisabled} />
                        <img alt="Address Proof"
                            onClick={(e) => handleAddressClick(e)} src={imgAddress}
                            height="160" width="160" style={{ width: 160, border: '1px solid #ddd' }}
                            onError={(e) => onError(30)}
                        />
                    </div>
                </div>
                <div className='col-sm-3' style={{ background: 'transparent' }}>
                    <label className="col-sm-3 control-label">Category Proof</label>
                    <div className="form-group col-sm-9">
                        <input docid="4" type="file" id="img_categoryId" name="img"
                            onChange={onCategoryProofChange}
                            className="hidden" accept="image/*" 
                            disabled={props.flgDisabled}/>
                        <img alt="Category Proof"
                            onClick={(e) => handleCategoryClick(e)} src={imgCategory}
                            height="160" width="160" style={{ width: 160, border: '1px solid #ddd' }}
                            onError={(e) => onError(40)}
                        />
                    </div>
                </div>
            </div>
        </form>
    )
}
export default StudentDocs;