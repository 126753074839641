import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import "./teacherAllocation.css";
import Header from "common/js/header/header";
import Loader from "common/components/Loader";

import { getTeacherAllocationListSvc } from "./teacherAllocationSvc";
import TeacherAllocationTable from "./teacherAllocationTbl";
import TeacherAllocationSlider from "./teacherAllocationSlider";


const TeacherAllocationCtrl = () => {

    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [lstTeacherAllocation, setLstTeacherAllocation] = useState([]);
    const [teacherAllocationId, setTeacherAllocationId] = useState(0);
    const [teacherAllocation, setTeacherAllocation] = useState({});
    const [isTeacherAllocationVisible, setIsTeacherAllocationVisible] = useState(false);
    const [key, setKey] = useState(0);

    useEffect(() => {
        // console.log("TeacherAllocationCtls called");
        getTeacherAllocationList();
    }, [key]);

    const getTeacherAllocationList = () => {
        // console.log("getTeacherAllocationList called");
        setLoader("block");
        setOpacity(0.5);
        getTeacherAllocationListSvc(teacherAllocationListResponse);
    }


    const teacherAllocationListResponse = (data) => {
        // console.log("teacherAllocationListResponse called, data", data);
        if (data.status === "Success") {
            setLstTeacherAllocation(data.lstTchAllocation);
        } else if (data.status === "Failure") {
            handleServiceError(data.message);
        } else {
            toast.error("Service failed " + data.error);
        }
        setLoader("none");
        setOpacity(1);
    }

    const handleServiceError = (svcMsg) => {
        if (svcMsg === "You are not Logged In") {
            toast.error("You are not logged in. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else if (svcMsg === "Server Timed Out, Login Again") {
            toast.error("Server timed out. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else {
            toast.error(svcMsg);
        }
    };

    const editTeacherAllocation = async (pTeacherAllocation) => {
        // console.log("editTeacherAllocation called, pTeacherAllocation ", pTeacherAllocation);
        await setTeacherAllocation(pTeacherAllocation);
        await setTeacherAllocationId(pTeacherAllocation.tchId);
        setIsTeacherAllocationVisible(true);
    }

    const deleteTeacherAllocation = (pTeacherAllocationId) => {
        // console.log("deleteTeacherAllocation called, pTeacherAllocationId ", pTeacherAllocationId);
    }

    const closeSlider = () => {
        setIsTeacherAllocationVisible(false);
    }

    const updateKey = () => {
        setKey(prevKey => prevKey + 1);
    }



    return (

        <div >
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <Loader loader={loader} position="fixed" />
            <div className="col-md-12 col-xs-12 col-centered padding-remove"
                style={{
                    cursor: (loader === "none") ? 'pointer' : 'none',
                    pointerEvents: (loader === "none") ? 'auto' : 'none',
                    opacity: opacity
                }}>
                <div className="col-ls-6 col-md-7 col-sm-8 col-xs-9 col-centered padding-remove" style={{ marginTop: "30px" }}>
                    <TeacherAllocationTable
                        lstTeacherAllocation={lstTeacherAllocation}
                        teacherAllocationId={teacherAllocationId}
                        setTeacherAllocationId={setTeacherAllocationId}
                        editTeacherAllocation={editTeacherAllocation}
                        deleteTeacherAllocation={deleteTeacherAllocation}
                    />
                </div>
            </div>
            {
                isTeacherAllocationVisible ?
                    <div className="slider1-60">
                        <TeacherAllocationSlider
                            key={teacherAllocationId}
                            teacherAllocation={teacherAllocation}
                            updateKey={updateKey}
                            deleteTeacherAllocation={deleteTeacherAllocation}
                            closeSlider={closeSlider}
                        />
                    </div>
                    : null
            }
        </div >
    );
}

export default TeacherAllocationCtrl;