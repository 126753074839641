import axios from "axios";
import lstModules from 'common/json/lstModules.json';
// import { httpURLLogin } from "../../config/config.json";

var tokenid = localStorage.getItem("tokenid");
const httpURLLogin = lstModules['login'].url + ":" + lstModules['login'].port;
const httpURL = lstModules['timeTable'].url + ':' + lstModules['timeTable'].port;


const screenId = 8;

function getTeachersList(handleResponse) {
  axios({
    url: httpURL + "/sms/tt/v1/tch/list",
    method: "POST",
    headers: { tokenid },
    params: { screenId },
    withCredentials: true,
  })
    .then((response) => {
      handleResponse(response.data);
    })
    .catch((error) => {
      handleResponse(error);
    });
}

function getClassList(tchId, callback) {
  axios({
    url: httpURL + "/sms/tt/v1/tch/cls/list",
    method: "POST",
    params: { tchId, screenId },
    headers: { tokenid },
    withCredentials: true,
  })
    .then((response) => {
      callback(response.data, "success");
    })
    .catch((error) => {
      callback(error, "fail");
    });
}

function saveClassList(tchClsObj, callback) {
  axios({
    url: httpURL + "/sms/tt/v1/tch/cls/save",
    method: "POST",
    params: { tchCls: tchClsObj, screenId },
    headers: { tokenid },
    withCredentials: true,
  })
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      callback(error);
    });
}

function getSyallabus(tchId, classId, sectionId, callback) {
  const syllabusObj = { tchId, classId, sectionId, screenId };
  axios({
    url: httpURL + "/sms/tt/v1/tch/sub/list",
    method: "POST",
    params: syllabusObj,
    headers: { tokenid },
    withCredentials: true,
  })
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      callback(error);
    });
}
function saveSyallabus(tchId, classId, sectionId, subId, rating, callback) {
  const syllabusObj = { tchId, classId, sectionId, subId, rating };

  axios({
    url: httpURL + "/sms/tt/v1/tch/sub/save",
    method: "POST",
    params: { tchSub: syllabusObj, screenId },
    headers: { tokenid },
    withCredentials: true,
  })
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      callback(error);
    });
}

function login(schoolObj, callback) {
  axios({
    url: httpURLLogin + "/sms/school/login",
    method: "POST",
    params: { school: schoolObj },
    withCredentials: true,
  })
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      callback(error);
    });
}

export {
  getTeachersList,
  getClassList,
  saveClassList,
  getSyallabus,
  saveSyallabus,
  login,
};
