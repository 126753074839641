import React from 'react'

const CampaignContent = (props) => {
    return (
        <div className="campaignContentTableWrapper">
            <table className="tableLayout1">
                <thead>
                    <tr>
                        <th style={{ width: 40 }}> Name</th>
                        <th style={{ width: 40 }}> Type </th>
                        <th style={{ width: 45 }}> Undated On</th>
                        <th style={{ width: 30 }} className="selectAll"> Select
                        <input type="checkbox" id="cbxSelectAll" onClick={props.selectAll}
                                checked={props.checkedAll} />
                        </th>
                    </tr>
                </thead>
            </table>
            <div className="tableDivMedium">
                <table id="campaignContentTable" className="tableLayout">
                    <tbody id="campaignContentTableBody">
                        {props.lstContent.map((contents, index) => (
                            <tr key={contents.contentId} id={contents.contentId}
                            >
                                <td width="40" className="no-display">{contents.contentId}</td>
                                <td width="40" className="text-center">{contents.content}</td>
                                <td width="40" className="text-center">{contents.mediaType}</td>
                                <td width="45" className="text-center">{contents.uploadDttm}</td>
                                <td width="30" className="text-center">
                                    <input type="checkbox"
                                        defaultChecked={contents.selected}
                                        onClick={(e) =>
                                            props.checkedClick(e)}
                                        className="formControl "
                                        id={index} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <button type="button" style={{ marginTop: 20 }} onClick={props.saveCampaignContent} className="btn btn-success text-right">Save</button>
            <button type="button" className="btn btn-info text-center " id="closeSlider" onClick={props.closeSlider}>
                <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
            </button>
        </div>
    )
}

export default CampaignContent


