import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'

import 'common/css/master.css'
import Header from 'common/js/header/header'

import WTestStudentTable from './WTestStudentTable.js';
import WTestHeader from './WTestHeader.js';
import { getTestDtlsSvc } from "./WTestSvc.js";
import OtpPDFSlider from 'sms/WeeklyTest/schedule/js/otpPDFSlider.js'


const WTestCtrl = () => {
  const [loader, setLoader] = useState("none")
  const [lstStudentId, setLstStudentId] = useState([]);
  const search = useLocation().search
  const [testId, setTestId] = useState(new URLSearchParams(search).get('testId'));
  const [lstStudent, setLstStudent] = useState([]);
  const [testObj, setTestObj] = useState({});
  const [flgOTPSlider, setFlgOTPSlider] = useState(false);
  const [lstOtp, setLstOtp] = useState([]);
  const [flgPDFStudent, setFlgPDFStudent] = useState(10);   // 10 for students, 20 for staff/guest

  useEffect(() => {
    var params = new URLSearchParams(search);
    if (params.get("testId")) {
      setTestId(params.get("testId"))
    }
    getTestDtls();
  }, [])

  function getTestDtls() {
    getTestDtlsSvc(testId, cbTestDtlsSvc);
  }

  function cbTestDtlsSvc(data) {
    // // console.log("cbTestDtlsSvc() called.. ",data.SvcStatus)
    if (data.SvcStatus === "Success") {
      let testData = data.testObj;
      setLstStudent(testData.lstStudent);
      setLstOtp(getLstOtp(testData.lstStudent));
      delete testData.lstStudent;
      setTestObj(testData);
      toast.success(data.SvcMsg);
    } else {
      setLstStudent([]);
      toast.error(data.SvcMsg);
    }
    setLoader("none");
    // setTestObj({ test: "New Test", sectionId: 19, clsSection: "I-A", timeToStart: "05:00" })
  }

  function getLstOtp(pLstStudent) {
    return pLstStudent.map(student => ({
      studentId: student.studentId,
      student: student.student,
      enrollmentNo: student.enrollmentNo,
      otp: student.otp
    }));
  }


  const printStudentOtp = async (pFlgPDFStudent) => {
    // // console.log("printStudentOtp called, pFlgPDFStudent",pFlgPDFStudent);
    await setFlgPDFStudent(pFlgPDFStudent);
    setFlgOTPSlider(true);
  }


  const closeSlider = () => {
    setFlgOTPSlider(false);
  }

  return (
    <div style={{ height: '100%' }}>
      <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }} >
        <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
      </div >
      <Header />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className='MainContainer' style={{ pointerEvents: loader === "none" ? "auto" : "none", marginTop: 30 }}>
        <div className="col-sm-12 col-centered" style={{ minHeight: "50px" }}>
          <WTestHeader
            lstStudentId={lstStudentId}
            testId={testId}
            testObj={testObj}
            countTotalStudent={lstStudent.length}
            lstStudent={lstStudent}
            getTestDtls={getTestDtls}
            printStudentOtp={printStudentOtp}
          />
        </div>
        <div className="col-sm-12 col-centered" >
          <WTestStudentTable
            loader={loader}
            setLstStudentId={setLstStudentId}
            testId={testId}
            lstStudent={lstStudent} />
        </div>
      </div>

      {flgOTPSlider ?
        <div className='slider1-60'>
          <OtpPDFSlider
            testId={testId}
            test={testObj.test || ""}
            testDt={""}
            closeSlider={closeSlider}
            lstOtp={lstOtp}
            flgPDFStudent={flgPDFStudent}
          />
        </div>
        : null
      }
    </div>
  )
}

export default WTestCtrl