
import React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';
import { styleFooter } from './reportCardStyles.js';


const FooterSection = (props) => {
    const { teacherSignUrl, imageUrl, flgValidated } = props;
    return (
        <View style={styleFooter.block}>
            <View style={styleFooter.row_sign}>
                <View style={styleFooter.colSign}>
                    {
                        !!teacherSignUrl && flgValidated ?
                            <Image style={styleFooter.image_sign} src={teacherSignUrl} />
                            : null
                    }
                </View>
                <View style={styleFooter.colSign}>
                </View>
                <View style={styleFooter.colSign}>
                    {
                        !!imageUrl && flgValidated ?
                            <Image style={styleFooter.image_sign} src={imageUrl} />
                            : null
                    }
                </View>
            </View>
            <View style={styleFooter.row_labels}>
                <View style={styleFooter.colSignLabel}>
                    <Text style={[styleFooter.sign_text, {}]}>CLASS TEACHER</Text>
                </View>
                <View style={styleFooter.colSignLabel}>
                    <Text style={styleFooter.sign_text}>PARENT</Text>
                </View>
                <View style={styleFooter.colSignLabel}>
                    <Text style={styleFooter.sign_text}>PRINCIPAL</Text>
                </View>
            </View>
        </View>
    );
}

export default FooterSection;