import React, { useEffect, useState } from "react";
import "common/css/master.css";
import "../css/subjectAllocation.css";
import { toast } from "react-toastify";
import Loader from "common/components/Loader";

import { getSubjectDtlsSvc, getFacilityListSvc, saveSubjectAllocationSvc} from "./subjectAllocationSvc.js";  // Assuming you have a service for this
import GroupSectionSelector from "./groupSectionSelector";
// import data from "./subjectData.json";

const SubjectAllocationSlider = (props) => {
    const { subject, syllabusId, updateKey, deleteSubjectAllocation, closeSlider } = props;

    const [editLoader, setEditLoader] = useState("none");
    const [editOpacity, setEditOpacity] = useState(1);

    const [selSubId, setSelSubId] = useState("");
    const [sub, setSub] = useState("");
    const [totalPeriod, setTotalPeriod] = useState(0);
    const [flgCommonTch, setFlgCommonTch] = useState(false);
    const [flgSharedSlot, setFlgSharedSlot] = useState(false);
    const [facilityId, setFacilityId] = useState(0);
    const [lstFacility, setLstFacility] = useState([]);
    const [facility, setFacility] = useState("");
    const [lstSection, setLstSection] = useState([]);
    const [lstGroup, setLstGroup] = useState([]);

    useEffect(() => {
        // setSubjectDetails(data);
        console.log("SubjectAllocationSlider component loaded, subject", subject);
        getSubjectDetails(syllabusId, subject.subId);
        getFacilityList();
        setSubjectDetails(subject);
    }, [subject, syllabusId]);

    const getSubjectDetails = (pSyllabusId, pSubjectId) => {
        // console.log("getSubjectDetails called pSyllabusId", pSyllabusId, ", pSubjectId", pSubjectId);
        setEditLoader("block");
        setEditOpacity(0.5);
        getSubjectDtlsSvc(pSyllabusId, pSubjectId, subjectDtlsResponse);
    };


    const subjectDtlsResponse = (data) => {
        // console.log("subjectDtlsResponse called, data", data);
        if (data.status === "Success") {
            setLstGroup(data.lstGroup);
            setLstSection(data.lstSection);
        } else if (data.status === "Failure") {
            handleServiceError(data.message);
        } else {
            toast.error("Service failed " + data.error);
        }
        setEditLoader("none");
        setEditOpacity(1);
    };


    const getFacilityList = () => {
        // console.log("getFacilityList called");
        setEditLoader("block");
        setEditOpacity(0.5);
        getFacilityListSvc(facilityListResponse);
    }


    const facilityListResponse = (data) => {
        // console.log("facilityListResponse called, data", data);
        if (data.status === "Success") {
            setLstFacility(data.lstFacility);
        } else if (data.status === "Failure") {
            handleServiceError(data.message);
        } else {
            toast.error("Service failed " + data.error);
        }
        setEditLoader("none");
        setEditOpacity(1);
    }

    const handleServiceError = (svcMsg) => {
        if (svcMsg === "You are not Logged In") {
            toast.error("You are not logged in. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else if (svcMsg === "Server Timed Out, Login Again") {
            toast.error("Server timed out. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else {
            toast.error(svcMsg);
        }
    };

    const setSubjectDetails = (subject) => {
        // console.log("setSubjectDetails called with", subject);
        if (!!subject) {
            setSelSubId(subject.subId);  // subjectId from JSON
            setSub(subject.sub);  // subjectName from JSON
            setTotalPeriod(subject.totalPeriod || 0);  // totalPeriod from JSON, default to 0
            setFlgCommonTch(subject.flgCommonTch || false);  // flgCommonTch from JSON, default to false
            setFlgSharedSlot(subject.flgSharedSlot || false);  // flgSharedSlot from JSON, default to false
            setFacilityId(subject.facilityId || 0);  // facilityId from JSON, default to empty string
            setFacility(subject.facility || "");  // facility (lab/classroom) from JSON, default to empty string
            setLstFacility(subject.lstFacility || []);  // lstFacility from JSON, default to empty array
            setLstSection(subject.lstSection || []);  // lstSection from JSON, default to empty array
            setLstGroup(subject.lstGroup || []);  // lstGroup from JSON, default to empty array
        }
    };


    const saveSubjectAllocation = () => {
        // console.log("saveSubjectAllocation called");
        const hasLessThanTwoSections = lstGroup.some(group => group.lstSecId.length < 2);
        // console.log("lstGroup", lstGroup);
        // console.log("hasLessThanTwoSections", hasLessThanTwoSections);
        if (!sub) {
            toast.warn("Subject name is required.");
            return;
        }
        if (!totalPeriod) {
            toast.warn("Total periods are required.");
            return;
        }
        if (hasLessThanTwoSections) {
            toast.warn("Groups should have atleast two sections in it.");
            return;
        }

        const saveSubjectObj = {
            "subId": selSubId,
            totalPeriod,
            flgCommonTch,
            flgSharedSlot,
            facilityId,
            lstGroup
        };

        // console.log("saveSubjectObj", saveSubjectObj);
        setEditLoader("block");
        setEditOpacity(0.5);
        saveSubjectAllocationSvc(syllabusId, saveSubjectObj, saveSubjectAllocationResponse);
    };

    const saveSubjectAllocationResponse = async (data) => {
        // console.log("saveSubjectAllocationResponse called, data", data);
        if (data.status === "Success") {
            toast.success(data.message);
            await updateKey();
            closeSlider();
        } else if (data.status === "Failure") {
            toast.error(data.message);
        } else {
            toast.error("Service failed: " + data.error);
        }
        setEditLoader("none");
        setEditOpacity(1);
    };


    return (
        <div>
            <button
                type="button"
                className="btn btn-info text-center btnCloseDiv"
                onClick={closeSlider}
                style={{ right: "80%" }}
            >
                <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
            </button>
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '58%', top: '45%', display: editLoader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div
                className="col-md-12 col-xs-12 col-centered padding-remove"
                style={{
                    cursor: editLoader === "none" ? 'pointer' : 'none',
                    pointerEvents: editLoader === "none" ? 'auto' : 'none',
                    opacity: editOpacity
                }}
            >
                <div className="col-sm-12 col-md-12 padding-remove" style={{ width: '94%', margin: '3%', background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
                    <form id="editSubjectAllocationForm" className="form form-horizontal" style={{ height: "80vh", overflowX: 'hidden', padding: "20px 20px 5px 20px" }}>
                        <div className="form-group col-md-12 " style={{ padding: "5px 0" }}>
                            <div className="form-group col-md-12 padding-remove">
                                <label className="control-label col-md-3 col-xs-6 " style={{ padding: "7px 10px 0 0" }}>
                                    Subject:
                                </label>
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={sub}
                                        disabled
                                    />
                                </div>
                            </div>

                            <div className="form-group col-md-12 padding-remove">
                                <label className="control-label col-md-3 " style={{ padding: "7px 10px 0 0" }}>
                                    Total Periods:
                                </label>
                                <div className="col-sm-3">
                                    <input
                                        type="number"
                                        className="form-control"
                                        // min="0"
                                        // max="99"
                                        value={totalPeriod}
                                        // onChange={(e) => setTotalPeriod(Math.max(0, Math.min(99, Number(e.target.value))))}
                                        onChange={(e) => setTotalPeriod(e.target.value)}
                                        placeholder="1"
                                    />
                                </div>
                            </div>

                            <div className="form-group col-md-12 padding-remove">
                                <label className="control-label col-md-3" style={{ padding: "7px 10px 0 0" }}>
                                    Common Teacher:
                                </label>
                                <div className="col-sm-3">
                                    <input
                                        type="checkbox"
                                        className="form-control"
                                        checked={flgCommonTch}
                                        onChange={(e) => setFlgCommonTch(e.target.checked)}
                                    />
                                </div>
                            </div>
                            {
                                flgCommonTch ?
                                    <GroupSectionSelector
                                        lstSection={lstSection}
                                        lstGroup={lstGroup}
                                        setLstGroup={setLstGroup}
                                    />
                                    : null
                            }

                            <div className="form-group col-md-12 padding-remove">
                                <label className="control-label col-md-3" style={{ padding: "7px 10px 0 0" }}>
                                    Shared Slot:
                                </label>
                                <div className="col-sm-3">
                                    <input
                                        type="checkbox"
                                        className="form-control"
                                        checked={flgSharedSlot}
                                        onChange={(e) => setFlgSharedSlot(e.target.checked)}
                                    />
                                </div>
                            </div>
                            <div className="form-group col-md-12 padding-remove">
                                <label className="control-label col-md-3" style={{ padding: "7px 10px 0 0" }}>
                                    Facility:
                                </label>
                                <div className="col-sm-3">
                                    <select
                                        className="form-control"
                                        value={facility}
                                        onChange={(e) => setFacility(e.target.value)}
                                    >
                                        <option value={0}>Facility</option>
                                        {/* <option value={0}>--None--</option> */}
                                        {lstFacility.map((fac, index) => (
                                            <option key={fac.facilityId} value={fac.facilityId}>{fac.facility}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="col-sm-10 padding-remove" style={{ height: "50px", marginTop: "30px" }}>
                                <div className="col-sm-2 col-md-2 padding-remove pull-right">
                                    <button type="button" className="btn btn-info" onClick={saveSubjectAllocation}>Save</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SubjectAllocationSlider;
