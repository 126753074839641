import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
const httpURL = lstModules['transport'].url + ':' + lstModules['transport'].port;
var tokenid = localStorage.getItem('tokenid');
function getVehicleTypeList(callBack){
    axios(
        {
            url: httpURL+"/sms/tpt/vtype/list",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
           
        }
    ).catch((error) => {
        // // console.log("Test List Catch error")
    });
}
function getVehicleTypeDtls(pvId,callBack){
    axios(
        {
            url: httpURL+"/sms/tpt/vtype/dtls",
            method: 'POST',
            params: {vehicleTypeId:pvId},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
           
        }
    ).catch((error) => {
        // // console.log("Test List Catch error")
    });
}
function deleteVehicleType(pvId,callBack){
    axios(
        {
            url: httpURL+"/sms/tpt/vtype/delete",
            method: 'POST',
            params: {vehicleTypeId:pvId},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
           
        }
    ).catch((error) => {
        // // console.log("Test List Catch error")
    });
}
function saveVehicleType(pVehicleObj,callBack){
    var formData = new FormData();
    formData.append("vehicleType", JSON.stringify(pVehicleObj));
    axios({
        url:httpURL+"/sms/tpt/vtype/save",
        method: "POST",
        data: formData,
        headers: { 'tokenid': tokenid },
        withCredentials: true
    }).then((response) => {

        var data = response.data
        callBack(data);
    }).catch((error) => { 
        // // console.log("Error Occured") 
    });
}
export {getVehicleTypeList,getVehicleTypeDtls,deleteVehicleType,saveVehicleType};