import React, { useState, useRef, useEffect } from "react";

const CommanTimer = (props) => {
    const { secondsTostart, minToStart, textColr, fSize, fWeight } = props;


    const [secondsPassed, setSecondsPassed] = useState(0);
    const [countdown, setCountDown] = useState(0);
    const [tmToStart, setTmToStart] = useState(0);
    const [myFlg, setMyFlg] = useState(0);
    const [flgPositive, setFlgPositive] = useState(true)
    var arrTmToStart = [], minsToStart = 0, secsToStart = 0;

    useEffect(() => {
        // // console.log("CommanTimer useEffect called 1");

        if (minToStart) {
            arrTmToStart = minToStart.split(":");
            // // console.log('arrTmToStart = ' + arrTmToStart);

            minsToStart = parseInt(arrTmToStart[0]);
            secsToStart = parseInt(arrTmToStart[1]);
            if (minsToStart >= 0) {
                // // console.log("POSITIVE")
                if (secsToStart >= 0) {
                    setFlgPositive(true);
                } else {
                    setFlgPositive(false);
                }
                setTmToStart(minsToStart * 60 + secsToStart);
            } else {
                // // console.log("NEGATIVE");
                setFlgPositive(false);
                setTmToStart(-minsToStart * 60 - secsToStart);
            }
            setMyFlg(1);
        }

    }, [secondsTostart, minToStart])

    useEffect(() => {
        const secsInterval = setInterval(() => {
            setSecondsPassed(sp => {
                if (myFlg == 1) {
                    if (flgPositive) {
                        let tmpTmToStart = tmToStart - sp;
                        if (tmpTmToStart >= 0) {
                            // // console.log("P-POSITIVE")
                            minsToStart = ~~(tmpTmToStart / 60);
                            secsToStart = tmpTmToStart % 60;
                            setCountDown(`${minsToStart}:${secsToStart.toLocaleString("en-US", {
                                minimumIntegerDigits: 2
                            })}`);
                            // // console.log('tmToStart = ' + tmToStart + ', sp = ' + sp + ', tmpTmToStart = ' + tmpTmToStart + `${minsToStart}:${secsToStart.toLocaleString("en-US", {     minimumIntegerDigits: 2    })}`)//correct
                        } else {
                            setFlgPositive(false)
                            minsToStart = ~~(-tmpTmToStart / 60);
                            secsToStart = -tmpTmToStart % 60;
                            setCountDown(`${minsToStart}:${secsToStart.toLocaleString("en-US", {
                                minimumIntegerDigits: 2
                            })}`);
                            // // console.log('else part of tmpTmpToStart<=0' + `${minsToStart}:${secsToStart.toLocaleString("en-US", {    minimumIntegerDigits: 2      }) } `)
                        }
                    } else {
                        if (tmToStart - sp > 0) {
                            let tmpTmToStart = tmToStart + sp;
                            minsToStart = ~~(tmpTmToStart / 60);
                            secsToStart = tmpTmToStart % 60;
                            setCountDown(`${minsToStart}: ${secsToStart.toLocaleString("en-US", {
                                minimumIntegerDigits: 2
                            })
                                } `);
                            // // console.log('N-NEGATIVE tmpTmToStart>0 tmToStart = ' + tmToStart + ', sp = ' + sp + ', tmpTmToStart = ' + tmpTmToStart + '-' + minsToStart + '-' + secsToStart + `${ minsToStart }: ${  secsToStart.toLocaleString("en-US", {minimumIntegerDigits: 2})      } `)
                        } else {
                            let tmpTmToStart = tmToStart - sp;
                            minsToStart = ~~(-tmpTmToStart / 60);
                            secsToStart = -tmpTmToStart % 60;
                            setCountDown(`${minsToStart}: ${secsToStart.toLocaleString("en-US", {
                                minimumIntegerDigits: 2
                            })
                                } `);
                            // // console.log('N-NEGATIVE tmpTmToStart<0 tmToStart = ' + tmToStart + ', sp = ' + sp + ', tmpTmToStart = ' + tmpTmToStart + `${ minsToStart }: ${   secsToStart.toLocaleString("en-US", {     minimumIntegerDigits: 2  })  } `)
                        }
                    }
                }
                return sp + 1
            }
            )
        }, 992);
        return () => { clearInterval(secsInterval) }
    }, [secondsPassed]);


    return (
        <div style={{ textAlign: "center", color: textColr, fontSize: fSize, fontWeight: fWeight, margin: "auto" }}>
            {/* {flgPositive ? "" : "-"} {countdown} */}
            {flgPositive ? countdown : "00:00"}
        </div >
    );
};

export default CommanTimer;
