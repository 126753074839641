import React, { useEffect } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { styleContent, fontStyles } from './styleBonafidePdf';

const BonafideContent = (props) => {
    const { printDt, certificateName, gender, studentName, parentName, enrollmentNo, cls, year, dob, schoolName, notes } = props;

    useEffect(() => {
        // Any side effects if needed
    }, []);

    return (
        <View style={styleContent.content_section}>
            <View style={styleContent.certificate_name}>
                <View style={styleContent.certificate_name_container}>
                    <Text style={fontStyles.textCenterBigBold}>{certificateName}</Text>
                </View>
            </View>
            <View style={styleContent.date}>
                <Text style={fontStyles.textLeftNormal}>Date: {printDt && printDt !== "-" ? printDt : "............"}</Text>
            </View>
            <View style={styleContent.content}>
                <View style={styleContent.mainContentSection}>
                    <View style={styleContent.textSection}>
                        <Text style={fontStyles.fontInterItalic}>
                            {"This is to certify that "}
                        </Text>
                        <Text style={fontStyles.boldText}>
                            {gender === 'M' ? 'Mr. ' : gender === 'F' ? 'Ms. ' : 'Mr./Ms. '}
                            {studentName && studentName !== "-" ? studentName + ", " : "............, "}
                        </Text>
                        <Text style={fontStyles.fontInterItalic}>
                            {gender === 'M' ? 'S/O ' : gender === 'F' ? 'D/O ' : 'Child Of '}
                        </Text>
                        <Text style={fontStyles.boldText}>
                            {parentName && parentName !== "-" ? "Mr. " + parentName : "............"}
                        </Text>,
                        <Text style={fontStyles.fontInterItalic}>
                            {" bearing Enrollment No. "}
                        </Text>,
                        <Text style={fontStyles.boldText}>
                            {enrollmentNo && enrollmentNo !== "-" ? enrollmentNo : "............"}
                        </Text>,
                        <Text style={fontStyles.fontInterItalic}>
                            {" is studying in grade "}
                        </Text>,
                        <Text style={fontStyles.boldText}>
                            {cls && cls !== "-" ? cls : "............"}
                        </Text>
                        <Text style={fontStyles.fontInterItalic}>
                            {" for the academic year "}
                        </Text>
                        <Text style={fontStyles.boldText}>
                            {year && year !== "-" ? year + "." : "............"}
                        </Text>.
                        <Text style={fontStyles.fontInterItalic}>
                            {gender === 'M' ? ' His' : gender === 'F' ? ' Her' : ' His/Her'}
                            {" date of birth is "}
                        </Text>
                        <Text style={fontStyles.boldText}>
                            {dob && dob !== "-" ? dob + "." : "............"}
                        </Text>.
                    </View>
                    <View style={styleContent.gapSection}>
                        <Text style={fontStyles.fontInterItalic}>
                            {gender === 'M' ? 'He ' : gender === 'F' ? 'She ' : 'He/She '}
                            {"is a bonafide student of "}
                            <Text style={fontStyles.boldText}>
                                {schoolName && schoolName !== "-" ? schoolName : "............"}
                            </Text>.
                        </Text>
                    </View>
                    <View style={styleContent.notesSection}>
                        <Text style={fontStyles.fontInterItalic}>
                            {notes}
                        </Text>
                    </View>
                </View>
            </View>
        </View >
    );
}

export default BonafideContent;
