import React, { useEffect, useState } from "react";

import "common/css/master.css";
import "../../css/subjectAllocation.css";

const TeacherReportTable = (props) => {
    const { lstTchSummary, allocationFiltered } = props;

    const [lstTchSummaryFiltered, setLstTchSummaryFiltered] = useState([]);

    const [teacherFiltered, setTeacherFiltered] = useState("");
    const [allocationPctFiltered, setAllocationPctFiltered] = useState(0);


    useEffect(() => {
        setLstTchSummaryFiltered(lstTchSummary);
    }, [lstTchSummary]);

    useEffect(() => {
        filterAllocation(allocationFiltered)
    }, [allocationFiltered]);

    const filterTeacher = async (fTeacher) => {
        await setTeacherFiltered(fTeacher || "");
        filterTable(fTeacher, allocationPctFiltered);
    }

    const filterAllocation = async (fAllocation) => {
        console.log("filterAllocation called in teacherReportTable, fAllocation", fAllocation);
        await setAllocationPctFiltered(fAllocation || "> 0");
        filterTable(teacherFiltered, fAllocation);
    }

    const filterTable = async (fTeacher, fAllocation) => {
        console.log("filterTable called, fTeacher", fTeacher, ", fAllocation", fAllocation);

        let filteredTeacherTable = lstTchSummary;

        // Filter by teacher name
        filteredTeacherTable = filteredTeacherTable.filter(item =>
            item.tch.toLowerCase().includes(fTeacher.toLowerCase())
        );

        // Initialize condition variables
        let conditionType = null; // Can be "lessThan" or "greaterThan"
        let allocationNumber = null;

        // Extract operator and number from fAllocation
        if (fAllocation) {
            const match = fAllocation.match(/([<>])\s*(\d+)/); // Regex to capture operator and number
            if (match) {
                const operator = match[1]; // "<" or ">"
                allocationNumber = parseInt(match[2], 10); // Extract the number

                // Set the condition type based on the operator
                if (operator === "<") {
                    conditionType = "lessThan";
                } else if (operator === ">") {
                    conditionType = "greaterThan";
                }
            }
        }

        console.log("Extracted:", { conditionType, allocationNumber });

        // Filter by allocation condition
        if (conditionType === "lessThan") {
            filteredTeacherTable = filteredTeacherTable.filter(item => (item.pctAllocation || 0) < allocationNumber);
        } else if (conditionType === "greaterThan") {
            filteredTeacherTable = filteredTeacherTable.filter(item => (item.pctAllocation || 0) > allocationNumber);
        }

        await setLstTchSummaryFiltered(filteredTeacherTable);
        console.log("Filter Teacher table updated, filteredTeacherTable", filteredTeacherTable);
    };





    const colgrp = (
        <colgroup>
            <col width="150"></col>
            <col width="50"></col>
            <col width="50"></col>
            <col width="100"></col>
            <col width="100"></col>
            <col width="50"></col>
            <col width="50"></col>
        </colgroup>
    );


    return (
        <div>
            <div className="col-md-12 col-xs-12 col-centered padding-remove">
                <table className="tableLayout1">
                    {colgrp}
                    <thead>
                        <tr>
                            <th>
                                <input
                                    type="text"
                                    className="form-control inp-search"
                                    value={teacherFiltered}
                                    onChange={(e) => filterTeacher(e.target.value)}
                                    name="Teacher"
                                    placeholder="Teacher"
                                />
                            </th>
                            <th>Allocation</th>
                            <th>Percent</th>
                            <th>Class</th>
                            <th>Section</th>
                            <th>Allocated</th>
                            <th>Pct Allocation</th>
                        </tr>
                    </thead>
                </table>
                <div className="tableDiv" style={{ height: "60vh", pointerEvents: "auto", zIndex: 1, position: "relative" }}>
                    <table className="tableLayout" style={{ position: "relative" }}>
                        {colgrp}
                        <tbody id="teacherReportTable">
                            {lstTchSummaryFiltered && lstTchSummaryFiltered.length > 0 ? (
                                lstTchSummaryFiltered.map((teacher) => {
                                    const numSections = teacher.lstClsSecSub?.length || 0;

                                    return (
                                        <React.Fragment key={teacher.tchId}>
                                            {/* Teacher row */}
                                            <tr>
                                                <td rowSpan={numSections || 1} className="text-left">
                                                    {teacher.tch || ""}
                                                </td>
                                                <td rowSpan={numSections || 1} className="text-center">
                                                    {teacher.allocatedPeriods && teacher.totalPeriods
                                                        ? `${teacher.allocatedPeriods}/${teacher.totalPeriods}`
                                                        : ""}
                                                </td>
                                                <td rowSpan={numSections || 1} className="text-center">
                                                    {teacher.pctAllocation ? `${teacher.pctAllocation} %` : ""}
                                                </td>

                                                {numSections > 0 ? (
                                                    <>
                                                        {/* First section row */}
                                                        <td className="text-left">{teacher.lstClsSecSub[0]?.cls || ""}</td>
                                                        <td className="text-center">{teacher.lstClsSecSub[0]?.sec || ""}</td>
                                                        <td className="text-center">{teacher.lstClsSecSub[0]?.allocatedPeriods || ""}</td>
                                                        <td className="text-center">
                                                            {teacher.lstClsSecSub[0]?.pctAllocation
                                                                ? `${teacher.lstClsSecSub[0].pctAllocation} %`
                                                                : ""}
                                                        </td>
                                                    </>
                                                ) : (
                                                    <>
                                                        {/* Empty row for teacher without sections */}
                                                        <td colSpan="4" className="text-center">

                                                        </td>
                                                    </>
                                                )}
                                            </tr>

                                            {/* Subsequent section rows */}
                                            {numSections > 1 &&
                                                teacher.lstClsSecSub.slice(1).map((section, index) => (
                                                    <tr key={`${teacher.tchId}-${index}`}>
                                                        <td className="text-left">{section.cls || ""}</td>
                                                        <td className="text-center">{section.sec || ""}</td>
                                                        <td className="text-center">{section.allocatedPeriods || ""}</td>
                                                        <td className="text-center">
                                                            {section.pctAllocation ? `${section.pctAllocation} %` : ""}
                                                        </td>
                                                    </tr>
                                                ))}
                                        </React.Fragment>
                                    );
                                })
                            ) :
                                null
                            }
                        </tbody>
                    </table>
                </div>


            </div>
        </div>
    );
};

export default TeacherReportTable;
