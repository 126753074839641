import React, { useEffect } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styleContent, fontStyles } from './styleTcPdf';

const TcContent = (props) => {
    const {
        tcSerialNo, enrollmentNo, date, gender, student, age, issuedDt, fatherName,
        motherName, nationality, religion, dob, doa, educationBoard, lastClass,
        academicYear, flgEleigiblePromotion, flgFeesCleared, pendingFees, otherDues,
        generalConduct, reasonTc, specialRemarks, attendance
    } = props;

    useEffect(() => {
        // Any side effects if needed
    }, []);

    return (
        <View style={styleContent.content_section}>
            <View style={styleContent.certificate_dtls_section}>
                <View style={styleContent.row}>
                    <View style={styleContent.column}>
                        <Text style={fontStyles.textLeftNormal}>Serial No: {tcSerialNo}</Text>
                    </View>
                    <View style={styleContent.column}>
                        <Text style={fontStyles.textRightNormal}>Enrollment No: {enrollmentNo}</Text>
                    </View>
                </View>
                <View style={styleContent.row}>
                    <View style={styleContent.column}>
                        <Text style={fontStyles.textLeftSmallBold}>Date: {date}</Text>
                    </View>
                    <View style={styleContent.column}>
                    </View>
                </View>
            </View>
            <View style={styleContent.certificate_name}>
                <View style={styleContent.certificate_name_container}>
                    <Text style={fontStyles.textCenterNormalInter}>
                        Transfer Certificate for {student} {gender}/{age} issued on {issuedDt}.
                    </Text>
                </View>
            </View>
            <View style={styleContent.content}>
                <View style={styleContent.gap_section_small}>
                </View>
                <View style={styleContent.row}>
                    <View style={styleContent.column}>
                        <Text style={fontStyles.textLeftNormal}>Father's Name</Text>
                    </View>
                    <View style={styleContent.columnDivider}>
                        <Text style={fontStyles.textLeftNormal}>:</Text>
                    </View>
                    <View style={styleContent.columnRight}>
                        <Text style={fontStyles.textLeftNormal}>{fatherName}</Text>
                    </View>
                </View>

                <View style={styleContent.row}>
                    <View style={styleContent.column}>
                        <Text style={fontStyles.textLeftNormal}>Mother's Name</Text>
                    </View>
                    <View style={styleContent.columnDivider}>
                        <Text style={fontStyles.textLeftNormal}>:</Text>
                    </View>
                    <View style={styleContent.columnRight}>
                        <Text style={fontStyles.textLeftNormal}>{motherName}</Text>
                    </View>
                </View>

                <View style={styleContent.row}>
                    <View style={styleContent.column}>
                        <Text style={fontStyles.textLeftNormal}>Nationality</Text>
                    </View>
                    <View style={styleContent.columnDivider}>
                        <Text style={fontStyles.textLeftNormal}>:</Text>
                    </View>
                    <View style={styleContent.columnRight}>
                        <Text style={fontStyles.textLeftNormal}>{nationality}</Text>
                    </View>
                </View>

                <View style={styleContent.row}>
                    <View style={styleContent.column}>
                        <Text style={fontStyles.textLeftNormal}>Religion</Text>
                    </View>
                    <View style={styleContent.columnDivider}>
                        <Text style={fontStyles.textLeftNormal}>:</Text>
                    </View>
                    <View style={styleContent.columnRight}>
                        <Text style={fontStyles.textLeftNormal}>{religion}</Text>
                    </View>
                </View>
                <View style={styleContent.gap_section_big}>
                </View>
                <View style={styleContent.row}>
                    <View style={styleContent.column}>
                        <Text style={fontStyles.textLeftNormal}>Date of Birth</Text>
                    </View>
                    <View style={styleContent.columnDivider}>
                        <Text style={fontStyles.textLeftNormal}>:</Text>
                    </View>
                    <View style={styleContent.columnRight}>
                        <Text style={fontStyles.textLeftNormal}>{dob}</Text>
                    </View>
                </View>

                <View style={styleContent.row}>
                    <View style={styleContent.column}>
                        <Text style={fontStyles.textLeftNormal}>Date of Admission</Text>
                    </View>
                    <View style={styleContent.columnDivider}>
                        <Text style={fontStyles.textLeftNormal}>:</Text>
                    </View>
                    <View style={styleContent.columnRight}>
                        <Text style={fontStyles.textLeftNormal}>{doa}</Text>
                    </View>
                </View>
                <View style={styleContent.gap_section_big}>
                </View>
                <View style={styleContent.row}>
                    <View style={styleContent.column}>
                        <Text style={fontStyles.textLeftNormal}>Education Board</Text>
                    </View>
                    <View style={styleContent.columnDivider}>
                        <Text style={fontStyles.textLeftNormal}>:</Text>
                    </View>
                    <View style={styleContent.columnRight}>
                        <Text style={fontStyles.textLeftNormal}>{educationBoard}</Text>
                    </View>
                </View>

                <View style={styleContent.row}>
                    <View style={styleContent.column}>
                        <Text style={fontStyles.textLeftNormal}>Last Class</Text>
                    </View>
                    <View style={styleContent.columnDivider}>
                        <Text style={fontStyles.textLeftNormal}>:</Text>
                    </View>
                    <View style={styleContent.columnRight}>
                        <Text style={fontStyles.textLeftNormal}>{lastClass}</Text>
                    </View>
                </View>

                <View style={styleContent.row}>
                    <View style={styleContent.column}>
                        <Text style={fontStyles.textLeftNormal}>Academic Year</Text>
                    </View>
                    <View style={styleContent.columnDivider}>
                        <Text style={fontStyles.textLeftNormal}>:</Text>
                    </View>
                    <View style={styleContent.columnRight}>
                        <Text style={fontStyles.textLeftNormal}>{academicYear}</Text>
                    </View>
                </View>
                <View style={styleContent.gap_section_big}>
                </View>
                <View style={styleContent.row}>
                </View><View style={styleContent.row}>
                    <View style={styleContent.column}>
                        <Text style={fontStyles.textLeftNormal}>Fees Cleared</Text>
                    </View>
                    <View style={styleContent.columnDivider}>
                        <Text style={fontStyles.textLeftNormal}>:</Text>
                    </View>
                    <View style={styleContent.columnRight}>
                        <Text style={fontStyles.textLeftNormal}>{flgFeesCleared ? 'Yes' : 'No'}</Text>
                    </View>
                </View>

                {!flgFeesCleared && (
                    <View style={styleContent.row}>
                        <View style={styleContent.column}>
                            <Text style={fontStyles.textLeftNormal}>Pending Fees</Text>
                        </View>
                    <View style={styleContent.columnDivider}>
                        <Text style={fontStyles.textLeftNormal}>:</Text>
                    </View>
                        <View style={styleContent.columnRight}>
                            <Text style={fontStyles.textLeftNormal}>{pendingFees}</Text>
                        </View>
                    </View>
                )}

                <View style={styleContent.row}>
                    <View style={styleContent.column}>
                        <Text style={fontStyles.textLeftNormal}>Other Dues</Text>
                    </View>
                    <View style={styleContent.columnDivider}>
                        <Text style={fontStyles.textLeftNormal}>:</Text>
                    </View>
                    <View style={styleContent.columnRight}>
                        <Text style={fontStyles.textLeftNormal}>{otherDues}</Text>
                    </View>
                </View>
                <View style={styleContent.gap_section_big}>
                </View>
                <View style={styleContent.row}>
                    <View style={styleContent.column}>
                        <Text style={fontStyles.textLeftNormal}>Attendance</Text>
                    </View>
                    <View style={styleContent.columnDivider}>
                        <Text style={fontStyles.textLeftNormal}>:</Text>
                    </View>
                    <View style={styleContent.columnRight}>
                        <Text style={fontStyles.textLeftNormal}>{attendance}</Text>
                    </View>
                </View>
                <View style={styleContent.gap_section}>
                </View>
                <View style={styleContent.row}>
                    <View style={styleContent.column}>
                        <Text style={fontStyles.textLeftNormal}>General Conduct</Text>
                    </View>
                    <View style={styleContent.columnDivider}>
                        <Text style={fontStyles.textLeftNormal}>:</Text>
                    </View>
                    <View style={styleContent.columnRight}>
                        <Text style={fontStyles.textLeftNormal}>{generalConduct}</Text>
                    </View>
                </View>
                <View style={styleContent.gap_section_big}>
                </View>
                <View style={styleContent.row}>
                    <View style={styleContent.column}>
                        <Text style={fontStyles.textLeftNormal}>Eligible for Promotion</Text>
                    </View>
                    <View style={styleContent.columnDivider}>
                        <Text style={fontStyles.textLeftNormal}>:</Text>
                    </View>
                    <View style={styleContent.columnRight}>
                        <Text style={fontStyles.textLeftNormal}>{flgEleigiblePromotion ? 'Yes' : 'No'}</Text>
                    </View>
                </View>
                <View style={styleContent.gap_section}>
                </View>
                <View style={styleContent.row}>
                    <View style={styleContent.column}>
                        <Text style={fontStyles.textLeftNormal}>Reason for TC</Text>
                    </View>
                    <View style={styleContent.columnDivider}>
                        <Text style={fontStyles.textLeftNormal}>:</Text>
                    </View>
                    <View style={styleContent.columnRight}>
                        <Text style={fontStyles.textLeftNormal}>{reasonTc}</Text>
                    </View>
                </View>
                <View style={styleContent.gap_section}>
                </View>
                <View style={styleContent.row}>
                    <View style={styleContent.column}>
                        <Text style={fontStyles.textLeftNormal}>Special Remarks</Text>
                    </View>
                    <View style={styleContent.columnDivider}>
                        <Text style={fontStyles.textLeftNormal}>:</Text>
                    </View>
                    <View style={styleContent.columnRight}>
                        <Text style={fontStyles.textLeftNormal}>{specialRemarks}</Text>
                    </View>
                </View>
            </View>

            {/* <View style={styleContent.notes}>
            </View> */}
        </View>
    );
}

export default TcContent;
