import React, { useEffect, useState } from "react";
import lstModules from "common/json/lstModules.json";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";

const httpURLAcdYr = lstModules['academicYear'].url + ":" + lstModules['academicYear'].port;
const tokenid = localStorage.getItem("tokenid");

const AcademicYearPopup = (props) => {
    const { academicYearId, setAcademicYearId } = props;

    const [lstAcademicYear, setLstAcademicYear] = useState([]);


    useEffect(() => {
        getLstAcdYrSvc();
    }, []);


    const getLstAcdYrSvc = () => {
        axios({
            url: httpURLAcdYr + '/sms/acdyr/list',
            method: "POST",
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true,
        })
            .then((response) => {
                if (response.data.SvcStatus === "Success") {
                    setLstAcademicYear(response.data.lstAcademicYear);
                } else {
                    toast.error(response.data.SvcMsg);
                    if (response.data.SvcMsg === "You are not Logged In") {
                        setTimeout(function () {
                            window.location.href = "/";
                        }, 2000);
                        return false;
                    } else if (response.data.SvcMsg === "Server Timed Out, Login Again") {
                        setTimeout(function () {
                            window.location.href = "/";
                        }, 2000);
                        return false;
                    }
                }
            })
            .catch((error) => {
                toast.error("Service failed " + error);
            });
    }

    return (
        <div className="col-sm-10 padding-remove">
            <select
                className="form-control"
                value={academicYearId}
                onChange={(e) => props.setAcademicYearId(e.target.value)}
            >
                <option value={0}>Academic Year</option>
                {lstAcademicYear &&
                    lstAcademicYear.map((academicYear) => (
                        <option
                            key={academicYear.academicYearId}
                            value={academicYear.academicYearId}>
                            {academicYear.academicYear}
                        </option>
                    ))
                }
            </select>
        </div>
    )
}

export default AcademicYearPopup;