import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import axios from 'axios';
import $ from 'jquery';
import '../css/verifyEmail.css'


var httpURL = "";
var queryString = require('querystring');
export default class verifyEmail extends Component {
    state = {
        tokenid: localStorage.getItem('tokenid')
    }

    componentDidMount = () => {
        var url_string = window.location.href;
        var url = new URL(url_string);
        var pId = url.searchParams.get("pId");
        var fId = url.searchParams.get("fId");
        var mId = url.searchParams.get("mId");
        var gId = url.searchParams.get("gId");
        var stuId = url.searchParams.get("stuId");
        var schId = url.searchParams.get("schId");


        if (pId === "true") {
            pId = 1;
            fId = 0;
            mId = 0;
            gId = 0;
        }
        else if (fId === "true") {
            pId = 0;
            fId = 1;
            mId = 0;
            gId = 0;
        }
        else if (mId === "true") {
            pId = 0;
            fId = 0;
            mId = 1;
            gId = 0;
        }
        else if (gId === "true") {
            pId = 0;
            fId = 0;
            mId = 0;
            gId = 1;
        }


        var status = { "pId": pId, "fId": fId, "mId": mId, "gId": gId, "stuId": stuId, "schId": schId }



        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/student/setEmailStatus',
            queryString.stringify({ status: JSON.stringify(status) }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            // // console.log(response);
            if (response.data.SvcStatus === 'Success') {
                $('#emailIcon').addClass('fa fa-check-circle success');
                $('#msg').html("Email Verfied!");
                $('#emailIcon , #msg').css({ color: 'green' });
            } else {
                $('#emailIcon').addClass('fa fa-exclamation-circle  warning');
                $('#msg').html("Email Not Verfied!");
                $('#emailIcon , #msg').css({ color: 'orange' });
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            $('#emailIcon').addClass('fa fa-exclamation-circle error');
            $('#msg').html("OOPs Something went wrong!");
            $('#emailIcon , #msg').css({ color: 'red' });
        });
    }

    render() {
        httpURL = lstModules['components'].url + ':' + lstModules['components'].port
        return (
            <div className="emailVerify">
                <i id="emailIcon" aria-hidden="true"></i>
                <p id="msg"></p>
            </div>
        )
    }
}
