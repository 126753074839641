import React, { useEffect, useState } from "react";
import 'common/css/master.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../css/studentTest.css';
import '../css/question.css';

const Answer = (props) => {

    const { lstOptions, optionSelected, handleChangeOption, flgAnswered } = props;


    useEffect(() => {
        // // console.log("Answer called");
        // // console.log(" flgAnswered", flgAnswered);
        // // console.log("lstOptions", lstOptions);
        // // console.log("optionSelected", optionSelected);
    }, [lstOptions, optionSelected, flgAnswered]);

    return (
        <div className="answer-section" >
            <div className="col-sm-10 col-xs-12 pull-right padding-remove" >
                {
                    lstOptions && lstOptions.length ?
                        lstOptions.map((option) => (
                            <div
                                key={option.optionKey}
                                className=" col-sm-12 col-xs-3 padding-remove"
                                style={{ alignContent: "center", pointerEvents: !flgAnswered ? "auto" : "none" }}
                                onClick={() => handleChangeOption(option.optionKey)}
                            >
                                <div className="option-block">
                                    <div className={optionSelected === option.optionKey ? "answer-selected" : "answer"}>
                                        <span>{option.optionKey}</span>
                                        <span className={optionSelected === option.optionKey ? "glyphicon glyphicon-ok" : ""} style={{ color: 'green' }}></span>
                                    </div>
                                </div>
                            </div>
                        ))
                        : null
                }
            </div>
        </div>
    );
}

export default Answer;