import React, { useEffect, useState } from 'react';
import { Text, View, Image } from '@react-pdf/renderer';

import { fontStyles, styleHeaderLogo, styleContent } from './styleTcPdf';

const TcHeader = (props) => {
    const { schoolLogo, schoolName, schoolAddress, schoolAffiliationNo } = props;

    useEffect(() => {
        // // console.log("TcHeader component called");
        // // console.log("schoolLogo", schoolLogo);
        // // console.log("schoolName", schoolName);
    }, [schoolLogo, schoolName]);

    return (
        <View style={styleHeaderLogo.header_logo_section}>
            <View style={styleHeaderLogo.header_left}>
                <Image style={styleHeaderLogo.image1} src={schoolLogo} />
            </View>
            <View style={styleHeaderLogo.header_center}>
                <Text style={fontStyles.textSchool}>
                    {schoolName}<Text style={{ textTransform: "capitalize" }}></Text>
                </Text>
                <View style={styleContent.gap_section_small}>
                </View>
                <Text style={fontStyles.textSchoolDtls}>
                    {schoolAddress}
                </Text>
                <Text style={fontStyles.textSchoolDtlsSmall}>
                    Affliation: {schoolAffiliationNo}
                </Text>
            </View>
            <View style={styleHeaderLogo.header_right}>
                <Text></Text>
            </View>
        </View>
    )
}

export default TcHeader;