import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getSlotListSvc, deleteSlotSvc } from "./SlotConfigSvc";
const controller = new AbortController();
var signal = controller.signal;

const SlotConfigTable = (props) => {
  const [lstSlot, setLstSlot] = useState([]);
  const [loader, setLoader] = useState("none");
  const [selSlotId, setSelSlotId] = useState('');
  useEffect(() => {
    setLoader("block");
    getSlotListSvc(cbSlotListResponse);
    // // console.log(props)
    setSelSlotId(props.slotId);
    return () => {
      controller.abort();
    };
  }, [props.slotId]);

  function cbSlotListResponse(data) {
    if (data.SvcStatus === "Success") {
      setLstSlot(data.lstSlot);
      setFoundClass(data.lstSlot);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }

  function editSlot(e, slotId, slotObj) {
    e.persist();
    // // console.log(slotId)
    props.setSlotForEdit(slotId,slotObj);
    props.openSlider();
  }
  function deleteSlot(pSlotId) {
    setLoader("block");
    deleteSlotSvc(pSlotId, cbDeleteSlot);
  }
  function cbDeleteSlot(data) {
    setLoader("none");
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
      // props.closeSlider();
      props.generateRandomNo();
    } else {
      toast.error(data.SvcMsg);
      // props.closeSlider();
    }
  }
  function handleSelSlotId(param) {
    setSelSlotId(param);
  }
  const [class2, setClass2] = useState("");
  const [foundClass, setFoundClass] = useState(lstSlot);
  const filterCls = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = foundClass.filter((user) => {
        return user.cls.toLowerCase().includes(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      setFoundClass(results);
    } else {
      setFoundClass(lstSlot);
      // If the text field is empty, show all users
    }

    setClass2(keyword);
  };
  const [invi1, setInvi1] = useState("");
  const filterInv1 = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = foundClass.filter((user) => {
        return user.invigilator1.toLowerCase().includes(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      setFoundClass(results);
    } else {
      setFoundClass(lstSlot);
      // If the text field is empty, show all users
    }

    setInvi1(keyword);
  };
  const [invi2, setInvi2] = useState("");
  const filterInv2 = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = foundClass.filter((user) => {
        return user.invigilator2.toLowerCase().includes(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      setFoundClass(results);
    } else {
      setFoundClass(lstSlot);
      // If the text field is empty, show all users
    }

    setInvi2(keyword);
  };

  return (
    <div style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>
      <table className="tableLayout1">
        <thead>
          <tr>
            <th>Date</th>
            <th>Time</th>
            <th className="text-left" style={{}}>
              <input
                type="text"
                onChange={filterCls}
                className="form-control inp-search"
                id="class"
                placeholder="Class"
              />
            </th>
            <th className="text-left" style={{}}>
              <input
                type="text"
                onChange={filterInv1}
                className="form-control inp-search"
                id="invgilator1"
                placeholder="Invgilator 1"
              />
            </th>
            <th className="text-left" style={{}}>
              <input
                type="text"
                onChange={filterInv2}
                className="form-control inp-search"
                id="invgilator2"
                placeholder="Invgilator 2"
              />
            </th>
            <th>Action</th>
          </tr>
        </thead>
      </table>
      <div className="tablebodyWrapper">
        <table className="tableLayout">
          <tbody>
            {foundClass &&
              foundClass.length > 0 &&
              foundClass.map((slot, idx) => {
                return (
                  <tr
                    onClick={(e) => handleSelSlotId(slot.slotId)}
                    className={slot.slotId === selSlotId ? "selected" : ""}
                    key={idx}
                  >
                    <td>{slot.dt}</td>
                    <td>{slot.tm}</td>
                    <td className="text-left">{slot.cls}</td>
                    <td className="text-left">{slot.invigilator1}</td>
                    <td className="text-left">{slot.invigilator2}</td>
                    <td>
                      <span
                        className="glyphicon glyphicon-edit"
                        onClick={(e) => editSlot(e, slot.slotId, slot)}
                        id="slotConfigBtn"
                      ></span>
                      <span
                        className="glyphicon glyphicon-trash"
                        onClick={(e) => deleteSlot(slot.slotId)}
                        id="slotConfigBtn"
                      ></span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default SlotConfigTable;
