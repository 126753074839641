import React, { useState, useEffect } from 'react';
import lstModules from 'common/json/lstModules.json';
import 'jquery-ui';
import '../css/wings.css';
import 'common/css/master.css';
import 'react-datepicker/dist/react-datepicker.css';

const WingsForm = (props) => {

    const [wingsData, setWingsData] = useState(props.wingsData)
    const [allDays, setAllDays] = useState({
        mon: props.wingsData.mon
        , tue: props.wingsData.tue
        , wed: props.wingsData.wed
        , thu: props.wingsData.thu
        , fri: props.wingsData.fri
        , sat: props.wingsData.sat
        , sun: props.wingsData.sun
    });

    useEffect(() => {
        // // console.log("WingsForm called props.wingsData", props.wingsData);
        setWingsData(props.wingsData)
        setAllDays({
            mon: props.wingsData.mon
            , tue: props.wingsData.tue
            , wed: props.wingsData.wed
            , thu: props.wingsData.thu
            , fri: props.wingsData.fri
            , sat: props.wingsData.sat
            , sun: props.wingsData.sun
        })
        // $('.dayCheckbox').prop('checked', true);
        // $('#sun').prop('checked', false)
    }, [props]);

    function handleChange(e) {
        setWingsData({ ...wingsData, [e.target.name]: e.target.value, });
    }

    function handleChangeCbox(e) {
        var pos = 0;
        var newDays = allDays;
        var value = e.target.checked
        // // console.log("newDays=" + JSON.stringify(newDays));
        if (e.target.id === 'mon') {
            newDays.mon = value;
        } else if (e.target.id === 'tue') {
            newDays.tue = value;
        } else if (e.target.id === 'wed') {
            newDays.wed = value;
        } else if (e.target.id === 'thu') {
            newDays.thu = value;
        } else if (e.target.id === 'fri') {
            newDays.fri = value;
        } else if (e.target.id === 'sat') {
            newDays.sat = value;
        } else if (e.target.id === 'sun') {
            newDays.sun = value;
        }
        // // console.log("newDays after=" + JSON.stringify(newDays));
        setAllDays(newDays);
    }

    function saveWings() {
        props.saveWings(wingsData, allDays)
    }

    return (
        <form id="wingsAddForm" className="form-horizontal formWrapper">
            <div className="form-group">
                <label className="control-label col-sm-5" >Wings<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                <div className="col-sm-6 padding-remove">
                    <input value={wingsData.wing} autoComplete="off" name="wing" id="wings" type="text"
                        className="form-control " placeholder="Enter wings"
                        onChange={handleChange} />
                </div>
            </div>

            <div className="form-group">
                <label className="control-label col-sm-5" >From Class<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                <div className="col-sm-6 padding-remove">
                    <select value={wingsData.fromClassId} name="fromClassId"
                        className="form-control" style={{ width: '100%' }} id="fromClassCmb"
                        onChange={handleChange}>
                        <option value={"Class"} key={0}>{"Class"}</option>
                        {props.lstClass.map((obj, idx) => {
                            return <option key={idx} value={obj.clsId}>{obj.cls}</option>
                        })}
                    </select></div>
            </div>
            <div className="form-group">
                <label className="control-label col-sm-5" >To Class<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                <div className="col-sm-6 padding-remove">
                    <select value={wingsData.toClassId} name="toClassId"
                        className="form-control" style={{ width: '100%' }} id="toClassCmb"
                        onChange={handleChange}>
                        <option value={"Class"} key={0}>{"Class"}</option>
                        {props.lstClass.map((obj) => {
                            return <option value={obj.clsId} key={obj.clsId}>{obj.cls}</option>
                        })}
                    </select>
                </div>
            </div>
            <div className="form-group">
                <label className="control-label col-sm-5" >Days<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                <div className="col-sm-6 padding-remove daysWrapper">
                    <div>
                        <span>M</span>
                        <input autoComplete="off" id="mon" type="checkbox" name="M"
                            className="form-control dayCheckbox "
                            defaultChecked={allDays.mon}
                            onClick={handleChangeCbox} />
                    </div>
                    <div>
                        <span>T</span><input autoComplete="off" id="tue" type="checkbox" name="T"
                            className="form-control dayCheckbox "
                            defaultChecked={allDays.tue}
                            onClick={handleChangeCbox} />
                    </div>
                    <div>
                        <span>W</span><input autoComplete="off" id="wed" type="checkbox" name="W"
                            className="form-control dayCheckbox "
                            defaultChecked={allDays.wed}
                            onClick={handleChangeCbox} />
                    </div>
                    <div>
                        <span>Th</span><input autoComplete="off" id="thu" type="checkbox" name="T"
                            className="form-control dayCheckbox "
                            defaultChecked={allDays.thu}
                            onClick={handleChangeCbox} />
                    </div>
                    <div>
                        <span>F</span><input autoComplete="off" id="fri" type="checkbox" name="F"
                            className="form-control dayCheckbox "
                            defaultChecked={allDays.fri}
                            onClick={handleChangeCbox} />
                    </div>
                    <div>
                        <span>S</span><input autoComplete="off" id="sat" type="checkbox" name="S"
                            className="form-control dayCheckbox "
                            defaultChecked={allDays.sat}
                            onClick={handleChangeCbox} />
                    </div>
                    <div>
                        <span>Su</span><input autoComplete="off" id="sun" type="checkbox" name="S"
                            className="form-control dayCheckbox "
                            defaultChecked={allDays.sun}
                            onClick={handleChangeCbox} />
                    </div>
                </div>
            </div>
            <div className="form-group" >
                <div className="col-sm-12 text-center userButtonWrap" >
                    <button type="button" style={{ marginTop: 20 }} title="Save" onClick={saveWings} className="btn btn-info">Save</button>
                </div>
            </div>
        </form>

    );
}

export default WingsForm;