import React, { useState, useEffect } from "react";
import axios from 'axios'
import { toast, ToastContainer } from "react-toastify";
import lstModules from 'common/json/lstModules.json'
const httpURL = lstModules['payrollSetup'].url + ':' + lstModules['payrollSetup'].port;
var tokenid = localStorage.getItem('tokenid');
function AllowancesTable(props){
  const [loader, setLoader] = useState('none')
    const [lstAllowance,setLstAllowance]=useState([])
    const [selRow,setSelRow]=useState()
    useEffect(()=>{
      setLoader('block')
        getAllowanceData(cbGetAllowanceData)
  
      },[]);
      const colgrp = (
        <colgroup>
          <col width="4%"></col>
          <col width="4%"></col>
          <col width="4%"></col>
          <col width="15%"></col>
        </colgroup>
      );
      function handleSelRow(param){
        setSelRow(param)

        props.getSelData(lstAllowance[param]) 
      }
      function getAllowanceData(callback){
          axios(
              {
                  url: httpURL +"/sms/allowance/list",
                  method: 'POST',
                  params: {},
                  headers: { 'tokenid': tokenid },
                  withCredentials: true
              }
          ).then(
              (Response) => {
                  var data = Response.data
      
                  callback(data)
      
              }
          ).catch((error) => {
              console.error(error)
          });
          }
      function cbGetAllowanceData(data){
          if(data.SvcStatus==='Success'){
            setLstAllowance(data.lstAllowance)
          }
          else {
            toast.error(data.SvcMsg)
          }
          setLoader('none')
      }
return(<div className="col-sm-10"style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
<div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
  <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
</div>
<table className="tableLayout1">
{colgrp}
<thead>
<tr>
  <th>Allowance Code</th>
  <th>Label</th>
  <th>Applied To Grade</th>
  <th>Applied To Role</th>
</tr>
</thead>
</table>
<div className='tablebodyWrapper'>
<table className='tableLayout'>
{colgrp}
<tbody>
{lstAllowance.map((obj,idx)=>{
  return(
    <tr key={idx} onClick={(e)=>handleSelRow(idx)} className={idx===selRow ? "selected": "none"}>
    <td>{obj.allowanceCode}</td> 
    <td>{obj.allowanceLabel}</td>
    <td>{obj.appliedGrades}</td>
    <td>{obj.appliedRoles}</td>

    </tr>
  )
})}
</tbody>
</table>
</div>
</div>)
}
export default AllowancesTable