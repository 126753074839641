import StudentSlider from "./studentSlider"
import StudentTable from "./studentTable"
import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import Header from "common/js/header/header.js";
import axios from 'axios'
import lstModules from 'common/json/lstModules.json';
function StudentTr() {
    const [open, setOpen] = useState(0)
    const [selStudentId, setSelStudentId] = useState(null)
    const [selObj, setSelObj] = useState({})
    function closeSlider() {
        setOpen(0)
        setSelObj({})
    }
    function getSelData(sId, obj) {
        setSelStudentId(sId)
        setSelObj(obj)
        setOpen(1)
    }
    return (
        <div>
            <ToastContainer autoClose={3000} hideProgressBar />
            <Header></Header>
            <div className="col-sm-12" style={{ paddingTop: '5px', width: '100%' }}>
                <StudentTable
                    getSelData={getSelData}
                />
                <div
                    className='TestSlider'
                    style={{ display: open === 1 ? 'block' : 'none' }}>
                    <StudentSlider
                        key={selStudentId}
                        closeSlider={closeSlider}
                        selStudentId={selStudentId}
                        selObj={selObj}
                    />
                </div>

            </div>
        </div>)
}
export default StudentTr