import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import { saveFacilitySvc } from './facilitySvc';


const FacilitySlider = (props) => {
    const { facilityId, facility, updateKey, deleteFacility, closeSlider } = props;

    const [editLoader, setEditLoader] = useState("none");
    const [editOpacity, setEditOpacity] = useState(1);

    const [facilityName, setFacilityName] = useState("");
    const [allowedClasses, setAllowedClasses] = useState(0);
    const [capacity, setCapacity] = useState(0);


    useEffect(() => {
        // console.log("FacilitySlider called");
        if (!!facilityId) {
            setFacilityName(facility.facility);
            setAllowedClasses(facility.allowedClasses);
            setCapacity(facility.capacity);
        }
    }, [facilityId, facility]);

    const saveFacility = () => {
        if (!facilityName) {
            toast.error("Facility name is required.");
            return;
        }
        if (!allowedClasses) {
            toast.error("Allowed classes are required.");
            return;
        }
        if (!capacity) {
            toast.error("Capacity is required.");
            return;
        }

        // console.log("saveFacility called");
        const saveFacilityObj = {
            "facilityId": facilityId || 0,
            "facility": facilityName,
            "allowedClasses": parseInt(allowedClasses),
            "capacity": parseInt(capacity)
        };

        setEditLoader("block");
        setEditOpacity(0.5);
        saveFacilitySvc(saveFacilityObj, saveFacilityResponse);
    };


    const saveFacilityResponse = async (data) => {
        // console.log("saveFacilityResponse called, data", data);
        if (data.status === "Success") {
            toast.success(data.message);
            await updateKey();
            closeSlider();
        } else if (data.status === "Failure") {
            handleServiceError(data.message);
        } else {
            toast.error("Service failed " + data.error);
        }
        setEditLoader("none");
        setEditOpacity(1);
    }

    const handleServiceError = (svcMsg) => {
        if (svcMsg === "You are not Logged In") {
            toast.error("You are not logged in. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else if (svcMsg === "Server Timed Out, Login Again") {
            toast.error("Server timed out. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else {
            toast.error(svcMsg);
        }
    };


    return (
        <div >
            <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={() => closeSlider()} style={{ right: "60%" }}>
                <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
            </button>
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '58%', top: '45%', display: editLoader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="col-md-12 col-xs-12 col-centered padding-remove "
                style={{
                    cursor: (editLoader === "none") ? 'pointer' : 'none',
                    pointerEvents: (editLoader === "none") ? 'auto' : 'none',
                    opacity: editOpacity
                }}>
                <div className="col-sm-12 col-md-12 padding-remove"
                    style={{ width: '94%', margin: '3% ', background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
                    <form
                        id="editFacilityForm"
                        className='form form-horizontal'
                        style={{ height: "300px", overflowX: 'hidden', padding: "20px 20px 5px 20px", }}
                    >
                        <div className="form-group col-md-12 " style={{ padding: "5px 0" }}>
                            <div className="form-group col-md-12 padding-remove">
                                <label className="control-label col-md-4 col-xs-6 " style={{ padding: "7px 0 0 0" }}>
                                    Facility:
                                </label>
                                <div className='col-md-6 col-sm-6 col-xs-12  '>
                                    <input
                                        type="text"
                                        className="form-control"
                                        maxLength={20}
                                        value={facilityName}
                                        onChange={(e) => setFacilityName(e.target.value)}
                                        placeholder='Sports Ground'
                                    ></input>
                                </div>
                            </div>
                            <div className="form-group col-md-12 padding-remove">
                                <label className="control-label col-md-4 " style={{ padding: "7px 0 0 0" }}>
                                    Allowed Classes:
                                </label>
                                <div className='col-sm-3 '>
                                    <input
                                        type="number"
                                        className="form-control"
                                        min="0"
                                        max="99"
                                        value={(facilityId === 0 && allowedClasses === 0) ? "" : allowedClasses}
                                        onChange={(e) => {
                                            const value = Math.max(0, Math.min(99, Number(e.target.value)));
                                            setAllowedClasses(value);
                                        }}
                                        placeholder="1"
                                    />
                                </div>
                            </div>
                            <div className="form-group col-md-12 padding-remove">
                                <label className="control-label col-md-4 " style={{ padding: "7px 0 0 0" }}>
                                    Capacity:
                                </label>
                                <div className='col-sm-3 '>
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={(facilityId === 0 && capacity === 0) ? "" : capacity}
                                        onChange={(e) => setCapacity(e.target.value)}
                                        placeholder="1"
                                    />
                                </div>
                            </div>
                            <div className='col-sm-10   padding-remove' style={{ height: "50px", marginTop: "80px" }}>
                                <div className="col-sm-2 col-md-2  padding-remove pull-right">
                                    <button type="button" className="btn btn-info " onClick={() => saveFacility()} >Save</button>
                                </div>
                                {/* <div className="col-sm-6 col-md-6  padding-remove">
                                <button type="button" className="btn btn-info " onClick={(e) => deleteFacility(e)} disabled={!facilityId}>Delete</button>
                                </div> */}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div >
    );
}

export default FacilitySlider;

