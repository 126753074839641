import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import Header from "common/js/header/header.js";
import GradeSlider from "./gradeSlider";
import GradeTable from "./gradeTable";
import axios from 'axios'
import lstModules from 'common/json/lstModules.json';
var tokenid = localStorage.getItem('tokenid');
function Grade(){
    const [lstGrade,setLstGrade]=useState([])
    const [randNo,setRandNo]=useState(0)
    const [selObj,setSelObj]=useState({})
    const [selIdx,setSelIdx]=useState()
    useEffect(()=>{
    },[]);
  
    function generateRandomNo() {
      var lRand= Math.floor(Math.random() * 999999);
      setRandNo(lRand)
  }

    function getSelData(param){
      setSelObj(param)
      setSelIdx(param.gradeId)
    }
    return(<div>
          <Header></Header>
          <ToastContainer autoClose={3000} hideProgressBar />
          <div className="col-sm-10 col-centered TestTblWrapper">
        <div className="col-sm-8">
           <GradeTable
           key={randNo}
           getSelData={getSelData}

           ></GradeTable>
        </div>
        <div className="col-sm-4"> 
       <GradeSlider
        key={selIdx}
        gradeObj={selObj}
        generateRandomNo={generateRandomNo}
        ></GradeSlider>
        </div>
        </div>

    </div>)
}
export default Grade;
