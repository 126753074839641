import React, { useEffect, useState } from "react";


const lstHour = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
const lstMinutes = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"];
const lstAMPM = ["AM", 'PM'];

const Time = (props) => {
    const { label, hour, setHour, minutes, setMinutes, ampm, setAMPM } = props;

    // useEffect(() => {
    // // console.log("setTime called");
    // // console.log("hour",hour);
    // // console.log("minutes",minutes);
    // // console.log("ampm",ampm);
    // }, []);

    return (
        <>
            <label className="control-label col-sm-6" >{label} <span style={{ color: 'red', marginLeft: 2 }}>* </span>  </label>
            <div className="col-sm-6 padding-remove ">
                <div className="col-sm-4 padding-remove ">
                    <select className="form-control" value={hour} onChange={(e) => setHour(e.target.value)}>
                        <option value="HH">HH</option>
                        {lstHour.map((hour) => (<option key={hour} value={hour}> {hour} </option>))}
                    </select>
                </div>
                <div className="col-sm-4 padding-remove ">
                    <select className="form-control" value={minutes} onChange={(e) => setMinutes(e.target.value)}>
                        <option value="MM">MM</option>
                        {lstMinutes.map((minutes) => (<option key={minutes} value={minutes}> {minutes} </option>))}
                    </select>
                </div>
                <div className="col-sm-4 padding-remove ">
                    <select className="form-control" value={ampm} onChange={(e) => setAMPM(e.target.value)}>
                        <option value="AM/PM">AM/PM</option>
                        {lstAMPM.map((ampm) => (<option key={ampm} value={ampm}> {ampm} </option>))}
                    </select>
                </div>
            </div>
        </>
    );

}

export default Time;