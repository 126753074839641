import React, { useEffect, useState } from 'react'
import WTestStudentRow from './WTestStudentRow';
import { set } from 'lodash';

var lstTmpStudentId = []
const WTestStudentTable = (props) => {
    const [lstTmpStudentId, setLstStudentTmpId] = useState([]);
    const [studentId, setStudentId] = useState(0);

    const colgrp = (
        <colgroup>
            <col width='5%'></col>
            <col width='15%'></col>
            <col width='10%'></col>
            <col width='10%'></col>
            <col width='13%'></col>
            <col width='12%'></col>
            <col width='10%'></col>
            <col width='13%'></col>
        </colgroup>
    )

    const selectAll = (e) => {
        var list_cbox = document.querySelectorAll('#tbodyStudent td input[type=checkbox]');
        list_cbox.forEach((cbox) => { if (cbox.checked !== e.target.checked) cbox.click(); })
    }


    return (
        <div style={{ pointerEvents: props.loader === 'none' ? 'auto' : 'none' }}>
            <table className='tableLayout1'>
                {colgrp}
                <thead>
                    <tr>
                        <th>Student Id</th>
                        <th>Student</th>
                        <th>Enrollment No.</th>
                        <th>OTP</th>
                        <th>OTP Generated At</th>
                        <th>Joined At</th>
                        <th>Nickname</th>
                        <th>Select
                            <div>
                                <input onChange={(e) => selectAll(e)} type="checkbox" id="cbxSelectAll" />
                            </div>
                        </th>
                    </tr>
                </thead>
            </table>
            <div className='tablebodyWrapper'>
                <table className='tableLayout'>
                    {colgrp}
                    <tbody id="tbodyStudent">
                        {props.lstStudent.map((obj, idx) => {
                            return (
                                <WTestStudentRow
                                    testId={props.testId}
                                    obj={obj}
                                    key={'sr_' + obj.studentId}
                                    lstTmpStudentId={lstTmpStudentId}
                                    setLstStudentId={props.setLstStudentId}
                                    studentId={studentId}
                                    setStudentId={setStudentId}
                                />
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default WTestStudentTable