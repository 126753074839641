import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

export default function LabelWise(props) {
  const [lstLabelCollection, setLstLabelCollection] = useState(props.lstLabelCollection);
  const [selRowId, setSelRowId] = useState(1)
  var mTotal = 0;
  var lTotal;
  useEffect(() => {
    setLstLabelCollection(props.lstLabelCollection);

  }, []);

  function setSelRow(param) {
    setSelRowId(param)
  }
  return (
    <div id="monthTable">

<div id='tableHead' className="col-sm-4 col-centered">
        <> Label Wise Planned Collection</>
      </div>
      <table className='col-sm-12'>
        <thead>
        <tr>
            {props.lstLabelCollection.map((obj,idx) => (
              <th key={idx}>{obj.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>



        <tr>
            {props.lstLabelCollection.map((obj,idx) => (
              <td style={{fontWeight: 'bold' }}key={idx}>{Number(obj.amt).toLocaleString("en-in")}</td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
}
