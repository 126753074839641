import React from 'react';
import { Text, View, Font } from '@react-pdf/renderer';

import { toast } from "react-toastify";
import { styleCoSchoScore } from './examReportCardStyles.js';

Font.register({
    family: 'Calibri'
    , fonts: [{ src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibri.ttf" }
        , { src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibrib.ttf", fontWeight: "bold" }], format: 'truetype'
});


const CoScholasticSection = (props) => {
    const { lstCoschoScore, exam } = props;
    return (
        <View style={styleCoSchoScore.container}>
            <View style={styleCoSchoScore.score_table}>
                <View style={styleCoSchoScore.exam_list}>
                    <View style={[styleCoSchoScore.exam_row, { flex: 1 }]}>
                        <View style={[styleCoSchoScore.top_left_cell, { flex: 1 }]}>
                            <Text style={styleCoSchoScore.text_schoExam_label}>Grade Subjects</Text>
                        </View>
                        <View style={[styleCoSchoScore.top_cell, { flex: 1 }]}>
                            <Text style={styleCoSchoScore.text_schoExam_label}>{exam}</Text>
                        </View>
                    </View>
                    {lstCoschoScore && lstCoschoScore.length ?
                        lstCoschoScore.map((subject, idx) => (
                            <View key={idx} style={[styleCoSchoScore.exam_row, { flex: 1 }]}>
                                <View style={[styleCoSchoScore.left_cell, { flex: 1 }]}>
                                    <Text style={styleCoSchoScore.text_subject_label}>{subject.subject}</Text>
                                </View>
                                <View style={[styleCoSchoScore.inner_cell, { flex: 1 }]}>
                                    <Text style={styleCoSchoScore.text_subject_score}>{subject.grade}</Text>
                                </View>
                            </View>
                        )) :
                        <View style={[styleCoSchoScore.exam_row, { flex: 1 }]}>
                            <View style={[styleCoSchoScore.left_cell, { flex: 1 }]}>
                            </View>
                        </View>
                    }
                </View>
                <View style={styleCoSchoScore.remarks_list}>
                    <View style={[styleCoSchoScore.remark_row, { flex: 3 }]}>
                        <Text style={styleCoSchoScore.text_remark_label}>REMARKS</Text>
                        <Text style={styleCoSchoScore.text_remark}></Text>
                    </View>
                    <View style={[styleCoSchoScore.result_row, { flex: 2 }]}>
                        <Text style={styleCoSchoScore.text_result_label}>ATTENDANCE</Text>
                        <Text style={styleCoSchoScore.text_result}></Text>
                    </View>
                </View>
            </View>
        </View>
    );
}

export default CoScholasticSection;