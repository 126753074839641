import React, { useEffect, useState } from 'react'

import "common/css/master.css";


const MonthlyDiarySummaryTbl = (props) => {
    const { lstSummary, sectionId, setSectionId, openDailySummary } = props;

    const [lstSummaryFiltered, setLstSummaryFiltered] = useState([]);
    const [selSectionId, setSelSectionId] = useState(0);

    useEffect(() => {
        // // console.log("useEffect in MonthlyDiarySummaryTbl component called,lstSumary", lstSummary);
        setLstSummaryFiltered(lstSummary);
    }, [lstSummary]);

    useEffect(() => {
        // // console.log("useEffect in MonthlyDiarySummaryTbl component called sectionId", sectionId);
        setSelSectionId(sectionId);
    }, [sectionId]);

    const colgrp = <colgroup>
        <col width="60"></col>
        <col width="30"></col>
        <col width="30"></col>
        <col width="30"></col>
        <col width="50"></col>
    </colgroup>



    return (
        <div >
            <div className="col-md-12 col-xs-12 col-centered padding-remove " >
                <table className="tableLayout1" >
                    {colgrp}
                    <thead>
                        <tr>
                            <th >
                                Class
                            </th>
                            <th >
                                Section
                            </th>
                            <th >
                                Students
                            </th>
                            <th >
                                % Completion
                            </th>
                            <th >
                                Action
                            </th>
                        </tr>
                    </thead>
                </table>
                <div className="tableDiv" style={{ height: "60vh" }}>
                    <table className="tableLayout">
                        {colgrp}
                        <tbody id="diaryMonthlySummaryTable" >
                            {lstSummaryFiltered &&
                                lstSummaryFiltered.map((section, i) => (
                                    <tr key={section.sectionId}
                                        onClick={() => setSectionId(section.sectionId)}
                                        className={section.sectionId === selSectionId ? "selected" : ''}>
                                        <td className="text-left">{section.cls}</td>
                                        <td className="text-center">{section.section}</td>
                                        <td className="text-center">{section.countStudents}</td>
                                        <td className="text-center">{section.completionPct}</td>
                                        <td className="text-center" >
                                            <span
                                                onClick={() => openDailySummary(section.clsId, section.sectionId)}
                                                title="Edit"
                                                style={{ textDecoration: "underline", cursor: "pointer", color: "blue", margin: "5px" }}
                                            >
                                                Details
                                            </span>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default MonthlyDiarySummaryTbl;