import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css'
import Header from 'common/js/header/header.js';
import StudentDtlsSlider from './StudentDtlsSlider'
import CompletionDtlsSlider from './CompletionDtlsSlider'
import OnTimeDtlsSlider from './OnTimeDtlsSlider'
import '../css/pDashboard.css';
import { getAttendanceSummarySvc, getTaskSummarySvc, getFeeSummarySvc, getTchSummarySvc, getEnquirySummarySvc, getFeedbackSummarySvc } from "./pDashboardSvc";

const PDashboard = () => {

    const [loader, setLoader] = useState("none")
    const [attendanceSummary, setAttendanceSummary] = useState({})
    const [taskSummary, setTaskSummary] = useState({})
    const [feeSummary, setFeeSummary] = useState({})
    const [tchSummary, setTchSummary] = useState({})
    const [feedbackSummary, setFeedbackSummary] = useState({})
    const [enquirySummary, setEnquirySummary] = useState({})
    const [flgSlider, setFlgSlider] = useState({})
    const [lstCompletionDtls, setLstCompletionDtls] = useState([])
    const [lstStudentDtls, setLstStudentDtls] = useState([])
    const [randNo, setRandNo] = useState(0)
    const [circleCls, setCircleCls] = useState('col-sm-12 col-centered subsec-circle')

    useEffect(() => {
        getAttendanceSummarySvc(cbAttendanceSummaryResponse);
        getFeeSummarySvc(cbFeeSummaryResponse);
        getTchSummarySvc(cbTchSummaryResponse);
        getTaskSummarySvc(cbTaskSummaryResponse);
        getEnquirySummarySvc(cbEnquirySummaryResponse);
        getFeedbackSummarySvc(cbFeedbackSummaryResponse);
        setCircleCls('col-sm-12 col-centered subsec-circle')
    }, [])

    function refreshScreen() {
        setFlgSlider(0);
        generateRandomNo();
        setCircleCls('col-sm-12 col-centered subsec-circle')
    }
    function generateRandomNo() {
        var lRandNo = Math.floor(Math.random() * 999999);
        // // console.log(lRandNo)
        setRandNo(lRandNo)
    }
    function cbAttendanceSummaryResponse(data) {
        if (data.SvcStatus === "Success") {
            setAttendanceSummary(data.attendance)
        } else {
            toast.error(data.SvcMsg)
        }
    }
    function cbFeeSummaryResponse(data) {
        if (data.SvcStatus === "Success") {
            setFeeSummary(data.fees)
        } else {
            toast.error(data.SvcMsg)
        }
    }
    function cbEnquirySummaryResponse(data) {
        if (data.SvcStatus === "Success") {
            setEnquirySummary(data.enquiry)
        } else {
            toast.error(data.SvcMsg)
        }
    }
    function cbTaskSummaryResponse(data) {
        if (data.SvcStatus === "Success") {
            setTaskSummary(data.task)
        } else {
            toast.error(data.SvcMsg)
        }
    }
    function cbFeedbackSummaryResponse(data) {
        if (data.SvcStatus === "Success") {
            setFeedbackSummary(data.feedback)
        } else {
            toast.error(data.SvcMsg)
        }
    }
    function cbTchSummaryResponse(data) {
        if (data.SvcStatus === "Success") {
            setTchSummary(data.teacher)
        } else {
            toast.error(data.SvcMsg)
        }
    }

    function setDataInSlider(pFlgSlider) {
        setFlgSlider(pFlgSlider)
        setCircleCls('col-sm-12 col-centered subsec-circle sel')

    }
    const getWingId = (wingId) => {
        // // console.log('wingId = ' + wingId)
    }
    return (
        <div className='MainContainer'
            style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <Header wingFromChild={getWingId} />

            <ToastContainer autoClose={3000} hideProgressBar />
            <div className="col-sm-12" style={{ top: '2%', height: '100%' }}>
                <div className="col-sm-12" style={{ height: '27%' }}>
                    <div className="col-sm-3 padding-remove card sec bg-purple" >
                        <div className="card-body">
                            <div className="card-title sec-title">
                                <div className="col-sm-11" style={{ textAlign: "center" }}>Attendance</div>
                                <div className="col-sm-1" onClick={() => window.open("/academicSchedule", "_blank")}>
                                    <span className="glyphicon glyphicon-cog" style={{ textAlign: 'right', cursor: 'pointer' }}></span>
                                </div>
                            </div>
                            <div className="col-sm-4 subsec" title="Percentage of students present">
                                <h6 className="card-subtitle mb-2 text-muted subsec-title">Students</h6>
                                <div className={flgSlider == 10 ? circleCls : "col-sm-12 col-centered subsec-circle"} onClick={(e) => setDataInSlider(10)}>{attendanceSummary.studentPct}%</div>
                            </div>
                            <div className="col-sm-4 subsec" title="Percent of classes where attendance is taken">
                                <h6 className="card-subtitle mb-2 text-muted subsec-title">Completion</h6>
                                <div className={flgSlider == 20 ? circleCls : "col-sm-12 col-centered subsec-circle"} onClick={() => setDataInSlider(20)}>{attendanceSummary.completionPct}%</div>
                            </div>
                            <div className="col-sm-4 subsec" title="Timely attendance completed percentage">
                                <h6 className="card-subtitle mb-2 text-muted subsec-title">On Time</h6>
                                <div className={flgSlider == 30 ? circleCls : "col-sm-12 col-centered subsec-circle"} onClick={() => setDataInSlider(30)} > {attendanceSummary.onTimePct}%</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3 padding-remove card sec bg-green" >
                        <div className="card-body">
                            <div className="card-title sec-title">Fees</div>
                            <div className="col-sm-4 subsec" title="Total fees collected in the current month">
                                <h6 className="card-subtitle mb-2 text-muted subsec-title">Collection</h6>
                                <div className="col-sm-12 col-centered subsec-rect">
                                    {feeSummary.collection}
                                </div>
                            </div>

                            <div className="col-sm-4 subsec" title="Percentage of students having no dues">
                                <h6 className="card-subtitle mb-2 text-muted subsec-title">Completion</h6>
                                <div className="col-sm-12 col-centered subsec-circle">
                                    {feeSummary.completionPct}%
                                </div>
                            </div>
                            <div className="col-sm-4 subsec" title="Count of students having fees overdue">
                                <h6 className="card-subtitle mb-2 text-muted subsec-title">Defaulters</h6>
                                <div className="col-sm-12 col-centered subsec-rect">
                                    {feeSummary.defaulter}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3 padding-remove card sec bg-deep-purple" >
                        <div className="card-body">
                            <div className="card-title sec-title">Teachers</div>

                            <div className="col-sm-4 subsec" title="Count of teachers not available today">
                                <h6 className="card-subtitle mb-2 text-muted subsec-title">Absent</h6>
                                <div className="col-sm-12 col-centered subsec-rect">{tchSummary.absent}</div>

                            </div>

                            <div className="col-sm-4 subsec" title="Count of periods impacted due to absence">
                                <h6 className="card-subtitle mb-2 text-muted subsec-title">Impact</h6>
                                <div className="col-sm-12 col-centered subsec-rect">{tchSummary.impact}</div>                            </div>


                            <div className="col-sm-4 subsec" title="Total free periods for today">
                                <h6 className="card-subtitle mb-2 text-muted subsec-title">Free Periods</h6>
                                <div className="col-sm-12 col-centered subsec-rect">
                                    {tchSummary.freePeriod}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12" style={{ height: '27%' }}>
                    <div className="col-sm-3 padding-remove card sec bg-orange" >
                        <div className="card-body">
                            <div className="card-title sec-title">
                                <div className="col-sm-11" style={{ textAlign: "center" }}>Enquiry</div>
                                <div className="col-sm-1" onClick={() => window.open("/tat", "_blank")}>
                                    <span className="glyphicon glyphicon-cog" style={{ textAlign: 'right', cursor: 'pointer' }}></span>
                                </div>
                            </div>
                            <div className="col-sm-4 subsec" title="Total enquiries received">
                                <h6 className="card-subtitle mb-2 text-muted subsec-title">Received</h6>
                                <div className="col-sm-12 col-centered subsec-rect">{enquirySummary.received}</div>
                            </div>
                            <div className="col-sm-4 subsec" title="Total registrations">
                                <h6 className="card-subtitle mb-2 text-muted subsec-title">Registration</h6>
                                <div className="col-sm-12 col-centered subsec-rect">{enquirySummary.registration}</div>
                            </div>
                            <div className="col-sm-4 subsec" title="Total admissions as percent of Registration">
                                <h6 className="card-subtitle mb-2 text-muted subsec-title">Admission</h6>
                                <div className="col-sm-12 col-centered subsec-circle">{enquirySummary.admissionRate}%</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3 padding-remove card sec bg-blue" >
                        <div className="card-body">
                            <div className="card-title sec-title">Tasks</div>
                            <div className="col-sm-6 subsec" title="Count of tasks where end date is not past or task is not completed" style={{ /* background: '#666666' */ }}>
                                <h6 className="card-subtitle mb-2 text-muted subsec-title">Count</h6>
                                <div className="col-sm-12 col-centered subsec-rect">
                                    {taskSummary.count}
                                </div>
                            </div>
                            <div className="col-sm-6 subsec" title="Percentage of tasks completed out of total tasks today (end date is not in the past)" style={{ /* background: '#666666' */ }}>
                                <h6 className="card-subtitle mb-2 text-muted subsec-title">Completed</h6>
                                <div className="col-sm-12 col-centered subsec-circle">
                                    {taskSummary.completed}%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3 padding-remove card sec bg-pink" >
                        <div className="card-body">
                            <div className="card-title sec-title">
                                <div className="col-sm-11" style={{ textAlign: "center" }}>
                                    Parent's Feedback
                                </div>
                                <div className="col-sm-1" onClick={() => window.open("/feedback", "_blank")}>
                                    <span className="glyphicon glyphicon-cog" style={{ textAlign: 'right', cursor: 'pointer' }}></span>
                                </div>
                            </div>
                            <div className="col-sm-4 subsec" title="Feedback received in last 30 days">
                                <h6 className="card-subtitle mb-2 text-muted subsec-title">Received</h6>
                                <div className="col-sm-12 col-centered subsec-rect">{feedbackSummary.received}</div>
                            </div>
                            <div className="col-sm-4 subsec" title="Feedbacks open">
                                <h6 className="card-subtitle mb-2 text-muted subsec-title">Open</h6>
                                <div className="col-sm-12 col-centered subsec-rect">{feedbackSummary.open}</div>

                            </div>
                            <div className="col-sm-4 subsec" title="Action delayed">
                                <h6 className="card-subtitle mb-2 text-muted subsec-title">Delayed</h6>
                                <div className="col-sm-12 col-centered subsec-rect">{feedbackSummary.delayRate}</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {flgSlider == 10 && <div style={{ width: '85%', display: flgSlider == 10 ? 'block' : 'none' }} className='enquirySlider'>
                <button type="button" className='btn btn-info text-center btnCloseDiv' style={{ right: '85%' }} onClick={refreshScreen}>
                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                </button>
                <StudentDtlsSlider
                    closeSlider={refreshScreen}
                    flgSlider={flgSlider}
                />
            </div>}
            {flgSlider == 20 && <div style={{ width: '85%', display: flgSlider == 20 ? 'block' : 'none' }} className='enquirySlider'>
                <button type="button" className='btn btn-info text-center btnCloseDiv' style={{ right: '85%' }} onClick={refreshScreen}>
                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                </button>
                <CompletionDtlsSlider
                    closeSlider={refreshScreen}
                    flgSlider={flgSlider}
                />
            </div>}
            {flgSlider == 30 && <div style={{ width: '85%', display: flgSlider == 30 ? 'block' : 'none' }} className='enquirySlider'>
                <button type="button" className='btn btn-info text-center btnCloseDiv' style={{ right: '85%' }} onClick={refreshScreen}>
                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                </button>
                <OnTimeDtlsSlider
                    closeSlider={refreshScreen}
                    flgSlider={flgSlider}
                />
            </div>}


        </div >
    )
}

export default PDashboard