import React, { useState } from "react";
import { searchBookBorrowerSvc } from "./returnBooksSvc";
import { toast } from "react-toastify";

export function BookBorrowerSearchBar(props) {
  const [keyBorrower, setKeyBorrower] = useState("");
  const [keyBookNo, setKeyBookNo] = useState("");
  const [flgType, setFlgType] = useState("Student");

  function searchBorrower() {
    var flgStaff = flgType === "Staff";
    props.searchBookBorrower({
      keyBorrower: keyBorrower,
      flgStaff: flgStaff,
      keyBookNo: keyBookNo,
    });
  }

  return (
    <div>
      <div className="col-sm-12">
        <div className="form-group">
          <div className="col-sm-2">
            <input
              autoComplete="off"
              id="bookNoKey"
              type="text"
              onChange={(e) => setKeyBookNo(e.target.value)}
              className="form-control "
              placeholder="Book No."
            />
          </div>
          <div className="col-sm-2 padding-remove">
            <input
              autoComplete="off"
              id="borrowerKey"
              type="text"
              onChange={(e) => setKeyBorrower(e.target.value)}
              className="form-control "
              placeholder="Enrollment No./Emp. Code"
            />
          </div>

          <div className="col-sm-4 padding-remove">
            <div className="form-group">
              <label className="col-sm-3">
                Borrower<span className="red-color">*</span>
              </label>
              <div className="col-sm-5 padding-remove">
                <label className="radio-inline">
                  <input
                    type="radio"
                    name="type"
                    value="Student"
                    checked={flgType === "Student"}
                    onChange={(e) => setFlgType(e.target.value)}
                  ></input>
                  Student
                </label>
                <label className="radio-inline">
                  <input
                    type="radio"
                    name="type"
                    value="Staff"
                    checked={flgType === "Staff"}
                    onChange={(e) => setFlgType(e.target.value)}
                  ></input>
                  Staff
                </label>
              </div>
              <div className="col-sm-3">
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={() => searchBorrower()}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
