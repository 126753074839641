import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import "common/css/master.css";
import "../css/student.css";
import { getCityListSvc, getParentDtlsSvc, saveParentDetailsSvc, reverifyEmailSvc, deleteParentSvc} from "./studentParentDetailsSvc.js";


const StudentParentDetails = (props) => {
    const { setLoader, setOpacity, studentId, flgDisabled, lstStates } = props;


    const [lstState, setLstState] = useState([]);
    // State initialization for father details
    const [fName, setFName] = useState("");
    const [fId, setFId] = useState(0);
    const [fAddress1, setFAddress1] = useState("");
    const [fAddress2, setFAddress2] = useState("");
    const [fStateId, setFStateId] = useState(0);
    const [fCityId, setFCityId] = useState(0);
    const [fState, setFState] = useState("");
    const [fCity, setFCity] = useState("");
    const [fPin, setFPin] = useState("");
    const [fEmail, setFEmail] = useState("");
    const [fEmailStatus, setFEmailStatus] = useState("");
    const [fMobileNo, setFMobileNo] = useState("");
    const [lstCityF, setLstCityF] = useState([]);
    // State initialization for mother details
    const [mName, setMName] = useState("");
    const [mId, setMId] = useState(0);
    const [mAddress1, setMAddress1] = useState("");
    const [mAddress2, setMAddress2] = useState("");
    const [mStateId, setMStateId] = useState(0);
    const [mCityId, setMCityId] = useState(0);
    const [mState, setMState] = useState("");
    const [mCity, setMCity] = useState("");
    const [mPin, setMPin] = useState("");
    const [mEmail, setMEmail] = useState("");
    const [mEmailStatus, setMEmailStatus] = useState("");
    const [mMobileNo, setMMobileNo] = useState("");
    const [lstCityM, setLstCityM] = useState([]);
    // State initialization for guardian details
    const [gName, setGName] = useState("");
    const [gId, setGId] = useState(0);
    const [gAddress1, setGAddress1] = useState("");
    const [gAddress2, setGAddress2] = useState("");
    const [gStateId, setGStateId] = useState(0);
    const [gCityId, setGCityId] = useState(0);
    const [gState, setGState] = useState("");
    const [gCity, setGCity] = useState("");
    const [gPin, setGPin] = useState("");
    const [gEmail, setGEmail] = useState("");
    const [gEmailStatus, setGEmailStatus] = useState("");
    const [gMobileNo, setGMobileNo] = useState("");
    const [lstCityG, setLstCityG] = useState([]);
    const schoolId = localStorage.getItem("schoolId");
    const [parentDtlsKey, setParentDtlsKey] = useState(0);

    useEffect(() => {
        // // console.log("StudentParentDetails called, studentId");
        setLstState(lstStates);
        if (!flgDisabled) {
            getParentDetails(studentId);
        }
    }, [studentId, flgDisabled, parentDtlsKey, lstStates]);

    const getParentDetails = (sId) => {
        // // console.log("getParentDetails called, studentId", studentId);
        setLoader("block");
        setOpacity(0.5);
        getParentDtlsSvc(sId, parentDetailsResponse);
    }

    const parentDetailsResponse = (response) => {
        if (response.status === 'Success') {
            setParentsData(response.parents);
        } else {
            handleServiceError(response.message);
        }
        setLoader("none");
        setOpacity(1);
    }

    const setParentsData = (nParentDtls) => {
        // // console.log("setParentData called, nParentDtls", nParentDtls);
        // Set father details
        setFName(nParentDtls.father.name);
        setFId(nParentDtls.father.id);
        setFAddress1(nParentDtls.father.address1);
        setFAddress2(nParentDtls.father.address2);
        setFStateId(nParentDtls.father.stateId);
        setFState(nParentDtls.father.state);
        setFCityId(nParentDtls.father.cityId);
        setFCity(nParentDtls.father.city);
        setFPin(nParentDtls.father.pin);
        setFEmail(nParentDtls.father.eMail);
        setFEmailStatus(nParentDtls.father.emailStatus);
        setFMobileNo(nParentDtls.father.phoneNo);
        // Set mother details
        setMName(nParentDtls.mother.name);
        setMId(nParentDtls.mother.id);
        setMAddress1(nParentDtls.mother.address1);
        setMAddress2(nParentDtls.mother.address2);
        setMStateId(nParentDtls.mother.stateId);
        setMState(nParentDtls.mother.state);
        setMCityId(nParentDtls.mother.cityId);
        setMCity(nParentDtls.mother.city);
        setMPin(nParentDtls.mother.pin);
        setMEmail(nParentDtls.mother.eMail);
        setMEmailStatus(nParentDtls.mother.emailStatus);
        setMMobileNo(nParentDtls.mother.phoneNo);
        // Set guardian details
        setGName(nParentDtls.guardian.name);
        setGId(nParentDtls.guardian.id);
        setGAddress1(nParentDtls.guardian.address1);
        setGAddress2(nParentDtls.guardian.address2);
        setGStateId(nParentDtls.guardian.stateId);
        setGState(nParentDtls.guardian.state);
        setGCityId(nParentDtls.guardian.cityId);
        setGCity(nParentDtls.guardian.city);
        setGPin(nParentDtls.guardian.pin);
        setGEmail(nParentDtls.guardian.eMail);
        setGEmailStatus(nParentDtls.guardian.emailStatus);
        setGMobileNo(nParentDtls.guardian.phoneNo);
    }


    const saveParentDetails = () => {
        // // console.log("saveParentDetails called");
        const parentsObj = {
            "studentId": studentId,
            "schoolId": schoolId,
            "father": {
                "name": fName,
                "id": fId,
                "phoneNo": fMobileNo,
                "address1": fAddress1,
                "address2": fAddress2,
                "stateId": fStateId,
                "cityId": fCityId,
                "state": fState,
                "city": fCity,
                "pin": fPin,
                "eMail": fEmail,
                "emailStatus": fEmailStatus
            },
            "mother": {
                "name": mName,
                "id": mId,
                "phoneNo": mMobileNo,
                "address1": mAddress1,
                "address2": mAddress2,
                "stateId": mStateId,
                "cityId": mCityId,
                "state": mState,
                "city": mCity,
                "pin": mPin,
                "eMail": mEmail,
                "emailStatus": mEmailStatus
            },
            "guardian": {
                "name": gName,
                "id": gId,
                "phoneNo": gMobileNo,
                "address1": gAddress1,
                "address2": gAddress2,
                "stateId": gStateId,
                "cityId": gCityId,
                "state": gState,
                "city": gCity,
                "pin": gPin,
                "eMail": gEmail,
                "emailStatus": gEmailStatus
            }
        }
        setLoader("block");
        setOpacity(0.5);
        saveParentDetailsSvc(parentsObj, saveParentDetailsResponse);
    }

    const saveParentDetailsResponse = (response) => {
        if (response.status === 'Success') {
            toast.success(response.message);
        } else {
            handleServiceError(response.message);
        }
        setLoader("none");
        setOpacity(1);
    }

    const handleServiceError = (svcMsg) => {
        if (svcMsg === "You are not Logged In") {
            toast.error("You are not logged in. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else if (svcMsg === "Server Timed Out, Login Again") {
            toast.error("Server timed out. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else {
            toast.error(svcMsg);
        }
    };

    const handleFatherStateChange = (fStateId) => {
        // // console.log("handleFatherStateChange called, fStateId called");
        setFStateId(fStateId);
        setFState(getStateById(fStateId));
        setLoader("block");
        setOpacity(0.5);
        getCityListSvc(fStateId, handleFatherCityListResponse);
    }

    const handleFatherCityListResponse = (response) => {
        // // console.log("response in handleGetCityListResponse, response", response);
        if (response.status === 'Success') {
            setLstCityF(response.lstCity);
            // Add any additional processing if needed
        } else {
            handleServiceError(response.message);
        }
        setLoader("none");
        setOpacity(1);
    };


    const handleMotherStateChange = (mStateId) => {
        // // console.log("handleMotherStateChange called, fStateId called");
        setMStateId(mStateId);
        setMState(getStateById(mStateId));
        setLoader("block");
        setOpacity(0.5);
        getCityListSvc(mStateId, handleMotherCityListResponse);
    }

    const handleMotherCityListResponse = (response) => {
        // // console.log("response in handleMotherCityListResponse, response", response);
        if (response.status === 'Success') {
            setLstCityM(response.lstCity);
            // Add any additional processing if needed
        } else {
            handleServiceError(response.message);
        }
        setLoader("none");
        setOpacity(1);
    };


    const handleGuardianStateChange = (gStateId) => {
        // // console.log("handleGuardianStateChange called, fStateId called")
        setGStateId(gStateId);
        setGState(getStateById(gStateId));
        setLoader("block");
        setOpacity(0.5);
        getCityListSvc(gStateId, handleGuardianCityListResponse);
    }

    const handleGuardianCityListResponse = (response) => {
        // // console.log("response in hanleGuardianCityListResponse, response", response);
        if (response.status === 'Success') {
            setLstCityG(response.lstCity);
            // Add any additional processing if needed
        } else {
            handleServiceError(response.message);
        }
        setLoader("none");
        setOpacity(1);
    };

    const reverifyFEmail = () => {
        // // console.log("reverifyFEmail called");
        if (fEmail === "") {
            toast.warning("Please Enter Father Email");
            return false;
        }
        const resendDetails = {
            pId: 0,
            fId: 1,
            mId: 0,
            gId: 0,
            email: fEmail,
            stuId: studentId,
            schId: schoolId,
            name: fName,
        };
        setLoader("block");
        setOpacity(0.5);
        reverifyEmailSvc(resendDetails, reverifyEmailResponse);
    }

    const reverifyMEmail = () => {
        // // console.log("reveriMyMEmail called");
        if (mEmail === "") {
            toast.warning("Please Enter Mother Email");
            return false;
        }
        const resendDetails = {
            pId: 0,
            fId: 0,
            mId: 1,
            gId: 0,
            email: mEmail,
            stuId: studentId,
            schId: schoolId,
            name: mName,
        };
        setLoader("block");
        setOpacity(0.5);
        reverifyEmailSvc(resendDetails, reverifyEmailResponse);
    }

    const reverifyGEmail = () => {
        // // console.log("reverifyGEmail called");
        if (gEmail === "") {
            toast.warning("Please Enter Guardian Email");
            return false;
        }
        const resendDetails = {
            pId: 0,
            fId: 0,
            mId: 0,
            gId: 1,
            email: gEmail,
            stuId: studentId,
            schId: schoolId,
            name: gName,
        };
        setLoader("block");
        setOpacity(0.5);
        reverifyEmailSvc(resendDetails, reverifyEmailResponse);
    }

    const reverifyEmailResponse = (response) => {
        // // console.log("reverifyEmailResponse called");
        if (response.status === 'Success') {
            toast.success(response.message);
        } else {
            handleServiceError(response.message);
        }
        setLoader("none");
        setOpacity(1);
    }

    const deleteFatherDetails = () => {
        // // console.log("deleteFatherDetails called");
        deleteParentDetails(fId, 20);
    }

    const deleteMotherDetails = () => {
        // // console.log("deleteMotherDetails called");
        deleteParentDetails(mId, 30);
    }

    const deleteGuardianDetails = () => {
        // // console.log("deleteGuardianDetails called");
        deleteParentDetails(gId, 40);
    }

    const deleteParentDetails = (parentId, pType) => {
        // // console.log("deleteParentDetails called, parentId", parentId, ", pType", pType);
        setLoader("block");
        setOpacity(0.5);
        deleteParentSvc(studentId, parentId, pType, deleteParentResponse);
    }

    const deleteParentResponse = (response) => {
        // // console.log("deleteParentResponse called");
        if (response.status === 'Success') {
            toast.success(response.message);
            updateKey();
        } else {
            handleServiceError(response.message);
        }
        setLoader("none");
        setOpacity(1);
    }



    const handleFCityChange = (fCityId) => {
        // // console.log('handleFCityChange called, fCityId', fCityId);
        setFCityId(fCityId);
        setFCity(getCityById(lstCityF, fCityId));
    }

    const handleGCityChange = (gCityId) => {
        // // console.log('handleGCityChange called, gCityId', gCityId);
        setGCityId(gCityId);
        setGCity(getCityById(lstCityG, gCityId)); // assuming lstCityG is the array of guardian cities
    }

    const handleMCityChange = (mCityId) => {
        // // console.log('handleMCityChange called, mCityId', mCityId);
        setMCityId(mCityId);
        setMCity(getCityById(lstCityM, mCityId)); // assuming lstCityM is the array of mother cities
    }

    const getStateById = (stateId) => {
        const state = lstState.find(element => element.stateId === stateId);
        return state ? state.state : 'State not found';
    };

    const getCityById = (citiesArray, cityId) => {
        const city = citiesArray.find(element => element.cityId === cityId);
        return city ? city.city : 'City not found';
    };

    const updateKey = () => {
        // // console.log("updateKey called");
        setParentDtlsKey(prevKey => prevKey + 1);
    }


    return (
        <div className="col-sm-12 col-xs-12 padding-remove">
            <div className="col-sm-12 padding-remove">
                <div
                    className="col-sm-12 section1"
                    style={{ paddingTop: "6px", top: "2px", width: "100%" }}
                >
                    <div className="">
                        <label className="control-label col-sm-4">
                            Parents/Guardian
                        </label>
                    </div>
                </div>
                <form
                    id="parentsForm"
                    className="form-horizontal padding-remove"
                >
                    <div className="col-sm-4">
                        <div className="col-sm-12 section1">
                            <div className="">
                                <label className="control-label col-sm-4">
                                    Father
                                </label>
                            </div>
                        </div>
                        <div className="col-sm-12 shadowShow padding-remove">
                            <div className="col-sm-12">
                                <div style={{ display: "none" }}>
                                    <label className="control-label col-sm-4">
                                        Id:
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Father Id"
                                            id="inp_fatherId"
                                            value={fId}
                                            onChange={(e) => setFId(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        Name:
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Father's Name"
                                            id="inp_fathername"
                                            value={fName}
                                            onChange={(e) => setFName(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        Phone No:
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Phone No"
                                            id="inp_fphone"
                                            value={fMobileNo}
                                            onChange={(e) => setFMobileNo(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        Address 1:
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Address 1"
                                            id="inp_faddress1"
                                            value={fAddress1}
                                            onChange={(e) => setFAddress1(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        Address 2:
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Address 2"
                                            id="inp_faddress2"
                                            value={fAddress2}
                                            onChange={(e) => setFAddress2(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        State:
                                    </label>
                                    <div className="col-sm-7">
                                        <select
                                            className="form-control"
                                            value={fStateId}
                                            onChange={(e) => handleFatherStateChange(e.target.value)}
                                            disabled={flgDisabled}
                                        >
                                            <option value={0}>State</option>
                                            {lstState ?
                                                lstState.map((state) => (
                                                    <option
                                                        key={state.stateId}
                                                        value={state.stateId}>
                                                        {state.state}
                                                    </option>
                                                )) : null
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        City:
                                    </label>
                                    <div className="col-sm-7">
                                        <select
                                            className='form-control'
                                            // style={{border:"1px solid #ccc"}}
                                            value={fCityId}
                                            onChange={e => handleFCityChange(e.target.value)}
                                            disabled={flgDisabled}
                                        >
                                            <option value={""}>City</option>
                                            {lstCityF ?
                                                lstCityF.map((city, idx) => {
                                                    return (
                                                        <option key={idx} value={city.cityId}>
                                                            {city.city}
                                                        </option>
                                                    )
                                                }) : null
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        Pin:
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Pin"
                                            id="inp_fpin"
                                            value={fPin}
                                            onChange={(e) => setFPin(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        E-Mail Id:
                                    </label>
                                    <div className="col-sm-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Email"
                                            id="inp_femail"
                                            value={fEmail}
                                            onChange={(e) => setFEmail(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                    <i id="emailStatusIconF" aria-hidden="true"></i>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-4 emailStatusHide">
                                        Email Status:
                                    </label>
                                    <div className="col-sm-2 emailStatusHide">
                                        <select
                                            className="form-control"
                                            id="inp_fEmailStatus"
                                            value={fEmailStatus}
                                            onChange={(e) => setFEmailStatus(e.target.value)}
                                            disabled
                                        />
                                    </div>
                                    <span style={{ marginRight: "15px" }}>
                                        <a
                                            className={flgDisabled ? "reverify-disabled" : "deleteDtls"}
                                            id="pDelete"
                                            title="Delete Father Details"
                                            onClick={deleteFatherDetails}
                                            href="#!"
                                        >
                                            Delete Details
                                        </a>
                                    </span>

                                    <span>
                                        <a
                                            // className="reverify"
                                            className="reverify-disabled"
                                            id="fReverify"
                                            title="Reverify Father Email"
                                            onClick={reverifyFEmail}
                                            href="#!"
                                            disabled
                                        >
                                            Reverify
                                        </a>
                                    </span>
                                </div>
                                {/* <div className="form-group" style={{ marginTop: 50 }}>
                              <div className="col-sm-4 col-centered text-center ">
                                <button
                                  type="button"
                                  // style={{width:"80%"}}
                                  onClick={deleteFatherDetails}
                                  className="btn btn-danger"
                                >
                                  Delete Details
                                </button>
                              </div>
                            </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="col-sm-12 section1">
                            <div className="">
                                <label className="control-label col-sm-4">
                                    Mother
                                </label>
                            </div>
                        </div>
                        <div className="col-sm-12 shadowShow padding-remove">
                            <div className="col-sm-12">
                                <div style={{ display: "none" }}>
                                    <label className="control-label col-sm-4">
                                        Id:
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Mother Id"
                                            id="inp_motherId"
                                            value={mId}
                                            onChange={(e) => setMId(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        Name:
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Mother's Name"
                                            id="inp_mothername"
                                            value={mName}
                                            onChange={(e) => setMName(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        Phone No:
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Phone No"
                                            id="inp_mphone"
                                            value={setMMobileNo}
                                            onChange={(e) => setMMobileNo(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        Address 1:
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Address 1"
                                            id="inp_maddress1"
                                            value={mAddress1}
                                            onChange={(e) => setMAddress1(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        Address 2:
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Address 2"
                                            id="inp_maddress2"
                                            value={mAddress2}
                                            onChange={(e) => setMAddress2(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        State:
                                    </label>
                                    <div className="col-sm-7">
                                        <select
                                            className="form-control"
                                            value={mStateId}
                                            onChange={(e) => handleMotherStateChange(e.target.value)}
                                            disabled={flgDisabled}
                                        >
                                            <option value={0}>State</option>
                                            {lstState ?
                                                lstState.map((state) => (
                                                    <option
                                                        key={state.stateId}
                                                        value={state.stateId}>
                                                        {state.state}
                                                    </option>
                                                )) : null
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        City:
                                    </label>
                                    <div className="col-sm-7">
                                        <select
                                            className='form-control'
                                            // style={{border:"1px solid #ccc"}}
                                            value={mCityId}
                                            onChange={e => handleMCityChange(e.target.value)}
                                            disabled={flgDisabled}
                                        >
                                            <option value={""}>City</option>
                                            {lstCityM ?
                                                lstCityM.map((city, idx) => {
                                                    return (
                                                        <option key={idx} value={city.cityId}>
                                                            {city.city}
                                                        </option>
                                                    )
                                                }) : null
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        Pin:
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Pin"
                                            id="inp_mpin"
                                            value={mPin}
                                            onChange={(e) => setMPin(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        E-Mail Id:
                                    </label>
                                    <div className="col-sm-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Email"
                                            id="inp_memail"
                                            value={setMEmail}
                                            onChange={(e) => setMEmail(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                    <i id="emailStatusIconM" aria-hidden="true"></i>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-4 emailStatusHide">
                                        Email Status:
                                    </label>
                                    <div className="col-sm-2 emailStatusHide">
                                        <select
                                            className="form-control"
                                            id="inp_mEmailStatus"
                                            disabled
                                        />
                                    </div>
                                    <span style={{ marginRight: "15px" }}>
                                        <a
                                            className={flgDisabled ? "reverify-disabled" : "deleteDtls"}
                                            id="mDelete"
                                            title="Delete Mother Details"
                                            onClick={deleteMotherDetails}
                                            href="#!"
                                        >
                                            Delete Details
                                        </a>
                                    </span>

                                    <span>
                                        <a
                                            // className="reverify"
                                            className="reverify-disabled"
                                            id="mReverify"
                                            title="Reverify Mother Email"
                                            onClick={reverifyMEmail}
                                            href="#!"
                                            disabled
                                        >
                                            Reverify
                                        </a>
                                    </span>
                                </div>
                                {/* <div className="form-group" style={{ marginTop: 50 }}>
                              <div className="col-sm-4 col-centered text-center ">
                                <button
                                  type="button"
                                  // style={{width:"80%"}}
                                  onClick={deleteMotherDetails}
                                  className="btn btn-danger"
                                >
                                  Delete Details
                                </button>
                              </div>
                            </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-4">
                        <div className="col-sm-12 section1">
                            <div className="">
                                <label className="control-label col-sm-4">
                                    Guardian
                                </label>
                            </div>
                        </div>
                        <div className="col-sm-12 shadowShow padding-remove">
                            <div className="col-sm-12">
                                <div style={{ display: "none" }}>
                                    <label className="control-label col-sm-4">
                                        Id:
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Guardian Id"
                                            id="inp_guardianId"
                                            value={gId}
                                            onChange={(e) => setGId(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        Name:
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Guardian's Name"
                                            id="inp_guardianname"
                                            value={gName}
                                            onChange={(e) => setGName(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        Phone No:
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Phone No"
                                            id="inp_gphone"
                                            value={gMobileNo}
                                            onChange={(e) => setGMobileNo(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        Address 1:
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Address 1"
                                            id="inp_gaddress1"
                                            value={gAddress1}
                                            onChange={(e) => setGAddress1(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        Address 2:
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Address 2"
                                            id="inp_gaddress2"
                                            value={gAddress2}
                                            onChange={(e) => setGAddress2(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        State:
                                    </label>
                                    <div className="col-sm-7">
                                        <select
                                            className="form-control"
                                            value={gStateId}
                                            onChange={(e) => handleGuardianStateChange(e.target.value)}
                                            disabled={flgDisabled}
                                        >
                                            <option value={0}>State</option>
                                            {lstState ?
                                                lstState.map((state) => (
                                                    <option
                                                        key={state.stateId}
                                                        value={state.stateId}>
                                                        {state.state}
                                                    </option>
                                                )) : null
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        City:
                                    </label>
                                    <div className="col-sm-7">
                                        <select
                                            className='form-control'
                                            // style={{border:"1px solid #ccc"}}
                                            value={gCityId}
                                            onChange={e => handleGCityChange(e.target.value)}
                                            disabled={flgDisabled}
                                        >
                                            <option value={""}>City</option>
                                            {lstCityG ?
                                                lstCityG.map((city, idx) => {
                                                    return (
                                                        <option key={idx} value={city.cityId}>
                                                            {city.city}
                                                        </option>
                                                    )
                                                }) : null
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        Pin:
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Pin"
                                            id="inp_gpin"
                                            value={gPin}
                                            onChange={(e) => setGPin(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label col-sm-4">
                                        E-Mail Id:
                                    </label>
                                    <div className="col-sm-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Email"
                                            id="inp_gemail"
                                            value={gEmail}
                                            onChange={(e) => setGEmail(e.target.value)}
                                            disabled={flgDisabled}
                                        />
                                    </div>
                                    <i id="emailStatusIconG" aria-hidden="true"></i>
                                </div>
                                <div className="form-group">
                                    <label className="control-label col-sm-4 emailStatusHide">
                                        Email Status:
                                    </label>
                                    <div className="col-sm-2 emailStatusHide">
                                        <select
                                            className="form-control"
                                            id="inp_gEmailStatus"
                                            disabled
                                        />
                                    </div>
                                    <span style={{ marginRight: "15px" }}>
                                        <a
                                            className={flgDisabled ? "reverify-disabled" : "deleteDtls"}
                                            id="gDelete"
                                            title="Delete Guardian Details"
                                            onClick={deleteGuardianDetails}
                                            href="#!"
                                        >
                                            Delete Details
                                        </a>
                                    </span>

                                    <span>
                                        <a
                                            // className="reverify"
                                            className="reverify-disabled"
                                            id="gReverify"
                                            title="Reverify Gaurdian Email"
                                            onClick={reverifyGEmail}
                                            href="#!"
                                            disabled
                                        >
                                            Reverify
                                        </a>
                                    </span>
                                </div>
                                {/* <div className="form-group" style={{ marginTop: 50 }}>
                              <div className="col-sm-4 col-centered text-center ">
                                <button
                                  type="button"
                                  // style={{width:"80%"}}
                                  onClick={deleteGuardianDetails}
                                  className="btn btn-danger"
                                >
                                  Delete Details
                                </button>
                              </div>
                            </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="form-group" style={{ marginTop: 50 }}>
                        <div className="col-sm-2 text-center pull-right">
                            <button
                                type="button"
                                onClick={() => saveParentDetails()}
                                className="btn btn-info"
                                disabled={flgDisabled}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default StudentParentDetails;