import React, { useEffect, useState } from 'react';

import '../css/academicYear.css';
import 'common/css/master.css';
import Header from 'common/js/header/header.js';
import { toast, ToastContainer } from 'react-toastify';

import AcademicYearSlider from './academicYearSlider';
import AcademicYearTable from "./acdYearTable";

import { deleteAcademicYearSvc, getAcademicYearListSvc } from './academicYearSvc';

const AcademicYearCtrl = () => {

    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [lstAcademicYear, setLstAcademicYear] = useState([]);

    const [academicYearId, setAcademicYearId] = useState(0);
    const [Key, setKey] = useState(0);
    const [isSliderOpen, setIsSliderOpen] = useState(false);

    useEffect(() => {
        getAcademicYearData();
    }, [Key]);

    const deleteAcademicYear = (acdYrId) => {
        deleteAcademicYearSvc(acdYrId, deleteAcademicYearResponse)
    }

    const deleteAcademicYearResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            // // console.log("obj", obj);
            toast.success(obj.message);
            changeKey();
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    const setAcademicYearForm = (acdYrId) => {
        setAcademicYearId(acdYrId);
        openSlider();
    }
    const getAcademicYearData = () => {
        setLoader("block");
        setOpacity(0.5);
        getAcademicYearListSvc(getAcademicYearResponse);
    }

    const getAcademicYearResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            setLstAcademicYear(obj.lstAcademicYear);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }


    const openSlider = () => {
        setIsSliderOpen(true);
    }

    const closeSlider = () => {
        // // console.log("closeSlider called");
        setIsSliderOpen(false);
    }

    const addNewAcademicYear = () => {
        setAcademicYearForm(0);
    }

    const changeKey = () => {
        // // console.log("call changeKey");
        setKey(Math.round(Math.random() * 99999));
    }

    return (
        <div style={{ height: '100%' }}>
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="mainContainer " style={{ opacity: opacity }}>
                <div className="col-md-7 col-centered">
                    <div className="col-xs-11  padding-remove">
                        <AcademicYearTable
                            lstAcademicYear={lstAcademicYear}
                            setAcademicYearForm={setAcademicYearForm}
                            deleteAcademicYear={deleteAcademicYear}

                        />
                    </div>
                    <div className="col-xs-1 ">
                        <span
                            title="Add" onClick={addNewAcademicYear} className="addIcon glyphicon glyphicon-plus-sign"></span>
                    </div>
                    {isSliderOpen &&
                        <div className="col-xs-8 padding-remove TestSlider" style={{ width: "60%" }} >
                            <AcademicYearSlider
                                // fromDt={fromDt}
                                // toDt={toDt}
                                // academicYearData={academicYearData}
                                academicYearId={academicYearId}
                                changeKey={changeKey}
                                closeSlider={closeSlider}
                                setLoader={setLoader}
                                setOpacity={setOpacity}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default AcademicYearCtrl;