import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import "../css/subjectAllocation.css";

import Loader from "common/components/Loader";

import { getSyllabusListSvc, allocateTeacherSvc } from "./subjectAllocationSvc";


const SubjectAllocationHeader = (props) => {
    const { setLoader, setOpacity, syllabusId, setSyllabusId } = props;

    const [lstSyllabus, setLstSyllabus] = useState([]);
    const [selSyllabusId, setSelSyllabusId] = useState(0);


    useEffect(() => {
        // console.log("SubjectAllocationHeader called");
        getSyllabusList();
    }, []);

    useEffect(() => {
        // console.log("SubjectAllocationHeader called");
        setSelSyllabusId(syllabusId);
    }, [syllabusId]);

    const getSyllabusList = () => {
        // console.log("getSyllabusList called");
        setLoader("block");
        setOpacity(0.5);
        getSyllabusListSvc(handleSyllabusListResponse);
    }

    const handleSyllabusListResponse = async (data) => {
        // console.log("handleSyllabusListResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            await setLstSyllabus(obj.lstSyllabus);
            if (obj.lstSyllabus && obj.lstSyllabus.length) {
                await handleSyllabusChange(obj.lstSyllabus[0].syllabusId);
            }
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    const handleSyllabusChange = async (pSyllabusId) => {
        // console.log("handlesyllabusChange called, pSyllabusId", pSyllabusId);
        await setSyllabusId(pSyllabusId);
        await setSelSyllabusId(pSyllabusId);
    }

    const allocateTeacher = () => {
        console.log("Allocate Teacher Called");
        setLoader("block");
        setOpacity(0.5);
        allocateTeacherSvc(2, handleAllocateTeacherResponse);
    }

    const viewReport = () => {
        console.log("View Report called");
        const newPageUrl = "/allocationReports";
        window.open(newPageUrl, '_blank');
    }

    const handleAllocateTeacherResponse = async (data) => {
        console.log("handleAllocateTeacherResponse called, data", data);
        let obj = data;
        if (obj.status === 'Success') {
            toast.success(obj.message);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }




    return (
        <div className="col-md-12 col-xs-12 col-centered padding-remove " >
            <div className="col-md-12 col-xs-12 col-centered padding-remove " >
                <div className="col-md-3 col-sm-3 col-xs-3 pull-right padding-remove">
                    <div className="col-md-6 col-sm-6 col-xs-6 padding-remove">
                        <button type="button" style={{ marginTop: 5 }} className="btn btn-primary pull-right" onClick={(e) => { e.stopPropagation(); allocateTeacher(); }}>
                            Allocate Teacher
                        </button>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-6 padding-remove">
                        <button type="button" style={{ marginTop: 5 }} className="btn btn-primary pull-right" onClick={(e) => { e.stopPropagation(); viewReport(); }}>
                            View Report
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-md-12 col-xs-12 col-centered padding-remove " >
                <div className="col-md-3 col-sm-3 col-xs-3 padding-remove">
                    <label
                        className="col-sm-3 text-left padding-remove"
                        style={{ top: "5px" }}
                    >
                        Syllabus
                    </label>
                    <div className="form-group col-sm-9 padding-remove">
                        <select autoComplete="off" name="Syllabus"
                            type="text" className="form-control "
                            value={selSyllabusId}
                            onChange={(e) => handleSyllabusChange(e.target.value)}
                        >
                            <option value="0" >Syllabus</option>
                            {lstSyllabus && lstSyllabus.length > 0 ?
                                lstSyllabus.map((syllabus) => (
                                    <option
                                        key={syllabus.syllabusId}
                                        value={syllabus.syllabusId}
                                    >
                                        {syllabus.syllabus}
                                    </option>
                                )) : null
                            }
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SubjectAllocationHeader;