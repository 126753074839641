import React, { useState, useEffect } from "react";
import "common/css/master.css";
import { getLstSchoolSvc, saveDefaultSchoolId } from "./schoolSetupSvc.js";
import Header from "common/js/header/header.js";
import { toast, ToastContainer } from "react-toastify";

const SchoolSetupCtrl = () => {
  const [state, setState] = useState({
    wingId: "",
    loader: "none",
    opacity: 1,
    tokenid: localStorage.getItem("tokenid"),
    schoolId: parseInt(localStorage.getItem("schoolId")),
  });
  const [lstSchool, setLstSchool] = useState([]);

  useEffect(() => {
    getLstSchoolSvc(cbLstSchool);
  }, []);

  function cbLstSchool(data) {
    if (data.status === "Success") {
      setLstSchool(data.lstSchool);
    } else {
      toast.error(data.message);
    }
  }

  function highlight(pId) {
    if (state.schoolId !== pId) {
      setState({ ...state, schoolId: pId });
      saveDefaultSchoolId(pId, cbSaveSchool);
      redirectTo("roleSetup");
    }
  }

  function redirectTo(page) {
    setTimeout(function () {
      window.location.href = "/" + page;
    }, 2000);
  }

  function cbSaveSchool(data) {
    if (data.status === "Success") {
      toast.success(data.message);
      localStorage.setItem("schoolId", data.schoolId);
    } else {
      toast.error(data.message);
    }
  }

  return (
    <div style={{ height: "100%" }}>
      <Header />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: state.loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>
      <div className="mainContainer" style={{ opacity: state.opacity }}>
        <div className="col-sm-6 col-centered">
          <form
            className="form form-vertical col-sm-12"
            style={{
              margin: "2%",
              padding: "2%",
              border: "1px solid #ddd",
              borderRadius: 6,
            }}
          >
            <div
              className="EnquiryHead col-centered col-sm-5"
              style={{
                fontWeight: "bold",
                width: "35%",
              }}
            >
              <h4>Select Your School</h4>
            </div>
            <div className="row" style={{ background: "transparent" }}>
              <div className="col-sm-offset-3 col-sm-6">
                {lstSchool &&
                  lstSchool.map(function (obj, i) {
                    return (
                      <div
                        id={obj.schoolId}
                        key={obj.schoolId}
                        style={{
                          border: "1px solid #ccc",
                          borderRadius: 6,
                          height: 50,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: 16,
                          background:
                            state.schoolId === obj.schoolId
                              ? "blue"
                              : "transparent",
                          color:
                            state.schoolId === obj.schoolId ? "#fff" : "#000",
                          cursor: "pointer",
                        }}
                        onClick={() => highlight(obj.schoolId)}
                      >
                        {obj.school}
                      </div>
                    );
                  })}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SchoolSetupCtrl;
