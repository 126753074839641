import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import BonafidePdf from '../pdf/BonafidePdf';
import { getPrinciSignSvc, getLogoSvc, bonafideDtlsSvc } from './bonafideSvc';
import LoaderSlider from 'common/components/LoaderSlider';

const BonafidePdfSlider = (props) => {
    const { certificateId, closePdfSlider } = props;

    const [pdfLoader, setPdfLoader] = useState("none");
    const [pdfOpacity, setPdfOpacity] = useState(1);
    const [schoolLogo, setSchoolLogo] = useState("");
    const [principalSign, setPrincipalSign] = useState("");
    const [pdfDtls, setPdfDtls] = useState({});

    useEffect(() => {
        // // console.log("BonafidePdfSlider called, certificateId", certificateId);
        getPrinciSignSvc(cbPrinciSign);
        getLogoSvc(cbLogoResponse);
        getBonafideDtls(certificateId);

    }, [certificateId]);


    function cbLogoResponse(imageUrl) {
        // // console.log("cbLogoResponse called, imageUrl", imageUrl);
        setSchoolLogo(imageUrl)
    }

    function cbPrinciSign(imageUrl) {
        setPrincipalSign(imageUrl);
    }

    const getBonafideDtls = (pCertificateId) => {
        // // console.log("getBonafideDtls called");
        setPdfLoader("block");
        setPdfOpacity(0.5);
        bonafideDtlsSvc(pCertificateId, bonafideDtlsResponse);
    }

    const bonafideDtlsResponse = (data) => {
        // // console.log("bonafideDtlsResponse called, data", data);
        if (data.status === "Success") {
            // // console.log("data.certificateDtls in bonafideDtlsResponse", data.certificateDtls);
            setPdfDtls(data.certificateDtls);
        } else if (data.status === "Failure") {
            handleServiceError(data.message);
        } else {
            toast.error("Service failed " + data.error);
        }
        setPdfLoader("none");
        setPdfOpacity(1);
    }

    const handleServiceError = (svcMsg) => {
        if (svcMsg === "You are not Logged In") {
            toast.error("You are not logged in. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else if (svcMsg === "Server Timed Out, Login Again") {
            toast.error("Server timed out. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else {
            toast.error(svcMsg);
        }
    };

    return (
        <div >
            <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={() => closePdfSlider()} style={{ right: "80%" }}>
                <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
            </button>
            <LoaderSlider
                loader={pdfLoader}
                position={'fixed'}
                left={"58%"}
                top={"45%"}
            />
            <div className="col-md-12 col-xs-12 col-centered padding-remove "
                style={{

                    cursor: (pdfLoader === "none") ? 'pointer' : 'none',
                    pointerEvents: (pdfLoader === "none") ? 'auto' : 'none',
                    opacity: pdfOpacity
                }}>
                <BonafidePdf
                    schoolLogo={schoolLogo}
                    principalSign={principalSign}
                    pdfDtls={pdfDtls}
                />
            </div>
        </div>
    );

}

export default BonafidePdfSlider;