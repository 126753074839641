import React, { useEffect, useState } from "react";
import 'common/css/master.css';
import 'common/css/header.css';
import 'common/bootstrap/css/bootstrap.min.css';

import { toast } from 'react-toastify';

import { logoutSvc  } from "./headerSvc";

const Logout = (props) => {
    const { setLoader, setOpacity } = props;

    
    const logout = () => {
        setLoader("block");
        setOpacity(0.5);
        logoutSvc(logoutResponse);
    }

    const logoutResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            toast.success(obj.message);
            setTimeout(function () {
                window.location.href = "/";
            }, 2000);
            localStorage.removeItem('studentId');
            localStorage.removeItem('teacherId');
            localStorage.removeItem('tokenid');
            localStorage.removeItem('email');
        }
        else {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    return (
        <>
        <i className="col-sm-1 fa fa-sign-out" onClick={() => logout()}></i>
        </>
    );
}

export default Logout;
