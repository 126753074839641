import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import { getLstEvaluationSvc, getLstSubTypeSvc, saveSubjectSvc, deleteSubjectSvc, getLocaleListSvc } from './SubjectSliderSvc'
import './SubjectSlider.css';

export default function SubjectSlider(props) {
    const [lstEvaluation, setLstEvaluation] = useState([])
    const [lstSubType, setLstSubType] = useState([])
    const [subjectId, setSubjectId] = useState(0)
    const [subject, setSubject] = useState('')
    const [typeId, setTypeId] = useState(0)
    const [evaluationId, setEvaluationId] = useState(0)
    const [hasPractical, setHasPractical] = useState(false);
    const [vocational, setVocational] = useState(false);
    const [split, setSplit] = useState(false);
    const [optional, setOptional] = useState(false);
    const [language, setLanguage] = useState("");
    const [localeCode, setLocaleCode] = useState("");
    const [lstLocale, setLstLocale] = useState([]);
    const [loader, setLoader] = useState("none");

    useEffect(() => {
        // // console.log("subjectSlider called, props", props);
        getLstLocale();
        getLstEvaluationSvc(handleLstEvaluationResponse);
        getLstSubTypeSvc(handleLstSubTypeResponse);
        setSubjectId(props.subObj.subId);
        setSubject(props.subObj.sub);
        setTypeId(props.subObj.subTypeId);
        setEvaluationId(props.subObj.evaluationId);
        setHasPractical(props.subObj.flgHasPractical);
        setOptional(props.subObj.flgOptional);
        setVocational(props.subObj.flgVocational);
        setSplit(props.subObj.flgSplitAllowed);
        setLocaleCode(props.subObj.localeCode);
    }, [props.subObj])

    const getLstLocale = () => {
        // // console.log("getLstLocale called");
        setLoader("block");
        getLocaleListSvc(localeListResponse);
    }

    const localeListResponse = (data) => {
        // // console.log("localeListResponse called, data", data);
        setLoader("none");
        if (data.SvcStatus === "Success") {
            setLstLocale(data.lstMstLocale);
        } else {
            toast.error(data.message)
        }
    }

    function handleLstEvaluationResponse(data) {
        // // console.log("handleEvaluationResponse called, data", data);
        if (data.status === "Success") {
            setLstEvaluation(data.lstEvaluation)
        } else {
            toast.error(data.message)
        }
    }
    function handleLstSubTypeResponse(data) {
        if (data.status === "Success") {
            setLstSubType(data.lstSubType)
        } else {
            toast.error(data.message)
        }
    }

    function handleSubjectChange(pSubject) {
        setSubject(pSubject)
    }
    function handleTypeChange(pTypeId) {
        setTypeId(pTypeId)
    }
    const handleLanguageChange = (pLocaleCode) => {
        // // console.log("handleLanguageChange called, pLocaleCode", pLocaleCode);
        setLocaleCode(pLocaleCode);
    }
    function handleEvalChange(pEvalId) {
        setEvaluationId(pEvalId)
    }
    function handlePracticalChange(pChecked) {
        setHasPractical(pChecked)
    }
    function handleOptionalChange(pChecked) {
        setOptional(pChecked)
    }
    function handleVocationalChange(pChecked) {
        setVocational(pChecked)
    }
    function handleSplitChange(pChecked) {
        setSplit(pChecked)
    }

    function saveSubject() {
        if(!localeCode){
            toast.error("Please select language");
            return ;
        }
        var obj = {
            subId: subjectId,
            sub: subject,
            evaluationId: evaluationId,
            subTypeId: typeId,
            localeCode: localeCode,
            flgOptional: optional || false,
            flgVocational: vocational || false,
            flgSplitAllowed: split || false,
            flgHasPractical: hasPractical || false
        }
        saveSubjectSvc(obj, handleSaveSubResponse)
    }

    function handleSaveSubResponse(data) {
        if (data.SvcStatus === "Success") {
            toast.success(data.SvcMsg)
            props.closeSlider(subjectId)
            if (subjectId === 0){
                props.closeSlider(data.subId)
                props.updateKey();
            }

        } else {
            toast.error(data.SvcMsg)
        }
    }

    function deleteSubject() {
        // // console.log(subjectId)
        deleteSubjectSvc(subjectId, handleSaveSubResponse)
    }
    return (

        <form className='form form-horizontal' style={{ overflow: 'hidden', pointerEvents: loader === "none" ? "auto" : "none"  }}>
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }} >
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div >
            <div className='EnquiryHead col-sm-12 col-centered' style={{
                border: "1px solid #aaa", borderTopLeftRadius: 10,
                borderTopRightRadius: 10, borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5, fontWeight: 'bold'
            }}><h4>Subject Details</h4></div>

            <div className='col-sm-12'>
                <label className='col-sm-2 control-label'>Subject</label>
                <div className='form-group col-sm-4'>
                    <input type='text' className='form-control' defaultValue={subject} onChange={(e) => handleSubjectChange(e.target.value)}></input>
                </div>
            </div>
            <div className='col-sm-12'>
                <label className='col-sm-2 control-label'>Language</label>
                <div className='form-group col-sm-4'>
                    <select className='form-control' value={localeCode} onChange={(e) => handleLanguageChange(e.target.value)}>
                        <option value={""}>Language</option>
                        {lstLocale.map((obj, idx) => {
                            return <option key={idx} value={obj.localeCode}>{obj.language}</option>
                        })}
                    </select>
                </div>
            </div>
            <div className='col-sm-12'>
                <label className='col-sm-2 control-label'>Type</label>
                <div className='form-group col-sm-4'>
                    <select className='form-control' value={typeId} onChange={(e) => handleTypeChange(e.target.value)}>
                        <option value={0}>Type</option>
                        {lstSubType.map((obj, idx) => {
                            return <option key={idx} value={obj.typeId}>{obj.type}</option>
                        })}
                    </select>
                </div>
            </div>
            <div className='col-sm-12'>
                <label className='col-sm-2 control-label'>Evaluation</label>
                <div className='form-group col-sm-2'>
                    <select className='form-control' value={evaluationId} onChange={(e) => handleEvalChange(e.target.value)}>
                        <option value={0}>Evaluation</option>
                        {lstEvaluation.map((obj, idx) => {
                            return <option key={idx} value={obj.evaluationId}>{obj.evaluation}</option>
                        })}
                    </select>
                </div>
            </div>

            <div className='col-sm-12'>
                <label className='col-sm-2 control-label'>Optional</label>
                <div className='form-group col-sm-1' style={{ width: 'auto' }}>
                    <input type="checkbox" checked={optional} onChange={(e) => handleOptionalChange(e.target.checked)} />
                </div>
            </div>
            <div className='col-sm-12'>
                <label className='col-sm-2 control-label'>Vocational</label>
                <div className='form-group col-sm-1' style={{ width: 'auto' }}>
                    <input type="checkbox" checked={vocational} onChange={(e) => handleVocationalChange(e.target.checked)} />
                </div>
            </div>
            <div className='col-sm-12'>
                <label className='col-sm-2 control-label'>Split Allowed</label>
                <div className='form-group col-sm-1' style={{ width: 'auto' }}>
                    <input type="checkbox" checked={split} onChange={(e) => handleSplitChange(e.target.checked)} />
                </div>
            </div>
            <div className='col-sm-12'>
                <label className='col-sm-2 control-label'>Has Practical</label>
                <div className='form-group col-sm-1' style={{ width: 'auto' }}>
                    <input type="checkbox" checked={hasPractical} onChange={(e) => handlePracticalChange(e.target.checked)} />
                </div>
            </div>
            <div className="form-group col-sm-6">
                <button type="button" className='btn btn-info pull-right' onClick={() => saveSubject()}>Save</button>
            </div>


        </form>
    )
}