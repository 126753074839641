import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { deleteAcademicSchedulePeriodSvc, getAcademicSchedulePeriodTypeListSvc, saveAcademicSchedulePeriodSvc } from "./academicScheduleSvc";


const PeriodForm = (props) => {
    const { schedulePeriodData, closePopup, setLoader, setOpacity, updateKey } = props;

    const [schedulePeriodId, setSchedulePeriodId] = useState(schedulePeriodData.schedulePeriodId);
    const [academicScheduleId, setAcademicScheduleId] = useState(schedulePeriodData.academicScheduleId);
    const [period, setPeriod] = useState(schedulePeriodData.period);
    const [startTime, setStartTime] = useState(schedulePeriodData.startTime);
    const [lstPeriodType, setLstPeriodType] = useState([]);
    const [periodTypeId, setPeriodTypeId] = useState(schedulePeriodData.periodTypeId);
    const [periodType, setPeriodType] = useState(schedulePeriodData.periodType);
    const [duration, setDuration] = useState(schedulePeriodData.duration);
    const [flgAttendance, setFlgAttendance] = useState(schedulePeriodData.flgAttendance);
    const [flgAdjustTiming, setFlgAdjustTiming] = useState(false);
    const [daysOff, setDaysOff] = useState(schedulePeriodData.daysOff)
    const [allDays, setAllDays] = useState({
        mon: false
        , tue: false
        , wed: false
        , thu: false
        , fri: false
        , sat: false
        , sun: false
    });

    useEffect(() => {
        // console.log("useEffect called in periodForm , schedulePeriodData", schedulePeriodData )
        getPeriodTypeList();
        setDefaultPeriodTypeId(schedulePeriodData.periodType);
    }, []);
    const setDays = async (pDaysOff) => {
        let offDays = pDaysOff.split("");
        console.log('setAllDays():: ',offDays[0], offDays[1], offDays[2], offDays[3], offDays[4], offDays[5], offDays[6])
        let days = {
            mon: offDays[0]=="1",
            tue: offDays[1]=="1",
            wed: offDays[2]=="1",
            thu: offDays[3]=="1",
            fri: offDays[4]=="1",
            sat: offDays[5]=="1",
            sun: offDays[6]=="1"
        }
        console.log(days)
        await setAllDays(days)
    }
    const setDefaultPeriodTypeId = (pType) => {
        // console.log("setDefaultPeriodTypeId called, pType", pType);
        if (lstPeriodType) {
            const periodType = lstPeriodType.find(prdType => prdType.periodType == pType);
            // console.log("periodType matched", periodType);
            if (periodType) {
                // console.log("setPeriodTypeId(periodType.periodTypeId) called");
                setPeriodTypeId(periodType.periodTypeId);
            }
        }
    }

    const getPeriodTypeList = () => {
        setLoader("block");
        setOpacity(0.5);
        getAcademicSchedulePeriodTypeListSvc(periodTypeListResponse);
    }

    const periodTypeListResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            setLstPeriodType(obj.lstPeriodType);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    const savePeriod = () => {
        if (period === "") {
            toast.warning('Please Enter Period');
            return false;
        }
        if (parseInt(periodTypeId) === 0) {
            toast.warning('Please Select Valid Period Type');
            return false;
        }
        if (duration === "") {
            toast.warning('Please Enter Duration');
            return false;
        }
        const timeRegex = /^(\d{1,2}):(\d{2})(AM|PM)$/;
        if (!timeRegex.test(startTime)) {
            toast.warning('Please Enter start time in format HHMMAM');
            return false;
        }
        const schedulePeriod = {
            schedulePeriodId,
            academicScheduleId,
            period,
            startTm: startTime,
            periodTypeId,
            duration,
            adjustTimings: flgAdjustTiming ? 1 : 0,
            flgAttendancePeriod: flgAttendance,
            daysOff: daysOff
        };
        setLoader("block");
        setOpacity(0.5);
        saveAcademicSchedulePeriodSvc(schedulePeriod, savePeriodResponse);

    }

    const savePeriodResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            setSchedulePeriodId(obj.schedulePeriodId);
            updateKey();
            closePopup();
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    const deletePeriod = () => {
        var retVal = window.confirm("Do you want to Delete this Period ?");
        if (retVal === true) {
            setLoader("block");
            setOpacity(0.5);
            deleteAcademicSchedulePeriodSvc(schedulePeriodId, flgAdjustTiming, deletePeriodResponse);
        } else {
            return false;
        }
    }

    const deletePeriodResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            updateKey();
            closePopup();
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        setLoader("none");
        setOpacity(1);
    }

    useEffect(() => {
        console.log("periodForm called ", schedulePeriodData.daysOff);
        setDays(schedulePeriodData.daysOff)
        // setAllDays({
        //     mon: props.wingsData.mon
        //     , tue: props.wingsData.tue
        //     , wed: props.wingsData.wed
        //     , thu: props.wingsData.thu
        //     , fri: props.wingsData.fri
        //     , sat: props.wingsData.sat
        //     , sun: props.wingsData.sun
        // })
        // $('.dayCheckbox').prop('checked', true);
        // $('#sun').prop('checked', false)
    }, [schedulePeriodData.daysOff]);
    async function setOffDays(daysOff, value, index) {
        console.log("setOffDays():: ", daysOff, value, index)
        let newStr = daysOff, newChar = ''
        
        if (value) {
            newChar = '1'
        } else {
            newChar = '0'
        }
        newStr = replaceAt(daysOff,index, newChar);
        console.log(newStr)
        await setDaysOff(newStr)

    }
    function replaceAt (str, index, replacement) {
        return (
          str.substr(0, index) +
          replacement +
          str.substr(index + replacement.length)
        )
      }
    function handleChangeCbox(e) {
        var pos = 0;
        var newDays = allDays;
        var value = e.target.checked
        // console.log("newDays=" + JSON.stringify(newDays));
        if (e.target.id === 'mon') {
            newDays.mon = value;
            
            setOffDays(daysOff, value, 0)
        } else if (e.target.id === 'tue') {
            newDays.tue = value;
            setOffDays(daysOff, value, 1)
        } else if (e.target.id === 'wed') {
            newDays.wed = value;
            setOffDays(daysOff, value, 2)
        } else if (e.target.id === 'thu') {
            newDays.thu = value;
            setOffDays(daysOff, value, 3)
        } else if (e.target.id === 'fri') {
            newDays.fri = value;
            setOffDays(daysOff, value, 4)
        } else if (e.target.id === 'sat') {
            newDays.sat = value;
            setOffDays(daysOff, value, 5)
        } else if (e.target.id === 'sun') {
            newDays.sun = value;
            setOffDays(daysOff, value, 6)
        }
        // console.log("newDays after=" + JSON.stringify(newDays));
        setAllDays(newDays);
    }

    return (
        <div style={{ display: "flex", justifyContent: "center", position: "absolute", padding: 0, top: "5%", left: "10%", backgroundColor: "#F8F8F8", width: 500, height: 300, zIndex: 1001 }}>
            <form className="form-horizontal formWrapper" style={{ width: "100%", height: "100%", margin: 0 }}>
                <div className="form-group">
                    <label className="control-label col-sm-5" > Period <span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                    <div className="col-sm-6 padding-remove">
                        <input autoComplete="off" type="text" className="form-control " value={period} onChange={(e) => setPeriod(e.target.value)} placeholder="Period" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="control-label col-sm-5" > Start Time <span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                    <div className="col-sm-6 padding-remove">
                        <input autoComplete="off" type="text" className="form-control " value={startTime} onChange={(e) => setStartTime(e.target.value)} placeholder="Start Time" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="control-label col-sm-5" > Period Type</label>
                    <div className="col-sm-6 padding-remove">
                        <select
                            className="form-control"
                            defaultValue={periodTypeId}
                            onChange={(e) => setPeriodTypeId(e.target.value)}
                        >
                            <option value={0}>Period Type</option>
                            {lstPeriodType &&
                                lstPeriodType.map((periodType) => (
                                    <option
                                        key={periodType.periodTypeId}
                                        value={periodType.periodTypeId}>
                                        {periodType.periodType}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="form-group">
                    <label className="control-label col-sm-5" >Duration<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                    <div className="col-sm-6 padding-remove">
                        <input autoComplete="off" type="text" className="form-control " value={duration} onChange={(e) => setDuration(e.target.value)} placeholder="Duration" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="control-label col-sm-5" >Mark Attendence<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                    <div className="col-sm-6 padding-remove">
                        <input type="checkbox" className="form-control" checked={flgAttendance} onChange={(e) => setFlgAttendance(e.target.checked)} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="control-label col-sm-5" >Adjust Timings<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                    <div className="col-sm-6 padding-remove">
                        <input type="checkbox" className="form-control " checked={flgAdjustTiming} onChange={(e) => setFlgAdjustTiming(e.target.checked)} />
                    </div>
                </div>

                <div className="form-group">
                    <label className="control-label col-sm-5" >Days<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                    <div className="col-sm-6 padding-remove daysWrapper">
                        <div>
                            <span>M</span>
                            <input autoComplete="off" id="mon" type="checkbox" name="M"
                                className="form-control dayCheckbox "
                                checked={allDays.mon}
                                onClick={handleChangeCbox} />
                        </div>
                        <div>
                            <span>T</span><input autoComplete="off" id="tue" type="checkbox" name="T"
                                className="form-control dayCheckbox "
                                checked={allDays.tue}
                                onClick={handleChangeCbox} />
                        </div>
                        <div>
                            <span>W</span><input autoComplete="off" id="wed" type="checkbox" name="W"
                                className="form-control dayCheckbox "
                                checked={allDays.wed}
                                onClick={handleChangeCbox} />
                        </div>
                        <div>
                            <span>Th</span><input autoComplete="off" id="thu" type="checkbox" name="T"
                                className="form-control dayCheckbox "
                                checked={allDays.thu}
                                onClick={handleChangeCbox} />
                        </div>
                        <div>
                            <span>F</span><input autoComplete="off" id="fri" type="checkbox" name="F"
                                className="form-control dayCheckbox "
                                checked={allDays.fri}
                                onClick={handleChangeCbox} />
                        </div>
                        <div>
                            <span>S</span><input autoComplete="off" id="sat" type="checkbox" name="S"
                                className="form-control dayCheckbox "
                                checked={allDays.sat}
                                onClick={handleChangeCbox} />
                        </div>
                        <div>
                            <span>Su</span><input autoComplete="off" id="sun" type="checkbox" name="S"
                                className="form-control dayCheckbox "
                                checked={allDays.sun}
                                onClick={handleChangeCbox} />
                        </div>
                    </div>
                </div>
                <div className="form-group" style={{ marginTop: 10 }}>
                    <div className="col-sm-12 text-center userButtonWrap" >
                        <button type="button" title="Save" onClick={() => savePeriod(period, startTime, periodTypeId, duration, flgAttendance, flgAdjustTiming, daysOff)} className="btn btn-info">Save</button>
                        <button type="button" title="Cancel" onClick={() => closePopup()} className="btn btn-info">Close</button>
                        {schedulePeriodId != 0 &&
                            <button type="button" title="Delete" onClick={() => deletePeriod(schedulePeriodId)} className="btn btn-danger deleteButton">Delete</button>
                        }
                    </div>
                </div>
            </form>
        </div>
    );
}

export default PeriodForm;