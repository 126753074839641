import React, { Component } from 'react'
import lstModules from 'common/json/lstModules.json'
import $ from 'jquery'

export default class tableBody extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {
  //     }
  // }
  addClassOnRow = id => {
    $('#feeClassSetupTableBody tr#' + id)
      .addClass('selected')
      .siblings()
      .removeClass('selected')
  }
  render () {
    return (
      <>
        <table id={this.props.tableId} className='tableLayout'>
          <colgroup>
            {this.props.colswidth.map((width, idx) => {
              return <col key={idx} width={width}></col>
            })}
          </colgroup>

          <tbody id={this.props.tbodyId}>
            {this.props.data.map((item, index) => {
              return (
                (!this.props.cls ||
                  this.props.cls == item.className.toLowerCase()) &&
                (!this.props.section ||
                  this.props.section == item.section.toLowerCase()) && (
                  <tr
                    key={item.sectionId}
                    id={item.sectionId}
                    onClick={() => this.addClassOnRow(item.sectionId)}
                  >
                    {/* <td colSpan={'0'}>{item[lstKeys[0]]}</td> */}
                    <td>{item.className}</td>
                    <td>{item.section}</td>
                    <td className='text-right'>{item.countStudents}</td>
                    <td className='text-right'>{item.countSetupDone}</td>
                    <td className='text-right'>{item.countSetupPending}</td>

                    <td className='text-center'>
                      <span
                        className='glyphicon glyphicon-edit'
                        style={{ cursor: 'pointer' }}
                        title='Setup Fee'
                        onClick={() => {
                          this.props.parentCallback(
                            item['sectionId'],
                            item['classId']
                          )
                          // this.props.setForm(item.classId, item.sectionId,
                          //     item.className, item.section
                          // );
                        }}
                      ></span>
                      <span
                        className='glyphicon glyphicon-send'
                        title='Associate Fee For All Students'
                        onClick={() => {
                          this.props.sendFeebook(item.sectionId)
                        }}
                      ></span>
                      <span className="glyphicon glyphicon-envelope" title="Send Feebook" onClick={() => this.props.sendFeebookClassSection(item.sectionId)}
                    ></span>

                    </td>
                  </tr>
                )
              )
            })}
          </tbody>
        </table>
      </>
    )
  }
}
