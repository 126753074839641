import axios from 'axios';
import lstModules from 'common/json/lstModules.json';
var tokenid = localStorage.getItem('tokenid');
var httpURL = lstModules['main'].url + ":" + lstModules['main'].port;

function uploadSubjectExcelSvc(formData, cb) {
    var status = 'Success'
    var obj = { status: status };
    axios({
        url: httpURL + '/sms/subject/excel/upload',
        method: "POST",
        data: formData,
        headers: { tokenid },
        withCredentials: true,
    }).then((response) => {
        if (response.data.SvcStatus === "Success") {

            obj.message = response.data.SvcMsg;
            obj.status = response.data.SvcStatus;
        } else {
            obj.message = response.data.SvcMsg;
            obj.status = response.data.SvcStatus;
            obj.errors = response.data.errors;
        }
        cb(obj)
    }).catch((e) => {
        obj.status = 'Exception';
        obj.message = e;
        cb(obj)

    });
}
export { uploadSubjectExcelSvc };