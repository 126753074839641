import React, { Component, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';

import $ from 'jquery';
import { toast, ToastContainer } from 'react-toastify';
//import './class.css';

var queryString = require('querystring');

const screenId = 37;

const ClassTeacherSlider = (props) => {

    const [classData2, setClassData2] = useState(props.classData2)
    const [secId, setSecId] = useState(props.secId);
    // const [classSectionId, setClassSectionId] = useState(props.classData2.classSectionId)
    const [bckTchId, setBckTchId] = useState(0);

    const [clsTchId, setClsTchId] = useState(0);

    const [lstClassTeacher, setLstClassTeacher] = useState(props.lstClassTeacher)
    // const [classTeacher, setClassTeacher] = useState(props.classData2.clsTchId)
    // const [backupTeacher, setBackupTeacher] = useState(props.classData2.bckTchId)

    useEffect(() => {
        if (props.classData2 !== undefined) {
            // // console.log(props.classData2)
            setLstClassTeacher(props.lstClassTeacher)
            setClassData2(props.classData2)
            setSecId(props.secId);
            setBckTchId(props.classData2.bckTchId);
            setClsTchId(props.classData2.clsTchId);
            // setClassSectionId(props.classData2.classSectionId)

            // setClassTeacher(props.classData2.clsTchId)
            // setBackupTeacher(props.classData2.bckTchId)
        }

    }, [props]);



    function handleChange(e) {
        // // console.log(e.target.value)
        setClassData2({ ...classData2, [e.target.name]: e.target.value, classSectionId: secId });
        if(e.target.name==='classTeacherId'){
            setClsTchId(e.target.value)
        }
        else{
            setBckTchId(e.target.value)
        }
        // // console.log({ ...classData2, [e.target.name]: e.target.value })
    }

    function saveClassTeacher() {
        props.saveClassTeacher(classData2)
    }
    return (
        <>
            <form id="addForm" className="form-horizontal formWrapper clearfix">
                <div className="ab">
                    <div className="form-group">
                        <label className="control-label col-sm-4" >Class Teacher<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                        <div className="col-sm-3 padding-remove">
                            <select autoComplete="off" name="classTeacherId" id="classTeacherCmb"
                                type="text" className="form-control "
                                value={clsTchId}
                                onChange={handleChange}>
                                <option value="0" >Class Teacher</option>
                                {props.lstClassTeacher && props.lstClassTeacher.length !== 0 && props.lstClassTeacher.map((clsData) => (
                                    <option
                                        key={clsData.teacherId} value={clsData.teacherId} >{clsData.teacher}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-sm-4" >Backup Teacher<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                        <div className="col-sm-3 padding-remove">
                            <select autoComplete="off" name="backupTeacherId" id="backupTeacherCmb"
                                type="text" className="form-control "
                                value={bckTchId}
                                onChange={handleChange}>
                                <option value="0" >Backup Teacher</option>
                                {props.lstClassTeacher && props.lstClassTeacher.length !== 0 && props.lstClassTeacher.map((clsData) => (
                                    <option key={clsData.teacherId} value={clsData.teacherId} >{clsData.teacher}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-sm-3 padding-remove paymentButtons">
                    <div className="form-group" >
                        <div className="ab1" >
                            <button type="button" style={{ marginTop: 20 }}
                                title="Save"
                                onClick={saveClassTeacher}
                                className="btn btn-info">Save</button>
                        </div>
                        <button type="button" className="btn btn-info text-center " id="closeSlider" onClick={props.removeClassFromBody}>
                            <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                        </button>
                    </div>

                </div>
            </form >
        </>

    )
}
export default ClassTeacherSlider;
