import React, { useEffect, useState } from "react";
import 'common/css/master.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../css/studentTest.css';
import '../css/question.css';



const Students = (props) => {

    const { lstStudent } = props;

    const [mapAvatar, setMapAvatar] = useState(new Map());
    const [avatarPath, setAvatarPath] = useState("");

    useEffect(() => {
        // // console.log("Students called");
        getMapAvatar();
        getPathAvatar();
    }, [lstStudent]);

    const getMapAvatar = () => {
        // // console.log("getMapAvatar called");
        const tempMapAvatar = JSON.parse(localStorage.getItem('mapAvatar'));
        // // console.log("tempMapAvatar in getMapAvatar", tempMapAvatar);
        setMapAvatar(tempMapAvatar);
        // // console.log("item 1 in map", tempMapAvatar[1]);
    }

    const getPathAvatar = () => {
        // // console.log("getPathAvatar Called");
        const tempPathAvatar = localStorage.getItem('avatarPath');
        // // console.log("tempPathAvatar in getMapAvatar", tempPathAvatar);
        setAvatarPath(tempPathAvatar);
    }

    return (
        <div className="student-section" >
            <div className="label-pallet" >
                <span className="avatar-list-label"  >
                    {"Answered"}
                </span>
            </div>
            <div className="avatars-grid">
                {
                    lstStudent && lstStudent.length ?
                        lstStudent.map((student) => (
                            <>
                                <div key={student.studentId} className="student-item" >
                                    <div>
                                        {student.crypticNm}
                                    </div>
                                    <div key={student.studentId} className="avatar-item" >
                                        <div
                                            className="student-avatar-img">
                                            {/* {mapAvatar && mapAvatar.length
                                        ? */}
                                            <>
                                                <img src={avatarPath + "/" + student.avatarId + ".png"} height="100px" />
                                                {/* <img src={"http://192.168.1.3:8280/sms/student/docs/view?imgPath=D://projects//sms//be//sms_files/avatars" + "/" + student.avatarId + ".png"} /> */}
                                            </>
                                            {/* : null
                                    } */}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))
                        : null
                }
            </div>
        </div>
    );
}

export default Students;