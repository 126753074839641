import React, { useState, useEffect } from 'react'
import DutyCell from './dutyCell'
import 'sms/tt/disruption/css/duty.css'
import 'sms/tt/disruption/css/selectedCell.css'

var secId = 3;
var cellType = 10;
var clsId = 4;
var cellDtls = [];

const DutyTbl = (props) => {
    const [state, setState] = useState({
        cellDtls: [],
        selectedPeriodId: 0,
        selectedDayId: 0,

        selCellDtls: {
            selRoleId: props.selRoleId,
            selPeriodId: props.selPeriodId,
            selTchId: props.selTchId
        },
    })
    const [lstAbsentTch, setLstAbsentTch] = useState(props.lstAbsentTch)
    const [lstPeriod, setLstPeriod] = useState(props.lstPeriod)
    const [selPeriodId, setSelPeriodId] = useState(props.selPeriodId);
    const [selTchId, setSelTchId] = useState(props.selTchId);

    useEffect(() => {
        // // console.log(props.lstPeriod)
        // // console.log(props.lstAbsentTch)
        setSelPeriodId(props.selPeriodId);
        setSelTchId(props.selTchId);
        setLstPeriod(props.lstPeriod)
        setLstAbsentTch(props.lstAbsentTch)
    }, [props])


    function onClickCell(e, pId, tId, clsId, sectionId, subId, subsId) {
        // // console.log(e.currentTarget);
        var cell = e.currentTarget.getAttribute('selcelldtls');
        setState({ ...state, selCellDtls: cell, selPeriodId: e.currentTarget.selperiodid, selTchId: e.currentTarget.seltchid });
        props.setSelCellDtls(cell);
        props.setSelPeriodId(pId)
        props.setSelTchId(tId)
        props.onCellClicked(cell, tId, clsId, sectionId, subId, subsId)
    }


    return (
        <div>
            <table className="tableLayout1 border-bottom-none" id='customTable'>
                <thead>
                    <tr className="border-bottom-none" style={{ backgroundColor: 'white' }} >
                        <th className="border-bottom-none period-th" style={{ width: '17%' }}></th>
                        {lstPeriod.map((periodObj) => (
                            <th className="period-th" key={periodObj.periodId} id={periodObj.periodId} >{periodObj.startTm}</th>
                        ))}
                    </tr>
                </thead>
            </table>

            <div className="tableDiv" style={{ height: 500 + 'px' }}>
                <table id="periodTypeTable" className="tableLayout">
                    <tbody id="periodTypeTableBody">
                        {lstAbsentTch && lstAbsentTch.map((obj, i) => {
                            return <tr key={i}>
                                <td className="tch-cell padding-remove" tchid={obj.tchId}>
                                    <div className="divTch">
                                        <span className="spanDutyTch">{obj.tch}</span>
                                        <span className={obj.subsStatus === 10 ? "spanColor": (obj.subsStatus === 20 ? "spanGreenColor" : "spanGreyColor")}></span>
                                    </div>
                                    <div className="divRole"><span>{obj.role}</span></div>
                                </td>

                                {obj.lstDuty.map((cell, j) => {
                                    var isCellSelected = obj.tchId === parseInt(selTchId) && cell.periodId === parseInt(selPeriodId);
                                    return <td key={j}
                                        className={cell.cellType === 20 ? "unused-cell cell" : (isCellSelected ? "selected-cell" : "cell")}
                                        selcelldtls={JSON.stringify(cell)}
                                        selperiodid={cell.periodId}
                                        seltchid={obj.tchId}
                                        selroleid={obj.roleId}
                                        onClick={(e) => cell.cellType === 10 ? onClickCell(e, cell.periodId, obj.tchId, cell.clsId, cell.sectionId, cell.subId, cell.subsId) : e.preventDefault()}>

                                        <DutyCell
                                            cellDtls={cell}
                                            roleId={obj.roleId}
                                            selPeriodId={selPeriodId}
                                            selTchId={selTchId}
                                            tchId={obj.tchId}
                                            removeTchDuty={props.removeTchDuty}
                                        />
                                    </td>
                                })}
                            </tr>
                        })}

                    </tbody>
                </table>
            </div>
        </div >
    )
}

export default DutyTbl;