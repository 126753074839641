import axios from 'axios'
import lstModules from 'common/json/lstModules.json';
var tokenid = localStorage.getItem('tokenid');
var httpURL = lstModules['enquiry'].url + ":" + lstModules['enquiry'].port;
var httpURLCommon = lstModules['main'].url + ":" + lstModules['main'].port;

function getCounselorDtlsSvc(cb) {
    axios(
        {
            url: httpURL + "/sms/enquiry/setup/counselor/dtls",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            cb(data)
        }
    ).catch((error) => {
        // // console.log("Counselor dtls Fetch error")
    });

}

export { getCounselorDtlsSvc };