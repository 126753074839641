
import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import mortarboard from "../../images/mortarboard.png";
import SMSQR from "../../images/SMSQR.png";


const styles = StyleSheet.create({
    pageContainer: {
        border: 1,
        borderColor: "#b7b7b7",
        // borderColor: "red",
        height: '100%',
        width: '100%',
        padding: 2,
        margin: 0,
    },

    book1: {
        height: '46%',
        width: '46%',
        position: 'absolute',
        top: '2%',
        left: '2%',
    },
    book2: {
        height: '46%',
        width: '46%',
        position: 'absolute',
        top: '2%',
        right: '2%',
    },
    book3: {
        height: '46%',
        width: '46%',
        position: 'absolute',
        bottom: '2%',
        left: '2%',
    },
    book4: {
        height: '46%',
        width: '46%',
        position: 'absolute',
        bottom: '2%',
        right: '2%',
    },
    schoolHeading: {
        // fontFamily: "Arial sans-serif",
        fontSize: 15,
        fontWeight: 'bold',
        textAlign: "center",
        margin: "5px 0px",
    },
    text: {
        fontSize: 11,
        // fontFamily: "Arial sans-serif",
    },
    textWarning: {
        // fontFamily: "Arial sans-serif",
        fontSize: 8,
        color: "red",
        position: 'absolute',
        bottom: '3%',
        right: '5%',
    },
    tableHeading: {
        // fontFamily: "Arial sans-serif",
        fontSize: 10,
        fontWeight: 'bold',
        textAlign: "center",
    },
    table: {
        marginTop: 10,
    },
    tableRow: {
        flexDirection: 'row',
        borderColor: '#cccccc',
        height: 50,
    },
    tableCell: {
        borderRight: 1,
        borderBottom: 1,
        borderColor: '#cccccc',
    },
    lastCol: {
        borderBottom: 1,
        borderColor: '#cccccc',
    },
    lastRow: {
        borderBottom: 0,
    },
    image1: {
        height: 50,
    }
});

const BookDetails = (props) => {

    const { schoolName, lstSelect } = props;

    const numPages = Math.ceil(lstSelect.length / 4);

    const pages = Array.from({ length: numPages }, (_, pageIndex) => (
        <Page key={pageIndex} >
            {lstSelect.slice(pageIndex * 4, (pageIndex + 1) * 4).map((selected, index) => (
                <View key={index} style={index % 4 === 0 ? styles.book1 : index % 4 === 1 ? styles.book2 : index % 4 === 2 ? styles.book3 : styles.book4}>
                    <View style={styles.pageContainer}>
                        <View style={{ width: '100%', height: '25%', flexDirection: 'row' }}>
                            <View style={{ width: '65%' }}>
                                <Text style={[styles.schoolHeading, { paddingLeft: 0 }]}>{schoolName}</Text>
                                <Text style={[styles.text, { paddingLeft: 2 }]}>{selected.bookNo + "-" + selected.copyNo}</Text>
                                <Text style={[styles.text, { paddingLeft: 2 }]}>{selected.title}</Text>
                                <Text style={[styles.text, { paddingLeft: 2 }]}>{selected.author}</Text>
                                <Text style={[styles.textWarning, { paddingLeft: 2 }]}>{selected.restriction}</Text>
                            </View>
                            <View style={{ width: '35%' }}>
                                <Image src={SMSQR} />
                            </View>
                        </View>
                        <View style={{ width: '100%', height: '75%' }}>
                            <View style={[{ flexDirection: 'row', width: '100%', border: 1, borderColor: "#b7b7b7", height: 60, alignItems: 'center' }]}>
                                <View style={{ width: '24%', height: 20, backgroundColor: "#ecececff" }}>
                                    <Text style={[styles.tableHeading, { fontWeight: 'bold', paddingTop: 3 }]}>Issue Date</Text>
                                </View>
                                <View style={{ width: '10%', borderLeft: 1, height: 20, borderColor: "#b7b7b7", backgroundColor: "#ecececff" }}>
                                    <Image style={[styles.tableHeading, styles.image1]} src={mortarboard} />
                                </View>
                                <View style={{ width: '50%', borderLeft: 1, height: 20, borderColor: "#b7b7b7", backgroundColor: "#ecececff" }}>
                                    <Text style={[styles.tableHeading, { fontWeight: 'bold', paddingTop: 3 }]}>Borrower</Text>
                                </View>
                                <View style={{ width: '25%', borderLeft: 1, height: 20, borderColor: "#b7b7b7", backgroundColor: "#ecececff" }}>
                                    <Text style={[styles.tableHeading, { fontWeight: 'bold', paddingTop: 3 }]}>Return Date</Text>
                                </View>
                            </View>
                            <View >
                                {Array.from({ length: 15 }, (_, index) => (
                                    <View key={index} style={styles.tableRow}>
                                        <Text style={[styles.tableCell, { width: '25%', borderRight: 1, ...(index === 14 && { ...styles.lastRow }) }]}></Text>
                                        <Text style={[styles.tableCell, { width: '10%', borderRight: 1, ...(index === 14 && { ...styles.lastRow }) }]}></Text>
                                        <Text style={[styles.tableCell, { width: '50%', borderRight: 1, ...(index === 14 && { ...styles.lastRow }) }]}></Text> {/* Adjusted width here */}
                                        <Text style={[styles.tableCell, styles.lastCol, { width: '25%', borderRight: 0, ...(index === 14 && { ...styles.lastRow }) }]}></Text> {/* Adjusted width here */}
                                    </View>
                                ))}
                            </View>
                        </View>
                    </View>
                </View>
            ))}
        </Page>
    ));

    return <Document>{pages}</Document>;
}

export default BookDetails;
