import React, { Component } from "react";
import lstModules from 'common/json/lstModules.json';
import $ from "jquery";
import "jquery-ui";
import "common/css/master.css";
import "../css/feeHeads.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import '../css/parentAppLogin.css'

import Header from "common/js/header/header";
import Section from "./section";
import ParentAppLoginTable from "./parentAppLoginTable";
import ParentLoginList from './parentAppLogin.json'

var httpURL = "";
var httpURLTT = "";
var queryString = require("querystring");
var screenId = 11;
var lstFeeHeads = [];

class ParentAppLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lstClass: [],
            tokenid: localStorage.getItem("tokenid"),
            wingId: localStorage.getItem("wingId"),
            studentId: 0,
            feeClassId: 0,
            lstClassSection: [],
            classId: "",
            lstSelect: [],
            lstParentLogin: [],

        };
        this.changeCls = this.changeCls.bind(this);
    }

    componentDidMount() {
        this.getLstClass();
        // // console.log(this.state.lstParentLogin);
    }
    getLstClass = () => {
        let wingId = this.state.wingId;
        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURLTT + '/sms/mst/cls/list',
            queryString.stringify({ wingId, screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                this.setState({ lstClass: response.data.lstClass });
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });
    }
    changeCls = (e) => {
        var classId = $('#clsCmb').find('option:selected').attr('id');
        this.setState({ classId: classId });
        this.setState({ lstParentLogin: [] })
        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURLTT + '/sms/cls/sec/list',
            queryString.stringify({ classId, screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                this.setState({ lstClassSection: response.data.lstClassSection });
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
            // // console.log("changeClass service response = " , response);
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
            // // console.log("changeClass Service failed: ", e);
        });
    }

    getStudentsList = (classId, classSectionId) => {
        this.setState({ loader: "block", opacity: 0.5 });
        axios({
            url: httpURL + "/sms/pa/student/list",
            method: "POST",
            params: { 'screenId': screenId, 'classId': classId, 'sectionId': classSectionId },
            headers: { tokenid: this.state.tokenid },
            withCredentials: true,
        })
            .then((response) => {
                if (response.data.SvcStatus === "Success") {
                    this.setState({
                        lstParentLogin: response.data.lstStudent
                    });
                    const tempLstSelect = response.data.lstStudent.map(student => ({
                        studentId: student.studentId,
                        accessStatus : (!!student.relative) ? student.relative.appAccess : false,
                        email: (student.relative && student.relative.length > 0) ? student.relative.email : "",
                    }));
                    this.setState({ lstSelect: tempLstSelect });
                } else {
                    toast.error(response.data.SvcMsg);
                }
                this.setState({ loader: "none", opacity: 1 });
                // // console.log("getStudentList service response = " , response);
            })
            .catch((e) => {
                this.setState({ loader: "none", opacity: 1 });
                toast.error("Service failed: " + e);
                // // console.log("get StudentList Service failed: ", e);
            });
    };
    handleCallback = (classSectionId) => {
        this.setState({ classSectionId: classSectionId });
        this.getStudentsList(this.state.classId, classSectionId);
        // // console.log("getStudentList called from handlecallback in parentAppLogin");
    }

    getWingId = (wingId) => {
        this.setState({
            wingId: wingId
        }, () => this.getLstClass())
    }
    setLoaderValue = (ldr) => {
        this.setState({ loader: ldr });
    }

    setOpacityValue = (opct) => {
        this.setState({ opacity: opct });
    }


    render() {
        httpURL = lstModules['parentAppLogin'].url + ":" + lstModules['parentAppLogin'].port;
        httpURLTT = lstModules['main'].url + ":" + lstModules['main'].port;
        return (
            <div>
                <Header wingFromChild={this.getWingId} />
                <ToastContainer autoClose={3000} hideProgressBar />

                {/* <div className="mainContainer feeHeads"> */}
                <div className="container-fluid" style={{ boxShadow: '0 0 10px 0 #214a75', marginTop: '-10px' }}>
                    <div className="col-sm-12" style={{ padding: '10px' }}>
                        <div className="col-sm-2">
                            <select autoComplete="off" id="clsCmb" onChange={() => this.changeCls()} className="form-control">
                                <option id={0} key={0}>Class</option>
                                {this.state.lstClass && this.state.lstClass.map((cls) => {
                                    return <option id={cls.clsId} key={cls.clsId}>{cls.cls}</option>
                                })}
                            </select>
                        </div>

                        <div className="col-sm-10">
                            {this.state
                                && this.state.lstClassSection
                                && this.state.lstClassSection.length != 0
                                && <Section
                                    key={this.state.lstClassSection[0].sectionId}
                                    data={this.state.lstClassSection}
                                    selected={this.state.lstClassSection[0].sectionId}
                                    parentCallback={this.handleCallback} />
                            }
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 padding-remove" style={{ marginTop: 20 }}>
                    {this.state.lstParentLogin && this.state.lstParentLogin.length > 0 ?
                        <ParentAppLoginTable
                            key={this.state.lstParentLogin}
                            lstParentLogin={this.state.lstParentLogin}
                            lstSelect={this.state.lstSelect}
                            classId={this.state.classId}
                            classSectionId={this.state.classSectionId}
                            getStudentsList={this.getStudentsList}
                            setLoader={this.setLoaderValue}
                            setOpacity={this.setOpacityValue}
                        />
                        : null
                    }
                </div>

            </div>
        );
    }
}

export default ParentAppLogin;
