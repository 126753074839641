import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css'
import Header from 'common/js/header/header.js';
import { getTaskListSvc, deleteTaskSvc, saveTaskSvc, getTaskDtlsSvc, completeTaskSvc } from "./taskSvc";
import TaskTable from './taskTable';
import TaskSlider from './taskSlider';

const TaskCtrl = () => {

    const [loader, setLoader] = useState("none")
    const [taskObj, setTaskObj] = useState({})
    const [taskId, setTaskId] = useState(0)
    const [lstTask, setLstTask] = useState([])
    const [randNo, setRandNo] = useState(0)
    const [flgSlider, setFlgSlider] = useState(false);

    useEffect(() => {
        setLoader("block");
        getTaskList()
    }, [])

    function getTaskList() {
        getTaskListSvc(cbTaskListResponse)
    }
    function cbTaskListResponse(data) {
        // // console.log("cbTaskListResponse called");
        if (data.SvcStatus === "Success") {
            setLstTask(data.lstTask)
            toast.success(data.SvcMsg)
        } else {
            toast.error(data.SvcMsg)
        }
        setLoader("none");
    }

    function generateRandomNo() {
        var lRandNo = Math.floor(Math.random() * 999999);
        // // console.log(lRandNo)
        setRandNo(lRandNo)
    }

    async function openTaskSlider(pTaskId, pTaskObj) {
        // // console.log("openTaskSlider called, pTaskId", pTaskId, ", pTaskObj ", pTaskId)
        await setTaskId(pTaskId)

        if (pTaskId) {
            await setLoader("block");
            await getTaskDtlsSvc(pTaskId, cbTaskDtlsResponse);
        }
        // setTaskObj(pTaskObj)
        setFlgSlider(true);
        
    }
    
    function cbTaskDtlsResponse(data) {
        // // console.log("cbTaskDtlsResponse called");
        if (data.SvcStatus === "Success") {
            setTaskObj(data.task)
            toast.success(data.SvcMsg);
        } else {
            toast.error(data.SvcMsg);
        }
        setLoader("none");
        setFlgSlider(true)
    }

    function closeSlider() {
        setFlgSlider(false)
        setTaskObj({})
        setTaskId(0)
        getTaskListSvc(cbTaskListResponse)
    }

    function deleteTask(pTaskId) {
        var confirmDelTask = window.confirm("Are you sure to delete the task?")
        if (confirmDelTask) {
            setLoader("block")
            deleteTaskSvc(pTaskId, cbDeleteTaskResponse)
        }
    }

    function cbDeleteTaskResponse(data) {
        setLoader("none")
        if (data.SvcStatus === "Success") {
            toast.success(data.SvcMsg);
            getTaskListSvc(cbTaskListResponse)
        } else {
            toast.error(data.SvcMsg)
        }
        closeSlider()
    }

    function completeTask(pTaskId) {
        setLoader("block");
        completeTaskSvc(pTaskId, cbCompleteTaskResponse)

    }

    function cbCompleteTaskResponse(data) {
        setLoader("none")
        if (data.SvcStatus === "Success") {
            toast.success(data.SvcMsg);
            getTaskListSvc(cbTaskListResponse)
        } else {
            toast.error(data.SvcMsg)
        }
        closeSlider()
    }
    const getWingId = (wingId) => {
        // // console.log('wingId = ' + wingId)
    }
    return (
        <div className='MainContainer' style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }} >
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div >
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div className="col-sm-9 col-centered" style={{ marginTop: '2%' }}>
                <div className="col-sm-11" style={{ height: '100%' }}>
                    <TaskTable
                        setTaskId={setTaskId}
                        taskId={taskId}
                        openTaskSlider={openTaskSlider}
                        deleteTask={deleteTask}
                        completeTask={completeTask}
                        lstTask={lstTask} />

                </div>
                <div className="col-xs-1" onClick={(e) => openTaskSlider(0, {})}>
                    <span id="addReadyId" title="Add" className="addIcon glyphicon glyphicon-plus-sign"></span>
                </div>
            </div>
            {flgSlider ?
                <div className="TestSlider" style={{ display: flgSlider ? 'block' : 'none', width: "50%" }}>
                    <TaskSlider
                        key={taskId}
                        taskId={taskId}
                        closeSlider={closeSlider}
                        taskObj={taskObj}
                        getTaskList={getTaskList}
                    />
                </div>
                : null
            }

        </div >
    )
}

export default TaskCtrl