import React, { useEffect, useState } from "react";
import 'common/css/master.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../css/studentTest.css';
import CommanTimer from "student/utility/commanTimer";

const InstructionTimer = (props) => {

    const { secondsTostart, minToStart } = props;

    useEffect(() => {
        // // console.log("InstructionTimer called");
    }, []);

    return (

        <div className="col-sm-10 col-centered padding-remove ">
            <div className="timerRectangle" >
                <CommanTimer
                    secondsTostart={secondsTostart}
                    minToStart={minToStart}
                    textColr={"white"}
                    fSize={30}
                    fWeight={"bold"}
                />
            </div>
            <div className="timerRectangle1" >
                <CommanTimer
                    secondsTostart={secondsTostart}
                    minToStart={minToStart}
                    textColr={"white"}
                    fSize={18}
                    fWeight={"bold"}
                />
            </div>
        </div>
    );
}

export default InstructionTimer;