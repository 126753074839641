import React, { useState, useEffect } from "react";
import { saveFeeInfoSvc } from "./FeeSvc";
import { toast, ToastContainer } from "react-toastify";

var schoolId = 77; //localStorage.getItem("schoolId");

const SchoolFeeSlider = (props) => {
  const [loader, setLoader] = useState("none");
  const [feeObj, setFeeObj] = useState({});
  const [regsFee, setRegsFee] = useState(0);
  const [admissionFee, setAdmissionFee] = useState(0);
  const [annualFee, setAnnualFee] = useState(0);
  const [tutionFee, setTutionFee] = useState(0);
  const [transportFee, setTransportFee] = useState(0);
  const [otherFee, setOtherFee] = useState(0);
  const [tutionFreq, setTutionFreq] = useState(0);
  const [transportFreq, setTransportFreq] = useState(0);
  const [otherFreq, setOtherFreq] = useState(0);
  const [classId, setClassId] = useState(0);
  const [classNm, setClassNm] = useState("");
  const [acdYr, setAcdYr] = useState("");
  const [academicYearId, setAcademicYearId] = useState(0);
  const [totalFeeFY, setTotalFeeFY] = useState(0);
  const [totalFeeM, setTotalFeeM] = useState(0);

  useEffect(() => {
    // // console.log(props);
    setClassId(props.feeObj.classId);
    setClassNm(props.feeObj.classNm);
    setFeeObj(props.feeObj);
    // // console.log(props.feeObj);
    setTutionFee(props.feeObj.tutionFee);
    setTransportFee(props.feeObj.transportFee);
    setAnnualFee(props.feeObj.annualFee);
    setAdmissionFee(props.feeObj.annualFee);
    setOtherFee(props.feeObj.otherFee);
    setRegsFee(props.feeObj.regsFee);
    setAcdYr(props.acdYr);
    setAcademicYearId(props.academicYearId);
    setTutionFreq(props.feeObj.tutionFreq);
    setTransportFreq(props.feeObj.transportFreq);
    setOtherFreq(props.feeObj.otherFreq);
    setTotalFeeFY(
      props.feeObj.regsFee +
        props.feeObj.tutionFee * props.feeObj.tutionFreq +
        props.feeObj.transportFee * props.feeObj.transportFreq +
        props.feeObj.otherFee * props.feeObj.otherFreq +
        props.feeObj.admissionFee +
        props.feeObj.annualFee
    );
    setTotalFeeM(
      props.feeObj.tutionFee * props.feeObj.tutionFreq +
        props.feeObj.transportFee * props.feeObj.transportFreq +
        props.feeObj.otherFee * props.feeObj.otherFreq
    );
  }, [props.academicYearId, props.classId, props.feeObj]);

  useEffect(() => {
    setTotalFeeFY(0);
    setTotalFeeM(0);
    setSummaryFee();
  }, [
    tutionFee,
    tutionFreq,
    regsFee,
    annualFee,
    transportFee,
    transportFreq,
    otherFee,
    otherFreq,
    admissionFee,
  ]);
  function setSummaryFee() {
    setTotalFeeFY(
      parseInt(regsFee) +
        parseInt(tutionFee) * parseInt(tutionFreq) +
        parseInt(transportFee) * parseInt(transportFreq) +
        parseInt(otherFee) * parseInt(otherFreq) +
        parseInt(admissionFee) +
        parseInt(annualFee)
    );
    setTotalFeeM(
        parseInt(tutionFee) * parseInt(tutionFreq) +
        parseInt(transportFee) * parseInt(transportFreq) +
        parseInt(otherFee) * parseInt(otherFreq) 
       
    );
  }
  function saveFee() {
    var feeDtls = {
      schoolId: schoolId,
      acdYrId: academicYearId,
      classId: classId,
      regsFee: regsFee,
      tutionFee: tutionFee,
      annualFee: annualFee,
      otherFee: otherFee,
      admissionFee: admissionFee,
      transportFee: transportFee,
      transportFreq: transportFreq,
      otherFreq: otherFreq,
      tutionFreq: tutionFreq,
    };

    // // console.log(feeDtls);
    saveFeeInfoSvc(feeDtls, cbSaveFeeResponse);
  }

  function cbSaveFeeResponse(pObj) {
    if (pObj.status === "Success") {
      toast.success(pObj.message);
      props.getFeeList();
    } else {
      toast.error(pObj.message);
    }
    setLoader("none");
  }

  return (
    <form
      className="form form-horizontal"
      style={{
        pointerEvents: loader === "none" ? "auto" : "none",
        height: "auto",
        width: "90%",
        margin: "10px auto",
        background: "#fff",
        border: "1px solid #ccc",
        borderRadius: 10,
      }}
    >
      <button
        type="button"
        className="btn btn-info text-center btnCloseDiv"
        onClick={props.closeSlider}
        style={{ right: "50%" }}
      >
        <span
          id="close"
          title="Close Slider"
          className="glyphicon glyphicon-remove"
        ></span>
      </button>
      <div
        style={{
          position: "fixed",
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: "50%",
          top: "50%",
          display: loader,
        }}
      >
        <img
          className="loderImg"
          src={require("common/images/loader.gif")}
          alt="Loader..."
        />
      </div>
      <div className="row no-display" style={{ width: "100%" }}>
        <div className="form-group col-md-6">
          <label className="control-label col-sm-5 text-left">Class Id</label>
          <div className="col-sm-6 padding-remove">
            <input
              type="text"
              defaultValue={classId}
              className="form-control"
              readOnly
            ></input>
          </div>
        </div>
      </div>
      <div className="row" style={{ width: "100%" }}>
        <div className="form-group col-md-6">
          <label className="control-label col-sm-8">Academic Year </label>
          <div className="col-sm-4 padding-remove">
            <input
              type="text"
              defaultValue={props.acdYr}
              className="form-control"
              readOnly
            ></input>
          </div>
        </div>
        <div className="form-group col-md-6">
          <label className="control-label col-sm-6">Class </label>
          <div className="col-sm-6 padding-remove">
            <input
              type="text"
              defaultValue={classNm}
              className="form-control"
              readOnly
            ></input>
          </div>
        </div>
      </div>
      <div className="row" style={{ width: "100%" }}>
        <div className="form-group col-md-6">
          <label className="control-label col-sm-8">Registration Fee </label>
          <div className="col-sm-4 padding-remove">
            <input
              type="number"
              value={regsFee}
              className="form-control text-right"
              onChange={(e) => setRegsFee(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="form-group col-md-6">
          <div className="col-sm-4"></div>
          <label className="control-label col-sm-5 text-left">One Time </label>
        </div>
      </div>

      <div className="row" style={{ width: "100%" }}>
        <div className="form-group col-md-6">
          <label className="control-label col-sm-8">Admission Fee </label>
          <div className="col-sm-4 padding-remove">
            <input
              type="number"
              value={admissionFee}
              className="form-control text-right"
              onChange={(e) => setAdmissionFee(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="form-group col-md-6">
          <div className="col-sm-4"></div>
          <label className="control-label col-sm-5 text-left">One Time </label>
        </div>
      </div>

      <div className="row" style={{ width: "100%" }}>
        <div className="form-group col-md-6">
          <label className="control-label col-sm-8">Tuition Fee </label>
          <div className="col-sm-4 padding-remove">
            <input
              type="nu,ber"
              value={tutionFee}
              className="form-control text-right"
              onChange={(e) => setTutionFee(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="form-group col-md-6">
          <div className="col-sm-4">
            <input
              type="number"
              className="form-control text-right"
              value={tutionFreq}
              onChange={(e) => setTutionFreq(e.target.value)}
            />
          </div>
          <label className="control-label col-sm-5 text-left">Months </label>
        </div>
      </div>

      <div className="row" style={{ width: "100%" }}>
        <div className="form-group col-md-6">
          <label className="control-label col-sm-8">Transport Fee </label>
          <div className="col-sm-4 padding-remove">
            <input
              type="number"
              value={transportFee}
              className="form-control text-right"
              onChange={(e) => setTransportFee(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="form-group col-md-6">
          <div className="col-sm-4">
            <input
              type="number"
              className="form-control text-right"
              value={transportFreq}
              onChange={(e) => setTransportFreq(e.target.value)}
            />
          </div>
          <label className="control-label col-sm-5 text-left">Months </label>
        </div>
      </div>

      <div className="row" style={{ width: "100%" }}>
        <div className="form-group col-md-6">
          <label className="control-label col-sm-8">Others </label>
          <div className="col-sm-4 padding-remove">
            <input
              type="number"
              value={otherFee}
              className="form-control text-right"
              onChange={(e) => setOtherFee(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="form-group col-md-6">
          <div className="col-sm-4">
            <input
              type="number"
              className="form-control text-right"
              value={otherFreq}
              onChange={(e) => setOtherFreq(e.target.value)}
            />
          </div>
          <label className="control-label col-sm-5 text-left">Months </label>
        </div>
      </div>

      <div className="row" style={{ width: "100%" }}>
        <div className="form-group col-md-6">
          <label className="control-label col-sm-8">Annual Fee </label>
          <div className="col-sm-4 padding-remove">
            <input
              type="number"
              value={annualFee}
              className="form-control text-right"
              onChange={(e) => setAnnualFee(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="form-group col-md-6">
          <div className="col-sm-4"></div>
          <label className="control-label col-sm-5 text-left">Annual </label>
        </div>
      </div>

      <div className="row" style={{ width: "100%", paddingBottom: "1px" }}>
        <div className="col-md-offset-10 col-md-1 padding-remove">
          <button
            type="button"
            className="btn btn-info pull-right"
            onClick={(e) => saveFee()}
          >
            Save
          </button>
        </div>
      </div>

      <h3 className="col-sm-4 col-centered">TOTAL</h3>
      <div className="row">
        <div className="col-sm-12" style={{ width: "100%" }}>
          <label className="control-label col-sm-4 text-left">Monthly</label>
          <div className="form-group col-md-4">
            <div className="col-sm-6 padding-remove">
              <input
                type="number"
                value={totalFeeM}
                className="form-control text-right"
                readOnly
              ></input>
            </div>
          </div>
        </div>
        <div className="col-sm-12" style={{ width: "100%" }}>
          <label className="control-label col-sm-4 text-left">Annual</label>
          <div className="form-group col-md-4">
            <div className="col-sm-6 padding-remove">
              <input
                type="number"
                value={totalFeeFY}
                className="form-control text-right"
                readOnly
              ></input>
            </div>
          </div>
        </div>
        <div className="col-sm-12" style={{ width: "100%" }}>
          <label className="control-label col-sm-4 text-left">
            Annual(First Year)
          </label>
          <div className="form-group col-md-4">
            <div className="col-sm-6 padding-remove">
              <input
                type="number"
                value={totalFeeFY + totalFeeM}
                className="form-control text-right"
                readOnly
              ></input>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SchoolFeeSlider;
