import axios from 'axios'
import lstModules from 'common/json/lstModules.json'

const httpURL = lstModules['cms'].url + ':' + lstModules['cms'].port
var tokenid = localStorage.getItem('tokenid')
var screenId = 8

function getAwardListSvc (callback) {
  var status = 'Success'
  var obj = { status: status }

  axios({
    url: httpURL + '/sms/cms/award/list',
    method: 'POST',
    params: { screenId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      if (response.data.SvcStatus === 'Success') {
        obj.lstAward = response.data.lstAward
        obj.message = response.data.SvcMsg
        obj.status = 'Success'
        callback(obj)
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      callback(obj)
    })
}
function saveAwardSvc (formData, callBack) {
  axios({
    url: httpURL + '/sms/cms/award/save',
    method: 'POST',
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      callBack(response.data)
    })
    .catch(e => {
      console.error('Service failed ' + e)
    })
}

function deleteAwardSvc (pAwardId, callBack) {
  // // console.log('pAwardId = ' + pAwardId)
  axios({
    url: httpURL + '/sms/cms/award/delete',
    method: 'POST',
    params: { awardId: pAwardId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      callBack(response.data)
    })
    .catch(e => {
      console.error('Service failed ' + e)
    })
}

/* 
function getBannerSvc (pBannerId, pos, pth, callBack) {
  axios({
    url: httpURL + '/sms/cms/img/view',
    method: 'GET',
    params: { imgPath: pth },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      callBack(pos, pBannerId, response)
    })
    .catch(e => {
      console.error('Service failed ' + e)
    })
}
function saveBannerSvc (file, pBannerId, callBack) {
  var formData = new FormData()
  formData.append('file', file)
  formData.append('bannerId', pBannerId)

  axios({
    url: httpURL + '/sms/cms/banner/img/upload',
    method: 'POST',
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      callBack(response.data, pBannerId)
    })
    .catch(e => {
      console.error('Service failed ' + e)
    })
}
 */

function getRecognitionListSvc (callback) {
  var status = 'Success'
  var obj = { status: status }

  axios({
    url: httpURL + '/sms/cms/recognition/list',
    method: 'POST',
    params: { screenId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      if (response.data.SvcStatus === 'Success') {
        obj.lstRecognition = response.data.lstRecognition
        obj.message = response.data.SvcMsg
        obj.status = 'Success'
        callback(obj)
      }
    })
    .catch(error => {
      obj.status = 'Exception'
      obj.message = error
      callback(obj)
    })
}

function saveRecognitionSvc (formData, callBack) {
  axios({
    url: httpURL + '/sms/cms/recognition/save',
    method: 'POST',
    data: formData,
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      callBack(response.data)
    })
    .catch(e => {
      console.error('Service failed ' + e)
    })
}

function deleteRecognitionSvc (pRecognitionId, callBack) {
  // // console.log('pRecognitionId = ' + pRecognitionId)
  axios({
    url: httpURL + '/sms/cms/recognition/delete',
    method: 'POST',
    params: { recognitionId: pRecognitionId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(response => {
      callBack(response.data)
    })
    .catch(e => {
      console.error('Service failed ' + e)
    })
}
export {
  getAwardListSvc,
  saveAwardSvc,
  deleteAwardSvc,
  getRecognitionListSvc,
  saveRecognitionSvc,
  deleteRecognitionSvc
}
