import React from 'react';

const InterviewSlotTable = (props) => {

    const colgrp = (
        <colgroup>
          <col width="8%"></col>
          <col width="8%"></col>
          <col width="8%"></col>
          <col width="12%"></col>
          <col width="8%"></col>
          <col width="8%"></col>
          <col width="8%"></col>
          <col width="8%"></col>
          <col width="8%"></col>
          <col width="8%"></col>
          <col width="18%"></col>
        </colgroup>
      );

      return (
        <div>
          <table className="tableLayout1">
            {colgrp}
            <thead>
              <tr>
                <th>Date</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Slot Label</th>
                <th>Time/Candidate (Mins)</th>
                <th>Max. Candidates</th>
                <th>Assigned Candidates</th>
                <th>Total Time (Mins)</th>
                <th>Assigned Time (Mins)</th>
                <th>Available Time (Mins)</th>
                <th>Panel</th>
              </tr>
            </thead>
          </table>
          <div className="tablebodyWrapper" style={{overflowX:'hidden'}}>
            <table className="tableLayout" >
              {colgrp}
              <tbody>
                {props.lstInterviewSlot.map((slot, idx) => {
                  return (
                    <tr key={idx} id={slot.interviewSlotId} onClick={(e)=>props.setInterviewSlotId(slot.interviewSlotId)} 
                    className={slot.interviewSlotId === props.selectedInterviewSlotId ? "selected" : ""} >
                      <td>{slot.slotDt}</td>
                      <td>{slot.startTm}</td>
                      <td>{slot.endTm}</td>
                      <td>{slot.slotLabel}</td>
                      <td>{slot.timePerCandidate}</td>
                      <td>{slot.maxCandidates}</td>
                      <td>{slot.assignedCandidates}</td>
                      <td>{slot.totalTm}</td>
                      <td>{slot.assignedTm}</td>
                      <td>{slot.availableTm}</td>
                      <td>{slot.csvPanel}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
};

export default InterviewSlotTable;
