import React, { useState, useRef, useEffect, useMemo } from 'react'
import { getAdmissionListSvc, getStaffListSvc } from './AdmissionSvc.js'
import { toast, ToastContainer } from 'react-toastify'
import SliderType from './sliderType.js'

const AdmissionTable = props => {
  const [lstAdmission, setLstAdmission] = useState([])
  const [flgSliderType, setFlgSliderType] = useState([])
  const [loader, setLoader] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [cls, setCls] = useState('')
  const [foundAdmission, setFoundAdmission] = useState([])
  //const [lstSelAdmission, setLstSelAdmission] = useState({enqId:0,selected:false});
  const [lstSelAdmission, setLstSelAdmission] = useState([])
  // const [checked, setChecked] = useState(false);
  // const data=useMemo(()=> MOCK_DATA,[])
  const ref = useRef()

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false)
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isMenuOpen])

  useEffect(() => {
    setLoader('block')
    getAdmissionListSvc(handleEnqResponse)
  }, [])

  function handleEnqResponse (data) {
    setLoader('none')
    if (data.SvcStatus === 'Success') {
      setLstAdmission(data.lstAdmn)
      setFoundAdmission(data.lstAdmn)
    } else {
      toast.error(data.SvcMsg)
    }
  }

  function handleCBoxChange (admissionId, status) {
    // // console.log('handleCBoxChange triggered')
    if (status && !lstSelAdmission.includes(admissionId)) {
      var lstTempArr = lstSelAdmission
      lstTempArr.push(admissionId)
      setLstSelAdmission(lstTempArr)
      // // console.log('checked ' + lstTempArr)
      returnSelEnqId()
    } else {
      var lstTempArr = lstSelAdmission
      lstTempArr.splice(lstTempArr.indexOf(admissionId))
      setLstSelAdmission(lstTempArr)
      // // console.log('unchecked' + lstTempArr)
      returnSelEnqId()
    }
  }

  function returnSelEnqId () {
    props.setAdmissionToAssign(lstSelAdmission)
  }

  function updateFlgSliderType (params) {
    setFlgSliderType(params)
    // // console.log(flgSliderType)
  }

  function actionClick (pAdmissionId, pClsId) {
    props.setSelectedAdmissionId(pAdmissionId)
    props.setSelectedClsId(pClsId)
    setIsMenuOpen(oldState => !oldState)
  }
  const goToRegisterScreen = async pAdmissionId => {
    /* await localStorage.setItem('AdmissionId', pAdmissionId)
    await localStorage.setItem('pUserId', pUserId) */
    window.open('/admnregister?admissionId=' + pAdmissionId, '_blank')
  }
  const filterName = e => {
    const keyword = e.target.value
    if (keyword !== '') {
      const results = foundAdmission.filter(user => {
        return user.name.toLowerCase().includes(keyword.toLowerCase())
        // Use the toLowerCase() method to make it case-insensitive
      })
      setFoundAdmission(results)
    } else {
      setFoundAdmission(lstAdmission)
      // If the text field is empty, show all users
    }

    setName(keyword)
  }
  const filterEmail = e => {
    const keyword = e.target.value
    if (keyword !== '') {
      const results = foundAdmission.filter(user => {
        return user.email.toLowerCase().includes(keyword.toLowerCase())
        // Use the toLowerCase() method to make it case-insensitive
      })
      setFoundAdmission(results)
    } else {
      setFoundAdmission(lstAdmission)
      // If the text field is empty, show all users
    }

    setEmail(keyword)
  }
  const filterClass = e => {
    const keyword = e.target.value
    if (keyword !== '') {
      const results = foundAdmission.filter(user => {
        return user.cls.toLowerCase().includes(keyword.toLowerCase())
        // Use the toLowerCase() method to make it case-insensitive
      })
      setFoundAdmission(results)
    } else {
      setFoundAdmission(lstAdmission)
      // If the text field is empty, show all users
    }

    setCls(keyword)
  }
  const colgrp = (
    <colgroup>
      <col width='11%'></col>
      <col width='7%'></col>
      <col width='15%'></col>
      <col width='5%'></col>
      {/* regs cols */}
      <col width='7%'></col>
      <col width='5%'></col>
      <col width='7%'></col>
      {/* admission cols */}
      <col width='5%'></col>
      <col width='7%'></col>

      <col width='11%'></col>
      <col width='9%'></col>
      <col width='6%'></col>
      <col width='6%'></col>
    </colgroup>
  )

  return (
    <div ref={ref}>
      <div
        style={{
          position: 'fixed',
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: '50%',
          top: '50%',
          display: loader
        }}
      >
        <img
          className='loderImg'
          src={require('common/images/loader.gif')}
          alt='Loader...'
        />
      </div>
      <table className='tableLayout1'>
        {colgrp}
        <thead>
          <tr>
            <th colSpan='4'></th>
            <th colSpan='3'>Registration</th>
            <th colSpan='2'>Admission</th>
            <th colSpan='4'></th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th className='text-center' style={{ width: 75 }}>
              <input
                onChange={filterName}
                type='text'
                value={name}
                className='form-control inp-search'
                placeholder='Name'
              />
            </th>
            <th className='text-center'>Contact</th>
            <th className='text-center' style={{ width: 75 }}>
              <input
                onChange={filterEmail}
                type='text'
                value={email}
                className='form-control inp-search'
                placeholder='Email'
              />
            </th>
            <th className='text-center' style={{ width: 75 }}>
              <input
                onChange={filterClass}
                type='text'
                value={cls}
                className='form-control inp-search'
                placeholder='Class'
              />
            </th>
            <th className='text-center'>Started On</th>
            <th className='text-center'>Amount</th>
            <th className='text-center'>Paid On</th>
            <th className='text-center'>Amount</th>
            <th className='text-center'>Paid On</th>
            <th className='text-center'>Counselor</th>
            <th className='text-center'>Status</th>
            <th className='text-center'>Select</th>
            <th className='text-center'>Action</th>
          </tr>
        </thead>
      </table>
      <div className='tablebodyWrapper'>
        <table className='tableLayout'>
          {colgrp}
          <tbody>
            {foundAdmission.map((admn, idx) => {
              return (
                <tr
                  className={
                    admn.admissionId === props.selectedAdmissionId
                      ? 'selected'
                      : ''
                  }
                  key={idx}
                  onClick={e => props.setSelectedAdmissionId(admn.admissionId)}
                >
                  <td className='text-left'>{admn.name}</td>
                  <td className='text-left'>{admn.contact}</td>
                  <td className='text-left'>{admn.email}</td>
                  <td
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {admn.cls}
                  </td>

                  <td className='text-center'>{admn.regsDt}</td>
                  <td className='text-right'>
                    {admn.regsFee.toLocaleString('en-IN')}
                  </td>
                  <td className='text-center'>{admn.regsPaymentDt}</td>
                  <td className='text-right'>
                    {admn.admissionFee.toLocaleString('en-IN')}
                  </td>
                  <td className='text-center'>{admn.admissionPaymentDt}</td>
                  <td className='text-left'>{admn.assignedTo}</td>
                  <td className='text-left'>{admn.status}</td>
                  <td>
                    <input
                      type='checkbox'
                      onClick={e =>
                        handleCBoxChange(admn.admissionId, e.target.checked)
                      }
                    />
                  </td>
                  <td className='text-center'>
                    <span
                      className='glyphicon glyphicon-option-vertical'
                      onClick={e => actionClick(admn.admissionId, admn.clsId)}
                    >
                      <ul
                        className='ulStyle1'
                        style={{
                          display:
                            isMenuOpen &&
                            admn.admissionId === props.selectedAdmissionId
                              ? 'block'
                              : 'none'
                        }}
                      >
                        <li
                          onClick={e => {
                            goToRegisterScreen(admn.admissionId)
                          }}
                        >
                          <a href='/admnregister' target='_blank'>
                            Profile
                          </a>
                        </li>

                        <li
                          onClick={e => {
                            props.setCls(admn.cls)
                            props.setFlgSliderType(
                              SliderType.SLIDER_REGISTER_FEE
                            )
                          }}
                        >
                          Registration Fee
                        </li>

                        <li
                          onClick={e => {
                            props.setCls(admn.cls)
                            props.setFlgSliderType(
                              SliderType.SLIDER_ADMISSION_FEE
                            )
                          }}
                        >
                          Admission Fee
                        </li>

                        <li
                          onClick={e =>
                            props.setFlgSliderType(
                              SliderType.SLIDER_SCHEDULE_TEST
                            )
                          }
                        >
                          Schedule Test
                        </li>
                        {/* <li onClick={(e) => props.setFlgSliderType(SliderType.SLIDER_SCHEDULE_INTERVIEW)}>Schedule Interview</li> */}
                        <li
                          onClick={e =>
                            props.setFlgSliderType(
                              SliderType.SLIDER_TEST_RESULT
                            )
                          }
                        >
                          Evaluate Test
                        </li>
                        <li
                          onClick={e =>
                            props.setFlgSliderType(
                              SliderType.SLIDER_INTERVIEW_RESULT
                            )
                          }
                        >
                          Evaluate Interview
                        </li>
                        <li
                          onClick={e =>
                            props.setFlgSliderType(SliderType.SLIDER_CLOSE)
                          }
                        >
                          Close
                        </li>
                      </ul>
                    </span>{' '}
                  </td>
                  {/* <td>
                  <div className="dropdown">
                    <button className="dropbtn" onClick={e => actionClick(admn.admissionId)}>Action</button>
                  </div>
                </td> */}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default AdmissionTable
