import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "common/css/master.css";
import "../css/transport.css";
import { getStopListSvc } from "./routeSvc";
import { LoadScript } from "@react-google-maps/api";
import Map from "./Map";
import Loader from "common/components/Loader";
import "../css/tabs.css";
const lodashCloneDeep = require('lodash.clonedeep')
const RouteMapView = (props) => {
  const [loader, setLoader] = useState("none");
  const [toggleState, setToggleState] = useState(1);
  const [displayLoader1, setDisplayLoader1] = useState("none");
  const [displayLoader2, setDisplayLoader2] = useState("none");
  const [flgMorEve, setFlgMorEve] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [objRoute, setObjRoute] = useState({routes:[], toggleState:1})
  useEffect(() => {
    setFlgMorEve(false);
    // // console.log("useEffect called");
    getStopListSvc(props.routeId, false, cbStopList);
  }, [props.routeId]);

  useEffect(() => {
    if (props.flgMap && props.routeId) {
      setLoader("block");
    }
  }, [props]);

  function cbStopList(data, pFlgMorEve) {
    // // console.log(data);
    if (data.SvcStatus === "Success") {
      // // console.log(JSON.stringify(data.lstStop));
      if (data.lstStop.length > 0) {
        getRouteFromStops(data.lstStop);
        setToggleState(pFlgMorEve?2:1)
      }
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }

  const getRouteFromStops = (pLstStop) => {
    var lstNewCoordinates = [];
    for (var i = 0; i < pLstStop.length; i++) {
      var obj = pLstStop[i];
      var tmpLatLng = obj.latLong.split(",");
      var loc = {
        location: {
          id: obj.address,
          lat: parseFloat(tmpLatLng[0]),
          lng: parseFloat(tmpLatLng[1]),
        },
      };
      lstNewCoordinates.push(loc);
    }
    setRoutes(lstNewCoordinates);

    // // console.log("lstNewCoordinates = " + JSON.stringify(lstNewCoordinates));
  };

  const toggleTab = (index) => {
    if (index === 1) {
      setFlgMorEve(false);
      setDisplayLoader1("block");
      getStopListSvc(props.routeId, false, cbStopList);
    } else if (index === 2) {
      setFlgMorEve(true);
      setDisplayLoader2("block");
      getStopListSvc(props.routeId, true, cbStopList);
    }
    /* setToggleState(index); */
  };

  return (
    <>
      <form
        className="form form-horizontal"
        style={{
          pointerEvents: loader === "none" ? "auto" : "none",
          height: "auto",
          background: "#fff",
          border: "1px solid #ccc",
          borderRadius: 10,
        }}
      >
        <button
          type="button"
          className="btn btn-info text-center btnCloseDiv"
          onClick={props.closeSlider}
          style={{ right: "70%" }}
        >
          <span
            id="close"
            title="Close Slider"
            className="glyphicon glyphicon-remove"
          ></span>
        </button>
        <LoadScript
          googleMapsApiKey={"AIzaSyC_G16AeOUmhNAP9FuTvreRhFTs31bL4mQ"}
        >
          <div className="tabs-container">
            <div className="bloc-tabs">
              <button
                className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                onClick={(e) => {e.preventDefault();toggleTab(1);}}
              >
                Morning
              </button>
              <button
                className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                onClick={(e) => {e.preventDefault();toggleTab(2)}}
              >
                Evening
              </button>
            </div>
            <div className="content-tabs">
              <div
                className={
                  "body-content active-body-content"
                }
              >
                <Loader loader={loader} position="absolute" />
                
                {routes && routes.length >0 ? (
                  <Map routes={lodashCloneDeep(routes)} key={toggleState}/>
                ):"No stops Found"}
              </div>
            </div>
          </div>
        </LoadScript>
      </form>
    </>
  );
};
export default RouteMapView;
