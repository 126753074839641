import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import Loader from "common/components/LoaderSlider";

import "../css/schoolProfile.css";
import { saveLatLongSvc, getLatLongSvc } from "./schoolProfileSvc";


const SchoolLocationCoordinates = () => {
    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [latLong, setLatLong] = useState("");

    useEffect(() => {
        // // console.log("SchoolLocationCoordinates component called");
        getLocationCoordinates();
    }, []);

    const getLocationCoordinates = () => {
        
        setLoader("block");
        setOpacity(0.5);
        getLatLongSvc(cbLatLongResponse);
    }

    const cbLatLongResponse = (obj) => {
        setLoader("none");
        setOpacity(1);
        if (obj.status === "Success") {
          setLatLong(obj.latLong);
        } else if (obj.status === "Failure") {
          if (obj.message === "You are not logged in") {
            setTimeout(function () {
              window.location.href = "/";
            }, 2000);
            return false;
          } else if (obj.message === "Your Session is expired, login again") {
            setTimeout(function () {
              window.location.href = "/";
            }, 2000);
            return false;
          } else {
            toast.error(obj.message);
          }
        } else {
          toast.error("Service failed" + obj);
        }
      }
    
  function saveLatLong(e) {
    e.preventDefault();
    setLoader("block");
    setOpacity(0.5);
    saveLatLongSvc(latLong, handleLatLongSaveResponse);
  }
  function handleLatLongSaveResponse(obj) {
    setLoader("none");
    setOpacity(1);
    if (obj.status === "Success") {
      toast.success(obj.message);
    } else if (obj.status === "Failure") {
      if (obj.message === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.message === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.message);
      }
    } else {
      toast.error("Service failed" + obj);
    }
  }

    return (
        <div className="col-xl-12 col-ls-12 col-md-12 col-sm-12 col-xs-12 padding-remove"
            style={{
                cursor: (loader === "none") ? 'pointer' : 'none',
                pointerEvents: (loader === "none") ? 'auto' : 'none',
                opacity: opacity
            }}>
            <Loader loader={loader} position="fixed" top="15%" left="45%" />
            <form
                className="form form-vertical col-sm-12"
                style={{ margin: "2%", border: "1px solid #ddd", borderRadius: 6 }}
            >
                <div
                    className="EnquiryHead col-centered"
                    style={{
                        fontWeight: "bold",
                    }}
                >
                    <h4>Coordinates</h4>
                </div>
                <div className="row">
                    <label className="control-label col-sm-2" htmlFor="coordinates">
                        Coordinates
                    </label>
                    <div className="form-group col-sm-9">
                        <input
                            className="form-control"
                            name="coordinates"
                            value={latLong}
                            onChange={(e) => setLatLong(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-sm-11">
                        <button
                            className="pull-right btn btn-info"
                            onClick={(e) => saveLatLong(e)}
                            title="Save"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default SchoolLocationCoordinates;
