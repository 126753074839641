import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "common/css/master.css";
import Loader from "common/components/Loader";
import "../css/schoolProfile.css";
import { saveParentAppDtlsSvc, getParentAppDtlsSvc } from "./schoolProfileSvc";

const SchoolParentApp = () => {
  const [loader, setLoader] = useState("none");
  const [opacity, setOpacity] = useState(1);
  const [flgSystemGenerated, setFlgSystemGenerated] = useState(true);
  const [defaultPwd, setDefaultPwd] = useState('');

  useEffect(() => {
    // // console.log("SchoolParentApp component mounted");
    fetchParentAppDetails();
  }, []);

  const fetchParentAppDetails = () => {
    setLoader("block");
    setOpacity(0.5);
    getParentAppDtlsSvc(handleParentAppDtlsResponse);
  }

  const handleParentAppDtlsResponse = (data) => {
    setLoader("none");
    setOpacity(1);
    
    if (data.status === "Success") {
      setFlgSystemGenerated(data.parentDtls.systemGeneratedPwd);
      setDefaultPwd(data.parentDtls.defaultPwd);
    } else {
      handleError(data);
    }
  }

  const handleError = (data) => {
    if (data.message === "You are not logged in" || data.message === "Your Session is expired, login again") {
      setTimeout(() => window.location.href = "/", 2000);
    } else {
      toast.error(data.message);
    }
  }

  const handleFlgSystemGenerated = (flg) => {
    setFlgSystemGenerated(flg);
    setDefaultPwd(flg ? '' : '1234567890');
  }

  const saveParentAppDtls = () => {
    setLoader("block");
    setOpacity(0.5);

    const parentAppObj = {
      systemGeneratedPwd: flgSystemGenerated,
      defaultPwd: defaultPwd,
    };

    saveParentAppDtlsSvc(parentAppObj, handleSaveParentAppDtlsResponse);
  }

  const handleSaveParentAppDtlsResponse = (obj) => {
    setLoader("none");
    setOpacity(1);

    if (obj.status === "Success") {
      toast.success(obj.message);
    } else {
      handleError(obj);
    }
  }

  return (
    <div className="col-xl-12 col-ls-12 col-md-12 col-sm-12 col-xs-12 padding-remove"
         style={{
           cursor: loader === "none" ? 'pointer' : 'none',
           pointerEvents: loader === "none" ? 'auto' : 'none',
           opacity: opacity,
         }}>
      <Loader loader={loader} position="fixed" />
      <form className="form form-vertical col-sm-12"
            style={{ margin: "2%", border: "1px solid #ddd", borderRadius: 6 }}>
        <div className="EnquiryHead col-centered" style={{ fontWeight: "bold" }}>
          <h4>Parent App Details</h4>
        </div>
        <div className="row">
          <label className="col-sm-5 control-label text-left">System Generated Password</label>
          <div className="col-sm-1 form-group text-left">
            <input
              className="form-check-input"
              type="checkbox"
              checked={flgSystemGenerated}
              onChange={(event) => handleFlgSystemGenerated(event.target.checked)}
            />
          </div>
        </div>
        <div className="row">
          <label className="control-label col-sm-5 text-left">Password</label>
          <div className="form-group col-sm-4 text-left">
            <input
              type="text"
              className="form-control"
              name="pwd"
              value={defaultPwd}
              onChange={(e) => setDefaultPwd(e.target.value)}
              disabled={flgSystemGenerated}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-11">
            <button
              className="pull-right btn btn-info"
              title="Save"
              onClick={(e) => {
                e.preventDefault(); // Prevent default form submission
                saveParentAppDtls();
              }}>
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SchoolParentApp;
