import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';
import DarkModeToggle from 'common/js/darkMode/DarkModeToggle';


import MenuItems from "common/json/menuItems.json";


const Menu = () => {

    const [lstMenuItems, setLstMenuItems] = useState(MenuItems.menuItems);
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const [subMenuClass, setSubMenuClass] = useState("");
    const [objModule, setObjModule] = useState(JSON.parse(localStorage.getItem('module')));
    const [module, setModule] = useState(objModule);

    const toggleSubmenuvisibility = (menuCls) => {
        if (isSubMenuOpen && subMenuClass === menuCls) {
            setIsSubMenuOpen(false);
            setSubMenuClass("");
        }
        else {
            setIsSubMenuOpen(true);
            setSubMenuClass(menuCls);
        }
    }

    return (
        <>
        {module && Object.keys(module).length > 0 && (
                    <ul className="navigationMenu">
                        <DarkModeToggle />
                        {lstMenuItems &&
                            lstMenuItems.map((menuItem) => (
                                <li
                                    className={
                                        menuItem.hasChildren
                                            ? "hasChildren " +
                                            menuItem.menuClass +
                                            (isSubMenuOpen
                                                ? subMenuClass === menuItem.menuClass
                                                    ? " openChildMenu"
                                                    : ""
                                                : "")
                                            : ""
                                    }
                                    style={{
                                        display: menuItem.isHidden ? "none" : "",
                                        pointerEvents: module[menuItem.module] ? "" : "none",
                                        cursor: module[menuItem.module] ? "pointer" : "not-allowed",
                                        opacity: module[menuItem.module] ? "1" : "0.4",
                                    }}
                                    onClick={() => {
                                        if (menuItem.hasChildren) {
                                            toggleSubmenuvisibility(menuItem.menuClass);
                                        }
                                    }}>
                                    {menuItem.hasChildren && (
                                        <>
                                            <a className="dropdownToggle" href="#!">
                                                {menuItem.menuTitle}
                                                <div className="mobile childTrigger">
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                            </a>
                                            <ul className="dropdownMenu">
                                                {menuItem.submenu.map((submenuItem) => (
                                                    <li
                                                        key={submenuItem.subMenuUrl}
                                                        style={submenuItem.isHidden ?
                                                            {
                                                                cursor: "pointer",
                                                                opacity: "1",
                                                                display: "none",
                                                            } : {}
                                                        }
                                                    >
                                                        <NavLink
                                                            to={submenuItem.subMenuUrl}
                                                        // activeClassName="active"
                                                        >
                                                            {submenuItem.subMenuTitle}
                                                        </NavLink>
                                                    </li>
                                                ))}
                                            </ul>
                                        </>
                                    )}
                                    {!menuItem.hasChildren && (
                                        <NavLink to={menuItem.menuUrl}
                                        // activeClassName="active"
                                        >
                                            {menuItem.menuTitle}
                                        </NavLink>
                                    )}
                                </li>
                            ))}
                    </ul>
                )}
        </>
    );
}

export default Menu;