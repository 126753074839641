import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
var tokenid = localStorage.getItem('tokenid')
var httpURL = lstModules['ptm'].url + ':' + lstModules['ptm'].port
var httpURLCommon = lstModules['main'].url + ':' + lstModules['main'].port

function getClsPtmSummaryListSvc (ptmId, callBack) {
  axios({
    url: httpURLCommon + '/sms/ptm/feedback/cls/section/list',
    method: 'POST',
    params: { ptmId: ptmId },
    headers: { tokenid: tokenid },
    withCredentials: true
  })
    .then(Response => {
      // // console.log(Response.data)
      var ourData = Response.data

      callBack(ourData)
    })
    .catch(error => {
      // // console.log('error')
    })
}

export { getClsPtmSummaryListSvc }
