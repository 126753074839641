import React, { useState, useEffect, useRef } from 'react';
import { getCellDtls, deleteCellDtls } from './ttSubServices';
import { toast, ToastContainer } from 'react-toastify';


const CellForSub = (props) => {
    var initialState = {
        loader: 'none',
        opacity: 1,
    };
    const [state, setState] = useState(initialState);
    const [cellDtls, setCellDtls] = useState([props.cellDtls]);
    const [periodId, setPeriodId] = useState(cellDtls.periodId);
    const [cellId, setCellId] = useState([props.cellId]);
    const [dayId, setDayId] = useState(props.dayId);
    const [sectionId, setSectionId] = useState(props.sectionId);
    const [randNo, setRandNo] = useState(0)
    const [refresh, setRefresh] = useState(true)
    const isMountedRef = useIsMounted();

    // 👇️ extract logic into reusable hook
    function useIsMounted() {
        const isMounted = useRef(false);

        useEffect(() => {
            isMounted.current = true;

            return () => {
                isMounted.current = false;
            };
        });

        return isMounted;
    }

    function showLoader() {
        setState({ ...state, loader: 'block', opacity: 0.5 });
    }
    function hideLoader() {
        setState({ ...state, loader: 'none', opacity: 1 });
    }
    function generateRandomNo() {
        var lRandNo = Math.floor(Math.random() * 999999);
        // // console.log(lRandNo)
        setRandNo(lRandNo)
    }

    useEffect(() => {
        async function fetchData() {
            const result = await Promise.resolve(['hello', 'world']);

            // 👇️ only update state if component is mounted
            if (isMountedRef.current) {
                setState(result);
                setPeriodId(props.periodId);
                setDayId(props.dayId)
                setSectionId(props.sectionId)
                setCellId(props.cellId)
                if (refresh === true) {
                    // // console.log("ttsubcell")
                    var rqCell = {
                        schedulePeriodId: props.periodId,
                        classSectionId: props.sectionId,
                        dayId: props.dayId,
                        cellId: !props.selCellId ? 0 : props.selCellId,
                    };

                    setCell(rqCell, false);

                }
                // // console.log("useEffect of Cell" + JSON.stringify(props))

            }
        }

        fetchData();

    }, [isMountedRef])

    function generateRandomNo() {
        var lRandNo = Math.floor(Math.random() * 999999);
        // // console.log(lRandNo)
        setRandNo(lRandNo)
    }

    function setCell(rqCell, pRefresh) {
        getCellDtls(handleCellResponse, rqCell, pRefresh, rqCell.cellId);
    }

    function handleCellResponse(data, pRefresh) {
        var obj = data;
        // // console.log("data" + JSON.stringify(data))
        if (obj.SvcStatus === 'Success') {

            setRefresh(false)
            setCellDtls(obj.cellDtls);
            setCellId(obj.cellDtls.cellId)


            // props.setSelCellId(obj.cellDtls.cellId)
            if (pRefresh) generateRandomNo()
        } else if (obj.SvcStatus === 'Failure') {
            if (obj.SvcMsg === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.SvcMsg === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.SvcMsg);
            }
        } else {
            toast.error("Service failed " + obj.SvcMsg);
        }
    }

    function removeSub(cellId, subPosition) {
        deleteCellDtls(cellId, subPosition, handleDeleteResponse);
    }

    function handleDeleteResponse(data, pRqCell, subPosition) {
        if (data.status === 'Success') {
            var rqCell = {
                schedulePeriodId: props.periodId,
                classSectionId: props.sectionId,
                dayId: props.dayId,
                cellId: pRqCell.cellId,
            };
            toast.success(data.message);
            setCell(rqCell, true);
            props.setSubPositionId(subPosition - 1 < 0 ? 0 : subPosition - 1)
            // generateRandomNo();
        } else if (data.status === 'Failure') {
            if (data.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (data.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(data.message);
            }
        } else {
            toast.error("Service failed " + data.error);
        }
    }



    return (cellDtls.subCtr === 0 && <CellForSubZero key={randNo} cellId={cellDtls.cellId} cellDtls={cellDtls} generateRandomNo={generateRandomNo} subCtr={cellDtls.subCtr} dayId={dayId} periodId={periodId} classSectionId={sectionId} /> ||
        cellDtls.subCtr === 1 && <CellForSubOne key={randNo} cellId={cellDtls.cellId} cellDtls={cellDtls} removeSub={removeSub} generateRandomNo={generateRandomNo} subCtr={cellDtls.subCtr} dayId={dayId} periodId={periodId} classSectionId={sectionId} /> ||
        cellDtls.subCtr === 2 && <CellForSubTwo key={randNo} cellId={cellDtls.cellId} cellDtls={cellDtls} removeSub={removeSub} generateRandomNo={generateRandomNo} subCtr={cellDtls.subCtr} dayId={dayId} periodId={periodId} classSectionId={sectionId} /> ||
        cellDtls.subCtr === 3 && <CellForSubThree key={randNo} cellId={cellDtls.cellId} cellDtls={cellDtls} removeSub={removeSub} generateRandomNo={generateRandomNo} subCtr={cellDtls.subCtr} dayId={dayId} periodId={periodId} classSectionId={sectionId} /> ||
        cellDtls.subCtr === 4 && <CellForSubFour key={randNo} cellId={cellDtls.cellId} cellDtls={cellDtls} removeSub={removeSub} generateRandomNo={generateRandomNo} subCtr={cellDtls.subCtr} dayId={dayId} periodId={periodId} classSectionId={sectionId} />
    );
}

const CellForSubZero = (props) => {
    const [cellDtls, setCellDtls] = useState([props.cellDtls]);
    const [periodId, setPeriodId] = useState(props.periodId);
    const [dayId, setdayId] = useState(props.dayId);
    const [classSectionId, setclassSectionId] = useState(props.classSectionId);
    const [cellId, setCellId] = useState([props.cellId]);
    const [sessionId, setSessionId] = useState([props.sessionId]);

    /* function handleSaveCellResponse(data) {
        var obj = data;
        if (obj.status === 'Success') {
            toast.success(obj.message);
            props.generateRandomNo();
        } else if (obj.status === 'Failure') {
            if (obj.message === 'You are not logged in') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            }
            else if (obj.message === 'Your Session is expired, login again') {
                setTimeout(function () { window.location.href = ('/') }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error("Service failed " + obj);
        }
    } */

    return <div cell-dtls={props.cellDtls} pos={props.subCtr} session-id={sessionId} class-section-id={classSectionId} cell-id={cellId} day-id={dayId} period-id={periodId}></div>
}

const CellForSubOne = (props) => {
    const [cellDtls, setCellDtls] = useState([props.cellDtls]);
    const [periodId, setPeriodId] = useState(props.periodId);
    const [dayId, setdayId] = useState(props.dayId);
    const [classSectionId, setclassSectionId] = useState(props.classSectionId);
    const [cellId, setCellId] = useState([props.cellId]);
    const [sessionId, setSessionId] = useState([props.sessionId]);

    useEffect(() => {
        // // console.log(props)
    }, [])

    return <div cell-id={props.cellId} pos={props.subCtr} style={{ height: '100%' }} session-id={sessionId} section-id={classSectionId} day-id={dayId} period-id={periodId}>
        <div style={{ height: '50%', position: 'relative' }} className="bottomOutline">
            <span className="sub1"> {props.cellDtls.sub1}</span>
            <button onClick={() => props.removeSub(props.cellDtls.cellId, 1)} className="glyphicon glyphicon-remove" style={{ textAlign: 'right', margin: '0px', padding: '0px', height: '20px', width: '20px', right: '0px', top: '0px', position: 'absolute' }}></button>
        </div>

        {/* <div onClick={() => props.removeSub(props.cellDtls.cellId, 1)}
            className={parseInt(props.selPeriodId) === props.cellDtls.periodId && parseInt(props.selCellId) === props.cellId ? "spanRemove" : "spanRemove hide"} style={{ cursor: 'pointer', right: 0, bottom: 0, left: 0, display: 'table-cell' }}></div>
        <span className="glyphicon glyphicon-remove cross-icon"></span> */}
        {/* <button className="glyphicon glyphicon-remove" style={{ textAlign: 'right', margin: 0, padding: 0, height: 'auto', right: 0, top: 0, position: 'absolute' }} ></button> */}
    </div>
}

const CellForSubTwo = (props) => {
    const [cellDtls, setCellDtls] = useState([props.cellDtls]);
    const [periodId, setPeriodId] = useState(props.periodId);
    const [dayId, setdayId] = useState(props.dayId);
    const [classSectionId, setclassSectionId] = useState(props.classSectionId);
    const [cellId, setCellId] = useState([props.cellId]);
    const [sessionId, setSessionId] = useState([props.sessionId]);

    return <>


        <div pos={props.subCtr} session-id={sessionId} section-id={classSectionId} cell-id={cellId} day-id={dayId} period-id={periodId} style={{ height: '100%' }}>
            <div pos={props.subCtr} style={{ height: '50%', position: 'relative' }} className="bottomOutline">
                <span className="sub1"> {props.cellDtls.sub1}</span>
                <button onClick={() => props.removeSub(props.cellDtls.cellId, 1)} className="glyphicon glyphicon-remove" style={{ textAlign: 'right', margin: '0px', padding: '0px', height: '20px', width: '20px', right: '0px', top: '0px', position: 'absolute' }}></button>
            </div>
            <div session-id={sessionId} section-id={classSectionId} day-id={dayId} period-id={periodId} style={{ height: '50%', position: 'relative' }}>
                <span className="sub2"> {props.cellDtls.sub2}</span>
                <button onClick={() => props.removeSub(props.cellDtls.cellId, 2)} className="glyphicon glyphicon-remove" style={{ textAlign: 'right', margin: '0px', padding: '0px', height: '20px', width: '20px', right: '0px', top: '0px', position: 'absolute' }}></button>
            </div>

        </div>
        {/* <span>{props.cellDtls.sub1}</span>
            <span onClick={() => props.removeSub(props.cellDtls.cellId, 1)}
                className="glyphicon glyphicon-trash"
                style={{ cursor: 'pointer', margin: '0 10px', display: 'inline-block', alignItems: 'end' }}>
            </span>
        </div>
        <div session-id={sessionId} section-id={classSectionId} cell-id={cellId} day-id={dayId} period-id={periodId}><span>{props.cellDtls.sub2}</span>
            <span onClick={() => props.removeSub(props.cellDtls.cellId, 2)}
                className="glyphicon glyphicon-remove"
                style={{ cursor: 'pointer', margin: '0 10px', display: 'inline-block', alignItems: 'end' }}>
            </span>*/}

    </>
}
const CellForSubThree = (props) => {
    const [cellDtls, setCellDtls] = useState([props.cellDtls]);
    const [periodId, setPeriodId] = useState(props.periodId);
    const [dayId, setdayId] = useState(props.dayId);
    const [classSectionId, setclassSectionId] = useState(props.classSectionId);
    const [cellId, setCellId] = useState([props.cellId]);
    const [sessionId, setSessionId] = useState([props.sessionId]);

    return <>
        <div pos={props.subCtr} session-id={sessionId} section-id={classSectionId} cell-id={cellId} day-id={dayId} period-id={periodId} style={{ height: '100%' }}>
            <div pos={props.subCtr} style={{ height: '50%', position: 'relative' }} className="bottomOutline">
                <div style={{ float: 'left', height: '100%', width: '50%', borderRight: '1px solid #e4e4e4' }}>
                    <div className="sub1"> {props.cellDtls.sub1}</div>
                    <button onClick={() => props.removeSub(props.cellDtls.cellId, 1)} className="glyphicon glyphicon-remove btnsub1" ></button>
                </div>
                <div style={{ float: 'left', height: '100%', width: '50%' }}>
                    <div className="sub3"> {props.cellDtls.sub3}</div>
                    <button onClick={() => props.removeSub(props.cellDtls.cellId, 3)} className="glyphicon glyphicon-remove btnsub3" style={{ textAlign: 'right', margin: '0px', padding: '0px', height: '20px', width: '20px', right: '0px', top: '0px', position: 'absolute' }}></button>
                </div>
            </div>
            <div session-id={sessionId} section-id={classSectionId} day-id={dayId} period-id={periodId} style={{ height: '50%', position: 'relative' }}>
                <div style={{ float: 'left', height: '100%', width: '50%', borderRight: '1px solid #e4e4e4' }}>
                    <div className="sub2"> {props.cellDtls.sub2}</div>
                    <button onClick={() => props.removeSub(props.cellDtls.cellId, 2)} className="glyphicon glyphicon-remove btnsub2" ></button>
                </div>
            </div>

            {/* <span>{props.cellDtls.sub1}</span>
            <span onClick={() => props.removeSub(props.cellDtls.cellId, 1)}
                className="glyphicon glyphicon-trash"
                style={{ cursor: 'pointer', margin: '0 10px', display: 'inline-block', alignItems: 'end' }}>
            </span>
        </div>
        <div session-id={sessionId} section-id={classSectionId} cell-id={cellId} day-id={dayId} period-id={periodId}><span>{props.cellDtls.sub2}</span>
            <span onClick={() => props.removeSub(props.cellDtls.cellId, 2)}
                className="glyphicon glyphicon-remove"
                style={{ cursor: 'pointer', margin: '0 10px', display: 'inline-block', alignItems: 'end' }}>
            </span>*/}
        </div>
    </>
}
const CellForSubFour = (props) => {
    const [cellDtls, setCellDtls] = useState([props.cellDtls]);
    const [periodId, setPeriodId] = useState(props.periodId);
    const [dayId, setdayId] = useState(props.dayId);
    const [classSectionId, setclassSectionId] = useState(props.classSectionId);
    const [cellId, setCellId] = useState([props.cellId]);
    const [sessionId, setSessionId] = useState([props.sessionId]);


    return <>
        <div pos={props.subCtr} session-id={sessionId} section-id={classSectionId} cell-id={cellId} day-id={dayId} period-id={periodId} style={{ height: '100%' }}>
            <div pos={props.subCtr} style={{ height: '50%', position: 'relative' }} className="bottomOutline">
                <div style={{ float: 'left', height: '100%', width: '50%', borderRight: '1px solid #e4e4e4' }} >
                    <div className="sub1"> {props.cellDtls.sub1}</div>
                    <button
                        onClick={() => props.removeSub(props.cellDtls.cellId, 1)}
                        className="glyphicon glyphicon-remove btnsub1"
                        style={{ display: 'block', textAlign: 'right', margin: '0px', padding: '0px', height: '20px', width: '20px', right: '0px', top: '0px' }}></button>
                </div>
                <div style={{ float: 'left', height: '100%', width: '50%' }} >
                    <div className="sub3"> {props.cellDtls.sub3}</div>
                    <button
                        onClick={() => props.removeSub(props.cellDtls.cellId, 3)}
                        className="glyphicon glyphicon-remove btnsub3"
                        style={{ display: 'block', textAlign: 'right', margin: '0px', padding: '0px', height: '20px', width: '20px', right: '0px', top: '0px' }}></button>
                </div>
            </div>
            <div session-id={sessionId} section-id={classSectionId} day-id={dayId} period-id={periodId} style={{ height: '50%', position: 'relative' }}>
                <div style={{ float: 'left', height: '100%', width: '50%', borderRight: '1px solid #e4e4e4' }} >
                    <div className="sub2"> {props.cellDtls.sub2}</div>
                    <button
                        onClick={() => props.removeSub(props.cellDtls.cellId, 2)}
                        className="glyphicon glyphicon-remove btnsub2"
                        style={{ display: 'block', textAlign: 'right', margin: '0px', padding: '0px', height: '20px', width: '20px', right: '0px', top: '0px' }}></button>
                </div>
                <div style={{ float: 'left', height: '100%', width: '50%' }} >
                    <div className="sub4"> {props.cellDtls.sub4}</div>
                    <button
                        onClick={() => props.removeSub(props.cellDtls.cellId, 4)}
                        className="glyphicon glyphicon-remove btnsub4"
                        style={{ display: 'block', textAlign: 'right', margin: '0px', padding: '0px', height: '20px', width: '20px', right: '0px', top: '0px' }}></button>
                </div>
            </div>
        </div>
    </>
}


export default CellForSub;
