import axios from 'axios'
import lstModules from 'common/json/lstModules.json'

var httpURL = lstModules['recruitment'].url + ':' + lstModules['recruitment'].port;
var httpurl = lstModules['main'].url + ':' + lstModules['main'].port;
var tokenid = localStorage.getItem('tokenid');

function getAddressDtlsSvc(tchId, cbAddressDtlsResponse) {
    axios(
        {
            url: httpURL + "/sms/recruitment/tch/address/dtls",
            method: "POST",
            withCredentials: true,
            params: { tchId : tchId },
            headers: { 'tokenid': tokenid }

        })
        .then((response) => {
            var result = {}
            result = response.data
            cbAddressDtlsResponse(result)

        })
        .catch((error) => {
            // // console.log("Address Dtls is not getting called")
        });
}

function getLstStateSvc(cbLstStateResponse) {
    axios(
        {
            url: httpurl + "/sms/mst/state/list",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = {}
            data = Response.data.lstState
            cbLstStateResponse(data)
            // // console.log(Response.data.SvcStatus)
        }
    ).catch((error) => {
        // // console.log("State List Fetch error")
    });
}

function getLstCitySvc(cbLstCityResponse, stateId) {
    axios(
        {
            url: httpurl + "/sms/mst/city/list",
            method: 'POST',
            params: { stateId: stateId },
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = {}
            data = Response.data.lstCity
            cbLstCityResponse(data)
            // // console.log(Response.data.SvcStatus)
        }
    ).catch((error) => {
        // // console.log("City List Fetch error")
    });
}

function saveAddressSvc(cbSaveAddressResponse, address) {
    axios(
        {
            url: httpURL + "/sms/recruitment/tch/address/save",
            method: "POST",
            withCredentials: true,
            params: { address : address },
            headers: { 'tokenid': tokenid }

        })
        .then((Response) => {
            var result = {}
            result.status = Response.data.SvcStatus
            result.message = Response.data.SvcMsg
            cbSaveAddressResponse(result)
        })
        .catch((error) => {
            console.error(error)
        });
}

export { getAddressDtlsSvc, getLstStateSvc, getLstCitySvc, saveAddressSvc }