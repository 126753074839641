import React, { useState, useEffect } from 'react'

const SyllabusSlider = (props) => {
    const [data, setData] = useState(props.data)
    const [classLabel, setClassLabel] = useState(props.data.classLabel)
    const [section, setSection] = useState(props.data.section)
    const [syllabusId, setSyllabusId] = useState(props.syllabusId)
    const [lstSyb, setLstSyb] = useState(props.lstSyb)
    const [lstSub, setLstSub] = useState(props.lstSub)

    useEffect(() => {
        // // console.log(props.data)
        setData(props.data)
        setLstSub(props.lstSub)
        setClassLabel(props.data.classLabel)
        setSection(props.data.section)
        setLstSyb(props.lstSyb)
        setSyllabusId(props.syllabusId)
        // // console.log("Subject list::"+JSON.stringify(props.lstSub))
    }, [props.syllabusId, props.data, props.lstSub])

    function handleChange(e) {
        setData({ ...data, [e.target.name]: e.target.value, });
        //props.subjectCall(e.target.value);
    }

    function handleSyllabusChange(e) {
        // setData({ ...data, [e.target.name]: e.target.value });
        setSyllabusId(e.target.value);
        // // console.log(data);
        props.subjectCall(e.target.value);
    }

    function saveSyllabus() {
        // // console.log(data);
        props.saveSyllabus(data, syllabusId)
    }

    return (
        <>
            <form id="addForm" className="form-horizontal formWrapper clearfix">

                <div className="form-group">
                    <label className="control-label col-sm-4" >Class<span style={{ color: 'red', marginLeft: 1 }}>*</span> </label>
                    <div className="col-sm-3 padding-remove">
                        <   input autoComplete="off" name="classLabel" id="classId"
                            type="text" className="form-control " readOnly
                            defaultValue={classLabel}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label className="control-label col-sm-4" >Section<span style={{ color: 'red', marginLeft: 1 }}>*</span> </label>
                    <div className="col-sm-3 padding-remove">
                        <   input autoComplete="off" name="section" id="sectionId"
                            type="text" className="form-control " readOnly
                            defaultValue={section}
                        />
                    </div>
                </div>

                <div className="form-group">
                    <label className="control-label col-sm-4" >Syllabus<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                    <div className="col-sm-3 padding-remove">
                        <select autoComplete="off" name="syllabusId" id="syllabusId"
                            type="text" className="form-control "
                            defaultValue={syllabusId}
                            onChange={handleSyllabusChange}
                        >
                            <option value="0" >Syllabus</option>
                            {lstSyb.map((obj) => (
                                <option key={obj.syllabusId} value={obj.syllabusId} >{obj.syllabus}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="form-group" >
                    <label className="control-label col-sm-4">Subject</label>
                    <div className="col-md-4 padding-remove" >
                        <div className="" style={{ height: 'auto' }}>
                            <table id="periodTypeTable" >
                                <tbody id="periodTypeTableBody">
                                    {props.lstSub && props.lstSub.length !== 0 && props.lstSub.map((obj) => (
                                        <tr key={obj.subId} id={obj.subId} data={JSON.stringify(obj)}>
                                            <td hidden>{obj.subId}</td>
                                            <td style={{ width: 10 + '%' }} className="text-center">{obj.sub}</td>
                                            <td style={{ width: 10 + '%' }} className="text-center">{obj.subType}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-sm-3 padding-remove paymentButtons">
                    <div className="form-group" >
                        <div className="ab1" style={{ paddingLeft: '470px' }}>
                            <button type="button" style={{ marginTop: 20 }}
                                title="Save"
                                onClick={saveSyllabus}
                                className="btn btn-info">Save</button>
                        </div>
                        <button type="button" className="btn btn-info text-center " id="closeSlider" onClick={props.removeClassFromBody}>
                            <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                        </button>
                    </div>

                </div>
            </form >
        </>
    )
}
export default SyllabusSlider;