import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import Loader from "common/components/LoaderSlider";

import "../css/schoolProfile.css";
import { getLstCity, getLstLocality, saveFeebookSvc, getFeeBookDtlsSvc } from "./schoolProfileSvc";


const SchoolFeeBook = (props) => {
    const { schoolId, lstState } = props;
    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [lstBankCity, setLstBankCity] = useState([]);
    const [lstBankLocality, setLstBankLocality] = useState([]);
    const [bankCityId, setBankCityId] = useState(0);
    const [bankLocalityId, setBankLocalityId] = useState(0);
    const [bankStateId, setBankStateId] = useState(0);
    const [bankAddress1, setBankAddress1] = useState("");
    const [bankPin, setBankPin] = useState("");
    const [bank, setBank] = useState("");
    const [accountNo, setAccountNo] = useState("");
    const [reAccountNo, setReAccountNo] = useState("");
    const [accountName, setAccountName] = useState("");
    const [corpCode, setCorpCode] = useState("");
    const [contactNo, setContactNo] = useState("");
    const [contactPerson, setContactPerson] = useState("");

    useEffect(() => {
        // // console.log("SchoolFeeBook component called");
        getFeeBookDtls();
    }, []);

    const getFeeBookDtls = () => {

        setLoader("block");
        setOpacity(0.5);

        getFeeBookDtlsSvc(handleFeebookResponse);
    }

    const handleFeebookResponse = (obj) => {
        setLoader("none");
        setOpacity(1);
        if (obj.status === "Success") {
            setBank(obj.feebookObj.bankName);
            setBankAddress1(obj.feebookObj.bankAddress.address1);
            setBankPin(obj.feebookObj.bankAddress.pin);
            var pStateId = obj.feebookObj.bankAddress.stateId;
            var pCityId = obj.feebookObj.bankAddress.cityId;
            var pLocalityId = obj.feebookObj.bankAddress.localityId;
            setBankStateId(pStateId);
            getLstCity(pStateId, handleLstBankCityResponse, pCityId);
            getLstLocality(pCityId, handleBankLocalityResponse, pLocalityId);
            setAccountName(obj.feebookObj.actName);
            setAccountNo(obj.feebookObj.actNo);
            setCorpCode(obj.feebookObj.corpCode);
            setContactPerson(obj.feebookObj.contactPerson);
            setContactNo(obj.feebookObj.contactNo);
        } else if (obj.status === "Failure") {
            if (obj.message === "You are not logged in") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else if (obj.message === "Your Session is expired, login again") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error("Service failed" + obj);
        }
    };


    const changeBankState = (e) => {
        var pStateId = e.target.value;
        setBankStateId(pStateId);
        setLoader("block");
        setOpacity(0.5);
        getLstCity(pStateId, handleLstBankCityResponse, 0);
    };
    const changeBankLocality = (e) => {
        setBankLocalityId(e.target.value);
    };
    const changeBankCity = (e) => {
        var pCityId = e.target.value;
        setBankCityId(pCityId);
        setLoader("block");
        setOpacity(0.5);
        getLstLocality(pCityId, handleBankLocalityResponse, 0);
    };
    async function handleLstBankCityResponse(data, pCityId) {
        var obj = data;
        setLoader("none");
        setOpacity(1);
        if (obj.status === "Success") {
            await setLstBankCity(obj.lstCity);
            setBankCityId(pCityId);
        } else if (obj.status === "Failure") {
            if (obj.message === "You are not logged in") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else if (obj.message === "Your Session is expired, login again") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error("Service failed" + obj);
        }
    } function handleBankLocalityResponse(data, pLocalityId) {
        var obj = data;
        if (data.status === "Success") {
            setLstBankLocality(obj.lstLocality);
            setBankLocalityId(pLocalityId);
        } else if (obj.status === "Failure") {
            if (obj.message === "You are not logged in") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else if (obj.message === "Your Session is expired, login again") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error("Service failed" + obj);
        }
    }
    function saveFeebookDtls(e) {
        e.preventDefault();
        if (reAccountNo !== accountNo) {
            toast.error("Account No should match");
            return;
        }
        var bankAddress = {
            cityId: bankCityId,
            stateId: bankStateId,
            pin: bankPin,
            localityId: bankLocalityId,
            address1: bankAddress1,
        };
        var feebookObj = {
            bankAddress: bankAddress,
            bankName: bank,
            actName: accountName,
            actNo: accountNo,
            corpCode: corpCode,
            contactPerson: contactPerson,
            contactNo: contactNo,
        };
        var formData = new FormData();
        formData.append("feebookObj", JSON.stringify(feebookObj));

        setLoader("block");
        setOpacity(0.5);
        saveFeebookSvc(formData, handleSaveFeebookResponse);
    }

    function handleSaveFeebookResponse(obj) {
        setLoader("none");
        setOpacity(1);
        if (obj.status === "Success") {
            toast.success(obj.message);
        } else if (obj.status === "Failure") {
            if (obj.message === "You are not logged in") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else if (obj.message === "Your Session is expired, login again") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error("Service failed" + obj);
        }
    }


    return (
        <div className="col-xl-12 col-ls-12 col-md-12 col-sm-12 col-xs-12 padding-remove"
            style={{
                cursor: (loader === "none") ? 'pointer' : 'none',
                pointerEvents: (loader === "none") ? 'auto' : 'none',
                opacity: opacity
            }}>
            <Loader loader={loader} position="fixed" top="20%" left="45%" />
            <form
                className="form form-vertical col-sm-12"
                style={{ margin: "2%", border: "1px solid #ddd", borderRadius: 6 }}
            >
                <div
                    className="EnquiryHead col-centered"
                    style={{
                        fontWeight: "bold",
                    }}
                >
                    <h4>Fee Book Details</h4>
                </div>
                <div className="row">
                    <label className="control-label col-sm-3" htmlFor="name">
                        Bank Name
                    </label>
                    <div className="form-group col-sm-9">
                        <input
                            className="form-control"
                            name="school"
                            defaultValue={bank}
                            onChange={(e) => setBank(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <label className="control-label col-sm-3" htmlFor="state">
                        State
                    </label>
                    <div className="form-group col-sm-3">
                        <select
                            value={bankStateId}
                            onChange={(e) => changeBankState(e)}
                            className="form-control"
                            style={{ width: "100%" }}
                            id="stateCmb"
                            name="stateId"
                        >
                            <option>State</option>

                            {lstState &&
                                lstState.map(function (obj, i) {
                                    return (
                                        <option key={i} value={obj.stateId}>
                                            {obj.state}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <label className="control-label col-sm-3" htmlFor="city">
                        City
                    </label>
                    <div className="form-group col-sm-3">
                        <select
                            value={bankCityId}
                            onChange={(e) => changeBankCity(e)}
                            className="form-control"
                            style={{ width: "100%" }}
                            id="cityCmb1"
                            name="cityId"
                        >
                            <option value="0">City</option>
                            {lstBankCity &&
                                lstBankCity.map(function (obj, i) {
                                    return (
                                        <option key={i} value={obj.cityId}>
                                            {obj.city}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <label className="control-label col-sm-3" htmlFor="locality">
                        Locality
                    </label>
                    <div className="form-group col-sm-4">
                        <select
                            value={bankLocalityId}
                            onChange={(e) => changeBankLocality(e)}
                            className="form-control"
                            style={{ width: "100%" }}
                            id="localityCmb"
                            name="localityId"
                        >
                            <option value="0">Locality</option>
                            {lstBankLocality &&
                                lstBankLocality.map(function (obj, i) {
                                    return (
                                        <option key={i} value={obj.localityId}>
                                            {obj.locality}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <label className="control-label col-sm-3" htmlFor="pin">
                        Pin
                    </label>
                    <div className="form-group col-sm-2">
                        <input
                            className="form-control"
                            name="pin"
                            defaultValue={bankPin}
                            onChange={(e) => setBankPin(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <label className="control-label col-sm-3" htmlFor="address1">
                        Address
                    </label>
                    <div className="form-group col-sm-9">
                        <input
                            className="form-control"
                            name="address1"
                            defaultValue={bankAddress1}
                            onChange={(e) => setBankAddress1(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <label className="control-label col-sm-3" htmlFor="acctNo">
                        Account No.
                    </label>
                    <div className="form-group col-sm-3">
                        <input
                            className="form-control"
                            name="acctNo"
                            defaultValue={accountNo}
                            onChange={(e) => setAccountNo(e.target.value)}
                        />
                    </div>
                    <label
                        className="control-label col-sm-3 padding"
                        htmlFor="reAcctNo"
                    >
                        Re-Enter Account No.
                    </label>
                    <div className="form-group col-sm-3">
                        <input
                            type="password"
                            onPaste={(e) => e.preventDefault()}
                            onDrop={(e) => e.preventDefault()}
                            autoComplete="off"
                            className="form-control"
                            name="reAcctNo"
                            defaultValue={reAccountNo}
                            onChange={(e) => setReAccountNo(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <label className="control-label col-sm-3" htmlFor="accountName">
                        Account Name
                    </label>
                    <div className="form-group col-sm-4 ">
                        <input
                            className="form-control"
                            name="accountName"
                            defaultValue={accountName}
                            onChange={(e) => setAccountName(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <label className="control-label col-sm-3" htmlFor="corpCode">
                        Corporate Code
                    </label>
                    <div className="form-group col-sm-4">
                        <input
                            className="form-control"
                            name="corpCode"
                            defaultValue={corpCode}
                            onChange={(e) => setCorpCode(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <label className="control-label col-sm-3" htmlFor="contactPerson">
                        Contact Person
                    </label>
                    <div className="form-group col-sm-4">
                        <input
                            className="form-control"
                            name="contactPerson"
                            defaultValue={contactPerson}
                            onChange={(e) => setContactPerson(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <label className="control-label col-sm-3" htmlFor="contactNo">
                        Contact No.
                    </label>
                    <div className="form-group col-sm-3">
                        <input
                            className="form-control"
                            name="contactNo"
                            defaultValue={contactNo}
                            onChange={(e) => setContactNo(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-sm-11">
                        <button
                            className="pull-right btn btn-info"
                            title="Save"
                            onClick={(e) => saveFeebookDtls(e)}
                        >
                            {" "}
                            Save
                        </button>
                    </div>
                </div>
            </form>

        </div>
    );
};

export default SchoolFeeBook;
