
import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { useEffect, useState } from "react";
import "../../css/library.css";
import BookDetails from './bookDetails';


const PrintSlider = (props) => {
    const { closeSlider, schoolName, lstSelect } = props;

    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);

    // useEffect(() => {
    // // console.log("lstSelect",lstSelect);

    // },[]);

    return (

        <div className="col-xs-12 col-centered " style={{ height: '', width: '98%', margin: '5px auto', paddingTop: '10px', background: '#fff' }}>
            <div>
                <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={closeSlider} style={{ right: "60%" }}>
                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                </button>
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }} >
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div >

            </div>
            <div className="col-xs-12 col-centered ">
                <PDFViewer style={{ height: "98vh", width: "100%" }}>
                        <BookDetails
                            schoolName={schoolName}
                            lstSelect={lstSelect}
                        />
                </PDFViewer>
            </div>
        </div >

    )

}

export default PrintSlider;