import React, { useEffect, useState } from "react";
import 'common/css/master.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../css/studentTest.css';
import { studentDtlsSvc } from "./testSvc";


const TestHeader = (props) => {

    const { testId, studentOtp, schoolId, setTestId, setStudentId, studentName, setStudentName, setLoader, setOpacity, flgOtp, setFlgOtp, setLeaderboardCount } = props;

    const [studentTestId, setStudentTestId] = useState("");
    const [otp, setOtp] = useState("");
    const [testSchoolId, setTestSchoolId] = useState(0);
    const avtarInstruction = "Select an avatar from the characters below and chose a cryptic name for yourself. Other participants will be able to see your avatar and nickname for the duration of the test.";

    useEffect(() => {
        // // console.log("TestHeader called");
        // // console.log("testId", testId);
        // // console.log("studentOtp", studentOtp);
        // // console.log("schoolId", schoolId);
        if (!studentTestId) {
            setStudentTestId(testId);
        }
        if (!otp) {
            setOtp(studentOtp);
        }
        if (!testSchoolId) {
            setTestSchoolId(schoolId);
        }
    }, [testId, studentOtp, schoolId]);

    const testOtpSubmit = () => {
        // // console.log("testOtpSubmit called")
        // // console.log("scschoolId:",schoolId, ",testSchoolId:",testSchoolId);
        // // console.log("otp:", otp, ", testId:", testId);
        if (testId === "" && otp === "") {
            toast.warn("please enter testId and Otp!");
            return;
        } else if (testId === "") {
            toast.warn("please enter testId!");
            return;
        } else if (otp === "") {
            toast.warn("please enter otp!");
            return;
        }

        props.setLoader("block");
        props.setOpacity(0.5);
        studentDtlsSvc(testId, otp, testSchoolId, submitOtpResponse);
    }

    const submitOtpResponse = (data) => {
        // // console.log("handleSubmitOtp called");
        let obj = data;
        if (obj.status === 'Success') {
            const studentObj = obj.studentObj;
            // // console.log("obj in submitOtpResponse", obj);
            // // console.log(" obj.schoolId in submitOtpResponse",  obj.schoolId);
            // // console.log(" obj.testId in submitOtpResponse", obj.testId);
            // // console.log("setFlgOtp(false) called");
            if (studentObj.completed == 1) {
                const newPageUrl = "/testLeaderBoard?tId=" + obj.testId + "&sId=" + studentObj.studentId + "&schId=" + obj.schoolId + "&qusNo=" + 0 + "&rankers=" + studentObj.leaderboardCount;
                window.location.href = newPageUrl;
            }
            else if (studentObj.started == 1) {
                const newPageUrl = "/testQuestion?tId=" + obj.testId + "&sId=" + studentObj.studentId + "&schId=" + obj.schoolId + "&rankers=" + studentObj.leaderboardCount;
                window.location.href = newPageUrl;
            } else {
                setStudentId(studentObj.studentId);
                setStudentName(studentObj.student);
                setLeaderboardCount(studentObj.leaderboardCount);
                setFlgOtp(false);
            }
        }
        else {
            toast.error(obj.message);
        }
        setLoader('none');
        setOpacity(1);
    }

    const handleTestIdChange = (tId) => {
        // // console.log("handleTestIdChange called, tId", tId);
        // const tempId = parseInt(tId);
        const tempId = parseInt(tId);
        // // console.log("tempId", tempId);
        setTestId(tempId);
        setStudentTestId(tempId);
    }


    return (
        <div className="weeklyTestHeader">
            <div className="col-sm-12 form-group padding-remove" style={{ marginTop: "3%" }}>
                {
                    flgOtp ?
                        <div className="col-sm-6 padding-remove" >
                            <div className="otp-section"  >
                                <div className="col-sm-3 padding-remove" >
                                    <label className="col-sm-4 text-left padding-remove" style={{ top: "5px" }} >
                                        Test Id
                                    </label>
                                    <div className="form-group col-sm-7 padding-remove">
                                        <input
                                            className="form-control"
                                            style={{
                                                borderColor: "blue",
                                            }}
                                            type="number"
                                            name="Test Id"
                                            placeholder="Test Id"
                                            defaultValue={!!studentTestId ? studentTestId : ""}
                                            onChange={(e) => handleTestIdChange(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-3 padding-remove">
                                    <label className="text-left col-sm-4 padding-remove" style={{ top: "5px" }} >
                                        OTP
                                    </label>
                                    <div className="form-group col-sm-7 padding-remove">
                                        <input
                                            className="form-control"
                                            style={{
                                                borderColor: "blue",
                                            }}
                                            type="number"
                                            name="OTP"
                                            placeholder="OTP"
                                            defaultValue={otp}
                                            onChange={(e) => setOtp(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4 padding-remove form-group "  >
                                    <button type="button" className="btn btn-primary  pull-right" style={{ height: "34x", borderColor: "blue", backgroundColor: "blue", borderRadius: "17px", minWidth: "100px", width: "60%" }} onClick={(e) => testOtpSubmit()} >
                                        Validate
                                    </button>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="welcome-pallet" >
                            <span className="student-name"  >
                                {"Welcome " + studentName}
                            </span>
                        </div>
                }
            </div>
            <div className="col-sm-12 col-xs-12 form-group col-centered padding-remove" style={{ marginTop: "5px" }}>
                {
                    flgOtp ?
                        <div className="col-sm-12  col-xs-12" style={{ height: "90px" }} >
                        </div>
                        :
                        <div className=" col-sm-12 padding-remove">
                            <div className="avatar-instruction">
                                {avtarInstruction}
                            </div>
                        </div>
                }
            </div>
        </div>
    );
}
export default TestHeader;