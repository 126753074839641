
import React, { useEffect, useState } from 'react';
import { Document, Page, PDFViewer, View, Font } from '@react-pdf/renderer';

import {// getICardSvc,
  getLogoSvc, getPrinciSignSvc
} from './progressReportSvc.js'
import { toast } from "react-toastify";
import { styles, pageStyle } from './reportCardStyles.js';
import { HeaderLogoSection, HeaderAddressSection, HeaderNameSection } from './headerSection.js';
import StudentSection from './studentSection.js';
import { ScoreSection } from './scoreSection.js';
import FooterSection from './footerSection.js';

import LstReportCard from "./lstReportCard.json";

Font.register({
  family: 'Calibri'
  , fonts: [{ src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibri.ttf" }
    , { src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibrib.ttf", fontWeight: "bold" }], format: 'truetype'
});

const ReportCardPDF = (props) => {

  const [lstReportCard, setLstReportCard] = useState([]);
  // const [reportCard, setReportCard] = useState(props.lstReportCard[0]);

  // const [lstReportCard, setLstReportCard] = useState(LstReportCard.lstReportCardObj);


  useEffect(() => {
    // // console.log("ReportCardPDF called");
    // // console.log("props.lstReportCard ", props.lstReportCard);
    // // console.log("reportCard ", reportCard);
    setLstReportCard(props.lstReportCard);
  }, [props.lstReportCard]);

  const pages = [];

  for (let i = 0; i < lstReportCard.length; i++) {
    pages.push(
      <Page
        key={lstReportCard[i].studentSection.studentId}
        size="A4" style={pageStyle.page}>
        <View style={pageStyle.page_border}>
          <View style={pageStyle.header_section}>
            <View style={pageStyle.header_logo}>
              <HeaderLogoSection
                logoUrl={props.logoUrl}
                headerSection={lstReportCard[i].headerSection}
              />
            </View>
            <View style={pageStyle.header_address}>
              <HeaderAddressSection
                headerSection={lstReportCard[i].headerSection}
              />
            </View>
            <View style={pageStyle.header_name}>
              <HeaderNameSection
                headerName={lstReportCard[i].headerName}
              />
            </View>
          </View>
          <View style={pageStyle.student_section}>
            <StudentSection
              studentSection={lstReportCard[i].studentSection}
            />
          </View>
          <View style={pageStyle.score_section}>
            <ScoreSection
              scoreSection={lstReportCard[i].scoreSection}
            />
          </View>
          <View style={pageStyle.footer_section}>
            <FooterSection
              teacherSignUrl={props.teacherSignUrl}
              imageUrl={props.imageUrl}
              flgValidated={props.flgValidated}
            />
          </View>
        </View>
      </Page>
    );
  }


  return (
    <div className='col-sm-12 padding-remove' >
      <PDFViewer style={{ width: "100%", height: "600px", overflowY: "scroll" }}>
        <Document>{pages}</Document>
      </PDFViewer>
    </div>
  );
}

export default ReportCardPDF;

