import React, { useEffect, useState } from 'react';
import { Text, View, Font } from '@react-pdf/renderer';

import { styleSchoScore } from './termReportPDFStyle';

Font.register({
    family: 'Calibri'
    , fonts: [{ src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibri.ttf" }
        , { src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibrib.ttf", fontWeight: "bold" }], format: 'truetype'
});


const ScholasticSection = (props) => {
    const { lstScholastic, lstExam, term } = props;

    const [totalSchoScore, setTotalSchoScore] = useState(0);


    useEffect(() => {
        // // console.log("scoreSection.js called, lstSchoScore", lstScholastic);
        // // console.log("lstExam", lstExam);
        // // console.log("term", term);
        let tempTotal = 0;
        for (let i = 0; i < lstExam.length; i++) {
            tempTotal += lstExam[i].maxScore;
        }
        setTotalSchoScore(tempTotal);

    }, [lstScholastic, lstExam, term]);

    return (
        <View style={styleSchoScore.container}>
            <View style={styleSchoScore.score_table}>
                <View style={[styleSchoScore.score_head, { height: "20px" }]}>
                    <View style={[styleSchoScore.exam_row, { flex: 1 }]}>
                        <View style={[styleSchoScore.subject_col, { flex: 3 }]}>
                            <View style={[styleSchoScore.top_left_cell, { flex: 1, backgroundColor: '#f6f6f6' }]}>
                            </View>
                        </View>
                        <View style={[styleSchoScore.top_cell, { flex: 7, backgroundColor: '#f6f6f6' }]}>
                            <Text style={[styleSchoScore.text_schoExam_label, styleSchoScore.text_center]}>
                                {term}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={[styleSchoScore.score_head, { height: "30px" }]}>
                    <View style={[styleSchoScore.exam_row, { flex: 1 }]}>
                        <View style={[styleSchoScore.subject_col, { flex: 3 }]}>
                            <View style={[styleSchoScore.left_cell, { flex: 1, backgroundColor: '#f6f6f6' }]}>
                                <Text style={[styleSchoScore.text_schoExam_label, styleSchoScore.text_center]}>
                                    SUBJECTS
                                </Text>
                            </View>
                        </View>
                        <View style={[styleSchoScore.mark_col, { flex: 7 }]}>
                            {lstExam && lstExam.length ?
                                lstExam.map((exam, idx) => (
                                    <View key={exam.examId} style={[styleSchoScore.inner_cell, { flex: 1, backgroundColor: '#f6f6f6' }]}>
                                        <View style={{ flex: 1, justifyContent: "center" }}>
                                            <Text style={[styleSchoScore.text_schoExam_label, styleSchoScore.text_center]}>
                                                {exam.exam}
                                            </Text>
                                        </View>
                                        <View style={{ flex: 1, justifyContent: "center" }}>
                                            <Text style={[styleSchoScore.text_schoExam_maxMarks, styleSchoScore.text_center]}>
                                                {exam.maxScore}
                                            </Text>
                                        </View>
                                    </View>
                                ))
                                : null
                            }
                            <View style={[styleSchoScore.inner_cell, { flex: 1, backgroundColor: '#f6f6f6' }]}>
                                <View style={{ flex: 1, justifyContent: "center" }}>
                                    <Text style={[styleSchoScore.text_schoExam_label, styleSchoScore.text_center]}>
                                        Total
                                    </Text>
                                </View>
                                <View style={{ flex: 1, justifyContent: "center" }}>
                                    <Text style={[styleSchoScore.text_schoExam_maxMarks, styleSchoScore.text_center]}>
                                        {totalSchoScore}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={[styleSchoScore.score_rows, {}]}>
                    {lstScholastic.map((subject, idx) => (
                        <View key={subject.subId} style={[styleSchoScore.score_row, { height: "20px" }]}>
                            <View style={[styleSchoScore.subject_col, { flex: 3 }]}>
                                <View style={[styleSchoScore.left_cell, { flex: 1 }]}>
                                    <Text style={styleSchoScore.text_subject_label}>
                                        {subject.sub}<Text style={{ textTransform: "capitalize" }}></Text>
                                    </Text>
                                </View>
                            </View>
                            <View style={[styleSchoScore.mark_col, { flex: 7 }]}>
                                {
                                    !!subject.lstScore && subject.lstScore.length ?
                                        subject.lstScore.map((score, idx) => (
                                            <View key={idx} style={[styleSchoScore.inner_cell, { flex: 1 }]}>
                                                <Text style={[styleSchoScore.text_center, styleSchoScore.text_value]}>{score}</Text>
                                            </View>
                                        ))
                                        : null
                                }
                            </View>
                        </View>
                    ))}
                </View >
            </View >
        </View >
    );
}

export default ScholasticSection;
