import React, { useEffect, useState } from 'react';
import Header from 'common/js/header/header';
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css';

import ExamHeader from './examHeader';
import ClassesTable from './classesTable';


const ClassExamSyllabus = () => {

    const [loader, setLoader] = useState(0);
    const [opacity, setOpacity] = useState(1);
    const [examId, setExamId] = useState(0);
    const [examLength, setExamLength] = useState(0);


    useEffect(() => {
        // // console.log("ClassExamSyllabus service called");
    }, []);

    function getColSm(n) {
        if (n >= 1 && n <= 4) {
            return 'col-sm-6';
        } else if (n >= 5 && n <= 6) {
            return 'col-sm-8';
        } else if (n >= 7 && n <= 8) {
            return 'col-sm-9';
        } else if (n >= 9 && n <= 10) {
            return 'col-sm-10';
        } else if (n > 12) {
            return 'col-sm-12';
        } else {
            return 'col-sm-8';
        }
    }


    return (
        <div style={{ height: '100%' }}>
            <Header />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader === 0 ? "none" : "block" }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className={getColSm(examLength) + " exam-syllabus-main col-centered"} style={{ pointerEvents: loader === 0 ? "auto" : "none", marginTop: "10px" }}>
                <div className="col-sm-11 padding-remove col-centered">
                    <div className="col-sm-12 wrapper-box padding-remove" style={{
                        boxShadow: 'rgba(64, 60, 67, 0.16) 0px 2px 5px 1px', marginBottom: 10, marginTop: "10px"
                    }}>
                        <ExamHeader
                            examId={examId}
                            setExamId={setExamId}
                            setLoader={setLoader}
                            setOpacity={setOpacity}
                            setExamLength={setExamLength}
                        />
                    </div>
                </div>
                <div className="col-sm-11 padding-remove col-centered">
                    <div className="col-sm-12 padding-remove col-centered">
                        <ClassesTable
                            examId={examId}
                            setLoader={setLoader}
                            setOpacity={setOpacity}
                        />
                    </div>
                </div>
            </div>
        </div>

    );

}

export default ClassExamSyllabus;