import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "common/css/master.css";
import "../css/transport.css";
import DropDown from "common/components/DropDown";
import {
  getCityListSvc,
  getStateListSvc,
  getCityListSvcAndSetCity,
  getLocalityListSvc,
  saveStopSvc,
  getLstStopRangeSvc,
} from "./routeSvc";

const RouteStopSlider = (props) => {
  const [loader, setLoader] = useState("none");
  const [flgAdd, setFlgAdd] = useState(true);
  const [stopId, setStopId] = useState(props.stopObj.stopId);
  const [routeId, setRouteId] = useState(props.routeId);
  const [stopNo, setStopNo] = useState(props.stopObj.stopNo);
  const [stopDesc, setStopDesc] = useState(props.stopObj.stopDesc);
  const [stateId, setStateId] = useState(props.stopObj.stateId);
  const [cityId, setCityId] = useState(props.stopObj.cityId);
  const [localityId, setLocalityId] = useState(props.stopObj.localityId);
  const [address, setAddress] = useState(props.stopObj.address);
  const [tmToNext, setTmToNext] = useState(props.stopObj.tmToNext);
  const [tmFromPrev, setTmFromPrev] = useState(props.stopObj.tmFromPrev);
  const [arrivalTm, setArrivalTm] = useState(props.stopObj.arrivalTm);
  // const [latLong, setLatLong] = useState(props.stopObj.latLong)
  const [stopRange, setStopRange] = useState(props.stopObj.stopRange);
  const [stopRangeId, setStopRangeId] = useState(props.stopObj.stopRangeId);
  const [lstStopRange, setLstStopRange] = useState([]);
  const [lstState, setLstState] = useState([]);
  const [lstCity, setLstCity] = useState([]);
  const [lstLocality, setLstLocality] = useState([]);
  const [flgMorEve, setFlgMorEve] = useState(false);
  const [flgDirection, setFlgDirection] = useState(10);
  const [lstDirection, setLstDirection] = useState([
    { directionId: 10, direction: "Both" },
    { directionId: 20, direction: "Morning" },
  ]);
  useEffect(() => {
    // // console.log(props.stopObj);
    getLstStopRangeSvc(cbGetLstStopRange);
    getStateListSvc(
      cbStateListResponse,
      props.stopObj.stateId,
      props.stopObj.cityId,
      props.stopObj.localityId
    );
    setStopId(props.stopObj.stopId);
    setFlgAdd(stopId === 0);
    setStopNo(props.stopObj.stopNo);
    setStopDesc(props.stopObj.stopDesc);
    setRouteId(props.routeId);
    // setStateId(props.stopObj.stateId)
    setTmToNext(props.stopObj.tmToNext);
    setTmFromPrev(props.stopObj.tmFromPrev);
    setArrivalTm(props.stopObj.arrivalTm);
    // setLocalityId(props.stopObj.localityId)
    setAddress(props.stopObj.address);
    // setLatLong(props.stopObj.latLong)
    setStopRange(props.stopObj.stopRange);
    setStopRangeId(props.stopObj.stopRangeId);
    setFlgMorEve(props.flgMorEve);
    if (props.flgMorEve) {
      setLstDirection([
        { directionId: 10, direction: "Both" },
        { directionId: 30, direction: "Evening" },
      ]);
    } else {
      setLstDirection([
        { directionId: 10, direction: "Both" },
        { directionId: 20, direction: "Morning" },
      ]);
    }
  }, [
    props.stopObj.stopRangeId,
    props.stopObj.stopRange,
    props.stopObj.arrivalTm,
    props.stopObj.address,
    props.stopObj.tmFromPrev,
    props.stopObj.tmToNext,
    props.routeId,
    props.stopObj.stopId,
    props.stopObj.stopNo,
    props.stopObj.stopDesc,
    props.stopObj.stateId,
    props.stopObj.cityId,
    props.stopObj.localityId,
    props.flgMorEve,
  ]);
  function cbGetLstStopRange(data) {
    setLoader("none");
    if (data.SvcStatus === "Success") {
      setLstStopRange(data.lstStopRange);
    } else {
      toast.error(data.SvcMsg);
    }
  }
  function handleStopNoChange(param) {
    setStopNo(param);
  }
  function handleStopDescChange(param) {
    setStopDesc(param);
  }
  function handleAddressChange(param) {
    setAddress(param);
  }
  function handleStateChange(pStateId) {
    if (pStateId) {
      setStateId(pStateId);
      getCityListSvc(cbCityListResponse, pStateId);
    }
  }
  function handleStopRangeChange(pStopRangeId) {
    setStopRangeId(pStopRangeId);
  }
  function handleCityChange(pCityId, pLocalityId) {
    setCityId(pCityId);
    getLocalityListSvc(cbLocalityListResponse, pCityId, pLocalityId);
  }
  function handleLocalityChange(pLocalityId) {
    setLocalityId(pLocalityId);
  }
  function cbStateListResponse(data, pStateId, pCityId, pLocalityId) {
    if (data.SvcStatus === "Success") {
      setLstState(data.lstState);
      if (pStateId) {
        getCityListSvcAndSetCity(
          cbCityListResponseAndSetCity,
          pStateId,
          pCityId,
          pLocalityId
        );
      }
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function cbCityListResponseAndSetCity(data, pCityId, pLocalityId) {
    if (data.SvcStatus === "Success") {
      setLstCity(data.lstCity);
      setCityId(pCityId);
      getLocalityListSvc(cbLocalityListResponse, pCityId, pLocalityId);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function cbCityListResponse(data) {
    if (data.SvcStatus === "Success") {
      setLstCity(data.lstCity);
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function cbLocalityListResponse(data, pLocalityId) {
    if (data.SvcStatus === "Success") {
      setLstLocality(data.lstLocality).then(() => {
        setLocalityId(pLocalityId);
      });
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  function save() {
    var obj = {
      stopId: stopId,
      localityId: localityId,
      cityId: cityId,
      stateId: stateId,
      address: address,
      stopDesc: stopDesc,
      stopRangeId: stopRangeId,
    };
    // // console.log(JSON.stringify(obj));
    // // console.log("stopId = " + stopId);
    // // console.log("stateId = " + stateId);
    // // console.log("cityId = " + cityId);
    // // console.log("localityId = " + localityId);
    // // console.log("address = " + address);
    // // console.log("stopDesc = " + stopDesc);
    // // console.log("stopRangeId = " + stopRangeId);
    saveStopSvc(routeId, obj, flgDirection, cbSaveStopResponse);
  }
  function cbSaveStopResponse(data) {
    if (data.SvcStatus === "Success") {
      props.setStopId(data.stopId);
      toast.success(data.SvcMsg);
      props.generateRandomNo();
      props.closeSlider();
    } else {
      toast.error(data.SvcMsg);
    }
    setLoader("none");
  }
  return (
    <>
      <div
        className="col-sm-11 col-centered padding-remove"
        style={{
          pointerEvents: loader === "none" ? "auto" : "none",
          height: "100%",
          width: "100%",
          background: "#fff",
          border: "1px solid #ccc",
          borderRadius: 10,
        }}
      >
        <form className="form form-horizontal">
          <button
            type="button"
            className="btn btn-info text-center btnCloseDiv"
            onClick={props.closeSlider}
            style={{ right: "50%" }}
          >
            <span
              id="close"
              title="Close Slider"
              className="glyphicon glyphicon-remove"
            ></span>
          </button>
          <div className="col-sm-7">
            <div className="row">
              <label className="control-label col-sm-4">Stop No.</label>
              <div className="form-group col-sm-4">
                <input
                  type="text"
                  defaultValue={stopNo}
                  className="form-control"
                  onChange={(e) => handleStopNoChange(e.target.value)}
                  readOnly
                ></input>
              </div>
            </div>
            <div className="row">
              <label className="control-label col-sm-4">Stop Desc.</label>
              <div className="form-group col-md-8">
                <input
                  type="text"
                  defaultValue={stopDesc}
                  className="form-control"
                  onChange={(e) => handleStopDescChange(e.target.value)}
                ></input>
              </div>
            </div>

            <DropDown
              label="State"
              onChangeDropDown={handleStateChange}
              option1="State"
              key={"s_" + stateId}
              lst={lstState}
              dataValue={stateId}
              keyId="stateId"
              keyVal="state"
            ></DropDown>

            <DropDown
              label="City"
              onChangeDropDown={handleCityChange}
              option1="City"
              key={"c_" + cityId}
              lst={lstCity}
              dataValue={cityId}
              keyId="cityId"
              keyVal="city"
            ></DropDown>

            <DropDown
              label="Locality"
              onChangeDropDown={handleLocalityChange}
              option1="Locality"
              key={"l_" + localityId}
              lst={lstLocality}
              dataValue={localityId}
              keyId="localityId"
              keyVal="locality"
            ></DropDown>

            <div className="row">
              <label className="control-label col-sm-4">Address</label>
              <div className="form-group col-md-8">
                <input
                  type="text"
                  defaultValue={address}
                  className="form-control"
                  onChange={(e) => handleAddressChange(e.target.value)}
                ></input>
              </div>
            </div>

            {/* <div className='row'>

                            <label className="control-label col-sm-4">Lat Long</label>
                            <div className="form-group col-md-8">
                                <input type="text" value={latLong} className="form-control"

                                ></input>
                            </div>
                        </div> */}
            <DropDown
              label="Stop Range"
              onChangeDropDown={handleStopRangeChange}
              option1="Stop Range"
              key={"st_" + stopRangeId}
              lst={lstStopRange}
              dataValue={stopRangeId}
              keyId="stopRangeId"
              keyVal="stopRange"
            ></DropDown>
            <div className="row">
              <label className="control-label col-sm-4">Direction</label>
              <div className="col-sm-2 form-group">
                {lstDirection.map((obj, idx) => {
                  return (
                      <label className="radio-inline" key={`${idx}_radio`} style={{ margin: 0 }}>
                        <input
                          type="radio"
                          name="direction"
                          value={obj.directionId}
                          checked={flgDirection === obj.directionId}
                          onChange={(e) =>
                            setFlgDirection(parseInt(e.target.value))
                          }
                        ></input>
                        {obj.direction}
                      </label>
                  );
                })}
              </div>
            </div>
            <div
              className="row"
              style={{ width: "100%", paddingBottom: "1px" }}
            >
              <div className="col-md-offset-4 col-md-6 padding-remove">
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={(e) => save()}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-info pull-right"
                  onClick={(e) => props.deleteRouteStop(routeId, stopId)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </form>
        {/* <form className='col-sm-12 form' >
                    <div className="col-sm-6 col-centered"><h5>Timings</h5></div>
                    <div className="row">
                        <div className="col-sm-12 form-group">
                            <div className="pull-left padding-remove col-sm-3" style={{ border: '1px solid #b4b4b4' }}>
                                <label className="col-sm-7 control-label padding-remove" style={{
                                    color: '#fff', background: '#ccc'
                                }}>Arrival</label>
                                <label className="col-sm-5 control-label padding-remove pull-right">{props.arrivalTm}</label>
                            </div>
                            <div className="pull-left padding-remove col-sm-3" style={{ marginLeft: '2%', border: '1px solid #b4b4b4' }}>
                                <label className="col-offset-1 col-sm-7 control-label padding-remove" style={{ color: '#fff', background: '#ccc' }}>To Next Stop</label>
                                <label className="col-sm-5 control-label padding-remove pull-right">{props.tmToNext}</label>
                            </div>
                            <div className="pull-left padding-remove col-sm-3" style={{ marginLeft: '2%', border: '1px solid #b4b4b4' }}>
                                <label className="col-sm-7 control-label padding-remove" style={{ color: '#fff', background: '#ccc' }}>From Prev. Stop</label>
                                <label className="col-sm-5 control-label padding-remove pull-right">{props.tmFromPrev}</label>
                            </div>
                        </div>
                    </div>

                </form> */}
      </div>
    </>
  );
};
export default RouteStopSlider;
