import axios from 'axios'
import React, { Component, useEffect, useState } from 'react';
import {previewPdf } from './tchWiseServices';
const ViewSlider = (props) => {
    useEffect(() => {
        if (props.tchId && props.sessionId && props.flg) {
            previewPdf(props.sessionId, props.tchId, handlePreviewPdfResponse);
        }
    })

    function handlePreviewPdfResponse(data) {
        let blob = new Blob([data.data], { type: 'application/pdf' }),
            url = window.URL.createObjectURL(blob);
        const iframe = document.getElementById('viewer');
        iframe.setAttribute('src', url);
        URL.revokeObjectURL(url);
    }
    return (
        <div>
            <button type="button" className="btn btn-info text-center " id="closeSlider" style={{ right: '71%', opacity: 1 }}
                onClick={(e) => props.setFlgClose()}>
                <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
            </button>
            <div className="pdf" style={{ width: '100%', height: '96vh' }}>
                <iframe src={require("common/images/loader.gif")} title="Time Table Preview" id="viewer" style={{ width: '100%', height: '100%' }} />
            </div>
        </div>
    )
}
export default ViewSlider