import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'common/css/master.css';
import Header from 'common/js/header/header.js';
import "../css/syllabus.css";
import TeacherSyllabusForm from "./teacherSyllabusForm";
import { getTeachersList } from "./teacherSyllabusService";


const TeacherSyllabus = () => {
  var initialState = {
    loader: 'none',
    opacity: 1,
  };
  const [state, setState] = useState(initialState);
  const [teachers, setTeachers] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    showLoader();
    getTeachersList(handleTeachersListResponse);
  }, []);

  function showLoader() {
    setState({ ...state, loader: 'block', opacity: 0.5 });
  }
  function hideLoader() {
    setState({ ...state, loader: 'none', opacity: 1 });
  }


  function handleTeachersListResponse(data) {
    hideLoader();
    if (data.SvcStatus === "Success") {
      setTeachers(data.lstTch);
      hideLoader()
    } else {
      if (data.SvcMsg === "You are not logged in") {
        window.location = "/";
      }
      setError(data.SvcMsg);
      hideLoader()
    }
  }
  const getWingId = (wingId) => {
    // // console.log("wingId" + wingId);
  }

  return (
    <div style={{ height: '100%' }}>
      <Header wingFromChild={getWingId} />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className="container-fluid mainContainer" style={{ opacity: state.opacity }}>
        <div className="row">
          <div className="col-md-3 col-sm-12 col-lg-4">
            <div style={{ position: 'absolute', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: state.loader }}>
              <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <div className="card" style={{ height: '80vh' }}>
              <div className="table-responsive">
                <table
                  className="teachersTable tableLayout1"
                  cellSpacing="0"
                  cellPadding="0"
                >
                  <thead>
                    <tr style={{ margin: "5px" }}>
                      <th>Teacher (Filter)</th>
                      <th>Role (Filter) </th>
                      <th>Classes (Filter) </th>
                      <th style={{ textAlign: "center" }}> Action</th>
                    </tr>
                  </thead>
                </table>
                <div className="tableDiv" style={{ height: '70vh' }}><table id="tchtable" className="tableLayout">
                  <tbody>
                    {teachers.map((teacher, index) => {
                      const clas =
                        selectedTeacher === teacher
                          ? "selected"
                          : "";
                      return (
                        <tr className={clas} id={teacher.tchId} key={index}>
                          <td>{teacher.tch}</td>
                          <td>{teacher.role}</td>
                          <td style={{ padding: "5px" }}>
                            {teacher.class_Section}
                          </td>
                          <td
                            onClick={() => setSelectedTeacher(teacher)}
                            className="edit"
                          >
                            <b>Edit</b>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                </div>
              </div>
            </div>
          </div>

          {selectedTeacher && (
            <TeacherSyllabusForm
              teacher={selectedTeacher}
            />
          )}
        </div>
      </div>
    </div >
  );
};

export default TeacherSyllabus;
