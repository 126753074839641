import React, { createContext, useContext, useState } from 'react';
import { initiateSocketConnection, disconnectSocket, subscribeToMessages, sendSocketMessage } from './socketServices.js';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
    const [invigilatorSocketConnected, setInvigilatorSocketConnected] = useState(false);
    const [studentSocketConnected, setStudentSocketConnected] = useState(false);
    // const url = "ws://192.168.1.4:6400/";
    const url = "ws://localhost:6400/";


    const connectSocket = async (clientType) => {
        const socketUrl = url + clientType;
        try {
            await initiateSocketConnection(socketUrl);
            if (clientType === "student") {
                setStudentSocketConnected(true);
            } else {
                setInvigilatorSocketConnected(true);
            }
        } catch (error) {
            console.error('Failed to connect to WebSocket:', error);
            if (clientType === "student") {
                setStudentSocketConnected(false);
            } else {
                setInvigilatorSocketConnected(false);
            }
        }
    };

    const disconnectSocketWrapper = (clientType) => {
        // // console.log("disconnectSocketWrapper called");
        disconnectSocket();
        if (clientType === "student") {
            setStudentSocketConnected(false);
        } else {
            setInvigilatorSocketConnected(false);
        }
    };

    const subscribeToMessagesWrapper = (callback) => {
        // // console.log("subscribeToMessageWrapper called");
        const unsubscribe = subscribeToMessages((data) => {
            // // console.log('Received message in subscribeToMessagesWrapper:', JSON.stringify(data, null, 2));
            callback && callback(data);
        });
        return unsubscribe;
    };

    const sendMessage = (message) => {
        // // console.log("sendMessage called with message:", message);
        sendSocketMessage(message); // Call the sendMessage from socketServices
    };
    return (
        <WebSocketContext.Provider
            value={{
                invigilatorSocketConnected,
                studentSocketConnected,
                connectSocket,
                disconnectSocketWrapper,
                subscribeToMessagesWrapper,
                sendMessage
            }}
        >
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => useContext(WebSocketContext);
