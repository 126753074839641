import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import "../css/subjectAllocation.css";

import { getSubjectAllocationListSvc } from "./subjectAllocationSvc.js";
import SubjectAllocationTable from "./subjectAllocationTbl.js";
import SubjectAllocationSlider from "./subjectAllocationSlider.js";


const SubjectTeacherAllocationCtrl = (props) => {
    const {loader, setLoader, opacity, setOpacity, syllabusId} = props;
    const [lstSubjectAllocation, setLstSubjectAllocation] = useState([]);
    const [subjectAllocationId, setSubjectAllocationId] = useState(0);
    const [subjectAllocation, setSubjectAllocation] = useState({});
    const [isSubjectAllocationVisible, setIsSubjectAllocationVisible] = useState(false);
    const [key, setKey] = useState(0);

    useEffect(() => {
        // console.log("SubjectAllocationCtls called");
        if (!!syllabusId)
            getSubjectAllocationList(syllabusId);
    }, [key, syllabusId]);

    const getSubjectAllocationList = (pSyllabusId) => {
        // console.log("getSubjectAllocationList called");
        setLoader("block");
        setOpacity(0.5);
        getSubjectAllocationListSvc(pSyllabusId, subjectAllocationListResponse);
    };

    const subjectAllocationListResponse = (data) => {
        // console.log("subjectAllocationListResponse called, data", data);
        if (data.status === "Success") {
            setLstSubjectAllocation(data.lstSubject);
        } else if (data.status === "Failure") {
            handleServiceError(data.message);
        } else {
            toast.error("Service failed " + data.error);
        }
        setLoader("none");
        setOpacity(1);
    };

    const handleServiceError = (svcMsg) => {
        if (svcMsg === "You are not Logged In") {
            toast.error("You are not logged in. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else if (svcMsg === "Server Timed Out, Login Again") {
            toast.error("Server timed out. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else {
            toast.error(svcMsg);
        }
    };

    const editSubjectAllocation = async (pSubjectAllocation) => {
        // console.log("editSubjectAllocation called, pSubjectAllocation ", pSubjectAllocation);
        await setSubjectAllocation(pSubjectAllocation);
        await setSubjectAllocationId(pSubjectAllocation.subId);
        setIsSubjectAllocationVisible(true);
    };

    const deleteSubjectAllocation = (pSubjectAllocationId) => {
        // console.log("deleteSubjectAllocation called, pSubjectAllocationId ", pSubjectAllocationId);
    };

    const closeSlider = () => {
        setIsSubjectAllocationVisible(false);
    };

    const updateKey = () => {
        setKey(prevKey => prevKey + 1);
    };

    return (
        <div className="col-md-12 col-xs-12 col-centered padding-remove">
            <div className="col-ls-10 col-md-10 col-sm-10 col-xs-10 col-centered padding-remove" style={{ marginTop: "45px" }}>
                <SubjectAllocationTable
                    lstSubjectAllocation={lstSubjectAllocation}
                    subjectAllocationId={subjectAllocationId}
                    setSubjectAllocationId={setSubjectAllocationId}
                    editSubjectAllocation={editSubjectAllocation}
                    deleteSubjectAllocation={deleteSubjectAllocation}
                />
            </div>
            {
                isSubjectAllocationVisible ?
                    (
                        <div className="slider1-80" >
                            <SubjectAllocationSlider
                                key={subjectAllocationId}
                                subject={subjectAllocation}
                                syllabusId={syllabusId}
                                updateKey={updateKey}
                                deleteSubjectAllocation={deleteSubjectAllocation}
                                closeSlider={closeSlider}
                            />
                        </div>
                    )
                    : null
            }
        </div >
    );
};

export default SubjectTeacherAllocationCtrl;
