import React,{useState, useEffect} from 'react';

const RdoLbl = (props) => {
    const [checked, setChecked] = useState(props.checked)
    
    useEffect(()=>{
        setChecked(props.checked)
    },[props.checked])
    
    return (
        <div className='col-sm-2'>
            <label className='radio-inline control-label'>
                <input type='radio' defaultChecked={checked} value={props.dataValue} onChange={(e)=>props.onChecked(props.dataValue)} />{props.label}
            </label>
        </div>
    );
};

export default RdoLbl;
