

const PrintCopies = (props) => {
    const { printOneBook, printAllCopies, closePrintCopies } = props;


    return (
        <div style={{ display: "flex", justifyContent: "center", paddingTop: 20, position: "absolute", top: "35%", left: "60%", backgroundColor: "#F8F8F8", width: 400, height: 200, zIndex: 1001 }}>
            {/* <div> */}
            <div>
                <div className="col-sm-12" style={{ marginBottom: 10 }} >
                    <div className="col-sm-8" style={{ padding: "10px 2px", fontSize: "large" }}>
                        Print Book Copies
                    </div>
                    <div className="col-sm-4" >
                        <button type="button"
                            title="close print-book"
                            onClick={closePrintCopies}
                            className='btn btn-info text-center pull-right'>X</button>
                    </div>
                </div>


                <div className="col-sm-12 padding-remove" >
                    <div className=" col-md-12 form-group text-center multiButtonWrap1 save-print-buttons" >
                        <div style={{ marginTop: 45, width: 150 }}>
                            <button type="button" style={{ width:"90%" }} onClick={printOneBook} className="btn btn-success">Print one Book</button>
                        </div>
                        <div style={{ marginTop: 45, width: 150 }}>
                            <button type="button" style={{ width:"90%" }} onClick={printAllCopies} className="btn btn-success">Print all copies</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrintCopies;