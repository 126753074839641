const SliderType = Object.freeze({
    NONE: '0'
    , SLIDER_PERSONAL_DTLS: '10'
    , SLIDER_ADDRESS_DTLS: '20'
    , SLIDER_EDUCATION: '30'
    , SLIDER_EXPERIENCE: '35'
    , SLIDER_AWARD: '40'
    , SLIDER_CLS_SUBJECT: '50'
    , SLIDER_PROFILE: '60'
    , SLIDER_ATTACHMENTS: '70'
})

export default SliderType;