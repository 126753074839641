import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from 'common/js/App';
// import { BrowserRouter } from "react-router-dom";

import * as serviceWorker from './serviceWorker';
//App is wrapped in  BrowserRouter of React router dom so that when working with Link, page load do not load entire component.
// Adding BrowserRouter will make you render a component in a route directly.
// ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById('sms'));
const root = ReactDOM.createRoot(document.getElementById("sms"));
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
