import React, { Component } from 'react'

export default class ContentForm extends Component {
    handleSubmit = (e) => {
        e.preventDefault();
    }
    render() {
        return (
            <div>
                <div className="col-xs-12 padding-remove" >
                    <form onSubmit={this.handleSubmit} id="addForm" className="form-horizontal formWrapper">
                        <div className="form-group valueWrap">
                            <label className="control-label col-sm-4" >Name<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                            <div className="col-sm-7 padding-remove">
                                <input autoComplete="off" name="content" id="content" type="text" className="form-control " placeholder="Enter Name" required />
                            </div>
                        </div>
                        <div className="form-group valueWrap">
                            <label className="control-label col-sm-4" >Tags</label>
                            <div className="col-sm-7 padding-remove">
                                <textarea autoComplete="off" name="tags" id="tags" type="text" className="form-control " placeholder="Enter Tags" />
                            </div>
                        </div>
                        <div className="form-group valueWrap">
                            <label className="control-label col-sm-4" >Media</label>
                            <div className="col-sm-7 padding-remove">
                                <input autoComplete="off" name="contentFile" onClick={this.props.uploadFile} id="contentFile" type="file" className="form-control " placeholder="Select File" />
                            </div>
                        </div>
                        <div className="form-group" >
                            <div className="col-sm-12 text-center userButtonWrap" >
                                <button type="submit" style={{ marginTop: 20 }} title="Save" onClick={this.props.saveContent} className="btn btn-info">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
