import React, { useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import '../css/EnquiryCtrl.css'
import { getStaffListSvc } from "./ScheduleTestSvc"
import { assignEvalSvc, assignAndInformEvalSvc, informEvalSvc } from "./ScheduleTestSvc"

const EvalList = (props) => {
  const [lstStaff, setLstStaff] = useState([])
  const [evalId, setEvalId] = useState(0)
  const [lstTestSlotId, setLstTestSlotId] = useState(props.lstTestSlotId)
  const [testSlotEvalId, setTestSlotEvalId] = useState(0)

  function cbStaffListResponse(lstStaff) {
    setLstStaff(lstStaff);

  }
  useEffect(() => {
    getStaffListSvc(cbStaffListResponse)
    // // console.log("Test Slot Id in EvalList " + props.lstTestSlotId);
    setLstTestSlotId(props.lstTestSlotId);
  }, [props])

  function cbAssignAndInformEvalResp(data) {
    if (data.SvcStatus === "Success") {
      setTestSlotEvalId(data.testSlotEvalId)
      toast.success(data.SvcMsg)
    }
    else {
      toast.error(data.SvcMsg)
    }
  }

  function assignAndInformEval() {
    assignAndInformEvalSvc(cbAssignAndInformEvalResp, lstTestSlotId, evalId)

  }

  function cbAssignEvalResp(data) {
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
      setTestSlotEvalId(data.testSlotEvalId);
    }
    else {
      toast.error(data.SvcMsg);
    }
  }

  function assignEval() {
    // // console.log("LstTestSlotId in assign Eval is " + lstTestSlotId)
    // // console.log("Eval Id in assign Eval is " + evalId)
    assignEvalSvc(cbAssignEvalResp, lstTestSlotId, evalId)
  }

  function cbInformEval(data) {
    if (data.SvcStatus === "Success") {
      toast.success(data.SvcMsg)
    }
    else {
      toast.error(data.SvcMsg)
    }
  }

  function informEval() {
    // // console.log(lstTestSlotId)
    informEvalSvc(cbInformEval, lstTestSlotId)
  }

  function handleEvalChange(staffId) {

    setEvalId(staffId);
    // // console.log("Selected StaffId is  " + staffId)

  }
  return (
    <div className="col-sm-12" style={{ border: '1px solid #ddd', padding: 2, marginTop: 2 }}>
      <div className="form-group">
        <label className="control-label col-sm-6">Evaluator</label>
        <div className="col-sm-6">

          <select className="form-control" onChange={(e) => handleEvalChange(e.target.value)}>
            <option value={0}>Evaluator</option>
            {lstStaff.map((local, idx) => {
              return (
                <option key={idx} value={local.staffId}>
                  {local.staff}
                </option>
              );
            })}
          </select>

        </div>
      </div>

      <div className="">
        <div className="col-sm-12" style={{ marginTop: 5 }}>
          <button id='bAdd' className="btn btn-primary pull-right" type='button' onClick={(e) => assignAndInformEval()}>Assign And Inform Evaluator</button>
        </div>
        <div className="col-sm-12" style={{ marginTop: 5 }}>
          <button id='bAdd' className="btn btn-primary pull-right" type='button' onClick={(e) => assignEval()}>Assign Evaluator</button>
        </div>
        <div className="col-sm-12" style={{ marginTop: 5 }}>
          <button id='bAdd' className="btn btn-primary pull-right" type='button' onClick={(e) => informEval()}>Inform Evaluator</button>
        </div>

      </div>
    </div>
  );

}
export default EvalList;