
import React, { useEffect, useState } from 'react';
import { Document, Page, PDFViewer, View, Font } from '@react-pdf/renderer';


import { styles, pageStyle } from './examReportCardStyles.js';
import { HeaderLogoSection, HeaderAddressSection, HeaderNameSection } from "./headerSection.js";
import StudentSection from './studentSection.js';
import { ScoreSection } from './scoreSection.js';
import FooterSection from './footerSection.js';
// import LstReportCard from "./lstReportCard.json";

Font.register({
  family: 'Calibri'
  , fonts: [{ src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibri.ttf" }
    , { src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibrib.ttf", fontWeight: "bold" }], format: 'truetype'
});




const ExamReportCardPDF = (props) => {

  const [reportHeader, setReportHeader] = useState(null);
  const [headerName, setHeaderName] = useState('');
  const [lstStudent, setLstStudent] = useState([]);
  const [clsSectionDtls, setClsSectionDtls] = useState(null);
  const [principalSign, setPrincipalSign] = useState('');
  const [teacherSign, setTeacherSign] = useState('');
  const [flgValidated, setFlgValidated] = useState(false);

  useEffect(() => {
    setReportHeader(props.reportCard.reportHeader);
    setHeaderName(props.reportCard.headerName);
    setLstStudent(props.reportCard.lstStudent);
    setClsSectionDtls(props.reportCard.clsSectionDtls);
    setPrincipalSign(props.principalSignUrl);
    setTeacherSign(props.teacherSignUrl);
    setFlgValidated(props.flgValidated);
    return () => {
      setTeacherSign("");
  };
    // Uncomment if you want to log the values
    // // console.log("ExamReportCardPDF called", "reportHeader:", JSON.stringify(props.reportCard.reportHeader, null, 2), "headerName:", props.reportCard.headerName, "lstStudent:", JSON.stringify(props.reportCard.lstStudent, null, 2));
  }, [props.reportCard, props.principalSignUrl, props.teacherSignUrl, props.flgValidated]);


  const pages = [];

  for (let i = 0; i < lstStudent.length; i++) {
    pages.push(
      <Page
        key={lstStudent[i].studentHeader.studentId}
        size="A4" style={pageStyle.page}>
        <View style={pageStyle.page_border}>
          <View style={pageStyle.header_section}>
            <View style={pageStyle.header_logo}>
              <HeaderLogoSection
                logoUrl={props.logoUrl}
                reportHeader={reportHeader}
              />
            </View>
            <View style={pageStyle.header_address}>
              <HeaderAddressSection
                reportHeader={reportHeader}
              />
            </View>
            <View style={pageStyle.header_name}>
              <HeaderNameSection
                headerName={headerName}
              />
            </View>
          </View>
          <View style={pageStyle.student_section}>
            <StudentSection
              studentHeader={lstStudent[i].studentHeader}
              clsSectionDtls={clsSectionDtls}
            />
          </View>
          <View style={pageStyle.score_section}>
            <ScoreSection
              studentHeader={lstStudent[i].studentHeader}
              studentSummary={lstStudent[i].studentSummary}
              lstScholastic={lstStudent[i].lstScholastic}
              lstCoscholastic={lstStudent[i].lstCoscholastic}
              exam={props.exam}
            />
          </View>
          <View style={pageStyle.footer_section}>
            <FooterSection
              principalSign={flgValidated ? principalSign : ""}
              teacherSign={flgValidated ? teacherSign : ""}
            />
          </View>
        </View>
      </Page>
    );
  }


  return (
    <div className='col-sm-12 padding-remove' >
      <PDFViewer style={{ width: "100%", height: "600px", overflowY: "scroll" }}>
        <Document>{pages}</Document>
      </PDFViewer>
    </div>
  );
}

export default ExamReportCardPDF;

