import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';

import Header from 'common/js/header/header.js';
import 'common/css/master.css';
import '../css/studentdtls.css';
// import './../../bootstrap/css/bootstrap.min.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
//import moment from 'moment';
//import {baseUrl} from './const';

var httpURL = "", httpURLRegs = "";
var queryString = require('querystring');
var schoolId = 1;
var lstClass = [];
var lstStudent = [];
let screenId = 17;
var lstCity = [];
var lstState = [];
var lstSiblings = [];
var docType = "";
var docFile = "";
var lstDocuments = [];
var lstDocType = [
    { 'docType': 10, 'docName': 'Id Proof' },
    { 'docType': 20, 'docName': 'Age Proof' },
    { 'docType': 30, 'docName': 'Resident Proof' },
    { 'docType': 40, 'docName': 'Birth Certificate' },
    { 'docType': 50, 'docName': 'Sibling Proof' }
];


var tokenid = localStorage.getItem("tokenid");
var studentId = localStorage.getItem("studentId");

var lstGender = [{ "genderId": "M", "gender": "Male" }, { "genderId": "F", "gender": "Female" }, { "genderId": "O", "gender": "Other" }];
var COL_STUDENTID = 'td:eq(0)';
var MAX_STUDENT_LENGTH = 30;
class studentRegisterDtls extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schoolId: studentId,
            lstClass: [],
            studentId: studentId,
            lstCity: [],
            lstState: [],
            studentObj: {},
            studentDtls: {},
            parents: {},
            lstSiblings: [],
            flg: true,
            width: '97%',
            loader: 'none',
            opacity: 1,
            tokenid: tokenid,
            src: require('common/images/avatar.png'),
        }
    }
    async componentWillMount() {
        // // console.log("componentWillMount() called:"+localStorage.studentId);

        await this.setState({
            studentId: parseInt(localStorage.getItem("studentId")),
            flg: localStorage.getItem("flg"),
        });

    }

    componentDidMount = () => {
        this.handleResize();
        window.addEventListener("load", this.handleResize);
        window.addEventListener("resize", this.handleResize);
        $('#inp_city').prepend($("<option> </option>").val('0').html('City'));
        $('#inp_state').prepend($("<option> </option>").val('0').html('State'));
        $('#inp_gender').prepend($("<option> </option>").val('0').html('Gender'));
        $('#inp_sgender').prepend($("<option> </option>").val('0').html('Gender'));
        this.getMasterData();
        this.getStudentsData(studentId);
        this.fillDocTypeCmb();
        this.getProfilePic(studentId);
        this.getStudentDocs(studentId);

    }

    getProfilePic = (studentId) => {
        axios({
            url: httpURLRegs + '/sms/student/view_profile',
            method: "GET",
            params: { studentId, screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
            responseType: 'arraybuffer',
        }).then((response) => {
            var temp = JSON.stringify(response);
            if (temp.length > 550) {
                // toast.error("No Profile Pic For This Student");
                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);

                }
            } else {
                var file = new Blob([response.data], { type: 'image/jpeg' });
                var fileURL = URL.createObjectURL(file);
                //window.open(fileURL);
                $('#profileImage').attr('src', fileURL);
            }
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });
    }

    getStudentDocs = (studentId) => {

        axios({
            url: httpURLRegs + '/sms/student/get_docs',
            method: "POST",
            params: { studentId, screenId },
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                lstDocuments = response.data.lstDocuments;
                // // console.log(lstDocuments);
                this.SetDocumentsTable();
            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not Logged In') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
            }
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }
    getStudentsData = (studentId) => {
        var schoolStudent = {
            'schoolId': schoolId,
            'studentId': parseInt(studentId)
        };
        axios({
            url: httpURLRegs + '/sms/student/dtls',
            method: "POST",
            data: queryString.stringify({ 'schoolStudent': JSON.stringify(schoolStudent), screenId }),
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                this.setState({
                    studentDtls: response.data.studentDtls,
                })
                var studentDtls = this.state.studentDtls;
                // // console.log(studentDtls);
                this.setStudentDtls(studentDtls);
            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not Logged In') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });

    }
    getParentsData = (studentId) => {
        var schoolStudent = {
            'schoolId': schoolId,
            'studentId': parseInt(studentId)
        };
        // // console.log(schoolStudent);
        axios({
            url: httpURLRegs + '/sms/student/parents',
            method: "POST",
            data: queryString.stringify({ 'schoolStudent': JSON.stringify(schoolStudent), screenId }),
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                this.setState({
                    parents: response.data.parents,
                })
                var parents = this.state.parents;
                // // console.log(parents);
                this.setParentsDtls(parents);
            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not Logged In') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });

    }

    async setSiblingsTable(lstSiblings) {
        $('#siblingTableBody tr').remove();
        // var table 				= document.getElementById('siblingTable');
        var tbody = document.getElementById('siblingTableBody');

        for (var i = 0; i < lstSiblings.length; i++) {
            var obj = lstSiblings[i];
            var siblingId = obj.siblingId;

            // // console.log("siblingId:"+siblingId);
            var sibling = obj.sibling;
            var age = obj.age;
            var gender = obj.gender;
            var clsId = obj.clsId;
            var cls = obj.cls;
            var school = obj.school;
            var row = document.createElement('tr');
            row.id = siblingId;
            row.onclick = (e) => {
                $(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');
            }
            this.addTD(row, siblingId, 'no-display');
            this.addTD(row, sibling, '');
            this.addTD(row, age, '');
            this.addTD(row, gender, '');
            this.addTD(row, clsId, 'no-display');
            this.addTD(row, cls, '');
            this.addTD(row, school, '');
            this.addSiblingTDActions(row, 'text-center');
            tbody.append(row);
        }
        // table.append(tbody);
    }
    async setDocsTable(lstSiblings) {
        $('#studentDocsTableBody tr').remove();
        // var table 				= document.getElementById('siblingTable');
        var tbody = document.getElementById('studentDocsTableBody');

        for (var i = 0; i < lstSiblings.length; i++) {
            var obj = lstSiblings[i];
            var siblingId = obj.siblingId;

            // // console.log("siblingId:"+siblingId);
            var sibling = obj.sibling;
            var age = obj.age;
            var gender = obj.gender;
            var clsId = obj.clsId;
            var cls = obj.cls;
            var school = obj.school;
            var row = document.createElement('tr');
            row.id = siblingId;
            row.onclick = (e) => {
                $(e.target).closest('tr').addClass('selected').siblings().removeClass('selected');
            }
            this.addTD(row, siblingId, 'no-display');
            this.addTD(row, sibling, '');
            this.addTD(row, age, '');
            this.addTD(row, gender, '');
            this.addTD(row, clsId, 'no-display');
            this.addTD(row, cls, '');
            this.addTD(row, school, '');
            this.addSiblingTDActions(row, 'text-center');
            tbody.append(row);
        }
        // table.append(tbody);
    }
    addSiblingTDActions(row, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var img1 = document.createElement("img");
        img1.src = require("common/images/edit.png");
        img1.width = 20;
        img1.addEventListener('click', (e) => this.setSiblingPopup(e));
        var img2 = document.createElement("img");
        img2.src = require("common/images/bin.png");
        img2.width = 20;
        img2.addEventListener('click', (e) => this.deleteSibling(e));
        cell.append(img1);
        cell.append(img2);
        row.append(cell);
    }

    deleteSibling = (event) => {
        var schoolSibling = {
            "siblingId": $(event.target).closest('tr').find("td:eq(0)").text(),
            "schoolId": schoolId
        }

        // $(event.target).closest('tr').click();
        // $(event.target).closest('tr').remove();
        axios.post(httpURLRegs + '/sms/student/remove_sibling',
            queryString.stringify({ 'schoolSibling': JSON.stringify(schoolSibling), screenId }),
            {
                headers: { 'tokenid': this.state.tokenid },
                withCredentials: true
            }
        ).then((response) => {
            // // console.log(JSON.stringify(response));
            // // console.log(response.data.SvcStatus);
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getSiblingsList(this.state.studentId);

            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not logged in') {
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }

    setStudentDtls = (studentDtls) => {
        // // console.log(studentDtls.personal.dob);
        // var dd = studentDtls.personal.dob.split('-')[0];
        // var mm = studentDtls.personal.dob.split('-')[1];
        // var yr = parseInt(studentDtls.personal.dob.split('-')[2]);
        // var d = studentDtls.personal.admissionDt.split('-')[0];
        // var m = studentDtls.personal.admissionDt.split('-')[1];
        // var y = studentDtls.personal.admissionDt.split('-')[2];
        // this.setState({
        //     dob: new Date(yr, mm, dd),
        //     doa: new Date(y, m, d),
        // })
        // // console.log(studentDtls.personal.admissionDt);
        // this.setState({
        //     dob:studentDtls.personal.dob,
        //     doa:studentDtls.personal.admissionDt,
        // });
        this.setPersonalDtls(studentDtls.personal);
        this.setStudentHistory(studentDtls.history);
        this.setMedicalHistory(studentDtls.medical);
    }
    setParentsDtls = (parents) => {
        this.setFatherDtls(parents.father);
        this.setMotherDtls(parents.mother);
        this.setGuardianDtls(parents.guardian);
    }
    async setPersonalDtls(personal) {
        // this.setState({
        //     dob:new Date(personal.dob),
        //     doa:new Date(personal.admissionDt),
        // });
        $('#inp_state').val(personal.stateId);
        await this.getCityList();
        $('#inp_student').val(personal.student);

        $('#inp_gender').val(personal.gender);
        $('#inp_blocked').attr('checked', personal.blocked);
        $('#inp_address1').val(personal.address1);
        $('#inp_address2').val(personal.address2);
        $('#inp_pin').val(personal.pin);
        $('#lbl_enrollment_no').text(personal.enrollmentNo);

        if (personal.dob === "00-00-0000" || personal.dob === null) {
            this.setState({
                dob: "",
            });
        }
        else {
            var date = personal.dob;
            date = date.split('-');
            var setDate = date.reverse().join();
            setDate = setDate.replace(/,/g, '-');
            var dob = new Date(setDate);
            this.setState({
                dob: dob,
            });
        }
        this.calcAge(personal.dob);
        setTimeout(function () {
            $('#inp_city').val(personal.cityId);
        }, 500);


    }
    calcAge = (dob) => {
        // // console.log(dob);
        if (typeof dob == "object")
            dob = this.formatDate(dob);
        var dd = dob.split('-')[0];
        var mm = dob.split('-')[1];
        var yr = parseInt(dob.split('-')[2]);
        dob = new Date(yr, mm, dd, 0, 0);
        var d2 = new Date();
        var diff = d2.getTime() - dob.getTime();
        var age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));

        // var age = parseInt(new Date().getFullYear())-dob.getTime();
        $('#lbl_age').text('Age:' + age);
    }

    formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('-');
    }
    async setStudentHistory(history) {
        $('#inp_prestate').val(!history.stateId ? 'State' : history.stateId);
        await this.getPrevCityList();
        $('#inp_preschool').val(history.school);
        $('#inp_preclass').val(history.clsId);

        $('#inp_precity').val(!history.cityId ? 'City' : history.cityId);
        $('#inp_result').val(history.result);
        $('#inp_board').val(history.board);
    }
    setMedicalHistory(medical) {
        $('#inp_height').val(medical.height);
        $('#inp_weight').val(medical.weight);
        $('#inp_bloodgroup').val(medical.bloodGroup);
        $('#inp_disease').val(medical.diseases);
        $('#inp_eyes').val(medical.eyes);
        $('#inp_ears').val(medical.ears);
        $('#inp_allergicto').val(medical.allergicTo);
    }
    async setFatherDtls(father) {
        $('#inp_fstate').val(!father.stateId ? 'State' : father.stateId);
        await this.getFatherCityList();
        $('#inp_fathername').val(father.name);

        $('#inp_fphone').val(father.phoneNo);
        $('#inp_faddress1').val(father.address1);
        $('#inp_faddress2').val(father.address2);


        $('#inp_fcity').val(!father.cityId ? 'City' : father.cityId);
        $('#inp_fpin').val(father.pin);
        $('#inp_femail').val(father.eMail);
    }
    async setMotherDtls(mother) {
        $('#inp_mstate').val(!mother.stateId ? 'State' : mother.stateId);
        await this.getMotherCityList();
        $('#inp_mothername').val(mother.name);
        $('#inp_mphone').val(mother.phoneNo);
        $('#inp_maddress1').val(mother.address1);
        $('#inp_maddress2').val(mother.address2);
        $('#inp_mcity').val(!mother.cityId ? 'City' : mother.cityId);
        $('#inp_mpin').val(mother.pin);
        $('#inp_memail').val(mother.eMail);
    }
    async setGuardianDtls(guardian) {
        $('#inp_gstate').val(!guardian.stateId ? 'State' : guardian.stateId);
        await this.getGuardianCityList();
        $('#inp_guardianname').val(guardian.name);
        $('#inp_gphone').val(guardian.phoneNo);
        $('#inp_gaddress1').val(guardian.address1);
        $('#inp_gaddress2').val(guardian.address2);
        $('#inp_gcity').val(!guardian.cityId ? 'City' : guardian.cityId);
        $('#inp_gpin').val(guardian.pin);
        $('#inp_gemail').val(guardian.eMail);
    }
    fillGenderCmb() {
        $('#inp_gender').empty();
        $('#inp_gender').prepend($("<option> </option>").val('Gender').html('Gender'));
        for (var i = 0; i < lstGender.length; i++) {
            var genderId = lstGender[i].genderId;
            var gender = lstGender[i].gender;
            $('#inp_gender').append($("<option> </option>").val(genderId).html(gender));
        }
    }
    getMasterData = () => {
        this.getMstClassList(schoolId);
        this.setState({ loader: 'block', opacity: 0.5 });
        axios({
            url: httpURL + '/sms/mst/state/list',
            method: "POST",
            data: queryString.stringify({ 'schoolId': schoolId, screenId }),
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                this.setState({
                    lstState: response.data.lstState,
                })
                lstState = this.state.lstState;
                // // console.log(lstState);
                this.fillStateCmb();
                this.fillGenderCmb();
                this.setSiblingGenderCmb();
                var studentId = localStorage.getItem("studentId");
                this.setState({ 'studentId': studentId });
                if (parseInt(studentId) !== 0) {
                    // $('#StudentHistoryForm input,#StudentHistoryForm select,#StudentHistoryForm button').css({ cursor: 'pointer' });
                    // $('#medicalHistoryForm input,#medicalHistoryForm select,#medicalHistoryForm button').css({ cursor: 'pointer' });
                    // $('.siblingTableDiv input, #addSibling input').css({ cursor: 'pointer' });
                    // $('#parentsForm input,#parentsForm select,#parentsForm button').css({ cursor: 'pointer' });
                    $('#addSibling').css({ opacity: '1' });

                    $('#StudentHistoryForm *,#parentsForm *,.siblingTableDiv *,#medicalHistoryForm *').removeAttr('disabled');
                    this.getStudentsData(studentId);
                    this.getParentsData(studentId);
                    this.getSiblingsList(studentId);
                } else {
                    $('#StudentHistoryForm input,#StudentHistoryForm select,#StudentHistoryForm button').css({ cursor: 'not-allowed' });
                    $('#medicalHistoryForm input,#medicalHistoryForm select,#medicalHistoryForm button').css({ cursor: 'not-allowed' });
                    $('.siblingTableDiv input, #addSibling').css({ cursor: 'not-allowed' });
                    $('#parentsForm input,#parentsForm select,#parentsForm button').css({ cursor: 'not-allowed' });
                    $('#addSibling').css({ opacity: '.65' });
                    $('#addSibling').click(function (e) {
                        return false;
                    });
                    $('#StudentHistoryForm *,#parentsForm *,.siblingTableDiv *,#medicalHistoryForm *').attr('disabled', 'true');
                }
            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not Logged In') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });

        // axios({
        //     url: httpURL + '/sms/mst/city/list',
        //     method: "POST",
        //     data:  queryString.stringify({ 'schoolId': schoolId,screenId}) ,
        //     headers: { 'tokenid': this.state.tokenid },
        //     withCredentials: true,
        // }).then((response) => {
        //     if (response.data.SvcStatus === 'Success') {
        //         this.setState({
        //             lstCity:response.data.lstCity,
        //         })
        //         lstCity = this.state.lstCity;

        // // console.log(lstCity);
        //         this.fillCityCmb();
        //         this.fillFCityCmb();
        //         this.fillMCityCmb();
        //         this.fillGCityCmb();
        //         this.fillPreCityCmb();
        //     }
        //     else {
        //         toast.error(response.data.SvcMsg);
        //         if (response.data.SvcMsg == 'You are not Logged In') {
        //             setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
        //             return false;
        //         }
        //         else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
        //             setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
        //             return false;
        //         }
        //     }
        //     this.setState({ loader: 'none', opacity: 1 });
        // }).catch((error) => {
        //     toast.error("Service failed " + error);
        // });  

    }
    fillStateCmb() {

        this.fillSStateCmb();
        this.fillFStateCmb();
        this.fillMStateCmb();
        this.fillGStateCmb();
        this.fillPreStateCmb();

    }
    fillSStateCmb() {
        $('#inp_state').empty();
        $('#inp_state').prepend($("<option> </option>").val('State').html('State'));
        for (var i = 0; i < lstState.length; i++) {
            var stateId = lstState[i].stateId;
            var state = lstState[i].state;
            $('#inp_state').append($("<option> </option>").val(stateId).html(state));
        }
    }
    fillFStateCmb() {
        $('#inp_fstate').empty();
        $('#inp_fstate').prepend($("<option> </option>").val('State').html('State'));
        for (var i = 0; i < lstState.length; i++) {
            var stateId = lstState[i].stateId;
            var state = lstState[i].state;
            $('#inp_fstate').append($("<option> </option>").val(stateId).html(state));
        }
    }
    fillMStateCmb() {
        $('#inp_mstate').empty();
        $('#inp_mstate').prepend($("<option> </option>").val('State').html('State'));
        for (var i = 0; i < lstState.length; i++) {
            var stateId = lstState[i].stateId;
            var state = lstState[i].state;
            $('#inp_mstate').append($("<option> </option>").val(stateId).html(state));
        }
    }
    fillGStateCmb() {
        $('#inp_gstate').empty();
        $('#inp_gstate').prepend($("<option> </option>").val('State').html('State'));
        for (var i = 0; i < lstState.length; i++) {
            var stateId = lstState[i].stateId;
            var state = lstState[i].state;
            $('#inp_gstate').append($("<option> </option>").val(stateId).html(state));
        }
    }
    fillPreStateCmb() {
        $('#inp_prestate').empty();
        $('#inp_prestate').prepend($("<option> </option>").val('State').html('State'));
        for (var i = 0; i < lstState.length; i++) {
            var stateId = lstState[i].stateId;
            var state = lstState[i].state;
            $('#inp_prestate').append($("<option> </option>").val(stateId).html(state));
        }
    }
    getMstClassList = (schoolId) => {

        axios({
            url: httpURL + '/sms/mst/class/list',
            method: "POST",
            data: queryString.stringify({ 'schoolId': schoolId, screenId }),
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                this.setState({
                    lstClass: response.data.lstClass,
                })
                lstClass = this.state.lstClass;

                // // console.log(lstClass);
                this.fillClassCmb();
                this.fillPreClassCmb();
                this.fillSibClassCmb();
            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not Logged In') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }

    getSiblingsList = (studentId) => {
        var schoolStudent = {
            'schoolId': schoolId,
            'studentId': parseInt(studentId)
        };
        axios({
            url: httpURLRegs + '/sms/student/list_siblings',
            method: "POST",
            data: queryString.stringify({ 'schoolStudent': JSON.stringify(schoolStudent), screenId }),
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                this.setState({
                    lstSiblings: response.data.lstSiblings,
                })
                lstSiblings = this.state.lstSiblings;
                this.setSiblingsTable(lstSiblings);
            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not Logged In') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });


    }
    handleResize = () => {
        var width = ($('.tableLayout1').parent().width()) - 18;
        this.setState({ width: width });

    }
    fillClassCmb() {
        $('#inp_class').empty();
        $('#inp_class').prepend($("<option> </option>").val('Class').html('Class'));
        for (var i = 0; i < lstClass.length; i++) {
            var classId = lstClass[i].classId;
            var classNm = lstClass[i].className;
            $('#inp_class').append($("<option> </option>").val(classId).html(classNm));
        }
    }
    fillPreClassCmb() {
        $('#inp_preclass').empty();
        $('#inp_preclass').prepend($("<option> </option>").val('Class').html('Class'));
        for (var i = 0; i < lstClass.length; i++) {
            var classId = lstClass[i].classId;
            var classNm = lstClass[i].className;
            $('#inp_preclass').append($("<option> </option>").val(classId).html(classNm));
        }
    }
    fillSibClassCmb() {
        $('#inp_sclass').empty();
        $('#inp_sclass').prepend($("<option> </option>").val('Class').html('Class'));
        for (var i = 0; i < lstClass.length; i++) {
            var classId = lstClass[i].classId;
            var classNm = lstClass[i].className;
            $('#inp_sclass').append($("<option> </option>").val(classId).html(classNm));
        }
    }


    addTD(row, val, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var text = document.createTextNode(val);
        cell.append(text);
        row.append(cell);
    }


    highlightRow(table, id) {
        $('#' + table + ' tr').each(function () {
            var studentId = $(this).find(COL_STUDENTID).text();
            if (studentId === id) {
                $(this).addClass('selected').siblings().removeClass('selected');
            }
        });
    }

    setSiblingPopup = (event) => {
        $(event.target).closest('tr').click();
        this.setState({
            selSibling: $('#siblingTableBody tr.selected').find("td:eq(0)").text()
        });

        var selSibling = this.state.selSibling;
        // // console.log("selSibling: "+selSibling);
        //this.setSiblingSlider();
        this.setSiblingData(selSibling);

        this.openSiblingPopup();
    }

    // setSiblingSlider = () => {

    //     $('#inp_sgender').val("");

    // }

    setSiblingGenderCmb() {
        $('#inp_sgender').empty();
        $('#inp_sgender').prepend($("<option> </option>").val('Gender').html('Gender'));
        for (var i = 0; i < lstGender.length; i++) {
            var genderId = lstGender[i].genderId;
            var gender = lstGender[i].gender;
            $('#inp_sgender').append($("<option> </option>").val(genderId).html(gender));
        }
    }

    setSiblingData(pSiblingId) {
        var siblingData = this.getSiblingData(pSiblingId);
        // // console.log(siblingData);
        $('#inp_sibling').val(siblingData.sibling);
        $('#inp_sage').val(siblingData.age);
        $('#inp_sgender').val(siblingData.gender);
        $('#inp_sclass').val(siblingData.clsId);
        $('#inp_sschool').val(siblingData.school);

    }

    getSiblingData(pSiblingId) {
        for (var i = 0; i < lstSiblings.length; i++) {
            if (parseInt(pSiblingId) === lstSiblings[i].siblingId)
                return lstSiblings[i];
        }
    }

    openSiblingPopup = () => {
        $('.cd-panel').addClass('is-visible');
        $('#siblingSlider').show();
        $('#siblingSlider').siblings('#siblingSlider').hide();
        $('.mainContainer').addClass('addOpacity');
        $('.siblingPopup').css("display", 'block');
    }


    onClickAddSiblingIcon = () => {
        // this.setSiblingSlider();
        this.openSiblingPopup();
        $('#siblingForm').trigger('reset');
        $('body').addClass('showPopup');
    }

    hideSiblingSlider(e) {
        e.preventDefault();
        $('.cd-panel').removeClass('is-visible');
        $('.mainContainer').removeClass('addOpacity');
        $('.siblingPopup').hide();
        $('body').removeClass('showPopup');
    }
    getCityList = async () => {
        var selState = $('#inp_state').val();
        await axios({
            url: httpURL + '/sms/mst/city/list',
            method: "POST",
            data: queryString.stringify({ 'schoolId': schoolId, 'stateId': selState, screenId }),
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                this.setState({
                    lstCity: response.data.lstCity,
                })
                lstCity = this.state.lstCity;

                // // console.log(lstCity);
                this.fillCityCmb();

            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not Logged In') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }

    fillStateCityCmb = async (stateId) => {
        await axios({
            url: httpURL + '/sms/mst/city/list',
            method: "POST",
            data: queryString.stringify({ 'schoolId': schoolId, 'stateId': stateId, screenId }),
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                this.setState({
                    lstCity: response.data.lstCity,
                })
                lstCity = this.state.lstCity;

                // // console.log(lstCity);
                this.fillCityCmb();

            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not Logged In') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }
    getPrevCityList = async () => {
        var selState = $('#inp_prestate').val();
        await axios({
            url: httpURL + '/sms/mst/city/list',
            method: "POST",
            data: queryString.stringify({ 'schoolId': schoolId, 'stateId': selState, screenId }),
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                this.setState({
                    lstCity: response.data.lstCity,
                })
                lstCity = this.state.lstCity;

                // // console.log(lstCity);
                this.fillPreCityCmb();

            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not Logged In') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }
    getFatherCityList = async () => {
        var selState = $('#inp_fstate').val();
        await axios({
            url: httpURL + '/sms/mst/city/list',
            method: "POST",
            data: queryString.stringify({ 'schoolId': schoolId, 'stateId': selState, screenId }),
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                this.setState({
                    lstCity: response.data.lstCity,
                })
                lstCity = this.state.lstCity;

                // // console.log(lstCity);
                this.fillFCityCmb();

            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not Logged In') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }

    getMotherCityList = async () => {
        var selState = $('#inp_mstate').val();
        await axios({
            url: httpURL + '/sms/mst/city/list',
            method: "POST",
            data: queryString.stringify({ 'schoolId': schoolId, 'stateId': selState, screenId }),
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                this.setState({
                    lstCity: response.data.lstCity,
                })
                lstCity = this.state.lstCity;

                // // console.log(lstCity);
                this.fillMCityCmb();

            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not Logged In') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }

    getGuardianCityList = async () => {
        var selState = $('#inp_gstate').val();
        await axios({
            url: httpURL + '/sms/mst/city/list',
            method: "POST",
            data: queryString.stringify({ 'schoolId': schoolId, 'stateId': selState, screenId }),
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                this.setState({
                    lstCity: response.data.lstCity,
                })
                lstCity = this.state.lstCity;

                // // console.log(lstCity);
                this.fillGCityCmb();

            }
            else {
                toast.error(response.data.SvcMsg);
                if (response.data.SvcMsg == 'You are not Logged In') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
                else if (response.data.SvcMsg == 'Server Timed Out, Login Again') {
                    setTimeout(function () { window.location.href = ('/') }.bind(this), 2000);
                    return false;
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }
    fillCityCmb() {
        // // console.log('fillCityCmb() called..');
        var stateId = parseInt($('#inp_state').find("option:selected").val());
        $('#inp_city').empty();
        $('#inp_city').prepend($("<option> </option>").val('City').html('City'));

        for (var i = 0; i < lstCity.length; i++) {
            if (stateId == lstCity[i].stateId) {
                var cityId = lstCity[i].cityId;
                var city = lstCity[i].city;
                $('#inp_city').append($("<option> </option>").val(cityId).html(city));
            }
        }
    }

    fillFCityCmb() {
        // // console.log('fillFCityCmb() called');
        var stateId = $('#inp_fstate').find("option:selected").val();
        $('#inp_fcity').empty();
        $('#inp_fcity').prepend($("<option> </option>").val('City').html('City'));
        for (var i = 0; i < lstCity.length; i++) {
            if (stateId == lstCity[i].stateId) {
                var cityId = lstCity[i].cityId;
                var city = lstCity[i].city;
                $('#inp_fcity').append($("<option> </option>").val(cityId).html(city));
            }
        }
    }
    fillMCityCmb() {
        // // console.log("fillMCityCmb() called..");
        var stateId = $('#inp_mstate').find("option:selected").val();
        $('#inp_mcity').empty();
        $('#inp_mcity').prepend($("<option> </option>").val('City').html('City'));
        for (var i = 0; i < lstCity.length; i++) {
            if (stateId == lstCity[i].stateId) {
                var cityId = lstCity[i].cityId;
                var city = lstCity[i].city;
                $('#inp_mcity').append($("<option> </option>").val(cityId).html(city));
            }
        }
    }
    fillGCityCmb() {
        // // console.log("fillGCityCmb() called..");
        var stateId = $('#inp_gstate').find("option:selected").val();
        $('#inp_gcity').empty();
        $('#inp_gcity').prepend($("<option> </option>").val('City').html('City'));
        for (var i = 0; i < lstCity.length; i++) {
            if (stateId == lstCity[i].stateId) {
                var cityId = lstCity[i].cityId;
                var city = lstCity[i].city;
                $('#inp_gcity').append($("<option> </option>").val(cityId).html(city));
            }
        }
    }
    fillPreCityCmb() {
        // // console.log("fillPreCityCmb() called..");
        var stateId = $('#inp_prestate').find("option:selected").val();
        $('#inp_precity').empty();
        $('#inp_precity').prepend($("<option> </option>").val('City').html('City'));
        for (var i = 0; i < lstCity.length; i++) {
            if (stateId == lstCity[i].stateId) {
                var cityId = lstCity[i].cityId;
                var city = lstCity[i].city;
                $('#inp_precity').append($("<option> </option>").val(cityId).html(city));
            }
        }
    }

    maintainSibling = () => {
        // // console.log("this.state.studentId = "+this.state.studentId);
        var siblingId = parseInt($('#siblingTableBody tr.selected').find('td:eq(0)').text());
        // // console.log("siblingId:"+siblingId);
        // // console.log(typeof siblingId);
        if (siblingId == 0 || isNaN(siblingId)) {
            this.addSibling();
        } else {
            this.editSibling();
        }

    }

    addSibling = () => {
        var sibling = {};
        var siblingId = 0;
        var sibling = $('#inp_sibling').val();
        var sgender = $('#inp_sgender').find('option:selected').val();
        var clsId = parseInt($('#inp_sclass').find('option:selected').val());
        var cls = $('#inp_sclass').find('option:selected').text();
        var sage = parseInt($('#inp_sage').val());
        var sschool = $('#inp_sschool').val();


        var studentSibling = {
            'studentId': this.state.studentId,
            'siblingId': siblingId,
            'sibling': sibling,
            'age': sage,
            'gender': sgender,
            'clsId': clsId,
            'cls': cls,
            'school': sschool,
        };

        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURLRegs + '/sms/student/save_sibling',
            queryString.stringify({ 'studentSibling': JSON.stringify(studentSibling), screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getSiblingsList(this.state.studentId);
                $('.cd-panel').removeClass('is-visible');
                $('.mainContainer').removeClass('addOpacity');
                $('.siblingPopup').hide();
                $('body').removeClass('showPopup');

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            this.setState({ loader: 'none', opacity: 1 });
            toast.error('Service failed: ' + e);
        });

    }

    editSibling = () => {
        var sibling = {};
        let studentId = this.state.studentId;
        var siblingId = parseInt($('#siblingTableBody tr.selected').find('td:eq(0)').text());
        var sibling = $('#inp_sibling').val();
        var sgender = $('#inp_sgender').find('option:selected').val();
        var clsId = parseInt($('#inp_sclass').find('option:selected').val());
        var cls = $('#inp_sclass').find('option:selected').text();
        var sage = parseInt($('#inp_sage').val());
        var sschool = $('#inp_sschool').val();

        var studentSibling = {
            'siblingId': siblingId,
            'sibling': sibling,
            'age': sage,
            'gender': sgender,
            'clsId': clsId,
            'cls': cls,
            'school': sschool,
        };
        // // console.log(studentSibling);
        axios.post(httpURLRegs + '/sms/student/save_sibling',
            queryString.stringify({ 'studentSibling': JSON.stringify(studentSibling), screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.setState({ loader: 'block', opacity: 0.5 });
                this.getSiblingsList(this.state.studentId);
                $('.cd-panel').removeClass('is-visible');
                $('.mainContainer').removeClass('addOpacity');
                $('.siblingPopup').hide();

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });

    }

    editStudents = () => {
        var student = {};
        var studentId = this.state.studentId;
        var student = $('#inp_student').val();
        var dob = $('#inp_dob').val();
        var gender = $('#inp_gender').find('option:selected').val();
        var enrollmentNo = "";
        var age = $('#lbl_age').text();
        age = parseInt(age.substring(4, age.length));
        var blocked = $('#inp_blocked').is(':checked');
        var address1 = !$('#inp_address1').val() ? null : $('#inp_address1').val();
        var address2 = !$('#inp_address2').val() ? null : $('#inp_address2').val();
        var stateId = parseInt($('#inp_state').find('option:selected').val());
        var cityId = parseInt($('#inp_city').find('option:selected').val());
        var pin = $('#inp_pin').val();
        var enquiry = $('#enquiry').val();


        student = {
            studentId,
            student,
            dob,
            age,
            gender,
            address1,
            address2,
            stateId,
            cityId,
            pin,
            blocked,
        }



        this.setState({ loader: 'none', opacity: 1 });
        if (student === '') {
            toast.warning('Please fill student name');
            document.getElementById('inp_student').focus();
            return false;
        }

        if (student.length > MAX_STUDENT_LENGTH) {
            toast.warning('Student length must not exceed ' + MAX_STUDENT_LENGTH + ' characters');
            document.getElementById('inp_student').focus();
            return false;
        }
        axios.post(httpURLRegs + '/sms/student/save',
            queryString.stringify({ 'student': JSON.stringify(student), screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.setState({ loader: 'block', opacity: 0.5 });
                this.getStudentsData(studentId);

            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });
    }


    maintainStudentHistory = () => {
        // // console.log("this.state.studentId = "+this.state.studentId);
        this.editStudentHistory();
    }
    editStudentHistory = () => {
        var school = $('#inp_preschool').val();
        var clsId = parseInt($('#inp_preclass').find('option:selected').val());
        var cls = $('#inp_preclass').find('option:selected').text();
        var result = !$('#inp_result').val() ? null : $('#inp_result').val();
        var state = $('#inp_prestate').find('option:selected').text();
        var city = $('#inp_precity').find('option:selected').text();
        var stateId = parseInt($('#inp_prestate').find('option:selected').val());
        var cityId = parseInt($('#inp_precity').find('option:selected').val());
        var board = $('#inp_board').val();

        // var studentHistory={};
        var studentHistory = {
            'studentId': this.state.studentId,
            'schoolId': schoolId,
            'school': school,
            'clsId': clsId,
            'cls': cls,
            'result': result,
            'stateId': stateId,
            'state': state,
            'cityId': cityId,
            'city': city,
            'board': board,
        }



        // if(student === ''){
        // 	toast.warning('Please fill student name');
        // 	document.getElementById('inp_student').focus();
        // 	return false;
        // }

        // if(student.length > MAX_STUDENT_LENGTH){
        // 	toast.warning('Student length must not exceed '+ MAX_STUDENT_LENGTH + ' characters');
        // 	document.getElementById('inp_student').focus();
        // 	return false;
        // }

        this.setState({ loader: 'block', opacity: 0.5 });

        axios.post(httpURLRegs + '/sms/student/save_history',
            queryString.stringify({ 'studentHistory': JSON.stringify(studentHistory), screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.setState({ loader: 'none', opacity: 1 });
                this.getStudentsData(this.state.studentId);
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });

    }
    maintainParentsInfo = () => {
        // // console.log("this.state.studentId = "+this.state.studentId);
        this.editParentsData();
    }

    editParentsData = () => {
        var father = $('#inp_fathername').val();
        var fPhoneNo = $('#inp_fphone').val();
        var fAddress1 = !$('#inp_faddress1').val() ? null : $('#inp_faddress1').val();
        var fAddress2 = !$('#inp_faddress2').val() ? null : $('#inp_faddress2').val();
        var fState = $('#inp_fstate').find('option:selected').text();
        var fCity = $('#inp_fcity').find('option:selected').text();
        var fStateId = parseInt($('#inp_fstate').find('option:selected').val());
        var fCityId = parseInt($('#inp_fcity').find('option:selected').val());
        var fPin = $('#inp_fpin').val();
        var fEmail = $('#inp_femail').val();

        var mother = $('#inp_mothername').val();
        var mPhoneNo = $('#inp_mphone').val();
        var mAddress1 = !$('#inp_maddress1').val() ? null : $('#inp_maddress1').val();
        var mAddress2 = !$('#inp_maddress2').val() ? null : $('#inp_maddress2').val();
        var mState = $('#inp_mstate').find('option:selected').text();
        var mCity = $('#inp_mcity').find('option:selected').text();
        var mStateId = parseInt($('#inp_mstate').find('option:selected').val());
        var mCityId = parseInt($('#inp_mcity').find('option:selected').val());
        var mPin = $('#inp_mpin').val();
        var mEmail = $('#inp_memail').val();

        var guardian = $('#inp_guardianname').val();
        var gPhoneNo = $('#inp_gphone').val();
        var gAddress1 = !$('#inp_gaddress1').val() ? null : $('#inp_gaddress1').val();
        var gAddress2 = !$('#inp_gaddress2').val() ? null : $('#inp_gaddress2').val();
        var gState = $('#inp_gstate').find('option:selected').text();
        var gCity = $('#inp_gcity').find('option:selected').text();
        var gStateId = parseInt($('#inp_gstate').find('option:selected').val());
        var gCityId = parseInt($('#inp_gcity').find('option:selected').val());
        var gPin = $('#inp_gpin').val();
        var gEmail = $('#inp_gemail').val();

        var fatherObj = {
            'name': father,
            'phoneNo': fPhoneNo,
            'address1': fAddress1,
            'address2': fAddress2,
            'state': fState,
            'city': fCity,
            'stateId': fStateId,
            'cityId': fCityId,
            'pin': fPin,
            'eMail': fEmail
        };
        var motherObj = {
            'name': mother,
            'phoneNo': mPhoneNo,
            'address1': mAddress1,
            'address2': mAddress2,
            'state': mState,
            'city': mCity,
            'stateId': mStateId,
            'cityId': mCityId,
            'pin': mPin,
            'eMail': mEmail
        };
        var guardianObj = {
            'name': guardian,
            'phoneNo': gPhoneNo,
            'address1': gAddress1,
            'address2': gAddress2,
            'state': gState,
            'city': gCity,
            'stateId': gStateId,
            'cityId': gCityId,
            'pin': gPin,
            'eMail': gEmail

        };
        var parentsObj = {
            'studentId': this.state.studentId,
            'schoolId': schoolId,
            'father': fatherObj,
            'mother': motherObj,
            'guardian': guardianObj
        }



        // if(student === ''){
        // 	toast.warning('Please fill student name');
        // 	document.getElementById('inp_student').focus();
        // 	return false;
        // }

        // if(student.length > MAX_STUDENT_LENGTH){
        // 	toast.warning('Student length must not exceed '+ MAX_STUDENT_LENGTH + ' characters');
        // 	document.getElementById('inp_student').focus();
        // 	return false;
        // }

        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURLRegs + '/sms/student/save_parents',
            queryString.stringify({ 'studentParents': JSON.stringify(parentsObj), screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.setState({ loader: 'none', opacity: 1 });
                this.getParentsData(this.state.studentId);
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });

    }

    maintainMedicalHistory = () => {
        // // console.log("this.state.studentId = "+this.state.studentId);
        this.editMedicalHistory();
    }
    editMedicalHistory = () => {
        var height = $('#inp_height').val();
        var weight = $('#inp_weight').val();
        var bloodGroup = $('#inp_bloodgroup').val();
        var diseases = $('#inp_disease').val();
        var eyes = $('#inp_eyes').val();
        var ears = $('#inp_ears').val();
        var allergicTo = $('#inp_allergicto').val();


        var studentMedical = {
            'studentId': this.state.studentId,
            'schoolId': schoolId,
            'height': height,
            'weight': weight,
            'bloodGroup': bloodGroup,
            'diseases': diseases,
            'eyes': eyes,
            'ears': ears,
            'allergicTo': allergicTo,

        }


        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURLRegs + '/sms/student/save_medical',
            queryString.stringify({ 'studentMedical': JSON.stringify(studentMedical), screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.setState({ loader: 'none', opacity: 1 });
                this.getStudentsData(this.state.studentId);
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });

    }
    addProofs = () => {
        $('body').addClass('showPopup');
    }

    cancelPopup = () => {
        $('body').removeClass('showPopup');
    }

    fillDocTypeCmb() {
        $('#docTypeCmb').empty();
        for (var i = 0; i < lstDocType.length; i++) {
            var docType = lstDocType[i].docType;
            var docName = lstDocType[i].docName;
            $('#docTypeCmb').append($("<option> </option>").val(docType).html(docName));
        }
    }


    uploadDocs = (inputId) => {
        $("#" + inputId).on('change', function (event) {
            if (!event.target.files) return;
            var files = event.target.files; //FileList object
            docFile = files[0];
        });
    }

    addStudentDocs = () => {
        studentId = this.state.studentId;
        var formData = new FormData();
        var docType = parseInt($('#docTypeCmb').find("option:selected").val());
        formData.append("docFile", docFile);
        formData.append("docType", docType);
        formData.append('studentId', studentId);
        formData.append('screenId', screenId);

        this.setState({ loader: 'block', opacity: 0.5 });
        axios({
            url: httpURLRegs + '/sms/student/save_docs',
            method: "POST",
            data: formData,
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true,
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.setState({ loader: 'none', opacity: 1 });
                this.getStudentDocs(studentId);
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });
    }

    uploadProfileImage = () => {
        studentId = this.state.studentId;

        $("#imageUpload").click();
        $("#imageUpload").on('change', function (event) {
            if (!event.target.files) return;
            var files = event.target.files; //FileList object
            var docFile = files[0];
            if (docFile) {
                $('#profileImage').attr('src',
                    window.URL.createObjectURL(docFile));


                var formData = new FormData();
                formData.append("profileImage", docFile);
                formData.append('studentId', studentId);
                formData.append('screenId', screenId);


                axios({
                    url: httpURLRegs + '/sms/student/save_profile',
                    method: "POST",
                    data: formData,
                    headers: { tokenid },
                    withCredentials: true,
                }).then((response) => {
                    if (response.data.SvcStatus === 'Success') {
                        toast.success(response.data.SvcMsg);
                    } else {
                        toast.error(response.data.SvcMsg);
                    }

                }).catch((e) => {
                    toast.error('Service failed: ' + e);
                });
            }
        });
    }


    SetDocumentsTable = () => {
        $('#studentDocsTableBody tr').remove();
        var table = document.getElementById('studentDocsTable');
        var tbody = document.getElementById('studentDocsTableBody');

        for (var i = 0; i < lstDocuments.length; i++) {
            var docId = lstDocuments[i].docId;
            var docTypeVal = lstDocuments[i].docTypeVal;
            var updatedOn = lstDocuments[i].updatedOn;
            var row = document.createElement('tr');
            row.id = docId;


            this.addTD(row, docId, 'no-display');
            this.addTD(row, docTypeVal, 'text-center');
            this.addTD(row, updatedOn, 'text-center');
            this.addTDPdf(row, 'glyphicon glyphicon-file', 'text-center');

            tbody.append(row);
        }
        table.append(tbody);
    }

    addTDPdf(row, val, classname) {
        var cell = document.createElement('td');
        cell.className = classname;
        var div = document.createElement('div');
        var span = document.createElement('span');
        var span1 = document.createElement('span');
        div.className = "tdActions"
        span.className = val;
        span1.className = "glyphicon glyphicon-trash"
        span.style.cursor = 'pointer';
        span.title = 'Documents';
        span1.style.cursor = 'pointer';
        span1.title = 'Delete';
        span.onclick = () => {
            $(row).addClass('selected').siblings().removeClass('selected');
            $('td').removeClass('selected');
            var docId = $('#studentDocsTableBody tr.selected').find('td:eq(0)').text();
            var docTypeVal = $('#studentDocsTableBody tr.selected').find('td:eq(1)').text();
            this.setState({ loader: 'block', opacity: 0.5 });
            axios({
                url: httpURLRegs + '/sms/student/view_doc?docId=' + docId,
                method: "GET",
                params: { screenId },
                headers: { 'tokenid': this.state.tokenid },
                responseType: 'arraybuffer',
                withCredentials: true
            }
            ).then((response) => {
                var temp = JSON.stringify(response);
                // // console.log(temp.length);
                if (temp.length > 550) {
                    toast.error("No Document File For :- " + docTypeVal);
                    if (response.data.SvcMsg === 'You are not logged in') {
                        setTimeout(function () { window.location.href = ('/') }, 2000);
                        return false;
                    }
                    else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                        setTimeout(function () { window.location.href = ('/') }, 2000);
                        return false;
                    } else {
                        toast.error(response.data.SvcMsg);

                    }
                } else {
                    var file = new Blob([response.data], { type: 'image/jpeg' });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                } this.setState({ loader: 'none', opacity: 1 });
            }).catch((e) => {
                toast.error('Service failed: ' + e);
            });
        };

        span1.onclick = (e) => {
            $(row).addClass('selected').siblings().removeClass('selected');
            this.getConfirmation();
        };
        div.append(span);
        div.append(span1);
        cell.append(div);
        row.append(cell);
    }

    getConfirmation = () => {
        var retVal = window.confirm("Do you want to Delete this Document ?");
        if (retVal === true) {
            this.deleteDocument();
            return true;
        } else {
            return false;
        }
    }

    deleteDocument = () => {
        var docId = $('#studentDocsTableBody tr.selected').find('td:eq(0)').text();
        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURLRegs + '/sms/student/delete_doc',
            queryString.stringify({ 'docId': docId, screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                toast.success(response.data.SvcMsg);
                this.getStudentDocs(studentId);
            } else {
                toast.error(response.data.SvcMsg);
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((e) => {
            toast.error('Service failed: ' + e);
        });
    }

    refreshDocument = () => {
        this.getStudentDocs(studentId);
    }
    refreshPersonalDetails = () => {
        this.getStudentsData(studentId);
    }
    refreshParents = () => {
        this.getParentsData(studentId);
    }
    refreshSibling = () => {
        this.getSiblingsList(studentId);
    }

    getWingId = (wingId) => {
        // // console.log("wingId" + wingId);
    }
    render() {
        httpURLRegs = lstModules['registration'].url + ":" + lstModules['registration'].port;
        httpURL = lstModules['main'].url + ":" + lstModules['main'].port;
        return (
            <div >
                <Header wingFromChild={this.getWingId} />
                <ToastContainer autoClose={3000} hideProgressBar />
                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: this.state.loader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>


                <div className="mainContainer studentsDtls" style={{ opacity: this.state.opacity }}>
                    <div className="container-fluid">
                        <div className="col-sm-12 col-centered">
                            <div className="col-sm-12 col-centered" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 10 }}>
                                <div className="col-sm-12 " >

                                    <form id="studentAddForm" className="col-sm-12 form-horizontal">
                                        <div className="col-sm-12 section1">
                                            <div className="">
                                                <label className="control-label col-sm-9">Personal Details</label>
                                                {/* <label className="control-label col-sm-2 pull-right" id="lbl_enrollment_no">10076803110</label> */}
                                            </div>
                                            <div className="refresh refreshButton">
                                                <span className="glyphicon glyphicon-refresh" title="Refresh" onClick={this.refreshPersonalDetails}></span>
                                            </div>
                                        </div>
                                        <div className="shadowShow col-sm-12 padding-remove" >
                                            <div className="col-sm-6" >
                                                <div className="form-group">
                                                    <label className="control-label col-sm-4">Name:</label>
                                                    <div className="col-sm-6">
                                                        <input type="text" className="form-control" placeholder="Name" id="inp_student" />
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="control-label col-sm-4">Date of birth:</label>
                                                    <div className="col-sm-5">
                                                        <DatePicker id="inp_dob"
                                                            className='form-control'
                                                            placeholderText="Date of birth"
                                                            dateFormat="dd-MM-yyyy"
                                                            dropdownMode="select"
                                                            selected={this.state.dob}
                                                            onChange={(date) => { this.setState({ dob: date }); this.calcAge(date) }}
                                                            autoComplete='off'
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            adjustDateOnChange
                                                        />
                                                    </div>
                                                    <label className="control-label col-sm-2" id="lbl_age">Age:</label>
                                                </div>

                                                <div className="form-group">
                                                    <label className="control-label col-sm-4">Gender:</label>
                                                    <div className="col-sm-6">
                                                        <select className="form-control" id="inp_gender" ></select>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="control-label col-sm-4">Enquiry No:</label>
                                                    <div className="col-sm-6">
                                                        <input type="text" className="form-control" id="enquiry" placeholder="Enquiry Number" />
                                                    </div>
                                                    {/* <label className="control-label col-sm-4">Date of Admission:</label>
                                                    <div className="col-sm-6">
                                                        <DatePicker id="inp_doa"
                                                            className='form-control'
                                                            placeholderText="Date of admission"
                                                            dateFormat="dd-MM-yyyy"
                                                            dropdownMode="select"
                                                            selected={this.state.doa}
                                                            onChange={(date) => { this.setState({ doa: date }); }}
                                                            autoComplete='off'
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            adjustDateOnChange
                                                        />
                                                    </div> */}
                                                </div>
                                                <div className="form-group">
                                                    <label className="control-label col-sm-4">Blocked:</label>
                                                    <div className="col-sm-6">
                                                        <input type="checkbox" className="form-control" id="inp_blocked" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6" >
                                                <div className="form-group">
                                                    <label className="control-label col-sm-4">Address 1:</label>
                                                    <div className="col-sm-6">
                                                        <input type="text" className="form-control" placeholder="Address 1" id="inp_address1" />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="control-label col-sm-4">Address 2:</label>
                                                    <div className="col-sm-6">
                                                        <input type="text" className="form-control" placeholder="Address 2" id="inp_address2" />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="control-label col-sm-4">State:</label>
                                                    <div className="col-sm-6">
                                                        <select className="form-control" id="inp_state" onChange={this.getCityList}></select>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="control-label col-sm-4">City:</label>
                                                    <div className="col-sm-6">
                                                        <select className="form-control" id="inp_city"></select>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="control-label col-sm-4">Pin:</label>
                                                    <div className="col-sm-6">
                                                        <input type="text" className="form-control" placeholder="Pin" id="inp_pin" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group" style={{ marginTop: 50 }}>
                                            <div className="col-sm-11 text-right">
                                                <button type="button" onClick={this.editStudents} className="btn btn-info">Save</button>
                                            </div>
                                        </div>

                                    </form>
                                    <div className="col-sm-12 padding-remove">
                                        <div className="col-sm-12 section1" style={{ top: '2px' }}>
                                            <div className="">
                                                <label className="control-label col-sm-3">Parents/Guardian</label></div>
                                            <div className="refresh refreshButton">
                                                <span className="glyphicon glyphicon-refresh" title="Refresh" onClick={this.refreshParents}></span>
                                            </div>
                                        </div>
                                        <form id="parentsForm" className="form-horizontal padding-remove">
                                            <div className="col-sm-4">
                                                <div className="col-sm-12 section1">
                                                    <div className="">
                                                        <label className="control-label col-sm-3">Father</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 padding-remove shadowShow" >
                                                    <div className="col-sm-12" >
                                                        <div className="form-group">
                                                            <label className="control-label col-sm-4">Name:</label>
                                                            <div className="col-sm-7">
                                                                <input type="text" className="form-control" placeholder="Father's Name" id="inp_fathername" />
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <label className="control-label col-sm-4">Phone No:</label>
                                                            <div className="col-sm-7">
                                                                <input type="text" className="form-control" placeholder="Phone No" id="inp_fphone" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="control-label col-sm-4">Address 1:</label>
                                                            <div className="col-sm-7">
                                                                <input type="text" className="form-control" placeholder="Address 1" id="inp_faddress1" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="control-label col-sm-4">Address 2:</label>
                                                            <div className="col-sm-7">
                                                                <input type="text" className="form-control" placeholder="Address 2" id="inp_faddress2" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="control-label col-sm-4">State:</label>
                                                            <div className="col-sm-7">
                                                                <select className="form-control" id="inp_fstate" onChange={this.getFatherCityList}></select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="control-label col-sm-4">City:</label>
                                                            <div className="col-sm-7">
                                                                <select className="form-control" id="inp_fcity"></select>
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <label className="control-label col-sm-4">Pin:</label>
                                                            <div className="col-sm-7">
                                                                <input type="text" className="form-control" placeholder="Pin" id="inp_fpin" />
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <label className="control-label col-sm-4">E-Mail Id:</label>
                                                            <div className="col-sm-7">
                                                                <input type="text" className="form-control" placeholder="Email" id="inp_femail" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-sm-4">
                                                <div className="col-sm-12 section1">
                                                    <div className="">
                                                        <label className="control-label col-sm-3">Mother</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 padding-remove shadowShow" >
                                                    <div className="col-sm-12" >
                                                        <div className="form-group">
                                                            <label className="control-label col-sm-4">Name:</label>
                                                            <div className="col-sm-7">
                                                                <input type="text" className="form-control" placeholder="Mother's Name" id="inp_mothername" />
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <label className="control-label col-sm-4">Phone No:</label>
                                                            <div className="col-sm-7">
                                                                <input type="text" className="form-control" placeholder="Phone No" id="inp_mphone" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="control-label col-sm-4">Address 1:</label>
                                                            <div className="col-sm-7">
                                                                <input type="text" className="form-control" placeholder="Address 1" id="inp_maddress1" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="control-label col-sm-4">Address 2:</label>
                                                            <div className="col-sm-7">
                                                                <input type="text" className="form-control" placeholder="Address 2" id="inp_maddress2" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="control-label col-sm-4">State:</label>
                                                            <div className="col-sm-7">
                                                                <select className="form-control" id="inp_mstate" onChange={this.getMotherCityList}></select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="control-label col-sm-4">City:</label>
                                                            <div className="col-sm-7">
                                                                <select className="form-control" id="inp_mcity"></select>
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <label className="control-label col-sm-4">Pin:</label>
                                                            <div className="col-sm-7">
                                                                <input type="text" className="form-control" placeholder="Pin" id="inp_mpin" />
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <label className="control-label col-sm-4">E-Mail Id:</label>
                                                            <div className="col-sm-7">
                                                                <input type="text" className="form-control" placeholder="Email" id="inp_memail" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-sm-4">
                                                <div className="col-sm-12 section1">
                                                    <div className="">
                                                        <label className="control-label col-sm-3">Guardian</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 padding-remove shadowShow" >
                                                    <div className="col-sm-12" >
                                                        <div className="form-group">
                                                            <label className="control-label col-sm-4">Name:</label>
                                                            <div className="col-sm-7">
                                                                <input type="text" className="form-control" placeholder="Guardian's Name" id="inp_guardianname" />
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <label className="control-label col-sm-4">Phone No:</label>
                                                            <div className="col-sm-7">
                                                                <input type="text" className="form-control" placeholder="Phone No" id="inp_gphone" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="control-label col-sm-4">Address 1:</label>
                                                            <div className="col-sm-7">
                                                                <input type="text" className="form-control" placeholder="Address 1" id="inp_gaddress1" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="control-label col-sm-4">Address 2:</label>
                                                            <div className="col-sm-7">
                                                                <input type="text" className="form-control" placeholder="Address 2" id="inp_gaddress2" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="control-label col-sm-4">State:</label>
                                                            <div className="col-sm-7">
                                                                <select className="form-control" id="inp_gstate" onChange={this.getGuardianCityList}></select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="control-label col-sm-4">City:</label>
                                                            <div className="col-sm-7">
                                                                <select className="form-control" id="inp_gcity"></select>
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <label className="control-label col-sm-4">Pin:</label>
                                                            <div className="col-sm-7">
                                                                <input type="text" className="form-control" placeholder="Pin" id="inp_gpin" />
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <label className="control-label col-sm-4">E-Mail Id:</label>
                                                            <div className="col-sm-7">
                                                                <input type="text" className="form-control" placeholder="Email" id="inp_gemail" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="form-group" style={{ marginTop: 50 }}>
                                                <div className="col-sm-11 text-right">
                                                    <button type="button" onClick={this.maintainParentsInfo} className="btn btn-info">Save</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                    <div className="col-sm-12 padding-remove">

                                        <form id="siblingAddForm" className="col-sm-12 form-horizontal">
                                            <div className="col-sm-12 section1" >
                                                <div className="">
                                                    <label className="control-label col-sm-3">Siblings</label>
                                                </div>
                                                <div className="refresh refreshButton">
                                                    <span className="glyphicon glyphicon-refresh" title="Refresh" onClick={this.refreshSibling}></span>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="col-sm-10 padding-remove" style={{ top: '10px' }}>
                                                    <table className="tableLayout1" style={{ width: this.state.width1 }}>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: '23%' }}>Name</th>
                                                                <th style={{ width: '12%' }}>Age</th>
                                                                <th style={{ width: '12%' }}>Gender</th>
                                                                <th style={{ width: '12%' }}>Class</th>
                                                                <th style={{ width: '25%' }}>School</th>
                                                                <th style={{ width: '16%' }}>Action</th>
                                                            </tr>
                                                        </thead>
                                                    </table>
                                                    <div className="siblingTableDiv col-sm-12 col-xs-11 padding-remove">
                                                        <table id="siblingTable" className="tableLayout">
                                                            <colgroup>
                                                                <col width="23%" />
                                                                <col width="12%" />
                                                                <col width="12%" />
                                                                <col width="12%" />
                                                                <col width="25%" />
                                                                <col width="16%" />
                                                            </colgroup>
                                                            <tbody id="siblingTableBody">
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2 col-xs-2">
                                                    <span id="addSibling" title="Add Sibling" onClick={this.onClickAddSiblingIcon} className="addIcon glyphicon glyphicon-plus-sign"></span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                    <div className="col-sm-12 padding-remove">
                                        <div className="col-sm-12 section1" >
                                            <div className="">
                                                <label className="control-label col-sm-3">History</label>
                                            </div>
                                            <div className="refresh refreshButton">
                                                <span className="glyphicon glyphicon-refresh" title="Refresh" onClick={this.refreshPersonalDetails}></span>
                                            </div>
                                        </div>
                                        <div className="col-sm-12" id="History">

                                            <div className="col-sm-6 padding-remove">
                                                <form id="StudentHistoryForm" className="form-horizontal padding-remove" >
                                                    <div className="col-sm-12 section1" style={{ marginLeft: '15px' }}>
                                                        <div className="">
                                                            <label className="control-label col-sm-3">Student</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 padding-remove" >
                                                        <div className="col-sm-12 padding-remove" >
                                                            <div className="form-group">
                                                                <label className="control-label col-sm-3">Previous School:</label>
                                                                <div className="col-sm-7">
                                                                    <input type="text" className="form-control" placeholder="Previous School" id="inp_preschool" />
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="control-label col-sm-3">Previous Class:</label>
                                                                <div className="col-sm-7">
                                                                    <select className="form-control" id="inp_preclass"></select>
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="control-label col-sm-3">Previous State:</label>
                                                                <div className="col-sm-7">
                                                                    <select className="form-control" id="inp_prestate" onChange={this.getPrevCityList}></select>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="control-label col-sm-3">Previous City:</label>
                                                                <div className="col-sm-7">
                                                                    <select className="form-control" id="inp_precity"></select>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="control-label col-sm-3">Result:</label>
                                                                <div className="col-sm-7">
                                                                    <input type="text" className="form-control" placeholder="Result" id="inp_result" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="control-label col-sm-3">Board:</label>
                                                                <div className="col-sm-7">
                                                                    <input type="text" className="form-control" placeholder="Board" id="inp_board" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group" style={{ marginTop: 50 }}>
                                                                <div className="col-sm-10 text-right">
                                                                    <button type="button" onClick={this.maintainStudentHistory} className="btn btn-info">Save</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>


                                            <div className="col-sm-6 padding-remove">
                                                <form id="medicalHistoryForm" className="form-horizontal padding-remove">
                                                    <div className="col-sm-12 section1" style={{ marginLeft: '15px' }}>
                                                        <div className="">
                                                            <label className="control-label col-sm-3">Medical</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 padding-remove" >
                                                        <div className="col-sm-12 padding-remove" >
                                                            <div className="form-group">
                                                                <label className="control-label col-sm-3">Height:</label>
                                                                <div className="col-sm-7">
                                                                    <input type="text" className="form-control" placeholder="Height(cms)" id="inp_height" />
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="control-label col-sm-3">Weight:</label>
                                                                <div className="col-sm-7">
                                                                    <input type="text" className="form-control" placeholder="Weight" id="inp_weight" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="control-label col-sm-3">Blood Group:</label>
                                                                <div className="col-sm-7">
                                                                    <input type="text" className="form-control" placeholder="Blood Group" id="inp_bloodgroup" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="control-label col-sm-3">Disease:</label>
                                                                <div className="col-sm-7">
                                                                    <input type="text" className="form-control" placeholder="Disease" id="inp_disease" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="control-label col-sm-3">Eyes:</label>
                                                                <div className="col-sm-7">
                                                                    <input type="text" className="form-control" placeholder="Eyes" id="inp_eyes" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="control-label col-sm-3">Ears:</label>
                                                                <div className="col-sm-7">
                                                                    <input type="text" className="form-control" placeholder="Ears" id="inp_ears" />
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="control-label col-sm-3">Allergic To:</label>
                                                                <div className="col-sm-7">
                                                                    <input type="text" className="form-control" placeholder="Allergic To" id="inp_allergicto" />
                                                                </div>
                                                                <div className="col-sm-1 text-right">
                                                                    <button type="button" onClick={this.maintainMedicalHistory} className="btn btn-info">Save</button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </form>




                                            </div>
                                        </div>


                                    </div>
                                    <div className="col-sm-12 padding-remove">
                                        <form id="docsAddForm" className="col-sm-12 form-horizontal">
                                            <div className="col-sm-12 section1" >
                                                <div className="">
                                                    <label className="control-label col-sm-3">Student Documents</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="col-sm-7 padding-remove" style={{ top: '10px' }}>
                                                    <div className="docRefresh refreshButton">
                                                        <span className="glyphicon glyphicon-refresh" title="Refresh" onClick={this.refreshDocument}></span>
                                                    </div>
                                                    <table className="tableLayout1" style={{ width: this.state.width1 }}>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ display: 'none' }}>Documnet Id</th>
                                                                <th style={{ width: '17%' }}>Document Type</th>
                                                                <th style={{ width: '17%' }}>Updated On</th>
                                                                <th style={{ width: '17%' }}>Document</th>
                                                            </tr>
                                                        </thead>
                                                    </table>
                                                    <div className="studentDocsTableDiv col-sm-12 col-xs-11 padding-remove" style={{ height: '150px', overflowY: 'scroll', border: '1px solid #ccc' }}>
                                                        <table id="studentDocsTable" className="tableLayout">
                                                            <colgroup>
                                                                <col width="17%" />
                                                                <col width="17%" />
                                                                <col width="17%" />
                                                            </colgroup>
                                                            <tbody id="studentDocsTableBody">
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="col-xs-1">

                                                </div>
                                                <div className="col-md-4 col-xs-8 padding-remove">
                                                    <h3 id="classHeader">Docs</h3>
                                                    <form id="studentDocsForm" className="form-horizontal formWrapper">
                                                        <div className="col-sm-12 padding-remove" >
                                                            <div className="col-sm-12" >
                                                                <div className="form-group">
                                                                    <label className="control-label col-sm-5">Document Type:</label>
                                                                    <div className="col-sm-7 padding-remove">
                                                                        <select className="form-control" id="docTypeCmb" onChange={this.changeDocType} ></select>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="control-label col-sm-5">Id Proof:</label>
                                                                    <div className="col-sm-7 padding-remove">
                                                                        <input type="file" className="form-control inpFile" onClick={this.uploadDocs('idProof')} placeholder="Id Proof" id="idProof" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group" style={{ marginTop: 50 }}>
                                                            <div className="col-sm-11 text-right userButtonWrap">
                                                                <button type="button" onClick={this.addStudentDocs} className="btn btn-info">Save</button>
                                                            </div>
                                                        </div>
                                                        <div className="profileWrap text-center">
                                                            <div id="profile-container">
                                                                <img id="profileImage" alt="Profile Picture" onClick={this.uploadProfileImage} src={this.state.src} />
                                                            </div>
                                                            <input id="imageUpload" type="file"
                                                                name="profile_photo" placeholder="Photo" />
                                                        </div>
                                                        <h5 className="text-center">Profile Photo</h5>
                                                    </form>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="siblingPopup" style={{ width: '33%', left: '77%', height: '60%' }}>
                    <header style={{ width: '90%' }}>
                        <h3 id="classHeader">Add/Edit Sibling</h3>
                        <a href="#0" id="hideSiblingSlider" className="cd-panel-close glyphicon glyphicon-remove" onClick={this.hideSiblingSlider}>Close</a>
                    </header>

                    <div style={{ width: '90%' }}>
                        <div className="cd-panel-content" style={{ padding: '20px 0% 20px' }}>
                            <div id="SiblingSlider" className="col-sm-12" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div style={{ width: '90%', paddingLeft: 20 }}>
                                    <form id="siblingForm" className="form-horizontal">
                                        <div className="col-sm-12 padding-remove" >
                                            <div className="col-sm-12" >
                                                <div className="form-group">
                                                    <label className="control-label col-sm-5">Name:</label>
                                                    <div className="col-sm-7 padding-remove">
                                                        <input type="text" className="form-control" placeholder="Name" id="inp_sibling" />
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="control-label col-sm-5" id="">Age:</label>
                                                    <div className="col-sm-7 padding-remove">
                                                        <input type="text" className="form-control" id="inp_sage" />
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="control-label col-sm-5">Gender:</label>
                                                    <div className="col-sm-7 padding-remove">
                                                        <select className="form-control" id="inp_sgender" ></select>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="control-label col-sm-5">Class:</label>
                                                    <div className="col-sm-7 padding-remove">
                                                        <select className="form-control" id="inp_sclass" ></select>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="control-label col-sm-5">School:</label>
                                                    <div className="col-sm-7 padding-remove">
                                                        <input type="text" className="form-control" id="inp_sschool" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="form-group" style={{ marginTop: 50 }}>
                                            <div className="col-sm-11 text-right pull-right">
                                                <button type="button" onClick={this.maintainSibling} className="btn btn-info">Save</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
export default studentRegisterDtls;