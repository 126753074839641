import React, { useState, useEffect } from "react";
import "common/css/master.css";
import "../css/transport.css";
import { toast, ToastContainer } from "react-toastify";
import { getChannelListSvc, sendCommunicationSvc } from "./channelPopupSvc.js";
var tmpLstChannelId = [];

const ChannelPopup = (props) => {
  const [loader, setLoader] = useState("none");
  const [lstChannelId, setLstChannelId] = useState([]);
  const [routeId, setRouteId] = useState(0);
  const [lstChannel, setLstChannel] = useState([]);
  const [flgAppNotification, setFlgAppNotification] = useState(false);
  const [flgEmail, setFlgEmail] = useState(false);
  const [flgIvr, setFlgIvr] = useState(false);
  const [flgSms, setFlgSms] = useState(false);
  const [flgWhatsapp, setFlgWhatsapp] = useState(false);

  useEffect(() => {
    // // console.log(props.routeId);
    setRouteId(props.routeId);
    getChannelList();
  }, [props.routeId]);

  function pushToLstChannelId(pChnlId) {
    tmpLstChannelId.push(pChnlId);
  }
  function removeFromLstChannelId(pChnlId) {
    // // console.log(tmpLstChannelId.indexOf(pChnlId));
    tmpLstChannelId.splice(tmpLstChannelId.indexOf(pChnlId), 1);
  }
  function getChannelList() {
    getChannelListSvc(cbChannelListResponse);
  }

  function cbChannelListResponse(data) {
    setLoader("none");
    if (data.SvcStatus === "Success") {
      setLstChannel(data.lstChannel);
      toast.success(data.SvcMsg);
    } else {
      toast.error(data.SvcMsg);
    }
  }
  /*   async function addChannelId(pChk, pChannelId) {
    if (pChk) {
      // // console.log("checked");
      if (!tmpLstChannelId.includes(pChannelId)) {
        // // console.log("not existing in list, need to push");
        pushToLstChannelId(pChannelId);
      }
    } else {
      // // console.log("unchecked");
      if (tmpLstChannelId.includes(pChannelId)) {
        // // console.log("it is there, need to remove");
        removeFromLstChannelId(pChannelId);
      }
    }
    await setLstChannelId(tmpLstChannelId)
}
 */
  function sendCommunication() {
    // // console.log("flgIvr = " + flgIvr);
    // // console.log("flgSms = " + flgSms);
    // // console.log("flgEmail = " + flgEmail);
    // // console.log("flgAppNotification = " + flgAppNotification);
    // // console.log("flgWhatsapp = " + flgWhatsapp);
    var ctr = 0;
    var lst = [];
    if (flgEmail) lst.push(10);
    if (flgWhatsapp) lst.push(20);
    if (flgSms) lst.push(30);
    if (flgAppNotification) lst.push(40);
    if (flgIvr) lst.push(50);
    // // console.log(lst);
    // // console.log(typeof lst);
    var formData = new FormData();
    formData.append("routeId", routeId);
    formData.append("lstChannelId", JSON.stringify(lst));
    sendCommunicationSvc(formData, cbSendCommunicationResponse);
  }

  function cbSendCommunicationResponse(data) {
    tmpLstChannelId = [];
    setLstChannelId([]);
    setLoader("none");
    var obj = data;
    if (obj.SvcStatus === "Success") {
      toast.success(data.SvcMsg);
      props.closePopup();
    } else if (obj.SvcStatus === "Failure") {
      if (obj.SvcMsg === "You are not logged in") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else if (obj.SvcMsg === "Your Session is expired, login again") {
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
        return false;
      } else {
        toast.error(obj.SvcMsg);
      }
    } else {
      toast.error("Service failed " + obj);
    }
  }

  return (
    <form
      className="form form-horizontal"
      style={{
        width: "100%",
        height: 300,
        margin: "0 auto",
        background: "#fff",
        border: "1px solid #ccc",
        borderRadius: 10,
      }}
    >
      <button
        type="button"
        className="btn btn-info pull-right"
        onClick={() => props.closePopup()}
      >
        <span
          id="close"
          title="Close"
          className="glyphicon glyphicon-remove"
        ></span>
      </button>
      <h6 className="text-center">Choose Channel</h6>
      <div className="col-sm-12" style={{ width: "100%" }}>
        <label className="control-label col-sm-2" style={{ width: "20%" }}>
          Channel
        </label>

        <div className="form-group col-sm-10">
          <div className="col-sm-12">
            {/* {lstChannel.map((item, idx) => {
              return (
                <div
                  key={"kc_" + item.channelId}
                  className="col-sm-5 multiButtonWrap padding-remove"
                >
                  <label className="control-label text_left">
                    {item.channel}
                  </label>
                  <div className="form-group">
                    <input
                      className="form-control"
                      key={"c_" + item.channelId}
                      type="checkbox"
                      defaultChecked={lstChannelId.includes(item.channelId)}
                      onChange={(e) =>
                        addChannelId(e.target.checked, item.channelId)
                      }
                    />
                  </div>
                </div>
              );
            })} */}
            <div className="col-sm-5 multiButtonWrap padding-remove">
              <label className="control-label">App</label>
              <div className="form-group">
                <input
                  className="form-control"
                  type="checkbox"
                  checked={flgAppNotification}
                  onChange={(e) => setFlgAppNotification(e.target.checked)}
                />
              </div>
            </div>
            <div className="col-sm-5 multiButtonWrap padding-remove">
              <label className="control-label">E-Mail</label>
              <div className="form-group">
                <input
                  className="form-control"
                  type="checkbox"
                  checked={flgEmail}
                  onChange={(e) => setFlgEmail(e.target.checked)}
                />
              </div>
            </div>
            <div className="col-sm-5 multiButtonWrap padding-remove">
              <label className="control-label">SMS</label>
              <div className="form-group">
                <input
                  className="form-control"
                  type="checkbox"
                  checked={flgSms}
                  onChange={(e) => setFlgSms(e.target.checked)}
                />
              </div>
            </div>
            <div className="col-sm-5 multiButtonWrap padding-remove">
              <label className="control-label">IVR</label>
              <div className="form-group">
                <input
                  className="form-control"
                  type="checkbox"
                  checked={flgIvr}
                  onChange={(e) => setFlgIvr(e.target.checked)}
                />
              </div>
            </div>
            <div className="col-sm-5 multiButtonWrap padding-remove">
              <label className="control-label">WhatsApp</label>
              <div className="form-group">
                <input
                  className="form-control"
                  type="checkbox"
                  checked={flgWhatsapp}
                  onChange={(e) => setFlgWhatsapp(e.target.checked)}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="form-group col-md-10"
          style={{
            marginLeft: "18%",
            paddingBottom: "1px",
          }}
        >
          <div
            className="col-sm-8 padding-remove"
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "flex-start",
            }}
          >
            {" "}
            <button
              type="button"
              className="btn btn-info pull-right"
              onClick={(e) => sendCommunication()}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ChannelPopup;
