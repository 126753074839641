import React, { useEffect, useState } from 'react';
import { Text, View, Image } from '@react-pdf/renderer';


import { styleFooter, fontStyles } from './styleBonafidePdf';

const BonafideFooter = (props) => {
    const { principalSign, teacherSign } = props;

    const [principalSignUrl, setPrincipalSignUrl] = useState("");
    const [teacherSignUrl, setTeacherSignUrl] = useState("");
    

    useEffect(() => {
        // // console.log("BonafideFooter called, principalSign", principalSign, ", teacherSign", teacherSign);
        let tempTeacherSignUrl = "https://www.sms.cooledsys.com:8280/sms/student/docs/view?imgPath=" + teacherSign;
        // // console.log("tempTeacherSignUrl", tempTeacherSignUrl);
        setTeacherSignUrl(tempTeacherSignUrl);
        setPrincipalSignUrl(principalSign);
    }, [principalSign, teacherSign]);

    return (
        <View style={styleFooter.block}>
            <View style={styleFooter.empty_row}>
                <View style={styleFooter.colSign}>
                    {
                        !!teacherSignUrl ?
                            <Image style={styleFooter.image_sign} src={teacherSignUrl} />
                            : null
                    }
                </View>
                <View style={styleFooter.colSign}>
                </View>
                <View style={styleFooter.colSign}>
                    {
                        !!principalSignUrl ?
                            <Image style={styleFooter.image_sign} src={principalSignUrl} />
                            : null
                    }
                </View>
            </View>
            <View style={styleFooter.row_labels}>
                <View style={styleFooter.colSignLabel}>
                    <Text style={[fontStyles.textCenterNormalInter, {}]}>CLASS TEACHER</Text>
                </View>
                <View style={styleFooter.colSignLabel}>
                </View>
                <View style={styleFooter.colSignLabel}>
                    <Text style={fontStyles.textCenterNormalInter}>PRINCIPAL</Text>
                </View>
            </View>
        </View>
    );
}

export default BonafideFooter;