import React, { useState, useEffect } from "react";
import '../css/StaffCtrl.css'
import { toast, ToastContainer } from "react-toastify";
import SliderType from "./SliderType";
import { getClassListSvc, getClassSectionListSvc, getSectionSubjectListSvc, saveClassSectionSvc } from "./StaffSvc";

const ClassSubjectForm = (props) => {
    // const [staffId, setStaffId] = useState(props.staffId)
    const [userId, setUserId] = useState(props.userId);
    const [lstClass, setLstClass] = useState([])
    const [lstClassSection, setLstClassSection] = useState([])
    const [lstSubject, setLstSubject] = useState([])
    const [clsId, setClsId] = useState(props.classId)
    const [sectionId, setSectionId] = useState(props.sectionId)
    const [lstSubjectId, setLstSubjectId] = useState([])
    const [loader, setLoader] = useState(props.loader)
    const [found, setFound] = useState([])
    const [subject, setSubject] = useState("")
    function getClassList() {
        setLoader("block")
        getClassListSvc(cbClassList)
    }
    function cbClassList(data) {
        if (data.SvcStatus === "Success") {
            setLstClass(data.lstClass)
            // // console.log(JSON.stringify(data.lstClass))
        } else {
            toast.error(data.SvcMsg)
        }
        setLoader('none')
    }
    function handleClsIdChange(param) {
        setClsId(param)
        getClassSectionListSvc(param, cbClassSectionList, sectionId)
    }
    function handleSectionIdChange(param) {
        // // console.log("section Id" + param)
        setSectionId(param)
        getSectionSubjectListSvc(props.userId, param, cbSectionSubjectList)
    }
    function cbClassSectionList(data, pSectionId) {
        if (data.SvcStatus === "Success") {
            setLstClassSection(data.lstClassSection)
            setSectionId(pSectionId)
        } else {
            toast.error(data.SvcMsg)
        }
        setLoader('none')
    }
    async function cbSectionSubjectList(data) {
        if (data.SvcStatus === 'Success') {
            await setLstSubject(data.lstSubject)
            await setFound(data.lstSubject)
            await setStateLstSubject(data.lstSubject);
            const tempLstSubjectId = data.lstSubject.filter(subject => subject.selected).map(subject => subject.subjectId);
            await setLstSubjectId(tempLstSubjectId);
        }
        else {
            toast.error(data.SvcMsg)
        }
        setLoader('none')
    }
    function setStateLstSubject(pLstSubjectId) {
        var tmpArr = [];
        for (var i = 0; i < pLstSubjectId.length; i++) {
            var obj = pLstSubjectId[i];
            if (obj.selected) {
                tmpArr.push(obj.subjectId)
            }
        }
        // // console.log(tmpArr);
        setLstSubjectId(tmpArr);
    }

    async function handleCBoxChange(subject_id, status) {
        // console.log("handleCBoxChange called, subject_id", subject_id, ", status", status);
        // console.log("lstSubjectId", lstSubjectId);
    
        // Make a copy of lstSubjectId to avoid direct mutation
        let lstTempArr = [...lstSubjectId];
    
        if (status && !lstSubjectId.includes(subject_id)) {
            // If the status is true and subject_id is not in lstSubjectId, add it
            lstTempArr.push(subject_id);
            await setLstSubjectId(lstTempArr);
            // console.log("checked " + lstTempArr);
    
            // Update found array to mark the subject as selected
            for (let i = 0; i < found.length; i++) {
                if (found[i].subjectId === subject_id) {
                    found[i].selected = true;
                    break; // Found the subject, no need to continue the loop
                }
            }
        } else {
            // If the status is false, remove the subject_id
            const index = lstTempArr.indexOf(subject_id);
            if (index > -1) {
                lstTempArr.splice(index, 1); // Correctly remove the subjectId at the found index
                await setLstSubjectId(lstTempArr);
                // console.log("unchecked" + lstTempArr);
            }
    
            // Update found array to mark the subject as unselected
            for (let i = 0; i < found.length; i++) {
                if (found[i].subjectId === subject_id) {
                    found[i].selected = false;
                    break; // Found the subject, no need to continue the loop
                }
            }
        }
    
        // console.log("lstSubjectId after update", lstSubjectId);
    }
    

    function saveClassSection() {
        setLoader("block")
        saveClassSectionSvc(userId, clsId, sectionId, lstSubjectId, cbSaveClassSection)
    }
    function cbSaveClassSection(data, pClassId, pSectionId) {
        setLoader('none')
        if (data.SvcStatus === "Success") {
            toast.success(data.SvcMsg)
            props.generateRandomNo()
            props.setClassSubjectTblKey(props.generateRandomNo());
            props.setSelClassId(pClassId)
            props.setSelSectionId(pSectionId)
            // props.closeSlider();
        } else {
            toast.error(data.SvcMsg)
        }
    }
    useEffect(() => {
        if (props.flgSliderType == SliderType.SLIDER_CLASS_SUBJECTS) {
            getClassList();
            getSectionSubjectListSvc(props.userId, props.sectionId, cbSectionSubjectList)
            getClassSectionListSvc(props.classId, cbClassSectionList, props.sectionId)
            setClsId(props.classId)
            setFound([])
            setLstSubjectId([]);
            //setSectionId(props.sectionId)
            // // console.log("SLider ClassID" + props.classId + "SLider section Id" + props.sectionId)
        }

    }, [props.flgSliderType, props.sectionId, props.classId, props.userId])
    const filterSubject = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = found.filter((user) => {
                return user.subject.toLowerCase().includes(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
            setFound(results);
            const tempLstSubjectId = results.filter(subject => subject.selected).map(subject => subject.subjectId);
            setLstSubjectId(tempLstSubjectId);
        } else {
            setFound(lstSubject);
            const tempLstSubjectId = lstSubject.filter(subject => subject.selected).map(subject => subject.subjectId);
            setLstSubjectId(tempLstSubjectId);
            // If the text field is empty, show all users
        }
        setSubject(keyword);
    };

    return (
        <form className='form form-horizontal' style={{ width: '100%', overflowX: 'hidden', margin: '0 auto', background: '#fff', border: '1px solid #ccc', borderRadius: 10 }}>
            <div className="col-sm-12" style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
                <ToastContainer autoClose={3000} hideProgressBar />

                <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                    <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
                </div>
                <div className='row' style={{ width: '100%' }}>
                    <div className="form-group col-md-12">
                        <label className="control-label col-sm-3">Class<span className="red-color">*</span></label>
                        <div className='col-sm-3 padding-remove'>
                            <select className="form-control" onChange={(e) => handleClsIdChange(e.target.value)} value={clsId}>
                                <option value={0}>Class</option>
                                {lstClass.map((obj, idx) => {
                                    return <option key={idx} value={obj.clsId}>{obj.cls}</option>
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                <div className='row' style={{ width: '100%' }}>
                    <div className="form-group col-md-12">
                        <label className="control-label col-sm-3">Section<span className="red-color">*</span></label>
                        <div className='col-sm-3 padding-remove'>
                            <select className="form-control" onChange={(e) => handleSectionIdChange(e.target.value)} value={sectionId}>
                                <option value={0}>Section</option>
                                {lstClassSection.map((sec, idx) => {
                                    return <option key={idx} value={sec.sectionId}>{sec.section}</option>
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                <div className='row' style={{ width: '100%' }}>
                    <div className="form-group col-md-12" >
                        <label className="control-label col-sm-3">Subject<span className="red-color">*</span></label>
                        <div className='tablebodyWrapper' style={{ height: '63vh' }}>
                            <table className='tableLayout'>
                                <colgroup>
                                    <col width={30} />
                                    <col width={30} />
                                </colgroup>
                                <thead>{
                                    clsId !== 0 && sectionId !== 0 &&
                                    <tr>
                                        <td className="padding-remove"><input onChange={filterSubject} type="text" value={subject} className="form-control inp-search" placeholder="Subject" /></td>
                                    </tr>
                                }
                                </thead>

                                <tbody key={'c_' + clsId + '_sec_' + sectionId}>{

                                    clsId !== 0 && sectionId !== 0 && found.map((sub, idx) => {
                                        return (<tr /*onClick={(e) => handleSelSubjectId(sub.subjectId)} className={sub.subjectId === subjectId ? "selected" : ""}*/ key={idx}>
                                            <td className="text-left">{sub.subject}</td>
                                            <td>
                                                <input
                                                    key={sub.subjectId}
                                                    type="checkbox"
                                                    // defaultChecked={sub.selected}
                                                    checked={lstSubjectId.includes(sub.subjectId)}
                                                    onChange={(e) => handleCBoxChange(sub.subjectId, e.target.checked)}
                                                />
                                            </td>
                                        </tr>);
                                    })
                                }</tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ width: '100%' }}>
                    <button type="button" className="btn btn-info btn-space" onClick={(e) => saveClassSection()}>Save</button>
                </div>
            </div>
        </form>
    )
}
export default ClassSubjectForm;