import React, { useState, useEffect } from 'react';
import './css/headerDtTab.css';
/* var lstDt = [
    {
        date: '23-July'
    },
    {
        date: '25-July'
    },
    {
        date: '26-July'
    },
    {
        date: '27-July'
    },
    {
        date: '28-July'
    }
]
 */
const HeaderDtTab = (props) => {
    const [state, setState] = useState(
        {
            lstDt: props.lstDates,
            dynamicWidth: 'calc(100% / ' + Object.keys(props.lstDates).length + ')',
        }
    )

    useEffect(() => {
        //setLstDt(props.lstDt)
        setState({ lstDt: props.lstDates, dynamicWidth: 'calc(100% / ' + Object.keys(props.lstDates).length + ')' })

    }, [props])

    function previousClick(dt) {
        var dateString = dt;
        var dateParts = dateString.split("-");
        // month is 0-based, that's why we need dataParts[1] - 1
        var dtObj = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

        dtObj.setDate(dtObj.getDate() - 7);
        props.next(dtObj)
    }
    function nextClick(dt) {
        var dateString = dt;
        var dateParts = dateString.split("-");
        // month is 0-based, that's why we need dataParts[1] - 1
        var dtObj = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

        dtObj.setDate(dtObj.getDate() + 7);
        props.next(dtObj)
    }

    return (
        <>
            <span
                className="col-xs-1 glyphicon glyphicon-menu-left"
                onClick={() => previousClick(Object.values(state.lstDt)[0])}
                style={{ color: 'orange', lineHeight: '30px', cursor: 'pointer' }} />
            <div className="col-xs-10 padding-remove" style={{
                display: 'inline-block', boxShadow: 'orange 0px 1px 4px 0px',
                height: '75%', border: '1px solid orange',
                borderWidth: '1px 0px 1px 0px'
            }}>
                {state.dynamicWidth && state.lstDt && Object.keys(state.lstDt).map((obj, idx) => (
                    <div onClick={() => props.onDtTabClicked(state.lstDt[obj])}
                        key={idx}
                        className="grid-container"
                        dt={state.lstDt[obj]}
                        style={{ width: state.dynamicWidth }}>

                        <div className={state.lstDt[obj] === props.selDt ? "grid-item active" : "grid-item"} > {obj}</div>
                    </div>))
                }
            </div >
            <span
                className="col-xs-1 glyphicon glyphicon-menu-right"
                style={{ color: 'orange', lineHeight: '30px', cursor: 'pointer' }}
                onClick={() => nextClick(Object.values(state.lstDt)[0])}
            />
        </>);
}

export default HeaderDtTab;