import React, { useEffect, useState } from 'react'

import "common/css/master.css";


const FacilityTable = (props) => {
    const { lstFacility, facilityId, setFacilityId, editFacility, deleteFacility } = props;

    const [lstFacilityFiltered, setLstFacilityFiltered] = useState([]);
    const [selFacilityId, setSelFacilityId] = useState(0);

    useEffect(() => {
        // console.log("useEffect in FacilityTable component called, lstFacility", lstFacility);
        setLstFacilityFiltered(lstFacility);
    }, [lstFacility]);

    useEffect(() => {
        // console.log("useEffect in FacilityTable component called facilityId", facilityId);
        setSelFacilityId(facilityId);
    }, [facilityId]);

    const colgrp = <colgroup>
        <col width="80"></col>
        <col width="35"></col>
        <col width="35"></col>
        <col width="40"></col>
    </colgroup>



    return (
        <div >
            <div className="col-md-12 col-xs-12 col-centered padding-remove " >
                <table className="tableLayout1" >
                    {colgrp}
                    <thead>
                        <tr>
                            <th >
                                Facility
                            </th>
                            <th >
                                Allowed Classes
                            </th>
                            <th >
                                Capacity
                            </th>
                            <th >
                                Action
                            </th>
                        </tr>
                    </thead>
                </table>
                <div className="tableDiv" style={{ height: "70vh" }}>
                    <table className="tableLayout">
                        {colgrp}
                        <tbody id="diaryMonthlyFacilityTable" >
                            {lstFacilityFiltered &&
                                lstFacilityFiltered.map((facility, i) => (
                                    <tr key={facility.facilityId}
                                        onClick={() => setFacilityId(facility.facilityId)}
                                        className={facility.facilityId === selFacilityId ? "selected" : ''}>
                                        <td className="text-left">{facility.facility}</td>
                                        <td className="text-center">{facility.allowedClasses}</td>
                                        <td className="text-center">{facility.capacity}</td>
                                        <td className="text-center" >
                                            <span
                                                onClick={() => editFacility(facility)}
                                                title="Edit Facility Details"
                                                style={{ textDecoration: "underline", cursor: "pointer", color: "blue", margin: "5px" }}
                                            >
                                                Edit
                                            </span>
                                            {/* <span
                                                onClick={() => deleteFacility(facility.facilityId)}
                                                title="Delete Facility"
                                                style={{ textDecoration: "underline", cursor: "pointer", color: "blue", margin: "5px" }}
                                            >
                                                Delete
                                            </span> */}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default FacilityTable;