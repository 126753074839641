import React, { useState, useEffect } from 'react'
import lstModules from 'common/json/lstModules.json'
import axios from 'axios'
import 'common/css/master.css'
import Header from 'common/js/header/header'
import { toast, ToastContainer } from 'react-toastify'
import { getClassListSvc, saveLstClsLabelSvc } from './ClassSetupCtrlSvc'

var httpURL = lstModules['main'].url + ':' + lstModules['main'].port
let queryString = require('querystring')
var tmpLstCls = []
export default function ClassSetupCtrl () {
  const [state, setState] = useState({
    wingId: '',
    loader: 'none',
    opacity: 1,
    tokenid: localStorage.getItem('tokenid')
  })
  const [selClsId, setSelClsId] = useState(0)
  const [lstCls, setLstCls] = useState([])

  function getLstCls () {
    showLoader()
    getClassListSvc(handleLstClsResponse)
  }

  function showLoader () {
    setState({ ...state, loader: 'block', opacity: 0.5 })
  }
  function hideLoader () {
    setState({ ...state, loader: 'none', opacity: 1 })
  }
  function handleLstClsResponse (data) {
    var obj = data
    // // console.log(data)
    tmpLstCls = obj.lstCls
    setLstCls(obj.lstCls)

    hideLoader()
    if (obj.status === 'Success') {
    } else if (obj.status === 'Failure') {
      if (obj.message === 'You are not logged in') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else if (obj.message === 'Your Session is expired, login again') {
        setTimeout(function () {
          window.location.href = '/'
        }, 2000)
        return false
      } else {
        toast.error(data.message)
      }
    } else {
      toast.error('Service failed' + obj)
    }
  }

  function saveLstClsLabel () {
    var pLstTmpCls = []
    for (var i = 0; i < tmpLstCls.length; i++) {
      var obj = { clsId: tmpLstCls[i].clsId, clsLabel: tmpLstCls[i].clsLabel }
      pLstTmpCls.push(obj)
    }

    saveLstClsLabelSvc(pLstTmpCls, cbSaveLstClsLabelResponse)
  }
  function cbSaveLstClsLabelResponse (data) {
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      getLstCls()
    } else {
      toast.error(data.SvcMsg)
    }
  }
  useEffect(() => {
    getLstCls()
  }, [])
  function setLstClsLabel (pCls, pIdx) {
    tmpLstCls[pIdx].clsLabel = pCls
    setLstCls(tmpLstCls)
  }
  return (
    <div style={{ height: '100%' }}>
      <Header wingFromChild={getWingId} />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div
        style={{
          position: 'fixed',
          zIndex: 10000,
          width: 100,
          height: 100,
          marginLeft: -50,
          marginTop: -50,
          left: '50%',
          top: '50%',
          display: 'none'
        }}
      >
        <img
          className='loderImg'
          src={require('common/images/loader.gif')}
          alt='Loader...'
        />
      </div>
      <div className='MainContainer' style={{ opacity: state.opacity }}>
        <div className='col-sm-4 col-centered' style={{ marginTop: 15 }}>
          <table className='tableLayout1'>
            {/* {colgrp} */}
            <thead>
              <tr>
                <th className='text-left'>Class</th>
                <th>Label</th>
              </tr>
            </thead>
          </table>
          <div className='' style={{ height: '35em', overflowY: 'scroll' }}>
            <table className='tableLayout'>
              <tbody>
                {lstCls.map((obj, idx) => {
                  return (
                    <tr
                      onClick={e => setSelClsId(obj.clsId)}
                      className={obj.clsId === selClsId ? 'selected' : ''}
                      key={idx}
                    >
                      <td>{obj.clsName}</td>
                      <td>
                        <input
                          type='text'
                          className='form-control'
                          defaultValue={obj.clsLabel}
                          onChange={e => setLstClsLabel(e.target.value, idx)}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className='col-sm-12'>
            <button
              type='button'
              title='Save'
              onClick={saveLstClsLabel}
              className='btn btn-info pull-right'
            >
              Save
            </button>
          </div>
        </div>
        <div className='col-sm-4' style={{ marginTop: 10 }}></div>
      </div>
    </div>
  )

  function getWingId (wingId) {
    // // console.log('wingId' + wingId)
  }
}
