import React, { useState, useEffect } from 'react'
import { getAddressDtlsSvc, getLstStateSvc, getLstCitySvc, saveAddressSvc } from './AddressDtlsSliderSvc'
import { toast } from 'react-toastify';
import 'common/css/master.css';



const AddressDtlsSlider = (props) => {
    const [tchId, setTchId] = useState(props.tchId);
    const [flgSliderType, setFlgSliderType] = useState(props.flgSliderType);
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [stateId, setStateId] = useState(0);
    const [lstState, setLstState] = useState([]);
    const [cityId, setCityId] = useState(0);
    const [lstCity, setLstCity] = useState([]);
    const [pin, setPin] = useState();

    useEffect(() => {
        setTchId(props.tchId)
        setFlgSliderType(props.flgSliderType)
        getAddressDtlsSvc(props.tchId, cbAddressDtlsResponse)
    }, [props.tchId, props.flgSliderType])

    function cbLstStateResponse(data, pStateId) {
        setLstState(data)
        setStateId(pStateId)
    }

    function cbLstCityResponse(data, pCityId) {
        setLstCity(data)
        setCityId(pCityId)
    }

    function cbAddressDtlsResponse(response) {
        // // console.log(response)
        if (response.SvcStatus === "Success") {
            setAddressLine1(response.address.addressLine1);
            setAddressLine2(response.address.addressLine2);
            setPin(response.address.pin);
            setStateId(response.address.stateId);
            setCityId(response.address.cityId);
            getLstStateSvc(cbLstStateResponse, response.address.stateId);
            getLstCitySvc(cbLstCityResponse, response.address.stateId, response.address.cityId)
        } else {
            toast.error(response.SvcMsg)
        }
    }

    function saveAddress() {
        var address = {};
        address.addressLine1 = addressLine1;
        address.addressLine2 = addressLine2;
        address.stateId = stateId;
        address.cityId = cityId;
        address.pin = pin;
        saveAddressSvc(cbSaveAddressResponse, address)
    }

    function cbSaveAddressResponse(data) {
        if (data.status == "Success") {
            props.onGetRandNo()
            toast.success(data.message)
        } else {
            toast.error(data.SvcMsg)
        }
    }

    return (
        <div className='container'>
            <form>
                <div className='row'>
                    <label className="control-label col-sm-2">Current Address:</label>
                    <div className="form-group col-sm-10">
                        <textarea rows="1" style={{ resize: "noun" }} className="form-control" placeholder='address line 1' defaultValue={addressLine1} onChange={(event) => setAddressLine1(event.target.value)} />
                    </div>
                </div>
                <div className='row'>
                    <label className="control-label col-sm-2"></label>
                    <div className="form-group col-sm-10">
                        <textarea rows="1" style={{ resize: "noun" }} className="form-control" placeholder='address line 2' defaultValue={addressLine2} onChange={(event) => setAddressLine2(event.target.value)} />
                    </div>
                </div>
                <div className='row'>
                    <label className="control-label col-sm-2">State:</label>
                    <div className="form-group col-sm-4">
                        <select className="form-control" value={stateId} onChange={(event) => setStateId(event.target.value)}>
                            <option value={0}>State</option>
                            {lstState.map((obj, idx) => {
                                return (
                                    <option key={idx} value={obj.stateId}>
                                        {obj.state}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
                <div className='row'>
                    <label className="control-label col-sm-2">City:</label>
                    <div className="form-group col-sm-4">
                        <select className="form-control" value={cityId} onChange={(event) => setCityId(event.target.value)}>
                            <option value={0}>City</option>
                            {lstCity.map((obj, idx) => {
                                return (
                                    <option key={idx} value={obj.cityId}>
                                        {obj.city}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
                <div className='row'>
                    <label className="control-label col-sm-2">Pin:</label>
                    <div className="form-group col-sm-4">
                        <input type="text" className="form-control" placeholder='PIN No.' defaultValue={pin} onChange={(event) => setPin(event.target.value)} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-1  col-xs-1 pull-right'>
                        <button type="button" onClick={(e) => saveAddress()} style={{ marginTop: '1%' }} className="btn btn-outline-warning text-dark fw-bold btn_primary">
                            Save
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddressDtlsSlider