import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import 'jquery-ui';
import axios from 'axios';
import { toast } from 'react-toastify';
import ReactApexChart from 'react-apexcharts';

var httpURL = '';
var queryString = require('querystring');
var tokenid = localStorage.getItem('tokenid');


var weekLabels = [];
var year1 = [];
var year2 = [];
var year3 = [];
var year4 = [];
var years = [];
var screenId = 4;


class YearlyComparisonEnquiry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {},
            series: [],
            tokenid: tokenid,
        };
    }
    componentDidMount = () => {
        this.getGraph();

    }
    getGraph = () => {
        this.setState({ loader: 'block', opacity: 0.5 });
        axios.post(httpURL + '/sms/enquiry/yearcomparison ',
            queryString.stringify({ screenId }), {
            headers: { 'tokenid': this.state.tokenid },
            withCredentials: true
        }).then((response) => {
            if (response.data.SvcStatus === 'Success') {
                year1 = response.data.yearlyComparison.year1;
                year2 = response.data.yearlyComparison.year2;
                year3 = response.data.yearlyComparison.year3;
                year4 = response.data.yearlyComparison.year4;
                weekLabels = response.data.yearlyComparison.weekLabels;
                years = response.data.yearlyComparison.years;

                this.setState({
                    series: [{
                        name: years[3],
                        data: year4
                    },
                    {
                        name: years[2],
                        data: year3
                    },
                    {
                        name: years[1],
                        data: year2
                    },
                    {
                        name: years[0],
                        data: year1
                    }
                    ],
                    options: {
                        chart: {
                            type: 'line',
                        },
                        stroke: {
                            width: [5, 5, 5, 5],
                            show: true,
                            dashArray: 0,
                        },
                        legend: {
                            show: true,
                            showForSingleSeries: false,
                            showForNullSeries: true,
                            showForZeroSeries: true,
                            position: 'top',
                            horizontalAlign: 'center',
                            floating: false,
                            fontSize: '14px',
                            fontWeight: 400,
                            offsetX: 100,
                            offsetY: -20,

                        },
                        markers: {
                            size: [8, 5, 8, 5],
                            width: 12,
                            height: 12,
                            shape: ['square', 'circle', 'square', 'circle'],
                        },
                        title: {
                            text: 'Yearly Comparison',
                            align: 'left',
                        },
                        dataLabels: {
                            enabled: true,
                            style: {
                                fontSize: '15px',
                                fontWeight: 'bold',
                            },
                        },


                        xaxis: {
                            categories: weekLabels,
                            labels: {
                                offsetX: 6,
                                rotate: -45,
                                show: true,
                            },
                            axisTicks: {
                                show: true,
                            },
                            title: {
                                text: 'Weeks',
                                offsetX: 0,
                                offsetY: -20,
                                style: {
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                },
                            },
                        },
                        yaxis: [{
                            axisTicks: {
                                show: true,
                            },
                            axisBorder: {
                                show: true,
                                color: '#000'
                            },
                            labels: {
                                style: {
                                    color: '#000',
                                    fontSize: 13
                                }
                            },
                            title: {
                                text: 'Enquiry',
                                style: {
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                },
                            },
                            tooltip: {
                                enabled: false
                            }

                        }]
                    },
                })
            }
            else {

                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            }
            this.setState({ loader: 'none', opacity: 1 });
        }).catch((error) => {
            toast.error("Service failed " + error);
        });
    }



    render() {
        httpURL = lstModules['enquiry'].url + ":" + lstModules['enquiry'].port;
        return (
            <div className="YearlyComparisonEnquiry graphWrapper" style={{ opacity: this.state.opacity }}>
                <div className="col-sm-10 col-centered">
                    <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={500} />
                </div>
            </div>

        );
    }



}
export default YearlyComparisonEnquiry;