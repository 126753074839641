import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import $ from 'jquery';

export default class syllabusTable extends Component {

    addClassOnBody = (id) => {
        $('#tableBody tr#' + id).addClass('selected').siblings().removeClass('selected');
    }
    render() {
        return (
            <div>
                <>
                    <table className="tableLayout1" id='customTable'>
                        <thead>
                            <tr>
                                <th width="100">Syllabus Code</th>
                                <th width="200">Notes</th>
                                <th width="50">Action</th>
                            </tr>
                        </thead>
                    </table>
                    <div className="tableDiv" style={{ height: '80vh' }}>
                        <table id="mainTable" className="tableLayout">
                            <tbody id="tableBody">
                                {this.props.lstSyllabus.map((syllabus) => (
                                    <tr key={syllabus.syllabusId} id={syllabus.syllabusId} onClick={
                                        () => this.addClassOnBody(syllabus.syllabusId)}>
                                        <td width="100" className="no-display">{syllabus.syllabusId}</td>
                                        <td width="100" className="text-left">{syllabus.syllabusCode}</td>
                                        <td width="200" className="text-left">{syllabus.syllabusNotes}</td>
                                        <td width="50" className="text-center">
                                            <span onClick={() => this.props.setForm(syllabus.syllabusId)}
                                                className="glyphicon glyphicon-edit">
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            </div>
        )
    }
}
