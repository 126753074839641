import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['students'].url + ':' + lstModules['students'].port;
const tokenid = localStorage.getItem('tokenid');
const schoolId = localStorage.getItem("schoolId");
var queryString = require("querystring");

export const saveImgSvc = (file, type, studentId, callback) => {
    // // console.log("saveImgSvc called, file, type", file, type);
    const formData = new FormData();
    formData.append("studentId", studentId);
    formData.append("file", file);
    formData.append("typeId", type);

    const responseObj = { status: "", message: "", error: "" };

    axios({
        url: `${httpURL}/sms/student/docs/save`,
        method: "POST",
        data: formData,
        headers: { tokenid },
        withCredentials: true,
    })
        .then(response => {
            responseObj.status = response.data.SvcStatus;
            responseObj.message = response.data.SvcMsg;
            callback(responseObj);
        })
        .catch(error => {
            responseObj.status = "Exception";
            responseObj.error = error.message;
            callback(responseObj);
        });
};


export const getSectionListSvc = (clsId, callback) => {
    const responseObj = { status: "", message: "", error: "" };
    axios({
        url: `${httpURL}/sms/mst/section/list`,
        method: 'POST',
        data: queryString.stringify({ clsId }),
        headers: { tokenid },
        withCredentials: true,
    })
    .then(response => {
        // // console.log("response in getSEctionListSvc, response", response);
        responseObj.status = response.data.SvcStatus;
        responseObj.message = response.data.SvcMsg;
        if(response.data.SvcStatus === "Success"){
            responseObj.lstSection = response.data.lstSection;           
        }
        callback(responseObj);
    })
    .catch(error => {
        responseObj.status = "Exception";
        responseObj.error = error.message;
        callback(responseObj);
    });
};


export const getCityListSvc = (stateId, callback) => {
    const responseObj = { status: "", message: "", error: "" };
    axios({
        url: `${httpURL}/sms/mst/city/list`,
        method: 'POST',
        data: queryString.stringify({ schoolId, stateId }),
        headers: { tokenid },
        withCredentials: true,
    })
    .then(response => {
        // // console.log("response in getCityListSvc , response", response);
        responseObj.status = response.data.SvcStatus;
        responseObj.message = response.data.SvcMsg;
        if(response.data.SvcStatus === "Success"){
            responseObj.lstCity= response.data.lstCity;           
        }
        callback(responseObj);
    })
    .catch(error => {
        responseObj.status = "Exception";
        responseObj.error = error.message;
        callback(responseObj);
    });
};

export const getLocalityListSvc = (cityId, callback) => {
    const responseObj = { status: "", message: "", error: "" };
    axios({
        url: `${httpURL}/sms/mst/locality/list`,
        method: 'POST',
        data: queryString.stringify({ cityId }),
        headers: { tokenid },
        withCredentials: true,
    })
    .then(response => {
        // // console.log("response in getLocalityListSvc, response", response);
        responseObj.status = response.data.SvcStatus;
        responseObj.message = response.data.SvcMsg;
        if(response.data.SvcStatus === "Success"){
            responseObj.lstLocality= response.data.lstLocality;           
        }
        callback(responseObj);
    })
    .catch(error => {
        responseObj.status = "Exception";
        responseObj.error = error.message;
        callback(responseObj);
    });
};


export const saveStudentDtlsSvc = (studentObj, callback) => {
    // // console.log('saveStudentDtlsSvc called, studentObj', studentObj);
    const responseObj = { status: "", message: "", error: "" };
    axios({
        url: httpURL + "/sms/student/save",
        method: 'POST',
        data: queryString.stringify({ student: JSON.stringify(studentObj) }),
        headers: { tokenid },
        withCredentials: true,
    })
    .then(response => {
        // // console.log("response in saveStudentDtlsSvc, response", response);
        responseObj.status = response.data.SvcStatus;
        responseObj.message = response.data.SvcMsg;
        if(response.data.SvcStatus === "Success" && !!response.data.studentId ){ 
            responseObj.studentId = response.data.studentId;    
            responseObj.classsSectionStudentId = response.data.classsSectionStudentId;    
        }
        callback(responseObj);
    })
    .catch(error => {
        responseObj.status = "Exception";
        responseObj.error = error.message;
        callback(responseObj);
    });
};

