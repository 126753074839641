import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import Loader from "common/components/Loader";

import "../css/schoolProfile.css";
import { saveReportCardSvc, getReportCardDtlsSvc } from "./schoolProfileSvc";


const SchoolReportCard = (props) => {
    const { schoolId } = props;
    const [loader, setLoader] = useState("none");
    const [opacity, setOpacity] = useState(1);
    const [mobileReportCard, setMobileReportCard] = useState("");
    const [addressReportCard, setAddressReportCard] = useState("");
    const [affiliationReportCard, setAffiliationReportCard] = useState("");

    useEffect(() => {
        // // console.log("SchoolReportCard component called");
        getReportCard();
    }, []);

    const getReportCard = () => {

        setLoader("block");
        setOpacity(0.5);
        getReportCardDtlsSvc(handleReportCardResponse);
    }
    const handleReportCardResponse = (obj) => {
        // // console.log("handleReportCardResponse called, obj", obj);
        setLoader("none");
        setOpacity(1);
        if (obj.status === "Success") {
            setMobileReportCard(obj.reportCard.phone);
            setAffiliationReportCard(obj.reportCard.affiliation);
            setAddressReportCard(obj.reportCard.address);
        } else if (obj.status === "Failure") {
            if (obj.message === "You are not logged in") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else if (obj.message === "Your Session is expired, login again") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error("Service failed" + obj);
        }
    };


    function saveReportCardDtls(e) {
        e.preventDefault();
        var reportCard = {
            phone: mobileReportCard,
            address: addressReportCard,
            affiliation: affiliationReportCard,
        };
        setLoader("block");
        setOpacity(0.5);
        saveReportCardSvc(reportCard, handleReportCardSaveResponse);
    }
    function handleReportCardSaveResponse(obj) {
        setLoader("none");
        setOpacity(1);
        if (obj.status === "Success") {
            toast.success(obj.message);
        } else if (obj.status === "Failure") {
            if (obj.message === "You are not logged in") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else if (obj.message === "Your Session is expired, login again") {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        } else {
            toast.error("Service failed" + obj);
        }
    }

    return (
        <div className="col-xl-12 col-ls-12 col-md-12 col-sm-12 col-xs-12 padding-remove"
            style={{
                cursor: (loader === "none") ? 'pointer' : 'none',
                pointerEvents: (loader === "none") ? 'auto' : 'none',
                opacity: opacity
            }}>
            <Loader loader={loader} position="fixed" />
            <form
                className="form form-vertical col-sm-12"
                style={{ margin: "2%", border: "1px solid #ddd", borderRadius: 6 }}>
                <div
                    className="EnquiryHead col-centered"
                    style={{
                        fontWeight: "bold",
                    }}>
                    <h4>Student Report Card Details</h4>
                </div>
                <div className="row">
                    <label className="control-label col-sm-2" htmlFor="phone">
                        Phone No
                    </label>
                    <div className="form-group col-sm-3">
                        <input
                            className="form-control"
                            name="phone no"
                            defaultValue={mobileReportCard}
                            onChange={(e) => setMobileReportCard(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <label className="control-label col-sm-2" htmlFor="whatsapp">
                        Affiliation No.
                    </label>
                    <div className="form-group col-sm-3">
                        <input
                            className="form-control"
                            name="Affiliation"
                            defaultValue={affiliationReportCard}
                            onChange={(e) => setAffiliationReportCard(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <label className="control-label col-sm-2" htmlFor="website">
                        Address
                    </label>
                    <div className="form-group col-sm-10">
                        <input
                            className="form-control"
                            name="report card address"
                            defaultValue={addressReportCard}
                            onChange={(e) => setAddressReportCard(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-sm-11">
                        <button
                            className="pull-right btn btn-info"
                            title="Save"
                            onClick={(e) => saveReportCardDtls(e)}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default SchoolReportCard;
