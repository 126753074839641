import React, { useState, useEffect } from "react";
// import { BorrowerSearchBar } from "./BorrowerSearchBar";
import Loader from "common/components/Loader";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { ReturnDtls } from "./ReturnDtls";
import { getIssueDetails } from "./returnBooksSvc";
//import { last } from "lodash";

export function ReturnSlider(props) {
  const [objBorrowerDtls, setObjBorrowerDtls] = useState({});
  const [loader, setLoader] = useState("none");
  // const [totalFine, setTotalFine] = useState(0);
  const [totalSecurityDeposit, setTotalSecurityDeposit] = useState(0);
  // const [sliderLoader, setSliderLoader] = useState("none");
  const [lstBook, setLstBook] = useState([]);
  const [studentIssuePeriod, setStudentIssuePeriod] = useState(0);
  const [staffIssuePeriod, setStaffIssuePeriod] = useState(false);
  const [studentFine, setStudentFine] = useState(0);
  const [staffFine, setStaffFine] = useState(0);
  var dt = new Date();
  var tmpDd = dt.getDate();
  var dd = tmpDd < 9 ? "0" + tmpDd : tmpDd;
  var mon = dt.getMonth() + 1;
  var mm = mon < 10 ? "0" + mon : mon;
  var ddmmyyyy = dd + "-" + mm + "-" + dt.getFullYear();

  const [returnDt, setReturnDt] = useState(getDateForCalendar(ddmmyyyy));
  const [returnDtlsKey, setReturnDtlsKey] = useState(0);

  useEffect(() => {
    // // console.log("slider open");
    // // console.log(props);
    
    setObjBorrowerDtls(props.objBorrowerDtls);
    // setTotalFine(props.objBorrowerDtls.totalFine);
    getIssueDetails(issueDetailsResponse);
    setTotalSecurityDeposit(props.objBorrowerDtls.totalSecurityDeposit);
  }, [props.objBorrowerDtls, props.sliderKey]);


  const issueDetailsResponse = (data) => {
    var obj = data;
    if (obj.status === "Success") {
      setStudentIssuePeriod(obj.issueDtls.studentIssuePeriod);
      setStaffIssuePeriod(obj.issueDtls.staffIssuePeriod);
      setStudentFine(obj.issueDtls.studentFine);
      setStaffFine(obj.issueDtls.staffFine);
      setReturnDtlsKey(Math.random()*9999);
    }
    else {
      toast.error(obj.message);
    }
  }



  function getDateForCalendar(strDt) {
    if (!strDt) return null;
    var parts = strDt.split("-");
    var d = parts[0];
    var m = parts[1] - 1;
    var y = parts[2];

    return new Date(y, m, d);
  }

  function handleChangeReturnDt(pDt) {
    // // console.log("date passed to handleChangeDt",pDt);
    var dt = new Date();
    if(pDt>dt){
      toast("return date can't be in future.");
      return;
    }
    setReturnDt(pDt);
  }


  return (
    <div
      className="slider halfWidthSlider"
      style={{ opacity: props.sliderDisplayed ? 1 : 0, right: 0 }}
    >
      <Loader loader={loader} position="fixed" />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className="pdf" style={{ width: "100%" }}>
        <div className="col-sm-11" style={{ height: "100%", marginBottom: 20 }}>
          <ReturnDtls
            key={"rd_" +returnDtlsKey}
            setLstBook={setLstBook}
            // setSliderLoader={setSliderLoader}
            loader={loader}
            setLoader={setLoader}
            totalSecurityDeposit={totalSecurityDeposit}
            staffId={props.objBorrowerDtls.staffId}
            studentId={props.objBorrowerDtls.studentId}
            flgStaff={props.objBorrowerDtls.flgStaff}
            staffFine={staffFine}
            studentFine={studentFine}
            lstBookCopyNo={props.objBorrowerDtls.lstBookCopyNo}
            returnDt={returnDt}
            handleChangeReturnDt={handleChangeReturnDt}
            // setTotalFine={setTotalFine}
            // totalFine={totalFine}
          />
        </div>

        <div
          className="col-sm-11"
          style={{
            border: "1px solid #aaa",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            height: "84vh",
            marginTop: 20,
          }}
        >
          <BookList
            lstBook={lstBook}
            flgStaff={props.objBorrowerDtls.flgStaff}
            staffFine={staffFine}
            studentFine={studentFine}
            returnDt={returnDt}
            // totalFine={totalFine}
            // setTotalFine={setTotalFine}
          />
        </div>
        <button
          type="button"
          className="btn btn-info text-center "
          id="closeSlider"
          style={{ opacity: props.sliderDisplayed ? 1 : 0, right: "70%" }}
          onClick={() => props.setSliderDisplayed(0)}
        >
          <span
            id="close"
            title="Close Slider"
            className="glyphicon glyphicon-remove"
          ></span>
        </button>
      </div>
    </div>
  );
}

function BookList(props) {
  return (
    <div className="col-sm-12 padding-remove">
      {props.lstBook.map((obj) => {
        return <Book
          flgStaff={props.flgStaff}
          studentFine={props.studentFine}
          staffFine={props.staffFine}
          returnDt={props.returnDt}
          // totalFine={props.totalFine}
          // setTotalFine={props.setTotalFine}
          key={obj.bookNo + obj.copyNo}
          obj={obj}
        />;
      })}
    </div>
  );
}
function Book(props) {
  
  function isLessThanToday(mydate) {
    var q = new Date();
    var m = q.getMonth();
    var d = q.getDate();
    var y = q.getFullYear();
    var date = new Date(y, m, d);
    var tmpArr = mydate.split("-");
    d = tmpArr[0];
    m = tmpArr[1] - 1;
    y = tmpArr[2];

    mydate = new Date(y, m, d);
    return mydate < date;
  }

  var dailyFine = props.flgStaff ? props.staffFine : props.studentFine;

  const [dueDay, dueMonth, dueYear] = props.obj.dueDt.split('-');
  const dueDate = new Date(dueYear, dueMonth - 1, dueDay);
  // // console.log("dueDate ", dueDate)

  const differenceInTime = props.returnDt.getTime() - dueDate.getTime();
  
  var differenceInDays = differenceInTime / (1000 * 3600 * 24);
  if (differenceInDays < 0)
    differenceInDays = 0;
  const bookFine = dailyFine * differenceInDays;
  

  return (
    <form className="form form-horizontal">
      <div className="row">
        <div
          className="col-md-3"
          style={{ border: "1px solid #ccc", padding: "6px 0" }}
        >
          <label className="col-sm-5 padding-remove">Issue Date:</label>
          <label
            className="control-label col-sm-7 padding-remove text-left"
            style={{ top: 5 }}
          >
            {props.obj.issueDt}
          </label>
        </div>
        <div
          className="col-md-3"
          style={{
            border: "1px solid #ccc",
            borderLeft: "none",
            padding: "6px 0",
          }}
        >
          <label className="col-sm-5 padding-remove">Due Date:</label>
          <label
            className="control-label col-sm-7 padding-remove text-left"
            style={{
              top: 5,
              color: isLessThanToday(props.obj.dueDt) ? "#ff5722" : "",
              fontWeight: isLessThanToday(props.obj.dueDt) ? "bold" : "",
            }}
          >
            {props.obj.dueDt}
          </label>
        </div>
        <div
          className="col-md-4"
          style={{
            border: "1px solid #ccc",
            borderLeft: "none",
            padding: "6px 0",
          }}
        >
          <label className="col-sm-5 padding-remove">Security Deposit:</label>
          <label
            className="control-label col-sm-7 padding-remove text-left"
            style={{
              top: 5,
              color: props.obj.securityDeposit > 0 ? "#ff5722" : "",
              fontWeight: props.obj.securityDeposit > 0 ? "bold" : "",
            }}
          >
            Rs. {props.obj.securityDeposit}
          </label>
        </div>
        <div
          className="col-md-2"
          style={{
            border: "1px solid #ccc",
            borderLeft: "none",
            borderRight: "none",
            padding: "6px 0",
          }}
        >
          <label className="col-sm-4 padding-remove">Fine:</label>
          <label
            className="control-label col-sm-8 padding-remove text-left"
            style={{
              top: 5,
              color: props.obj.fine > 0 ? "#ff5722" : "",
              fontWeight: props.obj.fine > 0 ? "bold" : "",
            }}
          >
            Rs. {bookFine}
          </label>
        </div>
      </div>
      <div className="form-group">
        <div className="col-sm-6">
          <div className="col-sm-12 padding-remove">
            <label className="col-sm-4 padding-remove" style={{ top: 5 }}>
              Title
            </label>
            <div className="form-group col-md-5 padding-remove">
              <input
                type="text"
                className="form-control text-left"
                value={props.obj.title}
                readOnly
              />
            </div>
          </div>
          <div className="col-sm-12 padding-remove">
            <label className="col-sm-4 padding-remove" style={{ top: 5 }}>
              Author
            </label>
            <div className="form-group col-md-8 padding-remove">
              <input
                type="text"
                className="form-control text-left"
                value={props.obj.author}
                readOnly
              />
            </div>
          </div>
          <div className="col-sm-12 padding-remove">
            <label className="col-sm-4 padding-remove" style={{ top: 5 }}>
              Pages
            </label>
            <div className="form-group col-md-2 padding-remove">
              <input
                type="text"
                className="form-control text-right"
                value={props.obj.pageCount}
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="col-sm-12 padding-remove">
            <label className="col-sm-4 padding-remove" style={{ top: 5 }}>
              Book No.
            </label>
            <div className="form-group col-md-3 padding-remove">
              <input
                type="text"
                className="form-control text-center"
                value={`${props.obj.bookNo}-${props.obj.copyNo}`}
                readOnly
              />
            </div>
          </div>
          <div className="col-sm-12 padding-remove">
            <label className="col-sm-4 padding-remove" style={{ top: 5 }}>
              Cost
            </label>
            <div className="form-group col-md-2 padding-remove">
              <input
                type="text"
                className="form-control text-right"
                value={props.obj.cost}
                readOnly
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
    </form>
  );
}
