import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import '../css/sessionTab.css';

const SessionTab = props => {
    const [dynamicWidth, setDynamicWidth] = useState('calc(100% / ' + props.len + ')');
    const [duration, setDuration] = useState(props.session.dispToDt + ' | ' + props.session.dispToDt);

    useEffect(() => {
        // // console.log("session passed to sessionTab",props.session);
        setDynamicWidth('calc(100% / ' + props.len + ')');
        setDuration(props.session.dispFromDt + ' | ' + props.session.dispToDt);
    }, [props])

    return (
        <div className="box-block" style={{ width: dynamicWidth }} onClick={(e) => props.clickSessionTab(e, props.session.academicScheduleId)}>
            <div className="div-block">
                <span>{props.session.label}</span>
                <span className="pull-right">{props.session.percentage}</span></div>
            <div className="div-block"><span>{duration}</span></div>
        </div>
    )
}

SessionTab.propTypes = {
    session: PropTypes.object,

}

export default SessionTab
