import axios from 'axios'
import lstModules from 'common/json/lstModules.json'
const httpURL = lstModules['payrollSetup'].url + ':' + lstModules['payrollSetup'].port;
var tokenid = localStorage.getItem('tokenid');

function getTaxListSvc(callBack) {

    axios(
        {
            url: httpURL +"/sms/tax_yr/list",
            method: 'POST',
            params: {},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
           
        }
    ).catch((error) => {
        // // console.log("Test List Catch error")
    });
}
function getTaxDtlsSvc(ptaxyrId,callBack){
    axios(
        {
            url: httpURL +"/sms/tax_yr/dtls",
            method: 'POST',
            params: {taxYrId: ptaxyrId},
            headers: { 'tokenid': tokenid },
            withCredentials: true
        }
    ).then(
        (Response) => {
            var data = Response.data
            callBack(data)
           
        }
    ).catch((error) => {
        // // console.log("Test List Catch error")
    });
}
function saveTaxYrSvc(pTaxYrObj,callBack){
    var formData = new FormData();
    formData.append("taxYr", JSON.stringify(pTaxYrObj));
    axios({
      url:httpURL +"/sms/tax_yr/save",
      method: "POST",
      data: formData,
      headers: { 'tokenid': tokenid },
      withCredentials: true
    }).then((response) => {
  
      var data = response.data
      callBack(data);
    }).catch((error) => {
        console.error(error) 
    });
}
function saveTaxDtlsSvc(pTaxDtlsObj,callBack){
    var formData = new FormData();
    formData.append("taxData", JSON.stringify(pTaxDtlsObj));
    axios({
      url:httpURL +"/sms/tax_yr/dtls/save",
      method: "POST",
      data: formData,
      headers: { 'tokenid': tokenid },
      withCredentials: true
    }).then((response) => {
  
      var data = response.data
      callBack(data);
    }).catch((error) => { 
        console.error(error) 
    });
}
export {getTaxListSvc,getTaxDtlsSvc,saveTaxYrSvc,saveTaxDtlsSvc}