import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import Header from "common/js/header/header.js";
import MonthWiseCollection from "./MonthWiseCollection";
import MonthWiseSummary from "./MonthWiseSummary";
import 'common/css/master.css'
import '../css/ActualCollection.css'
function ActualCollection() {
    const [selMonthId, setSelMonthId] = useState(0)
    const [selMonth, setSelMonth] = useState("")
    const [randNo, setRandNo] = useState(0)
    const [loader, setLoader] = useState('none')
    function generateRandNo() {
        var lRandNo = Math.floor(Math.random() * 999999);
        setRandNo(lRandNo)
    }
    const getWingId = (wingId) => {
        // // console.log('wingId = ' + wingId)
    }
    return (
        <div className='MainContainer'
            style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
            <div style={{ position: 'fixed', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />
            <div className="col-sm-12" style={{ padding: "10px 17px", pointerEvents: loader === "none" ? "auto" : "none" }}>
                <MonthWiseSummary
                    setSelMonthId={setSelMonthId}
                    selMonthId={selMonthId}
                    setSelMonth={setSelMonth}
                >
                </MonthWiseSummary>
            </div>
            {selMonthId != 0 &&
                <div className="col-sm-12" style={{ padding: "10px 15px", pointerEvents: loader === "none" ? "auto" : "none" }}>
                    <MonthWiseCollection
                        selMonthId={selMonthId}
                        selMonth={selMonth}
                    >
                        {/* {
                         // // console.log(selMonthId)
                    } */}
                    </MonthWiseCollection>
                </div>}
        </div>
    )
}
export default ActualCollection;