import React, { useState, useEffect } from "react";
import { getStatusSummaryList } from "./StatusSummarySvc";
import "../css/feedback.css";
var arr = []
var tmpSelLstStatusId = []

const StatusSummary = (props) => {
  const [lstStatusSummary, setLstStatusSummary] = useState([]);

  useEffect(() => {
    getStatusSummaryList(cbStatusSummaryResponse, props.statusId);

  }, []);

  function cbStatusSummaryResponse(data) {
    if (!!data.lstStatusSummary) {
      for (var i = 0; i < data.lstStatusSummary.length; i++) {
        arr[i] = 0;
        tmpSelLstStatusId = []
      }
      setLstStatusSummary(data.lstStatusSummary)
    }

  }
  function clickStatus(pStatusId, pIndx) {
    arr[pIndx] = arr[pIndx] + 1;
    if (arr[pIndx] % 2 === 0)
      tmpSelLstStatusId.splice(tmpSelLstStatusId.indexOf(pStatusId), 1)
    else {
      if (!tmpSelLstStatusId.includes(pStatusId))
        tmpSelLstStatusId.push(pStatusId)
    }
    props.clickStatus(tmpSelLstStatusId)

  }
  return (
    <>
      {lstStatusSummary.map((obj, idx) => {
        var isSelected = !!props.selLstStatusId && props.selLstStatusId.includes(obj.statusId) && arr[idx] % 2 !== 0;
        return <div key={obj.statusId} className="col-sm-3 padding-remove" style={{ marginTop: 0 }} onClick={() => clickStatus(obj.statusId, idx)}>
          <div className="circle_area_summary col-sm-12 col-centered" style={{ background: isSelected ? '#4a86e8' : '', color: isSelected ? '#fff' : '' }}>
            <div className="div_area_count col-sm-12 col-centered">{obj.count}</div>
          </div>
          <div>{obj.status}</div>
        </div>
      })}</>
  );
};

export default StatusSummary;