import React, { Component } from 'react';
import lstModules from 'common/json/lstModules.json';
import $ from 'jquery';

export default class topicTable extends Component {

    addClassOnBody = (id) => {
        $('#topicTableBody tr#' + id).addClass('selected').siblings().removeClass('selected');
        this.props.rowClick(id)
    }
    render() {
        return (
            <div>
                <table className="tableLayout1" id='customTable'>
                    <thead>
                        <tr>
                            <th width="100">S.No.</th>
                            <th width="100">Topic</th>
                            <th width="200">Name</th>
                        </tr>
                    </thead>
                </table>
                <div className="tableDivMedium">
                    <table id="mainTable" className='tableLayout'>
                        <tbody id="topicTableBody">
                            {this.props.lstTopics.map((topics, index) => (
                                <tr key={topics.topicId} id={topics.topicId} onClick={
                                    () => this.addClassOnBody(topics.topicId)}>
                                    <td width="100" className="text-left">{index + 1}</td>
                                    <td width="100" className="text-left">{topics.topicCode}</td>
                                    <td width="200" className="text-left">{topics.topicName}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
