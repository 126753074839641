import axios from 'axios';
import lstModules from 'common/json/lstModules.json';
var tokenid = localStorage.getItem("tokenid");
var httpURL = lstModules['performance'].url + ":" + lstModules['performance'].port;

function saveAllocationSvc(examId, expHrs, allocatedToId, [state], cbsaveAllocationResponse) {
    var obj = { status: "Success" }
    var formData = new FormData();
    formData.append("allocateObj", JSON.stringify({ examId: examId, expHrs: parseInt(expHrs), allocatedToId: parseInt(allocatedToId), lstData: [state] }));
    axios(
        {
            url: httpURL + "/sms/performance/allocate/save",
            method: "POST",
            withCredentials: true,
            headers: { 'tokenid': tokenid },
            data: formData

        })
        .then((response) => {
            var data = response.data;
            // // console.log(data)
            if (data.SvcStatus === "Success") {
                obj.status = data.SvcStatus;
                obj.message = data.SvcMsg;
            } else {
                obj.status = data.SvcStatus;
                obj.message = data.SvcMsg;
            }
            // // console.log(obj)
            cbsaveAllocationResponse(obj)
        })
        .catch((error) => {
            obj.status = "Exception"
            obj.message = error;
            cbsaveAllocationResponse(obj)
        });


}


function allocateTchLstSvc(cbTchLstResponse) {
    axios(
        {
            url: httpURL + "/sms/performance/allocate/tch/list",
            method: "POST",
            withCredentials: true,
            headers: { 'tokenid': tokenid }

        })
        .then((response) => {
            response && response.data && cbTchLstResponse(response.data.lstTch)
        })
        .catch((error) => {
            console.error(error)
        });


}
const teachersList = (cb) => {

    axios(
        {
            url: "http://www.sms.cooledsys.com:8183/sms/tt/v1/tch/list",
            method: 'POST',
            params: {},
            withCredentials: true,
            headers: { 'tokenid': tokenid }
        }
    ).then(
        (Response) => {
            var data = Response.data.lstTch

            cb(data)

        }
    ).catch((error) => {
        console.error(error)
    });
}


export { saveAllocationSvc, allocateTchLstSvc, teachersList }
///sms/tt/v1/tch/list 8183