import { red } from '@mui/material/colors';
import { StyleSheet, Font } from '@react-pdf/renderer';


import NaanumGothicRegular from "common/fonts/Nanum_Gothic/NanumGothic-Regular.ttf";
import NaanumGothicBold from "common/fonts/Nanum_Gothic/NanumGothic-Bold.ttf";
import InterItalic from "common/fonts/Inter/Inter-Italic-VariableFont_opsz,wght.ttf";
import Inter from "common/fonts/Inter/Inter-VariableFont_opsz,wght.ttf";



Font.register({ family: 'Inter', src: Inter });
Font.register({ family: 'InterItalic', src: InterItalic });
Font.register({
    family: 'NanumGothic',
    fonts: [
        { src: NaanumGothicRegular, fontWeight: 'normal' },
        { src: NaanumGothicBold, fontWeight: 'bold' }
    ]
});



export const pageStyle = StyleSheet.create({
    page: {
        display: 'flex',
        flexDirection: 'column',
        // padding: 10,
        // border:"1px solid #ddd",
        // backgroundColor: "#f5f5f5",  // Light background
    },
    page_half: {
        width: "100%",
        height: "50%",
        // padding: "10px",
    },
    border: {
        border: "1px solid black",
        // flex:1,
    },
    page_border: {
        // flex:1
        // paddingVertical: 20,
        // paddingHorizontal: 30,
        border: "5px solid #ccc", /* Light grey border */
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", /* Lighter shadow */
        borderRadius: 15,
        // backgroundColor: "#fff", /* White background inside */
        backgroundImage: "linear-gradient(to right, #f7e8d5, #fae0c1)", /* Softer, lighter gradient */
        height: '92%',
        width: '96%',
        margin: "4% 2%",
        padding:"0 10px"
    },
    header_section: {
        flex: 6,
        // borderBottom: "2px solid #ddd",
        // paddingBottom: 10,
    },
    title_section: {
        flex: 2,
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        textAlign: "center",
        // borderBottom: "2px solid #ddd",
        // paddingBottom: 10,
    },
    content_section: {
        flex: 16,
        // marginTop: 20,
    },
});


export const fontStyles = StyleSheet.create({
    textCenterSmallBold: {
        fontFamily: 'NanumGothic',
        justifyContent: "center",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: 10,
        color: '#000',
        // borderWidth: 1,
        // borderColor: "#000",
    },
    textLeftSmall: {
        fontFamily: 'NanumGothic',
        justifyContent: "center",
        textAlign: "left",
        fontWeight: "normal",
        fontSize: 14,
        color: '#000',
    },
    textLeftNormal: {
        fontFamily: 'NanumGothic',
        justifyContent: "center",
        textAlign: "left",
        fontWeight: "normal",
        fontSize: 18,
        color: '#000',
    },
    textCenterMediumInter: {
        fontFamily: 'Inter',
        justifyContent: "center",
        textAlign: "center",
        fontSize: 18,
        color: '#000',
    },
    textCenterNormalInter: {
        fontFamily: 'Inter',
        justifyContent: "center",
        textAlign: "center",
        fontSize: 12,
        color: '#000',
    },
    textLeftNormalInter: {
        fontFamily: 'Inter',
        justifyContent: "center",
        textAlign: "left",
        fontSize: 10,
        color: '#000',
    },
    textCenterSmallInter: {
        fontFamily: 'Inter',
        justifyContent: "center",
        textAlign: "center",
        fontSize: 9,
        color: '#000',
    },
    textCenterBigBold: {
        fontFamily: 'NanumGothic',
        fontWeight: "medium",
        justifyContent: "center",
        textAlign: "center",
        fontSize: 26,
        color: '#000',
    },
    textSchool: {
        fontFamily: 'NanumGothic',
        fontWeight: "bold",
        justifyContent: "center",
        textAlign: "center",
        fontSize: 22,
        color: '#000',
    },
    fontNaunamGauthic: {
        fontFamily: 'NanumGothic',
        justifyContent: "center",
        textAlign: "left",
        fontWeight: "normal",
        fontSize: 14,
        color: '#000',
    },
    fontNaunamGauthicBold: {
        fontFamily: 'NanumGothic',
        justifyContent: "center",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: 16,
        color: '#000',
    },
    fontNaunamGauthicMedium: {
        fontFamily: 'NanumGothic',
        fontWeight: "medium",
        justifyContent: "center",
        textAlign: "left",
        fontSize: 14,
        color: '#000',
    },

    fontInter: {
        fontFamily: 'Inter',
        fontSize: 18,
        justifyContent: "center",
        textAlign: "left",
        color: '#000',
    },
    fontInterItalic: {
        fontFamily: 'InterItalic',
        justifyContent: "center",
        textAlign: "left",
        alignItems: "flex-start",
        alignContent: "flex-start",
        alignSelf: "flex-start",
        fontSize: 16,
        color: '#000',
        lineHeight: 1.75,

    },
    boldText: {
        fontFamily: 'NanumGothic',
        fontWeight: 'bold',
        fontSize: 16, // You can adjust this as needed
        color: '#000',
        textAlign: "left",
        alignItems: "flex-start",
        alignContent: "flex-start",
        alignSelf: "flex-start",
    },
});

export const styleDtls = StyleSheet.create({
    dtls_section: {
        flex: 1,
        color: "#000",
        display: 'flex',
        flexDirection: 'row',
        family: 'Calibri',
    },
    photo_section: {
        margin: "5px 0",
        flex: 3,
        flexDirection: "column",
    },
    photo_container: {
        flex: 7,
        margin: "5px 5px 5px 0",
        border: "1px solid #ccc",
    },
    sign_container_student: {
        flex: 3,
        flexDirection: "column",
        margin: "5px 5px 5px  0",
        border: "1px solid #ccc",
        textAlign: "center",
        alignItems: "center",
        // padding:"0 20px",
    },
    sign_container: {
        flex: 3,
        flexDirection: "column",
        margin: "5px  0 5px 5px",
        border: "1px solid #ccc",
        textAlign: "center",
        alignItems: "center",
        // padding:"0 20px",
    },
    sign_label: {
        flex: 3,
        width: "50%",
        borderTop: "1px solid #ccc",
    },
    sign_label_student: {
        flex: 7,
        width: "50%",
        borderTop: "1px solid #ccc",
    },
    sign_space_student: {
        flex: 3,
        padding:"20px"
    },
    sign_space: {
        flex: 7,
        padding:"20px"
    },
    details_section: {
        flex: 7,
        flexDirection: "column",
        margin: "5px 0 5px 5px",
    },
    student_dtls_section: {
        flex: 5,
        flexDirection: "column",
    },
    sign_section: {
        flex: 5,
        flexDirection: "row",
    },
    studentRow: {
        display:"flex",
        flexDirection:"row",
        width: "100%",         // Full width
        height: "100%",        // Full height
        margin: "5px 0",       // 5px vertical margin (top and bottom)
        backgroundColor: "#f0f0f0",  // Light grey background
        border: "1px solid #ccc",    // Light grey border similar to #ccc
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
        borderRadius: 5,       // Optional: Slightly rounded corners for smooth look
        paddingHorizontal: "5px ",       // Optional: Add padding for inner spacing
        // paddingVertical: "auto",       // Optional: Add padding for inner spacing
        justifyContent:"center"
    },
    rowLabel:{
        flex:3,
        justifyContent:"center"
    },
    rowColon:{
        flex:1,
        justifyContent:"center"
    },
    rowData:{
        flex:20,
        justifyContent:"center"
    },
    image_sign: {
        height: "auto",
        maxWidth: "100%",
        aspectRatio:"contain"
        // textAlign: "center",
        // padding: 0,
        // justifyContent:"center",
        // alignContent:"center",
        // textAlign:"center",
        // alignItems:"center",
        // alignSelf:"center"
    },
    image_photo :{
        height: "auto",
        maxWidth: "100%",
        aspectRatio:"contain"
    },

});

export const styleHeaderLogo = StyleSheet.create({
    header_logo_section: {
        flex: 1,
        // padding: "5px",
        color: "#000",
        display: 'flex',
        flexDirection: 'row',
        family: 'Calibri',
    },
    header_left: {
        flex: 4,
        height: '100%',
        padding: "5px 0",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        textAlign: "center",
    },
    header_center: {
        flex: 10,
        padding: "5px",
        textAlign: "center",
        justifyContent: "center",
        // fontWeight: 'heavy',
        fontStyle: 'normal',
        // borderWidth:innerBorderWidth,
        // borderColor:"#ccc",
        // fontFamily: "Helvetica-Bold",
    },
    header_right: {
        flex: 6,
        padding: "5px 0 5px 5px",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        textAlign: "center",
        // borderWidth:innerBorderWidth,
        // borderColor:"#ccc",
    },
    exam_name_box: {
        height: "50px",
        width: "100%",
        padding: "5px",
        border: "1px solid #ccc"
    },
    title_box: {
        height: "30px",
        width: "100%",
        padding: "0 5px",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        textAlign: "center",
        backgroundColor: "#e0f7fa", // Light pastel blue
        border: "1px solid #4a90e2"
    },
    school_header: {
        fontSize: 11,
        paddingTop: 5,
        textTransform: "uppercase",
    },
    image1: {
        maxHeight: "80px",
        maxWidth: "80px",
        // width:"auto",
        // maxWidth: "100%",
        textAlign: "center",
        padding: 0,
        objectFit: "contian",
        // justifyContent:"center",
        // alignContent:"center",
        // textAlign:"center",
        // alignItems:"center",
        // alignSelf:"center"
    },
});


