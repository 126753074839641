import React, { useState, useEffect } from "react";
import { BorrowerSearchBar } from "./BorrowerSearchBar";
import Loader from "common/components/Loader";
import { BorrowerDtls } from "./BorrowerDtls";
import { ToastContainer } from "react-toastify";
import { BookIssueSection } from "./BookIssueSection";

export function Slider(props) {
  const [borrowerDtls, setBorrowerDtls] = useState({
    gender: "-",
    age: "0",
    borrower: "",
    clsSection: "",
    staffId: 0,
    studentId: 0,
  });
  const [borrowerId, setBorrowerId] = useState("");
  const [loader, setLoader] = useState("none");
  const [issueDtls, setIssueDtls] = useState({});
  const [studentId, setStudentId] = useState(0);
  const [staffId, setStaffId] = useState(0);

  useEffect(() => {
    // // console.log(props);
    setIssueDtls(props.issueDtls);
    setStaffId(props.staffId);
    setStudentId(props.studentId);
  }, [props.issueDtls, props.bookCopyNo, props.studentId, props.staffId]);

  return (
    <div
      className="slider halfWidthSlider"
      style={{ opacity: props.sliderDisplayed ? 1 : 0, right: 0 }}
    >
      <Loader loader={loader} position="fixed" />
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className="pdf" style={{ width: "100%" }}>
        <div className="col-sm-11" style={{ height: "100%", marginBottom: 20 }}>
          <BorrowerSearchBar
            setLoader={setLoader}
            setBorrowerDtls={setBorrowerDtls}
            setBorrowerId={setBorrowerId}
            setStaffId={setStaffId}
            setStudentId={setStudentId}
          />
        </div>
        <div
          className="col-sm-11"
          style={{
            border: "1px solid #aaa",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            height: "84vh",
            marginTop: 20,
            marginLeft: "4%",
          }}
        >
          <BorrowerDtls
            borrowerId={borrowerId}
            borrowerDtls={borrowerDtls}
            setLoader={setLoader}
            issueRestriction={props.issueDtls.issueRestriction}
          />
          <hr />
          <BookIssueSection
            issueDtls={props.issueDtls}
            flgStaff={borrowerDtls.staffId === 0 ? false : true}
            borrowerDtls={borrowerDtls}
            borrowerId={borrowerId}
            setSliderDisplayed={props.setSliderDisplayed}
          />
        </div>
      </div>

      <button
        type="button"
        className="btn btn-info text-center "
        id="closeSlider"
        style={{ opacity: props.sliderDisplayed ? 1 : 0, right: "70%" }}
        onClick={() => props.setSliderDisplayed(0)}
      >
        <span
          id="close"
          title="Close Slider"
          className="glyphicon glyphicon-remove"
        ></span>
      </button>
    </div>
  );
}
