import React, { useState, useEffect, useRef } from 'react'
import DatePicker from 'react-datepicker'
import { toast, ToastContainer } from 'react-toastify'
import {
  getPtmDtlsSvc,
  getTemplateSvc,
  getWingsListSvc,
  savePtmSvc
} from './PtmSliderSvc'
const PtmSlider = props => {
  const weekday = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ]
  const [ptmDt, setPtmDt] = useState(null)
  const [ptmId, setPtmId] = useState(null)
  const [lstTm, setLstTm] = useState([])
  const [state, setState] = useState({
    startTm: '',
    endTm: '',
    template:
      'Dear Parents,\n\nOnline PTM for V - A is scheduled for 12-11-2022 Saturday. Your time slot is between 8:00 AM and 10:00 AM . Kindly arrive 10 mins before your slot.\n\nNotes\n\nRegards'
  })
  const [notes, setNotes] = useState('')
  const [flgNotes, setFlgNotes] = useState(true)
  const [flgOnline, setFlgOnline] = useState(false)
  const [objective, setObjective] = useState('')
  const [slotInterval, setSlotInterval] = useState(0)
  const [lstSelWingId, setLstSelWingId] = useState([])
  const [lstWingId, setLstWingId] = useState([])
  const [lstWings, setLstWings] = useState([])
  const [lstMstWings, setLstMstWings] = useState([])
  const [flgSms, setFlgSms] = useState(false)
  const [flgEmail, setFlgEmail] = useState(false)
  const [flgIvr, setFlgIvr] = useState(false)
  const [flgWhatsapp, setFlgWhatsapp] = useState(false)
  const [flgReminder, setFlgReminder] = useState(false)
  const [smsSentOn, setSmsSentOn] = useState('')
  const [emailSentOn, setEmailSentOn] = useState('')
  const [ivrSentOn, setIvrSentOn] = useState('')
  const [whatsappSentOn, setWhatsappSentOn] = useState('')
  const [template, setTemplate] = useState('')
  const [tmpTemplate, setTmpTemplate] = useState('')
  const [_cls, setCls] = useState('IV')
  const [_sec, setSec] = useState('A')
  var theTemplate = ''

  useEffect(() => {
    // // console.log("Ptmslider called, props.ptmId", props.ptmId);
    populate();
    setPtmId(props.ptmId);
    getTemplateSvc(cbTemplateResponse);
    getWingsListSvc(cbWingsResponse);
    if (props.ptmId === 0) {
      clearForm();
    } else {
      getPtmDtlsSvc(props.ptmId, cbPtmDtlsResponse);
    }
  }, [props.ptmId, props.sliderKey]);

  async function cbWingsResponse(data) {
    if (data.SvcStatus === 'Success') {
      var tmpWingLst = data.lstWings
      var tempLstWing = []
      for (var i = 0; i < tmpWingLst.length; i++) {
        var obj = {
          wingId: tmpWingLst[i].wingId,
          wing: tmpWingLst[i].wing,
          selected: false
        }
        tempLstWing.push(obj)
      }
      await setLstMstWings(tempLstWing)
    } else {
      toast.error(data.SvcMsg)
    }
  }
  function cbTemplateResponse(data) {
    if (data.SvcStatus === 'Success') {
      setTmpTemplate(data.template)
      setTemplate(data.template)

      theTemplate = data.template
    } else {
      toast.error(data.SvcMsg)
    }
  }

  function cbPtmDtlsResponse(data) {
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      var dt = data.ptmObj.ptmDt
      var dtparts = dt.split('-')
      var vFlgOnline = data.ptmObj.flgOnline
      setFlgOnline(vFlgOnline)

      var vDt = new Date(dtparts[2], dtparts[1] - 1, dtparts[0])
      setPtmDt(vDt)
      var vStartTm = data.ptmObj.startTm
      var vEndTm = data.ptmObj.endTm
      setState({ ...state, startTm: vStartTm, endTm: vEndTm })

      var vFlgNotes = !!data.ptmObj.notesToParents
      // // console.log('vflgNotes: ' + vFlgNotes)
      var vNotes = data.ptmObj.notesToParents
      // // console.log('vNotes: ' + vNotes)
      setFlgNotes(vFlgNotes)
      setNotes(vNotes)

      getSelLstWingId(data.ptmObj.lstWings)
      setObjective(data.ptmObj.objective)
      setFlgSms(data.ptmObj.flgSms)
      setFlgEmail(data.ptmObj.flgEmail)
      setFlgWhatsapp(data.ptmObj.flgWhatsapp)
      setFlgIvr(data.ptmObj.flgIvr)

      setSlotInterval(data.ptmObj.slotInterval)
      setSmsSentOn(data.ptmObj.smsSentDttm)
      setEmailSentOn(data.ptmObj.emailSentDttm)
      setWhatsappSentOn(data.ptmObj.whatsappSentDttm)
      setIvrSentOn(data.ptmObj.ivrSentDttm)
      setLstWings(data.ptmObj.lstWings)

      setFlgReminder(data.ptmObj.flgReminder)
      // // console.log('template = ' + template)
      // // console.log('tmpTemplate = ' + tmpTemplate)
      // // console.log('theTemplate:' + theTemplate)
    } else {
      toast.error(data.SvcMsg)
    }
  }
  function myUpdTemplate() {
    var myTemplate =
      'Dear Parent,\n\nPTM for <_cls> - <_sec> is scheduled for <_ddmmyyyy> <_day>.Your time slot is between <_hhmm1> and <_hhmm2> . Kindly arrive 10 mins before your slot.\n\n<_notes>\n\nRegards'

    var vTemplate = myTemplate
    // // console.log('myUpdTemplate state.startTm = ' + state.startTm)
    // // console.log('myUpdTemplate state.endTm = ' + state.endTm)
    vTemplate = ifTm(vTemplate, '<_hhmm1>', state.startTm)
    vTemplate = ifTm(vTemplate, '<_hhmm2>', state.endTm)

    setState({ ...state, vTemplate })
  }

  function updateTemplate(myTemplate) {
    /* // // console.log(myTemplate)
    myTemplate =
      'Dear Parent,\n\nOnline PTM for <_cls> - <_sec> is scheduled for <_ddmmyyyy> <_day>.Your time slot is between <_hhmm1> and <_hhmm2> . Kindly arrive 10 mins before your slot.\n\n<_notes>\n\nRegards'

    // // console.log('flgOnline = ' + flgOnline)
    var vTemplate = myTemplate

    vTemplate = ifOnline(vTemplate, 'Online ', flgOnline)
    vTemplate = vTemplate.replace('<_cls>', _cls)

    vTemplate = vTemplate.replace('<_sec>', _sec)
    // // console.log('ptmDt = ' + ptmDt)
    vTemplate = ifDt(vTemplate, '<_ddmmyyyy>', ptmDt)
    // // console.log('state.startTm = ' + state.startTm)
    // // console.log('state.endTm = ' + state.endTm)
    // // console.log('flgNotes = ' + flgNotes)
    // // console.log('notes = ' + notes)
    vTemplate = ifTm(vTemplate, '<_hhmm1>', state.startTm)
    vTemplate = ifTm(vTemplate, '<_hhmm2>', state.endTm)
    if (flgNotes) {
      vTemplate = ifNotes(vTemplate, '<_notes>', notes)
    } else {
      vTemplate = ifNotes(vTemplate, '<_notes>', '')
    }

    setTemplate(vTemplate) */
  }

  async function getSelLstWingId(pLstWing) {
    var tmpLstWingId = []
    for (var i = 0; i < pLstWing.length; i++) {
      var obj = pLstWing[i]
      // // console.log(i + '::' + (obj.selected === true))
      if (obj.selected === true) {
        tmpLstWingId.push(obj.wingId)
      }
    }
    // // console.log(tmpLstWingId)
    try {
      await setLstSelWingId(tmpLstWingId)
    } catch (error) {
      console.error(error)
    }
  }
  function populate() {
    var tempLstTime = []
    var hours, minutes, ampm
    for (var i = 0; i <= 24 * 59; i += 30) {
      hours = Math.floor(i / 60)
      minutes = i % 60
      if (minutes < 10) {
        minutes = '0' + minutes // adding leading zero
      }
      ampm = hours % 24 < 12 ? 'AM' : 'PM'
      hours = hours % 12
      if (hours === 0) {
        hours = 12
      }
      if (hours < 10) {
        hours = '0' + hours
      }
      tempLstTime.push(hours + ':' + minutes + ' ' + ampm)
    }
    setLstTm(tempLstTime)
  }

  function handlePtmDtChange(param) {
    setPtmDt(param)
  }

  function handleStartTmChange(param) {
    setState({ ...state, startTm: param })
  }

  function handleEndTmChange(param) {
    setState({ ...state, endTm: param })
  }

  function convert(str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2)
    return [day, mnth, date.getFullYear()].join('-')
  }

  async function handleLstWingIdChange(pId, pChecked) {
    var tmpLstWing = lstSelWingId
    pId = parseInt(pId)
    pChecked = !!pChecked
    if (pChecked && !lstSelWingId.includes(pId)) {
      tmpLstWing.push(parseInt(pId))
      setLstSelWingId(tmpLstWing)
    } else {
      tmpLstWing.splice(tmpLstWing.indexOf(pId), 1)
      setLstSelWingId(tmpLstWing)
    }
    // // console.log(tmpLstWing)
    setLstWingId(tmpLstWing)
  }

  function savePtm() {
    // // console.log('savePtm() called')
    var formData = new FormData()
    var ptmObj = {
      ptmId: ptmId,
      ptmDt: convert(ptmDt),
      startTm: state.startTm,
      endTm: state.endTm,
      slotInterval: slotInterval,
      notesToParents: notes,
      lstWings: lstWingId,
      flgSms: flgSms,
      flgWhatsapp: flgWhatsapp,
      flgEmail: flgEmail,
      flgIvr: flgIvr,
      flgOnline: flgOnline,
      objective: objective,
      flgReminder: flgReminder
    }
    formData.append('ptmObj', JSON.stringify(ptmObj))

    // // console.log(ptmObj)
    savePtmSvc(formData, cbResponse)
  }
  function cbResponse(data) {
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      if (ptmId == 0) {
        setPtmId(data.ptmId)
        props.refreshTable(data.ptmId)
      } else {
        props.refreshTable(ptmId)
      }
    } else {
      toast.error(data.SvcMsg)
    }
  }

  function updateOnlineFlg(pChk) {
    setFlgOnline(pChk)
  }

  function ifOnline(myTemplate, keyword, pChk) {
    if (pChk) {
      if (!(myTemplate.indexOf(keyword) > -1))
        myTemplate = myTemplate.replace('PTM', 'Online PTM')
    } else {
      if (myTemplate.indexOf(keyword) > -1)
        myTemplate = myTemplate.replace(keyword, '')
    }

    return myTemplate
  }

  function ifDt(myTemplate, keyword, pDt) {
    if (pDt) {
      // // console.log(keyword + ' found')
      myTemplate = myTemplate.replace(keyword, convert(pDt))
      var day = pDt.getDay()
      if (myTemplate.indexOf('<_day>') > -1) {
        // // console.log('<_day> found')
        myTemplate = myTemplate.replace('<_day>', weekday[day])
      }
    }
    return myTemplate
  }
  function ifTm(myTemplate, keyword, pStartTm) {
    if (pStartTm) {
      // // console.log(keyword + ' found')
      myTemplate = myTemplate.replace(keyword, pStartTm)
    }
    return myTemplate
  }
  function ifNotes(myTemplate, keyword, pNotes) {
    if (pNotes) {
      // // console.log(keyword + ' found')
      myTemplate = myTemplate.replace(keyword, pNotes)
    }
    return myTemplate
  }
  
  const clearForm = () => {
    // // console.log("clearForm called");
    setNotes('');
    setFlgNotes(true);
    setFlgOnline(false);
    setObjective('');
    setSlotInterval(0);
    setFlgSms(false);
    setFlgEmail(false);
    setFlgIvr(false);
    setFlgWhatsapp(false);
    setFlgReminder(false);
    setSmsSentOn('');
    setEmailSentOn('');
    setIvrSentOn('');
    setWhatsappSentOn('');
    setTemplate('');
    setTmpTemplate('');
  }


  return (
    <div className='col-sm-12 padding-remove'>
      <ToastContainer autoClose={3000} hideProgressBar />
      <div
        className='col-sm-12 padding-remove'
        style={{
          height: '60px',
          marginBottom: '1%',
          fontWeight: 'bold',
          fontFamily: 'Arial',
          fontSize: '16px',
          textTransform: 'uppercase',
          padding: '1.6%',
          background: 'transparent',
          borderRadius: '4px',
          border: '1px solid #ccc',
          overflowX: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        Add/Edit PTM
      </div>
      <div className='row'>
        <div className='col-sm-3'>
          <label className='control-label col-sm-6 text-left padding-remove'>
            Date
          </label>
          <div className='col-sm-6'>
            <DatePicker
              id='inp_ptmDate'
              className='form-control text-center'
              placeholderText='Start Date'
              selected={ptmDt}
              dateFormat='dd-MM-yyyy'
              onChange={handlePtmDtChange}
              autoComplete='off'
              showYearDropdown
              showMonthDropdown
              dropdownMode='select'
            />
          </div>
        </div>
        <div className='col-sm-3'>
          <label className='control-label col-sm-6 text-left'>Online</label>
          <div className='col-sm-2'>
            <input
              type='checkbox'
              className='form-control'
              checked={flgOnline}
              onChange={e => {
                updateOnlineFlg(!flgOnline)
              }}
            />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-12 padding-remove'>
          {/* start-end time div starts */}
          <div
            className='col-sm-6'
            style={{
              border: '1px solid #ccc',
              borderRadius: 5,
              padding: 5,
              margin: 10
            }}
          >
            <div className='col-sm-12 padding-remove'>
              <div className='col-sm-6 padding-remove'>
                <label className='control-label col-sm-5 text-left padding-remove'>
                  Start Time
                </label>
                <div className='col-sm-6 form-group'>
                  <select
                    className='form-control'
                    onChange={e => handleStartTmChange(e.target.value)}
                    value={state.startTm}
                  >
                    <option value={0}>Start Time</option>
                    {lstTm.map((time, idx) => {
                      return (
                        <option key={idx} value={lstTm[idx]}>
                          {lstTm[idx]}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>

              <div className='col-sm-6 padding-remove'>
                <label className='control-label col-sm-5 text-left padding-remove'>
                  End Time
                </label>
                <div className='col-sm-6 form-group'>
                  <select
                    className='form-control'
                    onChange={e => handleEndTmChange(e.target.value)}
                    value={state.endTm}
                  >
                    <option value={0}>End Time</option>
                    {lstTm.map((time, idx) => {
                      return (
                        <option key={idx} value={lstTm[idx]}>
                          {lstTm[idx]}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
            </div>
            {/* start-end time div ends */}
            {/* slot interval div starts */}
            <div className='col-sm-6 padding-remove'>
              <label className='control-label col-sm-5 text-left padding-remove'>
                Slot Interval
              </label>
              <div className='col-sm-6 form-group'>
                <input
                  type='number'
                  className='form-control text-right'
                  placeholder='Slot Interval(mins)'
                  defaultValue={slotInterval}
                  onChange={e => setSlotInterval(e.target.value)}
                />
              </div>
            </div>
            {/* slot interval div ends */}
          </div>
          <div
            className='col-sm-5'
            style={{
              border: '1px solid #ccc',
              borderRadius: 5,
              padding: 5,
              margin: 10
            }}
          >
            <label className='control-label col-sm-2 text-left'>Wing</label>
            <div className='col-sm-8 form-group'>
              {ptmId == 0
                ? lstMstWings.map(function (obj, idx) {
                  return (
                    <div key={'w_' + idx}>
                      <label className='control-label col-sm-8 text-left'>
                        {obj.wing}
                      </label>
                      <div className='form-group'>
                        <input
                          type='checkbox'
                          className='form-control'
                          defaultChecked={obj.selected}
                          onChange={e =>
                            handleLstWingIdChange(
                              obj.wingId,
                              e.target.checked
                            )
                          }
                        />
                      </div>
                    </div>
                  )
                })
                : lstWings.map(function (obj, idx) {
                  return (
                    <div key={'w_' + idx}>
                      <label className='control-label col-sm-8 text-left'>
                        {obj.wing}
                      </label>
                      <div className='form-group'>
                        <input
                          type='checkbox'
                          className='form-control'
                          defaultChecked={obj.selected}
                          onChange={e =>
                            handleLstWingIdChange(
                              obj.wingId,
                              e.target.checked
                            )
                          }
                        />
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
        {/* objective div starts */}
        <div className='col-sm-12 padding-remove'>
          <div className='col-sm-3'>
            <label className='control-label col-sm-6 text-left'>
              Objective
            </label>
            <div className='col-sm-6'>
              <input
                type='text'
                className='form-control'
                placeholder='Objective(20 chars)'
                defaultValue={objective}
                onChange={e => setObjective(e.target.value)}
              />
            </div>
          </div>
        </div>
        {/* objective div ends */}
      </div>
      <div className='row'>
        <div
          className='col-sm-11 padding-remove'
          style={{
            border: '1px solid #ccc',
            borderRadius: 5,
            margin: 10,
            padding: 5
          }}
        >
          <div
            className='col-sm-5'
            style={{
              borderRight: '1px solid #ccc'
            }}
          >
            <div className='col-sm-12 padding-remove'>
              <label className='control-label col-sm-3 text-left padding-remove'>
                Communication
              </label>
              <div className='col-sm-9 form-group padding-remove'>
                <div className='col-sm-12 padding-remove'>
                  <label className='control-label col-sm-4 text-left'>
                    WhatsApp
                  </label>
                  <input
                    type='checkbox'
                    className='col-sm-1 form-control'
                    checked={flgWhatsapp}
                    onChange={e => setFlgWhatsapp(e.target.checked)}
                  />
                  <label className='control-label col-sm-7 text-left'>
                    {whatsappSentOn}
                  </label>
                </div>
                <div className='col-sm-12 padding-remove'>
                  <label className='control-label col-sm-4 text-left'>
                    SMS
                  </label>
                  <input
                    type='checkbox'
                    className='col-sm-1 form-control'
                    checked={flgSms}
                    onChange={e => setFlgSms(e.target.checked)}
                  />
                  <label className='control-label col-sm-7 text-left'>
                    {smsSentOn}
                  </label>
                </div>
                <div className='col-sm-12 padding-remove'>
                  <label className='control-label col-sm-4 text-left'>
                    Email
                  </label>
                  <input
                    type='checkbox'
                    className='col-sm-1 form-control'
                    checked={flgEmail}
                    onChange={e => setFlgEmail(e.target.checked)}
                  />
                  <label className='control-label col-sm-7 text-left'>
                    {emailSentOn}
                  </label>
                </div>
                <div className='col-sm-12 padding-remove'>
                  <label className='control-label col-sm-4 text-left'>
                    IVR
                  </label>
                  <input
                    type='checkbox'
                    className='col-sm-1 form-control'
                    checked={flgIvr}
                    onChange={e => setFlgIvr(e.target.checked)}
                  />
                  <label className='control-label col-sm-7 text-left'>
                    {ivrSentOn}
                  </label>
                </div>
              </div>
              <div className='col-sm-12 padding-remove'>
                <label className='control-label col-sm-4 text-left padding-remove'>
                  Notes to Parents
                </label>
                <div className='col-sm-7 padding-remove'>
                  <textarea
                    defaultValue={notes}
                    className='form-control'
                    onChange={async e => {
                      setNotes(e.target.value)
                    }}
                    maxLength='100'
                    rows='6'
                  ></textarea>
                </div>
              </div>
              <div
                className='col-sm-12 padding-remove'
                style={{ marginBottom: '2%' }}
              >
                <label className='control-label col-sm-4 text-left padding-remove'>
                  Reminder
                </label>
                <input
                  type='checkbox'
                  className='form-control col-sm-1'
                  checked={flgReminder}
                  onChange={e => setFlgReminder(e.target.checked)}
                />
              </div>
            </div>
          </div>
          {state.template && (
            <div
              className='col-sm-6'
              style={{ whiteSpace: 'pre-wrap', textAlign: 'left' }}
            >
              {state.template}
            </div>
          )}
        </div>
      </div>
      <div className='col-sm-11 padding-remove'>
        <button className='btn btn-info pull-right' onClick={e => savePtm()}>
          Save
        </button>
      </div>
    </div>
  )
}

export default PtmSlider
