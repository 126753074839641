import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Header from 'common/js/header/header';
import { toast, ToastContainer } from "react-toastify"
/* import '../css/collectionSummary.css' */
import 'common/css/master.css';
import $ from "jquery";
import { Link } from 'react-router-dom';
import lstModules from 'common/json/lstModules.json';
var queryString = require('querystring');
var screenId = 33;
var httpURL = lstModules['fees'].url + ":" + lstModules['fees'].port;
var lstMonth = [
    {
        monthId: 10,
        month: 'Jan'
    },
    {
        monthId: 11,
        month: 'Feb'
    },
    {
        monthId: 12,
        month: 'Mar'
    },
    {
        monthId: 1,
        month: 'Apr'
    },
    {
        monthId: 2,
        month: 'May'
    },
    {
        monthId: 3,
        month: 'Jun'
    },
    {
        monthId: 4,
        month: 'Jul'
    },
    {
        monthId: 5,
        month: 'Aug'
    },
    {
        monthId: 6,
        month: 'Sep'
    },
    {
        monthId: 7,
        month: 'Oct'
    },
    {
        monthId: 8,
        month: 'Nov'
    },
    {
        monthId: 9,
        month: 'Dec'
    }
]


function CollectionSummary() {
    const [state, setState] = useState(
        {
            loader: 'none',
            opacity: 1,
            flg: true,
            tokenid: localStorage.getItem('tokenid'),
            lstMonth: lstMonth,
            checkedAll: false,
            lstCollection: [{}],
            monthId: 1,

        });
    useEffect(() => {
        // // console.log("useEffect")
        getSummary(state.monthId)
    }, [])


    function getSummary(mthId) {
        setState({ ...state, loader: 'block', opacity: 0.5 });
        // // console.log("getSummary called in collectionSumamry.js");
        // // console.log('month Id in getSummary::' + state.monthId)
        axios({
            url: httpURL + '/sms/fee/report/monthly/summary',
            method: "POST",
            params: { monthId: mthId, screenId },
            headers: { 'tokenid': state.tokenid },
            withCredentials: true,
        }).then((response) => {
            // // console.log(response.data);
            if (response.data.SvcStatus === 'Success') {
                setState({
                    ...state,
                    lstCollection: response.data.lstFeeClassMonthlySummary,
                    monthId: mthId,
                });
            }
            else {

                if (response.data.SvcMsg === 'You are not logged in') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                }
                else if (response.data.SvcMsg === 'Your Session is expired, login again') {
                    setTimeout(function () { window.location.href = ('/') }, 2000);
                    return false;
                } else {
                    toast.error(response.data.SvcMsg);
                }
            }
            // setState({ ...state ,loader: 'none', opacity: 1 });
        }).catch((error) => {
            //  setState({ ...state ,loader: 'none', opacity: 1 });
            toast.error("Service failed " + error);
        });
    }



    const changeMonth = () => {
        let screenId = 33;
        var monthId = $('#monthcmb').find('option:selected').attr('id');

        // // console.log("monthID::" + monthId)
        // await setState({ ...state, monthId: monthId });

        if(monthId != 0){
            getSummary(monthId);
        }

        // setState({ ...state, loader: 'block', opacity: 0.5 });
        // axios.post(httpURL + '/sms/fee/report/monthly/summary',
        //     queryString.stringify({ monthId, screenId }), {
        //     headers: { 'tokenid': state.tokenid },
        //     withCredentials: true,
        // }).then((response) => {
        //     if (response.data.SvcStatus === 'Success') {
        //         // // console.log("response.data.lstClassSection = " + response.data.FeeMonthlySummaryReport.lstCollection);
        //         setState({ ...state, lstCollection: response.data.FeeMonthlySummaryReport.lstCollection, });
        //     } else {
        //         toast.error(response.data.SvcMsg);
        //     }
        //     setState({ ...state, loader: 'none', opacity: 1 });
        // }).catch((e) => {
        //     setState({ ...state, loader: 'none', opacity: 1 });
        //     toast.error('Service failed: ' + e);
        // });
    }



    function getWingId(wingId) {
        // // console.log("wingId" + wingId);
    }


    return (
        <div className="container1">
            <Header wingFromChild={getWingId} />
            <ToastContainer autoClose={3000} hideProgressBar />

            <div className="col-md-1 col-md-offset-1" style={{ marginTop: '1%' }}>

                <select autoComplete="off" id="monthcmb" onChange={() => changeMonth()} defaultValue={state.monthId} className="form-control">
                    <option id={0} key={0}>Month</option>
                    {state.lstMonth.map((obj) => {
                        return <option id={obj.monthId} value={obj.monthId} key={obj.month}>{obj.month}</option>
                    })}
                </select>
            </div>
            <br></br>
            <div className="col-md-12 padding-remove">
                <div className="col-md-10 col-md-offset-1" style={{ marginTop: 15 + 'px' }}>
                    <table className="tableLayout1" id='customTable'>
                        <thead>
                            <tr>
                                <th style={{ width: 10 + '%' }} rowSpan="2">Class</th>
                                <th style={{ width: 10 + '%' }} rowSpan="2">Section</th>
                                <th style={{ width: 10 + '%' }} rowSpan="2">Students</th>
                                <th style={{ width: 45 + '%' }} colSpan="3">Collection</th>
                                <th style={{ width: 10 + '%' }} rowSpan="2">ShortFall</th>
                            </tr>
                            <tr>
                                <th style={{ width: 15 + '%' }}>Expected</th>
                                <th style={{ width: 15 + '%' }}>Collected</th>
                                <th style={{ width: 15 + '%' }}>%</th>
                            </tr>
                        </thead>
                    </table>

                    <div className="tableDiv" style={{ height: 500 + 'px' }}>
                        <table id="collectionTable" className="tableLayout">
                            <tbody id="collectionTableBody">
                                {state.lstCollection.map((obj, index) => (
                                    <tr key={index} id={obj.classId}>
                                        <td hidden>{obj.classId}</td>
                                        <td style={{ width: 10 + '%' }} className="text-center">{obj.className}</td>
                                        <td hidden>{obj.sectionId}</td>
                                        <td style={{ width: 10 + '%' }} className="text-center">{obj.section}</td>
                                        <td style={{ width: 10 + '%' }} className="text-center">{obj.studentCount}</td>
                                        <td style={{ width: 15 + '%' }} className="text-center">{obj.expected}</td>
                                        <td style={{ width: 15 + '%' }} className="text-center">
                                            <Link to='/outstanding' className="link">{obj.collected}</Link>
                                        </td>
                                        <td style={{ width: 15 + '%' }} className="text-center">{obj.percentage}</td>
                                        <td style={{ width: 10 + '%' }} className="text-center">
                                            <Link to='/outstanding' className="link">{obj.shortfall}</Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CollectionSummary;