import React, { useEffect, useState } from 'react';
import { Text, View, Image } from '@react-pdf/renderer';

import { styleHeaderLogo, styleHeaderAddress, styleHeaderName } from './examReportCardStyles.js';


const HeaderLogoSection = (props) => {
    const { reportHeader } = props;
    const { logoUrl } = props;
    return (
        <View style={styleHeaderLogo.header_logo_section}>
            <View style={styleHeaderLogo.header_left}>
                <Image style={styleHeaderLogo.image1} src={logoUrl} />
            </View>
            <View style={styleHeaderLogo.header_center}>
                <Text style={styleHeaderLogo.school_header}>
                    {reportHeader.school}<Text style={{ textTransform: "capitalize" }}></Text>
                </Text>
                <Text style={styleHeaderLogo.school_header}>
                    {reportHeader.address}<Text style={{ textTransform: "capitalize" }}></Text>
                    {/* MURADNAGAR, HISALI, U.P. – 201206 */}
                </Text>
                <Text style={styleHeaderLogo.school_header}>
                    {"SESSION - " + reportHeader.session}<Text style={{ textTransform: "capitalize" }}></Text>
                    {/* SESSION – 2023 - 24 */}
                </Text>
            </View>
            <View style={styleHeaderLogo.header_right}>
                <Text></Text>
            </View>
        </View>
    )
};

const HeaderAddressSection = (props) => {
    const { reportHeader } = props;
    return (
        <View style={styleHeaderAddress.header_address_section}>
            <View style={styleHeaderAddress.address_left}>
            </View>
            <View style={styleHeaderAddress.address_center}>
                <View style={styleHeaderAddress.row_1}>
                    <Text style={styleHeaderAddress.text_address_label}>Address</Text>
                    <Text style={styleHeaderAddress.text_address_colon}>:</Text>
                    <Text style={styleHeaderAddress.text_address_value}>{reportHeader.address}</Text>
                </View>
                <View style={styleHeaderAddress.row_1}>
                    <Text style={styleHeaderAddress.text_address_label}>Phone No. </Text>
                    <Text style={styleHeaderAddress.text_address_colon}>:</Text>
                    <Text style={styleHeaderAddress.text_address_value}>{reportHeader.phone}</Text>
                </View>
                <View style={styleHeaderAddress.row_1}>
                    <Text style={styleHeaderAddress.text_address_label}>Affilliation No. </Text>
                    <Text style={styleHeaderAddress.text_address_colon}>:</Text>
                    <Text style={styleHeaderAddress.text_address_value}>{reportHeader.affiliation}</Text>
                </View>
            </View>
            <View style={styleHeaderAddress.address_left}>
            </View>
        </View>
    )
}


const HeaderNameSection = (props) => {
    const { headerName } = props;
    useEffect(() => {
        // // console.log("useEffect in HeaderNameSection called, headerName", headerName);
    }, []);

    return (
        <View style={[styleHeaderName.header_text_section]}>
            <View style={[styleHeaderName.header_text]}>
                <Text style={styleHeaderName.headerNameText}>
                    {headerName}
                </Text>
            </View>
            <View style={{ borderBottom: '2px solid #000', margin: '15px 10px 0 10px' }}>
            </View>
        </View>
    )
}


export { HeaderLogoSection, HeaderAddressSection, HeaderNameSection };