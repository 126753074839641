import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import "common/css/master.css";
import "../../css/subjectAllocation.css";

import TeacherData from "../../json/teacherReport.json";
import TeacherReportTable from "./teacherReportTbl";
import { getTeacherReportListSvc } from "../allocationReportSvc";

const TeacherReportCtrl = (props) => {
    const { setLoader, setOpacity, allocationFiltered } = props;
    const [lstTchSummary, setLstTchSummary] = useState([]);

    useEffect(() => {
        // Fetch teacher data from the JSON file or 
        // setLstTchSummary(TeacherData.teacherData);
        getTeacherList();
    }, []);



    const getTeacherList = () => {
        console.log("getTeacherList called");
        setLoader("block");
        setOpacity(0.5);
        getTeacherReportListSvc(teacherReportListResponse);
    }
    const teacherReportListResponse = (data) => {
        console.log("teacherReportListResponse called, data", data);
        if (data.status === "Success") {
            setLstTchSummary(data.lstTeacher);
        } else if (data.status === "Failure") {
            handleServiceError(data.message);
        } else {
            toast.error("Service failed " + data.error);
        }
        setLoader("none");
        setOpacity(1);
    };


    const handleServiceError = (svcMsg) => {
        if (svcMsg === "You are not Logged In") {
            toast.error("You are not logged in. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else if (svcMsg === "Server Timed Out, Login Again") {
            toast.error("Server timed out. Redirecting to login page...");
            setTimeout(() => {
                window.location.href = "/";
            }, 2000);
        } else {
            toast.error(svcMsg);
        }
    };

    return (
        <div className="col-md-12 col-xs-12 col-centered padding-remove">
            <div className="col-md-12 col-xs-12 col-centered padding-remove" style={{ marginTop: "45px" }}>
                <TeacherReportTable
                    lstTchSummary={lstTchSummary}
                    allocationFiltered={allocationFiltered}
                />
            </div>
        </div>
    );
};

export default TeacherReportCtrl;
