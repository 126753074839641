import React, { useEffect, useState } from 'react'
import ClassList from './classList'
import SectionList from './sectionList'

const HeaderPtmClassSection = props => {
  const [state, setState] = useState({})

  return (
    <div>
      <div
        style={{
          marginTop: '1%',
          boxShadow: 'rgb(204, 204, 204) 0px 1px 4px 0px',
          borderWidth: '1px 0px',
          borderStyle: 'solid',
          height: 40,
          borderColor: 'rgb(204, 204, 204)',
          padding: '5px 20px'
        }}
      >
        <ClassList
          lstCls={props.lstCls}
          classClick={props.changeClass}
          selClsId={props.classId}
        />
        <div className='col-md-10  col-xs-10'>
          <SectionList
            lstSection={props.lstSection}
            sectionClick={props.changeSection}
            selSectionId={props.selSectionId}
          />
        </div>
      </div>
    </div>
  )
}

export default HeaderPtmClassSection
