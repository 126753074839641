import React, { useEffect } from 'react';
import { Text, View, Font } from '@react-pdf/renderer';

import { toast } from "react-toastify";
import { styleCoSchoScore } from './examReportCardStyles.js';

Font.register({
    family: 'Calibri'
    , fonts: [{ src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibri.ttf" }
        , { src: "https://github.com/jondot/dotfiles/blob/master/.fonts/calibrib.ttf", fontWeight: "bold" }], format: 'truetype'
});


const CoScholasticSection = (props) => {
    const { studentHeader, lstCoscholastic, studentSummary, exam } = props;



    return (
        <View style={styleCoSchoScore.container}>
            <View style={styleCoSchoScore.score_table_coscho}>
                <View style={styleCoSchoScore.exam_list}>
                    <View style={[styleCoSchoScore.exam_row, { flex: 1 }]}>
                        <View style={[styleCoSchoScore.top_left_cell, { flex: 1, backgroundColor: '#f6f6f6' }]}>
                            <Text style={styleCoSchoScore.text_schoExam_label}>Grade Subjects</Text>
                        </View>
                        <View style={[styleCoSchoScore.top_cell, { flex: 1, backgroundColor: '#f6f6f6' }]}>
                            <Text style={styleCoSchoScore.text_schoExam_label}>Grade</Text>
                        </View>
                    </View>
                    {lstCoscholastic && lstCoscholastic.length ?
                        lstCoscholastic.map((subject, idx) => (
                            <View key={subject.subId} style={[styleCoSchoScore.exam_row, { flex: 1 }]}>
                                <View style={[styleCoSchoScore.left_cell, { flex: 1 }]}>
                                    <Text style={styleCoSchoScore.text_subject_label}>{subject.sub}</Text>
                                </View>
                                <View style={[styleCoSchoScore.inner_cell, { flex: 1 }]}>
                                    {
                                        !subject.attendance ?
                                            <Text style={[styleCoSchoScore.text_subject_score, { color: "red" }]}>{"Ab"}</Text>
                                            :
                                            <Text style={styleCoSchoScore.text_subject_score}>{subject.grade}</Text>
                                    }
                                </View>
                            </View>
                        )) :
                        <View style={[styleCoSchoScore.exam_row, { flex: 1 }]}>
                            <View style={[styleCoSchoScore.left_cell, { flex: 1 }]}>
                            </View>
                        </View>
                    }
                </View>
                <View style={[styleCoSchoScore.remarks_list, { flex: 3 }]}>
                    <View style={[ { flex: 1 }]}>
                        <Text style={styleCoSchoScore.text_remark_label}>{"REMARKS"}</Text>
                    </View>
                    <View style={[styleCoSchoScore.remark_data, { flex: 5 }]}>
                        <Text style={[styleCoSchoScore.text_remark, ]}>{studentHeader.remarks}</Text>
                    </View>
                </View>
            </View>
        </View>
    );
}

export default CoScholasticSection;