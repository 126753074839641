import React, { Component, useEffect, useState } from "react";
import LoaderImage from "common/components/LoaderImage";
function ViewDatesheet(props) {
    return (
        <div>
            <div className="pdf" style={{ width: "100%", height: "96vh" }}>
                <iframe
                    src={require("common/images/loader.gif")}
                    title="Outstanding Fee"
                    id="viewer"
                    style={{ width: "100%", height: "100%" }}
                />
            </div>
        </div>
    );
}
export default ViewDatesheet;
