import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import { getAttendanceSvc, getMonthSummary } from './MonthlyAttendanceSvc';


const MonthlyAttendanceTable = (props) => {
    const [loader, setLoader] = useState("none");
    const [lstAttendance, setLstAttendance] = useState([])
    const [lstSummary, setLstSummary] = useState([])
    const [nameKeyword, setNameKeyword] = useState('')
    const [lstFoundStudent, setLstFoundStudent] = useState([]);

    useEffect(() => {
        if (props.monthId && props.sectionId) {
            setLoader('block')
            getAttendanceSvc(props.monthId, props.sectionId, cbAttendanceResponse);
        } else {
            setLstAttendance([])
            setLstSummary([])
        }
    }, [props.monthId, props.sectionId])

    function cbAttendanceResponse(data) {
        setLoader('none')
        if (data.SvcStatus === "Success") {
            setLstAttendance(data.lstAttendance)
            setLstFoundStudent(data.lstAttendance);
            getMonthSummary(props.monthId, props.sectionId, cbMonthSummaryResponse);
            // // console.log("data.lstAttendance in cbAttendance response",data.lstAttendance);
        } else {
            toast.error(data.SvcMsg)
        }
    }
    function cbMonthSummaryResponse(data) {
        setLoader('none')
        if (data.status === "Success") {
            setLstSummary(data.lstSummary)
            props.setPresentPct(data.presentPct)
        } else {
            toast.error(data.SvcMsg)
        }
    }

    const filterByName = async (e) => {
        // // console.log(e.key)
        if (!e.target.value) {
            setLstFoundStudent(lstAttendance)
        }

        const keyword = e.target.value.toLowerCase();
        await setNameKeyword(keyword);
        filterFn()

    };

    async function filterFn() {

        var tmpLst = []

        for (var i = 0; i < lstAttendance.length; i++) {
            var obj = lstAttendance[i]
            var nm = obj.name.toLowerCase();
            if (nameKeyword !== '' && !nm.includes(nameKeyword)) {
                continue;
            }
            tmpLst.push(obj)

        }
        await setLstFoundStudent(tmpLst)
    }

    const colgrp = <colgroup>
        <col width="6%"></col>
        <col width="22%"></col>
        <col width="6%"></col>
        {lstAttendance.length > 0 && lstAttendance[0].lstDate.map((subObj, subIdx) => {
            return <col key={'c_' + subIdx} width={(63 / lstAttendance[0].lstDate.length) + "%"}></col>
        })}

    </colgroup>


    return (
        <div style={{ pointerEvents: loader === "none" ? "auto" : "none" }}>
            <div style={{ position: 'absolute', zIndex: 10000, width: 100, height: 100, marginLeft: -50, marginTop: -50, left: '50%', top: '50%', display: loader }}>
                <img className="loderImg" src={require("common/images/loader.gif")} alt="Loader..." />
            </div>
            <table className='tableLayout1'>
                {colgrp}
                <thead>
                    <tr>
                        <th>Roll No.</th>
                        <th><input className="form-control inp-search" placeholder="Name" onChange={(e) => filterByName(e)} onKeyUp={(e) => filterByName(e)} /></th>
                        <th>Gender</th>
                        {lstAttendance.length > 0 && lstAttendance[0].lstDate.map((subobj, subIdx) => {
                            return <th id={'d_' + subIdx} key={'d_' + subIdx}>{subobj.dt}</th>
                        })}
                    </tr>
                </thead>
            </table>
            <div style={{ height: '57vh', overflowY: 'scroll', border: "1px solid #ccc" }} >
                <table className='tableLayout'>
                    {colgrp}
                    <tbody>
                        {lstFoundStudent.length > 0 && lstFoundStudent.map((obj, idx) => {
                            return <tr key={idx} id={obj.studentId}
                                onClick={(e) => props.setStudentId(obj.studentId)} className={obj.studentId === props.studentId ? "selected" : ""}
                            >
                                <td className="no-display">{obj.studentId}</td>
                                <td>{obj.rollNo}</td>
                                <td className="text-left">{obj.name}</td>
                                <td className="text-center">{obj.gender}</td>
                                {lstAttendance[idx] && lstAttendance[idx].lstDate.map((subobj, subIdx) => {
                                    return <td
                                        id={'s_' + obj.studentId + '_dt_' + subIdx}
                                        key={'s_' + obj.studentId + '_dt_' + subIdx}
                                        style={{ color: subobj.attendance === "P" ? '#aaa' : '' }}>
                                        {subobj.attendance === "A" ?
                                            <span className="glyphicon glyphicon-remove-circle" style={{ marginLeft: 0, padding: 0, fontSize: '2rem', color: '#f25e53' }}></span>
                                            : subobj.attendance}</td>
                                })}
                            </tr>
                        })}

                    </tbody>
                </table>
            </div>
            {lstFoundStudent.length > 0 && 
                <table className='tableLayout1'>
                {colgrp}
                <tbody>
                    <tr>
                        <td className="text-left">TOTALS</td>
                        <td className="text-left">P</td>
                        <td></td>
                        {lstSummary.map((sumObj, sumIdx) => {
                            return <td key={'sum_p_' + sumIdx}>{sumObj.present}</td>
                            
                        })}
                    </tr>
                    <tr>
                        <td></td>
                        <td className="text-left">A</td>
                        <td></td>
                        {lstSummary.map((sumObj, sumIdx) => {
                            return <td key={'sum_p_' + sumIdx}>{sumObj.absent}</td>

                        })}
                    </tr>
                    <tr>
                        <td></td>
                        <td className="text-left">L</td>
                        <td></td>
                        {lstSummary.map((sumObj, sumIdx) => {
                            return <td key={'sum_p_' + sumIdx}>{sumObj.leave}</td>
                            
                        })}
                    </tr>

                </tbody>
            </table>
                    }
        </div>
    )
}

export default MonthlyAttendanceTable