import React, { useState, useEffect } from 'react';
import "common/css/master.css";
import "../css/measureCard.css";

const MeasureCard = (props) => {
    const [obj, setObj] = useState({
        measureId: 1
        , measure: 'Self-Attendance'
        , remarks: 'Based on #Attendance',
        lstScore: [{ key: '0', score: 20 }
            , { key: '1', score: 15 }
            , { key: '2', score: 10 }
            , { key: '4', score: 5 }
            , { key: 'otherwise', score: 0 }]
    })
    useEffect(() => {
        setObj(props.obj);
    }, [props.obj])
    return (
        <div className="row">
            <div className="col-centered padding-remove">

                <div className="card padding-remove" style={{ height: '100%', border: '1px solid #aaa', cursor: obj.disabled ? 'not-allowed' : 'pointer' }}>
                    <div className="card-body" style={{ height: '100%' }}>
                        <h6 className="card-title text-center" style={{ height: '34px', fontSize: '14px', padding: '2%', margin: 0, background: 'cornflowerblue', color: 'rgb(255, 255, 255)', width: '100%' }}>{obj.measure}</h6>
                        <div className="col-sm-12" style={{ height: '77px' }}><label className="control-label text-center card-subtitle mb-2 text-muted" style={{ padding: '4%', margin: 0, background: '#fff', width: '100%' }}>{obj.remarks}</label></div>
                        <div className="col-centered" style={{ position: 'relative' }}>
                            {obj.disabled && <div style={{ position: 'absolute', height: '200px', width: '200px' }}><img height="200" width="200" style={{ opacity: '0.7', filter: 'alpha(opacity=80)' }} src={require("../images/prohibit.png")} /></div>}
                            <table style={{ position: 'relative' }} className="tableLayout">
                                <tbody>
                                    {obj.lstScore.map((subObj, idx) => {
                                        return <tr key={idx}>
                                            <td className="text-center" style={{ cursor: obj.disabled ? 'not-allowed' : 'pointer' }}>{subObj.key}</td>
                                            <td className="text-center" style={{ cursor: obj.disabled ? 'not-allowed' : 'pointer' }}>{subObj.score}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default MeasureCard