import React, { useState, useEffect } from 'react'
import 'jquery-ui';
import 'common/css/master.css';
import { toast, ToastContainer } from 'react-toastify';
import TchCellDtls from './ttTeacherCellDtls';
import TchSubjectTableBody from './tchsubjectTableBody';
/* var sectionId = 3;
var sessionId = 1;
var classId = 4;
 */var cellDtls = [];

var lstDay = [
    {
        dayId: 1,
        day: 'Mon'
    },
    {
        dayId: 2,
        day: 'Tue'
    },
    {
        dayId: 3,
        day: 'Wed'
    },
    {
        dayId: 4,
        day: 'Thu'
    },
    {
        dayId: 5,
        day: 'Fri'
    },
    {
        dayId: 6,
        day: 'Sat'
    },
    {
        dayId: 7,
        day: 'Sun'
    },
];


const TTTeacherBody = (props) => {
    const [state, setState] = useState({
        //lstDay: [],
        cellDtls: [],
        // selectedPeriodId: 0,
        // selectedDayId: 0,
        selCellDtls: {
            selDayId: props.selDayId,
            selPeriodId: props.selPeriodId
        },

    })
    const [lstDay, setLstDay] = useState(props.lstDay)
    const [lstPeriod, setLstPeriod] = useState(props.lstPeriod)
    const [selPeriodId, setSelPeriodId] = useState(props.selPeriodId);
    // const [refreshDayId, setRefreshDayId] = useState(props.refreshDayId);
    // const [refreshPeriodId, setRefreshPeriodId] = useState(props.refreshPeriodId);
    const [selDayId, setSelDayId] = useState(props.selDayId);
    const [cellRefresh, setCellRefresh] = useState(true)

    useEffect(() => {
        setLstPeriod(props.lstPeriod)
        // setSelDayId(props.selDayId)
        // setSelPeriodId(props.selPeriodId)
        setLstDay(props.lstDay)
        // // console.log(props.lstDay);
        // // console.log(Object.keys)
        // // console.log('props: ' + JSON.stringify(props));
        if (props.selDayId && props.selPeriodId) {
            setSelDayId(props.selDayId)
            setSelPeriodId(props.selPeriodId)

        }

    }, [props.selDayId, props.selPeriodId])
    function generateRandNo() {
        return Math.floor(Math.random() * 999999);

    }
    function onClickCell(e, pDayId, pPeriodId, pClassSectionId) {
        // // console.log(e.target.closest('td').find('div.sub'))
        // // console.log(e.target.closest('td').querySelector('div.sub'))
        var subCtr = parseInt(e.target.closest('td').querySelector('div.sub').getAttribute('pos'));
        // // console.log(subCtr);
        // props.onClickCell(e, pDayId, pPeriodId, pClassSectionId, subCtr);

    }

    return (
        <>
            <div className="col-sm-12" style={{ width: '66%', height: '100%', paddingLeft: 0 }}>
                <table className="tableLayout">
                    <thead>
                        <tr>
                            <th style={{ width: 28, background: 'darkgray' }}> Periods </th>
                            {lstDay.map((cell, j) => {
                                return (cell.working && <th style={{ width: 38, background: 'darkgray' }} key={j}>{cell.day}</th>
                                    || !cell.working && <th style={{ width: 10, background: 'darkgray' }} key={j}>{cell.day}</th>)
                            })}

                        </tr>
                    </thead>
                </table>
                <div className="tableLayout" style={{ height: 'auto-fit' }} >
                    <table id="" className="tableLayout">
                        <colgroup>
                            <col width="27" />
                            {lstDay.map((cell, j) => {
                                return (cell.working && <col key={j} width="38" />
                                    || !cell.working && <col key={j} width="10" />)
                            })}
                        </colgroup>
                        <tbody id="timeLayout">
                            {lstPeriod && lstPeriod.map((obj, idk) => {
                                return ((obj.periodType !== 'Regular' &&
                                    <tr style={{ background: 'transparent' }} height="10" key={idk}>
                                        <td
                                            style={{
                                                width: 40
                                                , textAlign: 'center'
                                                , borderRight: '1px solid gainsboro'
                                                , borderBottom: '1px solid gainsboro'
                                                , background: '#eee'
                                            }}
                                            key={obj.periodId}
                                            id={obj.periodId}>
                                            <p style={{ margin: 0 }}>{obj.timings}</p>
                                        </td>
                                        {
                                            obj.periodType !== 'Regular' &&
                                            <td colSpan="7" className="text-center" style={{ background: '#eee' }}>
                                                <label>{obj.period}</label>
                                            </td>
                                        }
                                    </tr>)
                                    ||
                                    (obj.periodType === 'Regular' &&
                                        <tr style={{ background: 'transparent' }} height="10" key={idk}>
                                            <td
                                                style={{
                                                    width: 40
                                                    , textAlign: 'center'
                                                    , borderRight: '1px solid gainsboro'
                                                    , borderBottom: '1px solid gainsboro'
                                                    , background: '#eee'
                                                }}
                                                key={obj.periodId}
                                                id={obj.periodId}>
                                                <label>{obj.period}</label>
                                                <p style={{ margin: 0 }}>{obj.timings}</p>
                                            </td>
                                            {obj.periodType === 'Regular' && lstDay &&
                                                lstDay.map((cell, j) => {
                                                    var isCellSelected = obj.periodId === parseInt(selPeriodId) && cell.dayId === parseInt(selDayId);
                                                    return <td style={{ width: cell.working === false ? 20 : 40 }} key={j}
                                                        className={cell.working === false
                                                            ? "unused-cell cell" : "cell"/* (isCellSelected ? "selected-cell" : "cell") */}
                                                        selcelldtls={JSON.stringify(cell)}
                                                        /*selcellid={cellDtls.cellId}
                                                        seldayid={cell.dayId}
                                                        selperiodid={obj.periodId}*/
                                                        /*onClick={(e) => onClickCell(e, cell.dayId, obj.periodId, props.classSectionId)}*/>
                                                        {cell.working && <TchCellDtls
                                                            key={cell.dayId === props.selDayId && obj.periodId === props.selPeriodId ? Math.floor(Math.random() * 999999) : "d_" + cell.dayId + "_p_" + obj.periodId}
                                                            /* key={props.cellId}
                                                             cellId={refreshDayId && refreshPeriodId ? ('p_' + refreshPeriodId + '_d_' + refreshDayId) : 'd_' + idk + '_p_' + j}
                                                             refresh={cell.dayId == refreshDayId && obj.periodId == refreshPeriodId}*/
                                                            dayId={cell.dayId}
                                                            classId={props.classId}
                                                            periodId={obj.periodId}
                                                            sessionId={props.sessionId}
                                                            sectionId={props.classSectionId}
                                                            cellId={props.cellId}
                                                            cellDtls={JSON.stringify(cell)}
                                                            selCellDtls={props.selCellDtls}
                                                            cellRefresh={cellRefresh}
                                                            setCellRefresh={setCellRefresh}
                                                            selCellId={props.selCellId}
                                                            selSubPosId={props.selSubPosId}
                                                            // selectCellDtls={props.selectCellDtls}
                                                            onClickSubCell={props.onClickSubCell}
                                                            selTchId={props.selTchId}
                                                        /* selDayId={selDayId}
                                                         selPeriodId={selPeriodId}
                                                         refreshDayId={refreshDayId}
                                                         refreshPeriodId={refreshPeriodId}*/
                                                        />}
                                                    </td>
                                                })}
                                        </tr>))
                            })}
                            {/* <tr style={{height: 50}}>
                        <td>
                            <label textAlign="centre">0</label><br/>
                            <p>10:00 2:00</p>
                        </td>
                        <th colSpan="6" style={{height: 50}}><label>ASSEMBLY</label></th>
                    </tr> */}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-sm-6 padding-remove" style={{ width: '34%', height: '100%' }}>
                {props.selClsLabel !== '' && props.selSectionLabel !== '' && props.lstTch
                    && props.lstTch.length !== 0 && <TchSubjectTableBody
                        key={props.tchbodyKey}
                        lstTch={props.lstTch}
                        selTchId={props.selTchId}
                        rowClick={props.rowClick}
                        classLabel={props.selClsLabel}
                        sectionLabel={props.selSectionLabel}
                        sortAscTotal={props.sortAscTotal}
                        sortDscTotal={props.sortDscTotal}
                        sortAscAllocation={props.sortAscAllocation}
                        sortDscAllocation={props.sortDscAllocation}
                        selCellDtls={props.selCellDtls}
                        selSubPosId={props.selSubPosId}
                        setSelCellDtls={props.setSelCellDtls}
                        setCellId={props.setCellId}
                        setCellRefresh={props.setCellRefresh}
                        setCellProps={props.setCellProps}
                    />}
            </div>
        </>
    )

}

export default TTTeacherBody;