import React, { useEffect, useState } from 'react'
import AcademicYearWingTable from './acdYearWingTbl';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast, ToastContainer } from 'react-toastify';
import { getAcademicYearDtlsSvc, saveAcademicYearSvc } from './academicYearSvc';


const AcademicYearSlider = (props) => {

    const [isSchoolFormSelected, setIsSchoolFormSelected] = useState(false);
    const [isWingFormSelected, setIsWingFormSelected] = useState(false);
    const [applyLevel, setApplyLevel] = useState(0);
    const [academicYearName, setAcademicYearName] = useState("");
    const [academicYearId, setAcademicYearId] = useState(0);
    const [fromDt, setFromDt] = useState("");
    const [toDt, setToDt] = useState("");
    const [isDefaultSelected, setIsDefaultSelected] = useState(false);
    const [isLocked, setIsLocked] = useState(false);


    const [lstAcademicYearWing, setLstAcademicYearWing] = useState([]);
    const [sliderKey, setSliderKey] = useState(0);


    useEffect(() => {
        // // console.log("academicYearSlider called, props", props);


        setAcademicYearId(props.academicYearId);
        if (props.academicYearId) {
            getAcademicYearData(props.academicYearId);
        }
    }, [sliderKey, props.academicYearId]);

    // const changeWingSchool = () => {
    // }

    const getAcademicYearData = (acdYrId) => {
        getAcademicYearDtlsSvc(acdYrId, academicYearDataResponse);
    }

    const academicYearDataResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            setLstAcademicYearWing(obj.lstAcademicYearWing);
            setAcademicYearName(obj.academicYearData.academicYear);
            setFromDt(setDateFormat(obj.academicYearData.fromDt));
            setToDt(setDateFormat(obj.academicYearData.toDt));
            setApplyLevel(obj.academicYearData.applyLevel);
            setIsDefaultSelected(obj.academicYearData.dflt);
            setIsLocked(obj.academicYearData.locked);
            setIsSchoolFormSelected(obj.academicYearData.applyLevel === 10);
            setIsWingFormSelected(obj.academicYearData.applyLevel === 20);
            // // console.log("obj", obj);
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        props.setLoader("none");
        props.setOpacity(1);
    }



    const selectApplyLevel = (applLvl) => {
        if (applLvl === "school") {
            setIsWingFormSelected(false);
            setIsSchoolFormSelected(true);
            setApplyLevel(10);
        } else if (applLvl === "wing") {
            setIsSchoolFormSelected(false);
            setIsWingFormSelected(true);
            setApplyLevel(20);
        }
    }

    const saveAcademicYearForm = () => {
        // // console.log("save called ");
        // let academicYearId = 0;
        // let academicYear = academicYearName;
        // let locked = isLocked;
        // let dflt = isDefaultSelected;
        if (academicYearName === "") {
            toast.warning("Please Fill Academic Name");
            return false;
        }
        if (fromDt === "") {
            toast.warning("Please Fill From Date");
            return false;
        }
        if (toDt === "") {
            toast.warning("Please Fill To Date");
            return false;
        }
        if (applyLevel === 0) {
            toast.warning("Please Select Apply At");
            return false;
        }
        
        props.setLoader("block");
        props.setOpacity(0.5);
        const academicYearObj = {
            "academicYearId": academicYearId,
            "academicYear": academicYearName,
            "fromDt": toDMY(fromDt),
            "toDt": toDMY(toDt),
            "applyLevel": applyLevel,
        };
        if (isSchoolFormSelected) {
            academicYearObj.locked = isLocked;
            academicYearObj.dflt = isDefaultSelected;
        }

        saveAcademicYearSvc(academicYearObj, saveAcademicYearResponse);
    }

    const saveAcademicYearResponse = (data) => {
        var obj = data;
        if (obj.status === 'Success') {
            // // console.log("obj", obj);
            toast.success(obj.message);
            props.changeKey();
            props.closeSlider();
        }
        else {
            if (obj.message == 'You are not logged in') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            }
            else if (obj.message == 'Your Session is expired, login again') {
                setTimeout(function () {
                    window.location.href = "/";
                }, 2000);
                return false;
            } else {
                toast.error(obj.message);
            }
        }
        props.setLoader("none");
        props.setOpacity(1);
    }

    const setDateFormat = (dt) => {
        if (dt === "00-00-0000" || dt === null || dt === undefined) {
            return "";
        }
        var date = dt;
        date = date.split('-');
        var setDate = date.reverse().join();
        setDate = setDate.replace(/,/g, '-');
        var formatDt = new Date(setDate);
        return formatDt;
    }

    const handleChangeDateFormat = (dt) => {
        // // console.log("handleChangeDateFormat called date received", dt);
        if(!!dt)
        var ddmmyyyy = toDMY(dt);
        var formatedDate = setDateFormat(ddmmyyyy);
        // // console.log("date returned", formatedDate);
        return formatedDate;
    }
    
    const toDMY = (dt) => {
        var tmpDd = dt.getDate();
        var dd = tmpDd < 9 ? "0" + tmpDd : tmpDd;
        var mon = dt.getMonth() + 1;
        var mm = mon < 10 ? "0" + mon : mon;
        let dmyDate = dd + "-" + mm + "-" + dt.getFullYear();
        return dmyDate;
    }

    const changeSliderKey = () => {
        // // console.log("call changeKey");
        setSliderKey(Math.round(Math.random() * 99999));
    }

    const closeSlider = () => {
        props.changeKey();
        props.closeSlider();
    }

    return (
        <div className="col-xs-12 col-centered padding-remove ">
            <ToastContainer autoClose={3000} hideProgressBar />
            <div>
                <button type="button" className='btn btn-info text-center btnCloseDiv' onClick={closeSlider} style={{ right: "60%" }}>
                    <span id="close" title="Close Slider" className="glyphicon glyphicon-remove"></span>
                </button>

            </div>
            <div className="col-xs-12 col-centered padding-remove " style={{ height: '100vh', width: '100%', background: '#fff', border: '1px solid #ccc' }}>
                <form className="form-horizontal formWrapper" style={{ width: '80%', borderRadius: 10, marginLeft: "10%", marginTop: "50px" }}>
                    <div className="form-group col-md-8">
                        <label className="control-label col-sm-6" > Name <span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                        <div className="col-sm-6 padding-remove">
                            <input
                                autoComplete="off"
                                type="text"
                                className="form-control"
                                value={academicYearName}
                                onChange={(e) => setAcademicYearName(e.target.value)}
                                placeholder="2020-21"
                            />
                        </div>
                    </div>
                    <div className="form-group col-md-8">
                        <label className="control-label col-sm-6" >From Dt.<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                        <div className="col-sm-6 padding-remove">
                            <DatePicker id="fromDt"
                                className='form-control'
                                placeholderText="Enter From Date"
                                selected={fromDt}
                                onChange={(dt) => setFromDt(handleChangeDateFormat(dt))}
                                dateFormat="dd-MM-yyyy"
                                dropdownMode="select"
                                autoComplete='off'
                                showMonthDropdown
                                showYearDropdown
                                adjustDateOnChange
                            />
                        </div>
                    </div>

                    <div className="form-group col-md-8">
                        <label className="control-label col-sm-6" >To Dt.<span style={{ color: 'red', marginLeft: 2 }}>*</span></label>
                        <div className="col-sm-6 padding-remove">
                            <DatePicker id="toDt"
                                className='form-control'
                                placeholderText="Enter To Date"
                                selected={toDt}
                                onChange={(dt) => setToDt(handleChangeDateFormat(dt))}
                                dateFormat="dd-MM-yyyy"
                                dropdownMode="select"
                                autoComplete='off'
                                showMonthDropdown
                                showYearDropdown
                                adjustDateOnChange
                            />
                        </div>
                    </div>
                    <div className="form-group col-md-8">
                        <label className="control-label col-sm-6" > Apply At:</label>
                        <div className="col-sm-6 padding-remove radioButtonsWraps">
                            <input id="school" type="radio" checked={isSchoolFormSelected} onChange={() => selectApplyLevel("school")} name="applyAt" value="school" />
                            <label htmlFor="school">School</label>
                            <input id="wing" type="radio" checked={isWingFormSelected} onChange={() => selectApplyLevel("wing")} name="applyAt" value="wing" />
                            <label htmlFor="wing">Wing</label>
                        </div>
                    </div>
                    {isSchoolFormSelected &&
                        <div className="schoolFormWrap">
                            <div className="form-group col-md-8">
                                <label className="control-label col-sm-6" > Default</label>
                                <div className="col-sm-6 padding-remove">
                                    <input
                                        autoComplete="off"
                                        type="checkbox"
                                        className="form-control"
                                        checked={isDefaultSelected}
                                        onChange={(e) => setIsDefaultSelected(!isDefaultSelected)}
                                    />
                                </div>
                            </div>
                            <div className="form-group col-md-8">
                                <label className="control-label col-sm-6" > Locked</label>
                                <div className="col-sm-6 padding-remove">
                                    <input
                                        autoComplete="off"
                                        type="checkbox"
                                        className="form-control"
                                        checked={isLocked}
                                        onChange={() => setIsLocked(!isLocked)}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    <div className="form-group" >
                        <div className="col-sm-12 text-center multiButtonWrap" >
                            <button type="button" style={{ marginTop: 20 }} onClick={() => saveAcademicYearForm()} className="btn btn-success">Save</button>
                        </div>
                    </div>
                </form>
                {isWingFormSelected && 
                    academicYearId > 0 &&
                    lstAcademicYearWing && lstAcademicYearWing.length > 0 &&
                    <div className=" col-xs-12 padding-remove col-centered wingFormWrap clearfix">
                        <form id="addForm" className="form-horizontal formWrapper clearfix" style={{ width: '80%', borderRadius: 10, marginLeft: "10%", marginTop: "10px" }}>
                            <div className="col-xs-10 col-centered">
                                <AcademicYearWingTable
                                    setLoader={props.setLoader}
                                    setOpacity={props.setOpacity}
                                    academicYearId={academicYearId}
                                    lstAcademicYearWing={lstAcademicYearWing}
                                    closeSlider={props.closeSlider}
                                    changeSliderKey= {changeSliderKey}
                                />

                            </div>
                        </form>
                    </div>
                }
            </div>

        </div>
    );
}

export default AcademicYearSlider;