import React, { useEffect, useState } from 'react';
import { Text, View, Image } from '@react-pdf/renderer';

import { styleHeaderLogo, fontStyles } from './styleBonafidePdf';

const BonafideHeader = (props) => {
    const { schoolLogo, schoolName } = props;

    useEffect(() => {
        // // console.log("BonafideHeader component called");
        // // console.log("schoolLogo", schoolLogo);
        // // console.log("schoolName", schoolName);
    }, [schoolLogo, schoolName]);

    return (
        <View style={styleHeaderLogo.header_logo_section}>
            <View style={styleHeaderLogo.header_left}>
                <Image style={styleHeaderLogo.image1} src={schoolLogo} />
            </View>
            <View style={styleHeaderLogo.header_center}>
                <Text style={fontStyles.textSchool}>
                    {schoolName}<Text style={{ textTransform: "capitalize" }}></Text>
                </Text>
            </View>
            <View style={styleHeaderLogo.header_right}>
                <Text></Text>
            </View>
        </View>
    )
}

export default BonafideHeader;