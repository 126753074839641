import axios from 'axios';
import lstModules from 'common/json/lstModules.json';

const httpURL = lstModules['performance'].url + ':' + lstModules['performance'].port;

var tokenid = localStorage.getItem("tokenid");

function getLstTermSvc(callback) {
    var obj = { status: "Success", message: "", lstTerm: [] }
    axios({
        url: httpURL + '/sms/performance/mst/term/list',
        method: "POST",
        params: {},
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {
        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === "Success") {
            obj.lstTerm = response.data.lstTerm;
        }
        // // console.log('data.SvcStatus=' + response.data.SvcStatus);
        callback(obj);
    }).catch((error) => {
        obj.status= "Exception";
        obj.message = error;
        callback(obj);
    });
}
export { getLstTermSvc }