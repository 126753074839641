import React, { useState, useEffect } from 'react'
import '../css/EnquiryCtrl.css'
import { toast, ToastContainer } from 'react-toastify'
import 'react-datepicker/dist/react-datepicker.css'
import Datepicker from 'react-datepicker'
import { getTestResultDtlsSvc } from './EvaluateTestSvc'
import { saveResultSvc } from './EvaluateTestSvc'
import SliderType from './sliderType'

const ResultForm = props => {
  const [admissionId, setAdmissionId] = useState(props.admissionId)
  const [testSlotId, setTestSlotId] = useState(props.testSlotId)
  const [evaluationDt, setEvaluationDt] = useState('')
  const [maxScore, setMaxScore] = useState('')
  const [minScore, setMinScore] = useState('')
  const [score, setScore] = useState('')
  const [result, setResult] = useState('')
  const [remarks, setRemarks] = useState('')
  const [lstResult, setLstResult] = useState([])
  const [loader, setLoader] = useState(props.loader)
  function getDateForCalendar (strDt) {
    if (!strDt) return null
    var parts = strDt.split('-')
    var d = parts[0]
    var m = parts[1] - 1
    var y = parts[2]

    return new Date(y, m, d)
  }
  function handleEvaluationDtChange (param) {
    var m = param.getMonth() + 1
    if (m < 10) {
      m = '0' + m
    }
    var d = param.getDate()
    if (d < 10) {
      d = '0' + d
    }
    const dt = d + '-' + m + '-' + param.getFullYear()
    setEvaluationDt(dt)
  }
  function handleScoreChange (param) {
    setScore(param)
  }
  function handleResultChange (param) {
    setResult(param)
  }
  function handleRemarksChange (param) {
    setRemarks(param)
  }
  function cbTestResultDtlsSvc (data) {
    if (data.SvcStatus === 'Success') {
      if (typeof data === 'String') {
        return
      }
      setLstResult(data.evalResultDtls)
      // // console.log(data)
      setEvaluationDt(
        data.evalResultDtls.evaluationDt == '-'
          ? null
          : data.evalResultDtls.evaluationDt
      )
      setMaxScore(data.evalResultDtls.maxScore)
      setMinScore(data.evalResultDtls.passScore)
      setScore(data.evalResultDtls.score)
      setRemarks(data.evalResultDtls.remarks)
      setResult(data.evalResultDtls.result)
    } else {
      toast.error(data.SvcMsg)
    }
  }
  function saveResult () {
    setLoader('block')
    const resultObj = {
      testSlotId: testSlotId,
      evaluationDt: evaluationDt,
      score: score,
      result: result,
      remarks: remarks
    }

    saveResultSvc(resultObj, cbSaveResult)
    // // console.log(resultObj)
  }
  function cbSaveResult (data) {
    setLoader('none')
    if (data.SvcStatus === 'Success') {
      toast.success(data.SvcMsg)
      props.generateRandomNo()
      props.closeSlider()
    } else {
      toast.error(data.SvcMsg)
    }
  }
  useEffect(() => {
    // // console.log(props.flgSliderType + '-' + SliderType.SLIDER_TEST_RESULT)
    if (props.flgSliderType === SliderType.SLIDER_TEST_RESULT) {
      setAdmissionId(props.admissionId)
      setTestSlotId(props.testSlotId)
      getTestResultDtlsSvc(cbTestResultDtlsSvc, props.testSlotId)
      // // console.log('ResultForm testslotid' + props.testSlotId)
    }
  }, [props.admissionId, props.testSlotId, props.flgSliderType])
  return (
    <form
      className='form form-horizontal'
      style={{
        width: '100%',
        overflowX: 'hidden',
        margin: '0 auto',
        background: '#fff',
        border: '1px solid #ccc',
        borderRadius: 10
      }}
    >
      <div
        className='col-sm-12'
        style={{ pointerEvents: loader === 'none' ? 'auto' : 'none' }}
      >
        <ToastContainer autoClose={3000} hideProgressBar />

        <div
          style={{
            position: 'fixed',
            zIndex: 10000,
            width: 100,
            height: 100,
            marginLeft: -50,
            marginTop: -50,
            left: '50%',
            top: '50%',
            display: loader
          }}
        >
          <img
            className='loderImg'
            src={require('common/images/loader.gif')}
            alt='Loader...'
          />
        </div>
        <div
          className='EnquiryHead col-sm-12 col-centered'
          style={{
            border: '1px solid #aaa',
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            fontWeight: 'bold'
          }}
        >
          <h4>Evaluate Test</h4>
        </div>

        <div className='form-group col-md-12'>
          <label className='control-label col-md-5'>
            Evaluation Date<span className='red-color'>*</span>
          </label>
          <div className='col-sm-6 padding-remove'>
            <Datepicker
              type='date'
              className='form-control'
              selected={getDateForCalendar(evaluationDt)}
              onChange={handleEvaluationDtChange}
              dateFormat='dd-MM-yyyy'
              showYearDropdown
              showMonthDropdown
              dropdownMode='select'
            ></Datepicker>
          </div>
        </div>

        <div className='form-group col-md-12'>
          <label className='control-label col-md-5'> Max Score</label>
          <div className='col-sm-6 padding-remove'>
            <input
              type='text'
              defaultValue={maxScore}
              className='form-control'
              readOnly
            ></input>
          </div>
        </div>
        <div className='form-group col-md-12'>
          <label className='control-label col-md-5'> Passing Score</label>
          <div className='col-sm-6 padding-remove'>
            <input
              type='text'
              defaultValue={minScore}
              className='form-control'
              readOnly
            ></input>
          </div>
        </div>

        <div className='form-group col-md-12'>
          <label className='control-label col-md-5'>
            Score Obtained<span className='red-color'>*</span>
          </label>
          <div className='col-sm-6 padding-remove'>
            <input
              type='text'
              className='form-control'
              defaultValue={score}
              onChange={e => handleScoreChange(e.target.value)}
              required
            ></input>
          </div>
        </div>

        <div className='form-group col-md-12'>
          <label className='control-label col-md-5'>
            Result<span className='red-color'>*</span>
          </label>
          <div className='col-sm-6 padding-remove text-left'>
            <label className='radio-inline'>
              <input
                type='radio'
                name='result'
                value='P'
                checked={result === 'P'}
                onChange={e => handleResultChange(e.target.value)}
              ></input>
              Pass
            </label>
            <label className='radio-inline'>
              <input
                type='radio'
                name='result'
                value='F'
                checked={result === 'F'}
                onChange={e => handleResultChange(e.target.value)}
              ></input>
              Fail
            </label>
          </div>
        </div>

        <div className='form-group col-md-12'>
          <label className='control-label col-md-5'>Remarks</label>
          <div className='col-sm-6 padding-remove'>
            <textarea
              maxLength={500}
              rows='8'
              cols='80'
              defaultValue={remarks}
              className='form-control'
              onChange={e => handleRemarksChange(e.target.value)}
            ></textarea>
          </div>
        </div>

        <div className='form-group col-md-12'>
          <div className='col-md-offset-10 col-md-1 padding-remove'>
            <button
              type='button'
              className='btn btn-info pull-right'
              onClick={e => saveResult()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}
export default ResultForm
