import { StyleSheet } from '@react-pdf/renderer';

const otpBorderWidth = ".6px";
const otpBorderColor = "#ccc";

export const pageStyle = StyleSheet.create({
    page: {
        // backgroundColor: "#fff",
        backgroundColor: "white",
        display: 'flex',
        flexDirection: 'column',
        padding: "25px 15px 15px 15px"
    },
    fullPage: {
        flex: 1,
    },
    page_border: {
        height: '100%',
        width: '100%',
        borderWidth: 1,
        borderColor: "#000",
        // backgroundColor:"red"
    },
    pageHeader: {
        height: "180px",
        // flex: 1,
    },
    pageContent: {
        flex: 1,
        marginTop:"20px",
        flexDirection: "row",
    },
    pageFooter: {
        // flex: 1,
        // bottom:"10px",
        // position:"absolute"
    },
    horizontalBar1: {
        borderBottom: '2px solid #b7b7b7',
        margin: '3px 20px',
        // alignContent:"center",
        // alignItems:"center",
        // textAlign:"center",
        // width:"300px"
    },
    horizontalBar2: {
        borderBottom: '2px solid #b7b7b7',
        // borderBottom: '1px solid #ccc',
        // borderBottom: '2px solid #101010',
        margin: '5px 0 10px 0',
    },
});


export const textStyle = StyleSheet.create({
    textCenterLarge: {
        justifyContent: "center",
        textAlign: "center",
        fontSize: 16,
        color: '#000',
    },
    textCenterMediumBold: {
        justifyContent: "center",
        textAlign: "center",
        fontSize: 13,
        color: '#000',
        fontWeight: "bold",
    },
    textLeftMedium: {
        justifyContent: "center",
        textAlign: "left",
        fontSize: 13,
        color: '#000',
        fontWeight: "400",
    },
    textRightMediumBold: {
        justifyContent: "center",
        textAlign: "right",
        fontSize: 13,
        color: '#000',
        fontWeight: "bold",
    },
    textLeftVerySmall: {
        justifyContent: "center",
        textAlign: "left",
        fontSize: 9,
        color: '#000',
        // borderWidth: 1,
        // borderColor: "#000",
    },
    textLeftSmall: {
        justifyContent: "center",
        textAlign: "left",
        fontSize: 10,
        color: '#000',
        // borderWidth: 1,
        // borderColor: "#000",
    },
    textRightSmall: {
        justifyContent: "center",
        textAlign: "right",
        fontSize: 10,
        color: '#000',
        // borderWidth: 1,
        // borderColor: "#000",
    },
    textLine: {
        justifyContent: "flex-end",
        textAlign: "right",
        fontSize: 8,
        color: "#101010"
    }
})

export const headerStyle = StyleSheet.create({
    row: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        textAlign: "center",
    },
    column: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
    },
    leftSection: {
        flex: 3,
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "left",
        // borderWidth: 1,
        // borderColor: "#ccc",
    },
    midSection: {
        flex: 4,
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        // borderWidth: 1,
        // borderColor: "#ccc",
    },
    rightSection: {
        flex: 3,
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "flex-end",
        textAlign: "left",
        // borderWidth: 1,
        // borderColor: "#ccc",
        // borderTopWidth: 1,
        // borderTopColor: "#ccc",
        // borderBottomWidth: 1,
        // borderBottomColor: "#ccc",
        // borderRightWidth: 1,
        // borderRightColor: "#ccc",
    },
    vieLeftValue: {
        flex: 3,
        justifyContent: "center",
        textAlign: "right",
        padding: "2px 5px",
    },
    viewCenter: {
        flex: 1,
        justifyContent: "center",
        textAlign: "center",
        alignContent: "center",
        padding: "2px 5px",
        // borderWidth: 1,
        // borderColor: "#ccc",
    },
    viewLeftLabel: {
        flex: 1,
        justifyContent: "center",
        textAlign: "left",
        padding: "2px 5px"
    },
    viewRightLabel: {
        flex: 14,
        justifyContent: "center",
        textAlign: "left",
        padding: "2px 5px"
    },
    viewRightValue: {
        flex: 8,
        justifyContent: "center",
        textAlign: "right",
        padding: "2px 5px",
        // borderWidth: 1,
        // borderColor: "#ccc",
    },
    viewRightLabel1: {
        flex: 7,
        justifyContent: "center",
        textAlign: "left",
        padding: "2px 5px"
    },
    viewRightValue1: {
        flex: 8,
        justifyContent: "center",
        textAlign: "right",
        padding: "2px 5px",
        // borderWidth: 1,
        // borderColor: "#ccc",
    },
    header: {
        margin: "10px 0"
    },
});



export const styleHeaderLogo = StyleSheet.create({
    image1: {
        maxHeight: "100%",
        maxWidth: "80%",
        textAlign: "center",
        padding: 0,
        alignContent: "center",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
    },
});

export const styleOMR = StyleSheet.create({
    column: {
        flexDirection:"column",
        width: '33%', 
        // height:"auto",
        padding: "5px",
        // borderWidth: 1,
        // borderColor: "#ccc",
    },
    row: {
        flexDirection: 'row',
        justifyContent:'center',
        alignItems: 'center',
        marginBottom: "2px",
        // padding:"3px",
        height: "20px",
        width:"150px",
        borderWidth: 1,
        borderColor: "#ccc",
    },
    questionNumber: {
        width: 30,
        fontSize:10,
        height:"20px",
        textAlign: 'center',
        marginRight: 10,
        paddingTop:"2px",
        justifyContent:'center',
        margin:"auto",
    },
    circleContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        height:"20px"
    },
    circle: {
        width: "14px",
        height: "14px",
        borderRadius: "7px",
        borderWidth: 1,
        borderColor: '#ccc',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin:"auto",
    },
    circleText: {
        fontSize: 8,
        color: 'gray',
    },
});