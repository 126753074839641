import axios from 'axios'
import lstModules from 'common/json/lstModules.json';
var tokenid = localStorage.getItem('tokenid');
var httpURL = lstModules['enquiry'].url + ":" + lstModules['enquiry'].port;
var httpURLCommon = lstModules['main'].url + ":" + lstModules['main'].port;
function getLstSummarySvc(cbGetLstSummarySvcResponse, fromDt, toDt) {
  var formData = new FormData();
  formData.append('fromDt', fromDt);
  formData.append('toDt', toDt);
  axios(
    {
      url: httpURL+"/sms/enquiry/dashboard/summary",
      method: 'POST',
      data: formData,
      headers: { 'tokenid': tokenid },
      withCredentials: true
    }
  ).then(
    (Response) => {
      var data = Response.data
      // // console.log('summary list fetching success' + data.lstData)
      cbGetLstSummarySvcResponse(data)

    }
  ).catch((error) => {
    if (error.response) {
      // // console.log(error.response.data);
      // // console.log(error.response.status);
      // // console.log(error.response.headers);
    }
  });

}
function getLstDataSvc(cbGetLstDataSvcResponse, fromDt, toDt) {

  axios(
    {
      url: httpURL+"/sms/enquiry/dashboard/data",
      method: 'POST',
      params: { fromDt: fromDt, toDt: toDt },
      headers: { 'tokenid': tokenid },
      withCredentials: true
    }
  ).then(
    (Response) => {
      var data = Response.data
      // // console.log('data list fetching success')
      cbGetLstDataSvcResponse(data)

    }
  ).catch((error) => {
    if (error.response) {
      // // console.log(error.response.data);
      // // console.log(error.response.status);
      // // console.log(error.response.headers);
    }
  });

}

export { getLstSummarySvc };
export { getLstDataSvc };
