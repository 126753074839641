import axios from 'axios';
import lstModules from 'common/json/lstModules.json';
var tokenid = localStorage.getItem("tokenid");
var httpURLAcdYr = lstModules['academicYear'].url + ":" + lstModules['academicYear'].port;
var httpURLCommon = lstModules['main'].url + ":" + lstModules['main'].port;

function getLstStudentSvc(fromAcdYrId, fromClsId, cb) {
    var obj = { status: "Success" }
    axios({
        url: httpURLAcdYr + '/sms/promote/student/list',
        method: "POST",
        params: { fromAcdYrId, fromClsId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {

        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        if (response.data.SvcStatus === 'Success') {
            obj.lstStudent = response.data.lstStudent;
        } else {
            obj.lstStudent = [];
        }
        cb(obj)

    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = 'Service Failed ' + error;
        obj.lstStudent = []
        cb(obj)
    });
}
function calcDueSvc(fromYrId, toYrId, cb) {
    var obj = { status: "Success" }
    axios({
        url: httpURLAcdYr + '/sms/acdyr/feedue/carry_forward',
        method: "POST",
        params: { 'oldAcdYrId': fromYrId, 'newAcdYrId': toYrId },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {

        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cb(obj)

    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = 'Service Failed ' + error;
        cb(obj)
    });
}

function promoteSvc(pObj, cb) {
    var obj = { status: "Success" }

    axios({
        url: httpURLCommon + '/sms/promote/class/promote',
        method: "POST",
        params: {
            promote: JSON.stringify(pObj)
        },
        headers: { 'tokenid': tokenid },
        withCredentials: true,
    }).then((response) => {

        obj.status = response.data.SvcStatus;
        obj.message = response.data.SvcMsg;
        cb(obj)

    }).catch((error) => {
        obj.status = 'Exception';
        obj.message = 'Service Failed ' + error;
        cb(obj)
    });
}

function savePromotionSvc(promoteObj, lstStudent, cbPromoteResponse) {
    var obj = { status: "Success" }
    var formData = new FormData();
    formData.append("promote", JSON.stringify({
        fromAcdYrId: promoteObj.fromAcdYrId
        , fromClsId: promoteObj.fromClsId
        , toAcdYrId: promoteObj.toAcdYrId
        , toSectionId: promoteObj.toSectionId
        , lstStudentId: lstStudent
    }));
    axios(
        {
            url: httpURLAcdYr + "/sms/promote/section/promote",
            method: "POST",
            withCredentials: true,
            headers: { 'tokenid': tokenid },
            data: formData

        })
        .then((response) => {
            var data = response.data;
            obj.status = data.SvcStatus;
            obj.message = data.SvcMsg;
            cbPromoteResponse(obj)
        })
        .catch((error) => {

            obj.status = "Exception"
            obj.message = error;
            cbPromoteResponse(obj)
        });


}

export { getLstStudentSvc, savePromotionSvc }