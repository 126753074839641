import axios from 'axios';
import lstModules from 'common/json/lstModules.json';
var tokenid = localStorage.getItem("tokenid");
var httpURL = lstModules['performance'].url + ":" + lstModules['performance'].port;
var httpURLTt = lstModules['timeTable'].url + ":" + lstModules['timeTable'].port;

function getLstAllocationSvc(cbLstAllocationResponse, examId) {
    var obj = { status: "success", lstAllocation: [] }
    axios(
        {
            url: httpURL + "/sms/performance/allocate/list",
            method: "POST",
            withCredentials: true,
            params: { examId: examId },
            headers: { 'tokenid': tokenid }

        })
        .then((Response) => {
            var data = Response.data;
            if (data.SvcStatus === "Success") {
                obj.status = data.SvcStatus;
                obj.message = data.SvcMsg;
                obj.lstAllocation = data.lstAllocation;
            } else {
                obj.status = data.SvcStatus;
                obj.message = data.SvcMsg;
            }

            cbLstAllocationResponse(obj)

        })
        .catch((error) => {
            obj.status = "Exception"
            obj.message = error;
            cbLstAllocationResponse(obj)
        });
}

const teachersList = (cb) => {

    var obj = { status: "success", lstTch: [] }
    axios(
        {
            url: httpURLTt + "/sms/tt/v1/tch/list",
            method: 'POST',
            params: {},
            withCredentials: true,
            headers: { 'tokenid': tokenid }
        }
    ).then(
        (Response) => {
            var data = Response.data;
            if (data.SvcStatus === "Success") {
                obj.status = data.SvcStatus;
                obj.message = data.SvcMsg;
                obj.lstTch = data.lstTch;
            } else {
                obj.status = data.SvcStatus;
                obj.message = data.SvcMsg;
            }

            cb(obj)

        }
    ).catch((error) => {
        obj.status = "Exception"
        obj.message = error;
        cb(obj)
    });
}
function saveAllocationSvc(examId, expHrs, allocatedToId, lstData, cbsaveAllocationResponse) {
    var obj = { status: "Success" }
    var formData = new FormData();
    formData.append("allocateObj", JSON.stringify({ examId: examId, expHrs: parseInt(expHrs), allocatedToId: parseInt(allocatedToId), lstData: lstData }));
    axios(
        {
            url: httpURL + "/sms/performance/allocate/save",
            method: "POST",
            withCredentials: true,
            headers: { 'tokenid': tokenid },
            data: formData

        })
        .then((response) => {
            var data = response.data;
            if (data.SvcStatus === "Success") {
                obj.status = data.SvcStatus;
                obj.message = data.SvcMsg;
                obj.lstTch = data.lstTch;
            } else {
                obj.status = data.SvcStatus;
                obj.message = data.SvcMsg;
            }
            cbsaveAllocationResponse(obj)
        })
        .catch((error) => {

            obj.status = "Exception"
            obj.message = error;
            cbsaveAllocationResponse(obj)
        });


}


export { getLstAllocationSvc, teachersList, saveAllocationSvc }